// Basic
import React from 'react';

// UI, images, styles and icons
import '../App.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import PowerIcon from '@material-ui/icons/Power';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import CloudIcon from '@material-ui/icons/Cloud';
import CloudOffIcon from '@material-ui/icons/CloudOff';
import WarningIcon from '@material-ui/icons/Warning';
import { dateDiff } from '../helpers/Intl';

const useStyles = makeStyles({
  card: {
    padding: '15px',
    cursor: 'pointer',
    width: '100%',
  },
  notifications: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  notificationIcon: {
    fontSize: '30px',
    margin: '0 12px',
  },
  onIcon: {
    color: 'green',
    fontSize: '30px',
    margin: '0 12px',
  },
  alertOnIcon: {
    color: 'red',
    fontSize: '30px',
    margin: '0 12px',
  },
  offIcon: {
    color: 'gray',
    fontSize: '30px',
    opacity: 0.4,
    margin: '0 12px',
  },
});

export default (props) => {
  const classes = useStyles();
  const MySwal = withReactContent(Swal);

  return (
    <div className={classes.notifications}>
      <Tooltip
        title={props.connection && props.connection.date ? `Última Conexão: ${dateDiff(
          new Date(props.connection.date),
        )}` : 'Sem dados de conexão'}
        style={{ cursor: 'default' }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {props.connection && !props.connection.offline ? (
          <CloudIcon className={classes.onIcon} />
        ) : (
          <CloudOffIcon className={classes.notificationIcon} />
        )}
      </Tooltip>

      <Tooltip
        title={props.running ? 'Ligado' : 'Desligado'}
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ cursor: 'default' }}
      >
        {props.running ? (<PowerIcon className={classes.onIcon} />) : (
          <PowerOffIcon className={classes.notificationIcon} />
        )}
      </Tooltip>

      <Tooltip
        title={props.alert && props.alert.active ? `${(props.alert.list || []).length} alerta${(props.alert.list || []).length > 1 ? 's' : ''} encontrado${(props.alert.list || []).length > 1 ? 's' : ''}` : 'Nenhum alerta'}
        onClick={(e) => {
          e.stopPropagation();
          MySwal.fire({
            icon: props.alert && props.alert.active ? 'warning' : 'success',
            title: props.alert && props.alert.active ? `${(props.alert.list || []).length} alerta${(props.alert.list || []).length > 1 ? 's' : ''} encontrado${(props.alert.list || []).length > 1 ? 's' : ''}` : 'Nenhum alerta encontrado!',
            html: `
              <div style="text-align: left">
              ${(props?.alert?.list || []).map((m, i) => `<p>${i + 1}) ${m}</p>`).join('')}
              </div>`,
          });
        }}
        style={{ cursor: 'help' }}
      >
        {props.alert && props.alert.active ? (<WarningIcon className={classes.alertOnIcon} />) : (
          <WarningIcon className={classes.notificationIcon} />
        )}
      </Tooltip>
    </div>
  );
};
