import React from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import '../../../App.css';
import AdornField from '../../../components/Fields/AdornField';
import Breadcrumbs from '../../../components/Breadcrumbs';
import BottomButtons from '../../../components/Buttons/BottomButtons';
import FockinkAPI from '../../../middlewares/Api';

export default () => {
  const [subTypes, setSubTypes] = React.useState({
    irrigation: [],
    thermometry: [],
    automation: [],
    photovoltaic: [],
  });
  const [add, setAdd] = React.useState({
    irrigation: '',
    thermometry: '',
    automation: '',
    photovoltaic: '',
  });
  const [loading, setLoading] = React.useState(true);
  const MySwal = withReactContent(Swal);

  const sysTranslate = {
    irrigation: 'Irrigação',
    thermometry: 'Termometria',
    automation: 'Automação',
    photovoltaic: 'Fotovoltaica',
  };

  React.useEffect(() => {
    FockinkAPI.get('/settings/techDeliveryTypes').then((response) => {
      setSubTypes({
        irrigation: response.data.data.irrigation,
        thermometry: response.data.data.thermometry,
        automation: response.data.data.automation,
        photovoltaic: response.data.data.photovoltaic,
      });
      setLoading(false);
    });
  }, []);

  const handleDelete = (h, system) => {
    MySwal.fire({
      title: `Tem certeza que quer apagar "${h}"?`,
      text: 'Essa ação não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, APAGAR!',
      cancelButtonText: 'Não, MANTER',
    }).then((result) => {
      if (result.isConfirmed) {
        const newArr = subTypes[system].filter((f) => f !== h);
        setSubTypes({ ...subTypes, [system]: newArr });
      }
    });
  };

  const handleAdd = (system) => {
    const newArr = [...subTypes[system], add[system]];
    newArr.sort();
    setSubTypes({ ...subTypes, [system]: newArr });
    setAdd({ ...add, [system]: '' });
  };

  const handleChange = (event, system) => {
    setAdd({ ...add, [system]: event.target.value });
  };

  const saveData = () => {
    const dt = {
      data: subTypes,
    };

    dt.method = 'put';
    dt.url = '/settings/techDeliveryTypes';
    return dt;
  };

  const breadcrumb = {
    title: 'Subtipos de Entrega Técnica',
    routes: [
      { to: '/settings', name: 'Configurações' },
      { name: 'Subtipos de Entrega Técnica' },
    ],
  };

  if (!localStorage.profile) {
    return <Redirect to="/logout" />;
  }

  if (loading) {
    return (
      <Skeleton variant="rect" height={250} width="100%" animation="wave" />
    );
  }

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1}>
        {['irrigation', 'thermometry', 'automation', 'photovoltaic'].map(
          (system) => (
            <Grid item xs={12}>
              <Paper style={{ padding: '15px' }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <h1>{sysTranslate[system]}</h1>
                  </Grid>
                  <Grid item lg={4} md={6} xs={9}>
                    <AdornField
                      name="add"
                      handleChange={(e) => handleChange(e, system)}
                      value={add[system]}
                      label={`Novo subtipo de ${sysTranslate[system]}`}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      size="large"
                      variant="contained"
                      color="secondary"
                      style={{ color: 'white', marginTop: '5px' }}
                      onClick={() => handleAdd(system)}
                      startIcon={<AddIcon />}
                    >
                      Adicionar
                    </Button>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      {subTypes[system].map((h) => (
                        <Grid item lg={2} md={4} xs={6}>
                          <Chip
                            key={h}
                            label={h}
                            onDelete={() => handleDelete(h, system)}
                            color="primary"
                            style={{ margin: '0 5px 5px' }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ),
        )}

        <Grid
          item
          xs={12}
          style={{
            marginTop: '15px',
            borderTop: '1px solid #ccc',
            paddingTop: '10px !important',
            textAlign: 'right',
          }}
        >
          <BottomButtons saveData={saveData} backTo="/settings" />
        </Grid>
      </Grid>
    </>
  );
};
