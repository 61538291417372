// Basic
import React from 'react';

// UI, images, styles and icons
import '../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import BackButton from '../../../components/Buttons/BackButton';
import EquipmentCards from './EquipmentCards';
import EquipmentMap from './EquipmentMap';
import Breadcrumbs from '../../../components/Breadcrumbs';
// import { set } from 'lodash';
import './styles.css';

const useStyles = makeStyles({
  spacing: {
    marginTop: '15px',
  },
  bottomButtons: {
    margin: '25px 0',
    textAlign: 'right',
  },
  noMargin: {
    margin: 0,
  },
  toggleButton: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  smallIcon: {
    height: '30px',
  },
});

const breadcrumb = {
  routes: [{ name: 'Equipamentos' }],
};

export default () => {
  // Stats
  const [view, setView] = React.useState();
  const [plantId, setPlantId] = React.useState();
  const [operation, setOperation] = React.useState();
  const [linkRoute, setLinkRoute] = React.useState();
  const classes = useStyles();

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const mapCard = params.get('view');
    const plant = params.get('plantId');
    const op = params.get('op');

    setView(mapCard || 'cards');
    setPlantId(plant);
    setOperation(op);
  }, [window.location.search]);

  React.useEffect(() => {
    operation === 'irrigation'
      ? setLinkRoute('/plants/?op=irrigation')
      : setLinkRoute(plantId ? `/plants?view=${view}` : '/');
  }, [operation]);

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} />
      {/* <Grid container spacing={3} alignItems="flex-start"> */}
      <Grid item xs={12} style={{ paddingTop: '0' }}>
        {!view && <Skeleton variant="rect" height={150} />}
        {view === 'cards' && <EquipmentCards view={view} setView={setView} />}
        {view === 'map' && <EquipmentMap view={view} setView={setView} />}
      </Grid>
      <Grid item xs={12} className={classes.bottomButtons}>
        <BackButton redirectTo={linkRoute} className={classes.btnSpace} />
      </Grid>
      {/* </Grid> */}
    </>
  );
};
