import React, {
  useState, useCallback, useRef, useEffect,
} from 'react';
import ReactCrop from 'react-image-crop';
import Grid from '@material-ui/core/Grid';
import BackupIcon from '@material-ui/icons/Backup';
import Button from '@material-ui/core/Button';
import 'react-image-crop/dist/ReactCrop.css';

export default function App(props) {
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const fileInput = useRef(null);
  const [fileSelected, setFileSelected] = useState(undefined);
  const [noImage, setNoImage] = useState(false);
  const [crop, setCrop] = useState({ unit: '%', width: 30 });
  const [completedCrop, setCompletedCrop] = useState(null);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setFileSelected(true);
    }
  };

  const onLoad = useCallback((img) => { imgRef.current = img; }, []);

  useEffect(() => {
    if (!completedCrop || !props.previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const nw = image.naturalWidth;
    const nh = image.naturalHeight;

    const canvas = props.previewCanvasRef.current;
    const scaleX = nw / image.width;
    const scaleY = nh / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = completedCrop.width;
    canvas.height = completedCrop.height;
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height,
    );
  }, [completedCrop]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={3}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={4} xs={4}>
            {!noImage ? (
              <img
                src={`${process.env.REACT_APP_FILESTORAGE_API}/organizations/${props.organizationId}/accounts/${props.accountId}/logo.jpg?${Math.floor(Math.random() * 1000000000)}`}
                alt="logo"
                style={{ width: '100%' }}
                onError={() => setNoImage(true)}
              />
            ) : (
              <img
                src="\images\suaLogo.png"
                alt="logo"
                style={{ width: '100%' }}
              />
            )}
            <input type="file" accept="image/*" onChange={onSelectFile} ref={fileInput} style={{ display: 'none' }} />
          </Grid>
          {!props.noUpload && (
          <Grid item lg={12} md={4} xs={4}>
            <Button
              size="medium"
              style={{ marginTop: '20px' }}
              variant="contained"
              color="secondary"
              onClick={() => fileInput.current && fileInput.current.click()}
              startIcon={<BackupIcon />}
            >
              Upload
            </Button>
          </Grid>
          )}
        </Grid>
      </Grid>
      {fileSelected && (
        <>
          <Grid item xs={3}>
            <h3 style={{ textAlign: 'center' }}>
              Recorte a Imagem
            </h3>
            <ReactCrop
              src={upImg}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
            />
          </Grid>
          <Grid item xs={3}>
            <h3 style={{ textAlign: 'center' }}>Imagem Cortada</h3>
            <canvas
              ref={props.previewCanvasRef}
              style={{
                width: '100%',
                height: 'auto',
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
