import { createMuiTheme } from '@material-ui/core/styles';

export const adminTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#1e1f1d',
      contrastText: '#fff',
    },
    secondary: {
      main: '#2F80ED',
      contrastText: '#fff',
    },
    background: {
      default: '#E5E5E5',
      paper: '#FAFAFA',
    },
    text: {
      primary: '#727272',
      secondary: '#4F4F4F',
      disabled: '#BDBDBD',
      hint: '#25D500',
    },
  },

  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: '#F2F2F2',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
  },
});
