import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';

const customMedia = generateMedia({
  xs: '120px',
  xxs: '400px',
  sm: '767px',
  md: '1024px',
  lg: '1200px',
  lgg: '1300px',
});

export const Container = styled.div`
  display: flex;
  max-width: 250px;
  height: 100%;
  min-height: 260px;
  padding: 0.5rem;
  margin-left: 2rem;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.greenLight};
  border-radius: 0.4rem;
`;

export const Header = styled.div`
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

export const Grain = styled.p`
  font-weight: bold;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.greenDeep};
  margin: 0px;
  max-height: 92px;
  max-width: 170px;
  line-height: 2rem;

  ${customMedia.between('xs', 'md')`
    font-size: 23px;
  `}
`;

export const DateGrain = styled.p`
  font-size: 11px;
`;

export const ContainerGrain = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 92px;
`;

export const Chart = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: baseline;
  max-width: 100%;
`;

export const ButtonClose = styled.button`
  width: auto;
  align-self: flex-end;
  background-color: ${({ theme }) => theme.colors.greenLight2};
  outline: none;
  border-style: none;
  color: ${({ theme }) => theme.colors.messageDark5};
  margin-left: 10px;
  border-radius: 3px;
  font-size: 14px;
`;
