/* eslint-disable no-mixed-operators */
import React from 'react';
import colors from '../../../styles/colors';

export default (props) => {
  const color = props.status ? colors.pivotOk : colors.darkGrey;
  const backgroundColor = 'none';

  return (
    <svg viewBox="0 0 500 500" width="100%">
      <g onClick={props.onClick}>
        <rect
          width="500"
          height="500"
          style={{ fill: backgroundColor }}
          rx="57.152"
          ry="57.152"
        />
        <g>
          {props.status && (
            <g>
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="190.3"
                cy="423.581"
                r="7.772"
              />
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="222.104"
                cy="423.581"
                r="7.772"
              />
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="253.543"
                cy="423.581"
                r="7.772"
              />
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="286.078"
                cy="423.581"
                r="7.772"
              />
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="317.517"
                cy="423.581"
                r="7.772"
              />
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="174.756"
                cy="447.708"
                r="7.772"
              />
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="214.378"
                cy="447.708"
                r="7.772"
              />
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="253.543"
                cy="447.708"
                r="7.772"
              />
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="293.031"
                cy="447.708"
                r="7.772"
              />
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="333.061"
                cy="447.708"
                r="7.772"
              />
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="158.306"
                cy="472.201"
                r="7.772"
              />
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="206.56"
                cy="472.201"
                r="7.772"
              />
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="253.543"
                cy="472.201"
                r="7.772"
              />
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="301.622"
                cy="472.201"
                r="7.772"
              />
              <circle
                style={{ fill: color, fillOpacity: 0.6 }}
                cx="349.877"
                cy="472.201"
                r="7.772"
              />
            </g>
          )}
          <g>
            <path
              d="M 340.832 232.808 C 340.832 280.91 317.79 322.023 285.259 338.541 L 285.259 352.26 L 300.082 352.26 L 334.016 400.213 L 301.994 400.213 L 276.768 374.987 L 266.62 399.486 L 240.256 399.486 L 230.378 375.639 L 206.134 399.883 L 174.802 399.883 L 207.837 352.26 L 223.079 352.26 L 223.079 339.247 C 189.797 323.219 166.078 281.611 166.078 232.808 C 166.078 197.213 178.696 165.445 198.439 144.638 L 198.439 128.206 L 213.991 128.206 L 213.991 96.638 L 202.042 96.638 C 195.518 96.638 190.229 91.349 190.229 84.825 L 190.229 71.94 L 318.644 71.94 L 318.644 84.825 C 318.644 91.349 313.355 96.638 306.831 96.638 L 294.346 96.638 L 294.346 128.206 L 309.778 128.206 L 309.778 146.043 C 328.769 166.86 340.832 198.005 340.832 232.808 Z M 197.132 228.013 C 197.132 264.993 209.909 296.989 228.491 312.359 L 238.056 312.359 L 238.056 191.821 L 223.079 191.821 L 223.079 159.64 L 214.773 159.64 C 203.91 176.788 197.132 201.079 197.132 228.013 Z M 309.778 228.013 C 309.778 201.079 303 176.788 292.137 159.64 L 285.259 159.64 L 285.259 191.821 L 270.607 191.821 L 270.607 312.359 L 278.419 312.359 C 297.001 296.989 309.778 264.993 309.778 228.013 Z"
              style={{ fill: color }}
            />
            <rect
              x="190.252"
              y="31.836"
              width="127.825"
              height="23.985"
              style={{ fill: color }}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
