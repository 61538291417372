/* eslint-disable no-bitwise */
import React from 'react';
import Chart from 'react-apexcharts';
// import { normalizeErrorData } from '../../../../../helpers/normalizeErrorData';
import { number, formatDate } from '../../../../../helpers/Intl';

export function MultilineGraph({ series }) {
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    // stroke: {
    //   curve: 'smooth',
    //   dashArray: dashArray(),
    // },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        formatter: (day) => formatDate(new Date(day)),
      },
      y: {
        formatter: (legendData) => number(legendData),
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (value) => formatDate(new Date(value)),
        // format: 'dd/MM/yyyy',
      },
    },
    yaxis: {
      type: 'number',
      // title: {
      //   text: 'Total de pontos quentes',
      // },
      labels: {
        formatter: (value) => number(value),
      },
    },
    // responsive: [
    //   {
    //     breakpoint: 720,
    //     options: {
    //       xaxis: {
    //         labels: {
    //           show: false,
    //         },
    //       },
    //     },
    //   },
    // ],
  };

  return <Chart type="area" height={350} series={series} options={options} />;
}
