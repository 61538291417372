/* eslint-disable indent */
import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  withStyles,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';

import { subDays, addDays } from 'date-fns';
import {
  GiHeavyRain,
  MdGrain,
  BiCloudLightRain,
  FiAlertTriangle,
} from 'react-icons/all';
import { useHistory } from 'react-router-dom';
import { Ellipsis } from 'react-spinners-css';
import FockinkAPI from '../../../../middlewares/Api';
import { number, formatDate } from '../../../../helpers/Intl';
import * as C from './components';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import BackButton from '../../../../components/Buttons/BackButton';

const breadcrumb = {
  title: 'Planejamento de Irrigação',
  routes: [
    { name: 'Relatórios', to: '/reports' },
    { name: 'Planejamento de Irrigação' },
  ],
};

const useStyles = makeStyles({
  root: {
    minHeight: 'calc(95vh - 145px)',
    maxWidth: '1900px',
    margin: '0 auto',
  },
  divCell: {
    marginTop: '0.6rem',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '410px',
  },
  buttonBack: {
    marginTop: '3rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export function IrrigationPlanning() {
  const [breadcrumbs, setBreadcrumbs] = React.useState({ routes: [] });
  const classes = useStyles();
  const history = useHistory();

  const [data, setData] = useState();
  const [dateHeaderTable, setDateHeaderTable] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [plants, setPlants] = useState();
  const [plantId, setPlantId] = useState();
  const [quantityDaysToConsult, setQuantityDaysToConsult] = useState(5);
  const urlParams = new URLSearchParams(window.location.search);
  const url = urlParams.get('accountId');

  function makeColumnsDate(todayDate) {
    const startDate = subDays(todayDate, quantityDaysToConsult);
    const dateArray = [];

    let currentDate = startDate;
    while (currentDate < todayDate) {
      dateArray.push(formatDate(new Date(currentDate), 'dd/MM/yyyy'));
      currentDate = addDays(currentDate, 1);
    }
    return dateArray;
  }

  const dateKeys = makeColumnsDate(new Date()).map((date) => {
    const d = date.split('/');
    return formatDate(new Date(`${d[2]}/${d[1]}/${d[0]}`), 'yyyy-MM-dd');
  });

  useEffect(() => {
    FockinkAPI.get(`/dashboards/plants?accountId=${url}`).then((response) => {
      setPlants(response.data.data.plants.filter((plant) => plant.operationId === 1));
      setPlantId(response.data.data.plants.filter((plant) => plant.operationId === 1)[0].id);
    });
  }, []);
  useEffect(() => {
    setBreadcrumbs({
      title: 'Planejamento de Irrigação',
      routes: [
        { name: 'Relatórios', to: '/reports' },
        { name: 'Planejamento de Irrigação' },
      ],
      secondRoute: [
        { name: 'Clientes', to: '/clientsAdm/' },
        { name: 'Relatórios', to: `/reportsAdm?${urlParams}&op=all` },
        { name: 'Planejamento de Irrigação' },
      ] });
    (async () => {
      try {
        setLoading(true);
        setError(false);
        const paramsRequest = {
          startDate: formatDate(
            subDays(new Date(), quantityDaysToConsult),
            'yyyy-MM-dd',
          ),
          dateFinish: formatDate(new Date(), 'yyyy-MM-dd'),
          plantId,
        };

        const response = await FockinkAPI.get(
          'reports/whenIrrigate',
          { params: paramsRequest },
        );

        const { reports } = response.data.data;
        if (reports.length === 0) {
          setLoading(false);
          setData();
          return;
        }

        const dateApi = makeColumnsDate(new Date()).map((date) => {
          const d = date.split('/');
          return formatDate(new Date(`${d[2]}/${d[1]}/${d[0]}`), 'dd/MM');
        });

        if (quantityDaysToConsult === 5) {
          const dateWillRain = willRainDateKeys(
           (response?.data.data.reports[0].willRain),
          ).map((date) => {
            const d = date.split('-');
            const formattedDate = formatDate(
              new Date(`${d[0]}/${d[1]}/${d[2]}`),
              'dd/MM',
            );
            return formattedDate;
          });

          const newArray = dateApi.concat(dateWillRain);
          setDateHeaderTable(newArray);
        } else setDateHeaderTable(dateApi);

        setData(response.data.data);
        setLoading(false);
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [quantityDaysToConsult, plantId]);

  const makeIrrigationCell = (irrigationData, date, equipament) => {
    const irrigationResult = Math.max.apply(null, irrigationData);
    const uniformIrrigation = new Set(irrigationData).size;

    return (
      <div>
        {Number(irrigationResult).toFixed(2)}
        <MdGrain color="blue" style={{ marginLeft: '5px' }} />
        {uniformIrrigation > 1 && (
          <FiAlertTriangle
            style={{ marginLeft: '5px' }}
            size={12}
            color="#c53030"
          />
        )}
        {(((equipament) && (equipament.rained[date])) || (equipament.rained[date] === 0)) && (
          <div className={classes.divCell}>
            {number(equipament.rained[date], 2, 2)}
            <BiCloudLightRain color="blue" style={{ marginLeft: '5px' }} />
          </div>
        )}
      </div>
    );
  };

  const makeWillRainCell = (rowWillRainData, willRainDate) => (
    <StyledTableCell align="center" style={{ background: '#a5ecf3' }}>
      <div className={classes.divCell}>
        {number(rowWillRainData.days[willRainDate], 2, 2)}
        <GiHeavyRain color="blue" style={{ marginLeft: '5px' }} />
      </div>
    </StyledTableCell>
  );

  const willRainDateKeys = (datesRain) => Object.keys(datesRain.days);

  const formatEquipamentPositon = (position) => ` ${position}º `;

  return (
    <div className={classes.root}>
      <Breadcrumbs routes={localStorage.profile === 'admin' && urlParams ? breadcrumbs.secondRoute : breadcrumbs.routes} title={breadcrumbs.title} />

      <div className={classes.content}>

        <FormControl variant="filled" style={{ maxWidth: 250, marginBottom: 20 }}>
          <InputLabel id="plantId">Plantas de Irrigação</InputLabel>
          <Select
            labelId="plantId"
            id="plantId"
            name="plantId"
            value={plantId || ''}
            label="Cliente"
            onChange={(e) => setPlantId(e.target.value)}
          >
            {plants?.map((plant) => (
              <MenuItem value={plant.id}>{plant.description}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <C.HeaderTable
          quantityDays={quantityDaysToConsult}
          setState={setQuantityDaysToConsult}
        />
        <div className={classes.main}>
          {// eslint-disable-next-line no-nested-ternary
          loading ? (
            <Ellipsis color="#3D5059" />
          ) : data ? (
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Equipamento</StyledTableCell>
                    <StyledTableCell align="center">Cultura</StyledTableCell>

                    {dateHeaderTable &&
                      dateHeaderTable.map((dateColumn) => (
                        <StyledTableCell align="center">
                          {dateColumn}
                        </StyledTableCell>
                      ))}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.reports.map((row) => (
                      <StyledTableRow key={row.equipamentId}>
                        <StyledTableCell component="th" scope="row">
                          {row.description}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.culture.grain}
                          <br />
                          {`${formatEquipamentPositon(
                            row.culture.startingPosition,
                          )}-${formatEquipamentPositon(
                            row.culture.finalPosition,
                          )}`}
                        </StyledTableCell>
                        {dateKeys.map((rowDate) => (
                          <StyledTableCell align="center">
                            {// eslint-disable-next-line no-nested-ternary
                            row.irrigated[rowDate]
                              ? makeIrrigationCell(
                                  row.irrigated[rowDate],
                                  rowDate,
                                  row,
                                )
                              : row.rained[rowDate] ?
                             row.rained[rowDate] : ''
                            }
                          </StyledTableCell>
                        ))}
                        {quantityDaysToConsult === 5 &&
                          willRainDateKeys(row.willRain).map(
                            (rowWillRainDate) =>
                              makeWillRainCell(row.willRain, rowWillRainDate),
                            // eslint-disable-next-line function-paren-newline
                          )}
                      </StyledTableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <span>Sem dados para serem exibidos</span>
          )}

          {error && (
            <span>
              Encontramos um erro inesperado erro para obter os dados.
            </span>
          )}
        </div>
        <div className={classes.buttonBack}>
          <BackButton
            back={() => history.goBack()}
            className={classes.buttonBack}
          />
        </div>
      </div>
    </div>
  );
}
