import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Grid } from '@material-ui/core';
import { RiCloseFill } from 'react-icons/ri';
import { IconButton } from '@mui/material';
import SvgEngine from './svgEngine';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 0,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function EngineModal({ engineInfo, openModal, closeModal }) {
  const classes = useStyles();

  const handleClose = () => {
    closeModal(!openModal);
  };
  return (
    <div>
      <Modal
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ marginTop: 0, textAlign: 'right' }}>
                <IconButton onClick={handleClose}>
                  <RiCloseFill size={30} color="#556E7B" />
                </IconButton>
              </Grid>
              <Grid item xs={12} lg={4} style={{ marginTop: -35, textAlign: 'center' }}>

                <SvgEngine onOff={engineInfo.engineOnOff} />

              </Grid>
              <Grid item xs={12} lg={8} style={{ marginTop: -35 }}>
                <h2>{engineInfo.engineName}</h2>
                <p>
                  Status:
                  {' '}
                  <b>
                    {engineInfo.engineOnOff === 'L' ? 'Ligado' : 'Desligado'}
                  </b>
                </p>
                <p>
                  Horas de Aeração:
                  {' '}
                  <b>
                    {engineInfo.engineAeration}
                    {' '}
                    h
                  </b>
                </p>
              </Grid>
            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
