import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import withReactContent from 'sweetalert2-react-content';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import { Card } from '@mui/material';
import AdornField from '../../../../components/Fields/AdornField';
import FockinkAPI from '../../../../middlewares/Api';
import BottomButtons from '../../../../components/Buttons/BottomButtons';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import CardTitle from '../../../../components/CardTitle';
import Spacer from '../../../../components/Spacer';
import CardText from '../../../../components/CardText';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

export default () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    hourUtc: 10,
    emails: [],
    active: true,
  });
  const [add, setAdd] = React.useState('');
  const MySwal = withReactContent(Swal);

  const breadcrumb = {
    title: 'Cadastro de Relatório',
    routes: [
      { to: '/settings', name: 'Configurações' },
      { to: '/settings/reportSettings', name: 'Relatórios' },
      { name: 'Cadastro de Relatório' },
    ],
  };

  useEffect(() => {
    FockinkAPI.get('/reports/settings/qualityReport')
      .then((response) => {
        const hourUtc = moment()
          .utc().hour(response.data.data.data.hourUtc).tz(localStorage.tz)
          .hour();
        setData({
          hourUtc,
          emails: response.data.data.data.emails,
          active: response.data.data.active,
        });
        setLoading(false);
      })
      .catch((e) => {
        console.error('e', e);
        setLoading(false);
      });
  }, []);

  const classes = useStyles();
  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleAddChange = (event) => {
    setAdd(event.target.value.toLowerCase());
  };

  const handleDelete = (h) => {
    MySwal.fire({
      title: `Tem certeza que quer apagar "${h}"?`,
      text: 'Essa ação não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, APAGAR!',
      cancelButtonText: 'Não, MANTER',
    }).then((result) => {
      if (result.isConfirmed) {
        const newArr = data.emails.filter((f) => f !== h);
        setData({
          ...data,
          emails: newArr,
        });
      }
    });
  };

  const handleAdd = () => {
    const newArr = [...data.emails, add];
    newArr.sort();
    setData({
      ...data,
      emails: newArr,
    });
    setAdd('');
  };

  const saveData = () => {
    const hourUtc = moment().tz(localStorage.tz)
      .hour(parseInt(data.hourUtc, 10)).utc()
      .hour();
    const dt = {
      data: {
        ...data,
        hourUtc,
      },
      method: 'put',
      url: '/reports/settings/qualityReport',
    };

    return dt;
  };

  if (loading) {
    return (
      <Skeleton variant="rect" height={500} width="100%" animation="wave" />
    );
  }

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xs={12}>
          <Card>
            <CardTitle>
              Geral
              <Spacer />
            </CardTitle>

            <Grid container spacing={2}>
              <Grid item lg={12} md={12} xs={12}>
                <CardText>
                  <Grid container spacing={2}>
                    <Grid item lg={4} md={4} xs={12}>
                      <TextField
                        variant="filled"
                        name="hourUtc"
                        onChange={handleChange}
                        value={data.hourUtc}
                        label="Hora (0-23)"
                      />
                    </Grid>

                    <Grid item lg={4} md={4} xs={12}>
                      <FormControl variant="filled">
                        <InputLabel>Status</InputLabel>
                        <Select
                          value={data.active}
                          onChange={handleChange}
                          name="active"
                        >
                          <MenuItem value>Ativo</MenuItem>
                          <MenuItem value={false}>Inativo</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                  </Grid>
                </CardText>
              </Grid>
            </Grid>

            <CardText>
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} xs={12}>
                  <AdornField
                    name="add"
                    handleChange={handleAddChange}
                    value={add}
                    label="Novo E-Mail"
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    style={{ color: 'white', marginTop: '5px' }}
                    onClick={handleAdd}
                    startIcon={<AddIcon />}
                  >
                    Adicionar
                  </Button>
                </Grid>
              </Grid>
            </CardText>
          </Card>

          <br />

          <Grid container spacing={2}>
            <Grid item lg={12} md={12} xs={12}>
              <Card>
                <CardTitle>
                  Relatório será enviado para:
                  <Spacer />
                </CardTitle>

                <CardText>
                  {(data.emails || []).map((h) => (
                    <Chip
                      key={h}
                      label={h}
                      onDelete={() => handleDelete(h)}
                      color="primary"
                      style={{ margin: '0 5px 5px 0' }}
                    />
                  ))}
                </CardText>
              </Card>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            className={`${classes.textAlign} ${classes.bottomButtons}`}
          >
            <BottomButtons
              saveData={saveData}
              backTo="/settings/reportSettings"
            />
          </Grid>

        </Grid>
      </Grid>
    </>
  );
};
