// Basic
import React from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

// UI, Styles and Icons
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Help } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import DateFnsUtils from '@date-io/date-fns';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import DataTable from 'react-data-table-component';
import withReactContent from 'sweetalert2-react-content';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  RiCalendarLine,
  RiMenuLine,
} from 'react-icons/ri';
import {
  Zoom,
  Tooltip,
  IconButton,
  FormControl,
  Checkbox,
  ListItemText,
  MenuItem,
  ListItem,
  List,
} from '@material-ui/core';
import AddressFields from '../../../../components/AddressFields';
import BottomButtons from '../../../../components/Buttons/BottomButtons';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import AccountImageUploader from './AccountImageUploader';
import AdminPermissions from '../../../../helpers/enums/AdminPermissions';
import AccountClient from './AccountClient';
import Card from '../../../../components/Card';
import CardText from '../../../../components/CardText';
import CardTitle from '../../../../components/CardTitle';
import Spacer from '../../../../components/Spacer';
import '../../../../App.css';

// Helpers
import { formatDate } from '../../../../helpers/Intl';

// Services
import FockinkAPI from '../../../../middlewares/Api';

// Styles
const useStyles = makeStyles({
  btnSpace: {
    marginLeft: '15px',
  },
  subTitle: {
    borderBottom: '1px solid #ccc',
    textAlign: 'center',
    paddingBottom: '0 !important',
  },
  bottomButtons: {
    textAlign: 'right',
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

const autenticationItemns = [
  {
    value: 'local',
    name: 'Local',
  },
  {
    value: 'simpleAzureADApp',
    name: 'Azure AD Simples',
  },
];
const activeInactive = [
  {
    value: 'A',
    name: 'Ativo',
  },
  {
    value: 'I',
    name: 'Inativo',
  },
];
const systemTypes = {
  1: 'Irrigação',
  2: 'Termometria',
  3: 'Automação',
  4: 'Fotovoltaica',
  5: 'Estação Meteorológica',
};
const reports = [
  {
    reportName: 'Informe de Qualidade',
    reportKey: 'qualityReport',
  },
];
const defaultColumns = [
  {
    name: 'Nome',
    selector: 'planName',
    cell: (row) => row.description,
    sortable: true,
  },
  {
    name: 'Alterado em',
    selector: 'changedAt',
    cell: (row) => moment.unix(row.changedAt).format('DD/MM/YYYY, h:mm:ss a'),
    sortable: true,
  },
  {
    name: 'Data de Expiração',
    selector: 'expirationDate',
    cell: (row) => moment(row.expirationDate).format('D/MM/YYYY, h:mm:ss a'),
    sortable: true,
  },
  {
    name: 'Meses Válidos',
    selector: 'validMonths',
    cell: (row) => row.validMonths || 0,
    sortable: true,
  },
];

export default (props) => {
  // Refs
  const previewCanvasRef = React.useRef(null);

  // Styles
  const classes = useStyles();

  // Inits
  const MySwal = withReactContent(Swal);
  const dataPrevisao = new Date();

  // States
  const [dataColumns, setDataColumns] = React.useState({ columns: [], data: [] });
  const [loading, setLoading] = React.useState(true);
  const [valueTab, setValueTab] = React.useState('1');
  const [data, setData] = React.useState({
    description: '',
    expirationDate: null,
    activationDate: null,
    unit: { singular: 'Lugar', plural: 'Lugares' },
    authentication: {
      type: 'local',
    },
    status: 'A',
  });
  const [dataPlan, setDataPlan] = React.useState();
  const [dataOrg, setDataOrg] = React.useState();
  const [dataValidMonths, setDataValidMonths] = React.useState();
  const [systemsPlanData, setSystemsPlanData] = React.useState();
  const [userLimitPlan, setUserLimitPlan] = React.useState();
  const [dataPlanId, setDataPlanId] = React.useState();
  // const [planSystems, setPlanSystems] = React.useState();
  const [permissionCheckArray, setPermissionCheckArray] = React.useState({
    plants: [],
  });
  const [reportsCheckArray, setReportsCheckArray] = React.useState({
    reports: [],
  });
  const [search, setSearch] = React.useState(false); // aqui
  const handleChangeLocation = (location) => {
    setData({ ...data, location });
  };
  // useEffect
  React.useEffect(() => {
    if (props.match.params.id === 'new') {
      // Show Loading
      setLoading(true);

      // Request plan data
      FockinkAPI.get('/plan').then((planResponse) => {
        // Set plan data
        setDataPlan(planResponse.data.data);

        // Remove Loading
        setLoading(false);
      });
      // Read Organization
      FockinkAPI.get(`/organizations/${props.match.params.orgId}`)
        .then((response) => {
          setDataOrg(response.data.data);
        });
    } else {
      // Show loading
      setLoading(true);

      // Read account
      FockinkAPI.get(`/accounts/${props.match.params.id}`)
        .then((response) => {
          setData({
            ...response.data.data,
            activationDate: new Date(response.data.data.activationDate * 1000),
            expirationDate: new Date(response.data.data.expirationDate * 1000),
            createdAt: new Date(
              (response.data.data.createdAt || response.data.data.firstTelemetry) * 1000,
            ),
            authentication: response.data.data.authentication || {
              type: 'local',
            },
            unit: response.data.data.unit || {
              singular: '',
              plural: '',
            },
          });

          setReportsCheckArray({
            ...reportsCheckArray,
            reports: response.data.data.reportWithConfigs || [],
          });

          setDataColumns({ columns: defaultColumns, data: response.data.data.planHistory || [] });

          return response;
        })
        .then((response) => {
          // Read plans
          FockinkAPI.get('/plan').then((planResponse) => {
            // Set plan data
            setDataPlan(planResponse.data.data);

            // Set System from plan
            const systemFromPlan = planResponse.data.data?.find(
              (plan) => plan.id === response.data.data.plan.planId,
            );
            // setPlanSystems(systemFromPlan?.systems || []);

            // Filter account systems
            setPermissionCheckArray({
              ...permissionCheckArray,
              plants: systemFromPlan?.systems || [],
            });

            // Remove Loading
            setLoading(false);
          });
        });
    }
  }, [props.match.params.id]);

  // Methods
  const handleChangeTab = (event, newValue) => {
    setValueTab(newValue);
  };
  const addMonth = () => {
    dataPrevisao.setMonth(String(dataPrevisao.getMonth() + parseInt(dataValidMonths, 10)));
  };
  const confUpdate = () => {
    MySwal.fire({
      title: 'Tem certeza?',
      text: `Data da expiração: ${moment(dataPrevisao).format('D/MM/YYYY, h:mm:ss a')}`, // pegar data de hj mais validmonths
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, ALTERAR!',
      cancelButtonText: 'Não, MANTER',
    }).then((result) => {
      if (result.isConfirmed) {
        if (props.match.params.id === 'new') {
          saveData();
        } else {
          const dt = {
            planId: dataPlanId,
          };
          FockinkAPI.put(`/accounts/${props.match.params.id}/updatePlan `, dt).then(() => {
            toast.success('Dados salvos com sucesso');
          })
            .catch(async (error) => {
              const { response } = error;

              if (response.status === 400) {
                toast.error(response.data.validation.body.message);
              } else {
                toast.error(response.data.message);
              }
            });
        }
      } else {
        data.status = 'A';
      }
    });
  };
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const formatSysDt = (sys) => {
    if (data && data.firstTelemetrySystem && data.firstTelemetrySystem[sys]) {
      return formatDate(data.firstTelemetrySystem[sys]);
    }
    return 'Nenhuma telemetria recebida';
  };
  const handleChangeUnit = (event) => {
    setData({
      ...data,
      ...(data.unit[event.target.name] = event.target.value),
    });
  };
  const setClient = (client) => {
    setData({ ...data, client, clientId: client.gnf });
    setSearch(true);
  };
  const handleChangeAuth = (event) => {
    const dataAdd = data.authentication || {};
    dataAdd[event.target.name] = event.target.value;
    setData({ ...data, authentication: dataAdd });
  };
  const handleChangeSelect = (event) => {
    if (event.target.value.indexOf('Todas') > -1) {
      setPermissionCheckArray({ plants: [] });
    } else {
      setPermissionCheckArray({
        ...permissionCheckArray,
        [event.target.name]: event.target.value || event.target.checked,
      });
    }
  };
  const handleChangeSelectReports = (event) => {
    if (event.target.value.indexOf('Todas') > -1) {
      setReportsCheckArray({ reports: [] });
    } else {
      setReportsCheckArray({
        ...reportsCheckArray,
        [event.target.name]: event.target.value || event.target.checked,
      });
    }
  };
  const handleUpdatePlan = (event) => {
    const planData = dataPlan?.filter((plano) => plano.id === event.target.value);
    setSystemsPlanData(planData[0].systems);
    setUserLimitPlan(planData[0].userLimitation);
    setDataValidMonths(planData[0].validMonths);
    setDataPlanId(planData[0].id);
  };
  const handleDateChange = (date, input) => {
    setData({ ...data, [input]: date });
  };
  const saveData = () => {
    const dataURL = previewCanvasRef.current
      ? previewCanvasRef.current.toDataURL()
      : undefined;
    const dt = {
      data: {
        organizationId: props.match.params.orgId,
        description: data.description,
        planId: dataPlanId,
        activationDate: data.activationDate?.toISOString().substring(0, 10),
        // expirationDate: data.expirationDate.toISOString().substring(0, 10),
        userLimit: data.userLimit ? parseInt(data.userLimit, 10) : undefined,
        status: data.status,
        hostname: data.hostname,
        location: data.location,
        documentNumber: data.document,
        documentType: data.type,
        clientName: data.name,
        clientId: data.clientId,
        authentication: data.authentication,
        logoImage: dataURL,
        reportWithConfigs: reportsCheckArray?.reports,
        systemIds: permissionCheckArray?.plants,
        unit: data.unit,
      },
    };

    if (dt.data.authentication.type === 'local') {
      delete dt.data.authentication.tenantId;
      delete dt.data.authentication.clientId;
      delete dt.data.authentication.secret;
    }

    if (props.match.params.id === 'new') {
      dt.method = 'post'; // method da req
      dt.url = '/accounts'; // url da requisição
    } else {
      dt.method = 'put';
      dt.url = `/accounts/${props.match.params.id}`;
    }
    return dt;
  };
  const deleteData = () => ({ url: `/accounts/${props.match.params.id}` });
  addMonth();

  const breadcrumb = {
    title: `Conta: ${data.description}`,
    routes: [
      { to: '/organization', name: 'Organizações' },
      { to: `/organization/${props.match.params.orgId}`, name: 'Organização' },
      { name: 'Cadastro de Conta' },
    ],
  };

  // Loading
  if (loading) {
    return (
      <Skeleton variant="rect" height={500} width="100%" animation="wave" />
    );
  }

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={valueTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="Tabs">
              <Tab label="Dados Atuais" value="1" />
              {props.match.params.id !== 'new' ?
                <Tab label="Planos" value="2" />
                : null}
            </TabList>
          </Box>
          <TabPanel value="1">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Card>
                  <CardTitle>
                    Cliente
                    <Spacer />
                  </CardTitle>
                  <CardText>
                    <Grid container spacing={3}>
                      {dataOrg?.isFockinkOrg === true ? (
                        <Grid item lg={12} md={12} xs={12}>
                          <AccountClient
                            client={{ ...data.client, clientId: data.gnf }}
                            setClient={setClient}
                          />
                        </Grid>
                      )
                        : null}
                      {(!search && props.match.params.id !== 'new' && dataOrg?.isFockinkOrg === true) && (
                      <>
                        <Grid item lg={4} md={4} xs={12}>
                          <TextField
                            variant="outlined"
                            name="name"
                            value={data?.client?.clienteNegocio?.nome || ''}
                            label="Nome"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                          <TextField
                            variant="outlined"
                            name="id"
                            value={data?.client?.codigo || ''}
                            label="GNF"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>

                          <TextField
                            variant="outlined"
                            name="type"
                            value={
                              data?.client?.clienteNegocio?.tipoPessoa === 'F' ?
                                'Física'
                                :
                                data?.client?.clienteNegocio?.tipoPessoa === 'J' ?
                                  'Jurídica'
                                  :
                                  ''
                            }
                            label="Tipo"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                          <TextField
                            variant="outlined"
                            name="document"
                            value={data?.client?.clienteNegocio?.cpfCnpj || ''}
                            label="Documento"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item lg={8} md={8} xs={12}>
                          <TextField
                            variant="outlined"
                            name="adress"
                            value={data?.client?.clienteNegocio?.logradouro ?
                              `${data?.client?.clienteNegocio?.logradouro || ''} ${data?.client?.clienteNegocio?.bairro || ''}
                              ${data?.client?.clienteNegocio?.numero || ''} ${data?.client?.clienteNegocio?.municipio || ''}
                              ${data?.client?.clienteNegocio?.estado || ''}
                              ${`CEP ${data?.client?.clienteNegocio?.cep}` || ''}`.replace(/\s\s+/g, ' ') : ' '}
                            label="Endereço"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Grid>
                      </>
                      )}
                      {(!dataOrg?.isFockinkOrg) && (
                      <>
                        <Grid item lg={4} md={4} xs={12}>
                          <TextField
                            variant="outlined"
                            name="name"
                            value={data?.clientName}
                            onChange={handleChange}
                            label="Nome"
                          />
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                          <FormControl variant="outlined">
                            <InputLabel id="typeLabel">Tipo</InputLabel>
                            <Select
                              name="type"
                              value={data.documentType}
                              onChange={handleChange}
                              label="Tipo"
                            >

                              <MenuItem value="F">
                                Física
                              </MenuItem>
                              <MenuItem value="J">
                                Jurídica
                              </MenuItem>

                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                          <TextField
                            variant="outlined"
                            name="document"
                            value={data?.documentNumber}
                            onChange={handleChange}
                            label="Documento"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <AddressFields
                            handleChangeLocation={handleChangeLocation}
                            data={data.location || []}
                          />
                        </Grid>

                      </>
                      )}
                    </Grid>
                  </CardText>
                </Card>
              </Grid>
              {props.match.params.id === 'new' ? (
                <Grid item xs={12}>
                  <Card>
                    <CardTitle>
                      Gerenciar Planos
                      <Spacer />
                    </CardTitle>
                    <CardText>
                      <Grid container spacing={2}>
                        <Grid item xs={12} lg={3}>
                          <FormControl variant="outlined">
                            <InputLabel id="Plans">Planos</InputLabel>
                            <Select
                              labelId="planoLabel"
                              id="plano"
                              name="plano"
                              value={dataPlan?.planName}
                              onChange={handleUpdatePlan}
                              label="Plano"
                            >
                              {dataPlan?.map((res) => (
                                res.status === 'A' ? (
                                  <MenuItem value={res.id}>
                                    {res.planName}
                                  </MenuItem>
                                )
                                  : null
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </CardText>
                  </Card>
                </Grid>
              )
                : null}
              <Grid item xs={12}>
                <Card>
                  <CardTitle>
                    Conta
                    <Spacer />
                  </CardTitle>
                  <CardText>
                    <Grid container spacing={3}>
                      <Grid item lg={4} md={4} xs={12}>
                        <TextField
                          variant="outlined"
                          name="description"
                          onChange={handleChange}
                          value={data.description || ''}
                          label="Descrição"
                        />
                      </Grid>

                      <Grid item lg={4} md={4} xs={12}>
                        <FormControl variant="outlined">
                          <InputLabel id="statusLabel">Status</InputLabel>
                          <Select
                            labelId="statusLabel"
                            id="status"
                            name="status"
                            value={data.status}
                            onChange={handleChange}
                            label="Status"
                          >
                            {
                              activeInactive.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              ))
                            }

                          </Select>
                        </FormControl>
                      </Grid>
                      {(data.createdAt && data.createdAt.toString() !== 'Invalid Date') && (
                      <Grid item lg={4} md={4} xs={12} style={{ maxHeight: 80 }}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            margin="normal"
                            label="Data Cadastro"
                            disabled
                            format="dd/MM/yyyy"
                            value={data.createdAt}
                            name="expirationDate"
                            inputVariant="outlined"
                            keyboardIcon={<RiCalendarLine size={20} />}
                            InputAdornmentProps={{ position: 'end' }}
                            style={{ marginTop: -0.5 }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      ) }

                      <Grid item lg={4} md={4} xs={12} style={{ maxHeight: 80 }}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            margin="normal"
                            label="Data Ativação"
                            format="dd/MM/yyyy"
                            value={data.activationDate}
                            name="activationDate"
                            inputVariant="outlined"
                            onChange={(e) => handleDateChange(e, 'activationDate')}
                            keyboardIcon={<RiCalendarLine size={20} />}
                            InputAdornmentProps={{ position: 'end' }}
                            style={{ marginTop: -0.5 }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item lg={4} md={4} xs={12} style={{ maxHeight: 80 }}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            margin="normal"
                            label="Data Expiração"
                            disabled
                            format="dd/MM/yyyy"
                            value={data.expirationDate}
                            name="expirationDate"
                            inputVariant="outlined"
                            onChange={(e) => handleDateChange(e, 'expirationDate')}
                            keyboardIcon={<RiCalendarLine size={20} />}
                            InputAdornmentProps={{ position: 'end' }}
                            style={{ marginTop: -0.5 }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item lg={4} md={4} xs={12}>
                        <TextField
                          variant="outlined"
                          name="userLimit"
                          onChange={handleChange}
                          value={data.userLimit}
                          disabled={!userLimitPlan}
                          label="Limite de Usuários"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xs={12}>
                        <TextField
                          variant="outlined"
                          name="hostname"
                          onChange={handleChange}
                          value={data.hostname}
                          label="Hostname"
                          disabled={props.match.params.id !== 'new'}
                          InputProps={{
                            endAdornment: ('.konecta.ind.br'
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xs={12}>
                        <FormControl variant="outlined">
                          <InputLabel id="authLabel">Autenticação</InputLabel>
                          <Select
                            labelId="authLabel"
                            id="auth"
                            name="auth"
                            value={data.authentication.type}
                            onChange={handleChangeAuth}
                            label="Autenticação"
                          >
                            {
                              autenticationItemns.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item lg={4} md={4} xs={12}>
                        <TextField
                          variant="outlined"
                          name="singular"
                          onChange={handleChangeUnit}
                          value={data.unit?.singular || ''}
                          label="Unidade singular"
                        />
                      </Grid>
                      <Grid item lg={4} md={4} xs={12}>
                        <TextField
                          variant="outlined"
                          name="plural"
                          onChange={handleChangeUnit}
                          value={data.unit?.plural || ''}
                          label="Unidade plural"
                        />
                      </Grid>

                      <Grid item lg={4} md={4} xs={12}>
                        <FormControl variant="outlined">
                          <InputLabel id="permissaoLabel">Permissão de Relatórios</InputLabel>
                          <Select
                            labelId="permissaoLabel"
                            multiple
                            label="Permissão de Relatórios"
                            value={reportsCheckArray.reports}
                            onChange={handleChangeSelectReports}
                            name="reports"
                            id="reports"
                            renderValue={(selected) =>
                              reportsCheckArray.reports?.length > 0
                                ? `Selecionado: ${selected.length} de ${reports?.length}`
                                : 'Selecione os Relatórios'
                              }
                            displayEmpty
                            startAdornment={(
                              <InputAdornment position="start">
                                <RiMenuLine size={20} />
                              </InputAdornment>
                          )}
                          >
                            {reports?.map((s) => (
                              <MenuItem key={s.reportKey} value={s.reportKey}>
                                <Checkbox
                                  checked={
                                    (reportsCheckArray.reports || []).indexOf(s.reportKey) > -1
                                  }
                                />
                                <ListItemText primary={s.reportName} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {data.authentication.type === 'simpleAzureADApp' && (
                      <>
                        <Grid item xs={12}>
                          <h3 className={classes.subTitle}>
                            Configurações Azure AD Simples
                            <Tooltip
                              title="Precisa de ajuda com estes campos?"
                              placement="bottom-end"
                              arrow
                              TransitionComponent={Zoom}
                              aria-label="help"
                            >
                              <IconButton
                                color="primary"
                                target="_blank"
                                href="/docs/TutorialAzureADSimples.pdf"
                                className={classes.fab}
                              >
                                <Help />
                              </IconButton>
                            </Tooltip>
                          </h3>
                        </Grid>

                        <Grid item lg={4} md={4} xs={12}>
                          <TextField
                            variant="filled"
                            name="tenantId"
                            onChange={handleChangeAuth}
                            value={data.authentication.tenantId}
                            label="Tenant ID"
                          />
                        </Grid>

                        <Grid item lg={4} md={4} xs={12}>
                          <TextField
                            variant="filled"
                            name="clientId"
                            onChange={handleChangeAuth}
                            value={data.authentication.clientId}
                            label="Client ID"
                          />
                        </Grid>

                        <Grid item lg={4} md={4} xs={12}>
                          <TextField
                            variant="filled"
                            name="secret"
                            onChange={handleChangeAuth}
                            value={data.authentication.secret}
                            label="Secret"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <h3 style={{ margin: '0px', fontWeight: '500' }}>
                            <small style={{ fontWeight: '400' }}>URL Redirect: </small>
                            {`${process.env.REACT_APP_BACKEND_API}/login/simpleAzureADRedirect/${props.match.params.id}`}
                          </h3>
                          <h3 style={{ margin: '0px', fontWeight: '500' }}>
                            <small style={{ fontWeight: '400' }}>URL Logout: </small>
                            {`${window.location.protocol}//${data.hostname}.${window.location.host}/login`}
                          </h3>
                        </Grid>
                      </>
                      )}
                    </Grid>
                  </CardText>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <CardTitle>
                    Sistemas
                    <Spacer />
                  </CardTitle>
                  <CardText>
                    <Grid item lg={4} md={4} xs={12}>
                      <FormControl variant="outlined">
                        <InputLabel id="systemLabels">Sistemas</InputLabel>
                        <Select
                          labelId="systemLabels"
                          multiple
                          label="Sistemas"
                          value={permissionCheckArray.plants}
                          onChange={handleChangeSelect}
                          disabled={!systemsPlanData}
                          name="plants"
                          id="plants"
                          renderValue={(selected) =>
                            permissionCheckArray.plants?.length > 0
                              ? `Selecionado: ${selected.length} de ${props.match.params.id === 'new' ? systemsPlanData?.length : dataPlan?.filter((plano) => plano.id === data.plan.planId)[0].systems.length}`
                              : 'Selecione os Sistemas'
                          }
                          displayEmpty
                          startAdornment={(
                            <InputAdornment position="start">
                              <RiMenuLine size={20} />
                            </InputAdornment>
                        )}
                        >
                          {systemsPlanData?.map((planId) => (
                            <MenuItem key={planId} value={planId}>
                              <Checkbox
                                checked={
                                  (permissionCheckArray.plants || []).indexOf(planId) > -1
                                }
                              />
                              <ListItemText primary={systemTypes[planId]} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    {permissionCheckArray.plants?.length > 0 && (
                    <Grid item lg={12} md={12} xs={12}>
                      <List>
                        {permissionCheckArray.plants?.map((x) => (
                          <ListItem style={{ paddingLeft: 0 }}>
                            <ListItemText
                              primary={systemTypes[x]}
                              secondary={formatSysDt(x)}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Grid>
                    )}

                  </CardText>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <CardTitle>
                    Logo
                    <Spacer />
                  </CardTitle>
                  <CardText>
                    <Grid item xs={12}>
                      <AccountImageUploader
                        organizationId={props.match.params.orgId}
                        accountId={props.match.params.id}
                        previewCanvasRef={previewCanvasRef}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.bottomButtons}>
                      <BottomButtons
                        saveData={saveData}
                        deleteData={props.match.params.id !== 'new' && deleteData}
                        backTo={`/organization/${props.match.params.orgId}`}
                        canSave={AdminPermissions.ACCOUNTS_MANAGE.value}
                      />
                    </Grid>
                  </CardText>
                </Card>
              </Grid>
            </Grid>

          </TabPanel>
          {props.match.params.id !== 'new' ? (
            <TabPanel value="2">
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Card>
                    <CardTitle>
                      Histórico
                      <Spacer />
                    </CardTitle>
                    <CardText>
                      <Grid container spacing={3}>
                        <Grid item lg={12} md={4} xs={12}>
                          <DataTable
                            pagination
                            paginationPerPage={15}
                            paginationComponentOptions={{
                              rowsPerPageText: 'Linhas:',
                              rangeSeparatorText: 'de',
                            }}
                            paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
                            noDataComponent="Não existem dados para serem mostrados"
                            columns={dataColumns.columns}
                            data={dataColumns.data}
                            defaultSortField="plantName"
                            defaultSortAsc={false}
                            title=""
                          />
                        </Grid>
                      </Grid>
                    </CardText>
                  </Card>
                </Grid>
                <Grid item xs={12}>

                  <Card>
                    <CardTitle>
                      Gerenciar Planos
                      <Spacer />
                    </CardTitle>
                    <CardText>
                      <Grid container spacing={2}>
                        <Grid item xs={12} lg={3}>
                          <FormControl variant="outlined">
                            <InputLabel id="Plans">Planos</InputLabel>
                            <Select
                              labelId="planoLabel"
                              id="plano"
                              name="plano"
                              value={dataPlan?.planName}
                              onChange={handleUpdatePlan}
                              label="Plano"
                            >
                              {dataPlan?.map((res) => (
                                res.status === 'A' ? (
                                  <MenuItem value={res.id}>
                                    {res.planName}
                                  </MenuItem>
                                )
                                  : null
                              ))}
                            </Select>
                          </FormControl>

                        </Grid>
                        <Grid
                          item
                          xs={12}
                          className={`${classes.textAlign} ${classes.bottomButtons}`}
                        >
                          <BottomButtons saveData={confUpdate} backTo={`/organization/${props.match.params.orgId}`} />
                        </Grid>
                      </Grid>
                    </CardText>
                  </Card>
                </Grid>
              </Grid>
            </TabPanel>
          )
            : null}
        </TabContext>
      </Box>

    </>
  );
};
