import React from 'react';
import {
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import DataTable from 'react-data-table-component';
// import Button from '@material-ui/core/Button';
// import GetAppIcon from '@material-ui/icons/GetApp';
import { formatDate, number, secondsToHm } from '../../../../../../helpers/Intl';
import { normalizeErrorData } from '../../../../../../helpers/normalizeErrorData';
import ExpandedRow from './ExpandedRow';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '0.4rem',
    width: '100%',
    background: '#FFF',
    padding: '0.8rem',
    borderRadius: '0.2rem',
    minHeight: '5rem',
  },
  header: {
    height: '5rem',
    marginBottom: '0.8rem',
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& strong': {
      display: 'block',
    },
    '& span': {
      fontWight: 'normal',
      marginLeft: '0.2rem',
    },
  },
}));

export function TableAeration({
  reportMotors, selectedGrain, groupedData }) {
  // const [csv, setCsv] = useState();
  // const [fileName, setFileName] = useState();
  // const [groupedData, setGroupedData] = useState([]);
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });

  // React.useEffect(() => {
  //   clickDownload();
  // }, [csv]);

  // const formatMotors = (motor) => motor && motor.has ? (
  //   <spam>
  //     <Tooltip title="Ligados">
  //       <b>{motor.on}</b>
  //     </Tooltip>
  //     <Tooltip title="Contém">
  //       <small>
  //         /
  //         {motor.has}
  //       </small>
  //     </Tooltip>
  //   </spam>
  // ) : '-/-';

  const formatMotorsSub = (motor) => {
    const { on, has } = Object.values(motor || {}).reduce((a, b) =>
      ({ on: a.on + b.on, has: a.has + b.has }), { on: 0, has: 0 });
    return has ? (
      <spam>
        <Tooltip title="Ligados">
          <b>{on}</b>
        </Tooltip>
        <Tooltip title="Contém">
          <small>
            /
            {has}
          </small>
        </Tooltip>
      </spam>
    ) : '-/-';
  };

  // const formatMotorsSimple = (motor) => motor && motor.has ?
  //   `${motor.on} | ${motor.has}` : '- | -';

  const formatHr = (diffDateSecs) => {
    if (diffDateSecs) {
      const max = Math.max(...Object.values(diffDateSecs));
      return secondsToHm(max / 1000).replace('h', ':').replace('m', '');
    }
    return '';
  };

  React.useEffect(() => {
    const columns = [
      { name: 'Seq.', cell: (row, index) => index + 1, compact: true },
      { name: 'Produto', selector: 'grain', compact: true },
      { name: 'Equipamento', selector: 'equipament.description', compact: true },
      // { name: 'Sub', selector: 'sub', compact: true },
      { name: 'Data', selector: 'date', cell: (row) => formatDate(row.date, 'dd/MM/yyyy'), compact: true },
      { name: 'Início', selector: 'sentDate', cell: (row) => formatDate(row.sentDate, "HH':'mm"), compact: true },
      { name: 'Fim', selector: 'finishDate', cell: (row) => formatDate(row.finishDate, "HH':'mm"), compact: true },
      { name: 'Horas Aeradas', cell: (row) => formatHr(row.diffDateSecs), compact: true },
      { name: 'Temp. Início', selector: 'weatherStart.weatherTemp', cell: (row) => normalizeErrorData(row.weatherStart.weatherTemp, '°C'), compact: true },
      { name: 'Temp. Fim', selector: 'weatherFinish.weatherTemp', cell: (row) => normalizeErrorData(row.weatherFinish.weatherTemp, '°C'), compact: true },
      { name: 'Umid. Início', selector: 'weatherStart.weatherHumid', cell: (row) => normalizeErrorData(row.weatherStart.weatherHumid, '%'), compact: true },
      { name: 'Umid. Fim', selector: 'weatherFinish.weatherHumid', cell: (row) => normalizeErrorData(row.weatherFinish.weatherHumid, '%'), compact: true },
      { name: 'Nº Mot.', cell: (row) => formatMotorsSub(row.enginesPerSub.total), compact: true },
      // { name: 'Nº Mot. Ligados', selector: 'enginesHps.total.on', compact: true },
      ...motorsKeys.map((hp) => (
        // { name: `Mot. ${motors}cv On`, selector: `enginesHps.hps.${motors}.on`, compact: true }
        { name: `Mot. ${hp}cv`, cell: (row) => formatMotorsSub(row.enginesPerSub[hp]), compact: true }
      )),
      { name: 'Total', selector: 'enginesHps.total.on', cell: (row) => getTotalPercentageMotorsActive(row.enginesHps.total.on, row.enginesHps.total.has), compact: true },
    ];

    const filteredData = selectedGrain
      ? groupedData.filter((uniData) => uniData.grain === selectedGrain)
      : groupedData;
    setDtCl({ columns, data: filteredData });
  }, [groupedData, selectedGrain]);

  const classes = useStyles();

  const motorsKeys = Object.keys(reportMotors.perHP);

  const getTotalPercentageMotorsActive = (count, base) => {
    const percentage = number((count / base) * 100, 2, 2);
    return `${percentage}%`;
  };

  // const clickDownload = () => {
  //   document.getElementById('link').click();
  //   setCsv(undefined);
  // };

  // const generateSimpleCSV = () => {
  //   let csvFile = 'data:text/csv;charset=utf-8,\uFEFF';
  //   const csvArray = [
  //     [
  //       'Seq.',
  //       'Produto',
  //       'Equipamento',
  //       // 'Sub',
  //       'Data',
  //       'Início',
  //       'Fim',
  //       'Horas Aeradas',
  //       'Temp. Início',
  //       'Temp. Fim',
  //       'Umid. Início',
  //       'Umid. Fim',
  //       'Nº Mot',
  //       ...motorsKeys.map((motors) => `Mot. ${motors}cv`),
  //       'Total',
  //     ],
  //   ];
  //   groupedData.forEach((d, i) => {
  //     csvArray.push([
  //       i + 1,
  //       d.grain,
  //       d.equipament.description,
  //       // d.sub,
  //       formatDate(d?.date, 'dd/MM/yyyy'),
  //       formatDate(d?.sentDate, "HH':'mm"),
  //       formatDate(d?.finishDate, "HH':'mm"),
  //       formatHr(d?.diffDateSecs),
  //       normalizeErrorData(d.weatherStart.weatherTemp, '°C'),
  //       normalizeErrorData(d.weatherFinish.weatherTemp, '°C'),
  //       normalizeErrorData(d.weatherStart.weatherHumid, '%'),
  //       normalizeErrorData(d.weatherFinish.weatherHumid, '%'),
  //       formatMotorsSimple(d.enginesHps.total),
  //       ...motorsKeys.map((motors) => formatMotorsSimple(d.enginesHps.hps[motors])),
  //       getTotalPercentageMotorsActive(d?.enginesHps.total.on, d?.enginesHps.total.has),
  //     ]);
  //   });
  //   csvArray.forEach((line) => {
  //     csvFile += line.join(';');
  //     csvFile += '\n';
  //   });
  //   const encodedUri = encodeURI(csvFile);
  //   const n = new Date().getTime();
  //   setFileName(`Relatorio_Aeracao_Simplificado_${n}`);
  //   setCsv(encodedUri);
  // };

  // const generateFullCSV = () => {
  //   let csvFile = 'data:text/csv;charset=utf-8,\uFEFF';
  //   const csvArray = [
  //     [
  //       'Seq.',
  //       'Produto',
  //       'Equipamento',
  //       'Sub',
  //       'Data',
  //       'Início',
  //       'Fim',
  //       'Horas Aeradas',
  //       'Temp. Início',
  //       'Temp. Fim',
  //       'Umid. Início',
  //       'Umid. Fim',
  //       'Nº Mot.',
  //       ...motorsKeys.map((motors) => `Mot. ${motors}cv`),
  //       'Total',
  //     ],
  //   ];
  //   reportData.forEach((d, i) => {
  //     csvArray.push([
  //       i + 1,
  //       d.grain,
  //       d.equipament.description,
  //       d.sub,
  //       formatDate(d?.sentDate, 'dd/MM/yyyy'),
  //       formatDate(d?.sentDate, "HH':'mm"),
  //       formatDate(d?.finishDate, "HH':'mm"),
  //       secondsToHm((new Date(d.finishDate).getTime() - new Date(d.sentDate).getTime()) / 1000)
  //         .replace('h', ':').replace('m', ''),
  //       normalizeErrorData(d.weatherStart.weatherTemp, '°C'),
  //       normalizeErrorData(d.weatherFinish.weatherTemp, '°C'),
  //       normalizeErrorData(d.weatherStart.weatherHumid, '%'),
  //       normalizeErrorData(d.weatherFinish.weatherHumid, '%'),
  //       formatMotorsSimple(d.enginesHps.total),
  //       ...motorsKeys.map((motors) => formatMotorsSimple(d.enginesHps.hps[motors])),
  //       getTotalPercentageMotorsActive(d?.enginesHps.total.on, d?.enginesHps.total.has),
  //     ]);
  //   });
  //   csvArray.forEach((line) => {
  //     csvFile += line.join(';');
  //     csvFile += '\n';
  //   });
  //   const encodedUri = encodeURI(csvFile);
  //   const n = new Date().getTime();
  //   setFileName(`Relatorio_Aeracao_Completo_${n}`);
  //   setCsv(encodedUri);
  // };

  return (
    <>
      <div className={classes.root}>
        <div>
          <DataTable
            noHeader
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: 'Linhas:',
              rangeSeparatorText: 'de',
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
            noDataComponent="Não existem dados para serem mostrados"
            expandableRows
            expandableRowsComponent={<ExpandedRow motorsKeys={motorsKeys} />}
            columns={dtCl.columns}
            data={dtCl.data}
          />
        </div>

        {/* <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 20 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              generateSimpleCSV();
            }}
            style={{ marginRight: '10px' }}
          >
            <GetAppIcon />
            Baixar CSV Simplificado
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              generateFullCSV();
            }}
          >
            <GetAppIcon />
            Baixar CSV Completo
          </Button>
          <a id="link" download={`${fileName}.csv`} href={csv} style={{ display: 'none' }}>
            download
          </a>
        </div> */}
      </div>
    </>
  );
}
