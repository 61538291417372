import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  angleFrom: yup
    .number('Deve ser um número')
    .required('Obrigatório'),
  angleTo: yup
    .number('Deve ser um número')
    .required('Obrigatório'),
  oberservations: yup
    .string()
    .notRequired(),
});
