import React from 'react';
import './style.scss';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import FockinkAPI from '../../middlewares/Api';

export default ({
  // medidas físicas do pivô
  name,
  tooltipData,
  radius,
  currentAngle,
  // destinationAngle,
  startAngle,
  endAngle,
  recipeStartAngle,
  recipeEndAngle,
  // Telemetria
  hasWater,
  stOnOff,
  waterBladeSize,
  isRotatingLeft,
  isRotatingRight,
  // isStoped,
  isPowerOn,
  isStandbyTime,
  // isCannonOn,
  // Receita
  cannonAngles,
  // Alarmes
  isEmergencyWarning,
  isFailing,
  // isPumpWarning,
  // isPressureWarning,
  // isEnergyWarning,
  // Posicionamento no mapa
  isProgrammingScreen,
  zoomLevel,
  // latitude,
  // longitude,
  zeroAngle,
  // Metodos de clique
  onClickRight,
  onClickLeft,
  onClickPower,
  // Configurações
  isMapMode,
  isControlMode,
  isViewMode,
  isEditingMode,
  showUncovered,
  showCovered,
  equipamentId,
  isDisconnected,
  // minimumMM,
  // maximumSpeed,
  // wheelLength,
  // recipe,
  // telemetry,
}) => {
  const animationController = 1; // Travei as animações pois estavam pesando demais
  const [isMoving, setIsMoving] = React.useState(false);
  const [isRotating, setIsRotating] = React.useState(false);
  moment.locale('pt-br');
  const [selectedRecipe, setSelectedRecipe] = React.useState(undefined);

  const [zoomBreakpoints, setZoomBreakpoints] = React.useState({
    hidden: 13,
    small: 14,
    mid: 15,
    high: 16,
  });

  const failing = isFailing?.filter((x) => x === 'Falha de Comunicação com o software local')[0];

  function setBreakpoints() {
    const p = (radius || 200) / 200;
    setZoomBreakpoints({
      hidden: 13 - (p - 1),
      small: 14 - (p - 1),
      mid: 15 - (p - 1),
      high: 16 - (p - 1),
    });
  }
  React.useEffect(() => {
    setBreakpoints();
  }, []);

  React.useEffect(() => {
    if (equipamentId) {
      FockinkAPI.get(
        `/dashboards/irrigation/${equipamentId}/recipes?dateBase=${moment(new Date()).format(
          'yyyy-MM-DD',
        )}&pastDays=3`,
      ).then((response) => {
        const { current, past } = response.data.data.recipes;
        if (current && current.length > 0) {
          setSelectedRecipe({
            ...current[current.length - 1].telemetry,
            ...current[current.length - 1].metadata,
          });
        } else if (past && past.length > 0) {
          setSelectedRecipe({
            ...past[past.length - 1].telemetry,
            ...past[past.length - 1].metadata,
          });
        }
      });
    }
  }, []);

  function getCenterPositionX() {
    return 180 * Math.sin(((endAngle / 2) * Math.PI) / 180);
  }

  function getCenterPositionY() {
    return 180 * Math.cos(((endAngle / 2) * Math.PI) / 180);
  }

  function defineCurrentState() {
    if (isEmergencyWarning) {
      return 'Em Emergência';
    }
    if (failing === 'Falha de Comunicação com o software local' || isDisconnected) {
      return 'Sem Comunicação'; // 'Com falha';
    }
    if ((isStandbyTime || Standby() !== '') && Standby() !== 'EM MOVIMENTO' && stOnOff === 1) {
      return 'Aguardando';
    }
    if (isPowerOn) {
      return 'Ligado';
    }
    return 'Desligado';
  }

  const destination = drawCannonClipPath(recipeEndAngle, recipeEndAngle, 180, 110, false);
  const starting = drawCannonClipPath(recipeStartAngle, recipeStartAngle, 250, 180, false);
  const recipeUncovered = drawCannonClipPath(
    isViewMode ? currentAngle || recipeStartAngle : currentAngle,
    recipeEndAngle,
    250,
    0,
    true,
  );
  const recipeCovered = drawCannonClipPath(
    recipeStartAngle || selectedRecipe?.startingPosition || undefined,
    isViewMode ? currentAngle || recipeEndAngle : currentAngle,
    250,
    0,
    true,
  );
  const defaultPosition = {
    d: 0,
    r: 0,
    mX: {
      center: 0,
    },
    mY: {
      center: 0,
    },
    sX: {
      center: 0,
    },
    sY: {
      center: 0,
    },
    eX: {
      center: 0,
    },
    eY: {
      center: 0,
    },
  };
  const alternativePosition = drawCannonClipPath(
    startAngle + zeroAngle,
    endAngle + zeroAngle,
    125,
    124,
    false,
  );
  const centerInfo = endAngle < 360 ? alternativePosition : defaultPosition;
  const config = {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  };

  const HasWater = () => {
    if (
      tooltipData?.telemetry?.recipeWater === 0 ||
      tooltipData?.telemetries?.op1001.recipeWater === 0
    ) {
      return 'Sem água';
    }
    return 'Com água';
  };

  const Standby = () => {
    if (tooltipData?.telemetry?.stPivot === 1 || tooltipData?.telemetries?.op1001.stPivot === 1) {
      return 'EM MOVIMENTO';
    }
    if (
      tooltipData?.telemetry?.stInitialTtime === 1 ||
      tooltipData?.telemetries?.op1001.stInitialTtime === 1
    ) {
      return 'TEMPO INICAL';
    }
    if (
      tooltipData?.telemetry?.stPivotLocked === 1 ||
      tooltipData?.telemetries?.op1001.stPivotLocked === 1
    ) {
      return 'BLOQUEADO';
    }
    if (
      tooltipData?.telemetry?.stPressureTime === 1 ||
      tooltipData?.telemetries?.op1001.stPressureTime === 1
    ) {
      return 'PRESSÃO';
    }
    if (
      tooltipData?.telemetry?.stScheduledDate === 1 ||
      tooltipData?.telemetries?.op1001.stScheduledDate === 1
    ) {
      return 'Aguardando Data e Hora Programada';
    }
    if (
      tooltipData?.telemetry?.stProgramOff === 1 ||
      tooltipData?.telemetries?.op1001.stProgramOff === 1
    ) {
      return 'DATA PROGRAMADA';
    }
    if (
      tooltipData?.telemetry?.stReversal === 1 ||
      tooltipData?.telemetries?.op1001.stReversal === 1
    ) {
      return 'REVERSÃO';
    }
    if (tooltipData?.telemetry?.stHP === 1 || tooltipData?.telemetries?.op1001.stHP === 1) {
      return 'HORA PONTA';
    }
    if (
      tooltipData?.telemetry?.stStoppedByRain === 1 ||
      tooltipData?.telemetries?.op1001.stStoppedByRain === 1
    ) {
      return 'CHUVA';
    }
    if (
      tooltipData?.telemetry?.stFailEnergy === 1 ||
      tooltipData?.telemetries?.op1001.stFailEnergy === 1
    ) {
      return 'ENERGIA';
    }
    if (
      tooltipData?.telemetry?.stFailLowPressure === 1 ||
      tooltipData?.telemetries?.op1001.stFailLowPressure === 1
    ) {
      return `Falta de pressão no grau ${
        tooltipData?.telemetry?.positionActual || tooltipData?.telemetries?.op1001.positionActual
      }`;
    }
    if (
      tooltipData?.telemetry?.stFailHighPressure === 1 ||
      tooltipData?.telemetries?.op1001.stFailHighPressure === 1
    ) {
      return `Excesso de pressão no grau ${
        tooltipData?.telemetry?.positionActual || tooltipData?.telemetries?.op1001.positionActual
      }`;
    }
    if (
      tooltipData?.telemetry?.stFailPumping === 1 ||
      tooltipData?.telemetries?.op1001.stFailPumping === 1
    ) {
      return 'Falha de bombeamento';
    }
    if (
      tooltipData?.telemetry?.stFailEmergency === 1 ||
      tooltipData?.telemetries?.op1001.stFailEmergency === 1
    ) {
      return `Emergência acionada no grau ${
        tooltipData?.telemetry?.positionActual || tooltipData?.telemetries?.op1001.positionActual
      }`;
    }
    if (
      tooltipData?.telemetry?.stFailEncoderOutOfBand === 1 ||
      tooltipData?.telemetries?.op1001.stFailEncoderOutOfBand === 1
    ) {
      return `Encoder fora de faixa no grau ${
        tooltipData?.telemetry?.positionActual || tooltipData?.telemetries?.op1001.positionActual
      }`;
    }
    if (
      tooltipData?.telemetry?.stFailEncoderOutOfBand === 1 ||
      tooltipData?.telemetries?.op1001.stFailEncoderOutOfBand === 1
    ) {
      return `Encoder fora de faixa no grau ${
        tooltipData?.telemetry?.positionActual || tooltipData?.telemetries?.op1001.positionActual
      }`;
    }
    if (
      tooltipData?.telemetry?.stFailPositionEncoder === 1 ||
      tooltipData?.telemetries?.op1001.stFailPositionEncoder === 1
    ) {
      return `Encoder parado no grau ${
        tooltipData?.telemetry?.positionActual || tooltipData?.telemetries?.op1001.positionActual
      }`;
    }
    if (
      tooltipData?.telemetry?.stFailCommunicClpIhm === 1 ||
      tooltipData?.telemetries?.op1001.stFailCommunicClpIhm === 1
    ) {
      return 'Falha de comunicação CLP/IHM';
    }
    if (
      tooltipData?.telemetry?.stFailCommunicEncoder === 1 ||
      tooltipData?.telemetries?.op1001.stFailCommunicEncoder === 1
    ) {
      return 'Posicionador sem comunicação';
    }
    if (
      tooltipData?.telemetry?.stFailCommunicStation === 1 ||
      tooltipData?.telemetries?.op1001.stFailCommunicStation === 1
    ) {
      return 'Estação sem comunicação';
    }
    if (
      tooltipData?.telemetry?.stStopActivated === 1 ||
      tooltipData?.telemetries?.op1001.stStopActivated === 1
    ) {
      return 'Batente acionado';
    }
    if (
      tooltipData?.telemetry?.stPivotLocked === 1 ||
      tooltipData?.telemetries?.op1001.stPivotLocked === 1
    ) {
      return 'Operação do pivô bloqueada';
    }
    if (
      tooltipData?.telemetry?.stPreventiveMaintenance === 1 ||
      tooltipData?.telemetries?.op1001.stPreventiveMaintenance === 1
    ) {
      return 'Realizar manutenção preventiva';
    }
    if (
      tooltipData?.telemetry?.stFailCommunicPumping === 1 ||
      tooltipData?.telemetries?.op1001.stFailCommunicPumping === 1
    ) {
      return 'Falha de comunicação com bombeamento';
    }
    if (
      tooltipData?.telemetry?.stPreventiveMaintenancePumping === 1 ||
      tooltipData?.telemetries?.op1001.stPreventiveMaintenancePumping === 1
    ) {
      return 'Realizar manutenção no bombeamento';
    }
    return '';
  };

  //   React.useEffect(() => {
  //     if (animationController < 1) {
  //       setTimeout(() => {
  //         setAnimationController(animationController + 0.01);
  //       }, 10);
  //     }
  //   }, [animationController]);

  function getClassCenterInfo() {
    if (isEmergencyWarning) {
      return zoomLevel <= zoomBreakpoints.mid ? 'center_info_map warning' : 'center_info warning';
    } if (failing === 'Falha de Comunicação com o software local' || isDisconnected) {
      return zoomLevel <= zoomBreakpoints.mid ? 'center_info_map fail' : 'center_info fail';
    } if (isPowerOn) {
      if ((isStandbyTime || Standby() !== '') && Standby() !== 'EM MOVIMENTO') {
        return zoomLevel <= zoomBreakpoints.mid ? 'center_info_map standby' : 'center_info standby';
      }
      if (hasWater) {
        return zoomLevel <= zoomBreakpoints.mid
          ? 'center_info_map water_on'
          : 'center_info water_on';
      }
      return zoomLevel <= zoomBreakpoints.mid
        ? 'center_info_map water_off'
        : 'center_info water_off';
    }
    return zoomLevel <= zoomBreakpoints.mid
      ? 'center_info_map off_state'
      : 'center_info off_state';
  }
  function getClassUncovered() {
    if (hasWater) {
      return 'path_water_100';
    }
    return 'path_no_water';
  }
  function generateCannonPaths(c, i) {
    const cas = parseInt(c[0], 10);
    const cae = parseInt(c[1], 10);
    let as = -1;
    let ae = -1;
    if (as > ae) {
      const t = ae;
      ae = as;
      as = t;
    }
    as = cas;
    ae = cae;

    if (as >= 0 && ae >= 0 && as !== ae) {
      const par = drawCannonClipPath(as, ae, 260, 248);
      return (
        <g className="cannon_path" key={`cannon_path${i}`}>
          <path
            transform={`rotate(${par.r})`}
            className="cannon_path_trace"
            origin="0 0"
            d={par.d}
          />
          {(isViewMode || isControlMode) && (
            <>
              <text
                className="cannon_path_text"
                transform={`rotate(${-zeroAngle}, ${par.mX.end}, ${par.mY.end})`}
                x={par.mX.end}
                y={par.mY.end}
              >
                {`${cas}º - ${cae}º`}
              </text>
              <line
                origin="0 0"
                className="cannon_path_lines"
                x1="0"
                y1="0"
                x2={getX(cas)}
                y2={getY(cas)}
              />
              <line
                origin="0 0"
                className="cannon_path_lines"
                x1="0"
                y1="0"
                x2={getX(cae)}
                y2={getY(cae)}
              />
            </>
          )}
        </g>
      );
    }
  }
  function getPivotContour(angleStart, angleEnd) {
    const ae = angleEnd || 360;
    const ast = angleStart || 0;
    const radi = isMapMode ? 246 : 250;
    const x = radi * Math.sin((ae * Math.PI) / 180);
    const y = -radi * Math.cos((ae * Math.PI) / 180);
    let ret;
    if (ae < 360) {
      if (ast > 180) {
        ret = `M 0 0 L ${getPoint(angleStart, radi)} A ${radi} ${radi} 180 0 1 ${x} ${y} Z`;
      } else if (x < 0) {
        ret = `M 0 0 L ${getPoint(
          angleStart,
          radi,
        )} A ${radi} ${radi} 180 0 1 0 ${radi} A ${radi} ${radi} 180 0 1 ${x} ${y} Z`;
      } else {
        ret = `M 0 0 L ${getPoint(angleStart, radi)} A ${radi} ${radi} 180 0 1 ${x} ${y} Z`;
      }
    } else if (ast > 180) {
      ret = `M ${getPoint(angleStart, radi)} A ${radi} ${radi} 180 0 1 ${x} ${y} Z`;
    } else if (x < 0) {
      ret = `M ${getPoint(
        angleStart,
        radi,
      )} A ${radi} ${radi} 180 0 1 0 ${radi} A ${radi} ${radi} 180 0 1 ${x} ${y} Z`;
    } else {
      ret = `M ${getPoint(angleStart, radi)} A ${radi} ${radi} 180 0 1 ${x} ${y} Z`;
    }
    return ret;
  }
  function startDragging(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsMoving(true);
    // console.log('Movendo ', e);
  }
  function stopDragging(e) {
    e.preventDefault();
    e.stopPropagation();
    if (isMoving) {
      // console.log('Parando de mover ', e);
      setIsMoving(false);
    }
  }
  function startRotating(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsRotating(true);
    // console.log('rotacionando ', e);
  }
  function stopRotating(e) {
    e.preventDefault();
    e.stopPropagation();
    if (isRotating) {
      // console.log('parando de rotacionar ', e);
      setIsRotating(false);
    }
  }

  function getContourClass() {
    if (isEmergencyWarning) {
      return 'emergency';
    }
    if (failing === 'Falha de Comunicação com o software local' || isDisconnected) {
      return 'fail';
    }
    if ((isStandbyTime || Standby() !== '') && Standby() !== 'EM MOVIMENTO') {
      return 'standby';
    }
    if (isPowerOn) {
      return hasWater ? 'water_on' : 'water_off';
    }
    return 'off';
  }
  function getStatusColor() {
    if (isEmergencyWarning) {
      return '#FF000077';
    }
    if (failing === 'Falha de Comunicação com o software local' || isDisconnected) {
      return '#424242';
    }
    if ((isStandbyTime || Standby() !== '') && Standby() !== 'EM MOVIMENTO') {
      return '#00836d77';
    }
    if (isPowerOn) {
      return hasWater ? '#005db477' : '#1b860077';
    }
    return '#c7a20077';
  }
  function drawCannonClipPath(angleStart, angleEnd, rads, minorRad, checkDirection) {
    let ae;
    let ast;
    let swp;
    let rot;
    if (angleStart === 0) angleStart = 360;
    // if (angleEnd === 0) angleEnd = 360;
    if (checkDirection) {
      if (isRotatingLeft) {
        if (angleStart < angleEnd) {
          ae = 360 - angleEnd + angleStart;
          ast = 0;
          rot = -(360 - currentAngle);
        } else {
          ast = 0;
          ae = angleStart - angleEnd;
          rot = angleEnd;
        }
      } else if (angleStart < angleEnd) {
        ae = angleEnd - angleStart;
        ast = 0;
        rot = angleStart;
      } else {
        ast = 0;
        ae = 360 - angleStart + angleEnd;
        rot = -1 * (360 - angleStart);
      }
    } else if (angleEnd < angleStart) {
      ae = 360 - angleStart + angleEnd;
      ast = 0;
      rot = angleStart;
    } else {
      ast = 0;
      ae = angleEnd - angleStart;
      rot = angleStart;
    }
    if (ae - ast > 180) {
      swp = 1;
    } else {
      swp = 0;
    }
    const x0 = rads * Math.sin((ast * Math.PI) / 180);
    const y0 = -rads * Math.cos((ast * Math.PI) / 180);
    const x1 = rads * Math.sin((ae * Math.PI) / 180);
    const y1 = -rads * Math.cos((ae * Math.PI) / 180);
    const x2 = minorRad * Math.sin((ae * Math.PI) / 180);
    const y2 = -minorRad * Math.cos((ae * Math.PI) / 180);
    const x3 = minorRad * Math.sin((ast * Math.PI) / 180);
    const y3 = -minorRad * Math.cos((ast * Math.PI) / 180);
    const ret = `M ${x0} ${y0} A ${rads} ${rads} 360 ${swp} 1 ${x1} ${y1} L ${x2} ${y2} A ${minorRad} ${minorRad} 360 ${swp} 0 ${x3} ${y3} Z`;
    const middleX = {
      start: rads * Math.sin(((ae / 2 + rot) * Math.PI) / 180),
      center: ((rads + minorRad) / 2) * Math.sin(((ae / 2 + rot) * Math.PI) / 180),
      end: minorRad * Math.sin(((ae / 2 + rot) * Math.PI) / 180),
    };
    const middleY = {
      start: -rads * Math.cos(((ae / 2 + rot) * Math.PI) / 180),
      center: ((-rads - minorRad) / 2) * Math.cos(((ae / 2 + rot) * Math.PI) / 180),
      end: -minorRad * Math.cos(((ae / 2 + rot) * Math.PI) / 180),
    };
    const startX = {
      start: rads * Math.sin(((ast + rot) * Math.PI) / 180),
      center: ((rads + minorRad) / 2) * Math.sin(((ast + rot) * Math.PI) / 180),
      end: minorRad * Math.sin(((ast + rot) * Math.PI) / 180),
    };
    const startY = {
      start: -rads * Math.cos(((ast + rot) * Math.PI) / 180),
      center: ((-rads - minorRad) / 2) * Math.cos(((ast + rot) * Math.PI) / 180),
      end: -minorRad * Math.cos(((ast + rot) * Math.PI) / 180),
    };
    const endX = {
      start: rads * Math.sin(((ae + rot) * Math.PI) / 180),
      center: ((rads + minorRad) / 2) * Math.sin(((ae + rot) * Math.PI) / 180),
      end: minorRad * Math.sin(((ae + rot) * Math.PI) / 180),
    };
    const endY = {
      start: -rads * Math.cos(((ae + rot) * Math.PI) / 180),
      center: ((-rads - minorRad) / 2) * Math.cos(((ae + rot) * Math.PI) / 180),
      end: -minorRad * Math.cos(((ae + rot) * Math.PI) / 180),
    };
    return {
      d: ret,
      r: rot,
      mX: middleX,
      mY: middleY,
      sX: startX,
      sY: startY,
      eX: endX,
      eY: endY,
    };
  }
  function getX(angle, extra) {
    if (angle) {
      const x = (250 + (extra || 0)) * Math.sin((angle * Math.PI) / 180);
      return `${x}`;
    }
    const x = (250 + (extra || 0)) * Math.sin(0);
    return `${x}`;
  }
  function getY(angle, extra) {
    if (angle) {
      const y = (250 + (extra || 0)) * Math.cos((angle * Math.PI) / 180);
      return `${-y}`;
    }
    const y = (250 + (extra || 0)) * Math.cos(0);
    return `${-y}`;
  }

  function getPoint(angle, r) {
    const x = (r || 250) * Math.sin((angle * Math.PI) / 180);
    const y = (r || 250) * Math.cos((angle * Math.PI) / 180);
    return `${x} ${-y}`;
  }

  const Direction = (left, right) => {
    if (left === 1) {
      return 'Esquerda';
    }
    if (right === 1) {
      return 'Direita';
    }
    if (left === true) {
      return 'Esquerda';
    }
    if (right === true) {
      return 'Direita';
    }
    return 'Parado';
  };
  const tooltipInfos = [
    `Situação Atual: ${defineCurrentState()} - ${name}`,
    <br />,
    selectedRecipe?.startingPosition === undefined && recipeStartAngle === undefined
      ? 'Sem dados de receita'
      : null,
    <br />,
    isProgrammingScreen ? null : Standby(),
    <br />,
    `Pos. Atual: ${
      tooltipData?.telemetry?.positionActual || tooltipData?.telemetries?.op1001.positionActual
    }`,
    <br />,
    `Destino: ${
      tooltipData?.telemetry?.recipePosition ||
      tooltipData?.telemetries?.op1001.recipePosition ||
      '0'
    }`,
    <br />,
    `Água: ${isProgrammingScreen ? null : HasWater()}`,
    <br />,
    `Direção: ${Direction(isRotatingLeft, isRotatingRight)}`,
    <br />,
    `MM: ${
      tooltipData?.telemetry?.recipeWaterMm || tooltipData?.telemetries?.op1001.recipeWaterMm
    }`,
  ];

  return (
    <Tooltip title={isProgrammingScreen ? '' : tooltipInfos} placement="top-start">
      <svg viewBox={`${isMapMode ? '-260 -260 520 520' : '-300 -300 600 600'}`} width="100%">
        <defs />
        {(zoomLevel >= zoomBreakpoints.high || !zoomLevel) && (
          <g id="breakpoint_high">
            <g id="ZERO">
              {!isMapMode ? (
                <g>
                  <line
                    origin="0 0"
                    className="zero_position"
                    x1={getX(zeroAngle - 2, 0)}
                    y1={getY(zeroAngle - 2, 0)}
                    x2={getX(zeroAngle, 10)}
                    y2={getY(zeroAngle, 10)}
                  />
                  <line
                    origin="0 0"
                    className="zero_position"
                    x1={getX(zeroAngle + 2, 0)}
                    y1={getY(zeroAngle + 2, 0)}
                    x2={getX(zeroAngle, 10)}
                    y2={getY(zeroAngle, 10)}
                  />
                  <text className="zero_text" x={getX(zeroAngle, 15)} y={getY(zeroAngle, 15)}>
                    0º
                  </text>
                </g>
              ) : (
                <g>
                  <line
                    origin="0 0"
                    className="zero_position_map"
                    x1={getX(zeroAngle - 2, -20)}
                    y1={getY(zeroAngle - 2, -20)}
                    x2={getX(zeroAngle, -10)}
                    y2={getY(zeroAngle, -10)}
                  />
                  <line
                    origin="0 0"
                    className="zero_position_map"
                    x1={getX(zeroAngle + 2, -20)}
                    y1={getY(zeroAngle + 2, -20)}
                    x2={getX(zeroAngle, -10)}
                    y2={getY(zeroAngle, -10)}
                  />
                  <text className="zero_text_map" x={getX(zeroAngle, -35)} y={getY(zeroAngle, -35)}>
                    0º
                  </text>
                </g>
              )}
            </g>
            <g className="main_container" transform={`rotate(${zeroAngle})`}>
              <path
                origin="0 0"
                id="pivot_area"
                className={`pivot_contour ${getContourClass()}`}
                d={getPivotContour(startAngle, endAngle)}
              />
              {showUncovered && (
                <g transform={`rotate(${recipeUncovered.r}, 0, 0)`}>
                  <path id="path_water" className={getClassUncovered()} d={recipeUncovered.d} />
                </g>
              )}
              {showCovered && (
                <g transform={`rotate(${recipeCovered.r}, 0, 0)`}>
                  <path
                    id="path_done"
                    className={hasWater ? 'path_water_done' : 'path_no_water_done'}
                    d={recipeCovered.d}
                  />
                </g>
              )}
              {cannonAngles && cannonAngles.length && (
                <g>{cannonAngles.map(generateCannonPaths)}</g>
              )}
              <line
                id="RECIPE_END"
                origin="0 0"
                className="recipe_end_line"
                x1="0"
                y1="0"
                x2={getX(recipeEndAngle)}
                y2={getY(recipeEndAngle)}
              />
              <line
                id="RECIPE_START"
                origin="0 0"
                className="recipe_start_line"
                x1="0"
                y1="0"
                x2={getX(recipeStartAngle)}
                y2={getY(recipeStartAngle)}
              />
              {(isControlMode || isMapMode) && currentAngle >= 0 && (
                <g id="CURRENT_POSITION">
                  <path
                    className={`${isPowerOn ? 'current_position_on' : 'current_position_off'} ${
                      hasWater ? 'with_water' : 'with_no_water'
                    }`}
                    d={`M 0 0 L ${getX(currentAngle + 2, -20)} ${getY(
                      currentAngle + 2,
                      -20,
                    )} L ${getX(currentAngle, 0)} ${getY(currentAngle, 0)} L ${getX(
                      currentAngle - 2,
                      -20,
                    )} ${getY(currentAngle - 2, -20)} Z`}
                  />
                  <line
                    origin="0 0"
                    className={isPowerOn ? 'centerline on' : 'centerline off'}
                    x1="0"
                    y1="0"
                    x2={getX(currentAngle)}
                    y2={getY(currentAngle)}
                  />
                </g>
              )}
            </g>

            {(isControlMode || isMapMode) && (
              <g
                className="center_button"
                onClick={() => {
                  onClickPower(isPowerOn);
                }}
              >
                <circle
                  cx={`${centerInfo.mX.center}`}
                  cy={`${centerInfo.mY.center}`}
                  r="100"
                  className={getClassCenterInfo()}
                />
                <text
                  x={`${centerInfo.mX.center}`}
                  y={`${centerInfo.mY.center - 35}`}
                  className="state"
                >
                  {`${defineCurrentState()}`}
                </text>
                <text
                  x={`${centerInfo.mX.center + 40}`}
                  y={`${centerInfo.mY.center + 10}`}
                  className="water_blade"
                >
                  {`${

                    waterBladeSize
                      ? (waterBladeSize * animationController).toLocaleString('pt-BR', config)
                      : '---'
                  }`}
                  {}
                </text>
                <text
                  x={`${centerInfo.mX.center + 80}`}
                  y={`${centerInfo.mY.center + 10}`}
                  className="water_blade_mm"
                >
                  mm
                </text>

                <text
                  x={`${centerInfo.mX.center + 35}`}
                  y={`${centerInfo.mY.center + 55}`}
                  className="angle"
                >
                  {`${
                    currentAngle >= 0 ? Math.floor(currentAngle * animationController) : '- - -'
                  }`}
                </text>
                <text
                  x={`${centerInfo.mX.center + 55}`}
                  y={`${centerInfo.mY.center + 55}`}
                  className="angle"
                >
                  º
                </text>
              </g>
            )}
            {(isControlMode || isViewMode) && (
              <g id="info_layer" transform={`rotate(${zeroAngle})`}>
                <g className="starting_info">
                  <circle
                    className="starting_circle"
                    cx={starting.mX.center}
                    cy={starting.mY.center}
                    r="30"
                  />
                  <text
                    className="starting_text"
                    transform={`rotate(${-zeroAngle},${starting.mX.center},${starting.mY.center})`}
                    x={starting.mX.center + 3}
                    y={starting.mY.center + 6}
                  >
                    {`${recipeStartAngle || '*'}º`}
                  </text>
                </g>
                <g className="ending_info">
                  <circle
                    className="ending_circle"
                    cx={destination.mX.center}
                    cy={destination.mY.center}
                    r="30"
                  />
                  <text
                    className="ending_text"
                    transform={`rotate(${-zeroAngle},${destination.mX.center},${
                      destination.mY.center
                    })`}
                    x={destination.mX.center + 3}
                    y={destination.mY.center + 6}
                  >
                    {`${recipeEndAngle}º`}
                  </text>
                </g>
              </g>
            )}
            {isControlMode && (
              <g>
                <path
                  onClick={() => {
                    onClickLeft(isRotatingLeft);
                  }}
                  d="M -207.779 -259.838 C -200.854 -263.795 -191.879 -262.397 -186.523 -256.101 C -181.012 -249.622 -181.234 -240.191 -186.693 -233.988 C -193.121 -228.853 -199.332 -223.458 -205.311 -217.821 L -198.339 -211.329 L -239.198 -194.966 C -239.87 -194.787 -240.576 -194.692 -241.304 -194.692 C -245.816 -194.692 -249.474 -198.35 -249.474 -202.862 C -249.474 -203.519 -249.396 -204.157 -249.25 -204.769 L -235.946 -246.348 L -229.769 -240.596 C -222.727 -247.323 -215.39 -253.744 -207.779 -259.838 Z"
                  className={`control_arrows ${isRotatingLeft ? 'active' : ''}`}
                />
                <path
                  onClick={() => {
                    onClickRight(isRotatingRight);
                  }}
                  d="M 207.779 -259.838 C 200.854 -263.795 191.879 -262.397 186.523 -256.101 C 181.012 -249.622 181.234 -240.191 186.693 -233.988 C 193.121 -228.853 199.332 -223.458 205.311 -217.821 L 198.339 -211.329 L 239.198 -194.966 C 239.87 -194.787 240.576 -194.692 241.304 -194.692 C 245.816 -194.692 249.474 -198.35 249.474 -202.862 C 249.474 -203.519 249.396 -204.157 249.25 -204.769 L 235.946 -246.348 L 229.769 -240.596 C 222.727 -247.323 215.39 -253.744 207.779 -259.838 Z"
                  className={`control_arrows ${isRotatingRight ? 'active' : ''}`}
                />
              </g>
            )}
            {isViewMode && (
              <g>
                <path
                  d="M -207.779 -259.838 C -200.854 -263.795 -191.879 -262.397 -186.523 -256.101 C -181.012 -249.622 -181.234 -240.191 -186.693 -233.988 C -193.121 -228.853 -199.332 -223.458 -205.311 -217.821 L -198.339 -211.329 L -239.198 -194.966 C -239.87 -194.787 -240.576 -194.692 -241.304 -194.692 C -245.816 -194.692 -249.474 -198.35 -249.474 -202.862 C -249.474 -203.519 -249.396 -204.157 -249.25 -204.769 L -235.946 -246.348 L -229.769 -240.596 C -222.727 -247.323 -215.39 -253.744 -207.779 -259.838 Z"
                  className={`control_arrows_view ${isRotatingLeft ? 'active' : ''}`}
                />
                <path
                  d="M 207.779 -259.838 C 200.854 -263.795 191.879 -262.397 186.523 -256.101 C 181.012 -249.622 181.234 -240.191 186.693 -233.988 C 193.121 -228.853 199.332 -223.458 205.311 -217.821 L 198.339 -211.329 L 239.198 -194.966 C 239.87 -194.787 240.576 -194.692 241.304 -194.692 C 245.816 -194.692 249.474 -198.35 249.474 -202.862 C 249.474 -203.519 249.396 -204.157 249.25 -204.769 L 235.946 -246.348 L 229.769 -240.596 C 222.727 -247.323 215.39 -253.744 207.779 -259.838 Z"
                  className={`control_arrows_view ${isRotatingRight ? 'active' : ''}`}
                />
              </g>
            )}
            {isMapMode && (
              <g transform={`rotate(${currentAngle + zeroAngle}, 0, 0)`}>
                {isRotatingLeft && (
                  <g transform="scale(0.7) rotate(30)">
                    <path
                      d="M -207.779 -259.838 C -200.854 -263.795 -191.879 -262.397 -186.523 -256.101 C -181.012 -249.622 -181.234 -240.191 -186.693 -233.988 C -193.121 -228.853 -199.332 -223.458 -205.311 -217.821 L -198.339 -211.329 L -239.198 -194.966 C -239.87 -194.787 -240.576 -194.692 -241.304 -194.692 C -245.816 -194.692 -249.474 -198.35 -249.474 -202.862 C -249.474 -203.519 -249.396 -204.157 -249.25 -204.769 L -235.946 -246.348 L -229.769 -240.596 C -222.727 -247.323 -215.39 -253.744 -207.779 -259.838 Z"
                      className="control_arrows_map"
                    />
                  </g>
                )}
                {isRotatingRight && (
                  <g transform="scale(0.7) rotate(-30)">
                    <path
                      d="M 207.779 -259.838 C 200.854 -263.795 191.879 -262.397 186.523 -256.101 C 181.012 -249.622 181.234 -240.191 186.693 -233.988 C 193.121 -228.853 199.332 -223.458 205.311 -217.821 L 198.339 -211.329 L 239.198 -194.966 C 239.87 -194.787 240.576 -194.692 241.304 -194.692 C 245.816 -194.692 249.474 -198.35 249.474 -202.862 C 249.474 -203.519 249.396 -204.157 249.25 -204.769 L 235.946 -246.348 L 229.769 -240.596 C 222.727 -247.323 215.39 -253.744 207.779 -259.838 Z"
                      className="control_arrows_map"
                    />
                  </g>
                )}
              </g>
            )}
          </g>
        )}
        {zoomLevel < zoomBreakpoints.high && zoomLevel >= zoomBreakpoints.mid && (
          <g id="breakpoint_high">
            <g id="ZERO">
              {!isMapMode ? (
                <g>
                  <line
                    origin="0 0"
                    className="zero_position"
                    x1={getX(zeroAngle - 2, 0)}
                    y1={getY(zeroAngle - 2, 0)}
                    x2={getX(zeroAngle, 10)}
                    y2={getY(zeroAngle, 10)}
                  />
                  <line
                    origin="0 0"
                    className="zero_position"
                    x1={getX(zeroAngle + 2, 0)}
                    y1={getY(zeroAngle + 2, 0)}
                    x2={getX(zeroAngle, 10)}
                    y2={getY(zeroAngle, 10)}
                  />
                  <text className="zero_text" x={getX(zeroAngle, 15)} y={getY(zeroAngle, 15)}>
                    0º
                  </text>
                </g>
              ) : (
                <g>
                  <line
                    origin="0 0"
                    className="zero_position_map"
                    x1={getX(zeroAngle - 2, -20)}
                    y1={getY(zeroAngle - 2, -20)}
                    x2={getX(zeroAngle, -10)}
                    y2={getY(zeroAngle, -10)}
                  />
                  <line
                    origin="0 0"
                    className="zero_position_map"
                    x1={getX(zeroAngle + 2, -20)}
                    y1={getY(zeroAngle + 2, -20)}
                    x2={getX(zeroAngle, -10)}
                    y2={getY(zeroAngle, -10)}
                  />
                  <text className="zero_text_map" x={getX(zeroAngle, -35)} y={getY(zeroAngle, -35)}>
                    0º
                  </text>
                </g>
              )}
            </g>
            <g className="main_container" transform={`rotate(${zeroAngle})`}>
              <path
                origin="0 0"
                id="pivot_area"
                className={`pivot_contour ${getContourClass()}`}
                style={{
                  fill:
                    centerInfo.mX.center === 0 && centerInfo.mY.center === 0
                      ? 'transparent'
                      : getStatusColor(),
                }}
                d={getPivotContour(startAngle, endAngle)}
              />
              {showUncovered && (
                <g transform={`rotate(${recipeUncovered.r}, 0, 0)`}>
                  <path id="path_water" className={getClassUncovered()} d={recipeUncovered.d} />
                </g>
              )}
              {showCovered && (
                <g transform={`rotate(${recipeCovered.r}, 0, 0)`}>
                  <path
                    id="path_done"
                    className={hasWater ? 'path_water_done' : 'path_no_water_done'}
                    d={recipeCovered.d}
                  />
                </g>
              )}
              {cannonAngles && cannonAngles.length && (
                <g>{cannonAngles.map(generateCannonPaths)}</g>
              )}
              <line
                id="RECIPE_END"
                origin="0 0"
                className="recipe_end_line_small"
                x1="0"
                y1="0"
                x2={getX(recipeEndAngle)}
                y2={getY(recipeEndAngle)}
              />
              <line
                id="RECIPE_START"
                origin="0 0"
                className="recipe_start_line_small"
                x1="0"
                y1="0"
                x2={getX(recipeStartAngle)}
                y2={getY(recipeStartAngle)}
              />
              {(isControlMode || isMapMode) && currentAngle >= 0 && (
                <g id="CURRENT_POSITION">
                  <path
                    className={`${isPowerOn ? 'current_position_on' : 'current_position_off'} ${
                      hasWater ? 'with_water' : 'with_no_water'
                    }`}
                    d={`M 0 0 L ${getX(currentAngle + 2, -20)} ${getY(
                      currentAngle + 2,
                      -20,
                    )} L ${getX(currentAngle, 0)} ${getY(currentAngle, 0)} L ${getX(
                      currentAngle - 2,
                      -20,
                    )} ${getY(currentAngle - 2, -20)} Z`}
                  />
                  <line
                    origin="0 0"
                    style={{ stroke: '#FFFFFF', strokeWidth: '8px' }}
                    x1="0"
                    y1="0"
                    x2={getX(currentAngle)}
                    y2={getY(currentAngle)}
                  />
                </g>
              )}
            </g>
            {(isControlMode || isMapMode) && (
              <g
                className="center_button_big"
                onClick={() => {
                  onClickPower();
                }}
              >
                {centerInfo.mX.center === 0 && centerInfo.mY.center === 0 && (
                  <circle
                    cx={`${centerInfo.mX.center}`}
                    cy={`${centerInfo.mY.center}`}
                    r={200 * (12 / zoomLevel)}
                    className={getClassCenterInfo()}
                  />
                )}
                <text
                  x={`${centerInfo.mX.center}`}
                  y={`${centerInfo.mY.center + 20}`}
                  className="water_blade"
                  style={{ fontSize: '70px' }}
                >
                  {`${

                    waterBladeSize
                      ? (waterBladeSize * animationController).toLocaleString('pt-BR', config)
                      : '---'
                  }`}
                  {}
                </text>
                <text
                  x={`${centerInfo.mX.center}`}
                  y={`${centerInfo.mY.center + 70}`}
                  style={{ fontSize: '35px' }}
                  className="water_blade_mm"
                >
                  mm
                </text>
              </g>
            )}
            {(isControlMode || isViewMode) && (
              <g id="info_layer" transform={`rotate(${zeroAngle})`}>
                <g className="starting_info">
                  <circle
                    className="starting_circle"
                    cx={starting.mX.center}
                    cy={starting.mY.center}
                    r="30"
                  />
                  <text
                    className="starting_text"
                    transform={`rotate(${-zeroAngle},${starting.mX.center},${starting.mY.center})`}
                    x={starting.mX.center + 3}
                    y={starting.mY.center + 6}
                  >
                    {`${recipeStartAngle}º`}
                  </text>
                </g>
                <g className="ending_info">
                  <circle
                    className="ending_circle"
                    cx={destination.mX.center}
                    cy={destination.mY.center}
                    r="30"
                  />
                  <text
                    className="ending_text"
                    transform={`rotate(${-zeroAngle},${destination.mX.center},${
                      destination.mY.center
                    })`}
                    x={destination.mX.center + 3}
                    y={destination.mY.center + 6}
                  >
                    {`${recipeEndAngle}º`}
                  </text>
                </g>
              </g>
            )}
            {isControlMode && (
              <g>
                <path
                  onClick={() => {
                    onClickLeft(isRotatingLeft);
                  }}
                  d="M -207.779 -259.838 C -200.854 -263.795 -191.879 -262.397 -186.523 -256.101 C -181.012 -249.622 -181.234 -240.191 -186.693 -233.988 C -193.121 -228.853 -199.332 -223.458 -205.311 -217.821 L -198.339 -211.329 L -239.198 -194.966 C -239.87 -194.787 -240.576 -194.692 -241.304 -194.692 C -245.816 -194.692 -249.474 -198.35 -249.474 -202.862 C -249.474 -203.519 -249.396 -204.157 -249.25 -204.769 L -235.946 -246.348 L -229.769 -240.596 C -222.727 -247.323 -215.39 -253.744 -207.779 -259.838 Z"
                  className={`control_arrows ${isRotatingLeft ? 'active' : ''}`}
                />
                <path
                  onClick={() => {
                    onClickRight(isRotatingRight);
                  }}
                  d="M 207.779 -259.838 C 200.854 -263.795 191.879 -262.397 186.523 -256.101 C 181.012 -249.622 181.234 -240.191 186.693 -233.988 C 193.121 -228.853 199.332 -223.458 205.311 -217.821 L 198.339 -211.329 L 239.198 -194.966 C 239.87 -194.787 240.576 -194.692 241.304 -194.692 C 245.816 -194.692 249.474 -198.35 249.474 -202.862 C 249.474 -203.519 249.396 -204.157 249.25 -204.769 L 235.946 -246.348 L 229.769 -240.596 C 222.727 -247.323 215.39 -253.744 207.779 -259.838 Z"
                  className={`control_arrows ${isRotatingRight ? 'active' : ''}`}
                />
              </g>
            )}
            {isViewMode && (
              <g>
                <path
                  d="M -207.779 -259.838 C -200.854 -263.795 -191.879 -262.397 -186.523 -256.101 C -181.012 -249.622 -181.234 -240.191 -186.693 -233.988 C -193.121 -228.853 -199.332 -223.458 -205.311 -217.821 L -198.339 -211.329 L -239.198 -194.966 C -239.87 -194.787 -240.576 -194.692 -241.304 -194.692 C -245.816 -194.692 -249.474 -198.35 -249.474 -202.862 C -249.474 -203.519 -249.396 -204.157 -249.25 -204.769 L -235.946 -246.348 L -229.769 -240.596 C -222.727 -247.323 -215.39 -253.744 -207.779 -259.838 Z"
                  className={`control_arrows_view ${isRotatingLeft ? 'active' : ''}`}
                />
                <path
                  d="M 207.779 -259.838 C 200.854 -263.795 191.879 -262.397 186.523 -256.101 C 181.012 -249.622 181.234 -240.191 186.693 -233.988 C 193.121 -228.853 199.332 -223.458 205.311 -217.821 L 198.339 -211.329 L 239.198 -194.966 C 239.87 -194.787 240.576 -194.692 241.304 -194.692 C 245.816 -194.692 249.474 -198.35 249.474 -202.862 C 249.474 -203.519 249.396 -204.157 249.25 -204.769 L 235.946 -246.348 L 229.769 -240.596 C 222.727 -247.323 215.39 -253.744 207.779 -259.838 Z"
                  className={`control_arrows_view ${isRotatingRight ? 'active' : ''}`}
                />
              </g>
            )}
            {isMapMode && (
              <g transform={`rotate(${currentAngle + zeroAngle}, 0, 0)`}>
                {isRotatingLeft && (
                  <g transform="scale(0.7) rotate(30)">
                    <path
                      d="M -207.779 -259.838 C -200.854 -263.795 -191.879 -262.397 -186.523 -256.101 C -181.012 -249.622 -181.234 -240.191 -186.693 -233.988 C -193.121 -228.853 -199.332 -223.458 -205.311 -217.821 L -198.339 -211.329 L -239.198 -194.966 C -239.87 -194.787 -240.576 -194.692 -241.304 -194.692 C -245.816 -194.692 -249.474 -198.35 -249.474 -202.862 C -249.474 -203.519 -249.396 -204.157 -249.25 -204.769 L -235.946 -246.348 L -229.769 -240.596 C -222.727 -247.323 -215.39 -253.744 -207.779 -259.838 Z"
                      className="control_arrows_map"
                    />
                  </g>
                )}
                {isRotatingRight && (
                  <g transform="scale(0.7) rotate(-30)">
                    <path
                      d="M 207.779 -259.838 C 200.854 -263.795 191.879 -262.397 186.523 -256.101 C 181.012 -249.622 181.234 -240.191 186.693 -233.988 C 193.121 -228.853 199.332 -223.458 205.311 -217.821 L 198.339 -211.329 L 239.198 -194.966 C 239.87 -194.787 240.576 -194.692 241.304 -194.692 C 245.816 -194.692 249.474 -198.35 249.474 -202.862 C 249.474 -203.519 249.396 -204.157 249.25 -204.769 L 235.946 -246.348 L 229.769 -240.596 C 222.727 -247.323 215.39 -253.744 207.779 -259.838 Z"
                      className="control_arrows_map"
                    />
                  </g>
                )}
              </g>
            )}
          </g>
        )}
        {zoomLevel < zoomBreakpoints.mid && zoomLevel >= zoomBreakpoints.small && (
          <g
            className="clickable main_container"
            onClick={() => {
              onClickPower();
            }}
            id="breakpoint_mid"
          >
            <g transform={`rotate(${zeroAngle})`}>
              <path
                origin="0 0"
                id="pivot_area"
                className={`pivot_contour_small ${getClassCenterInfo()}`}
                d={getPivotContour(startAngle, endAngle)}
              />
              {currentAngle >= 0 && (
                <g id="CURRENT_POSITION">
                  <path
                    style={{ fill: '#3d5059' }}
                    d={`M 0 0 L ${getX(currentAngle + 2, -20)} ${getY(
                      currentAngle + 2,
                      -20,
                    )} L ${getX(currentAngle, 0)} ${getY(currentAngle, 0)} L ${getX(
                      currentAngle - 2,
                      -20,
                    )} ${getY(currentAngle - 2, -20)} Z`}
                  />
                  <line
                    origin="0 0"
                    style={{ stroke: '#FFFFFF', strokeWidth: '8px' }}
                    x1="0"
                    y1="0"
                    x2={getX(currentAngle)}
                    y2={getY(currentAngle)}
                  />
                </g>
              )}
              <g>
                <line
                  id="RECIPE_END"
                  origin="0 0"
                  className="recipe_end_line_small"
                  x1="0"
                  y1="0"
                  x2={getX(recipeEndAngle)}
                  y2={getY(recipeEndAngle)}
                />
                <line
                  id="RECIPE_START"
                  origin="0 0"
                  className="recipe_start_line_small"
                  x1="0"
                  y1="0"
                  x2={getX(recipeStartAngle)}
                  y2={getY(recipeStartAngle)}
                />
              </g>
            </g>
            <g id="ZERO">
              <g>
                <line
                  origin="0 0"
                  className="zero_position_map"
                  x1={getX(zeroAngle - 2, -20)}
                  y1={getY(zeroAngle - 2, -20)}
                  x2={getX(zeroAngle, -10)}
                  y2={getY(zeroAngle, -10)}
                />
                <line
                  origin="0 0"
                  className="zero_position_map"
                  x1={getX(zeroAngle + 2, -20)}
                  y1={getY(zeroAngle + 2, -20)}
                  x2={getX(zeroAngle, -10)}
                  y2={getY(zeroAngle, -10)}
                />
              </g>
            </g>

            <g>
              <text
                x={`${centerInfo.mX.center}`}
                y={`${centerInfo.mY.center}`}
                className="water_blade_mid"
                style={{ fontSize: '70px' }}
              >
                {`${

                  waterBladeSize
                    ? (waterBladeSize * animationController).toLocaleString('pt-BR', config)
                    : '---'
                }`}
                {}
              </text>
              <text
                x={`${centerInfo.mX.center}`}
                y={`${centerInfo.mY.center + 80}`}
                className="water_blade_mm_mid"
                style={{ fontSize: '35px' }}
              >
                mm
              </text>
            </g>
            <g transform={`rotate(${currentAngle + zeroAngle}, 0, 0)`}>
              {isRotatingLeft && (
                <g>
                  <path
                    d="M -70 -209 C -57 -212 -42 -203 -37 -191 C -31 -177 -36 -161 -49 -154 C -61 -149 -74 -142 -87 -136 L -79 -121 L -156 -116 C -157 -116 -159 -117 -159 -117 C -167 -119 -170 -127 -168 -134 C -167 -136 -167 -136 -167 -138 L -124 -199 L -116 -188 C -102 -194 -85 -202 -70 -209 Z"
                    className="control_arrows_map"
                  />
                </g>
              )}
              {isRotatingRight && (
                <g>
                  <path
                    d="M 70 -209 C 57 -212 42 -203 37 -191 C 31 -177 36 -161 49 -154 C 61 -149 74 -142 87 -136 L 79 -121 L 156 -116 C 157 -116 159 -117 159 -117 C 167 -119 170 -127 168 -134 C 167 -136 167 -136 167 -138 L 124 -199 L 116 -188 C 102 -194 85 -202 70 -209 Z"
                    className="control_arrows_map"
                  />
                </g>
              )}
            </g>
            {isEditingMode && (
              <g transform={`rotate(${zeroAngle},0,0)`}>
                <path
                  origin="0 0"
                  id="pivot_editing_area"
                  className="pivot_editing"
                  d={getPivotContour(startAngle, endAngle)}
                  onMouseDown={(e) => {
                    startDragging(e);
                  }}
                  onMouseUp={(e) => {
                    stopDragging(e);
                  }}
                  onMouseLeave={(e) => {
                    stopDragging(e);
                  }}
                />
                <line
                  x1="0"
                  y1="0"
                  className="zero_indicator_line"
                  x2={getCenterPositionX()}
                  y2={getCenterPositionY()}
                />
                <circle
                  r="25"
                  onMouseDown={(e) => {
                    startRotating(e);
                  }}
                  onMouseUp={(e) => {
                    stopRotating(e);
                  }}
                  onMouseLeave={(e) => {
                    stopRotating(e);
                  }}
                  className="zero_indicator_circle"
                  cx={getCenterPositionX()}
                  cy={getCenterPositionY()}
                />
              </g>
            )}
          </g>
        )}
        {zoomLevel < zoomBreakpoints.small && zoomLevel >= zoomBreakpoints.hidden && (
          <g
            className="clickable main_container"
            onClick={() => {
              onClickPower();
            }}
            id="breakpoint_low"
          >
            <g id="ZERO">
              <g>
                <line
                  origin="0 0"
                  className="zero_position_map"
                  x1={getX(zeroAngle - 2, -20)}
                  y1={getY(zeroAngle - 2, -20)}
                  x2={getX(zeroAngle, -10)}
                  y2={getY(zeroAngle, -10)}
                />
                <line
                  origin="0 0"
                  className="zero_position_map"
                  x1={getX(zeroAngle + 2, -20)}
                  y1={getY(zeroAngle + 2, -20)}
                  x2={getX(zeroAngle, -10)}
                  y2={getY(zeroAngle, -10)}
                />
              </g>
            </g>
            <g transform={`rotate(${zeroAngle})`}>
              <path
                origin="0 0"
                id="pivot_area"
                className={`pivot_contour_small ${getContourClass()} ${getClassCenterInfo()}`}
                d={getPivotContour(startAngle, endAngle)}
              />
              {currentAngle >= 0 && (
                <g id="CURRENT_POSITION">
                  <path
                    style={{ fill: '#3d5059' }}
                    d={`M 0 0 L ${getX(currentAngle + 2, -20)} ${getY(
                      currentAngle + 2,
                      -20,
                    )} L ${getX(currentAngle, 0)} ${getY(currentAngle, 0)} L ${getX(
                      currentAngle - 2,
                      -20,
                    )} ${getY(currentAngle - 2, -20)} Z`}
                  />
                  <line
                    origin="0 0"
                    style={{ stroke: '#FFFFFF', strokeWidth: '8px' }}
                    x1="0"
                    y1="0"
                    x2={getX(currentAngle)}
                    y2={getY(currentAngle)}
                  />
                </g>
              )}
            </g>
            <g transform={`rotate(${currentAngle + zeroAngle}, 0, 0)`}>
              {isRotatingLeft && (
                <g>
                  <path
                    d="M -78 -199 C -59 -204 -35 -193 -26 -174 C -16 -154 -22 -130 -40 -117 C -59 -109 -77 -97 -96 -87 L -83 -64 L -199 -50 C -200 -50 -203 -50 -203 -50 C -215 -53 -221 -65 -219 -75 C -217 -79 -217 -79 -217 -82 L -159 -179 L -145 -162 C -125 -173 -100 -187 -78 -199 Z"
                    className="control_arrows_map"
                  />
                </g>
              )}
              {isRotatingRight && (
                <g>
                  <path
                    d="M 78 -199 C 59 -204 35 -193 26 -174 C 16 -154 22 -130 40 -117 C 59 -109 77 -97 96 -87 L 83 -64 L 199 -50 C 200 -50 203 -50 203 -50 C 215 -53 221 -65 219 -75 C 217 -79 217 -79 217 -82 L 159 -179 L 145 -162 C 125 -173 100 -187 78 -199 Z"
                    className="control_arrows_map"
                  />
                </g>
              )}
            </g>
          </g>
        )}
        {isEditingMode && (
          <g transform={`rotate(${zeroAngle},0,0)`}>
            <path
              origin="0 0"
              id="pivot_editing_area"
              className="pivot_editing"
              d={getPivotContour(startAngle, endAngle)}
              onMouseDown={(e) => {
                startDragging(e);
              }}
              onMouseUp={(e) => {
                stopDragging(e);
              }}
              onMouseLeave={(e) => {
                stopDragging(e);
              }}
            />
            <line
              x1="0"
              y1="0"
              className="zero_indicator_line"
              x2={getCenterPositionX()}
              y2={getCenterPositionY()}
            />
            <circle
              r="25"
              onMouseDown={(e) => {
                startRotating(e);
              }}
              onMouseUp={(e) => {
                stopRotating(e);
              }}
              onMouseLeave={(e) => {
                stopRotating(e);
              }}
              className="zero_indicator_circle"
              cx={getCenterPositionX()}
              cy={getCenterPositionY()}
            />
          </g>
        )}
      </svg>
    </Tooltip>
  );
};
