import React from 'react';
import { Typography, AccordionDetails, AccordionSummary, Accordion } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import * as S from './styles';

export function AccordionContainer({ title, expanded, name, handleExpandAccordion, children }) {
  return (
    <S.ContentAccordion>
      <Accordion expanded={expanded === name} onChange={handleExpandAccordion(name)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {children}
        </AccordionDetails>
      </Accordion>
    </S.ContentAccordion>
  );
}
