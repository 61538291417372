import moment from 'moment';
import React from 'react';
import Chart from 'react-apexcharts';
import { formatDate } from '../../../../../helpers/Intl';

export default function StationGraphic({ data, selectedData }) {
  const series = [];
  const categories = [];

  if (!data || data.length === 0) {
    series.push({ name: '', data: [0] });
  } else {
    data?.categories.forEach((item) => {
      categories.push(item);
      console.log('item aqui =>', formatDate(item));
    });
    series.pop();
    selectedData.forEach((item) => {
      data?.series.forEach((subItem) => {
        if (item === subItem.name) {
          series.push({ name: subItem?.name, data: subItem?.data });
        }
      });
    });
  }

  const state = {
    series: [...series],
    options: {
      chart: {
        height: '100%',
        type: 'area',
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        categories: [...categories],
        labels: {
          formatter(value) {
            return formatDate(moment.utc(value).toDate()); // The formatter function overrides format property
          },
        },
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
      },
    },
  };

  return (
    <div>
      <Chart
        options={state.options}
        series={state.series}
        type="area"
        width="100%"
        height="190px"
      />
    </div>
  );
}
