import React, { useEffect, useState } from 'react';
import './style.scss';
import { Grid } from '@material-ui/core';
import {
  FlatBottomSilo,
  ConicalBottomSilo,
} from '../siloTypes';
import ViewSensorsStyle from './viewSensorsStyle';
import verifyTemperature from '../../helpers/verifyTemperature';

function HeatMap({ data, selectedNumberCables, siloType, modal, mobile, viewStyle }) {
  const [cables, setCables] = useState([]);
  const [heatMapData, setHeatMapData] = useState([]);
  const [heatMapDataOneCable, setHeatMapDataOneCable] = useState([]);
  // const [sensorSize, setSensorSize] = useState({ x: 25, y: 25 });
  const [sensorsNumber, setSensorsNumber] = useState();
  const [cablesNumber, setCablesNumber] = useState();
  // const [viewSensor, setViewSensor] = useState(false);
  const viewSensor = false;

  if (!viewStyle) {
    viewStyle = false;
  }

  let sensors = 0;
  if (cables) {
    sensors = Math.max(...cables.map((x) => x?.sensors.length));
  }

  const calcVTempY = (index, cableQtd) =>
    (535 / sensors) * (sensors - (index + (sensors - cableQtd) / 2)) - 10;

  const calcPlanTempY = (index, cableQtd) =>
    (492 / sensorsNumber) * (sensorsNumber - index) -
    (6 + (sensorsNumber - cableQtd) * 2);

  function headerCalc(type) {
    if (type === 'plainTulha' || siloType === 'plain2Tulha' || siloType === 'plain3Tulha') {
      return 330;
    }
    if (type === 'VTulha' || type === 'V1Tulha' || type === 'V2Tulha' || type === 'V3Tulha') {
      return 400;
    }
  }

  function headerCableMobileCalc(cableLength) {
    let x;
    switch (cableLength) {
      case 1:
        x = 25;
        break;
      case 2:
        x = 22;
        break;
      case 3:
        x = 5;
        break;
      case 4:
        x = 16;
        break;
      default:
        x = 12;
    }
    return x;
  }

  function headerSensorMobileCalc(sensorLength) {
    let x;
    switch (sensorLength) {
      case 1:
        x = 25;
        break;
      case 2:
        x = 7;
        break;
      case 3:
        x = 4;
        break;
      case 4:
        x = 3;
        break;
      default:
        x = 12;
    }
    return x;
  }

  React.useEffect(() => {
    if (selectedNumberCables !== undefined) {
      if (selectedNumberCables.length > 0) {
        const auxCables = [];
        const refCables = [];
        data.chartData.forEach((cd) => {
          cd.unitData.arcs.forEach((arc) => {
            arc.cabs.forEach((cab) => {
              if (selectedNumberCables.indexOf(cab.num) !== -1) {
                auxCables.push({
                  cableNumber: cab.num,
                  sensors: cab.sens.map((c) => ({ color: c.gradualColor, t: c.t })),
                });
              }
            });
          });
        });

        // Just to keep the selectedNumberCables order
        if (auxCables.length > 0) {
          selectedNumberCables.forEach((e) => {
            refCables.push(auxCables.find((f) => f.cableNumber === e));
          });
        }

        // console.log('refCables', refCables);
        setCables(refCables);
      }
    }
  }, [data, selectedNumberCables]);

  useEffect(() => {
    const largeCable = Math.max(...cables.map((x) => x?.sensors.length));
    const numberOfCables = cables?.length;
    setSensorsNumber(largeCable);
    setCablesNumber(numberOfCables);
    const sensorDynamicSizeX = 88 / numberOfCables;
    // const sensorDynamicSizeX = (250 / largeCable).toFixed(2);
    // const sensorDynamicSizeXModal = (100 / largeCable).toFixed(5);
    const linearDigitalSize = (100 / largeCable).toFixed(2);
    // setSensorSize({ x: sensorDynamicSizeY, y: sensorDynamicSizeX });
    // console.log(largeCable);

    const normalSensors = [];

    cables.forEach((x, indice) =>
      x?.sensors.forEach((s, is) => {
        const a = ((indice) * sensorDynamicSizeX) + (modal ? 20 : (55 / numberOfCables));
        const b = (calcVTempY(is, x.sensors.length) * 100) / 700;
        const c = s.color;
        const d = viewSensor ? 10 : 0;
        const e = viewSensor ? 0 : cables?.length <= 3 ? 35 : 18;
        const radial = ['radial-gradient(ellipse at ', a, '% ', b, '%, ', c, ' ', d, 'px, transparent ', e, '%)'].join('');
        normalSensors.push({
          t: s.t,
          radial,
        });
      }));

    normalSensors.sort((a, b) => b.t - a.t);

    const normalSensorsFix = normalSensors.map((x) => x.radial);
    if (numberOfCables === 1) {
      const oneCableCase = cables.map((x) =>
        x?.sensors.map((s, is) => [s.color, ' ', linearDigitalSize * is, '%'].join('')));
      setHeatMapDataOneCable(oneCableCase);
    }
    setHeatMapData(normalSensorsFix);
  }, [cables, data, selectedNumberCables, siloType, viewSensor]);

  // console.log('>>>>', sensorsNumber);
  // const heatMapData = cables.map((x, indice) =>
  // (`radial-gradient(at ${indice * sensorSize.x}% ${}0%, ${x.sensors.map((y) => (y.color))}
  // 0px, transparent ${sensorSize.x}%)`));

  /* const heatMapData = cables.map((x, indice) =>
  // x.sensors.map((s, is) => (`radial-gradient(at ${indice * 24}% ${is * 8}%, ${s.color}
  // 5px, transparent 20%)`))); */

  return (
    <Grid container spacing={2} style={{ height: modal ? 750 : 'auto' }}>
      <Grid item xs={12} lg={12}>
        <div id="termovisor">
          {/* indicação dos cabos no silo */}
          {(modal) && (!mobile) && (
            <div className="cableHeadersTulha">
              {cables?.map((cable) => (
                <svg width={headerCalc(siloType) / cablesNumber} height="50">
                  <rect
                    x={headerCalc(siloType) / cablesNumber / 2 - 15}
                    width="30px"
                    height="30px"
                    style={{ fill: '#d1d1d1' }}
                    stroke="#d1d1d1"
                    strokeWidth="2px"
                  />
                  <text
                    x={
                      headerCalc(siloType) / cablesNumber / 2 -
                      15 +
                      (cable.cableNumber < 10 ? 10 : 5)
                    }
                    y={18}
                    fill="black"
                    style={{ fontSize: 12, fontWeight: 700 }}
                  >
                    {cable.cableNumber}
                  </text>
                </svg>
              ))}
            </div>
          )}

          {mobile ? (
            <div className="heatmap" style={{ justifyContent: 'center' }}>
              {cables?.map((cable) => (
                <svg width={70} height={sensorsNumber * 30 + 35}>
                  <rect
                    y={0}
                    width="60px"
                    height="30px"
                    style={{ fill: '#d1d1d1' }}
                    stroke="#d1d1d1"
                    strokeWidth="2px"
                  />
                  <text
                    x={
                      headerCableMobileCalc(cable.cableNumber.toFixed(0).length)
                    }
                    y={20}
                    fill="black"
                    style={{ fontSize: 12, fontWeight: 700 }}
                  >

                    {cable.cableNumber}
                  </text>
                  {cable.sensors.map((sensor, index) => (
                    <rect
                      y={sensorsNumber * 30 + 35 - (index * 30 + 30)}
                      width="60px"
                      height="30px"
                      style={{ fill: sensor.color }}
                      stroke="#d1d1d1"
                      strokeWidth="2px"
                    />
                  ))}
                  {cable.sensors.map((sensor, index) => (
                    <text
                      x={headerSensorMobileCalc(verifyTemperature(sensor.t).toString().length)}
                      y={sensorsNumber * 30 + 35 - (index * 30 + 8)}
                      fill="black"
                      style={{ fontSize: 20, fontWeight: 700 }}
                    >
                      {verifyTemperature(sensor.t)}
                      {/* {
                      console.log('teste', sensor.t.toString().length)
                      } */}
                    </text>
                  ))}
                </svg>
              ))}
            </div>

          ) : (

            <div
              style={{
                position: 'relative',
                // margin: 0,
                // display: 'flex',
                // justifyContent: 'center',
              }}
            >
              {/* Arquivo salvo(biruleibe) */}

              {/* aqui mostra o fundo cinza no modal */}
              {(siloType === 'plainTulha' || siloType === 'plain2Tulha' || siloType === 'plain3Tulha') && (
                <div style={{ marginLeft: modal ? 180 : 60 }}>
                  <FlatBottomSilo modal={modal} thermicVision="white" />
                </div>
              )}
              {(siloType === 'VTulha' || siloType === 'V2Tulha' || siloType === 'V3Tulha') && (
                <div style={{ textAlign: modal ? 'center' : 'left' }}>
                  <ConicalBottomSilo
                    modal={modal}
                    cables={cables}
                    cablesNumber={cablesNumber}
                    thermicVision="white"
                  />
                </div>
              )}

              <div
            // corzinha do silo
                className={modal ? `${siloType}Modal` : siloType}
                style={{
                  backgrounColor: 'white',
                  backgroundImage:
                  ((cables?.length === 1) && (viewStyle === false))
                    ? `linear-gradient(360deg, ${heatMapDataOneCable}`
                    : ((viewStyle === false) || (!modal)) && heatMapData,
                  position: 'absolute',
                  top: 8,
                  left: 17.5,
                  marginLeft: modal ? 0 : 0,
                }}
              >

                {/* Números dentro do silo no modal */}
                {modal && (siloType === 'plainTulha' || siloType === 'plain2Tulha' || siloType === 'plain3Tulha') && (

                  cables?.map((cable) => (
                    <svg width={330 / cablesNumber} height="515">
                      {cable.sensors.map((sensor, index) => (
                        <ViewSensorsStyle
                          x={(180 / cablesNumber) - 25}
                          y={calcPlanTempY(index, cable.sensors.length)}
                          color={sensor.color}
                          temperature={sensor.t}
                          style={viewStyle}
                          siloType={siloType}
                        />
                      ))}
                    </svg>
                  ))

                )}

                {modal &&
                (siloType === 'VTulha' ||
                  siloType === 'V2Tulha' ||
                  siloType === 'V3Tulha') && (
                  cables?.map((cable) => (
                    <svg width={((330 / cablesNumber))} height="700">
                      {/* {console.log('cable in modal->', cable.cableNumber,
                      'width->', (330 / cablesNumber))} */}
                      {cable.sensors.map((sensor, indexSensor) => (
                        <ViewSensorsStyle
                          x={(180 / cablesNumber) - 25}
                          y={calcVTempY(indexSensor, cable.sensors.length)}
                          color={sensor.color}
                          temperature={sensor.t}
                          style={viewStyle}
                          siloType={siloType}
                        />
                      ))}
                    </svg>
                  ))
                )}
              </div>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default HeatMap;
