// import React from 'react';

export default function selectedSection(cableNumber, cortes) {
  const cablesInSection = cortes[0].length;
  let sectionForReturn;

  // console.log('cablesInSection ', cablesInSection);
  // console.log('cortes ->', cortes);

  for (let k = 0; k < cablesInSection; k += 1) { //  loop com os cabos no corte
    for (let j = 0; j < cortes.length; j += 1) { // loop com a quantidade de cortes
      // console.log(`${cortes[j][k]}===${cableNumber}`);
      if (cortes[j][k] === cableNumber) {
        // console.log('achou! o corte é: ', cortes[j]);
        sectionForReturn = cortes[j]; // vetor de cabos para retornar
        k = cablesInSection; // finaliza o loop dos cabos nos cortes
        j = cortes.length; // finaliza o loop da quantidade de cortes
      }
    }
  }

  return (sectionForReturn);
}
