/* eslint-disable no-nested-ternary */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import Skeleton from '@material-ui/lab/Skeleton';
// import Select from '@material-ui/core/Select';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';
// import MenuItem from '@material-ui/core/MenuItem';
import EquipStatus from './EquipStatus';

const unitTypes = [
  { name: 'Não Identificado', icon: 'armazem' },
  { name: 'Silo Plano', icon: 'silo' },
  { name: 'Silo V', icon: 'silo' },
  { name: 'Armazém Plano', icon: 'armazem' },
  { name: 'Armazém SemiV', icon: 'armazem' },
  { name: 'Armazém V', icon: 'armazem' },
  { name: 'Armazém W', icon: 'armazem' },
  { name: 'Armazém Bordas Arredondadas - Plano', icon: 'armazem' },
  { name: 'Armazém Bordas Arredondadas - Fundo V', icon: 'armazem' },
  { name: 'Silo Elevado', icon: 'silo' },
  { name: 'Silo Semi V', icon: 'silo' },
  { name: 'Silo W', icon: 'silo' },
  { name: 'Tulha Plano', icon: 'tulha' },
  { name: 'Tulha Fundo V', icon: 'tulha' },
  { name: 'Bunker Plano', icon: 'bunker' },
  { name: 'Bunker Fundo V', icon: 'bunker' },
  { name: 'Armazém Bordas Semi Arredondadas - Plano', icon: 'armazem' },
  { name: 'Armazém Bordas Semi Arredondadas - Semi V', icon: 'armazem' },
];

const useStyles = makeStyles(() => ({
  settingsButton: {
    height: '55px',
    width: 'auto',
    margin: '0 8px',
    cursor: 'pointer',
  },
  icons: {
    height: '35px',
    width: 'auto',
    margin: '8px',
    cursor: 'pointer',
  },
  titleDescription: {
    margin: '0',
    fontWeight: '500',
  },
  titleDescriptionSmall: {
    margin: '0',
    fontWeight: '400',
  },
  avatar: {
    background: '#3D5059',
  },
}));

export default (props) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      spacing={0}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ marginBottom: '32px' }}
    >
      <Grid item xs={12} sm={6}>
        <h2 className={classes.titleDescription}>
          {props.loading ? (
            <Skeleton variant="rect" height={30} width={220} animation="wave" />
          ) : (
            <>
              <span style={{ paddingRight: '10px' }}>{props.title}</span>
              {props.unitType && (
                <Tooltip
                  title={unitTypes[props.unitType].name || 'Não Identificado'}
                >
                  <img
                    src={`/images/unitType/${
                      unitTypes[props.unitType].icon
                    }.svg`}
                    style={{
                      width: '100%',
                      maxWidth: '32px',
                      maxHeight: '32px',
                      marginLeft: '8px',
                      marginRight: '16px',
                    }}
                    alt=""
                  />
                </Tooltip>
              )}
              {props.type && typeof props.type === 'string' && (
                <Chip color="primary" size="small" label={props.type} />
              )}
              {props.type &&
                Array.isArray(props.type) &&
                props.type.map((t) => (
                  <Chip
                    style={{ marginRight: '5px' }}
                    color="primary"
                    size="small"
                    label={t}
                  />
                ))}
            </>
          )}
        </h2>
      </Grid>
      <Grid
        item
        xs={12}
        sm={6}
        style={{
          textAlign: 'right',
          maxWidth: '240px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {props.loading && (
          <Skeleton variant="rect" height={30} width="100%" animation="wave" />
        )}

        {!props.loading && !props.noStatus && (
          <EquipStatus
            connection={props.connection}
            running={props.running}
            alert={props.alert}
          />
        )}

        {/* {!props.setStrategy || props.loading || props.strategys.length < 2 ? (
          (props.strategys || []).length === 0 ? (
            <></>
          ) : (
            <span style={{ minWidth: 'fit-content' }}>
              <p style={{ margin: '0', fontSize: '11px' }}>Subdivisão</p>
              <p
                style={{ margin: '0' }}
              >
                {`#${props.strategys[0].sub} - ${props.strategys[0].grain}`}
              </p>
            </span>
          )
        ) : (
          <FormControl
            style={{ textAlign: 'left', marginLeft: '20px' }}
            variant="filled"
          >
            <InputLabel>Subdivisão</InputLabel>
            <Select
              value={props.strategy}
              onChange={(value) => {
                props.setStrategy(value.target.value);
              }}
              name="operationId"
            >
              {props.strategys.map((v) => (
                <MenuItem key={v.sub} value={v.sub}>
                  {`#${v.sub} - ${v.grain}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )} */}

        {props.noSettings || props.loading ? (
          <></>
        ) : (
          <Button
            size="large"
            variant="contained"
            style={{
              background: '#828282',
              color: 'white',
              padding: '8px',
              minWidth: 'fit-content',
              float: 'right',
              marginLeft: '12px',
            }}
            onClick={() =>
              history.push(
                `${window.location.pathname}/settings${props.hasCustomRoute ? props.hasCustomRoute : ''}`,
              )
            }
          >
            <SettingsIcon />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
