import React from 'react';
import { Grid } from '@material-ui/core';
import { Ouroboro } from 'react-spinners-css';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import jwtDecode from 'jwt-decode';
import FockinkAPI from '../../../../middlewares/Api';
import { useStyles } from './styles';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import BackButton from '../../../../components/Buttons/BackButton';
import { formatDate } from '../../../../helpers/Intl';
import { HeaderPainel, MultilineGraph } from './components';

export default function VariableTracking(props) {
  const [breadcrumbs, setBreadcrumbs] = React.useState({ routes: [] });
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState([]);
  const [graphData, setGraphData] = React.useState(undefined);
  const [equipaments, setEquipaments] = React.useState([]);
  const [variableTracking, setVariableTracking] = React.useState([]);
  const MySwal = withReactContent(Swal);
  const [search, setSearch] = React.useState({
    dateStart: new Date(new Date().setDate(new Date().getDate() - 7)),
    dateFinish: new Date(),
    reports: [
      {
        equipamentId: '',
        telemetryVariableId: '',
        color: '#f44336',
        rid: 123,
      },
    ],
  });
  const token = localStorage.getItem('@FockinkAPP:token');
  const { systemIds } = jwtDecode(token);
  const urlParams = window.location.search;

  const history = useHistory();

  React.useEffect(() => {
    (async () => {
      setBreadcrumbs({
        title: 'Tracking de Variáveis',
        routes: [
          { name: 'Relatórios', to: '/reports' },
          { name: 'Tracking de Variáveis' },
        ],
        secondRoute: [
          { name: 'Clientes', to: '/clientsAdm/' },
          { name: 'Relatórios', to: `/reportsAdm${urlParams}&op=all` },
          { name: 'Tracking de Variáveis' },
        ] });
      if (localStorage.profile === 'admin') {
        const equips = await FockinkAPI.get('/equipaments');
        setEquipaments(equips.data.data.filter((res) => res.accountId === urlParams.split('?accountId=')[1]));
      } else {
        const equips = await FockinkAPI.get('/dashboards/equipaments');
        const filteredEquips = equips.data.data.equipaments.filter(
          (f) => systemIds.indexOf(f.operationId) !== -1,
        );

        setEquipaments(filteredEquips);
      }
      const tva = await FockinkAPI.get('/telemetryVariables');
      setVariableTracking(tva.data.data);
    })();
  }, []);

  const handleGetReport = async () => {
    try {
      setLoading(true);
      setGraphData(undefined);
      const newSearch = {
        ...search,
        dateStart: formatDate(search.dateStart, 'yyyy-MM-dd'),
        dateFinish: formatDate(search.dateFinish, 'yyyy-MM-dd'),
        reports: search.reports.filter((f) => f.removed !== true),
      };
      const res = await FockinkAPI.post('reports/variableTracking', newSearch);
      const { series, errors: errorsRes } = res.data.data;
      setErrors(errorsRes);
      setGraphData(series);
      setLoading(false);
    } catch (error) {
      const em =
        (error.response.data.validation &&
          error.response.data.validation.body.message) ||
        error.response.data.reason ||
        error.response.data.message;
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: em,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Breadcrumbs routes={localStorage.profile === 'admin' && urlParams ? breadcrumbs.secondRoute : breadcrumbs.routes} title={breadcrumbs.title} />
      <Grid
        container
        direction="column"
        spacing={1}
        className={classes.wrapper}
      >
        {equipaments.length !== 0 ? (
          <HeaderPainel
            search={search}
            setSearch={setSearch}
            equipaments={equipaments}
            variableTracking={variableTracking}
            runSearch={handleGetReport}
          />
        )
          : <p>Não existem equipamentos cadastrados nessa conta</p>}
        {errors.length > 0 && (
          <Grid item xs={12} className={classes.basicCard}>
            <h2 style={{ textAlign: 'center' }}>Erros encontrados</h2>
            {errors.map((e, i) => (
              <h4 style={{ fontWeight: '500' }}>{`${i + 1}) ${e}`}</h4>
            ))}
          </Grid>
        )}

        {loading && (
          <Grid item xs={12} className={classes.basicCard}>
            <div className={classes.loadingContainer}>
              <Ouroboro color="#3D5059" />
            </div>
          </Grid>
        )}

        {graphData && graphData.length > 0 && (
          <Grid
            item
            xs={12}
            style={{
              background: '#FFF',
              marginTop: '10px',
              borderRadius: '0.2rem',
            }}
          >
            <MultilineGraph series={graphData} />
          </Grid>
        )}

        <Grid className={classes.btnBttn} item xs={12}>
          <BackButton
            back={() => history.goBack()}
            className={classes.btnSpace}
          />
        </Grid>
      </Grid>
    </>
  );
}
