import React from 'react';
import { useDashboardContext } from '../../../../hooks/dashboard';
import { SensorsSummaryChart } from './components/SensorSummaryChart';
import Card from '../../../Card';
import CardText from '../../../CardText';
import CardTitle from '../../../CardTitle';

export function SensorSummary() {
  const { dataHotSensorSummaryReport, plantIdToRequest } = useDashboardContext();

  return (
    <Card similar>
      <CardTitle pt="8px" pr="8px" pl="8px" pb="0" size="1rem">
        Resumo dos Sensores
        {' '}
        {plantIdToRequest && '(Planta)'}
      </CardTitle>

      <CardText pt="4px" pr="4px" pl="4px" pb="10px" size="1rem">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90%' }}>
          <SensorsSummaryChart data={dataHotSensorSummaryReport} />
        </div>
      </CardText>
    </Card>
  );
}
