// Basic
import React from 'react';

// UI, images, styles and icons
import '../../../App.css';
import { Redirect, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import RoomIcon from '@material-ui/icons/Room';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import ReactDOMServer from 'react-dom/server';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip as LeafletTooltip,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './styles.css';
import L from 'leaflet';

// API
import FockinkAPI from '../../../middlewares/Api';

const iconHTML = (icon) =>
  ReactDOMServer.renderToString(
    <div
      style={{
        background: '#CECECE',
        padding: '5px 10px',
        borderRadius: '0 10px 10px 10px',
        border: '1px solid #3D5059',
        width: 'fit-content',
      }}
    >
      <img src={icon} alt="" />
    </div>,
  );
const Icon = (icon) =>
  new L.DivIcon({
    html: iconHTML(icon),
  });

const useStyles = makeStyles({
  spacing: {
    marginTop: '15px',
  },
  bottomButtons: {
    margin: '25px 0',
    textAlign: 'right',
  },
  noMargin: {
    margin: 0,
  },
  toggleButton: {
    paddingTop: 0,
    paddingBottom: 0,
    height: '49px',
  },
  smallIcon: {
    height: '30px',
  },
});

export default (props) => {
  // Stats
  const [loading, setLoading] = React.useState(true);
  const [inputs, setInputs] = React.useState([]);
  const [bounds, setBounds] = React.useState([[-24.26988, -53.84174]]);
  const [data, setData] = React.useState([]);
  const [redirect, setRedirect] = React.useState(false);
  const [solution, setSolution] = React.useState(-1);
  const history = useHistory();

  const details = {
    1: {
      icon: '/images/icons/pivotIcon.svg',
      url: 'irrigationDash',
      rawName: 'irrigation',
    },
    2: {
      icon: '/images/icons/thermometryIcon.svg',
      url: 'thermometryDash',
      rawName: 'thermometry',
    },
    3: {
      icon: '/images/icons/automationIcon.svg',
      url: 'automationDash',
      rawName: 'automation',
    },
    4: {
      icon: '/images/icons/powerPlantIcon.svg',
      url: 'photovoltaicDash',
      rawName: 'photovoltaic',
    },
    5: {
      icon: '/images/icons/weatherStationSmall.svg',
      url: 'weatherStation',
      rawName: 'weatherstation',
    },
  };

  // Lifecycle
  React.useEffect(() => {
    [
      '/images/icons/automationIcon.svg',
      '/images/icons/powerPlantIcon.svg',
      '/images/icons/pivotIcon.svg',
      '/images/icons/thermometryIcon.svg',
      '/images/icons/wheather-station.svg',
    ].forEach((image) => {
      new Image().src = image;
    });
  }, []);

  React.useEffect(() => {
    const dt = data.filter(
      (f) =>
        f.location &&
        f.location.lat &&
        f.location.lon &&
        (solution === '*' || details[f.operationId].rawName === solution),
    );
    setInputs(dt);
    const a = dt.map((m) => [
      parseFloat(m.location.lat),
      parseFloat(m.location.lon),
    ]);
    setBounds(a.length === 0 ? [[-24.26988, -53.84174]] : a);
  }, [data, solution]);

  React.useEffect(() => {
    setLoading(true);
    FockinkAPI.get('/dashboards/plants?extended=true').then((response) => {
      setData(response.data.data.plants);
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const oid = urlParams.get('op');
    const systems = ['automation', 'photovoltaic', 'irrigation', 'thermometry', 'weatherStation'];
    if (systems.indexOf(oid) !== -1) {
      setSolution(oid);
    } else if (!oid) {
      setSolution('*');
    }
  }, [window.location.search]);

  const handleSolution = (event, currentSolution) => {
    if (currentSolution !== null && currentSolution !== '*') {
      const params = new URLSearchParams(window.location.search);
      params.set('op', currentSolution);
      history.push({ search: params.toString() });
    } else if (currentSolution !== null && currentSolution === '*') {
      const params = new URLSearchParams(window.location.search);
      params.delete('op');
      history.push({ search: params.toString() });
    }
  };

  const handleView = (event, currentSolution) => {
    if (currentSolution && currentSolution === 'map') {
      const params = new URLSearchParams(window.location.search);
      params.set('view', currentSolution);
      history.push({ search: params.toString() });
    } else if (currentSolution && currentSolution === 'cards') {
      const params = new URLSearchParams(window.location.search);
      params.delete('view');
      history.push({ search: params.toString() });
    }
  };

  const classes = useStyles();
  // if (inputs.length === 0) {
  //   return <Skeleton variant="rect" height={150} />;
  // }

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item xs={6}>
          <p className={classes.noMargin}>Sistemas</p>
          <ToggleButtonGroup
            aria-label="device"
            value={solution}
            exclusive
            onChange={handleSolution}
          >
            <ToggleButton value="*" aria-label="all">
              Todos
            </ToggleButton>
            <ToggleButton
              value="automation"
              aria-label="automation"
              className={classes.toggleButton}
            >
              <Tooltip title="Automação">
                <img
                  src="/images/icons/automationIcon.svg"
                  alt="Automação"
                  className={classes.smallIcon}
                />
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value="photovoltaic"
              aria-label="photovoltaic"
              className={classes.toggleButton}
            >
              <Tooltip title="Fotovoltaica">
                <img
                  src="/images/icons/powerPlantIcon.svg"
                  alt="Fotovoltaica"
                  className={classes.smallIcon}
                />
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value="irrigation"
              aria-label="irrigation"
              className={classes.toggleButton}
            >
              <Tooltip title="Irrigação">
                <img
                  src="/images/icons/pivotIcon.svg"
                  alt="Irrigação"
                  className={classes.smallIcon}
                />
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value="thermometry"
              aria-label="thermometry"
              className={classes.toggleButton}
            >
              <Tooltip title="Termometria">
                <img
                  src="/images/icons/thermometryIcon.svg"
                  alt="Termometria"
                  className={classes.smallIcon}
                />
              </Tooltip>
            </ToggleButton>
            <ToggleButton
              value="weatherStation"
              aria-label="weatherStation"
              className={classes.toggleButton}
            >
              <Tooltip title="Estação Meteorológica">
                <img
                  src="/images/icons/wheater-station.svg"
                  alt="Estação Meteorológica"
                  className={classes.smallIcon}
                />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <p className={classes.noMargin}>Visão</p>
          <ToggleButtonGroup
            aria-label="device"
            value={props.view}
            exclusive
            onChange={handleView}
          >
            <ToggleButton value="cards" className={classes.toggleButton}>
              <Tooltip title="Visualização por Card">
                <DashboardIcon style={{ height: '48px' }} />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="map" className={classes.toggleButton}>
              <Tooltip title="Visualização por Mapa">
                <RoomIcon style={{ height: '48px' }} />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '0' }}>
          {loading && <Skeleton variant="rect" height={440} />}

          {inputs.length === 0 && !loading && (
            <span>Nenhuma planta cadastrada.</span>
          )}

          {inputs.length > 0 && !loading && (
            <MapContainer
              bounds={bounds}
              maxZoom={17}
              style={{ height: '450px' }}
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution="World Map"
                // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
              />

              {inputs.map((i) => (
                <Marker
                  key={i.id}
                  style={{ background: 'purple' }}
                  icon={Icon(details[i.operationId].icon)}
                  position={[i.location.lat, i.location.lon]}
                >
                  <Popup>
                    <Button
                      size="large"
                      variant="contained"
                      color="secondary"
                      onClick={() =>
                        setRedirect(`/equipamentData?plantId=${i.id}&view=map`)
                      }
                    >
                      Ver Equipamentos
                    </Button>
                  </Popup>
                  <LeafletTooltip>{`Planta: ${i.description}`}</LeafletTooltip>
                </Marker>
              ))}
            </MapContainer>
          )}
        </Grid>
      </Grid>
    </>
  );
};
