export function headerCell({ value }) {
  const objCol = { v: value, s: { font: { sz: '10.5', bold: true }, alignment: { horizontal: 'center' } } };
  // const objCol = { v: value, s: { font: { sz: '10.5', bold: true } } };
  // console.log('object', objCol);
  return objCol;
}

export function headerCellSplit({ value }) {
  const objCol = { v: value,
    s: {
      font: { sz: '10.5', bold: true },
      alignment: { horizontal: 'center' },
      border: {
        right: { style: 'medium', color: { rgb: '000000' } },
      },
    } };
  return objCol;
}

export function descriptionHeaderCell({ value }) {
  const objCol = { v: value,
    s: {
      font: { sz: '10.5', bold: true, color: { rgb: 'FFFFFF' } },
      fill: { fgColor: { rgb: '244062' } },
      alignment: { horizontal: 'center' },
      border: {
        top: { style: 'medium', color: { rgb: 'FFFFFF' } },
        bottom: { style: 'medium', color: { rgb: 'FFFFFF' } },
        left: { style: 'medium', color: { rgb: 'FFFFFF' } },
        right: { style: 'medium', color: { rgb: 'FFFFFF' } },
      },
    } };
  return objCol;
}

export function square({
  value = '', borderColor = 'FFFFFF', fill = 'FFFFFF',
  fontColor = '000000', fontSize = 8, bold = true, textAlign = 'center',
  borderOn = [1, 1, 1, 1],
}) {
  const objCol = { v: value,
    s: {
      font: { sz: fontSize, bold, color: { rgb: fontColor } },
      fill: { fgColor: { rgb: fill } },
      alignment: { horizontal: textAlign, vertical: 'center', wrapText: true },
      border: {
        top: { style: 'medium', color: { rgb: borderOn[0] ? borderColor : 'FFFFFF' } },
        right: { style: 'medium', color: { rgb: borderOn[1] ? borderColor : 'FFFFFF' } },
        bottom: { style: 'medium', color: { rgb: borderOn[2] ? borderColor : 'FFFFFF' } },
        left: { style: 'medium', color: { rgb: borderOn[3] ? borderColor : 'FFFFFF' } },
      },
    } };
  return objCol;
}

export function dataCell({ value }) {
  const objCol = { v: value, s: { font: { sz: '10.5', bold: false } } };
  // console.log('object', objCol);
  return objCol;
}

export function dataCellSplit({ value }) {
  const objCol = { v: value,
    s: {
      font: { sz: '10.5', bold: false },
      border: {
        right: { style: 'medium', color: { rgb: '000000' } },
      },
    } };
  // console.log('object', objCol);
  return objCol;
}

export function dataArrowCell({ value, color }) {
  const objCol = {
    v: getNumberForWebdings(value),
    s: { font: { sz: '14', name: 'Webdings', color: { rgb: color.replace('#', '') } } },
  };
  // console.log('object', objCol);
  return objCol;
}

function getNumberForWebdings(simbol) {
  if (simbol === '=') {
    // -> seta para direita
    return '4';
  }
  if (simbol === '>') {
    // /\ seta para cima
    return '5';
  }
  if (simbol === '<') {
    // \/ seta para baixo
    return '6';
  }
  return '3';
}

export function getColor(ind) {
  if (ind === '=') return '#ECE101';
  if (ind === '>') return '#FF0100';
  if (ind === '<') return '#008000';
  return 'purple';
}

const minSize = (size) => size < 3 ? 3 : size;

export function fitToColumn(arrayOfArray) {
  return arrayOfArray[0].map((a, i) => ({ wch:
    Math.max(...arrayOfArray.map((a2) => a2[i] && a2[i].v ?
      minSize(a2[i].v.toString().length) : 0)) }));
}
