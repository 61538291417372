import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';

export function MultiInputSelect({
  label,
  value,
  onChange,
  name,
  data,
  nameLabel,
  valueData,
  comparevalues,
  style,
  disabled,
}) {
  return (
    <TextField
      disabled={disabled}
      variant="outlined"
      select
      label={label}
      value={value}
      onChange={onChange}
      name={name}
      style={{ margin: '0.3rem 0.2rem', ...style }}
    >
      {data.map((item) => (
        <MenuItem key={item[valueData]} value={item[valueData]}>
          {comparevalues ? item[comparevalues[0]] || item[comparevalues[1]] : item[nameLabel]}
        </MenuItem>
      ))}
    </TextField>
  );
}
