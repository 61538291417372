import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  RiBarcodeFill,
  RiFileCopyLine,
  RiTempHotLine,
  RiMenuLine,
} from 'react-icons/ri';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import BuildIcon from '@material-ui/icons/Build';
import RouterIcon from '@material-ui/icons/Router';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import AddressFields from '../../../../../../components/AddressFields';
import FockinkAPI from '../../../../../../middlewares/Api';
import BottomButtons from '../../../../../../components/Buttons/BottomButtons';
import Breadcrumbs from '../../../../../../components/Breadcrumbs';
import ConnectedAcordeon from '../../../../../../components/ConnectedAcordeon';
import RequestButton from '../../../../../../components/Buttons/RequestButton';
import AdminPermissions from '../../../../../../helpers/enums/AdminPermissions';
import Card from '../../../../../../components/Card';
import CardTitle from '../../../../../../components/CardTitle';
import CardText from '../../../../../../components/CardText';
import { UploadFileManager } from './EquipamentFileManager';
import './style.scss';
import UnlockableField from '../../../../../../components/Fields/UnlockableField';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  subTitle: {
    borderBottom: '1px solid #ccc',
    textAlign: 'center',
    paddingBottom: '0 !important',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

const activeInactive = [
  {
    value: 'A',
    name: 'Ativo',
  },
  {
    value: 'I',
    name: 'Inativo',
  },
];

export default (props) => {
  const [loading, setLoading] = React.useState(true);
  const [communicators, setComunicators] = React.useState([]);
  const [setup, setSetup] = React.useState({ setup: undefined });
  const [differentComm, setDifferentComm] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [plantType, setPlantType] = React.useState();
  const MySwal = withReactContent(Swal);
  const [equipaments, setEquipaments] = React.useState();
  const weatherStation = equipaments?.filter((x) => x.operationId === 5);
  const [auroraVision, setAuroraVision] = React.useState();

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      MySwal.fire({
        icon: 'success',
        title: 'Equipament ID',
        text: 'Texto copiado',
      });
    } catch (err) {
      MySwal.fire({
        icon: 'error',
        title: 'Equipament ID',
        text: 'Texto não copiado',
      });
    }
  };

  const equipsTypes = {
    1: 'Equipamento de Irrigação',
    2: 'Equipamento de Termometria',
    3: 'Equipamento de Automação',
    4: 'Equipamento de Fotovoltaica',
  };
  const [data, setData] = React.useState({
    description: '',
    operationId: '',
    gnf: '',
    location: {},
    integrationMode: 'Local',
    status: 'A',
    communicator: {},
    agextec: {
      active: false,
    },
    auroraVisionPlantId: '',
  });

  const breadcrumb = {
    title: `Equipamento: ${data.clientDescription || data.description}`,
    routes: [
      { to: '/organization', name: 'Organizações' },
      { to: `/organization/${props.match.params.orgId}`, name: 'Organização' },
      {
        to: `/organization/${props.match.params.orgId}/account/${props.match.params.accId}`,
        name: 'Conta',
      },
      {
        to: `/organization/${props.match.params.orgId}/account/${props.match.params.accId}/plant/${props.match.params.plId}`,
        name: 'Planta',
      },
      { name: 'Cadastro de Equipamento' },
    ],
  };
  // Init
  moment.locale('pt-br');
  React.useEffect(() => {
    if (setup.setup === false) {
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'O equipamento não pode ser verificado',
      });
    }
  }, [setup]);

  React.useEffect(() => {
    FockinkAPI.get(`/communicators?plantId=${props.match.params.plId}`).then((response) => {
      setComunicators(response.data.data);
    });
  }, []);
  React.useEffect(() => {
    if (data.communicators && data.communicators.length) {
      setDifferentComm(data.communicators[0].id !== data.communicator.id);
    }
  }, [data]);

  React.useEffect(() => {
    // const query = new URLSearchParams(props.location.search);
    // const operationId = query.get('op');

    if (props.match.params.id === 'new') {
      setLoading(false);
      FockinkAPI.get(`/plants/${props.match.params.plId}`).then((response) => {
        setData({
          ...data,
          location: response.data.data?.settings?.location || {},
        });
        setPlantType(parseInt(response.data.data.operationId, 10));
      });
      FockinkAPI.get(`/equipaments?plantId=${props.match.params.plId}`).then((response) => {
        setEquipaments(response.data.data);
      });
      FockinkAPI.get('/auroravision').then((response) => {
        setAuroraVision(response.data.data);
      });
    } else {
      setLoading(true);
      FockinkAPI.get('/auroravision').then((response) => {
        setAuroraVision(response.data.data);
      });
      FockinkAPI.get(`/equipaments?plantId=${props.match.params.plId}`).then((response) => {
        setEquipaments(response.data.data);
      });
      FockinkAPI.get(`/plants/${props.match.params.plId}`).then((response) => {
        setPlantType(parseInt(response.data.data.operationId, 10));
      });
      FockinkAPI.get(`/equipaments/${props.match.params.id}`).then((response) => {
        /* if (response.data.data.clientDescription) {
          setIsDescriptionAvailable(true);
        } */
        const dt = {
          ...response.data.data,
          location: response.data.data.settings.location || {},
          communicator: response.data.data.communicator || {},
          integrationMode: response.data.data.integration
            ? response.data.data.integration.mode
            : 'Local',
          auroraVisionPlantId: response.data.data.integration
            ? response.data.data.integration.auroraVisionPlantId
            : '',
          operationId: response.data.data.operationId,
          validFrom: moment.utc(response.data.data.agextec?.validFrom).format('YYYY-MM-DD'),
          validUntil: moment.utc(response.data.data.agextec?.validUntil).format('YYYY-MM-DD'),
          active: response.data.data.agextec?.active || false,
        };
        delete dt.settings;
        setData(dt);
        setLoading(false);
      });
    }
  }, [props.match.params.id]);
  const classes = useStyles();
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const handleChangeEquipament = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleCommunicatorChange = (event) => {
    const communicator = communicators.find((c) => c.id === event.target.value);
    setData({
      ...data,
      communicator: {
        id: communicator.id,
        deviceId: communicator.authentication.deviceId,
        description: communicator.description,
      },
    });
  };

  const handleChangeLocation = (location) => {
    setData({ ...data, location });
  };
  const saveData = () => {
    const dt = {
      data: {
        plantId: props.match.params.plId,
        description: data.description,
        clientDescription: data.clientDescription || undefined,
        gnf: data.gnf,
        operationId: parseInt(data.operationId, 10),
        integrationMode: data.integrationMode,
        auroraVisionPlantId: data.auroraVisionPlantId,
        location: data.location,
        status: data.status,
        communicator: data.communicator,
        weatherStationId: data.weatherStationId,
        agextec: {
          active: data.active || false,
          validFrom: data.validFrom,
          validUntil: data.validUntil,
        },
      },
    };
    if (!dt.data.communicator?.description && communicators.length > 0 && dt.data.communicator.id) {
      dt.data.communicator.description = communicators?.find(
        (f) => f.id === dt.data?.communicator.id,
      ).description;
    }

    if (props.match.params.id === 'new') {
      dt.method = 'post';
      dt.url = '/equipaments';
    } else {
      dt.method = 'put';
      dt.url = `/equipaments/${props.match.params.id}`;
    }
    return dt;
  };

  const deleteData = () => ({ url: `/equipaments/${props.match.params.id}` });
  if (loading) {
    return <Skeleton variant="rect" height={500} width="100%" animation="wave" />;
  }
  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1} style={{ padding: '0 24px 15px' }}>
        <Grid item xs={12}>
          <Paper square className={classes.root}>
            <Tabs
              value={tab}
              onChange={(event, newValue) => setTab(newValue)}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
            >
              <Tab icon={<BuildIcon />} label="Dados" />
              {props.match.params.id !== 'new' && (
                <Tab icon={<RouterIcon />} label="Últimos Comunicadores" />
              )}
              <Tab icon={<RouterIcon />} label="Upload de Arquivos" />
            </Tabs>
          </Paper>
        </Grid>

        {tab === 0 && (
          <>
            <Grid item xs={12} lg={4} xl={4}>
              <Card square similar>
                <CardTitle>Dados Gerais</CardTitle>
                <CardText>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={12}>
                      <TextField
                        variant="outlined"
                        name="description"
                        onChange={handleChange}
                        value={data.description || ''}
                        label="Identificação Fockink"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={12}>
                      <UnlockableField
                        name="description"
                        handleChange={handleChange}
                        value={data.description || ''}
                        label="Nome"
                        unlockText="Esse campo afetará como o usuário reconhece o equipamento"
                      />

                      {/* <TextField
                        variant="filled"
                        name="clientDescription"
                        onChange={handleChange}
                        disabled={isDescriptionAvailable}
                        value={data.clientDescription || ''}
                        label="Nome (Descrição do Cliente)"
                        inputProps={{ autocomplete: 'chrome-off' }}
                      /> */}
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={12}>
                      <FormControl variant="outlined">
                        <InputLabel id="statusLabel">Status</InputLabel>
                        <Select
                          labelId="statusLabel"
                          id="status"
                          name="status"
                          value={data.status}
                          onChange={handleChange}
                          label="Status"
                        >
                          {
                              activeInactive.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              ))
                            }

                        </Select>
                      </FormControl>
                    </Grid>

                    {plantType === 4 && (
                      <>
                        <Grid item xs={12} sm={6} md={6} lg={12}>
                          <FormControl variant="outlined">
                            <InputLabel id="fotoLabel">Tipo</InputLabel>
                            <Select
                              labelId="fotoLabel"
                              label="Tipo"
                              value={data.integrationMode}
                              onChange={handleChange}
                              name="integrationMode"
                            >
                              <MenuItem value="Local">Local</MenuItem>
                              <MenuItem value="AuroraVision">AuroraVision</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>

                        {/* <Grid item xs={12} sm={6} md={6} lg={12}>
                          {data.integrationMode === 'AuroraVision' ? (
                            <TextField
                              variant="filled"
                              name="auroraVisionPlantId"
                              onChange={handleChange}
                              value={data.auroraVisionPlantId}
                              label="ID da Planta do Aurora Vision"
                            />
                          ) : (
                            <></>
                          )}
                        </Grid> */}
                      </>
                    )}
                    <Grid item xs={12} sm={6} md={6} lg={12}>
                      <TextField
                        variant="outlined"
                        name="gnf"
                        onChange={handleChange}
                        value={data.gnf || ''}
                        label="GNF"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={12}>
                      <FormControl variant="outlined">
                        <InputLabel id="operationIdLabel">Tipo de Equipamento</InputLabel>
                        <Select
                          labelId="operationIdLabel"
                          label="Tipo de Equipamento"
                          id="operationId"
                          name="operationId"
                          value={data.operationId}
                          onChange={handleChangeEquipament}
                          startAdornment={(
                            <InputAdornment position="start">
                              <RiMenuLine size={20} />
                            </InputAdornment>
                              )}

                        >
                          <MenuItem value={plantType}>{equipsTypes[plantType]}</MenuItem>
                          <MenuItem value={5}>Estação Meteorológica</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {data.operationId !== 5 && data.operationId !== '' && (
                      <Grid item xs={12} sm={6} md={6} lg={12}>
                        {weatherStation.length > 0 ? (
                          <FormControl variant="outlined">
                            <InputLabel id="weatherStationIdLabel">Estação Meteorológica</InputLabel>
                            <Select
                              labelId="weatherStationIdLabel"
                              id="weatherStationId"
                              name="weatherStationId"
                              value={data.weatherStationId}
                              label="Estação Meteorológica"
                              onChange={handleChangeEquipament}
                              startAdornment={(
                                <InputAdornment position="start">
                                  <RiTempHotLine size={20} />
                                </InputAdornment>
                                )}
                            >

                              { weatherStation?.map((equip) => (
                                <MenuItem value={equip.id}>{equip.description}</MenuItem>
                              )) }
                            </Select>
                          </FormControl>
                        )
                          : (
                            <TextField
                              variant="outlined"
                              name="noWeatherStations"
                              value="Sem estação"
                              label="Estação Meteorológica"
                              disabled
                              startAdornment={(
                                <InputAdornment position="start">
                                  <RiBarcodeFill size={20} />
                                </InputAdornment>
                          )}
                            />
                          )}
                      </Grid>
                    )}
                    {data.integrationMode === 'AuroraVision' && (
                      <Grid item xs={12} sm={6} md={6} lg={12}>
                        <FormControl variant="outlined">
                          <InputLabel id="auroraVisionPlantId">Aurora Vision</InputLabel>
                          <Select
                            labelId="auroraVisionPlantId"
                            id="auroraVisionPlantId"
                            name="auroraVisionPlantId"
                            value={data?.auroraVisionPlantId}
                            label="Planta"
                            onChange={handleChange}
                          >
                            {auroraVision?.map((plant) => (
                              <MenuItem
                                value={plant.id}
                                disabled={plant.used}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'baseline',
                                }}
                              >
                                {plant.plantName}
                                <br />
                                {plant.used && (
                                  <h6 style={{ fontWeight: 400 }}>
                                    {plant.used && 'Usado por: '}
                                    {plant.used?.description}
                                  </h6>
                                )}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}

                    {plantType === 1 && (
                      <Grid item xs={12} sm={6} md={6} lg={12}>
                        <FormControlLabel
                          control={
                            // eslint-disable-next-line react/jsx-wrap-multilines
                            <Checkbox
                              checked={data.active}
                              name="active"
                              onChange={(e) =>
                                setData({
                                  ...data,
                                  [e.target.name]: e.target.checked,
                                })
                              }
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          }
                          label="AgexTec"
                        />
                      </Grid>
                    )}

                    {data.active && (
                      <>
                        <Grid item lg={6} xs={12}>
                          <MuiPickersUtilsProvider
                            libInstance={moment}
                            utils={MomentUtils}
                            style={{ width: '100%' }}
                          >
                            <KeyboardDatePicker
                              variant="outlined"
                              margin="normal"
                              label="Início"
                              format="DD/MM/YYYY"
                              value={data.validFrom}
                              name="validFrom"
                              autoOk
                              onChange={(date) => {
                                setData({
                                  ...data,
                                  validFrom: date.format('YYYY-MM-DD'),
                                });
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item lg={6} xs={12}>
                          <MuiPickersUtilsProvider
                            libInstance={moment}
                            utils={MomentUtils}
                            style={{ width: '100%' }}
                          >
                            <KeyboardDatePicker
                              variant="filled"
                              margin="normal"
                              label="Fim"
                              format="DD/MM/YYYY"
                              value={data.validUntil}
                              name="validUntil"
                              autoOk
                              onChange={(date) =>
                                setData({
                                  ...data,
                                  validUntil: date.format('YYYY-MM-DD'),
                                })
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardText>
              </Card>
            </Grid>

            <Grid item xs={12} lg={4} xl={4}>
              <Card square similar>
                <CardTitle>Endereço</CardTitle>
                <CardText>
                  <AddressFields handleChangeLocation={handleChangeLocation} data={data.location} />
                </CardText>
              </Card>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={7} lg={12}>
                  <Card square similar>
                    <CardTitle>Identificação e Comunicação</CardTitle>
                    <CardText>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            label="Equipament ID:"
                            name="equipamentId"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  onClick={() => copyToClipBoard(props.match.params.id)}
                                >
                                  <RiFileCopyLine size={20} />
                                </InputAdornment>
                              ),
                            }}
                            value={
                              props.match.params.id === 'new'
                                ? 'Salve para criar o ID do Equipamento'
                                : props.match.params.id
                            }
                            onClick={() => copyToClipBoard(props.match.params.id)}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl variant="outlined">
                            <InputLabel id="defaultComunicatorLabel">Comunicador Padrão</InputLabel>
                            <Tooltip
                              title={
                                differentComm
                                  ? 'Comunicador diferente do histórico da telemetria!'
                                  : ''
                              }
                              placement="top"
                            >
                              <Select
                                labelId="defaultComunicatorLabel"
                                id="defaultComunicator"
                                name="defaultComunicator"
                                value={data?.communicator.id}
                                onChange={handleCommunicatorChange}
                                label="Comunicador Padrão"
                                style={{
                                  backgroundColor: differentComm ? '#FFFF88' : '#FEFEFE',
                                }}
                              >
                                {/* Criar estrutura de repetição com os comunicadores */}
                                {communicators.map((comunicator) => (
                                  <MenuItem key={comunicator.description} value={comunicator.id}>
                                    {comunicator.description}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Tooltip>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </CardText>
                  </Card>
                </Grid>
                {props.match.params.id !== 'new' && (
                  <Grid item xs={12} sm={5} lg={12}>
                    <Card square similar>
                      <CardTitle>Verificar Equipamento</CardTitle>
                      <CardText>
                        <Grid container spacing={2}>
                          <Grid item xs={12} style={{ textAlign: 'center' }}>
                            <RequestButton
                              isDisabled={setup.setup || data.setup}
                              defaultText="Verificar"
                              disabledText="Verificado"
                              icon={!(setup.setup || data.setup) && <NotListedLocationIcon />}
                              setData={setSetup}
                              postData={{
                                url: `/equipaments/${props.match.params.id}/setup`,
                                method: 'get',
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardText>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}

        {tab === 1 && (
          <>
            <Grid item xs={12}>
              {props.match.params.id !== 'new' ? (
                <Grid item xs={12}>
                  <ConnectedAcordeon rows={data.communicators} title="Comunicadores" />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </>
        )}

        {tab === 2 && (
          <Grid item xs={12}>
            <UploadFileManager />
          </Grid>
        )}

        <Grid item xs={12} className={`${classes.textAlign} ${classes.bottomButtons}`}>
          <BottomButtons
            saveData={tab === 0 && saveData}
            deleteData={tab === 0 && props.match.params.id !== 'new' && deleteData}
            backTo={`/organization/${props.match.params.orgId}?accountId=${props.match.params.accId}&plantId=${props.match.params.plId}`}
            canSave={AdminPermissions.EQUIPAMENTS_MANAGE.value}
          />
        </Grid>
      </Grid>
    </>
  );
};
