import React, { useState } from 'react';
import colors from '../../styles/colors';

export default (props) => {
  const { stPumping, stFailPumping } = props.status;

  const text =
    stPumping === 0
      ? stFailPumping
        ? 'FALHA BOMBA'
        : 'BOMBEAMENTO DESLIGADO'
      : stFailPumping
        ? 'FALHA BOMBA'
        : 'BOMBEAMENTO LIGADO';
  const [status, setStatus] = useState({
    textColor: colors.black,
    statusColor: colors.black,
    backgroundColor: colors.white,
  });
  setTimeout(() => {
    setStatus(defineStatus());
  }, 1000);
  function defineStatus() {
    if (stFailPumping) {
      // if (status.backgroundColor === colors.white) {
      return {
        textColor: colors.white,
        statusColor: colors.error,
        backgroundColor: colors.error,
      };
      /* } else {
        return {
          textColor: colors.black,
          statusColor: colors.black,
          backgroundColor: colors.white,
        };
      } */
    }
    if (stPumping) {
      return {
        textColor: colors.white,
        statusColor: colors.pivotOk,
        backgroundColor: colors.pivotOk,
      };
    }
    return {
      textColor: colors.black,
      statusColor: colors.black,
      backgroundColor: colors.white,
    };
  }

  return (
    <svg viewBox="0 0 500 500" width="100%">
      <defs>
        <grid x="0" y="0" width="122.653" height="92.449" />
      </defs>
      <g id="BOMBA">
        <rect
          x="76.147"
          y="66.286"
          width="300"
          height="200"
          style={{ stroke: status.statusColor, strokeWidth: '12px', fill: 'none' }}
          rx="58.152"
          ry="58.152"
        />
        <rect x="381.028" y="143.268" style={{ stroke: status.statusColor, fill: status.statusColor }} width="32.045" height="51.365" />
        <rect x="402.915" y="115.488" style={{ stroke: status.statusColor, fill: status.statusColor }} width="32.045" height="106.925" />
        <rect
          x="117.509"
          y="70.4"
          width="217.48"
          height="191.798"
          style={{ fill: 'none', strokeWidth: '5px', stroke: status.statusColor }}
          rx="21.143"
          ry="21.143"
        />
        <line
          style={{ stroke: status.statusColor, strokeWidth: '8px' }}
          x1="135.954"
          y1="94.926"
          x2="315.375"
          y2="94.926"
        />
        <line
          style={{ stroke: status.statusColor, strokeWidth: '8px' }}
          x1="135.954"
          y1="237.332"
          x2="315.375"
          y2="237.332"
        />
        <line
          style={{ stroke: status.statusColor, strokeWidth: '8px' }}
          x1="135.954"
          y1="204.715"
          x2="315.375"
          y2="204.715"
        />
        <line
          style={{ stroke: status.statusColor, strokeWidth: '8px' }}
          x1="135.954"
          y1="127.934"
          x2="315.375"
          y2="127.934"
        />
        <line
          style={{ stroke: status.statusColor, strokeWidth: '8px' }}
          x1="135.954"
          y1="166.653"
          x2="315.375"
          y2="166.653"
        />
        <polygon
          style={{
            stroke: status.statusColor,
            strokeLinejoin: 'round',
            strokeLinecap: 'round',
            strokeWidth: '12px',
            fill: 'none',
          }}
          points="136.173 265.664 111.618 324.944 355.512 324.944 330.616 264.839"
        />
      </g>
      <g id="BOMBA_TEXT">
        <rect
          y="358.899"
          width="500.204"
          height="139.499"
          style={{ fill: status.backgroundColor, stroke: colors.grey }}
          rx="21.419"
          ry="21.419"
        />
        <text
          style={{
            fill: status.textColor,
            fontFamily: 'Roboto',
            fontWeight: 800,
            fontSize: '36px',
            textAnchor: 'middle',
            whiteSpace: 'pre',
          }}
          x="254.694"
          y="445.48"
        >
          {text}
        </text>
      </g>
    </svg>
  );
};
