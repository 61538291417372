import React from 'react';
import { useTheme } from 'styled-components';
import Chart from 'react-apexcharts';

export function ChartMotor({ series: seriesArr }) {
  const originalTheme = useTheme();
  const [formattedSeries, setFormattedSeries] = React.useState();
  const [categories, setCategories] = React.useState();
  const [dates, setDates] = React.useState();

  const [labelDates, setLabelDates] = React.useState();

  const fomateDataSeries = () => {
    const lastFiveDates = seriesArr.slice(0, 5);
    setDates(lastFiveDates);
    const workedHours = [];
    const textDateHours = [];

    const formattedCategories = lastFiveDates.map((categoryDate) =>
      new Date(categoryDate.ts).toLocaleDateString('pt-br'));
    setCategories(formattedCategories);

    for (const date of lastFiveDates) {
      const hoursFromDate = date.aerationDec / 3600;

      workedHours.push(hoursFromDate);
      textDateHours.push(date.aeration);
    }
    setLabelDates(textDateHours);

    const pse = [
      {
        name: 'Horas trabalhadas',
        data: workedHours,
      },
    ];

    setFormattedSeries(pse);
  };

  React.useEffect(() => {
    fomateDataSeries();
  }, []);

  const options = {
    colors: [originalTheme.colors.chartBlue],
    chart: {
      zoom: {
        enabled: false,
      },
      selection: {
        enabled: false,
      },
      parentHeightOffset: 0,
      type: 'line',
      legend: {
        display: false,
      },
      toolbar: {
        show: false,
      },
      width: 50,
      id: 'basic-bar',
      dropShadow: {
        enabled: true,
        top: 0,
        left: 0,
        blur: 3,
        opacity: 0.15,
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false,
    },
    stroke: {
      curve: 'smooth',
      width: 4,
    },
    xaxis: {
      categories,
      labels: {
        show: false,
      },
    },
    axisBorder: {
      show: false,
    },

    yaxis: {
      show: false,
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 4000,
        options: {
          chart: {
            width: '100%',
            height: '50%',
          },
        },
      },
      {
        breakpoint: 800,
        options: {
          chart: {
            width: '100%',
            height: '100px',
          },
        },
      },
    ],
    tooltip: {
      enabled: true,

      custom({ series, dataPointIndex }) {
        if (series) {
          return (
            `${
              `${
                '<div class="arrow_box customTool">' +
                '<strong style={{ display: "block" }}>'
              }${new Date(dates[dataPointIndex].ts).toLocaleString(
                'pt-br',
              )}</strong>` +
              '<br />' +
              '<span>' +
              'Horas trabalhadas' +
              ': '
            }${labelDates[dataPointIndex]}</span>` + '</div>'
          );
        }
        return '<div></div>';
      },
      x: {
        show: true,
        format: 'dd/MM/yyyy HH:mm',
      },
    },
  };

  return (
    <div style={{ borderRadius: '10px !important', display: 'flex' }}>
      {formattedSeries && (
        <Chart
          options={options}
          series={formattedSeries}
          type="bar"
          width="100%"
        />
      )}
    </div>
  );
}
