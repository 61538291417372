export function startDispositionsSensorsY(siloType, cuttingPositions, zPosition) {
  /* ***********************************************************************
    Esta função referencia em pixels onde irá iniciar a
    disposição dos sensores dentro do termovisor no eixo Y
  *********************************************************************** */
  const startDistribuition = [];
  startDistribuition.pop();

  if (!siloType) { // caso não tenha sido passado o tipo de fundo
    siloType = 'plainArm'; // seta um tipo de fundo padrão
  }

  if ((siloType === 'plainArm') || (siloType === 'R1Arm') || (siloType === 'plainTestArm')) {
    return 0;
  }

  if (siloType === 'semiVArm' || siloType === 'SR1Arm') {
    // return (indexCable === firstCable || indexCable === lastCable ? 13 : 60);
    switch (cuttingPositions.length) {
      case 4:
        startDistribuition.push(70, 110, 110, 70);
        break;
      case 5:
        startDistribuition.push(50, 100, 110, 110, 85);
        break;
      case 6:
        startDistribuition.push(50, 95, 115, 115, 115, 75);
        break;
      case 7:
        startDistribuition.push(13, 60, 60, 60, 60, 60, 13);
        break;
      case 8:
        startDistribuition.push(48, 80, 110, 110, 110, 110, 90, 55); // agora é aqui
        break;
      case 9:
        startDistribuition.push(13, 60, 60, 60, 60, 60, 60, 60, 13);
        break;
      case 10:
        startDistribuition.push(48, 75, 100, 115, 115, 115, 115, 115, 90, 60);
        break;
      case 11:
        startDistribuition.push(13, 45, 80, 80, 80, 80, 80, 80, 70, 40, 13);
        break;
      case 12:
        startDistribuition.push(17, 45, 70, 85, 85, 85, 85, 85, 85, 66, 35, 16);
        break;
      default:
        return 0;
    }
  }

  if (siloType === 'VArm') {
    // return (indexCable === firstCable || indexCable === lastCable ? 15 : 10);
    switch (cuttingPositions.length) {
      case 5:
        startDistribuition.push(7, 65, 125, 110, 45);
        break;
      case 6:
        startDistribuition.push(8, 50, 95, 120, 40, 15);
        break;
      case 7:
        startDistribuition.push(8, 50, 95, 130, 120, 40, 15);
        break;
      case 8:
        startDistribuition.push(5, 45, 80, 120, 140, 100, 60, 25);
        break;
      case 10:
        startDistribuition.push(6, 35, 70, 100, 130, 140, 110, 80, 50, 15);
        break;
      default:
        return 0;
    }
  }

  if (siloType === 'WArm') {
    // return (indexCable === firstCable || indexCable === lastCable ? 70 : 70);
    switch (cuttingPositions.length) {
      case 5:
        startDistribuition.push(30, 30, 30, 30, 25);
        break;
      case 6:
        startDistribuition.push(30, 30, 30, 30, 30, 25);
        break;
      case 7:
        startDistribuition.push(30, 30, 30, 30, 30, 30, 25);
        break;
      case 8:
        startDistribuition.push(30, 110, 90, 10, 30, 130, 80, 15);
        break;
      case 9:
        startDistribuition.push(30, 30, 30, 30, 30, 30, 30, 30, 25);
        break;
      case 10:
        startDistribuition.push(30, 30, 30, 30, 30, 30, 30, 30, 30, 25);
        break;
      case 11:
        startDistribuition.push(30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 25);
        break;
      case 12:
        startDistribuition.push(30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 25);
        break;
      default:
        return 0;
    }
  }

  // Retorno
  return (startDistribuition[cuttingPositions.findIndex((x) => (x === zPosition))]);
}

export function calcTempPositionY(siloType, index, sensorsInCableQtd, cableQtd, cableIndex) {
  /* ***********************************************************************
    Esta função referencia em pixels o espaço útil para efetuar a
    distribuição dos sensores no eixo Y e retorna a posição dentro deste
    espaço que o mesmo irá ser posicionado
  *********************************************************************** */
  let cableSpacing;

  if (!siloType) { // caso não tenha sido passado o tipo de fundo
    siloType = 'plainArm'; // seta um tipo de fundo padrão
  }

  if ((siloType === 'plainArm') || (siloType === 'R1Arm') || (siloType === 'plainTestArm')) {
    cableSpacing = {
      1: [380],
      2: [340, 340],
      3: [170, 215, 170],
      4: [210, 340, 340, 210],
      5: [170, 215, 260, 215, 170],
      6: [200, 300, 380, 360, 300, 200],
      7: [200, 300, 380, 360, 360, 300, 200],
      8: [190, 250, 310, 385, 430, 320, 250, 180], // ok 20220721
      9: [170, 230, 300, 380, 400, 350, 300, 230, 170],
      10: [170, 230, 300, 340, 380, 400, 350, 300, 230, 170],
      11: [170, 230, 300, 340, 380, 400, 380, 350, 300, 230, 170],
      12: [165, 205, 250, 285, 330, 365, 368, 320, 285, 250, 210, 160],
      13: [160, 195, 225, 270, 300, 345, 372, 338, 300, 265, 230, 190, 160],
      15: [160, 185, 225, 270, 300, 340, 380, 420, 380, 340, 280, 260, 210, 193, 160],
      16: [160, 185, 225, 270, 300, 340, 380, 380, 420, 380, 340, 280, 260, 210, 193, 160],
      17: [160, 185, 225, 270, 300, 340, 380, 380, 380, 420, 380, 340, 280, 260, 210, 193, 160],
      18: [160,
        185, 225, 270, 300, 340, 380, 380, 380, 380, 420, 380, 340, 280, 260, 210, 193, 160],
      19: [160,
        185, 225, 270, 300, 340, 380, 380, 380, 380, 380, 420, 380, 340, 280, 260, 210, 193, 160],
      20: [160,
        185,
        225, 270, 300, 340, 380, 380, 380, 380, 380, 380, 420, 380, 340, 280, 260, 210, 193, 160],
      21: [160,
        185,
        225,
        270, 300, 340, 380, 380, 380, 380, 380, 380, 380, 420, 380, 340, 280, 260, 210, 193, 160],
      22: [160,
        185,
        225,
        270,
        300,
        340, 380, 380, 380, 380, 380, 380, 380, 380, 420, 380, 340, 280, 260, 210, 193, 160],
      23: [160,
        185,
        225,
        270,
        300,
        340,
        380, 380, 380, 380, 380, 380, 380, 380, 380, 420, 380, 340, 280, 260, 210, 193, 160],
      24: [160,
        185,
        225,
        270,
        300,
        340,
        380,
        380, 380, 380, 380, 380, 380, 380, 380, 380, 420, 380, 340, 280, 260, 210, 193, 160],
      25: [160,
        185,
        190,
        200,
        230,
        280,
        275,
        305,
        310,
        330, 350, 380, 380, 380, 350, 322, 310, 280, 268, 258, 230, 210, 195, 193, 160],
    };
  }

  if (siloType === 'semiVArm' || siloType === 'SR1Arm') {
    cableSpacing = {
      1: [400],
      2: [340, 340],
      3: [210, 340, 210],
      4: [240, 430, 430, 240],
      5: [170, 320, 430, 380, 270],
      6: [175, 305, 402, 400, 375, 270],
      7: [220, 320, 420, 400, 400, 310, 190],
      8: [170, 260, 345, 400, 420, 360, 290, 200],
      9: [210, 290, 365, 420, 420, 400, 350, 290, 200],
      10: [170, 238, 310, 370, 420, 430, 380, 340, 270, 195],
      12: [160, 220, 300, 360, 395, 450, 435, 360, 330, 280, 210, 160], // ok 22/07
    };
  }

  if (siloType === 'VArm') {
    cableSpacing = {
      4: [230, 450, 450, 300],
      5: [180, 320, 470, 440, 300],
      6: [200, 340, 380, 380, 340, 200],
      7: [180, 280, 400, 496, 485, 330, 245],
      8: [170, 270, 360, 455, 490, 400, 310, 230],
      9: [170, 230, 300, 380, 400, 350, 300, 230, 170],
      10: [190, 250, 330, 405, 480, 490, 420, 355, 280, 220], // agora é aqui
      11: [170, 230, 300, 340, 380, 400, 380, 350, 300, 230, 170],
      12: [170, 230, 300, 340, 380, 400, 380, 350, 300, 230, 170, 170],
      13: [160, 195, 300, 340, 380, 400, 430, 400, 370, 330, 280, 290, 160],
      14: [160, 195, 300, 340, 380, 400, 430, 400, 370, 330, 280, 290, 160, 160],
      15: [160, 195, 300, 340, 380, 400, 430, 430, 400, 370, 330, 280, 290, 195, 160],
    };
  }

  if (siloType === 'WArm') {
    cableSpacing = {
      4: [100, 410, 410, 260],
      5: [100, 410, 410, 410, 260],
      6: [100, 340, 380, 380, 340, 200],
      8: [240, 360, 400, 380, 380, 420, 310, 200],
      9: [170, 230, 300, 380, 400, 350, 300, 230, 170],
      10: [170, 230, 300, 340, 380, 400, 350, 300, 230, 170],
      11: [170, 230, 300, 340, 380, 400, 380, 350, 300, 230, 170],
      12: [170, 230, 300, 340, 380, 400, 380, 350, 300, 230, 170, 170],
      13: [160, 195, 300, 340, 380, 400, 430, 400, 370, 330, 280, 290, 160],
      14: [160, 195, 300, 340, 380, 400, 430, 400, 370, 330, 280, 290, 160, 160],
      15: [160, 195, 300, 340, 380, 400, 430, 430, 400, 370, 330, 280, 290, 195, 160],
    };
  }

  const spacing = cableSpacing[cableQtd].map((t) => 380 - t);

  return (
    (cableSpacing[cableQtd][cableIndex] / sensorsInCableQtd) *
      (sensorsInCableQtd - index) +
    spacing[cableIndex]
  );
}

export function heatmapPositionY(siloType, index, sensorsInCableQtd, cableQtd, cableIndex) {
  /* ***********************************************************************
    Esta função referencia em pixels o distânciamento entre focos de calor
    para o eixo Y
  *********************************************************************** */
  let cableSpacing;

  if (!siloType) { // caso não tenha sido passado o tipo de fundo
    siloType = 'plainArm'; // seta um tipo de fundo padrão
  }

  if ((siloType === 'plainArm') || (siloType === 'R1Arm') || (siloType === 'plainTestArm')) {
    cableSpacing = {
      2: [75, 75],
      3: [75, 90, 75],
      4: [45, 75, 75, 45],
      5: [45, 75, 90, 75, 45],
      6: [44, 64, 82, 76, 64, 42],
      7: [35, 80, 90, 100, 90, 80, 35],
      8: [41, 53.5, 67, 82.5, 92, 68.5, 54, 38.5], // ok 20220721 // foco de calor
      9: [35, 80, 90, 100, 100, 100, 90, 80, 35],
      10: [35, 80, 90, 100, 100, 100, 100, 90, 80, 35],
      11: [35, 80, 90, 100, 100, 100, 100, 100, 90, 80, 35],
      12: [35, 44.5, 54, 61, 71, 79, 79, 68, 61, 54, 45, 33.5],
      13: [33.5, 40.5, 47, 58.5, 64, 75, 80, 73, 64, 57, 50, 40.5, 33.5],
      14: [35, 80, 90, 100, 100, 100, 100, 100, 100, 100, 100, 90, 80, 35],
      15: [34, 40, 47.5, 58, 63.5, 73, 82, 90, 82, 73, 60, 56, 44.5, 41.5, 34],
      16: [34, 40, 47.5, 58, 63.5, 73, 82, 82, 90, 82, 73, 60, 56, 44.5, 41.5, 34],
      17: [34, 40, 47.5, 58, 63.5, 73, 82, 82, 82, 90, 82, 73, 60, 56, 44.5, 41.5, 34],
      18: [34, 40, 47.5, 58, 63.5, 73, 82, 82, 82, 82, 90, 82, 73, 60, 56, 44.5, 41.5, 34],
      19: [34, 40, 47.5, 58, 63.5, 73, 82, 82, 82, 82, 82, 90, 82, 73, 60, 56, 44.5, 41.5, 34],
      20: [34, 40, 47.5, 58, 63.5, 73, 82, 82, 82, 82, 82, 82, 90, 82, 73, 60, 56, 44.5, 41.5, 34],
      21: [34,
        40, 47.5, 58, 63.5, 73, 82, 82, 82, 82, 82, 82, 82, 90, 82, 73, 60, 56, 44.5, 41.5, 34],
      22: [34,
        40,
        47.5, 58, 63.5, 73, 82, 82, 82, 82, 82, 82, 82, 82, 90, 82, 73, 60, 56, 44.5, 41.5, 34],
      23: [34,
        40,
        47.5,
        58, 63.5, 73, 82, 82, 82, 82, 82, 82, 82, 82, 82, 90, 82, 73, 60, 56, 44.5, 41.5, 34],
      24: [34,
        40,
        47.5,
        58,
        63.5, 73, 82, 82, 82, 82, 82, 82, 82, 82, 82, 82, 90, 82, 73, 60, 56, 44.5, 41.5, 34],
      25: [34,
        40,
        40,
        43,
        49.5,
        60, 59, 65.5, 67, 71, 75, 82, 82, 82, 75, 69, 67, 60, 58, 55, 49.5, 45, 42, 41.5, 34],
    };
  }

  if (siloType === 'semiVArm' || siloType === 'SR1Arm') {
    cableSpacing = {
      2: [75, 75],
      3: [45, 75, 45],
      4: [49, 88, 88, 49],
      5: [45, 70, 90, 70, 45],
      6: [36, 63, 83, 83, 77, 55],
      7: [45, 70, 85, 85, 85, 70, 41.5],
      8: [35, 53, 70, 85.5, 85, 71.5, 60, 41],
      9: [35, 45, 70, 90, 100, 90, 70, 45, 35],
      10: [36.5, 50, 67, 75, 85.5, 79, 71.5, 63, 45.5, 32],
      12: [32.5, 45, 61.5, 73, 81, 92, 89, 73, 67, 57, 43, 33.5], // ok 22/07
      13: [35, 80, 90, 100, 100, 100, 100, 100, 100, 100, 90, 80, 35],
      14: [35, 80, 90, 100, 100, 100, 100, 100, 100, 100, 100, 90, 80, 35],
      15: [35, 80, 90, 100, 100, 100, 100, 100, 100, 100, 100, 100, 90, 78, 35],
    };
  }
  //  6: [45, 70, 85, 85, 70, 41.5],

  if (siloType === 'VArm') {
    cableSpacing = {
      4: [50, 80, 80, 50],
      5: [35, 69, 89, 84, 57],
      6: [35, 80, 90, 90, 80, 35],
      7: [36, 53, 76, 93, 90, 63, 42],
      8: [36, 53, 69, 88, 93, 76, 57, 42],
      9: [35, 80, 90, 100, 100, 100, 90, 80, 35],
      10: [39, 49, 62.5, 78, 92, 90, 79, 68, 53, 40],
      11: [35, 80, 90, 100, 100, 100, 100, 100, 90, 80, 35],
      12: [35, 80, 90, 100, 100, 100, 100, 100, 100, 90, 80, 35],
      13: [35, 80, 90, 100, 100, 100, 100, 100, 100, 100, 90, 80, 35],
      14: [35, 80, 90, 100, 100, 100, 100, 100, 100, 100, 100, 90, 80, 35],
      15: [35, 80, 90, 100, 100, 100, 100, 100, 100, 100, 100, 100, 90, 78, 35],
    };
  }

  if (siloType === 'WArm') {
    cableSpacing = {
      4: [50, 80, 80, 50],
      5: [50, 80, 80, 80, 50],
      6: [35, 80, 90, 90, 80, 35],
      7: [35, 80, 90, 90, 90, 80, 35],
      8: [46, 68, 76, 74, 73, 79, 60, 39], // agora é aqui
      9: [35, 80, 90, 100, 100, 100, 90, 80, 35],
      10: [35, 80, 90, 100, 100, 100, 100, 90, 80, 35],
      11: [35, 80, 90, 100, 100, 100, 100, 100, 90, 80, 35],
      12: [35, 80, 90, 100, 100, 100, 100, 100, 100, 90, 80, 35],
      13: [35, 80, 90, 100, 100, 100, 100, 100, 100, 100, 90, 80, 35],
      14: [35, 80, 90, 100, 100, 100, 100, 100, 100, 100, 100, 90, 80, 35],
      15: [35, 80, 90, 100, 100, 100, 100, 100, 100, 100, 100, 100, 90, 80, 35],
    };
  }

  const spacing = cableSpacing[cableQtd].map((t) => 132 - t);

  return (
    (cableSpacing[cableQtd][cableIndex] / sensorsInCableQtd) *
        (sensorsInCableQtd - index) +
      spacing[cableIndex]
  );
}

export function startDispositionsHeatMapY(siloType, cuttingPositions, zPosition) {
  /* ***********************************************************************
    Esta função referencia em pixels onde irá iniciar a
    disposição do heatmap no eixo Y
  *********************************************************************** */
  const startDistribuition = [];
  startDistribuition.pop();

  if (!siloType) { // caso não tenha sido passado o tipo de fundo
    siloType = 'plainArm'; // seta um tipo de fundo padrão
  }

  if ((siloType === 'plainArm') || (siloType === 'R1Arm') || (siloType === 'plainTestArm')) {
    return 2.5;
  }

  if (siloType === 'semiVArm' || siloType === 'SR1Arm') {
    // return (indexCable === firstCable || indexCable === lastCable ? 13 : 60);
    switch (cuttingPositions.length) {
      case 4:
        startDistribuition.push(9, 3, 3, 9);
        break;
      case 5:
        startDistribuition.push(12.5, 4.5, 3, 3, 7);
        break;
      case 6:
        startDistribuition.push(12, 5, 2, 2, 2, 8.5);
        break;
      case 7:
        startDistribuition.push(2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5);
        break;
      case 8:
        startDistribuition.push(13, 8, 3.5, 3.5, 3.5, 3.5, 6, 11.5); // ok
        break;
      case 9:
        startDistribuition.push(2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5);
        break;
      case 10:
        startDistribuition.push(11, 8, 4, 4, 4, 4, 4, 4, 9, 12.5);
        break;
      case 11:
        startDistribuition.push(2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5);
        break;
      case 12:
        startDistribuition.push(12.8, 8.5, 4.7, 2.5, 2.5, 2.5, 2.5, 2.5, 2.5, 5.5, 10, 12.8);
        break;
      default:
        return 0;
    }
  }

  if (siloType === 'VArm') {
    // return (indexCable === firstCable || indexCable === lastCable ? 15 : 10);
    switch (cuttingPositions.length) {
      case 5:
        startDistribuition.push(23.5, 10, 7, 9, 18.5);
        // altura de início do posicionamento do cabo
        break;
      case 6:
        startDistribuition.push(22.5, 18, 11.5, 13, 20, 23.5);
        // altura de início do posicionamento do cabo
        break;
      case 7:
        startDistribuition.push(22.5, 18, 10.5, 7, 8, 19, 23.5); // Aqui ó
        // altura de início do posicionamento do cabo
        break;
      case 8:
        startDistribuition.push(22.5, 18, 13.5, 7, 5, 10, 17, 22);
        // altura de início do posicionamento do cabo
        break;
      case 10:
        startDistribuition.push(23, 19, 15, 10, 6, 6, 9, 13, 17.5, 23);
        break;
      default:
        return 0;
    }
  }

  if (siloType === 'WArm') {
    // return (indexCable === firstCable || indexCable === lastCable ? 70 : 70);
    switch (cuttingPositions.length) {
      case 5:
        startDistribuition.push(10.5, 10.5, 10.5, 10.5, 10.5);
        break;
      case 6:
        startDistribuition.push(10.5, 10.5, 10.5, 10.5, 10.5, 10.5);
        break;
      case 7:
        startDistribuition.push(10.5, 10.5, 10.5, 10.5, 10.5, 10.5, 10.5);
        break;
      case 8:
        startDistribuition.push(20.5, 9, 12, 22, 20, 6, 13, 22); // mapa de calor
        break;
      case 9:
        startDistribuition.push(10.5, 10.5, 10.5, 10.5, 10.5, 10.5, 10.5, 10.5, 10.5);
        break;
      case 10:
        startDistribuition.push(10.5, 10.5, 10.5, 10.5, 10.5, 10.5, 10.5, 10.5, 10.5, 10.5);
        break;
      case 11:
        startDistribuition.push(10.5, 10.5, 10.5, 10.5, 10.5, 10.5, 10.5, 10.5, 10.5, 10.5, 10.5);
        break;
      case 12:
        startDistribuition.push(10.5, 10.5, 10.5, 10.5, 10.5, 10.5,
          10.5, 10.5, 10.5, 10.5, 10.5, 10.5);
        break;
      default:
        return 0;
    }
  }

  // Retorno
  return (startDistribuition[cuttingPositions.findIndex((x) => (x === zPosition))]);
}
