import styled from 'styled-components';

export const Wrapper = styled.div`

.button-new {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.8rem;
  }
`;
