import React from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import GridContainerInput from '../GridInputContainer';

export default function GridDatePicker(props) {
  const {
    handleChangeDate, isDisable, value, label,
  } = props;

  return (
    <GridContainerInput>
      <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ with: '100%' }}>
        <KeyboardDatePicker
          disabled={isDisable}
          label={label}
          format="dd/MM/yyyy"
          value={value}
          name="expirationDate"
          onChange={(date) => handleChangeDate(date)}
        />
      </MuiPickersUtilsProvider>
    </GridContainerInput>
  );
}
