export function dateNow() {
  return new Date().toISOString().substring(0, 10).replace(/^(\d{4})-(\d{1,2})-(\d{1,2})$/, '$3/$2/$1');
}

export function dateForExport() {
  return new Date().toISOString().substring(0, 10).replace(/^(\d{4})-(\d{1,2})-(\d{1,2})$/, '$1-$2-$3');
}

export function timeNow() {
  const today = new Date();
  const h = today.getHours() > 9 ? today.getHours() : `0${today.getHours()}`;
  const m = today.getMinutes() > 9 ? today.getMinutes() : `0${today.getMinutes()}`;
  const s = today.getSeconds() > 9 ? today.getSeconds() : `0${today.getSeconds()}`;
  return (`${h}:${m}:${s}`);
}
