import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Table = styled.table`
  overflow-x: auto;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  font-size: 0.8rem;
  border-collapse: collapse;
  margin-bottom: 2.5rem;
  display: block;

  tr, td, th {
    border: 1px solid black;
  }

  td {
    min-width: 2.5rem;
  }

  @media (min-width: 1650px) {
    td {
      min-width: 3rem;
    }
  }
`;

export const Subtitle = styled.div`
  display: flex;
  max-width: 50%;


>  div {
    display: flex;
    flex-direction: column;
    padding-right: 14px;
    border-right: 1px solid white;
    margin-right: 14px;
  }

  strong {
    font-size: 1.1rem;
    font-weight: normal;
    margin-bottom: 0.5rem;
  }

  span {
    font-size: 0.9rem;
    line-height: 1.1rem;
  }

`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  background: #3d5059;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.4rem;
  color: #FFF;
  align-items: center;
  justify-content: space-between;
`;

export const Td = styled.td`
  background:  ${(props) => props.color};
  font-weight: ${(props) => props.isLegend ? 'bold' : 'normal'};
  width: ${(props) => props.isLegend ? '200px' : '30px'};

  color: #000
`;
