import styled from 'styled-components';

export const Wrapper = styled.div`

.confirm-button {
   display: flex;
   justify-content: flex-end;
  }
`;

export const InfoCard = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 1rem;
  border: 1px solid #c53030;
  background: #c530302b;
  border-radius: 3px;
  margin-bottom: 1rem;

  strong {
    display: block;
    margin-bottom: 0.6rem;
  }
`;
