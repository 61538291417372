import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  RiFileCopyLine,
} from 'react-icons/ri';
import AddressFields from '../../../../../components/AddressFields';
import FockinkAPI from '../../../../../middlewares/Api';
import UnlockableField from '../../../../../components/Fields/UnlockableField';
import BottomButtons from '../../../../../components/Buttons/BottomButtons';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import Card from '../../../../../components/Card';
import CardTitle from '../../../../../components/CardTitle';
import CardText from '../../../../../components/CardText';
import Spacer from '../../../../../components/Spacer';
import AdminPermissions from '../../../../../helpers/enums/AdminPermissions';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  subTitle: {
    borderBottom: '1px solid #ccc',
    textAlign: 'center',
    paddingBottom: '0 !important',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

export default (props) => {
  const [loading, setLoading] = React.useState(true);
  const MySwal = withReactContent(Swal);
  const [systemIds, setSystemIds] = React.useState();
  const [systemAlert, setSystemAlert] = React.useState(false);
  const [data, setData] = React.useState({
    description: '',
    technicalDescription: '',
    operationId: '',
    // alias: '',
    location: {},
    status: 'A',
  });

  const activeInactive = [
    {
      value: 'A',
      name: 'Ativo',
    },
    {
      value: 'I',
      name: 'Inativo',
    },
  ];

  const breadcrumb = {
    title: `Planta: ${data.technicalDescription}`,
    routes: [
      { to: '/organization', name: 'Organizações' },
      { to: `/organization/${props.match.params.orgId}`, name: 'Organização' },
      {
        to: `/organization/${props.match.params.orgId}/account/${props.match.params.accId}`,
        name: 'Conta',
      },
      { name: 'Cadastro de Planta' },
    ],
  };

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      MySwal.fire({
        icon: 'success',
        title: 'Plant ID',
        text: 'Texto copiado',
      });
    } catch (err) {
      MySwal.fire({
        icon: 'error',
        title: 'Plant ID',
        text: 'Texto não copiado',
      });
    }
  };
  React.useEffect(() => {
    FockinkAPI.get(`/accounts/${props.match.params.accId}`).then((response) => {
      setSystemIds(response.data.data.systemIds);
    });
  }, []);
  React.useEffect(() => {
    if (!systemIds?.includes(parseInt(data.operationId, 10)) && data.operationId > 0 && typeof data.operationId === 'string') {
      setSystemAlert(true);
    } else {
      setSystemAlert(false);
    }
  }, [data]);

  React.useEffect(() => {
    if (props.match.params.id === 'new') {
      setData({
        description: '',
        technicalDescription: '',
        operationId: '',
        // alias: '',
        location: {},
        status: 'A',
      });
      setLoading(false);
    } else {
      setLoading(true);
      FockinkAPI.get(`/plants/${props.match.params.id}`).then((response) => {
        setData({
          ...response.data.data,
          location: (response.data.data.settings || {}).location || {},
          activationDate: new Date(response.data.data.activationDate * 1000),
          expirationDate: new Date(response.data.data.expirationDate * 1000),
        });
        setLoading(false);
      });
    }
  }, [props.match.params.id]);

  const classes = useStyles();

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleChangeLocation = (location) => {
    setData({ ...data, location });
  };

  const throwWarning = (text) =>
    MySwal.fire({
      title: text,
    });

  const checkValidation = () => {
    // if (!data.description || data.description.length > 60) {
    //   throwWarning('Nome deve ter entre 1 e 60 caracteres');
    //   return false;
    // }

    if (!data.technicalDescription || data.technicalDescription.length > 120) {
      throwWarning('Descrição Técnica deve ter entre 1 e 120 caracteres');
      return false;
    }

    if (!data.location.address1 || data.location.address1.length > 60) {
      throwWarning('Endereço 1 deve ter entre 1 e 60 caracteres');
      return false;
    }

    const tests = [
      { text: 'CEP', input: 'postalCode' },
      { text: 'Cidade', input: 'city' },
      { text: 'Estado', input: 'state' },
      { text: 'Pais', input: 'country' },
      { text: 'Latitude', input: 'lat' },
      { text: 'Longitude', input: 'lon' },
    ]
      .map((e) => {
        if (!data.location[e.input]) {
          throwWarning(`${e.text} deve ser preenchido`);
          return false;
        }
        return true;
      })
      .reduce((a, b) => a && b);

    if (!tests) {
      return false;
    }

    return true;
  };

  const saveData = () => {
    const valid = checkValidation();
    if (valid) {
      const dt = {
        data: {
          accountId: props.match.params.accId,
          description: data.description || undefined,
          technicalDescription: data.technicalDescription,
          operationId: parseInt(data.operationId, 10),
          // alias: data.alias,
          location: data.location,
          status: data.status,
        },
      };

      if (props.match.params.id === 'new') {
        dt.method = 'post';
        dt.url = '/plants';
      } else {
        dt.method = 'put';
        dt.url = `/plants/${props.match.params.id}`;
      }
      return dt;
    }
    return undefined;
  };

  const deleteData = () => ({ url: `/plants/${props.match.params.id}` });
  if (loading) {
    return (
      <Skeleton variant="rect" height={500} width="100%" animation="wave" />
    );
  }

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardTitle>
              Identificação
              <Spacer />
            </CardTitle>
            <CardText>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="outlined"
                    name="technicalDescription"
                    onChange={handleChange}
                    value={data.technicalDescription || ''}
                    label="Identificação Fockink"
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <UnlockableField
                    name="description"
                    handleChange={handleChange}
                    value={data.description || ''}
                    label="Nome"
                    unlockText="Esse campo afetará como o usuário reconhece o equipamento"
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={6}>
                  <FormControl variant="outlined" color="secondary">
                    <InputLabel id="operationIdLabel">Sistema</InputLabel>
                    <Select
                      value={data.operationId}
                      onChange={handleChange}
                      name="operationId"
                      id="operationId"
                      labelId="operationIdLabel"
                      label="Sistema"
                    >
                      <MenuItem value="1">Irrigação</MenuItem>
                      <MenuItem value="2">Termometria</MenuItem>
                      <MenuItem value="3">Automação Industrial</MenuItem>
                      <MenuItem value="4">Fotovoltaica</MenuItem>
                      <MenuItem value="5">Estação Meteorológica</MenuItem>
                    </Select>
                  </FormControl>
                  {systemAlert && (
                  <Alert variant="filled" severity="warning" style={{ marginTop: 5 }}>
                    O cliente não possui acesso a esse sistema!
                  </Alert>
                  ) }
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControl variant="outlined">
                    <InputLabel id="statusLabel">Status</InputLabel>
                    <Select
                      labelId="statusLabel"
                      id="status"
                      name="status"
                      value={data.status}
                      onChange={handleChange}
                      label="Status"
                    >
                      {
                              activeInactive.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              ))
                            }

                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardText>
          </Card>
        </Grid>

        {props.match.params.id !== 'new' && (
          <>
            <Grid item xs={12}>
              <Card>
                <CardTitle>
                  Identificação
                  <Spacer />
                </CardTitle>
                <CardText>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                      variant="outlined"
                      name="plantId"
                      value={props.match.params.id}
                      label="Plant ID"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            onClick={() => copyToClipBoard(props.match.params.id)}
                          >
                            <RiFileCopyLine size={20} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </CardText>
              </Card>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Card>
            <CardTitle>
              Endereço
              <Spacer />
            </CardTitle>
            <CardText>
              <AddressFields
                handleChangeLocation={handleChangeLocation}
                data={data.location}
              />
            </CardText>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          className={`${classes.textAlign} ${classes.bottomButtons}`}
        >
          <BottomButtons
            saveData={saveData}
            deleteData={props.match.params.id !== 'new' && deleteData}
            backTo={`/organization/${props.match.params.orgId}?accountId=${props.match.params.accId}`}
            canSave={AdminPermissions.PLANTS_MANAGE.value}
          />
        </Grid>
      </Grid>
    </>
  );
};
