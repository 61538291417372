import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../App.css';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import BusinessIcon from '@material-ui/icons/Business';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';
import FockinkAPI from '../../../middlewares/Api';
import SaveButton from '../../../components/Buttons/SaveButton';
import BackButton from '../../../components/Buttons/BackButton';
import AddressFields from '../../../components/AddressFields';
import Breadcrumbs from '../../../components/Breadcrumbs';
import NewButton from '../../../components/Buttons/NewButton';
import AdminPermissions from '../../../helpers/enums/AdminPermissions';
import { hasPermission } from '../../../helpers/permission';
import { formatDate } from '../../../helpers/Intl';
import Account from './account/Account';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  subTitle: {
    borderBottom: '1px solid #ccc',
    textAlign: 'center',
    paddingBottom: '0 !important',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

export default (props) => {
  const [returnToList, setReturnToList] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [tab, setTab] = React.useState(0);
  const [data, setData] = React.useState({
    status: 'A',
    operationId: '1',
    client: {},
    integrationMode: 'Local',
    plantId: '',
    location: {},
    unit: {},
  });

  const breadcrumb = {
    title: 'Cadastro de Organização',
    routes: [
      { to: '/organization', name: 'Organizações' },
      { name: 'Cadastro' },
    ],
  };

  React.useEffect(() => {
    setReturnToList(false);
    if (props.match.params.id === 'new') {
      setData({
        description: '',
        corporateName: '',
        document: '',
        status: 'I',
        location: {},
        plan: {},
      });
    } else {
      setLoading(true);
      FockinkAPI.get(`/organizations/${props.match.params.id}`).then(
        (response) => {
          setData({
            ...response.data.data,
          });
          setLoading(false);
        },
      );
    }
  }, [props.match.params.id]);

  const classes = useStyles();

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleChangeLocation = (location) => {
    setData({ ...data, location });
  };

  const handleChangePlan = (event) => {
    const dataAdd = data.plan || {};
    dataAdd[event.target.name] = event.target.value;
    setData({ ...data, plan: dataAdd });
  };

  const save = () => {
    const sendData = {
      description: data.description,
      corporateName: data.corporateName,
      document: data.document,
      planId: data.plan.planId,
      status: data.status,
      location: data.location,
      isFockinkOrg: !!data.isFockinkOrg,
    };
    if (props.match.params.id === 'new') {
      FockinkAPI.post('/organizations', sendData).then(() => {
        setReturnToList(true);
      });
    } else {
      FockinkAPI.put(`/organizations/${props.match.params.id}`, sendData).then(
        () => {
          setReturnToList(true);
        },
      );
    }
  };
  const downloadCsv = async () => {
    const sys = {
      1: 'Irrigação',
      2: 'Termometria',
      3: 'Automação',
      4: 'Fotovoltaica',
    };

    const allData = await FockinkAPI.get(
      `/accounts/?organizationId=${props.match.params.id}&withEquip=true`,
    );

    let csvFile = 'data:text/csv;charset=utf-8,\uFEFF';
    const csvArray = [
      [
        'Nome da Conta',
        'Plano',
        'Status Conta',
        'Limite de Usuários',
        'Ativação',
        'Expiração',
        'Identificação Fockink (Planta)',
        'Nome da Planta',
        'Sistema',
        'Endereço',
        'Status Planta',
        'Identificação Fockink (Equip)',
        'Nome do Equipamento',
        'Status Equipamento',
      ],
    ];
    allData.data.data.forEach((a) => {
      if (a.plantList && a.plantList.length === 0) {
        csvArray.push([
          a.description,
          a.plan.description,
          a.status === 'A' ? 'Ativo' : 'Inativo',
          a.userLimit,
          formatDate(new Date(a.activationDate * 1000), 'dd/MM/yyyy'),
          formatDate(new Date(a.expirationDate * 1000), 'dd/MM/yyyy'),
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
        ]);
      } else {
        a.plantList.forEach((p) => {
          if (p.equipaments && p.equipaments.length === 0) {
            csvArray.push([
              a.description,
              a.plan.description,
              a.status === 'A' ? 'Ativo' : 'Inativo',
              a.userLimit,
              formatDate(new Date(a.activationDate * 1000), 'dd/MM/yyyy'),
              formatDate(new Date(a.expirationDate * 1000), 'dd/MM/yyyy'),
              p.technicalDescription,
              p.description,
              sys[p.operationId],
              p.location
                ? `${p.location.address1} ${p.location.address2} ${p.location.city} ${p.location.state} ${p.location.country} ${p.location.postalCode}`
                : '',
              p.status === 'A' ? 'Ativo' : 'Inativo',
              '',
              '',
              '',
            ]);
          } else {
            p.equipaments.forEach((e) => {
              csvArray.push([
                a.description,
                a.plan.description,
                a.status === 'A' ? 'Ativo' : 'Inativo',
                a.userLimit,
                formatDate(new Date(a.activationDate * 1000), 'dd/MM/yyyy'),
                formatDate(new Date(a.expirationDate * 1000), 'dd/MM/yyyy'),
                p.technicalDescription,
                p.description,
                sys[p.operationId],
                p.location
                  ? `${p.location.address1} ${p.location.address2} ${p.location.city} ${p.location.state} ${p.location.country} ${p.location.postalCode}`
                  : '',
                p.status === 'A' ? 'Ativo' : 'Inativo',
                e.description,
                e.clientDescription,
                e.status === 'A' ? 'Ativo' : 'Inativo',
              ]);
            });
          }
        });
      }
    });
    csvArray.forEach((line) => {
      csvFile += line.join(';');
      csvFile += '\n';
    });
    const encodedUri = encodeURI(csvFile);

    const a = document.createElement('a');
    a.href = encodedUri;
    a.download = 'EquipamentosDaConta';
    a.click();
  };

  if (returnToList) return <Redirect push to="/organization" />;

  if (loading) {
    return (
      <Skeleton variant="rect" height={500} width="100%" animation="wave" />
    );
  }

  return (
    <>
      <Breadcrumbs
        routes={breadcrumb.routes}
        title={data.corporateName || breadcrumb.title}
      />

      <Grid container spacing={3} direction="row-reverse">
        <Grid item xs={12}>
          <Paper square className={classes.root}>
            <Tabs
              value={tab}
              onChange={(event, newValue) => setTab(newValue)}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
            >
              <Tab
                icon={<EventSeatIcon />}
                disabled={!hasPermission(AdminPermissions.ACCOUNTS_VIEW.value)}
                label="Contas"
              />
              <Tab icon={<BusinessIcon />} label="Dados" />
            </Tabs>
          </Paper>
        </Grid>
        {console.log('teste', data)}

        {tab === 1 && (
          <>
            <Grid item lg={4} md={4} xs={12}>
              <TextField
                variant="filled"
                name="corporateName"
                onChange={handleChange}
                value={data.corporateName || ''}
                label="Nome"
              />
            </Grid>

            <Grid item lg={4} md={4} xs={12}>
              <TextField
                variant="filled"
                name="description"
                onChange={handleChange}
                value={data.description || ''}
                label="Descrição"
              />
            </Grid>

            <Grid item lg={4} md={4} xs={12}>
              <TextField
                variant="filled"
                name="isFockinkOrg"
                value={data.isFockinkOrg ? 'Sim' : 'Não' || ''}
                label="Organização Fockink?"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>

            <Grid item lg={4} md={4} xs={12}>
              <TextField
                variant="filled"
                name="document"
                onChange={handleChange}
                value={data.document || ''}
                label="N° Documento"
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <FormControl variant="filled">
                <InputLabel>Plano</InputLabel>
                <Select
                  value={data.plan.planId}
                  onChange={handleChangePlan}
                  name="planId"
                >
                  <MenuItem value={data.plan.planId}>
                    {data.plan.description}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item lg={4} md={4} xs={12}>
              <FormControl variant="filled">
                <InputLabel>Status</InputLabel>
                <Select
                  value={data.status}
                  onChange={handleChange}
                  name="status"
                >
                  <MenuItem value="A">Ativo</MenuItem>
                  <MenuItem value="I">Inativo</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <h3 className={classes.subTitle}>Endereço</h3>
            </Grid>

            <Grid item xs={12}>
              <AddressFields
                handleChangeLocation={handleChangeLocation}
                data={data.location}
              />
            </Grid>
          </>
        )}

        {tab === 0 && (
          <>
            <Grid item style={{ textAlign: 'right' }}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={downloadCsv}
                startIcon={<CloudDownloadIcon />}
              >
                Baixar CSV
              </Button>
            </Grid>

            {hasPermission(AdminPermissions.ACCOUNTS_MANAGE.value) && (
              <Grid item style={{ textAlign: 'right' }}>
                <NewButton
                  redirectTo={`/organization/${props.match.params.id}/account/new`}
                  title="Nova Conta"
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <Account
                organizationId={props.match.params.id}
                location={props.location}
              />
            </Grid>
          </>
        )}

        <Grid
          item
          xs={12}
          className={`${classes.textAlign} ${classes.bottomButtons}`}
        >
          <BackButton
            back={() => setReturnToList(true)}
            className={classes.btnSpace}
          />
          {tab === 1 && (
            <SaveButton
              save={save}
              className={classes.btnSpace}
              canSave={AdminPermissions.ORGANIZATIONS_MANAGE.value}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
