/* eslint-disable react/style-prop-object */
import React from 'react';
import verifyTemperature from '../../helpers/verifyTemperature';

export default function ViewSensorsStyle({ x, y, color, temperature, style }) {
  return (
    <>
      {(style === true) &&
        (
        <circle
          cx={x - 12}
          cy={y - 4}
          r={10}
          stroke="green"
          strokeWidth="0"
          fill={color}
        />
        // <ellipse cx={x} cy={y - 4} rx="80" ry="10" fill={color} />
        )
      }
      <text
        x={x - 19.5}
        y={y - 2}
        fill="black"
        style={{ fontSize: 12, fontWeight: 700 }}
        // onClick={
        //   () =>
        //   console.log('Click!')
        // }
      >
        {verifyTemperature(temperature)}
      </text>
    </>
  );
}
