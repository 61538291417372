// Basic
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

// UI, styles and icons
import Grid from '@material-ui/core/Grid';
import '../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import 'moment/locale/pt-br';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { InputAdornment } from '@material-ui/core';
import parseFloatCustom from '../../../../helpers/parseFloatCustom';
import BottomButtons from '../../../../components/Buttons/BottomButtons';
import Breadcrumbs from '../../../../components/Breadcrumbs';

// Services
import FockinkAPI from '../../../../middlewares/Api';

// Custom styles
const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  subTitle: {
    borderBottom: '1px solid #ccc',
    textAlign: 'center',
    paddingBottom: '0 !important',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

// Init
moment.locale('pt-br');

export default (props) => {
  // Styles
  const classes = useStyles();

  // States
  const [loading, setLoading] = useState(true);
  const [equipaments, setEquipaments] = useState([]);
  const [user, setUser] = useState();
  const [data, setData] = useState({
    status: 'A',
    equipamentId: '',
    lat: '',
    lon: '',
    sample: '',
    laboratory: '',
    techniciansName: '',
    producerCode: '',
    producerName: '',
    instructor: '',
    receivingDate: null,
    expirationDate: null,
    registry: '',
    depth: '',
    clay: '',
    sand: '',
    silt: '',
    obs: '',
  });
  const irrigationEquipaments = equipaments?.filter((x) => x.operationId === 1);
  // Init
  const breadcrumb = {
    title: 'Cadastro de Análise física do solo',
    routes: [
      { to: '/settings', name: 'Configurações' },
      {
        to: `/settings/soilPhysicalAnalysis/${props.match.params.clid}`,
        name: 'Análise física do solo',
      },
      { name: 'Lançamento de análise' },
    ],
  };

  // useEffects
  useEffect(() => {
    FockinkAPI.get(
      `/dashboards/equipaments/?accountId=${props.match.params.clid}`,
    ).then((response) => {
      setEquipaments(response.data.data.equipaments);
      setUser(response.data.data.account);
      setLoading(false);
    });
  }, []);

  const deleteData = () => ({
    url: `/soilPhysicalAnalysis/${props.match.params.id}`,
  });

  useEffect(() => {
    if (props.match.params.id === 'new') {
      setLoading(false);
    } else {
      setLoading(true);
      FockinkAPI.get(`/soilPhysicalAnalysis/${props.match.params.id}`).then(
        (response) => {
          setData({
            ...response.data.data,
            receivingDate: moment
              .utc(response.data.data.receivingDate)
              .format('YYYY-MM-DD'),
            expirationDate: moment
              .utc(response.data.data.expirationDate)
              .format('YYYY-MM-DD'),
          });
          setLoading(false);
        },
      );
    }
  }, [props.match.params.id]);
  // Methods
  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value || event.target.checked,
    });

    if (event.target.name === 'equipamentId') {
      setData({
        ...data,
        [event.target.name]: event.target.value || event.target.checked,
        lat: irrigationEquipaments?.filter(
          (x) => x.id === event.target.value,
        )[0].settings.location.lat,
        lon: irrigationEquipaments?.filter(
          (x) => x.id === event.target.value,
        )[0].settings.location.lon,
      });
    }
  };
  const saveData = () => {
    const dt = {
      data: {
        accountId: user.id,
        organizationId: user.organizationId,
        equipamentId: data.equipamentId,
        sample: data.sample,
        lat: parseFloatCustom(data.lat),
        lon: parseFloatCustom(data.lon),
        laboratory: data.laboratory,
        techniciansName: data.techniciansName,
        producerCode: data.producerCode,
        producerName: data.producerName,
        instructor: data.instructor,
        receivingDate: data.receivingDate,
        expirationDate: data.expirationDate,
        registry: data.registry,
        depth: parseFloatCustom(data.depth),
        clay: parseFloatCustom(data.clay),
        sand: parseFloatCustom(data.sand),
        silt: parseFloatCustom(data.silt),
        obs: data.obs,
        // status: data.status,
      },
    };
    Object.keys(dt.data).forEach((k) => {
      if (!dt.data[k]) {
        delete dt.data[k];
      }
    });
    if (props.match.params.id === 'new') {
      dt.method = 'post';
      dt.url = '/soilPhysicalAnalysis';
    } else {
      dt.method = 'put';
      dt.url = `/soilPhysicalAnalysis/${props.match.params.id}`;
    }
    return dt;
  };

  // Render
  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {loading && (
            <Skeleton
              variant="rect"
              height={300}
              width="100%"
              animation="wave"
            />
          )}

          {!loading && (
            <>
              <h2 style={{ marginTop: '20px' }}>Equipamento</h2>
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} xs={12}>
                  <FormControl variant="filled">
                    <InputLabel id="equipamentId">Equipamento</InputLabel>
                    <Select
                      labelId="equipamentId"
                      id="equipamentId"
                      name="equipamentId"
                      value={data.equipamentId}
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Selecione</em>
                      </MenuItem>
                      {irrigationEquipaments.map((e) => (
                        <MenuItem value={e.id} key={e.id}>
                          {e.clientDescription}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <h2 style={{ marginTop: '20px' }}>Dados Iniciais</h2>
              <Grid container spacing={2}>
                <Grid item lg={8} xs={12}>
                  <TextField
                    variant="filled"
                    name="sample"
                    onChange={handleChange}
                    value={data.sample || ''}
                    label="Número da amostra"
                    autoComplete="nope"
                  />
                </Grid>
                <Grid item lg={2} xs={12}>
                  <TextField
                    variant="filled"
                    name="lat"
                    onChange={handleChange}
                    value={data.lat || ''}
                    label="Latitude"
                    type="number"
                    autoComplete="nope"
                  />
                </Grid>

                <Grid item lg={2} xs={12}>
                  <TextField
                    variant="filled"
                    name="lon"
                    onChange={handleChange}
                    value={data.lon || ''}
                    label="Longitude"
                    type="number"
                    autoComplete="nope"
                  />
                </Grid>

                <Grid item lg={12} xs={12}>
                  <TextField
                    variant="filled"
                    name="laboratory"
                    onChange={handleChange}
                    value={data.laboratory || ''}
                    label="Laboratório"
                  />
                </Grid>

                <Grid item lg={12} md={4} xs={12}>
                  <TextField
                    variant="filled"
                    name="techniciansName"
                    onChange={handleChange}
                    value={data.techniciansName || ''}
                    label="Nome do Técnico"
                    autoComplete="nope"
                  />
                </Grid>

                <Grid item lg={4} xs={12}>
                  <TextField
                    variant="filled"
                    name="producerCode"
                    onChange={handleChange}
                    value={data.producerCode || ''}
                    label="Cód. Produtor"
                    autoComplete="nope"
                  />
                </Grid>

                <Grid item lg={8} xs={12}>
                  <TextField
                    variant="filled"
                    name="producerName"
                    onChange={handleChange}
                    value={data.producerName || ''}
                    label="Produtor"
                    autoComplete="nope"
                  />
                </Grid>

                <Grid item lg={6} xs={12}>
                  <TextField
                    variant="filled"
                    name="instructor"
                    onChange={handleChange}
                    value={data.instructor || ''}
                    label="Instrutor"
                    autoComplete="nope"
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <TextField
                    variant="filled"
                    name="registry"
                    onChange={handleChange}
                    value={data.registry || ''}
                    label="Registro"
                    autoComplete="nope"
                  />
                </Grid>

                <Grid item lg={3} xs={12}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    style={{ width: '100%' }}
                  >
                    <KeyboardDatePicker
                      variant="filled"
                      margin="normal"
                      label="Data de recebimento"
                      format="DD/MM/YYYY"
                      value={data.receivingDate}
                      name="receivingDate"
                      autoOk
                      onChange={(date) => {
                        setData({
                          ...data,
                          receivingDate: date.format('YYYY-MM-DD'),
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item lg={3} xs={12}>
                  <MuiPickersUtilsProvider
                    libInstance={moment}
                    utils={MomentUtils}
                    style={{ width: '100%' }}
                  >
                    <KeyboardDatePicker
                      variant="filled"
                      margin="normal"
                      label="Data de expedição"
                      format="DD/MM/YYYY"
                      value={data.expirationDate}
                      name="expirationDate"
                      autoOk
                      onChange={(date) =>
                        setData({
                          ...data,
                          expirationDate: date.format('YYYY-MM-DD'),
                        })
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>

              <h2 style={{ marginTop: '20px' }}>Dados da Análise Física</h2>
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="filled"
                    name="clay"
                    onChange={handleChange}
                    value={data.clay || ''}
                    type="number"
                    label="Argila (%)"
                    autoComplete="nope"
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="filled"
                    name="sand"
                    onChange={handleChange}
                    value={data.sand || ''}
                    type="number"
                    label="Areia (%)"
                    autoComplete="nope"
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="filled"
                    name="silt"
                    onChange={handleChange}
                    value={data.silt || ''}
                    type="number"
                    label="Silte (%)"
                    autoComplete="nope"
                  />
                </Grid>

                <Grid item lg={12} md={12} xs={12}>
                  <TextField
                    variant="filled"
                    name="obs"
                    onChange={handleChange}
                    value={data.obs || ''}
                    label="Observações"
                    multiline
                    rows={4}
                    autoComplete="nope"
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <FormControl variant="filled">
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={data.status}
                      onChange={handleChange}
                      name="status"
                    >
                      <MenuItem value="A">Ativo</MenuItem>
                      <MenuItem value="I">Inativo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="filled"
                    name="depth"
                    onChange={handleChange}
                    value={data.depth || ''}
                    type="number"
                    label="Profundidade"
                    autoComplete="nope"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          cm
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        className={`${classes.textAlign} ${classes.bottomButtons}`}
      >
        <BottomButtons
          saveData={saveData}
          deleteData={props.match.params.id !== 'new' && deleteData}
          backTo={`/settings/soilPhysicalAnalysis/${props.match.params.clid}`}
        />
      </Grid>
    </>
  );
};
