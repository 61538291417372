import React from 'react';
import { number } from '../../../../helpers/Intl';
import DadosIndisponiveis from '../DadosIndisponiveis';

export default function UvAtual({ info1, info2 }) {
  let source = '';
  let type = 0;

  let displayInfo = '';
  if ((info1 || info1 === 0) && (info1 !== undefined || info1 !== '')) {
    displayInfo = info1;
    type = 0;
  } else if ((info2 || info2 === 0) && (info2 !== undefined || info2 !== '')) {
    displayInfo = info2;
    type = 1;
    source = 'openweathermap';
  }

  let indicador = '';
  if (source === 'openweathermap') {
    indicador = '*';
  }

  type === undefined && (type = 0);

  // type = [ 0, 1, 2, 3, 4, 5 ]
  const unity = [
    `W/m² ${indicador}`,
    `% ${indicador}`,
    `W/m² Max. ${indicador}`,
    `W/m² Min. ${indicador}`,
    `% Max. ${indicador}`,
    `% Min. ${indicador}`,
  ];

  return (
    <>
      {displayInfo !== '' ? (
        `${number(displayInfo, 1, 1)}${unity[type]}`
      ) : (
        <DadosIndisponiveis unitType={unity[type]} />
      )}
    </>
  );
}
