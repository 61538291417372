import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import NumberFormat from 'react-number-format';
import '../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FockinkAPI from '../../../../middlewares/Api';

const useStyles = makeStyles({
  paper: {
    padding: '15px',
    height: '100%',
  },
  title: {
    margin: '0',
    fontWeight: '400',
  },
  gridImp: {
    padding: '0 5px',
  },
});

export default (props) => {
  const [equips, setEquips] = React.useState([]);
  const classes = useStyles();

  React.useEffect(() => {
    FockinkAPI.get('/dashboards/equipaments').then((response) => {
      setEquips(response.data.data.equipaments);
    });
  }, []);

  const handleChange = (event) => {
    const newData = {
      ...props.data.taxes,
      [event.target.name]: event.target.value,
    };
    props.setData({ ...props.data, taxes: newData });
  };

  const handleChangeEquips = (event) => {
    props.setData({ ...props.data, equipaments: event.target.value });
  };

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item lg={8} md={4} xs={12} className={classes.grid}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <h4 className={classes.title}>Impostos</h4>
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <NumberFormat
                value={props.data.taxes.pis}
                name="pis"
                label="PIS"
                customInput={TextField}
                variant="filled"
                size="small"
                suffix="%"
                thousandSeparator="."
                decimalSeparator=","
                onValueChange={(v) =>
                  handleChange({ target: { name: 'pis', value: v.floatValue } })
                }
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12} className={classes.gridImp}>
              <NumberFormat
                value={props.data.taxes.cofins}
                name="cofins"
                label="COFINS"
                customInput={TextField}
                variant="filled"
                size="small"
                suffix="%"
                thousandSeparator="."
                decimalSeparator=","
                onValueChange={(v) =>
                  handleChange({
                    target: { name: 'cofins', value: v.floatValue },
                  })
                }
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <NumberFormat
                value={props.data.taxes.icms}
                name="icms"
                label="ICMS"
                customInput={TextField}
                variant="filled"
                size="small"
                suffix="%"
                thousandSeparator="."
                decimalSeparator=","
                onValueChange={(v) =>
                  handleChange({
                    target: { name: 'icms', value: v.floatValue },
                  })
                }
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      <Grid item lg={4} md={4} xs={12} className={classes.grid}>
        <Paper className={classes.paper}>
          <Grid container>
            <Grid item xs={12}>
              <h4 className={classes.title}>Aplicar nos Equipamentos</h4>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="filled">
                <InputLabel>Equipamentos</InputLabel>
                <Select
                  multiple
                  value={props.data.equipaments}
                  onChange={handleChangeEquips}
                  name="plantId"
                  renderValue={(selected) =>
                    selected.length === 0
                      ? 'Nenhum equipamento selecionado'
                      : `Selecionado: ${selected.length} de ${equips.length}`
                  }
                >
                  {equips.map((m) => (
                    <MenuItem key={m.id} value={m.id}>
                      <Checkbox
                        checked={
                          (props.data.equipaments || []).indexOf(m.id) > -1
                        }
                      />
                      <ListItemText primary={m.clientDescription || m.description} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
