export default function findBiggerTemperatureColor(sensors, colorScale) {
  let color = '#ACABAB';
  let bigTemperature = 0;

  if (!colorScale) {
    colorScale = 0;
  }

  sensors.forEach((sensor) => {
    if ((sensor.t > bigTemperature) && (sensor.out === false) &&
      (sensor.t !== 997) && (sensor.t !== 998) && (sensor.t !== 999)) {
      bigTemperature = sensor.t;
      if (colorScale === 0) {
        color = sensor.gradualColor;
      } else if (colorScale === 1) {
        color = sensor.qualityColor;
      }
    }
  });

  return color;
}
