import React from 'react';
import { useTheme } from 'styled-components';
import moment from 'moment';
import * as S from './styles';
import { ProgressBarWithLabel } from '../../../../ProgressBarWithLabel';

export function GrainCard({ data, grain, date }) {
  const originalTheme = useTheme();

  return (
    <>
      <S.Container>
        <S.Header>
          <S.ContainerGrain>
            <S.Grain>{grain}</S.Grain>
            <S.DateGrain>
              {date && moment(date).format('DD/MM/YYYY HH:mm:ss')}
            </S.DateGrain>
          </S.ContainerGrain>

          <hr
            style={{
              width: '80%',
              background: originalTheme.colors.card_light,
            }}
          />

          <ProgressBarWithLabel
            trailColor={originalTheme.colors.backgroundLight3}
            r="230"
            g="76"
            b="76"
            percent={data.percSensorHot}
            description="Pontos Quentes"
          />
        </S.Header>

      </S.Container>
    </>
  );
}
