import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import '../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { formatDate } from '../../../../helpers/Intl';

const useStyles = makeStyles({
  paper: {
    padding: '15px',
    height: '100%',
  },
  title: {
    margin: '0',
    fontWeight: '400',
  },
});

export default (props) => {
  const classes = useStyles();

  const handleChange = (event) => {
    const newData = {
      ...props.data[props.section],
      [event.target.name]: event.target.value,
    };
    props.setData({ ...props.data, [props.section]: newData });
  };

  const getDt = () => props.data[props.section] || {};

  const handleDateChange = (date, input) => {
    const newData = {
      ...props.data[props.section],
      [input]: formatDate(date, 'HH:mm'),
    };
    props.setData({ ...props.data, [props.section]: newData });
  };

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="center"
      alignItems="stretch"
    >
      <Grid item lg={4} md={4} xs={12} className={classes.grid}>
        <Paper className={classes.paper} style={{ background: props.color }}>
          <h4 className={classes.title}>{props.title}</h4>

          {!props.lockHour ? (
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              style={{ width: '100%' }}
            >
              <KeyboardTimePicker
                margin="normal"
                label="Início"
                invalidDateMessage="Data Inválida"
                ampm={false}
                value={`2020T${getDt().start}`}
                keyboardIcon={<QueryBuilderIcon />}
                onChange={(e) => handleDateChange(e, 'start')}
              />

              <KeyboardTimePicker
                margin="normal"
                label="Fim"
                invalidDateMessage="Data Inválida"
                ampm={false}
                value={`2020T${getDt().finish}`}
                keyboardIcon={<QueryBuilderIcon />}
                onChange={(e) => handleDateChange(e, 'finish')}
              />
            </MuiPickersUtilsProvider>
          ) : (
            <h2 style={{ fontWeight: '400', textAlign: 'center' }}>
              Demais horários
            </h2>
          )}
        </Paper>
      </Grid>

      <Grid item lg={4} md={4} xs={12} className={classes.grid}>
        <Paper className={classes.paper} style={{ background: props.color }}>
          <h4 className={classes.title}>Valor da Demanda (R$/kW)</h4>
          <NumberFormat
            value={props.hasDemand ? getDt().demand : ''}
            name="demand"
            label="Demanda"
            disabled={!props.hasDemand}
            customInput={TextField}
            variant="filled"
            size="small"
            prefix="R$ "
            style={{ margin: '10% 0' }}
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={(v) =>
              handleChange({ target: { name: 'demand', value: v.floatValue } })
            }
          />
          <NumberFormat
            value={props.hasDemand ? getDt().demandPenalty : ''}
            name="demandPenalty"
            label="Demanda Ultrapassagem"
            disabled={!props.hasDemand}
            customInput={TextField}
            variant="filled"
            size="small"
            prefix="R$ "
            style={{ margin: '0 0 10%' }}
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={(v) =>
              handleChange({
                target: { name: 'demandPenalty', value: v.floatValue },
              })
            }
          />
        </Paper>
      </Grid>

      <Grid item lg={4} md={4} xs={12} className={classes.grid}>
        <Paper className={classes.paper} style={{ background: props.color }}>
          <h4 className={classes.title}>Valor do Consumo (R$/kWh)</h4>
          <NumberFormat
            value={getDt().consumption}
            name="consumption"
            label="Consumo"
            customInput={TextField}
            variant="filled"
            size="small"
            prefix="R$ "
            style={{ margin: '10% 0' }}
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={(v) =>
              handleChange({
                target: { name: 'consumption', value: v.floatValue },
              })
            }
          />
        </Paper>
      </Grid>
    </Grid>
  );
};
