import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import BuildIcon from '@material-ui/icons/Build';
import RouterIcon from '@material-ui/icons/Router';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ReportIcon from '@material-ui/icons/Report';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import {
  RiFileCopyLine,
} from 'react-icons/ri';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import AddressFields from '../../../../../../components/AddressFields';
import FockinkAPI from '../../../../../../middlewares/Api';
import BottomButtons from '../../../../../../components/Buttons/BottomButtons';
import Breadcrumbs from '../../../../../../components/Breadcrumbs';
import ConnectedAcordeon from '../../../../../../components/ConnectedAcordeon';
import Card from '../../../../../../components/Card';
import CardTitle from '../../../../../../components/CardTitle';
import RequestButton from '../../../../../../components/Buttons/RequestButton';
import CardText from '../../../../../../components/CardText';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  subTitle: {
    borderBottom: '1px solid #ccc',
    textAlign: 'center',
    paddingBottom: '0 !important',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

export default (props) => {
  const [loading, setLoading] = React.useState(true);
  const [setup, setSetup] = React.useState({ setup: undefined });
  const [tab, setTab] = React.useState(0);
  const [differentComm, setDifferentComm] = React.useState(false);
  const [expandForm, setExpandForm] = React.useState(false);
  const MySwal = withReactContent(Swal);
  const [equipaments, setEquipaments] = React.useState();
  const weatherStation = equipaments?.filter((x) => x.operationId === 5);
  const [plantType, setPlantType] = React.useState();
  const equipsTypes = {
    1: 'Equipamento de Irrigação',
    2: 'Equipamento de Termometria',
    3: 'Equipamento de Automação',
    4: 'Equipamento de Fotovoltaica',
  };

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      MySwal.fire({
        icon: 'success',
        title: 'Equipament ID',
        text: 'Texto copiado',
      });
    } catch (err) {
      MySwal.fire({
        icon: 'error',
        title: 'Equipament ID',
        text: 'Texto não copiado',
      });
    }
  };

  const [data, setData] = React.useState({
    description: '',
    technicalDescription: '',
    operationId: '',
    // alias: '',
    gnf: '',
    location: {},
    integrationMode: 'Local',
    status: 'A',
    communicator: {},
  });

  const activeInactive = [
    {
      value: 'A',
      name: 'Ativo',
    },
    {
      value: 'I',
      name: 'Inativo',
    },
  ];
  const breadcrumb = {
    title: `Equipamento: ${data.description}`,
    routes: [
      { to: '/organization', name: 'Organizações' },
      { to: `/organization/${props.match.params.orgId}`, name: 'Organização' },
      {
        to: `/organization/${props.match.params.orgId}/account/${props.match.params.accId}`,
        name: 'Conta',
      },
      {
        to: `/organization/${props.match.params.orgId}/account/${props.match.params.accId}/plant/${props.match.params.plId}`,
        name: 'Planta',
      },
      { name: 'Visualização de Equipamento' },
    ],
  };
  // Init
  moment.locale('pt-br');

  React.useEffect(() => {
    if (setup.setup === false) {
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'O equipamento não pode ser verificado',
      });
    }
  }, [setup]);

  React.useEffect(() => {
    if (data.communicators && data.communicators.length) {
      setDifferentComm(data.communicators[0].id !== data.communicator.id);
    }
  }, [data]);

  React.useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const operationId = query.get('op');
    setExpandForm(operationId === '4' || operationId === 4);
    if (props.match.params.id === 'new') {
      setLoading(false);
    } else {
      setLoading(true);
      FockinkAPI.get(`/plants/${props.match.params.plId}`).then((response) => {
        setData({ ...data, location: response.data.data.settings.location });
        setPlantType(response.data.data.operationId);
      });
      FockinkAPI.get(`/equipaments?plantId=${props.match.params.plId}`).then(
        (response) => {
          setEquipaments(response.data.data);
        },
      );
      FockinkAPI.get(`/equipaments/${props.match.params.id}`).then(
        (response) => {
          const dt = {
            ...response.data.data,
            location: response.data.data.settings.location || {},
            communicator: response.data.data.communicator || {},
            // alias: response.data.data.unit
            //   ? response.data.data.unit.alias || ''
            //   : '',
            // technicalDescription: response.data.data.unit
            //   ? response.data.data.unit.description || ''
            //   : '',
            integrationMode: response.data.data.integration
              ? response.data.data.integration.mode
              : 'Local',
            auroraVisionPlantId: response.data.data.integration
              ? response.data.data.integration.auroraVisionPlantId
              : '',
            operationId,
            validFrom: moment
              .utc(response.data.data.agextec?.validFrom)
              .format('YYYY-MM-DD'),
            validUntil: moment
              .utc(response.data.data.agextec?.validUntil)
              .format('YYYY-MM-DD'),
            active: response.data.data.agextec?.active,
          };
          delete dt.settings;
          // if (dt.communicators && dt.communicators.length) {
          //   dt.communicator.description = dt.communicators.find(
          //     (c) => dt.communicator.id === c.id,
          //   ).description;
          // }
          setData(dt);
          setLoading(false);
        },
      );
    }
  }, [props.match.params.id]);

  const classes = useStyles();
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };
  const handleChangeEquipament = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };
  const handleChangeLocation = (location) => {
    setData({ ...data, location });
  };
  if (loading) {
    return (
      <Skeleton variant="rect" height={500} width="100%" animation="wave" />
    );
  }

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignContent="space-between"
        style={{ padding: '0 24px 15px' }}
      >
        <Grid item xs={12}>
          <Paper square className={classes.root}>
            <Tabs
              value={tab}
              onChange={(event, newValue) => setTab(newValue)}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
            >
              <Tab icon={<BuildIcon />} label="Dados" />
              {props.match.params.id !== 'new' && (
                <Tab icon={<RouterIcon />} label="Últimos Comunicadores" />
              )}
            </Tabs>
          </Paper>
        </Grid>

        {tab === 0 && (
          <>
            <Grid item xs={12} lg={4} xl={4}>
              <Card square similar>
                <CardTitle>Dados Gerais:</CardTitle>
                <CardText>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={12}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        name="description"
                        onChange={handleChange}
                        value={data.description || ''}
                        label="Identificação Fockink"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={12}>
                      <TextField
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        name="clientDescription"
                        onChange={handleChange}
                        value={data.clientDescription || ''}
                        label="Nome"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={12}>
                      <FormControl variant="outlined">
                        <InputLabel id="statusLabel">Status</InputLabel>
                        <Select
                          labelId="statusLabel"
                          id="status"
                          name="status"
                          value={data.status}
                          onChange={handleChange}
                          label="Status"
                          inputProps={{ readOnly: true }}
                        >
                          {
                              activeInactive.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              ))
                            }

                        </Select>
                      </FormControl>
                    </Grid>

                    {expandForm && (
                      <>
                        <Grid item xs={12} sm={6} md={6} lg={12}>
                          <FormControl variant="outlined">
                            <InputLabel id="fotoLabel">Tipo</InputLabel>
                            <Select
                              labelId="fotoLabel"
                              label="Tipo"
                              inputProps={{ readOnly: true }}
                              value={data.integrationMode}
                              onChange={handleChange}
                              name="integrationMode"
                            >
                              <MenuItem value="Local">Local</MenuItem>
                              <MenuItem value="AuroraVision">AuroraVision</MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={12}>
                          <TextField
                            variant="outlined"
                            InputProps={{
                              readOnly: true,
                            }}
                            name="auroraVisionPlantId"
                            disabled={data.integrationMode !== 'AuroraVision'}
                            value={
                              data.integrationMode === 'AuroraVision'
                                ? data.auroraVisionPlantId
                                : ''
                            }
                            label="ID da Planta do Aurora Vision"
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={6} md={6} lg={12}>
                      <TextField
                        variant="outlined"
                        name="gnf"
                        onChange={handleChange}
                        value={data.gnf || ''}
                        label="GNF"
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={12}>
                      <FormControl variant="outlined">
                        <InputLabel id="operationId">
                          Tipo de Equipamento
                        </InputLabel>
                        <Select
                          inputProps={{ readOnly: true }}
                          labelId="operationId"
                          id="operationId"
                          name="operationId"
                          value={data.operationId}
                          label="Tipo de Equipamento"
                          onChange={handleChangeEquipament}
                        >
                          <MenuItem value={plantType}>
                            {equipsTypes[plantType]}
                          </MenuItem>
                          <MenuItem value={5}>Estação Meteorológica</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={12}>
                      <FormControl variant="outlined">
                        <InputLabel id="weatherStationId">
                          Estação Meteorológica
                        </InputLabel>
                        <Select
                          inputProps={{ readOnly: true }}
                          labelId="weatherStationId"
                          id="weatherStationId"
                          name="weatherStationId"
                          value={data.weatherStationId}
                          label="Equipamento"
                          onChange={handleChangeEquipament}
                        >
                          {weatherStation?.map((equip) => (
                            <MenuItem value={equip.id}>
                              {equip.description}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={12}>
                      <FormControlLabel
                        control={
                          // eslint-disable-next-line react/jsx-wrap-multilines
                          <Checkbox
                            checked={data.active}
                            name="active"
                            onChange={(e) =>
                              setData({
                                ...data,
                                [e.target.name]: e.target.checked,
                              })
                            }
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        }
                        label="AgexTec"
                      />
                    </Grid>
                    {data.active && (
                      <>
                        <Grid item lg={6} xs={12}>
                          <MuiPickersUtilsProvider
                            libInstance={moment}
                            utils={MomentUtils}
                            style={{ width: '100%' }}
                          >
                            <KeyboardDatePicker
                              variant="outlined"
                              margin="normal"
                              label="Início"
                              format="DD/MM/YYYY"
                              value={data.validFrom}
                              name="validFrom"
                              autoOk
                              onChange={(date) => {
                                setData({
                                  ...data,
                                  validFrom: date.format('YYYY-MM-DD'),
                                });
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item lg={6} xs={12}>
                          <MuiPickersUtilsProvider
                            libInstance={moment}
                            utils={MomentUtils}
                            style={{ width: '100%' }}
                          >
                            <KeyboardDatePicker
                              variant="outlined"
                              margin="normal"
                              label="Fim"
                              format="DD/MM/YYYY"
                              value={data.validUntil}
                              name="validUntil"
                              autoOk
                              onChange={(date) =>
                                setData({
                                  ...data,
                                  validUntil: date.format('YYYY-MM-DD'),
                                })
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardText>
              </Card>
            </Grid>

            <Grid item xs={12} lg={4} xl={4}>
              <Card square similar>
                <CardTitle>Endereço:</CardTitle>
                <CardText>
                  <AddressFields
                    handleChangeLocation={handleChangeLocation}
                    data={data.location}
                  />
                </CardText>
              </Card>
            </Grid>

            <Grid item xs={12} lg={4}>
              <Grid container spacing={2}>
                {props.match.params.id !== 'new' && (
                  <Grid item xs={12} sm={7} lg={12}>
                    <Card square similar>
                      <CardTitle>Identificação e Comunicação</CardTitle>
                      <CardText>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              variant="outlined"
                              name="equipamentId"
                              value={props.match.params.id}
                              label="Equipament ID:"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    onClick={() => copyToClipBoard(props.match.params.id)}
                                  >
                                    <RiFileCopyLine size={20} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              variant="outlined"
                              style={{
                                backgroundColor: differentComm
                                  ? '#FFFF88'
                                  : '#FEFEFE',
                              }}
                              name="defaultComunicator"
                              InputProps={{
                                endAdornment: differentComm && (
                                  <InputAdornment position="end">
                                    <Tooltip
                                      title="Comunicador diferente do histórico da telemetria!"
                                      placement="top"
                                    >
                                      <ReportIcon
                                        style={{
                                          color: '#AAAA00',
                                          cursor: 'pointer',
                                        }}
                                      />
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                              }}
                              value={
                                data.communicator.description
                                  ? data.communicator.description
                                  : data.communicator.id ||
                                    'Sem Comunicador Definido'
                              }
                              label="Comunicador Padrão"
                            />
                          </Grid>
                        </Grid>
                      </CardText>
                    </Card>
                  </Grid>
                )}

                {props.match.params.id !== 'new' && (
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    lg={12}
                    style={{ textAlign: 'center' }}
                  >
                    <Card square similar>
                      <CardTitle>Verificar Equipamento</CardTitle>
                      <CardText>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <RequestButton
                              isDisabled={setup.setup || data.setup}
                              defaultText="Verificar"
                              disabledText="Verificado"
                              icon={
                                !(setup.setup || data.setup) && (
                                  <NotListedLocationIcon />
                                )
                              }
                              setData={setSetup}
                              postData={{
                                url: `/equipaments/${props.match.params.id}/setup`,
                                method: 'get',
                              }}
                            />
                          </Grid>
                        </Grid>
                      </CardText>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}

        {tab === 1 && (
          <>
            <Grid item xs={12}>
              {props.match.params.id !== 'new' ? (
                <Grid item xs={12}>
                  <ConnectedAcordeon
                    rows={data.communicators}
                    title="Comunicadores"
                  />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </>
        )}

        <Grid
          item
          xs={12}
          className={`${classes.textAlign} ${classes.bottomButtons}`}
        >
          <BottomButtons
            backTo={`/organization/${props.match.params.orgId}?accountId=${props.match.params.accId}&plantId=${props.match.params.plId}`}
          />
        </Grid>
      </Grid>
    </>
  );
};
