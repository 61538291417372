import React from 'react';
import { Grid } from '@mui/material';
import verifyTemperature from '../../../../../helpers/verifyTemperature';
import { IconDewPoint } from '../Icons/WeatherIcons';
import { number } from '../../../../../helpers/Intl';

export default function DewPoint({ value, icon, noValue }) {
  if ((!value) && (value !== 0)) {
    value = 'OFF';
  } else {
    value = number(value, 1, 1);
    value = verifyTemperature(value);
  }

  if (!icon) {
    icon = false;
  }

  if (!noValue) {
    noValue = false;
  }

  return (
    <div style={{ textAlign: 'center' }}>
      { !noValue ? (
        <Grid item xs={12}>
          {value}
          {value !== 'OFF' ? ' °C' : '' }
        </Grid>
      )
        :
        ''
      }
      <Grid item xs={12}>
        {
          icon ?
            <IconDewPoint size={50} color="#556E7B" />
            :
            ''
        }
      </Grid>
      <Grid item xs={12}>
        Ponto de Orvalho
      </Grid>
    </div>
  );
}
