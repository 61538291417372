/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import NumberFormat from 'react-number-format';
import Grid from '@material-ui/core/Grid';
import '../../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { InputAdornment, TextField } from '@material-ui/core';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import FockinkAPI from '../../../../../middlewares/Api';
import BottomButtons from '../../../../../components/Buttons/BottomButtons';
import AddressFields from '../../../../../components/AddressFields';
import ThermometrySettingsQuality from './ThermometrySettingsQuality';
import GradualThermometryGraphic from '../../../../../components/GradualThermometryGraphic';
import ThermometrySettingsStrategy from './ThermometrySettingsStrategy';
import ThermometrySettingsAdditional from './ThermometrySettingsAdditional';
import PortalPermissions from '../../../../../helpers/enums/PortalPermissions';
import { EnergyCostCard } from '../../../../../components/EnergyCostCard';
import { handleMessageError } from '../../../../../helpers/handlerMessageError';
import { FilesToDownload } from '../../FilasToDownloadList';

const useStyles = makeStyles({
  bottomButtons: {
    margin: '15px 0',
    textAlign: 'right',
    paddingTop: '10px !important',
  },
  btnSpace: {
    marginLeft: '15px',
  },
});

export default (props) => {
  const [data, setData] = React.useState({ settings: {} });
  const [loading, setLoading] = React.useState(true);
  const [breadcrumbs, setBreadcrumbs] = React.useState(null);
  const [expanded, setExpanded] = React.useState('strategy');
  const classes = useStyles();
  const MySwal = withReactContent(Swal);
  const [
    availableFilesToDownload,
    setAvailableFilesToDownload,
  ] = React.useState([]);
  const urlParams = new URLSearchParams(window.location.search);
  const whatRoute = urlParams.get('whatRoute');

  const getDocumentsFileToDonwload = async () => {
    try {
      const response = await FockinkAPI.get(
        `equipaments/${props.match.params.id}/readFiles`,
      );

      const fomattedFileObject = response.data.data.map((file) => ({
        file,
        fileName: file.split('/').pop(),
      }));

      setAvailableFilesToDownload(fomattedFileObject);
    } catch (error) {
      handleMessageError(error);
    }
  };

  const throwWarning = (text) =>
    MySwal.fire({
      title: text,
    });

  const callApi = () => {
    setLoading(true);
    FockinkAPI.get(`/equipaments/${props.match.params.id}/settings`).then(
      (response) => {
        if (response.data.data.settings) {
          const responseData = response.data.data;
          setData({
            ...responseData.settings,
            clientDescription: responseData.clientDescription || null,
            location: responseData.settings.location || {},
            performanceEstimate:
              responseData.settings.performanceEstimate || {},
            limits: response.data.data.settings.limits || {
              hotSpots: '',
              failedEngines: '',
            },
          });
        } else {
          setData({
            location: {},
            performanceEstimate: {},
            limits: { hotSpots: '', failedEngines: '' },
          });
        }

        setBreadcrumbs({
          routes: [
            { name: 'Equipamentos', to: '/equipamentData' },
            {
              name:
                response.data.data.clientDescription ||
                response.data.data.description,
              to: `/thermometryDash/${props.match.params.id}`,
            },
            { name: 'Configurações' },
          ],
        });
        setLoading(false);
      },
    );
  };

  React.useEffect(() => {
    (async () => {
      await getDocumentsFileToDonwload();
    })();
    callApi();
  }, [props.match.params.id]);

  if (data && data.operationId === 'loading') {
    return <h3 className={classes.loading}>Carregando</h3>;
  }

  const handleChangeLocation = (location) => {
    setData({ ...data, location });
  };

  const handleChangeLimits = (e) => {
    const limits = { ...data.limits, [e.target.name]: e.target.value };
    setData({ ...data, limits });
  };

  const checkValidation = () => {
    if (!data.location.address1 || data.location.address1.length > 60) {
      throwWarning('Endereço 1 deve ter entre 1 e 60 caracteres');
      return false;
    }

    const tests = [
      { text: 'CEP', input: 'postalCode' },
      { text: 'Cidade', input: 'city' },
      { text: 'Estado', input: 'state' },
      { text: 'Pais', input: 'country' },
      { text: 'Latitude', input: 'lat' },
      { text: 'Longitude', input: 'lon' },
    ]
      .map((e) => {
        if (!data.location[e.input]) {
          throwWarning(`${e.text} deve ser preenchido`);
          return false;
        }
        return true;
      })
      .reduce((a, b) => a && b);

    if (!tests) {
      return false;
    }

    return true;
  };

  const saveData = () => {
    const valid = checkValidation();
    if (valid) {
      const dt = {
        data: {
          location: data.location,
          clientDescription: data.clientDescription,
          limits: data.limits,
        },
        url: `/equipaments/${props.match.params.id}/thermometrySettings`,
        method: 'put',
      };
      return dt;
    }
    return undefined;
  };

  const handleChangeAcc = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleDataClientDescription = (event) => {
    setData({
      ...data,
      clientDescription: event.target.value,
    });
  };
  if (loading) {
    return (
      <>
        <Breadcrumbs loading={loading} />
        <Grid container spacing={2}>
          {[0, 1, 2, 3].map((k) => (
            <React.Fragment key={k}>
              <Grid item xs={12}>
                <Skeleton
                  variant="rect"
                  height={20}
                  width={100}
                  animation="wave"
                />
                <hr />
              </Grid>
              {[0, 1, 2, 3, 4, 5, 6].map((j) => (
                <Grid item key={j} xs={12} md={12} lg={3}>
                  <Skeleton
                    key={`${k}${j}`}
                    className={classes.skeleton}
                    variant="rect"
                    height={50}
                    width="100%"
                    animation="wave"
                  />
                </Grid>
              ))}
            </React.Fragment>
          ))}
        </Grid>
      </>
    );
  }

  return (
    <>
      <Breadcrumbs routes={breadcrumbs.routes} />
      <Grid container spacing={2} style={{ minHeight: 'calc(100vh - 101px)' }}>
        <Grid item xs={12}>
          <Accordion
            expanded={expanded === 'clientDescription'}
            onChange={handleChangeAcc('clientDescription')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Descrição do Equipamento</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={3}>
                  <TextField
                    variant="filled"
                    name="clientDescription"
                    onChange={handleDataClientDescription}
                    value={data.clientDescription || null}
                    label="Defina uma descrição para o equipamento"
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion
            expanded={expanded === 'limits'}
            onChange={handleChangeAcc('limits')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Detalhes em equipamento</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={3}>
                  <NumberFormat
                    value={data.limits.hotSpots}
                    label="Percentagem aceitável de pontos quentes"
                    customInput={TextField}
                    variant="filled"
                    thousandSeparator=""
                    decimalSeparator="."
                    maxlength="2"
                    onValueChange={(v) => {
                      if (v.floatValue <= 100) {
                        handleChangeLimits({
                          target: { name: 'hotSpots', value: v.floatValue },
                        });
                      }
                      if (v.floatValue > 100) {
                        handleChangeLimits({
                          target: { name: 'hotSpots', value: 100 },
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} lg={3}>
                  <NumberFormat
                    value={data.limits.failedEngines}
                    label="Percentagem aceitável de motores em falha"
                    customInput={TextField}
                    variant="filled"
                    thousandSeparator=""
                    decimalSeparator="."
                    onValueChange={(v) => {
                      if (v.floatValue <= 100) {
                        handleChangeLimits({
                          target: {
                            name: 'failedEngines',
                            value: v.floatValue,
                          },
                        });
                      }
                      if (v.floatValue > 100) {
                        handleChangeLimits({
                          target: { name: 'failedEngines', value: 100 },
                        });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion
            expanded={expanded === 'location'}
            onChange={handleChangeAcc('location')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Endereço</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <AddressFields
                handleChangeLocation={handleChangeLocation}
                data={data.location}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion
            expanded={expanded === 'strategy'}
            onChange={handleChangeAcc('strategy')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Configuração da Estratégia</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ThermometrySettingsStrategy data={data.strategy} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion
            expanded={expanded === 'others'}
            onChange={handleChangeAcc('others')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Configurações Adicionais</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ThermometrySettingsAdditional
                    peakHours={data.peakHours}
                    rainAndPriority={data.rainAndPriority}
                    alarms={data.alarms}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion
            expanded={expanded === 'quality'}
            onChange={handleChangeAcc('quality')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Range de Qualidade</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ThermometrySettingsQuality data={data.qualityRanges} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion
            expanded={expanded === 'gradual'}
            onChange={handleChangeAcc('gradual')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Cores Graduais</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <GradualThermometryGraphic
                    type="gradual"
                    formatData
                    data={{ gradual: data.gradualRanges[0] }}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion
            expanded={expanded === 'energyCost'}
            onChange={handleChangeAcc('energyCost')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Tarifação: Mercado Cativo</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <EnergyCostCard energyCost={data.energyCost} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <Accordion
            expanded={expanded === 'manuals'}
            onChange={handleChangeAcc('manuals')}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Arquivos e Manuais</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={3}>
                  <FilesToDownload files={availableFilesToDownload} />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} className={classes.bottomButtons}>
          <BottomButtons
            saveData={saveData}
            backTo={
              whatRoute === 'Equips'
                ? `/thermometryDash/${props.match.params.id}?whatRoute=Equips`
                : `/thermometryDash/${props.match.params.id}`
            }
            canSave={PortalPermissions.THERMOMETRY_SETTINGS_EDIT.value}
          />
        </Grid>
      </Grid>
    </>
  );
};
