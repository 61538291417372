import moment from 'moment';

export function createUTCDatesInMonth(date) {
  const daysMap = [];
  const daysInMonth = moment(date).daysInMonth();
  for (let d = 1; d <= daysInMonth; d += 1) {
    const day = moment(date).date(d).format('YYYY-MM-DD');
    daysMap.push(day);
  }

  return daysMap;
}
