import React from 'react';
import colors from '../../../helpers/colors';

export default function SiloSVAuto({ width, height }) {
  if (!height) {
    height = width;
  }

  if (!width) {
    width = height;
  }

  if ((!width) && (!height)) {
    return <h5>ops! Empty Parameters. See More! </h5>;
  }

  return (
    <svg width={width} height={height}>
      <rect
        width={width}
        height={height}
        stroke={colors.borda}
        strokeWidth="0"
        fill={colors.silo}
      />
    </svg>
  );
}
