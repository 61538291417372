import React from 'react';
import colors from '../../../helpers/colors';

export default function SiloSVAuto({ diameter }) {
  const radius = (diameter / 2);

  return (

    <svg width={diameter} height={diameter}>
      <circle
        cx={radius}
        cy={radius}
        r={radius}
        stroke={colors.borda}
        strokeWidth="0"
        fill={colors.silo}
      />
      {/* <circle
        cx={radius}
        cy={radius}
        r={85}
        stroke={colors.borda}
        strokeWidth="1"
        fill={colors.silo}
      />
      <circle
        cx={radius}
        cy={radius}
        r={55}
        stroke={colors.borda}
        strokeWidth="1"
        fill={colors.silo}
      />
      <circle
        cx={radius}
        cy={radius}
        r={30}
        stroke={colors.borda}
        strokeWidth="1"
        fill={colors.silo}
  /> */}
      Sorry, your browser does not support inline SVG.
    </svg>

  );
}
