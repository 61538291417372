import React, { useCallback, useEffect, useState } from 'react';
import { BsGraphUp } from 'react-icons/bs';
import * as S from './styles';
import { useThermometryReport } from '../../../../../../hooks/thermometryReport';
import { createUTCDatesInMonth } from '../../../../../../helpers/createUTCDatesInMonth';
import { ButtonGraphic } from '../ButtonGraph';
import { ButtonAvg } from '../ButtonAvg';
import { ReportTemperatureGraph } from '../TemperatureGraph';

export function TableReport({ labelDays, reportData, plant, selectedDate, subtitle }) {
  const { aeration, humidity, parameters, total, temperatures } = reportData;
  const daysInMonth = createUTCDatesInMonth(selectedDate);

  const [totals, setTotals] = useState();
  const [aerations, setAerations] = useState();
  const [humidityData, SetHumidityData] = useState();
  const [tempereturesData, setTemperetures] = useState();
  const [parametersData, setParametersData] = useState();
  const [showDataGraph, setShowDataGraph] = useState(false);
  const [showMovingAvg, setShowMovingAvg] = useState(false);

  const {
    handleFormatReportParameters,
    handleFormatReportTotal,
    handleFormatAeration,
    handleFormtTemperatueres,
    handleFormatHumidity,
  } = useThermometryReport();

  useEffect(() => {
    const formattedParameters = handleFormatReportParameters(
      parameters,
      plant,
      daysInMonth,
      showMovingAvg,
    );
    const formattedTotals = handleFormatReportTotal(total, plant, daysInMonth);
    const formattedAeration = handleFormatAeration(aeration, plant, daysInMonth);
    const formattedHumidity = handleFormatHumidity(humidity, plant, daysInMonth);
    const formattedTemperatures = handleFormtTemperatueres(temperatures, plant, daysInMonth);

    setParametersData(formattedParameters);
    setTotals(formattedTotals);
    setAerations(formattedAeration);
    SetHumidityData(formattedHumidity);
    setTemperetures(formattedTemperatures);
  }, [plant, showMovingAvg]);

  const handleActionModalDataGraph = useCallback(() => {
    const valueDataGraph = showDataGraph;
    setShowDataGraph(!valueDataGraph);
  }, [showDataGraph]);

  const handleActionAvg = useCallback(() => {
    // const valueDataGraph = showDataGraph;
    setShowMovingAvg(!showMovingAvg);
  }, [showMovingAvg]);

  return (
    <S.Wrapper>
      <S.Header>
        <S.Subtitle>
          <div>
            <strong>{plant}</strong>
            <span>{subtitle}</span>
          </div>

          {reportData.equipament && <strong>{reportData.equipament.description}</strong>}
        </S.Subtitle>
        <div>
          <ButtonGraphic handleClick={handleActionModalDataGraph} style={{ marginRight: '10px' }}>
            <BsGraphUp size={20} color="#fff" />
          </ButtonGraphic>
          <ButtonAvg handleClick={handleActionAvg}>
            {showMovingAvg ? 'Ocultar média móvel' : 'Mostrar média móvel'}
          </ButtonAvg>
        </div>
      </S.Header>
      {tempereturesData && (
        <S.Table>
          <thead>
            <tr>
              <th style={{ color: '#000000' }}>Data e Hora</th>
              {labelDays.map((labelDay) => (
                <th key={labelDay.day}>
                  {labelDay.day}
                  <br />
                  {labelDay.lastTelemetryOnDay}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {parametersData.map((temperatureSection) => (
              <tr>
                {temperatureSection.map((parameterData) => (
                  <S.Td
                    key={parameterData.key}
                    color={parameterData.color}
                    isLegend={parameterData.legend}
                  >
                    {parameterData.label}
                  </S.Td>
                ))}
              </tr>
            ))}

            <tr>
              {totals.map((totalSection) => (
                <S.Td
                  color={totalSection.color}
                  key={totalSection.key}
                  isLegend={totalSection.legend}
                >
                  {totalSection.label}
                </S.Td>
              ))}
            </tr>

            <tr>
              {tempereturesData.map((temperatureSection) => (
                <S.Td
                  color={temperatureSection.color}
                  key={temperatureSection.key}
                  isLegend={temperatureSection.legend}
                >
                  {temperatureSection.label}
                </S.Td>
              ))}
            </tr>

            <tr>
              {humidityData.map((humiditySection) => (
                <S.Td
                  color={humiditySection.color}
                  key={humiditySection.key}
                  isLegend={humiditySection.legend}
                >
                  {humiditySection.label}
                </S.Td>
              ))}
            </tr>

            <tr>
              {aerations.map((aerationSection) => (
                <S.Td
                  color={aerationSection.color}
                  key={aerationSection.key}
                  isLegend={aerationSection.legend}
                >
                  {aerationSection.label}
                </S.Td>
              ))}
            </tr>
          </tbody>
        </S.Table>
      )}

      {showDataGraph && (
        <ReportTemperatureGraph
          categories={labelDays}
          parameters={parameters}
          daysInMonth={daysInMonth}
          date={selectedDate}
          showMovingAvg={showMovingAvg}
        />
      )}
    </S.Wrapper>
  );
}
