import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import NumberFormat from 'react-number-format';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import FockinkAPI from '../../../../middlewares/Api';
import BottomButtons from '../../../../components/Buttons/BottomButtons';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import EnergyCostSVG from '../../../../components/EnergyCostSVG';
import PortalPermissions from '../../../../helpers/enums/PortalPermissions';
import EnergyCostHourDetail from './EnergyCostHourDetail';
import EnergyCostTaxesEquips from './EnergyCostTaxesEquips';
import { subGroups, taxTypes } from '../../../../helpers/energyCostHelper';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

export default (props) => {
  const [loading, setLoading] = React.useState(true);
  const [special, setSpecial] = React.useState(false);
  const [hasInters, setHasInters] = React.useState(false);
  const [isGroupB, setIsGroupB] = React.useState(false);
  const [isMono, setIsMono] = React.useState(false);
  const [completeForm, setCompleteForm] = React.useState(false);
  const [distributors, setDistributors] = React.useState([]);
  const hourObj = (start, finish) => ({
    start,
    finish,
    demand: '',
    demandPenalty: '',
    consumption: '',
  });

  const ufs = ['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG',
    'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'];

  const [data, setData] = React.useState({
    description: '',
    distributorId: '',
    subGroupId: '',
    taxTypeId: '',
    demand: 0,
    state: 'SP',
    city: '',
    consumerUnit: '',
    notes: '',
    equipaments: [],
    dateStart: new Date(),
    dateFinish: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    peakHour: { ...hourObj('18:00', '21:00') },
    commonHour: { ...hourObj('12:00', '12:00') },
    specialHour: { ...hourObj('10:00', '11:00') },
    intermediateHour1: { ...hourObj('17:00', '18:00') },
    intermediateHour2: { ...hourObj('21:00', '22:00') },
    taxes: {
      pis: 0,
      cofins: 0,
      icms: 0,
    },
  });

  React.useEffect(() => {
    const tfA = !!data.description && !!data.distributorId && !!data.subGroupId && !!data.taxTypeId
    && (isGroupB || !!data.demand) && !!data.state && !!data.city && !!data.consumerUnit
    && !!data.equipaments && data.equipaments.length > 0 && !!data.dateStart && !!data.dateFinish
    // && !!data.commonHour.start && !!data.commonHour.finish
    && (isGroupB || !!data.commonHour.demand) && (isGroupB || !!data.commonHour.demandPenalty)
    && !!data.commonHour.consumption
    && !!data.taxes.pis && !!data.taxes.cofins && !!data.taxes.icms;

    // eslint-disable-next-line no-nested-ternary
    const tfB = special ? (
      data.specialHour ? (!!data.specialHour.start && !!data.specialHour.finish
        && (isGroupB || !!data.specialHour.demand)
        && (isGroupB || !!data.specialHour.demandPenalty)
        && !!data.specialHour.consumption) : false
    ) : true;

    // eslint-disable-next-line no-nested-ternary
    const tfC = hasInters ? (
      data.intermediateHour1 ? (!!data.intermediateHour1.start && !!data.intermediateHour1.finish
        && (isGroupB || !!data.intermediateHour1.demand)
        && (isGroupB || !!data.intermediateHour1.demandPenalty)
        && !!data.intermediateHour1.consumption) : false
    ) : true;

    // eslint-disable-next-line no-nested-ternary
    const tfD = hasInters ? (
      data.intermediateHour2 ? (!!data.intermediateHour2.start && !!data.intermediateHour2.finish
        && (isGroupB || !!data.intermediateHour2.demand)
        && (isGroupB || !!data.intermediateHour2.demandPenalty)
        && !!data.intermediateHour2.consumption) : false
    ) : true;

    // eslint-disable-next-line no-nested-ternary
    const tfE = !isMono ? (
      data.peakHour ? (!!data.peakHour.start && !!data.peakHour.finish
        && (isGroupB || !!data.peakHour.demand)
        && (isGroupB || !!data.peakHour.demandPenalty)
        && !!data.peakHour.consumption) : false
    ) : true;

    setCompleteForm(tfA && tfB && tfC && tfD && tfE);
  }, [data, isGroupB]);

  const breadcrumb = {
    title: 'Cadastro do Tarifador',
    routes: [
      { to: '/settings', name: 'Configurações' },
      { to: '/energyCost', name: 'Tarifador' },
      { name: 'Cadastro de Tarifador' }],
  };

  React.useEffect(() => {
    FockinkAPI.get('/energyDistributors').then((response) => {
      setDistributors(response.data.data);
    });
  }, []);

  React.useEffect(() => {
    const taxType = taxTypes({ id: data.taxTypeId });
    if (data.taxTypeId && taxType) {
      setSpecial(taxType.taxType.indexOf('Especial') !== -1);
      setHasInters(taxType.taxType.indexOf('Branca') !== -1);

      const ismono = taxType.taxType.indexOf('Monômia') !== -1 || taxType.taxType.indexOf('Social') !== -1;
      setIsMono(ismono);
      if (ismono) {
        const ch = { ...data.commonHour, start: '12:00', finish: '12:00' };
        setData({ ...data, commonHour: ch });
      }
    }
  }, [data.taxTypeId]);

  React.useEffect(() => {
    const sg = subGroups({ id: data.subGroupId });
    if (data.subGroupId && sg && sg.subGroup.indexOf('B') === 0) {
      setIsGroupB(true);
    } else setIsGroupB(false);
  }, [data.subGroupId]);

  React.useEffect(() => {
    if (props.match.params.id === 'new') {
      setLoading(false);
    } else {
      setLoading(true);
      FockinkAPI.get(`/energyCosts/${props.match.params.id}`).then((response) => {
        setData({
          ...response.data.data,
          subGroupId: response.data.data.subGroup.id,
          taxTypeId: response.data.data.taxType.id,
          distributorId: response.data.data.distributor.id,
          equipaments: response.data.data.equipaments,
          specialHour: response.data.data.specialHour
            ? response.data.data.specialHour : { ...hourObj('10:00', '11:00') },
          intermediateHour1: response.data.data.intermediateHour1
            ? response.data.data.intermediateHour1 : { ...hourObj('17:00', '18:00') },
          intermediateHour2: response.data.data.intermediateHour2
            ? response.data.data.intermediateHour2 : { ...hourObj('21:00', '22:00') },
        });
        setLoading(false);
      });
    }
  }, [props.match.params.id]);

  const classes = useStyles();

  const handleChange = (event) => {
    const extra = {};
    if (event.target.name === 'subGroupId') {
      extra.taxTypeId = '';
    }
    setData({ ...data, [event.target.name]: event.target.value, ...extra });
  };

  const getTimeRanges = () => {
    const ret = [
      { from: '12:00', to: '12:00', color: '#FFF5E0' },
    ];

    if (!isMono && data.peakHour && data.peakHour.start && data.peakHour.finish) {
      ret.push({ from: data.peakHour.start, to: data.peakHour.finish, color: '#FFE0E0' });
    }

    if (special && data.specialHour && data.specialHour.start && data.specialHour.finish) {
      ret.push({ from: data.specialHour.start, to: data.specialHour.finish, color: '#E7FFE0' });
    }

    if (hasInters && data.intermediateHour1
      && data.intermediateHour1.start && data.intermediateHour1.finish) {
      ret.push({ from: data.intermediateHour1.start, to: data.intermediateHour1.finish, color: '#E0FCFF' });
    }

    if (hasInters && data.intermediateHour2
      && data.intermediateHour2.start && data.intermediateHour2.finish) {
      ret.push({ from: data.intermediateHour2.start, to: data.intermediateHour2.finish, color: '#EAE0FF' });
    }

    return ret;
  };

  const handleDateChange = (date, input) => {
    setData({ ...data, [input]: date });
  };

  const saveData = () => {
    const dt = {
      data: {
        ...data,
        dateStart: new Date(data.dateStart).toISOString().substr(0, 10),
        dateFinish: new Date(data.dateFinish).toISOString().substr(0, 10),
        demand: parseFloat(data.demand),
        consumerUnit: parseInt(data.consumerUnit, 10),
        peakHour: {
          ...data.peakHour,
          demand: parseFloat((data.peakHour || {}).demand),
          demandPenalty: parseFloat((data.peakHour || {}).demandPenalty),
          consumption: parseFloat((data.peakHour || {}).consumption),
        },
        commonHour: {
          ...data.commonHour,
          demand: parseFloat(data.commonHour.demand),
          demandPenalty: parseFloat(data.commonHour.demandPenalty),
          consumption: parseFloat(data.commonHour.consumption),
        },
        specialHour: {
          ...data.specialHour,
          demand: parseFloat((data.specialHour || {}).demand),
          demandPenalty: parseFloat((data.specialHour || {}).demandPenalty),
          consumption: parseFloat((data.specialHour || {}).consumption),
        },
        intermediateHour1: {
          ...data.intermediateHour1,
          demand: parseFloat((data.intermediateHour1 || {}).demand),
          demandPenalty: parseFloat((data.intermediateHour1 || {}).demandPenalty),
          consumption: parseFloat((data.intermediateHour1 || {}).consumption),
        },
        intermediateHour2: {
          ...data.intermediateHour2,
          demand: parseFloat((data.intermediateHour2 || {}).demand),
          demandPenalty: parseFloat((data.intermediateHour2 || {}).demandPenalty),
          consumption: parseFloat((data.intermediateHour2 || {}).consumption),
        },
        taxes: {
          ...data.taxes,
          pis: parseFloat(data.taxes.pis),
          cofins: parseFloat(data.taxes.cofins),
          icms: parseFloat(data.taxes.icms),
        },
      },
    };

    if (isGroupB) {
      delete dt.data.demand;
      delete dt.data.peakHour.demand;
      delete dt.data.peakHour.demandPenalty;
      delete dt.data.commonHour.demand;
      delete dt.data.commonHour.demandPenalty;
      delete dt.data.specialHour.demand;
      delete dt.data.specialHour.demandPenalty;
      delete dt.data.intermediateHour1.demand;
      delete dt.data.intermediateHour1.demandPenalty;
      delete dt.data.intermediateHour2.demand;
      delete dt.data.intermediateHour2.demandPenalty;
    }
    if (!special) {
      delete dt.data.specialHour;
    }
    if (isMono) {
      delete dt.data.peakHour;
    }
    if (!hasInters) {
      delete dt.data.intermediateHour1;
      delete dt.data.intermediateHour2;
    }
    delete dt.data.id;
    delete dt.data.organizationId;
    delete dt.data.accountId;
    delete dt.data.distributor;
    delete dt.data.subGroup;
    delete dt.data.taxType;
    delete dt.data.status;
    delete dt.data.requestCharge;

    if (props.match.params.id === 'new') {
      dt.method = 'post';
      dt.url = '/energyCosts';
    } else {
      dt.method = 'put';
      dt.url = `/energyCosts/${props.match.params.id}`;
    }
    return dt;
  };

  const deleteData = () => ({ url: `/energyCosts/${props.match.params.id}` });
  if (loading) return <Skeleton variant="rect" height={500} width="100%" animation="wave" />;
  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1}>

        <Grid item lg={3} md={4} xs={12}>
          <TextField variant="filled" name="description" onChange={handleChange} value={data.description || ''} label="Descrição" />
        </Grid>

        <Grid item lg={3} md={4} xs={12}>
          <FormControl variant="filled">
            <InputLabel>Distribuidora</InputLabel>
            <Select
              value={data.distributorId}
              onChange={handleChange}
              name="distributorId"
            >
              {distributors
                .map((m) => <MenuItem key={m.id} value={m.id}>{m.description}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={3} md={4} xs={12}>
          <FormControl variant="filled">
            <InputLabel>Subgrupo</InputLabel>
            <Select
              value={data.subGroupId}
              onChange={handleChange}
              name="subGroupId"
            >
              {subGroups()
                .map((m) => <MenuItem key={m.id} value={m.id}>{m.subGroup}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={3} md={4} xs={12}>
          <FormControl variant="filled">
            <InputLabel>Modalidade Tarifária</InputLabel>
            <Select
              disabled={!data.subGroupId}
              value={data.taxTypeId}
              onChange={handleChange}
              name="taxTypeId"
            >
              {!data.subGroupId && <MenuItem value="ndef">Selecione Subgrupo</MenuItem>}
              {taxTypes({ subGroupId: data.subGroupId })
                .map((m) => <MenuItem key={m.id} value={m.id}>{m.taxType}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={3} md={4} xs={12}>
          <NumberFormat
            value={!isGroupB ? data.demand : ''}
            name="demand"
            disabled={isGroupB}
            label="Demanda Contratada (kW)"
            customInput={TextField}
            variant="filled"
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={(v) => handleChange({ target: { name: 'demand', value: v.floatValue } })}
          />
        </Grid>

        <Grid item lg={3} md={4} xs={12}>
          <FormControl variant="filled">
            <InputLabel>UF</InputLabel>
            <Select
              value={data.state}
              onChange={handleChange}
              name="state"
            >
              {ufs.map((m) => <MenuItem key={m} value={m}>{m}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>

        <Grid item lg={3} md={4} xs={12}>
          <TextField variant="filled" name="city" onChange={handleChange} value={data.city || ''} label="Cidade" />
        </Grid>

        <Grid item lg={3} md={4} xs={12}>
          <NumberFormat
            value={data.consumerUnit}
            name="consumerUnit"
            label="UC"
            customInput={TextField}
            variant="filled"
            thousandSeparator="."
            decimalSeparator=","
            onValueChange={(v) => handleChange({ target: { name: 'consumerUnit', value: v.floatValue } })}
          />
        </Grid>

        <Grid item lg={3} md={4} xs={12}>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            style={{ width: '100%' }}
          >
            <KeyboardDatePicker
              margin="normal"
              label="Início"
              format="dd/MM/yyyy"
              value={data.dateStart}
              name="dateStart"
              onChange={(e) => handleDateChange(e, 'dateStart')}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item lg={3} md={4} xs={12}>
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            style={{ width: '100%' }}
          >
            <KeyboardDatePicker
              margin="normal"
              label="Fim"
              format="dd/MM/yyyy"
              value={data.dateFinish}
              name="dateFinish"
              onChange={(e) => handleDateChange(e, 'dateFinish')}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12}><hr /></Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>

            <Grid item lg={2} md={2} xs={12}>
              <EnergyCostSVG
                timeRanges={getTimeRanges()}
              />
            </Grid>

            <Grid item lg={8} md={8} xs={12}>
              {!isMono && <EnergyCostHourDetail data={data} setData={setData} section="peakHour" title="Horário de Ponta" color="#FFE0E0" hasDemand={!isGroupB} />}
              {hasInters && <EnergyCostHourDetail data={data} setData={setData} section="intermediateHour1" title="Horário Intermediário 1" color="#E0FCFF" hasDemand={!isGroupB} />}
              {hasInters && <EnergyCostHourDetail data={data} setData={setData} section="intermediateHour2" title="Horário Intermediário 2" color="#EAE0FF" hasDemand={!isGroupB} />}
              <EnergyCostHourDetail data={data} setData={setData} section="commonHour" title="Horário Fora de Ponta" color="#FFF5E0" hasDemand={!isGroupB} lockHour />
              {special && <EnergyCostHourDetail data={data} setData={setData} section="specialHour" title="Horário Especial (Irrigação)" color="#E7FFE0" hasDemand={!isGroupB} />}
              <EnergyCostTaxesEquips data={data} setData={setData} />
            </Grid>

            <Grid item lg={2} md={2} xs={12}>
              <TextField
                label="Observações"
                name="notes"
                onChange={handleChange}
                multiline
                rows={19}
                defaultValue={data.notes}
                variant="filled"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className={`${classes.textAlign} ${classes.bottomButtons}`}>
          <BottomButtons
            saveData={saveData}
            deleteData={props.match.params.id !== 'new' && deleteData}
            backTo="/energyCost"
            notComplete={!completeForm}
            canSave={PortalPermissions.USERS_MANAGE.value}
          />
        </Grid>
      </Grid>
    </>
  );
};
