import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import DataTable from 'react-data-table-component';
import GetAppIcon from '@material-ui/icons/GetApp';
import XLSX from 'xlsx-js-style'; // Exporta em Excel
import pdfMake from 'pdfmake/build/pdfmake'; // exporta em pdf
// import pdfFonts from 'pdfmake/build/vfs_fonts'; // exporta em pdf
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { RiCloseFill } from 'react-icons/ri';
// import { RowingTwoTone } from '@material-ui/icons';
import { headerCell, dataCell } from '../helpers/AuxExcelExport';
import { dateNow, dateForExport } from '../helpers/AuxInfo';
import { PdfFooter, PdfHeader } from '../components/reports/pdf';

// pdfMake.vfs = pdfFonts.pdfMake.vfs; // exporta em pdf

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #000',
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const columns = [
  // { name: 'Serviço', selector: 'description', grow: 2, sortable: true },
  {
    name: '',
    selector: 'calc',
    sortable: true,
    minWidth: 130,
  },
  {
    name: '',
    selector: 'result',
    sortable: true,
  },
  // { name: 'Ações', selector: 'actions', sortable: true },
];
// function BasicTooltip() {
//   return (
//     <Tooltip title="Delete">
//       <IconButton />
//     </Tooltip>
//   );
// }
export default function StatisticsModal({ statistics, close }) {
  // console.log('statistics', statistics);
  const classes = useStyles();
  const [data, setData] = useState([]);
  // const { reportData } = data;
  // const [plotDataMotors, setPlotDataMotors] = useState(reportData);
  // const [selectedGrain, setSelectedGrain] = useState();
  const [csv, setCsv] = useState();
  const [fileName, setFileName] = useState();

  React.useEffect(() => {
    if (csv) {
      clickDownload();
    }
    setCsv(undefined);
  }, [csv]);

  function clickDownload() {
    document.getElementById('link').click();
  }

  const [dataExcel, setDataExcel] = useState([]);

  useEffect(() => {
    if (statistics) {
      const newStatistics = [
        {
          calc: 'Contagem:',
          result: `${statistics.count} `,
        },
        {
          calc: 'Média:',
          result: `${statistics.avg.toFixed(2)} °C`,
        },
        {
          calc: 'Mediana:',
          result: `${statistics.median} °C`,
        },
        {
          calc: 'Moda:',
          result: `${statistics.mode} °C`,
        },
        {
          calc: 'Desvio Padrão:',
          result: `${statistics.standardDeviation.toFixed(2)} °C `,
        },
        {
          calc: 'Intervalo:',
          result: `${statistics.interval.toFixed(2)} °C`,
        },
        {
          calc: 'Minino:',
          result: `${statistics.min} °C`,
        },
        {
          calc: 'Maximo:',
          result: `${statistics.max} °C`,
        },

        {
          calc: 'Nível de confiaça:',
          result: `${statistics.trust.toFixed(2)} `,
        }];
      setData(newStatistics);
    }
  }, [statistics]);
  const open = !!statistics;

  useEffect(() => {
    setDataExcel([
      [headerCell({ value: 'ESTATÍSTICA DESCRITIVA' })],
      [headerCell({
        value: `${statistics?.plant?.description || statistics?.plant?.technicalDescription} ${'-'} ${statistics?.equipament?.clientDescription || statistics?.equipament?.description}`,
      })],
      [
        headerCell({ value: '' }),
      ],
      [
        headerCell({ value: 'Dados da Leitura' }),
      ],
      [
        headerCell({ value: 'Data' }),
        dataCell({
          value: moment.unix(statistics?.ts).format('DD/MM/YYYY'),
        }),
      ],
      [
        headerCell({ value: 'Hora' }),
        dataCell({
          value: moment.unix(statistics?.ts).format('HH:MM:SS'),
        }),
      ],
      [
        headerCell({ value: '' }),
      ],
      [
        headerCell({ value: 'Sensores:' }),
        dataCell({ value: statistics?.count }),
        headerCell({ value: 'Anéis:' }),
        dataCell({ value: statistics?.arcs }),
        headerCell({ value: 'Cabos:' }),
        dataCell({ value: statistics?.cables }),
      ],
      [
        headerCell({ value: 'Média:' }),
        dataCell({ value: `${statistics?.avg.toFixed(2)} °C` }),
      ],
      [
        headerCell({ value: 'Mediana:' }),
        dataCell({ value: `${statistics?.median.toFixed(2)} °C` }),
      ],
      [
        headerCell({ value: 'Moda:' }),
        dataCell({ value: `${statistics?.mode.toFixed(2)} °C` }),
      ],
      [
        headerCell({ value: 'Desvio Padrão:' }),
        dataCell({ value: `${statistics?.standardDeviation.toFixed(2)} °C` }),
      ],
      [
        headerCell({ value: 'Intervalo:' }),
        dataCell({ value: `${statistics?.interval.toFixed(2)} °C` }),
      ],
      [
        headerCell({ value: 'Mínimo:' }),
        dataCell({ value: `${statistics?.min.toFixed(2)} °C` }),
      ],
      [
        headerCell({ value: 'Máximo:' }),
        dataCell({ value: `${statistics?.max.toFixed(2)} °C` }),
      ],
      [
        headerCell({ value: 'Confiança:' }),
        dataCell({ value: `${statistics?.trust.toFixed(2)}` }),
      ],
      [
        headerCell({ value: 'Exportado dia: ' }),
        dataCell({ value: dateNow() }),
      ],
    ]);
    // console.log('dataExcel ', dataExcel);
  }, [statistics]);

  function generateCSV() {
    let csvFile = 'data:text/csv;charset=utf-8,\uFEFF';
    const csvArray = [
      [
        'ESTATÍSTICA DESCRITIVA',
      ],
      [
        // 'Planta Azul - Silo Azul',
        `${statistics?.plant?.description || statistics?.plant?.technicalDescription} ${'-'} ${statistics?.equipament?.clientDescription || statistics?.equipament?.description}`,
      ],
      [
        '',
      ],
      [
        'Dados da Leitura',
      ],
      [
        'Data',
        moment.unix(statistics?.ts).format('DD/MM/YYYY'),
      ],
      [
        'Hora',
        moment.unix(statistics?.ts).format('HH:MM:SS'),
      ],
      [
        '',
      ],
      [
        'Sensores',
        `${statistics.count} `,
        'Anéis',
        `${statistics.arcs}`,
        'Cabos',
        `${statistics.cables}`,
      ],
      [
        'Média',
        `${statistics.avg.toFixed(2)} °C`,
      ],
      [
        'Mediana',
        `${statistics.median.toFixed(2)} °C`,
      ],
      [
        'Moda',
        `${statistics.mode.toFixed(2)} °C`,
      ],
      [
        'Desvio Padrão',
        `${statistics.standardDeviation.toFixed(2)} °C`,
      ],
      [
        'Intervalo',
        `${statistics.interval.toFixed(2)} °C`,
      ],
      [
        'Mínimo',
        `${statistics.min.toFixed(2)} °C`,
      ],
      [
        'Máximo',
        `${statistics.max.toFixed(2)} °C`,
      ],

      [
        'Nível de Confiança',
        `${statistics.trust.toFixed(2)}`,
      ],
      [
        'Exportado dia: ',
        dateNow(),
      ],
    ];

    csvArray.forEach((line) => {
      csvFile += line.join(';');
      csvFile += '\n';
    });
    const encodedUri = encodeURI(csvFile);
    // const n = new Date().getTime();
    setFileName(`report-estatistica-descritiva-${dateForExport()}`);
    setCsv(encodedUri);
  }

  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(dataExcel);// json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Statistics');
    XLSX.writeFile(wb, `report-estatistica-descritiva-${dateForExport()}.xlsx`);
  };

  const handleExportPDF = () => {
    /* const pdfHeader = [
      {
        text: 'ESTATÍSTICA DESCRITIVA',
        fontSize: 14,
        bold: true,
        margin: [15, 20, 0, 45],
      },
    ]; */
    const pdfContent = [
      {
        text: 'ESTATÍSTICA DESCRITIVA',
        fontSize: 14,
        bold: true,
        alignment: 'center',
        margin: [0, 20, 0, 20],
      },
      {
        text: `${statistics?.plant?.description || statistics?.plant?.technicalDescription} ${'-'} ${statistics?.equipament?.clientDescription || statistics?.equipament?.description}`,
        fontSize: 12,
        bold: true,
        alignment: 'center',
        margin: [0, 0, 0, 10],
      },
      {
        text: `Leitura do Dia ${moment.unix(statistics.ts).format('DD/MM/YYYY')} | Horário: ${moment.unix(statistics.ts).format('HH:MM:SS')}`,
        fontSize: 11,
        bold: true,
        margin: [0, 5, 0, 5],
      },
      {
        text: `Sensores: ${statistics.count}, Anéis: ${statistics.arcs}, Cabos: ${statistics.cables}`,
        fontSize: 12,
        bold: false,
        margin: [0, 0, 0, 5],
      },
      {
        style: 'tableExample',
        fontSize: 12,
        bold: false,
        alignment: 'justify',
        table: {
          headerRows: 1,
          widths: ['*', '*'],
          body: [
            [
              '',
              '',
            ],
            [
              { text: 'Média:' },
              { text: `${statistics.avg.toFixed(2)} °C`, alignment: 'right' },
            ],
            [
              { text: 'Mediana:' },
              { text: `${statistics.median.toFixed(2)} °C`, alignment: 'right' },
            ],
            [
              { text: 'Moda:' },
              { text: `${statistics.mode.toFixed(2)} °C`, alignment: 'right' },
            ],
            [
              { text: 'Desvio Padrão:' },
              { text: `${statistics.standardDeviation.toFixed(2)} °C`, alignment: 'right' },
            ],
            [
              { text: 'Intervalo:' },
              { text: `${statistics.interval.toFixed(2)} °C`, alignment: 'right' },
            ],
            [
              { text: 'Mínimo:' },
              { text: `${statistics.min.toFixed(2)} °C`, alignment: 'right' },
            ],
            [
              { text: 'Máximo:' },
              { text: `${statistics.max.toFixed(2)} °C`, alignment: 'right' },
            ],
            [
              { text: 'Nível de Confiança:' },
              { text: `${statistics.trust.toFixed(2)}`, alignment: 'right' },
            ],
          ],
        },
        layout: 'lightHorizontalLines',
      },
    ];
    /* const pdfFooter = [
      {
        text: `Exportado dia: ${dateNow()}`,
        fontSize: 10,
        bold: false,
        margin: [15, 20, 0, 45],
      },
    ]; */

    const pdfDefinitions = {
      pageSize: 'A4',
      pageMargins: [15, 50, 15, 40],
      header: PdfHeader(),
      content: [pdfContent],
      footer: PdfFooter(),
    };

    pdfMake.createPdf(pdfDefinitions).download(`report-estatistica-descritiva-${dateForExport()}`);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div style={{ textAlign: 'right', marginBottom: -20, marginRight: -30, marginTop: -20 }}>
              <IconButton onClick={close}>
                <RiCloseFill size={30} color="#556E7B" />
              </IconButton>
            </div>
            {/* <Typography
              variant="h5"
            >
              {statistics?.plant?.description || statistics?.plant?.technicalDescription }
              {' '}
              -
              {' '}
              {statistics?.equipament?.clientDescription || statistics?.equipament?.description }
            </Typography> */}
            <h4>ESTATÍSTICA DESCRITIVA</h4>
            {statistics?.count === 0 ?
              <Alert severity="error">Não existem dados para serem mostrados</Alert>
              : (
                <div>
                  <p>
                    Sensores:
                    {' '}
                    {statistics?.count}
                    ,
                    {' '}
                    Anéis:
                    {' '}
                    {statistics?.arcs}
                    ,
                    {' '}
                    Cabos:
                    {' '}
                    {statistics?.cables}
                  </p>
                  <DataTable
                    noDataComponent="Não existem dados para serem mostrados"
                    columns={columns}
                    data={data}
                      // title="ESTATÍSTICA DESCRITIVA"
                      // style={{ marginTop: -50 }}
                    noTableHead
                  />
                  <ButtonGroup variant="contained" color="primary">
                    <Button onClick={() => { generateCSV(); }}>
                      <GetAppIcon size={22} style={{ padding: 5 }} />
                      CSV
                    </Button>
                    <a id="link" download={`${fileName}.csv`} href={csv} style={{ display: 'none' }}>
                      download
                    </a>
                    <Button onClick={() => handleExportExcel()}>
                      <GetAppIcon size={22} style={{ padding: 5 }} />
                      XLSX
                    </Button>
                    <Button onClick={() => { handleExportPDF(); }}>
                      <GetAppIcon size={22} style={{ padding: 5 }} />
                      PDF
                    </Button>
                  </ButtonGroup>
                </div>
              )
          }
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
