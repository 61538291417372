import React from 'react';
import {
  Grid,
} from '@material-ui/core';

export function TextValue({ header, value }) {
  return (
    <Grid
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      container
      direction="row"
      style={{ border: '1px solid #000' }}
    >
      <Grid item sm={12} style={{ background: '#CECECE', textAlign: 'center', borderBottom: '1px solid #000', width: '100%' }}>
        {header}
      </Grid>
      <Grid item sm={12} style={{ textAlign: 'center' }}>
        {value}
      </Grid>
    </Grid>
  );
}
