import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 720px;

  form {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > span {
      font-size: 1rem;
      margin-bottom: 0.6rem;
    }

    .confirm-button {
      display: block;
      margin-left: auto;
      margin-top: 1rem;
      padding: 1rem 0;
    }

    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0.4rem;
    }

    .success {
      color: #2fbf26;
      text-transform: uppercase;
    }

    .error {
      color: #c53030;
      text-transform: uppercase;
    }
  }
`;
