import React from 'react';
import '../../../../../App.css';
import ReactApexChart from 'react-apexcharts';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FockinkAPI from '../../../../../middlewares/Api';
import { formatDate } from '../../../../../helpers/Intl';

export default (props) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(undefined);
  const [state, setState] = React.useState(undefined);
  const [days, setDays] = React.useState(5);

  const callApi = () => {
    setLoading(true);
    FockinkAPI.get(
      `/dashboards/thermometry/${props.sensorData.equipamentId}/charts/sensor`,
      {
        params: {
          ...props.sensorData,
          date: formatDate(props.sensorData.date, 'yyyy-MM-dd'),
          days,
          sen: props.sensorData.sen - 1,
        },
      },
    )
      .then((response) => {
        setData(response.data.data.chartData);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const errTranslate = (t, sinal, type) => {
    if (t === 999) {
      return 'OFF - Sensor desconectado ou com defeito';
    }
    if (t === 998) {
      return 'N/D - Não disponível - Data hora do módulo termometria ';
    }
    if (t === 997) {
      return 'ERR - Erro ao realizar download da leitura';
    }
    if (type !== 'humidity' && t > 69) {
      return 'OFF';
    }
    return `${t} ${sinal || ''}`.trim();
  };

  const calcBiggestTemperature = () => {
    const bigestTemp =
      Math.max(...data.series[0].data, ...data.series[1].data) + 2;
    return bigestTemp >= 70 ? 70 : parseInt(bigestTemp, 10);
  };

  React.useEffect(() => {
    if (props.sensorData) {
      callApi();
    }
  }, [props.sensorData, days]);

  React.useEffect(() => {
    if (data) {
      const biggestTemperature = calcBiggestTemperature();
      setState({
        series: data.series.map((a) => ({ ...a, data: a.data.reverse() })),
        options: {
          chart: {
            height: 450,
            width: '100%',
            type: 'line',
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          colors: ['#39FD6A', '#9130BF', '#009AFF'],
          dataLabels: {
            enabled: false,
          },
          title: {
            show: false,
          },
          legend: {
            position: 'top',
          },
          markers: {
            colors: ['#009AFF', '#39FD6A', '#9130BF'],
          },
          xaxis: {
            categories: data.categories.map((m) => formatDate(m)).reverse(),
          },
          yaxis: [
            {
              max: biggestTemperature,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
              },
              title: {
                text: 'Temperatura do sensor e ambiente (°C)',
              },
            },
            {
              max: biggestTemperature,
              show: false,
            },
            {
              max: 100,
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
              },
              title: {
                text: 'Umidade relativa (%)',
              },
            },
          ],
          tooltip: {
            // followCursor: true,
            y: {
              formatter(y, x) {
                if (y) {
                  if (x.seriesIndex === 2) {
                    return `${errTranslate(y, '%', 'humidity')}`;
                  }
                  return `${errTranslate(y, '°C', 'temperature')}`;
                }
                return y;
              },
            },
          },
        },
      });
    }
  }, [data]);

  if (loading) {
    return (
      <Skeleton variant="rect" height={350} width="100%" animation="wave" />
    );
  }

  if (!props.sensorData || !state) {
    return <></>;
  }

  return (
    <div style={{ minWidth: '900px', width: '100%' }}>
      <Grid container spacing={1}>
        <Grid item lg={6} md={6} xs={12}>
          <h3 style={{ fontWeight: 400 }}>
            {`Resistência de temperatura do sensor ${props.sensorData.sen} / cabo ${props.sensorData.cab} / arco ${props.sensorData.arc}`}
          </h3>
        </Grid>
        <Grid item lg={6} md={6} xs={12} style={{ textAlign: 'right' }}>
          <ToggleButtonGroup
            value={days}
            exclusive
            onChange={(event, currentSolution) => {
              if (currentSolution !== null) {
                setDays(currentSolution);
              }
            }}
          >
            <ToggleButton value={5} aria-label="all">
              5 Dias
            </ToggleButton>
            <ToggleButton value={10} aria-label="all">
              10 Dias
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={12}>
          {data.logs && data.logs.length > 0 && (
          <>
            <h2
              style={{
                color: '#dc3f3f',
                border: '1px solid',
                padding: '10px',
                textAlign: 'center',
                marginBottom: '0',
                borderBottom: '0',
              }}
            >
              {`ATENÇÃO! Sensor com ${data.logs.length} ${
                data.logs.length > 1 ? 'erros' : 'erro'
              }!`}
            </h2>
            <div style={{ border: '1px solid #dc3f3f' }}>
              {data.logs.map((l, i) => (
                <h4
                  key={l.date}
                  style={{ fontWeight: '400', textAlign: 'center' }}
                >
                  {`${i + 1}) ${errTranslate(l.t)} - ${formatDate(l.date)}`}
                </h4>
              ))}
            </div>
          </>
          )}
        </Grid>
      </Grid>
      <div style={{ width: '100%' }}>
        <ReactApexChart
          options={state.options}
          series={state.series}
          height={state.options.chart.height}
        />
      </div>
    </div>
  );
};
