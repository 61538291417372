import React from 'react';
import ShareIcon from '@material-ui/icons/Share';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Ellipsis } from 'react-spinners-css';
import * as S from './styles';
import { ProgressBarWithLabel } from '../../../../ProgressBarWithLabel';
import { formatName } from '../../../helpers';
import { useDashboardContext } from '../../../../../hooks/dashboard';
import { ChartMotor } from '../ChartMotor';

export function MotorInfoCard(props) {
  const [serie, setSerie] = React.useState();
  const [width, setWidth] = React.useState(window.innerWidth);
  const history = useHistory();

  const { dataMotorUnitChart, aerationChartLoadgin, setPlantToRequestData } = useDashboardContext();

  const formatDataChart = () => {
    const chartData = dataMotorUnitChart.find(
      (i) => i.nameUnit === props.name && i.sub === props.subarea,
    );

    if (chartData) {
      setSerie(chartData);
    }
  };

  React.useEffect(() => {
    if (dataMotorUnitChart) {
      formatDataChart();
    }
  }, [dataMotorUnitChart]);

  const originalTheme = useTheme();

  React.useEffect(() => {
    if (window) {
      const resize = () => {
        if (window.innerWidth) {
          setWidth(window.innerWidth);
        }
      };
      window.addEventListener('resize', resize);

      return () => {
        window.removeEventListener('resize', resize);
      };
    }
  }, []);

  return (
    <S.Container
      onClick={() => setPlantToRequestData(props.plantId, props.name)}
      style={{
        marginRight: !props.existData ? '25%' : '',
        marginLeft: !props.existData ? '25%' : '',
      }}
      size={width}
    >
      <S.NameContainer>
        {formatName(props.name, props.subarea)}

        <div style={{ marginLeft: '8px' }}>
          {props.haveAerationShared ? (
            <Tooltip title="Tem aeração compartilhada">
              <ShareIcon fontSize="small" />
            </Tooltip>
          ) : null}
        </div>
        <div style={{ marginLeft: '8px' }}>
          {props.failMotor ? (
            <Tooltip title="Tem falha na comunicação">
              <ErrorOutlineIcon fontSize="small" />
            </Tooltip>
          ) : null}
        </div>
      </S.NameContainer>
      <S.Header>
        <S.ContainerHour>
          <S.Hours>{props.hours}</S.Hours>
          <S.LegendHours>Horas totais de aeração</S.LegendHours>
        </S.ContainerHour>
        <ProgressBarWithLabel
          trailColor={originalTheme.colors.chartBlue}
          r="65"
          g="108"
          b="224"
          percent={props.percent}
          // description={<S.LegendHours>Motores ligados</S.LegendHours>}
        />
      </S.Header>

      <S.Loader>{aerationChartLoadgin ? <Ellipsis /> : null}</S.Loader>

      <S.Chart>
        {serie && (
        <ChartMotor
          series={serie.dates}
        />
        )}
      </S.Chart>
      {props.equipamentId && (
      <button
        type="button"
        style={{ fontSize: 14.5 }}
        onClick={() => history.push(`thermometryDash/${props.equipamentId}`)}
      >
        Visualizar Equipamento
      </button>
      )}

    </S.Container>
  );
}
