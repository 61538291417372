import React from 'react';

const colors = {
  borda: '#C0C0C0',
  silo: '#D6E0E6', // '#CCCCCC',
  cable: '#556E7B', // 'teal',
  textColor: '#FFFFFF',
};

export function FlatBottomSilo({ modal, mobile }) { // Silo Fundo Plano
  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={modal ? '681.82px' : !mobile ? '300px' : '200px'}
        height={modal ? '681.82px' : !mobile ? '300px' : '200px'}
        xmlSpace="preserve"
        viewBox={modal ? '0 0 200 200' : '0 0 200 200'}
      >

        <polygon
          stroke={colors.borda}
          strokeWidth="0"
          fill={colors.silo}
          points="13,186 13,43 100,0 187,43 187,186 200,186 200,200 0,200 0,186 "
        />
      </svg>
    </div>
  );
}

export function SemiConicalBottomSilo({ modal }) { // Silo Fundo Semi V
  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={modal ? '630px' : '330px'}
        height={modal ? '700px' : '351px'}
        xmlSpace="preserve"
        viewBox={modal ? '0 0 200 220' : '0 0 230 251'}
      >
        <path
          stroke={colors.borda}
          strokeWidth="0"
          fill={colors.silo}
          d="M13 185c0,-142 0,-142 0,-142l87 -43 87 43 0 142 13 0 0 15 -13 0 -43 21 -87 0 -44 -21 -13 0 0 -15 13 0z"
        />
      </svg>
    </div>
  );
}

export function ConicalBottomSilo({ modal, mobile }) { // Silo Fundo V
  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={modal ? '730px' : !mobile ? '300px' : '200px'}
        height={modal ? '770px' : '350px'}
        xmlSpace="preserve"
        viewBox="0 0 200 250"
      >
        <path
          stroke={colors.borda}
          strokeWidth="0"
          fill={colors.silo}
          d="M13 186c0,-143 0,-143 0,-143l87 -43 87 43 0 143 13 0 0 14 -13 0 -87 42 -87 -42 -13 0 0 -14 13 0z"
        />
      </svg>
    </div>
  );
}

export function MelitaBottomSilo({ modal, mobile }) { // Silo Fundo W Melita
  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={modal ? '730px' : !mobile ? '300px' : '200px'}
        height={modal ? '770px' : '350px'}
        xmlSpace="preserve"
        viewBox="0 0 200 250"
      >
        <path
          stroke={colors.borda}
          strokeWidth="0"
          fill={colors.silo}
          d="M13 186c0,-143 0,-143 0,-143l87 -43 87 43 0 143 13 0 0 14 -13 0 -46 42 -41 -42 -42 42 -45 -42 -13 0 0 -14 13 0z"
        />
      </svg>
    </div>
  );
}

export function ConicalElevateBottomSilo({ modal }) { // Silo Fundo V Elevado
  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={modal ? '730px' : '300px'}
        height={modal ? '770px' : '350px'}
        xmlSpace="preserve"
        viewBox="0 0 200 250"
      >
        <path
          stroke={colors.borda}
          strokeWidth="0"
          fill={colors.silo}
          d="M13 185c0,-142 0,-142 0,-142l87 -43 87 43 0 142 13 0 0 15 -13 0 0 42 -10 0 0 -37 -18 8 0 29 -10 0 0 -24 -17 8 0 16 -10 0 0 -11 -17 9 0 2 -10 0 0 -3 -17 -8 0 11 -10 0 0 -16 -18 -8 0 24 -10 0 0 -29 -17 -8 0 37 -10 0 0 -42 -13 0 0 -15 13 0z"
        />

      </svg>
    </div>
  );
}
