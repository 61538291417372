import React from 'react';
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import jwtDecode from 'jwt-decode';
import FockinkAPI from '../../../middlewares/Api';
import { FilesToDownload } from '../Dashes/FilasToDownloadList';
import BackButton from '../../../components/Buttons/BackButton';
import Breadcrumbs from '../../../components/Breadcrumbs';
// import EquipCard from '../../../components/EquipCard';

// Solutions
const solutions = [];
solutions[1] = 'irrigation';
solutions[2] = 'thermometry';
solutions[3] = 'automation';
solutions[4] = 'photovoltaic';
solutions[5] = 'weatherStation';

const useStyles = makeStyles({
  noMargin: {
    margin: 0,
  },
  toggleButton: {
    paddingTop: 0,
    paddingBottom: 0,
    height: '49px',
  },
  smallIcon: {
    height: '30px',
  },
});

export default () => {
  const [inputs, setInputs] = React.useState([]);
  const [plants, setPlants] = React.useState([]);
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [solution, setSolution] = React.useState({ sys: '*', plant: '*' });
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const history = useHistory();
  const token = localStorage.getItem('@FockinkAPP:token');
  const { systemIds } = jwtDecode(token);

  const breadcrumb = {
    title: 'Consulta de Documentos',
    routes: [{ name: 'Documentos' }],
  };

  React.useEffect(() => {
    const sid = sysNumb[solution.sys];
    const filterPlant =
      solution.plant === '*'
        ? inputs
        : inputs.filter((f) => f.plantId === solution.plant);
    const filterSys =
      solution.sys === '*'
        ? filterPlant
        : filterPlant.filter((f) => f.operationId === sid);

    setDtCl({ ...dtCl, data: filterSys });
  }, [solution]);

  React.useEffect(() => {
    setLoading(true);
    FockinkAPI.get('/dashboards/equipaments?docsInfo=true&plantInfo=true').then(
      (response) => {
        setInputs(response.data.data.equipaments);
        setPlants(response.data.data.plants);
        setLoading(false);
      },
    );
  }, []);

  const ExpandedComponent = ({ data }) => (
    <div style={{ padding: '15px', background: 'white' }}>
      <FilesToDownload
        files={data.files.map((file) => ({
          file,
          fileName: file.split('/').pop(),
        }))}
      />
    </div>
  );

  const sys = {
    1: 'Irrigação',
    2: 'Termometria',
    3: 'Automação',
    4: 'Fotovoltaica',
    5: 'Estação Meteorológica',
  };

  const sysNumb = {
    irrigation: 1,
    thermometry: 2,
    automation: 3,
    photovoltaic: 4,
    weatherStation: 5,
  };

  React.useEffect(() => {
    const columns = [
      {
        name: 'Nome',
        selector: 'clientDescription',
        sortable: true,
      },
      {
        name: 'Identificação Fockink',
        selector: 'description',
        sortable: true,
      },
      {
        name: 'Qtd. Arquivos',
        selector: 'files.length',
        sortable: true,
      },
      {
        name: 'Sistema',
        sortable: true,
        selector: 'operationId',
        cell: (row) => sys[row.operationId],
      },
      {
        name: 'Status',
        sortable: true,
        cell: (row) => (row.status === 'A' ? 'Ativo' : 'Inativo'),
      },
    ];
    setDtCl({ columns, data: inputs });
  }, [inputs]);

  // const handleSolutionSys = (event, currentSolution) => {
  //   setSolution({ sys: currentSolution, plant: '*' });
  // };

  const handleSolutionSys = (event, currentSolution) => {
    if (currentSolution !== null && currentSolution !== '*') {
      const params = new URLSearchParams(window.location.search);
      params.set('op', currentSolution);
      history.push({ search: params.toString() });
    } else if (currentSolution !== null && currentSolution === '*') {
      const params = new URLSearchParams(window.location.search);
      params.delete('op');
      history.push({ search: params.toString() });
    }
  };

  const handleSolutionPlant = (event) => {
    setSolution({ sys: solution.sys, plant: event.target.value });
  };

  if (loading) {
    return (
      <Skeleton variant="rect" height={500} width="100%" animation="wave" />
    );
  }

  // const formatClass = (name) => (name ? name.replace(/[^\w]/gi, '') : 'undef');

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={2} style={{ minHeight: 'calc(20vh - 101px)' }}>
        <Grid item>
          <p className={classes.noMargin}>Sistemas</p>
          <ToggleButtonGroup
            aria-label="device"
            value={solution.sys}
            exclusive
            onChange={handleSolutionSys}
          >
            <ToggleButton value="*" aria-label="all">
              Todos
            </ToggleButton>

            {
              systemIds.find((x) => x === 1) && (

              <ToggleButton
                value="irrigation"
                aria-label="irrigation"
                className={classes.toggleButton}
              >
                <Tooltip title="Irrigação">
                  <img
                    src="/images/icons/pivotIcon.svg"
                    alt="Irrigação"
                    className={classes.smallIcon}
                  />
                </Tooltip>
              </ToggleButton>
              )
              }
            {
              systemIds.find((x) => x === 2) && (
              <ToggleButton
                value="thermometry"
                aria-label="thermometry"
                className={classes.toggleButton}
              >
                <Tooltip title="Termometria">
                  <img
                    src="/images/icons/thermometryIcon.svg"
                    alt="Termometria"
                    className={classes.smallIcon}
                  />
                </Tooltip>
              </ToggleButton>
              )
            }
            {
              systemIds.find((x) => x === 3) && (
              <ToggleButton
                value="automation"
                aria-label="automation"
                className={classes.toggleButton}
              >
                <Tooltip title="Automação">
                  <img
                    src="/images/icons/automationIcon.svg"
                    alt="Automação"
                    className={classes.smallIcon}
                  />
                </Tooltip>
              </ToggleButton>
              )
            }
            {
                  systemIds.find((x) => x === 4) && (
                  <ToggleButton
                    value="photovoltaic"
                    aria-label="photovoltaic"
                    className={classes.toggleButton}
                  >
                    <Tooltip title="Fotovoltaica">
                      <img
                        src="/images/icons/powerPlantIcon.svg"
                        alt="Fotovoltaica"
                        className={classes.smallIcon}
                      />
                    </Tooltip>
                  </ToggleButton>
                  )
              }
            {
              systemIds.find((x) => x === 5) && (
              <ToggleButton
                value="weatherStation"
                aria-label="weatherStation"
                className={classes.toggleButton}
              >
                <Tooltip title="Estação Meteorológica">
                  <img
                    src="/images/icons/wheater-station.svg"
                    alt="Estação"
                    className={classes.smallIcon}
                  />
                </Tooltip>
              </ToggleButton>
              )
            }

            {/* <ToggleButton
              value="automation"
              aria-label="automation"
              className={classes.toggleButton}
            >
              <Tooltip title="Automação">
                <img
                  src="/images/icons/automationIcon.svg"
                  alt="Automação"
                  className={classes.smallIcon}
                />
              </Tooltip>
            </ToggleButton> */}

            {/* <ToggleButton
              value="photovoltaic"
              aria-label="photovoltaic"
              className={classes.toggleButton}
            >
              <Tooltip title="Fotovoltaica">
                <img
                  src="/images/icons/powerPlantIcon.svg"
                  alt="Fotovoltaica"
                  className={classes.smallIcon}
                />
              </Tooltip>
            </ToggleButton> */}

            {/* <ToggleButton
              value="irrigation"
              aria-label="irrigation"
              className={classes.toggleButton}
            >
              <Tooltip title="Irrigação">
                <img
                  src="/images/icons/pivotIcon.svg"
                  alt="Irrigação"
                  className={classes.smallIcon}
                />
              </Tooltip>
            </ToggleButton> */}

            {/* <ToggleButton
              value="thermometry"
              aria-label="thermometry"
              className={classes.toggleButton}
            >
              <Tooltip title="Termometria">
                <img
                  src="/images/icons/thermometryIcon.svg"
                  alt="Termometria"
                  className={classes.smallIcon}
                />
              </Tooltip>
            </ToggleButton> */}

            {/* <ToggleButton
              value="weatherStation"
              aria-label="weatherStation"
              className={classes.toggleButton}
            >
              <Tooltip title="Estação Meteorológica">
                <img
                  src="/images/icons/wheater-station.svg"
                  alt="Estação"
                  className={classes.smallIcon}
                />
              </Tooltip>
            </ToggleButton> */}

          </ToggleButtonGroup>
        </Grid>

        <Grid item style={{ alignSelf: 'flex-end' }}>
          <FormControl variant="filled" style={{ minWidth: '300px' }}>
            <InputLabel>Planta</InputLabel>
            <Select
              value={solution.plant}
              onChange={handleSolutionPlant}
              name="plant"
            >
              <MenuItem value="*">Todos</MenuItem>
              {plants
                .filter(
                  (f) =>
                    solution.sys === '*'
                      ? true
                      : f.operationId === sysNumb[solution.sys],
                  // eslint-disable-next-line function-paren-newline
                )
                .map((f) => (
                  <MenuItem key={f.id} value={f.id}>
                    {`${f.description} (${f.technicalDescription})`}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <DataTable
            // pagination
            // paginationPerPage={5}
            // paginationComponentOptions={{
            //   rowsPerPageText: 'Linhas:',
            //   rangeSeparatorText: 'de',
            // }}
            // paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
            noDataComponent="Não existem dados para serem mostrados"
            columns={dtCl.columns}
            data={dtCl.data}
            title=""
            expandableRows
            expandableRowsComponent={<ExpandedComponent />}
            defaultSortField="files.length"
            defaultSortAsc={false}
            // expandableRowExpanded={expandableRowExpanded}
            // onRowExpandToggled={onRowExpandToggled}
          />
        </Grid>
        <Grid item xs={12} style={{ margin: '25px 0', textAlign: 'right' }}>
          <BackButton redirectTo="/" />
        </Grid>
      </Grid>
    </>
  );
};
