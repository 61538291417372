import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  .button-new {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.8rem;
  }
  /* flex: 1;
  display: flex;

  .diver {
    height: 100%;
    width: 1px;
    margin: 0 1rem;
    background: black;

    @media screen and (max-width: 1282px) {
      display: none;
      flex-direction: column;
    }
  }

  @media screen and (max-width: 1282px) {
    flex-direction: column;
  } */
 `;
