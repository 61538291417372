import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../App.css';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FockinkAPI from '../../../middlewares/Api';
import SaveButton from '../../../components/Buttons/SaveButton';
import BackButton from '../../../components/Buttons/BackButton';
import Breadcrumbs from '../../../components/Breadcrumbs';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

export default () => {
  const [sent, setSent] = React.useState(false);
  const [returnToLogin, setReturnToLogin] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const [data, setData] = React.useState({ email: '' });
  const MySwal = withReactContent(Swal);

  const breadcrumb = {
    title: 'Esqueci minha Senha',
    routes: [
      { name: 'Esqueci minha Senha' }],
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const classes = useStyles();

  const save = () => {
    setIsSaving(true);
    const sendData = {
      login: data.email,
    };
    FockinkAPI.post('/login/forgotPassword', sendData).then(() => {
      setIsSaving(false);
      setSent(true);
    }).catch((e) => {
      setIsSaving(false);
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: e.response.data.reason || e.response.data.message,
      });
    });
  };

  if (returnToLogin) return <Redirect to="/login" />;

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1} style={{ padding: '0 24px', minHeight: 'calc(100vh - 101px)' }}>

        {sent && (
          <Grid item xs={12}>
            <h2>Recuperação de senha iniciada!</h2>
            <h3>
              Se
              <i>{` ${data.email} `}</i>
              estiver cadastrado na plataforma, você receberá um email com o link
              para continuar a recuperação.
            </h3>
          </Grid>
        )}

        {!sent && (
          <Grid item xs={6}>
            <TextField variant="filled" name="email" onChange={handleChange} value={data.email || ''} label="Email" />
          </Grid>
        )}

        <Grid item xs={12} className={`${classes.textAlign} ${classes.bottomButtons}`}>
          {sent ? <BackButton back={() => setReturnToLogin(true)} className={classes.btnSpace} />
            : (
              <SaveButton
                save={save}
                className={classes.btnSpace}
                notComplete={!data.email}
                isSaving={isSaving}
              />
            )}
        </Grid>
      </Grid>
    </>
  );
};
