import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  .back-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1;

  @media screen and (max-width: 920px) {
    flex-direction: column;
  }
`;

export const MainGraphic = styled.main`
  background: white;
  width: 100%;
  margin-right: 1rem;
  padding: 1rem;
  border-radius: 4px;

  @media screen and (min-width: 1416px) {
    width: 100%;
    min-width: 980px;
  }

  @media screen and (max-width: 720px) {
    min-width: 100%;
    margin-right: 0;
  }
`;

export const Info = styled.div`
  width: 100%;

  height: 100%;
  .info-label {
    font-size: 1.2rem;
  }

  @media screen and (min-width: 9200px) {
    max-width: 300px;
  }

  .header-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ContentInfo = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 0.7rem 0 2rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`;

export const LoadingDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const FilterDiv = styled.div`
  width: 100%;
  max-width: 300px;
  margin-bottom: 2rem;
`;

export const ReportLogField = styled.div`
  width: 100%;
  max-width: 400px;
  height: 100%;
  max-height: 70px;
  background-color: #c530302b;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.41);
  box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.41);
`;
