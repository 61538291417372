import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Swal from 'sweetalert2';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import withReactContent from 'sweetalert2-react-content';
import AddressFields from '../../../../../components/AddressFields';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import FockinkAPI from '../../../../../middlewares/Api';
import { AccordionContainer } from '../../../../../components/ExpandAccordion';
import * as S from './style';
import { EnergyCostCard } from '../../../../../components/EnergyCostCard';
import { EquipamentsIrrigationCard } from './Components/Equipaments';
import BottomButtons from '../../../../../components/Buttons/BottomButtons';
import PortalPermissions from '../../../../../helpers/enums/PortalPermissions';
import { DatasheetCard } from './Components/DataSheet';
import { EquipamentCulture } from './Components/EquipamentCulture';
import { handleMessageError } from '../../../../../helpers/handlerMessageError';
import { FilesToDownload } from '../../FilasToDownloadList';

export function IrrigationSettings(props) {
  const { id } = useParams();

  const [pivoData, setPivoData] = useState({});
  const [operationalEquipamentsPivo, setOperalEquipamentsPivo] = useState({});
  const [datasheetEquipaments, setDatasheetEquipaments] = useState({});
  const [expanded, setExpanded] = useState('equipaments');
  const [energyCost, setEnergyCost] = useState();

  const [
    availableFilesToDownload,
    setAvailableFilesToDownload,
  ] = React.useState([]);

  const [dataToRequest, setDataToRequest] = useState();

  const MySwal = withReactContent(Swal);

  const formatEquipamentsSettings = (equipamentsData) => {
    const {
      timePressurization,
      timePressureFail,
      tsStartHP,
      tsEndHP,
      sundayHP,
      mondayHP,
      tuesdayHP,
      wednesdayHP,
      thursdayHP,
      fridayHP,
      saturdayHP,
      ...rest
    } = equipamentsData;

    const opetarionEquipamments = {
      timePressurization,
      timePressureFail,
      tsStartHP,
      tsEndHP,
      sundayHP,
      mondayHP,
      tuesdayHP,
      wednesdayHP,
      thursdayHP,
      fridayHP,
      saturdayHP,
    };
    setOperalEquipamentsPivo(opetarionEquipamments);
    setDatasheetEquipaments(rest);
  };

  const getDocumentsFileToDonwload = async () => {
    try {
      const response = await FockinkAPI.get(`equipaments/${id}/readFiles`);

      const fomattedFileObject = response.data.data.map((file) => ({
        file,
        fileName: file.split('/').pop(),
      }));

      setAvailableFilesToDownload(fomattedFileObject);
    } catch (error) {
      handleMessageError(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getDocumentsFileToDonwload();
      const response = await FockinkAPI.get(`equipaments/${id}/settings`);
      const {
        equipamentSettings,
        clientDescription,
        ...rest
      } = response.data.data;
      const { settings } = rest;

      setDataToRequest({
        ...settings,
        clientDescription,
        location: settings.location || {},
      });

      setPivoData({ ...rest, clientDescription });
      setEnergyCost(settings.energyCost);
      formatEquipamentsSettings(equipamentSettings);
    })();
  }, [id]);

  const routes = [
    { name: 'Equipamentos', to: '/equipamentData' },
    {
      name: pivoData.clientDescription || pivoData.description,
      to: `/irrigationDash/${id}`,
    },
    { name: 'Configurações' },
  ];

  const handleExpandendAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeLocation = (location) => {
    setDataToRequest({ ...dataToRequest, location });
  };

  const handleChange = (event) => {
    setDataToRequest({
      ...dataToRequest,
      [event.target.name]: event.target.value.floatValue,
    });
  };

  const handleChangeClientDescription = (event) => {
    setDataToRequest({
      ...dataToRequest,
      clientDescription: event.target.value,
    });
  };

  const throwWarning = (text) =>
    MySwal.fire({
      title: text,
    });

  const checkValidation = () => {
    if (
      !dataToRequest.location.address1 ||
      dataToRequest.location.address1.length > 60
    ) {
      throwWarning('Endereço 1 deve ter entre 1 e 60 caracteres');
      return false;
    }

    const tests = [
      { text: 'CEP', input: 'postalCode' },
      { text: 'Cidade', input: 'city' },
      { text: 'Estado', input: 'state' },
      { text: 'Pais', input: 'country' },
      { text: 'Latitude', input: 'lat' },
      { text: 'Longitude', input: 'lon' },
    ]
      .map((e) => {
        if (!dataToRequest.location[e.input]) {
          throwWarning(`${e.text} deve ser preenchido`);
          return false;
        }
        return true;
      })
      .reduce((a, b) => a && b);

    if (!tests) {
      return false;
    }

    return true;
  };

  const saveData = () => {
    const valid = checkValidation();
    if (valid) {
      const dt = {
        data: {
          location: dataToRequest.location,
          northFaceAngle: dataToRequest.northFaceAngle || 0,
          clientDescription: dataToRequest.clientDescription,
        },
        url: `/equipaments/${id}/irrigationSettings`,
        method: 'put',
      };
      return dt;
    }
    return undefined;
  };
  const urlParams = new URLSearchParams(window.location.search);
  const whatRoute = urlParams.get('whatRoute');

  return (
    <S.Wrapper>
      <Breadcrumbs routes={routes} />
      {!dataToRequest ? (
        <span>carregando</span>
      ) : (
        <S.Content>
          <AccordionContainer
            title="Descrição do Equipamento"
            expanded={expanded}
            name="clientDescription"
            handleExpandAccordion={handleExpandendAccordion}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={3}>
                <TextField
                  variant="filled"
                  name="clientDescription"
                  onChange={handleChangeClientDescription}
                  value={dataToRequest.clientDescription || null}
                  label="Defina uma descrição para o equipamento"
                />
              </Grid>
            </Grid>
          </AccordionContainer>

          <AccordionContainer
            title="Endereço"
            expanded={expanded}
            name="address"
            handleExpandAccordion={handleExpandendAccordion}
          >
            <AddressFields
              handleChangeLocation={handleChangeLocation}
              data={dataToRequest.location}
              data1={dataToRequest}
            />
          </AccordionContainer>

          <AccordionContainer
            title="Parâmetros de Operação"
            expanded={expanded}
            name="equipaments"
            handleExpandAccordion={handleExpandendAccordion}
          >
            <EquipamentsIrrigationCard
              operationalEquipaments={operationalEquipamentsPivo}
              dataSheetEquipaments={datasheetEquipaments}
            />
          </AccordionContainer>

          <AccordionContainer
            title="Configuração de cultura"
            expanded={expanded}
            name="culture"
            handleExpandAccordion={handleExpandendAccordion}
          >
            <EquipamentCulture equipamentId={id} />
          </AccordionContainer>

          <AccordionContainer
            title="Configuração Posição Zero"
            expanded={expanded}
            name="northFaceAngle"
            handleExpandAccordion={handleExpandendAccordion}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={3}>
                <NumberFormat
                  value={dataToRequest.northFaceAngle || 0}
                  name="northFaceAngle"
                  label="Posição Zero"
                  customInput={TextField}
                  variant="filled"
                  thousandSeparator="."
                  decimalSeparator=","
                  onValueChange={(v) =>
                    handleChange({
                      target: { name: 'northFaceAngle', value: v || 0 },
                    })
                  }
                />
              </Grid>
            </Grid>
          </AccordionContainer>

          <AccordionContainer
            title="Ficha Técnica"
            expanded={expanded}
            name="tecnichal"
            handleExpandAccordion={handleExpandendAccordion}
          >
            <DatasheetCard dataSheetEquipaments={datasheetEquipaments} />
          </AccordionContainer>

          <AccordionContainer
            title="Arquivos e Manuais"
            expanded={expanded}
            name="manuasl"
            handleExpandAccordion={handleExpandendAccordion}
          >
            <FilesToDownload files={availableFilesToDownload} />
          </AccordionContainer>

          <AccordionContainer
            title="Tarifação: Mercado Cativo"
            expanded={expanded}
            name="energyCost"
            handleExpandAccordion={handleExpandendAccordion}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <EnergyCostCard energyCost={energyCost} />
              </Grid>
            </Grid>
          </AccordionContainer>
        </S.Content>
      )}

      {/* <Grid item xs={12}>
        <Accordion
          expanded={expanded === 'manuals'}
          onChange={handleChangeAcc('manuals')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Arquivos e Manuais</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={3}>
                <FilesToDownload files={availableFilesToDownload} />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid> */}

      <S.Options>
        <BottomButtons
          saveData={
            (expanded === 'address' ||
              expanded === 'northFaceAngle' ||
              expanded === 'clientDescription') &&
            saveData
          }
          backTo={(whatRoute === 'Equips' ? `/irrigationDash/${props.match.params.id}?whatRoute=Equips` : `/irrigationDash/${id}`)}
          canSave={PortalPermissions.THERMOMETRY_SETTINGS_EDIT.value}
        />
      </S.Options>
    </S.Wrapper>
  );
}
