import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DropZoneContainer, Modal, ButtonComponent as Button } from '../../../../../../../components';
import FockinkAPI from '../../../../../../../middlewares/Api';
import { handleMessageError } from '../../../../../../../helpers/handlerMessageError';
import { UpdatedFilesDataTable } from './components/FilesDataTable';

import * as S from './styles';

export function UploadFileManager() {
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [files, setFiles] = React.useState([]);

  const handleCloseModal = () => setOpenModal(false);

  const onDrop = React.useCallback((acceptedFiles) => {
    setSelectedFiles((preventState) => [...preventState, ...acceptedFiles]);
  }, []);

  const { id } = useParams();

  const getFiles = async () => {
    try {
      const response = await FockinkAPI.get(`equipaments/${id}/readFiles`);
      setFiles(response.data.data);
    } catch (error) {
      handleMessageError(Error);
    }
  };

  const handleUploadFile = async ({ file, fileName }) => {
    try {
      const formData = new FormData();
      formData.append('uploadedFile', file);
      formData.append('fileName', fileName);

      const config = {
        onUploadProgress: (progressEvent) => progressEvent.loaded,
      };

      await FockinkAPI.post(`equipaments/${id}/uploadFile`, formData, config);
      await getFiles();

      const copyState = [...selectedFiles];
      const newState = copyState.filter(
        (fileFromState) => fileFromState.path !== file.path,
      );
      setSelectedFiles(newState);

      toast.success(`O documento ${fileName} foi enviado com sucesso!`);
    } catch (error) {
      handleMessageError(error);
    }
  };

  const handleDeletFile = async (file) => {
    try {
      await FockinkAPI.delete(`equipaments/${id}/deleteFile`, { params: {
        fileName: file,
      } });
      await getFiles();
      toast.success('Arquivo excluído');
    } catch (error) {
      handleMessageError(error);
    }
  };

  React.useState(() => {
    (async () => {
      await getFiles();
    })();
  }, []);

  return (
    <S.Wrapper>
      <S.ButtonDeleteDiv>
        <Button type="button" handle={() => setOpenModal(true)}>
          upload de arquivos
        </Button>
      </S.ButtonDeleteDiv>
      <UpdatedFilesDataTable data={files} handleDeletFile={handleDeletFile} />

      <Modal title="Upload de Arquivos" open={openModal} handleCloseModal={handleCloseModal}>

        <S.Content>
          <DropZoneContainer
            confirmUpload={handleUploadFile}
            alternativeLabel="Upload de arquivos"
            onDrop={onDrop}
            files={selectedFiles}
          />
        </S.Content>

      </Modal>
    </S.Wrapper>
  );
}
