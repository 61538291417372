
import * as BABYLON from 'babylonjs'
import * as MyEarcut from 'earcut'

const _ = require('lodash')

let showInfo = false
let arcSee = []

export const CleanVarsTulha = () => {
  showInfo = false
  arcSee = []
}

export const CriaTulhaPlano = (
  scene,
  layout,
  termoReport,
  colorConfig,
  cod,
  nomeUnidade,
  comprimento,
  largura,
  alturaParede,
  tipoAgua,
  tipoFundo,
  alturaFundo,
  reducaoCabeceira,
  anguloUnidade,
  posX,
  posZ,
  motores,
  fncChangeViewInfo,
  objetoCamadaProduto,
  unidadesObservaveis,
  setUnidadesObservaveis,
  produto_visivel,
  handleOnSetObjetoCamadaProduto,
  produto_info,
  fncChangeViewLevel,
  grao,
  fchChangeViewStaircase,
  escada_visivel,
  fcnSeeTooltip
) => {
  const pivoArmazem = new BABYLON.TransformNode(`pivoArmazem${nomeUnidade}`)
  pivoArmazem.unidade = cod
  pivoArmazem.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(anguloUnidade))
  pivoArmazem.position.x = posX
  pivoArmazem.position.z = posZ
  const alturaParedeArmazem = alturaParede
  const compBaseArmazem = comprimento
  const largBaseArmazem = largura
  const abaTelhadoArmazem = 1
  const anguloTelhadoArmazem = 30
  const alturaTelhadoArmazem =
    (largBaseArmazem / 2) * Math.tan((anguloTelhadoArmazem * Math.PI) / 180)
  const larguraCalcada = 3.5
  const distanciaEntreSensores = 2

  const tipoVisualizacao = BABYLON.Mesh.FRONTSIDE

  const pontoParedeArmazem = [
    new BABYLON.Vector3(0, compBaseArmazem, 0),
    new BABYLON.Vector3(largBaseArmazem, compBaseArmazem, 0),
    new BABYLON.Vector3(largBaseArmazem, 0, 0),
    new BABYLON.Vector3(0, 0, 0)
  ]
  pontoParedeArmazem.push(pontoParedeArmazem[0])
  const caminhoParedeArmazem = [
    new BABYLON.Vector3(0, alturaParedeArmazem, 0),
    new BABYLON.Vector3(0, 0, 0)
  ]
  const texturaParede = new BABYLON.StandardMaterial(
    `texturaParede${nomeUnidade}`,
    scene
  )
  texturaParede.diffuseTexture = new BABYLON.Texture(
    '/assets/texturas/parede-lateral.png',
    scene
  )
  texturaParede.diffuseTexture.uScale = 50
  texturaParede.diffuseTexture.vScale = 1
  const armazem = BABYLON.MeshBuilder.ExtrudeShape(
    'armazem',
    {
      cap: BABYLON.Mesh.NO_CAP,
      shape: pontoParedeArmazem,
      path: caminhoParedeArmazem,
      sideOrientation: tipoVisualizacao,
      updatable: false
    },
    scene
  )
  armazem.position.z = -(largBaseArmazem / 2)
  armazem.position.x = compBaseArmazem / 2
  armazem.material = texturaParede
  armazem.isPickable = false
  armazem.parent = pivoArmazem

  // console.log('Cria Armazem - [x] 2');
  // Telhado =============================
  const texturaTelhado = new BABYLON.StandardMaterial(
    `texturaTelhado${nomeUnidade}`,
    scene
  )
  texturaTelhado.diffuseTexture = new BABYLON.Texture(
    '/assets/texturas/textura-telhado.png',
    scene
  )
  texturaTelhado.diffuseTexture.uScale = 1
  texturaTelhado.diffuseTexture.vScale = 50
  texturaTelhado.sideOrientation = BABYLON.Mesh.DOUBLESIDE

  const texturaTelhadoLateral = new BABYLON.StandardMaterial(
    `texturaTelhadoLateral${nomeUnidade}`,
    scene
  )
  texturaTelhadoLateral.diffuseTexture = new BABYLON.Texture(
    '/assets/texturas/textura-telhado-lateral.png',
    scene
  )
  texturaTelhadoLateral.diffuseTexture.uScale = 25
  texturaTelhadoLateral.diffuseTexture.vScale = 1
  texturaTelhadoLateral.sideOrientation = BABYLON.Mesh.FRONTSIDE
  texturaTelhadoLateral.backFaceCulling = true
  texturaTelhadoLateral.zOffset = -2

  if (tipoAgua === 2) {
    // Duas águas
    const pontoTelhado = [
      new BABYLON.Vector3(
        -(largBaseArmazem / 2 + abaTelhadoArmazem) + 0.05,
        0,
        0
      ),
      new BABYLON.Vector3(0, alturaTelhadoArmazem, 0),
      new BABYLON.Vector3(largBaseArmazem / 2 + abaTelhadoArmazem - 0.05, 0, 0)
    ]
    const caminhoTelhado = [
      new BABYLON.Vector3(-abaTelhadoArmazem, 0, 0),
      new BABYLON.Vector3(compBaseArmazem + abaTelhadoArmazem, 0, 0)
    ]
    const telhado = BABYLON.MeshBuilder.ExtrudeShape(
      `telhado${nomeUnidade}`,
      {
        shape: pontoTelhado,
        path: caminhoTelhado,
        sideOrientation: BABYLON.Mesh.DOUBLESIDE,
        updatable: false
      },
      scene
    )
    telhado.position.y = alturaParedeArmazem - abaTelhadoArmazem / 2
    telhado.position.x = -(compBaseArmazem / 2)
    telhado.material = texturaTelhado
    telhado.isPickable = false
    telhado.parent = pivoArmazem

    // Lateral telhado Frente
    const pontosLateralTelhado = [
      new BABYLON.Vector2(-(largBaseArmazem / 2), 0),
      new BABYLON.Vector2(largBaseArmazem / 2, 0),
      new BABYLON.Vector2(0, alturaTelhadoArmazem - abaTelhadoArmazem / 2)
    ]

    const trianguloTelhado = new BABYLON.PolygonMeshBuilder(
      `trianguloTelhado${nomeUnidade}`,
      pontosLateralTelhado,
      scene,
      MyEarcut
    )
    const lateralTelhadoF = trianguloTelhado.build(null, 0.0)
    lateralTelhadoF.material = texturaTelhadoLateral
    lateralTelhadoF.rotate(BABYLON.Axis.X, BABYLON.Tools.ToRadians(270))
    lateralTelhadoF.rotate(BABYLON.Axis.Z, BABYLON.Tools.ToRadians(270))
    lateralTelhadoF.position.x = compBaseArmazem
    lateralTelhadoF.position.y = abaTelhadoArmazem / 2
    lateralTelhadoF.isPickable = false
    lateralTelhadoF.parent = telhado
    // lateralTelhadoF.sideOrientation = BABYLON.Mesh.BACKSIDE

    const lateralTelhadoV = lateralTelhadoF.clone('lateralTelhadoV')
    lateralTelhadoV.position.x = -(compBaseArmazem / 2 - compBaseArmazem / 2)
    lateralTelhadoV.isPickable = false
  } else {
    // Curvado
    // Telhado Curvado ======================================================
    const comprimentoTelhadoAngulado = compBaseArmazem + abaTelhadoArmazem
    var alturaTelhadoAngulado = alturaTelhadoArmazem
    const larguraTelhadoAngulado = largBaseArmazem

    // Pontos telhado
    const funcaoPontosTelhado = function (k) {
      const path = []
      for (let i = 0; i <= larguraTelhadoAngulado; i++) {
        const x = i
        const y = 0
        const z = k
        path.push(new BABYLON.Vector3(x, y, z))
      }
      return path
    }

    // Atualiza posicionamento do telhado
    const atualizaPosicionamento = function (path) {
      for (let i = 0; i < path.length; i++) {
        const { x } = path[i]
        const { z } = path[i]
        const y =
          alturaTelhadoAngulado *
          Math.sin(BABYLON.Tools.ToRadians(180 / larguraTelhadoAngulado) * i)
        path[i].x = x
        path[i].y = y
        path[i].z = z
      }
    }
    // Criação do caminhos do telhado curvado
    const vetorCaminhoTelhadoAngulado = []
    for (let i = 0; i <= comprimentoTelhadoAngulado; i++) {
      vetorCaminhoTelhadoAngulado.push(funcaoPontosTelhado(i))
    }
    // Atualiza o comprimento do caminho
    for (let p = 0; p < vetorCaminhoTelhadoAngulado.length; p++) {
      atualizaPosicionamento(vetorCaminhoTelhadoAngulado[p])
    }
    // Telhado
    const telhadoCurvado = BABYLON.Mesh.CreateRibbon(
      `telhadoCurvado${nomeUnidade}`,
      vetorCaminhoTelhadoAngulado,
      false,
      false,
      0,
      scene,
      true
    )
    telhadoCurvado.material = texturaTelhado
    telhadoCurvado.position.z = larguraTelhadoAngulado / 2
    telhadoCurvado.position.x = -(comprimentoTelhadoAngulado / 2)
    telhadoCurvado.position.y = alturaParedeArmazem
    telhadoCurvado.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(90))
    telhadoCurvado.isPickable = false
    telhadoCurvado.parent = pivoArmazem

    const criacaoPontosTelhadoAngular = function () {
      const path = []
      for (let i = 0; i <= larguraTelhadoAngulado; i++) {
        const x = i
        const y =
          alturaTelhadoAngulado *
          Math.sin(BABYLON.Tools.ToRadians(180 / larguraTelhadoAngulado) * i)
        path.push(new BABYLON.Vector2(x, y))
      }
      return path
    }
    // Lateral telhado curvado
    const lateralTelhadoCurvado = new BABYLON.PolygonMeshBuilder(
      `lateralTelhadoCurvado${nomeUnidade}`,
      criacaoPontosTelhadoAngular(),
      scene,
      MyEarcut
    )
    const lateralTelhadoCurvadoF = lateralTelhadoCurvado.build(null, 0.0)
    lateralTelhadoCurvadoF.material = texturaTelhadoLateral
    lateralTelhadoCurvadoF.rotate(BABYLON.Axis.X, BABYLON.Tools.ToRadians(270))
    lateralTelhadoCurvadoF.rotate(BABYLON.Axis.Z, BABYLON.Tools.ToRadians(270))
    lateralTelhadoCurvadoF.position.z = -(larguraTelhadoAngulado / 2)
    lateralTelhadoCurvadoF.position.y = alturaParedeArmazem
    lateralTelhadoCurvadoF.position.x = compBaseArmazem / 2
    lateralTelhadoCurvadoF.isPickable = false
    lateralTelhadoCurvadoF.parent = pivoArmazem

    const lateralTelhadoCurvadoV = lateralTelhadoCurvadoF.clone(
      'lateralTelhadoCurvadoV'
    )
    lateralTelhadoCurvadoV.position.x = -(compBaseArmazem / 2)
    lateralTelhadoCurvadoV.position.z = largBaseArmazem / 2
    lateralTelhadoCurvadoV.rotate(BABYLON.Axis.Z, BABYLON.Tools.ToRadians(180))
    lateralTelhadoCurvadoV.isPickable = false
  }

  // console.log('Cria Armazem - [x] 4');
  // Calçada
  const texturaCalcada = new BABYLON.StandardMaterial(
    `texturaCalcada${nomeUnidade}`,
    scene
  )
  texturaCalcada.diffuseTexture = new BABYLON.Texture(
    '/assets/texturas/calcada.png',
    scene
  )
  texturaCalcada.diffuseTexture.uScale = 15
  texturaCalcada.diffuseTexture.vScale = 1
  texturaCalcada.sideOrientation = BABYLON.Mesh.DOUBLESIDE
  texturaCalcada.zOffset = -2

  let pontosCalcada = []
  pontosCalcada.push(new BABYLON.Vector2(0, 0))
  pontosCalcada.push(new BABYLON.Vector2(compBaseArmazem, 0))
  pontosCalcada.push(new BABYLON.Vector2(compBaseArmazem, larguraCalcada))
  pontosCalcada.push(new BABYLON.Vector2(0, larguraCalcada))

  const calcadaArmazem = new BABYLON.PolygonMeshBuilder(
    `calcadaArmazem${nomeUnidade}`,
    pontosCalcada,
    scene,
    MyEarcut
  )
  const calcadaArmazemE = calcadaArmazem.build(null, 0.01)
  calcadaArmazemE.material = texturaCalcada
  calcadaArmazemE.position.x = -(compBaseArmazem / 2)
  calcadaArmazemE.position.y = 0.01
  calcadaArmazemE.position.z = -(largBaseArmazem / 2 + larguraCalcada)
  calcadaArmazemE.isPickable = false
  calcadaArmazemE.checkCollisions = true
  calcadaArmazemE.parent = pivoArmazem

  const calcadaArmazemD = calcadaArmazemE.clone(`calcadaArmazemD${nomeUnidade}`)
  calcadaArmazemD.position.z = largBaseArmazem / 2
  calcadaArmazemD.position.y = 0.01
  calcadaArmazemD.isPickable = false
  calcadaArmazemD.checkCollisions = true
  calcadaArmazemD.parent = pivoArmazem

  const tempLarCalcada = largBaseArmazem + larguraCalcada * 2
  pontosCalcada = []
  pontosCalcada.push(new BABYLON.Vector2(0, 0))
  pontosCalcada.push(new BABYLON.Vector2(tempLarCalcada, 0))
  pontosCalcada.push(new BABYLON.Vector2(tempLarCalcada, larguraCalcada))
  pontosCalcada.push(new BABYLON.Vector2(0, larguraCalcada))

  const calcadaArmazemPontas = new BABYLON.PolygonMeshBuilder(
    `calcadaArmazemPontas${nomeUnidade}`,
    pontosCalcada,
    scene,
    MyEarcut
  )
  const calcadaArmazemPontasE = calcadaArmazemPontas.build(null, 0.01)
  calcadaArmazemPontasE.material = texturaCalcada
  calcadaArmazemPontasE.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(90))
  calcadaArmazemPontasE.position.x = -(compBaseArmazem / 2 + larguraCalcada)
  calcadaArmazemPontasE.position.y = 0.01
  calcadaArmazemPontasE.position.z = tempLarCalcada / 2
  calcadaArmazemPontasE.isPickable = false
  calcadaArmazemPontasE.checkCollisions = true
  calcadaArmazemPontasE.parent = pivoArmazem

  const calcadaArmazemPontasD = calcadaArmazemPontasE.clone(
    `calcadaArmazemPontasD${nomeUnidade}`
  )
  calcadaArmazemPontasD.position.x = compBaseArmazem / 2
  calcadaArmazemPontasD.position.y = 0.01
  calcadaArmazemPontasD.isPickable = false
  calcadaArmazemPontasD.checkCollisions = true
  calcadaArmazemPontasD.parent = pivoArmazem
  // console.log('Cria Armazem - [x] 5');
  // Cria fundo armazém
  const CreateMesh = function (name, vertices, verticesOrder, scene) {
    const mesh = new BABYLON.Mesh(name, scene)
    mesh.verticesOrder = verticesOrder
    const positions = []
    const normals = []
    const indices = []
    for (const i in verticesOrder) {
      positions.push(
        vertices[verticesOrder[i]].x,
        vertices[verticesOrder[i]].y,
        vertices[verticesOrder[i]].z
      )
    }
    _.forEach(vertices, item => {
      normals.push(0, 0, 0)
    })
    for (const x in verticesOrder) {
      indices.push(x)
    }
    BABYLON.VertexData.ComputeNormals(positions, indices, normals)
    mesh.setVerticesData(BABYLON.VertexBuffer.PositionKind, positions, true)
    mesh.setVerticesData(BABYLON.VertexBuffer.NormalKind, normals, true)
    mesh.setIndices(indices)
    return mesh
  }

  const profundidadeFundo = alturaFundo
  const comprimentoFundo = compBaseArmazem
  const larguraFundo = largBaseArmazem
  let reducaoSemiV = 0
  if (tipoFundo === 0) {
    // Tipo V
    reducaoSemiV = largBaseArmazem / 2
  } else {
    // Tipo semi V
    reducaoSemiV = tipoFundo
  }

  const fundoBaseXMin = reducaoCabeceira
  const fundoBaseXMax = comprimentoFundo - reducaoCabeceira
  const fundoBaseZMin = reducaoSemiV
  const fundoBaseZMax = larguraFundo - reducaoSemiV
  const fundoTopoXMin = 0
  const fundoTopoXMax = comprimentoFundo
  const fundoTopoZMin = 0
  const fundoTopoZMax = larguraFundo

  // 1-Criar as vértices do fundo do armazém
  ///const vertices = [ // x, y, z
  ///  new BABYLON.Vector3(fundoBaseXMin, 0, fundoBaseZMin), // 0
  ///  new BABYLON.Vector3(fundoBaseXMax, 0, fundoBaseZMin), // 1
  ///  new BABYLON.Vector3(fundoBaseXMin, 0, fundoBaseZMax), // 2
  ///  new BABYLON.Vector3(fundoBaseXMax, 0, fundoBaseZMax), // 3
  ///
  ///  new BABYLON.Vector3(fundoTopoXMin, 0, fundoTopoZMin), // 4
  ///  new BABYLON.Vector3(fundoTopoXMax, 0, fundoTopoZMin), // 5
  ///  new BABYLON.Vector3(fundoTopoXMin, 0, fundoTopoZMax), // 6
  ///  new BABYLON.Vector3(fundoTopoXMax, 0, fundoTopoZMax), // 7
  ///];
  // Preenche as vértices do fundo
  //const verticesOrder = [
  //  0, 6, 2,
  //  0, 4, 6,
  //  1, 5, 4,
  //  0, 1, 4,
  //  3, 7, 5,
  //  5, 1, 3,
  //  2, 6, 7,
  //  7, 3, 2,
  //  3, 1, 0,
  //  0, 2, 3,
  //];

  const vertices = [
    new BABYLON.Vector3(-compBaseArmazem / 2, 0, -(largBaseArmazem / 2)),
    new BABYLON.Vector3(-compBaseArmazem / 2, 0, largBaseArmazem / 2),
    new BABYLON.Vector3(compBaseArmazem / 2, 0, largBaseArmazem / 2),
    new BABYLON.Vector3(compBaseArmazem / 2, 0, -largBaseArmazem / 2)
  ]

  const texturaFundoArmazem = new BABYLON.StandardMaterial(
    'texturaFundoArmazem',
    scene
  )
  texturaFundoArmazem.diffuseColor = new BABYLON.Color4.FromHexString(
    '#cbd0d8FF'
  )
  texturaFundoArmazem.backFaceCulling = false
  texturaFundoArmazem.sideOrientation = BABYLON.Mesh.BACKSIDE
  //texturaFundoArmazem.zOffset = -2;

  const fundoArmazem = BABYLON.MeshBuilder.CreatePolygon(
    'polygonfund',
    { shape: vertices, sideOrientation: BABYLON.Mesh.DOUBLESIDE },
    scene,
    MyEarcut
  )
  fundoArmazem.isPickable = false
  fundoArmazem.checkCollisions = true
  fundoArmazem.position.y = 0.01
  //fundoArmazem.position.z = -(largBaseArmazem / 2);
  //fundoArmazem.position.x = -compBaseArmazem;
  fundoArmazem.material = texturaFundoArmazem
  // Fundo armazém
  //const fundoArmazem = CreateMesh('fundoArmazem', vertices, scene);
  //fundoArmazem.material = texturaFundoArmazem;
  //fundoArmazem.position.x = -(comprimentoFundo / 2);
  //fundoArmazem.position.z = -(larguraFundo / 2);
  //fundoArmazem.position.y = -profundidadeFundo;
  //fundoArmazem.isPickable = true;
  //fundoArmazem.checkCollisions = true;

  fncUpdateInfoLevelProductArmazem(
    scene,
    compBaseArmazem,
    largBaseArmazem,
    profundidadeFundo,
    alturaParedeArmazem,
    alturaTelhadoAngulado,
    alturaTelhadoArmazem,
    distanciaEntreSensores,
    termoReport,
    colorConfig,
    layout,
    fncChangeViewInfo,
    objetoCamadaProduto,
    produto_info,
    produto_visivel,
    grao
  )

  const unidadeOnservavel = new BABYLON.Observable()
  unidadeOnservavel.add(value => {
    if (value.produto_visivel !== undefined) {
      fncChangeViewLevel(scene, value.produto_visivel)
    }

    if (value.produto_info !== undefined) {
      fncChangeViewInfo(scene, value.produto_info) // TODO:
    }
    if (value.leitura_nivel_produto !== undefined) {
      // Aqui atualiza o nível quando selecionado uma leitura
      const selectedTermoReport = value.leitura_nivel_produto
      fncUpdateInfoLevelProductArmazem(
        scene,
        compBaseArmazem,
        largBaseArmazem,
        profundidadeFundo,
        alturaParedeArmazem,
        alturaTelhadoAngulado,
        alturaTelhadoArmazem,
        distanciaEntreSensores,
        selectedTermoReport,
        colorConfig,
        layout,
        fncChangeViewInfo,
        objetoCamadaProduto,
        produto_info,
        produto_visivel,
        grao
      )
    }
  })

  const unitsObserv = []
  unitsObserv.push({ unidade: nomeUnidade, observavel: unidadeOnservavel })
  setUnidadesObservaveis(unitsObserv)

  // Chão
  const comprimentoChao = compBaseArmazem / 2
  const larguraChao = largBaseArmazem / 2

  // const comprimentoChao = 1000;
  // const larguraChao = 1000;
  const distanciaChao = 540 / 2

  // Poligono da superfície
  const forma = [
    new BABYLON.Vector3(-distanciaChao, 0, -distanciaChao),
    new BABYLON.Vector3(-distanciaChao, 0, distanciaChao),
    new BABYLON.Vector3(distanciaChao, 0, distanciaChao),
    new BABYLON.Vector3(distanciaChao, 0, -distanciaChao)
  ]

  // Buraco da superfície
  const buracoChao = []
  buracoChao.push([
    new BABYLON.Vector3(-comprimentoChao, 0, -larguraChao),
    new BABYLON.Vector3(-comprimentoChao, 0, larguraChao),
    new BABYLON.Vector3(comprimentoChao, 0, larguraChao),
    new BABYLON.Vector3(comprimentoChao, 0, -larguraChao)
  ])

  const matChao = new BABYLON.StandardMaterial('matChao', scene)
  matChao.zOffset = 0
  matChao.diffuseTexture = new BABYLON.Texture(
    '/assets/texturas/grama1.jpg',
    scene
  )
  matChao.diffuseTexture.uScale = 10
  matChao.diffuseTexture.vScale = 10
  matChao.specularColor = new BABYLON.Color3(0, 0, 0)
  const chaoArmazem = BABYLON.MeshBuilder.CreatePolygon(
    'polygon',
    { shape: forma, sideOrientation: BABYLON.Mesh.DOUBLESIDE },
    scene,
    MyEarcut
  )
  chaoArmazem.isPickable = false
  chaoArmazem.checkCollisions = true
  chaoArmazem.material = matChao
}

const fncUpdateInfoLevelProductArmazem = (
  scene,
  compBaseArmazem,
  largBaseArmazem,
  profundidadeFundo,
  alturaParedeArmazem,
  alturaTelhadoAngulado,
  alturaTelhadoArmazem,
  distanciaEntreSensores,
  termoReport,
  colorConfig,
  layout,
  fncChangeViewInfo,
  objetoCamadaProduto,
  produto_info,
  produto_visivel,
  grao
) => {
  let sensorIdForMat = 0
  // Reverte a ordem dos arcos (padrão antigo/anti-horário)
  const objetoArmazem = layout.arcs.slice().reverse()
  // 2-Criação da superfície
  const superficie = []
  const qtdLinhaLarg = largBaseArmazem
  const distEntreCabos = 1
  let distEntreArcos = 0
  const mediaArco = compBaseArmazem / (objetoArmazem.length + 1)
  let incDistArco = 0

  const linhasArcos = objetoArmazem.length * 2 + 3

  for (let x = 0; x <= linhasArcos; x++) {
    superficie.push([])
    if (x > 1 && x < linhasArcos) {
      if (x % 2 === 1) {
        distEntreArcos = 1
      } else if (x === 2 || x === linhasArcos - 1) {
        distEntreArcos = mediaArco - 0.5
      } else {
        distEntreArcos = mediaArco - 1
      }
      incDistArco += distEntreArcos
    }
    fncSurfaceArmazem(superficie, x, incDistArco, qtdLinhaLarg, distEntreCabos) // TODO:
  }

  // Arcos
  const matCabo = new BABYLON.StandardMaterial('matCabo', scene)
  matCabo.diffuseColor = new BABYLON.Color3(0.1, 0.1, 0.1)
  matCabo.specularColor = new BABYLON.Color3(0, 0, 0)

  const matSensor = new BABYLON.StandardMaterial('matSensor', scene)
  matSensor.specularColor = new BABYLON.Color3(0, 0, 0)

  const matInfoSensor = new BABYLON.StandardMaterial(
    'materialSuperficie',
    scene
  )

  matInfoSensor.specularColor = new BABYLON.Color3(0, 0, 0)
  matInfoSensor.emissiveColor = new BABYLON.Color3(1, 1, 1)
  matInfoSensor.backFaceCulling = false

  const conjuntoArco = new BABYLON.Mesh('conjuntoArco', scene)
  conjuntoArco.position.z = -(largBaseArmazem / 2)
  conjuntoArco.position.y = 0.03 //-(profundidadeFundo);
  conjuntoArco.position.x = -(compBaseArmazem / 2)

  const texturaInfoObjeto = new BABYLON.DynamicTexture(
    'texturaInfoObjeto',
    512,
    scene,
    false
  ) // era TRUE

  const criaInfoLabels = (arc, arcMesh) => {
    const cabos = arcMesh.getChildren()

    for (let i = 0; i < objetoArmazem.length; i += 1) {
      if (arc == i) {
        for (let cab = 0; cab < cabos.length; cab += 1) {
          // Cabos
          const sensorsMesh = cabos[cab].getChildMeshes()
          let countSensor = 0
          for (let sen = 0; sen < sensorsMesh.length; sen++) {
            if (sensorsMesh[sen].name.includes('Sensor')) {
              const objSensor =
                objetoArmazem[i].cables[cab].sensors[countSensor]
              countSensor++
              let tempColor = '#FFFFFFFF'
              tempColor = `${objSensor.color}FF`

              const objetoSensor = sensorsMesh[sen]
              NomeObjeto(
                arcMesh.name,
                scene,
                tempColor,
                [{ val: `${objSensor.t}`, tam: 220 }],
                objetoSensor,
                0,
                1.4,
                1,
                1
              )
            }
          }
        }
      }
    }
  }

  // 3-Desenha o arco, cabo e sensor
  for (let arc = 0; arc < objetoArmazem.length; arc += 1) {
    const objetoArco = new BABYLON.Mesh(`objetoArco${arc}`, scene)
    objetoArco.parent = conjuntoArco

    objetoArco.position.x =
      (compBaseArmazem / (objetoArmazem.length + 1)) * (arc + 1)

    const objArco = objetoArmazem[arc]
    const maxDepthCableArc = maxDepthCableArcCalc(objArco) // Quando possuir profundidade no armazém, considera o real nível

    let posicaoZCable = largBaseArmazem / (objArco.cables.length + 1)
    for (let cab = 0; cab < objArco.cables.length; cab += 1) {
      //const alturaCaboTeto = ((alturaTelhadoArmazem) * (Math.sin(BABYLON.Tools.ToRadians(180 / (largBaseArmazem)) * ((cab + 1) * (largBaseArmazem / (objArco.cables.length + 1))))));
      //let myPosZ = ((objArco.cables[cab].position_z * (largBaseArmazem)) / 192) - (largBaseArmazem / 7)
      let myPosZ = posicaoZCable * (cab + 1)
      const alturaCaboTeto =
        alturaTelhadoArmazem *
        Math.sin(BABYLON.Tools.ToRadians(180 / largBaseArmazem) * myPosZ)
      const alturaCabo = alturaParedeArmazem + 0.03 + alturaCaboTeto
      const objCabo = objArco.cables[cab]
      const caminhoTubo = [
        new BABYLON.Vector3(0, 0.01, 0.0),
        new BABYLON.Vector3(0, alturaCabo, 0.0)
      ]
      // console.log('Cab ' + cab)
      // console.log('ALTURA CABO ==> ' + alturaCabo)
      const objetoCabo = BABYLON.MeshBuilder.CreateTube(
        `objetoCabo${cab}`,
        {
          path: caminhoTubo,
          radius: 0.05,
          sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          updatable: false
        },
        scene
      )
      objetoCabo.material = matCabo
      // Calcula position z 🌵
      // 255 é o valor maximo do padrao
      // 28 - 220  (35)

      //objetoCabo.position.z = (((objArco.cables[cab].position_z * (largBaseArmazem)) - 28) / 225);
      objetoCabo.position.z = myPosZ //((objArco.cables[cab].position_z * (largBaseArmazem)) / 192) - (largBaseArmazem / 7)
      objetoCabo.isPickable = true
      objetoCabo.parent = objetoArco

      objetoCabo.actionManager = new BABYLON.ActionManager(scene)
      objetoCabo.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          event => {
            const pickedMesh = event.meshUnderPointer

            const arco = pickedMesh.parent

            if (showInfo === false) {
              criaInfoLabels(arc, arco)
              showInfo = true
              arcSee.push(arco.name)
            } else {
              if (arcSee.includes(arco.name)) {
                _.forEach(scene.meshes, val => {
                  if (
                    val.name.includes('infoObjeto') &&
                    val.name.includes(arco.name)
                  ) {
                    val.visibility = !val.visibility
                  }
                })
                // TOGGL
              } else {
                // fncChangeViewInfo()
                criaInfoLabels(arc, arco)
                arcSee.push(arco.name)
              }
              // esconde info
            }
          }
        )
      )
      const alturaBase = 0
      for (let sen = 0; sen < objCabo.sensors.length; sen++) {
        sensorIdForMat++
        const meuY = (sen + 0.5) * Math.trunc(distanciaEntreSensores)

        const objetoSensor = new BABYLON.MeshBuilder.CreateSphere(
          `objetoSensor${sensorIdForMat}`,
          {
            diameter: 0.5,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false
          },
          scene
        )

        //objetoSensor.position.y = objCabo.sensors[sen].position_y;
        objetoSensor.position.y = alturaBase + 0.7 + 1.1 * sen
        const novoMat = matSensor.clone(`novoMat${sensorIdForMat}`)
        novoMat.id = `novoMat${sensorIdForMat}`

        const objSensor = objCabo.sensors[sen]

        const tempVariaSensor = { temperatura: objSensor.t }

        let tempColor = '#FFFFFFFF'

        if (sen <= objCabo.level) {
          tempColor = `${objSensor.color}FF`
          novoMat.diffuseColor = new BABYLON.Color4.FromHexString(tempColor)
        } else {
          novoMat.diffuseColor = new BABYLON.Color4.FromHexString(tempColor)
        }

        objetoSensor.isPickable = true
        objetoSensor.parent = objetoCabo

        objetoSensor.actionManager = new BABYLON.ActionManager(scene)
        objetoSensor.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction(
            BABYLON.ActionManager.OnPickTrigger,
            event => {
              const pickedMesh = event.meshUnderPointer
              const cabo = pickedMesh.parent
              const arco = cabo.parent
              if (showInfo === false) {
                criaInfoLabels(arc, arco)
                showInfo = true
                arcSee.push(arco.name)
              } else {
                if (arcSee.includes(arco.name)) {
                  _.forEach(scene.meshes, val => {
                    if (
                      val.name.includes('infoObjeto') &&
                      val.name.includes(arco.name)
                    ) {
                      val.visibility = !val.visibility
                    }
                  })
                  // TOGGL
                } else {
                  fncChangeViewInfo()
                  criaInfoLabels(arc, arco)
                  arcSee.push(arco.name)
                }
                // esconde info
              }
            }
          )
        )
        objetoSensor.enablePointerMoveEvents = true
        objetoSensor.material = novoMat
        // + (maxDepthCableArc - objCabo.depth)
        objetoSensor.sensor = {
          cor: novoMat.diffuseColor,
          sensor_id: sen + 1,
          level_sensor: objCabo.level,
          arc_id: arc + 1,
          cable_number: objCabo.cab,
          arc_index: objArco.index,
          real_level: sen + 1
        }
      }
      // Nome Cabo here

      NomeCabo(
        scene,
        '#242d45',
        [
          { val: objArco.cables[cab].cab, tam: 160 },
          { val: 'Cabo', tam: 150 }
        ],
        objetoCabo,
        alturaCaboTeto - 0.2,
        1.0,
        0.2,
        0.5
      )
    }
  }

  distanciaEntreSensores = 1.1
  // 4-Elevação da superfície
  for (let arc = 0; arc < objetoArmazem.length; arc++) {
    let ultAltCabo = 0
    let ultPntInit = 1
    const posArcoNivel = Math.trunc(arc * distanciaEntreSensores + 2)
    const alturaParedeMaxima = profundidadeFundo + alturaParedeArmazem
    const numCables = objetoArmazem[arc].cables.length

    for (let cab = 0; cab <= numCables; cab++) {
      const contNivelProduto = fncAltCabo(
        objetoArmazem[arc].cables[cab],
        distanciaEntreSensores,
        termoReport
      ) // TODO:
      let altProxima = contNivelProduto === 1 ? 2 : contNivelProduto
      // CALC PROXPNT TODO:
      let proxPnt =
        Math.trunc(
          (largBaseArmazem / (objetoArmazem[arc].cables.length + 1)) * (cab + 1)
        ) + 1
      if (cab === 0) {
        // Caso for o caso do primeiro cabo define o nível lateral
        const proxNivelProd = fncAltCabo(
          objetoArmazem[arc].cables[cab + 1],
          distanciaEntreSensores,
          termoReport
        )
        if (proxNivelProd >= contNivelProduto) {
          // Enchendo
          ultAltCabo =
            contNivelProduto > alturaParedeMaxima
              ? alturaParedeMaxima
              : contNivelProduto
        } else {
          // Esvaziando
          ultAltCabo =
            contNivelProduto + 1 > alturaParedeMaxima
              ? alturaParedeMaxima - 1
              : contNivelProduto + 1
        }
      } else if (cab === numCables) {
        // Quando for o último cabo define o nível lateral
        proxPnt += 1
        const anteNivelProd = fncAltCabo(
          objetoArmazem[arc].cables[numCables - 2],
          distanciaEntreSensores,
          termoReport
        )
        const anteNivelAtual = fncAltCabo(
          objetoArmazem[arc].cables[numCables - 1],
          distanciaEntreSensores,
          termoReport
        )
        if (anteNivelProd >= anteNivelAtual) {
          // Enchendo
          altProxima =
            anteNivelAtual > alturaParedeMaxima
              ? alturaParedeMaxima
              : anteNivelAtual
        } else {
          // Esvaziando
          altProxima =
            anteNivelAtual + 1 > alturaParedeMaxima
              ? alturaParedeMaxima - 1
              : anteNivelAtual + 1
        }
      }
      fncLevelArmazem(
        superficie,
        posArcoNivel,
        ultAltCabo,
        altProxima,
        ultPntInit,
        proxPnt
      ) // TODO:
      // Eleva os níveis das cabeceiras
      if (arc === 0) {
        const maiorQueB = fncAvgProductCompare(
          superficie,
          posArcoNivel,
          posArcoNivel - 1,
          ultPntInit,
          proxPnt
        ) // TODO:
        let altPosA = 0
        let altPosB = 0
        let posicaoArco = posArcoNivel

        altPosA = maiorQueB ? ultAltCabo * 0.85 : ultAltCabo * 0.85
        altPosB = maiorQueB ? altProxima * 0.85 : altProxima * 0.85
        posicaoArco -= 1

        altPosA = altPosA < 0 ? 0 : altPosA
        altPosB = altPosB < 0 ? 0 : altPosB
        altPosA = altPosA > alturaParedeMaxima ? alturaParedeMaxima : altPosA
        altPosB = altPosB > alturaParedeMaxima ? alturaParedeMaxima : altPosB
        fncLevelArmazem(
          superficie,
          posicaoArco,
          altPosA,
          altPosB,
          ultPntInit,
          proxPnt,
          true
        ) // TODO:
      }

      if (arc === objetoArmazem.length - 1) {
        const maiorQueB = fncAvgProductCompare(
          superficie,
          posArcoNivel,
          posArcoNivel - 1,
          ultPntInit,
          proxPnt
        ) // TODO:
        let altPosA = 0
        let altPosB = 0
        let posicaoArco = posArcoNivel
        // Caso for a cabeceira final
        posicaoArco += 2
        altPosA = !maiorQueB ? ultAltCabo * 0.85 : ultAltCabo * 0.85
        altPosB = !maiorQueB ? altProxima * 0.85 : altProxima * 0.85

        altPosA = altPosA < 0 ? 0 : altPosA
        altPosB = altPosB < 0 ? 0 : altPosB
        altPosA = altPosA > alturaParedeMaxima ? alturaParedeMaxima : altPosA
        altPosB = altPosB > alturaParedeMaxima ? alturaParedeMaxima : altPosB
        fncLevelArmazem(
          superficie,
          posicaoArco,
          altPosA,
          altPosB,
          ultPntInit,
          proxPnt,
          true
        ) // TODO:
      }
      ultAltCabo = altProxima
      ultPntInit = proxPnt
    }
  }

  // Material Produto
  const matProduto = new BABYLON.StandardMaterial('matProduto', scene)
  matProduto.backFaceCulling = false
  if (grao === 'Milho') {
    matProduto.diffuseTexture = new BABYLON.Texture(
      '/assets/texturas/graos/corn.jpg',
      scene
    )
  } else if (grao === 'Soja') {
    matProduto.diffuseTexture = new BABYLON.Texture(
      '/assets/texturas/graos/soybean.jpg',
      scene
    )
  } else if (grao === 'Trigo Duro' || grao === 'Trigo Mole') {
    matProduto.diffuseTexture = new BABYLON.Texture(
      '/assets/texturas/graos/wheat.jpg',
      scene
    )
  } else {
    matProduto.diffuseTexture = new BABYLON.Texture(
      '/assets/texturas/graos/produto.jpg',
      scene
    )
  }
  matProduto.diffuseTexture.uScale = 10
  matProduto.diffuseTexture.vScale = 10
  matProduto.specularColor = new BABYLON.Color3(0, 0, 0)

  // Malha do Produto
  const produto = BABYLON.MeshBuilder.CreateRibbon(
    'nivelProduto',
    { pathArray: superficie, updatable: false },
    scene
  )
  produto.material = matProduto
  produto.backFaceCulling = true
  produto.isPickable = false
  // produto.visibility = 0.5 => ORIGINAL
  produto.visibility = produto_visivel
  produto.parent = conjuntoArco

  objetoCamadaProduto = conjuntoArco
  fncChangeViewInfo(scene, produto_info)
}

const fncSurfaceArmazem = (
  superficie,
  linha,
  incDistArco,
  qtdLinhaLarg,
  distEntreCabos
) => {
  // Superficie lateral inicial
  superficie[linha].push(new BABYLON.Vector3(incDistArco, 0, 0))
  for (let i = 0; i <= qtdLinhaLarg; i++) {
    superficie[linha].push(
      new BABYLON.Vector3(incDistArco, 0, i * distEntreCabos)
    )
  }
  // Superficie lateral final
  superficie[linha].push(
    new BABYLON.Vector3(incDistArco, 0, qtdLinhaLarg * distEntreCabos)
  )
}

const maxDepthCableArcCalc = arc => {
  // Coleta o real nível do produto do armazém
  if (arc !== null || arc !== undefined) {
    let maxVal = 0
    const retorno = _.maxBy(arc.cables, (cabo, cc) => cabo.level, 0)
    if (retorno.level > maxVal) {
      maxVal = retorno.level
    }
    return maxVal
  }
  return 0
}

const fncAltCabo = (cabo, distSensor, termoReport) => {
  if (cabo === undefined) {
    return 0
  }
  return getLevelSensor(cabo, termoReport) * distSensor
}

const getLevelSensor = (c, termoReport) => c.level

const fncLevelArmazem = (
  superficie,
  linha,
  H1,
  H2,
  D1,
  D2,
  umaSuperficie = false
) => {
  const a = (H2 - H1) / (D2 - D1)
  const b = a * (0 - D1) + H1
  for (let x = D1; x < D2; x++) {
    const alt = a * x + b
    superficie[linha][x].y = alt
    if (!umaSuperficie) {
      superficie[linha + 1][x].y = alt
    }
  }
}

const fncAvgProductCompare = (superficie, arco1, arco2, pIni, pFim) => {
  let AisBigger = false
  const totalLarg = superficie[arco1].length
  let contA = 0
  let contB = 0
  for (let x = pIni; x <= pFim; x++) {
    contA += superficie[arco1][x].y
    contB += superficie[arco2][x].y
  }
  const mediaA = contA / totalLarg
  const mediaB = contB / totalLarg
  if (mediaA > mediaB) {
    AisBigger = true
  }
  return AisBigger
}

const NomeObjeto = (
  nome,
  scene,
  corFundo,
  id,
  parent,
  altura,
  tamanho,
  x = 0,
  z = 0
) => {
  const infoObjeto = BABYLON.Mesh.CreatePlane(
    `infoObjeto ${nome}`,
    tamanho,
    scene,
    false
  )

  // BORDA
  infoObjeto.enableEdgesRendering()
  infoObjeto.edgesWidth = 4.0
  infoObjeto.edgesColor = corFundo
  //
  infoObjeto.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL
  infoObjeto.position = new BABYLON.Vector3(x - 0.5, altura, z)
  infoObjeto.parent = parent
  const texturaInfoObjeto = new BABYLON.DynamicTexture(
    'texturaInfoObjeto',
    512,
    scene,
    true
  )
  const fontSize = 190
  if (typeof id === 'object') {
    for (let i = 0; i < id.length; i++) {
      texturaInfoObjeto.drawText(
        id[i].val,
        null,
        300 + id[i].tam * i,
        `bold ${id[i].tam}px arial`,
        'black',
        i === 0 ? corFundo : null
      )
    }
  } else {
    texturaInfoObjeto.drawText(
      id,
      null,
      300,
      `bold ${fontSize}px arial`,
      'black',
      corFundo
    )
  }
  infoObjeto.material = new BABYLON.StandardMaterial(
    'materialSuperficie',
    scene
  )
  infoObjeto.material.diffuseTexture = texturaInfoObjeto
  infoObjeto.material.specularColor = new BABYLON.Color3(0, 0, 0)
  infoObjeto.material.emissiveColor = new BABYLON.Color3(1, 1, 1)
  infoObjeto.material.backFaceCulling = false
  infoObjeto.isPickable = false
  texturaInfoObjeto.hasAlpha = true
  infoObjeto.material.freeze()
}

const NomeCabo = (
  scene,
  corFundo,
  id,
  parent,
  altura,
  tamanho,
  x = 0,
  z = 0
) => {
  const nomeObjeto = BABYLON.Mesh.CreatePlane('nomeCabo', tamanho, scene, false)
  nomeObjeto.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL
  nomeObjeto.position = new BABYLON.Vector3(x, altura + 5.5, z)
  nomeObjeto.parent = parent
  const texturaNomeCabo = new BABYLON.DynamicTexture(
    'texturaNomeCabo',
    512,
    scene,
    true
  )
  const fontSize = 250
  if (typeof id === 'object') {
    for (let i = 0; i < id.length; i++) {
      if (id[i].val === 'Cabo') {
        texturaNomeCabo.drawText(
          id[i].val,
          null,
          200 + id[i].tam * i,
          'bold 150px arial',
          'white',
          i === 0 ? corFundo : null
        )
      } else {
        texturaNomeCabo.drawText(
          id[i].val,
          null,
          200 + id[i].tam * i,
          'bold 250px arial',
          'white',
          i === 0 ? corFundo : null
        )
      }
    }
  } else {
    texturaNomeCabo.drawText(
      id,
      null,
      200,
      `bold ${fontSize}px arial`,
      'white',
      corFundo
    )
  }
  nomeObjeto.material = new BABYLON.StandardMaterial(
    'materialSuperficieCabo',
    scene
  )
  nomeObjeto.material.diffuseTexture = texturaNomeCabo
  nomeObjeto.material.specularColor = new BABYLON.Color3(0, 0, 0)
  nomeObjeto.material.emissiveColor = new BABYLON.Color3(1, 1, 1)
  nomeObjeto.material.backFaceCulling = false
  nomeObjeto.isPickable = false
  texturaNomeCabo.hasAlpha = true
}
