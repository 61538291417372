import React, { useState } from 'react';
import { TextField, Grid, MenuItem, Select, InputLabel, FormControl, InputAdornment, Checkbox, ListItemText } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import withReactContent from 'sweetalert2-react-content';
import Card from '../../../components/Card';
import CardText from '../../../components/CardText';
import AddressFields from '../../../components/AddressFields';
import BottomButtons from '../../../components/Buttons/BottomButtons';
import FockinkAPI from '../../../middlewares/Api';
import Breadcrumbs from '../../../components/Breadcrumbs';

const OrganizationCreate = (props) => {
  const [loading, setLoading] = useState(true);
  const [referenceMonth, setReferenceMonth] = useState(localStorage.listCostMount || new Date());
  const [data, setData] = useState({
    date: new Date(),
  });
  const [dataPlan, setDataPlan] = useState([]);
  const [dataAccounts, setDataAccounts] = useState();
  const [service, setService] = useState();
  const [dataAll, setDataAll] = useState();
  const [available, setAvailable] = useState(true);
  const breadcrumbs = {
    title: 'Cadastro de Organização',
    routes: [{ name: 'Organizações', to: '/organization' }, { name: 'Cadastro Organização' }],
  };
  const useStyles = makeStyles({
    textAlign: {
      textAlign: 'right',
    },
    btnSpace: {
      marginLeft: '15px',
    },
    bottomButtons: {
      marginTop: '15px',
      borderTop: '1px solid #ccc',
      paddingTop: '10px !important',
    },

    root: {
      width: '100%',
      marginTop: 30,
    },
  });
  const classes = useStyles();
  const back = {
    name: 'Cadastro de custo de armazenamento',
    to: '/ListCost/ConfigCost/contractedService',
  };
  const history = useHistory();

  const MySwal = withReactContent(Swal);

  const handleChangeLocation = (location) => {
    setData({ ...data, location });
  };

  const confDelete = () => {
    MySwal.fire({
      title: 'Tem certeza?',
      text: 'Essa ação não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, APAGAR!',
      cancelButtonText: 'Não, MANTER',
    }).then((result) => {
      if (result.isConfirmed) {
        data.status = 'I';
        const dt = {
          serviceName: data.serviceName,
          isAzureService: data.isAzureService,
          azureName: data.azureName,
          serviceType: data.serviceType,
          environment: data.environment,
          apportionmentForAll: data.apportionmentForAll,
          hasCustomValue: data.hasCustomValue,
          customValue: data.hasCustomValue ? parseFloat(data.customValue.replace(',', '.')) : 1,
          chargeable: data.chargeable,
          observations: data.observations,
          status: data.status,
        };
        FockinkAPI.put(`/contractedService/${props.match.params.id}`, dt).then(() => {
          toast.success('Dados salvos com sucesso');

          history.push('/ListCost/ConfigCost/contractedService');
        })
          .catch(async (error) => {
            const { response } = error;

            if (response.status === 400) {
              toast.error(response.data.validation.body.message);
            } else {
              toast.error(response.data.message);
            }
          });
      } else {
        data.status = 'A';
      }
    });
  };

  React.useEffect(() => {
    FockinkAPI.get(
      '/accounts',
    ).then((response) => {
      setDataAccounts(response.data.data);
    });
  }, []);
  React.useEffect(() => {
    FockinkAPI.get(
      '/plan',
    ).then((response) => {
      if (props.match.params.id === 'new') {
        setLoading(false);
      } else {
        setDataPlan(response.data.data);
        setLoading(false);
      }
    }).catch(async () => {
      if (props.match.params.id === 'new') {
        setLoading(false);
      }
    });
  }, [props.match.params.id]);

  React.useEffect(() => {
    const date = moment(referenceMonth).format('yyyy-MM');
    FockinkAPI.get(`/costManagement/contractedServiceCost?date=${date}`).then((response) => {
      setService(response.data.data.withoutRecord.services);
      setDataAll(response.data.data.withoutRecord.services.concat(response.data.data.azureServices.map((res) => res.azureName)));
    });
  }, []);

  const saveData = () => {
    // if (props.match.params.id === 'new') {
    console.log('plan', data.plan);
    const dt = {
      description: data.description,
      corporateName: data.corporateName,
      document: data.document,
      planId: data.plan,
      isFockinkOrg: data.isFockinkOrg,
      location: data.location,
      status: 'A',
    };
    FockinkAPI.post('/organizations', dt)
      .then(() => {
        toast.success('Dados salvos com sucesso');
        history.push('/organization');
      })
      .catch(async (error) => {
        const { response } = error;

        if (response.status === 400) {
          toast.error(response.data.validation?.body.message || response.data.message);
        }
      });
    // }
    // else if (data.status === 'I') {
    //   confDelete();
    // } else {
    //   const dt = {
    //     serviceName: data.serviceName,
    //     isAzureService: data.isAzureService,
    //     azureName: data.azureName,
    //     serviceType: data.serviceType,
    //     environment: data.environment,
    //     apportionmentForAll: data.apportionmentForAll,
    //     apportionmentClients: data.apportionmentClients,
    //     hasCustomValue: data.hasCustomValue,
    //     customValue: data.hasCustomValue ? parseFloat(data.customValue.toString().replace(',', '.')) : 1,
    //     chargeable: data.chargeable,
    //     observations: data.observations ? data.observations : undefined,
    //     status: data.status,
    //   };
    //   FockinkAPI.put(`/contractedService/${props.match.params.id}`, dt).then(() => {
    //     toast.success('Dados salvos com sucesso');
    //     history.push('/ListCost/ConfigCost/contractedService');
    //   })
    //     .catch(async (error) => {
    //       const { response } = error;

    //       if (response.status === 400) {
    //         toast.error(response.data.validation.body.message);
    //       } else {
    //         toast.error(response.data.message);
    //       }
    //     });
    // }
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Breadcrumbs
        routes={breadcrumbs.routes}
        title={breadcrumbs.title}
      />
      {
    loading ? (
      <div className={classes.root}>
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
      </div>
    )
      :

      (
        <Card>
          <CardText>

            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <TextField
                  variant="outlined"
                  name="description"
                  value={data?.description}
                  onChange={handleChange}
                  label="Descrição"
                />

              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  variant="outlined"
                  name="corporateName"
                  value={data?.corporateName}
                  onChange={handleChange}
                  label="Nome Coporativo"
                />

              </Grid>
              <Grid item xs={12} lg={4}>
                <TextField
                  variant="outlined"
                  name="document"
                  value={data?.document}
                  onChange={handleChange}
                  label="Documento"
                />

              </Grid>
              <Grid item xs={12} lg={4}>
                <FormControl variant="outlined">
                  <InputLabel id="isFockinkOrg">É uma organização Fockink?</InputLabel>
                  <Select
                    labelId="isFockinkOrg"
                    id="isFockinkOrg"
                    name="isFockinkOrg"
                    value={data?.isFockinkOrg}
                    label="isFockinkOrg"
                    onChange={handleChange}
                  >
                    <MenuItem value>Sim</MenuItem>
                    <MenuItem value={false}>Não</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} lg={4}>
                <FormControl variant="outlined">
                  <InputLabel id="plan">Plano</InputLabel>
                  <Select
                    labelId="plan"
                    id="plan"
                    name="plan"
                    value={data?.plan}
                    label="plan"
                    onChange={handleChange}
                  >

                    <MenuItem value="0a380b4e-bb15-4df1-b7bb-9ecf317c385b">
                      Plano Básico
                    </MenuItem>

                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <AddressFields
                  handleChangeLocation={handleChangeLocation}
                  data={data.location || []}
                />
              </Grid>
              <Grid
                item
                xs={12}
                className={`${classes.textAlign} ${classes.bottomButtons}`}
              >
                <BottomButtons saveData={saveData} backTo={back.to} />
              </Grid>
            </Grid>
          </CardText>
        </Card>
      )
      }
    </>
  );
};

export default OrganizationCreate;
