import React from 'react';
import '../../../../App.css';
import { Redirect } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import { toast } from 'react-toastify';
import FockinkAPI from '../../../../middlewares/Api';
import BackButton from '../../../../components/Buttons/BackButton';
import Breadcrumbs from '../../../../components/Breadcrumbs';

export default (props) => {
  const [inputs, setInputs] = React.useState([]);
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [refresh, setRefresh] = React.useState();

  const breadcrumb = {
    title: 'Análise física do solo',
    routes: [
      { to: '/settings', name: 'Configurações' },
      { name: 'Análise física do solo' },
    ],
  };

  const deleteItem = (value) => {
    FockinkAPI.delete(`/soilPhysicalAnalysis/${value}`).then(() => {
      toast.success('Dados deletados com sucesso');
    });
    setRefresh(Math.random());
  };

  React.useEffect(() => {
    setLoading(true);

    FockinkAPI.get(
      `/soilPhysicalAnalysis?accountId=${props.match.params.clid}`,
    ).then((response) => {
      setInputs(response.data.data);
      setLoading(false);
    });
  }, [refresh]);

  React.useEffect(() => {
    const columns = [
      { name: 'Pivô', selector: 'equipamentName' },
      { name: 'Registro', selector: 'registry' },
      { name: 'Laboratório', selector: 'laboratory' },
      {
        name: 'Data de recebimento',
        selector: 'receivingDate',
        cell: (row) => moment.utc(row.receivingDate).format('DD/MM/YYYY'),
        sortable: true,
      },
      {
        name: 'Data de expedição',
        selector: 'expirationDate',
        cell: (row) => moment.utc(row.expirationDate).format('DD/MM/YYYY'),
        sortable: true,
      },
      {
        name: 'Status',
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {row.status === 'A' ? 'Ativo' : 'Inativo'}
          </div>
        ),
      },
      {
        name: '',
        center: true,
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton color="secondary" onClick={() => setRedirect(row.id)}>
              <EditIcon />
            </IconButton>
            <IconButton color="secondary" onClick={() => deleteItem(row.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    ];
    setDtCl({ columns, data: inputs });
  }, [inputs]);

  if (redirect) {
    return (
      <Redirect
        push
        to={`/settings/soilPhysicalAnalysis/${props.match.params.clid}/${redirect}`}
      />
    );
  }

  if (loading) {
    return (
      <Skeleton variant="rect" height={500} width="100%" animation="wave" />
    );
  }

  return (
    <>
      <Breadcrumbs
        routes={breadcrumb.routes}
        title={breadcrumb.title}
        newBtn="Nova análise"
      />
      <Grid container spacing={1} style={{ minHeight: 'calc(100vh - 101px)' }}>
        <Grid item xs={12}>
          <DataTable
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: 'Linhas:',
              rangeSeparatorText: 'de',
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
            noDataComponent="Não existem dados para serem mostrados"
            columns={dtCl.columns}
            data={dtCl.data}
            title=""
          />
        </Grid>
        <Grid item xs={12} style={{ margin: '25px 0', textAlign: 'right' }}>
          <BackButton redirectTo="/settings" />
        </Grid>
      </Grid>
    </>
  );
};
