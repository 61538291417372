import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import EditIcon from '@material-ui/icons/Edit';
import { Redirect } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FockinkAPI from '../../../../middlewares/Api';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { currency } from '../../../../helpers/Intl';
import BottomButtons from '../../../../components/Buttons/BottomButtons';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
  root: {
    width: '100%',
    marginTop: 30,
  },
});

const StorageCostList = () => {
  const [dataColumns, setDataColumns] = useState({ columns: [], data: [] });
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [data, setData] = useState();
  const classes = useStyles();
  const urlParams = new URLSearchParams(window.location.search);
  const breadcrumbs = {
    routes: [
      { name: 'Gestão de Custos', to: '/listCost' },
      { name: 'Configurações', to: '/ListCost/ConfigCost' },
      { name: 'Custo de armazenamento' },
    ],
    title: 'Custo de armazenamento',
  };
  const back = {
    name: 'Configurações',
    to: '/ListCost/ConfigCost',
  };

  useEffect(() => {
    FockinkAPI.get('/costApportionment').then((response) => {
      setData(response.data.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const defaultColumns = [
      /* {
        name: 'Combustível',
        selector: 'fuel',
        cell: (row) => fuelTranslate[row.fuel],
        sortable: true,
      }, */
      {
        name: 'Custo total de RU',
        selector: 'totalRuCost',
        cell: (row) => currency(row.totalRuCost, 6),
        sortable: true,
      },
      {
        name: 'Valor por GB do Blob',
        selector: 'blobGbCost',
        cell: (row) => currency(row.blobGbCost, 6),
        sortable: true,
      },
      {
        name: 'Mês de Referência',
        selector: 'date',
        cell: (row) => moment(row.date).format('MM/yyyy'),
        sortable: true,
      },
      /* {
        name: 'Status',
        selector: 'status',
        cell: (row) => row.status === 'A' ? 'Ativo' : 'Inativo',
        sortable: true,
      }, */
      {
        name: '',
        center: true,
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton color="secondary" onClick={() => setRedirect(row.id)}>
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];
    setDataColumns({ columns: defaultColumns, data });
  }, [data]);

  if (redirect) {
    return <Redirect push to={`/ListCost/ConfigCost/costManagement/${redirect}`} />;
  }
  return (
    <div>
      <Breadcrumbs
        routes={breadcrumbs.routes}
        title={breadcrumbs.title}
        newBtn="Cadastrar Armazenamento"
        clientName={urlParams.get('client')}
      />

      {
       loading ?
         (
           <div className={classes.root}>
             <Skeleton />
             <Skeleton animation={false} />
             <Skeleton animation="wave" />
             <Skeleton />
             <Skeleton animation={false} />
             <Skeleton animation="wave" />
             <Skeleton />
             <Skeleton animation={false} />
             <Skeleton animation="wave" />
           </div>
         )
         :
         (
           <Grid container spacing={2}>
             <Grid item lg={12} xs={12}>
               <DataTable
                 pagination
                 paginationPerPage={15}
                 paginationComponentOptions={{
                   rowsPerPageText: 'Linhas:',
                   rangeSeparatorText: 'de',
                 }}
                 paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
                 noDataComponent="Não existem dados para serem mostrados"
                 columns={dataColumns.columns}
                 data={dataColumns.data}
                 defaultSortField="date"
                 defaultSortAsc
                 title=""
               />
             </Grid>
             <Grid
               item
               xs={12}
               className={`${classes.textAlign} ${classes.bottomButtons}`}
             >
               <BottomButtons backTo={back.to} />
             </Grid>
           </Grid>
         )
      }

    </div>
  );
};

export default StorageCostList;
