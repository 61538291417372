import React from 'react';
import { formatUnits } from '../helpers/Intl';

export default (props) => {
  const pa = formatUnits(props.pa, 'px', 16);
  const pl = formatUnits(props.pl, 'px', 16);
  const pr = formatUnits(props.pr, 'px', 16);
  const pt = formatUnits(props.pt, 'px', 16);
  const pb = formatUnits(props.pb, 'px', 16);
  const px = formatUnits(props.px, 'px', 16);
  const py = formatUnits(props.py, 'px', 16);
  const font = formatUnits(props.size, 'rem', 1.25);

  function getPaddingTop() {
    if (props.pt) {
      return `${pt.number}${pt.unit}`;
    }
    if (props.py) {
      return `${py.number}${py.unit}`;
    }
    if (props.pa) {
      return `${pa.number}${pa.unit}`;
    }
    return `${pt.number}${pt.unit}`;
  }
  function getPaddingBottom() {
    if (props.pb) {
      return `${pb.number}${pb.unit}`;
    }
    if (props.py) {
      return `${py.number}${py.unit}`;
    }
    if (props.pa) {
      return `${pa.number}${pa.unit}`;
    }
    return `${pb.number}${pb.unit}`;
  }
  function getPaddingLeft() {
    if (props.pl) {
      return `${pl.number}${pl.unit}`;
    }
    if (props.px) {
      return `${px.number}${px.unit}`;
    }
    if (props.pa) {
      return `${pa.number}${pa.unit}`;
    }
    return `${pl.number}${pl.unit}`;
  }
  function getPaddingRight() {
    if (props.pr) {
      return `${pr.number}${pr.unit}`;
    }
    if (props.px) {
      return `${px.number}${px.unit}`;
    }
    if (props.pa) {
      return `${pa.number}${pa.unit}`;
    }
    return `${pr.number}${pr.unit}`;
  }

  return (
    <div
      style={{
        alignItems: 'center',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        fontSize: `${font.number}${font.unit}`,
        fontWeight: '500',
        letterSpacing: '0.0125em',
        lineHeight: `${font.number * 1.6}${font.unit}`,
        paddingTop: getPaddingTop(),
        paddingBottom: getPaddingBottom(),
        paddingLeft: getPaddingLeft(),
        paddingRight: getPaddingRight(),
        backgroundColor: props.color,
        color: props.textColor,
      }}
    >
      {props.children}
    </div>
  );
};
