import React from 'react';
import { formatUnits } from '../helpers/Intl';

export default (props) => {
  const pa = formatUnits(props.pa, 'px', 16);
  const pl = formatUnits(props.pl, 'px', 16);
  const pr = formatUnits(props.pr, 'px', 16);
  const pt = formatUnits(props.pt, 'px', 16);
  const pb = formatUnits(props.pb, 'px', 16);
  const px = formatUnits(props.px, 'px', 16);
  const py = formatUnits(props.py, 'px', 16);
  const font = formatUnits(props.size, 'rem', 0.875);
  const weight = formatUnits(props.weight, '', 400);

  function getPaddingTop() {
    if (props.pt) {
      return `${pt.number}${pt.unit}`;
    }
    if (props.py) {
      return `${py.number}${py.unit}`;
    }
    if (props.pa) {
      return `${pa.number}${pa.unit}`;
    }
    return `${pt.number}${pt.unit}`;
  }
  function getPaddingBottom() {
    if (props.pb) {
      return `${pb.number}${pb.unit}`;
    }
    if (props.py) {
      return `${py.number}${py.unit}`;
    }
    if (props.pa) {
      return `${pa.number}${pa.unit}`;
    }
    return `${pb.number}${pb.unit}`;
  }
  function getPaddingLeft() {
    if (props.pl) {
      return `${pl.number}${pl.unit}`;
    }
    if (props.px) {
      return `${px.number}${px.unit}`;
    }
    if (props.pa) {
      return `${pa.number}${pa.unit}`;
    }
    return `${pl.number}${pl.unit}`;
  }
  function getPaddingRight() {
    if (props.pr) {
      return `${pr.number}${pr.unit}`;
    }
    if (props.px) {
      return `${px.number}${px.unit}`;
    }
    if (props.pa) {
      return `${pa.number}${pa.unit}`;
    }
    return `${pr.number}${pr.unit}`;
  }
  return (
    <div
      className={props.className}
      style={{
        width: '100%',
        height: '100%',
        fontSize: `${font.number}${font.unit}`,
        fontWeight: `${weight.number}`,
        lineHeight: `${1.57 * font.number}${font.unit}`,
        letterSpacing: `${font.number / 122}${font.unit}`, // '0.0071428571em'
        color: props.color,
        paddingTop: getPaddingTop(),
        paddingBottom: getPaddingBottom(),
        paddingLeft: getPaddingLeft(),
        paddingRight: getPaddingRight(),
      }}
    >
      {props.children}
    </div>
  );
};
