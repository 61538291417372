import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../App.css';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from 'react-data-table-component';
import EditIcon from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import NewButton from '../../../components/Buttons/NewButton';
import FockinkAPI from '../../../middlewares/Api';
import BackButton from '../../../components/Buttons/BackButton';
import Breadcrumbs from '../../../components/Breadcrumbs';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  textAlign: {
    textAlign: 'center',
  },
  searchBtn: {
    textAlign: 'right',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  bottomButtons: {
    margin: '25px 0',
    textAlign: 'right',
  },
  Rovr: {
    '&:hover': {
      backgroundColor: '#cecece',
      cursor: 'pointer',
    },
  },
});

export default () => {
  const [inputs, setInputs] = React.useState([]);
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    setLoading(true);
    FockinkAPI.get('/organizations').then((response) => {
      setInputs(response.data.data);
      if (response.data.data.length === 1) {
        setRedirect(response.data.data[0].id);
      }
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    const columns = [
      { name: 'Nome', selector: 'corporateName', center: true },
      { name: 'Descrição', selector: 'description', center: true },
      { name: 'Documento', selector: 'document', center: true },
      {
        name: 'Plano',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            {row.plan.description}
          </div>
        ),
      },
      {
        name: 'Endereço',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            {`${row.location.address1} ${row.location.address2} ${row.location.city} ${row.location.state} ${row.location.country} ${row.location.postalCode}`}
          </div>
        ),
      },
      {
        name: '',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color="secondary" onClick={() => setRedirect(row.id)}>
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];
    setDtCl({ columns, data: inputs });
  }, [inputs]);

  const breadcrumb = {
    title: 'Consulta de Organizações',
    routes: [{ name: 'Organizações' }],
  };

  if (redirect) return <Redirect push to={`organization/${redirect}`} />;

  if (loading) {
    return <Skeleton variant="rect" height={500} width="100%" animation="wave" />;
  }

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid item style={{ textAlign: 'right', position: 'absolute', top: '100px', right: '50px' }}>
        <NewButton
          redirectTo="/organization/organizationCreate"
          title="Nova Organização"
        />
      </Grid>
      <Grid container spacing={1} style={{ padding: '0 24px', minHeight: 'calc(100vh - 101px)' }}>
        <Grid item xs={12} style={{ paddingTop: '15px' }}>
          <DataTable
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{ rowsPerPageText: 'Linhas:', rangeSeparatorText: 'de' }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
            noDataComponent="Não existem dados para serem mostrados"
            columns={dtCl.columns}
            data={dtCl.data}
            title=""
          />
        </Grid>
        <Grid item xs={12} className={classes.bottomButtons}>
          <BackButton redirectTo="/" className={classes.btnSpace} />
        </Grid>
      </Grid>
    </>
  );
};
