import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

import { useTheme } from 'styled-components';

export function SensorsSummaryChart(props) {
  const originalTheme = useTheme();
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    if (window) {
      const resize = () => {
        if (window.innerWidth) {
          setWidth(window.innerWidth);
        }
      };
      window.addEventListener('resize', resize);

      return () => {
        window.removeEventListener('resize', resize);
      };
    }
  }, [window, window.innerWidth]);

  const options = {
    stroke: {
      width: 0,
    },
    colors: [
      originalTheme.colors.chartRedLight,
      originalTheme.colors.chartGreenLight,
    ],
    labels: [
      'Sensores acima do limite',
      'Sensores abaixo do limite',
    ],
    dataLabels: {
      enabled: false,
      formatter(val) {
        return val;
      },
      style: {
        fontSize: '22px',
        fontWeight: 'bold',
      },
      dropShadown: {
        enable: false,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: width > 1247 ? '50%' : '60%',
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Total',
              color: originalTheme.colors.messageDark8,
              fontWeight: 'bold',
              fontSize: 12,
              formatter(w) {
                return w.globals.seriesTotals.reduce(
                  (a, b) => a + b,
                  0,
                );
              },
            },
            value: {
              show: true,
              color: originalTheme.colors.messageDark8,
              fontWeight: 'bold',
              fontSize: 30,
            },
            name: {
              show: true,
              color: originalTheme.colors.messageDark8,
            },
          },
        },
      },
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 4000,
        options: {
          chart: {
            width: '240px',
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    fontSize: 14,
                  },
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 1678,
        options: {
          chart: {
            width: '240px',
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  value: {
                    fontSize: 19,
                  },
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 1000,
        options: {
          chart: {
            width: '240px',
          },
        },
      },
      {
        breakpoint: 900,
        options: {
          chart: {
            width: '180px',
          },
        },
      },
    ],
  };

  return (
    <Chart
      options={options}
      series={props.data}
      type="donut"
      // width={width > 1700 ? '300px' : '240px'}
    />
  );
}
