import React from 'react';
import { CarouselComponent } from '../../../../Carousel';
import { StorageUnitInfoCard } from '../StorageUnidCard';

export function SliderUnitInfor({ level, media, nivel }) {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    if (window) {
      const resize = () => {
        if (window.innerWidth) {
          setWidth(window.innerWidth);
        }
      };
      window.addEventListener('resize', resize);

      return () => {
        window.removeEventListener('resize', resize);
      };
    }
  }, [window]);

  const getPlantId = (name, sub) => {
    const filteredPlantId = media.find((p) => p.name === name && p.subarea === sub);
    return filteredPlantId.plantId;
  };

  const unitItemns = nivel.map((nivelItem, index) => (
    <StorageUnitInfoCard
      id={nivelItem.id}
      plantId={getPlantId(nivelItem.name, nivelItem.subarea)}
      key={nivelItem.id}
      series={
        level &&
        level.filter(
          (el) =>
            el.name === nivelItem.name && el.subarea === nivelItem.subarea,
        )
      }
      temperature={
        media &&
        typeof media[index] !== 'undefined' &&
        media[index].temperature_average
      }
      name={nivelItem.name}
      subarea={nivelItem.subarea}
      percent={nivelItem.storage}
      grain={nivelItem.grain}
      existData={media && media.length > 1}
      equipamentId={nivelItem.equipamentId}
    />
  ));

  return (
    <CarouselComponent
      infinite={width > 760 && media && media.length > 1}
      centerMode={width > 760 && media && media.length > 1}
      autoPlay={width > 760 && media && media.length > 1}
      className={
         (media && media.length > 1) || !media
           ? 'full-card-responsive'
           : 'full-card-responsive-width'
       }
    >
      {unitItemns}
    </CarouselComponent>
  );
}
