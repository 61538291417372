import React, { useState, useEffect } from 'react';
import moment from 'moment';
import DataTable from 'react-data-table-component';
import EditIcon from '@material-ui/icons/Edit';
import { Redirect } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import FockinkAPI from '../../../../middlewares/Api';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { currency } from '../../../../helpers/Intl';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: 30,
  },
});

const FuelCostList = () => {
  const [dataColumns, setDataColumns] = useState({ columns: [], data: [] });
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [data, setData] = useState();
  const classes = useStyles();

  const breadcrumbs = {
    routes: [
      { name: 'Configurações', to: '/settings' },
      { name: 'Cadastro de combustíveis' },
    ],
    title: 'Cadastro de combustíveis',
  };

  const fuelTranslate = {
    lpg: 'GLP R$/13kg',
    cng: 'GNV R$/m³',
    gasoline: 'Gasolina R$/L',
    gasolineWithAdditives: 'Gasolina Aditivada R$/L',
    ethanol: 'Etanol R$/L',
    dieselS10: 'Diesel S10 RR$/L',
    diesel: 'Diesel R$/L',
  };

  useEffect(() => {
    FockinkAPI.get('/fuelCost').then((response) => {
      setData(response.data.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const defaultColumns = [
      {
        name: 'Combustível',
        selector: 'fuel',
        cell: (row) => fuelTranslate[row.fuel],
        sortable: true,
      },
      {
        name: 'Valor',
        selector: 'price',
        cell: (row) => currency(row.price),
        sortable: true,
      },
      {
        name: 'Mês de Referência',
        selector: 'referenceDate',
        cell: (row) => moment(row.referenceDate).format('MM/yyyy'),
        sortable: true,
      },
      {
        name: 'Status',
        selector: 'status',
        cell: (row) => row.status === 'A' ? 'Ativo' : 'Inativo',
        sortable: true,
      },
      {
        name: '',
        center: true,
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton color="secondary" onClick={() => setRedirect(row.id)}>
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];
    setDataColumns({ columns: defaultColumns, data });
  }, [data]);

  if (redirect) {
    return <Redirect push to={`/settings/costFuel/${redirect}`} />;
  }
  return (
    <div>
      <Breadcrumbs
        routes={breadcrumbs.routes}
        title={breadcrumbs.title}
        newBtn="Cadastrar combustível"
      />

      {
       loading ?
         (
           <div className={classes.root}>
             <Skeleton />
             <Skeleton animation={false} />
             <Skeleton animation="wave" />
             <Skeleton />
             <Skeleton animation={false} />
             <Skeleton animation="wave" />
             <Skeleton />
             <Skeleton animation={false} />
             <Skeleton animation="wave" />
           </div>
         )
         :
         (
           <div>
             <DataTable
               pagination
               paginationPerPage={15}
               paginationComponentOptions={{
                 rowsPerPageText: 'Linhas:',
                 rangeSeparatorText: 'de',
               }}
               paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
               noDataComponent="Não existem dados para serem mostrados"
               columns={dataColumns.columns}
               data={dataColumns.data}
               defaultSortField="plantName"
               defaultSortAsc={false}
               title=""
             />
           </div>
         )
      }

    </div>
  );
};

export default FuelCostList;
