// import React from 'react';

function motorTopLeft(angleCable, topCable, leftCable) {
  let top;
  let left;

  if (angleCable <= 60) {
    top = topCable - 100;
    left = leftCable;
    // if (left < 190) {
    //   left = 214;
    // }
  }

  return { top, left };
}

export default function autoDispositionMotors(engines, cablesDisposition) {
  const disposition = [];
  if ((!engines) || (!cablesDisposition)) {
    return '';
  }

  engines.map((engine) =>
    cablesDisposition.map((cable) => {
      if (cable.cable === engine.cableRef) {
        disposition.push({
          cable: engine.cableRef,
          angleCable: cable.angle,
          engineId: engine.idMotor,
          engineName: engine.nameMotor,
          engineOnOff: engine.onOff,
          engineTop: motorTopLeft(cable.angle, cable.top, cable.left).top,
          engineLeft: motorTopLeft(cable.angle, cable.top, cable.left).left,
          engineAeration: engine.aeration,
        });
      }
      return '';
    }));

  return disposition;
}
