// import React from 'react';
import countCablesInArc from '../../../helpers/countCablesInArc';

export default function autoDispositionCables(cables, cableDiameter, width, height) {
  let totalCabos = 0;
  const disposition = [];

  // console.log('cables=', cables);

  cables.forEach((item, index) => {
    // contabiliza o total de cabos no arco em específico
    totalCabos = countCablesInArc(cables, item.arc);
    // console.log('totalCabos=', totalCabos);

    disposition.push(
      {
        cable: item.cable,
        color: item.color,
        arc: item.arc,
        angle: 0,
        top: ((height / 2) - (cableDiameter / 2)),
        left: ((width / (totalCabos + 1)) * (index + 1)) - (cableDiameter / 2),
      },
    );
  });

  // console.log('disposition=', disposition);

  return disposition;
}
