import styled from 'styled-components';

export const Wrapper = styled.div`
  box-sizing: border-box;
  margin: 50px auto;
  width: 100%;
  max-width: 1790px;
  height: 90%;
  max-height: 800px;
  padding: 14px;
  background: #fff;
  overflow-y: scroll;
  align-items: center;
  justify-content: center;

  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .close-btn {
    display: flex;
    justify-content: flex-end;
  }
`;
