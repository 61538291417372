export function headerCell({ value }) {
  const objCol = { v: value, s: { font: { sz: '11', bold: true } } };
  // console.log('object', objCol);
  return objCol;
}

export function dataCell({ value }) {
  const objCol = { v: value, s: { font: { sz: '11', bold: false } } };
  // console.log('object', objCol);
  return objCol;
}

export function headerCellBgColor({ value, bgColor, fontColor }) {
  !fontColor && (
    fontColor = '#000000'
  );
  const objCol = { v: value, s: { font: { sz: '11', bold: true, color: { rgb: fontColor.replace('#', '') } }, fill: { fgColor: { rgb: bgColor.replace('#', '') } } } };
  // console.log('object', objCol);
  return objCol;
}

export function dataCellBgColor({ value, bgColor, fontColor }) {
  !fontColor && (
    fontColor = '#000000'
  );
  const objCol = { v: value, s: { font: { sz: '11', bold: false, color: { rgb: fontColor.replace('#', '') } }, fill: { fgColor: { rgb: bgColor.replace('#', '') } } } };
  // console.log('object', objCol);
  return objCol;
}

/*
exemplos de estilização:

  { v: 'teste', s: { font: { bold: true } } },
  { v: 'bold & color', t: 's', s: { font: { bold: true, color: { rgb: 'FF0000' } } } },
  { v: 'fill: color', t: 's', s: { fill: { bgColor: { rgb: 'E9E9E9' } } } },
  { v: 'line\nbreak', t: 's', s: { alignment: { wrapText: true } } },

  //pintando o fundo da celula
  { v: 'teste', s: { font: { sz: '10' }, fill: { fgColor: { rgb: '000000' } } } };

  // mmudando a cor da fonte
  { v: 'bold & color', t: 's', s: { font: { bold: true, color: { rgb: 'FF0000' } } } },

  //gera as setas
  { v: '3456', s: { font: { name: 'Webdings', bold: true, color: { rgb: 'FF0000' } } } }

*/
