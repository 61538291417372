/* eslint-disable react/no-array-index-key */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Switch from '@material-ui/core/Switch';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default (props) => {
  const [searchInternal, setSearchInternal] = React.useState({
    ...props.graphSettings,
    estimative: props.estimative,
  });

  const externalSearchInternal = (dt) => {
    setSearchInternal(dt);
    if (dt.from && dt.to) {
      props.setGraphSettings(dt);
    }
  };

  const handleChange = (event) => {
    saveSearch({ ...searchInternal, [event.target.name]: event.target.value });
  };

  const handleDateChange = (date, input) => {
    saveSearch({ ...searchInternal, [input]: date });
  };

  const saveSearch = (data) => {
    externalSearchInternal(data);
  };

  const setNewOffset = (newOffset) => {
    if (searchInternal.searchType === 'today') {
      getToday(newOffset);
    } else if (searchInternal.searchType.endsWith('D')) {
      getNDays(parseInt(searchInternal.searchType, 10), newOffset);
    } else {
      getNMonths(parseInt(searchInternal.searchType, 10), newOffset);
    }
  };

  const getToday = (newOffset) => {
    const nd = new Date();
    nd.setDate(nd.getDate() - newOffset);
    externalSearchInternal({
      ...searchInternal,
      from: new Date(nd),
      to: new Date(nd),
      group: 'day',
      offset: newOffset,
      searchType: 'today',
    });
  };

  const getNDays = (n, newOffset) => {
    const ndFrom = new Date();
    ndFrom.setDate(ndFrom.getDate() - n * (newOffset + 1));

    const ndTo = new Date();
    ndTo.setDate(ndTo.getDate() - n * newOffset);
    externalSearchInternal({
      ...searchInternal,
      from: new Date(ndFrom),
      to: new Date(ndTo),
      group: 'day',
      offset: newOffset,
      searchType: `${n}D`,
    });
  };

  const getNMonths = (n, newOffset) => {
    const ndFrom = new Date();
    ndFrom.setMonth(ndFrom.getMonth() - n * (newOffset + 1));

    const ndTo = new Date();
    ndTo.setMonth(ndTo.getMonth() - n * newOffset);

    externalSearchInternal({
      ...searchInternal,
      from: new Date(ndFrom),
      to: new Date(ndTo),
      group: 'month',
      offset: newOffset,
      searchType: `${n}M`,
    });
  };

  return (
    <Grid
      container
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
    >
      <Grid item>
        <ToggleButtonGroup
          value={searchInternal.searchType}
          color="primary"
          variant="contained"
        >
          {!props.notToday && (
            <ToggleButton value="today" onClick={() => getToday(0)}>
              Hoje
            </ToggleButton>
          )}
          <ToggleButton value="6D" onClick={() => getNDays(6, 0)}>
            7 Dias
          </ToggleButton>
          <ToggleButton value="30D" onClick={() => getNDays(30, 0)}>
            30 Dias
          </ToggleButton>
          <ToggleButton value="12M" onClick={() => getNMonths(12, 0)}>
            12 Meses
          </ToggleButton>
          <ToggleButton
            value="custom"
            onClick={() =>
              externalSearchInternal({
                ...searchInternal,
                from: null,
                to: null,
                searchType: 'custom',
                group: 'day',
              })
            }
          >
            Outro
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      {searchInternal.searchType !== 'custom' ? (
        <Grid
          item
          style={{ cursor: 'pointer' }}
          onClick={() => setNewOffset(searchInternal.offset + 1)}
        >
          <KeyboardArrowLeftIcon />
        </Grid>
      ) : (
        <></>
      )}

      <Grid item xs={2}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ width: '100%' }}>
          <KeyboardDatePicker
            disabled={searchInternal.searchType !== 'custom'}
            margin="normal"
            label="Início"
            format="dd/MM/yyyy"
            value={searchInternal.from}
            name="from"
            onChange={(e) => handleDateChange(e, 'from')}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={2}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ width: '100%' }}>
          <KeyboardDatePicker
            disabled={searchInternal.searchType !== 'custom'}
            margin="normal"
            label="Fim"
            format="dd/MM/yyyy"
            value={searchInternal.to}
            name="to"
            onChange={(e) => handleDateChange(e, 'to')}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      {searchInternal.searchType !== 'custom' ? (
        <Grid
          item
          style={{
            cursor: 'pointer',
            color: searchInternal.offset > 0 ? '#727272' : '#bdbdbd',
          }}
          onClick={() => {
            if (searchInternal.offset > 0) {
              setNewOffset(searchInternal.offset - 1);
            }
          }}
        >
          <KeyboardArrowRightIcon />
        </Grid>
      ) : (
        <></>
      )}

      {searchInternal.searchType === 'custom' ? (
        <Grid item xs={1}>
          <FormControl style={{ width: '100%' }}>
            <InputLabel>Agrupado</InputLabel>
            <Select
              disabled={searchInternal.searchType !== 'custom'}
              value={searchInternal.group}
              onChange={handleChange}
              name="group"
            >
              <MenuItem value="day">Dia</MenuItem>
              <MenuItem value="month">Mês</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      ) : (
        <></>
      )}

      {searchInternal.searchType !== 'today' && (
        <Grid item>
          <FormControlLabel
            control={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Switch
                // defaultChecked
                checked={searchInternal.estivative}
                onChange={(e) => {
                  setSearchInternal({
                    ...searchInternal,
                    estivative: e.target.checked,
                  });
                  props.setEstimative(e.target.checked);
                }}
                name="seeEstimative"
                color="primary"
              />
            }
            label="Previsão"
          />
        </Grid>
      )}

      {/* <Grid item xs={3}>
        <FormControl style={{ width: '100%' }}>
          <InputLabel>Inversor</InputLabel>
          <Select
            value={searchInternal.inv}
            onChange={handleChange}
            name="inv"
          >
            <MenuItem value={undefined}>Todos</MenuItem>
            {buildItems(props.invs)}
          </Select>
        </FormControl>
      </Grid> */}
    </Grid>
  );
};
