import React from 'react';

function positionTextCable(lengthCableNumber) {
  let result = '';
  switch (lengthCableNumber) {
    case 1:
      result = '180';
      break;
    case 2:
      result = '140';
      break;
    case 3:
      result = '100';
      break;
    default:
      result = '80';
  }
  return result;
}

export default function DropWatter({ value }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="55px"
      height="70px"
      viewBox="0 0 427 643"
    >
      <path
        stroke="#556E7B"
        strokeWidth="15"
        fill="#FFFFFF"
        d="M399 331l-185 -321c-62,107 -124,214 -187,321 -64,111 12,299 187,307 191,-11 244,-201 185,-307z"
      />
      <text
        fill="#556E7B"
        fontSize="150"
        fontFamily="Roboto"
        fontWeight="800"
        x="150"
        y="300"
      >
        %
      </text>
      <text
        fill="#556E7B"
        fontSize="120"
        fontFamily="Roboto"
        fontWeight="800"
        x={positionTextCable(value.toString().length)}
        y="450"
      >
        {value}
      </text>
    </svg>
  );
}
