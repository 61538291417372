import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import ApexCharts from 'apexcharts';

const WeatherStationReportChart = (props) => {
  React.useEffect(() => {
    ApexCharts.exec('myChart', 'hideSeries', ['UV']);
    ApexCharts.exec('myChart', 'hideSeries', ['Velocidade do Vento']);
    ApexCharts.exec('myChart', 'hideSeries', ['Evapotranspiração']);
  }, []);
  return (
    <Grid container spacing={3}>
      {props.loading ? (
        <Skeleton variant="rect" height={320} width="100%" animation="wave" />
      ) : (
        <Grid item xs={12} style={{ minHeight: '320px' }}>
          <ReactApexChart
            options={props.state?.options}
            series={props.state?.series}
            type={props.state?.type}
            height={props.state.options?.chart.height}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default WeatherStationReportChart;
