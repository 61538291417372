export const grains = [
  'Milho',
  'Soja',
  'Trigo Duro',
  'Trigo Mole',
  'Arroz',
  'Aveia',
  'Sorgo',
  'Cevada',
  'Canola',
  'Girassol',
  'Cacau',
  'Algodão em Sem.',
  'Feijão',
  'Café',
];
