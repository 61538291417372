// Basic
import React from 'react';
// UI, images, styles and icons
import '../../../../../App.css';
import Grid from '@material-ui/core/Grid';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NavigationIcon from '@material-ui/icons/Navigation';
import Skeleton from '@material-ui/lab/Skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Tabs } from 'antd';
import Card from '../../../../../components/Card';
import CardTitle from '../../../../../components/CardTitle';
import CardText from '../../../../../components/CardText';
import CardActions from '../../../../../components/CardActions';
import Spacer from '../../../../../components/Spacer';
import { formatDate } from '../../../../../helpers/Intl';
import colors from '../../../../../styles/colors';
import TempAtual from '../../../../../components/Weather/Components/TempAtual/tempAtual';
import UmidAtual from '../../../../../components/Weather/Components/UmidAtual/umidAtual';
import ChuvaAtual from '../../../../../components/Weather/Components/ChuvaAtual/chuvaAtual';
import VentoAtual from '../../../../../components/Weather/Components/VentoAtual/ventoAtual';
import UvAtual from '../../../../../components/Weather/Components/UvAtual/uvAtual';
import ForecastCard from '../../../../../components/Weather/ForecastCard/forecastCard';

const useStyles = makeStyles((theme) => ({
  sourceSub: {
    fontSize: '12px',
    fontWeight: '300',
    margin: '5px 3px 0',
    textAlign: 'left',
  },
  sourceSubInverse: {
    fontSize: '12px',
    fontWeight: '300',
    margin: '5px 0 0 3px',
    textAlign: 'right',
  },
  iconTemp: {
    fontSize: '30px',
    margin: 'auto',
    width: '100%',
  },
  iconTempImg: {
    background: 'grey',
    borderRadius: '10px',
    maxHeight: '40px',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    margin: 'auto 3px',
    textAlign: 'left',
  },
  secondaryHeadingTitle: {
    fontSize: '12px',
  },
  secondaryHeadingValue: {
    fontWeight: '500',
    fontSize: '17px',
  },
  tempSub: {
    fontSize: '13px',
    fontWeight: 'bold',
    margin: 'auto 3px',
    textAlign: 'left',
  },
  eachBox: {
    borderRight: '1px solid gray',
  },
  dayHead: {
    textAlign: 'center',
    margin: '0 0 17px',
  },
  title: {
    margin: '0px 0px 8px',
  },
}));
export default (props) => {
  const [data, setData] = React.useState(undefined);
  const [dialog, setDialog] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const { TabPane } = Tabs;

  React.useEffect(() => {
    const dt = {};

    if (
      props.weatherData &&
      props.weatherData.forecast &&
      props.weatherData.forecast.list &&
      props.weatherData.forecast.list.length > 0
    ) {
      Object.assign(dt, {
        ...props.weatherData.forecast.list[0],
        source: props.weatherData.forecast.source,
      });
    }

    if (props.weatherData && props.weatherData.current) {
      let { source } = props.weatherData.current;
      if (source !== dt.source && dt.source) {
        source = `${source} + ${dt.source}`;
      } else {
        source = `${source}`;
      }

      Object.assign(dt, { ...props.weatherData.current, source });
    }

    setData(dt);
  }, [props.weatherData]);

  const getIcon = (iconUrl, iconId) => {
    if (iconUrl) {
      return (
        <img style={{ maxWidth: '100%' }} src={iconUrl} alt="" className={classes.iconTempImg} />
      );
    }
    if (iconId) {
      return (
        <img
          style={{ maxWidth: '100%' }}
          src={`/images/accuweatherIcons/${iconId}-s.png`}
          alt=""
          className={classes.iconTempImg}
        />
      );
    }
    return <WbSunnyIcon className={classes.iconTemp} />;
  };

  // const formatData = (date) => {
  //   const reg = date.match(/(\d{4})-(\d{2})-(\d{2})/);
  //   if (reg) {
  //     return `${reg[3]}/${reg[2]}`;
  //   }
  //   return date;
  // };

  if (!data) {
    return <Skeleton variant="rect" height="100%" width="100%" animation="wave" />;
  }

  return (
    <Card similar>
      <CardText pa="0px" pt="0">
        <Card
          outlined
          hover
          changeBgColor
          color={colors.primaryUserBrighter}
          onClick={() => {
            setDialog(true);
          }}
        >
          <CardText pa="8px">
            <h3 className={classes.title}>Tempo Atual</h3>
            <Grid container spacing={1} alignContent="center" alignItems="center">
              <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: 'center' }}>
                {getIcon(data.iconUrl, data.iconId)}
              </Grid>
              <Grid item lg={10} xs={10}>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={4} md={4} lg={6} xl={4}>
                    <small style={{ fontSize: '13px', fontWeight: '200' }}>T. Atual </small>
                    <h4 className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}>
                      <TempAtual info1={data.temp} info2="" source={data.source} type={0} />
                    </h4>
                  </Grid>
                  <Grid item xs={6} sm={4} md={4} lg={6} xl={4}>
                    <small style={{ fontSize: '13px', fontWeight: '200' }}>Umid. </small>
                    <h4 className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}>
                      <UmidAtual
                        info1={data.humid}
                        info2={data.humidity}
                        source={data.source}
                        type={0}
                      />
                    </h4>
                  </Grid>
                  <Grid item xs={6} sm={4} md={4} lg={6} xl={4}>
                    <small style={{ fontSize: '13px', fontWeight: '200' }}>Precip. </small>
                    <h4 className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}>
                      <ChuvaAtual info1={data.pop * 100} info2="" source={data.source} type={3} />
                    </h4>
                  </Grid>
                  <Grid item xs={6} sm={4} md={4} lg={6} xl={4}>
                    <small style={{ fontSize: '13px', fontWeight: '200' }}>UV </small>
                    <h4 className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}>
                      <UvAtual info1={data.uv} info2={data.uvIndex} />
                    </h4>
                  </Grid>
                  <Grid item xs={6} sm={4} md={4} lg={6} xl={4}>
                    <small style={{ fontSize: '13px', fontWeight: '200' }}>
                      Vento
                      {' '}
                      <Tooltip title={`${data.windDirection}°`}>
                        <NavigationIcon
                          style={{
                            width: 'auto',
                            height: '12px',
                            transform: `rotate(${data.windDirection}deg)`,
                          }}
                        />
                      </Tooltip>
                    </small>
                    <h4 className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}>
                      <VentoAtual info1={data.windSpeed} info2="" source={data.source} type={0} />
                    </h4>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardText>
          <CardActions>
            <Grid container>
              <Grid item xs={12}>
                {props.weatherData?.alerts && props.weatherData?.alerts.length > 0 && (
                  <Alert severity="warning" style={{ marginTop: 5 }}>
                    <strong>{props.weatherData?.alerts[0].event}</strong>
                  </Alert>
                )}
              </Grid>
              <Grid item xs={12}>
                {lg ? (
                  <Tooltip title={`Data de Aferição: ${formatDate(new Date(data.date))}`}>
                    <span className={classes.sourceSub}>{`Fonte: ${data.source}`}</span>
                  </Tooltip>
                ) : (
                  <>
                    <span className={classes.sourceSub}>{`Fonte: ${data.source}`}</span>
                    <Spacer />
                    <span className={classes.sourceSubInverse}>
                      {`${formatDate(
                        new Date(data.date),
                      )}`}

                    </span>
                  </>
                )}
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </CardText>
      <Dialog open={dialog} fullWidth maxWidth="lg" fullScreen={sm} aria-labelledby="termo">
        <CardTitle color={sm ? '#3d5059' : ''} textColor={sm ? '#FFF' : ''}>
          Dados Meteorológicos
          {' '}
          <Spacer />
          <CloseIcon
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setDialog(false);
            }}
          />
        </CardTitle>
        <DialogContent>
          <Grid item xs={12} sm={12} lg={12}>
            <h3 className={classes.title}>Hoje</h3>
            <Card>
              <CardText pa={sm ? '8px' : ''}>
                <Grid container spacing={1} alignContent="center" alignItems="center">
                  <Grid item xs={2} sm={3} md={2} lg={2} xl={2} style={{ textAlign: 'center' }}>
                    {getIcon(data.iconUrl, data.iconId)}
                  </Grid>
                  <Grid item xs={9} sm={9} md={10} lg={10} xl={10}>
                    <Grid container spacing={3}>
                      <Grid item xs={5} sm={3} md={2} lg={2} xl={2}>
                        <h4
                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                        >
                          T. Atual
                          {' '}
                        </h4>
                        <h4
                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                        >
                          <TempAtual info1={data.temp} info2="" source={data.source} type={0} />
                        </h4>
                      </Grid>
                      <Grid item xs={5} sm={3} md={2} lg={2} xl={2}>
                        <h4 className={classes.tempSub}>
                          <TempAtual info1={data.max} info2="" source={data.source} type={1} />
                        </h4>
                        <h4 className={classes.tempSub}>
                          <TempAtual info1={data.min} info2="" source={data.source} type={2} />
                        </h4>
                      </Grid>
                      <Grid item xs={5} sm={3} md={2} lg={2} xl={2}>
                        <h4
                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                        >
                          Umid.
                          {' '}
                        </h4>
                        <h4
                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                        >
                          <UmidAtual
                            info1={data.humid}
                            info2={data.humidity}
                            source={data.source}
                            type={0}
                          />
                        </h4>
                      </Grid>
                      <Grid item xs={5} sm={3} md={2} lg={2} xl={2}>
                        <h4
                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                        >
                          Precip.
                          {' '}
                        </h4>
                        <h4
                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                        >
                          <ChuvaAtual
                            info1={data.pop * 100}
                            info2=""
                            source={data.source}
                            type={3}
                          />
                        </h4>
                      </Grid>
                      <Grid item xs={5} sm={3} md={2} lg={2} xl={2}>
                        <h4
                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                        >
                          UV
                          {' '}
                        </h4>
                        <h4
                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                        >
                          <UvAtual info1={data.uv} info2={data.uvIndex} />
                        </h4>
                      </Grid>
                      <Grid item xs={5} sm={3} md={2} lg={2} xl={2}>
                        <h4
                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                        >
                          Vento
                          {' '}
                          <Tooltip title={`${data.windDirection}°`}>
                            <NavigationIcon
                              style={{
                                width: 'auto',
                                height: '12px',
                                transform: `rotate(${data.windDirection}deg)`,
                              }}
                            />
                          </Tooltip>
                        </h4>
                        <h4
                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                        >
                          <VentoAtual
                            info1={props.weatherData?.current?.windSpeed}
                            info2={props.weatherData?.forecast?.list[0].windSpeed}
                            source={data.source}
                            type={0}
                          />
                        </h4>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardText>
            </Card>
            <Grid container spacing={2} style={{ marginBotton: 15 }}>
              <Grid item xs={6}>
                <Tooltip title={`Data de Aferição: ${formatDate(new Date(data.date))}`}>
                  <h4 className={classes.sourceSub}>{`Fonte: ${data.source}`}</h4>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <h4 className={classes.sourceSubInverse}>* openweathermap</h4>
              </Grid>
            </Grid>
            {props.weatherData?.alerts && props.weatherData?.alerts.length > 0 && (
              <Alert severity="warning" style={{ marginTop: 5 }}>
                <AlertTitle>{props.weatherData?.alerts[0].event}</AlertTitle>
                {props.weatherData?.alerts[0].description}
              </Alert>
            )}
          </Grid>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Previsão do Tempo" key="1">
              <DialogContentText>
                <Grid container spacing={3} alignContent="flex-start" alignItems="flex-start">
                  <Grid item xs={12} lg={12}>
                    <Grid container spacing={2}>
                      {props.weatherData &&
                      props.weatherData.forecast &&
                      props.weatherData.forecast.list ? (
                          [1, 2, 3, 4, 5, 6].map((e) => {
                            const nd = new Date();
                            nd.setDate(nd.getDate() + e);
                            const item = props.weatherData.forecast.list[e];

                            if (!item) {
                              return <Grid key={nd} item xs={12} lg={6} />;
                            }

                            return <ForecastCard nd={nd} sm={sm} item={item} />;
                          })
                        ) : (
                          <Grid item xs={12}>
                            <h3 style={{ textAlign: 'center', fontWeight: '400' }}>
                              Previsão do tempo indisponível
                            </h3>
                          </Grid>
                        )}
                    </Grid>

                    <Grid container spacing={2} style={{ marginBotton: 15 }}>
                      <Grid item xs={12}>
                        <h4 className={classes.sourceSub} style={{ marginTop: 0 }}>
                          {props.weatherData && `Fonte: ${props.weatherData.forecast.source}`}
                        </h4>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContentText>
            </TabPane>
          </Tabs>
        </DialogContent>
      </Dialog>
    </Card>
  );
};
