import React from 'react';
import '../../../App.css';
import Grid from '@material-ui/core/Grid';
import jwtDecode from 'jwt-decode';
import CardIconText from '../../../components/CardIconText';
import PortalPermissions from '../../../helpers/enums/PortalPermissions';
import { hasPermission } from '../../../helpers/permission';
import BackButton from '../../../components/Buttons/BackButton';
import Breadcrumbs from '../../../components/Breadcrumbs';

export default () => {
  const [reports, setReports] = React.useState([]);
  const token = localStorage.getItem('@FockinkAPP:token');
  const { systemIds } = jwtDecode(token);

  const breadcrumb = {
    title: 'Lista de Relatórios',
    routes: [{ name: 'Relatórios' }],
  };

  const rawReports = [
    {
      url: '/reports/thermoquality',
      name: 'Informe de Qualidade',
      icon: (
        <img
          style={{ width: 58, height: 58 }}
          src="\images\thermometryIcons\report.svg"
          alt="icon"
        />
      ),
      permission: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
      type: 2,
    },

    {
      url: '/reports/thermometry',
      name: 'Auditoria de Temperatura',
      icon: (
        <img
          style={{ width: 58, height: 58 }}
          src="\images\thermometryIcons\Termometro.svg"
          alt="icon"
        />
      ),
      permission: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
      type: 2,
    },
    {
      url: '/reports/aeration',
      name: 'Aeração',
      icon: (
        <img
          style={{ width: 58, height: 58 }}
          src="\images\icons\fan.svg"
          alt="icon"
        />
      ),
      permission: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
      type: 2,
    },
  ];

  const freeReports = [
    {
      url: '/reports/variableTracking',
      name: 'Tracking de Variáveis',
      icon: (
        <img
          style={{ width: 58, height: 58 }}
          src="\images\icons\analysis.svg"
          alt="icon"
        />
      ),
      permission: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
      type: 0,
    },
    // {
    //   url: '/reports/photolcoltaic-earnings',
    //   name: 'Fotovoltaica',
    //   icon: <img src="\images\icons\powerPlantIcon.svg" alt="icon" />,
    //   permission: PortalPermissions.PHOTOVOLTAIC_DASHBOARD_VIEW.value,
    //   type: 4,
    // },

    {
      url: '/reports/weatherStationReport',
      name: 'Estação Meteorológica',
      icon: (
        <img
          src="\images\icons\wheater-station.svg"
          alt="icon"
          style={{ width: 58, height: 58 }}
        />
      ),
      permission: PortalPermissions.PHOTOVOLTAIC_DASHBOARD_VIEW.value,
      type: 5,
    },

    {
      url: '/reports/irrigation-planning',
      name: 'Planejamento de Irrigação',
      icon: (
        <img
          src="\images\icons\pivotIcon.svg"
          alt="icon"
          style={{ width: 58, height: 58 }}
        />
      ),
      permission: PortalPermissions.PHOTOVOLTAIC_DASHBOARD_VIEW.value,
      type: 1,
    },
    {
      url: '/reports/engineCost',
      name: 'Consumo de motores',
      icon: (
        <img
          src="\images\icons\energy.svg"
          alt="icon"
          style={{ width: 58, height: 58 }}
        />
      ),
      type: 2,
    },
  ];

  React.useEffect(() => {
    const r = rawReports.filter((f) => hasPermission(f.permission));
    const reps = [...r, ...freeReports].filter(
      (f) => systemIds.indexOf(f.type) !== -1 || f.type === 0,
    );
    setReports(reps);
  }, []);

  // if (redirect) return <Redirect push to={redirect} />;

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1}>
        {/* {reports === false && <h2>Carregando..</h2>} */}
        {reports.length === 0 && (
          <h2>Você não tem acesso a nenhum relatório</h2>
        )}
        {reports.map((s) => (
          <Grid
            item
            lg={2}
            md={2}
            xs={6}
            key={s.url}
            style={{ maxHeight: 300 }}
          >
            <CardIconText icon={s.icon} title={s.name} url={s.url} />
          </Grid>
        ))}
        <Grid item xs={12} style={{ margin: '25px 0', textAlign: 'right' }}>
          <BackButton redirectTo="/" />
        </Grid>
      </Grid>
    </>
  );
};
