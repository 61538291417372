/* eslint-disable indent */
import React, { useContext } from 'react';

import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import '../../../../App.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import AddIcon from '@material-ui/icons/Add';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import HistoryIcon from '@material-ui/icons/History';
import MultilineChartSharpIcon from '@material-ui/icons/MultilineChartSharp';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  MapContainer,
  TileLayer,
  LayersControl,
  SVGOverlay,
} from 'react-leaflet';
import { PageContext } from '../../../../hooks/Contexts/Page';
import EquipError from '../../../../components/EquipError';
import PivoSVG from '../../../../components/PivotSVG/index';
import Card from '../../../../components/Card';
import CardText from '../../../../components/CardText';
import CardTitle from '../../../../components/CardTitle';
import Spacer from '../../../../components/Spacer';
import WeatherCard from './Components/WeatherCard';
import FockinkAPI from '../../../../middlewares/Api';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import DashesHeader from '../../../../components/DashesHeader';
import BackButton from '../../../../components/Buttons/BackButton';
import WaterSVG from '../../../../components/SVGPivotIcons/WaterSVG';
import ScheduleSVG from '../../../../components/SVGPivotIcons/ScheduleSVG';
import PumpingSVG from '../../../../components/SVGPivotIcons/PumpingSVG';
import PressureGaugeSVG from '../../../../components/SVGPivotIcons/PressureGaugeSVG';
import EnergySVG from '../../../../components/SVGPivotIcons/EnergySVG';
import CannonSVG from '../../../../components/SVGPivotIcons/CannonSVG';
import IrrigationCarousel from './IrrigationCarousel';
import { IrrigationGraphic } from './IrrigationRainGraphic';
import IrrigationTabHistorical from './Tabs/IrrigationTabHistorical';
import IrrigationCreateOp2100 from './IrrigationCreateOp2100';
import colors from '../../../../styles/colors';
import { handleMessageError } from '../../../../helpers/handlerMessageError';
import { Modal } from '../../../../components';
import { FilesToDownload } from '../FilasToDownloadList';
import { DashTitle } from '../DashesStyles/index';
import 'leaflet/dist/leaflet.css';

const useStyles = makeStyles({
  recipe: {
    width: 'fit-content',
    background: '#C9C9C9',
  },
  btnBttn: {
    marginTop: '15px',
    textAlign: 'right',
  },
  title: {
    textAlign: 'center',
    width: '100%',
  },
  pivotBG: {
    background:
      'url(https://pt.map-of-sao-paulo.com/img/0/s%C3%A3o-paulo-mapa-de-sat%C3%A9lite.jpg)',
  },
  pivot: {
    alignSelf: 'flex-end',
    textAlign: 'right',
  },
});

moment.locale('pt-br');

export default (props) => {
  const [currentRecipe, setCurrentRecipe] = React.useState({});
  const [newRecipe, setNewRecipe] = React.useState({});
  const [intentRecipe, setIntentRecipe] = React.useState({});
  const [selectedRecipe, setSelectedRecipe] = React.useState(undefined);
  const [chartDialog, setChartDialog] = React.useState(false);
  const [historyDialog, setHistoryDialog] = React.useState(false);
  const [addRecipeDialog, setAddRecipeDialog] = React.useState(false);
  const [listRecipeDialog, setListRecipeDialog] = React.useState(false);
  const [data, setData] = React.useState({});
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const [loading, setLoading] = React.useState(true);
  const [zeroRecipes, setZeroRecipes] = React.useState(false);
  const [breadcrumbs, setBreadcrumbs] = React.useState({});
  const [recipes, setRecipes] = React.useState([]);
  const [mapStyle, setMapStyle] = React.useState(false);
  const [status, setStatus] = React.useState({
    statusColor: colors.transparent,
    statusTextColor: colors.lightGrey,
    emergencyStatus: 'Carregando...',
  });
  const [returnToList, setReturnToList] = React.useState(false);
  const [centerPosition, setCenterPosition] = React.useState([0, 0]);
  const [name, setName] = React.useState('');
  const [
    availableFilesToDownload,
    setAvailableFilesToDownload,
  ] = React.useState([]);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));
  const md = useMediaQuery(theme.breakpoints.only('md'));
  const lg = useMediaQuery(theme.breakpoints.only('lg'));
  function iconWidth() {
    if (xs) return '120px';
    if (sm) return '160px';
    if (md) return '100px';
    if (lg) return '120px';
    return '130px';
  }

  function findBoundsSVG(latitude, longitude, radius) {
    const angularRadius = (radius * 0.00001) / 0.9235;
    const lat1 = latitude - angularRadius;
    const lat2 = latitude + angularRadius;
    const long1 = longitude - angularRadius;
    const long2 = longitude + angularRadius;
    return [
      [lat1, long1],
      [lat2, long2],
    ];
  }

  const callRecipeApi = () => {
    FockinkAPI.get(
      `/dashboards/irrigation/${
        props.match.params.id
      }/recipes?dateBase=${moment(new Date()).format('yyyy-MM-DD')}&pastDays=2`,
    ).then((response) => {
      const rec = [
        ...(response.data.data.recipes.past || []),
        ...(response.data.data.recipes.current || []),
        ...(response.data.data.recipes.future || []),
      ];
      setRecipes(rec);
      if (rec.length === 0) {
        setZeroRecipes(true);
      }
      const { current, past } = response.data.data.recipes;
      if (current && current.length > 0) {
        setSelectedRecipe({
          ...current[current.length - 1].telemetry,
          ...current[current.length - 1].metadata,
        });
      } else if (past && past.length > 0) {
        setSelectedRecipe({
          ...past[past.length - 1].telemetry,
          ...past[past.length - 1].metadata,
        });
      }
    });
  };

  const formatDate = (dt, hr) =>
    dt
      ? `${moment.utc(dt).format('DD/MM/YYYY')} ${hr}`
      : 'Última programação não encontrada';

  const [openModalDownloadFiles, setOpenModalDownloadFiles] = React.useState(
    false,
  );
  const handleCloseModelDownloadFiles = () => setOpenModalDownloadFiles(false);
  const getDocumentsFileToDonwload = async () => {
    try {
      const response = await FockinkAPI.get(
        `equipaments/${props.match.params.id}/readFiles`,
      );
      const fomattedFileObject = response.data.data.map((file) => ({
        file,
        fileName: file.split('/').pop(),
      }));

      setAvailableFilesToDownload(fomattedFileObject);
    } catch (error) {
      handleMessageError(error);
    }
  };
  const callDataApi = () => {
    setLoading(true);
    FockinkAPI.get(`/dashboards/irrigation/${props.match.params.id}/status`)
      .then((response) => {
        setData({
          description: response.data.data.description,
          connection: response.data.data.connection,
          customLocation: response.data.data.customLocation,
          running: response.data.data.running,
          weatherStation: response.data.data.weatherStation,
          failures: response.data.data.failures,
          telemetry: response.data.data.recipe.telemetry,
          settings: response.data.data.settings,
          account: response.data.data.account,
          op1000: response.data.data.op1000,
        });
        if (response.data.data.op1000.wheelLength < 150) {
          setMapStyle(true);
        }
        setCenterPosition([
          response.data.data.customLocation?.lat ||
            response.data.data.settings.location.lat,
          response.data.data.customLocation?.lon ||
            response.data.data.settings.location.lon,
        ]);
        if (response.data.data.recipe) {
          setCurrentRecipe({
            ...response.data.data.recipe.telemetry,
            ...response.data.data.recipe.metadata,
            ...response.data.data.recipe.settings,
          });
        }
        const stats = checkStatusColor(response.data.data.recipe.telemetry);
        setStatus(stats);

        const { clientDescription, description } = response.data.data;
        setName(clientDescription || description);

        setBreadcrumbs({
          routes: [
            { name: 'Equipamentos', to: '/equipamentData' },
            { name: clientDescription || description },
          ],
          secondRoute: [
            { name: 'Clientes', to: '/clientsAdm/' },
            {
              name: 'Equipamentos',
              to: `/equipamentDataAdm?accountId=${response.data.data.account.id}&op=irrigation`,
            },
            {
              name:
                response.data.data.clientDescription ||
                response.data.data.description,
            },
          ],
        });
        setLoading(false);
      })
      .catch((e) => {
        if (e.response && e.response.status === 503) {
          setErrorMessage('Equipamento sem conexão para trazer os dados');
        } else if (e.response) {
          setErrorMessage(`${e.response.data.message} (${e.response.status})`);
        } else setErrorMessage(`${e.response}`);
        setLoading(false);
      });
  };
  const callDataApiNoLoad = () => {
    FockinkAPI.get(`/dashboards/irrigation/${props.match.params.id}/status`)
      .then((response) => {
        setData({
          description: response.data.data.description,
          connection: response.data.data.connection,
          running: response.data.data.running,
          weatherStation: response.data.data.weatherStation,
          failures: response.data.data.failures,
          telemetry: response.data.data.recipe.telemetry,
          settings: response.data.data.settings,
          op1000: response.data.data.op1000,
        });
        if (response.data.data.op1000.wheelLength < 150) {
          setMapStyle(true);
        }
        if (response.data.data.recipe) {
          setCurrentRecipe({
            ...response.data.data.recipe.telemetry,
            ...response.data.data.recipe.metadata,
            ...response.data.data.recipe.settings,
          });
        }
        const stats = checkStatusColor(response.data.data.recipe.telemetry);
        setStatus(stats);
      })
      .catch((e) => {
        if (e.response && e.response.status === 503) {
          setErrorMessage('Equipamento sem conexão para trazer os dados');
        } else if (e.response) {
          setErrorMessage(`${e.response.data.message} (${e.response.status})`);
        } else setErrorMessage(`${e.response}`);
      });
  };

  function checkStatusColor(d) {
    if (d.stFailEmergency) {
      return {
        statusColor: colors.error,
        statusTextColor: colors.white,
        emergencyStatus: `Emergência acionada no grau ${d.positionActual}`,
      };
    }
    if (d.stFailPumping) {
      return {
        statusColor: colors.error,
        statusTextColor: colors.white,
        emergencyStatus: 'FALHA DE BOMBEAMENTO',
      };
    }
    if (d.stFailLowPressure) {
      return {
        statusColor: colors.error,
        statusTextColor: colors.white,
        emergencyStatus: `Falta de pressão no grau ${d.positionActual}`,
      };
    }
    if (d.stFailHighPressure) {
      return {
        statusColor: colors.error,
        statusTextColor: colors.white,
        emergencyStatus: `Excesso de pressão no grau ${d.positionActual}`,
      };
    }
    if (d.stFailEncoderOutOfBand) {
      return {
        statusColor: colors.error,
        statusTextColor: colors.white,
        emergencyStatus: `Encoder fora de faixa no grau ${d.positionActual}`,
      };
    }
    if (d.stFailPositionEncoder) {
      return {
        statusColor: colors.error,
        statusTextColor: colors.white,
        emergencyStatus: `Encoder parado no grau ${d.positionActual}`,
      };
    }
    if (d.stFailCommunicClpIhm) {
      return {
        statusColor: colors.error,
        statusTextColor: colors.white,
        emergencyStatus: 'Falha de comunicação CLP/IHM',
      };
    }
    if (d.stFailCommunicEncoder) {
      return {
        statusColor: colors.error,
        statusTextColor: colors.white,
        emergencyStatus: 'Posicionador sem comunicação',
      };
    }
    if (d.stFailCommunicStation) {
      return {
        statusColor: colors.error,
        statusTextColor: colors.white,
        emergencyStatus: 'Estação sem comunicação',
      };
    }
    if (d.stScheduledDate) {
      return {
        statusColor: colors.warning,
        statusTextColor: colors.white,
        emergencyStatus: 'Pivô Ligado - AGUARDANDO HORÁRIO PROGRAMADO',
      };
    }
    if (d.stHP) {
      return {
        statusColor: colors.warning,
        statusTextColor: colors.white,
        emergencyStatus: 'Pivô Ligado - AGUARDANDO HORÁRIO DE PONTA',
      };
    }
    if (d.stPressureTime) {
      return {
        statusColor: colors.warning,
        statusTextColor: colors.white,
        emergencyStatus: 'Pivô Ligado - AGUARDANDO PRESSURIZAÇÃO',
      };
    }
    if (d.stStopActivated) {
      return {
        statusColor: colors.warning,
        statusTextColor: colors.white,
        emergencyStatus: 'Batente acionado',
      };
    }
    if (d.stPivotLocked) {
      return {
        statusColor: colors.error,
        statusTextColor: colors.white,
        emergencyStatus: 'Operação do pivô bloqueada',
      };
    }
    if (d.stPreventiveMaintenance) {
      return {
        statusColor: colors.warning,
        statusTextColor: colors.white,
        emergencyStatus: 'Realizar manutenção preventiva',
      };
    }
    if (d.stFailCommunicPumping) {
      return {
        statusColor: colors.error,
        statusTextColor: colors.white,
        emergencyStatus: 'Falha de comunicação com bombeamento',
      };
    }
    if (d.stPreventiveMaintenancePumping) {
      return {
        statusColor: colors.warning,
        statusTextColor: colors.white,
        emergencyStatus: 'Realizar manutenção no bombeamento',
      };
    }
    if (d.stOnOff) {
      return {
        statusColor: colors.transparent,
        statusTextColor: colors.lightGrey,
        emergencyStatus: 'Pivô Ligado',
      };
    }

    return {
      statusColor: colors.transparent,
      statusTextColor: colors.lightGrey,
      emergencyStatus: 'Pivô Desligado',
    };
  }

  React.useEffect(() => {
    (async () => {
      await getDocumentsFileToDonwload();
    })();
    callDataApi();
    callRecipeApi();
    const interval = setInterval(() => {
      callDataApiNoLoad();
    }, 60000);
    return () => clearInterval(interval);
  }, [props.match.params.id]);
  const classes = useStyles();
  const urlParams = new URLSearchParams(window.location.search);
  const whatRoute = urlParams.get('whatRoute');
  if (errorMessage) return <EquipError errorMessage={errorMessage} />;

  const {
    backPageContext,
    plantIDContext /* , setBackPageContext */,
  } = useContext(PageContext);

  if (returnToList) {
    return (
      <>
        <Redirect
          push
          to={
            backPageContext === 'mapa'
              ? `/globalMap?plantId=${plantIDContext}` // a379b344-c992-4c26-b1ca-991b9aac327a
              : backPageContext === 'equip'
              ? // `/equipamentDataAdm?accountId=${data.account?.id}&op=irrigation`
                `/equipamentData?${
                  plantIDContext !== '' &&
                  plantIDContext !== null &&
                  plantIDContext !== undefined
                    ? `plantId=${plantIDContext}`
                    : ''
                }&op=irrigation`
              : '/equipamentData'
          }
        />
      </>
    );
  }
  const Standby = () => {
    if (data.telemetry.stPressureTime === 1) {
      return 1;
    }
    if (data.telemetry.stScheduledDate === 1) {
      return 1;
    }
    if (data.telemetry.stHP === 1) {
      return 1;
    }
    if (data.telemetry.stStoppedByRain === 1) {
      return 1;
    }
    if (data.telemetry.stInitialTtime === 1) {
      return 1;
    }
    if (data.telemetry.stProgramOff === 1) {
      return 1;
    }
    if (data.telemetry.stReversal === 1) {
      return 1;
    }
    if (data.telemetry.stPivotLocked === 1) {
      return 1;
    }
    if (data.telemetry.stFailLowPressure === 1) {
      return 1;
    }
    if (data.telemetry.stFailHighPressure === 1) {
      return 1;
    }
    if (data.telemetry.stFailPumping === 1) {
      return 1;
    }
    if (data.telemetry.stFailEncoderOutOfBand === 1) {
      return 1;
    }
    if (data.telemetry.stFailPositionEncoder === 1) {
      return 1;
    }
    if (data.telemetry.stFailCommunicClpIhm === 1) {
      return 1;
    }
    if (data.telemetry.stFailCommunicEncoder === 1) {
      return 1;
    }
    if (data.telemetry.stFailCommunicStation === 1) {
      return 1;
    }
    if (data.telemetry.stStopActivated === 1) {
      return 1;
    }
    if (data.telemetry.stPreventiveMaintenance === 1) {
      return 1;
    }
    if (data.telemetry.stFailCommunicPumping === 1) {
      return 1;
    }
    if (data.telemetry.stPreventiveMaintenancePumping === 1) {
      return 1;
    }
    return 0;
  };

  return (
    <>
      <Breadcrumbs
        routes={whatRoute ? breadcrumbs.secondRoute : breadcrumbs.routes}
        loading={loading}
      />
      <DashTitle>{data.account?.description}</DashTitle>
      <DashesHeader
        openModal={setOpenModalDownloadFiles}
        title={name}
        connection={data.connection}
        running={data.running}
        alert={data.failures}
        hasCustomRoute={whatRoute === 'Equips' ? `?whatRoute=${whatRoute}` : ''}
        // noSettings={!hasPermission(PortalPermissions.IRRIGATION_SETTINGS_VIEW.value)}
        type={[
          'Planta',
          ...(currentRecipe.maxSectorAngle !== 360 ? ['Setorial'] : []),
        ]}
        loading={loading}
      />
      <Grid container spacing={1} alignContent="stretch" alignItems="stretch">
        {/* <Grid item xs={12} style={{ textAlign: 'right' }}>
          <IrrigationCreateOp2100 equipamentId={props.match.params.id} />
        </Grid> */}

        <>
          <Grid item xs={12} md={8} lg={6} xl={6}>
            <Card similar>
              <CardTitle
                textColor={status.statusTextColor}
                color={status.statusColor}
              >
                {status.emergencyStatus}
                <Spacer />
              </CardTitle>
              <CardText>
                {loading ? (
                  <Skeleton
                    variant="rect"
                    height={500}
                    width="100%"
                    animation="wave"
                  />
                ) : (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={7} md={6} lg={7} xl={6}>
                        {
                          <MapContainer
                            style={{ height: 435 }}
                            center={centerPosition}
                            maxZoom={19}
                            zoomSnap={0.1}
                            zoomDelta={0.1}
                            wheelPxPerZoomLevel={120}
                            scrollWheelZoom={false}
                            doubleClickZoom={false}
                            dragging={false}
                            bounds={findBoundsSVG(
                              data.customLocation?.lat ||
                                data.settings.location.lat,
                              data.customLocation?.lon ||
                                data.settings.location.lon,
                              data.op1000.wheelLength + 35,
                            )}
                            className="map_container"
                          >
                            <LayersControl position="bottomright">
                              <LayersControl.BaseLayer
                                checked={!mapStyle}
                                name="Imagem Satelite"
                              >
                                <TileLayer
                                  attribution="World Map"
                                  // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                  url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                />
                              </LayersControl.BaseLayer>
                              <LayersControl.BaseLayer name="Colorido">
                                <TileLayer
                                  attribution="World Map"
                                  url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                              </LayersControl.BaseLayer>
                              <LayersControl.BaseLayer name="Alto Contraste">
                                <TileLayer
                                  attribution="World Map"
                                  url="http://a.tile.stamen.com/toner/{z}/{x}/{y}.png"
                                />
                              </LayersControl.BaseLayer>
                              <LayersControl.BaseLayer name="Escala de cinza">
                                <TileLayer
                                  attribution="World Map"
                                  url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                                />
                              </LayersControl.BaseLayer>
                              <LayersControl.BaseLayer name="Escuro">
                                <TileLayer
                                  attribution="World Map"
                                  url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
                                />
                              </LayersControl.BaseLayer>
                              <LayersControl.BaseLayer
                                checked={mapStyle}
                                name="Claro"
                              >
                                <TileLayer
                                  attribution="World Map"
                                  url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
                                />
                              </LayersControl.BaseLayer>
                            </LayersControl>

                            <SVGOverlay
                              key={data.postalCode}
                              interactive
                              bounds={findBoundsSVG(
                                data.customLocation?.lat ||
                                  data.settings.location.lat,
                                data.customLocation?.lon ||
                                  data.settings.location.lon,
                                data.op1000?.wheelLength + 35,
                              )}
                            >
                              <PivoSVG
                                name={name}
                                tooltipData={data}
                                showCovered
                                startAngle={0}
                                isFailing={data.failures.list}
                                endAngle={currentRecipe.maxSectorAngle}
                                zeroAngle={data.settings?.northFaceAngle || 0}
                                isControlMode
                                minimumMM={
                                  data.settings
                                    ? data.settings.minimumMM || 0
                                    : 0
                                }
                                maximumSpeed={
                                  data.settings
                                    ? data.settings.maximumSpeed || 0
                                    : 0
                                }
                                wheelLength={
                                  data.settings
                                    ? data.settings.wheelLength || 0
                                    : 0
                                }
                                latitude={
                                  data.settings
                                    ? data.settings.latitude || 0
                                    : 0
                                }
                                longitude={
                                  data.settings
                                    ? data.settings.longitude || 0
                                    : 0
                                }
                                cannonAngles={currentRecipe.cannons}
                                destinationAngle={data.telemetry.recipePosition}
                                currentAngle={data.telemetry.positionActual}
                                recipeStartAngle={
                                  selectedRecipe
                                    ? selectedRecipe.startingPosition
                                    : undefined
                                }
                                recipeEndAngle={data.telemetry.recipePosition}
                                recipe={currentRecipe}
                                telemetry={data.telemetry}
                                isRotatingLeft={
                                  data.telemetry.recipeDirectionRotation === 2
                                }
                                isRotatingRight={
                                  data.telemetry.recipeDirectionRotation === 1
                                }
                                isStoped={
                                  data.telemetry.stDirectionRotationRight ===
                                    0 &&
                                  data.telemetry.stDirectionRotationLeft === 0
                                }
                                isStandbyTime={Standby()}
                                waterBladeSize={currentRecipe.recipeWaterMm}
                                hasWater={data.telemetry.recipeWater}
                                isPowerOn={data.telemetry.stOnOff}
                                isDisconnected={data.connection.offline}
                                onClickRight={(st) => {
                                  setNewRecipe({
                                    ...data.telemetry,
                                    maxSectorAngle:
                                      currentRecipe.maxSectorAngle,
                                    finalPosition: data.telemetry.finalPosition,
                                    northFaceAngle: data.settings
                                      ? data.settings.northFaceAngle || 0
                                      : 0,
                                  });
                                  if (
                                    currentRecipe.maxSectorAngle === 360 ||
                                    currentRecipe.maxSectorAngle === undefined
                                  ) {
                                    setIntentRecipe({
                                      direction: 1,
                                      stDirectionRotationRight:
                                        st === 1 ? 0 : 1,
                                      stDirectionRotationLeft:
                                        st === 0 &&
                                        data.telemetry
                                          .stDirectionRotationLeft === 1
                                          ? 0
                                          : data.telemetry
                                              .stDirectionRotationLeft,
                                    });
                                  }
                                  setAddRecipeDialog(true);
                                  data.connection.offline === true
                                    ? toast.error('Pivô está desconectado')
                                    : null;
                                }}
                                onClickLeft={(st) => {
                                  setNewRecipe({
                                    ...data.telemetry,
                                    maxSectorAngle:
                                      currentRecipe.maxSectorAngle,
                                    finalPosition: data.telemetry.finalPosition,
                                    northFaceAngle: data.settings
                                      ? data.settings.northFaceAngle || 0
                                      : 0,
                                  });
                                  if (
                                    currentRecipe.maxSectorAngle === 360 ||
                                    currentRecipe.maxSectorAngle === undefined
                                  ) {
                                    setIntentRecipe({
                                      direction: 2,
                                      stDirectionRotationLeft: st === 1 ? 0 : 1,
                                      stDirectionRotationRight:
                                        st === 0 &&
                                        data.telemetry
                                          .stDirectionRotationRight === 1
                                          ? 0
                                          : data.telemetry
                                              .stDirectionRotationRight,
                                    });
                                  }
                                  setAddRecipeDialog(true);
                                  data.connection.offline === true
                                    ? toast.error('Pivô está desconectado')
                                    : null;
                                }}
                                onClickPower={(st) => {
                                  setNewRecipe({
                                    ...data.telemetry,
                                    maxSectorAngle:
                                      currentRecipe.maxSectorAngle,
                                    finalPosition: data.telemetry.finalPosition,
                                    direction:
                                      currentRecipe.recipeDirectionRotation,
                                    northFaceAngle: data.settings
                                      ? data.settings.northFaceAngle || 0
                                      : 0,
                                  });
                                  setIntentRecipe({
                                    stOnOff: st === 1 ? 0 : 1,
                                  });
                                  setAddRecipeDialog(true);
                                  data.connection.offline === true
                                    ? toast.error('Pivô está desconectado')
                                    : null;
                                }}
                                isEmergencyWarning={
                                  currentRecipe.stFailEmergency
                                }
                                stOnOff={currentRecipe.stOnOff}
                              />
                            </SVGOverlay>
                          </MapContainer>
                        }
                      </Grid>
                      <Grid item xs={12} sm={5} md={6} lg={5} xl={6}>
                        <TextField
                          margin="normal"
                          fullWidth
                          label="Horário de início:"
                          readOnly
                          variant="outlined"
                          value={formatDate(
                            selectedRecipe?.dtStart,
                            selectedRecipe?.hrStart,
                          )}
                        />
                        <TextField
                          margin="normal"
                          fullWidth
                          label="Horário estimado de término:"
                          readOnly
                          variant="outlined"
                          value={formatDate(
                            selectedRecipe?.dtFinish,
                            selectedRecipe?.hrFinish,
                          )}
                        />
                        <TextField
                          margin="normal"
                          fullWidth
                          readOnly
                          value={`${currentRecipe.recipeWaterMm} mm`}
                          label="Lâmina d'agua:"
                          variant="outlined"
                        />
                        <Button
                          fullWidth
                          size="large"
                          style={{
                            margin: '0px 0px 8px',
                            borderRadius: '0px',
                          }}
                          color="primary"
                          startIcon={<AddIcon />}
                          variant="contained"
                          onClick={() => {
                            setNewRecipe({
                              ...data.telemetry,
                              maxSectorAngle: currentRecipe.maxSectorAngle,
                              finalPosition: data.telemetry.recipePosition,
                              direction: data.telemetry.recipeDirectionRotation,
                              northFaceAngle: data.settings
                                ? data.settings.northFaceAngle || 0
                                : 0,
                            });
                            setIntentRecipe({});
                            setAddRecipeDialog(true);
                            data.connection.offline === true
                              ? toast.error('Pivô está desconectado')
                              : null;
                          }}
                        >
                          COMANDAR PIVÔ
                        </Button>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Card
                              similar
                              hover
                              changeBgColor
                              color={colors.pivotCommands}
                              outlined
                              onClick={() => {
                                setNewRecipe({
                                  ...data.telemetry,
                                  maxSectorAngle: currentRecipe.maxSectorAngle,
                                  finalPosition: data.telemetry.recipePosition,
                                  direction:
                                    currentRecipe.recipeDirectionRotation,
                                  northFaceAngle: data.settings
                                    ? data.settings.northFaceAngle || 0
                                    : 0,
                                });
                                setIntentRecipe({
                                  recipeWater:
                                    data.telemetry.recipeWater === 1 ? 0 : 1,
                                });
                                setAddRecipeDialog(true);
                                data.connection.offline === true
                                  ? toast.error('Pivô está desconectado')
                                  : null;
                              }}
                            >
                              <CardText pa="4px">
                                <div
                                  style={{
                                    margin: '0 auto',
                                    maxWidth: iconWidth(),
                                  }}
                                >
                                  <WaterSVG status={currentRecipe} />
                                </div>
                              </CardText>
                            </Card>
                          </Grid>
                          <Grid item xs={6}>
                            <Card
                              similar
                              hover
                              changeBgColor
                              color={colors.pivotCommands}
                              outlined
                              onClick={() => {
                                setNewRecipe({
                                  ...data.telemetry,
                                  maxSectorAngle: currentRecipe.maxSectorAngle,
                                  finalPosition: data.telemetry.finalPosition,
                                  direction:
                                    currentRecipe.recipeDirectionRotation,
                                  northFaceAngle: data.settings
                                    ? data.settings.northFaceAngle || 0
                                    : 0,
                                });
                                setIntentRecipe({
                                  recipeHP:
                                    data.telemetry.recipeHP === 1 ? 0 : 1,
                                });
                                setAddRecipeDialog(true);
                                data.connection.offline === true
                                  ? toast.error('Pivô está desconectado')
                                  : null;
                              }}
                            >
                              <CardText pa="4px">
                                <div
                                  style={{
                                    margin: '0 auto',
                                    maxWidth: iconWidth(),
                                  }}
                                >
                                  <ScheduleSVG status={currentRecipe} />
                                </div>
                              </CardText>
                            </Card>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </CardText>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} lg={3} xl={3}>
            <Card similar>
              <CardTitle>Status dos sensores:</CardTitle>
              <CardText>
                <Grid container justifyContent="space-around" spacing={1}>
                  {loading || !currentRecipe ? (
                    <Skeleton
                      variant="rect"
                      height={500}
                      width="100%"
                      animation="wave"
                    />
                  ) : (
                    <>
                      <Grid item xl={6} lg={6} md={6} sm={2} xs={6}>
                        <Card similar outlined>
                          <CardText pa="8px">
                            <div
                              style={{
                                margin: '0 auto',
                                maxWidth: iconWidth(),
                              }}
                            >
                              <PressureGaugeSVG status={currentRecipe} />
                            </div>
                          </CardText>
                        </Card>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={2} xs={6}>
                        <Card similar outlined>
                          <CardText pa="8px">
                            <div
                              style={{
                                margin: '0 auto',
                                maxWidth: iconWidth(),
                              }}
                            >
                              <PumpingSVG status={currentRecipe} />
                            </div>
                          </CardText>
                        </Card>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={2} xs={6}>
                        <Card
                          similar
                          hover
                          outlined
                          changeBgColor
                          color={colors.pivotCommands}
                          onClick={() => {
                            setNewRecipe({
                              ...data.telemetry,
                              maxSectorAngle: currentRecipe.maxSectorAngle,
                              finalPosition: data.telemetry.finalPosition,
                              direction: currentRecipe.recipeDirectionRotation,
                              northFaceAngle: data.settings
                                ? data.settings.northFaceAngle || 0
                                : 0,
                            });
                            setIntentRecipe({
                              recipeCannon:
                                data.telemetry.stCannon === 1 ? 0 : 1,
                            });
                            setAddRecipeDialog(true);
                            data.connection.offline === true
                              ? toast.error('Pivô está desconectado')
                              : null;
                          }}
                        >
                          <CardText>
                            <div
                              style={{
                                margin: '0 auto',
                                maxWidth: iconWidth(),
                              }}
                            >
                              <CannonSVG status={currentRecipe} />
                            </div>
                          </CardText>
                        </Card>
                      </Grid>
                      <Grid item xl={6} lg={6} md={6} sm={2} xs={6}>
                        <Card similar outlined>
                          <CardText>
                            <div
                              style={{
                                margin: '0 auto',
                                maxWidth: iconWidth(),
                              }}
                            >
                              <EnergySVG status={currentRecipe} />
                            </div>
                          </CardText>
                        </Card>
                      </Grid>
                    </>
                  )}
                </Grid>
              </CardText>
            </Card>
          </Grid>
          <Grid item xs={12} lg={3} xl={3}>
            <Card similar>
              <CardTitle>Outras Ações:</CardTitle>
              <CardText>
                <Grid
                  container
                  spacing={2}
                  alignContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12} sm={12} md={6} lg={12}>
                    <Button
                      fullWidth
                      size="large"
                      style={{
                        margin: '0px 0px 8px',
                        borderRadius: '0px',
                      }}
                      color="primary"
                      startIcon={<ViewCarouselIcon />}
                      variant="contained"
                      onClick={() => {
                        setListRecipeDialog(true);
                      }}
                    >
                      LISTAR PROGRAMAÇÕES
                    </Button>
                    <Button
                      fullWidth
                      size="large"
                      style={{
                        margin: '0px 0px 8px',
                        borderRadius: '0px',
                      }}
                      color="primary"
                      startIcon={<HistoryIcon />}
                      variant="contained"
                      onClick={() => {
                        setHistoryDialog(true);
                      }}
                    >
                      HISTÓRICO DE COMANDOS
                    </Button>
                    <Button
                      fullWidth
                      size="large"
                      style={{ margin: '0px', borderRadius: '0px' }}
                      color="primary"
                      startIcon={<MultilineChartSharpIcon />}
                      variant="contained"
                      onClick={() => {
                        setChartDialog(true);
                      }}
                    >
                      GRÁFICO DE IRRIGAÇÃO
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={12}>
                    <WeatherCard
                      weatherData={data.weatherStation}
                      EquipamentState={data.connection}
                    />
                  </Grid>
                </Grid>
              </CardText>
            </Card>
          </Grid>

          <Dialog
            fullWidth
            fullScreen={xs || sm}
            maxWidth="md"
            open={chartDialog}
            onClose={(e) => {
              e.stopPropagation();
              setChartDialog(false);
            }}
            aria-labelledby="addDialog"
          >
            <CardTitle
              color={sm || xs ? '#3d5059' : ''}
              textColor={sm || xs ? '#FFF' : ''}
            >
              Gráfico de Irrigação
              <Spacer />
              <CloseIcon
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setChartDialog(false);
                }}
              />
            </CardTitle>
            <DialogContent>
              <DialogContentText>
                <IrrigationGraphic equipamentId={props.match.params.id} />
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Dialog
            fullWidth
            fullScreen={xs || sm}
            maxWidth="md"
            open={historyDialog}
            onClose={(e) => {
              e.stopPropagation();
              setHistoryDialog(false);
            }}
            aria-labelledby="addDialog"
          >
            <CardTitle
              color={sm || xs ? '#3d5059' : ''}
              textColor={sm || xs ? '#FFF' : ''}
            >
              Histórico de Comandos Enviados
              <Spacer />
              <CloseIcon
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setHistoryDialog(false);
                }}
              />
            </CardTitle>
            <DialogContent>
              <DialogContentText>
                <IrrigationTabHistorical equipamentId={props.match.params.id} />
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Dialog
            fullWidth
            fullScreen={xs || sm}
            maxWidth="md"
            open={addRecipeDialog && !data.connection.offline}
            onClose={(e) => {
              e.stopPropagation();
              setAddRecipeDialog(false);
            }}
            aria-labelledby="addDialog"
          >
            <CardTitle
              color={sm || xs ? '#3d5059' : ''}
              textColor={sm || xs ? '#FFF' : ''}
            >
              Comandar Pivô
              <Spacer />
              <CloseIcon
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setAddRecipeDialog(false);
                }}
              />
            </CardTitle>
            <DialogContent>
              <DialogContentText>
                <IrrigationCreateOp2100
                  equipamentId={props.match.params.id}
                  newRecipe={newRecipe}
                  intentRecipe={intentRecipe}
                  closeDialog={() => {
                    setAddRecipeDialog(false);
                  }}
                />
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Dialog
            fullWidth
            fullScreen={xs || sm}
            maxWidth="lg"
            open={listRecipeDialog}
            onClose={(e) => {
              e.stopPropagation();
              setListRecipeDialog(false);
            }}
            aria-labelledby="addDialog"
          >
            <CardTitle
              color={sm || xs ? '#3d5059' : ''}
              textColor={sm || xs ? '#FFF' : ''}
            >
              Lista de Programações
              <Spacer />
              <CloseIcon
                style={{ cursor: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  setListRecipeDialog(false);
                }}
              />
            </CardTitle>
            <DialogContent>
              <DialogContentText>
                {zeroRecipes ? (
                  <h3
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      fontWeight: 400,
                    }}
                  >
                    Nenhuma programação encontrada
                  </h3>
                ) : (
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.title}
                    >
                      <IrrigationCarousel
                        currentRecipeId={currentRecipe}
                        selectedRecipeId={
                          selectedRecipe ? selectedRecipe.recipeId : undefined
                        }
                        selectedRecipeDate={
                          selectedRecipe ? selectedRecipe.dtStart : undefined
                        }
                        setZeroRecipes={setZeroRecipes}
                        setSelectedRecipe={setSelectedRecipe}
                        recipes={recipes}
                        equipamentId={props.match.params.id}
                        northFaceAngle={
                          data.settings ? data.settings.northFaceAngle || 0 : 0
                        }
                        maxSectorAngle={
                          !currentRecipe.maxSectorAngle ||
                          currentRecipe.maxSectorAngle === 360
                            ? undefined
                            : currentRecipe.maxSectorAngle
                        }
                      />
                    </Grid>
                  </Grid>
                )}
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
        {availableFilesToDownload && (
          <Modal
            title="Arquivos Disponíveis para Download"
            open={openModalDownloadFiles}
            handleCloseModal={handleCloseModelDownloadFiles}
          >
            <FilesToDownload files={availableFilesToDownload} />
          </Modal>
        )}
      </Grid>

      <Grid container spacing={2} style={{ padding: '24px 0px' }}>
        <Grid item xs={12} className={classes.btnBttn}>
          <BackButton
            back={() => setReturnToList(true)}
            className={classes.btnSpace}
          />
        </Grid>
      </Grid>
    </>
  );
};
