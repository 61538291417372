// import React from 'react';

function motorTopLeft(angleCable, topCable, leftCable) {
  let top;
  let left;

  if (angleCable <= 60) {
    top = topCable - 30;
    left = leftCable + 50;
    if (left < 190) {
      left = 214;
    }
  } else
  if (angleCable <= 80) {
    top = topCable - 40;
    left = leftCable + 50;
  } else
  if (angleCable <= 100) {
    top = topCable - 50;
    left = leftCable + 0;
  } else
  if (angleCable <= 120) {
    top = topCable - 40;
    left = leftCable - 50;
  } else
  if (angleCable <= 180) {
    top = topCable - 30;
    left = leftCable - 50;
  } else
  if (angleCable <= 200) {
    top = topCable + 30;
    left = leftCable - 50;
  } else
  if (angleCable <= 220) {
    top = topCable + 40;
    left = leftCable - 40;
  } else
  if (angleCable <= 240) {
    top = topCable + 50;
    left = leftCable - 30;
  } else
  if (angleCable <= 270) {
    top = topCable + 50;
    left = leftCable - 0;
  } else
  if (angleCable <= 290) {
    top = topCable + 40;
    left = leftCable + 30;
  } else
  if (angleCable <= 310) {
    top = topCable + 40;
    left = leftCable + 40;
  } else
  if (angleCable <= 359) {
    top = topCable + 30;
    left = leftCable + 50;
  }
  return { top, left };
}

export default function autoDispositionMotors(engines, cablesDisposition) {
  const disposition = [];
  if ((!engines) || (!cablesDisposition)) {
    return '';
  }

  engines.map((engine) =>
    cablesDisposition.map((cable) => {
      if (cable.cable === engine.cableRef) {
        disposition.push({
          cable: engine.cableRef,
          angleCable: cable.angle,
          engineId: engine.idMotor,
          engineName: engine.nameMotor,
          engineOnOff: engine.onOff,
          engineTop: motorTopLeft(cable.angle, cable.top, cable.left).top,
          engineLeft: motorTopLeft(cable.angle, cable.top, cable.left).left,
          engineAeration: engine.aeration,
        });
      }
      return '';
    }));

  return disposition;
}
