import React from 'react';
import { useParams } from 'react-router-dom';
import { FiEdit, BsPlus, GrDocumentUpdate } from 'react-icons/all';
import FockinkAPI from '../../../../../../../middlewares/Api';

import {
  AssociateCultureForm,
  CultureDataTable,
  HarvestForm,
  AssociatedCultureUpdateForm,
} from './components';
import { ButtonComponent as Button } from '../../../../../../../components/Button';
import { Modal } from '../../../../../../../components/Modal';

import * as S from './styles';

export function EquipamentCulture() {
  const { id } = useParams();

  const [
    openNewAssociateCultureForm,
    setOpenNewAssociateCultureForm,
  ] = React.useState(false);
  const [openUpdateModalForm, setOpenUpdateModalForm] = React.useState(false);
  const [openHarvestModal, setOpenHarvestModal] = React.useState(false);
  const [cultures, setCultures] = React.useState([]);
  const [selectedCulture, setSelecetedCulture] = React.useState();

  const handleGetCultures = async () => {
    const response = await FockinkAPI.get('cultivation', {
      params: {
        equipamentId: id,
        notHarvested: true,
      },
    });

    setCultures(response.data.data);
  };

  React.useEffect(() => {
    handleGetCultures();
  }, []);

  const handleCloseAssociateCultureFormModal = () => {
    setOpenNewAssociateCultureForm(false);
  };

  const handleCloseHarvestCultureFormModal = () => {
    setOpenHarvestModal(false);
  };

  const handleCloseHarvestFormModal = () => {
    setOpenUpdateModalForm(false);
  };

  const handleSelectCulture = (culture) => {
    setOpenUpdateModalForm(true);
    setSelecetedCulture(culture);
  };

  const handleHarvestModalSelect = (culture) => {
    setOpenHarvestModal(true);
    setSelecetedCulture(culture);
  };

  const aditionalCollumns = [
    {
      name: 'Editar',
      selector: 'edit',
      center: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <button type="button" onClick={() => handleSelectCulture(row)}>
            <FiEdit size={16} />
          </button>
        </div>
      ),
    },
    {
      name: 'Colheita',
      selector: 'colheita',
      center: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <button type="button" onClick={() => handleHarvestModalSelect(row)}>
            <GrDocumentUpdate size={16} />
          </button>
        </div>
      ),
    },
  ];

  return (
    <S.Wrapper>
      <div className="button-new">
        <Button
          color="secondary"
          icon={<BsPlus />}
          handle={() => setOpenNewAssociateCultureForm(true)}
        >
          Associar nova cultura
        </Button>
      </div>

      <Modal
        title="Associar nova cultura"
        open={openNewAssociateCultureForm}
        handleCloseModal={handleCloseAssociateCultureFormModal}
      >
        <AssociateCultureForm
          handleCloseAssociateCultureFormModal={
            handleCloseAssociateCultureFormModal
          }
          handleGetCultures={handleGetCultures}
        />
      </Modal>

      {selectedCulture && (
        <>
          <Modal
            title={`Colheita: ${selectedCulture.cultivationAccount?.culture}`}
            open={openHarvestModal}
            handleCloseModal={handleCloseHarvestCultureFormModal}
          >
            <HarvestForm
              culture={selectedCulture}
              handleCloseHarvestFormModal={handleCloseHarvestCultureFormModal}
              handleGetCultures={handleGetCultures}
            />
          </Modal>

          <Modal
            title={`Atualizar cultura associada: ${selectedCulture.cultivationAccount?.culture}`}
            open={openUpdateModalForm}
            handleCloseModal={handleCloseHarvestFormModal}
          >
            <AssociatedCultureUpdateForm
              culture={selectedCulture}
              handleCloseHarvestFormModal={handleCloseHarvestFormModal}
              handleGetCultures={handleGetCultures}
            />
          </Modal>
        </>
      )}

      <CultureDataTable data={cultures} aditionalCollumns={aditionalCollumns} />
    </S.Wrapper>
  );
}
