import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import CircularProgress from '@material-ui/core/CircularProgress';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { hasPermission } from '../../helpers/permission';
import FockinkAPI from '../../middlewares/Api';

export default (props) => {
  const [loading, setLoading] = React.useState(false);
  const MySwal = withReactContent(Swal);

  const callApi = () => {
    const { postData } = props;
    setLoading(true);
    FockinkAPI[postData.method](postData.url, postData.data).then((response) => {
      props.setData(response.data.data);
      setLoading(false);
    }).catch((e) => {
      setLoading(false);
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: e.response.data.reason || e.response.data.message,
      });
    });
  };
  const disabled = loading || props.isDisabled;

  const text = () => {
    if (loading) return 'Carregando';
    if (disabled) return props.disabledText || 'Desativado';
    return props.defaultText || 'Botão';
  };

  const icon = () => {
    if (loading) return <CircularProgress size={26} />;
    return props.icon || <DoneIcon />;
  };

  const defaultButton = (
    <Button
      disabled={disabled}
      size="large"
      variant="contained"
      color="secondary"
      className={props.className}
      style={{ /* background: disabled ? '#CECECE' : '#28AE60', color: 'white', */ ...props.style }}
      onClick={() => callApi()}
      startIcon={icon()}
    >
      {text()}
    </Button>
  );

  const notAllowButton = (
    <Button
      disabled
      size="large"
      variant="contained"
      color="secondary"
      className={props.className}
      style={{ background: '#CECECE', color: 'white', ...props.style }}
      startIcon={<NotInterestedIcon />}
    >
      Você não tem permissão
    </Button>
  );

  if (props.canSave) {
    if (hasPermission(props.canSave)) {
      return defaultButton;
    }
    return notAllowButton;
  }
  return defaultButton;
};
