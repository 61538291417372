import React, { useState } from 'react';
import MomentUtils from '@date-io/moment';
import { TextField, Grid, InputAdornment } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import parseFloatCustom from '../../../../helpers/parseFloatCustom';
import BottomButtons from '../../../../components/Buttons/BottomButtons';
import FockinkAPI from '../../../../middlewares/Api';
import Breadcrumbs from '../../../../components/Breadcrumbs';

const StorageCostForm = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    date: new Date(),
  });

  const breadcrumbs = {
    routes: [
      { name: 'Gestão de Custos', to: '/listCost' },
      { name: 'Configurações', to: '/ListCost/ConfigCost/' },
      { name: 'Cadastro de custo de armazenamento', to: '/ListCost/ConfigCost/costManagement/' },
      { name: 'Cadastro de valores' },
    ],
    title: 'Cadastro de valores',
  };
  const useStyles = makeStyles({
    textAlign: {
      textAlign: 'right',
    },
    btnSpace: {
      marginLeft: '15px',
    },
    bottomButtons: {
      marginTop: '15px',
      borderTop: '1px solid #ccc',
      paddingTop: '10px !important',
    },
    root: {
      width: '100%',
      marginTop: 30,
    },
  });
  const classes = useStyles();
  const back = {
    name: 'Cadastro de custo de armazenamento',
    to: '/ListCost/ConfigCost/costManagement',
  };
  const history = useHistory();
  React.useEffect(() => {
    FockinkAPI.get(
      `/costApportionment/${props.match.params.id}`,
    ).then((response) => {
      if (props.match.params.id === 'new') {
        setLoading(false);
      } else {
        setData(response.data.data);
        setLoading(false);
      }
    }).catch(async () => {
      if (props.match.params.id === 'new') {
        setLoading(false);
      }
    });
  }, [props.match.params.id]);

  const saveData = () => {
    if (props.match.params.id === 'new') {
      const dt = {
        date: moment(data.date).format('yyyy-MM'),
        totalRuCost: parseFloatCustom(data.totalRuCost),
        blobGbCost: parseFloatCustom(data.blobGbCost),
      };
      FockinkAPI.post('/costApportionment', dt)
        .then(() => {
          toast.success('Dados salvos com sucesso');
          history.push('/ListCost/ConfigCost/costManagement');
        })
        .catch(async (error) => {
          const { response } = error;

          if (response.status === 400) {
            toast.error(response.data.validation?.body.message || response.data.message);
          }
        });
    } else {
      const dt = {
        date: moment(data.date).format('yyyy-MM'),
        totalRuCost: parseFloatCustom(data.totalRuCost),
        blobGbCost: parseFloatCustom(data.blobGbCost),
      };
      FockinkAPI.put(`/costApportionment/${props.match.params.id}`, dt).then(() => {
        toast.success('Dados salvos com sucesso');
        history.push('/ListCost/ConfigCost/costManagement');
      })
        .catch(async (error) => {
          const { response } = error;

          if (response.status === 400) {
            toast.error(response.data.validation.body.message);
          } else {
            toast.error(response.data.message);
          }
        });
    }
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Breadcrumbs
        routes={breadcrumbs.routes}
        title={breadcrumbs.title}
      />
      {
    loading ? (
      <div className={classes.root}>
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
      </div>
    )
      :

      (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <TextField
              variant="filled"
              name="blobGbCost"
              value={data?.blobGbCost || ''}
              onChange={handleChange}
              label="Valor por GB do Blob"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    R$
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              variant="filled"
              name="totalRuCost"
              value={data?.totalRuCost || ''}
              onChange={handleChange}
              label="Valor total de RU"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    R$
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {
              /* props.match.params.id !== 'new' && (
                <Grid item lg={3} xs={12}>
                  <FormControl variant="filled">
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                      labelId="status"
                      id="status"
                      name="status"
                      value={data?.status || ''}
                      label="status"
                      onChange={handleChange}
                    >
                      <MenuItem value="A">Ativo</MenuItem>
                      <MenuItem value="I">Inativo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ) */
            }
          <Grid item xs={12} lg={3}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              style={{ width: '100%' }}
            >
              <KeyboardDatePicker
                variant="filled"
                views={['year', 'month']}
                margin="normal"
                label="Mês de referência"
                format="MM/YYYY"
                value={data?.date || null}
                name="date"
                style={{ margin: 0, marginTop: 8 }}
                autoOk
                onChange={(date) => {
                  setData({
                    ...data,
                    date: date?.format('YYYY-MM-DD'),
                  });
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            item
            xs={12}
            className={`${classes.textAlign} ${classes.bottomButtons}`}
          >
            <BottomButtons saveData={saveData} backTo={back.to} />
          </Grid>
        </Grid>
      )
      }
    </>
  );
};

export default StorageCostForm;
