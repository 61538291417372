import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../App.css';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Skeleton from '@material-ui/lab/Skeleton';
import { Tabs, Tab, Typography, Box, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core/';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import {
  GrConfigure,
} from 'react-icons/gr';
import {
  RiLockPasswordLine,
  RiAccountCircleFill,
  RiMailFill,
  RiTimeFill,
  RiSeparator,
  // RiMoneyDollarCircleFill,
  // RiTranslate2,
} from 'react-icons/ri';
import { SiOpslevel } from 'react-icons/si';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FockinkAPI from '../../../middlewares/Api';
import SaveButton from '../../../components/Buttons/SaveButton';
import BackButton from '../../../components/Buttons/BackButton';
import Breadcrumbs from '../../../components/Breadcrumbs';
import NewPasswordChecker from '../../../components/NewPasswordChecker';
import { useAuth } from '../../../hooks/account';
import ExternalApi from '../../../middlewares/ExternalApi';
import Card from '../../../components/Card';
import CardText from '../../../components/CardText';
import CardTitle from '../../../components/CardTitle';
import Spacer from '../../../components/Spacer';

// import { da } from 'date-fns/locale';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  bottomButtons: {
    // marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const csvSeparator = [
  {
    id: '0',
    separator: ',',
    description: 'Vírgula ( , ) ',
  },
  {
    id: '1',
    separator: ';',
    description: 'Ponto e Vírgula ( ; )',
  },
];

const csvQualificator = [
  {
    id: '0',
    separator: "'",
    description: 'Aspas Simples ' + '(' + " ' " + ')',
  },
  {
    id: '1',
    separator: '"',
    description: 'Aspas Duplas ' + '( " )',
  },
];

const tabTitle = [
  {
    id: '0',
    icon: <GrConfigure size={15} />,
    title: 'PREFERÊNCIAS',
  },
  {
    id: '1',
    icon: <RiLockPasswordLine size={15} />,
    title: 'ALTERAR SENHA',
  },
];

export default () => {
  const [returnToMain, setReturnToMain] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [changePw, setChangePw] = React.useState(false);
  const [pwData, setPwData] = React.useState({ ready: false });
  const [isComplete, setIsComplete] = React.useState({ ready: false });
  const [isSaving, setIsSaving] = React.useState(false);
  const MySwal = withReactContent(Swal);

  const [timeZones, setTimeZones] = useState(['America/Sao_Paulo']);
  const [utc, setUtc] = useState(['-03:00']);
  const [dateTime, setDateTime] = useState(['2022-03-17T15:32:03.786270-03:00']);

  const { user } = useAuth();

  const [data, setData] = React.useState({
    name: '',
    tz: 'America/Sao_Paulo',
    csv: ';',
    csvString: '"',
  });

  const [value, setValue] = useState(0);

  const handleChangeTabControl = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      'aria-controls': `nav-tabpanel-${index}`,
    };
  }

  function extraiHora(str) {
    let aux = '';

    if ((str !== undefined) && (str !== '')) {
      // eslint-disable-next-line no-plusplus
      for (let index = 11; index < 19; index++) {
        aux += str[index];
      }
    } else {
      aux = '00:00:00';
    }

    return aux;
  }

  function extraiData(str) {
    let aux = '';
    let d = '';
    let m = '';
    let y = '';

    if ((str !== undefined) && (str !== '')) {
      // 2022-03-18
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < 4; index++) {
        y += str[index];
      }
      // eslint-disable-next-line no-plusplus
      for (let index = 5; index < 7; index++) {
        m += str[index];
      }
      // eslint-disable-next-line no-plusplus
      for (let index = 8; index < 10; index++) {
        d += str[index];
      }
      // eslint-disable-next-line prefer-template
      aux = (d + '/' + m + '/' + y);
    } else {
      aux = '00/00/0000';
    }

    return aux;
  }

  React.useEffect(() => {
    setIsComplete(checkComplete());
  }, [data, pwData, changePw]);

  const checkComplete = () => {
    const internal = !!data.name;

    if (changePw) {
      return internal && pwData.ready;
    }

    return internal;
  };

  const breadcrumb = {
    title: 'Meu Perfil',
    routes: [
      { name: 'Perfil' }],
  };

  React.useEffect(() => {
    setLoading(true);
    setChangePw(localStorage.forceResetPassword === 'true');
    FockinkAPI.get('/login/myData').then((response) => {
      response.data.data.preferences ?
        (
          setData({
            ...response.data.data,
            tz: response.data.data.preferences.tz || 'America/Sao_Paulo',
            csv: response.data.data.preferences.csv || ';',
            csvString: response.data.data.preferences.csvString || '"',
          })
        )
        :
        (
          setData({
            ...response.data.data,
            preferences: {
              tz: 'America/Sao_Paulo',
              csv: ';',
              csvString: '"',
            },
          })
        );
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    ExternalApi.get('https://worldtimeapi.org/api/timezone')
      .then((response) => {
        setTimeZones(response.data);
      })
      .catch(() => {
        ExternalApi.get('https://www.timeapi.io/api/TimeZone/AvailableTimeZones')
          .then((response) => {
            setTimeZones(response.data);
          })
          .catch(() => {
            setTimeZones('America/Sao_Paulo');
          });
      });
  }, []);

  React.useEffect(() => {
    data.tz && data.tz !== undefined && data.tz !== '' && (
      ExternalApi.get(`https://worldtimeapi.org/api/timezone/${data.tz}`)
        .then((response) => {
          setUtc(response.data.utc_offset);
          setDateTime(response.data.datetime);
        })
        .catch(() => {
          ExternalApi.get(`https://www.timeapi.io/api/Time/current/zone?timeZone=${data.tz}`)
            .then((response) => {
              setUtc('');
              setDateTime(response.data.datetime);
            })
            .catch(() => {
              setUtc('');
              setDateTime('0000-00-00T00:00:00.000000-00:00');
            });
        })
    );
  }, [data.tz]);

  const classes = useStyles();

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const save = () => {
    setIsSaving(true);
    const sendData = {
      name: data.name,
      tz: data.tz || data.preferences.tz,
      csv: data.csv || data.preferences.csv,
      csvString: data.csvString || data.preferences.csvString,
    };
    if (changePw) {
      sendData.password = pwData.currentPw;
      sendData.newPassword = pwData.pw;
      sendData.confNewPassword = pwData.checkPw;
    }

    FockinkAPI.put('/login/myData', sendData).then(() => {
      setReturnToMain(true);
    }).catch((e) => {
      setIsSaving(false);
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: e.response.data.reason || e.response.data.message,
      });
    });
  };

  if (returnToMain) return <Redirect push to="/" />;
  if (loading) return <Skeleton variant="rect" height={500} width="100%" animation="wave" />;

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardTitle>
              Geral
              <Spacer />
            </CardTitle>

            <CardText>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="outlined"
                    name="name"
                    onChange={handleChange}
                    value={data.name || ''}
                    label="Nome"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <RiAccountCircleFill size={20} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="outlined"
                    name="email"
                    value={user.email || ''}
                    label="E-mail"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <RiMailFill size={20} />
                        </InputAdornment>
                      ),
                    }}
                    disabled
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="outlined"
                    name="nivel"
                    value={user.profile.description || ''}
                    label="Nível de Acesso"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SiOpslevel size={20} />
                        </InputAdornment>
                      ),
                    }}
                    disabled
                  />
                </Grid>

              </Grid>
            </CardText>
          </Card>
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <Paper
              elevation={2}
              style={{
                borderRadius: 5,
                marginLeft: 5,
                marginTop: 15,
                marginRight: 5,
              }}
            >
              <Tabs
                indicatorColor="primary"
                textColor="primary"
                value={value}
                onChange={handleChangeTabControl}
                aria-label="nav tabs example"
                style={{ justifyContent: 'center' }}
              >
                <LinkTab
                  icon={tabTitle[0].icon}
                  label={tabTitle[0].title}
                  {...a11yProps(0)}
                  onClick={(event) => { event.preventDefault(); setChangePw(false); }}
                  href="/0"
                />
                <LinkTab
                  icon={tabTitle[1].icon}
                  label={tabTitle[1].title}
                  {...a11yProps(1)}
                  onClick={(event) => { event.preventDefault(); user.authType === 'local' && (setChangePw(true)); }}
                  href="/1"
                />
              </Tabs>

              <TabPanel value={value} index={0}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={6}>
                    <Card similar>
                      <CardTitle>
                        FUSO HORÁRIO
                        <Spacer />
                      </CardTitle>
                      <CardText>
                        <FormControl variant="outlined">
                          <InputLabel id="tzLabel">Time Zone</InputLabel>
                          <Select
                            labelId="tzLabel"
                            id="tz"
                            name="tz"
                            value={data.tz || data.preferences.tz}
                            onChange={handleChange}
                            label="Time Zone"
                            startAdornment={(
                              <InputAdornment position="start">
                                <RiTimeFill size={20} />
                              </InputAdornment>
                        )}
                          >
                            {
                            timeZones.map((option) => (

                              option === data.preferences.tz ?
                                <MenuItem key={option} value={option} selected>{option}</MenuItem>
                                :
                                <MenuItem key={option} value={option}>{option}</MenuItem>
                            ))
                          }

                          </Select>
                        </FormControl>
                        <Grid container spacing={3} xs={12} style={{ marginTop: 25, textAlign: 'left' }}>
                          <Grid item xs={3}>
                            <Typography style={{ marginLeft: 5 }}>
                              {`UTC: ${utc} `}
                            </Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <Typography>
                              {`DATA: ${extraiData(dateTime)} `}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography>
                              {`HORA: ${extraiHora(dateTime)} `}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardText>
                    </Card>
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <Card similar>
                      <CardTitle>
                        ARQUIVO CSV
                        <Spacer />
                      </CardTitle>
                      <CardText>
                        <FormControl variant="outlined">
                          <InputLabel id="csvLabel">Separador</InputLabel>
                          <Select
                            labelId="csvLabel"
                            id="csv"
                            name="csv"
                            in
                            value={data.csv || data.preferences.csv}
                            onChange={handleChange}
                            label="Separador"
                            startAdornment={(
                              <InputAdornment position="start">
                                <RiSeparator size={20} />
                              </InputAdornment>
                          )}
                          >
                            {
                              csvSeparator.map((option) => (
                                option === data.preferences.csv ? (
                                  <MenuItem
                                    key={option.id}
                                    value={option.separator}
                                    selected
                                  >
                                    {option.description}
                                  </MenuItem>
                                )
                                  : (
                                    <MenuItem
                                      key={option.id}
                                      value={option.separator}
                                    >
                                      {option.description}
                                    </MenuItem>
                                  )
                              ))
                            }

                          </Select>
                        </FormControl>
                        <FormControl variant="outlined" style={{ marginTop: 20 }}>
                          <InputLabel id="csvStringLabel">Qualificador de Texto</InputLabel>
                          <Select
                            labelId="csvStringLabel"
                            id="csvString"
                            name="csvString"
                            in
                            value={data.csvString || data.preferences.csvString}
                            onChange={handleChange}
                            label="Qualificador de Texto"
                            startAdornment={(
                              <InputAdornment position="start">
                                <RiSeparator size={20} />
                              </InputAdornment>
                          )}
                          >
                            {
                              csvQualificator.map((option) => (
                                option === data.preferences.csvString ? (
                                  <MenuItem
                                    key={option.id}
                                    value={option.separator}
                                    selected
                                  >
                                    {option.description}
                                  </MenuItem>
                                )
                                  : (
                                    <MenuItem
                                      key={option.id}
                                      value={option.separator}
                                    >
                                      {option.description}
                                    </MenuItem>
                                  )
                              ))
                            }

                          </Select>
                        </FormControl>
                      </CardText>
                    </Card>
                  </Grid>
                  {/*
                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Card similar>
                      <CardTitle>
                        TEMA
                        <Spacer />
                      </CardTitle>
                      <CardText>
                      <Typography component="div">
                        <Grid container xs={12} spacing={1}>
                          <Grid item style={{ alignContent: 'center' }}>Habilitar Modo Dark: </Grid>
                          <Grid item> Off</Grid>
                          <Grid item style={{ marginTop: -8 }}>
                            <Switch
                              color="default"
                              //checked={state.checkedA}
                              //onChange={handleChange}
                              name="switchTheme"
                              inputProps={{ 'aria-label': 'checkbox with default color' }}
                            />
                          </Grid>
                          <Grid item>On</Grid>
                        </Grid>
                      </Typography>

                      </CardText>
                    </Card>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Card similar>
                      <CardTitle>
                        IDIOMA E MOEDA
                        <Spacer />
                      </CardTitle>
                      <CardText>
                        <TextField
                          id="idioma"
                          name="idioma"
                          select
                          label="Idioma"
                          value="Português - Brasil (PT-BR)"
                          // onChange={handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          helperText=""
                          variant="outlined"
                          size="small"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <RiTranslate2 size={20} />
                              </InputAdornment>
                            ),
                          }}
                          disabled
                        >
                          <option
                            key="0"
                            value="Português - Brasil (PT-BR)"
                            selected
                          >
                            Português - Brasil (PT-BR)
                          </option>
                        </TextField>

                        <TextField
                          id="moeda"
                          name="moeda"
                          select
                          label="Moeda"
                          value="R$"
                          // onChange={handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          helperText=""
                          variant="outlined"
                          size="small"
                          style={{ marginTop: 10 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <RiMoneyDollarCircleFill size={20} />
                              </InputAdornment>
                            ),
                          }}
                          disabled
                        >
                          <option
                            key="0"
                            value="R$"
                            selected
                          >
                            R$
                          </option>
                        </TextField>
                      </CardText>
                    </Card>
                  </Grid>
                        */}
                </Grid>
              </TabPanel>

              <TabPanel
                value={value}
                index={1}
              >
                {
                }
                {// user.authType === 'local' && (setChangePw(true)
                  /* <Grid item xs={12}>
                        <Button
                        size="large"
                        variant="contained"
                        color="secondary"
                        onClick={() => (setChangePw(!changePw))}
                        startIcon={<LockIcon />}
                      >
                        {changePw ? 'Manter senha atual' : 'Trocar Senha'}
                  </Button>
                    </Grid> */
                  // )
                }
                <Grid container xs={12} sm={12} md={12} lg={12} xl={12} style={{ justifyContent: 'left' }}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ alignContent: 'left' }}>
                    <Card>
                      <CardText>
                        {changePw ?
                          (
                            <>
                              {localStorage.forceResetPassword === 'true' && (
                                <Grid item xs={12}>
                                  <h3 style={{ fontWeight: 500, marginTop: 0 }}>
                                    Você precisa mudar sua senha para continuar com o acesso
                                  </h3>
                                </Grid>
                              )}
                              <Grid item xs={12}>
                                <NewPasswordChecker data={pwData} setData={setPwData} currentPw />
                              </Grid>
                            </>
                          )
                          :
                          ''
                        }
                      </CardText>
                    </Card>
                  </Grid>
                </Grid>
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={`${classes.textAlign} ${classes.bottomButtons}`}>
        <BackButton
          back={() => setReturnToMain(true)}
          className={classes.btnSpace}
          style={{ marginTop: '24px' }}
        />

        <SaveButton
          save={save}
          isSaving={isSaving}
          className={classes.btnSpace}
          notComplete={!isComplete}
        // canSave={AdminPermissions.USERS_MANAGE.value}
          style={{ marginTop: '24px' }}
        />
      </Grid>
    </>
  );
};
