// Basic
import React from 'react';

// UI, images, styles and icons
import '../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsIcon from '@material-ui/icons/Settings';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './IndustrialAutomationRoute.css';

// Device status
const motorStatus = [];
motorStatus[0] = { label: 'Desligado', color: '#026502' };
motorStatus[1] = { label: 'Ligado', color: '#DC3F3F' };
motorStatus[2] = { label: 'Ligado sentido reverso', color: '#DC3F3F' };
motorStatus[3] = { label: 'Ligando', color: '#DC3F3F' };
motorStatus[4] = { label: 'Defeito', color: '#FFCD41' };
motorStatus[5] = { label: 'Defeito reserva', color: '#FFCD41' };
motorStatus[6] = { label: 'Manutenção', color: '#2D9CDB' };

const valveStatus = [];
valveStatus[1] = { label: 'Posição 1', color: '#026502' };
valveStatus[2] = { label: 'Posição 2', color: '#026502' };
valveStatus[3] = { label: 'Posição 3', color: '#026502' };
valveStatus[4] = { label: 'Posição 4', color: '#026502' };
valveStatus[5] = { label: 'Movimentando', color: '#026502' };
valveStatus[6] = { label: 'Defeito', color: '#FFCD41' };
valveStatus[7] = { label: 'Manutenção', color: '#2D9CDB' };

const useStyles = makeStyles({
  slider: {
    display: 'flex',
  },
  sliderButton: {
    border: 'none',
    backgroundColor: 'transparent',
  },
  deviceCard: {
    margin: '0',
    padding: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deviceIcon: {
    width: '35%',
  },
  deviceName: {
    fontSize: '15px',
    fontWeight: 'normal',
    width: '100%',
    textAlign: 'center',
    margin: 0,
    padding: '5px 0',
  },
  deviceSettings: {
    fontSize: '15px',
    fontWeight: 'normal',
    width: '100%',
    textAlign: 'center',
    margin: 0,
    padding: '5px 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
});

export default ({
  subEqp,
  subEquipaments,
  setSubModal: setOpenSubModal,
  equipamentId,
}) => {
  const classes = useStyles();
  const [subEquipData, setSubEquipData] = React.useState();

  React.useEffect(() => {
    setSubEquipData(subEquipaments.find((f) => f.tag === subEqp.name));
  }, [subEqp, subEquipaments]);

  const configButton = (
    <IconButton
      size="small"
      onClick={() =>
        setOpenSubModal({
          open: true,
          subEqp: subEquipData || {
            tag: subEqp.name,
            equipamentId,
          },
          setSubEquipData,
        })
      }
    >
      <SettingsIcon />
    </IconButton>
  );

  return (
    <>
      <Paper style={{ background: '#E5E5E5', boxShadow: 'none' }}>
        <Tooltip
          title={
            subEqp.type === 'motor'
              ? motorStatus[subEqp.status].label
              : valveStatus[subEqp.status].label
          }
        >
          <div
            className={`${classes.deviceCard}`}
            style={{
              backgroundColor:
                subEqp.type === 'motor'
                  ? motorStatus[subEqp.status].color
                  : valveStatus[subEqp.status].color,
            }}
          >
            <img
              src={`/images/industrialAutomation/${subEqp.icon}.svg`}
              className={classes.deviceIcon}
              alt={subEqp.icon}
            />
          </div>
        </Tooltip>
        <h4 className={classes.deviceName}>{subEqp.name}</h4>
        {subEquipData ? (
          <h5 className={classes.deviceSettings}>
            {subEquipData?.jobDescription}
            {configButton}
          </h5>
        ) : (
          <h5 className={classes.deviceSettings}>
            <b>Não Configurado!</b>
            {configButton}
          </h5>
        )}
      </Paper>
    </>
  );
};
