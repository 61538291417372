/* eslint-disable  */

import { CriaSilo } from './CriaSilo'

import { CriaArmazem } from './CriaArmazem'
import { CriaArmazemV } from './CriaArmazemV'
import { CriaTulhaV } from './CriaTulhaV'
import { CriaTulhaPlano } from './CriaTulhaPlano'
import { CriaSiloV } from './CriaSiloV'
import { CriaSiloElevado } from './CriaSiloElevado'
import { CriaBunkerV } from './CriaBunkerV'
import { CriaBunkerPlano } from './CriaBunkerPlano'
import { CriaArmazemPlano } from './CriaArmazemPlano'
import { CriaArmazemArredondadoPlano } from './CriaArmazemArredondadoPlano'

// O BLOCO DE CÓDIGO ABAIXO, DE ACORDO COM O TIPO DE UNIDADE, CONSTROI O ELEMENTO CORRESPONDENTE
const AdicionarUnidades = (
  scene,
  layout,
  building,
  termoReport,
  colorConfig,
  fncChangeViewInfo,
  objetoCamadaProduto,
  unidadesObservaveis,
  setUnidadesObservaveis,
  produto_visivel,
  handleOnSetObjetoCamadaProduto,
  produto_info,
  fncChangeViewLevel,
  subareaSelected,
  grao,
  fchChangeViewStaircase,
  escada_visivel,
  fcnSeeTooltip,
  colorMotorStatus3D1,
  colorMotorStatus3D2,
  colorMotorStatus3D3,
  colorMotorStatus3D4,
  colorMotorStatus3D5,
  colorMotorStatus3D1Shaded,
  colorMotorStatus3D2Shaded,
  colorMotorStatus3D3Shaded,
  colorMotorStatus3D4Shaded,
  colorMotorStatus3D5Shaded,
  fcnSeeTooltipMotor,
  permissaoParaEditarOEstadoDosMotores,
  painelEditaMotoresEmUso,
  motor3D,
  baseMotor3D,
  conjuntoMotor3D,
  suporteDoMotor3D
) => {
  if (building !== undefined) {
    if (building.type === 1) {
      // Silo Plano
      // console.log('SILO PLANO')

      // calcula numero maximo de cabos em um arco
      let maxCable = 0
      layout.arcs.map((arc) => {
        if (maxCable < arc.cables.length) {
          maxCable = arc.cables.length
        }
      })

      // busca numero maximo de sensores em um cabo
      let maxSensor = 0
      layout.arcs.map((arc) => {
        arc.cables.map((cable) => {
          if (maxSensor < cable.sensors.length) {
            maxSensor = cable.sensors.length
          }
        })
      })

      // calcula altura de forma dinamica
      const entrePontosCalculado =
        building.between_points > 1 ? building.between_points : 1

      // calcula altura de forma dinamica
      const alturaCalculada =
        maxSensor * entrePontosCalculado -
        (building.radius * Math.tan((30 * Math.PI) / 180)) / 2

      // calcula diametro de forma dinamica
      const diametroCalculado =
        alturaCalculada > 25 ? building.radius * 1.5 : building.radius

      CriaSilo(
        scene,
        layout,
        termoReport,
        colorConfig,
        building.id,
        building.name,
        diametroCalculado,
        alturaCalculada,
        0,
        0,
        0,
        0,
        objetoCamadaProduto,
        unidadesObservaveis,
        setUnidadesObservaveis,
        fncChangeViewInfo,
        produto_visivel,
        handleOnSetObjetoCamadaProduto,
        produto_info,
        fncChangeViewLevel,
        entrePontosCalculado,
        grao,
        fchChangeViewStaircase,
        escada_visivel,
        maxCable,
        36,
        fcnSeeTooltip,
        colorMotorStatus3D1,
        colorMotorStatus3D2,
        colorMotorStatus3D3,
        colorMotorStatus3D4,
        colorMotorStatus3D5,
        colorMotorStatus3D1Shaded,
        colorMotorStatus3D2Shaded,
        colorMotorStatus3D3Shaded,
        colorMotorStatus3D4Shaded,
        colorMotorStatus3D5Shaded,
        fcnSeeTooltipMotor,
        permissaoParaEditarOEstadoDosMotores,
        painelEditaMotoresEmUso,
        motor3D,
        baseMotor3D,
        conjuntoMotor3D,
        suporteDoMotor3D
      )
    } else if (
      building.type === 2 ||
      building.type === 10 ||
      building.type === 11
    ) {
      // Silo V
      // console.log('SILO V ====================>')
      // console.log(building)
      // console.log(layout)

      // busca numero maximo de sensores em um cabo
      let maxSensor = 0
      layout.arcs.map((arc) => {
        arc.cables.map((cable) => {
          if (maxSensor < cable.sensors.length) {
            maxSensor = cable.sensors.length
          }
        })
      })

      // calcula altura de forma dinamica
      const entrePontosCalculado =
        building.between_points > 1 ? building.between_points : 1

      // calcula altura de forma dinamica
      let alturaCalculada =
        maxSensor * entrePontosCalculado -
        3 -
        (building.radius * Math.tan((30 * Math.PI) / 180)) / 2

      // calcula diametro de forma dinamica
      const diametroCalculado = building.radius

      CriaSiloV(
        scene,
        layout,
        termoReport,
        colorConfig,
        building.id,
        building.name,
        diametroCalculado,
        alturaCalculada,
        0,
        0,
        0,
        0,
        objetoCamadaProduto,
        unidadesObservaveis,
        setUnidadesObservaveis,
        fncChangeViewInfo,
        produto_visivel,
        handleOnSetObjetoCamadaProduto,
        produto_info,
        fncChangeViewLevel,
        building.height_bottom_v,
        entrePontosCalculado,
        grao,
        fchChangeViewStaircase,
        escada_visivel,
        fcnSeeTooltip,
        colorMotorStatus3D1,
        colorMotorStatus3D2,
        colorMotorStatus3D3,
        colorMotorStatus3D4,
        colorMotorStatus3D5,
        colorMotorStatus3D1Shaded,
        colorMotorStatus3D2Shaded,
        colorMotorStatus3D3Shaded,
        colorMotorStatus3D4Shaded,
        colorMotorStatus3D5Shaded,
        fcnSeeTooltipMotor,

        permissaoParaEditarOEstadoDosMotores,
        painelEditaMotoresEmUso,
        motor3D,
        baseMotor3D,
        conjuntoMotor3D,
        suporteDoMotor3D,
        building.type
      )
    } else if (building.type === 3 || building.type === 16) {
      // Armazém Plano
      if (layout.arcs !== null && layout.arcs !== undefined) {
        // calc width here with layout prop
        let quantArc = layout.arcs.length
        let myWidth = 0
        if (quantArc > 30) {
          myWidth = quantArc * 5
        } else {
          myWidth = quantArc * 10
        }

        // calc length here with layout prop
        let maxCable = 0
        layout.arcs.map((arc) => {
          if (maxCable < arc.cables.length) {
            maxCable = arc.cables.length
          }
        })

        let myLarg = maxCable * 10

        let maxNumberOfSensors = 0
        for (let i = 0; i < layout.arcs.length; i++) {
          for (let k = 0; k < layout.arcs[i].cables.length; k++) {
            if (layout.arcs[i].cables[k].sensors.length > maxNumberOfSensors) {
              maxNumberOfSensors = layout.arcs[i].cables[k].sensors.length
            }
          }
        }

        let variableHeight = 0
        let variableHeightBottomV = 0
        let distanciaEntrePontos = 0

        if (maxNumberOfSensors <= 6) {
          variableHeight = 6
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        } else if (maxNumberOfSensors > 6 && maxNumberOfSensors <= 8) {
          variableHeight = 6
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        } else if (maxNumberOfSensors > 8 && maxNumberOfSensors <= 10) {
          variableHeight = 8
          variableHeightBottomV = 6
          distanciaEntrePontos = 2
        } else if (maxNumberOfSensors > 10 && maxNumberOfSensors <= 12) {
          variableHeight = 8
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        } else if (maxNumberOfSensors > 12 && maxNumberOfSensors <= 14) {
          variableHeight = 10
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        } else {
          variableHeight = 10
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        }

        CriaArmazemPlano(
          scene,
          layout,
          termoReport,
          colorConfig,
          building.id,
          building.name,
          myWidth,
          myLarg,
          variableHeight,
          1,
          2,
          -0.5,
          2,
          0,
          0,
          0,
          [],
          fncChangeViewInfo,
          objetoCamadaProduto,
          unidadesObservaveis,
          setUnidadesObservaveis,
          produto_visivel,
          handleOnSetObjetoCamadaProduto,
          produto_info,
          fncChangeViewLevel,
          subareaSelected,
          grao,
          fchChangeViewStaircase,
          escada_visivel,
          fcnSeeTooltip,
          colorMotorStatus3D1,
          colorMotorStatus3D2,
          colorMotorStatus3D3,
          colorMotorStatus3D4,
          colorMotorStatus3D5,
          colorMotorStatus3D1Shaded,
          colorMotorStatus3D2Shaded,
          colorMotorStatus3D3Shaded,
          colorMotorStatus3D4Shaded,
          colorMotorStatus3D5Shaded,
          fcnSeeTooltipMotor,

          permissaoParaEditarOEstadoDosMotores,
          painelEditaMotoresEmUso,
          motor3D,
          baseMotor3D,
          conjuntoMotor3D,
          suporteDoMotor3D
        )
      }
    } else if (building.type === 7) {
      // Armazém Arredondado Plano
      if (layout.arcs !== null && layout.arcs !== undefined) {
        // calc width here with layout prop
        let quantArc = layout.arcs.length
        let myWidth = 0
        if (quantArc > 30) {
          myWidth = quantArc * 5
        } else {
          myWidth = quantArc * 10
        }

        // calc length here with layout prop
        let maxCable = 0
        layout.arcs.map((arc) => {
          if (maxCable < arc.cables.length) {
            maxCable = arc.cables.length
          }
        })

        let myLarg = maxCable * 10

        let maxNumberOfSensors = 0
        for (let i = 0; i < layout.arcs.length; i++) {
          for (let k = 0; k < layout.arcs[i].cables.length; k++) {
            if (layout.arcs[i].cables[k].sensors.length > maxNumberOfSensors) {
              maxNumberOfSensors = layout.arcs[i].cables[k].sensors.length
            }
          }
        }

        let variableHeight = 0
        let variableHeightBottomV = 0
        let distanciaEntrePontos = 0

        if (maxNumberOfSensors <= 6) {
          variableHeight = 6
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        } else if (maxNumberOfSensors > 6 && maxNumberOfSensors <= 8) {
          variableHeight = 6
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        } else if (maxNumberOfSensors > 8 && maxNumberOfSensors <= 10) {
          variableHeight = 8
          variableHeightBottomV = 6
          distanciaEntrePontos = 2
        } else if (maxNumberOfSensors > 10 && maxNumberOfSensors <= 12) {
          variableHeight = 8
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        } else if (maxNumberOfSensors > 12 && maxNumberOfSensors <= 14) {
          variableHeight = 10
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        } else {
          variableHeight = 10
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        }

        CriaArmazemArredondadoPlano(
          scene,
          layout,
          termoReport,
          colorConfig,
          building.id,
          building.name,
          myWidth,
          myLarg,
          variableHeight,
          1,
          2,
          -0.5,
          2,
          0,
          0,
          0,
          [],
          fncChangeViewInfo,
          objetoCamadaProduto,
          unidadesObservaveis,
          setUnidadesObservaveis,
          produto_visivel,
          handleOnSetObjetoCamadaProduto,
          produto_info,
          fncChangeViewLevel,
          subareaSelected,
          grao,
          fchChangeViewStaircase,
          escada_visivel,
          fcnSeeTooltip,
          colorMotorStatus3D1,
          colorMotorStatus3D2,
          colorMotorStatus3D3,
          colorMotorStatus3D4,
          colorMotorStatus3D5,
          colorMotorStatus3D1Shaded,
          colorMotorStatus3D2Shaded,
          colorMotorStatus3D3Shaded,
          colorMotorStatus3D4Shaded,
          colorMotorStatus3D5Shaded,
          fcnSeeTooltipMotor,
          permissaoParaEditarOEstadoDosMotores,
          painelEditaMotoresEmUso,
          motor3D,
          baseMotor3D,
          conjuntoMotor3D,
          suporteDoMotor3D
        )
      }
    } else if (building.type === 4 || building.type === 17) {
      //Semi V
      // console.log('AQUII')
      // console.log(building)
      // console.log(layout)
      if (layout.arcs !== null && layout.arcs !== undefined) {
        // calc width here with layout prop
        let quantArc = layout.arcs.length
        let myWidth = quantArc * 10

        // calc length here with layout prop
        let maxCable = 0
        layout.arcs.map((arc) => {
          if (maxCable < arc.cables.length) {
            maxCable = arc.cables.length
          }
        })

        let maxNumberOfSensors = 0
        for (let i = 0; i < layout.arcs.length; i++) {
          for (let k = 0; k < layout.arcs[i].cables.length; k++) {
            if (layout.arcs[i].cables[k].sensors.length > maxNumberOfSensors) {
              maxNumberOfSensors = layout.arcs[i].cables[k].sensors.length
            }
          }
        }

        let variableHeight = 0
        let variableHeightBottomV = 0
        let distanciaEntrePontos = 0

        if (maxNumberOfSensors <= 6) {
          variableHeight = 6
          variableHeightBottomV = 2
          distanciaEntrePontos = 2
        } else if (maxNumberOfSensors > 6 && maxNumberOfSensors <= 8) {
          variableHeight = 8
          variableHeightBottomV = 3
          distanciaEntrePontos = 1.9
        } else if (maxNumberOfSensors > 8 && maxNumberOfSensors <= 10) {
          variableHeight = 10
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        } else if (maxNumberOfSensors > 10 && maxNumberOfSensors <= 12) {
          variableHeight = 12
          variableHeightBottomV = 4
          distanciaEntrePontos = 1.7
        } else if (maxNumberOfSensors > 12 && maxNumberOfSensors <= 14) {
          variableHeight = 14
          variableHeightBottomV = 5
          distanciaEntrePontos = 1.6
        } else {
          variableHeight = 16
          variableHeightBottomV = 6
          distanciaEntrePontos = 1.5
        }

        // console.log('maxNumberOfSensors')
        // console.log(maxNumberOfSensors)
        // console.log('variableHeight')
        // console.log('variableHeightBottomV')
        // console.log(variableHeight)
        // console.log(variableHeightBottomV)

        // console.log('layout')
        // console.log(layout.arcs)

        let myLarg = maxCable * 10

        CriaArmazem(
          scene,
          layout,
          termoReport,
          colorConfig,
          building.id,
          building.name,
          myWidth,
          myLarg,
          variableHeight,
          1,
          1,
          variableHeightBottomV,
          2,
          0,
          0,
          0,
          [],
          fncChangeViewInfo,
          objetoCamadaProduto,
          unidadesObservaveis,
          setUnidadesObservaveis,
          produto_visivel,
          handleOnSetObjetoCamadaProduto,
          produto_info,
          fncChangeViewLevel,
          subareaSelected,
          grao,
          fchChangeViewStaircase,
          escada_visivel,
          fcnSeeTooltip,
          colorMotorStatus3D1,
          colorMotorStatus3D2,
          colorMotorStatus3D3,
          colorMotorStatus3D4,
          colorMotorStatus3D5,
          colorMotorStatus3D1Shaded,
          colorMotorStatus3D2Shaded,
          colorMotorStatus3D3Shaded,
          colorMotorStatus3D4Shaded,
          colorMotorStatus3D5Shaded,
          fcnSeeTooltipMotor,
          permissaoParaEditarOEstadoDosMotores,
          painelEditaMotoresEmUso,
          motor3D,
          baseMotor3D,
          conjuntoMotor3D,
          suporteDoMotor3D,
          distanciaEntrePontos
        )
      }
    } else if (building.type === 5 || building.type === 8) {
      // Armazém V

      if (layout.arcs !== null && layout.arcs !== undefined) {
        // calc width here with layout prop
        let quantArc = layout.arcs.length
        let myWidth = 0
        if (quantArc > 30) {
          myWidth = quantArc * 5
        } else if (quantArc > 20 && quantArc <= 30) {
          myWidth = quantArc * 7
        } else {
          myWidth = quantArc * 10
        }

        // calc length here with layout prop
        let maxCable = 0
        layout.arcs.map((arc) => {
          if (maxCable < arc.cables.length) {
            maxCable = arc.cables.length
          }
        })

        let myLarg = maxCable * 10

        let maxNumberOfSensors = 0
        for (let i = 0; i < layout.arcs.length; i++) {
          for (let k = 0; k < layout.arcs[i].cables.length; k++) {
            if (layout.arcs[i].cables[k].sensors.length > maxNumberOfSensors) {
              maxNumberOfSensors = layout.arcs[i].cables[k].sensors.length
            }
          }
        }

        let variableHeight = 0
        let variableHeightBottomV = 0
        let distanciaEntrePontos = 0

        if (maxNumberOfSensors <= 6) {
          variableHeight = 6
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        } else if (maxNumberOfSensors > 6 && maxNumberOfSensors <= 8) {
          variableHeight = 6
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        } else if (maxNumberOfSensors > 8 && maxNumberOfSensors <= 10) {
          variableHeight = 8
          variableHeightBottomV = 6
          distanciaEntrePontos = 2
        } else if (maxNumberOfSensors > 10 && maxNumberOfSensors <= 12) {
          variableHeight = 8
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        } else if (maxNumberOfSensors > 12 && maxNumberOfSensors <= 14) {
          variableHeight = 10
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        } else {
          variableHeight = 10
          variableHeightBottomV = 4
          distanciaEntrePontos = 2
        }

        // console.log('CRIAR ARMAZEM')
        // console.log(layout)
        // console.log(termoReport)
        // console.log(building)

        CriaArmazemV(
          scene,
          layout,
          termoReport,
          colorConfig,
          building.id,
          building.name,
          myWidth,
          myLarg,
          variableHeight,
          1,
          0,
          variableHeightBottomV,
          2,
          0,
          0,
          0,
          [],
          fncChangeViewInfo,
          objetoCamadaProduto,
          unidadesObservaveis,
          setUnidadesObservaveis,
          produto_visivel,
          handleOnSetObjetoCamadaProduto,
          produto_info,
          fncChangeViewLevel,
          subareaSelected,
          grao,
          fchChangeViewStaircase,
          escada_visivel,
          fcnSeeTooltip,
          colorMotorStatus3D1,
          colorMotorStatus3D2,
          colorMotorStatus3D3,
          colorMotorStatus3D4,
          colorMotorStatus3D5,
          colorMotorStatus3D1Shaded,
          colorMotorStatus3D2Shaded,
          colorMotorStatus3D3Shaded,
          colorMotorStatus3D4Shaded,
          colorMotorStatus3D5Shaded,
          fcnSeeTooltipMotor,
          permissaoParaEditarOEstadoDosMotores,
          painelEditaMotoresEmUso,
          motor3D,
          baseMotor3D,
          conjuntoMotor3D,
          suporteDoMotor3D,
          distanciaEntrePontos
        )
      }
    } else if (building.type === 6) {
      // Armazém W
      if (layout.arcs !== null && layout.arcs !== undefined) {
        // calc width here with layout prop
        let quantArc = layout.arcs.length
        let myWidth = quantArc * 10

        // calc length here with layout prop
        let maxCable = 0
        layout.arcs.map((arc) => {
          if (maxCable < arc.cables.length) {
            maxCable = arc.cables.length
          }
        })

        let myLarg = maxCable * 10

        CriaArmazem(
          scene,
          layout,
          termoReport,
          colorConfig,
          building.id,
          building.name,
          myWidth,
          myLarg,
          5,
          1,
          1,
          2,
          2,
          0,
          0,
          0,
          [],
          fncChangeViewInfo,
          objetoCamadaProduto,
          unidadesObservaveis,
          setUnidadesObservaveis,
          produto_visivel,
          handleOnSetObjetoCamadaProduto,
          produto_info,
          fncChangeViewLevel,
          grao,
          fchChangeViewStaircase,
          escada_visivel,
          fcnSeeTooltip,
          colorMotorStatus3D1,
          colorMotorStatus3D2,
          colorMotorStatus3D3,
          colorMotorStatus3D4,
          colorMotorStatus3D5,
          colorMotorStatus3D1Shaded,
          colorMotorStatus3D2Shaded,
          colorMotorStatus3D3Shaded,
          colorMotorStatus3D4Shaded,
          colorMotorStatus3D5Shaded,
          fcnSeeTooltipMotor,
          permissaoParaEditarOEstadoDosMotores,
          painelEditaMotoresEmUso,
          motor3D,
          baseMotor3D,
          conjuntoMotor3D,
          suporteDoMotor3D
        )
      }
    } else if (building.type === 9) {
      // busca numero maximo de sensores em um cabo
      let maxSensor = 0
      layout.arcs.map((arc) => {
        arc.cables.map((cable) => {
          if (maxSensor < cable.sensors.length) {
            maxSensor = cable.sensors.length
          }
        })
      })

      // calcula altura de forma dinamica
      const entrePontosCalculado =
        building.between_points > 1 ? building.between_points : 1

      // calcula altura de forma dinamica
      const alturaCalculada =
        maxSensor * entrePontosCalculado -
        3 -
        (building.radius * Math.tan((30 * Math.PI) / 180)) / 2

      // calcula diametro de forma dinamica
      const diametroCalculado =
        alturaCalculada > 10 ? building.radius * 1.5 : building.radius

      CriaSiloElevado(
        scene,
        layout,
        termoReport,
        colorConfig,
        building.id,
        building.name,
        diametroCalculado,
        alturaCalculada,
        0,
        0,
        0,
        0,
        objetoCamadaProduto,
        unidadesObservaveis,
        setUnidadesObservaveis,
        fncChangeViewInfo,
        produto_visivel,
        handleOnSetObjetoCamadaProduto,
        produto_info,
        fncChangeViewLevel,
        building.height_bottom_v,
        grao,
        fchChangeViewStaircase,
        escada_visivel,
        fcnSeeTooltip,
        colorMotorStatus3D1,
        colorMotorStatus3D2,
        colorMotorStatus3D3,
        colorMotorStatus3D4,
        colorMotorStatus3D5,
        colorMotorStatus3D1Shaded,
        colorMotorStatus3D2Shaded,
        colorMotorStatus3D3Shaded,
        colorMotorStatus3D4Shaded,
        colorMotorStatus3D5Shaded,
        fcnSeeTooltipMotor,
        permissaoParaEditarOEstadoDosMotores,
        painelEditaMotoresEmUso,
        motor3D,
        baseMotor3D,
        conjuntoMotor3D,
        suporteDoMotor3D,
        entrePontosCalculado
      )
    } else if (building.type === 12) {
      // Tulha Plano
      if (layout.arcs !== null && layout.arcs !== undefined) {
        // calc width here with layout prop
        let quantArc = layout.arcs.length
        let myWidth = quantArc * 10

        // calc length here with layout prop
        let maxCable = 0
        layout.arcs.map((arc) => {
          if (maxCable < arc.cables.length) {
            maxCable = arc.cables.length
          }
        })

        let myLarg = maxCable * 10

        // PAREI AQUI

        CriaTulhaPlano(
          scene,
          layout,
          termoReport,
          colorConfig,
          building.id,
          building.name,
          myWidth,
          myLarg,
          5,
          1,
          2,
          -0.5,
          2,
          0,
          0,
          0,
          [],
          fncChangeViewInfo,
          objetoCamadaProduto,
          unidadesObservaveis,
          setUnidadesObservaveis,
          produto_visivel,
          handleOnSetObjetoCamadaProduto,
          produto_info,
          fncChangeViewLevel,
          grao,
          fchChangeViewStaircase,
          escada_visivel,
          fcnSeeTooltip
        )
      }
    } else if (building.type === 13) {
      // Tulha V
      // console.log('BUILDINGGGG')
      // console.log(building)
      if (layout.arcs !== null && layout.arcs !== undefined) {
        // calc width here with layout prop
        let quantArc = layout.arcs.length
        let myWidth = quantArc * 10

        // calc length here with layout prop
        let maxCable = 0
        layout.arcs.map((arc) => {
          if (maxCable < arc.cables.length) {
            maxCable = arc.cables.length
          }
        })

        let myLarg = maxCable * 10

        CriaTulhaV(
          scene,
          layout,
          termoReport,
          colorConfig,
          building.id,
          building.name,
          myWidth,
          myLarg,
          5,
          1,
          0,
          4,
          2,
          0,
          0,
          0,
          [],
          fncChangeViewInfo,
          objetoCamadaProduto,
          unidadesObservaveis,
          setUnidadesObservaveis,
          produto_visivel,
          handleOnSetObjetoCamadaProduto,
          produto_info,
          fncChangeViewLevel,
          building.between_points,
          building.perc_level,
          grao,
          fchChangeViewStaircase,
          escada_visivel,
          fcnSeeTooltip,
          colorMotorStatus3D1,
          colorMotorStatus3D2,
          colorMotorStatus3D3,
          colorMotorStatus3D4,
          colorMotorStatus3D5,
          colorMotorStatus3D1Shaded,
          colorMotorStatus3D2Shaded,
          colorMotorStatus3D3Shaded,
          colorMotorStatus3D4Shaded,
          colorMotorStatus3D5Shaded,
          fcnSeeTooltipMotor,
          permissaoParaEditarOEstadoDosMotores,
          painelEditaMotoresEmUso,
          motor3D,
          baseMotor3D,
          conjuntoMotor3D,
          suporteDoMotor3D
        )
      }
    } else if (building.type === 14) {
      // Bunker Plano

      // busca numero maximo de sensores em um cabo
      let maxSensor = 0
      layout.arcs.map((arc) => {
        arc.cables.map((cable) => {
          if (maxSensor < cable.sensors.length) {
            maxSensor = cable.sensors.length
          }
        })
      })

      // calcula altura de forma dinamica
      const entrePontosCalculado =
        building.between_points > 1 ? building.between_points : 1

      // calcula altura de forma dinamica
      const alturaCalculada =
        maxSensor * entrePontosCalculado -
        (building.radius * Math.tan((30 * Math.PI) / 180)) / 2

      CriaBunkerPlano(
        scene,
        layout,
        termoReport,
        colorConfig,
        building.id,
        building.name,
        building.radius,
        alturaCalculada,
        0,
        0,
        0,
        0,
        objetoCamadaProduto,
        unidadesObservaveis,
        setUnidadesObservaveis,
        fncChangeViewInfo,
        produto_visivel,
        handleOnSetObjetoCamadaProduto,
        produto_info,
        fncChangeViewLevel,
        grao,
        fchChangeViewStaircase,
        escada_visivel,
        fcnSeeTooltip,
        colorMotorStatus3D1,
        colorMotorStatus3D2,
        colorMotorStatus3D3,
        colorMotorStatus3D4,
        colorMotorStatus3D5,
        colorMotorStatus3D1Shaded,
        colorMotorStatus3D2Shaded,
        colorMotorStatus3D3Shaded,
        colorMotorStatus3D4Shaded,
        colorMotorStatus3D5Shaded,
        fcnSeeTooltipMotor,
        permissaoParaEditarOEstadoDosMotores,
        painelEditaMotoresEmUso,
        motor3D,
        baseMotor3D,
        conjuntoMotor3D,
        suporteDoMotor3D,
        entrePontosCalculado
      )
    } else if (building.type === 15) {
      // Bunker Fundo V

      // busca numero maximo de sensores em um cabo
      let maxSensor = 0
      layout.arcs.map((arc) => {
        arc.cables.map((cable) => {
          if (maxSensor < cable.sensors.length) {
            maxSensor = cable.sensors.length
          }
        })
      })

      // calcula altura de forma dinamica
      const entrePontosCalculado =
        building.between_points > 1 ? building.between_points : 1

      // calcula altura de forma dinamica
      const alturaCalculada =
        maxSensor * entrePontosCalculado -
        3 -
        (building.radius * Math.tan((30 * Math.PI) / 180)) / 2

      CriaBunkerV(
        scene,
        layout,
        termoReport,
        colorConfig,
        building.id,
        building.name,
        building.radius,
        alturaCalculada,
        0,
        0,
        0,
        0,
        objetoCamadaProduto,
        unidadesObservaveis,
        setUnidadesObservaveis,
        fncChangeViewInfo,
        produto_visivel,
        handleOnSetObjetoCamadaProduto,
        produto_info,
        fncChangeViewLevel,
        building.height_bottom_v,
        entrePontosCalculado,
        grao,
        fchChangeViewStaircase,
        escada_visivel,
        fcnSeeTooltip,
        colorMotorStatus3D1,
        colorMotorStatus3D2,
        colorMotorStatus3D3,
        colorMotorStatus3D4,
        colorMotorStatus3D5,
        colorMotorStatus3D1Shaded,
        colorMotorStatus3D2Shaded,
        colorMotorStatus3D3Shaded,
        colorMotorStatus3D4Shaded,
        colorMotorStatus3D5Shaded,
        fcnSeeTooltipMotor,
        permissaoParaEditarOEstadoDosMotores,
        painelEditaMotoresEmUso,
        motor3D,
        baseMotor3D,
        conjuntoMotor3D,
        suporteDoMotor3D
      )
    }
  }
}

export default AdicionarUnidades
