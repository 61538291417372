import styled from 'styled-components';

export const Wrapper = styled.div`
flex: 1;

form {
  .error {
    color: #c53030;
    font-weight: 500;
  }

  .input-angle {
    width: 49.5%;
  }

  .angles-field {
    margin: 0.6rem 0;
    display: flex;
    justify-content: space-between;
  }

  .button-confirm {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
  }
}

  @media screen and (max-width: 1282px) {
    margin-top: 2rem;
  }


`;
