import React from 'react';
import '../../../../App.css';
import { Redirect } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import EditIcon from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import FockinkAPI from '../../../../middlewares/Api';
import PortalPermissions from '../../../../helpers/enums/PortalPermissions';
import { hasPermission } from '../../../../helpers/permission';
import BackButton from '../../../../components/Buttons/BackButton';
import Breadcrumbs from '../../../../components/Breadcrumbs';

export default () => {
  const [inputs, setInputs] = React.useState([]);
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);

  const breadcrumb = {
    title: 'Consulta de Usuários',
    routes: [{ to: '/settings', name: 'Configurações' }, { name: 'Usuários' }],
  };

  React.useEffect(() => {
    setLoading(true);
    FockinkAPI.get('/portalUsers').then((response) => {
      setInputs(response.data.data);
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    const columns = [
      { name: 'Nome', selector: 'name' },
      { name: 'Email', selector: 'login' },
      {
        name: 'Perfil',
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            {row.profile.description}
          </div>
        ),
      },
      {
        name: 'Status',
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            {row.status === 'A' ? 'Ativo' : 'Inativo'}
          </div>
        ),
      },
      {
        name: '',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color="secondary" onClick={() => setRedirect(row.id)}>
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];
    setDtCl({ columns, data: inputs });
  }, [inputs]);

  if (redirect) return <Redirect push to={`/portalUsers/${redirect}`} />;

  if (loading) {
    return <Skeleton variant="rect" height={500} width="100%" animation="wave" />;
  }

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} newBtn={hasPermission(PortalPermissions.USERS_MANAGE.value) && 'Novo Usuário'} />
      <Grid container spacing={1} style={{ minHeight: 'calc(100vh - 101px)' }}>
        <Grid item xs={12}>
          <DataTable
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{ rowsPerPageText: 'Linhas:', rangeSeparatorText: 'de' }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
            noDataComponent="Não existem dados para serem mostrados"
            columns={dtCl.columns}
            data={dtCl.data}
            title=""
          />
        </Grid>
        <Grid item xs={12} style={{ margin: '25px 0', textAlign: 'right' }}>
          <BackButton redirectTo="/settings" />
        </Grid>
      </Grid>
    </>
  );
};
