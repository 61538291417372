import { useFormik } from 'formik';
import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { omit } from 'lodash';
import { toast } from 'react-toastify';
import * as S from './styles';
import {
  Input,
  ButtonComponent as Button,
  CardOption,
} from '../../../../../../components';
import { systemTypes, AvailablesTypes } from '../../utils';
import { validationSchema } from './validationSchema';
import FockinkAPI from '../../../../../../middlewares/Api';
import { handleMessageError } from '../../../../../../helpers/handlerMessageError';

export function NewTelemetrySettingForm({
  handleGetTelemetry,
  handleCloseNewTelemetryModal,
}) {
  const formik = useFormik({
    initialValues: {
      friendlyName: '',
      variablePattern: '',
      system: '',
      operationId: '',
      trackingAvailable: false,
      alarmAvailable: false,
      type: '',
      status: 'A',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await FockinkAPI.post('/telemetryVariables/', values);

        handleGetTelemetry();
        formik.resetForm();
        handleCloseNewTelemetryModal();
        toast.success('Variável cadastrada');
      } catch (error) {
        handleMessageError(error);
      }
    },
  });

  const handleChangeMinThreshold = (e) => {
    const { value } = e.target;
    if (value === '') {
      const values = { ...formik.values };
      const newFormValues = omit(values, 'thresholdMin');

      formik.setValues({
        ...newFormValues,
      });
    } else {
      formik.setValues({
        ...formik.values,
        thresholdMin: value,
      });
    }
  };

  const handleChangeMaxThreshold = (e) => {
    const { value } = e.target;
    if (value === '') {
      const values = { ...formik.values };
      const newFormValues = omit(values, 'thresholdMax');
      formik.setValues({
        ...newFormValues,
      });
    } else {
      formik.setValues({
        ...formik.values,
        thresholdMax: value,
      });
    }
  };

  const handleChangeTrackinkValue = () => {
    formik.setValues({
      ...formik.values,
      trackingAvailable: !formik.values.trackingAvailable,
    });
  };

  const handleChangeAlarmAvailableValue = () => {
    formik.setValues({
      ...formik.values,
      alarmAvailable: !formik.values.alarmAvailable,
    });
  };

  return (
    <S.Wrapper>
      <form onSubmit={formik.handleSubmit}>
        {/* <span>Preencha o formulário para cadastro de nova configuração</span> */}

        <Input
          error={formik.errors.friendlyName}
          name="friendlyName"
          label="Nome amigável"
          onChange={formik.handleChange}
          variant="filled"
          rows={3}
          multiline
          value={formik.values.friendlyName}
        />

        <Input
          error={formik.errors.variablePattern}
          name="variablePattern"
          label="Padrão variável"
          onChange={formik.handleChange}
          variant="filled"
          value={formik.values.variablePattern}
        />

        <Input
          error={formik.errors.operationId}
          type="number"
          name="operationId"
          label="ID de operação"
          variant="filled"
          onChange={formik.handleChange}
          value={formik.values.operationId}
        />

        <div className="row">
          <FormControl variant="filled">
            <InputLabel>Sistema</InputLabel>
            <Select
              name="system"
              value={formik.values.system}
              onChange={formik.handleChange}
              label="system"
            >
              {systemTypes.map((system) => (
                <MenuItem key={system} value={system}>
                  {system}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.errors.system}</FormHelperText>
          </FormControl>

          <FormControl style={{ marginLeft: '0.5rem' }} variant="filled">
            <InputLabel>Tipo</InputLabel>
            <Select
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              label="system"
            >
              {AvailablesTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.errors.type}</FormHelperText>
          </FormControl>
        </div>

        <div className="row">
          <Input
            error={formik.errors.thresholdMin}
            type="number"
            name="thresholdMin"
            label="Threshold Mínimo"
            variant="filled"
            onChange={(e) => handleChangeMinThreshold(e)}
            value={formik.values.thresholdMin}
          />
          <Input
            error={formik.errors.thresholdMax}
            style={{ marginLeft: '0.5rem' }}
            type="number"
            name="thresholdMax"
            label="Threshold máximo"
            variant="filled"
            onChange={(e) => handleChangeMaxThreshold(e)}
            value={formik.values.thresholdMax}
          />
        </div>

        <div className="row">
          <CardOption
            checked={formik.values.trackingAvailable}
            onClick={handleChangeTrackinkValue}
          >
            Elegível a tracking
          </CardOption>

          <CardOption
            checked={formik.values.alarmAvailable}
            onClick={handleChangeAlarmAvailableValue}
          >
            Elegível a alarme
          </CardOption>
        </div>

        <div className="confirm-button">
          <Button type="submit">cadastrar</Button>
        </div>
      </form>
    </S.Wrapper>
  );
}
