import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

export const CardEquipament = styled.div`
  padding: 0.7rem;
  flex: 1;
  width: 100%;
  border-radius: 4px;
  width: 100%;
  max-width: ${(props) => props.size === 'medium' ? '820px' : null};
  height: 100%;;

  & + & {
    margin-left: 0.4rem;
  }

  @media (max-width: 760px) {
    &+& {
      margin: 0.6rem 0 0 0;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
  }
`;

export const InformationCard = styled.div``;

export const DivDates = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 760px) {
      flex-direction: column;
  }
`;

export const DateCard = styled.div`
  height: 4rem;
  padding: 0.5rem;
  border: 1px solid #cecece;
  border-radius: 4px;
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  box-shadow: 5px 5px 22px -2px rgba(0,0,0,0.1);

  &+& {
    margin-left: 0.4rem;
  }

  @media (max-width: 760px) {
    margin-top: 0.4rem;

    &+& {
      margin-left: 0;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const RowDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;

`;

export const DivInformations = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  @media (max-width: 1020px) {
    flex-direction: column;
  }
`;

export const DivSwitch = styled.div`
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1.5rem;
`;
