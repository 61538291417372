import React, { useState } from 'react';
import { TextField, Grid, MenuItem, Select, InputLabel, FormControl } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import BottomButtons from '../../../../components/Buttons/BottomButtons';
import FockinkAPI from '../../../../middlewares/Api';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { formatDate } from '../../../../helpers/Intl';

const TicketsForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [equips, setEquips] = useState();
  const [gnf, setGnf] = useState('');
  const [clientName, setClientName] = useState('');
  const [selectedEquipament, setSelectedEquipament] = React.useState([]);
  const [data, setData] = useState({
    date: new Date(),
  });
  const date = new Date();
  const handleSelectEquipament = (event) => {
    setSelectedEquipament(event.target.value);
    setGnf(equips.filter((res) => res.description === event.target.value).map((res) => res.gnf)[0].toString());
    setClientName(equips.filter((res) => res.description === event.target.value).map((res) => res.clientDescription)[0].toString());
    return selectedEquipament;
  };
  const breadcrumbs = {
    routes: [
      { name: 'Suporte', to: '/support' },
      { name: 'Cadastro de Tickets' },
    ],
    title: 'Cadastro de Tickets',
  };
  const useStyles = makeStyles({
    textAlign: {
      textAlign: 'right',
    },
    btnSpace: {
      marginLeft: '15px',
    },
    bottomButtons: {
      marginTop: '15px',
      borderTop: '1px solid #ccc',
      paddingTop: '10px !important',
    },
    root: {
      width: '100%',
      marginTop: 30,
    },
  });
  const classes = useStyles();
  const back = {
    name: 'Suporte',
    to: '/support',
  };
  const history = useHistory();
  React.useEffect(() => {
    setLoading(true);
    FockinkAPI.get('/dashboards/equipaments').then((response) => {
      setEquips(response.data.data.equipaments);
      setLoading(false);
    });
  }, []);

  const saveData = () => {
    if (props.match.params.id === 'new') {
      // const descriptionComplete =
      const dt = {
        subject: data.subject,
        description: `${data.description} \n\n--------------\n Dados técnicos Portal IoT: \n Identificação Fockink: ${selectedEquipament} \n Nome cliente: ${clientName} \n GNF: ${gnf}`,
        isServiceStopped: data.isServiceStopped,
      };
      FockinkAPI.post('/attendanceTecAssist', dt)
        .then(() => {
          toast.success('Dados salvos com sucesso');
          history.push('/support');
        })
        .catch(async (error) => {
          const { response } = error;

          if (response.status === 400) {
            toast.error(response.data.validation?.body.message || response.data.message);
          }
        });
      // FockinkAPI.get()
    }
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Breadcrumbs
        routes={breadcrumbs.routes}
        title={breadcrumbs.title}
      />
      {
    loading ? (
      <div className={classes.root}>
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
      </div>
    )
      :

      (
        <Grid container spacing={2}>
          <Grid style={{ marginBottom: '10px' }} container spacing={2}>
            <Grid item xs={12} lg={3}>
              <TextField
                variant="filled"
                name="data"
                InputProps={{
                  readOnly: true,
                }}
                value={date.toLocaleDateString('pt-BR')}
                onChange={handleChange}
                label="Data"
              />

            </Grid>
            <Grid item xs={12} lg={9}>
              <TextField
                variant="filled"
                name="subject"
                InputProps={{
                  readOnly: true,
                }}
                value={localStorage.name}
                onChange={handleChange}
                label="Aberto por"
              />

            </Grid>
          </Grid>
          <Grid style={{ marginBottom: '10px' }} container spacing={2}>
            <Grid item xs={12} lg={6}>
              <FormControl variant="filled">
                <InputLabel id="equipaments">Equipamentos</InputLabel>
                <Select
                  labelId="equipaments"
                  id="equipaments"
                  name="equipaments"
                  value={selectedEquipament}
                  label="equipaments"
                  onChange={handleSelectEquipament}
                >
                  {equips?.map((res) => (
                    <MenuItem key={res.id} value={res.description}>
                      {res.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl variant="filled">
                <InputLabel id="isServiceStopped">O serviço está parado?</InputLabel>
                <Select
                  labelId="isServiceStopped"
                  id="isServiceStopped"
                  name="isServiceStopped"
                  value={data?.isServiceStopped}
                  label="isServiceStopped"
                  onChange={handleChange}
                >
                  <MenuItem value>Sim</MenuItem>
                  <MenuItem value={false}>Não</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid style={{ marginBottom: '10px' }} container spacing={2}>
            <Grid item xs={12} lg={12}>
              <TextField
                variant="filled"
                name="subject"
                value={data?.subject}
                onChange={handleChange}
                label="Assunto"
              />

            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12}>
              <TextField
                variant="filled"
                name="description"
                multiline
                maxRows={5}
                minRows={5}
                value={data?.description}
                onChange={handleChange}
                label="Descrição"
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            className={`${classes.textAlign} ${classes.bottomButtons}`}
          >
            <BottomButtons saveData={saveData} backTo={back.to} />
          </Grid>
        </Grid>
      )
      }
    </>
  );
};

export default TicketsForm;
