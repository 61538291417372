/* eslint-disable no-bitwise */
import React from 'react';
import Chart from 'react-apexcharts';
import { normalizeErrorData } from '../../../../../helpers/normalizeErrorData';
import { number } from '../../../../../helpers/Intl';

export function ReportTemperatureGraph({
  categories,
  parameters,
  daysInMonth,
  date,
  showMovingAvg,
}) {
  const dashArray = () => {
    if (showMovingAvg) {
      return new Array(parameters.length * 2)
        .fill(0)
        .map((x, i) => (i % 2 === 0 ? 0 : 8));
    }
    return new Array(parameters.length).fill(0);
  };

  const lightenDarkenColor = (color, percent) => {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100, 10);
    G = parseInt((G * (100 + percent)) / 100, 10);
    B = parseInt((B * (100 + percent)) / 100, 10);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    const RR =
      R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG =
      G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB =
      B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);

    return `#${RR}${GG}${BB}`;
  };

  const formatCategories = categories.map((dataDay) => dataDay.day);

  const formatParameters = () => {
    const formatValues = [];
    parameters.forEach((parameter) => {
      const dataSeries = daysInMonth.map((day) => {
        if (parameter.datas && parameter.datas[day]) {
          const dataValue = normalizeErrorData(parameter.datas[day]);
          return dataValue;
        }
        return 0;
      });

      const transformParameterToSerieGraph = {
        name: `Pontos entre ${parameter.limitMin} e ${parameter.limitMax} °C`,
        data: dataSeries,
        type: 'line',
        color: parameter.color,
      };

      formatValues.push(transformParameterToSerieGraph);

      if (showMovingAvg) {
        const dataSeriesAvg = daysInMonth.map((day) => {
          if (parameter.movingAverage && parameter.movingAverage[day]) {
            const dataValue = normalizeErrorData(parameter.movingAverage[day]);
            return dataValue;
          }
          return 0;
        });

        const transformParameterToSerieGraphAvg = {
          name: `Média móvel dos pontos entre ${parameter.limitMin} e ${parameter.limitMax} °C`,
          data: dataSeriesAvg,
          type: 'line',
          color: lightenDarkenColor(parameter.color, -10),
        };

        formatValues.push(transformParameterToSerieGraphAvg);
      }
    });
    return formatValues;
  };

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      dashArray: dashArray(),
    },
    tooltip: {
      enabled: true,
      x: {
        show: true,
        formatter: (day) =>
          `Dia ${day} de ${date.toLocaleDateString('pt-br', {
            month: 'long',
          })}`,
      },
      y: {
        formatter: (legendData) => number(legendData),
      },
    },
    xaxis: {
      type: 'number',
      categories: formatCategories,
      title: {
        text: date.toLocaleDateString('pt-br', {
          year: 'numeric',
          month: 'long',
        }),
      },
    },
    yaxis: {
      type: 'string',
      title: {
        text: 'Total de pontos quentes',
      },
      labels: {
        formatter: (value) => value,
      },
    },
    responsive: [
      {
        breakpoint: 720,
        options: {
          xaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
  };

  return (
    <Chart
      type="line"
      height={350}
      series={formatParameters()}
      options={options}
    />
  );
}
