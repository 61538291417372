import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import '../../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Select, InputLabel, FormControl, InputAdornment } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid } from '@mui/material';
import { toast } from 'react-toastify';
import MomentUtils from '@date-io/moment';
import TextField from '@material-ui/core/TextField';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import parseFloatCustom from '../../../../../helpers/parseFloatCustom';
import BottomButtons from '../../../../../components/Buttons/BottomButtons';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import FockinkAPI from '../../../../../middlewares/Api';

const SoilChemicalAnalysis = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    status: 'A',
    equipamentId: '',
    lat: '',
    lon: '',
    sample: '',
    laboratory: '',
    techniciansName: '',
    producerCode: '',
    producerName: '',
    instructor: '',
    receivingDate: null,
    expirationDate: null,
    registry: '',
    clay: '',
    obs: '',
  });
  const [equipaments, setEquipaments] = useState();
  const irrigationEquipaments = equipaments?.filter((x) => x.operationId === 1);
  const breadcrumb = {
    routes: [
      { name: 'Configurações', to: '/settings' },
      {
        name: 'Análise Química do Solo',
        to: `/settings/soilAnalysis/${props.match.params.clid}`,
      },
      { name: 'Análise' },
    ],
  };
  const back = {
    name: 'Análise Química do Solo',
    to: `/settings/soilAnalysis/${props.match.params.clid}`,
  };
  const useStyles = makeStyles({
    textAlign: {
      textAlign: 'right',
    },
    btnSpace: {
      marginLeft: '15px',
    },
    bottomButtons: {
      marginTop: '15px',
      borderTop: '1px solid #ccc',
      paddingTop: '10px !important',
    },
  });
  const classes = useStyles();
  moment.locale('pt-br');

  React.useEffect(() => {
    if (props.match.params.id === 'new') {
      setLoading(false);
    } else {
      setLoading(true);
      FockinkAPI.get(`/soilChemicalAnalysis/${props.match.params.id}`).then(
        (response) => {
          setData({
            ...response.data.data,
            receivingDate: moment
              .utc(response.data.data.receivingDate)
              .format('YYYY-MM-DD'),
            expirationDate: moment
              .utc(response.data.data.expirationDate)
              .format('YYYY-MM-DD'),
          });
          setLoading(false);
        },
      );
    }
  }, [props.match.params.id]);

  React.useEffect(() => {
    FockinkAPI.get(
      `/dashboards/equipaments/?accountId=${props.match.params.clid}`,
    ).then((response) => {
      if (props.match.params.id === 'new') {
        setLoading(false);
        setEquipaments(response.data.data.equipaments);
        setData({
          ...data,
          accountId: response.data.data.account.id,
          organizationId: response.data.data.account.organizationId,
        });
      } else {
        setEquipaments(response.data.data.equipaments);
      }
    });
  }, []);

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value || event.target.checked,
    });
  };

  const handleChangeEquipament = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value || event.target.checked,
      lat: irrigationEquipaments?.filter(
        (x) => x.id === event.target.value,
      )[0].settings.location.lat,
      lon: irrigationEquipaments?.filter(
        (x) => x.id === event.target.value,
      )[0].settings.location.lon,
    });
  };

  const saveSoilChemicalAnalysis = async () => {
    const soilAnalysisId = props.match.params.id;
    const dataToSend = {
      accountId: data.accountId,
      organizationId: data.organizationId,
      equipamentId: data.equipamentId,
      sample: data.sample,
      lat: parseFloatCustom(data.lat),
      lon: parseFloatCustom(data.lon),
      laboratory: data.laboratory,
      techniciansName: data.techniciansName,
      producerCode: data.producerCode,
      producerName: data.producerName,
      instructor: data.instructor,
      receivingDate: data.receivingDate,
      expirationDate: data.expirationDate,
      registry: data.registry,
      depth: parseFloatCustom(data.depth),
      clay: parseFloatCustom(data.clay),
      classCode: parseFloatCustom(data.classCode),
      phH2o: parseFloatCustom(data.phH2o),
      phCl: parseFloatCustom(data.phCl),
      smp: parseFloatCustom(data.smp),
      p: parseFloatCustom(data.p),
      pRem: parseFloatCustom(data.pRem),
      k: parseFloatCustom(data.k),
      mo: parseFloatCustom(data.mo),
      al: parseFloatCustom(data.al),
      ca: parseFloatCustom(data.ca),
      mg: parseFloatCustom(data.mg),
      ctcPh7: parseFloatCustom(data.ctcPh7),
      hAl: parseFloatCustom(data.hAl),
      baseSaturation: parseFloatCustom(data.baseSaturation),
      aluminumSaturation: parseFloatCustom(data.aluminumSaturation),
      s: parseFloatCustom(data.s),
      zn: parseFloatCustom(data.zn),
      cu: parseFloatCustom(data.cu),
      b: parseFloatCustom(data.b),
      mn: parseFloatCustom(data.mn),
      fe: parseFloatCustom(data.fe),
      cl: parseFloatCustom(data.cl),
      na: parseFloatCustom(data.na),
      obs: data.obs,
    };
    Object.keys(dataToSend).forEach((k) => {
      if (!dataToSend[k]) {
        delete dataToSend[k];
      }
    });
    if (soilAnalysisId === 'new') {
      // Request
      FockinkAPI.post('/soilChemicalAnalysis', dataToSend)
        .then(() => {
          toast.success('Dados salvos com sucesso');
          history.push(`/settings/soilAnalysis/${props.match.params.clid}`);
        })
        .catch(async (error) => {
          const { response } = error;

          if (response.status === 400) {
            toast.error(response.data.validation.body.message);
          } else {
            toast.error(response.data.message);
          }
        });
    } else {
      // Request
      FockinkAPI.put(`/soilChemicalAnalysis/${soilAnalysisId}`, dataToSend)
        .then(() => {
          toast.success('Dados salvos com sucesso');
          history.push(`/settings/soilAnalysis/${props.match.params.clid}`);
        })
        .catch(async (error) => {
          const { response } = error;

          if (response.status === 400) {
            toast.error(response.data.validation.body.message);
          } else {
            toast.error(response.data.message);
          }
        });
    }
  };

  if (loading) {
    return (
      <Skeleton variant="rect" height={300} width="100%" animation="wave" />
    );
  }

  return (
    <div>
      <Breadcrumbs routes={breadcrumb.routes} />
      <h2 style={{ marginTop: '20px' }}>Equipamento</h2>
      <Grid container spacing={2}>
        <Grid item lg={3} xs={12}>
          <FormControl variant="filled">
            <InputLabel id="equipamentId">Equipamento</InputLabel>
            <Select
              labelId="equipamentId"
              id="equipamentId"
              name="equipamentId"
              value={data.equipamentId}
              label="Sistema"
              onChange={handleChangeEquipament}
            >
              {irrigationEquipaments?.map((equip) => (
                <MenuItem value={equip.id}>{equip.description}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <h2 style={{ marginTop: '20px' }}>Dados Iniciais</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <TextField
            variant="filled"
            name="sample"
            value={data.sample || ''}
            onChange={handleChange}
            label="Amostra"
          />
        </Grid>
        <Grid item lg={2} xs={12}>
          <TextField
            variant="filled"
            name="lat"
            type="number"
            value={data.lat || ''}
            onChange={handleChange}
            label="Latitude"
          />
        </Grid>
        <Grid item lg={2} xs={12}>
          <TextField
            variant="filled"
            name="lon"
            type="number"
            value={data.lon || ''}
            onChange={handleChange}
            label="Longitude"
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextField
            variant="filled"
            name="laboratory"
            value={data.laboratory || ''}
            onChange={handleChange}
            label="Laboratório"
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextField
            variant="filled"
            name="techniciansName"
            value={data.techniciansName || ''}
            onChange={handleChange}
            label="Nome do técnico"
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField
            variant="filled"
            name="producerCode"
            value={data.producerCode || ''}
            onChange={handleChange}
            label="Código do produtor"
          />
        </Grid>
        <Grid item xs={12} lg={9}>
          <TextField
            variant="filled"
            name="producerName"
            value={data.producerName || ''}
            onChange={handleChange}
            label="Nome do produtor"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            variant="filled"
            name="instructor"
            value={data.instructor || ''}
            onChange={handleChange}
            label="Instrutor"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            variant="filled"
            name="registry"
            value={data.registry || ''}
            onChange={handleChange}
            label="Registro"
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}
            style={{ width: '100%' }}
          >
            <KeyboardDatePicker
              variant="filled"
              margin="normal"
              label="Data de recebimento"
              format="DD/MM/YYYY"
              value={data.receivingDate}
              name="receivingDate"
              autoOk
              onChange={(date) => {
                setData({
                  ...data,
                  receivingDate: date.format('YYYY-MM-DD'),
                });
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} lg={3}>
          <MuiPickersUtilsProvider
            libInstance={moment}
            utils={MomentUtils}
            style={{ width: '100%' }}
          >
            <KeyboardDatePicker
              variant="filled"
              margin="normal"
              label="Data de expedição"
              format="DD/MM/YYYY"
              value={data.expirationDate}
              name="expirationDate"
              autoOk
              onChange={(date) => {
                setData({
                  ...data,
                  expirationDate: date.format('YYYY-MM-DD'),
                });
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <h2 style={{ marginTop: '20px' }}>Dados Análise Química</h2>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="depth"
            value={data.depth || ''}
            onChange={handleChange}
            type="number"
            label="Profundidade"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  cm
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="clay"
            value={data.clay || ''}
            onChange={handleChange}
            type="number"
            label="Argila"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="classCode"
            value={data.classCode || ''}
            onChange={handleChange}
            type="number"
            label="Classe"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="phH2o"
            value={data.phH2o || ''}
            onChange={handleChange}
            type="number"
            label="ph H2o"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="phCl"
            value={data.phCl || ''}
            onChange={handleChange}
            type="number"
            label="ph Cl"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="smp"
            value={data.smp || ''}
            onChange={handleChange}
            type="number"
            label="smp"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="p"
            value={data.p || ''}
            onChange={handleChange}
            type="number"
            label="P"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="pRem"
            value={data.pRem || ''}
            onChange={handleChange}
            type="number"
            label="P Rem"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="k"
            value={data.k || ''}
            onChange={handleChange}
            type="number"
            label="K"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="mo"
            value={data.mo || ''}
            onChange={handleChange}
            type="number"
            label="MO"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="al"
            value={data.al || ''}
            onChange={handleChange}
            type="number"
            label="Al"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="ca"
            value={data.ca || ''}
            onChange={handleChange}
            type="number"
            label="Ca"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="mg"
            value={data.mg || ''}
            onChange={handleChange}
            type="number"
            label="Mg"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="ctcPh7"
            value={data.ctcPh7 || ''}
            onChange={handleChange}
            type="number"
            label="CTC pH7"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="hAl"
            value={data.hAl || ''}
            onChange={handleChange}
            type="number"
            label="H+Al"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="baseSaturation"
            value={data.baseSaturation || ''}
            onChange={handleChange}
            type="number"
            label="Saturação Base"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="aluminumSaturation"
            value={data.aluminumSaturation || ''}
            onChange={handleChange}
            type="number"
            label="Saturação Al"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="s"
            value={data.s || ''}
            onChange={handleChange}
            type="number"
            label="S"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="zn"
            value={data.zn || ''}
            onChange={handleChange}
            type="number"
            label="Zn"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="cu"
            value={data.cu || ''}
            onChange={handleChange}
            type="number"
            label="Cu"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="b"
            value={data.b || ''}
            onChange={handleChange}
            type="number"
            label="B"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="mn"
            value={data.mn || ''}
            onChange={handleChange}
            type="number"
            label="Mn"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="fe"
            value={data.fe || ''}
            onChange={handleChange}
            type="number"
            label="Fe"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="cl"
            value={data.cl || ''}
            onChange={handleChange}
            type="number"
            label="Cl"
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            variant="filled"
            name="na"
            value={data.na || ''}
            onChange={handleChange}
            type="number"
            label="Na"
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextField
            variant="filled"
            name="obs"
            multiline
            rows={4}
            value={data.obs || ''}
            onChange={handleChange}
            label="Observação"
          />
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        className={`${classes.textAlign} ${classes.bottomButtons}`}
      >
        <BottomButtons saveData={saveSoilChemicalAnalysis} backTo={back.to} />
      </Grid>
    </div>
  );
};

export default SoilChemicalAnalysis;
