import React from 'react';
import {
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Grid,
  TextField,
} from '@material-ui/core';
import CardTitle from '../../../../../components/CardTitle';
import { superiorViewWidth } from '../Cards/ThermometrySVG';

const grainType = {
  Milho: { icon: 'milho' },
  Soja: { icon: 'soja' },
  'Trigo Duro': { icon: 'trigo' },
  'Trigo Mole': { icon: 'trigo' },
  Arroz: { icon: 'arroz' },
  Aveia: { icon: 'aveia' },
  Sorgo: { icon: 'undefined' }, // TODO
  Cevada: { icon: 'cevada' },
  Canola: { icon: 'canola' },
  Girassol: { icon: 'girassol' },
  Cacau: { icon: 'cacau' },
  'Algodão em Sem.': { icon: 'algodao' },
  Feijão: { icon: 'feijao' },
  Café: { icon: 'cafe' },
  Pipoca: { icon: 'pipoca' },
  Triticale: { icon: 'triticale' },
  batata: { icon: 'batata' },
  'cana-de-açúcar': { icon: 'cana' },
  mamão: { icon: 'mamao' },
  Vazio: { icon: 'undefined' },
};

function selectIconType(strategy) {
  if (strategy && strategy.grain && grainType[strategy.grain]) {
    if (strategy.grain === 'Vazio') {
      return null;
    }
    return (
      <img
        src={`/images/grainType/${grainType[strategy.grain].icon}.svg`}
        style={{
          width: '100%',
          maxWidth: '36px',
          maxHeight: '36px',
          marginLeft: '8px',
        }}
        alt="icon"
      />
    );
  }
  return (
    <img
      src="/images/grainType/undefined.svg"
      style={{
        width: '100%',
        maxWidth: '36px',
        maxHeight: '36px',
        marginLeft: '8px',
      }}
      alt="icon"
    />
  );
}

export default (props) => (
  <Grid item xs={12} sm={12} md={4} lg={3} xl={2}>
    <CardTitle pa="0">
      {!props.setStrategy || props.loading || props.strategys.length < 2 ? (
        (props.strategys || []).length > 0 && (
        <div style={{ width: 'calc(100% - 44px)' }}>
          <TextField
            variant="outlined"
            label="Subdivisão"
            InputProps={{
              readOnly: true,
            }}
            defaultValue={`#${props.strategys[0].sub} - ${props.strategys[0].grain}`}
          />
        </div>
        )
      ) : (
        <div style={{ width: 'calc(100% - 44px)' }}>
          <FormControl style={{ textAlign: 'left' }} variant="filled">
            <InputLabel>Subdivisão</InputLabel>
            <Select
              value={props.strategy}
              onChange={(value) => {
                props.setStrategy(value.target.value);
                console.log('funcionou superiorViewWidth');
              }}
              name="operationId"
            >
              <MenuItem key="all" value="all">Todas</MenuItem>
              {props.strategys.map((v) => (
                <MenuItem key={v.sub} value={v.sub}>
                  {`#${v.sub} - ${v.grain}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <div style={{ width: '44px', visibility: props.strategy === 'all' ? 'hidden' : 'visible' }}>
        {selectIconType(props.selectedStrategy)}
      </div>
    </CardTitle>
  </Grid>
);
