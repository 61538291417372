import styled from 'styled-components';

export const Wrapper = styled.div`
flex: 1;

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.success-msg {
  font-weight: bold;
  color: #62BF28;
}

form {
  width: 100%;

  .error {
    color: #c53030;
    font-weight: 500;
  }

  .date-picker {
    width: 100%;
    max-width: 720px;
  }

  .input-angle {
    width: 49.5%;
  }

  .angles-field {
    margin: 0.6rem 0;
    display: flex;
    justify-content: space-between;
  }

  .column {
    display: flex;
    flex-direction: column;
  }

  .button-confirm {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
  }
}

  @media screen and (max-width: 1282px) {
    margin-top: 2rem;
  }

`;
