import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyle = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    maxWidth: '7rem',
    height: '100%',
    marginLeft: '2rem',
    '& span': {
      fontSize: '0.8rem',
    },
    '& strong': {
      textTransform: 'uppercase',
    },
  },
});

export function InformationCard({ data, icon: Icon }) {
  const classes = useStyle();
  const { title, subtitle } = data;

  return (
    <div className={classes.wrapper}>
      <strong>{title}</strong>
      <Icon size={33} />
      <span>{subtitle}</span>
    </div>
  );
}
