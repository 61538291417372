import * as yup from 'yup';
import { transformDate } from '../transformDate';

export const validationSchema = yup.object().shape({
  harvestDate: yup
    .date()
    .transform(transformDate)
    .required('Obrigatório')
    .max(new Date(), 'Data selecionada não é permitida'),
});
