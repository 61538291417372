import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button, InputLabel, MenuItem, Select, Typography, Paper, ButtonGroup } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { Alert } from '@mui/material';
import { Tabs } from 'antd';
import DataTable from 'react-data-table-component';
import jwtDecode from 'jwt-decode';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Chart from 'react-apexcharts';
import GetAppIcon from '@material-ui/icons/GetApp';
import XLSX from 'xlsx-js-style'; // Exportar em Excel
import pdfMake from 'pdfmake/build/pdfmake'; // exporta em pdf
import pdfFonts from 'pdfmake/build/vfs_fonts'; // exporta em pdf
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ptBR } from 'date-fns/esm/locale';
import { toast } from 'react-toastify';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import { PdfFooter, PdfHeader } from '../../../../components/Reports/pdf';
import { dateForExport, dateNow } from '../../../../helpers/AuxInfo';
import { headerCell, dataCell } from '../../../../components/AuxExcelExport/index'; // Exportar em Excel
import FockinkAPI from '../../../../middlewares/Api';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { currency, number, secondsToHm } from '../../../../helpers/Intl';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default () => {
  const [loading, setLoading] = React.useState(true);
  const [loadingTable, setLoadingTable] = React.useState(false);
  const [units, setUnits] = useState();
  const [breadcrumbs, setBreadcrumbs] = React.useState({ routes: [] });
  const [equipamentsWithoutUnit, setEquipamentsWithoutUnit] = useState();
  const [selectedUnit, setSelectedUnit] = useState();
  const [equips, setEquips] = useState();
  const [selectedEquips, setSelectedEquips] = useState([]);
  const token = localStorage.getItem('@FockinkAPP:token');
  const { unit } = jwtDecode(token);
  const [data, setData] = React.useState();
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [graphData, setGraphData] = useState();
  const urlParams = new URLSearchParams(window.location.search);
  const url = urlParams.get('accountId');
  const [selectedDate, setSelectedDate] = useState({
    dateStart: new Date(),
    dateFinish: new Date(),
  });
  const { TabPane } = Tabs;
  const breadcrumb = {
    title: 'Consumo de motores',
    routes: [
      { to: '/reports', name: 'Relatórios' },
      { name: 'Consumo de motores' },
    ],
    secondRoute: [
      { name: 'Clientes', to: '/clientsAdm/' },
      { name: 'Relatórios', to: `/reportsAdm${url}&op=all` },
      { name: 'Relatório de Aeração' },
    ],
  };
  const [csv, setCsv] = useState();
  const [fileName, setFileName] = useState();
  const [dataForExcel, setDataForExcel] = useState([]);

  React.useEffect(() => {
    if (csv) {
      clickDownload();
    }
    setCsv(undefined);
  }, [csv]);

  function clickDownload() {
    document.getElementById('link').click();
  }

  React.useEffect(() => {
    setBreadcrumbs({
      title: 'Consumo de motores',
      routes: [
        { to: '/reports', name: 'Relatórios' },
        { name: 'Consumo de motores' },
      ],
      secondRoute: [
        { name: 'Clientes', to: '/clientsAdm/' },
        { name: 'Relatórios', to: `/reportsAdm?accountId=${url}&op=all` },
        { name: 'Consumo de motores' },
      ] });
    FockinkAPI.get(`/units?withEquipaments=true&accountId=${url}`).then((response) => {
      setUnits(response.data.data);
      setEquipamentsWithoutUnit(response.data.equipamentsWithoutUnit);
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    setSelectedEquips([]);
    if (selectedUnit === 'ndef') {
      setEquips(equipamentsWithoutUnit.filter((x) => x.systemId === 2));
    } else {
      const unitFound = (units || []).find((f) => f.id === selectedUnit);
      if (unitFound) {
        setEquips(unitFound.equipaments.filter((x) => x.systemId === 2));
      }
      // FockinkAPI.get(`/units/${selectedUnit}`).then((response) => {
      //   setEquips(response.data.data.equipaments.filter((x) => x.operationId === 2));
      // });
    }
  }, [selectedUnit]);

  const getData = () => {
    if (selectedEquips.equipaments?.length > 0) {
      setLoadingTable(true);
      FockinkAPI.get('/reports/thermometry/motorsEnergyConsumption', {
        params: {
          equipamentIds: selectedEquips.equipaments,
          dateStart: moment(selectedDate.dateStart).format('YYYY-MM-DD'),
          dateFinish: moment(selectedDate.dateFinish).format('YYYY-MM-DD'),
        },
      }).then((response) => {
        setData(response.data.data);
        setLoadingTable(false);
      });
    } else {
      toast.error('Escolha pelo menos 1 equipamento');
    }
  };

  function getFilters() {
    const filters = [];
    const equipaments = [];

    // UNIDADE
    units !== undefined && (
      units.forEach((item) => {
        if (item.id === selectedUnit) {
        // console.log('testando map', item.description);
          filters.push({
            unidade: item.description,
          });
        }
      })
    );

    // EQUIPAMENTOS
    equips !== undefined && (
      equips.forEach((item) => {
        selectedEquips.equipaments.forEach((equipCode) => {
          if (item.id === equipCode) {
            equipaments.push(
              item.description,
            );
          }
        });
      })
    );

    equips !== undefined && (
      filters.push({
        equipaments,
      })
    );

    // DATA INICIAL SELECIONADA
    filters.push({
      dateStart: selectedDate.dateStart.toISOString().substring(0, 10).replace(/^(\d{4})-(\d{1,2})-(\d{1,2})$/, '$3/$2/$1'),
    });

    // DATA FINAL SELECIONADA
    filters.push({
      dateFinish: selectedDate.dateFinish.toISOString().substring(0, 10).replace(/^(\d{4})-(\d{1,2})-(\d{1,2})$/, '$3/$2/$1'),
    });

    // retorno da funcao
    return filters;
  }

  useEffect(() => {
    const column = [
      {
        name: 'Motor',
        selector: 'idMotor',
        cell: (row) => `Motor ${row.idMotor}`,
      },
      {
        name: 'Unidade',
        cell: (row) => row.clientDescription || row.description,
      },
      {
        name: 'Subárea',
        selector: 'sub',
      },
      {
        name: 'Potência',
        selector: 'hp',
        cell: (row) => `${row.hp}CV`,
      },
      {
        name: 'Tempo',
        selector: 'aerationDaySecTotal',
        cell: (row) => secondsToHm(row.aerationDaySecTotal),
      },
      {
        name: 'Consumo',
        selector: 'kw',
        cell: (row) => `${number(row.kw)}kWh`,
      },
      {
        name: 'Consumo total',
        selector: 'totalKwh',
        cell: (row) => `${number(row.totalKwh)}kWh`,
      },
      {
        name: 'Valor de consumo',
        selector: 'totalCost',
        cell: (row) => currency(row.totalCost),
      },
    ];
    setDtCl({ columns: column, data: data?.tableData });
  }, [data]);

  useEffect(() => {
  // function excelMontagem() {
    let hedrExcel = [];
    let dtExcel = [];
    hedrExcel = [];
    const filters = getFilters();

    hedrExcel = [
      headerCell({ value: 'Motor' }),
      headerCell({ value: 'Unidade' }),
      headerCell({ value: 'Subárea' }),
      headerCell({ value: 'Potência' }),
      headerCell({ value: 'Tempo' }),
      headerCell({ value: 'Consumo' }),
      headerCell({ value: 'Consumo total' }),
      headerCell({ value: 'Consumo em R$' }),
    ];

    dtExcel = [];

    if (dtCl.data !== undefined) {
      dtCl.data.forEach((item) => {
        dtExcel.push([
          dataCell({ value: `Motor ${item.idMotor}` }),
          dataCell({ value: item.clientDescription || item.description }),
          dataCell({ value: item.sub.toString() }),
          dataCell({ value: `${item.hp.toString()}CV` }),
          dataCell({ value: secondsToHm(item.aerationDaySecTotal) }),
          dataCell({ value: `${number(item.kw)}kWh` }),
          dataCell({ value: `${number(item.totalKwh)}kWh` }),
          dataCell({ value: currency(item.totalCost) }),
        ]);
      });
    }

    setDataForExcel([
      [headerCell({ value: 'Consumo de Motores' })],
      [headerCell({ value: '' })],
      [
        headerCell({ value: 'Unidade' }),
        dataCell({ value: `${filters[0]?.unidade}` }),
      ],
      [
        headerCell({ value: 'Equipamentos' }),
        // filters[1]?.equipaments?.map((item) => dataCell({ value: item })),
        dataCell({ value: `${funcEquip()}` }),
      ],
      [
        headerCell({ value: 'Data Inicial' }),
        dataCell({ value: `${filters[2]?.dateStart}` }),
      ],
      [
        headerCell({ value: 'Data Final' }),
        dataCell({ value: `${filters[3]?.dateFinish}` }),
      ],
      [headerCell({ value: '' })],

      hedrExcel.map((item) => item),
      ...dtExcel,
      [headerCell({ value: '' })],
      [
        headerCell({ value: 'Data de Exportação' }),
        dataCell({ value: dateNow() }),
      ],
    ]);
  }, [dtCl.data]);

  function funcEquip() {
    const filters = getFilters();
    // const resultadoMap = '';

    if (filters !== undefined) {
      const resultadoMap = filters[1]?.equipaments?.map((item) => item);
      return resultadoMap;
    }
  }

  // function funcaoPush() {
  //   const filters = getFilters();
  //   [
  //     [filters[0].unidade],
  //     [filters[1].equipaments.map((item) => item)],
  //     [filters[2].dateStart],
  //     [filters[3].dateFinish],
  //   ];
  // }

  function generateCSV() {
    let csvFile = 'data:text/csv;charset=utf-8,\uFEFF';
    const csvArray = [['Consumo de Motores']];
    const filters = getFilters();

    csvArray.push(
      [
        'Unidade',
        filters[0].unidade,
      ],
      [
        'Equipamentos',
        filters[1].equipaments.map((item) => item),
      ],
      [
        'Data Inicial',
        filters[2].dateStart,
      ],
      [
        'Data Final',
        filters[3].dateFinish,
      ],
    );
    csvArray.push(
      [
        'Motor',
        'Unidade',
        'Subárea',
        'Potência',
        'Tempo',
        'Consumo',
        'Consumo Total',
        'Consumo em R$',
      ],

    );
    dtCl.data.map((item) => (csvArray.push(
      [
        `Motor ${item.idMotor}`,
        item.clientDescription || item.description,
        item.sub,
        `${item.hp}CV`,
        secondsToHm(item.aerationDaySecTotal),
        `${number(item.kw)}kWh`,
        `${number(item.totalKwh)}kWh`,
        currency(item.totalCost),
      ],
    )));

    csvArray.push([]);
    csvArray.push([
      'Data de Exportação',
      dateNow(),
    ]);

    csvArray.forEach((line) => {
      csvFile += line.join(';');
      csvFile += '\n';
    });
    const encodedUri = encodeURI(csvFile);
    setFileName(`report-consumo-de-motores-${dateForExport()}`);
    setCsv(encodedUri);
  }

  React.useEffect(() => {
    const stateInt = {
      series: data?.chartData?.series,
      options: {
        chart: {
          toolbar: {
            show: false,
          },
          type: 'bar',
        },
        stroke: {
          curve: 'smooth',
        },
        yaxis: [
          {
            max: Math.max(...(data?.chartData?.series[0].data || [])),
            title: {
              text: 'Consumo',
            },
            labels: {
              formatter(value) {
                return `${number(value)}`;
              },
            },
          },
          {
            max: Math.max(...(data?.chartData?.series[1].data || [])),
            title: {
              text: 'Horas',
            },
            opposite: true,
            labels: {
              formatter(value) {
                return secondsToHm(value);
              },
            },
          },
        ],

        xaxis: {
          categories: data?.chartData?.categories,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          followCursor: true,
          y: {
            formatter(y, x) {
              if (typeof y !== 'undefined') {
                if (x.seriesIndex === 0) return `${y.toFixed(2)}kWh`;
                if (x.seriesIndex === 1) return secondsToHm(y);
              }
              return y;
            },
          },
        },

      },
    };
    setGraphData(stateInt);
  }, [data]);

  function handleExportExcel() {
    // excelMontagem();
    const wb = XLSX.utils.book_new(); // book_new novo arquivo em memória
    const ws = XLSX.utils.aoa_to_sheet(dataForExcel);
    // json_to_sheet(data); //sheet é a aba do excel
    XLSX.utils.book_append_sheet(wb, ws, 'ConsumoDeMotores'); // vincula o sheet ao arquivo
    XLSX.writeFile(wb, `report-consumo-de-motores-${dateForExport()}.xlsx`); // download do arquivo
  }

  const handleExportPDF = () => {
    const detailedEngineConsumption = dtCl.data.map((item) => (
      [
        `Motor ${item.idMotor}`,
        item.clientDescription || item.description,
        item.sub,
        item.hp,
        secondsToHm(item.aerationDaySecTotal),
        `${number(item.kw)}kWh`,
        `${number(item.totalKwh)}kWh`,
        currency(item.totalCost),
      ]
    ));

    // const pdfHeader = [
    //   {
    //     text: 'Consumo de Motores',
    //     fontSize: 14,
    //     bold: true,
    //     margin: [15, 20, 0, 45],
    //   },
    // ];

    // const testador = filters[1]?.equipaments?.map((item) => (
    //   [
    //     item,
    //   ]
    // ));

    const filters = getFilters();

    const pdfContent = [

      {
        text: 'CONSUMO DE MOTORES',
        fontSize: 14,
        bold: true,
        alignment: 'center',
        margin: [0, 20, 0, 20],
      },

      {
        fontSize: 10,
        margin: [0, 0, 0, 10],
        table: {
          headerRows: 0,
          body: [
            [
              {
                text: 'Unidade',
                bold: true,
              },
              filters[0]?.unidade,
            ],
            [
              {
                text: 'Equipamentos',
                bold: true,
              },
              funcEquip(),
            ],
            [
              {
                text: 'Data Inicial',
                bold: true,
              },
              filters[2]?.dateStart,
            ],
            [
              {
                text: 'Data Final',
                bold: true,
              },
              filters[3]?.dateFinish,
            ],
            // ...testador,
          ],
        },
        layout: 'lightHorizontalLines',
      },

      {
        style: 'tableExample',
        fontSize: 10,
        table: {
          headerRows: 1,
          body: [
            [
              {
                text: 'Motor',
                bold: true,
              },
              {
                text: 'Unidade',
                bold: true,
              },
              {
                text: 'Subárea',
                bold: true,
              },
              {
                text: 'Potência',
                bold: true,
              },
              {
                text: 'Tempo',
                bold: true,
              },
              {
                text: 'Consumo',
                bold: true,
              },
              {
                text: 'Consumo Total',
                bold: true,
              },
              {
                text: 'Consumo em R$',
                bold: true,
              },
            ],
            ...detailedEngineConsumption,
          ],
        },
        layout: 'lightHorizontalLines',
      },
    ];
    // const pdfFooter = [
    //   {
    //     text: `Exportado dia: ${dateNow()}`,
    //     fontSize: 10,
    //     bold: false,
    //     margin: [15, 20, 0, 45],
    //   },
    // ];

    // const filters = getFilters();

    const pdfDefinitions = {
      pageSize: 'A4',
      pageMargins: [15, 50, 15, 40],
      header: PdfHeader(),
      content: [pdfContent],
      footer: PdfFooter(),
    };

    pdfMake.createPdf(pdfDefinitions).download(`report-consumo-de-motores-${dateForExport()}`);
  };

  /*   const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value || event.target.checked,
    });
  }; */

  const handleChangeEquips = (event) => {
    setSelectedEquips({ ...selectedEquips, equipaments: event.target.value });
  };

  return (
    <>
      {
        !loading ? (
          <>
            <Breadcrumbs routes={localStorage.profile === 'admin' && url ? breadcrumbs.secondRoute : breadcrumbs.routes} title={breadcrumbs.title} />
            <Grid container spacing={2}>
              <Paper style={{ width: '100%', padding: 10 }}>
                <Grid container spacing={2}>
                  <Grid item lg={3} xs={12}>
                    <FormControl variant="filled">
                      <InputLabel>{unit?.plural || 'Unidades'}</InputLabel>
                      <Select
                        value={selectedUnit}
                        onChange={(e) => setSelectedUnit(e.target.value)}
                        name=""
                      >
                        <MenuItem value="ndef">{`Equipamentos sem ${unit?.singular || 'unidade'}`}</MenuItem>
                        {
                        units?.map((x) => (
                          <MenuItem value={x.id}>{x.description}</MenuItem>
                        ))
                      }
                      </Select>
                    </FormControl>

                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormControl variant="filled">
                      <InputLabel>Equipamentos</InputLabel>
                      <Select
                        disabled={!equips}
                        multiple
                        value={selectedEquips.equipaments || []}
                        onChange={handleChangeEquips}
                        name="plantId"
                        renderValue={(selected) =>
                          selected.length === 0
                            ? 'Nenhum equipamento selecionado'
                            : `Selecionado: ${selected.length} de ${equips?.length}`
                  }
                      >
                        {equips?.map((m) => (
                          <MenuItem key={m.description} value={m.id}>
                            <Checkbox
                              checked={
                          (selectedEquips.equipaments || []).indexOf(m.id) > -1
                        }
                            />
                            <ListItemText primary={m.clientDescription || m.description} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    lg={3}
                  >
                    <FormControl>
                      <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          variant="dialog"
                          label="Data inicial"
                          format="dd/MM/yyyy"
                          value={selectedDate.dateStart}
                          onChange={(e) => setSelectedDate({
                            ...selectedDate, dateStart: e,
                          })}
                          style={{ marginTop: 8 }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    lg={3}
                  >
                    <FormControl>
                      <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          margin="normal"
                          variant="dialog"
                          label="Data Final"
                          format="dd/MM/yyyy"
                          value={selectedDate.dateFinish}
                          onChange={(e) => setSelectedDate({
                            ...selectedDate, dateFinish: e,
                          })}
                          style={{ marginTop: 8 }}
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </Grid>
                  <Grid item lg={2} xs={12} style={{ marginLeft: 'auto', marginRight: 0 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginTop: 10, marginLeft: 'auto', marginRight: 0 }}
                      onClick={() => getData()}
                    >
                      Gerar Relatório
                    </Button>
                  </Grid>
                </Grid>
              </Paper>

              {
                data &&
                data.errors &&
                data.errors.length > 0 && (
                  <Grid item lg={12} xs={12}>
                    <Alert severity="warning">
                      <ul style={{ listStyle: 'none' }}>
                        {data.errors.map((e) => (
                          <li>{e}</li>
                        ))}
                      </ul>
                    </Alert>
                  </Grid>
                )
              }
              {
               loadingTable && (
               <Skeleton variant="rect" height={300} width="100%" animation="wave" />
               )
             }
              {
              data &&
              data.tableData &&
              data.tableData.length > 0 && (
                <Paper style={{ width: '100%', padding: 10, marginTop: 10 }}>
                  <Grid item lg={12} xs={12} style={{ marginLeft: 'auto', marginRight: 0 }}>

                    <div style={{ marginBottom: 0, textAlign: 'right' }}>
                      <ButtonGroup variant="contained" color="primary">
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            generateCSV();
                          }}
                        >
                          <GetAppIcon />
                          CSV
                        </Button>
                        <a id="link" download={`${fileName}.csv`} href={csv} style={{ display: 'none' }}>
                          download
                        </a>
                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            handleExportExcel();
                          }}
                        >
                          <GetAppIcon />
                          XLSX
                        </Button>

                        <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            handleExportPDF();
                          }}
                        >
                          <GetAppIcon />
                          PDF
                        </Button>
                        {/* <Button
                          variant="contained"
                          color="primary"
                          fullWidth
                          onClick={() => {
                            // excelMontagem();
                            // getFilters.map((item) =>
                            // console.log(getFilters().dateStart);
                          }
                        }
                        >
                          <GetAppIcon />
                          TESTE
                        </Button> */}
                      </ButtonGroup>
                    </div>

                    <Tabs defaultActiveKey="1">
                      <TabPane tab="Tabela" key="1">
                        <Grid item xs={12}>
                          <DataTable
                            pagination
                            paginationPerPage={15}
                            paginationComponentOptions={{
                              rowsPerPageText: 'Linhas:',
                              rangeSeparatorText: 'de',
                            }}
                            paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
                            title="Motores"
                            noDataComponent="Não existem dados para serem mostrados"
                            columns={dtCl.columns}
                            data={dtCl.data}
                          />
                        </Grid>
                      </TabPane>

                      {
                    selectedEquips.equipaments?.length === 1 && (
                      <TabPane tab="Gráfico" key="2">
                        <Grid item xs={12} lg={12}>
                          <Typography variant="h5" style={{ marginBottom: 60, margin: '14px 0' }}>
                            Gráfico de motores
                          </Typography>
                          <Chart
                            type="bar"
                            height="700"
                            series={graphData.series}
                            options={graphData.options}
                          />
                        </Grid>
                      </TabPane>
                    )}
                    </Tabs>
                  </Grid>
                </Paper>
              )}

            </Grid>
          </>
        ) : (
          <Skeleton variant="rect" height={400} width="100%" animation="wave" />
        )
      }
    </>
  );
};
