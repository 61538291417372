/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import { Alert } from '@material-ui/lab';
import FockinkAPI from '../../../../../middlewares/Api';
import { number } from '../../../../../helpers/Intl';
import PhotovoltaicSearch from '../PhotovoltaicSearch';

export default (props) => {
  const [errorMessage, setErrorMessage] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({ series: [], dateStartText: '', dateFinishText: '' });
  const [state, setState] = React.useState({});
  const [estimative, setEstimative] = React.useState(false);
  const [graphSettings, setGraphSettings] = React.useState({
    from: new Date(),
    to: new Date(),
    offset: 0,
    group: 'day',
    searchType: 'today',
  });
  const series = [];
  const colors = [];

  const callApi = () => {
    setLoading(true);
    setErrorMessage(false);
    FockinkAPI.get(`/dashboards/photovoltaic/${props.equipamentid}/charts/performance`, {
      params: {
        dateStart: moment(graphSettings.from).format('yyyy-MM-DD'),
        dateFinish: moment(graphSettings.to).format('yyyy-MM-DD'),
        groupBy: graphSettings.group,
        plotEstimate: true,
      },
    }).then((response) => {
      setData({
        ...response.data.data.chartData,
        dateStartText: response.data.data.dataStartText,
        dateFinishText: response.data.data.dateFinishText,
      });
      setLoading(false);
    }).catch((e) => {
      setErrorMessage(e.response.data.message);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    callApi();
    const interval = setInterval(() => {
      callApi();
    }, 3600000);
    return () => clearInterval(interval);
  }, [props.equipamentid, graphSettings]);

  React.useEffect(() => {
    if (data.dateStartText && data.dateFinishText
      && data.dateStartText.substring(0, 10) === data.dateFinishText.substring(0, 10)) {
      series.push({ data: data.series, name: 'Produção' });
      colors.push('#39FD6A');
    } else {
      if (estimative) {
        series.push(data.series.find((a) => a.name.startsWith('Previsão')) || { data: [] });
        colors.push('#33b1f5');
      }
      series.push(data.series.find((a) => a.name.startsWith('Produção')) || { data: [] });
      colors.push('#39FD6A');
    }

    const stateInt = {
      series,
      type: moment(graphSettings.from).format('yyyy-MM-DD') === moment(graphSettings.to).format('yyyy-MM-DD') ? 'area' : 'bar',
      options: {
        chart: {
          toolbar: {
            show: true,
          },
          height: 300,
          type: moment(graphSettings.from).format('yyyy-MM-DD') === moment(graphSettings.to).format('yyyy-MM-DD') ? 'area' : 'bar',
        },
        colors,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        yaxis: {
          labels: {
            formatter(value) {
              return number(value);
            },
          },
        },
        xaxis: {
          categories: data.categories,
          tickAmount: 24,
        },
      },
    };
    setState(stateInt);
  }, [graphSettings, data, estimative]);

  if (errorMessage) {
    return (
      <Skeleton variant="rect" height={420} width="100%" animation="wave" />
    );
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <PhotovoltaicSearch
          // invs={data.numInv}
          setEstimative={setEstimative}
          estimative={estimative}
          graphSettings={graphSettings}
          setGraphSettings={setGraphSettings}
        />
      </Grid>
      {loading ? <Skeleton variant="rect" height={320} width="100%" animation="wave" /> : (
        <Grid item xs={12} style={{ minHeight: '320px' }}>
          {errorMessage
            ? (
              <Alert severity="warning" mt={2}>
                {errorMessage}
              </Alert>
            )
            : (
              <ReactApexChart
                options={state.options}
                series={state.series}
                type={state.type}
                height={state.options.chart.height}
              />
            )}
        </Grid>
      )}

    </Grid>
  );
};
