import React from 'react';
import { Redirect } from 'react-router-dom';
import '../../App.css';
import { useAuth } from '../../hooks/account';

export default (props) => {
  const [isLoginDone, setIsLoginDone] = React.useState(false);
  const { authByToken } = useAuth();

  React.useEffect(() => {
    const f = async () => {
      const isTheTokenSave = await authByToken(props.match.params.token);
      setIsLoginDone(isTheTokenSave);
    };

    f();
  }, []);

  if (isLoginDone) {
    return <Redirect to="/" />;
  }

  return (
    <h2
      style={{
        textAlign: 'center',
        minHeight: 'calc(100vh - 140px)',
        fontWeight: '400',
      }}
    >
      Aguarde.. Login em andamento
    </h2>
  );
};
