import React from 'react';

const colors = {
  borda: '#C0C0C0',
  silo: '#D6E0E6', // '#CCCCCC',
  cable: '#556E7B', // 'teal',
  textColor: '#FFFFFF',
};

export function FlatBottom({ modal }) { // Fundo Plano
  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={modal ? '930px' : '300px'}
        xmlSpace="preserve"
        viewBox={modal ? '0 0 250 240' : '0 0 250 200'}
      >
        <path
          stroke={colors.borda}
          strokeWidth="0"
          fill={colors.silo}
          d="M8 123c25,-40 77,-86 117,-86 42,0 92,46 117,86l8 40 -250 0 8 -40z"
        />
      </svg>
    </div>
  );
}

export function SemiConicalBottom({ modal }) { // Fundo Semi V
  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={modal ? '930px' : '300px'}
        xmlSpace="preserve"
        viewBox={modal ? '0 0 250 240' : '0 0 250 200'}
      >
        <path
          stroke={colors.borda}
          strokeWidth="0"
          fill={colors.silo}
          d="M8 112c25,-40 77,-86 117,-86 42,0 92,46 117,86l8 41 -8 0 -65 20 -105 0 -64 -20 -8 0 8 -41z"
        />
      </svg>
    </div>
  );
}

export function ConicalBottom({ modal }) { // Fundo V
  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={modal ? '930px' : '300px'}
        xmlSpace="preserve"
        viewBox={modal ? '0 0 250 240' : '0 0 250 200'}
      >
        <path
          stroke={colors.borda}
          strokeWidth="0"
          fill={colors.silo}
          d="M8 101c25,-39 77,-85 117,-85 42,0 92,46 117,86l8 40 -9 0 -116 42 -117 -42 -8 0 8 -41z"
        />
      </svg>
    </div>
  );
}

export function MelitaBottom({ modal }) { // Fundo W Melita
  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={modal ? '930px' : '300px'}
        xmlSpace="preserve"
        viewBox={modal ? '0 0 250 240' : '0 0 250 200'}
      >
        <path
          stroke={colors.borda}
          strokeWidth="0"
          fill={colors.silo}
          d="M8 101c25,-39 77,-85 117,-85 42,0 92,46 117,86l8 40 -9 0 -62 42 -54 -42 -54 42 -63 -42 -8 0 8 -41z"
        />
      </svg>
    </div>
  );
}

export function MelitaBottom2({ modal }) { // Fundo M Melita
  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={modal ? '930px' : '300px'}
        xmlSpace="preserve"
        viewBox={modal ? '0 0 250 240' : '0 0 250 200'}
      >
        <path
          stroke={colors.borda}
          strokeWidth="0"
          fill={colors.silo}
          d="M8 101c25,-39 77,-85 117,-85 42,0 92,46 117,86l8 40 -9 0 -62 42 -27 -21 -27 21 -27 -21 -27 21 -63 -42 -8 0 8 -41z"
        />
      </svg>
    </div>
  );
}
