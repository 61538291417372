import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import InputAdornment from '@material-ui/core/InputAdornment';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import {
  RiFileCopyLine,
} from 'react-icons/ri';
import Card from '../../../../../components/Card';
import CardTitle from '../../../../../components/CardTitle';
import CardText from '../../../../../components/CardText';
import AddressFields from '../../../../../components/AddressFields';
import FockinkAPI from '../../../../../middlewares/Api';
import BottomButtons from '../../../../../components/Buttons/BottomButtons';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import Spacer from '../../../../../components/Spacer';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  subTitle: {
    borderBottom: '1px solid #ccc',
    textAlign: 'center',
    paddingBottom: '0 !important',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

export default (props) => {
  const [loading, setLoading] = React.useState(true);
  const MySwal = withReactContent(Swal);
  const [data, setData] = React.useState({
    description: '',
    technicalDescription: '',
    operationId: '',
    // alias: '',
    location: {},
    status: 'A',
  });

  const activeInactive = [
    {
      value: 'A',
      name: 'Ativo',
    },
    {
      value: 'I',
      name: 'Inativo',
    },
  ];
  const breadcrumb = {
    title: `Planta: ${data.technicalDescription}`,
    routes: [
      { to: '/organization', name: 'Organizações' },
      { to: `/organization/${props.match.params.orgId}`, name: 'Organização' },
      {
        to: `/organization/${props.match.params.orgId}/account/${props.match.params.accId}`,
        name: 'Conta',
      },
      { name: 'Visualização de Planta' },
    ],
  };

  React.useEffect(() => {
    if (props.match.params.id === 'new') {
      setData({
        description: '',
        technicalDescription: '',
        operationId: '',
        // alias: '',
        location: {},
        status: 'A',
      });
      setLoading(false);
    } else {
      setLoading(true);
      FockinkAPI.get(`/plants/${props.match.params.id}`).then((response) => {
        setData({
          ...response.data.data,
          location: (response.data.data.settings || {}).location || {},
          activationDate: new Date(response.data.data.activationDate * 1000),
          expirationDate: new Date(response.data.data.expirationDate * 1000),
        });
        setLoading(false);
      });
    }
  }, [props.match.params.id]);

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      MySwal.fire({
        icon: 'success',
        title: 'Plant ID',
        text: 'Texto copiado',
      });
    } catch (err) {
      MySwal.fire({
        icon: 'error',
        title: 'Plant ID',
        text: 'Texto não copiado',
      });
    }
  };
  const classes = useStyles();

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleChangeLocation = (location) => {
    setData({ ...data, location });
  };

  if (loading) {
    return (
      <Skeleton variant="rect" height={500} width="100%" animation="wave" />
    );
  }

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardTitle>
              Identificação
              <Spacer />
            </CardTitle>
            <CardText>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="outlined"
                    name="description"
                    onChange={handleChange}
                    value={data.description || ''}
                    label="Descrição"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="outlined"
                    name="technicalDescription"
                    onChange={handleChange}
                    value={data.technicalDescription || ''}
                    label="Descrição Técnica"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={6}>
                  <FormControl variant="outlined" color="secondary">
                    <InputLabel id="operationIdLabel">Sistema</InputLabel>
                    <Select
                      value={data.operationId}
                      onChange={handleChange}
                      name="operationId"
                      id="operationId"
                      labelId="operationIdLabel"
                      label="Sistema"
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value="1">Irrigação</MenuItem>
                      <MenuItem value="2">Termometria</MenuItem>
                      <MenuItem value="3">Automação Industrial</MenuItem>
                      <MenuItem value="4">Fotovoltaica</MenuItem>
                      <MenuItem value="5">Estação Meteorológica</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <FormControl variant="outlined">
                    <InputLabel id="statusLabel">Status</InputLabel>
                    <Select
                      labelId="statusLabel"
                      id="status"
                      name="status"
                      value={data.status}
                      onChange={handleChange}
                      label="Status"
                      inputProps={{ readOnly: true }}
                    >
                      {
                              activeInactive.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              ))
                            }

                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardText>
          </Card>
        </Grid>
        {props.match.params.id !== 'new' && (
          <>
            <Grid item xs={12}>
              <Card>
                <CardTitle>
                  Identificação
                  <Spacer />
                </CardTitle>
                <CardText>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                      variant="outlined"
                      name="plantId"
                      value={props.match.params.id}
                      label="Plant ID"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            onClick={() => copyToClipBoard(props.match.params.id)}
                          >
                            <RiFileCopyLine size={20} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </CardText>
              </Card>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Card>
            <CardTitle>
              Endereço
              <Spacer />
            </CardTitle>
            <CardText>
              <AddressFields
                handleChangeLocation={handleChangeLocation}
                data={data.location}
              />
            </CardText>
          </Card>
        </Grid>

        <Grid
          item
          xs={12}
          className={`${classes.textAlign} ${classes.bottomButtons}`}
        >
          <BottomButtons
            backTo={`/organization/${props.match.params.orgId}?accountId=${props.match.params.accId}`}
          />
        </Grid>
      </Grid>
    </>
  );
};
