import React from 'react';
import { ButtonComponent as Button } from '../../../../../../components';
import FockinkAPI from '../../../../../../middlewares/Api';

import { Wrapper, InfoCard } from './style';

export function ResetVariable({ variable, handleCloseResetVariableModal, handleGetVariables }) {
  const [errorRequest, setErrorRequest] = React.useState();

  const {
    telemetryVariablesId,
    thresholdMax,
    thresholdMin,
    id,
  } = variable;

  const handleResetVariableRequest = async () => {
    try {
      const data = {
        telemetryVariablesId,
        thresholdMax,
        thresholdMin,
        useDefault: true,
      };
      await FockinkAPI.put(`telemetryVariablesAccount/${id}`, data);
      handleGetVariables();
      handleCloseResetVariableModal();
    } catch (error) {
      setErrorRequest(JSON.parse(error.request.response).message);
      setTimeout(() => {
        setErrorRequest(false);
      }, 4000);
    }
  };

  return (
    <Wrapper>
      <InfoCard>
        <strong>Atenção!</strong>
        <span>
          Ao confirmar, a variável customizada
          <span style={{ fontWeight: 'bold' }}>
            {' '}
            {variable.friendlyName}
            {' '}
          </span>
          será restaurada para os valores iniciais disponibilizado pelo sistema.
          O histórico de alterações continuara disponível.
        </span>
      </InfoCard>

      <div className="confirm-button">
        { errorRequest ? (
          <strong className="error">{errorRequest}</strong>
        ) : (
          <Button handle={handleResetVariableRequest}>Restaurar padrão</Button>
        )}
      </div>

    </Wrapper>
  );
}
