import React from 'react';
import '../../App.css';
import InputLabel from '@material-ui/core/InputLabel';
import {
  FormControl,
  Checkbox,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
} from '@material-ui/core';
import jwtDecode from 'jwt-decode';
import FockinkAPI from '../../middlewares/Api';

export default ({ selectedPlants, setSelectedPlants, systemFilter, label, selectAllLabel }) => {
  const [loading, setLoading] = React.useState(true);
  const [units, setUnits] = React.useState([]);
  const token = localStorage.getItem('@FockinkAPP:token');
  const { unit } = jwtDecode(token);
  const urlParams = new URLSearchParams(window.location.search);
  const url = urlParams.get('accountId');

  React.useEffect(() => {
    FockinkAPI.get(
      `/units?accountId=${url}`,
    ).then((response) => {
      const unitsRaw = [];
      response.data.data.forEach((u) => {
        const plants = systemFilter
          ? (u.plants || []).filter((f) => f.systemId === systemFilter)
          : (u.plants || []);

        if (plants.length > 0) {
          unitsRaw.push({ id: u.id,
            name: u.name,
            description: u.description,
            plantIds: plants.map((p) => p.id),
            isUnit: true });

          plants.forEach((p) => {
            unitsRaw.push({ id: p.id,
              uid: u.id,
              description: p.description,
              technicalDescription: p.technicalDescription,
            });
          });
        }
      });

      const plantsNdef = systemFilter
        ? (response.data.plantsWithoutUnit || []).filter((f) => f.systemId === systemFilter)
        : (response.data.plantsWithoutUnit || []);

      if (plantsNdef.length > 0) {
        unitsRaw.push({ id: 'NDEF',
          name: 'Não Definido',
          description: 'Não Definido',
          plantIds: plantsNdef.map((p) => p.id),
          isUnit: true });

        plantsNdef.forEach((p) => {
          unitsRaw.push({ id: p.id,
            uid: 'Não Definido',
            description: p.description,
            technicalDescription: p.technicalDescription,
          });
        });
      }

      setUnits(unitsRaw);
      setLoading(false);
    });
  }, []);

  const handleChangeSelect = (event) => {
    if (event.target.value.indexOf('Todas') > -1) {
      setSelectedPlants({ plants: [] });
    } else {
      const plants = [];

      event.target.value.forEach((e) => {
        if (typeof e === 'string') plants.push(e);
        if (Array.isArray(e)) {
          e.forEach((f) => {
            if (typeof f === 'string') plants.push(f);
            else console.error('selected a invalid value', f, typeof f);
          });
        }
      });

      setSelectedPlants({ plants });
    }
  };

  const formatUnitName = (s) => {
    if (s.id === 'NDEF') return `Equipamento não associado a nenhuma ${unit?.singular || 'Unidade'}`;
    const name = `${s.name} - ${s.description}`;
    return name;
  };

  return (
    <FormControl variant="filled">
      <InputLabel shrink>{label || 'Permissões de plantas'}</InputLabel>
      <Select
        multiple
        disabled={loading}
        value={selectedPlants}
        onChange={handleChangeSelect}
        name="plants"
        renderValue={(selected) =>
          selected.length > 0
            ? `Plantas selecionadas: ${selected.length}`
            : (selectAllLabel || 'Acesso à todas as plantas')
              }
        displayEmpty
      >
        {units?.map((s) => (
          s.isUnit
            ? (
              <ListSubheader
                key={s.id}
                disableSticky
                value={s.plantIds}
                style={{ cursor: 'copy' }}
              >
                {formatUnitName(s)}
              </ListSubheader>
            )
            : (
              <MenuItem key={s.id + s.uid} value={s.id}>
                <Checkbox
                  checked={!!selectedPlants.find((f) => f === s.id)}
                />
                <ListItemText primary={s.description} />
              </MenuItem>
            )
        ))}
      </Select>
    </FormControl>
  );
};
