/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
// import { currency, number } from '../../../../helpers/Intl';
// import Skeleton from '@material-ui/lab/Skeleton';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DataTable from 'react-data-table-component';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { AccordionContainer } from '../../../../components/ExpandAccordion';
import * as S from './style';
import AddressFields from '../../../../components/AddressFields';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import FockinkAPI from '../../../../middlewares/Api';
import BottomButtons from '../../../../components/Buttons/BottomButtons';
import PortalPermissions from '../../../../helpers/enums/PortalPermissions';
import { FilesToDownload } from '../FilasToDownloadList';
import { handleMessageError } from '../../../../helpers/handlerMessageError';
import { formatDate } from '../../../../helpers/Intl';

const useStyles = makeStyles({
  bottomButtons: {
    margin: '15px 0',
    textAlign: 'right',
    paddingTop: '10px !important',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  skeleton: {
    width: '80%',
  },
});
function getDataValue(data) {
  return data.instalationDate
    ? new Date(`${data.instalationDate}T20:00`)
    : new Date();
}
export default (props) => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [breadcrumbs, setBreadcrumbs] = React.useState(null);
  const [expanded, setExpanded] = React.useState('estimate');
  const [permission, setPermission] = React.useState();
  const [dtCl, setDtCl] = React.useState();
  const [inputs, setInputs] = React.useState();
  const profile = localStorage.getItem('profile');
  const classes = useStyles();
  const [deviceData, setDeviceData] = React.useState();
  const [expandedRow, setExpandedRow] = React.useState();
  const [pbColumns, setPbColumns] = React.useState({ columns: [], data: [] });
  const [loggerLoad, setLoggerLoad] = React.useState(false);

  const [
    availableFilesToDownload,
    setAvailableFilesToDownload,
  ] = React.useState([]);

  const callApi = () => {
    setLoading(true);
    FockinkAPI.get(`/equipaments/${props.match.params.id}/settings`).then(
      (response) => {
        if (response.data.data.settings) {
          setData({
            ...response.data.data.settings,
            auroraVisionPlantId: response.data.data.auroraVisionPlantId,
            clientDescription: response.data.data.clientDescription,
            location: response.data.data.settings.location || {},
            performanceEstimate:
              response.data.data.settings.performanceEstimate || {},
          });
        } else {
          setData({
            location: {},
            performanceEstimate: {},
          });
        }

        setBreadcrumbs({
          routes: [
            { name: 'Equipamentos', to: '/equipamentData' },
            {
              name:
                response.data.data.clientDescription ||
                response.data.data.description,
              to: `/photovoltaicDash/${props.match.params.id}`,
            },
            { name: 'Configurações' },
          ],
        });
        setLoading(false);
      },
    );
  };
  React.useEffect(() => {
    FockinkAPI.get(`/auroravision/loggers/${data?.auroraVisionPlantId}`).then((response) => {
      setInputs(response.data.data);
    });
  }, [data?.auroraVisionPlantId]);

  const getDocumentsFileToDonwload = async () => {
    try {
      const response = await FockinkAPI.get(
        `equipaments/${props.match.params.id}/readFiles`,
      );

      const fomattedFileObject = response.data.data.map((file) => ({
        file,
        fileName: file.split('/').pop(),
      }));

      setAvailableFilesToDownload(fomattedFileObject);
    } catch (error) {
      handleMessageError(error);
    }
  };

  React.useEffect(() => {
    if (profile === 'admin') {
      setPermission(30);
    } else {
      setPermission(PortalPermissions.PHOTOVOLTAIC_SETTINGS_EDIT.value);
    }
  }, [profile]);

  React.useEffect(() => {
    const stts = (status) => {
      if (status === 'ACTIVE') {
        return (
          <Tooltip title="Ativo">
            <StopRoundedIcon size={30} style={{ color: 'green' }} />
          </Tooltip>
        );
      } if (status === 'DELETED') {
        return (
          <Tooltip title="Deletado">
            <StopRoundedIcon size={30} style={{ color: 'red' }} />
          </Tooltip>
        );
      } if (status === 'DECOMMISSIONED') {
        return (
          <Tooltip title="Descomissionado">
            <StopRoundedIcon size={30} style={{ color: 'orange' }} />
          </Tooltip>
        );
      }
      /* ACTIVE: 'Em Edição',
      DELETED: 'Publicado',
      DECOMMISSIONED: 'Cancelado', */
    };
    const columns = [
      { name: 'Nome', selector: 'deviceName', sortable: true },
      { name: 'ID', selector: 'deviceEntityID', sortable: true },
      {
        name: 'Tipo',
        selector: 'deviceCategory',
        cell: (row) => row.deviceCategory.join(', '),
        sortable: true,
      },
      { name: 'Fabricante', selector: 'deviceManufacturer', sortable: true },
      { name: 'Modelo', selector: 'deviceModel', sortable: true },
      { name: 'Serial', selector: 'deviceSerialNumber', sortable: true },
      {
        name: 'Status',
        selector: 'deviceState',
        cell: (row) => stts(row.deviceState),
        sortable: true,
      },
    ];

    const defaultColumns = [
      { name: 'Nome', selector: 'loggerDescription', sortable: true },
      { name: 'ID', selector: 'loggerEntityID', sortable: true },
      { name: 'Fabricante', selector: 'loggerManufacturer', sortable: true },
      { name: 'MAC Address', selector: 'loggerName', sortable: true },
      { name: 'Status', selector: 'loggerState', sortable: true },
    ];
    setDtCl({ defaultColumns, data: inputs });
    setPbColumns({ columns, data: deviceData });
    if (inputs?.length > 0) {
      setLoggerLoad(true);
    }
  }, [inputs, deviceData]);

  React.useEffect(() => {
    (async () => {
      await getDocumentsFileToDonwload();
    })();
    callApi();
  }, [props.match.params.id]);

  if ((data && data.operationId === 'loading') || loading) {
    return <h3 className={classes.loading}>Carregando</h3>;
  }

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value.floatValue });
  };

  const handleDateChange = (date, input) => {
    setData({ ...data, [input]: formatDate(date, 'yyyy-MM-dd') });
  };

  const handleChangeLocation = (location) => {
    setData({ ...data, location });
  };

  const handleChangePerf = (event) => {
    const dataAdd = data.performanceEstimate || {};
    dataAdd[event.target.name] = event.target.value.floatValue;
    setData({ ...data, performanceEstimate: dataAdd });
  };

  const handleExpandendAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const saveData = () => {
    const dt = {
      data,
    };

    dt.method = 'put';
    dt.url = `/equipaments/${props.match.params.id}/photovoltaicSettings`;
    return dt;
  };

  const handleChangeClientDescription = (event) => {
    setData({
      ...data,
      clientDescription: event.target.value,
    });
  };
  const urlParams = new URLSearchParams(window.location.search);
  const whatRoute = urlParams.get('whatRoute');

  const OnExapand = (open, myData) => {
    if (open) {
      setDeviceData();
      FockinkAPI.get(`/auroravision/devices/${myData.loggerEntityID}`).then((response) => {
        setDeviceData(response.data.data);
      });
      setExpandedRow(myData.loggerEntityID);
    }
  };
  const expandableRowExpanded = (otherData) => expandedRow === otherData.loggerEntityID;
  const MyTable = () => (
    <DataTable
      pagination
      paginationPerPage={15}
      paginationComponentOptions={{
        rowsPerPageText: 'Linhas:',
        rangeSeparatorText: 'de',
      }}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
      noDataComponent="Não existem dados para serem mostrados"
      columns={pbColumns.columns}
      data={pbColumns.data}
      defaultSortField="current"
      defaultSortAsc={false}
      title=""
    />
  );

  return (
    <S.Wrapper>
      <Breadcrumbs routes={breadcrumbs.routes} />
      <S.Content>
        <AccordionContainer
          title="Descrição do Equipamento"
          expanded={expanded}
          name="clientDescription"
          handleExpandAccordion={handleExpandendAccordion}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={3}>
              <TextField
                variant="filled"
                name="clientDescription"
                onChange={handleChangeClientDescription}
                value={data.clientDescription || null}
                label="Descrição do equipamento"
              />
            </Grid>

            <Grid item xs={12} md={12} lg={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="Data de Instalação"
                  format="dd/MM/yyyy"
                  value={getDataValue(data)}
                  name="from"
                  style={{ width: '100%', marginTop: '8px' }}
                  onChange={(e) => handleDateChange(e, 'instalationDate')}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </AccordionContainer>

        <AccordionContainer
          title="Endereço"
          expanded={expanded}
          name="address"
          handleExpandAccordion={handleExpandendAccordion}
        >
          <AddressFields
            handleChangeLocation={handleChangeLocation}
            data={data.location}
          />
        </AccordionContainer>

        <AccordionContainer
          title="Estimativa de geração de energia (kWh)"
          expanded={expanded}
          name="estimate"
          handleExpandAccordion={handleExpandendAccordion}
        >
          <Grid container spacing={1}>
            {[
              ['jan', 'Janeiro'],
              ['feb', 'Fevereiro'],
              ['mar', 'Março'],
              ['apr', 'Abril'],
              ['may', 'Maio'],
              ['jun', 'Junho'],
              ['jul', 'Julho'],
              ['aug', 'Agosto'],
              ['sep', 'Setembro'],
              ['oct', 'Outubro'],
              ['nov', 'Novembro'],
              ['dec', 'Dezembro'],
            ].map((m) => (
              <Grid item xs={2} md={2} lg={2} key={m}>
                <NumberFormat
                  value={data.performanceEstimate[m[0]]}
                  name={m[0]}
                  label={m[1]}
                  customInput={TextField}
                  variant="filled"
                  thousandSeparator="."
                  decimalSeparator=","
                  onValueChange={(v) =>
                    handleChangePerf({ target: { name: m[0], value: v } })
                  }
                />
              </Grid>
            ))}
          </Grid>
        </AccordionContainer>

        <AccordionContainer
          title="Parâmetros de Operação"
          expanded={expanded}
          name="params"
          handleExpandAccordion={handleExpandendAccordion}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={3}>
              <NumberFormat
                value={data.capacity}
                name="capacity"
                label="Capacidade instalada (kW)"
                customInput={TextField}
                variant="filled"
                thousandSeparator="."
                decimalSeparator=","
                onValueChange={(v) =>
                  handleChange({ target: { name: 'capacity', value: v } })
                }
              />
            </Grid>

            <Grid item xs={12} md={12} lg={3}>
              <NumberFormat
                value={data.kWhValue}
                name="kWhValue"
                label="Valor médio por R$ kWh"
                customInput={TextField}
                variant="filled"
                prefix="R$ "
                thousandSeparator="."
                decimalSeparator=","
                onValueChange={(v) =>
                  handleChange({ target: { name: 'kWhValue', value: v } })
                }
              />
            </Grid>

            <Grid item xs={12} md={12} lg={3}>
              <NumberFormat
                value={data.dieselPrice}
                name="dieselPrice"
                label="Valor médio do Diesel R$"
                customInput={TextField}
                variant="filled"
                prefix="R$ "
                thousandSeparator="."
                decimalSeparator=","
                onValueChange={(v) =>
                  handleChange({ target: { name: 'dieselPrice', value: v } })
                }
              />
            </Grid>
          </Grid>
        </AccordionContainer>

        <AccordionContainer
          title="Documentos"
          expanded={expanded}
          name="files"
          handleExpandAccordion={handleExpandendAccordion}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={3}>
              <FilesToDownload files={availableFilesToDownload} />
            </Grid>
          </Grid>
        </AccordionContainer>

        <AccordionContainer
          title="Loggers"
          expanded={expanded}
          name="files"
          handleExpandAccordion={handleExpandendAccordion}
        >

          <Grid item lg={12}>
            {loggerLoad ? (
              <DataTable
                pagination
                paginationPerPage={15}
                paginationComponentOptions={{
                  rowsPerPageText: 'Linhas:',
                  rangeSeparatorText: 'de',
                }}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
                noDataComponent="Não existem dados para serem mostrados"
                columns={dtCl.defaultColumns}
                data={dtCl.data}
                defaultSortField="status"
                defaultSortAsc={false}
                onRowExpandToggled={OnExapand}
                expandableRows
                expandableRowExpanded={expandableRowExpanded}
                expandableRowsComponent={
                deviceData?.length > 0 ? (<MyTable />)
                  :
                  (
                    <div className={classes.root}>
                      <Skeleton />
                      <Skeleton animation={false} />
                      <Skeleton animation="wave" />
                    </div>
                  )}
                title=""
              />
            ) : (
              <div className={classes.root}>
                <Skeleton />
                <Skeleton animation={false} />
                <Skeleton animation="wave" />
              </div>
            )}

          </Grid>
        </AccordionContainer>

      </S.Content>

      <S.Options>
        <BottomButtons
          saveData={saveData}
          backTo={(whatRoute === 'Equips' ? `/photovoltaicDash/${props.match.params.id}?whatRoute=Equips` : `/photovoltaicDash/${props.match.params.id}`)}
          canSave={permission}
        />
      </S.Options>
    </S.Wrapper>
  );
};
