/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../App.css';
import Swal from 'sweetalert2';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import withReactContent from 'sweetalert2-react-content';
import Skeleton from '@material-ui/lab/Skeleton';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import XLSX from 'xlsx-js-style'; // Exportar em Excel
import jwtDecode from 'jwt-decode';
import { headerCell, dataCell } from '../../../../components/AuxExcelExport/index'; // Exportar em Excel
import FockinkAPI from '../../../../middlewares/Api';
import ThermometryHotSensorsResistanceTable from './ThermometryHotSensorsResistanceTable';
import { formatDate } from '../../../../helpers/Intl';

export default (props) => {
  const [loading, setLoading] = useState(true);
  const [HS, setHS] = useState([]);
  const [reportLogs, setReportLogs] = useState([]);
  const MySwal = withReactContent(Swal);
  const token = localStorage.getItem('@FockinkAPP:token');
  const { unit } = jwtDecode(token);
  const [mDS, setMDS] = useState([]);
  // const [csv, setCsv] = React.useState();
  // const [fileName, setFileName] = React.useState();

  let hedrExcel = [];
  let dtExcel = [];

  useEffect(() => {
    hedrExcel = [];
    hedrExcel = [
      headerCell({ value: unit?.plural || 'Unidades' }),
      headerCell({ value: 'Equipamento' }),
      headerCell({ value: 'Planta' }),
      headerCell({ value: 'Data' }),
      headerCell({ value: 'Grão' }),
      headerCell({ value: 'Subarea' }),
      headerCell({ value: 'Cabo' }),
      headerCell({ value: 'Sensor' }),
      headerCell({ value: 'Temperatura' }),
    ];

    dtExcel = [];
    HS.forEach((item) => {
      dtExcel.push([
        dataCell({ value: (item.units || []).length > 0 ? item.units.map((m) => m.description).join(', ') : 'Não associado' }),
        dataCell({ value: item.equipament.clientDescription || item.equipament.description }),
        dataCell({ value: item.plant.description }),
        dataCell({ value: formatDate(item.date, 'dd/MM/yyyy') }),
        dataCell({ value: item.grain }),
        dataCell({ value: item.sub.toString() }),
        dataCell({ value: item.cab.toString() }),
        dataCell({ value: item.sen.toString() }),
        dataCell({ value: item.temp.toString() }),
      ]);
    });

    setMDS([
      [headerCell({ value: 'Pontos Quentes com Resistência na Faixa de Temperatura' })],
      hedrExcel.map((item) => item),
      ...dtExcel,
    ]);
    // console.log('isso.. ', mDS);
  }, [HS]);

  /* function downloadCSV() {
    let csvFile = 'data:text/csv;charset=utf-8,\uFEFF';
    const csvArray = [
      [
        'Data',
        'Planta',
        'Grão',
        'Unidade de Armazenagem',
        'Subárea',
        'Cabo',
        'Sensor',
        'Temperatura',
      ],
    ];
    HS.forEach((d) => {
      const dt = `${new Date(d.date).toLocaleDateString()}`;
      csvArray.push([dt, d.plant, d.grain, d.equipament, d.sub, d.cab, d.sen, d.temp]);
    });
    csvArray.forEach((line) => {
      csvFile += line.join(';');
      csvFile += '\n';
    });

    const encodedUri = encodeURI(csvFile);
    const n = new Date().getTime();
    setFileName(`Relatório_de_informe_da_qualidade_${n}`);
    setCsv(encodedUri);
  } */

  /* React.useEffect(() => {
    clickDownload();
  }, [csv]);

  function clickDownload() {
    document.getElementById('link');
  } */

  const callApi = () => {
    const searchDataFilter = { ...props.searchData };
    if (searchDataFilter.equipamentIds.length === 0) {
      delete searchDataFilter.equipamentIds;
    }
    if (searchDataFilter.grain === '--ALL--') delete searchDataFilter.grain;
    delete searchDataFilter.searched;
    delete searchDataFilter.units;

    setLoading(true);
    FockinkAPI.get('/reports/thermometry/hotSensorsResistance', {
      params: searchDataFilter,
    })
      .then((responseHS) => {
        setHS(responseHS.data.data.reportData);
        setReportLogs(responseHS.data.data.reportLogs);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    callApi();
  }, [props.searchData]);

  const showErrors = () => {
    MySwal.fire({
      icon: 'warning',
      title: `${(reportLogs || []).length} log${
        (reportLogs || []).length > 1 ? 's' : ''
      } encontrado${(reportLogs || []).length > 1 ? 's' : ''}`,
      html: `
        <div style="text-align: left">
          ${(reportLogs || []).map((m, i) => `<p>${i + 1}) ${m}</p>`).join('')}
        </div>`,
    });
  };

  const iconLog = () => {
    if ((reportLogs || []).length === 0) {
      return (
        <Tooltip title="Perfeito! Nenhum log encontrado ao gerar o relatório">
          <CheckIcon style={{ color: 'green' }} />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Logs encontrados ao gerar o relatório! Clique para saber mais..">
        <WarningIcon style={{ color: 'red', cursor: 'pointer' }} onClick={showErrors} />
      </Tooltip>
    );
  };

  if (loading) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Skeleton variant="rect" height={500} width="100%" animation="wave" />
        </Grid>
      </Grid>
    );
  }

  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new(); // book_new novo arquivo em memória
    const ws = XLSX.utils.aoa_to_sheet(mDS);// json_to_sheet(data); //sheet é a aba do excel
    XLSX.utils.book_append_sheet(wb, ws, 'ResistenciaDeSensores'); // vincula o sheet ao arquivo
    // XLSX.writeFile(wb, 'ResistenciaDeSensores.xlsx'); // download do arquivo
    XLSX.writeFile(wb, `report-resistencia-de-sensores-${new Date().toISOString().substring(0, 10)}.xlsx`);
  };

  return (
    <Grid container spacing={1} justifyContent="space-between" alignItems="stretch">
      <Grid item xs={12} md={10} lg={10}>
        <h3 style={{ display: 'flex' }}>
          Pontos quentes com resistência na faixa de temperatura
          {' '}
          {iconLog()}
        </h3>
      </Grid>
      <Grid item xs={12} md={2} lg={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            handleExportExcel();
          }}
        >
          <GetAppIcon />
          XSLX
        </Button>
      </Grid>
      {/* <Grid item xs={12} md={2} lg={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            downloadCSV();
          }}
          fullWidth
        >
          <GetAppIcon />
          Baixar CSV
        </Button>
        <a id="link" download={`${fileName}.csv`} href={csv} style={{ display: 'none' }}>
          download
        </a>
        </Grid> */}

      <Grid item xs={12}>
        <ThermometryHotSensorsResistanceTable data={HS} />
      </Grid>
    </Grid>
  );
};
