import styled, { css } from 'styled-components';

export const NavForm = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 1rem 0;
`;

export const ButtonNavForm = styled.button`
  width: 100%;
  height: 4rem;
  padding: 14px;
  color: #626262;
  position: relative;
  background: #dddddd;
  font-size: 0.8rem;
  text-transform: uppercase;
  font-weight: 600;
  .helper-label {
    visibility: hidden;
  }

  @media screen and (max-width: 550px) {
    .primary-label {
      display: none;
    }

    .helper-label {
      visibility: visible;
    }
  }

  transition: filter 0.3s;

  & + & {
    border-left: 1px solid #cfcfcf;
  }

  &:hover {
    filter: brightness(0.9);
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      &::after {
        content: '';
        height: 5px;
        width: 100%;
        position: absolute;
        border-radius: 4px 4px 0 0;
        bottom: 0;
        left: 0;
        background: #2fbf26;
      }
    `}
`;
