import React, { useState, useEffect } from 'react';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
} from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import FockinkAPI from '../../../../middlewares/Api';
import Breadcrumbs from '../../../../components/Breadcrumbs';

const SoilPhysicalAnalysisCl = () => {
  const [clients, setClients] = useState();
  const [loading, setLoading] = useState();
  const [clientId, setClientId] = useState();
  const [redirect, setRedirect] = useState(false);

  const breadcrumb = {
    title: 'Análise Física do Solo',
    routes: [
      { name: 'Configurações', to: '/settings' },
      { name: 'Análise Física do Solo' },
    ],
  };
  useEffect(() => {
    setLoading(true);
    FockinkAPI.get('/accounts/?extended=true').then((response) => {
      setClients(
        response.data.data?.filter((x) => x.plantsCount.irrigation > 0),
      );
      setLoading(false);
    });
  }, []);

  if (redirect) {
    return <Redirect push to={`/settings/soilPhysicalAnalysis/${clientId}`} />;
  }

  if (loading) {
    return (
      <Skeleton variant="rect" height={300} width="100%" animation="wave" />
    );
  }

  return (
    <div>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />

      <Grid container>
        <Grid item xs={3}>
          <FormControl variant="filled" style={{ maxWidth: 250 }}>
            <InputLabel id="clientId">Clientes de Irrigação</InputLabel>
            <Select
              labelId="clientId"
              id="clientId"
              name="clientId"
              value={clientId || ''}
              label="Cliente"
              onChange={(e) => setClientId(e.target.value)}
            >
              {clients?.map((client) => (
                <MenuItem value={client.id}>{client.description}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => setRedirect(true)}
            type="button"
            variant="contained"
            color="primary"
          >
            CONFIRMAR
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SoilPhysicalAnalysisCl;
