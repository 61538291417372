import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Alert, Grid, Skeleton, Typography } from '@mui/material';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import ActualyData from './Components/ActualyData';
import StationData from './Components/StationData';
import WeatherCard from './Components/WeatherCard';
import TabPanel, { a11yProps } from './Components/TabPanel';
import FockinkAPI from '../../../../middlewares/Api';
import EquipError from '../../../../components/EquipError';
import DashesHeader from '../../../../components/DashesHeader';
import { handleMessageError } from '../../../../helpers/handlerMessageError';
import { Modal } from '../../../../components';
import { FilesToDownload } from '../FilasToDownloadList';

function MensageError({ log }) {
  return (
    <div>
      <h4>Ops! parece que não foi possível localizar este equipamento.</h4>
      <h5>
        Por Favor, veifique se o equipamento esta realmente cadastrado ou o
        ID do mesmo esteja sendo informado corretamente.
      </h5>
      <h6>{log}</h6>
    </div>
  );
}

function NoDataForShow() {
  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Alert severity="error">
            Ops! Não foram encontrados dados para serem exibidos para essa Estação Meteorológica.
            Por Favor,
            verifique a telemetria de sua Estação Meteorológica e acesse novamente esta página.
          </Alert>
        </CardContent>
      </Card>
    </Grid>
  );
}

export default function WeatherStationDash(props) {
  const [wsStatus, setWsStatus] = useState([]);
  const [wsForecast, setWsForecast] = useState([]);
  const [value, setValue] = useState('historical');
  const [loadingStatus, setLoadingStatus] = useState(true);
  const [loadingForecast, setLoadingForecast] = useState(true);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [status, setStatus] = useState();
  const [openModalDownloadFiles, setOpenModalDownloadFiles] = useState(false);

  const [
    availableFilesToDownload,
    setAvailableFilesToDownload,
  ] = useState([]);
  const handleCloseModelDownloadFiles = () => setOpenModalDownloadFiles(false);
  const getDocumentsFileToDonwload = async () => {
    try {
      const response = await FockinkAPI.get(
        `equipaments/${props.match.params.id}/readFiles`,
      );
      const fomattedFileObject = response.data.data.map((file) => ({
        file,
        fileName: file.split('/').pop(),
      }));

      setAvailableFilesToDownload(fomattedFileObject);
    } catch (error) {
      handleMessageError(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getDocumentsFileToDonwload();
    })();
    // callDataApi();
    // callRecipeApi();
    // const interval = setInterval(() => {
    //   callDataApiNoLoad();
    // }, 60000);
    // return () => clearInterval(interval);
  }, [props.match.params.id]);

  const breadcrumb = {
    title: 'Estação Meteorológica',
    routes: [
      { to: '/equipamentData', name: 'Equipamentos' },
      { name: wsStatus.clientDescription || wsStatus.description },
    ],
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // Chama a API de leitura de Status
    FockinkAPI.get(
      `/dashboards/weatherStation/${props.match.params.id}/status`,
    ).then((r) => {
      setWsStatus(r.data.data);
      setStatus({
        connection: r.data.data.connection,
        running: r.data.data.running,
        alert: r.data.data.failures,
      });
      // setName(r.data.data.clientDescription || r.data.data.description);
      setLoadingStatus(false);
    }).catch((e) => {
      console.error(e);
      // if (e.status === 503) {
      setErrorMessage('Equipamento sem conexão para trazer os dados');
      // } else if (e.response) {
      //   setErrorMessage(`${e.response.data.message} (${e.response.status})`);
      // } else setErrorMessage(`${e.response}`);
      setLoadingStatus(false);
    });
  }, []);

  useEffect(() => {
    // Chama a API de leitura das Previsões
    if (wsStatus?.location) {
      FockinkAPI.get(
        `/weatherStation?lat=${wsStatus.location.lat}&lon=${wsStatus.location.lon}`,
      ).then((r) => {
        setWsForecast(r.data.data);
        setLoadingForecast(false);
      }).catch((error) => (<MensageError log={error} />));
    }
  }, [wsStatus]);

  if (errorMessage) {
    return (
      <EquipError errorMessage={errorMessage} />
    );
  }
  return (
    <>
      <Breadcrumbs
        routes={breadcrumb.routes}
        title={`${breadcrumb.title}: ${wsStatus.clientDescription || wsStatus.description}`}
      />

      {/* <DashTitle>{breadcrumb.title || wsStatus.description}</DashTitle> */}
      <div style={{ marginTop: -50 }}>
        <DashesHeader
          openModal={setOpenModalDownloadFiles}
          // title={name}
          connection={status?.connection}
          running={status?.running}
          alert={status?.alert}
          loading={loadingStatus}
        />
      </div>

      {/* componente Daniel  */}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={9}>
          {
            loadingStatus ?
              <Skeleton variant="rect" width="100%" height="400px" />
              :
              !wsStatus ?
                <NoDataForShow />
                :
                <ActualyData data={wsStatus} />
            }
        </Grid>

        {/* componente Marcos  */}
        <Grid item xs={12} lg={3}>
          {
            loadingStatus ?
              <Skeleton variant="rect" width="100%" height="400px" />
              :
              !wsStatus.tableData ?
                <NoDataForShow />
                :
                <StationData data={wsStatus?.tableData} />
          }
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">
                <Tab
                  value="historical"
                  label="Histórico"
                  wrapped
                  {...a11yProps('historical')}
                />
                <Tab value="forecast" label="Previsão" {...a11yProps('forecast')} />
              </Tabs>

              {/* componente Gabriel  */}
              <TabPanel value={value} index="historical">
                {
                  loadingStatus ?
                    <Skeleton variant="rect" width="100%" height="200px" />
                    :
                    !wsStatus?.historical7D ?
                      <NoDataForShow />
                      :
                      <WeatherCard data={wsStatus.historical7D.reverse()} type="historical" />
                    }
              </TabPanel>

              <TabPanel value={value} index="forecast">
                {
                  loadingForecast ?
                    <Skeleton variant="rect" width="100%" height="200px" />
                    :
                    !wsForecast.forecast ?
                      <NoDataForShow />
                      :
                      <WeatherCard data={wsForecast.forecast} type="forecast" />

                }
                <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'right' }}>
                  <Typography style={{ alignText: 'right' }}>* Fonte: Openweather</Typography>
                </div>
              </TabPanel>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {availableFilesToDownload && (
      <Modal
        title="Arquivos Disponíveis para Download"
        open={openModalDownloadFiles}
        handleCloseModal={handleCloseModelDownloadFiles}
      >
        <FilesToDownload files={availableFilesToDownload} />
      </Modal>
      )}
    </>
  );
}
