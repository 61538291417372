import React from 'react';
import * as S from './styles';

export function CardInformation({ data }) {
  return (
    <S.SampleCardInformation>
      <strong>{data.title}</strong>
      <span>{`${data.measureType === 'mm' ? Number(data.data / 100) : data.data}${data.measureType}`}</span>
    </S.SampleCardInformation>
  );
}
