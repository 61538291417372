// import React from 'react';
import { dateNow } from '../../../helpers/AuxInfo';
import logoFck64 from './logoFck64';
import logoAF64 from './logoAF64';

export function PdfHeader() {
  return [
    {
      alignment: 'justify',
      columns: [
        {
          image: logoFck64(),
          width: 100,
          margin: [10, 10, 0, 10],
        },
        {
          text: '',
          fontSize: 11,
          bold: false,
          margin: [15, 20, 0, 0],
        },
      ],
    },
  ];
}

export function PdfFooter() {
  return [
    {
      alignment: 'justify',
      columns: [
        {
          image: logoAF64(),
          width: 20,
          margin: [10, 15, 0, 0],
        },
        {
          text: `Exportado dia: ${dateNow()}`,
          fontSize: 11,
          bold: false,
          margin: [15, 18, 0, 0],
        },
      ],
    },
  ];
}
