import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 0.8rem;
  min-height: 150px;
  max-width: 120px;

  border-radius: 4px;

  -webkit-box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.41);
  box-shadow: 0px 0px 11px -6px rgba(0, 0, 0, 0.41);

  background: ${({ positive }) => (positive ? '#2fbf262b' : '#c530302b')};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .description {
    margin: 0;
    display: block;
    text-align: center;
    position: absolute;
    bottom: 10px;
  }
`;
