import styled from 'styled-components';

export const Container = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: 'column';
  justify-content: flex-start;
  align-items: center;
  width: ${(props) =>
    props.width ? props.width : props.size > 1700 ? '6rem' : '4rem'};
  height: ${(props) =>
    props.width ? props.width : props.size > 1700 ? '6rem' : '4rem'};
  font-weight: bold;
`;

export const Label = styled.p`
  display: flex;
  flex: 1;
  height: 100%;
  margin: 0;

  font-size: ${(props) => (props.fontSize ? props.fontSize : '1rem')};
  font-weight: ${(props) => props.fontWeight};
  text-align: center;
`;
