import React from 'react';

import * as S from './styles';

// ex
// const DEFAULT_STEPS = [
//   {
//     name: 'Tensões',
//   },
// ];

export function SimpleSteptForm({ onChange, values, currentValue }) {
  return (
    <S.NavForm>
      {values.map((item, index) => (
        <S.ButtonNavForm
          isSelected={currentValue === index}
          type="button"
          key={item.name}
          onClick={() => onChange(index)}
          // receive function setState to index value
        >
          <span className="primary-label">{item.name}</span>

          <span className="helper-label">{index}</span>
        </S.ButtonNavForm>
      ))}
    </S.NavForm>
  );
}
