import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTheme } from 'styled-components';
import { number } from '../../helpers/Intl';

import * as S from './styles';

export function ProgressBarWithLabel({
  width,
  height,
  textSize,
  fontWeight,
  fontSize,
  trailColor,
  r,
  g,
  b,
  percent,
  description,
}) {
  const [size, setSize] = React.useState(window.innerWidth);

  const originalTheme = useTheme();

  React.useEffect(() => {
    if (window) {
      const resize = () => {
        if (window.innerWidth) {
          setSize(window.innerWidth);
        }
      };
      window.addEventListener('resize', resize);

      return () => {
        window.removeEventListener('resize', resize);
      };
    }
  }, [window, window.opener, window.screen, navigator.userAgent.match]);
  return (
    <S.Container>
      <S.Body width={width || ''} height={height || ''} size={size}>
        <CircularProgressbar
          styles={
            buildStyles({
              textColor: originalTheme.colors.messageDark6,
              textSize: `${textSize || '1.3rem'}`,
              pathTransitionDuration: 0.8,
              trailColor,
              pathColor: `rgba(${r}, ${g}, ${b}, ${percent})`,
            })}
          value={percent}
          text={`${number(percent)}%`}
        />
      </S.Body>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          minHeight: '100%',
        }}
      >
        <S.Label
          size={size}
          fontSize={fontSize || ''}
          fontWeight={fontWeight || ''}
        >
          {description}
        </S.Label>
      </div>
    </S.Container>
  );
}
