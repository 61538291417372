import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Card from './Card';
// import CardText from './CardText';
import CardTitle from './CardTitle';
import CardActions from './CardActions';
import Spacer from './Spacer';

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    fontWeight: '400',
    margin: '0',
  },
  icon: {
    padding: '10px !important',
    textAlign: 'center',
  },
});

export default (props) => {
  const [redirect, setRedirect] = React.useState(false);
  const classes = useStyles();

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <Card
      style={{ position: 'relative', minHeight: '150px' }}
      hover
      similar
      onClick={() => setRedirect(props.url)}
    >
      <CardTitle pb="10px" pt="4px" px="12px" className={classes.title}>
        <Spacer />
        <div style={{ textAlign: 'center' }}>{props.title}</div>
        <Spacer />
      </CardTitle>
      <CardActions
        pb="16px"
        className={classes.icon}
        style={{
          position: 'absolute',
          bottom: '0px',
          left: '0px',
          right: '0px',
        }}
      >
        <Spacer />
        {props.icon}
        <Spacer />
      </CardActions>
    </Card>
  );
};
