import React from 'react';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import '../../../App.css';
import AdornField from '../../../components/Fields/AdornField';
import Breadcrumbs from '../../../components/Breadcrumbs';
import BottomButtons from '../../../components/Buttons/BottomButtons';
import FockinkAPI from '../../../middlewares/Api';

export default () => {
  const [add, setAdd] = React.useState('');
  const [hosts, setHosts] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const MySwal = withReactContent(Swal);

  React.useEffect(() => {
    FockinkAPI.get('/settings/domainBlackList').then((response) => {
      const hostsb4 = response.data.data.domainList.map((f) => f.toLowerCase());
      hostsb4.sort();
      setHosts(hostsb4);
      setLoading(false);
    });
  }, []);

  const handleDelete = (h) => {
    MySwal.fire({
      title: `Tem certeza que quer apagar "${h}"?`,
      text: 'Essa ação não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, APAGAR!',
      cancelButtonText: 'Não, MANTER',
    }).then((result) => {
      if (result.isConfirmed) {
        const newArr = hosts.filter((f) => f !== h);
        setHosts(newArr);
      }
    });
  };

  const handleAdd = () => {
    const newArr = [...hosts, add];
    newArr.sort();
    setHosts(newArr);
  };

  const handleChange = (event) => {
    setAdd(event.target.value.toLowerCase());
  };

  const saveData = () => {
    const dt = {
      data: {
        terms: hosts,
      },
    };

    dt.method = 'put';
    dt.url = '/settings/domainBlackList';
    return dt;
  };

  const breadcrumb = {
    title: 'Bloqueio de Subdominios',
    routes: [
      { to: '/settings', name: 'Configurações' },
      { name: 'Bloqueio de Subdominios' }],
  };

  if (!localStorage.profile) {
    return <Redirect to="/logout" />;
  }

  if (loading) return <Skeleton variant="rect" height={250} width="100%" animation="wave" />;

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1} style={{ minHeight: 'calc(100vh - 101px)' }}>
        <Grid item lg={4} md={6} xs={10}>
          <AdornField name="add" handleChange={handleChange} value={add} label="Filtro + Novo Bloqueio" text=".konecta.ind.br" />
        </Grid>
        <Grid item xs={2}>
          <Button
            size="large"
            variant="contained"
            color="secondary"
            style={{ color: 'white', marginTop: '5px' }}
            onClick={handleAdd}
            startIcon={<AddIcon />}
          >
            Adicionar
          </Button>
        </Grid>

        <Grid item xs={12}>
          <h3 style={{ borderBottom: '1px solid #ccc', textAlign: 'center', paddingBottom: '0 !important' }}>Bloqueados</h3>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            {(hosts || []).filter((f) => f.match(RegExp(add, 'gi'))).map((h) => (
              <Grid item lg={2} md={4} xs={6}>
                <Chip key={h} label={h} onDelete={() => handleDelete(h)} color="primary" style={{ margin: '0 5px 5px' }} />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginTop: '15px', borderTop: '1px solid #ccc', paddingTop: '10px !important', textAlign: 'right',
          }}
        >
          <BottomButtons
            saveData={saveData}
            backTo="/settings"
          />
        </Grid>
      </Grid>
    </>
  );
};
