import React, { useState, useEffect } from 'react';
import { DivCable, DivSilo, DivMotor } from './style';
import SiloSVAuto from './siloSVAuto';
import autoDispositionCables from './autoDispositionCables';
import CableSVAuto from './cableSVAuto';
import crossSection from './crossSection';
import selectedSection from './selectedSection';
import autoDispositionMotors from './autoDispositionMotors';
import MotorSVAuto from './motorSVAuto';
import findBiggerTemperatureColor from '../../helpers/findBiggerTemperatureColor';

const cableDiameter = 30; // definição do diametro do cabo na visão superior
const motorDiameter = 30; // definição do diametro do motor na visão superior
const width = 200; // definição do width da tulha na visão superior
const height = 150; // definição do height da tulha na visão superior

export default function SuperiorViewAuto({ data, cablesTest, setSelectedNumberCables }) {
  const [cables, setCables] = useState([]);
  const [engines, setEngines] = useState([]);
  const [disposition, setDisposition] = useState([]);
  const [enginesDisposition, setEnginesDisposition] = useState([]);
  const [cortes, setCortes] = React.useState([]);
  const [section, setSection] = React.useState([]);

  function handleCableClick(cableNumber) {
    setSection(selectedSection(cableNumber, cortes)); // seta os cabos da seção selecionada
  }

  React.useEffect(() => {
    if (data) {
      const auxCables = [];
      data.chartData.forEach((cd) => {
        cd.unitData.arcs.forEach((arc) => {
          arc.cabs.forEach((cab) => {
            auxCables.push({
              cable: cab.num,
              arc: arc.arc,
              color: findBiggerTemperatureColor(cab.sens),
            });
          });
        });
      });
      setCables([]);
      setCables(auxCables);
      setEngines([]);
      setEngines(data.engines);
    }
  }, [data, cablesTest]);

  useEffect(() => {
    if ((cables[0]?.cable !== undefined)) {
    // carrega um array com a disposição dos cabos com o top e left de posicionamento
      setDisposition([]);
      setDisposition(autoDispositionCables(cables, cableDiameter, width, height));
    }
  }, [cables]);

  useEffect(() => {
  // carrega informações do corte transversal
    setCortes(crossSection(disposition));
  }, [disposition]);

  useEffect(() => { // inicializa os cortes
    if ((cortes?.length > 0)) { // verifica se os cortes ja foram calculados
      setSection(selectedSection(0, cortes)); // seta os cabos da seção selecionada
    }
  }, [cortes]);

  useEffect(() => {
    setSelectedNumberCables(section); // envia os cabos do corte par ao termovisor
  }, [section]); // a cada novo corte selecionado

  useEffect(() => {
    engines && (
      setEnginesDisposition(autoDispositionMotors(engines, disposition))
    );
    !engines && (
      setEnginesDisposition([])
    );
  }, [engines, disposition]);

  return (
    <DivSilo width={width} height={height}>

      <SiloSVAuto width={width} height={height} />

      {/* DISPOSIÇÃO DOS CABOS NA TELA */}
      {disposition.map((item) => (
        <DivCable
          key={item.cable}
          width={cableDiameter + 100}
          height={cableDiameter + 100}
          top={item.top}
          left={item.left}
        >
          <CableSVAuto
            cable={item.cable}
            tempColor={item.color}
            handleClick={handleCableClick}
            diameter={cableDiameter}
            selectedSection={section}
          />
        </DivCable>
      ))}
      {enginesDisposition.map((item) => (
        <DivMotor
          key={item.engineId}
          width={motorDiameter}
          height={motorDiameter}
          top={item.engineTop}
          left={item.engineLeft}
        >
          <MotorSVAuto
            motor={item}
            diameter={motorDiameter}
          />
        </DivMotor>
      ))}
    </DivSilo>
  );
}
