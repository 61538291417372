/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { Alert } from '@mui/material';
import { Content, ContentDiv, Main } from './style';
import { IconTemperature, IconHumidity, IconRain } from '../../Icons/WeatherIcons';
import ModalWeather from '../ModalWeather';
import { number } from '../../../../../../helpers/Intl';

function DateForShow({ date, cursor }) {
  return (
    <h1 style={{ cursor: cursor ? 'pointer' : 'default' }}>
      {
        date ?
          `
        ${(date || '----------').substr(8, 2)}
        /
        ${(date || '----------').substr(5, 2)}
      `
          :
          ''
        }
    </h1>
  );
}

export default function WeatherCard({ data, type }) {
  if (!data) {
    return <div />;
  }

  // const { children, value, index, ...other } = props;
  const [openModal, setOpenModal] = useState(false);
  const [dataModal, setDataModal] = useState([]);
  function handleClickDay() {
    (type === 'historical') ?
      setOpenModal(true)
      : '';
  }

  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Main>
      {
      (data || []).map((res, index) =>
        (
          <>
            {(index < 7) &&
              (
                (res?.errors) ? (
                  <Content>
                    <DateForShow date={res?.date} />
                    <ContentDiv>
                      <Alert severity="error">
                        Dados não encontrados para esse dia
                      </Alert>
                    </ContentDiv>
                  </Content>
                )
                  :
                  (type === 'historical') ? (
                    <Content
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        (type === 'historical') ? (
                          handleClickDay()) : '';
                        setDataModal(res);
                      }}
                    >
                      {
                  (type === 'historical') && (
                    <>
                      <DateForShow date={res?.date} cursor />

                      <ContentDiv style={{ cursor: 'pointer' }}>
                        <IconTemperature style={{ cursor: 'pointer' }} size={30} color="#556E7B" />
                        <div style={{ marginRight: -8, cursor: 'pointer' }}>
                          <p style={{ cursor: 'pointer' }}>Máx.</p>
                          <label style={{ cursor: 'pointer' }}>
                            {number(res.maxTemp, 1, 1)}
                            {' '}
                            °C
                          </label>
                        </div>
                        <div style={{ marginRight: -8, cursor: 'pointer' }}>
                          <p style={{ cursor: 'pointer' }}>Mín.</p>
                          <label style={{ cursor: 'pointer' }}>
                            {number(res.minTemp, 1, 1)}
                            {' '}
                            °C
                          </label>
                        </div>
                      </ContentDiv>
                      <ContentDiv style={{ cursor: 'pointer' }}>
                        <IconHumidity style={{ cursor: 'pointer' }} size={30} color="#556E7B" />
                        <div style={{ marginRight: -8, cursor: 'pointer' }}>
                          <label style={{ cursor: 'pointer' }}>
                            {number(res.maxHumd, 1, 1)}
                            {' '}
                            %
                          </label>
                        </div>
                        <div style={{ marginRight: -8, cursor: 'pointer' }}>
                          <label style={{ cursor: 'pointer' }}>
                            {number(res.minHumd, 1, 1)}
                            {' '}
                            %
                          </label>
                        </div>
                      </ContentDiv>

                      <ContentDiv style={{ cursor: 'pointer' }}>
                        <IconRain style={{ cursor: 'pointer' }} size={30} color="#556E7B" />
                        <div style={{ cursor: 'pointer' }}>
                          <label style={{ cursor: 'pointer' }}>
                            {number(res.rainDayAmount, 2, 2)}
                            {' '}
                            mm
                          </label>
                        </div>
                      </ContentDiv>
                    </>
                  )
                }
                    </Content>
                  ) : (
                    <Content>
                      {
                  (type === 'forecast') && (
                    <>
                      <DateForShow date={res?.dateText} />

                      <ContentDiv>
                        <IconTemperature size={30} color="#556E7B" />
                        <div style={{ marginRight: -8 }}>
                          <p>Máx.</p>
                          <label>
                            {number(res.max, 1, 1)}
                            {' '}
                            °C
                          </label>
                        </div>
                        <div style={{ marginRight: -8 }}>
                          <p>Mín.</p>
                          <label>
                            {number(res.min, 1, 1)}
                            {' '}
                            °C
                          </label>
                        </div>
                      </ContentDiv>
                      <ContentDiv>
                        <IconHumidity size={30} color="#556E7B" />
                        <div>
                          <label>
                            {number(res.humidity, 1, 1)}
                            {' '}
                            %
                          </label>
                        </div>
                      </ContentDiv>

                      <ContentDiv>
                        <IconRain size={30} color="#556E7B" />
                        <div>
                          <label>
                            {number((res.pop * 100), 2, 2)}
                            {' '}
                            %
                          </label>
                        </div>
                      </ContentDiv>
                    </>
                  )
                }
                    </Content>
                  )
              )
            }
          </>
        ))
      }
      <ModalWeather
        data={dataModal}
        openModal={openModal}
        closeModal={setOpenModal}
        type={(type)}
      />
    </Main>
  );
}
