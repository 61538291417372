// import React from 'react';
import countCablesInArc from '../../../helpers/countCablesInArc';

const pi = 3.14;
let disposition = [];

export default function autoDispositionCables(cables, siloDiameter, cableDiameter) {
  const siloRadius = (siloDiameter / 2);
  const cableRadius = (cableDiameter / 2);
  if (cables.length > 0) {
    disposition = []; // limpa os dados do vetor
    const maximoArco = cables[(cables.length - 1)].arc;
    let totalCabos = 0;
    const posCabo1 = 85;
    let diametro;
    let left_correcao = 0;
    let auxVariavel = 0;
    let auxAngulo = 0;
    let auxAnguloCorte = 0;
    let auxConut = 0;
    let auxArco = 0;
    // let setorAngulo = 0;
    let auxg2 = 0;
    let auxg = 0;
    let x = 0;
    let y = 0;

    cables.forEach((item) => {
      if (auxArco !== item.arc) {
        auxConut = 0;
      } else { (auxConut += 1); }
      auxArco = item.arc;

      switch (maximoArco) {
        case 1:
          switch (item.arc) {
            default:
              diametro = 80;
          }
          break;
        case 2:
          switch (item.arc) {
            default:
              diametro = 80;
          }
          break;
        case 3:
          switch (item.arc) {
            case 1:
              diametro = 125;
              break;
            case 2:
              diametro = 85;
              break;
            case 3:
              diametro = 40;
              break;
            default:
              diametro = 80;
          }
          break;
        case 4:
          switch (item.arc) {
            case 1:
              diametro = 125;
              break;
            case 2:
              diametro = 95;
              break;
            case 3:
              diametro = 65;
              break;
            case 4:
              diametro = 35;
              break;
            default:
              diametro = 80;
          }
          break;
        case 5:
          switch (item.arc) {
            case 1:
              diametro = 125;
              break;
            case 2:
              diametro = 100;
              break;
            case 3:
              diametro = 75;
              break;
            case 4:
              diametro = 50;
              break;
            case 5:
              diametro = 25;
              break;
            default:
              diametro = 80;
          }
          break;
        case 6:
          switch (item.arc) {
            case 1:
              diametro = 130;
              break;
            case 2:
              diametro = 108;
              break;
            case 3:
              diametro = 86;
              break;
            case 4:
              diametro = 64;
              break;
            case 5:
              diametro = 42;
              break;
            case 6:
              diametro = 20;
              break;
            default:
              diametro = 80;
          }
          break;
        case 7:
          switch (item.arc) {
            case 1:
              diametro = 130;
              break;
            case 2:
              diametro = 112;
              break;
            case 3:
              diametro = 94;
              break;
            case 4:
              diametro = 76;
              break;
            case 5:
              diametro = 58;
              break;
            case 6:
              diametro = 40;
              break;
            case 7:
              diametro = 22;
              break;
            default:
              diametro = 80;
          }
          break;
        default:
          diametro = 80;
      }
      left_correcao = (siloRadius - diametro - cableRadius);

      // contabiliza o total de cabos no arco em específico
      totalCabos = countCablesInArc(cables, item.arc);

      // console.log('totalCabos=>', totalCabos);
      auxVariavel = (360 / totalCabos); // distância entre cabos conforme o arco

      if (totalCabos === 1) {
        // quando tiver somente o cabo central
        auxAngulo = 0;
        auxAnguloCorte = auxAngulo;
        // setorAngulo = 1;
        auxg2 = 90 - auxAngulo;
        auxg = (auxg2 * pi) / 180;
        auxg = Math.cos(auxg);
        x = diametro + Math.trunc(diametro * auxg);
        auxg = (auxg2 * pi) / 180;
        auxg = Math.sin(auxg);
        y = diametro + Math.trunc(diametro * auxg);
        if (y > diametro) {
          y = diametro;
        }
      } else {
        auxAngulo = posCabo1 + (auxVariavel * (totalCabos - auxConut));

        auxAnguloCorte = auxVariavel * auxConut;

        if (auxAngulo > 360) {
          auxAngulo -= 360;
        }
        if (auxAngulo <= 90) {
          // setorAngulo = 1;
          auxg2 = 90 - auxAngulo;
          auxg = (auxg2 * pi) / 180;
          auxg = Math.cos(auxg);
          x = diametro + Math.trunc(diametro * auxg);
          auxg = (auxg2 * pi) / 180;
          auxg = Math.sin(auxg);
          y = diametro - Math.trunc(diametro * auxg);
          if (y > diametro) {
            y = diametro;
          }
        } else if (auxAngulo <= 180) {
          // setorAngulo = 2;
          auxg2 = auxAngulo;
          auxg = (auxg2 * pi) / 180;
          auxg = Math.sin(auxg);
          x = diametro + Math.trunc(diametro * auxg);
          auxg = (auxg2 * pi) / 180;
          auxg = Math.cos(auxg);
          y = diametro + (Math.trunc(diametro * auxg) * -1);
          if (y > diametro * 2) {
            y = diametro * 2;
          }
        } else if (auxAngulo <= 270) {
          // setorAngulo = 2;
          auxg2 = 270 - auxAngulo;
          auxg = (auxg2 * pi) / 180;
          auxg = Math.cos(auxg);
          x = diametro - Math.trunc(diametro * auxg);
          if (x < 0) {
            x = 0;
          }
          auxg = (auxg2 * pi) / 180;
          auxg = Math.sin(auxg);
          y = diametro + (Math.trunc(diametro * auxg));
          if (y < diametro) {
            y = diametro;
          }
        } else if (auxAngulo <= 360) {
          // setorAngulo = 1;
          auxg2 = auxAngulo;
          auxg = (auxg2 * pi) / 180;
          auxg = Math.sin(auxg);
          x = diametro + Math.trunc(diametro * auxg);
          auxg = (auxg2 * pi) / 180;
          auxg = Math.cos(auxg);
          y = diametro - (Math.trunc(diametro * auxg));
          if (y > diametro * 2) {
            y = diametro * 2;
          }
        }
      }
      disposition.push(
        {
          cable: item.cable,
          color: item.color,
          arc: item.arc,
          angle: auxAnguloCorte,
          top: (y + left_correcao),
          left: (x + left_correcao),
        },
      );
    });
  }

  return disposition;
}
