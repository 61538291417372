import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Card from '../../../../components/Card';
import CardText from '../../../../components/CardText';
import CardTitle from '../../../../components/CardTitle';
import Spacer from '../../../../components/Spacer';
import FockinkAPI from '../../../../middlewares/Api';
import BottomButtons from '../../../../components/Buttons/BottomButtons';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import AccountImageUploader from './AccountImageUploader';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  subTitle: {
    borderBottom: '1px solid #ccc',
    textAlign: 'center',
    paddingBottom: '0 !important',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

export default (props) => {
  const [loading, setLoading] = React.useState(true);
  const previewCanvasRef = React.useRef(null);

  const [data, setData] = React.useState({
    status: 'A',
    description: '',
    plan: {
      description: 'Plano Gratuito',
      planId: '77ae4ed4-3a64-4a2d-a42c-4e00f393be8f',
    },
    plantId: '',
    hostname: '',
    activationDate: new Date(),
    expirationDate: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    userLimit: 0,
    authentication: {
      type: 'local',
    },
  });

  const breadcrumb = {
    title: `Conta: ${data.description}`,
    routes: [
      { to: '/organization', name: 'Organizações' },
      { to: `/organization/${props.match.params.orgId}`, name: 'Organização' }, { name: 'Visualização de Conta' }],
  };

  React.useEffect(() => {
    if (props.match.params.id === 'new') {
      setLoading(false);
    } else {
      setLoading(true);
      FockinkAPI.get(`/accounts/${props.match.params.id}`).then((response) => {
        setData({
          ...response.data.data,
          activationDate: new Date(response.data.data.activationDate * 1000),
          expirationDate: new Date(response.data.data.expirationDate * 1000),
          authentication: response.data.data.authentication || { type: 'local' },
        });
        setLoading(false);
      });
    }
  }, [props.match.params.id]);

  const classes = useStyles();

  const activeInactive = [
    {
      value: 'A',
      name: 'Ativo',
    },
    {
      value: 'I',
      name: 'Inativo',
    },
  ];

  const autenticationItemns = [
    {
      value: 'local',
      name: 'Local',
    },
    {
      value: 'simpleAzureADApp',
      name: 'Azure AD Simples',
    },
  ];
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const handleChangeAuth = (event) => {
    const dataAdd = data.authentication || {};
    dataAdd[event.target.name] = event.target.value;
    setData({ ...data, authentication: dataAdd });
  };

  const handleChangePlan = (event) => {
    const dataAdd = data.plan || {};
    dataAdd[event.target.name] = event.target.value;
    setData({ ...data, plan: dataAdd });
  };

  // const handleDateChange = (date, input) => {
  //   setData({ ...data, [input]: date });
  // };

  if (loading) return <Skeleton variant="rect" height={500} width="100%" animation="wave" />;

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {data.client && (
          <>
            <Card>
              <CardTitle>
                Cliente
                <Spacer />
              </CardTitle>
              <CardText>
                <Grid container spacing={1}>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                      variant="outlined"
                      name="name"
                      value={data.client.clienteNegocio?.name || ''}
                      label="Nome"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                      variant="outlined"
                      name="id"
                      value={data.clientId || ''}
                      label="Código"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    <TextField
                      variant="outlined"
                      name="document"
                      value={data.client.document || ''}
                      label="Documento"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} md={4} xs={12}>
                    <TextField
                      variant="outlined"
                      name="adress"
                      value={`${data.client.address?.address1 || ''} ${data.client.address?.address2 || ''} ${data.client.address?.city || ''} ${data.client.address?.stateProvince || ''} ${data.client.address?.country || ''} ${`CEP: ${data.client.address?.postalCode}` || ''}`.replace(/\s\s+/g, ' ')}
                      label="Endereço"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardText>
            </Card>
          </>
          )}

        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardTitle>
              Conta
              <Spacer />
            </CardTitle>
            <CardText>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="outlined"
                    name="description"
                    onChange={handleChange}
                    value={data.description || ''}
                    label="Descrição"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <FormControl variant="outlined">
                    <InputLabel id="planoLabel">Plano</InputLabel>
                    <Select
                      labelId="planoLabel"
                      id="plano"
                      name="plano"
                      value={data.plan.planId}
                      onChange={handleChangePlan}
                      label="Plano"
                      inputProps={{ readOnly: true }}
                    >
                      <MenuItem value={data.plan.planId}>
                        {data.plan.description}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <FormControl variant="outlined">
                    <InputLabel id="statusLabel">Status</InputLabel>
                    <Select
                      labelId="statusLabel"
                      id="status"
                      name="status"
                      value={data.status}
                      label="Status"
                      inputProps={{ readOnly: true }}
                    >
                      {
                              activeInactive.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              ))
                            }

                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ width: '100%' }}>
                    <KeyboardDatePicker
                      margin="normal"
                      readOnly
                      inputVariant="outlined"
                      label="Data Ativação"
                      format="dd/MM/yyyy"
                      value={data.activationDate}
                      name="activationDate"
                      style={{ marginTop: -0.5 }}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ width: '100%' }}>
                    <KeyboardDatePicker
                      margin="normal"
                      label="Data Expiração"
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      value={data.expirationDate}
                      name="expirationDate"
                      readOnly
                      style={{ marginTop: -0.5 }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="outlined"
                    name="userLimit"
                    onChange={handleChange}
                    value={data.userLimit || 0}
                    label="Limite de Usuários"
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="outlined"
                    name="hostname"
                    value={data.hostname}
                    label="Hostname"
                    InputProps={{
                      readOnly: true,
                      endAdornment: ('.konecta.ind.br'),
                    }}
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <FormControl variant="outlined">
                    <InputLabel id="authLabel">Autenticação</InputLabel>
                    <Select
                      labelId="authLabel"
                      id="auth"
                      name="auth"
                      value={data.authentication.type}
                      onChange={handleChangeAuth}
                      label="Autenticação"
                      inputProps={{ readOnly: true }}
                    >
                      {
                              autenticationItemns.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              ))
                            }
                    </Select>
                  </FormControl>
                </Grid>

                {data.authentication.type === 'simpleAzureADApp' && (
                <>
                  <Grid item xs={12}>
                    <h3 className={classes.subTitle}>Configurações Azure AD Simples</h3>
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <TextField variant="filled" name="tenantId" onChange={handleChangeAuth} value={data.authentication.tenantId} label="Tenant ID" />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <TextField variant="filled" name="clientId" onChange={handleChangeAuth} value={data.authentication.clientId} label="Client ID" />
                  </Grid>

                  <Grid item lg={4} md={4} xs={12}>
                    <TextField variant="filled" name="secret" onChange={handleChangeAuth} value={data.authentication.secret} label="Secret" />
                  </Grid>
                </>
                )}
              </Grid>
            </CardText>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Card>
            <CardTitle>
              Logo
              <Spacer />
            </CardTitle>
            <CardText>
              <Grid item xs={12}>
                <AccountImageUploader
                  organizationId={props.match.params.orgId}
                  accountId={props.match.params.id}
                  previewCanvasRef={previewCanvasRef}
                  noUpload
                />
              </Grid>
            </CardText>
          </Card>
        </Grid>

        <Grid item xs={12} className={`${classes.textAlign} ${classes.bottomButtons}`}>
          <BottomButtons
            backTo={`/organization/${props.match.params.orgId}`}
          />
        </Grid>
      </Grid>
    </>
  );
};
