import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 720px) {
    min-width: 720px;
  }

  .row {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    padding: 8px 0px;
  }

  .button-new {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1.3rem;
  }

  form {
    width: 100%;
    max-width: 720px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > span {
      font-size: 1rem;
      margin-bottom: 0.6rem;
    }

    .row {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .success {
      color: #07ac10;
      margin-top: 1rem;
      font-weight: 500;
    }

    .color-picker {
      margin-top: 1rem;
    }

    .confirm-button {
      display: block;
      margin-left: auto;
      margin-top: 1rem;
    }
  }
`;

export const Content = styled.div`
  height: 100%;
  padding: 1rem;

  .label-header {
    margin: 2rem 0 0.6rem 0;
  }
`;

export const Label = styled.strong`
  margin: 0.2rem;
  font-size: 0.9rem;
  text-transform: uppercase;
`;

export const WarnCard = styled.div`
  padding: 1rem;
  background: #c530302b;
  width: 100%;
  margin-top: 1.2rem;
`;

export const AddButton = styled.button`
  padding: 14px;
  border-radius: 4px;
  border: 1px solid #3d5059;
  background: #3d50591a;
  color: #3d5059;
  transition: 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
`;
