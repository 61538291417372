import React, { useState, useEffect } from 'react';
import { Card, CardContent, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import { Alert, Skeleton } from '@material-ui/lab';
import { RiAttachment2 } from 'react-icons/ri';
import Breadcrumbs from '../../../components/Breadcrumbs';
import BackButton from '../../../components/Buttons/BackButton';
import { AnswerFeedBack, ReadFeedBack, ReadFile } from '../helpers/callApi';
import SaveButton from '../../../components/Buttons/SaveButton';

export default function AnswerFeedback(props) {
  const idFeedback = props?.match?.params?.id;
  const [returnToList, setReturnToList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState('');
  const [feedBack, setFeedBack] = useState('');
  const [answer, setAnswer] = useState('');
  const [sourcePage, setSourcePage] = useState('');
  const [attachment, setAttachment] = useState([]);
  const [attachmentList, setAttachmentList] = useState([]);

  const breadcrumb = {
    title: 'Respondendo Fale Conosco',
    routes: [
      { to: '/feedbacks', name: 'Fale Conosco' },
      { name: `Respondendo - ${idFeedback}` },
    ],
  };

  const collumnsAttachment = [
    {
      name: 'Anexo',
      selector: 'file',
      sortable: true,
      grow: 2,
    },
  ];

  if (returnToList) {
    /* return <Redirect push to="/myfeedbacks" />; */
    window.location.href = '/feedbacks';
  }

  async function feedbackRead() {
    const response = await ReadFeedBack({
      feedbackId: idFeedback,
    });

    if (response) {
      setUser(response?.conversation[0]?.user?.name);
      setSourcePage(response?.sourcePage);
      setFeedBack(response?.conversation[0]?.message);
      setAttachment(response?.files);
      setLoading(false);

      if (response?.conversation.length > 1) {
        setAnswer(response?.conversation[1]?.message);
      }
    }
  }

  async function downloadFile(file) {
    const fr = (await fileRead(file));
    await Promise.resolve(fr).then((r) => {
      const download = window.document.createElement('a');
      download.href = r;
      // link.download = 'export.txt';
      download.click();
      window.URL.revokeObjectURL(download.href);
    });
  }

  async function fileRead(item) {
    const linkFile = await ReadFile({
      feedbackId: idFeedback,
      file: item,
    });
    return linkFile;
  }

  function fileNameForButtonDownload(file) {
    file = file.replace(/\\/g, '/');
    const arq = file.substring(file.lastIndexOf('/') + 1);
    // const type = file.substring(file.lastIndexOf('.') + 1);
    return `${arq}`;
  }

  useEffect(() => {
    feedbackRead();
  }, []);

  useEffect(() => {
    if (attachment) {
      const list = [];
      attachment.forEach((item) => {
        list.push({
          file: item,
        });
      });
      setAttachmentList(list);
    }
  }, [attachment]);

  const save = async () => {
    const sendData = {
      message: answer,
    };

    const formData = new FormData();
    formData.append('message', feedBack);

    let response = '';

    response = await AnswerFeedBack({
      feedbackId: idFeedback,
      sendData,
    });

    if (response) {
      setReturnToList(true);
    }
  };

  return (
    <div>
      <Breadcrumbs
        routes={breadcrumb.routes}
        title={`${breadcrumb.title}`}
      />
      {
        loading ?
          (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Skeleton width="100%" height="300px" />
                </Grid>
              </Grid>
            </>
          )
          :
          (
            <>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        variant="outlined"
                        name="user"
                        value={user}
                        label="Usuário"
                        InputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        variant="outlined"
                        name="sourcePage"
                        value={sourcePage}
                        label="Tela"
                        InputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        name="feedBack"
                        onChange={(e) => { setFeedBack(e.target.value); }}
                        value={feedBack}
                        label="Comentários"
                        multiline
                        minRows={5}
                        maxRows={5}
                        InputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ overflowX: 'auto' }}>

                        <div
                          style={{
                            border: 2,
                            borderStyle: 'groove',
                            borderColor: '#J0J0J0',
                            borderRadius: 10,
                            width: '100%',
                            padding: 10,
                            marginTop: 0,
                          }}
                        >
                          <h5
                            style={{
                              backgroundColor: '#FAFAFA',
                              width: '50px',
                              marginTop: -16,
                              marginLeft: 10,
                              fontWeight: 350,
                              paddingLeft: 5,

                            }}
                          >
                            Anexos
                          </h5>

                          {
                            (attachmentList.length > 0) && (
                              attachmentList.map((item) => (
                                <Tooltip title={fileNameForButtonDownload(item.file)} arrow>
                                  <IconButton
                                    onClick={
                              () => {
                                downloadFile(item.file);
                              }
                            }
                                  >
                                    <RiAttachment2 />
                                  </IconButton>
                                </Tooltip>
                              ))
                            )
                          }
                          {
                            (attachmentList.length === 0) && (
                              <Alert severity="info">
                                Não foram Adicionados anexos.
                              </Alert>
                            )
                          }
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 0 }}>
                      <TextField
                        variant="outlined"
                        name="answer"
                        onChange={(e) => { setAnswer(e.target.value); }}
                        value={answer}
                        label="Resposta"
                        multiline
                        minRows={10}
                        maxRows={10}
                        fullWidth
                      />
                    </Grid>

                  </Grid>
                </CardContent>
              </Card>

              <div style={{ marginTop: 20, display: 'flex', justifyContent: 'right' }}>

                <BackButton
                  back={() => setReturnToList(true)}
                />

                <SaveButton save={save} style={{ marginLeft: 20 }} />

              </div>
            </>
          )
      }

    </div>

  );
}
