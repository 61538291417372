import { Button, Card, CardContent, Grid } from '@mui/material';
import React, { useState } from 'react';
import DewPoint from './DewPoint';
import Humid from './Humid';
import PreciptationRate from './PreciptationRate';
import Radiation from './Radiation';
import RainInDay from './RainInDay';
import StationGraphic from './StationGraphic';
import Temp from './Temp';
import ThermicSensation from './ThermicSensation';
import WindSpeed from './WindSpeed';
import { handleMessageError } from '../../../../../helpers/handlerMessageError';
import { formatDate } from '../../../../../helpers/Intl';

export default function ActualyData({ data }) {
  const [graphicSelection, setGraphicSelection] = useState(['Temperatura', 'Umidade']);

  if (!data) {
    return <div />;
  }

  function ExistInSelection(newSelection) {
    let exist = false;
    graphicSelection.forEach((item) => {
      if (item === newSelection) {
        exist = true;
      }
    });
    return exist;
  }

  function handleChangeDataGraphic(selection) {
    if ((ExistInSelection(selection)) && (graphicSelection.length > 1)) {
      const newGraphicSelection = [];
      graphicSelection.forEach((item) => {
        if (item !== selection) {
          newGraphicSelection.push(item);
        }
      });
      setGraphicSelection(newGraphicSelection);
    } else if (
      (!ExistInSelection(selection)) &&
    (graphicSelection.length >= 1) &&
    (graphicSelection.length < 3)) {
      setGraphicSelection([...graphicSelection, selection]);
    } else if ((graphicSelection.length >= 3)) {
      handleMessageError('Ops! Parece que você já selecionou 3 itens a serem graficados. Desmarque os itens que você não deseja mais visualizar para uma nova seleção. Mínimo de um item selecionado');
    }
  }

  return (
    <Card sx={{ height: '100%' }}>
      {/* 432 */}
      <CardContent>
        <Grid container spacing={1.9}>
          <Grid item xs={12}>
            Dados Atuais - Última Leitura em
            {' '}
            {
            data?.lastTelemetry?.ts ?
              (
                formatDate(
                  new Date(data?.lastTelemetry?.ts * 1000), 'dd/MM/yyyy HH:mm:ss',
                )
              )
              :
              '--/--/---- --:--'
            }
          </Grid>
          <Grid item xs={12} sm={6} lg={3}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={6} style={{ alignItems: 'center' }}>
                <Button
                  onClick={() => { handleChangeDataGraphic('Temperatura'); }}
                  variant={ExistInSelection('Temperatura') ? 'outlined' : 'text'}
                  style={{
                    fontSize: '12px',
                    color: '#556E7B',
                    minWidth: 120,
                    borderRadius: 10,
                    textTransform: 'none',
                  }}
                >
                  <Temp value={data?.lastTelemetry?.temperature} icon />
                </Button>
              </Grid>

              <Grid item xs={6} lg={6}>
                <Button
                  onClick={() => { handleChangeDataGraphic('Umidade'); }}
                  variant={ExistInSelection('Umidade') ? 'outlined' : 'text'}
                  style={{
                    fontSize: '12px',
                    color: '#556E7B',
                    minWidth: 120,
                    borderRadius: 10,
                    textTransform: 'none',
                  }}
                >
                  <Humid value={data?.lastTelemetry?.humidity} icon />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={6}>
                <Button
                  onClick={() => { handleChangeDataGraphic('Chuva do dia'); }}
                  variant={ExistInSelection('Chuva do dia') ? 'outlined' : 'text'}
                  style={{
                    fontSize: '12px',
                    color: '#556E7B',
                    minWidth: 120,
                    borderRadius: 10,
                    textTransform: 'none',
                  }}
                >
                  <RainInDay value={data?.lastTelemetry?.rainDayAmount} icon />
                </Button>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Button
                  onClick={() => { handleChangeDataGraphic('Ponto de orvalho'); }}
                  variant={ExistInSelection('Ponto de orvalho') ? 'outlined' : 'text'}
                  style={{
                    fontSize: '12px',
                    color: '#556E7B',
                    minWidth: 120,
                    borderRadius: 10,
                    textTransform: 'none',
                  }}
                >
                  <DewPoint value={data?.lastTelemetry?.dewPoint} icon />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={6}>
                <Button
                  onClick={() => { handleChangeDataGraphic('Sensação térmica'); }}
                  variant={ExistInSelection('Sensação térmica') ? 'outlined' : 'text'}
                  style={{
                    fontSize: '12px',
                    color: '#556E7B',
                    minWidth: 120,
                    borderRadius: 10,
                    textTransform: 'none',
                  }}
                >
                  <ThermicSensation value={data?.lastTelemetry?.thermicSensation} icon />
                </Button>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Button
                  onClick={() => { handleChangeDataGraphic('Velocidade do vento'); }}
                  variant={ExistInSelection('Velocidade do vento') ? 'outlined' : 'text'}
                  style={{
                    fontSize: '12px',
                    color: '#556E7B',
                    minWidth: 120,
                    borderRadius: 10,
                    textTransform: 'none',
                  }}
                >
                  <WindSpeed value={data?.lastTelemetry?.windSpeed} icon />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={6}>
                <Button
                  onClick={() => { handleChangeDataGraphic('Radiação'); }}
                  variant={ExistInSelection('Radiação') ? 'outlined' : 'text'}
                  style={{
                    fontSize: '12px',
                    color: '#556E7B',
                    minWidth: 120,
                    borderRadius: 10,
                    textTransform: 'none',
                  }}
                >
                  <Radiation value={data?.lastTelemetry?.solarRadiation} icon />
                </Button>
              </Grid>
              <Grid item xs={6} lg={6}>
                <Button
                  onClick={() => { handleChangeDataGraphic('Taxa de precipitação'); }}
                  variant={ExistInSelection('Taxa de precipitação') ? 'outlined' : 'text'}
                  style={{
                    fontSize: '12px',
                    color: '#556E7B',
                    minWidth: 120,
                    borderRadius: 10,
                    textTransform: 'none',
                  }}
                >
                  <PreciptationRate value={data?.lastTelemetry?.precipitationRate} icon />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* componente Felipe  */}
          <Grid item xs={12}>
            <StationGraphic
              data={data?.historical24hChart}
              selectedData={graphicSelection}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
