/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment from 'moment';
import '../../../../../App.css';
import Skeleton from '@material-ui/lab/Skeleton';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import OpacityIcon from '@material-ui/icons/Opacity';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import HistoryIcon from '@material-ui/icons/History';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import FockinkAPI from '../../../../../middlewares/Api';
import ThreeDimensionVisualization from '../../../../../components/ThreeDimensionVisualization';
import { number, formatDate } from '../../../../../helpers/Intl';
import { handleMessageError } from '../../../../../helpers/handlerMessageError';

export default ({
  currentDate,
  setCurrentDate,
  currentTelemetry,
  setCurrentTelemetry,
  lastValidateDate,
  telemetries,
  equipamentId,
  ...props
}) => {
  const [offset, setOffset] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState();

  const callApi = () => {
    setLoading(true);
    FockinkAPI.get(
      `/dashboards/thermometry/${equipamentId}/charts/3d?telemetryId=${telemetries[currentTelemetry].id}`,
    ).then((response) => {
      setData(response.data.data);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    if (
      currentTelemetry !== undefined &&
      telemetries.length > currentTelemetry
    ) {
      callApi();
    }
  }, [telemetries, currentTelemetry]);

  if (loading) {
    return (
      <Skeleton variant="rect" height={350} width="100%" animation="wave" />
    );
  }

  const setNewOffset = (newOffset) => {
    setLoading(true);
    setOffset(newOffset);
    const nd = new Date(currentDate);
    nd.setDate(nd.getDate() - newOffset);
    setCurrentDate(new Date(nd));
  };

  const handleDateChange = (date) => {
    const dt = moment(date.toISOString().substring(0, 10));
    const diffDate = moment().diff(dt, 'days');
    setNewOffset(diffDate);
  };

  return (
    <Grid container spacing={1}>

      <Grid item xs={12} md={4} lg={2}>
        <Grid container>

          <Grid
            item
            xs={1}
            style={{ cursor: 'pointer', margin: 'auto' }}
            onClick={() => setNewOffset(offset + 1)}
          >
            <KeyboardArrowLeftIcon style={{ margin: '-4px' }} />
          </Grid>

          <Grid item xs={10}>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              style={{ width: '100%' }}
              variant="outlined"
            >
              <KeyboardDatePicker
                variant="outlined"
                margin="normal"
                label="Data Aferição"
                format="dd/MM/yyyy"
                value={currentDate}
                name="date"
                onChange={(e) => handleDateChange(e, 'date')}
                InputProps={{ readOnly: true }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid
            item
            xs={1}
            style={{
              cursor: 'pointer',
              margin: 'auto',
              color: (currentDate.getDate() !== new Date().getDate()) ? '#727272' : '#bdbdbd',
            }}
            onClick={() => {
              if (currentDate.getDate() !== new Date().getDate()) {
                setNewOffset(offset - 1);
              } else {
                handleMessageError('Ops! A data que você esta tentando acessar é superior a data de hoje.');
              }
            }}
          >
            <KeyboardArrowRightIcon />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={4} lg={2}>
        <FormControl variant="filled">
          <InputLabel>Hora Aferição</InputLabel>
          <Select
            // variant="outlined"
            disabled={telemetries.length === 0}
            value={currentTelemetry !== undefined ? currentTelemetry : ''}
            onChange={(e) => setCurrentTelemetry(e.target.value)}
            name="hour"
          >
            {telemetries.map((m, i) => (
              <MenuItem value={i} key={m.id}>
                {m.hour}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={12} style={{ width: '100%', textAlign: 'center' }}>
        {lastValidateDate && !data ? (
          <>
            <h2
              style={{
                width: '100%',
                textAlign: 'center',
              }}
            >
              <EventBusyIcon
                style={{ marginRight: '10px', fontSize: '35px' }}
              />
              Nenhuma informação encontrada nessa data.
            </h2>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => setCurrentDate(lastValidateDate)}
              startIcon={<HistoryIcon />}
            >
              {`Ver ultima data (${formatDate(
                lastValidateDate,
                'dd/MM/yyyy',
              )})`}
            </Button>
          </>
        ) : (
          data ? (
            <ThreeDimensionVisualization
              key={Math.random()}
              building={data.chart.building}
              subareaSelected={{
                value: props.strategyId === 'all' ? 0 : props.strategyId,
              }}
              layout={data.chart.layout}
              station={data.chart.weather}
              unitInfo={data.chart.unitInfo}
            />
          ) : <p>Carregando..</p>
        )}

      </Grid>
      {data && (
        <>
          <Grid item xs={12} md={6} lg={6}>
            <h3 style={{ fontWeight: '500' }}>
              Estação meteorológica no momento da aferição:
            </h3>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <h3
              style={{ fontWeight: '500', display: 'flex', alignItems: 'center' }}
            >
              <span
                style={{ fontWeight: '400', fontSize: '12px', marginLeft: '5px' }}
              >
                Temp:
              </span>
              <span>
                {
              data.chart.layout.weather.weatherTemp === 997 ?
                'ERR'
                :
                data.chart.layout.weather.weatherTemp === 998 ?
                  'N/D' :
                  data.chart.layout.weather.weatherTemp === 999 ?
                    'OFF' :
                    `${number(data.chart.layout.weather.weatherTemp)}°C`}

              </span>
              <span
                style={{ fontWeight: '400', fontSize: '12px', marginLeft: '5px' }}
              >
                Umid:
              </span>
              <span>
                {
              data.chart.layout.weather.weatherHumid === 997 ?
                'ERR'
                :
                data.chart.layout.weather.weatherHumid === 998 ?
                  'N/D' :
                  data.chart.layout.weather.weatherHumid === 999 ?
                    'OFF' : `${number(data.chart.layout.weather.weatherHumid)}%`}

              </span>
              {data.chart.layout.weather.weatherRain === 0 ? (
                <Tooltip title="Sem Chuva">
                  <WbSunnyIcon style={{ fontSize: '21px', marginLeft: '5px' }} />
                </Tooltip>
              ) : (
                <Tooltip title="Chovendo">
                  <OpacityIcon style={{ fontSize: '21px', marginLeft: '5px' }} />
                </Tooltip>
              )}
            </h3>
          </Grid>
        </>
      )}

    </Grid>
  );
};
