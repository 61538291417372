import { Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import { AccordionContainer } from '../../../../../components/ExpandAccordion';
import AddressFields from '../../../../../components/AddressFields';
import FockinkAPI from '../../../../../middlewares/Api';
import BottomButtons from '../../../../../components/Buttons/BottomButtons';
import PortalPermissions from '../../../../../helpers/enums/PortalPermissions';

export default function WeatherStationSettings() {
  const [expanded, setExpanded] = useState('clientDescription');
  const [dataToRequest, setDataToRequest] = useState(
    {
      clientDescription: '',
      location: {
        postalCode: '',
        address1: '',
        address2: '.',
        city: '',
        state: '',
        country: '',
        lat: 0,
        lon: 0,
      },
    },
  );

  const params = window.location.pathname.split('/');
  const id = params[2];

  const MySwal = withReactContent(Swal);

  const routes = [
    { name: 'Equipamentos', to: '/equipamentData' },
    {
      name: dataToRequest?.clientDescription,
      to: `/weatherStation/${id}`,
    },
    { name: 'Configurações' },
  ];

  useEffect(() => {
    FockinkAPI.get(
      `equipaments/${id}/settings`,
    ).then((r) => {
      setDataToRequest({
        ...r.data.data.settings,
        clientDescription: r.data.data.clientDescription,
        location: r.data.data.settings.location || {},
      });
    }).catch((error) => (console.error(error)));
  }, [id]);

  const throwWarning = (text) =>
    MySwal.fire({
      title: text,
    });

  const checkValidation = () => {
    if (
      !dataToRequest.location.address1 ||
      dataToRequest.location.address1.length > 60
    ) {
      throwWarning('Endereço 1 deve ter entre 1 e 60 caracteres');
      return false;
    }

    const tests = [
      { text: 'CEP', input: 'postalCode' },
      { text: 'Cidade', input: 'city' },
      { text: 'Estado', input: 'state' },
      { text: 'Pais', input: 'country' },
      { text: 'Latitude', input: 'lat' },
      { text: 'Longitude', input: 'lon' },
    ]
      .map((e) => {
        if (!dataToRequest.location[e.input]) {
          throwWarning(`${e.text} deve ser preenchido`);
          return false;
        }
        return true;
      })
      .reduce((a, b) => a && b);

    if (!tests) {
      return false;
    }

    return true;
  };

  const saveData = () => {
    const valid = checkValidation();
    if (valid) {
      const dt = {
        data: {
          location: dataToRequest.location,
          clientDescription: dataToRequest.clientDescription,
        },
        url: `/equipaments/${id}/weatherStationSettings`,
        method: 'put',
      };
      return dt;
    }
    return undefined;
  };

  const handleExpandendAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeClientDescription = (event) => {
    setDataToRequest({
      ...dataToRequest,
      clientDescription: event.target.value,
    });
  };

  const handleChangeLocation = (location) => {
    setDataToRequest({ ...dataToRequest, location });
  };

  return (
    <div>
      <Breadcrumbs routes={routes} />

      <AccordionContainer
        title="Descrição do Equipamento"
        expanded={expanded}
        name="clientDescription"
        handleExpandAccordion={handleExpandendAccordion}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} md={12} lg={3}>
            <TextField
              variant="filled"
              name="clientDescription"
              onChange={handleChangeClientDescription}
              value={dataToRequest?.clientDescription}
              label="Defina uma descrição para o equipamento"
              fullWidth
            />
          </Grid>
        </Grid>
      </AccordionContainer>

      <AccordionContainer
        title="Endereço"
        expanded={expanded}
        name="address"
        handleExpandAccordion={handleExpandendAccordion}
      >
        {
          (dataToRequest) && (
          <AddressFields
            handleChangeLocation={handleChangeLocation}
            data={dataToRequest?.location}
            data1={dataToRequest}
          />
          )
        }

      </AccordionContainer>

      <div style={{ minWidth: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
        <BottomButtons
          saveData={
            (expanded === 'address' ||
              expanded === 'clientDescription') &&
            saveData
          }
          backTo={`/weatherStation/${id}`}
          canSave={PortalPermissions.WEATHER_STATION_SETTINGS_EDIT.value}
        />
      </div>
    </div>
  );
}
