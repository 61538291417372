import countCablesInArc from './countCablesInArc';

export default function findCenterCable(data) {
  let centerCable = 0;
  data.forEach((item) => {
    if (countCablesInArc(data, item.arc) === 1) {
      centerCable = countCablesInArc(data, item.arc);
    }
  });

  return centerCable;
}
