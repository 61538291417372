/*eslint-disable*/
import React, { useRef, useState, useEffect } from 'react'

import * as BABYLON from 'babylonjs'
import * as GUI from 'babylonjs-gui'

import { useTheme } from 'styled-components'

import { CleanPanelSilo } from './CriaSilo'
import { CleanPanelArmazemSemiV } from './CriaArmazem'
import { CleanPanelArmazemPlano } from './CriaArmazemPlano'
import { CleanPanelArmazemV } from './CriaArmazemV'
import { CleanPanelSiloV } from './CriaSiloV'
import { CleanPanelBunkerV } from './CriaBunkerV'
import { CleanPanelSiloElevado } from './CriaSiloElevado'
import { CleanPanelBunkerPlano } from './CriaBunkerPlano'
import { CleanPanelTulhaV } from './CriaTulhaV'

import AdicionarUnidades from './AdicionarUnidades'
import { CleanVarsArma } from './CriaArmazemV'
import { CleanVarsSilo } from './CriaSilo'
import { CleanVarsTulha } from './CriaTulhaV'
import { CleanVarsBunker } from './CriaBunkerV'
import { CleanVarsBunkerPlano } from './CriaBunkerPlano'
import { CleanVarsArmaPlano } from './CriaArmazemPlano'
import { CleanVarsArmaSemiV } from './CriaArmazem'
import { CleanVarsSiloElevado } from './CriaSiloElevado'
import CriaMotor from './CriaMotor'
import trash from './img/trash.svg'
import cancel from './img/cancel.svg'
import confirm from './img/confirm.svg'
import center from './img/center.svg'

import { theme } from '../../styles/themes/theme'

import { resolverUpdateInfoAndAppearanceOfMotors } from './utils'
import { CriaPaletaDeCores } from './CriaPaletaDeCores'

import CriarCena from './CriarCena'

import './style.css'

const _ = require('lodash')

const ThreeDimensionVisualization = (props) => {
  const handleOnSetObjetoCamadaProduto = (value) => {
    // setObjetoCamadaProduto(value);
  }

  // FUNÇÃO - UNIDADES OBSERVAVEIS
  const handleOnSetUnidadesObservaveis = (value) => {
    unidadesObservaveis = value
  }

  // FUNÇÃO - MUDA INFORMAÇÃO DO PRODUTO NO MENU
  const changeInfoProduct = () => {
    const unidade = unidadesObservaveis[0]
    produto_info = !produto_info
    // CRIA UM LISTENER PARA produto_info
    unidade.observavel.notifyObservers({ produto_info })
  }

  // FUNÇÃO - MUDA INFORMAÇÃO DO PRODUTO NO MENU
  const seeTooltip = () => {
    const unidade = unidadesObservaveis[0]
    tooltip = !tooltip
    // CRIA UM LISTENER PARA tooltip
    unidade.observavel.notifyObservers({ tooltip })
  }

  // FUNÇÃO - MUDA VISUALIZAÇÃO DO PRODUTO
  const changeViewProduct = () => {
    const unidade = unidadesObservaveis[0]
    produto_visivel = produto_visivel === 0.35 ? 0 : 0.35
    // CRIA UM LISTENER PARA produto_visivel
    unidade.observavel.notifyObservers({ produto_visivel })
  }

  // FUNÇÃO - MUDA INFORMAÇÃO DA ESCADA NO MENU
  const changeInfoStaircase = () => {
    const unidade = unidadesObservaveis[0]
    escada_visivel = escada_visivel === 1 ? 0 : 1
    // CRIA UM LISTENER PARA escada_visivel
    unidade.observavel.notifyObservers({ escada_visivel })
  }

  // FUNÇÃO - INICIAR CENA
  const IniciarCena = () => {
    // console.log('INICIAR CENA')
    // console.log(reactCanvas)
    // CRIA MOTOR GRÁFICO
    // const engine = new BABYLON.Engine(reactCanvas.current, false);
    const engine = new BABYLON.Engine(reactCanvas.current, true)
    // console.log('ENGINE')
    // console.log(engine)
    engine.enableOfflineSupport = false
    engine.doNotHandleContextLost = true

    // // console.log("PROPS FOR CENA")
    // // // console.log({
    //   1: engine,
    //   2: reactCanvas.current,
    //   3: props.building,
    //   4: changeInfoProduct,
    //   5: changeViewProduct,
    //   6: props.station,
    //   7: props.layout
    // })
    const values = CriarCena(
      engine,
      reactCanvas.current,
      props.building,
      changeInfoProduct,
      changeViewProduct,
      props.station,
      props.layout,
      changeInfoStaircase,
      props.unitInfo,
      props.subareaSelected.value,
      seeTooltip
    )
    // console.log('VALUES CRIAR CENA')
    // console.log(values)
    // CENA GERADA
    const scene = values[0]
    const cameraReset = values[1]
    camera = values[2]
    // VERIFICAR
    scene.useGeometryIdsMap = true
    scene.useMaterialMeshMap = true
    scene.useClonedMeshMap = true

    scene.clearCachedVertexData()
    scene.cleanCachedTextureBuffer()

    var advancedTextureTooltip = GUI.AdvancedDynamicTexture.CreateFullscreenUI(
      'UI-tooltip',
      true,
      scene
    )

    // cria painel tooltip
    painel = new GUI.StackPanel('tooltip')
    painel.width = window.innerHeight > 1080 ? '120px' : '100px'
    painel.height = window.innerHeight > 1080 ? '120px' : '100px'
    painel.isVertical = true
    painel.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER
    painel.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER
    painel.background = theme.colors.backgroundGrey
    painel.isVisible = false
    painel.checkCollisions = 1

    titlePanels3D = new GUI.TextBlock()
    titlePanels3D.text = ``
    titlePanels3D.color = 'black'
    titlePanels3D.fontWeight = 'bold'
    titlePanels3D.fontSize = 16
    titlePanels3D.lineSpacing = 2
    titlePanels3D.resizeToFit = true
    titlePanels3D.paddingTopInPixels = window.innerHeight > 1080 ? 15 : 5

    painel.addControl(titlePanels3D)

    advancedTextureTooltip.addControl(painel)

    scene.blockMaterialDirtyMechanism = true
    // ADICIONA A CENA NA FILA DE RENDERIZAÇÃO
    engine.runRenderLoop(() => {
      scene.render()
    })

    return scene
  }

  const createMotorIn3D = async (data) => {
    ;(motor3D = data.motor3D),
      (baseMotor3D = data.baseMotor3D),
      (conjuntoMotor3D = data.conjuntoMotor3D),
      (suporteDoMotor3D = data.suporteDoMotor3D)
  }

  // DESFAZ INSTANCIA CRIADA DO OBJETO PRODUTO
  const fncDisposeObjProduct = () => {
    if (objetoCamadaProduto !== null) {
      const filhos = objetoCamadaProduto.getChildren((val) => val)
      _.forEach(filhos, (val) => {
        val.dispose(true, true)
      })
      objetoCamadaProduto.dispose(true, true)
      objetoCamadaProduto = null
    }
  }

  // ELIMINA UMA CENA CRIADA
  const killScene = () => {
    if (scene !== null) {
      for (let i = 0; i < scene.meshes.length; i++) {
        scene.meshes[i].dispose(true, true)
        i--
      }
      scene.dispose(true, true)
      //scene = null;
      setScene(null)
    }
  }

  // ALTERA VISIBILIDADE DO PRODUTO
  const fncChangeViewInfo = (scene, visivel) => {
    if (scene) {
      _.forEach(scene.meshes, (val) => {
        if (val.name.includes('infoObjeto')) {
          // val.visibility = (val.visibility === 0 ? 0.5 : 0)
          val.visibility = 0
        }
      })
    }
  }

  // ALTERA VISIBILIDADE DO PRODUTO
  const fncChangeViewLevel = (scene, visivel) => {
    if (scene) {
      _.forEach(scene.meshes, (val) => {
        if (val.name === 'lofundoArmazemNivel') {
          val.visibility = visivel
        }

        if (val.name === 'nivelProduto') {
          val.visibility = visivel
        }
      })
    }
  }

  const fcnSeeTooltip = (data, scene, meshUnderPointer, { x, y, z }) => {
    if (data === 'remove') {
      if (painel) {
        painel.isVisible = false
      }

      if (titlePanels3D) {
        titlePanels3D.text = ''
      }
    } else {
      if (scene) {
        if (painel) {
          const innerWidth = window.innerWidth
          painel.isVisible = true
          if (x !== null && y !== null && z !== null) {
            // console.log(`x=${x} -- y=${y} -- z=${z}`)
            painel.moveToVector3(
              new BABYLON.Vector3(
                x,
                innerWidth > 1300
                  ? y >= 1
                    ? y + 3.5
                    : y - 4
                  : y >= 1
                  ? y + 5.5
                  : y - 5,
                z
              ),
              scene
            )
          }
        }

        if (titlePanels3D) {
          let temperature = 0
          if (data && data.temperature) {
            if (
              data.temperature !== 'N/D' &&
              data.temperature !== 'OFF' &&
              data.temperature !== 'ERR'
            ) {
              temperature = data.temperature + '°C'
            } else {
              temperature = data.temperature
            }
          }
          titlePanels3D.text =
            titlePanels3D.text = `ARCO: ${data.arc_index}\nCABO: ${data.cable_number}\nSENSOR: ${data.sensor_id}\nTemp: ${temperature}\n `
        }
      }
    }
  }

  const fcnSeeTooltipMotor = async (
    data,
    scene,
    meshUnderPointer,
    { x, y, z }
  ) => {
    if (data === 'remove') {
      if (painel) {
        painel.isVisible = false
        painel.width = window.innerHeight > 1080 ? '120px' : '100px'
      }

      if (titlePanels3D) {
        titlePanels3D.text = ''
      }
    } else {
      if (scene) {
        if (painel) {
          painel.width = window.innerHeight > 1080 ? '240px' : '220px'
          painel.isVisible = true
          if (x !== null && y !== null && z !== null) {
            // console.log(`x=${x} -- y=${y} -- z=${z}`)
            painel.moveToVector3(new BABYLON.Vector3(x, y + 4, z), scene)
          }
        }

        if (data.status !== null) {
          if (titlePanels3D) {
            titlePanels3D.fontSize = 12
            titlePanels3D.text = titlePanels3D.text = `${
              data.nameMotor
            }\nHorimetro: ${data.aeration}\n${getMsgOfStateMotor(
              data.status
            )}\n `
          }
        }
      }
    }
  }

  const getMsgOfStateMotor = (status) => {
    // console.log('status')
    // console.log(status)
    let msg = null
    switch (status) {
      case 1:
        msg = 'Motor em automático\nDesligado'
        break
      case 2:
        msg = 'Motor em manual\nDesligado'
        break
      case 3:
        msg =
          'Motor em automático\nLigado pelo software\nCom confirmação de motor ligado'
        break
      case 4:
        msg =
          'Motor em manual\nLigado pelo software\nCom confirmação de motor ligado'
        break
      case 5:
        msg =
          'Motor em automático\nLigado pelo software\nSem confirmação de motor ligado'
        break
      case 6:
        msg =
          'Motor em manual\nLigado pelo software\nSem confirmação de motor ligado'
        break
      case 7:
        msg =
          'Motor em bloqueio\nDesligado pelo software\nCom confirmação de motor ligado'
        break
      case 8:
        msg =
          'Motor em manual\nDesligado pelo software\nCom confirmação de motor ligado'
        break
      case 9:
        msg =
          'Motor em automático\nDesligado pelo software\nCom confirmação de motor ligado'
        break
      case 10:
        msg = 'Motor em bloqueio\nDesligado'
        break
      default:
        break
    }

    return msg
  }

  // ALTERA VISIBILIDADE DA ESCADA
  const fchChangeViewStaircase = (scene, visivel, numeroDeDegraus) => {
    if (scene) {
      _.forEach(scene.meshes, (val) => {
        if (val.name === 'tuboEscadaEsq') {
          val.visibility = visivel
        }

        if (val.name === 'tuboEscadaDiagonal') {
          val.visibility = visivel
        }

        if (val.name === 'guardaCorpoSuperiorDiagonal1') {
          val.visibility = visivel
        }

        if (val.name === 'guardaCorpoSuperiorDiagonal2') {
          val.visibility = visivel
        }

        if (val.name === 'guardaCorpoSuperiorDiagonal3') {
          val.visibility = visivel
        }

        if (val.name === 'guardaCorpoSuperiorDiagonal4') {
          val.visibility = visivel
        }

        if (val.name === 'guardaCorpoSuperiorDiagonal5') {
          val.visibility = visivel
        }

        if (val.name === 'guardaCorpoSuperiorDiagonal6') {
          val.visibility = visivel
        }

        if (val.name === 'tuboEscadaDiagonalDireita') {
          val.visibility = visivel
        }

        if (val.name === 'tuboEscadaDir') {
          val.visibility = visivel
        }

        if (val.name === 'plane0') {
          val.visibility = visivel
        }

        if (val.name === 'plane2') {
          val.visibility = visivel
        }

        if (val.name === 'plane3') {
          val.visibility = visivel
        }

        if (val.name === 'plane4') {
          val.visibility = visivel
        }

        if (val.name === 'polygon') {
          val.visibility = visivel
        }

        for (let i = 0; i < 6; i++) {
          if (val.name === `tuboHorizontalGuardaCorpoLateral${i}`) {
            val.visibility = visivel
          }
        }

        for (let i = 0; i < 3; i++) {
          if (val.name === `miniTuboHorizontalGuardaCorpo${i}`) {
            val.visibility = visivel
          }
        }

        for (let i = 0; i < 3; i++) {
          if (val.name === `tuboHorizontalGuardaCorpo${i}`) {
            val.visibility = visivel
          }
        }

        for (let i = 0; i < 7; i++) {
          if (val.name === `tuboHorizontalGuardaCorpoLateral${i}`) {
            val.visibility = visivel
          }
        }

        for (let i = 0; i < 7; i++) {
          if (val.name === `tuboVerticalGuardaCorpo${i}`) {
            val.visibility = visivel
          }
        }

        for (let i = 0; i < 7; i++) {
          if (val.name === `tuboHorizontalGuardaCorpo0${i}`) {
            val.visibility = visivel
          }
        }

        for (let i = 0; i < 7; i++) {
          if (val.name === `objetoApoioEsq${i}`) {
            val.visibility = visivel
          }
        }

        for (let i = 0; i < 7; i++) {
          if (val.name === `objetoApoioDir${i}`) {
            val.visibility = visivel
          }
        }

        for (let i = 0; i < 5; i++) {
          if (val.name === `tuboVerticalGuardaCorpoDiagonal${i}`) {
            val.visibility = visivel
          }
        }

        for (let i = 0; i < 5; i++) {
          if (val.name === `tuboVerticalGuardaCorpoDiagonalDireito${i}`) {
            val.visibility = visivel
          }
        }

        for (let i = 0; i < numeroDeDegraus - 1; i++) {
          if (val.name === `objetoDegrau${i}`) {
            val.visibility = visivel
          }
        }

        for (let i = 0; i < numeroDeDegraus - 1; i++) {
          if (val.name === `tuboEscadaDegrauDiagonal${i}`) {
            val.visibility = visivel
          }
        }

        for (let i = 0; i < 11; i++) {
          if (val.name === `objetoGuardaCorpoEscada${i}`) {
            val.visibility = visivel
          }
        }
      })
    }
  }

  // ESTADO - REACT CANVAS
  const reactCanvas = useRef(null)
  // ESTADO - CONTROLA SE JA FOI CARREGADO OU NÃO
  const [loaded, setLoaded] = useState(false)
  // ESTADO - RECEBE A CENA (OBJETO QUADRADO QUE CONTEM TODO O 3D)
  const [scene, setScene] = useState(null)

  const [readyForUpdateMotors, setReadyForUpdateMotors] = useState(false)
  const [prevAllMotorsById, setPrevAllMotorsById] = useState(null)
  const [paletaDeCores, setPaletaDeCores] = useState(null)
  const [haveReadings, setHaveReadings] = useState(true)

  useEffect(() => {
    setTimeout(function () {
      setReadyForUpdateMotors(true)
    }, 29000)
  }, [])

  // VERIFICA SE HÁ OU NÃO LEITURAS
  useEffect(() => {
    if (props && props.unitInfo && props.unitInfo.length > 0) {
      // console.log('VERIFICACAO')
      // console.log(props.unitInfo)
      let aux = props.unitInfo[0].ts
      if (aux === '') {
        setHaveReadings(false)
      } else {
        setHaveReadings(true)
      }
    }
  }, [props.unitInfo])

  useEffect(() => {
    if (props && props.layout && props.building.type === 9) {
      if (props.layout.arcs && props.layout.arcs.length > 0) {
        if (
          props.layout.arcs[0].cables &&
          props.layout.arcs[0].cables.length > 0
        ) {
          if (props.layout.arcs[0].cables.length > 1) {
            escada_visivel = 1
          } else {
            escada_visivel = 0
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    if (window) {
      const resize = () => {
        if (scene) {
          scene.getEngine().resize()
        }
      }
      window.addEventListener('resize', resize)

      return () => {
        if (scene) {
          scene.getEngine().dispose()
        }
        window.removeEventListener('resize', resize)
      }
    }
  }, [scene])

  useEffect(() => {
    // SE AINDA NÃO FOI CARREGADO
    if (!loaded) {
      //CleanVarsArma();
      //CleanVarsSilo();
      //fncDisposeObjProduct();
      //unidadesObservaveis = [];
      //killScene();
      // LIMPA ESTADOS E VARIAVEIS ANTERIORES
      CleanVarsArma()
      CleanVarsSilo()
      CleanVarsBunker()
      CleanVarsBunkerPlano()
      CleanVarsTulha()
      CleanVarsSiloElevado()
      CleanVarsArmaPlano()
      CleanVarsArmaSemiV()

      fncDisposeObjProduct()
      unidadesObservaveis = []
      killScene()

      // INICIA UMA NOVA CENA
      const scene = IniciarCena()
      setScene(scene)
      if (scene.isReady()) {
        props.onSceneReady(scene)
        // props.onSceneReady(scene)
      } else {
        // INVERTIGAR
        scene.onReadyObservable.addOnce((scene) => {
          if (props && props.onSceneReady) {
            props.onSceneReady(scene)
          }
        })
      }

      const data = CriaMotor(scene)

      const material = CriaPaletaDeCores(scene)

      if (material) {
        // console.log('criada paleta de cores')
        // console.log(material)
        setPaletaDeCores(material)

        if (data) {
          createMotorIn3D(data)
        }

        // FUNÇÃO QUE VAI CRIAR O ELEMENTO 3D DA UNIDADE (SILO, BUNKER, ETC...), CONFORME DADOS PASSADOS
        AdicionarUnidades(
          scene,
          props.layout,
          props.building,
          [],
          [],
          fncChangeViewInfo,
          objetoCamadaProduto,
          unidadesObservaveis,
          handleOnSetUnidadesObservaveis,
          produto_visivel,
          handleOnSetObjetoCamadaProduto,
          produto_info,
          fncChangeViewLevel,
          props.subareaSelected.value,
          grao,
          fchChangeViewStaircase,
          escada_visivel,
          fcnSeeTooltip,
          material.colorMotorStatus3D1,
          material.colorMotorStatus3D2,
          material.colorMotorStatus3D3,
          material.colorMotorStatus3D4,
          material.colorMotorStatus3D5,
          material.colorMotorStatus3D1Shaded,
          material.colorMotorStatus3D2Shaded,
          material.colorMotorStatus3D3Shaded,
          material.colorMotorStatus3D4Shaded,
          material.colorMotorStatus3D5Shaded,
          fcnSeeTooltipMotor,
          permissaoParaEditarOEstadoDosMotores,
          painelEditaMotoresEmUso,
          motor3D,
          baseMotor3D,
          conjuntoMotor3D,
          suporteDoMotor3D
        )
        scene.blockMaterialDirtyMechanism = false
        //scene.createOrUpdateSelectionOctree();
        //scene.debugLayer.show();
      }
    }

    return () => {
      if (scene) {
        scene.getEngine().dispose()
      }

      // LIMPA ESTADOS ANTERIORES
      CleanVarsArma()
      CleanVarsSilo()
      CleanVarsBunker()
      CleanVarsBunkerPlano()
      CleanVarsTulha()
      CleanVarsSiloElevado()
      CleanVarsArmaPlano()
      CleanVarsArmaSemiV()

      fncDisposeObjProduct()
      unidadesObservaveis = []
      killScene()
      if (scene !== null) {
        scene.dispose(true, true)
      }
    }
  }, [])

  // VARIAVEIS
  let escada_visivel = 1

  let painel = null
  let titlePanels3D = null

  let painelMotores = null
  let painelOnOffMotores = null
  let painelHeaderOnOff = null
  let btnStatusAuto = null
  let btnStatusManual = null
  let btnStatusBloqueado = null
  let textHeader = null
  let btnMotorOn = null
  let btnMotorOff = null

  let stateIdMotor = null
  let stateSubMotor = null
  let stateNameMotor = null
  let stateOperationMotor = null
  let stateOnOff = null

  let painelEditaMotoresEmUso = false

  let permissaoParaEditarOEstadoDosMotores = true

  let camera = null

  let motor3D = null
  let baseMotor3D = null
  let conjuntoMotor3D = null
  let suporteDoMotor3D = null

  let stateFinalizatedOperation = false

  // PROPRIEDADE REFERENTE A CAMADA DO PRODUTO
  let objetoCamadaProduto = null
  // PROPRIEDADE UNIDADES OBSERVAVEIS
  let unidadesObservaveis
  // PROPRIEDADE PRODUTO VISIVEL
  let produto_visivel = 0.35
  // PROPRIEDADE INFO PRODUTO
  let produto_info = '1'
  // TOOLTIP
  let tooltip = '1'

  // TIPO DE GRÂO
  let grao = 'Arroz'

  if (
    props &&
    props.layout &&
    props.layout.dataGrain &&
    props.layout.dataGrain.length > 0
  ) {
    grao = props.layout.dataGrain[0].nameGrain
  }

  return (
    <>
      {haveReadings === false ? (
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h5>Não há leituras disponíveis</h5>
        </div>
      ) : (
        <canvas
          id="renderCanvas"
          className="reactCanvas"
          style={{ width: '100%', height: '100%', minHeight: window.matchMedia('(max-width: 470px)').matches?'500px': '200px', borderRadius: '8px' }}
          ref={reactCanvas}
        />
      )}
    </>
  )
}

export default ThreeDimensionVisualization
