import React, { useState } from 'react';
import colors from '../../styles/colors';

export default (props) => {
  const { stFailEnergy } = props.status;

  const text = stFailEnergy === 0 ? 'ENERGIA OK' : 'FALHA DE ENERGIA';
  const [status, setStatus] = useState({
    textColor: colors.black,
    statusColor: colors.grey,
    backgroundColor: colors.white,
  });
  setTimeout(() => {
    setStatus(defineStatus());
  }, 1000);
  function defineStatus() {
    if (stFailEnergy) {
      // if (status.backgroundColor === colors.white) {
      return {
        textColor: colors.white,
        statusColor: colors.error,
        backgroundColor: colors.error,
      };
      /* } else {
        return {
          textColor: colors.black,
          statusColor: colors.grey,
          backgroundColor: colors.white,
        };
      } */
    }
    return {
      textColor: colors.white,
      statusColor: colors.pivotOk,
      backgroundColor: colors.pivotOk,
    };
  }

  return (
    <svg viewBox="0 0 500 500" width="100%">
      <defs>
        <grid x="0" y="0" width="122.653" height="92.449" />
      </defs>
      <path
        style={{ stroke: status.statusColor, fill: status.statusColor }}
        d="M 240.733 308.78 L 352.763 161.783 C 352.763 161.783 358.773 154.927 356.841 150.262 C 354.387 144.339 345.97 145.47 345.97 145.47 L 272.866 145.47 L 299.18 48.259 C 299.18 48.259 301.414 41.658 294.111 38.633 C 287.551 35.916 284.55 40.272 284.55 40.272 L 164.651 196.108 C 164.651 196.108 160.33 202.569 164.977 207.216 C 168.582 210.821 175.759 210.157 175.759 210.157 L 246.328 210.157 L 221.17 305.88 C 221.17 305.88 218.555 316.662 229.011 316.662 C 235.91 316.662 240.696 308.53 240.733 308.78 Z"
        id="ENERGIA"
      />
      <g id="ENERGIA_TEXT">
        <rect
          y="358.899"
          width="500.204"
          height="139.499"
          style={{ fill: status.backgroundColor, stroke: colors.grey }}
          rx="21.419"
          ry="21.419"
        />
        <text
          style={{
            fill: status.textColor,
            fontFamily: 'Roboto',
            fontWeight: 800,
            fontSize: '42px',
            textAnchor: 'middle',
            whiteSpace: 'pre',
          }}
          x="254.694"
          y="445.48"
        >
          {text}
        </text>
      </g>
    </svg>
  );
};
