import React from 'react';
import styled from 'styled-components';

const colors = {
  borda: '#C0C0C0',
  silo: '#D6E0E6', // '#CCCCCC',
  cable: '#556E7B', // 'teal',
  textColor: '#FFFFFF',
};

const StyledSVG = styled.svg`
  width: ${(props) => props.width};
  @media (max-width:480px){
    width: 260px !important;
  }
`;

export function FlatBottomSilo({ modal }) { // Silo Fundo Plano
  return (
    <div style={{ position: 'relative' }}>
      <StyledSVG
        width={modal ? '730px' : '360px'}
        height={modal ? '730px' : '360px'}
        xmlSpace="preserve"
        viewBox={modal ? '0 0 260 260' : '0 0 260 260'}
      >
        <polygon
          stroke={colors.borda}
          strokeWidth="0"
          fill={colors.silo}
          points="13,153 130,67 247,153 247,186 255,186 255,193 5,193 5,186 13,186 "
        />
      </StyledSVG>
    </div>
  );
}

export function ConicalBottomSilo({ modal }) { // Silo Fundo V
  return (
    <div style={{ position: 'relative' }}>
      <StyledSVG
        width={modal ? '730px' : '360px'}
        height={modal ? '730px' : '360px'}
        xmlSpace="preserve"
        viewBox={modal ? '0 0 260 260' : '0 0 260 260'}
      >
        <polygon
          stroke={colors.borda}
          strokeWidth="0"
          fill={colors.silo}
          points="13,153 130,67 247,153 247,186 255,186 255,193 247,193 130,235 13,193 5,193 5,186 13,186 "
        />
      </StyledSVG>
    </div>
  );
}
