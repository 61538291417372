/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import { number } from '../../../../../helpers/Intl';

export default (props) => {
  const [currentStrategy, setCurrentStrategy] = React.useState(1);
  const [strategyData, setStrategyData] = React.useState({});

  React.useEffect(() => {
    const peakHours = props.peakHours.data.find((f) => f.sub === currentStrategy);
    const rainAndPriority = props.rainAndPriority.data.find((f) => f.sub === currentStrategy);
    const alarms = props.alarms.data.find((f) => f.sub === currentStrategy);
    const hasMotors = (
      props.peakHours.subs.find((f) => f.sub === currentStrategy) || { motors: 0 }
    ).motors;
    setStrategyData({
      ...peakHours, ...rainAndPriority, ...alarms, hasMotors: !!hasMotors,
    });
  }, [currentStrategy]);

  const buildField = (i) => {
    if (i.switch) return buildSwitch(i);
    if (i.slider) return buildSlider(i);
    return buildTextValue(i);
  };

  const buildTextValue = (i) => (
    <Grid item xs={i.size || 12} key={i.title}>
      <h4 style={{
        width: '100%', fontWeight: '400', margin: '10px 0 0',
      }}
      >
        {i.title}
      </h4>
      <h3 style={{
        width: '100%', fontWeight: '500', margin: '0 0 5px',
      }}
      >
        {i.content}
      </h3>
    </Grid>
  );

  const buildSwitch = (i) => (
    <Grid item xs={i.size || 12} key={i.title}>
      <FormControlLabel
        control={<Switch checked={i.checked} onChange={() => {}} />}
        label={i.title}
      />
    </Grid>
  );

  const buildSlider = (i) => (
    <Grid item xs={i.size || 12} key={i.title}>
      <h4 style={{ fontWeight: '400' }}>{i.title}</h4>
      <Slider
        style={{ width: '80%', marginLeft: '1px' }}
        defaultValue={i.content}
        step={1}
        disabled
        onChange={() => {}}
        min={1}
        max={5}
        marks={[
          {
            value: 1,
            label: '1',
          },
          {
            value: 2,
            label: '2',
          },
          {
            value: 3,
            label: '3',
          },
          {
            value: 4,
            label: '4',
          },
          {
            value: 5,
            label: '5',
          },
        ]}
      />
    </Grid>
  );

  return (
    <Grid container spacing={3}>

      <Grid item xs={12} md={12} lg={4} style={{ height: '100%' }}>
        <Card variant="outlined" style={{ padding: '10px' }}>
          <h3 style={{ marginTop: '5px', textAlign: 'center', fontWeight: '400' }}>Subdivisão</h3>
          <FormControl variant="filled">
            <InputLabel>Subdivisão</InputLabel>
            <Select
              value={currentStrategy}
              onChange={(value) => {
                setCurrentStrategy(value.target.value);
              }}
            >
              {props.peakHours.subs.map((v) => (
                <MenuItem
                  key={v.sub}
                  value={v.sub}
                >
                  {`#${v.sub} - ${v.grain}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <hr style={{ border: 'none', borderTop: '1px solid #0000001f' }} />

          {[
            { title: 'Alarme por Temperatura', checked: !!strategyData.alarmTemperature, switch: true },
            { title: 'Limite de Temperatura', content: `${number(strategyData.limitTemperature, 1)}°C` },
          ].map(buildField)}

          {strategyData.hasMotors && [
            { title: 'Prioridade de Acionamento', content: strategyData.priority, slider: true },
            { title: 'Sensor de Chuva', checked: !!strategyData.rain, switch: true },
          ].map(buildField)}
        </Card>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <Card variant="outlined">
          <Grid container spacing={1} style={{ padding: '0 15px' }}>
            <Grid item xs={12}>
              <h3 style={{ margin: '5px 0 0', textAlign: 'center', fontWeight: '400' }}>
                Horário de Ponta 1
              </h3>
              <h3 style={{ marginTop: '0', textAlign: 'center', fontWeight: '400' }}>
                <small>{strategyData.peakTime1 && strategyData.peakTime1.active ? 'Habilitado' : 'Desabilitado'}</small>
              </h3>
            </Grid>

            {!strategyData.hasMotors && (
              <h3 style={{ width: '100%', textAlign: 'center', fontWeight: '500' }}>
                Não possui motores
              </h3>
            )}

            {strategyData.hasMotors && [
              { title: 'Início', content: strategyData.peakTime1 ? strategyData.peakTime1.start : '-', size: 6 },
              { title: 'Fim', content: strategyData.peakTime1 ? strategyData.peakTime1.finish : '-', size: 6 },
            ].map(buildTextValue)}

            {strategyData.hasMotors && [
              { title: 'Domingo', checked: !!(strategyData.peakTime1 && strategyData.peakTime1.sunday), size: 6 },
              { title: 'Segunda', checked: !!(strategyData.peakTime1 && strategyData.peakTime1.monday), size: 6 },
              { title: 'Terça', checked: !!(strategyData.peakTime1 && strategyData.peakTime1.tuesday), size: 6 },
              { title: 'Quarta', checked: !!(strategyData.peakTime1 && strategyData.peakTime1.thursday), size: 6 },
              { title: 'Quinta', checked: !!(strategyData.peakTime1 && strategyData.peakTime1.wednesday), size: 6 },
              { title: 'Sexta', checked: !!(strategyData.peakTime1 && strategyData.peakTime1.friday), size: 6 },
              { title: 'Sabado', checked: !!(strategyData.peakTime1 && strategyData.peakTime1.saturday), size: 6 },
            ].map(buildSwitch)}

          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <Card variant="outlined">
          <Grid container spacing={1} style={{ padding: '0 15px' }}>
            <Grid item xs={12}>
              <h3 style={{ margin: '5px 0 0', textAlign: 'center', fontWeight: '400' }}>
                Horário de Ponta 2
              </h3>
              <h3 style={{ marginTop: '0', textAlign: 'center', fontWeight: '400' }}>
                <small>{strategyData.peakTime2 && strategyData.peakTime2.active ? 'Habilitado' : 'Desabilitado'}</small>
              </h3>
            </Grid>

            {!strategyData.hasMotors && (
              <h3 style={{ width: '100%', textAlign: 'center', fontWeight: '500' }}>
                Não possui motores
              </h3>
            )}

            {strategyData.hasMotors && [
              { title: 'Início', content: strategyData.peakTime2 ? strategyData.peakTime2.start : '-', size: 6 },
              { title: 'Fim', content: strategyData.peakTime2 ? strategyData.peakTime2.finish : '-', size: 6 },
            ].map(buildTextValue)}

            {strategyData.hasMotors && [
              { title: 'Domingo', checked: !!(strategyData.peakTime2 && strategyData.peakTime2.sunday), size: 6 },
              { title: 'Segunda', checked: !!(strategyData.peakTime2 && strategyData.peakTime2.monday), size: 6 },
              { title: 'Terça', checked: !!(strategyData.peakTime2 && strategyData.peakTime2.tuesday), size: 6 },
              { title: 'Quarta', checked: !!(strategyData.peakTime2 && strategyData.peakTime2.thursday), size: 6 },
              { title: 'Quinta', checked: !!(strategyData.peakTime2 && strategyData.peakTime2.wednesday), size: 6 },
              { title: 'Sexta', checked: !!(strategyData.peakTime2 && strategyData.peakTime2.friday), size: 6 },
              { title: 'Sabado', checked: !!(strategyData.peakTime2 && strategyData.peakTime2.saturday), size: 6 },
            ].map(buildSwitch)}

          </Grid>
        </Card>
      </Grid>

    </Grid>
  );
};
