import React from 'react';
import { makeStyles } from '@material-ui/core';
import { BiCloudLightRain, GiHeavyRain, MdGrain, FiAlertTriangle, AiOutlinePlus } from 'react-icons/all';
import { InformationCard } from './InformationCard';
import { ButtonComponent as Button } from '../../../../../../components/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '0.8rem',
    marginBottom: '0.4rem',
    minHeight: '5rem',
    borderRadius: '6px',
    background: '#FFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  cards: {
    width: '100%',
    maxWidth: '550px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      marginBottom: '2rem',
    },
  },
}));

const legends = [
  {
    data: {
      title: 'Chuva',
      subtitle: 'Chuva no dia (mm)',
    },
    icon: BiCloudLightRain,
  },
  {
    data: {
      title: 'Irrigação',
      subtitle: 'Irrigação no dia (mm)',
    },
    icon: MdGrain,
  },
  {
    data: {
      title: 'Uniformidade',
      subtitle: 'Sem irrigação uniforme',
    },
    icon: FiAlertTriangle,
  },
  {
    data: {
      title: 'Previsão',
      subtitle: 'Previsão para os próximos dias',
    },
    icon: GiHeavyRain,
  },
];

export function HeaderTable({ quantityDays, setState }) {
  const classes = useStyles();

  const handleSetQuantityDateState = () => {
    // eslint-disable-next-line
    quantityDays < 15 && setState(quantityDays + 5);
  };

  return (
    <div className={classes.root}>
      <div className={classes.cards}>
        {legends.map((legend) => (
          <InformationCard key={legend.data.title} data={legend.data} icon={legend.icon} />
        ))}
      </div>
      <Button handle={handleSetQuantityDateState} isDisabled={quantityDays === 15}>
        <AiOutlinePlus />
        Ver mais
      </Button>
    </div>
  );
}
