import React from 'react';
import { BsPlus, BsFilter } from 'react-icons/bs';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { FiPlus } from 'react-icons/fi';
import { Tooltip } from '@material-ui/core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { ButtonComponent as Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal';
import {
  TDApproversTable,
  NewApproverForm,
  TelemetryFilters,
} from './components';
import FockinkAPI from '../../../../middlewares/Api';

import * as S from './styles';
import { handleMessageError } from '../../../../helpers/handlerMessageError';

export function TechDeliveryApprovers() {
  const [telemetry, setTelemetry] = React.useState([]);
  const [selectedRowToUpdate, setSelectedRowToUpdate] = React.useState();
  const [openNewModal, setOpenNewModal] = React.useState(false);
  const [getFilters, setGetFilters] = React.useState({
    system: [],
    groupName: '',
    email: '',
    status: 'A',
  });

  const [openFilterModal, setOpenFilterModal] = React.useState(false);

  const breadcrumb = {
    title: 'Aprovadores de Entrega Técnica',
    routes: [
      { name: 'Configurações', to: '/settings' },
      { name: 'Aprovadores de Entrega Técnica' },
    ],
  };

  const MySwal = withReactContent(Swal);
  const handleGetTelemetry = async (f) => {
    const response = await FockinkAPI.get('/techDeliveryApprovers/', {
      params: f || getFilters,
    });
    setTelemetry(response.data.data);
  };

  React.useEffect(() => {
    (async () => {
      await handleGetTelemetry();
    })();
  }, []);

  const handleIOpenFilterModal = () => setOpenFilterModal(true);
  const handleICloseFilterModal = () => setOpenFilterModal(false);
  const handleCloseNewModal = () => {
    setOpenNewModal(false);
  };

  const handleUpdateTelemetryModal = (row) => {
    setOpenNewModal(true);
    setSelectedRowToUpdate(row);
  };

  const handleDeleteVariable = async (values) => {
    try {
      MySwal.fire({
        title: `Deseja inativar ${values.groupName}? `,
        text: 'O grupo inativado ficará disponível na listagem de inativos',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Inativar',
        cancelButtonText: 'Cancelar Ação',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await FockinkAPI.delete(
            `/techDeliveryApprovers/${values.id}?status=I`,
          );
          toast.success('Inativado');
          handleGetTelemetry();
        }
      });
    } catch (error) {
      handleMessageError(error);
    }
  };

  const handleActiveVariable = async (values) => {
    try {
      MySwal.fire({
        title: `Deseja reativar ${values.groupName}? `,
        text: 'O grupo ativado ficará disponível na listagem padrão',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Reativar',
        cancelButtonText: 'Cancelar Ação',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await FockinkAPI.delete(
            `/techDeliveryApprovers/${values.id}?status=A`,
          );
          toast.success('Reativado');
          handleGetTelemetry();
        }
      });
    } catch (error) {
      handleMessageError(error);
    }
  };

  const aditionalButtons = [
    {
      name: 'Ações',
      selector: 'action',
      // center: true,
      cell: (row) => (
        <>
          {row.status === 'A' && (
            <Tooltip title="Editar">
              <button
                type="button"
                style={{ color: '#2F80ED' }}
                onClick={() => handleUpdateTelemetryModal(row)}
              >
                <EditIcon size={18} />
              </button>
            </Tooltip>
          )}
          <Tooltip title={row.status === 'A' ? 'Inativar' : 'Ativar'}>
            {row.status === 'A' ? (
              <button type="button" style={{ color: '#2F80ED' }} onClick={() => handleDeleteVariable(row)}>
                <DeleteIcon size={18} />
              </button>
            ) : (
              <button type="button" style={{ color: '#2F80ED' }} onClick={() => handleActiveVariable(row)}>
                <FiPlus size={18} />
              </button>
            )}
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <S.Wrapper>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />

      <div className="button-new">
        <Button
          icon={<BsFilter />}
          style={{ marginRight: '5px' }}
          handle={() => handleIOpenFilterModal(true)}
        >
          Aplicar Filtros
        </Button>
        <Button
          color="secondary"
          icon={<BsPlus />}
          handle={() => {
            setSelectedRowToUpdate(undefined);
            setOpenNewModal(true);
          }}
        >
          Novo grupo de aprovadores
        </Button>
      </div>

      <Modal
        title="Aplicar filtros"
        open={openFilterModal}
        handleCloseModal={handleICloseFilterModal}
      >
        <TelemetryFilters
          handleSubmit={(a) => {
            setGetFilters(a);
            handleGetTelemetry(a);
          }}
          closeModal={handleICloseFilterModal}
          state={getFilters}
          setState={setGetFilters}
        />
      </Modal>

      <Modal
        title="Grupo de Aprovadores"
        open={openNewModal}
        handleCloseModal={handleCloseNewModal}
      >
        <NewApproverForm
          updateData={selectedRowToUpdate}
          handleGetTelemetry={handleGetTelemetry}
          handleCloseNewModal={handleCloseNewModal}
        />
      </Modal>

      <TDApproversTable data={telemetry} aditionalButtons={aditionalButtons} />
    </S.Wrapper>
  );
}
