import React, { useState, useEffect } from 'react';
import { Button, Card, CardContent, Grid, IconButton, TextField } from '@material-ui/core';
import { MdCloudUpload, MdDelete } from 'react-icons/md';
import DataTable from 'react-data-table-component';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Skeleton } from '@material-ui/lab';
import Breadcrumbs from '../../../components/Breadcrumbs';
import BackButton from '../../../components/Buttons/BackButton';
import { DeleteFile, EditFeedBack, ReadFeedBack } from '../helpers/callApi';
import SaveButton from '../../../components/Buttons/SaveButton';
import { handleMessageError } from '../../../helpers/handlerMessageError';

export default function EditFeedback(props) {
  const idFeedback = props?.match?.params?.id;
  const [returnToList, setReturnToList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState('');
  const [feedBack, setFeedBack] = useState('');
  const [sourcePage, setSourcePage] = useState('');
  const [attachment, setAttachment] = useState([]);
  const [filesAttachment, setFilesAttachment] = useState([]);
  const MySwal = withReactContent(Swal);
  const [attachmentList, setAttachmentList] = useState([]);

  const breadcrumb = {
    title: 'Editando Fale Conosco',
    routes: [
      { to: '/myfeedbacks', name: 'Fale Conosco' },
      { name: `'Editando' - ${idFeedback}` },
    ],
  };

  const collumnsAttachment = [
    {
      name: 'Anexo',
      selector: 'file',
      sortable: true,
      grow: 2,
    },
    {
      name: 'Ações',
      selector: 'actions',
      sortable: true,
    },
  ];

  if (returnToList) {
    /* return <Redirect push to="/myfeedbacks" />; */
    window.location.href = '/myfeedbacks';
  }

  async function feedbackRead() {
    const response = await ReadFeedBack({
      feedbackId: idFeedback,
    });

    if (response) {
      setUser(response?.conversation[0]?.user?.name);
      setSourcePage(response?.sourcePage);
      setFeedBack(response?.conversation[0]?.message);
      setAttachment(response?.files);
      setLoading(false);
    }
  }

  useEffect(() => {
    feedbackRead();
  }, []);

  async function handleDelete({ feedbackId, file }) {
    const response = await DeleteFile({
      feedbackId,
      file,
    });
    if (response) {
      feedbackRead();
    } else {
      handleMessageError('Ops! parece que não foi possivel deletar este registro.');
    }
  }

  function deleteImage(file) {
    return (
      <IconButton
        onClick={
      async () => {
        MySwal.fire({
          title: 'Tem certeza?',
          text: 'Essa ação não poderá ser revertida',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Sim, APAGAR!',
          cancelButtonText: 'Não, MANTER',
        }).then((result) => {
          (result.isConfirmed) &&
          (
            handleDelete({ feedbackId: idFeedback, file })
          );
        });
      }
      }
      >
        <MdDelete color="#556E7B" size={22} />

      </IconButton>
    );
  }

  useEffect(() => {
    if (attachment) {
      const list = [];
      attachment.forEach((item) => {
        list.push({
          file: item,
          actions: deleteImage(item),
        });
      });
      setAttachmentList(list);
    }
  }, [attachment]);

  const save = async () => {
    const sendData = {
      message: feedBack,
    };

    const formData = new FormData();
    formData.append('uploadedFile', filesAttachment);
    formData.append('message', feedBack);

    let response = '';

    response = !filesAttachment ?
      (
        await EditFeedBack({
          feedbackId: idFeedback,
          sendData,
        })
      )
      :
      (
        await EditFeedBack({ feedbackId: idFeedback, sendData: formData })
      );

    if (response) {
      setReturnToList(true);
    }
  };

  return (
    <div>
      <Breadcrumbs
        routes={breadcrumb.routes}
        title={`${breadcrumb.title}`}
      />
      {
        loading ?
          (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Skeleton width="100%" height="300px" />
                </Grid>
              </Grid>
            </>
          )
          :
          (
            <>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        variant="outlined"
                        name="user"
                        value={user}
                        label="Usuário"
                        InputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <TextField
                        variant="outlined"
                        name="sourcePage"
                        value={sourcePage}
                        label="Tela"
                        InputProps={{ readOnly: true }}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        name="feedBack"
                        onChange={(e) => { setFeedBack(e.target.value); }}
                        value={feedBack}
                        label="Comentários"
                        multiline
                        minRows={10}
                        maxRows={10}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} lg={10}>
                          <DataTable
                          // pagination
                          // paginationComponentOptions={{
                          //   rowsPerPageText: 'Linhas:',
                          //   rangeSeparatorText: 'de',
                          // }}
                          // paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
                            noDataComponent="Não existem anexos para serem exibidos"
                            columns={collumnsAttachment}
                            data={attachmentList}
                            title=""
                          // paginationPerPage={10}
                            style={{ marginTop: -50 }}
                          />
                        </Grid>
                        <Grid item xs={12} lg={2}>
                          <input
                            accept="image/*"
                            // value={attachment}
                            onChange={(e) => {
                              if (attachment.length === 0) {
                                setFilesAttachment(e.target.files[0]);
                                setAttachment([e.target.value]);
                              } else {
                                setAttachment([...attachment, e.target.value]);
                                if (filesAttachment.length === 0) {
                                  setFilesAttachment(e.target.files[0]);
                                } else {
                                  setFilesAttachment(...filesAttachment, e.target.files[0]);
                                }
                              }
                            }}
                            id="icon-button-file"
                            type="file"
                            style={{
                              display: 'none',
                            }}
                          />
                          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                          <label htmlFor="icon-button-file">
                            <Button
                              variant="contained"
                              color="primary"
                              component="span"
                              startIcon={<MdCloudUpload size={22} />}
                              fullWidth
                            >
                              New Upload
                            </Button>
                          </label>

                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>

              <div style={{ marginTop: 20, display: 'flex', justifyContent: 'right' }}>

                <BackButton
                  back={() => setReturnToList(true)}
                />

                <SaveButton save={save} style={{ marginLeft: 20 }} />

              </div>
            </>
          )
      }

    </div>

  );
}
