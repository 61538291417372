import React from 'react';

const colors = {
  borda: '#C0C0C0',
  silo: '#D6E0E6', // '#CCCCCC',
  cable: '#556E7B', // 'teal',
  textColor: '#FFFFFF',
};

export function FlatBottomSilo({ modal }) { // Tulha Fundo Plano
  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={modal ? '362px' : '143px'}
        height={modal ? '700px' : '311px'}
        xmlSpace="preserve"
        viewBox={modal ? '0 0 362 700' : '0 0 83 161'}
      >
        <polygon
          stroke={colors.borda}
          strokeWidth="0"
          fill={colors.silo}
          points={modal ? '0,-2 362,-2 362,698 320,698 320,519 41,519 41,698 0,698' : '0,0 83,0 83,161 73,161 73,120 9,120 9,161 0,161'}
        />
      </svg>
    </div>
  );
}

export function ConicalBottomSilo({ modal }) { // Tulha Fundo V
  return (
    <div style={{ position: 'relative' }}>
      <svg
        width={modal ? '362px' : '143px'}
        height={modal ? '700px' : '311px'}
        xmlSpace="preserve"
        viewBox={modal ? '0 0 362 700' : '0 0 83 161'}
      >
        <polygon
          stroke={colors.borda}
          strokeWidth="0"
          fill={colors.silo}
          points={modal ? '0,0 362,0 362,700 320,700 320,543 181,632 41,543 41,700 0,700' : '0,0 83,0 83,161 73,161 73,125 41,145 9,125 9,161 0,161 '}
        />
      </svg>
    </div>
  );
}
