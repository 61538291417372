import styled from 'styled-components';

export const Wrapper = styled.div`
 
`;

export const Content = styled.main`
  display: flex;
  flex: 1;
  margin-top: 2rem;
  background: #FFF;
  align-items: center;
  justify-content: space-around;

  .diver {
    height: 100%;
    width: 1px;
    margin: 0 1rem;
    background: black;
  }
  @media screen and (min-width: 800px) {
    max-height: 500px;

  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    padding: 0 1rem;
    border-radius: 0.4rem;

  }
  form {
    width: 100%;
    max-width: 720px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.6rem;

    h2 {
      margin-top: 0;
      font-weight: normal;
    }

    .success {
      color: #07AC10;
      margin-top: 1rem;
      font-weight: 500;

    }

    .color-picker {
      margin-top: 1rem;
    }

    .confirm-button {
      display: block;
      margin-left:auto;
    }
  }
`;

export const ColorButton = styled.button`
  margin-top: 0.6rem;
  background: transparent;
  margin-bottom: 1.2rem;
  transition: filter 0.2s;
  svg {
    margin-right: 0.5rem;
  }


  &:hover {
    filter: brightness(0.1)
  }
`;

export const ErrorText = styled.strong`
  color: #c53030;
  margin-top: 0.7rem;
`;

export const DivCultures = styled.div`
  width: 100%;
  height: 100%;
  max-width: 400px;
  padding: 0.6rem;
  border-radius: 0.4rem;
  overflow-y: scroll;

  @media screen and (max-width: 800px) {
    margin-top: 0.8rem
  }

  h2 {
    margin-top: 0;
    font-weight: normal;
  }
`;
