import React from 'react';
import { Grid } from '@material-ui/core';

export function GridItem({ children, style }) {
  return (
    <Grid item lg={2} xs={12} md={3} sm={12} style={style}>
      {children}
    </Grid>
  );
}
