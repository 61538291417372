import React, { useState } from 'react';
import colors from '../../styles/colors';

export default (props) => {
  const { stPressurizedPivot, stFailLowPressure } = props.status;

  const text =
    stPressurizedPivot === 0
      ? stFailLowPressure
        ? 'FALHA DE PRESSÃO'
        : 'SEM PRESSÃO'
      : stFailLowPressure
        ? 'FALHA DE PRESSÃO'
        : 'COM PRESSÃO';
  const [status, setStatus] = useState({
    textColor: colors.black,
    statusColor: colors.black,
    backgroundColor: colors.white,
  });

  setTimeout(() => {
    setStatus(defineStatus());
  }, 1000);

  function defineStatus() {
    if (stFailLowPressure === 1) {
      // if (status.backgroundColor === colors.white) {
      return {
        textColor: colors.white,
        statusColor: colors.error,
        backgroundColor: colors.error,
      };
      /* } else {
        return {
          textColor: colors.black,
          statusColor: colors.black,
          backgroundColor: colors.white,
        };
      } */
    }
    if (stPressurizedPivot === 1) {
      return {
        textColor: colors.white,
        statusColor: colors.pivotOk,
        backgroundColor: colors.pivotOk,
      };
    }
    return {
      textColor: colors.black,
      statusColor: colors.black,
      backgroundColor: colors.white,
    };
  }

  return (
    <svg viewBox="0 0 500 500" width="100%">
      <defs>
        <grid x="0" y="0" width="122.653" height="92.449" />
      </defs>
      <g id="PRESS_TEXT">
        <rect
          y="358.899"
          width="500.204"
          height="139.499"
          style={{ fill: status.backgroundColor }}
          rx="21.419"
          ry="21.419"
        />
        <text
          style={{
            fill: status.textColor,
            fontFamily: 'Roboto',
            fontWeight: 800,
            fontSize: '42px',
            textAnchor: 'middle',
            whiteSpace: 'pre',
          }}
          x="254.694"
          y="445.48"
        >
          {text}
        </text>
      </g>
      <g id="PRESS">
        <circle
          style={{ strokeWidth: '4px', stroke: status.statusColor, fill: colors.grey }}
          cx="254.428"
          cy="174.84"
          r="167.202"
        />
        <circle
          style={{ fill: status.statusColor }}
          cx="254.007"
          cy="175.859"
          r="44.671"
        />
        <circle
          style={{ fill: status.statusColor }}
          cx="111.785"
          cy="170.354"
          r="14.63"
        />
        <circle
          style={{ fill: status.statusColor }}
          cx="397.495"
          cy="170.354"
          r="14.63"
        />
        <path
          style={{
            stroke: colors.white,
            strokeWidth: '6px',
            fill: status.statusColor,
          }}
          d="M 1185.764 398.153 A 179.106 179.106 0 1 1 827.561 400 L 1006.667 400 Z"
          transform="matrix(1, 0, 0, 1, -753.157532, -224.830994)"
          shape="pie 1006.667 400 0 179.106 89.409 270 1@c119c0b2"
        />
        <rect
          x="247.85"
          y="47.251"
          width="12.998"
          height="119.716"
          style={{ fill: status.statusColor }}
          rx="6.499"
          ry="6.499"
          transform="matrix(0.976516, 0.215447, -0.215447, 0.976516, 41.623547, -54.378963)"
        />
        <circle
          style={{ fill: status.statusColor }}
          cx="254.17"
          cy="30.319"
          r="14.63"
        />
        <circle
          style={{ fill: status.statusColor }}
          cx="127.488"
          cy="103.446"
          r="14.63"
        />
        <circle
          style={{ fill: status.statusColor }}
          cx="381.1"
          cy="103.446"
          r="14.63"
        />
        <circle
          style={{ fill: status.statusColor }}
          cx="336.225"
          cy="54.311"
          r="14.63"
        />
        <circle
          style={{ fill: status.statusColor }}
          cx="173.53"
          cy="54.311"
          r="14.63"
        />
      </g>
    </svg>
  );
};
