/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import XLSX from 'xlsx-js-style'; // Exportar em Excel
import moment from 'moment';
// import jwtDecode from 'jwt-decode';
import { square } from '../../../../../../components/AuxExcelExport/index'; // Exportar em Excel
import { secondsToHm, number, formatDate } from '../../../../../../helpers/Intl';
import { normalizeErrorData } from '../../../../../../helpers/normalizeErrorData';

export default ({
  reportHeader, reportGrains, reportMotors, reportType, selectedGrain,
  reportData, equipName, selectedDate, groupedData, accountData,
}) => {
  // const [loading, setLoading] = useState(true);
  // const [reportLogs, setReportLogs] = useState([]);
  // const token = localStorage.getItem('@FockinkAPP:token');
  // const { unit } = jwtDecode(token);
  const [mDS, setMDS] = useState();

  const eqpTypes = {
    1: 'Silo',
    2: 'Silo',
    3: 'Armazém',
    4: 'Armazem',
    5: 'Armazém',
    6: 'Armazém',
    7: 'Armazém',
    8: 'Armazém',
    9: 'Silo',
    10: 'Silo',
    11: 'Silo',
    12: 'Tulha',
    13: 'Tulha',
    14: 'Bunker',
    15: 'Bunker',
    16: 'Armazém',
    17: 'Armazém',
  };

  const formatHr = (diffDateSecs) => {
    if (diffDateSecs) {
      const max = Math.max(...Object.values(diffDateSecs));
      return secondsToHm(max / 1000).replace('h', ':').replace('m', '');
    }
    return '';
  };

  const formatMotorsSub = (motor) => {
    if (motor) {
      const ret = Object.values(motor).reduce((a, b) =>
        ({ on: a.on + b.on, has: a.has + b.has }), { on: 0, has: 0 });
      return ret;
    }
    return { on: 0, has: 0 };
  };

  const getTotalPercentageMotorsActive = (count, base) => {
    const percentage = number((count / base) * 100, 2, 2);
    return `${percentage}%`;
  };

  const getAerationData = (k) => selectedGrain[k]
    ? reportHeader[k].aerationHoursPerGrain[selectedGrain[k]]
    : reportHeader[k].hoursPerYear;

  const buildExcel = () => {
    const aux = [];
    const rdKeys = Object.keys(reportData);
    if (rdKeys.length > 0) {
      // const titleExcel = 'Quantidade de Pontos';
      rdKeys.forEach((key) => {
        const eqpName = equipName(key);
        const motorsKeys = Object.keys(reportMotors[key].perHP);
        /** ** inicia o preencimento do header para excel *** */

        const header = [
          new Array(22).fill(0).map(() => square({})),
          [
            square({}),
            square({ value: accountData.description, fill: '244062', borderColor: '000000', fontColor: 'FFFFFF', fontSize: 15 }),
            square({ fill: '244062', borderColor: '000000', fontColor: 'FFFFFF', fontSize: 24 }),
            square({ fill: '244062', borderColor: '000000', fontColor: 'FFFFFF', fontSize: 24 }),
            square({ value: 'Controle de Aeração', fill: '244062', borderColor: '000000', fontColor: 'FFFFFF', fontSize: 24 }),
            ...new Array(17).fill(0).map(() => square({ fill: '244062', borderColor: '000000' })),
          ],
          [
            square({}),
            ...new Array(21).fill(0).map(() => square({ fill: '244062', borderColor: '000000' })),
          ],
          [
            square({}),
            ...new Array(21).fill(0).map(() => square({ fill: '244062', borderColor: '000000' })),
          ],
          [
            square({}),
            ...new Array(21).fill(0).map(() => square({ fill: '244062', borderColor: '000000' })),
          ],
          [
            square({}),
            ...new Array(21).fill(0).map(() => square({ fill: '244062', borderColor: '000000' })),
          ],

          new Array(22).fill(0).map(() => square({})),

          [
            square({}),
            square({ value: 'CONTROLE DE HORAS POR MÊS', fill: 'D9D9D9', borderColor: '000000' }),
            ...new Array(14).fill(0).map(() => square({ fill: 'D9D9D9', borderColor: '000000' })),
            square({ value: 'POSTO', fill: 'D9D9D9', borderColor: '000000' }),
            ...new Array(5).fill(0).map(() => square({ fill: 'D9D9D9', borderColor: '000000' })),
          ],

          [
            square({}),
            ...['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez', 'Total de Horas Ano', '', ''].map((m) => square({ value: m, fill: 'D9D9D9', borderColor: '000000' })),
            square({ value: eqpName, fill: 'FFFFCB', borderColor: '000000', fontColor: '244062', fontSize: 24 }),
            ...new Array(5).fill(0).map(() => square({ fill: 'FFFFCB', borderColor: '000000' })),
          ],

          [
            square({}),
            ...new Array(12).fill(0).map((t, i) => square({ value: secondsToHm(reportHeader[key].hoursPerMonth[i]), borderColor: '000000', bold: false })),
            square({ value: secondsToHm(reportHeader[key].hoursPerYear), borderColor: '000000', bold: false }),
            square({ borderColor: '000000' }),
            square({ borderColor: '000000' }),
            ...new Array(6).fill(0).map(() => square({ fill: 'FFFFCB', borderColor: '000000' })),
          ],

          [
            square({}),
            ...new Array(15).fill(0).map(() => square({ borderColor: '000000' })),
            ...new Array(6).fill(0).map(() => square({ fill: 'FFFFCB', borderColor: '000000' })),
          ],

          [
            square({}),
            square({ value: 'Total Dias Aerados', fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: `Horas Aeração (${selectedGrain[key] || 'Todos'})`, fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Total Horas Aeradas após Ajuste', fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'TEMPERATURA', fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'UMIDADE RELATIVA', fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'MOTORES', fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            ...new Array(6).fill(0).map(() => square({ fill: 'FFFFCB', borderColor: '000000' })),
          ],

          [
            square({}),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),

            square({ value: 'Início (média)', fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Fim (média)', fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Média (total)', fill: 'D9D9D9', borderColor: '000000' }),

            square({ value: 'Início (média)', fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Fim (média)', fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Média (total)', fill: 'D9D9D9', borderColor: '000000' }),

            square({ value: 'Média (total)', fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Percentual (utilizado)', fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            ...new Array(6).fill(0).map(() => square({ fill: 'FFFFCB', borderColor: '000000' })),
          ],

          [
            square({}),
            square({ value: number(reportHeader[key].daysWithAeration), borderColor: '000000', bold: false }),
            square({ value: secondsToHm(getAerationData(key)), borderColor: '000000', bold: false }),
            square({ borderColor: '000000' }),
            square({ value: secondsToHm(getAerationData(key) * (reportHeader[key].engines.percent / 100)), borderColor: '000000', bold: false }),
            square({ borderColor: '000000' }),

            square({ value: number(reportHeader[key].temp.start), borderColor: '000000', bold: false }),
            square({ value: number(reportHeader[key].temp.finish), borderColor: '000000', bold: false }),
            square({ value: number(reportHeader[key].temp.avg), borderColor: '000000', bold: false }),

            square({ value: number(reportHeader[key].humid.start), borderColor: '000000', bold: false }),
            square({ value: number(reportHeader[key].humid.finish), borderColor: '000000', bold: false }),
            square({ value: number(reportHeader[key].humid.avg), borderColor: '000000', bold: false }),

            square({ value: number(reportHeader[key].engines.avgOn), borderColor: '000000', bold: false }),
            square({ value: `${number(reportHeader[key].engines.percent)}%`, borderColor: '000000', bold: false }),
            square({ borderColor: '000000' }),
            square({ borderColor: '000000' }),
            ...new Array(6).fill(0).map(() => square({ fill: 'FFFFCB', borderColor: '000000' })),
          ],

          [
            square({}),
            square({ value: 'Ventiladores', fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Ano', fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Produto', fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Local', fill: 'D9D9D9', borderColor: '000000' }),
            ...new Array(10).fill(0).map(() => square({ fill: 'D9D9D9', borderColor: '000000' })),
          ],

          [
            square({}),
            square({ value: 'Quantidade:', borderColor: '000000', textAlign: 'right', borderOn: [0, 0, 0, 1] }),
            square({ value: reportMotors[key].total, bold: false }),
            square({ }),
            square({ value: selectedDate || moment().year(), borderColor: '000000', fontColor: '244062', fontSize: 20 }),
            ...new Array(3).fill(0).map(() => square({ borderColor: '000000' })),
            square({ value: selectedGrain[key] || 'Todos', borderColor: '000000', fontSize: 15 }),
            ...new Array(2).fill(0).map(() => square({ borderColor: '000000' })),
            square({ value: eqpTypes[reportType[key].unitType] || 'Tipo Desconhecido', borderColor: '000000' }),
            square({ borderColor: '000000' }),
            square({ value: 'N°', textAlign: 'right' }),
            ...new Array(5).fill(0).map(() => square({ borderColor: '000000', bold: false, borderOn: [0, 0, 1, 0] })),
            ...new Array(3).fill(0).map(() => square({ borderColor: '000000', borderOn: [0, 1, 0, 0] })),
          ],

          [
            square({}),
            square({ value: 'Potência:', borderColor: '000000', textAlign: 'right', borderOn: [0, 0, 0, 1] }),
            square({ value: motorsKeys.map((k) => (
              `${reportMotors[key].perHP[k]} x ${k}cv`
            )).join(' / '),
            bold: false }),
            square({ }),
            ...new Array(9).fill(0).map(() => square({ borderColor: '000000' })),
            square({ value: 'N°', textAlign: 'right' }),
            ...new Array(5).fill(0).map(() => square({ borderColor: '000000', bold: false, borderOn: [0, 0, 1, 0] })),
            ...new Array(3).fill(0).map(() => square({ borderColor: '000000', borderOn: [0, 1, 0, 0] })),
          ],

          [
            square({}),
            square({ borderColor: '000000', borderOn: [0, 0, 0, 1] }),
            square({ }),
            ...new Array(5).fill(0).map(() => square({ borderColor: '000000' })),
            square({ value: reportGrains[key].join(', '), borderColor: '000000', bold: false }),
            ...new Array(4).fill(0).map(() => square({ borderColor: '000000' })),
            square({ value: 'N°', textAlign: 'right' }),
            ...new Array(5).fill(0).map(() => square({ borderColor: '000000', bold: false, borderOn: [0, 0, 1, 0] })),
            ...new Array(3).fill(0).map(() => square({ borderColor: '000000', borderOn: [0, 1, 0, 0] })),
          ],

          [
            square({}),
            square({ borderColor: '000000', borderOn: [0, 0, 0, 1] }),
            square({ }),
            ...new Array(19).fill(0).map(() => square({ borderColor: '000000', borderOn: [0, 1, 1, 1] })),
          ],

          [
            square({}),
            square({ value: 'Operação', fill: 'D9D9D9', borderColor: '000000' }),
            ...new Array(6).fill(0).map(() => square({ fill: 'D9D9D9', borderColor: '000000' })),
            square({ value: 'Características do Ar', fill: 'D9D9D9', borderColor: '000000' }),
            ...new Array(3).fill(0).map(() => square({ fill: 'D9D9D9', borderColor: '000000' })),
            square({ value: 'Potência / Total de Motores / Percentual Utilizado', fill: 'D9D9D9', borderColor: '000000', fontSize: 11 }),
            ...new Array(9).fill(0).map(() => square({ fill: 'D9D9D9', borderColor: '000000' })),
          ],

          [
            square({}),
            square({ value: 'Seq.', fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Produto', fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Local', fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Data', fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'HORA', fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'TEMPERATURA', fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'UMIDADE RELATIVA', fill: 'D9D9D9', borderColor: '000000' }),
            ...new Array(11).fill(0).map(() => square({ fill: 'D9D9D9', borderColor: '000000' })),
          ],

          [
            square({}),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),
            square({ fill: 'D9D9D9', borderColor: '000000' }),

            square({ value: 'Início', fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Fim', fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Total', fill: 'D9D9D9', borderColor: '000000' }),

            square({ value: 'Início', fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Fim', fill: 'D9D9D9', borderColor: '000000' }),

            square({ value: 'Início', fill: 'D9D9D9', borderColor: '000000' }),
            square({ value: 'Fim', fill: 'D9D9D9', borderColor: '000000' }),

            square({ value: 'Nº Mot. Ligados', fill: 'D9D9D9', borderColor: '000000' }),

            ...motorsKeys.reduce((a) => {
              a.push(...[
                square({ value: 'Qtd.', fill: 'D9D9D9', borderColor: '000000' }),
                square({ value: 'Pot.', fill: 'D9D9D9', borderColor: '000000' }),
              ]);
              return a;
            }, []),

            ...(motorsKeys.length < 4 ? new Array(4 - motorsKeys.length).fill(0).reduce((a) => {
              a.push(...[
                square({ value: 'Qtd.', fill: 'D9D9D9', borderColor: '000000' }),
                square({ value: 'Pot.', fill: 'D9D9D9', borderColor: '000000' }),
              ]);
              return a;
            }, []) : []),

            square({ value: 'Total', fill: 'D9D9D9', borderColor: '000000' }),

          ],

          [
            square({}),
            ...new Array(12).fill(0).map(() => square({ fill: 'D9D9D9', borderColor: '000000' })),
            ...motorsKeys.reduce((a, b) => {
              a.push(...[
                square({ value: reportMotors[key].perHP[b], fill: 'D9D9D9', borderColor: '000000' }),
                square({ value: `${b} cv`, fill: 'D9D9D9', borderColor: '000000' }),
              ]);
              return a;
            }, []),

            ...(motorsKeys.length < 4 ? new Array(4 - motorsKeys.length).fill(0).reduce((a) => {
              a.push(...[
                square({ value: '-', fill: 'D9D9D9', borderColor: '000000' }),
                square({ value: '-', fill: 'D9D9D9', borderColor: '000000' }),
              ]);
              return a;
            }, []) : []),

            square({ value: reportMotors[key].total, fill: 'D9D9D9', borderColor: '000000' }),
          ],

          ...(selectedGrain[key]
            ? groupedData[key].filter((uniData) => uniData.grain === selectedGrain[key])
            : groupedData[key]).map((b, i) => {
            const fill = i % 2 ? 'D9D9D9' : 'FFFFFF';
            const dt = { fill, borderColor: '000000', bold: false };
            return [
              square({}),
              square({ value: i + 1, ...dt }),
              square({ value: b.grain, ...dt }),
              square({ value: b.equipament.description, ...dt }),
              square({ value: formatDate(b.date, 'dd/MM/yyyy'), ...dt }),
              square({ value: formatDate(b.sentDate, "HH':'mm"), ...dt }),
              square({ value: formatDate(b.finishDate, "HH':'mm"), ...dt }),
              square({ value: formatHr(b.diffDateSecs), ...dt }),

              square({ value: normalizeErrorData(b.weatherStart.weatherTemp, '°C'), ...dt }),
              square({ value: normalizeErrorData(b.weatherFinish.weatherTemp, '°C'), ...dt }),
              square({ value: normalizeErrorData(b.weatherStart.weatherHumid, '%'), ...dt }),
              square({ value: normalizeErrorData(b.weatherFinish.weatherHumid, '%'), ...dt }),
              square({ value: formatMotorsSub(b.enginesPerSub.total).has, ...dt }),

              ...motorsKeys.reduce((a, c) => {
                const { on } = formatMotorsSub(b.enginesPerSub[c]);
                a.push(...[
                  square({ value: on, ...dt }),
                  square({ value: `${c} cv`, ...dt }),
                ]);
                return a;
              }, []),

              ...(motorsKeys.length < 4 ? new Array(4 - motorsKeys.length).fill(0).reduce((a) => {
                a.push(...[
                  square({ ...dt }),
                  square({ ...dt }),
                ]);
                return a;
              }, []) : []),

              square({
                value:
                  getTotalPercentageMotorsActive(b.enginesHps.total.on, b.enginesHps.total.has),
                ...dt,
              }),

            ];
          }),

          // new Array(22).fill(0).map(() => square({})),
        ];

        const merges = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: 21 } },
          { s: { r: 1, c: 1 }, e: { r: 5, c: 3 } },
          { s: { r: 1, c: 4 }, e: { r: 5, c: 21 } },
          { s: { r: 6, c: 1 }, e: { r: 6, c: 21 } },
          { s: { r: 7, c: 1 }, e: { r: 7, c: 15 } },
          { s: { r: 7, c: 16 }, e: { r: 7, c: 21 } },
          { s: { r: 8, c: 16 }, e: { r: 13, c: 21 } },
          { s: { r: 8, c: 13 }, e: { r: 8, c: 15 } },
          { s: { r: 9, c: 13 }, e: { r: 9, c: 15 } },
          { s: { r: 10, c: 1 }, e: { r: 10, c: 15 } },
          { s: { r: 11, c: 1 }, e: { r: 12, c: 1 } },
          { s: { r: 11, c: 2 }, e: { r: 12, c: 3 } },
          // { s: { r: 11, c: 3 }, e: { r: 12, c: 3 } },
          { s: { r: 11, c: 4 }, e: { r: 12, c: 5 } },
          { s: { r: 11, c: 6 }, e: { r: 11, c: 8 } },
          { s: { r: 11, c: 9 }, e: { r: 11, c: 11 } },
          { s: { r: 11, c: 12 }, e: { r: 11, c: 15 } },
          { s: { r: 12, c: 13 }, e: { r: 12, c: 15 } },
          { s: { r: 13, c: 13 }, e: { r: 13, c: 15 } },
          { s: { r: 13, c: 2 }, e: { r: 13, c: 3 } },
          { s: { r: 13, c: 4 }, e: { r: 13, c: 5 } },
          { s: { r: 14, c: 1 }, e: { r: 14, c: 3 } },
          { s: { r: 14, c: 4 }, e: { r: 14, c: 7 } },
          { s: { r: 14, c: 8 }, e: { r: 14, c: 10 } },
          { s: { r: 14, c: 11 }, e: { r: 14, c: 21 } },

          { s: { r: 15, c: 2 }, e: { r: 15, c: 3 } },
          { s: { r: 15, c: 4 }, e: { r: 18, c: 7 } },
          { s: { r: 15, c: 8 }, e: { r: 16, c: 10 } },
          { s: { r: 15, c: 11 }, e: { r: 18, c: 12 } },
          { s: { r: 15, c: 14 }, e: { r: 15, c: 18 } },
          { s: { r: 15, c: 19 }, e: { r: 15, c: 21 } },

          { s: { r: 16, c: 1 }, e: { r: 18, c: 1 } },
          { s: { r: 16, c: 2 }, e: { r: 18, c: 3 } },
          { s: { r: 16, c: 14 }, e: { r: 16, c: 18 } },
          { s: { r: 16, c: 19 }, e: { r: 16, c: 21 } },

          { s: { r: 17, c: 8 }, e: { r: 18, c: 10 } },
          { s: { r: 17, c: 14 }, e: { r: 17, c: 18 } },
          { s: { r: 17, c: 19 }, e: { r: 17, c: 21 } },

          { s: { r: 18, c: 13 }, e: { r: 18, c: 21 } },

          { s: { r: 19, c: 1 }, e: { r: 19, c: 7 } },
          { s: { r: 19, c: 8 }, e: { r: 19, c: 11 } },
          { s: { r: 19, c: 12 }, e: { r: 20, c: 21 } },

          { s: { r: 20, c: 1 }, e: { r: 22, c: 1 } },
          { s: { r: 20, c: 2 }, e: { r: 22, c: 2 } },
          { s: { r: 20, c: 3 }, e: { r: 22, c: 3 } },
          { s: { r: 20, c: 4 }, e: { r: 22, c: 4 } },
          { s: { r: 20, c: 5 }, e: { r: 20, c: 7 } },
          { s: { r: 20, c: 8 }, e: { r: 20, c: 9 } },
          { s: { r: 20, c: 10 }, e: { r: 20, c: 11 } },

          { s: { r: 21, c: 5 }, e: { r: 22, c: 5 } },
          { s: { r: 21, c: 6 }, e: { r: 22, c: 6 } },
          { s: { r: 21, c: 7 }, e: { r: 22, c: 7 } },
          { s: { r: 21, c: 8 }, e: { r: 22, c: 8 } },
          { s: { r: 21, c: 9 }, e: { r: 22, c: 9 } },
          { s: { r: 21, c: 10 }, e: { r: 22, c: 10 } },
          { s: { r: 21, c: 11 }, e: { r: 22, c: 11 } },
          { s: { r: 21, c: 12 }, e: { r: 22, c: 12 } },
        ];

        aux.push({
          sheet: header,
          merges,
          eqpName,
        });
      });
      setMDS(aux);
    }
  };

  React.useEffect(() => {
    if (mDS) {
      const wb = XLSX.utils.book_new();
      mDS.forEach(({ sheet, merges, eqpName }) => {
        const ws = XLSX.utils.aoa_to_sheet(sheet);
        ws['!merges'] = merges;
        ws['!cols'] = [{ wch: 2 }, ...new Array(21).fill(0).map(() => ({ wch: 8 }))];
        ws['!rows'] = [{ hpt: 6 }, {}, {}, {}, {}, {}, { hpt: 6 }, {}, {}, {}, { hpt: 6 }];
        XLSX.utils.book_append_sheet(wb, ws, eqpName);
      });
      XLSX.writeFile(wb, `report-controle-de-aeração-${new Date().toISOString().substring(0, 10)}.xlsx`);
    }
  }, [mDS]);

  return (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={() => {
        buildExcel();
      }}
    >
      <GetAppIcon />
      XSLX
    </Button>
  );
};
