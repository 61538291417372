import React from 'react';
import colors from '../styles/colors';

export default (props) => {
  const [ps, setPs] = React.useState({
    destinyX: 0,
    destinyY: 0,
    currentX: undefined,
    currentY: undefined,
    initialX: 0,
    initialY: 0,
    northFaceAngleX: 0,
    northFaceAngleY: 0,
  });
  const {
    stDirectionRotationLeft,
    stDirectionRotationRight,
    stOnOff,
  } = props.selectedRecipe;

  const setaEsquerda = stDirectionRotationLeft
    ? colors.pivotOk
    : colors.disabled;
  const setaDireita = stDirectionRotationRight
    ? colors.pivotOk
    : colors.disabled;
  const textBackground = stOnOff ? colors.pivotOk : colors.grey;
  const textColor = stOnOff ? colors.white : colors.black;
  const statusText = stOnOff ? 'PIVÔ LIGADO' : 'PIVÔ DESLIGADO';
  const statusColor = stOnOff ? colors.pivotOk : colors.darkGrey;
  function clickLeft() {
    if (props.controlMode) {
      props.onClickLeft(stDirectionRotationLeft);
    } else if (!props.visualizationMode) {
      props.onClickLeft(stDirectionRotationLeft);
    }
  }
  function clickRight() {
    if (props.controlMode) {
      props.onClickRight(stDirectionRotationRight);
    } else if (!props.visualizationMode) {
      props.onClickRight(stDirectionRotationRight);
    }
  }
  const calcInit = (init, dest) => {
    if (props.direction === 1) {
      return init + props.northFaceAngle;
    }
    return dest + props.northFaceAngle;
  };

  const calcInside = (st, nd) => {
    if (st > nd && props.direction === 1) {
      return st - nd;
    }
    if (st > nd && props.direction === 2) {
      return 360 - st + nd;
      // return st + nd;
    }
    if (st < nd && props.direction === 1) {
      return 360 - nd + st;
    }
    return nd - st;
  };

  const positionTextX = (value, originalX) => {
    if (value > 99) {
      return originalX - 1;
    }
    if (value > 9) {
      return originalX - 0.7;
    }
    return originalX - 0.3;
  };

  const positionTextY = (originalY) => originalY + 0.4;

  React.useEffect(() => {
    const np = {};
    if (props.initial !== undefined) {
      np.initialX =
        2 * Math.sin((props.initial + props.northFaceAngle) * 0.0174532925) +
        10;
      np.initialY =
        -2 * Math.cos((props.initial + props.northFaceAngle) * 0.0174532925) +
        10;
    }

    if (props.destiny !== undefined) {
      np.destinyX =
        4.5 * Math.sin((props.destiny + props.northFaceAngle) * 0.0174532925) +
        10;
      np.destinyY =
        -4.5 * Math.cos((props.destiny + props.northFaceAngle) * 0.0174532925) +
        10;
    }

    if (props.current !== undefined) {
      np.currentX =
        8.5 * Math.sin((props.current + props.northFaceAngle) * 0.0174532925) +
        10;
      np.currentY =
        -8.5 * Math.cos((props.current + props.northFaceAngle) * 0.0174532925) +
        10;
    }
    setPs(np);
  }, [props]);

  return (
    <svg
      height="100%"
      width="100%"
      viewBox={props.visualizationMode ? '0 5 20 20' : '0 0 20 26'}
      style={{ fill: colors.transparent, maxHeight: '450px' }}
    >
      <g transform="matrix(1, 0, 0, 1, 0, 5.8)">
        <circle
          id="circle"
          style={{
            stroke: colors.primaryUser,
            strokeWidth: '0.4px',
            strokeDasharray: '0.15',
          }}
          cx="10"
          cy="10"
          r="6.5"
        />

        {props.cannons.map((c) => (
          <circle
            key={c[1]}
            id="circle"
            style={{
              stroke: colors.pivotCannon,
              strokeWidth: '0.3px',
            }}
            cx="10"
            cy="10"
            r="7"
            strokeDasharray={`${
              (((Math.max(c[1], c[0]) - Math.min(c[1], c[0])) / 3.6) *
                (2 * Math.PI * 7)) /
              100
            }px ${2 * Math.PI * 7}px`}
            transform={`rotate(${
              -90 + Math.min(c[1], c[0]) + props.northFaceAngle
            }, 10, 10)`}
          />
        ))}

        <circle
          id="circle"
          style={{
            stroke: colors.error,
            strokeWidth: '0.14px',
          }}
          cx="10"
          cy="10"
          r="6.78"
          strokeDasharray={`${((10 / 3.6) * (2 * Math.PI * 6.78)) / 100}px ${
            2 * Math.PI * 6.78
          }px`}
          transform={`rotate(${-95 + props.northFaceAngle}, 10, 10)`}
        />

        {props.maxSectorAngle !== undefined && (
          <>
            <circle
              r="3.47"
              cx="10"
              cy="10"
              fill={colors.transparent}
              stroke={colors.white}
              strokeWidth="6.5"
              strokeDasharray={`${
                (((360 - props.maxSectorAngle) / 3.6) * (2 * Math.PI * 3.47)) /
                100
              }px ${2 * Math.PI * 3.47}px`}
              transform={`rotate(${
                -90 + props.maxSectorAngle + props.northFaceAngle
              }, 10, 10)`}
            />

            <line
              x1="10"
              y1="10"
              x2="10"
              y2="3.3"
              strokeDasharray="0.45 0.18"
              transform={`rotate(${
                props.maxSectorAngle + props.northFaceAngle
              } 10 10)`}
              style={{ strokeWidth: '0.15px', stroke: colors.primaryUser }}
            />

            <line
              x1="10"
              y1="10"
              x2="10"
              y2="3.3"
              strokeDasharray="0.45 0.18"
              transform={`rotate(${props.northFaceAngle} 10 10)`}
              style={{ strokeWidth: '0.15px', stroke: colors.primaryUser }}
            />
          </>
        )}

        {props.initial !== undefined && (
          <circle
            r="3"
            cx="10"
            cy="10"
            fill={colors.transparent}
            stroke={
              props.water ? colors.pivotWater : colors.pivotNoWater
            }
            strokeWidth="6.5"
            strokeDasharray={`${
              ((calcInside(props.current || props.destiny || 0, props.initial) /
                3.6) *
                (2 * Math.PI * 3)) /
              100
            }px ${2 * Math.PI * 3}px`}
            transform={`rotate(${
              -90 +
              calcInit(props.initial || 0, props.current || props.destiny || 0)
            }, 10, 10)`}
          />
        )}

        <g>
          {props.initial !== undefined && (
            <line
              x1="10"
              y1="10"
              x2="10"
              y2="8"
              transform={`rotate(${
                props.initial + props.northFaceAngle
              } 10 10)`}
              style={{ strokeWidth: '0.15px', stroke: colors.primaryUser }}
            />
          )}
          <line
            x1="10"
            y1="10"
            x2="10"
            y2="5"
            transform={`rotate(${props.destiny + props.northFaceAngle} 10 10)`}
            style={{ strokeWidth: '0.15px', stroke: colors.primaryUser }}
          />
          {props.current !== undefined && (
            <line
              x1="10"
              y1="10"
              x2="10"
              y2="1"
              transform={`rotate(${
                props.current + props.northFaceAngle
              } 10 10)`}
              style={{ strokeWidth: '0.15px', stroke: colors.secondaryUser }}
            />
          )}
        </g>
        <circle
          id="center"
          style={{ fill: colors.primaryUser }}
          cx="10"
          cy="10"
          r="0.3"
        />

        {/* Initial */}
        {props.initial !== undefined &&
          ps.initialX !== undefined &&
          ps.initialY !== undefined && (
            <>
              <circle
                id="center"
                style={{ fill: colors.primaryUser }}
                cx={ps.initialX}
                cy={ps.initialY}
                r="1.2"
              />
              <text
                x={positionTextX(props.initial, ps.initialX)}
                y={positionTextY(ps.initialY)}
                fill={colors.white}
                style={{
                  fontSize: '1.2px',
                  letterSpacing: '0px',
                  fontWeight: '500',
                }}
              >
                {props.initial}
              </text>
            </>
        )}

        {/* Current */}
        {props.current !== undefined &&
          ps.currentX !== undefined &&
          ps.currentY !== undefined && (
            <>
              <circle
                id="center"
                style={{ fill: colors.secondaryUser }}
                cx={ps.currentX}
                cy={ps.currentY}
                r="1.2"
              />
              <text
                x={positionTextX(props.current, ps.currentX)}
                y={positionTextY(ps.currentY)}
                fill={colors.white}
                style={{
                  fontSize: '1.2px',
                  letterSpacing: '0px',
                  fontWeight: '500',
                }}
              >
                {props.current}
              </text>
            </>
        )}

        {/* Destiny */}
        <circle
          id="center"
          style={{
            fill: colors.background,
          }}
          cx={ps.destinyX}
          cy={ps.destinyY}
          r="1.2"
        />
        <text
          x={positionTextX(props.destiny, ps.destinyX)}
          y={positionTextY(ps.destinyY)}
          fill={colors.primaryUser}
          style={{
            fontSize: '1.2px',
            letterSpacing: '0px',
            fontWeight: '500',
          }}
        >
          {props.destiny}
        </text>
      </g>
      <g
        transform={
          props.visualizationMode
            ? 'matrix(1, 0, 0, 1, 0, 6)'
            : 'matrix(1, 0, 0, 1, 0, 2)'
        }
      >
        <g id="SETAS">
          <g
            id="SETA_ESQUERDA"
            style={{ cursor: props.visualizationMode ? 'default' : 'pointer' }}
            onClick={() => {
              clickLeft();
            }}
            transform="matrix(1, 0, 0, 1, -0.5, -1)"
          >
            <path
              style={{
                stroke: setaEsquerda,
                fill: 'none',
                strokeLinecap: 'round',
              }}
              d="M 6.818 1.474 C 6.818 1.474 6.03 1.749 5.262 2.233 C 4.161 2.927 3.429 3.789 3.429 3.789"
            />
            <path
              d="M 11.114 8.923 Q 11.37 8.48 11.626 8.923 L 12.35 10.178 Q 12.606 10.621 12.094 10.621 L 10.646 10.621 Q 10.134 10.621 10.39 10.178 Z"
              style={{ fill: setaEsquerda }}
              transform="matrix(-0.267669, 0.963511, -0.963511, -0.267669, 16.014872, -4.540407)"
              shape="triangle 10.134 8.48 2.472 2.141 0.5 0.207 1@4c28d02c"
            />
          </g>
          <g
            id="SETA_DIREITA"
            style={{ cursor: props.visualizationMode ? 'default' : 'pointer' }}
            onClick={() => {
              clickRight();
            }}
            transform="matrix(1, 0, 0, 1, 0.5, -1)"
          >
            <path
              style={{
                stroke: setaDireita,
                fill: 'none',
                strokeLinecap: 'round',
              }}
              d="M 13.162 1.48 C 13.162 1.48 13.95 1.755 14.718 2.239 C 15.819 2.933 16.551 3.795 16.551 3.795"
            />
            <path
              d="M 11.114 8.923 Q 11.37 8.48 11.626 8.923 L 12.35 10.178 Q 12.606 10.621 12.094 10.621 L 10.646 10.621 Q 10.134 10.621 10.39 10.178 Z"
              style={{ fill: setaDireita }}
              transform="matrix(0.267669, 0.963511, 0.963511, -0.267669, 3.964667, -4.534704)"
              shape="triangle 10.134 8.48 2.472 2.141 0.5 0.207 1@4c28d02c"
            />
          </g>
        </g>

        {!props.visualizationMode && (
          <g
            onClick={() => {
              props.onClickPower(stOnOff);
            }}
            style={{ cursor: 'pointer' }}
            id="STATUS"
          >
            <g id="STATUS_BOX">
              <rect
                x="7.278"
                y="1.5"
                width="5.444"
                height="1.5"
                style={{ fill: textBackground }}
                rx="0.278"
                ry="0.278"
                id="STATUS_BACKGROUND"
              />
              <text
                style={{
                  fill: textColor,
                  fontFamily: 'Roboto',
                  fontSize: '0.5px',
                  letterSpacing: '-0.01px',
                  fontWeight: 700,
                  textAnchor: 'middle',
                  whiteSpace: 'pre',
                }}
                x="9.968"
                y="2.49"
                id="STATUS_TEXT"
              >
                {statusText}
              </text>
            </g>
            <g id="BOTAO">
              <circle
                style={{ fill: colors.grey }}
                cx="10"
                cy="-0.098"
                r="1.5"
              />
              <g id="BOTAO_SIMBOLO">
                <path
                  d="M 11.2 -0.094 C 11.2 0.569 10.663 1.105 10 1.105 C 9.337 1.105 8.8 0.569 8.8 -0.094 C 8.8 -0.593 9.104 -1.021 9.537 -1.201 L 9.537 -0.815 C 9.3 -0.664 9.143 -0.397 9.143 -0.094 C 9.143 0.379 9.527 0.763 10 0.763 C 10.473 0.763 10.857 0.379 10.857 -0.094 C 10.857 -0.397 10.7 -0.664 10.463 -0.815 L 10.463 -1.201 C 10.896 -1.021 11.2 -0.593 11.2 -0.094 Z"
                  style={{
                    strokeLinejoin: 'round',
                    strokeLinecap: 'round',
                    strokeWidth: '0px',
                    fill: statusColor,
                  }}
                />
                <rect
                  x="9.75"
                  y="-1.354"
                  width="0.5"
                  height="1.166"
                  style={{ fill: statusColor }}
                  rx="0.13"
                  ry="0.13"
                />
              </g>
            </g>
          </g>
        )}
      </g>
    </svg>
  );
};
