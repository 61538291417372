import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  friendlyName: yup
    .string()
    .required('Obrigatório'),
  variablePattern: yup
    .string()
    .required('Obrigatório'),
  system: yup
    .string()
    .required('Obrigatório'),
  operationId: yup.number().required('Obrigatório'),
  type: yup.string().required('Obrigatório'),
  thresholdMin: yup
    .number(),
  thresholdMax: yup
    .number(),
});
