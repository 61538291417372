import React from 'react';
import { BsFillCircleFill } from 'react-icons/bs';
import { formatDate } from '../../../../../../../../../helpers/Intl';

export const columns = [
  {
    name: '',
    selector: 'cultivationAccount.color',
    center: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <BsFillCircleFill size={20} color={row.cultivationAccount?.color} />
      </div>
    ),
  },
  {
    name: 'Cultura',
    selector: 'cultivationAccount.culture',
    center: true,
  },
  {
    name: 'Data do plantio',
    selector: 'plantingDate',
    center: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <p>{formatDate(row.plantingDate, 'dd/MM/yyyy')}</p>
        {/* <p>{new Date(row.plantingDate)}</p> */}
      </div>
    ),
  },
  {
    name: 'Ângulo inical',
    selector: 'angleFrom',
    center: true,
  },
  {
    name: 'Ângulo final',
    selector: 'angleTo',
    center: true,
  },
  {
    name: 'Status',
    selector: 'status',
    center: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <p>{row.status === 'A' ? 'Ativa' : 'Inativa'}</p>
      </div>
    ),
  },
];
