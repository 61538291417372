import React from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, withStyles } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export function TableStatistics({ data }) {
  const statistics = Object.keys(data[1]);

  const formatStatistic = (statistic) => {
    const formattedValue = Number(statistic);
    if (statistic % 1 !== 0) {
      return formattedValue.toFixed(2);
    }
    return formattedValue;
  };

  return (
    <TableContainer component={Paper}>
      <TableSortLabel>
        {data[0] === 'irrigation' ? 'Irrigação' : 'Chuva'}
      </TableSortLabel>
      <Table style={{ marginBottom: '1rem' }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="right">Máxima</StyledTableCell>
            <StyledTableCell align="right">Mínima</StyledTableCell>
            <StyledTableCell align="right">Média</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          <TableRow>
            {statistics.map((statistic) => (
              <TableCell key={statistic} align="right">{formatStatistic(data[1][statistic])}</TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
