import React from 'react';
import '../../../../App.css';
import { Redirect } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import EditIcon from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import ListIcon from '@material-ui/icons/List';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Grid from '@material-ui/core/Grid';
import FockinkAPI from '../../../../middlewares/Api';
import PortalPermissions from '../../../../helpers/enums/PortalPermissions';
import { hasPermission } from '../../../../helpers/permission';
import { formatDate, number } from '../../../../helpers/Intl';
import BackButton from '../../../../components/Buttons/BackButton';
import Breadcrumbs from '../../../../components/Breadcrumbs';

export default () => {
  const [inputs, setInputs] = React.useState([]);
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const MySwal = withReactContent(Swal);

  const breadcrumb = {
    title: 'Tarifador: Mercado Cativo',
    routes: [{ name: 'Configurações', to: '/settings' }, { name: 'Tarifador' }],
  };

  React.useEffect(() => {
    setLoading(true);
    FockinkAPI.get('/energyCosts').then((response) => {
      setInputs(response.data.data);
      setLoading(false);
    });
  }, []);

  const showEquips = (equips) => {
    MySwal.fire({
      title: 'Equipamentos associados',
      html: `
        <div style="text-align: left">
          ${(equips || []).map((m, i) => `<p>${i + 1}) ${m.description}</p>`).join('')}
          ${(equips || []).length === 0 ? '<h3>Nenhum equipamento associado</h3>' : ''}
        </div>`,
    });
  };

  React.useEffect(() => {
    const columns = [
      {
        name: 'Descrição', selector: 'description', grow: 2,
      },
      {
        name: 'Distribuidora', selector: 'distributor.description', grow: 2,
      },
      { name: 'Subgrupo', selector: 'subGroup.subGroup', center: true },
      {
        name: 'Tarifação', selector: 'taxType.taxType', grow: 2,
      },
      { name: 'UF', selector: 'state', center: true },
      {
        name: 'UC',
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            {number(row.consumerUnit)}
          </div>
        ),
      },
      {
        name: 'Qtd. Equipamentos',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            {`${(row.equipaments || []).length}`}
            <ListIcon style={{ cursor: 'pointer' }} onClick={() => showEquips(row.equipaments || [])} />
          </div>
        ),
      },
      {
        name: 'Vigência',
        center: true,
        grow: 2,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            {`${formatDate(new Date(`${row.dateStart}T14:00`), 'dd/MM/yyy')} á ${formatDate(new Date(`${row.dateFinish}T14:00`), 'dd/MM/yyy')}`}
          </div>
        ),
      },
      // {
      //   name: 'Status',
      //   center: true,
      //   cell: (row) => (
      //     <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
      //       {row.status === 'A' ? 'Ativo' : 'Inativo'}
      //     </div>
      //   ),
      // },
      {
        name: '',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color="secondary" onClick={() => setRedirect(row.id)}>
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];
    setDtCl({ columns, data: inputs });
  }, [inputs]);

  if (redirect) return <Redirect push to={`/energyCost/${redirect}`} />;

  if (loading) {
    return <Skeleton variant="rect" height={500} width="100%" animation="wave" />;
  }

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} newBtn={hasPermission(PortalPermissions.ENERGY_COST_MANAGE.value) && 'Nova Tarifa'} />
      <Grid container spacing={1} style={{ minHeight: 'calc(100vh - 101px)' }}>
        <Grid item xs={12}>
          <DataTable
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{ rowsPerPageText: 'Linhas:', rangeSeparatorText: 'de' }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
            noDataComponent="Não existem dados para serem mostrados"
            columns={dtCl.columns}
            data={dtCl.data}
            defaultSortAsc={false}
            defaultSortField="ts"
            title=""
          />
        </Grid>
        <Grid item xs={12} style={{ margin: '25px 0', textAlign: 'right' }}>
          <BackButton redirectTo="/settings" />
        </Grid>
      </Grid>
    </>
  );
};
