import React from 'react';
import DataTable from 'react-data-table-component';
import { FiTrash2 } from 'react-icons/fi';

export function UpdatedFilesDataTable({ data, handleDeletFile }) {
  const columns = [
    {
      name: 'Nome do arquivo',
      center: true,
      cell: (row) => row.split('/').pop().split('.').slice(0, -1),
    },
    {
      name: 'Tipo',
      center: true,
      cell: (row) => row.split('.').pop().toUpperCase(),
    },
    {
      center: true,
      cell: (row) => (
        <button type="button" onClick={() => handleDeletFile(row)}>
          <FiTrash2 size={18} />
        </button>
      ),
    },
  ];
  return (
    <DataTable
      pagination
      paginationPerPage={5}
      paginationComponentOptions={{ rowsPerPageText: 'Linhas:', rangeSeparatorText: 'de' }}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
      noDataComponent="Não existem dados para serem mostrados"
      columns={columns}
      data={data}
    />
  );
}
