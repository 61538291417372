import styled from 'styled-components';

export const Container = styled.div`
 min-height: calc(100vh - 150px);
 width: 100%;

 h1 {
   font-weight: normal;
   font-size: 1.5rem;
 }

 .strong-url {
   font-size: 1.2rem;
   display: block;

   span {
     font-weight: normal;
   }
 }
`;
