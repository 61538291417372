import React from 'react';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import BackButton from './BackButton';
import DeleteButton from './DeleteButton';
import SaveButton from './SaveButton';
import FockinkAPI from '../../middlewares/Api';

export default (props) => {
  const [loadStatus, setLoadStatus] = React.useState(undefined);
  const [back, setBack] = React.useState(false);
  const MySwal = withReactContent(Swal);

  if (back) return <Redirect push to={props.backTo} />;

  const errorMessage = (response) => {
    let em =
      (response.data.validation && response.data.validation.body.message) ||
      response.data.reason ||
      response.data.message;
    if (response.status === 409) em = 'Já existe';
    return em;
  };

  const callApi = (postData) => {
    FockinkAPI[postData.method](postData.url, postData.data)
      .then((r) => {
        setLoadStatus(undefined);
        if (props.customResponse) props.customResponse(r, setBack);
        else setBack(true);
      })
      .catch((e) => {
        setLoadStatus(undefined);
        if (props.customResponse) props.customErrorResponse(e, setBack);
        else {
          MySwal.fire({
            icon: 'error',
            title: 'Oops...',
            text: errorMessage(e.response),
          });
        }
      });
  };

  const saveData = async () => {
    setLoadStatus('save');
    const postData = await props.saveData();
    if (postData) {
      callApi(postData);
    } else {
      setLoadStatus(undefined);
    }
  };

  const deleteData = () => {
    setLoadStatus('delete');
    const postData = props.deleteData();
    callApi({ ...postData, method: 'delete', data: {} });
  };

  return (
    <>
      {!props.hideBack && (
        <BackButton
          back={() => (props.customBack ? props.customBack() : setBack(true))}
          style={{ marginLeft: '15px' }}
          customBackText={props.customBackText}
          isDisabled={!!loadStatus}
        />
      )}
      {props.deleteData && (
        <DeleteButton
          delete={deleteData}
          isDisabled={loadStatus && loadStatus !== 'delete'}
          isDeleting={loadStatus === 'delete'}
          style={{ marginLeft: '15px' }}
          canSave={props.canSave}
        />
      )}
      {props.saveData && (
        <SaveButton
          save={saveData}
          style={{ marginLeft: '15px' }}
          isDisabled={loadStatus && loadStatus !== 'save'}
          isSaving={loadStatus === 'save'}
          canSave={props.canSave}
          notComplete={props.notComplete}
          customSaveText={props.customSaveText}
        />
      )}
    </>
  );
};
