export const systemTypes = [
  {
    system: 'Irrigação',
    operationId: 1,
  },
  {
    system: 'Termometria',
    operationId: 2,
  },
  {
    system: 'Automação',
    operationId: 3,
  },
  {
    system: 'Fotovoltaica',
    operationId: 4,
  },
  {
    system: 'Estação Meteorológica',
    operationId: 5,
  },
];
