import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

export function ButtonAvg({ handleClick, children }) {
  const styles = makeStyles(() => ({
    btn: {
      alignItems: 'center',
    },
  }));

  const classes = styles();

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={handleClick}
      className={classes.btn}
    >
      {children}
    </Button>
  );
}
