/* eslint-disable*/
import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import { number } from '../../../../../helpers/Intl';

import { useTheme } from 'styled-components';

import moment from 'moment';

export function ChartUnitsCard(props) {
  const originalTheme = useTheme();

  const [mseries, setmSeries] = useState(null);
  const [cat, setcat] = useState(null);
  const [options, setOptions] = useState(null);
  const [filteredMaxAverage, setFilteredMaxAverage] = useState(0);

  const setMySeries = async (series) =>
    new Promise((resolve) => {
      const categories = series.map((a) => {
        return moment(a.ts).format('DD/MM/YYYY HH:mm:ss');
      });
      setcat(categories);
      const mnivel = series.map((a) => a.percLevel);
      const mmedia = series.map((a) => a.average);
      const maxAverage = series.map((a) => a.maxAverage);
      const minAverage = series.map((a) => a.minAverage);
      const max = Math.max.apply(null, mmedia);
      let aux = 0;
      const filteredVerifyWhatIsTheMaxMedia = maxAverage.map((a) => {
        if (aux < a) {
          aux = a;
        }
      });

      setFilteredMaxAverage(aux + 10);
      const mediaPerc = series.map((a) =>
        a.average > 0 ? (a.average * 100) / max - 30 : a.average,
      );

      let pse = null;

      if (props.isDashboard) {
        pse = [
          {
            // name: retornaLinguagemCorreta(eng.NIVEL, esp.NIVEL, ptbr.NIVEL),
            name: '',
            data: mnivel,
            type: 'area',
          },
          {
            // name: retornaLinguagemCorreta(eng.MEDIAMAXIMA, esp.MEDIAMAXIMA, ptbr.MEDIAMAXIMA),
            name: '',
            data: maxAverage,
            type: 'line',
          },
          {
            // name: retornaLinguagemCorreta(eng.MEDIA, esp.MEDIA, ptbr.MEDIA),
            name: '',
            data: mmedia,
            type: 'line',
          },
          {
            // name: retornaLinguagemCorreta(eng.MEDIAMINIMA, esp.MEDIAMINIMA, ptbr.MEDIAMINIMA),
            name: '',
            data: minAverage,
            type: 'line',
          },
        ];
      } else {
        pse = [
          {
            name: 'Nivel',
            data: mnivel,
            type: 'area',
          },
          {
            name: 'Média máxima',
            data: maxAverage,
            type: 'line',
          },
          {
            name: 'Média',
            data: mmedia,
            type: 'line',
          },
          {
            name: 'Média mínima',
            data: minAverage,
            type: 'line',
          },
        ];
      }

      setmSeries(pse);

      resolve();
    });

  const setMyOptions = async () => {
    const pop = {
      colors: [
        originalTheme.colors.chartBlue,
        originalTheme.colors.chartRed,
        originalTheme.colors.chartYellow,
        originalTheme.colors.chartGreen,
      ],
      chart: {
        zoom: {
          enabled: false,
        },
        selection: {
          enabled: false,
        },
        parentHeightOffset: 0,
        type: 'line',
        legend: {
          display: true,
        },
        toolbar: {
          show: false,
        },
        width: 50,
        id: 'basic-bar',
        dropShadow: {
          enabled: false,
          top: 0,
          left: 0,
          blur: 3,
          opacity: 0.15,
        },
      },
      fill: {
        type: ['gradient', 'solid', 'solid', 'solid'],
        gradient: {
          shadeIntensity: 0.9,
          opacityFrom: 0.5,
          opacityTo: 0.9,
          stops: [0, 90, 100],
        },
        opacity: [1, 0.9, 1, 1],
      },
      grid: {
        show: false,
      },
      stroke: {
        curve: 'smooth',
        width: 5,
        lineCap: 'round',
      },
      xaxis: {
        categories: cat,
        // type: 'datetime',
        tooltip: {
          enabled: false,
        },
        labels: {
          show: false,
        },
      },
      axisBorder: {
        show: false,
      },
      yaxis: [
        {
          show: false,
          title: {
            text: 'Nível',
            align: 'left',
            offsetX: 0,
            seriesName: 'Nível',
          },
        },
        {
          show: false,
          opposite: true,
          title: {
            text: 'Média máxima',
          },
          tickAmount: 5,
          min: 0,
          max: filteredMaxAverage,
        },
        {
          show: false,
          opposite: true,
          title: {
            text: 'Média',
          },
          tickAmount: 5,
          min: 0,
          max: filteredMaxAverage,
        },
        {
          show: false,
          opposite: true,
          title: {
            text: 'Média mínima',
          },
          tickAmount: 5,
          min: 0,
          max: filteredMaxAverage,
        },
      ],
      grid: {
        show: false,
      },
      legend: {
        display: props.isDashboard ? false : true,
        show: props.isDashboard ? false : true,
      },
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 4000,
          options: {
            chart: {
              width: '100%',
              height: '100px',
            },
          },
        },
        {
          breakpoint: 800,
          options: {
            chart: {
              width: '100%',
              height: '100px',
            },
          },
        },
      ],
      tooltip: {
        enabled: true,
        custom({ series, seriesIndex, dataPointIndex, w }) {
          // console.log('series ', series);
          if (series) {
            return (
              `${
                `${
                  `${
                    `${
                      `${'<div class="arrow_box">' + '<span>'}${cat[dataPointIndex]}</span>` +
                      '<br />' +
                      '<span>' +
                      'nível' +
                      ': '
                    }${
                      series[0].length > 0
                        ? series[0][dataPointIndex] < 101 && series[0][dataPointIndex] > -1
                          ? number(series[0][dataPointIndex], 2, 2) + ' %'
                          : 'OFF'
                        : ''
                    }</span>` +
                    '<br />' +
                    '<span>' +
                    'Média máxima' +
                    ': '
                  }${
                    series[1].length > 0 ? `${number(series[1][dataPointIndex], 2, 2)}°C` : ''
                  }</span>` +
                  // '<br />' +
                  '<span>' +
                  'Média' +
                  ': '
                }${
                  series[2].length > 0 ? `${number(series[2][dataPointIndex], 2, 2)}ºC` : ''
                }</span>` +
                '<br />' +
                '<span>' +
                'Média mínima' +
                ': '
              }${
                series[3].length > 0 ? `${number(series[3][dataPointIndex], 2, 2)}ºC` : ''
              }</span>` + '</div>'
            );
          }
          return '<div></div>';
        },
        x: {
          show: true,
          format: 'dd/MM/yyyy HH:mm',
        },
      },
    };
    setOptions(pop);
  };

  useEffect(() => {
    async function settingSeries() {
      await setMySeries(props.series);
    }
    settingSeries();
  }, [props.series]);

  useEffect(() => {
    function settingOptions() {
      if (mseries !== null) {
        setMyOptions();
      }
    }
    settingOptions();
    // eslint-disable-next-line
  }, [mseries]);

  return (
    <div style={{ borderRadius: '10px !important', display: 'flex' }}>
      {options !== null ? (
        <Chart options={options} series={mseries} type="area" width="100%" />
      ) : null}
    </div>
  );
}
