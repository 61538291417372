import React from 'react';
import moment from 'moment';
import Skeleton from '@material-ui/lab/Skeleton';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper,
} from '@material-ui/core';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import WeatherStationSearch from './WeatherStationSearch';
import WeatherStationReportChart from './weatherStationReportChart';
import WeatherStationReportTable from './weatherStationReportTable';

import FockinkAPI from '../../../../middlewares/Api';

const weatherStationReport = () => {
  const [breadcrumbs, setBreadcrumbs] = React.useState({ routes: [] });
  const urlParams = new URLSearchParams(window.location.search);

  const breadcrumb = {
    title: 'Relatório de Estação Meteorológica',
    routes: [
      { name: 'Relatórios', to: '/reports' },
      { name: 'Relatório de Estação Meteorológica' },
    ],
  };

  const [data, setData] = React.useState({
    series: [],
    dateStartText: '',
    dateFinishText: '',
  });
  const [tableData, setTableData] = React.useState([]);
  const [state, setState] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [estimative, setEstimative] = React.useState(false);
  const [weatherStations, setWeatherStations] = React.useState();
  const [selectedStation, setSelectedStation] = React.useState();
  const [graphSettings, setGraphSettings] = React.useState({
    from: new Date(),
    to: new Date(),
    offset: 0,
    group: 'hour',
    searchType: 'today',
  });

  const buildAxis = (maxAxis, show = false) => ({
    max: maxAxis,
    min: 0,
    show,
    // tickAmount: 24,
    showAlways: show,
    labels: {
      formatter(value) {
        return parseInt(value, 10);
      },
    },
  });
  const url = urlParams.get('accountId');

  React.useEffect(() => {
    setLoading(true);
    setBreadcrumbs({
      title: 'Relatório de Aeração',
      routes: [{ name: 'Relatórios', to: '/reports' },
        { name: 'Relatório de Estação Meteorológica' }],
      secondRoute: [
        { name: 'Clientes', to: '/clientsAdm/' },
        { name: 'Relatórios', to: `/reportsAdm?${urlParams}&op=all` },
        { name: 'Relatório de Estação Meteorológica' },
      ] });
    const pid = urlParams.get('plantId');
    FockinkAPI.get(
      `/dashboards/equipaments?operationId=5&accountId=${url}`,
    ).then((response) => {
      setWeatherStations(response.data.data.equipaments);
      setSelectedStation(response.data.data.equipaments[0].id);
    });
  }, []);

  const callApi = () => {
    setLoading(true);
    let groupBy;
    if (
      moment(graphSettings.from).format('yyyy-MM-DD') ===
      moment(graphSettings.to).format('yyyy-MM-DD')
    ) {
      groupBy = 'hour';
    } else if (
      moment(graphSettings.from).format('yyyy-MM-DD') <
        moment(graphSettings.to).format('yyyy-MM-DD') &&
      graphSettings.group === 'day'
    ) {
      groupBy = 'day';
    }

    if (groupBy) {
      FockinkAPI.get(
        `/reports/weatherStation/monitoring?dateStart=${moment(
          graphSettings.from,
        ).format('yyyy-MM-DD')}&dateFinish=${moment(graphSettings.to).format(
          'yyyy-MM-DD',
        )}&groupBy=${groupBy}&equipamentId=${selectedStation}`,
      ).then((response) => {
        setData({
          ...response.data.data.chartData,
          dateStartText: response.data.data.dataStartText,
          dateFinishText: response.data.data.dateFinishText,
        });
        setTableData(response.data.data.tableData);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    callApi();

    const interval = setInterval(() => {
      callApi();
    }, 3600000);
    return () => clearInterval(interval);
  }, [graphSettings, selectedStation]);

  React.useEffect(() => {
    const values = [];
    const valuesUV = [];
    data.series.forEach((m) => {
      if (m.name !== 'UV') { values.push(...m.data); }
      if (m.name === 'UV') { valuesUV.push(...m.data); }
    });
    const maxValue = values.reduce((a, b) => (a > b ? a : b), 0);
    const maxValueUV = valuesUV.reduce((a, b) => (a > b ? a : b), 0);
    const maxAxis = parseInt(maxValue || '98', 10) + 2;
    const maxAxisUV = parseInt(maxValueUV || '2000', 10) + 2;

    const stateInt = {
      series: data.series,
      options: {
        chart: {
          id: 'myChart',
          toolbar: {
            show: true,
          },
          height: 300,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 2,
        },
        yaxis: [
          buildAxis(maxAxis, true),
          buildAxis(maxAxis),
          buildAxis(maxAxis),
          buildAxis(maxAxis),
          buildAxis(maxAxis),
          buildAxis(maxAxis),
          {
            max: maxAxisUV,
            opposite: true,
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: 'UV (W/m²)',
            },
            labels: {
              formatter(value) {
                return parseInt(value, 10);
              },
            },
          },
          buildAxis(maxAxis),
        ],
        xaxis: {
          categories: data.categories,
          tickAmount: 24,
        },
        tooltip: {
          followCursor: true,
          y: {
            formatter(y, x) {
              if (typeof y !== 'undefined') {
                if (x.seriesIndex === 0) return `${y}°C`;
                if (x.seriesIndex === 1) return `${y}°C`;
                if (x.seriesIndex === 2) return `${y}%`;
                if (x.seriesIndex === 3) return `${y}%`;
                if (x.seriesIndex === 4) return `${y}mm`;
                if (x.seriesIndex === 5) return `${y}Km/h`;
                if (x.seriesIndex === 6) return `${y}W/m²`;
                if (x.seriesIndex === 7) return `${y || '-'}mm`;
              }
              return y;
            },
          },
        },
      },
    };
    setState(stateInt);
  }, [graphSettings, data, estimative]);

  if (loading) {
    return (
      <Skeleton variant="rect" height={300} width="100%" animation="wave" />
    );
  }

  return (
    <div>
      {' '}
      <Breadcrumbs routes={localStorage.profile === 'admin' && urlParams ? breadcrumbs.secondRoute : breadcrumbs.routes} title={breadcrumbs.title} />
      <Paper style={{ marginBottom: 20 }}>
        <Grid container spacing={1} style={{ padding: 10 }}>
          <Grid item xs={3}>
            <FormControl variant="filled" style={{ maxWidth: 250 }}>
              <InputLabel id="clientId">Clientes de Irrigação</InputLabel>
              <Select
                labelId="clientId"
                id="clientId"
                name="clientId"
                value={selectedStation || weatherStations[0].id}
                label="Cliente"
                onChange={(e) => setSelectedStation(e.target.value)}
              >
                {weatherStations?.map((station) => (
                  <MenuItem value={station.id}>{station.description}</MenuItem>
                ))}
              </Select>

            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <WeatherStationSearch
              // invs={data.numInv}
              setEstimative={setEstimative}
              estimative={estimative}
              graphSettings={graphSettings}
              setGraphSettings={setGraphSettings}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <WeatherStationReportChart state={state} loading={loading} />
        </Grid>
      </Paper>
      <Grid item xs={12}>
        <WeatherStationReportTable data={tableData} />
      </Grid>
    </div>
  );
};

export default weatherStationReport;
