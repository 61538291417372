import React, { useState } from 'react';
import MomentUtils from '@date-io/moment';
import { TextField, Grid, MenuItem, Select, InputLabel, FormControl, InputAdornment } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import parseFloatCustom from '../../../../helpers/parseFloatCustom';
import BottomButtons from '../../../../components/Buttons/BottomButtons';
import FockinkAPI from '../../../../middlewares/Api';
import Breadcrumbs from '../../../../components/Breadcrumbs';

const FuelCostForm = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    referenceDate: new Date(),
  });
  const breadcrumbs = {
    routes: [
      { name: 'Cadastro de combustíveis', to: '/settings/costFuel' },
      { name: 'Cadastro de valores' },
    ],
    title: 'Cadastro de valores',
  };
  const useStyles = makeStyles({
    textAlign: {
      textAlign: 'right',
    },
    btnSpace: {
      marginLeft: '15px',
    },
    bottomButtons: {
      marginTop: '15px',
      borderTop: '1px solid #ccc',
      paddingTop: '10px !important',
    },
    root: {
      width: '100%',
      marginTop: 30,
    },
  });
  const classes = useStyles();
  const back = {
    name: 'Cadastro de Combustíveis',
    to: '/settings/costFuel',
  };
  const history = useHistory();

  React.useEffect(() => {
    FockinkAPI.get(
      `/fuelCost/${props.match.params.id}`,
    ).then((response) => {
      if (props.match.params.id === 'new') {
        setLoading(false);
      } else {
        setData(response.data.data);
        setLoading(false);
      }
    });
    setLoading(false);
  }, []);

  const saveData = () => {
    if (props.match.params.id === 'new') {
      const dt = {
        fuel: data.fuel,
        referenceDate: moment(data.referenceDate).format('yyyy-MM'),
        price: parseFloatCustom(data.price),
        status: 'A',
      };
      FockinkAPI.post('/fuelCost', dt)
        .then(() => {
          toast.success('Dados salvos com sucesso');
          history.push('/settings/costFuel');
        })
        .catch(async (error) => {
          const { response } = error;

          if (response.status === 400) {
            toast.error(response.data.validation?.body.message || response.data.message);
          }
        });
    } else {
      const dt = {
        fuel: data.fuel,
        referenceDate: moment(data.referenceDate).format('yyyy-MM'),
        price: parseFloatCustom(data.price),
        status: data.status,
      };
      FockinkAPI.put(`/fuelCost/${props.match.params.id}`, dt).then(() => {
        toast.success('Dados salvos com sucesso');
        history.push('/settings/costFuel');
      })
        .catch(async (error) => {
          const { response } = error;

          if (response.status === 400) {
            toast.error(response.data.validation.body.message);
          } else {
            toast.error(response.data.message);
          }
        });
    }
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Breadcrumbs
        routes={breadcrumbs.routes}
        title={breadcrumbs.title}
      />
      {
    loading ? (
      <div className={classes.root}>
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
      </div>
    )
      :

      (
        <Grid container spacing={2}>
          <Grid item lg={3} xs={12}>
            <FormControl variant="filled">
              <InputLabel id="fuel">Combustível</InputLabel>
              <Select
                labelId="fuel"
                id="fuel"
                name="fuel"
                value={data?.fuel || ''}
                label="fuel"
                onChange={handleChange}
              >
                <MenuItem value="diesel">Diesel</MenuItem>
                <MenuItem value="dieselS10">Diesel S10</MenuItem>
                <MenuItem value="ethanol">Etanol</MenuItem>
                <MenuItem value="lpg">GLP</MenuItem>
                <MenuItem value="cng">GNV</MenuItem>
                <MenuItem value="gasoline">Gasolina</MenuItem>
                <MenuItem value="gasolineWithAdditives">Gasolina Aditivada</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              variant="filled"
              name="price"
              value={data?.price || ''}
              onChange={handleChange}
              label="Valor"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    R$
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {
              props.match.params.id !== 'new' && (
                <Grid item lg={3} xs={12}>
                  <FormControl variant="filled">
                    <InputLabel id="status">Status</InputLabel>
                    <Select
                      labelId="status"
                      id="status"
                      name="status"
                      value={data?.status || ''}
                      label="status"
                      onChange={handleChange}
                    >
                      <MenuItem value="A">Ativo</MenuItem>
                      <MenuItem value="I">Inativo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )
            }
          <Grid item xs={12} lg={3}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              style={{ width: '100%' }}
            >
              <KeyboardDatePicker
                variant="filled"
                views={['year', 'month']}
                margin="normal"
                label="Mês de referência"
                format="MM/YYYY"
                value={data?.referenceDate || null}
                name="referenceDate"
                style={{ margin: 0, marginTop: 8 }}
                autoOk
                onChange={(date) => {
                  setData({
                    ...data,
                    referenceDate: date?.format('YYYY-MM-DD'),
                  });
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            item
            xs={12}
            className={`${classes.textAlign} ${classes.bottomButtons}`}
          >
            <BottomButtons saveData={saveData} backTo={back.to} />
          </Grid>
        </Grid>
      )
      }
    </>
  );
};

export default FuelCostForm;
