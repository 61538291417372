import React from 'react';
import { useTheme } from 'styled-components';
import { GiReceiveMoney, ImPower, GiFuelTank } from 'react-icons/all';
import { Ellipsis } from 'react-spinners-css';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { ProgressBarWithLabel } from '../../../../components/ProgressBarWithLabel';
import { GraphicEarnings, CardInfo, SearchHeader } from './components';
import FockinkAPI from '../../../../middlewares/Api';
import BackButton from '../../../../components/Buttons/BackButton';

import * as S from './styles';
import { handleMessageError } from '../../../../helpers/handlerMessageError';
import { currency, formatDate } from '../../../../helpers/Intl';

export function PhotovoltaicReportEarning() {
  const [breadcrumbs, setBreadcrumbs] = React.useState({ routes: [] });
  const urlParams = window.location.search;
  const originalTheme = useTheme();

  const [data, setData] = React.useState();
  const [availablePlants, setAvailablePlants] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const [searchPeriod, setSearchPeriod] = React.useState('today');
  const [requestParams, setRequestParams] = React.useState({
    operationId: 4,
    dateStart: formatDate(new Date(), 'yyyy-MM-dd'),
    dateFinish: formatDate(new Date(), 'yyyy-MM-dd'),
    groupBy: 'day',
  });

  const handleGetEquipamentsFromPlants = async () => {
    try {
      setLoading(true);
      const response = await FockinkAPI.get('/plants', {
        params: {
          operationId: 4,
        },
      });
      const plants = response.data.data;
      setAvailablePlants(plants);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleMessageError(error);
    }
  };

  React.useEffect(() => {
    (async () => {
      setBreadcrumbs({
        title: 'Tracking de Variáveis',
        routes: [
          { name: 'Relatórios', to: '/reports' },
          { name: 'Relatório de Ganhos Fotovoltaica' },
        ],
        secondRoute: [
          { name: 'Clientes', to: '/clientsAdm/' },
          { name: 'Relatórios', to: `/reportsAdm${urlParams}&op=all` },
          { name: 'Relatório de Ganhos Fotovoltaica' },
        ] });
      await handleGetEquipamentsFromPlants();
    })();
  }, []);

  const breadcrumbsConfig = {
    routes: [
      { name: 'Relatórios', to: '/reports' },
      { name: 'Relatório de Ganhos Fotovoltaica' },
    ],
  };

  const handleSelectPlant = (event) => {
    const plantIdValue = event.target.value;
    const copyState = { ...requestParams };
    setRequestParams({ ...copyState, plantId: plantIdValue });
  };

  React.useEffect(() => {
    (async () => {
      if (requestParams.plantId) {
        await handelGetDataGraphic();
      }
    })();
  }, [requestParams.dateStart, requestParams.dateFinish, requestParams.plantId]);

  const handelGetDataGraphic = async () => {
    try {
      setLoading(true);
      const response = await FockinkAPI.get('/reports/photovoltaic/gain', {
        params: requestParams,
      });

      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      handleMessageError(error);
    }
  };

  const history = useHistory();

  return (
    <S.Wrapper>
      <Breadcrumbs routes={localStorage.profile === 'admin' && urlParams ? breadcrumbs.secondRoute : breadcrumbs.routes} title={breadcrumbs.title} />
      {availablePlants && (

        <Grid container xs={12}>
          <SearchHeader
            availablePlants={availablePlants}
            handleSelectPlant={handleSelectPlant}
            setRequestParams={setRequestParams}
            requestParams={requestParams}
            searchPeriod={searchPeriod}
            setSearchPeriod={setSearchPeriod}
          />
        </Grid>
      )}

      {data && data.reportLogs && (
        data.reportLogs.map((log) => (
          <S.ReportLogField>
            <span>{log}</span>
          </S.ReportLogField>
        ))
      )}

      {loading && (
        <S.LoadingDiv>
          <Ellipsis color="#3D5059" />
        </S.LoadingDiv>
      )}

      {data && !loading && (
        <Grid container spacing={1}>
          <Grid item sm={10}>
            <GraphicEarnings data={data} searchPeriod={searchPeriod} />
          </Grid>

          <Grid item sm={12} md={4} lg={2}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <strong className="info-label">Nesse período você:</strong>

            </div>

            {data.periodData.dieselReductCarbon && (

            <Grid container>
              <Grid container direction="row" spacing={1} xs={12}>

                <div style={{ marginTop: '1rem' }}>
                  <strong className="info-label">
                    Com a geração fotovoltaica você:
                  </strong>
                  <Grid container direction="row" spacing={2} xs={12}>
                    <Grid item xs={6}>
                      <CardInfo positive description="Economizado em Diesel">
                        <ProgressBarWithLabel
                          textSize="22px"
                          trailColor={originalTheme.colors.blueLight}
                          r="47"
                          g="191"
                          b="38"
                          percent={data.periodData.dieselReductPercent.toFixed(2)}
                        />
                      </CardInfo>
                    </Grid>

                    <Grid item xs={6}>
                      <CardInfo positive description={`Economizou ${currency(data.periodData.dieselReductValue)}`}>
                        <GiReceiveMoney
                          size={60}
                          color={originalTheme.colors.greenLight9}
                        />
                      </CardInfo>
                    </Grid>
                  </Grid>
                </div>

                <div style={{ marginTop: '1rem' }}>
                  <strong className="info-label">
                    Sem a geração fotovoltáica você teria:
                  </strong>
                  <Grid container direction="row" spacing={2} xs={12}>
                    <Grid item xs={6}>
                      <CardInfo description={`Consumido ${Math.round(data.periodData.dieselReductLiters)}L de diesel`}>
                        <GiFuelTank size={50} color="#c53030" />
                      </CardInfo>
                    </Grid>

                    <Grid item xs={6}>
                      <CardInfo description={`Gerado ${Math.round(data.periodData.dieselReductCarbon)} toneladas em CO²`}>
                        <ImPower size={50} color="#c53030" />
                      </CardInfo>
                    </Grid>
                  </Grid>
                </div>

              </Grid>
            </Grid>
            )}
          </Grid>
        </Grid>
      )}

      <div className="back-btn">
        <BackButton
          back={() => history.goBack()}
        />
      </div>

    </S.Wrapper>
  );
}
