import React from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { Tooltip, InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Checkbox,
  Grid,
  makeStyles,
  FormControl } from '@material-ui/core';
import { FiX } from 'react-icons/fi';
import jwtDecode from 'jwt-decode';
import { SiOneplus } from 'react-icons/si';
import {
  ButtonComponent as Button,
  Input,
  SimpleSteptForm,
  MultiInputSelect,
} from '../../../../../components';
import { systemTypes } from '../../../../../helpers/systemList';
import { useAlarmsContext } from '../../../../../hooks/AlarmsRule';
import { useTelemetryVariablesHook } from '../../../../../hooks/telemetryVariables';
import { useEquipamentContext } from '../../../../../hooks/equipament';

import * as S from './styles';

export function CreateNewAlarmRule({ handleCloseCreateNewAlarmModal }) {
  const [stepAction, setStepAction] = React.useState(0);
  const [equipaments, setEquipaments] = React.useState([]);
  const token = localStorage.getItem('@FockinkAPP:token');
  const { systemIds } = jwtDecode(token);
  const filteredSystemTypes = systemTypes.filter(
    (f) => systemIds.indexOf(f.operationId) !== -1,
  );
  const {
    handleAddNewAlarmRule,
    alarmToEdit,
    handleUpdateAlarm,
  } = useAlarmsContext();
  const [selectedEquipaments, setSelectedEquipaments] = React.useState(alarmToEdit?.equipamentIds || []);

  const [selectedSystem, setSelectedSystem] = React.useState(() => {
    if (alarmToEdit) {
      return alarmToEdit.equipamentsInfo[0].operationId;
      // const teste = alarmToEdit.equipamentsInfo[0].operationId;
      // console.log('alarmToEdit', teste);
    }
    return 1;
  });

  const changeStepState = (index) => {
    setStepAction(index);
  };

  const handleSelectEquipaments = (event) => {
    setSelectedEquipaments(event.target.value);

    return selectedEquipaments;
  };

  const { getEquipamentList } = useEquipamentContext();

  const { telemetryVariables } = useTelemetryVariablesHook();

  // React.useEffect(() => {
  //   alarmToEdit ? setSelectedEquipaments(alarmToEdit?.equipamentsInfo.map((res) => res.id)) : null;
  // });

  React.useEffect(() => {
    (async () => {
      const equipamentsResponse = await getEquipamentList();
      setEquipaments(equipamentsResponse);
    })();
  }, []);

  const formatTelemetryVariables = React.useCallback(() => {
    const selectedSytemData = systemTypes.find(
      (s) => s.operationId === selectedSystem,
    );

    const availableTelemetries = telemetryVariables.filter(
      (telemetry) => telemetry.system === selectedSytemData.system,
    );
    return availableTelemetries;
  }, [selectedSystem]);

  const CONDITIONAL_OPERATORS = [
    {
      helper: 'Maior ou igual à',
      operator: '>=',
    },
    {
      helper: 'Menor ou igual à',
      operator: '<=',
    },
    {
      helper: 'Maior que',
      operator: '>',
    },
    {
      helper: 'Menor que',
      operator: '<',
    },
    {
      helper: 'Igual à',
      operator: '=',
    },
  ];

  const STEP_ACTIONS = [
    {
      name: 'E-mail',
    },
    {
      name: 'WhatsApp',
    },
    {
      name: 'SMS',
    },
  ];

  const checkLastItemValue = (arr, itemIndex) => {
    const lastIndex = arr.indexOf(arr[arr.length - 1]);
    return itemIndex === lastIndex;
  };

  const handleSelectSystem = (event) => {
    setSelectedSystem(Number(event.target.value));
    setSelectedEquipaments([]);
  };

  return (
    <S.Wrapper>
      <S.Content>
        <Formik
          initialValues={{
            equipamentIds: alarmToEdit ? alarmToEdit.equipamentIds : [],
            telemetryVariablesId: alarmToEdit
              ? alarmToEdit.telemetryVariablesId
              : null,
            conditions: alarmToEdit
              ? alarmToEdit.conditions
              : [{ operator: '', value: 0 }],
            actions: {
              emails:
                alarmToEdit && alarmToEdit.actions.emails
                  ? alarmToEdit.actions.emails
                  : [],
              whatsapp:
                alarmToEdit && alarmToEdit.actions.whatsapp
                  ? alarmToEdit.actions.whatsapp
                  : [],
              sms:
                alarmToEdit && alarmToEdit.actions.sms
                  ? alarmToEdit.actions.sms
                  : [],
            },
            status: alarmToEdit ? alarmToEdit.status : 'A',
          }}
          onSubmit={async (values) => {
            values.equipamentIds = selectedEquipaments;
            if (!alarmToEdit) {
              await handleAddNewAlarmRule(values);
            } else {
              const { id } = alarmToEdit;
              values.equipamentIds = selectedEquipaments;
              const formatAlarmObject = { ...values, id };
              await handleUpdateAlarm(formatAlarmObject);
              handleCloseCreateNewAlarmModal();
            }
          }}
          render={({ values, handleChange }) => (
            <Form>
              <FieldArray
                name="conditions"
                render={(arrayHelpers) => (
                  <>
                    {!values.equipamentIds && (
                      <S.WarnCard>
                        Selecione um equipamento para adicionar um novo alarme
                      </S.WarnCard>
                    )}

                    <div className="row">
                      <MultiInputSelect
                        label="Sistema"
                        onChange={handleSelectSystem}
                        name="operationIdSystem"
                        value={selectedSystem}
                        data={filteredSystemTypes}
                        nameLabel="system"
                        valueData="operationId"
                      />
                    </div>

                    {selectedSystem && (
                      <>
                        {/* <MultiInputSelect
                          label="Equipamento"
                          onChange={handleChange}
                          name="equipamentId"
                          comparevalues={['clientDescription', 'description']}
                          value={values.equipamentId}
                          data={equipaments.filter(
                            (equipament) =>
                              equipament.operationId === selectedSystem,
                          )}
                          nameLabel="description"
                          valueData="id"
                        /> */}

                        <FormControl>
                          <InputLabel>Selecione um Equipamento</InputLabel>
                          <Select
                            multiple
                            label="Equipamento"
                            value={selectedEquipaments}
                            onChange={handleSelectEquipaments}
                            name="equipamentIds"
                            valueData="id"
                            nameLabel="description"
                            renderValue={(selected) =>
                              equipaments.filter((f) => selected.indexOf(f.id) !== -1).map((m) => m.description).join(', ')}
                          >
                            {equipaments.filter(
                              (equipament) =>
                                equipament.operationId === selectedSystem,
                            ).map((equipament) => (
                              <MenuItem key={equipament.id} value={equipament.id}>
                                <Checkbox checked={selectedEquipaments.indexOf(equipament.id) > -1} />
                                <ListItemText primary={`${equipament.clientDescription || equipament.description}`} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    )}
                    {equipaments.length !== 0 ? (
                      <>
                        <div className="row">
                          {selectedSystem && (
                            <>
                              <S.Label>Se</S.Label>
                              <MultiInputSelect
                                label="Selecione uma variável"
                                onChange={handleChange}
                                name="telemetryVariablesId"
                                value={values.telemetryVariablesId}
                                data={formatTelemetryVariables().filter((res) => res.alarmAvailable)}
                                nameLabel="friendlyName"
                                valueData="id"
                              />
                            </>
                          )}
                          {values.conditions.length === 0 && (
                            <Tooltip title="Nova condição">
                              <S.AddButton
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({ operator: '', value: 0 })
                                }
                              >
                                <SiOneplus size={18} />
                              </S.AddButton>
                            </Tooltip>
                          )}
                        </div>

                        {values.conditions.map((condition, index) => (
                          <div className="row">
                            <S.Label>for</S.Label>

                            <MultiInputSelect
                              label="Condição"
                              onChange={handleChange}
                              name={`conditions[${index}].operator`}
                              value={values.conditions[index].operator}
                              data={CONDITIONAL_OPERATORS}
                              nameLabel="helper"
                              valueData="operator"
                            />

                            <Input
                              name={`conditions[${index}].value`}
                              style={{ maxWidth: '100px' }}
                              label="Limite"
                              type="number"
                              onChange={handleChange}
                              value={values.conditions[index].value}
                            />
                            {checkLastItemValue(values.conditions, index) && (
                              <Tooltip title="Nova condição">
                                <S.AddButton
                                  type="button"
                                  onClick={() =>
                                    arrayHelpers.push({
                                      operator: '',
                                      value: 0,
                                    })
                                  }
                                >
                                  <SiOneplus size={18} />
                                </S.AddButton>
                              </Tooltip>
                            )}

                            <Tooltip title="Remover condição">
                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <FiX color="#c53030" size={18} />
                              </button>
                            </Tooltip>
                          </div>
                        ))}
                      </>
                    ) :
                      null}
                  </>
                )}
              />

              {values.telemetryVariablesId && (
                <>
                  <S.WarnCard>
                    <span>
                      Defina as ações executadas na sinalização dos alarmes
                    </span>
                  </S.WarnCard>

                  <SimpleSteptForm
                    values={STEP_ACTIONS}
                    onChange={changeStepState}
                    currentValue={stepAction}
                  />

                  {stepAction === 0 && (
                    <FieldArray
                      name="actions.emails"
                      render={(arrayHelpers) => (
                        <>
                          <div
                            className="row"
                            style={{
                              marginTop: '2rem',
                              justifyContent: 'space-between',
                            }}
                          >
                            <S.Label>Envio de Email</S.Label>
                            <Tooltip title="Novo e-mail">
                              <button
                                type="button"
                                onClick={() => arrayHelpers.push('')}
                              >
                                <SiOneplus size={18} />
                              </button>
                            </Tooltip>
                          </div>
                          {values.actions.emails &&
                            values.actions.emails.map((emailField, index) => (
                              <div className="row">
                                <Input
                                  name={`actions.emails[${index}]`}
                                  type="email"
                                  label="E-mail do destinatário"
                                  onChange={handleChange}
                                  value={emailField}
                                />

                                <button
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <FiX color="#c53030" size={16} />
                                </button>
                              </div>
                            ))}

                          <div className="button-new" />
                        </>
                      )}
                    />
                  )}

                  {stepAction === 1 && (
                    <FieldArray
                      name="actions.whatsapp"
                      render={(arrayHelpers) => (
                        <>
                          <div
                            className="row"
                            style={{
                              marginTop: '2rem',
                              justifyContent: 'space-between',
                            }}
                          >
                            <S.Label>Envio Por WhatsApp</S.Label>
                            <Tooltip title="Novo número de WhatsApp">
                              <button
                                type="button"
                                onClick={() => arrayHelpers.push('')}
                              >
                                <SiOneplus size={18} />
                              </button>
                            </Tooltip>
                          </div>
                          {values.actions.whatsapp.map(
                            (whatsappField, index) => (
                              <div className="row">
                                <Input
                                  name={`actions.whatsapp[${index}]`}
                                  label="WhatsApp do destinatário"
                                  onChange={handleChange}
                                  value={values.actions.whatsapp[index]}
                                />

                                <button
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  <FiX color="#c53030" size={16} />
                                </button>
                              </div>
                            ),
                          )}
                        </>
                      )}
                    />
                  )}

                  {stepAction === 2 && (
                    <FieldArray
                      name="actions.sms"
                      render={(arrayHelpers) => (
                        <>
                          <div
                            className="row"
                            style={{
                              marginTop: '2rem',
                              justifyContent: 'space-between',
                            }}
                          >
                            <S.Label>Envio Por Mensagem</S.Label>
                            <Tooltip title="Novo número para SMS">
                              <button
                                type="button"
                                onClick={() => arrayHelpers.push('')}
                              >
                                <SiOneplus size={18} />
                              </button>
                            </Tooltip>
                          </div>

                          {values.actions.sms.map((smsField, index) => (
                            <div className="row">
                              <Input
                                name={`actions.sms[${index}]`}
                                label="Número do destinatário"
                                onChange={handleChange}
                                value={values.actions.sms[index]}
                              />

                              <button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <FiX color="#c53030" size={16} />
                              </button>
                            </div>
                          ))}

                        </>
                      )}
                    />
                  )}
                </>
              )}

              <div className="button-new">
                <Button type="submit">
                  {alarmToEdit ? 'Atualizar' : 'Adicionar Alarme'}
                </Button>
              </div>
            </Form>
          )}
        />
      </S.Content>
    </S.Wrapper>
  );
}
