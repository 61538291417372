import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { IoAlertCircleOutline } from 'react-icons/io5';
import colors from '../../../../styles/colors';

export default function DadosIndisponiveis({ unitType }) {
  return (
    <>
      <Tooltip disableFocusListener title="Dados Indisponíveis">
        <IconButton style={{ padding: 5 }}>
          <IoAlertCircleOutline size={15} color={colors.darkGrey} />
        </IconButton>
      </Tooltip>
      {unitType}
    </>
  );
}
