import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import GetAppIcon from '@material-ui/icons/GetApp';
import { MdLeaderboard } from 'react-icons/md';
import { min, max, mean, mode, median, standardDeviation } from 'simple-statistics';
import XLSX from 'xlsx-js-style'; // Exporta em Excel
import pdfMake from 'pdfmake/build/pdfmake'; // exporta em pdf
import pdfFonts from 'pdfmake/build/vfs_fonts'; // exporta em pdf
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import moment from 'moment';
// import { makeStyles } from '@material-ui/core';
import { headerCell, dataCell, headerCellBgColor, dataCellBgColor } from '../../helpers/AuxExcelExport';
import { find95Percent } from '../../tTable';
import { dateForExport, dateNow } from '../../helpers/AuxInfo';
import { PdfFooter, PdfHeader } from '../reports/pdf';
import verifyTemperature from '../../helpers/verifyTemperature';

pdfMake.vfs = pdfFonts.pdfMake.vfs; // exporta em pdf

// const useStyles = makeStyles((theme) => ({
//   modal: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//   },
//   paper: {
//     backgroundColor: theme.palette.background.paper,
//     border: '0px solid #000',
//     borderRadius: 6,
//     boxShadow: theme.shadows[5],
//     padding: theme.spacing(2, 4, 3),
//   },
// }));

export default function ActionsArea({ statisticsCallback, data, selectedNumberCables }) {
  // const classes = useStyles();
  const [cables, setCables] = useState([]);
  const [imgTermovisor, setImgTermovisor] = useState('');
  const [imgSuperiorView, setImgSuperiorView] = useState('');
  const [weatherStation, setWeatherStation] = useState({ humid: '', temp: '' });
  const [maxSensors, setMaxSensors] = useState(0);

  React.useEffect(() => {
    const auxCables = [];
    data.chartData.forEach((cd) => {
      cd.unitData.arcs.forEach((arc) => {
        arc.cabs.forEach((cab) => {
          // if (selectedNumberCables.indexOf(cab.num) !== -1) {
          auxCables.push({
            cableNumber: cab.num,
            arcNumber: arc.arc,
            sensors: cab.sens.map((c) => ({
              color: c.gradualColor, t: c.t,
            })),
          });
          // }
        });
      });
    });
    /* selectedNumberCables.forEach((e) => {
      refCables.push(auxCables.find((f) => f.cableNumber === e));
    }); */
    setCables(auxCables/* refCables */);
    setWeatherStation({ humid: data.weatherStation.humid, temp: data.weatherStation.temp });
  }, [data, selectedNumberCables]);

  // ******** CALCULO DAS ESTATÍSTICAS ******** \\
  const calcStatistics = () => {
    const temps = [];
    const stats = {
      cables: 0,
      arcs: 0,
      equipament: {
        description: data.description,
        clientDescription: data.clientDescription,
      },
      plant: {
        description: data.plant.description,
        technicalDescription: data.plant.technicalDescription,
      },

    };
    data.chartData.forEach((cd) => {
      cd.unitData.arcs.forEach((arc) => {
        stats.arcs += 1;
        arc.cabs.forEach((cab) => {
          stats.cables += 1;
          cab.sens.forEach((s) => {
            if ((!s.out) && (s.t !== 997) && (s.t !== 998) && (s.t !== 999)) {
              temps.push(s.t);
            }
          });
        });
      });
    });
    const minI = min(temps);
    const maxI = max(temps);
    const standardDeviationI = standardDeviation(temps);
    const avg = mean(temps);

    const found95 = find95Percent(temps.length - 1);
    const trust = found95 * (standardDeviationI / Math.sqrt(temps.length));

    // percent95

    statisticsCallback({
      avg,
      median: median(temps),
      mode: mode(temps),
      standardDeviation: standardDeviationI,
      interval: maxI - minI,
      min: minI,
      max: maxI,
      count: temps.length,
      trust,
      ...stats,
      ts: data.ts,
      weatherStation: data.weatherStation,
    });
  };
  // ///////////////////////////////////////////////////////////////////////////

  // exporta em CSV
  /* function handleExportCSV() {
    alert('Aqui irá exportar para CSV');
  } */

  // exportar em Excel
  function handleExportXLSX() {
    // console.log('cables->', cables);
    const dataforExcel = cables?.map((cable) => {
      // console.log('cable.sensors->', cable.sensors);
      const sensores = cable.sensors.map((sensor) => (
        dataCellBgColor({ value: verifyTemperature(sensor.t).toString(), bgColor: sensor.color })
      ));

      return [
        headerCellBgColor({ value: cable.cableNumber.toString(), bgColor: '#556E7B', fontColor: '#FFFFFF' }),
        headerCellBgColor({ value: cable.arcNumber.toString(), bgColor: '#556E7B', fontColor: '#FFFFFF' }),
        ...sensores,
      ];
    });

    const colSens = [[]];
    cables?.map((cable) => {
      if ((colSens.length === 0) || (colSens[0].length < cable.sensors.length)) {
        colSens.pop();
        colSens.push(cable.sensors.map((sensor, index) => (
          // headerCellBgColor({ value: `S${index + 1}`, bgColor: '#556E7B', fontColor: '#FFFFFF' })
          // eslint-disable-next-line prefer-template
          headerCellBgColor({ value: 'S' + (index + 1), bgColor: '#556E7B', fontColor: '#FFFFFF' })
        )));
      }
      return [
        ...colSens,
      ];
    });

    // console.log('data ', data);

    const headerExcel = [
      headerCellBgColor({ value: 'Cabo', bgColor: '#556E7B', fontColor: '#FFFFFF' }),
      headerCellBgColor({ value: 'Anel', bgColor: '#556E7B', fontColor: '#FFFFFF' }),
    ];

    headerExcel.push(...colSens[0]);

    const dataExcel = [
      [
        // headerCell({ value: 'Silo:' }),
        headerCell({
          value: `${data?.plant?.description || data?.plant?.technicalDescription} ${'-'} ${data?.clientDescription || data?.description}`,
        }),
      ],
      [
        headerCell({ value: '' }),
      ],
      [
        headerCell({ value: 'Dados da Leitura' }),
      ],
      [
        headerCell({ value: 'Data' }),
        dataCell({
          value: moment.unix(data.ts).format('DD/MM/YYYY'),
        }),
      ],
      [
        headerCell({ value: 'Hora' }),
        dataCell({
          value: moment.unix(data.ts).format('HH:MM:SS'),
        }),
      ],
      [
        headerCell({ value: '' }),
      ],
      [
        headerCell({ value: 'Dados Meteorológicos' }),
      ],
      [
        headerCell({ value: 'Temperatura' }),
        dataCell({
          value: `${verifyTemperature(weatherStation.temp)} ${verifyTemperature(weatherStation.temp) === 'OFF' ? '' : '°C'}`,
        }),
      ],
      [
        headerCell({ value: 'Umidade' }),
        dataCell({
          value: `${verifyTemperature(weatherStation.humid)} ${verifyTemperature(weatherStation.humid) === 'OFF' ? '' : '%'}`,
        }),
      ],
      [
        headerCell({ value: '' }),
      ],
      [
        headerCell({ value: 'Temperaturas Detalhadas' }),
      ],
      headerExcel,
      ...dataforExcel,
      [
        headerCell({ value: '' }),
        dataCell({ value: '' }),
      ],
      [
        headerCell({ value: 'Exportado dia: ' }),
        dataCell({
          value: dateNow(),
        }),
      ],
    ];

    // const dataExcel = [[headerCell({ value: 'Teste' })], [dataCell({ value: 'Teste' })]];
    // console.log('exportar em excel->', dataExcel);
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(dataExcel);// json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'svg1');
    XLSX.writeFile(wb, `report-2d-${dateForExport()}.xlsx`);
  }

  // exportar em PDF
  async function handleExportPDF() {
    // captura a termovisão
    html2canvasTermoVision();
    // captura a visão superior
    html2canvasSuperiorVision();
    /* const imgUrl = 'https://i.picsum.photos/id/158/200/300.jpg?hmac=zGxgMCbgRICJUx_OiJuG6hOjsFmnPpzUWCn5KO6Xk1g';
    const getBase64Image = (url) => {
      const img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/png');
        setImgTermovisor(dataURL);
        console.log(dataURL);
      };
      img.src = url;
    };
    console.log(getBase64Image(imgUrl));
    const tt = getBase64Image(imgUrl);
    console.log('img64 ', imgTermovisor); */
  }

  useEffect(() => {
    const colSens = [[]];
    cables?.map((cable) => {
      if ((colSens.length === 0) || (colSens[0].length < cable.sensors.length)) {
        colSens.pop();
        colSens.push(cable.sensors.map((sensor, index) => (
          // { text: `S${index + 1}`, fillColor: '#556E7B', style: 'tableHeader',
          // alignment: 'center', color: '#FFFFFF', bold: true }
          // eslint-disable-next-line prefer-template
          { text: 'S' + (index + 1), fillColor: '#556E7B', style: 'tableHeader', alignment: 'center', color: '#FFFFFF', bold: true }
        )));
      }
      return [
        ...colSens,
      ];
    });

    setMaxSensors(colSens[0].length);

    const detailedTemperature = cables?.map((cable) => {
      const sensores = cable.sensors.map((sensor/* , index */) => (
        // eslint-disable-next-line prefer-template
        { text: verifyTemperature(sensor.t).toString(), fillColor: sensor.color, alignment: 'center', fontSize: 6 }
      ));

      while (sensores.length < colSens[0].length) {
        sensores.push('');
      }

      return [
        // { text: '', fillColor: '#556E7B', style: 'tableHeader', fontSize: 2 },
        { text: cable.cableNumber.toString(), fillColor: '#556E7B', style: 'tableHeader', alignment: 'center', color: '#FFFFFF', bold: true },
        { text: cable.arcNumber.toString(), fillColor: '#556E7B', style: 'tableHeader', alignment: 'center', color: '#FFFFFF', bold: true },
        ...sensores,
        // { text: '', style: 'tableHeader', fontSize: 2 },
      ];
    });

    if ((imgTermovisor) && (imgSuperiorView)) {
      const pdfContent = [
        {
          text: (`${data?.plant?.description || data?.plant?.technicalDescription} ${'-'} ${data?.clientDescription || data?.description}`).toUpperCase(),
          fontSize: 12,
          bold: true,
          alignment: 'center',
          margin: [0, 5, 0, 5],
        },
        {
          text: `Leitura do Dia ${moment.unix(data.ts).format('DD/MM/YYYY')} | Horário: ${moment.unix(data.ts).format('HH:MM:SS')}`,
          fontSize: 11,
          bold: true,
          margin: [0, 5, 0, 5],
        },
        {
          alignment: 'justify',
          columns: [
            {
              text: 'Termovisor',
              fontSize: 11,
              bold: false,
              margin: [0, 10, 0, 0],
            },
            {
              text: 'Visão Superior',
              fontSize: 11,
              bold: false,
              margin: [0, 10, 0, 0],
            },
          ],
        },
        {
          alignment: 'justify',
          columns: [
            {
              image: imgTermovisor,
              width: 300,
              margin: [0, -20, 0, 0],
            },
            {
              image: imgSuperiorView,
              width: 350,
              margin: [0, 0, 0, 0],
            },
          ],
        },
        {
          text: 'Dados Meteorológicos',
          fontSize: 11,
          bold: true,
          margin: [0, 0, 0, 5],
        },
        {
          style: 'tableExample',
          fontSize: 8,
          alignment: 'center',
          table: {
            headerRows: 1,
            body: [
              [
                { text: '', fillColor: '#556E7B', style: 'tableHeader', alignment: 'center', color: '#FFFFFF', bold: true },
                { text: ' Temperatura ', fillColor: '#556E7B', style: 'tableHeader', alignment: 'center', color: '#FFFFFF', bold: true },
                { text: ' Umidade ', fillColor: '#556E7B', style: 'tableHeader', alignment: 'center', color: '#FFFFFF', bold: true },
                { text: '', fillColor: '#556E7B', style: 'tableHeader', alignment: 'center', color: '#FFFFFF', bold: true },
              ],
              [
                '',
                {
                  text: `${verifyTemperature(weatherStation.temp)} ${verifyTemperature(weatherStation.temp) === 'OFF' ? '' : '°C'}`,
                  style: 'tableHeader',
                  alignment: 'center',
                  color: '#000000',
                  bold: false },
                {
                  text: `${verifyTemperature(weatherStation.humid)} ${verifyTemperature(weatherStation.humid) === 'OFF' ? '' : '%'}`,
                  style: 'tableHeader',
                  alignment: 'center',
                  color: '#000000',
                  bold: false },
                '',
              ],
            ],
          },
          layout: 'lightHorizontalLines',
        },
        {
          text: 'Temperaturas Detalhadas',
          fontSize: 11,
          bold: true,
          margin: [0, 15, 0, 5],
        },
        {
          style: 'tableExample',
          fontSize: (maxSensors <= 16) ? (8) : (6),
          alignment: 'center',
          table: {
            headerRows: 1,
            body: [
              [
                // { text: '', fillColor: '#556E7B', style: 'tableHeader', fontSize: 2 },
                { text: ' Cabo', fillColor: '#556E7B', style: 'tableHeader', alignment: 'center', color: '#FFFFFF', bold: true },
                { text: 'Anel', fillColor: '#556E7B', style: 'tableHeader', alignment: 'center', color: '#FFFFFF', bold: true },
                ...colSens[0],
                // { text: '', fontSize: 2 },
              ],
              ...detailedTemperature,
            ],
          },
          layout: 'headerLineOnly', // 'lightHorizontalLines',
        },

      ];

      const pdfDefinitions = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],
        pageOrientation: (maxSensors <= 16) ? 'portrait' : 'landscape',
        header: PdfHeader(),
        content: [pdfContent],
        footer: PdfFooter(),
      };

      pdfMake.createPdf(pdfDefinitions).download(`report-2d-${dateForExport()}`);

      setImgTermovisor('');
      setImgSuperiorView('');
    }
  }, [imgTermovisor, imgSuperiorView]);

  function html2canvasTermoVision() {
    /* htmlToImage.toJpeg(document.getElementById('termovisor'), { quality: 0.95 })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = 'my-image-name.jpeg';
        link.href = dataUrl;
        console.log(dataUrl);
        link.click();
      }); */
    htmlToImage.toCanvas(document.getElementById('termovisor'))
      .then((canvas) => {
        // document.body.appendChild(canvas);
        setImgTermovisor(canvas.toDataURL());
      });
    /* html2canvas(document.body.querySelector('#termovisor')).then((canvas) => {
      // console.log(canvas.toDataURL());
      // document.body.appendChild(canvas);
      // Canvas2Image.saveAsPNG(canvas);
      // canvas2image.convertToPNG(canvas, 400, 400);
      canvas2image.saveAsImage(canvas, 400, 400);
      // setImgTermovisor(canvas.toDataURL());
    }); */
  }
  function html2canvasSuperiorVision() {
    html2canvas(document.body.querySelector('#superiorview')).then((canvas) => {
      // console.log(canvas.toDataURL());
      // document.body.appendChild(canvas);
      setImgSuperiorView(canvas.toDataURL());
    });
  }

  return (
    <div style={{ textAlign: 'right' }}>
      <ButtonGroup size="small" variant="contained" color="primary">
        <Button onClick={calcStatistics}>
          <MdLeaderboard size={22} style={{ padding: 5 }} />
          Estatísticas
        </Button>
        {/* <Button onClick={()=>{ handleExportCSV() }}>
          <GetAppIcon size={22} style={{ padding: 5 }} />
          CSV
  </Button> */}
        <Button onClick={() => handleExportXLSX()}>
          <GetAppIcon size={22} style={{ padding: 5 }} />
          XLSX
        </Button>
        <Button onClick={() => { handleExportPDF(); }}>
          <GetAppIcon size={22} style={{ padding: 5 }} />
          PDF
        </Button>
      </ButtonGroup>
    </div>
  );
}
