// import React from 'react';

export default function verifyTemperature(t) {
  // console.log('t ->', t);

  let tChecked;

  switch (t) {
    case 997:
      tChecked = 'ERR'; // 1 anel
      break;
    case 998:
      tChecked = 'N/D'; // 2 anel
      break;
    case 999:
      tChecked = 'OFF'; // 3 anel ou central
      break;
    default:
      tChecked = t;
      break;
  }

  // console.log('tChecked ->', tChecked);

  return tChecked;
}
