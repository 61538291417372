import { useFormik } from 'formik';
import React from 'react';
import { Input, ButtonComponent as Button } from '../../../../../../components';
import FockinkAPI from '../../../../../../middlewares/Api';
import * as S from './style';

export function EditCustomVariableForm({
  variable,
  handleGetVariables,
  handleCloseEditCustomModal,
}) {
  const [success, setSuccess] = React.useState();
  const [errorRequest, setErrorRequest] = React.useState();

  const formik = useFormik({
    initialValues: {
      telemetryVariablesId: variable.telemetryVariablesId,
      thresholdMax: variable.thresholdMax,
      thresholdMin: variable.thresholdMin,
      useDefault: false,
    },
    onSubmit: async (values) => {
      try {
        await FockinkAPI.put(
          `telemetryVariablesAccount/${variable.id}`,
          values,
        );
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
        handleGetVariables();
        handleCloseEditCustomModal();
      } catch (error) {
        setErrorRequest(JSON.parse(error.request.response).message);
        setTimeout(() => {
          setErrorRequest(false);
        }, 4000);
      }
    },
  });

  return (
    <S.Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <Input
            error={formik.errors.thresholdMin}
            type="number"
            name="thresholdMin"
            label="Thresold Mínimo"
            onChange={formik.handleChange}
            value={formik.values.thresholdMin}
          />
          <Input
            error={formik.errors.thresholdMax}
            style={{ marginLeft: '0.5rem' }}
            type="number"
            name="thresholdMax"
            label="Thresold máximo"
            onChange={formik.handleChange}
            value={formik.values.thresholdMax}
          />
        </div>

        <div className="confirm-button">
          {success ? (
            <strong className="success">Sucesso!</strong>
          ) : errorRequest ? (
            <strong className="error">{errorRequest}</strong>
          ) : (
            <Button type="submit">atualizar</Button>
          )}
        </div>
      </form>
    </S.Wrapper>
  );
}
