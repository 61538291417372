import React from 'react';
import { Select, InputLabel, MenuItem, ListItemText } from '@material-ui/core';

export function YearDatePicker({ selectedDate, onChange }) {
  const max = new Date().getFullYear();
  const min = max - 10;
  const years = [];

  for (let i = max; i >= min; i -= 1) {
    years.push(i);
  }

  return (
    <>
      <InputLabel>Selecione um ano</InputLabel>
      <Select
        value={selectedDate}
        defaultValue={max}
        onChange={onChange}
        name="plantId"
      >

        {years.map((year) => (
          <MenuItem key={year} value={year}>
            <ListItemText
              primary={year}
            />
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
