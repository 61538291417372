// Basic
import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

// UI, images, styles and icons
import '../App.css';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import EquipStatus from './EquipStatus';

import { PageContext } from '../hooks/Contexts/Page';

const useStyles = makeStyles({
  card: {
    padding: '15px',
    cursor: 'pointer',
  },
  title: {
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#0386FF',
    marginTop: 0,
  },
  masterIcon: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  masterImage: {
    height: '70px',
  },
  notifications: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'space-around',
  },
  notificationIcon: {
    fontSize: '30px',
  },
  onIcon: {
    color: 'green',
    fontSize: '30px',
  },
  offIcon: {
    color: 'gray',
    fontSize: '30px',
    opacity: 0.4,
  },
});

export default (props) => {
  const classes = useStyles();
  const [redirect, setRedirect] = React.useState(false);
  const details = {
    1: {
      icon: (
        <img
          src="/images/icons/pivotIcon.svg"
          className={classes.masterImage}
          alt=""
        />
      ),
      url: 'irrigationDash',
    },
    2: {
      icon: (
        <img
          src="/images/icons/thermometryIcon.svg"
          className={classes.masterImage}
          alt=""
        />
      ),
      url: 'thermometryDash',
    },
    3: {
      icon: (
        <img
          src="/images/icons/automationIcon.svg"
          className={classes.masterImage}
          alt=""
        />
      ),
      url: 'automationDash',
    },
    4: {
      icon: (
        <img
          src="/images/icons/powerPlantIcon.svg"
          className={classes.masterImage}
          alt=""
        />
      ),
      url: 'photovoltaicDash',
    },
    5: {
      icon: (
        <img
          src="/images/icons/wheater-station.svg"
          className={classes.masterImage}
          alt=""
        />
      ),
      url: 'weatherStation',
    },
    6: {
      icon: (
        <img
          src="/images/icons/painelEletrico.png"
          className={classes.masterImage}
          alt=""
        />
      ),
      url: 'equipamentData',
    },
  };

  const {
    setBackPageContext,
    setPlantIDContext,
  } = useContext(PageContext);

  const goRedirect = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const pid = urlParams.get('plantId');
    setPlantIDContext(pid);
    setBackPageContext('equip');
    setRedirect(`${details[props.data.operationId].url}/${props.data.id}`);
  };

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <Paper style={{ background: props.data.setup ? undefined : '#ffcbcb' }}>
      <Grid
        container
        className={classes.card}
        alignItems="flex-end"
        onClick={goRedirect}
      >
        <Grid item xs={12}>
          <h3 className={classes.title}>
            {props.data.clientDescription || props.data.description}
          </h3>
        </Grid>

        <Grid item xs={5} className={classes.masterIcon}>
          {details[props.data.operationId].icon}
        </Grid>

        <Grid item xs={7} className={classes.notifications}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs={12}>
              {props.data.unit && props.data.unit.name && (
                <Tooltip
                  title={`${props.data.unit.name} (${props.data.unit.description})`}
                >
                  <Chip
                    style={{ maxWidth: '100%' }}
                    color="primary"
                    size="small"
                    label={props.data.unit.name}
                  />
                </Tooltip>
              )}
            </Grid>

            <Grid item xs={12}>
              {props.data.setup ? (
                <EquipStatus
                  connection={props.data.connection}
                  running={props.data.running}
                  alert={props.data.failures}
                />
              ) : (
                <span
                  style={{
                    color: '#AE2828',
                    fontWeight: '500',
                    fontSize: '12px',
                  }}
                >
                  NÃO CONFIGURADO!
                </span>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
