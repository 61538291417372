import Enum from 'enum';

const PortalPermissions = new Enum(
  {
    THERMOMETRY_DASHBOARD_VIEW: 100,
    THERMOMETRY_SETTINGS_VIEW: 101,
    THERMOMETRY_SETTINGS_EDIT: 102,
    THERMOMETRY_REPORTS_MANAGE: 103,
    THERMOMETRY_SEND_OPERATION: 104,
    //
    IRRIGATION_DASHBOARD_VIEW: 200,
    IRRIGATION_SETTINGS_VIEW: 201,
    IRRIGATION_SETTINGS_EDIT: 202,
    IRRIGATION_SEND_OPERATION: 204,
    //
    PHOTOVOLTAIC_DASHBOARD_VIEW: 300,
    PHOTOVOLTAIC_SETTINGS_VIEW: 301,
    PHOTOVOLTAIC_SETTINGS_EDIT: 302,
    PHOTOVOLTAIC_SEND_OPERATION: 304,
    //
    AUTOMATION_DASHBOARD_VIEW: 400,
    AUTOMATION_SETTINGS_VIEW: 401,
    AUTOMATION_SETTINGS_EDIT: 402,
    AUTOMATION_SEND_OPERATION: 404,
    //
    PLANTS_SETTINGS_VIEW: 501,
    PLANTS_SETTINGS_EDIT: 502,
    //
    USERS_VIEW: 600,
    USERS_MANAGE: 601,
    //
    ENERGY_COST_MANAGE: 701,
    //
    UNITS_VIEW: 800,
    UNITS_MANAGE: 801,
    //
    WEATHER_STATION_DASHBOARD_VIEW: 900,
    WEATHER_STATION_SETTINGS_VIEW: 901,
    WEATHER_STATION_SETTINGS_EDIT: 902,
    WEATHER_STATION_SEND_OPERATION: 904,
  },
  { freeze: true },
);

export default PortalPermissions;
