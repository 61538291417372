import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { TextField } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberFormat from 'react-number-format';
import Breadcrumbs from '../../../components/Breadcrumbs';
import FockinkAPI from '../../../middlewares/Api';
import AddressFields from '../../../components/AddressFields';
import BottomButtons from '../../../components/Buttons/BottomButtons';
import PortalPermissions from '../../../helpers/enums/PortalPermissions';

const useStyles = makeStyles((theme) => ({
  bottomButtons: {
    margin: '15px 0',
    textAlign: 'right',
    paddingTop: '10px !important',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default (props) => {
  const [data, setData] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [breadcrumbs, setBreadcrumbs] = React.useState(null);
  const classes = useStyles();

  const callApi = () => {
    setLoading(true);
    FockinkAPI.get(`/plants/${props.match.params.id}/settings`).then(
      (response) => {
        if (response.data.data.settings) {
          setData({
            ...response.data.data.settings,
            description: response.data.data.description,
            location: response.data.data.settings.location || {},
            performanceEstimate:
              response.data.data.settings.performanceEstimate || {},
            limits: response.data.data.settings.limits || {
              failedHardwares: '',
              failedCommunications: '',
            },
          });
        } else {
          setData({
            location: {},
            performanceEstimate: {},
            limits: { failedHardwares: '', failedCommunications: '' },
          });
        }

        setBreadcrumbs({
          routes: [
            { name: 'Plantas', to: '/plants' },
            { name: 'Configurações' },
          ],
        });
        setLoading(false);
      },
    );
  };

  React.useEffect(() => {
    callApi();
  }, [props.match.params.id]);

  if (data && data.operationId === 'loading') {
    return <h3 className={classes.loading}>Carregando</h3>;
  }

  const handleChangeLocation = (location) => {
    setData({ ...data, location });
  };

  const handleChangeLimits = (e) => {
    const limits = { ...data.limits, [e.target.name]: e.target.value };
    setData({ ...data, limits });
  };

  const saveData = () => {
    const dt = {
      data,
      method: 'put',
      url: `/plants/${props.match.params.id}/settings`,
    };
    return dt;
  };

  if (loading) {
    return (
      <>
        <Breadcrumbs loading={loading} />
        <Grid container spacing={2}>
          {[0, 1, 2, 3].map((k) => (
            <>
              <Grid item xs={12}>
                <Skeleton
                  variant="rect"
                  height={20}
                  width={100}
                  animation="wave"
                />
                <hr />
              </Grid>
              {[0, 1, 2, 3, 4, 5, 6].map((j) => (
                <Grid item key={j} xs={12} md={12} lg={3}>
                  <Skeleton
                    key={`${k}${j}`}
                    className={classes.skeleton}
                    variant="rect"
                    height={50}
                    width="100%"
                    animation="wave"
                  />
                </Grid>
              ))}
            </>
          ))}
        </Grid>
      </>
    );
  }

  const changeBase = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <Breadcrumbs
        routes={breadcrumbs.routes}
        title={`Planta: ${data.description || ''}`}
      />
      <Grid container style={{ minHeight: 'calc(100vh - 201px)' }}>
        <Grid item xs={12}>
          <h3 style={{ marginBottom: '0' }}>Dados da Planta</h3>
          <hr />
          <TextField
            variant="filled"
            name="description"
            onChange={changeBase}
            value={data.description || data.technicalDescription}
            label="Descrição"
          />
          <hr />
          <Grid item lg={12}>
            <NumberFormat
              value={data.costKwh}
              label="Custo kWh"
              customInput={TextField}
              variant="filled"
              prefix="R$ "
              thousandSeparator="."
              decimalSeparator=","
              onValueChange={(v) =>
                changeBase({
                  target: {
                    name: 'costKwh',
                    value: v.floatValue,
                  },
                })
                      }
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       minutos
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Grid>
          <hr />
          <Grid item lg={12} xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Detalhes de plantas
                </Typography>
              </AccordionSummary>

              <AccordionDetails item lg={6} xs={12}>
                <Grid container spacing={2}>
                  <Grid item lg={6} xs={12} sm={12}>
                    <NumberFormat
                      value={data.limits.failedHardwares}
                      label="Minutagem aceitável de problemas de comunicação de hardware por dia"
                      customInput={TextField}
                      variant="filled"
                      thousandSeparator=""
                      decimalSeparator="."
                      onValueChange={(v) =>
                        handleChangeLimits({
                          target: {
                            name: 'failedHardwares',
                            value: v.floatValue,
                          },
                        })
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            minutos
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12} sm={12}>
                    <NumberFormat
                      value={data.limits.failedCommunications}
                      label="Minutagem aceitável de problemas de comunicação com o portal por dia"
                      customInput={TextField}
                      variant="filled"
                      thousandSeparator=""
                      decimalSeparator="."
                      onValueChange={(v) =>
                        handleChangeLimits({
                          target: {
                            name: 'failedCommunications',
                            value: v.floatValue,
                          },
                        })
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            minutos
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <hr />
        </Grid>

        <Grid item xs={12}>
          <h3 style={{ marginBottom: '0' }}>Localização</h3>
          <hr />
        </Grid>

        <Grid item xs={12}>
          <AddressFields
            handleChangeLocation={handleChangeLocation}
            data={data.location}
          />
        </Grid>

        <Grid item xs={12} className={classes.bottomButtons}>
          <BottomButtons
            saveData={saveData}
            backTo="/plants"
            canSave={PortalPermissions.PLANTS_SETTINGS_EDIT.value}
          />
        </Grid>
      </Grid>
    </>
  );
};
