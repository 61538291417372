/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import Skeleton from '@material-ui/lab/Skeleton';
import FockinkAPI from '../../../../middlewares/Api';
import { number, currency } from '../../../../helpers/Intl';

const useStyles = makeStyles({
  title: {
    margin: '5px 0',
    fontWeight: '500',
  },
});

export default (props) => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);
  const classes = useStyles();

  const callApi = () => {
    setLoading(true);
    FockinkAPI.get(
      `/dashboards/photovoltaic/${props.equipamentid}/production`,
    ).then((response) => {
      setData(response.data.data);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    callApi();
  }, [props.equipamentid]);

  if (loading) {
    return (
      <Skeleton variant="rect" height="100%" width="100%" animation="wave" />
    );
  }

  return (
    <Paper style={{ padding: '15px', height: '100%' }}>
      <h3 style={{ margin: '0 0 15px', fontWeight: '500' }}>Produção</h3>
      <Grid
        container
        spacing={2}
        style={{ paddingTop: '4px' }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={12} md={12} lg={1}>
          <FlashOnIcon style={{ fontSize: '30px', marginTop: '29px' }} />
          <LocalAtmIcon style={{ fontSize: '30px' }} />
        </Grid>

        <Grid item xs={12} md={12} lg={3}>
          <h3 className={classes.title}>Hoje</h3>
          <h2 className={classes.title}>
            {`${number(data.production.totalDay)} kWh`}
          </h2>
          <h4 className={classes.title}>
            {`${currency(data.production.totalDay * (data.kWhValue || 0))}`}
          </h4>
        </Grid>

        <Grid item xs={12} md={12} lg={3}>
          <h3 className={classes.title}>Semana</h3>
          <h2 className={classes.title}>
            {`${number(data.production.totalWeek / 1000)} MWh`}
          </h2>
          <h4 className={classes.title}>
            {`${currency(data.production.totalWeek * (data.kWhValue || 0))}`}
          </h4>
        </Grid>

        <Grid item xs={12} md={12} lg={3}>
          <h3 className={classes.title}>Mês</h3>
          <h2 className={classes.title}>
            {`${number(data.production.totalMonth / 1000)} MWh`}
          </h2>
          <h4 className={classes.title}>
            {`${currency(data.production.totalMonth * (data.kWhValue || 0))}`}
          </h4>
        </Grid>
      </Grid>
    </Paper>
  );
};
