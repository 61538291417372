import React from 'react';
import {
  Dialog,
  withStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core/';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { useTheme } from '@material-ui/core/styles';
import './style.scss';

const styles = (theme) => ({
  rootModal: {
    padding: '4px',
    paddingLeft: '4px',
  },
  root: {
    margin: 0,
    padding: '4px',
  },
  closeButton: {
    position: 'absolute',
    right: '4px',
    top: theme.spacing(1),
  },
});

export function Modal({ title, open, handleCloseModal, children }) {
  const materialTheme = useTheme();
  const fullScreen = useMediaQuery(materialTheme.breakpoints.down('sm'));
  const DialogTitle = withStyles(styles)(({ classes, ...other }) => (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{ maxWidth: '95%' }}>
        {title}
      </Typography>
      {handleCloseModal ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  ));

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
  }))(MuiDialogContent);

  return (
    <Dialog
      open={open}
      onClose={handleCloseModal}
      fullScreen={fullScreen}
      maxWidth={false}
      // disableBackdropClick
    >
      <DialogTitle className="modal_title" onClose={handleCloseModal} />
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
}
