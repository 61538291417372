import React, { useEffect, useState } from 'react';
import { useStyles } from './styles';
import { createDaysFromMonth } from '../../../../../helpers/createDaysOnMonth';
import { ButtonGraphic } from '../components/ButtonGraph';

import { TableReport } from '../components/TableReport';
import { EquiapmentsModal } from '../components/EquipamentsModal';

export default function ReportPlant({ selectedDate, data, selectedPlant }) {
  const report = data;
  const [labelTabel, setLabelTable] = useState();
  const [subtitle, setSubtitle] = useState();
  const [labelDays, setLabelDays] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    (() => {
      const name = `${selectedPlant.plant} - ${selectedDate.toLocaleString('default', {
        month: 'long',
      })}`;
      const copyGrains = [...report.grains];

      const subLabel = copyGrains.map((grain) => {
        const formatGrain = grain.charAt(0).toUpperCase() + grain.slice(1);
        return formatGrain;
      });

      const constFormatGrainsList = subLabel.join(' - ');
      setLabelTable(name);
      setSubtitle(constFormatGrainsList);
    })();

    const generateLabelDays = createDaysFromMonth(selectedDate, report.lastTelemetry);

    const newGenerateLabelDays = generateLabelDays.map((item) => ({
      day: `${item.day > 9 ? item.day : `0${item.day}`}/${
        selectedDate.getMonth() + 1 > 9
          ? selectedDate.getMonth() + 1
          : `0${selectedDate.getMonth() + 1}`
      }`,
      // `${item.day > 10 ? item.day : item.day}/${selectedDate.getMonth() + 1}`,
      lastTelemetryOnDay: item.lastTelemetryOnDay,
    }));

    /* console.log('selectedDate: ', selectedDate);
    // console.log('generateLabelDays: ', generateLabelDays);
    // console.log('newGenerateLabelDays: ', newGenerateLabelDays); */

    setLabelDays(newGenerateLabelDays);
  }, [selectedPlant.plant, report.grains, selectedDate, report.lastTelemetry]);

  const classes = useStyles();

  const handleOpenModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  return (
    <div className={classes.container}>
      <div className={classes.divOptions}>
        <ButtonGraphic handleClick={handleOpenModal}>Detalhes</ButtonGraphic>
      </div>

      <TableReport
        label={labelTabel}
        subtitle={subtitle}
        reportData={report}
        labelDays={labelDays}
        plant={selectedPlant.plant}
        selectedDate={selectedDate}
      />

      {isOpenModal && (
        <EquiapmentsModal
          labelDays={labelDays}
          selectedDate={selectedDate}
          grains={report.grains}
          plant={selectedPlant.plantId}
          isOpen={isOpenModal}
          onClose={handleOpenModal}
        />
      )}
    </div>
  );
}
