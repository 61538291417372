/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import DataTable from 'react-data-table-component';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import FockinkAPI from '../../../../../middlewares/Api';
import {
  formatDate,
  currency,
  secondsToHm,
  secondsToDhms,
} from '../../../../../helpers/Intl';

export default (props) => {
  const [data, setData] = React.useState({
    chartData: { series: [], categories: [] },
    tableData: { tableHeader: [], tableBody: [] },
  });
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [loading, setLoading] = React.useState(true);
  const [days, setDays] = React.useState(5);
  const callApi = () => {
    setLoading(true);
    FockinkAPI.get(
      `/dashboards/thermometry/${props.equipamentId}/charts/aeration?days=${days}&sub=${props.sub}&date=${props.lastValidDate}`,
    ).then((response) => {
      setData(response.data.data);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    callApi();
    // const interval = setInterval(() => {
    //   callApi();
    // }, 300000);
    // return () => clearInterval(interval);
  }, [props.equipamentId, days, props.sub, props.lastValidDate]);

  const formatName = (name) => {
    if (name === 'totalAeration') return 'Aeração Total';
    if (name === 'qtyMotors') return 'Qtd. Motores';
    if (name === 'kWhValue') return 'Consumo (R$)';
    return name;
  };

  React.useEffect(() => {
    const dt = [];

    const columns = [
      { name: '', selector: 'Head', center: true },
      ...(data.tableData.tableBody[0] || []).reverse().map((e, i) => ({
        name: formatDate(e, 'dd/MM/yyyy'),
        // selector: `R${i}`,
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {typeof row[`R${i}`] === 'object' ? (
              <>
                <span style={{ whiteSpace: 'nowrap' }}>
                  {/* {`${(row[`R${i}`].aerationDaySec || '00').replace(
                    /:/g,
                    'h ',
                  )}m`} */}
                  {secondsToDhms(row[`R${i}`].aerationDaySec)}
                </span>
                <Tooltip title="Horas favoráveis perdidas">
                  <span
                    style={{
                      color: 'red',
                      marginLeft: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {`(${(row[`R${i}`].aerationFailDay || '00').replace(
                      /:/g,
                      'h ',
                    )}m)`}
                  </span>
                </Tooltip>
              </>
            ) : (
              row[`R${i}`]
            )}
          </div>
        ),
        center: true,
      })),
    ];

    data.tableData.tableHeader.slice(1).forEach((e, i) => {
      dt[i] = dt[i] || {};
      dt[i].Head = formatName(e);
    });

    data.tableData.tableBody.slice(1).forEach((e, i) => {
      e.reverse().forEach((f, j) => {
        if (i === 0) dt[i][`R${j}`] = f;
        else if (i === 2) dt[i][`R${j}`] = currency(f);
        else if (typeof f !== 'object') {
          // dt[i][`R${j}`] = `${f.replace(/:/g, 'h ')}m`;
          dt[i][`R${j}`] = secondsToDhms(f);
        } else dt[i][`R${j}`] = f;
      });
    });

    setDtCl({ data: dt, columns });
  }, [data.tableData]);

  const state = {
    series: data && data.chartData ? data.chartData.series : [],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        type: 'bar',
        height: 300,
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: (data && data.chartData
          ? data.chartData.categories
          : []
        ).map((e) => formatDate(e, 'dd/MM/yyyy')),
      },
      yaxis: {
        labels: {
          formatter(val) {
            // return `${val}h`;
            return secondsToHm(val);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter(val) {
            // return `${new Date(val * 1000).toISOString().substr(11, 8).replace(':', 'h ')
            //   .replace(':', 'm ')}s`;
            // return `${val} ${val > 1 ? 'Horas' : 'Hora'}`;
            return secondsToDhms(val);
          },
        },
      },
    },
  };

  if (loading) {
    return (
      <Skeleton variant="rect" height={300} width="100%" animation="wave" />
    );
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <ToggleButtonGroup
          value={days}
          exclusive
          onChange={(event, currentSolution) => {
            if (currentSolution !== null) {
              setDays(currentSolution);
            }
          }}
        >
          <ToggleButton value={5} aria-label="all">
            5 Dias
          </ToggleButton>
          <ToggleButton value={10} aria-label="all">
            10 Dias
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item xs={12}>
        <ReactApexChart
          style={{ width: '100%' }}
          options={state.options}
          series={state.series}
          type="bar"
          height={state.options.chart.height}
        />
      </Grid>
      <Grid item xs={12}>
        {dtCl.data.length > 1 && (
          <DataTable
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: 'Linhas:',
              rangeSeparatorText: 'de',
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
            noDataComponent="Não existem dados para serem mostrados"
            columns={dtCl.columns}
            data={dtCl.data}
            title="Detalhes"
          />
        )}
      </Grid>
    </Grid>
  );
};
