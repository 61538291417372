import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../App.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@mui/material/Box';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Skeleton from '@material-ui/lab/Skeleton';
import moment from 'moment';
import Card from '../../../../components/Card';
import CardText from '../../../../components/CardText';
import FockinkAPI from '../../../../middlewares/Api';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import DashesHeader from '../../../../components/DashesHeader';
import BackButton from '../../../../components/Buttons/BackButton';
import EquipError from '../../../../components/EquipError';
import PortalPermissions from '../../../../helpers/enums/PortalPermissions';
import { hasPermission } from '../../../../helpers/permission';
import ThermometryStorage from './Cards/ThermometryStorage';
import ThermometryAeration from './Cards/ThermometryAeration';
import ThermometryWeather from './Cards/ThermometryWeather';
import ThermometryTemperature from './Cards/ThermometryTemperature';
import ThermometrySVG from './Cards/ThermometrySVG';
import ThermometryGraphEngine from './Cards/ThermometryGraphEngine';
import ThermometryLevelTempAvgGraph from './Cards/ThermometryLevelTempAvgGraph';
import SelectDivision from './Components/SelectDivision';
import Observations from './Components/Observations';
import { DashTitle } from '../DashesStyles/index';
import Thermometry3D from './Cards/Thermometry3D';
import { formatDate } from '../../../../helpers/Intl';

const useStyles = makeStyles({
  btnBttn: {
    textAlign: 'right',
  },
  linkLike: {
    color: '#009AFF',
    fontSize: '20px',
    width: '100%',
    textAlign: 'center',
  },
});

export default (props) => {
  const classes = useStyles();

  const [data, setData] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const [goTo, setGoTo] = useState(false);
  const [strategy, setStrategy] = useState(1);
  const [connection, setConnection] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = React.useState(1);
  const [telemetries, setTelemetries] = React.useState([]);
  const [lastValidateDate, setLastValidateDate] = React.useState();
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [currentTelemetry, setCurrentTelemetry] = React.useState();
  const [loadingStrategy, setLoadingStrategy] = useState(true);
  const [loadingTelemetries, setLoadingTelemetries] = useState(true);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const callApi = () => {
    // Loading
    setLoadingStrategy(true);

    // Request
    FockinkAPI.get(`/dashboards/thermometry/${props.match.params.id}/strategy`)
      .then((response) => {
        setData(response.data.data);
        setBreadcrumbs({
          routes: [
            { name: 'Equipamentos', to: '/equipamentData' },
            {
              name:
                response.data.data.clientDescription ||
                response.data.data.description,
            },
          ],
          secondRoute: [
            { name: 'Clientes', to: '/clientsAdm/' },
            {
              name: 'Equipamentos',
              to: `/equipamentDataAdm?accountId=${response.data.data.account.id}&op=all`,
            },
            {
              name:
                response.data.data.clientDescription ||
                response.data.data.description,
            },
          ],
        });

        // Set Loading
        setLoadingStrategy(false);
      })
      .catch((e) => {
        if (e.response) {
          if (e.response.status === 503) {
            setErrorMessage('Equipamento sem conexão para trazer os dados');
          } else {
            setErrorMessage(
              `${e.response.data.message} (${e.response.status})`,
            );
          }
        } else {
          setErrorMessage('Erro no serviço');
        }

        // Set Loading
        setLoadingStrategy(false);
      });
  };

  const callTelemetriesApi = () => {
    // Set Loading
    setLoadingTelemetries(true);

    // Request
    FockinkAPI.get(
      `/dashboards/thermometry/${props.match.params.id}/telemetries?date=${moment(
        currentDate,
      ).format('yyyy-MM-DD')}&sub=${strategy}`,
    )
      .then((response) => {
        const tels = response.data.data.telemetries.map((t) => ({
          id: t.id,
          hour: moment(t.date).format('HH:mm'),
          temp: t.temp,
          humid: t.humid,
          rain: t.rain,
        }));
        tels.reverse();
        setTelemetries(tels);
        setLastValidateDate(formatDate(currentDate, 'yyyy-MM-dd'));

        if (response.data.data.telemetries.length > 0) {
          setCurrentTelemetry(response.data.data.telemetries.length - 1);
        }

        // Set Loading
        setLoadingTelemetries(false);
      })
      .catch((e) => {
        if (e.response && e.response.status === 400) {
          setTelemetries([]);
          setCurrentTelemetry(undefined);
          setLastValidateDate(new Date(e.response.data.lastValidDate));
        }

        // Set Loading
        setLoadingTelemetries(false);
      });
  };

  const urlParams = new URLSearchParams(window.location.search);
  const whatRoute = urlParams.get('whatRoute');

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    callApi();
    const interval = setInterval(() => {
      callApi();
    }, 900000);
    return () => clearInterval(interval);
  }, [props.match.params.id]);

  useEffect(() => {
    callTelemetriesApi();
  }, [strategy, currentDate]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  if (goTo) {
    return (
      <Redirect
        push
        to={
          whatRoute === 'Equips'
            ? `/equipamentDataAdm?accountId=${data.account?.id}&op=all`
            : '/equipamentData'
        }
      />
    );
  }

  if (errorMessage) return <EquipError errorMessage={errorMessage} />;

  // Define loading
  const pageLoading = loadingStrategy || loadingTelemetries;

  if (pageLoading) {
    return (
      <>
        <Breadcrumbs loading={pageLoading} />
        <DashesHeader loading={pageLoading} />
        <Grid container spacing={2}>
          {[0, 1].map((k) => (
            <Grid key={k} item xs={12} md={12} lg={6}>
              <Skeleton
                variant="rect"
                height={500}
                width="100%"
                animation="wave"
              />
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  return (
    <>
      <Breadcrumbs
        routes={
          whatRoute === 'Equips' ? breadcrumbs.secondRoute : breadcrumbs.routes
        }
      />
      <DashTitle>{data?.account?.description}</DashTitle>
      <DashesHeader
        loading={pageLoading}
        title={data?.clientDescription || data?.description}
        connection={connection.connection}
        running={connection.running}
        alert={connection.failures}
        hasCustomRoute={whatRoute === 'Equips' ? `?whatRoute=${whatRoute}` : ''}
        unitType={data?.unitType}
        type="Área"
      />

      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="stretch"
      >
        <SelectDivision
          loading={pageLoading}
          title={data.description}
          connection={connection.connection}
          running={connection.running}
          alert={connection.failures}
          noSettings={hasPermission(
            PortalPermissions.THERMOMETRY_SETTINGS_EDIT.value,
          )}
          selectedStrategy={
             // Vai mostrar o ícone do milho antes das Observações
            data.strategy.find((f) => f.sub === strategy) || 'all'
          }
          type="Área"
          strategys={data.strategy} // se comentar quebra a tela
          strategy={strategy} // mostra qual subdivisão está selecionado no momento
          setStrategy={setStrategy} // mostra o menu de opções de seleção
        />

        <Observations
          visibility={strategy === 'all' ? 'hidden' : 'visible'}
          selectedStrategy={
            data.strategy.find((f) => f.sub === strategy) || 'all'
          }
        />

        <Grid item xs={12} md={12} xl={6} lg={6}>
          <ThermometryStorage
            loading={pageLoading}
            selectedStrategy={data.strategy.find((f) => f.sub === strategy)}
            unitType={data.unitType}
            equipamentId={props.match.params.id}
          />
        </Grid>
        <Grid item xs={12} sm={8} xl={3} lg={4}>
          <ThermometryAeration
            loading={pageLoading}
            selectedStrategy={data.strategy.find((f) => f.sub === strategy)}
            equipamentId={props.match.params.id}
          />
        </Grid>
        <Grid item xs={12} xl={3} sm={4} lg={2}>
          <ThermometryWeather
            loading={pageLoading}
            setConnection={setConnection}
            equipamentId={props.match.params.id}
          />
        </Grid>

        <Grid item xs={12}>
          <Paper style={{ width: '100%', backgroundColor: 'white' }}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
                <Tab label="Temperatura" {...a11yProps(0)} />
                <Tab label="Termovisor" {...a11yProps(1)} />
                <Tab label="3D" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CardText pt={0}>
              {value === 0 && (
              <Card outlined square>
                <CardText>
                  <ThermometryTemperature
                    loading={pageLoading}
                    strategyId={strategy}
                    strategyGrain={data.strategy}
                    equipamentId={props.match.params.id}
                    unitType={data.unitType}
                    telemetries={telemetries}
                    lastValidateDate={lastValidateDate}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    currentTelemetry={currentTelemetry}
                    setCurrentTelemetry={setCurrentTelemetry}
                  />
                </CardText>
              </Card>
              )}

              {value === 1 && (
              <Card outlined square>
                <CardText>
                  <ThermometrySVG
                    loading={pageLoading}
                    strategyId={strategy}
                    strategyGrain={data.strategy}
                    equipamentId={props.match.params.id}
                    unitType={data.unitType}
                    telemetries={telemetries}
                    lastValidateDate={lastValidateDate}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    currentTelemetry={currentTelemetry}
                    setCurrentTelemetry={setCurrentTelemetry}
                  />
                </CardText>
              </Card>
              )}
              {value === 2 && (
              <Card outlined square>
                <CardText>
                  <Thermometry3D
                    equipamentId={props.match.params.id}
                    strategyId={strategy}
                    telemetries={telemetries}
                    lastValidateDate={lastValidateDate}
                    currentDate={currentDate}
                    setCurrentDate={setCurrentDate}
                    currentTelemetry={currentTelemetry}
                    setCurrentTelemetry={setCurrentTelemetry}
                  />
                </CardText>
              </Card>
              )}

            </CardText>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Accordion
            expanded={expanded === 'Aeração'}
            onChange={handleChange('Aeração')}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h3 style={{ margin: '0', fontWeight: '500' }}>Aeração</h3>
            </AccordionSummary>
            <AccordionDetails>
              <ThermometryGraphEngine
                equipamentId={props.match.params.id}
                sub={strategy}
                lastValidDate={lastValidateDate}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === 'Nivel&Temp'}
            onChange={handleChange('Nivel&Temp')}
            TransitionProps={{ unmountOnExit: true }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <h3 style={{ margin: '0', fontWeight: '500' }}>
                Nivel e Temperatura
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <ThermometryLevelTempAvgGraph
                equipamentId={props.match.params.id}
                sub={strategy}
                lastValidDate={lastValidateDate}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} className={classes.btnBttn}>
          <BackButton back={() => setGoTo(true)} className={classes.btnSpace} />
        </Grid>
      </Grid>
    </>
  );
};
