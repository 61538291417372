import React from 'react';
import { Redirect } from 'react-router-dom';

export default () => {
  const [redirect, setRedirect] = React.useState(false);

  React.useEffect(() => {
    const { authType, accountId } = localStorage;
    const urlParams = new URLSearchParams(window.location.search);
    const reason = urlParams.get('reason');

    if (authType === 'simpleAzureADApp') {
      window.location.href = `${process.env.REACT_APP_BACKEND_API}/login/simpleAzureADLogout/${accountId}?redirect=${window.location.origin}/login`;
    } else if (authType === 'local' || !authType) {
      if (reason) {
        setRedirect(`?reason=${reason}`);
      } else {
        setRedirect(true);
      }
    }
  }, []);
  localStorage.removeItem('localeCurrency');
  localStorage.removeItem('login');
  localStorage.removeItem('forceResetPassword');
  localStorage.removeItem('@FockinkAPP:token');
  localStorage.removeItem('authType');
  localStorage.removeItem('name');
  localStorage.removeItem('permissions');
  localStorage.removeItem('@FockinkAPP:user');
  localStorage.removeItem('fockink:portaliot');
  localStorage.removeItem('localeTag');
  localStorage.removeItem('userProfile');
  localStorage.removeItem('userId');
  localStorage.removeItem('profile');

  /*   if (localStorage.getItem('sessionLastPage') === '/logout') {
    localStorage.removeItem('sessionLastPage');
  } */

  if (redirect === true) {
    return <Redirect to="/login" />;
  }

  if (redirect) {
    return <Redirect to={`/login${redirect}`} />;
  }

  return (
    <h2
      style={{
        textAlign: 'center',
        minHeight: 'calc(100vh - 140px)',
        fontWeight: '400',
      }}
    >
      Aguarde.. Logout em andamento
    </h2>
  );
};
