import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (min-width: 720px) {
    min-width: 620px;
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 620px;
`;

export const InfoText = styled.div`
  padding: 1rem;
  background: #c530302b;
`;

export const DivOptions = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;
