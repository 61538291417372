import React from 'react';
import '../../App.css';
import InputLabel from '@material-ui/core/InputLabel';
import {
  FormControl,
  Radio,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
} from '@material-ui/core';
import jwtDecode from 'jwt-decode';
import { ConsoleView } from 'react-device-detect';
import FockinkAPI from '../../middlewares/Api';

export default ({ selectedPlant, setSelectedPlant, systemFilter, label }) => {
  const [loading, setLoading] = React.useState(true);
  const [units, setUnits] = React.useState([]);
  const token = localStorage.getItem('@FockinkAPP:token');
  const { unit } = jwtDecode(token);
  const urlParams = new URLSearchParams(window.location.search);
  const url = urlParams.get('accountId');

  React.useEffect(() => {
    FockinkAPI.get(
      `/units?plantsWithEquipaments=true&accountId=${url}`,
    ).then((response) => {
      const unitsRaw = [];
      response.data.data.forEach((u) => {
        const plants = systemFilter
          ? (u.plants || []).filter((f) => f.systemId === systemFilter)
          : (u.plants || []);
        if (plants.length > 0) {
          unitsRaw.push({ id: u.id,
            name: u.name,
            description: u.description,
            isUnit: true });
          plants.forEach((p) => {
            unitsRaw.push({ id: p.id,
              uid: u.id,
              description: p.description,
              technicalDescription: p.technicalDescription,
              equipaments: p.equipaments,
            });
          });
        }
      });

      const plantsNdef = systemFilter
        ? (response.data.plantsWithoutUnit || []).filter((f) => f.systemId === systemFilter)
        : (response.data.plantsWithoutUnit || []);

      if (plantsNdef.length > 0) {
        unitsRaw.push({ id: 'NDEF',
          name: 'Não Definido',
          description: 'Não Definido',
          isUnit: true });

        plantsNdef.forEach((p) => {
          unitsRaw.push({ id: p.id,
            uid: 'Não Definido',
            description: p.description,
            technicalDescription: p.technicalDescription,
            equipaments: p.equipaments,
          });
        });
      }

      setUnits(unitsRaw);
      setLoading(false);
    });
  }, []);

  const formatUnitName = (s) => {
    if (s.id === 'NDEF') return `Equipamento não associado a nenhuma ${unit?.singular || 'Unidade'}`;
    const name = `${s.name} - ${s.description}`;
    return name;
  };

  const handleChangeSelect = (event) => {
    const eq = {};
    if (event.target.value.indexOf('Todas') > -1) {
      units.forEach((u) => {
        u.equipaments.forEach((e) => {
          eq[e.id] = e;
        });
      });
      setSelectedPlant({ plant: undefined, equipaments: Object.values(eq) });
    } else {
      const u = units.find((v) => v.id === event.target.value);
      setSelectedPlant({ plant: event.target.value, equipaments: u.equipaments });
    }
  };

  return (
    <FormControl variant="filled">
      <InputLabel shrink>{label || 'Permissões de plantas'}</InputLabel>
      <Select
        // multiple
        disabled={loading}
        value={selectedPlant.plant}
        onChange={handleChangeSelect}
        name="plants"
        renderValue={() => (units.find((f) => f.id === selectedPlant.plant) || { name: 'Selecione' }).description
        }
        displayEmpty
      >

        {units?.map((s) => (
          s.isUnit
            ? (
              <ListSubheader
                disableSticky
                key={s.id}
              >
                {formatUnitName(s)}
              </ListSubheader>
            )
            : (
              <MenuItem key={s.id + s.uid} value={s.id}>
                <Radio
                  checked={selectedPlant.plant === s.id}
                />
                <ListItemText primary={s.description} />
              </MenuItem>
            )
        ))}
      </Select>
    </FormControl>
  );
};
