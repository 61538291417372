import React from 'react';
import DataTable from 'react-data-table-component';
import Skeleton from '@material-ui/lab/Skeleton';
import { useAlarmsContext } from '../../../../../hooks/AlarmsRule';
import { columns } from './makeColumns';

const defaultColumns = [
  {
    name: 'Name',
    selector: 'name',
    cell: (row) => row.clientDescription || row.description,
    sortable: true,
  },
  {
    name: 'OperationId',
    selector: 'operationId',
    cell: (row) => row.operationId === 1 ? 'irrigação' :
      row.operationId === 2 ? 'Termometria' :
        row.operationId === 3 ? 'Automação' :
          row.operationId === 4 ? 'Fotovoltaica' :
            row.operationId === 5 ? 'Estação Metereológica' :
              null,
    sortable: true,
  },
];

export function AlarmsTable({ aditionalColumns }) {
  const { alarmRules } = useAlarmsContext();
  const [dataColumns, setDataColumns] = React.useState({ columns: [], data: [] });
  const [loading, setLoading] = React.useState(false);
  const [search, setSearch] = React.useState({});
  React.useEffect(() => {
    setLoading(true);
    setDataColumns({ columns: defaultColumns, data: alarmRules });
    setLoading(false);
  }, []);

  const ExpandedComponent = ({ data }) => (
    <DataTable
      pagination
      paginationPerPage={5}
      paginationComponentOptions={{ rowsPerPageText: 'Linhas:', rangeSeparatorText: 'de' }}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
      noDataComponent="Não existem dados para serem mostrados"
      columns={dataColumns.columns}
      data={data.equipamentsInfo.map((res) => res)}
    />
  );

  const expandableRowExpanded = (data) => search.accountId === data.id;
  const onRowExpandToggled = (open, data) => {
    if (open) {
      setSearch({
        accountId: data.id,
        plantId: undefined,
        equipmentId: undefined,
      });
    }
    return true;
  };

  const formattedColumns = [...columns, ...aditionalColumns];

  return (
    <>
      {loading ? (
        <Skeleton height="60vh" />
      ) : (
        <DataTable
          pagination
          paginationPerPage={5}
          paginationComponentOptions={{ rowsPerPageText: 'Linhas:', rangeSeparatorText: 'de' }}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
          noDataComponent="Não existem dados para serem mostrados"
          expandableRows
          expandableRowsComponent={<ExpandedComponent />}
          expandableRowExpanded={expandableRowExpanded}
          onRowExpandToggled={onRowExpandToggled}
          columns={formattedColumns}
          data={alarmRules}
        />
      )}
    </>
  );
}
