import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  margin: 10px 0;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
`;

export const Content = styled.div`
  width: 720px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 720px) {
    width: 100%;
  }
`;

export const ButtonDeleteDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
`;
