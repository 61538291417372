/* eslint-disable no-mixed-operators */
import React from 'react';
import { number } from '../helpers/Intl';

export default (props) => {
  const [itens, setItens] = React.useState([]);
  const [hasError, setHasError] = React.useState(false);
  const zeroX = 1.2;
  const zeroY = 18.1;

  const calcX = (x) => {
    let xis = x;
    if (x > 25) {
      xis = 25;
    } else if (x < 5) {
      xis = 5;
    }
    return (xis * 0.7999) + zeroX;
  };

  const calcY = (y) => {
    let yps = y;
    if (y > 40) {
      yps = 40;
    } else if (y < 0) {
      yps = 0;
    }
    return ((yps * (0.805 / 2)) * -1) + zeroY;
  };

  React.useEffect(() => {
    let hasErrorOut = false;
    setItens(props.itens.map((e) => {
      let hasErrorInner = false;

      if (e.humid > 25 || e.humid < 5) {
        hasErrorInner = true;
      }
      if (e.temp > 40 || e.temp < 0) {
        hasErrorInner = true;
      }
      if (hasErrorInner) {
        hasErrorOut = true;
      }
      return {
        ...e, hasError: hasErrorInner,
      };
    }));
    setHasError(hasErrorOut);
  }, [props.itens]);

  return (
    <svg viewBox="0 0 25 20" style={{ maxHeight: '96vh' }}>
      <image xlinkHref="/images/cerealChart/cerealChart.svg" width="100%" height="100%" />

      {hasError && (
        <text
          x={8.5}
          y={1.7}
          fill="red"
          style={{
            fontSize: '0.5px', letterSpacing: '0px', fontWeight: '500',
          }}
        >
          Atenção: Contém medidas fora de range
        </text>
      )}

      {itens.map((i, j) => (
        <>
          <circle
            r="0.3"
            cx={calcX(i.humid)}
            cy={calcY(i.temp)}
            fill={i.color}
            stroke="white"
            strokeWidth="0.1"
          />

          <rect
            x={21.5}
            y={2 + j * 5.45}
            rx="0.2"
            ry="0.2"
            width="3"
            height="5"
            stroke={i.color}
            strokeWidth="0.1"
            style={{ fill: i.hasError ? '#FF00009E' : '#FFFFFFB5' }}
          />

          <text
            x={23}
            textAnchor="middle"
            y={2.5 + j * 5.45}
            fill="black"
            style={{
              fontSize: '0.4px', letterSpacing: '0px', fontWeight: '500',
            }}
          >
            {i.text}
          </text>

          <text
            x={23}
            textAnchor="middle"
            y={3.3 + j * 5.45}
            fill="black"
            style={{
              fontSize: '0.6px', letterSpacing: '0px', fontWeight: '400',
            }}
          >
            {`${number(i.temp, 2)}°C`}
          </text>

          <text
            x={23}
            textAnchor="middle"
            y={3.8 + j * 5.45}
            fill="black"
            style={{
              fontSize: '0.4px', letterSpacing: '0px', fontWeight: '300',
            }}
          >
            Temperatura
          </text>

          <text
            x={23}
            textAnchor="middle"
            y={4.6 + j * 5.45}
            fill="black"
            style={{
              fontSize: '0.6px', letterSpacing: '0px', fontWeight: '400',
            }}
          >
            {`${number(i.humid, 2)}%`}
          </text>

          <text
            x={23}
            textAnchor="middle"
            y={5.05 + j * 5.45}
            fill="black"
            style={{
              fontSize: '0.4px', letterSpacing: '0px', fontWeight: '300',
            }}
          >
            Umidade
          </text>

          <text
            x={23}
            textAnchor="middle"
            y={5.9 + j * 5.45}
            fill="black"
            style={{
              fontSize: '0.6px', letterSpacing: '0px', fontWeight: '400',
            }}
          >
            {`${number((i.qtdSensors / props.totalSensors) * 100, 2)}%`}
          </text>

          <text
            x={23}
            textAnchor="middle"
            y={6.4 + j * 5.45}
            fill="black"
            style={{
              fontSize: '0.4px', letterSpacing: '0px', fontWeight: '300',
            }}
          >
            Sensores
          </text>

          <text
            x={23}
            textAnchor="middle"
            y={6.8 + j * 5.45}
            fill="black"
            style={{
              fontSize: '0.35px', letterSpacing: '0px', fontWeight: '300',
            }}
          >
            {`${number(i.qtdSensors, 2)} de ${number(props.totalSensors, 2)}`}
          </text>
        </>
      ))}

    </svg>
  );
};
