import React from 'react';
import '../App.css';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
// import { makeStyles } from '@material-ui/core/styles';
// import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import MapIcon from '@material-ui/icons/Map';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberFormat from 'react-number-format';
import CardTitle from './CardTitle';
import ExternalApi from '../middlewares/ExternalApi';
import MapClickLatLon from './MapClickLatLon';

export default (props) => {
  const [showMap, setShowMap] = React.useState(false);
  const [cepChanged, setCepChanged] = React.useState(false);
  // const classes = useStyles();
  const handleChangeLocation = (event) => {
    if (event.target.name === 'postalCode') {
      setCepChanged(true);
    }
    props.handleChangeLocation({
      ...props.data,
      [event.target.name]: event.target.value,
    });
  };

  const formatLatLon = (ll) => {
    if (typeof ll === 'string') {
      return parseFloat(parseFloat(ll).toFixed(8));
    }
    if (ll) return parseFloat(ll.toFixed(8));

    return ll;
  };

  const handleLatLon = (event) => {
    if (event) {
      props.handleChangeLocation({
        ...props.data,
        lat: formatLatLon(event.lat),
        lon: formatLatLon(event.lon),
      });
    }
  };

  React.useEffect(() => {
    const cep = `${props.data.postalCode}`.replace('-', '').trim();
    if (cepChanged && cep.length === 8) {
      ExternalApi.get(
        `https://viacep.com.br/ws/${props.data.postalCode}/json/`,
      ).then((response) => {
        props.handleChangeLocation({
          ...props.data,
          address1: response.data.logradouro,
          address2: response.data.bairro,
          city: response.data.localidade,
          state: response.data.uf,
          country: 'Brasil',
          lat: formatLatLon(props.data.lat),
          lon: formatLatLon(props.data.lon),
        });
      });
    }
  }, [props.data.postalCode]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3} md={3} lg={12}>
        <NumberFormat
          value={props.data.postalCode || ''}
          label="CEP"
          customInput={TextField}
          variant="outlined"
          format="#####-###"
          allowNegative={false}
          onValueChange={(v) =>
            handleChangeLocation({
              target: { name: 'postalCode', value: v.formattedValue },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={12}>
        <TextField
          variant="outlined"
          name="address1"
          onChange={handleChangeLocation}
          value={props.data.address1 || ''}
          label="Endereço e número"
        />
      </Grid>

      <Grid item xs={12} sm={3} md={3} lg={12}>
        <TextField
          variant="outlined"
          name="address2"
          onChange={handleChangeLocation}
          value={props.data.address2 || ''}
          label="Bairro"
        />
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={6} xl={6}>
        <TextField
          variant="outlined"
          name="city"
          onChange={handleChangeLocation}
          value={props.data.city || ''}
          label="Cidade"
        />
      </Grid>

      <Grid item xs={12} sm={4} lg={6} xl={6}>
        <TextField
          variant="outlined"
          name="state"
          onChange={handleChangeLocation}
          value={props.data.state || ''}
          label="Estado"
        />
      </Grid>

      <Grid item xs={12} sm={4} md={4} lg={12}>
        <TextField
          variant="outlined"
          name="country"
          onChange={handleChangeLocation}
          value={props.data.country || ''}
          label="Pais"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <NumberFormat
          value={props.data.lat || ''}
          label="Latitude"
          customInput={TextField}
          variant="outlined"
          thousandSeparator=""
          decimalSeparator="."
          onValueChange={(v) =>
            handleChangeLocation({
              target: { name: 'lat', value: v.floatValue },
            })
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <NumberFormat
          value={props.data.lon || ''}
          label="Longitude"
          customInput={TextField}
          variant="outlined"
          thousandSeparator=""
          decimalSeparator="."
          onValueChange={(v) =>
            handleChangeLocation({
              target: { name: 'lon', value: v.floatValue },
            })
          }
        />
      </Grid>

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => {
            setShowMap(true);
          }}
          endIcon={<MapIcon />}
        >
          Ver Mapa
        </Button>
        {/* <FormControlLabel
          control={
            <Switch
              checked={showMap}
              onChange={(e) => {
                setShowMap(e.target.checked);
              }}
              name="showMap"
              color="primary"
            />
          }
          label="Mostrar no Mapa"
        /> */}
      </Grid>
      <Dialog
        style={{ minHeight: '500px' }}
        maxWidth="md"
        fullWidth
        open={showMap}
      >
        <CardTitle pa={8}>
          Posicionamento:
          <div style={{ margin: '0 auto' }} />
          <CloseIcon
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setShowMap(false);
            }}
          />
        </CardTitle>
        <DialogContent style={{ padding: '2px' }}>
          <MapClickLatLon
            lat={props.data.lat}
            lon={props.data.lon}
            handleClick={handleLatLon}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
