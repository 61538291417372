import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Button from '@material-ui/core/Button';
import { ListItemText } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import FockinkAPI from '../../../../middlewares/Api';
import BottomButtons from '../../../../components/Buttons/BottomButtons';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import PortalPermissions from '../../../../helpers/enums/PortalPermissions';
import UnitSelectField from '../../../../components/Fields/UnitSelectField';
import { PermissionsModal } from '../../../../components/PermissionsModal';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

export default (props) => {
  const [loading, setLoading] = useState(true);
  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const MySwal = withReactContent(Swal);
  const [plantsList, setPlantsList] = useState();
  const [permissionCheckArray, setPermissionCheckArray] = useState({
    plants: [],
  });
  const [data, setData] = useState({
    status: 'A',
    name: '',
    login: '',
    profileId: '',
    loginIsNotEmail: false,
    onlyThisPlants: permissionCheckArray.plants,
  });

  const [permissionsApi, setPermissionsApi] = useState([]);
  const [defaultPermissionsProfile, setDefaultPermissionsProfile] = useState(
    [],
  );
  const [allowed, setAllowed] = useState([]);
  const [blocked, setBlocked] = useState([]);

  const checkUserCustomPermissions = (customPermissions) => {
    const { allow, block } = customPermissions;
    setAllowed(allow);
    setBlocked(block);

    return [...allow, ...block];
  };
  const handleModalState = () => {
    setOpenPermissionModal(!openPermissionModal);
  };
  const profiles = [
    { profileId: 51, description: 'Gestor' },
    { profileId: 52, description: 'Supervisor' },
    { profileId: 53, description: 'Operador' },
    { profileId: 54, description: 'Visualizador' },
  ];

  const breadcrumb = {
    title: 'Cadastro de Usuário',
    routes: [
      { to: '/settings', name: 'Configurações' },
      { to: '/portalUsers', name: 'Usuários' },
      { name: 'Cadastro de Usuário' },
    ],
  };
  useEffect(() => {
    let responseUserPortal;
    (async () => {
      if (props.match.params.id === 'new') {
        setLoading(false);
      } else {
        setLoading(true);
        responseUserPortal = await FockinkAPI.get(
          `/portalUsers/${props.match.params.id}`,
        );
        setData({
          ...responseUserPortal.data.data,
          profileId: responseUserPortal.data.data.profile.profileId,
        });
      }

      setPermissionCheckArray({
        ...permissionCheckArray,
        plants: responseUserPortal?.data?.data?.onlyThisPlants || [],
      });
      const responseProfiles = await FockinkAPI.get('users/profiles');
      const { permissions } = responseProfiles.data.data;
      const responsePlants = await FockinkAPI.get('/dashboards/plants');
      setPlantsList(responsePlants.data.data.plants);
      const formatUserPermissionsApiCode = permissions.portal.Automação.map(
        (permission) => permission.code,
      );

      const customPermissions =
        responseUserPortal?.data?.data?.customPermissions;
      if (customPermissions) {
        const permissionsAlredyChecked = checkUserCustomPermissions(
          customPermissions,
        );
        for (let i = 0; i < permissionsAlredyChecked.length; i += 1) {
          const index = formatUserPermissionsApiCode.indexOf(
            permissionsAlredyChecked[i],
          );
          formatUserPermissionsApiCode.splice(index, 1);
        }
        setDefaultPermissionsProfile(formatUserPermissionsApiCode);
      } else {
        setDefaultPermissionsProfile(formatUserPermissionsApiCode);
      }

      setPermissionsApi(permissions);
      setLoading(false);
    })();
  }, [props.match.params.id]);

  const classes = useStyles();
  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value || event.target.checked,
    });
  };
  const customResponse = (response, goBack) => {
    if (data.loginIsNotEmail) {
      MySwal.fire({
        title: 'Anote a senha, ela não aparecerá novamente',
        html: (
          <div style={{ textAlign: 'center' }}>
            <span style={{ fontWeight: 400, textDecoration: 'underline' }}>
              {response.data.data.randomPassword}
            </span>
            <Button
              variant="contained"
              style={{ marginLeft: '15px' }}
              onClick={() => {
                const textField = document.createElement('textarea');
                textField.innerText = response.data.data.randomPassword;
                document.body.appendChild(textField);
                textField.select();
                document.execCommand('copy');
                textField.remove();
              }}
              startIcon={<FileCopyIcon />}
            >
              Copiar
            </Button>
          </div>
        ),
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Sim, anotei a senha',
        allowOutsideClick: false,
      }).then(() => {
        if (goBack) goBack(true);
      });
    } else {
      MySwal.fire({
        title: 'Usuário criado!',
        text: 'Ele receberá o link de acesso no email cadastrado',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Continuar',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) goBack(true);
      });
    }
  };

  const confUpdatePw = () => {
    MySwal.fire({
      title: 'Tem certeza que deseja redefinir a senha?',
      text: 'Essa ação não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, REDEFINIR!',
      cancelButtonText: 'Não, MANTER',
    }).then((result) => {
      if (result.isConfirmed) {
        askNewPw();
      }
    });
  };

  const askNewPw = () => {
    FockinkAPI.put(`/portalUsers/${props.match.params.id}/resetPassword`)
      .then((r) => {
        customResponse(r);
      })
      .catch((e) => {
        MySwal.fire({
          icon: 'error',
          title: 'Oops...',
          text: e.response.data.reason || e.response.data.message,
        });
      });
  };

  const saveData = () => {
    const dt = {
      data: {
        name: data.name,
        login: data.login,
        profileId: data.profileId,
        loginIsNotEmail: data.loginIsNotEmail,
        status: data.status,
        onlyThisPlants: permissionCheckArray.plants,
      },
    };

    if (allowed.length > 0 || blocked.length > 0) {
      dt.data.customPermissions = {
        allow: allowed,
        block: blocked,
      };
    }

    if (props.match.params.id === 'new') {
      dt.method = 'post';
      dt.url = '/portalUsers';
    } else {
      dt.method = 'put';
      dt.url = `/portalUsers/${props.match.params.id}`;
    }
    return dt;
  };

  // const deleteData = () => ({ url: `/portalUsers/${props.match.params.id}` });
  if (loading) {
    return (
      <Skeleton variant="rect" height={500} width="100%" animation="wave" />
    );
  }
  // const handleChangeSelect = (event) => {
  //   if (event.target.value.indexOf('Todas') > -1) {
  //     setPermissionCheckArray({ plants: [] });
  //   } else {
  //     setPermissionCheckArray({
  //       ...permissionCheckArray,
  //       [event.target.name]: event.target.value || event.target.checked,
  //     });
  //   }
  // };

  const handleUpdatePermissonsProfileUser = async () => {
    const dataRequest = {
      accountId: data.accountId,
      name: data.name,
      login: data.login,
      profileId: data.profileId,
      loginIsNotEmail: !!data.loginIsNotEmail,
      status: data.status,
      onlyThisPlants: data.onlyThisPlants,
    };

    if (allowed.length > 0 || blocked.length > 0) {
      dataRequest.customPermissions = {
        allow: allowed,
        block: blocked,
      };
    }

    handleModalState();
  };
  const handleBlockSelectPermission = (eventChecked) => {
    const copyBlockedPermission = blocked.slice();
    const newDefaultPermissions = defaultPermissionsProfile.slice();

    const { checked, value } = eventChecked.target;
    const formattedPermission = Number(value);
    if (checked) {
      const defaultPermissionIndex = newDefaultPermissions.indexOf(
        formattedPermission,
      );
      const allowedPermissionIndex = allowed.indexOf(formattedPermission);

      if (defaultPermissionIndex > -1) {
        newDefaultPermissions.splice(defaultPermissionIndex, 1);
        setDefaultPermissionsProfile(newDefaultPermissions);
      }

      if (allowedPermissionIndex > -1) {
        const newAllowedPermission = allowed.slice();
        newAllowedPermission.splice(allowedPermissionIndex, 1);
        setAllowed(newAllowedPermission);
      }

      copyBlockedPermission.push(formattedPermission);
      setBlocked(copyBlockedPermission);
      return;
    }

    const index = copyBlockedPermission.indexOf(formattedPermission);
    copyBlockedPermission.splice(index, 1);
    newDefaultPermissions.push(formattedPermission);
    setDefaultPermissionsProfile(newDefaultPermissions);
    setBlocked(copyBlockedPermission);
  };

  const handleAllowedSelectPermission = (eventChecked) => {
    const { checked, value } = eventChecked.target;
    const copyAllowedPermission = allowed.slice();
    const newDefaultPermissions = defaultPermissionsProfile.slice();
    const copyBlockedPermissions = blocked.slice();
    const formattedPermission = Number(value);
    if (checked) {
      const defaultPermissionIndex = newDefaultPermissions.indexOf(
        formattedPermission,
      );
      const blockedPermissionIndex = copyBlockedPermissions.indexOf(
        formattedPermission,
      );

      if (defaultPermissionIndex > -1) {
        newDefaultPermissions.splice(defaultPermissionIndex, 1);
        setDefaultPermissionsProfile(newDefaultPermissions);
      }

      if (blockedPermissionIndex > -1) {
        copyBlockedPermissions.splice(blockedPermissionIndex, 1);
        setBlocked(copyBlockedPermissions);
      }

      copyAllowedPermission.push(formattedPermission);
      setAllowed(copyAllowedPermission);
      return;
    }

    const index = copyAllowedPermission.indexOf(formattedPermission);
    copyAllowedPermission.splice(index, 1);
    newDefaultPermissions.push(formattedPermission);
    setDefaultPermissionsProfile(newDefaultPermissions);
    setAllowed(copyAllowedPermission);
  };

  const handleHereditarySelectPermission = (eventChecked) => {
    const { checked, value } = eventChecked.target;
    const formattedPermission = Number(value);

    const copyBlockedPermissions = blocked.slice();
    const copyAllowedPermissions = allowed.slice();
    const copyDefautlPermission = defaultPermissionsProfile.slice();

    if (!checked) {
      return;
    }

    const blockedPermissionIndex = copyBlockedPermissions.indexOf(
      formattedPermission,
    );
    const allowerPermissionIndex = copyAllowedPermissions.indexOf(
      formattedPermission,
    );
    if (blockedPermissionIndex > -1) {
      copyBlockedPermissions.splice(blockedPermissionIndex, 1);
      setBlocked(copyBlockedPermissions);
    }

    if (allowerPermissionIndex > -1) {
      copyAllowedPermissions.splice(allowerPermissionIndex, 1);
      setAllowed(copyAllowedPermissions);
    }

    copyDefautlPermission.push(formattedPermission);
    setDefaultPermissionsProfile(copyDefautlPermission);
  };

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item lg={4} md={4} xs={12}>
              <TextField
                variant="filled"
                name="name"
                onChange={handleChange}
                value={data.name || ''}
                label="Nome"
              />
            </Grid>

            <Grid item lg={4} md={4} xs={12}>
              <TextField
                variant="filled"
                name="login"
                onChange={handleChange}
                value={data.login || ''}
                label="Login"
              />
            </Grid>

            <Grid item lg={4} md={4} xs={12}>
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Switch
                    checked={data.loginIsNotEmail}
                    onChange={handleChange}
                    name="loginIsNotEmail"
                    color="primary"
                  />
                }
                label="Usuário sem e-mail"
              />
            </Grid>

            <Grid item lg={4} md={4} xs={12}>
              <FormControl variant="filled">
                <InputLabel>Status</InputLabel>
                <Select
                  value={data.status}
                  onChange={handleChange}
                  name="status"
                >
                  <MenuItem value="A">Ativo</MenuItem>
                  <MenuItem value="I">Inativo</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item lg={4} md={4} xs={12}>
              <FormControl variant="filled">
                <InputLabel>Perfil</InputLabel>
                <Select
                  value={data.profileId}
                  onChange={handleChange}
                  name="profileId"
                >
                  {profiles.map((m) => (
                    <MenuItem value={m.profileId} key={m.profileId}>
                      {m.description}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {data.loginIsNotEmail && props.match.params.id !== 'new' && (
              <Grid item lg={4} md={4} xs={12}>
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  style={{ ...props.style }}
                  onClick={() => confUpdatePw()}
                  startIcon={<LockOpenIcon />}
                >
                  Redefinir a senha
                </Button>
              </Grid>
            )}

            <Grid item style={{ marginTop: '22px' }}>
              <Button
                size="medium"
                variant="contained"
                color="secondary"
                style={{ ...props.style }}
                onClick={() => handleModalState()}
              >
                Revisar de Permissões
              </Button>

              <PermissionsModal
                itsOpen={openPermissionModal}
                closeModal={handleModalState}
                // userId={props.match.params.id}
                user={data}
                // profileId={data.profile.profileId}
                handleAction={handleModalState}
                parmissionList={permissionsApi}
                allowed={allowed}
                blocked={blocked}
                handleHereditarySelectPermission={
                  handleHereditarySelectPermission
                }
                handleAllowedSelectPermission={handleAllowedSelectPermission}
                handleBlockSelectPermission={handleBlockSelectPermission}
                handleUpdatePermissonsProfileUser={
                  handleUpdatePermissonsProfileUser
                }
                defaultPermissionsProfile={defaultPermissionsProfile}
              />
            </Grid>

            {/* <Grid item lg={4} md={4} xs={12}>
              <FormControl variant="filled">
                <InputLabel shrink>Permissões de plantas</InputLabel>
                <Select
                  multiple
                  value={permissionCheckArray.plants}
                  onChange={handleChangeSelect}
                  name="plants"
                  renderValue={(selected) =>
                    permissionCheckArray.plants?.length > 0
                      ? `Selecionado: ${selected.length} de ${plantsList?.length}`
                      : 'Acesso à todas as plantas'
                  }
                  displayEmpty
                >
                  <MenuItem key="Todas" value="Todas">
                    <Checkbox
                      checked={!permissionCheckArray.plants?.length > 0}
                    />
                    <ListItemText primary="Todas" />
                  </MenuItem>
                  {plantsList?.map((m) => (
                    <MenuItem key={m.description} value={m.id}>
                      <Checkbox
                        checked={
                          (permissionCheckArray.plants || []).indexOf(m.id) > -1
                        }
                      />
                      <ListItemText primary={m.description} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            <Grid item lg={4} md={4} xs={12}>
              <UnitSelectField
                selectedPlants={permissionCheckArray.plants}
                setSelectedPlants={(plants) => {
                  setPermissionCheckArray(plants);
                }}
              />
            </Grid>
          </Grid>
          <Grid item lg={4} md={4} xs={12} style={{ paddingTop: 10 }}>
            <Typography variant="h6" className={classes.title}>
              Plantas com acesso permitido
            </Typography>
            <div>
              {permissionCheckArray.plants?.length === 0 ? (
                <List>
                  {plantsList?.map((x) => (
                    <ListItem style={{ padding: 0 }}>
                      <ListItemText primary={x.description} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <List>
                  {plantsList
                    ?.filter(
                      (x) =>
                        permissionCheckArray.plants?.includes(String(x.id)),
                      // eslint-disable-next-line function-paren-newline
                    )
                    ?.map((x) => (
                      <ListItem style={{ padding: 0 }}>
                        <ListItemText primary={x.description} />
                      </ListItem>
                    ))}
                </List>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={`${classes.textAlign} ${classes.bottomButtons}`}
        >
          <BottomButtons
            saveData={saveData}
            customResponse={props.match.params.id === 'new' && customResponse}
            // deleteData={props.match.params.id !== 'new' && deleteData}
            backTo="/portalUsers"
            canSave={PortalPermissions.USERS_MANAGE.value}
          />
        </Grid>
      </Grid>
    </>
  );
};
