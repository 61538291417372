import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;

  @media screen and (min-width: 720px) {
    min-width: 420px;
  }

  ul {
    padding: 0;
    list-style: none;
  }
`;

export const CardDownload = styled.li`
  width: 100%;
  padding: 0.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
