/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import colors from '../styles/colors';
import { formatUnits } from '../helpers/Intl';

export default (props) => {
  const [shadow, setShadow] = React.useState(1);
  const [transition, setTransition] = React.useState(0.3);
  const [hoverColor, setHoverColor] = React.useState(colors.grey);
  const [bgColor, setBgColor] = React.useState(colors.transparent);
  const handleMouseEnter = () => {
    setTransition(0.3);
    setShadow(4);
    if (props.hover && props.outlined) {
      setHoverColor(props.color || colors.info);
      if (props.changeBgColor) {
        setBgColor(props.color || colors.info);
      }
    }
  };
  const handleMouseLeave = () => {
    setTransition(0.3);
    setShadow(1);
    if (props.hover && props.outlined) {
      setHoverColor(colors.grey);
      if (props.changeBgColor) {
        setBgColor(colors.transparent);
      }
    }
  };
  const handleMouseUp = () => {
    setTransition(0.1);
    setShadow(4);
    if (props.hover && props.outlined) {
      setHoverColor(props.color || colors.info);
      if (props.changeBgColor) {
        setBgColor(props.color || colors.info);
      }
    }
  };
  const handleMouseDown = () => {
    setTransition(0.1);
    setShadow(1);
    if (props.hover && props.outlined) {
      setHoverColor(colors.grey);
      if (props.changeBgColor) {
        setBgColor(colors.transparent);
      }
    }
  };

  const pa = formatUnits(props.pa, 'px', 0);
  const pl = formatUnits(props.pl, 'px', 0);
  const pr = formatUnits(props.pr, 'px', 0);
  const pt = formatUnits(props.pt, 'px', 0);
  const pb = formatUnits(props.pb, 'px', 0);
  const px = formatUnits(props.px, 'px', 0);
  const py = formatUnits(props.py, 'px', 0);
  // const font = formatUnits(props.size, 'rem', 1.25);
  const radius = formatUnits(props.radius, 'px', 4);

  function getPaddingTop() {
    if (props.pt) {
      return `${pt.number}${pt.unit}`;
    }
    if (props.py) {
      return `${py.number}${py.unit}`;
    }
    if (props.pa) {
      return `${pa.number}${pa.unit}`;
    }
    return `${pt.number}${pt.unit}`;
  }
  function getPaddingBottom() {
    if (props.pb) {
      return `${pb.number}${pb.unit}`;
    }
    if (props.py) {
      return `${py.number}${py.unit}`;
    }
    if (props.pa) {
      return `${pa.number}${pa.unit}`;
    }
    return `${pb.number}${pb.unit}`;
  }
  function getPaddingLeft() {
    if (props.pl) {
      return `${pl.number}${pl.unit}`;
    }
    if (props.px) {
      return `${px.number}${px.unit}`;
    }
    if (props.pa) {
      return `${pa.number}${pa.unit}`;
    }
    return `${pl.number}${pl.unit}`;
  }
  function getPaddingRight() {
    if (props.pr) {
      return `${pr.number}${pr.unit}`;
    }
    if (props.px) {
      return `${px.number}${px.unit}`;
    }
    if (props.pa) {
      return `${pa.number}${pa.unit}`;
    }
    return `${pr.number}${pr.unit}`;
  }
  return (
    <div
      style={{
        transition: `${transition}s`,
        cursor: props.hover ? 'pointer' : '',
        height: props.height ? props.height : props.similar ? '100%' : 'none',
        width: '100%',
        paddingTop: getPaddingTop(),
        paddingBottom: getPaddingBottom(),
        paddingLeft: getPaddingLeft(),
        paddingRight: getPaddingRight(),
        boxShadow:
          props.flat || props.outlined
            ? 'none'
            : props.elevation > 0 && props.elevation < 6
              ? `0px 3px ${props.elevation + shadow}px -${
                props.elevation + shadow * 2
              }px rgb(0 0 0 / 20%), 0px ${props.elevation + shadow * 2}px ${
                props.elevation + shadow * 2
              }px 0px rgb(0 0 0 / 14%), 0px ${props.elevation + shadow * 1}px ${
                props.elevation + shadow * 5
              }px 0px rgb(0 0 0 / 12%)`
              : `0px 3px ${shadow * 1}px -${shadow * 2}px rgb(0 0 0 / 20%), 0px ${
                shadow * 2
              }px ${shadow * 2}px 0px rgb(0 0 0 / 14%), 0px ${shadow * 1}px ${
                shadow * 5
              }px 0px rgb(0 0 0 / 12%)`,

        borderRadius: props.square ? '0' : `${radius.number}${radius.unit}`,
        backgroundColor: props.outlined
          ? props.hover
            ? bgColor
            : 'transparent'
          : props.color || colors.white,
        color: props.textColor,
        overflow: 'hidden',
        border: props.outlined
          ? `1px solid ${
            props.color
              ? props.hover
                ? hoverColor
                : props.color
              : props.hover
                ? hoverColor
                : colors.grey
          }`
          : 'none',
        ...props.style,
      }}
      onMouseEnter={props.hover ? handleMouseEnter : null}
      onMouseUp={props.hover ? handleMouseUp : null}
      onMouseLeave={props.hover ? handleMouseLeave : null}
      onMouseDown={props.hover ? handleMouseDown : null}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};
