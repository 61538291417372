import React from 'react';
import '../../App.css';
import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';

export default (props) => (
  <FormControl variant="filled" style={props.style}>
    <InputLabel htmlFor="password">{props.label}</InputLabel>
    <FilledInput
      name={props.name}
      id={props.name}
      type="text"
      value={props.value}
      onChange={props.disabled ? () => {} : props.handleChange}
      endAdornment={
        props.text ? (
          <InputAdornment
            position={props.position || 'end'}
            style={{
              padding: '15px',
              boxShadow: '3px 1px 5px #f2dfbb',
              background: '#f2dfbb',
              height: '100%',
            }}
          >
            {props.text}
          </InputAdornment>
        ) : undefined
      }
    />
  </FormControl>
);
