import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 400px;

  @media screen and (min-width: 720px) {
    min-width: 620px;
  }
`;

export const DropZoneContent = styled.div`
  width: 100%;
  height: 100%;
  min-height: 300px;
  margin: 0 auto;
  background: #c1ebff2d;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  color: #9d9d9d;

  p {
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: bold;
    margin: 0;
  }

  > span {
    margin-bottom: 0.8rem;
  }

  border: 3px dashed #9d9d9d;
  padding: 1rem;
  border-radius: 0.2rem;
  cursor: pointer;
  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.7);
  }

  .alternative-indo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ContentFile = styled.div``;
