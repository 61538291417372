import React from 'react';
import { TiThList, TiArrowBack, GoPlus } from 'react-icons/all';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import FockinkAPI from '../../../../middlewares/Api';
import { Modal } from '../../../../components/Modal';
import {
  CustomVariableForm,
  VariableTable,
  EditCustomVariableForm,
  ResetVariable,
  VariableHistoric,
} from './components';

const breadcrumb = {
  title: 'Configuração de Variáveis',
  routes: [
    { name: 'Configurações', to: '/settings' },
    { name: 'Configuração de Variáveis' },
  ],
};

export function ThresholdConfig() {
  const [variableData, setVariableData] = React.useState();
  const [overwriteVariable, setOverwriteVariable] = React.useState();
  const [
    selectedCustomVariableToEdit,
    setSelectedCustomVariableToEdit,
  ] = React.useState();
  const [
    openNewCustomVariableModal,
    setOpenNewCustomVariableModal,
  ] = React.useState(false);
  const [
    openEditVariableCustomModal,
    setEditVariableCustomModal,
  ] = React.useState(false);

  const [variableHistoric, setVariableHistoric] = React.useState();
  const [
    openVariableHistoricModal,
    setOpenVariableHistoricModal,
  ] = React.useState(false);

  const [selectVariableToReset, setSelectVariableToReset] = React.useState();
  const [openVariableResetModal, setOpenVariableResetModal] = React.useState(
    false,
  );

  const handleCloseResetVariableModal = () => {
    setOpenVariableResetModal(false);
  };

  const handleSelectVarableToReset = (variableToReset) => {
    setSelectVariableToReset(variableToReset);
    setOpenVariableResetModal(true);
  };

  const selecteVariableToOverwrite = (variableToOvewrite) => {
    setOpenNewCustomVariableModal(true);
    setOverwriteVariable(variableToOvewrite);
  };

  const handleselecteVariableToEdit = (variableToEdit) => {
    setEditVariableCustomModal(true);
    setSelectedCustomVariableToEdit(variableToEdit);
  };

  const formatVariableData = (data) => {
    const { itemsCustom, itemsDefault } = data;
    const formatCustomItemns = itemsCustom.map((customVariable) => ({
      ...customVariable.telemetryVariablesInfo,
      id: customVariable.id,
      thresholdMax: customVariable.thresholdMax,
      thresholdMin: customVariable.thresholdMin,
      telemetryVariablesId: customVariable.telemetryVariablesId,
      useDefault: customVariable.useDefault,
      customized: true,
    }));

    return itemsDefault.concat(formatCustomItemns);
  };

  const handleGetVariables = async () => {
    const response = await FockinkAPI.get('telemetryVariablesAccount/');
    const formattedVariableData = formatVariableData(response.data.data);
    setVariableData(formattedVariableData);
  };

  React.useEffect(() => {
    handleGetVariables();
  }, []);

  const handleCloseNewCustomVariableModal = () => {
    setOpenNewCustomVariableModal(false);
  };

  const handleSelecteCustomVariableHistoric = async (variable) => {
    const response = await FockinkAPI.get(
      `telemetryVariablesAccount/${variable.id}`,
    );

    setVariableHistoric(response.data.data);
    setOpenVariableHistoricModal(true);
  };

  const aditionalColumnsCustomvariableData = [
    {
      name: 'Ações',
      right: true,
      width: '60px',
      cell: (row) =>
        row.customized ? (
          <>
            <Tooltip title="Visualizar histórico">
              <button
                style={{ color: '#2F80ED' }}
                type="button"
                onClick={() => handleSelecteCustomVariableHistoric(row)}
              >
                <TiThList size={18} />
              </button>
            </Tooltip>

            <Tooltip title="Editar variável">
              <button
                style={{ color: '#2F80ED' }}
                type="button"
                onClick={() => handleselecteVariableToEdit(row)}
              >
                <EditIcon size={18} />
              </button>
            </Tooltip>
            <Tooltip title="Restaurar variável">
              <button
                style={{ color: '#2F80ED' }}
                type="button"
                onClick={() => handleSelectVarableToReset(row)}
              >
                <TiArrowBack size={18} />
              </button>
            </Tooltip>
          </>
        ) : (
          <Tooltip title="Sobrescrever variável">
            <button
              style={{ color: '#2F80ED' }}
              type="button"
              onClick={() => selecteVariableToOverwrite(row)}
            >
              <GoPlus size={18} />
            </button>
          </Tooltip>
        ),
    },
  ];

  const handleCloseEditCustomModal = () => {
    setEditVariableCustomModal(false);
  };

  const handleCloseHistoricCustomModal = () => {
    setOpenVariableHistoricModal(false);
  };

  return (
    <div>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />

      {overwriteVariable && (
        <Modal
          open={openNewCustomVariableModal}
          handleCloseModal={handleCloseNewCustomVariableModal}
          title={`Sobrescrever variável ${overwriteVariable.friendlyName}`}
        >
          <CustomVariableForm
            variable={overwriteVariable}
            handleGetVariables={handleGetVariables}
          />
        </Modal>
      )}

      <VariableTable
        data={variableData}
        aditionalColumns={aditionalColumnsCustomvariableData}
      />

      {selectedCustomVariableToEdit && (
        <Modal
          open={openEditVariableCustomModal}
          handleCloseModal={handleCloseEditCustomModal}
          title={`Editar variável customizada ${selectedCustomVariableToEdit.friendlyName}`}
        >
          <EditCustomVariableForm
            variable={selectedCustomVariableToEdit}
            handleGetVariables={handleGetVariables}
            handleCloseEditCustomModal={handleCloseEditCustomModal}
          />
        </Modal>
      )}

      {variableHistoric && (
        <Modal
          open={openVariableHistoricModal}
          handleCloseModal={handleCloseHistoricCustomModal}
          title={`Hitórico ${variableHistoric.telemetryVariablesInfo.friendlyName}`}
        >
          <VariableHistoric data={variableHistoric.historical} />
        </Modal>
      )}

      <Modal
        open={openVariableResetModal}
        handleCloseModal={handleCloseResetVariableModal}
        title="Restaurar variável"
      >
        <ResetVariable
          variable={selectVariableToReset}
          handleGetVariables={handleGetVariables}
          handleCloseResetVariableModal={handleCloseResetVariableModal}
        />
      </Modal>
    </div>
  );
}
