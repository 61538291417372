import React, { useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { Button, Grid, IconButton, Backdrop } from '@material-ui/core';
import { RiCloseFill } from 'react-icons/ri';
import { TextField } from '@mui/material';
import DewPoint from './DewPoint';
import Humid from './Humid';
import PreciptationRate from './PreciptationRate';
import Radiation from './Radiation';
import RainInDay from './RainInDay';
import StationGraphic from './StationGraphic';
import Temp from './Temp';
import ThermicSensation from './ThermicSensation';
import WindSpeed from './WindSpeed';
import { handleMessageError } from '../../../../../helpers/handlerMessageError';
import FockinkAPI from '../../../../../middlewares/Api';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    // flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
    width: '90%',
    marginLeft: '5%',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 0,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function ModalWeather({ data, openModal, closeModal, type }) {
  const [dataGraphic, setDataGraphic] = useState([]);
  const [failuresDesc, setFailuresDesc] = useState([]);
  const [evapoTranspiracao, setEvapoTranspiracao] = useState('');
  const [batteryLevel, setBatteryLevel] = useState('');
  if (!data) {
    return (<div />);
  }

  let graphic = false;

  if (!type) {
    graphic = false;
  }

  (type === 'historical') ?
    (graphic = true)
    :
    (graphic = false);

  const classes = useStyles();
  const [graphicSelection, setGraphicSelection] = useState(['Temperatura', 'Umidade']);

  function ExistInSelection(newSelection) {
    let exist = false;
    graphicSelection.forEach((item) => {
      if (item === newSelection) {
        exist = true;
      }
    });
    return exist;
  }

  function handleChangeDataGraphic(selection) {
    if ((ExistInSelection(selection)) && (graphicSelection.length > 1)) {
      const newGraphicSelection = [];
      graphicSelection.forEach((item) => {
        if (item !== selection) {
          newGraphicSelection.push(item);
        }
      });
      setGraphicSelection(newGraphicSelection);
    } else if (
      (!ExistInSelection(selection)) &&
  (graphicSelection.length >= 1) &&
  (graphicSelection.length < 3)) {
      setGraphicSelection([...graphicSelection, selection]);
    } else if ((graphicSelection.length >= 3)) {
      handleMessageError('Ops! Parece que você já selecionou 3 itens a serem graficados. Desmarque os itens que você não deseja mais visualizar para uma nova seleção. Mínimo de um item selecionado');
    }
  }

  useEffect(() => {
    // Chama a API
    const params = window.location.pathname.split('/');
    const weatherStationId = params[2];

    if ((data.date) && (type !== 'forecast')) {
      FockinkAPI.get(
        `/dashboards/weatherStation/${weatherStationId}/dayHistorical?date=${data.date}`,
      ).then((r) => {
        setDataGraphic(r.data.data.chartData);
        // setDatahistorical(r.data.data.historical);
        setFailuresDesc([]);
        if (r.data.data.errors.length === 0) {
          setFailuresDesc((failuresDes) => [...failuresDes, 'Sem falhas']);
        } else {
          for (let i = 0; i < r.data.data.errors.length; i += 1) {
            if (r.data.data.errors[i] === 1) {
              setFailuresDesc((failuresDes) => [...failuresDes, 'Falha no relógio', '\n']);
            }
            if (r.data.data.errors[i] === 2) {
              setFailuresDesc((failuresDes) => [...failuresDes, 'Falha no sensor de temperatura e umidade', '\n']);
            }
            if (r.data.data.errors[i] === 4) {
              setFailuresDesc((failuresDes) => [...failuresDes, 'Falha de configuração de altitude e/ou latitude', '\n']);
            }
            if (r.data.data.errors[i] === 8) {
              setFailuresDesc((failuresDes) => [...failuresDes, 'Falha na configuração de sensores', '\n']);
            }
          }
        }
        setEvapoTranspiracao(r.data.data.lastOfDay.evapotranspiration);
        setBatteryLevel(r.data.data.lastOfDay.batteryLevel);
        setFailuresDesc((failuresDes) => [failuresDes.join('')]);
      }).catch((error) => (console.error(error)));
    }
  }, [data]);

  const handleClose = () => {
    closeModal(!openModal);
  };

  return (
    <div>
      <Modal
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>

                  <Grid item xs={12} style={{ marginTop: 0, textAlign: 'right' }}>
                    <IconButton onClick={handleClose}>
                      <RiCloseFill size={30} color="#556E7B" />
                    </IconButton>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <Grid container>
                      <Grid item xs={4} lg={2}>
                        <h1 style={{ marginTop: -50 }}>
                          {data?.date ? `${data?.date.substr(data?.date.length - 2, 2)}/${data?.date.substr(5, 2)}/${data?.date.substr(0, 4)}`
                            :
                            '--/--/----'}
                        </h1>
                      </Grid>

                      {/* <Grid item xs={4} lg={2}>
                        <h5 style={{ marginTop: -33 }}>
                          {
                            dataGraphic &&
                            ` ${dataGraphic?.series[0]?.data.length} leituras`
                            }
                        </h5>
                      </Grid> */}
                    </Grid>
                  </Grid>
                  {/* <div width="100%" style={{ overflowY: 'scroll' }}> */}
                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6} style={{ marginTop: 30, textAlign: 'center' }}>
                        <Button
                          onClick={() => { handleChangeDataGraphic('Temperatura'); }}
                          variant={ExistInSelection('Temperatura') ? 'outlined' : 'text'}
                          style={{
                            fontSize: '12px',
                            color: '#556E7B',
                            minWidth: 120,
                            borderRadius: 10,
                          }}
                        >
                          <Temp noValue />
                        </Button>
                      </Grid>

                      <Grid item xs={12} lg={6} style={{ marginTop: 30, textAlign: 'center' }}>
                        <Button
                          onClick={() => { handleChangeDataGraphic('Umidade'); }}
                          variant={ExistInSelection('Umidade') ? 'outlined' : 'text'}
                          style={{
                            fontSize: '12px',
                            color: '#556E7B',
                            minWidth: 120,
                            borderRadius: 10,
                          }}
                        >
                          <Humid noValue />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6} style={{ marginTop: 30, textAlign: 'center' }}>
                        <Button
                          onClick={() => { handleChangeDataGraphic('Chuva do dia'); }}
                          variant={ExistInSelection('Chuva do dia') ? 'outlined' : 'text'}
                          style={{
                            fontSize: '12px',
                            color: '#556E7B',
                            minWidth: 120,
                            borderRadius: 10,
                          }}
                        >
                          <RainInDay noValue />
                        </Button>
                      </Grid>

                      <Grid item xs={12} lg={6} style={{ marginTop: 30, textAlign: 'center' }}>
                        <Button
                          onClick={() => { handleChangeDataGraphic('Ponto de orvalho'); }}
                          variant={ExistInSelection('Ponto de orvalho') ? 'outlined' : 'text'}
                          style={{
                            fontSize: '12px',
                            color: '#556E7B',
                            minWidth: 120,
                            borderRadius: 10,
                          }}
                        >
                          <DewPoint noValue />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6} style={{ marginTop: 30, textAlign: 'center' }}>
                        <Button
                          onClick={() => { handleChangeDataGraphic('Sensação térmica'); }}
                          variant={ExistInSelection('Sensação térmica') ? 'outlined' : 'text'}
                          style={{
                            fontSize: '12px',
                            color: '#556E7B',
                            minWidth: 120,
                            borderRadius: 10,
                          }}
                        >
                          <ThermicSensation noValue />
                        </Button>
                      </Grid>

                      <Grid item xs={12} lg={6} style={{ marginTop: 30, textAlign: 'center' }}>
                        <Button
                          onClick={() => { handleChangeDataGraphic('Velocidade do vento'); }}
                          variant={ExistInSelection('Velocidade do vento') ? 'outlined' : 'text'}
                          style={{
                            fontSize: '12px',
                            color: '#556E7B',
                            minWidth: 120,
                            borderRadius: 10,
                          }}
                        >
                          <WindSpeed noValue />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6} lg={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={6} style={{ marginTop: 30, textAlign: 'center' }}>
                        <Button
                          onClick={() => { handleChangeDataGraphic('Radiação'); }}
                          variant={ExistInSelection('Radiação') ? 'outlined' : 'text'}
                          style={{
                            fontSize: '12px',
                            color: '#556E7B',
                            minWidth: 120,
                            borderRadius: 10,
                          }}
                        >
                          <Radiation noValue />
                        </Button>
                      </Grid>

                      <Grid item xs={12} lg={6} style={{ marginTop: 30, textAlign: 'center' }}>
                        <Button
                          onClick={() => { handleChangeDataGraphic('Taxa de precipitação'); }}
                          variant={ExistInSelection('Taxa de precipitação') ? 'outlined' : 'text'}
                          style={{
                            fontSize: '12px',
                            color: '#556E7B',
                            minWidth: 120,
                            borderRadius: 10,
                          }}
                        >
                          <PreciptationRate noValue />
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    {
                        graphic ? (
                          <Grid container spacing={2}>
                            <Grid item xs={12} lg={12} style={{ marginTop: 10, textAlign: 'center' }}>
                              <StationGraphic
                                data={dataGraphic}
                                selectedData={graphicSelection}
                              />
                            </Grid>
                          </Grid>
                        )
                          :
                          ''
                      }
                    <Grid container spacing={2}>
                      <Grid item xs={4} lg={2}>
                        <TextField
                          variant="outlined"
                          name="evapotranspiration"
                          value={`${evapoTranspiracao} mm`}
                          label="Evapotranspiração"
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item xs={4} lg={2}>
                        <TextField
                          variant="outlined"
                          name="atmosphericPressure"
                          value={`${data.atmosphericPressure} hPa`}
                          label="Pressão Atmosférica"
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item xs={4} lg={2}>
                        <TextField
                          variant="outlined"
                          name="batteryLevel"
                          value={`${batteryLevel} %`}
                          label="Nível da Bateria"
                          InputProps={{ readOnly: true }}
                        />
                      </Grid>

                      <Grid item xs={4} lg={6}>
                        <TextField
                          variant="outlined"
                          multiline={(failuresDesc.length > 1)}
                          maxRows={2}
                          name="failures"
                          value={failuresDesc}
                          label="Falhas"
                          InputProps={{ readOnly: true }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* </div> */}
                </Grid>
              </Grid>

            </Grid>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
