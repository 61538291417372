import React, { useEffect, useState } from 'react';
import { Modal, FormControlLabel, RadioGroup, Radio, FormControl, MenuItem, Grid } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
// import DoneIcon from '@material-ui/icons/Done';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from 'react-data-table-component';
import { RiCloseFill } from 'react-icons/ri';
import { IconButton } from '@mui/material';
import CardTitle from '../CardTitle';
import CardText from '../CardText';
import Card from '../Card';
// import * as S from './styles';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    border: 0,
    borderRadius: 6,
    boxShadow: theme.shadows[5],

  },
}));
const systems = [
  {
    value: 'Automação',
    name: 'Automação',
  },
  {
    value: 'Fotovoltaica',
    name: 'Fotovoltaica',
  },
  {
    value: 'Irrigação',
    name: 'Irrigação',
  },
  {
    value: 'EstaçãoMeteorológica',
    name: 'Estação Meteorológica',
  },
  {
    value: 'Unidades',
    name: 'Unidades',
  },
  {
    value: 'Usuários',
    name: 'Usuários',
  },
];

const columns = [
  {
    name: 'Permissão',
    selector: 'permissao',
    sortable: true,
    grow: 2,
  },
  {
    name: 'Concedido',
    selector: 'concedido',
    sortable: true,
    center: true,
  },
  {
    name: 'Bloqueado',
    selector: 'bloqueado',
    sortable: true,
    center: true,
  },
  {
    name: 'Herdado',
    selector: 'herdado',
    sortable: true,
    center: true,
  },
];

export function PermissionsModal({
  itsOpen,
  handleAction,
  user,
  closeModal,
  allowed,
  parmissionList,
  handleAllowedSelectPermission,
  handleBlockSelectPermission,
  handleHereditarySelectPermission,
  blocked,
  defaultPermissionsProfile,
  handleUpdatePermissonsProfileUser,

}) {
  const [selectedSystem, setSelectedSystem] = useState('Automação');
  const [permissions, setPermissions] = useState();
  const [valuesForTable, setValuesForTable] = useState([]);

  useEffect(() => {
    for (let i = 0; i < systems.length; i += 1) {
      if (systems[i].value === selectedSystem) {
        switch (selectedSystem) {
          case 'Automação':
            setPermissions(parmissionList?.portal?.Automação);
            break;
          case 'Fotovoltaica':
            setPermissions(parmissionList?.portal?.Fotovoltaica);
            break;
          case 'Irrigação':
            setPermissions(parmissionList?.portal?.Irrigação);
            break;
          case 'EstaçãoMeteorológica':
            setPermissions(parmissionList?.portal?.EstaçãoMeteorológica);
            break;
          case 'Unidades':
            setPermissions(parmissionList?.portal?.Unidades);
            break;
          case 'Usuários':
            setPermissions(parmissionList?.portal?.Usuários);
            break;
          default:
            setPermissions(parmissionList.portal.Automação);
            break;
        }
      }
    }
  }, [selectedSystem, itsOpen]);

  useEffect(() => {
    const values = [];
    permissions && permissions.forEach((permission) => {
      values.push(
        {
          permissao: permission.description,
          concedido: (
            <RadioGroup
              aria-label="quiz"
              name="quiz"
              value={permission.code}
              onChange={(event) => handleAllowedSelectPermission(event)}
            >
              <FormControlLabel
                value={permission.code}
                control={(
                  <Radio
                    checked={allowed.indexOf(permission.code) > -1}
                  />
          )}
              />
            </RadioGroup>
          ),
          bloqueado: (
            <RadioGroup
              aria-label="quiz"
              name="quiz"
              value={permission.code}
              onChange={(event) => {
                handleBlockSelectPermission(event);
              }}
            >
              <FormControlLabel
                value={permission.code}
                control={(
                  <Radio
                    checked={blocked.indexOf(permission.code) > -1}
                  />
                        )}
              />
            </RadioGroup>
          ),
          herdado: (
            <RadioGroup
              aria-label="quiz"
              name="quiz"
              value={permission.code}
              onChange={(event) =>
                handleHereditarySelectPermission(event)
              }
            >
              <FormControlLabel
                value={permission.code}
                control={(
                  <Radio
                    checked={
                            defaultPermissionsProfile.indexOf(permission.code) > -1
                          }
                  />
                        )}
              />
            </RadioGroup>
          ),
        },
      );
    });
    setValuesForTable(values);
  }, [permissions,
    handleAllowedSelectPermission,
    handleBlockSelectPermission,
    selectedSystem,
    handleHereditarySelectPermission]);
  const classes = useStyles();
  return (

    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={itsOpen}
      onClose={handleAction}
      className={classes.modal}
    >
      <Fade in={itsOpen}>
        <div className={classes.paper}>
          <Grid container spacing={1} style={{ minWidth: 600 }}>
            <Grid item xs={12}>
              <Card>
                <Grid item xs={12} style={{ marginTop: 0, textAlign: 'right' }}>
                  <IconButton onClick={() => {
                    closeModal();
                    handleUpdatePermissonsProfileUser();
                  }}
                  >
                    <RiCloseFill size={30} color="#556E7B" />
                  </IconButton>
                </Grid>
                <CardTitle>
                  Configuração de Permissões
                </CardTitle>
                <CardText>
                  <Grid item lg={12} md={4} xs={12}>
                    <h4>
                      {'Nível de acesso: '}
                      {user?.profile?.description}
                    </h4>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <FormControl variant="outlined" style={{ marginTop: 20, maxWidth: 350 }}>
                      <InputLabel id="sistemaLabel">Sistema</InputLabel>
                      <Select
                        labelId="sistemaLabel"
                        id="sistema"
                        name="sistema"
                        value={selectedSystem}
                        onChange={(e) => {
                          setSelectedSystem(e.target.value);
                        }}
                        label="Sistema"
                      >
                        {
                            systems.map((option) => (
                              <MenuItem
                                key={option.value}
                                value={option.value}
                              >
                                {option.name}
                              </MenuItem>
                            ))
                          }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12}>
                    <DataTable
                      noDataComponent="Não existem dados para serem mostrados"
                      columns={columns}
                      data={valuesForTable}
                      style={{ marginTop: -50 }}
                    />
                  </Grid>
                </CardText>
              </Card>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>

  );
}
