import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
} from '@material-ui/core';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MdDelete, MdEdit, MdAdd, MdRemoveRedEye } from 'react-icons/md';
import { RiFilterLine, RiFilterOffLine } from 'react-icons/ri';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Breadcrumbs from '../../../components/Breadcrumbs';
import TabPanel, { a11yProps } from '../Components/TabPanel';
import { ListFeedBack, DeleteFeedBack } from '../helpers/callApi';
import 'date-fns';
import { handleMessageError } from '../../../helpers/handlerMessageError';
import ModalFeedBack from '../ModalFeedBack';
import ModalReadFeedBack from '../ModalReadFeedback';

const breadcrumb = {
  title: 'Fale Conosco',
  routes: [
    // { to: '/equipamentData', name: 'Equipamentos' },
    { name: 'Fale Conosco' },
  ],
};

const columnsWaitingAnswer = [
  {
    name: 'Protocolo',
    selector: 'protocol',
    sortable: true,
  },
  {
    name: 'Data',
    selector: 'dateSolic',
    sortable: true,
    width: 80,
  },
  {
    name: 'Hora',
    selector: 'timeSolic',
    sortable: true,
    maxWidth: 80,
  },
  {
    name: 'Usuário',
    selector: 'userSolic',
    sortable: true,
    maxWidth: 200,
  },
  {
    name: 'Ações',
    selector: 'actionsSolic',
    sortable: true,
    maxWidth: 80,
  },
];

const columnsAnswered = [
  {
    name: 'Protocolo',
    selector: 'protocol',
    sortable: true,
  },
  {
    name: 'Data',
    selector: 'dateSolic',
    sortable: true,
  },
  {
    name: 'Hora',
    selector: 'timeSolic',
    sortable: true,
  },
  {
    name: 'Usuário',
    selector: 'userSolic',
    sortable: true,
  },
  {
    name: 'Respondido Por',
    selector: 'userAnswer',
    sortable: true,
  },
  {
    name: 'Data',
    selector: 'dateAnswer',
    sortable: true,
  },
  {
    name: 'Hora',
    selector: 'timeAnswer',
    sortable: true,
  },
  {
    name: 'Ações',
    selector: 'actionsAnswer',
    sortable: true,
  },

];

export default function ListMyFeedBacks() {
  if (!localStorage.getItem('searchDate')) {
    localStorage.setItem('searchDate', new Date());
  }

  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [feedbacks, setFeedbacks] = useState([]);
  const [waitingAnswer, setWaitingAnswer] = useState([]);
  const [answered, setAnswered] = useState([]);
  const [search, setSearch] = useState(false);
  const [searchUser, setSearchUser] = useState('');

  const lastSearch = localStorage.getItem('searchDate');
  const dateNowFormated = lastSearch;

  const [searchDate, setSearchDate] = useState(dateNowFormated);
  const accountId = localStorage.getItem('accountId');
  const MySwal = withReactContent(Swal);
  const [openModalFeedBack, setOpenModalFeedBack] = useState(false);
  const [openModalRead, setOpenModalRead] = useState(false);
  const [dataModalRead, setDataModalRead] = useState([]);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDateChange = (date) => {
    setSearchDate(date);
    localStorage.setItem('searchDate', date);
  };

  async function importListFeedback() {
    setFeedbacks(await ListFeedBack({
      accountId,
    }));
    setLoading(false);
  }

  useEffect(() => {
    importListFeedback();
    setLoading(true);
  }, [openModalFeedBack]);

  async function handleDelete({ id }) {
    const response = await DeleteFeedBack({
      feedbackId: id,
    });
    if (response) {
      importListFeedback();
    } else {
      handleMessageError('Ops! parece que não foi possivel deletar este registro.');
    }
  }

  function ButtonViewModal({ data }) {
    return (
      <IconButton
        onClick={
              () => {
                setDataModalRead(data);
                setOpenModalRead(true);
              }
            }
      >
        <MdRemoveRedEye color="#556E7B" size={22} />
      </IconButton>
    );
  }

  function actionsButtons({ id, data }) {
    if (id) {
      return (
        <div key={id}>
          <ButtonViewModal data={data} />
          <IconButton
            onClick={
              () => {
                // <Redirect push to="/myfeedbacks/edit/2" />;// ${id}
                window.location.href = `/myfeedbacks/edit/${id}`;
              }
            }
          >
            <MdEdit color="#556E7B" size={22} />
          </IconButton>
          <IconButton
            onClick={
          async () => {
            MySwal.fire({
              title: 'Tem certeza?',
              text: 'Essa ação não poderá ser revertida',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Sim, APAGAR!',
              cancelButtonText: 'Não, MANTER',
            }).then((result) => {
              (result.isConfirmed) &&
              (
                handleDelete({ id })
              );
            });
          }
          }
          >
            <MdDelete color="#556E7B" size={22} />

          </IconButton>
        </div>
      );
    }
  }

  useEffect(() => {
    if (feedbacks.length > 0) {
      setLoading(true);
      const waitAnswer = [];
      const alreadyAnswered = [];
      feedbacks.forEach((feedBack) => {
        let dateForSearch = '';
        const dateConversation = Date.parse(new Date((moment.unix(feedBack?.conversation[0]?.ts).format('MM/DD/YYYY'))));

        dateForSearch = Date.parse(searchDate);

        if ((feedBack.conversation.length === 1) && (feedBack.status === 'N')) {
          if ((!search) || ((search) && (
            ((searchUser === '') ||
          (feedBack?.conversation[0]?.user?.name.toUpperCase().includes(searchUser.toUpperCase())))
          &&
          ((dateForSearch === '')
          ||
          ((dateConversation > dateForSearch) || (dateConversation === dateForSearch)))
          ))) {
            waitAnswer.push({
              protocol: feedBack?.protocol,
              dateSolic: moment.unix(feedBack?.conversation[0]?.ts).format('DD/MM/YYYY'),
              timeSolic: moment.unix(feedBack?.conversation[0]?.ts).format('HH:mm:ss'),
              userSolic: feedBack?.conversation[0]?.user?.name,
              screenSolic: feedBack?.sourcePage,
              commentSolic: feedBack?.conversation[0]?.message,
              // attachmentSolic: attachmentsCount({ numbers: feedBack?.files?.length || 0 }),
              actionsSolic: actionsButtons({ id: feedBack?.id, data: feedBack }),
            });
          }
        } else if ((feedBack.conversation.length === 2) && (feedBack.status === 'A') &&
        ((!search) || ((search) && (
          ((searchUser === '') ||
       (feedBack?.conversation[0]?.user?.name.toUpperCase().includes(searchUser.toUpperCase())))
       &&
       ((dateForSearch === '')
       ||
       ((dateConversation > dateForSearch) || (dateConversation === dateForSearch)))
        )))) {
          alreadyAnswered.push({
            protocol: feedBack?.protocol,
            dateSolic: moment.unix(feedBack?.conversation[0]?.ts).format('DD/MM/YYYY'),
            timeSolic: moment.unix(feedBack?.conversation[0]?.ts).format('HH:mm:ss'),
            userSolic: feedBack?.conversation[0]?.user?.name,
            screenSolic: feedBack?.sourcePage,
            commentSolic: feedBack?.conversation[0]?.message,
            // attachmentSolic: attachmentsCount({ numbers: feedBack?.files?.length || 0 }),
            commentAnswer: feedBack?.conversation[1]?.message,
            userAnswer: feedBack?.conversation[1]?.user?.name,
            dateAnswer: moment.unix(feedBack?.conversation[1]?.ts).format('DD/MM/YYYY'),
            timeAnswer: moment.unix(feedBack?.conversation[1]?.ts).format('HH:mm:ss'),
            actionsAnswer: ButtonViewModal({ data: feedBack }),
          });
        }
      });
      setWaitingAnswer(waitAnswer);
      setAnswered(alreadyAnswered);
      setLoading(false);
    }
    setLoading(false);
  }, [feedbacks, searchUser, /* searchScreen, */ searchDate, search]);

  function handleOpenModalFeedBack() {
    setOpenModalFeedBack(true);
  }

  return (
    <div>
      <Breadcrumbs
        routes={breadcrumb.routes}
        title={`${breadcrumb.title}`}
      />
      {
        loading ?
          (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Skeleton width="100%" height="300px" />
              </Grid>
              <Grid item xs={12}>
                <Skeleton width="100%" height="500px" />
              </Grid>
            </Grid>

          )
          :
          (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} lg={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            margin="none"
                            inputVariant="outlined"
                            id="dateSolic"
                            label="Data a partir de"
                            format="dd/MM/yyyy"
                            value={searchDate || ' '}
                            onChange={handleDateChange}
                            // KeyboardButtonProps={{
                            //   'aria-label': 'change date',
                            // }}
                            // InputProps={{ readOnly: true }}
                            error={false}
                            helperText={false}
                            size="small"
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <TextField
                          id="userSolic"
                          label="Usuário Solicitante"
                          type="search"
                          variant="outlined"
                          value={searchUser}
                          onChange={(e) => { setSearchUser(e.target.value); }}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          startIcon={!search ? <RiFilterLine /> : <RiFilterOffLine />}
                          onClick={() => {
                            setLoading(true);
                            setSearch(!search);
                          }}
                        >
                          {search ? 'Limpar Filtros' : 'Aplicar Filtros'}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<MdAdd />}
                        onClick={() => {
                          handleOpenModalFeedBack();
                        }}
                      >
                        Novo Fale Conosco
                      </Button>
                    </div>

                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons="on"
                      indicatorColor="primary"
                      textColor="primary"
                      aria-label="scrollable force tabs example"
                    >
                      <Tab
                        label={(
                          <Grid container spacing={1}>
                            <Grid item xs={1}>
                              <Avatar
                                style={{
                                  maxWidth: 22,
                                  maxHeight: 22,
                                  fontSize: 12,
                                  marginLeft: -10,
                                  backgroundColor: '#556E7B',
                                }}
                              >
                                {waitingAnswer.length}
                              </Avatar>
                            </Grid>
                            <Grid item xs={11}>
                              Aguardando Resposta
                            </Grid>
                          </Grid>
                )}
                        {...a11yProps(0)}
                        style={{ marginRight: 20 }}
                      />
                      <Tab
                        label={(
                          <Grid container spacing={1}>
                            <Grid item xs={1}>
                              <Avatar
                                style={{
                                  maxWidth: 22,
                                  maxHeight: 22,
                                  fontSize: 12,
                                  marginLeft: -10,
                                  backgroundColor: '#556E7B',
                                }}
                              >
                                {answered.length}
                              </Avatar>
                            </Grid>
                            <Grid item xs={11}>
                              Respondidos
                            </Grid>
                          </Grid>
              )}
                        {...a11yProps()}
                      />
                    </Tabs>
                    <TabPanel value={tabValue} index={0}>
                      <DataTable
                        pagination
                        paginationComponentOptions={{
                          rowsPerPageText: 'Linhas:',
                          rangeSeparatorText: 'de',
                        }}
                // paginationDefaultPage={parseInt(pageData?.page || 1, 10)}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
                        noDataComponent="Não existem dados para serem mostrados"
                        columns={columnsWaitingAnswer}
                        data={waitingAnswer}
                        title=""
                        paginationPerPage={10}
                        style={{ marginTop: -50 }}
                      />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                      <DataTable
                        pagination
                        paginationComponentOptions={{
                          rowsPerPageText: 'Linhas:',
                          rangeSeparatorText: 'de',
                        }}
                        paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
                        noDataComponent="Não existem dados para serem mostrados"
                        columns={columnsAnswered}
                        data={answered}
                        title=""
                        paginationPerPage={10}
                        style={{ marginTop: -50 }}
                      />
                    </TabPanel>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )
      }
      <ModalFeedBack
        openModal={openModalFeedBack}
        closeModal={setOpenModalFeedBack}
      />
      <ModalReadFeedBack
        openModal={openModalRead}
        closeModal={setOpenModalRead}
        data={dataModalRead}
      />
    </div>
  );
}
