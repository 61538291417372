/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
// import ReactApexChart from 'react-apexcharts';
import Grid from '@material-ui/core/Grid';
import '../../../../../App.css';
import Skeleton from '@material-ui/lab/Skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import { Button } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FockinkAPI from '../../../../../middlewares/Api';
import PieGraph from '../../../../../components/PieGraph';
import ThermoSVG from '../../../../../components/ThermoSVG';
import Card from '../../../../../components/Card';
import CardText from '../../../../../components/CardText';
import CardTitle from '../../../../../components/CardTitle';
import Spacer from '../../../../../components/Spacer';
import DropWatter from '../Components/DropWatter';

// import LevelIcon from '../Components/LevelIcon';

// const useStyles = makeStyles({
//   subTitle: {
//     margin: '0',
//     fontSize: '14px',
//     textAlign: 'center',
//     fontWeight: '500',
//   },
//   obsTrim: {
//     margin: '0',
//     fontWeight: '500',
//     fontSize: '15px',
//     overflow: 'hidden',
//     display: '-webkit-box',
//     '-webkit-line-clamp': '2',
//     '-webkit-box-orient': 'vertical',
//   },
// });

export default (props) => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(undefined);
  // const classes = useStyles();
  const MySwal = withReactContent(Swal);
  // const [series, setSeries] = React.useState([]);
  // const options = {
  //   chart: {
  //     toolbar: {
  //       show: false,
  //     },
  //     type: 'pie',
  //   },
  //   dataLabels: {
  //     enabled: true,
  //   },
  //   legend: {
  //     position: 'bottom',
  //   },
  //   labels: ['Ocupado', 'Livre'],
  //   colors: ['#BBAA00', '#CACACA'],
  // };
  // TODO: Send it to the translation file
  // const unitTypes = [
  //   { name: 'Não Identificado', icon: 'armazem' },
  //   { name: 'Silo Plano', icon: 'silo' },
  //   { name: 'Silo V', icon: 'silo' },
  //   { name: 'Armazém Plano', icon: 'armazem' },
  //   { name: 'Armazém SemiV', icon: 'armazem' },
  //   { name: 'Armazém V', icon: 'armazem' },
  //   { name: 'Armazém W', icon: 'armazem' },
  //   { name: 'Armazém Bordas Arredondadas - Plano', icon: 'armazem' },
  //   { name: 'Armazém Bordas Arredondadas - Fundo V', icon: 'armazem' },
  //   { name: 'Silo Elevado', icon: 'silo' },
  //   { name: 'Silo Semi V', icon: 'silo' },
  //   { name: 'Silo W', icon: 'silo' },
  //   { name: 'Tulha Plano', icon: 'tulha' },
  //   { name: 'Tulha Fundo V', icon: 'tulha' },
  //   { name: 'Bunker Plano', icon: 'bunker' },
  //   { name: 'Bunker Fundo V', icon: 'bunker' },
  //   { name: 'Armazém Bordas Semi Arredondadas - Plano', icon: 'armazem' },
  //   { name: 'Armazém Bordas Semi Arredondadas - Semi V', icon: 'armazem' },
  // ];
  const callApi = () => {
    setLoading(true);
    FockinkAPI.get(
      `/dashboards/thermometry/${props.equipamentId}/status?sub=${props.selectedStrategy?.sub || 'all'}`,
    ).then((response) => {
      setData(response.data.data.telemetry);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    callApi();
    const interval = setInterval(() => {
      callApi();
    }, 60000);
    return () => clearInterval(interval);
  }, [props.selectedStrategy?.sub]);

  React.useEffect(() => {
    [
      '/images/unitType/armazem.svg',
      '/images/unitType/bunker.svg',
      '/images/unitType/silo.svg',
      '/images/unitType/tulha.svg',
    ].forEach((image) => {
      new Image().src = image;
    });
  }, []);

  const getIcon = (status) => {
    if (status === 'lower') {
      return (
        <span
          style={{
            color: 'green',
            fontSize: '22px',
            fontWeight: '500',
            marginLeft: '1px',
          }}
        >
          ▼
        </span>
      );
    }
    if (status === 'equal') {
      return (
        <span
          style={{
            color: 'orange',
            fontSize: '22px',
            fontWeight: '500',
            marginLeft: '1px',
          }}
        >
          ►
        </span>
      );
    }
    if (status === 'higher') {
      return (
        <span
          style={{
            color: 'red',
            fontSize: '22px',
            fontWeight: '500',
            marginLeft: '1px',
          }}
        >
          ▲
        </span>
      );
    }
    return <span style={{ color: 'purple', fontSize: '22px' }}>{status}</span>;
  };

  const showSvg = () => {
    MySwal.fire({
      html: buildSvg(
        props.selectedStrategy.moistureActual,
        data.averageMax,
        data.average,
        data.averageMin,
        data.levelSensors,
      ),
      grow: 'fullscreen',
      customClass: {
        container: 'svgTest',
        content: 'svgSmall',
        show: 'svgSmall',
        popup: 'svgBackground',
        closeButton: 'svgClose',
      },
      showCloseButton: true,
      showConfirmButton: false,
    });
  };

  const buildSvg = (humid, max, med, low, levelSensors) => (
    <ThermoSVG
      itens={[
        {
          temp: max,
          humid,
          text: 'Máximo',
          color: '#9130BF',
          qtdSensors: levelSensors.averageMax,
        },
        {
          temp: med,
          humid,
          text: 'Médio',
          color: '#CFBA1F',
          qtdSensors: levelSensors.average,
        },
        {
          temp: low,
          humid,
          text: 'Mínimo',
          color: '#009AFF',
          qtdSensors: levelSensors.averageMin,
        },
      ]}
      totalSensors={levelSensors.total}
    />
  );

  if (props.loading || loading) {
    return (
      <Skeleton variant="rect" height={200} width="100%" animation="wave" />
    );
  }

  return (
    <Card similar>
      <CardTitle size="1.17em" pb="4px">
        Termometria
        <Spacer />
        <Tooltip title="Diagrama de conservação de cereais">
          <Button size="small" onClick={showSvg} style={{ visibility: props.selectedStrategy?.sub ? 'visible' : 'hidden' }}>
            <small style={{ marginRight: '8px' }}>Gráfico de Cereais</small>
            <div
              style={{
                cursor: 'pointer',
                width: '24px',
                maxHeight: '24px',
              }}
            >
              <img
                src="/images/cerealChart/iconCerealChart.png"
                style={{
                  maxWidth: '100%',
                  height: '24px',
                }}
                alt=""
              />
            </div>
          </Button>
        </Tooltip>
      </CardTitle>
      <CardText pt="0">
        <Grid container spacing={1} justifyContent="space-evenly">
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <Card outlined similar>
              <CardTitle pl="8px" pr="8px" pt="2px" pb="2px" size="0.75rem">
                Temperatura do Grão
              </CardTitle>
              <CardText pb="0px" pr="2px" pl="2px">
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  alignContent="center"
                >
                  <Grid item xs={5} sm={5} xl={5}>
                    <img
                      src="/images/thermometryIcons/tempIcon.svg"
                      style={{ maxWidth: '48px', height: '70px', paddingRight: 15, float: 'right' }}
                      alt=""
                    />
                  </Grid>
                  <Grid item xs={7} sm={7} xl={7}>
                    <h3
                      style={{
                        margin: '0',
                        fontSize: '14px',
                        textAlign: 'left',
                      }}
                    >
                      <small style={{ fontSize: '13px', fontWeight: '200' }}>
                        Max:
                        {' '}
                      </small>
                      {`${data.averageMax.toFixed(1)}°C`}
                    </h3>
                    <h3
                      style={{
                        margin: '0',
                        fontSize: '14px',
                        textAlign: 'left',
                      }}
                    >
                      <small style={{ fontSize: '13px', fontWeight: '200' }}>
                        Med:
                        {' '}
                      </small>
                      {`${data.average.toFixed(1)}°C`}
                    </h3>
                    <h3
                      style={{
                        margin: '0',
                        fontSize: '14px',
                        textAlign: 'left',
                      }}
                    >
                      <small style={{ fontSize: '13px', fontWeight: '200' }}>
                        Min:
                        {' '}
                      </small>
                      {`${data.averageMin.toFixed(1)}°C`}
                    </h3>
                  </Grid>
                </Grid>
              </CardText>
            </Card>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <Card outlined similar>
              <CardTitle pl="8px" pr="8px" pt="2px" pb="2px" size="0.75rem">
                Umidade do Grão
              </CardTitle>
              <CardText pb="0px" pr="2px" pl="2px">
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  alignContent="center"
                >
                  {
                    props.selectedStrategy && (
                      <>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                          <DropWatter value={props.selectedStrategy.moistureActual} />
                        </Grid>

                      </>
                    )
                  }

                </Grid>
              </CardText>
            </Card>
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <Card outlined similar>
              <CardTitle pl="8px" pr="8px" pt="2px" pb="2px" size="0.75rem">
                {`Pontos Acima de ${data.limitTemperature}°C (Limite)`}
                {getIcon(data.hotSensors.status)}
              </CardTitle>
              <CardText pt="0px" pb="16px">
                <div style={{ maxHeight: '120px' }}>
                  <PieGraph
                    level={data.hotSensors.percentage?.toFixed(1)}
                    noName
                    color="#dc3f3f"
                  />
                </div>
              </CardText>
            </Card>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={3} xs={12}>
            <Card outlined similar>
              <CardTitle pl="8px" pr="8px" pt="2px" pb="2px" size="0.75rem">
                Estimativa de Nível
              </CardTitle>
              <CardText pt="0px" pb="16px">
                <div style={{ maxHeight: '120px' }}>
                  <PieGraph level={data.levelPerc.toFixed(1)} noName color="#dcaf00" />
                </div>
                {/* <ReactApexChart
                  options={options}
                  series={[data.levelPerc, 100 - data.levelPerc]}
                  type="pie"
                  height={140}
                /> */}
                {/* <LevelIcon percentage={data.levelPerc} /> */}
              </CardText>
            </Card>
          </Grid>
        </Grid>
      </CardText>
    </Card>
  );
};
