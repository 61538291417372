import styled from 'styled-components';

export const TextArea = styled.textarea`
  width: 100%;
  height: 5rem;
  resize: none;
  margin-bottom: 0.8rem;
  padding: 0.6rem;
  border-radius: 3px;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;
