import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import PlaceIcon from '@material-ui/icons/Place';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import jwtDecode from 'jwt-decode';
import { Alert } from '@mui/material';
import FockinkAPI from '../../../../middlewares/Api';
import BottomButtons from '../../../../components/Buttons/BottomButtons';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import PortalPermissions from '../../../../helpers/enums/PortalPermissions';
import { hasPermission } from '../../../../helpers/permission';
import PlantCard from '../../../../components/PlantCard';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  subTitle: {
    borderBottom: '1px solid #ccc',
    textAlign: 'center',
    paddingBottom: '0 !important',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

export default (props) => {
  const [loading, setLoading] = React.useState(true);
  const [tab, setTab] = React.useState(0);
  const [plants, setPlants] = React.useState([]);
  const token = localStorage.getItem('@FockinkAPP:token');
  const { unit } = jwtDecode(token);

  const [data, setData] = React.useState({
    status: 'A',
    name: '',
    description: '',
    location: {},
    plants: [],
  });

  const breadcrumb = {
    title: `Cadastro de ${unit?.plural || 'Unidades'}`,
    routes: [
      { to: '/settings', name: 'Configurações' },
      { to: '/units', name: unit?.plural || 'Unidades' },
      { name: `Cadastro de ${unit?.plural || 'Unidades'}` },
    ],
  };

  React.useEffect(() => {
    if (props.match.params.id === 'new') {
      setLoading(false);
    } else {
      setLoading(true);
      FockinkAPI.get(`/units/${props.match.params.id}`).then((response) => {
        setData({
          ...response.data.data,
          plants: (response.data.data.plants || []).map((e) => e.id),
        });
        setLoading(false);
      });
    }
  }, [props.match.params.id]);

  React.useEffect(() => {
    if (tab === 1) {
      setLoading(true);
      FockinkAPI.get('/dashboards/plants?withUnits=true').then((response) => {
        setPlants(response.data.data.plants);
        setLoading(false);
      });
    }
  }, [tab]);

  const classes = useStyles();

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value || event.target.checked,
    });
  };
  // const handleChangeOrg = (event) => {
  //   if (event.target.value.indexOf('Todas') > -1) {
  //     setSearchData({ ...searchData, plants: ['Todas'], searched: false });
  //   } else {
  //     setSearchData({ ...searchData, plants: event.target.value, searched: false });
  //   }
  // };

  const saveData = () => {
    const dt = {
      data: {
        name: data.name,
        description: data.description,
        location: data.location,
        plants: data.plants,
        status: data.status,
      },
    };
    if (props.match.params.id === 'new') {
      dt.method = 'post';
      dt.url = '/units';
    } else {
      dt.method = 'put';
      dt.url = `/units/${props.match.params.id}`;
    }
    return dt;
  };

  const deleteData = () => ({ url: `/units/${props.match.params.id}` });

  const selectedPlants = plants
    .map((m) => (data.plants || []).indexOf(m.id) > -1 && m)
    ?.filter((x) => x !== false);

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper square className={classes.root}>
            <Tabs
              value={tab}
              onChange={(event, newValue) => setTab(newValue)}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
            >
              <Tab icon={<PlaceIcon />} label="Dados" />
              <Tab
                icon={<AccountTreeIcon />}
                disabled={
                  !hasPermission(PortalPermissions.PLANTS_SETTINGS_EDIT.value)
                }
                label="Plantas"
              />
            </Tabs>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            {loading && (
              <Skeleton
                variant="rect"
                height={300}
                width="100%"
                animation="wave"
              />
            )}

            {!loading && tab === 0 && (
              <>
                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="filled"
                    name="name"
                    onChange={handleChange}
                    value={data.name || ''}
                    label="Nome"
                    autoComplete="nope"
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <TextField
                    variant="filled"
                    name="description"
                    onChange={handleChange}
                    value={data.description || ''}
                    label="Descrição"
                  />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <FormControl variant="filled">
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={data.status}
                      onChange={handleChange}
                      name="status"
                    >
                      <MenuItem value="A">Ativo</MenuItem>
                      <MenuItem value="I">Inativo</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* <Grid item xs={12}>
                  <h3 className={classes.subTitle}>Endereço</h3>
                </Grid>

                <Grid item xs={12}>
                  <AddressFields handleChangeLocation={handleChangeLocation} data={data.location} />
                </Grid> */}
              </>
            )}

            {!loading && tab === 1 && (
              <>
                <Grid item xs={12}>
                  <FormControl variant="filled">
                    <InputLabel>Equipamentos</InputLabel>
                    <Select
                      multiple
                      value={data.plants}
                      onChange={handleChange}
                      name="plants"
                      renderValue={(selected) =>
                        selected.length === 0
                          ? 'Nenhuma planta selecionado'
                          : `Selecionado: ${selected.length} de ${plants.length}`
                      }
                    >
                      {plants.map((m) => (
                        <MenuItem key={m.description} value={m.id}>
                          <Checkbox
                            checked={(data.plants || []).indexOf(m.id) > -1}
                          />
                          <ListItemText
                            primary={
                              m.description +
                              m.units
                                .map((u) => ['('] + u.description)
                                .join('), ') +
                              [')']
                            }
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Alert severity="warning">
                    {selectedPlants?.map(
                      (plant) =>
                        plant.units.length > 1 && (
                          <Grid item xs={12} lg={12}>
                            {/* <Alert severity="warning"> */}
                            Alerta:
                            {' '}
                            {plant.description}
                            {' '}
                            já está associada a,
                            {' '}
                            {plant.units
                              .map((x) => x.name)
                              ?.filter((y) => y !== data?.name)
                              .join(', ')}
                            {/* </Alert> */}
                          </Grid>
                        ),
                    )}
                  </Alert>
                </Grid>
                <Grid item xs={12}>
                  <h3 className={classes.subTitle}>Selecionados</h3>
                </Grid>

                {plants.map((m) =>
                  (data.plants || []).indexOf(m.id) > -1 ? (
                    <Grid item xs={3}>
                      <PlantCard data={m} simpleCard />
                    </Grid>
                  ) : (
                    <></>
                  ))}
              </>
            )}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          className={`${classes.textAlign} ${classes.bottomButtons}`}
        >
          <BottomButtons
            saveData={saveData}
            deleteData={props.match.params.id !== 'new' && deleteData}
            backTo="/units"
            canSave={PortalPermissions.USERS_MANAGE.value}
          />
        </Grid>
      </Grid>
    </>
  );
};
