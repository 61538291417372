import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { Led, LedWrapper, Label } from './style';
import { Modal } from '../../../../../../components';

const AuroraVisionAlert = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [dataColumns, setDataColumns] = useState({ columns: [], data: [] });
  const { data } = props;
  const eventsData = props.data.events;

  const colors = {
    MEDIUM: 'Orange',
    LOW: 'Yellow',
    NORMAL: 'Green',
  };

  const translate = {
    MEDIUM: 'Médio',
    LOW: 'Baixo',
    NORMAL: 'Normal',
  };
  const eventType = {
    DEVCOM: 'Dispositivo não está se comunicando',
    LOGCOM: 'Registrador de dados não está se comunicando',
  };
  React.useEffect(() => {
    const defaultColumns = [
      {
        name: 'Severidade',
        selector: 'eventSeverity',
        maxWidth: '80px',
        cell: (row) => (
          <Tooltip title={translate[row.eventSeverity]}>
            <Led ledColor={colors[row.eventSeverity]} />
          </Tooltip>
        ),
        sortable: true,
      },
      { name: 'Serial', selector: 'deviceSerialNumber', minWidth: '150px', sortable: true },
      {
        name: 'Tipo de Evento',
        selector: 'eventType',
        cell: (row) => (
          <Tooltip title={eventType[row.eventType]}>
            <tableLabel>{row.eventType}</tableLabel>
          </Tooltip>
        ),
        sortable: true,
      },
      {
        name: 'Início do Evento',
        selector: 'eventStart',
        cell: (row) => moment(row.eventStart).format('DD/MM/YYYY HH:mm:ss'),
        sortable: true,
      },
    ];
    setDataColumns({ columns: defaultColumns, data: eventsData });
  }, [data]);

  return (
    <LedWrapper>
      {
       eventsData?.length > 0 ? (
         <>
           <Tooltip title={translate[eventsData[0].eventSeverity]}>
             <Led ledColor={colors[eventsData[0].eventSeverity]} />
           </Tooltip>
           <Label onClick={() => setOpenModal(true)}>
             (
             {eventsData?.length}
             )
           </Label>
           <Modal
             title={`Eventos da planta: ${eventsData[0].plantName}`}
             open={openModal}
             handleCloseModal={() => setOpenModal(false)}
           >
             <DataTable
               pagination
               paginationPerPage={15}
               paginationComponentOptions={{
                 rowsPerPageText: 'Linhas:',
                 rangeSeparatorText: 'de',
               }}
               paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
               noDataComponent="Não existem dados para serem mostrados"
               columns={dataColumns.columns}
               data={dataColumns.data}
               defaultSortField="plantName"
               defaultSortAsc={false}
               title=""
             />
           </Modal>
         </>
       ) : (
         <>
           <Tooltip title="Normal">
             <Led ledColor="Green" />
           </Tooltip>
         </>
       )
     }
    </LedWrapper>
  );
};

export default AuroraVisionAlert;
