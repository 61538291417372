import React, { createContext, useCallback, useContext, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router-dom';
import FockinkAPI from '../middlewares/Api';

export const AuthContext = createContext([]);

const AuthProvider = ({ children }) => {
  const history = useHistory();

  const [data, setData] = useState(() => {
    const userFromLocalStorage = localStorage.getItem('@FockinkAPP:user');
    const tokenFromLocalStorage = localStorage.getItem('@FockinkAPP:token');
    if (userFromLocalStorage && tokenFromLocalStorage) {
      return {
        token: tokenFromLocalStorage,
        user: JSON.parse(userFromLocalStorage),
      };
    }
    return {};
  });

  const signIn = useCallback(async (email, password) => {
    const sessionResponse = await FockinkAPI.post('login', {
      login: email,
      password,
    });

    const { token, success } = sessionResponse.data;

    const {
      authType,
      accountId,
      login,
      name,
      profile,
      tz,
      preferences,
      userId,
      permissions,
      ...rest
    } = jwtDecode(token);

    let profileType;

    if (profile.profileId >= 10 && profile.profileId <= 13) {
      profileType = 'admin';
    } else if (profile.profileId >= 51 && profile.profileId <= 54) {
      profileType = 'clientadmin';
    }

    const userData = {
      token,
      user: {
        ...rest,
        id: userId,
        accountId,
        authType,
        email: login,
        name,
        permissions,
        profile,
        profileType,
        tz,
        preferences,
      },
    };

    localStorage.setItem('@FockinkAPP:token', token);
    localStorage.setItem('@FockinkAPP:user', JSON.stringify(userData.user));

    setData(userData);

    // TODO: remove the code below
    localStorage.userId = userId;
    localStorage.login = login;
    localStorage.name = name;
    localStorage.localeTag = rest.locale.tag;
    localStorage.localeCurrency = rest.locale.currency;
    localStorage.authType = authType;
    localStorage.userProfile = profile.description;
    if (accountId) {
      localStorage.accountId = accountId;
    }
    if (rest.organizationId) {
      localStorage.organizationId = rest.organizationId;
    }
    localStorage.forceResetPassword = rest.forceResetPassword || false;
    localStorage.permissions = JSON.stringify(permissions);
    localStorage.profile = profileType;
    localStorage.onlyThisPlants = rest.onlyThisPlants;
    localStorage.tz = tz;
    localStorage.csv = preferences.csv;
    localStorage.csvString = preferences.csvString;
    localStorage[process.env.REACT_APP_STORAGE_KEY] = token;

    return success;
  }, []);

  const authByToken = useCallback(async (token) => {
    const {
      authType,
      accountId,
      login,
      name,
      profile,
      tz,
      preferences,
      userId,
      permissions,
      ...rest
    } = jwtDecode(token);

    let profileType;

    if (profile.profileId >= 10 && profile.profileId <= 13) {
      profileType = 'admin';
    } else if (profile.profileId >= 51 && profile.profileId <= 54) {
      profileType = 'clientadmin';
    }

    const userData = {
      token,
      user: {
        ...rest,
        id: userId,
        accountId,
        authType,
        email: login,
        name,
        permissions,
        profile,
        profileType,
        tz,
        preferences,
      },
    };

    localStorage.setItem('@FockinkAPP:token', token);
    localStorage.setItem('@FockinkAPP:user', JSON.stringify(userData.user));

    // TODO: remove the code below
    localStorage.userId = userId;
    localStorage.login = login;
    localStorage.name = name;
    localStorage.localeTag = rest.locale.tag;
    localStorage.localeCurrency = rest.locale.currency;
    localStorage.authType = authType;
    localStorage.userProfile = profile.description;
    if (accountId) {
      localStorage.accountId = accountId;
    }
    if (rest.organizationId) {
      localStorage.organizationId = rest.organizationId;
    }
    localStorage.forceResetPassword = rest.forceResetPassword || false;
    localStorage.permissions = JSON.stringify(permissions);
    localStorage.profile = profileType;
    localStorage.onlyThisPlants = rest.onlyThisPlants;
    localStorage.tz = tz;
    localStorage.csv = preferences.csv;
    localStorage.csvString = preferences.csvString;
    localStorage[process.env.REACT_APP_STORAGE_KEY] = token;

    setData(userData);

    return true;
  }, []);

  const handleGetMyAccount = async () => {
    const response = await FockinkAPI.get('accounts/myAccount');
    return response.data.data;
  };

  const handleGetAccounts = async () => {
    const response = await FockinkAPI.get('accounts/');
    return response.data.data;
  };

  const signOut = useCallback(() => {
    const { user } = data;

    if (user.authType === 'simpleAzureADApp') {
      window.location.href = `${process.env.REACT_APP_BACKEND_API}/login/simpleAzureADLogout/${user.accountId}?redirect=${window.location.origin}/login`;
    }

    localStorage.removeItem('@FockinkAPP:token');
    localStorage.removeItem('@FockinkAPP:user');

    history.push('/login');
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        authByToken,
        handleGetMyAccount,
        handleGetAccounts,
        user: data.user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
