import React, { useState } from 'react';
import { TextField, Grid, MenuItem, Select, InputLabel, FormControl } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import Skeleton from '@material-ui/lab/Skeleton';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import withReactContent from 'sweetalert2-react-content';
import parseFloatCustom from '../../../../helpers/parseFloatCustom';
import BottomButtons from '../../../../components/Buttons/BottomButtons';
import FockinkAPI from '../../../../middlewares/Api';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { systemTypesNumeric } from '../utils';

const PlansForm = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    referenceDate: new Date(),
  });
  const breadcrumbs = {
    routes: [
      { name: 'Cadastro de Planos', to: '/settings/plans' },
      { name: 'Registro de Planos' },
    ],
    title: 'Registro de Planos',
  };
  const useStyles = makeStyles({
    textAlign: {
      textAlign: 'right',
    },
    btnSpace: {
      marginLeft: '15px',
    },
    bottomButtons: {
      marginTop: '15px',
      borderTop: '1px solid #ccc',
      paddingTop: '10px !important',
    },
    root: {
      width: '100%',
      marginTop: 30,
    },
  });
  const classes = useStyles();
  const back = {
    name: 'Cadastro de Planos',
    to: '/settings/plans',
  };
  const history = useHistory();

  const MySwal = withReactContent(Swal);

  const confDelete = () => {
    MySwal.fire({
      title: 'Tem certeza?',
      text: 'Essa ação não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, APAGAR!',
      cancelButtonText: 'Não, MANTER',
    }).then((result) => {
      if (result.isConfirmed) {
        data.status = 'I';
        const dt = {
          planName: data.planName,
          systems: [data.systems],
          invoiceFrequency: data.invoiceFrequency,
          invoiceDay: parseFloatCustom(data?.invoiceDay),
          validMonths: parseFloatCustom(data?.validMonths),
          lockAfterExpiration: data.lockAfterExpiration,
          userLimitation: data.userLimitation,
          fockinkId: data.fockinkId,
          cloudToDevice: data.cloudToDevice,
          status: data.status,
        };
        FockinkAPI.put(`/plan/${props.match.params.id}`, dt).then(() => {
          toast.success('Dados salvos com sucesso');
          history.push('/settings/plans');
        })
          .catch(async (error) => {
            const { response } = error;

            if (response.status === 400) {
              toast.error(response.data.validation.body.message);
            } else {
              toast.error(response.data.message);
            }
          });
      } else {
        data.status = 'A';
      }
    });
  };

  React.useEffect(() => {
    FockinkAPI.get(
      `/plan/${props.match.params.id}`,
    ).then((response) => {
      if (props.match.params.id === 'new') {
        setLoading(false);
      } else {
        setData(response.data.data);
        setLoading(false);
      }
    });
    setLoading(false);
  }, []);

  const saveData = () => {
    if (props.match.params.id === 'new') {
      const dt = {
        planName: data.planName,
        systems: data.systems,
        invoiceFrequency: data.invoiceFrequency,
        invoiceDay: parseFloatCustom(data?.invoiceDay),
        validMonths: parseFloatCustom(data?.validMonths),
        lockAfterExpiration: data.lockAfterExpiration,
        userLimitation: data.userLimitation,
        fockinkId: data.fockinkId,
        cloudToDevice: data.cloudToDevice,
        status: data.status,
      };
      FockinkAPI.post('/plan', dt)
        .then(() => {
          toast.success('Dados salvos com sucesso');
          history.push('/settings/plans');
        })
        .catch(async (error) => {
          const { response } = error;

          if (response.status === 400) {
            toast.error(response.data.validation?.body.message || response.data.message);
          }
        });
    } else if (data.status === 'I') {
      confDelete();
    } else {
      const dt = {
        planName: data.planName,
        systems: data.systems,
        invoiceFrequency: data.invoiceFrequency,
        invoiceDay: parseFloatCustom(data?.invoiceDay),
        validMonths: parseFloatCustom(data?.validMonths),
        lockAfterExpiration: data.lockAfterExpiration,
        userLimitation: data.userLimitation,
        fockinkId: data.fockinkId,
        cloudToDevice: data.cloudToDevice,
        status: data.status,
      };
      FockinkAPI.put(`/plan/${props.match.params.id}`, dt).then(() => {
        toast.success('Dados salvos com sucesso');
        history.push('/settings/plans');
      })
        .catch(async (error) => {
          const { response } = error;

          if (response.status === 400) {
            toast.error(response.data.validation.body.message);
          } else {
            toast.error(response.data.message);
          }
        });
    }
  };

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });

    if (data.status === 'I') {
      // confDelete();
    }
  };

  return (
    <>
      <Breadcrumbs
        routes={breadcrumbs.routes}
        title={breadcrumbs.title}
      />
      {
    loading ? (
      <div className={classes.root}>
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
        <Skeleton />
        <Skeleton animation={false} />
        <Skeleton animation="wave" />
      </div>
    )
      :

      (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <TextField
              variant="filled"
              name="planName"
              value={data?.planName || ''}
              onChange={handleChange}
              label="Nome"
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <FormControl variant="filled">
              <InputLabel id="Systems">Sistemas</InputLabel>

              <Select
                labelId="systems"
                multiple
                id="systems"
                name="systems"
                value={data?.systems || []}
                label="systems"
                onChange={handleChange}
                renderValue={(selected) =>
                  selected?.map((s) => systemTypesNumeric[s - 1]?.text).join(', ')
                }
              >

                {systemTypesNumeric.map(({ id, text }) => (
                  <MenuItem key={id} value={id}>
                    <Checkbox checked={(data?.systems || []).indexOf(id) > -1} />
                    <ListItemText primary={text} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              variant="filled"
              name="validMonths"
              value={data?.validMonths || ''}
              onChange={handleChange}
              label="Período de Validade"
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <FormControl variant="filled">
              <InputLabel id="plan">Frequência</InputLabel>
              <Select
                labelId="invoiceFrequency"
                id="invoiceFrequency"
                name="invoiceFrequency"
                value={data?.invoiceFrequency || ''}
                label="invoiceFrequency"
                onChange={handleChange}
              >
                <MenuItem value="eachMonth">Mensal</MenuItem>
                <MenuItem value="each2Months">Bimestral</MenuItem>
                <MenuItem value="each3Months">Trimestral</MenuItem>
                <MenuItem value="each4Months">Quadrimestral</MenuItem>
                <MenuItem value="each6Months">Semestral</MenuItem>
                <MenuItem value="each12Months">Anual</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextField
              variant="filled"
              name="invoiceDay"
              value={data?.invoiceDay || ''}
              onChange={handleChange}
              label="Dia de Fatura"
            />
          </Grid>

          <Grid item lg={3} xs={12}>
            <FormControl variant="filled">
              <InputLabel id="lockAfterExpiration">Bloquear em caso de Expiração</InputLabel>
              <Select
                labelId="lockAfterExpiration"
                id="lockAfterExpiration"
                name="lockAfterExpiration"
                value={data?.lockAfterExpiration}
                label="lockAfterExpiration"
                onChange={handleChange}
              >
                <MenuItem value>Sim</MenuItem>
                <MenuItem value={false}>Não</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item lg={3} xs={12}>
            <FormControl variant="filled">
              <InputLabel id="userLimitation">Limitar cadastro de Usuários</InputLabel>
              <Select
                labelId="userLimitation"
                id="userLimitation"
                name="userLimitation"
                value={data?.userLimitation}
                label="userLimitation"
                onChange={handleChange}
              >
                <MenuItem value>Ativo</MenuItem>
                <MenuItem value={false}>Inativo</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} lg={3}>
            <TextField
              variant="filled"
              name="fockinkId"
              value={data?.fockinkId || ''}
              onChange={handleChange}
              label="Código do Item"
            />
          </Grid>
          <Grid item lg={3} xs={12}>
            <FormControl variant="filled">
              <InputLabel id="cloudToDevice">Habilitar Operação</InputLabel>
              <Select
                labelId="cloudToDevice"
                id="cloudToDevice"
                name="cloudToDevice"
                value={data?.cloudToDevice}
                label="cloudToDevice"
                onChange={handleChange}
              >
                <MenuItem value>Ativo</MenuItem>
                <MenuItem value={false}>Inativo</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} xs={12}>
            <FormControl variant="filled">
              <InputLabel id="status">Status</InputLabel>
              <Select
                labelId="status"
                id="status"
                name="status"
                value={data?.status || ''}
                label="status"
                onChange={handleChange}
              >
                <MenuItem value="A">Ativo</MenuItem>
                <MenuItem value="I">Inativo</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            className={`${classes.textAlign} ${classes.bottomButtons}`}
          >
            <BottomButtons saveData={saveData} backTo={back.to} />
          </Grid>
        </Grid>
      )
      }
    </>
  );
};

export default PlansForm;
