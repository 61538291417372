import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  wrapper: {
    minHeight: 'calc(100vh - 205px)',
  },
  divScroll: {
    height: '100%',
    overflow: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  noDataToView: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    '& strong': {
      display: 'block',
      fontSize: '1.4rem',
    },
    '& span': {
      fontSize: '1rem',
    },
  },
  loadingContainer: {
    margin: '0 auto',
    textAlign: 'center',
  },
  legendSpan: {
    marginTop: '10px',
    fontSize: '0.9rem',
    textAlign: 'center',
    display: 'block',
    alignSelf: 'center',
  },
  btnBttn: {
    textAlign: 'right',
    marginTop: '15px',
  },
  basicCard: {
    background: '#FFF',
    marginTop: '10px',
    borderRadius: '0.2rem',
  },
}));
