import React from 'react';
import '../../../../App.css';
import { Redirect, useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import EditIcon from '@material-ui/icons/Edit';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Storage from '@material-ui/icons/Storage';
import AddIcon from '@material-ui/icons/Add';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import FockinkAPI from '../../../../middlewares/Api';
import Plants from './plants/Plants';
import { formatDate } from '../../../../helpers/Intl';
import AdminPermissions from '../../../../helpers/enums/AdminPermissions';
import { hasPermission } from '../../../../helpers/permission';
import DeleteTrashButton from '../../../../components/Buttons/DeleteTrashButton';

export default (props) => {
  const [inputs, setInputs] = React.useState([]);
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const ordData = JSON.parse(localStorage.getItem('ordAccountsOrganization'));
  const pageData = JSON.parse(localStorage.getItem('pageAccountsOrganization'));
  const rowsData = JSON.parse(localStorage.getItem('rowsAccountsOrganization'));
  const [search, setSearch] = React.useState({});
  const history = useHistory();

  // methods
  const setPage = (pageVal) => {
    const dataSort = {
      page: pageVal,
    };
    localStorage.setItem('pageAccountsOrganization', JSON.stringify(dataSort));
  };
  const setOrdenation = (ordenationVal, directionVal) => {
    const dataSort = {
      ordenation: ordenationVal,
      direction: directionVal,
    };
    localStorage.setItem('ordAccountsOrganization', JSON.stringify(dataSort));
  };
  const setRowsPage = (rowsVal) => {
    const dataSort = {
      rows: rowsVal,
    };
    localStorage.setItem('rowsAccountsOrganization', JSON.stringify(dataSort));
  };
  React.useEffect(() => {
    setLoading(true);
    FockinkAPI.get('/accounts', {
      params: { organizationId: props.organizationId },
    }).then((response) => {
      setInputs(response.data.data);
      setLoading(false);
    });
  }, []);

  const ExpandedComponent = ({ data }) => (
    <div style={{ padding: '15px', background: 'white' }}>
      <Plants
        accountId={data.id}
        organizationId={props.organizationId}
        search={search}
        setSearch={setSearch}
      />
    </div>
  );

  const expandableRowExpanded = (data) => search.accountId === data.id;

  const onRowExpandToggled = (open, data) => {
    if (open) {
      setSearch({
        accountId: data.id,
        plantId: undefined,
        equipmentId: undefined,
      });
    }
    return true;
  };

  React.useEffect(() => {
    const params = new URLSearchParams();
    ['accountId', 'plantId', 'equipmentId'].forEach((e) => {
      if (search[e]) {
        params.append(e, search[e]);
      } else {
        params.delete(e);
      }
    });
    history.push({ search: params.toString() });
  }, [search, history]);

  React.useEffect(() => {
    if (props.location) {
      const query = new URLSearchParams(props.location.search);
      const accountId = query.get('accountId');
      const plantId = query.get('plantId');
      const equipmentId = query.get('equipmentId');
      if (
        search.accountId !== accountId &&
        search.plantId !== plantId &&
        search.equipmentId !== equipmentId
      ) {
        setSearch({ accountId, plantId, equipmentId });
      }
    }
  }, [props.location.search]);

  React.useEffect(() => {
    const columns = [
      { name: 'Nome', selector: 'description', sortable: true },
      {
        name: 'Plano',
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            {row.plan.description}
          </div>
        ),
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            {new Date(row.expirationDate * 1000) < Date.now() ? 'Expirado' : row.status === 'A' ? 'Ativo' : 'Inativo'}
          </div>
        ),
      },
      { name: 'Limite de Usuários', selector: 'userLimit' },
      {
        name: 'Ativação',
        center: true,
        sortable: true,
        selector: 'activationDate',
        cell: (row) => row.activationDate ? formatDate(new Date(row.activationDate * 1000), 'dd/MM/yyyy') : 'Não Ativado',
      },
      {
        name: 'Expiração',
        center: true,
        cell: (row) => row.expirationDate ? formatDate(new Date(row.expirationDate * 1000), 'dd/MM/yyyy') : 'Não Expirado',
      },
    ];
    if (hasPermission(AdminPermissions.USERS_VIEW.value)) {
      columns.push({
        name: 'Usuários',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              color="secondary"
              onClick={() =>
                setRedirect(`/organization/${props.organizationId}/account/${row.id}/user`)
              }
            >
              <SupervisorAccountIcon />
            </IconButton>
          </div>
        ),
      });
    }
    columns.push({
      name: 'Ações',
      center: true,
      grow: 2,
      cell: (row) => (
        <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
          {hasPermission(AdminPermissions.ACCOUNTS_VIEW.value) && (
            <>
              <Tooltip title="Ver Conta">
                <IconButton
                  color="secondary"
                  onClick={() => setRedirect(`${props.organizationId}/account/${row.id}/view`)}
                >
                  <VisibilityIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Visualizar Equipamentos Ids ">
                <IconButton
                  color="secondary"
                  onClick={() => setRedirect(`/ids-configurations/${row.id}`)}
                >
                  <Storage />
                </IconButton>
              </Tooltip>
            </>
          )}
          {hasPermission(AdminPermissions.ACCOUNTS_MANAGE.value) && (
            <>
              <Tooltip title="Editar Conta">
                <IconButton
                  color="secondary"
                  onClick={() => setRedirect(`${props.organizationId}/account/${row.id}`)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Apagar Conta">
                <DeleteTrashButton url={`/accounts/${row.id}`} />
              </Tooltip>
            </>
          )}
          {hasPermission(AdminPermissions.PLANTS_MANAGE.value) && (
            <Tooltip title="Nova Planta">
              <IconButton
                color="secondary"
                onClick={() => setRedirect(`${props.organizationId}/account/${row.id}/plant/new`)}
              >
                <AccountTreeIcon />
                <AddIcon style={{ position: 'absolute', left: '5px', bottom: '4px' }} />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
    });
    setDtCl({ columns, data: inputs });
  }, [inputs]);

  if (redirect) return <Redirect push to={redirect} />;

  if (loading) {
    return <Skeleton variant="rect" height={500} width="100%" animation="wave" />;
  }
  return (
    <DataTable
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Linhas:',
        rangeSeparatorText: 'de',
      }}
      paginationDefaultPage={parseInt(pageData?.page || 1, 10)}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
      noDataComponent="Não existem dados para serem mostrados"
      expandableRows
      columns={dtCl.columns}
      data={dtCl.data}
      title=""
      onChangePage={(e) => setPage(e)}
      onSort={(e, x) => setOrdenation(e.selector, x)}
      onChangeRowsPerPage={(e) => setRowsPage(e)}
      expandableRowsComponent={<ExpandedComponent />}
      expandableRowExpanded={expandableRowExpanded}
      onRowExpandToggled={onRowExpandToggled}
      defaultSortField={ordData?.ordenation}
      defaultSortAsc={ordData?.direction !== 'desc'}
      paginationPerPage={rowsData?.rows || 10}
    />
  );
};
