/* eslint-disable no-nested-ternary */
import React from 'react';
import { Redirect } from 'react-router-dom';
// import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// const useStyles = makeStyles(() => ({
//   foot: {
//     borderTop: '2px solid #cecece',
//     // marginTop: '8px',
//     position: 'fixed',
//     bottom: '0px',
//     left: '0px',
//     right: '0px',
//   },
//   h2: {
//     border: '0',
//     textAlign: 'right',
//     fontSize: '15px',
//     fontWeight: '500',
//     margin: '5px',
//     float: 'right',
//   },
//   developer: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'flex-end',
//   },
// }));

export default () => {
  const [goSettings, setGoSettings] = React.useState(false);
  // const classes = useStyles();

  React.useEffect(() => {
    setGoSettings(false);
  }, [goSettings]);

  if (goSettings) {
    return <Redirect push to={`${window.location.pathname}/settings`} />;
  }

  return (
    <div className="footer">
      <div className="footer-container">
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={4} md={6} lg={3}>
            <span className="version">Versão 1.1.0</span>
          </Grid>
          <Grid item xs={8} md={6} lg={3}>
            <div className="developer">
              <span>Desenvolvido por</span>
              <img
                src="/images/fockinkLogo.svg"
                alt=""
                className="developerLogo"
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
