import React from 'react';
import { Redirect } from 'react-router-dom';
import { Header } from './components';
import { useAuth } from '../../hooks/account';

import * as S from './styles';

const ClientDashboard = React.lazy(async () => import('./ClientDashboard'));

export function Home() {
  const { user } = useAuth();

  if (!user) {
    return <Redirect to="/logout" />;
  }

  return (
    <S.Wrapper>
      <Header />
      <S.Content>
        {/* <S.Resume> */}
        <React.Suspense fallback={<div>Loading...</div>}><ClientDashboard /></React.Suspense>
        {/* </S.Resume> */}
      </S.Content>
    </S.Wrapper>
  );
}
