import React from 'react';
import { IoMdArrowDropright } from 'react-icons/all';

export const columns = [
  {
    name: 'Cor de identificação',
    selector: 'color',
    center: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <IoMdArrowDropright size={32} color={row.color} />
      </div>
    ),
  },
  {
    name: 'Cultura',
    selector: 'culture',
  },
  {
    name: 'Status',
    selector: 'status',
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.status === 'A' ? 'Ativo' : 'Inativo'}
      </div>
    ),
  },
];
