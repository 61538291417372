/* eslint-disable no-mixed-operators */
import React from 'react';

export default (props) => {
  const [hour, setHour] = React.useState([]);

  const calcInside = (st, nd) => {
    if (st > nd) {
      return st - nd;
    }
    return 360 - nd + st;
  };

  const calcAngle = (h) => {
    if (typeof h === 'string' && h.indexOf(':') !== -1) {
      const spl = h.split(':');
      return (parseInt(spl[0], 10) + (parseInt(spl[1] || 0, 10) / 60)) / 24 * 360;
    }
    return parseInt(h, 10) / 24 * 360;
  };

  React.useEffect(() => {
    setHour([...Array(24)].map((e, i) => ({
      x: 7 * Math.sin(calcAngle(i) * 0.0174532925) + 10,
      y: -7 * Math.cos(calcAngle(i) * 0.0174532925) + 10.4,
    })));
  }, [props]);

  return (
    <svg width="100%" viewBox="0 0 20 20" style={{ fill: 'transparent', maxHeight: '320px' }}>

      <circle
        id="circle"
        fill="white"
        cx="10"
        cy="10"
        r="8"
      />

      {(props.timeRanges || []).map((r) => {
        const to = calcAngle(r.to);
        const from = calcAngle(r.from);
        return (
          <circle
            key={r.from}
            r="5.2"
            cx="10"
            cy="10"
            fill="transparent"
            stroke={r.color}
            strokeWidth="1.3"
            strokeDasharray={`${calcInside(to, from) / 3.6 * (2 * Math.PI * 5.2) / 100}px ${2 * Math.PI * 5.2}px`}
            transform={`rotate(${-90 + from}, 10, 10)`}
          />
        );
      })}

      {hour.map((h, i) => (
        <text
          x={h.x}
          y={h.y}
          key={h.x}
          textAnchor="middle"
          fill="#3D5059"
          style={{
            fontSize: i % 3 === 0 ? '1.2px' : '1px', letterSpacing: '0px', fontWeight: i % 3 === 0 ? '500' : '300',
          }}
        >
          {i || '24'}
        </text>
      ))}

    </svg>
  );
};
