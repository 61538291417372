import { useFormik } from 'formik';
import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import * as S from './styles';
import {
  Input,
  CardOption,
  ButtonComponent as Button,
} from '../../../../../../components';
import { systemTypes, AvailablesTypes } from '../../utils';
import { validationSchema } from './validationSchema';
import FockinkAPI from '../../../../../../middlewares/Api';

export function UpdateTelemetrySettingForm({
  handleGetTelemetry,
  telemetry,
  handleCloseUpdateTelemetryModal,
}) {
  const [success, setSuccess] = React.useState(false);
  const [errorRequest, setErrorRequest] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      friendlyName: telemetry.friendlyName,
      variablePattern: telemetry.variablePattern,
      system: telemetry.system,
      operationId: telemetry.operationId,
      trackingAvailable: telemetry.trackingAvailable,
      alarmAvailable: telemetry.alarmAvailable,
      type: telemetry.type,
      thresholdMin: telemetry.thresholdMin,
      thresholdMax: telemetry.thresholdMax,
      status: telemetry.status,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        await FockinkAPI.put(`/telemetryVariables/${telemetry.id}`, values);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 4000);
        handleGetTelemetry();
        formik.resetForm();
        handleCloseUpdateTelemetryModal();
      } catch (error) {
        setErrorRequest(true);
        setTimeout(() => {
          setErrorRequest(false);
        }, 3000);
      }
    },
  });

  const handleChangeTrackinkValue = () => {
    formik.setValues({
      ...formik.values,
      trackingAvailable: !formik.values.trackingAvailable,
    });
  };

  const handleChangeAlarmAvailableValue = () => {
    formik.setValues({
      ...formik.values,
      alarmAvailable: !formik.values.alarmAvailable,
    });
  };

  return (
    <S.Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <span>Preencha o formulário para atualizar telemetria</span>

        <Input
          error={formik.errors.friendlyName}
          name="friendlyName"
          label="Nome amigável"
          onChange={formik.handleChange}
          variant="filled"
          rows={3}
          multiline
          value={formik.values.friendlyName}
        />

        <Input
          error={formik.errors.variablePattern}
          name="variablePattern"
          label="Padrão variável"
          onChange={formik.handleChange}
          variant="filled"
          value={formik.values.variablePattern}
        />

        <Input
          error={formik.errors.operationId}
          type="number"
          name="operationId"
          label="ID de operação"
          onChange={formik.handleChange}
          variant="filled"
          value={formik.values.operationId}
        />

        <div className="row">
          <FormControl variant="filled">
            <InputLabel>Sistema</InputLabel>
            <Select
              name="system"
              value={formik.values.system}
              onChange={formik.handleChange}
              label="system"
            >
              {systemTypes.map((system) => (
                <MenuItem key={system} value={system}>
                  {system}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.errors.system}</FormHelperText>
          </FormControl>

          <FormControl style={{ marginLeft: '0.5rem' }} variant="filled">
            <InputLabel>Tipo</InputLabel>
            <Select
              name="type"
              value={formik.values.type}
              onChange={formik.handleChange}
              label="system"
            >
              {AvailablesTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.errors.type}</FormHelperText>
          </FormControl>
        </div>

        <div className="row">
          <Input
            error={formik.errors.thresholdMin}
            type="number"
            name="thresholdMin"
            label="Threshold Mínimo"
            onChange={formik.handleChange}
            variant="filled"
            value={formik.values.thresholdMin}
          />
          <Input
            error={formik.errors.thresholdMax}
            style={{ marginLeft: '0.5rem' }}
            type="number"
            name="thresholdMax"
            label="Threshold máximo"
            onChange={formik.handleChange}
            variant="filled"
            value={formik.values.thresholdMax}
          />
        </div>

        <div className="row">
          <CardOption
            checked={formik.values.trackingAvailable}
            onClick={handleChangeTrackinkValue}
          >
            Elegível a tracking
          </CardOption>

          <CardOption
            checked={formik.values.alarmAvailable}
            onClick={handleChangeAlarmAvailableValue}
          >
            Elegível a alarme
          </CardOption>
        </div>

        <div className="confirm-button">
          {success ? (
            <strong className="success">Sucesso!</strong>
          ) : errorRequest ? (
            <strong className="error">Erro ao atualizar configuração</strong>
          ) : (
            <Button type="submit">atualizar</Button>
          )}
        </div>
      </form>
    </S.Wrapper>
  );
}
