import React from 'react';
import Chart from 'react-apexcharts';
import Grid from '@material-ui/core/Grid';
import { number } from '../../../../../helpers/Intl';
import GradualThermometryGraphic from '../../../../../components/GradualThermometryGraphic';

export default function GraphThermometry({
  chartData,
  equipamentId,
  date,
  setSensorData,
  searchData,
}) {
  const { unitData, qualityColorRanges, gradualColorRanges, colors } = chartData;
  const { sub, grain, arcs } = unitData;

  const [dataGraph, setDataGraph] = React.useState({
    series: [],
    categories: [],
    colors: [],
  });

  React.useEffect(() => {
    const series = [];
    const categories = [];

    let level = 0;
    let maxLevel = 1;
    let isEmpty = true;
    const cat = {};
    const weather = {
      temp: undefined,
      humid: undefined,
      rain: undefined,
    };

    if (chartData) {
      if (searchData.format === 'level') {
        while (level < maxLevel) {
          // eslint-disable-next-line no-loop-func
          arcs.forEach((arch) => {
            arch.cabs.forEach((cab) => {
              if (cab.lev > 0) {
                isEmpty = false;
              }
              if (!cat[level + 1]) {
                cat[level + 1] = [];
              }
              if (categories.indexOf(`A${arch.arc} \\ C${cab.num}`) === -1) {
                categories.push(`A${arch.arc} \\ C${cab.num}`);
              }
              cat[level + 1].push({
                data: cab.sens[level],
              });
              maxLevel =
                maxLevel >= cab.sens.length ? maxLevel : cab.sens.length;
            });
          });
          level += 1;
        }

        Object.keys(cat).forEach((k) => {
          series.push({
            name: k,
            data: cat[k].map((m) => {
              if (m.data && searchData.tempNoGrain) {
                return m.data.t;
              }
              if (m.data && !m.data.out) {
                return m.data.t;
              }
              return -9999999;
            }),
          });
        });
      } else {
        arcs.forEach((arch) => {
          arch.cabs.forEach((cab) => {
            if (cab.lev > 0) {
              isEmpty = false;
            }
            maxLevel = maxLevel >= cab.sens.length ? maxLevel : cab.sens.length;
            series.push({
              name: `A${arch.arc} \\ C${cab.num}`,
              data: cab.sens.map((e) => {
                if (e && searchData.tempNoGrain) {
                  return e.t;
                }
                if (e && !e.out) {
                  return e.t;
                }
                return -9999999;
              }),
            });
          });
        });
        categories.push(
          ...Array(maxLevel)
            .fill(0)
            .map((e, i) => i + 1),
        );
        series.reverse();
      }

      setDataGraph({
        series,
        categories,
        colors: searchData.color === 'quality' ? qualityColorRanges : gradualColorRanges,
        yLabel: searchData.format === 'level' ? 'Sensor' : '',
        isEmpty,
        tempNoGrain: searchData.tempNoGrain,
        weather,
      });
    }
  }, [
    unitData,
    searchData,
  ]);

  const options = {
    toolbar: {
      show: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      position: 'top',
      categories: dataGraph.categories,
    },
    chart: {
      height: (dataGraph.series.length || 1) * 30 + 60,
      toolbar: {
        show: false,
      },
      grid: {
        show: false,
      },
      events: {
        click(_event, _chartContext, config) {
          if (config.seriesIndex > -1) {
            let regToCheck;
            let sensor;
            if (dataGraph.yLabel === 'Sensor') {
              regToCheck = dataGraph.categories[config.dataPointIndex];
              sensor = config.seriesIndex;
            } else {
              regToCheck = dataGraph.series[config.seriesIndex].name;
              sensor = config.dataPointIndex;
            }
            const r = regToCheck.match(/.(\d+).{4}(\d+)/i);
            setSensorData({
              equipamentId,
              sub,
              arc: r[1],
              cab: r[2],
              date,
              sen: sensor + 1,
            });
          }
        },
      },
    },
    dataLabels: {
      enabled: dataGraph.categories.length < 60,
      formatter: (val, opts) => {
        if (val === 999) {
          return 'OFF';
        }
        if (val === 998) {
          return 'N/D';
        }
        if (val === 997) {
          return 'ERR';
        }
        if (val === -9999999) {
          return ' ';
        }
        return number(
          dataGraph.series[opts.seriesIndex].data[opts.dataPointIndex] === -9999999
            ? val
            : dataGraph.series[opts.seriesIndex].data[opts.dataPointIndex],
        );
      },
      style: {
        colors: ['#000'],
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '155%',
      },

      heatmap: {
        radius: 0,
        enableShades: false,
        shadeIntensity: 0,
        useFillColorAsStroke: true,
        // instensidade da cor em escala
        colorScale: {
          ranges: [
            ...dataGraph.colors,
            {
              from: -9999999,
              to: -9999999,
              name: 'OUT',
              color: '#FAFAFAFA',
            },
            // {
            //   from: 0,
            //   to: 0,
            //   color: '#CECECE',
            // },
            {
              from: 997,
              to: 997,
              name: 'ERR',
              color: '#e3b3e3',
            },
            {
              from: 998,
              to: 998,
              name: 'N/D',
              color: '#e3b3e3',
            },
            {
              from: 999,
              to: 999,
              name: 'OFF',
              color: '#d1b4d1',
            },
          ],
        },
      },
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 575,
        options: {
          dataLabels: {
            enabled: false,
          },
        },
      },
    ],
    yaxis: {
      show: true,
      axisTicks: {
        show: true,
        width: 20,
      },
      labels: {
        show: true,
        align: 'right',
      },
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (seriesName) => {
          if (seriesName === 999) {
            return 'Sensor desconectado ou com defeito';
          }
          if (seriesName === 998) {
            return 'Não disponível';
          }
          if (seriesName === 997) {
            return 'Erro ao realizar download da leitura';
          }
          return seriesName === -9999999 ? '-' : `${number(seriesName)}°C`;
        },
      },
    },
  };

  const gridRef = React.useRef();
  return (
    <Grid container spacing={1}>

      <div
        style={{
          width: '100%',
          marginTop: '12px',
          alignItems: 'flex-start',
          fontSize: '16px',
        }}
      >
        <span>
          Grão configurado na subdivisão
          <strong>
            {` ${sub} `}
          </strong>
          no momento da aferição:
          <strong>
            {` ${grain}`}
          </strong>
        </span>
      </div>

      <GradualThermometryGraphic
        type={searchData.color}
        data={colors}
        formatData
      />
      <Grid item xs={12} ref={gridRef}>
        <Chart
          options={options}
          series={dataGraph.series}
          type="heatmap"
          height={(dataGraph.series.length || 1) * 30 + 60}
        />
      </Grid>
    </Grid>
  );
}
