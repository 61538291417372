import React from 'react';

const colorStateEngine = {
  off1: '#CCCCCC',
  off2: '#ACABAB',
  on1: '#D6E0E6',
  on2: '#556E7B',
};

function Propeller({ onOff }) {
  const color = onOff === 'L' ? colorStateEngine.on2 : colorStateEngine.off2;
  return (
    <path
      d="M 66.07 53.407 L 63.823 41.124 C 72.569 37.734 81.995 37.734 90.288 41.194 C 87.925 30.358 81.302 21.108 72.259 15.301 C 64.006 18.704 57.273 25.391 53.457 33.871 L 41.088 36.021 C 37.848 27.358 37.901 17.918 41.32 9.715 C 30.381 12.068 21.055 18.761 15.236 27.904 C 18.652 36.091 25.302 42.767 33.722 46.567 L 35.945 58.884 C 27.299 62.097 17.89 62.027 9.71 58.607 C 12.033 69.543 18.687 78.88 27.793 84.726 C 36.026 81.33 42.748 74.667 46.568 66.213 L 58.997 63.81 C 62.311 72.52 62.284 82.03 58.851 90.286 C 69.687 87.923 78.933 81.296 84.736 72.25 C 81.319 63.967 74.593 57.217 66.07 53.407 Z"
      id="PropellerMotor"
      style={{ fill: color }}
      transform="matrix(-0.95955, -0.28154, 0.28154, -0.95955, 83.898397, 112.055162)"
    />
  );
}

function EngineAnimated() {
  return (
    <g
      id="PropellerMotor_rJJpYhYvM"
      data-animator-group="true"
      data-animator-type="1"
      transform="matrix(-0.95955, -0.28154, 0.28154, -0.95955, 83.898397, 112.055162)"
    >
      <Propeller onOff="L" />
    </g>
  );
}

export default function SvgEngine({ onOff }) {
  const color = onOff === 'L' ? colorStateEngine.on1 : colorStateEngine.off1;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="S1NZ3tnYwf"
      // x="0px"
      // y="0px"
      width={`${100}px`}
      height={`${100}px`}
    >
      <circle
        cx="25"
        cy="25"
        r="14.927"
        id="CircleBackMotor"
        style={{ fill: color }}
        transform="matrix(3.333132, 0, 0, 3.333803, -33.572929, -33.58202)"
      />
      { onOff === 'L' ?
        <EngineAnimated />
        :
        <Propeller onOff="D" />
            }
      <circle
        cx="-255.998"
        cy="-255.998"
        r="5"
        id="BkF-3F2Kvf"
        style={{ fill: color }}
        transform="matrix(-0.242704, -0.9701, 0.969905, -0.242754, 236.410254, -260.723736)"
      />
    </svg>
  );
}
