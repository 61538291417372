import React from 'react';
import * as S from './styles';

export function CardCannons({ cannon }) {
  return (
    <S.Wrapper>
      <div>
        <strong>Início</strong>
        <span>{`${cannon.s}º`}</span>
      </div>
      <div>
        <strong>Fim</strong>
        <span>{`${cannon.e}º`}</span>
      </div>
    </S.Wrapper>
  );
}
