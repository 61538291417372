import DateFnsUtils from '@date-io/date-fns';
import { FormControl, FormHelperText } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React from 'react';
import { ptBR } from 'date-fns/esm/locale';

export function DatePicker({ selectedDate,
  format,
  handleDateChange, label, error, style, name, disabled }) {
  return (
    <FormControl error={!!error}>
      <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
        <KeyboardDatePicker
          disabled={disabled}
          margin="normal"
          variant="dialog"
          style={{ ...style }}
          error={!!error}
          label={label || 'Selecione uma data'}
          format={format || 'dd/MM/yyyy'}
          value={selectedDate}
          name={name}
          onChange={(e) => handleDateChange(e)}
        />
      </MuiPickersUtilsProvider>
      <FormHelperText style={{ color: '#f44336' }}>{error}</FormHelperText>
    </FormControl>
  );
}
