import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import IconButton from '@material-ui/core/IconButton';
import FockinkAPI from '../../middlewares/Api';

export default (props) => {
  const MySwal = withReactContent(Swal);
  const [disabled, setDisabled] = React.useState(false);

  const callApi = () => {
    setDisabled(true);
    FockinkAPI.delete(props.url).then(() => {
      setDisabled(false);
      window.location.reload();
    }).catch((e) => {
      setDisabled(false);
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: e.response.data.reason || e.response.data.message,
      });
    });
  };

  const confDelete = () => {
    MySwal.fire({
      title: 'Tem certeza?',
      text: 'Essa ação não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, APAGAR!',
      cancelButtonText: 'Não, MANTER',
    }).then((result) => {
      if (result.isConfirmed) {
        callApi();
      }
    });
  };

  return (
    <IconButton
      disabled={disabled}
      color="secondary"
      onClick={() => confDelete()}
    >
      {disabled ? <CircularProgress size={26} /> : <DeleteForeverIcon />}
    </IconButton>
  );
};
