/* eslint-disable react-hooks/exhaustive-deps */
// Basic
import React from 'react';

// UI, images, styles and icons
import '../../../../App.css';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './IrrigationCarousel.css';
import Button from '@material-ui/core/Button';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import RecipeCard from './Components/RecipeCard';
import Card from '../../../../components/Card';
import CardText from '../../../../components/CardText';
import CardTitle from '../../../../components/CardTitle';
import colors from '../../../../styles/colors';
import Spacer from '../../../../components/Spacer';
// import PivotSVG from '../../../../components/PivotSVG';
import PivoSVG from '../../../../components/PivotSVG/index';
// import PivoSVG from '../../../../components/PivotSVG/index';

const useStyles = makeStyles({
  card: {
    padding: '15px',
  },
  header: {
    marginBottom: '20px',
  },
  routeHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    margin: 'auto',
    marginTop: '0',
    color: '#3D5059',
    // fontWeight: '400',
  },
  routeLabel: {
    fontSize: '20px',
  },
  smallMonth: {
    color: '#727272',
  },
  routeLabelRight: {
    textAlign: 'right',
    width: '100%',
    fontSize: '20px',
  },
  slider: {
    display: 'flex',
  },
  sliderButtonLeft: {
    height: 'fit-content',
    margin: 'auto',
    borderRadius: '5px',
  },
  sliderButtonRight: {
    height: 'fit-content',
    margin: 'auto',
    borderRadius: '5px',
  },
  deviceName: {
    fontSize: '15px',
    fontWeight: 'normal',
    width: '100%',
    textAlign: 'center',
    margin: 0,
    padding: '5px 0',
  },
});
// function getDirectionStatus(dir) {
//   switch (dir) {
//     case 0:
//       return 'Desligado';
//     case 1:
//       return 'Rotação a Direita';
//     case 2:
//       return 'Rotação a Esquerda';

//     default:
//       return 'NDef';
//   }
// }
export default (props) => {
  const [rows, setRows] = React.useState([]);
  let row = 0;
  let limit = 0;
  const [playing, setPlaying] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentRecipeId, setCurrentRecipeId] = React.useState('');
  const [pivotRecipe, setPivotRecipe] = React.useState({
    all: {
      cannon: [],
      finalPosition: 0,
      positionActual: 0,
      stDirectionRotationLeft: 0,
      stDirectionRotationRight: 0,
      stOnOff: 0,
      startingPosition: 0,
    },
  });

  // const columns = [
  //   {
  //     field: 'direction',
  //     headerName: 'Direção',
  //     description: 'Direção em que o pivô está se movendo.',
  //     sortable: true,
  //     width: 190,
  //   },
  //   {
  //     field: 'dateStart',
  //     headerName: 'Data de início',
  //     description: 'Data programada para iniciar a execução deste programa.',
  //     sortable: true,
  //     width: 190,
  //   },
  //   {
  //     field: 'dateFinish',
  //     headerName: 'Data de término',
  //     description: 'Data estimada para a finalização deste programa.',
  //     sortable: true,
  //     width: 190,
  //   },
  //   {
  //     field: 'water',
  //     headerName: "Lâmina d'agua",
  //     description: "Funcionamento com lâmina d'agua ou sem.",
  //     sortable: true,
  //     width: 190,
  //   },
  //   {
  //     field: 'startingPosition',
  //     headerName: 'Posição inicial',
  //     description: 'Posição inicial do pivô',
  //     sortable: true,
  //     width: 190,
  //   },
  //   {
  //     field: 'finalPosition',
  //     headerName: 'Posição final',
  //     description: 'Posição final do pivô',
  //     sortable: true,
  //     width: 190,
  //   },
  //   {
  //     field: 'recipeLoop',
  //     headerName: 'Recomeçar ativo',
  //     description: 'Repetir o programa ao finalizar',
  //     sortable: true,
  //     width: 190,
  //   },
  //   {
  //     field: 'stHP',
  //     headerName: 'Horário de Ponta',
  //     description: 'Programação de horário de ponta.',
  //     sortable: true,
  //     width: 190,
  //   },
  //   // {
  //   //   field: 'cardData',
  //   //   headerName: `Alarmes`,
  //   //   description: `Alertas deste pivô`,
  //   //   sortable: true,
  //   //   width: '100%',
  //   //   valueGetter: (params) =>
  //   //     `${params.recipeLoop ? 'Sim' : 'Não'}`,
  //   // },
  // ];

  const classes = useStyles();
  // const [recipes, setRecipes] = React.useState([]);
  // const [loading, setLoading] = React.useState(false);

  // const cardsQtd = () => {
  //   const width = document.documentElement.clientWidth;
  //   if (width < 1280) {
  //     return 2;
  //   }
  //   if (width >= 1280 && width < 1920) {
  //     return 4;
  //   }
  //   return 5;
  // };
  const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
  const getWeekDay = (day) => weekDays[new Date(`${day}T20:00`).getDay()];
  // const calcIndex = () => {
  //   const inx = props.recipes.findIndex((f) => f.metadata.current);
  //   return inx - cardsQtd() < 1 ? 0 : inx - cardsQtd() + 1;
  // };
  const delayTime = 1500;
  const months = [
    '???',
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];
  const getMonth = (plus) => {
    const month =
      (parseInt(props.selectedRecipeDate.substring(5, 7), 10) + plus) % 12;
    return months[month === 0 ? 12 : month];
  };

  function animateRecipes() {
    setPlaying(true);
    row = rows.length - 1;
    limit = 0;
    startAnimation();
  }

  function startAnimation() {
    if (row >= limit) {
      setCurrentActive(rows[row]);
      document.getElementById(`#${rows[row].id}`).scrollIntoView();
      setTimeout(() => {
        row -= 1;
        startAnimation();
      }, delayTime);
    } else {
      setCurrentActive(rows[0]);
      setPlaying(false);
      document.getElementById(`#${rows[0].id}`).scrollIntoView();
    }
  }

  function setCurrentActive(p) {
    setPivotRecipe(p);
    setCurrentRecipeId(p.id);
  }
  React.useEffect(() => {
    setLoading(true);
    const arr = props.recipes;
    const nArr = [];
    arr.forEach((recipe) => {
      const cardData = { ...recipe.metadata, ...recipe.telemetry };
      const recipeData = {
        direction: cardData.criteria.direction, // getDirectionStatus(),
        dateStart: {
          weekDay: getWeekDay(cardData.dtStart),
          day: cardData.dtStart.substring(8, 10),
          time: cardData.hrStart,
        },
        dateFinish: {
          weekDay: getWeekDay(cardData.dtFinish),
          day: cardData.dtFinish.substring(8, 10),
          time: cardData.hrFinish,
        },
        water: {
          status: cardData.recipeWater,
          size: cardData.recipeWater ? cardData.criteria.water : '--',
        },
        startingPosition: `${cardData.startingPosition}º`,
        finalPosition: `${
          cardData.completed ? cardData.finalPosition : cardData.recipePosition
        }º`,
        recipeLoop: cardData.recipeLoop,
        stHP: cardData.recipeHP,
        isActive: cardData.isActive,
        current: cardData.current,
        id: recipe.metadata.recipeId,
        all: cardData,
        cannons: cardData.cannons || [],
        alerts: cardData.fails || {},
      };
      nArr.push(recipeData);
      if (cardData.current) setCurrentActive(recipeData);
    });
    setRows(nArr.reverse());
    setLoading(false);
  }, [props.equipamentId]);

  if (props.recipes === 0) {
    return (
      <Paper className={classes.card}>
        <Grid item xs={12} md={12} lg={12}>
          <h3 className={classes.routeHeader}>
            <span className={classes.routeLabel}>
              Gerenciador disponível. Nenhuma Rota associada.
            </span>
          </h3>
        </Grid>
      </Paper>
    );
  }

  return (
    <Grid container className={classes.header} spacing={2}>
      <Grid item xs={12}>
        <Card similar>
          <CardTitle>
            <Button
              onClick={() => {
                animateRecipes();
              }}
              disabled={playing}
              variant="text"
              color="primary"
            >
              <PlayArrowIcon />
              {`${playing ? 'Animando ' : 'Animar '} Histórico de Programas`}
            </Button>
            <Spacer />
            {props.selectedRecipeDate && (
              <div>
                <h3 className={classes.routeHeader}>
                  <span className={classes.routeLabelRight}>
                    <small className={classes.smallMonth}>{getMonth(-1)}</small>
                    {` ${getMonth(0)} `}
                    <small className={classes.smallMonth}>{getMonth(1)}</small>
                  </span>
                </h3>
              </div>
            )}
          </CardTitle>
          <CardText>
            <Grid container spacing={1}>
              <Grid item xs={12} md={8}>
                <Card flat color={colors.grey}>
                  <CardText className="customScroll" pa="8px" pr="0">
                    {loading ? (
                      <Skeleton height="60vh" />
                    ) : (
                      <div className="recipeSelector">
                        {rows.map((card) => (
                          <RecipeCard
                            id={`#${card.id}`}
                            isPlaying={playing}
                            setCurrent={(data) => {
                              setCurrentActive(data);
                            }}
                            current={currentRecipeId}
                            key={card.id}
                            data={card}
                            alerts={card.alerts}
                            // enviar array com alertas no formato
                            // {text:'texto',color:'cor do colors.js para o ícone'}
                          />
                        ))}
                      </div>
                    )}
                  </CardText>
                </Card>
              </Grid>
              <Grid item xs={12} md={4}>
                <Card outlined>
                  <CardTitle pb="0px">
                    <Spacer />
                    {pivotRecipe && pivotRecipe.dateStart
                      ? `${pivotRecipe.dateStart.weekDay}`
                      : ''}
                    /
                    {pivotRecipe && pivotRecipe.dateStart
                      ? `${pivotRecipe.dateStart.day}`
                      : ''}
                    /
                    {pivotRecipe && pivotRecipe.dateStart
                      ? `${pivotRecipe.dateStart.time}`
                      : ''}
                    <Spacer />
                  </CardTitle>
                  <CardText pa="8px" pt="0px">
                    {loading ? (
                      <Skeleton height="100%" />
                    ) : (
                      <PivoSVG
                        isProgrammingScreen
                        showCovered
                        showUncovered
                        isViewMode
                        startAngle={0}
                        endAngle={props.maxSectorAngle}
                        zeroAngle={props.northFaceAngle}
                        destinationAngle={pivotRecipe.all.finalPosition}
                        currentAngle={pivotRecipe.all.positionActual}
                        recipeStartAngle={pivotRecipe.all.startingPosition}
                        recipeEndAngle={pivotRecipe.all.finalPosition}
                        isRotatingLeft={pivotRecipe.direction === 2}
                        isRotatingRight={pivotRecipe.direction === 1}
                        isStoped={pivotRecipe.direction === 0}
                        hasWater={pivotRecipe.all.recipeWater}
                        recipe={pivotRecipe.all}
                        isPowerOn={pivotRecipe.all.stOnOff}
                        cannonAngles={pivotRecipe.all.cannon}
                      />
                    )}
                  </CardText>
                </Card>
              </Grid>
            </Grid>
          </CardText>
        </Card>
      </Grid>
    </Grid>
  );
};
