import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../../App.css';
import moment from 'moment';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import HistoryIcon from '@material-ui/icons/History';
import Tooltip from '@material-ui/core/Tooltip';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import OpacityIcon from '@material-ui/icons/Opacity';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

import ThermometrySensorHistory from './ThermometrySensorHistory';
import Thermometry3D from './Thermometry3D';
import FockinkAPI from '../../../../../middlewares/Api';
import { formatDate } from '../../../../../helpers/Intl';
import GraphThermometry from './GraphThermometry';
import { Modal } from '../../../../../components';
import { normalizeErrorData } from '../../../../../helpers/normalizeErrorData';
import { handleMessageError } from '../../../../../helpers/handlerMessageError';

export default ({
  currentDate, setCurrentDate, currentTelemetry,
  setCurrentTelemetry, lastValidateDate, ...props }) => {
  // const [reqErrorData, setReqErrorData] = React.useState(true);
  // const [sDate, setSDate] = React.useState(new Date());
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  // const [telemetries, setTelemetries] = React.useState([]);
  const [chartDatas, setChartDatas] = React.useState([]);
  // const [currentTelemetry, setCurrentTelemetry] = React.useState(undefined);
  const [sensorData, setSensorData] = React.useState(undefined);
  // const [view3D, setView3D] = React.useState(false);
  const view3D = false;

  // const [lastValidateDate, setLastValidateDate] = React.useState();

  const [searchData, setSearchData] = React.useState({
    format: 'cable',
    color: 'gradual',
    tempNoGrain: true,
  });

  const setNewOffset = (newOffset) => {
    setLoading(true);
    setOffset(newOffset);
    const nd = new Date(currentDate);
    nd.setDate(nd.getDate() - newOffset);
    setCurrentDate(new Date(nd));
  };

  const callValueApi = () => {
    setLoading(true);
    FockinkAPI.get(
      `/dashboards/thermometry/${props.equipamentId}/charts/temperature?sub=${props.strategyId}&telemetryId=${props.telemetries[currentTelemetry].id}`,
    )
      .then((response) => {
        setChartDatas(response.data.data.chartData);
        setLoading(false);
      })

      .catch(() => {
        setLoading(false);
        // setReqErrorData(true);
      });
  };

  /**
     Chamada API para quando a data for alterada
     */
  // const callTelemetriesApi = () => {
  //   setLoading(true);
  //   FockinkAPI.get(
  //     `/dashboards/thermometry/${props.equipamentId}/telemetries?date=${moment(
  //       sDate,
  //     ).format('yyyy-MM-DD')}&sub=${props.strategyId}`,
  //   )
  //     .then((response) => {
  //       const tels = response.data.data.telemetries.map((t) => ({
  //         id: t.id,
  //         hour: moment(t.date).format('HH:mm'),
  //         temp: t.temp,
  //         humid: t.humid,
  //         rain: t.rain,
  //         // grain: t.unit.grain,
  //       }));
  //       tels.reverse();
  //       setTelemetries(tels);
  //       if (tels[0].grain === 'Vazio') {
  //         const copySearchData = { ...searchData };
  //         copySearchData.tempNoGrain = true;
  //         setSearchData(copySearchData);
  //       }

  //       if (response.data.data.telemetries.length > 0) {
  //         setCurrentTelemetry(response.data.data.telemetries.length - 1);
  //       } else {
  //         setLoading(false);
  //         setReqErrorData(true);
  //       }
  //     })
  //     .catch((e) => {
  //       console.error('e', e);
  //       setLoading(false);
  //       if (e.response.status === 400) {
  //         setReqErrorData(new Date(e.response.data.lastValidDate));
  //         setLastValidateDate(new Date(e.response.data.lastValidDate));
  //       }
  //     });
  // };

  // React.useEffect(() => {
  //   props.getLastValidDate(currentDate);
  // }, [currentDate]);

  // React.useEffect(() => {
  // setCurrentTelemetry(undefined);
  // setReqErrorData(false);
  // callTelemetriesApi();
  // }, [currentDate, props.strategyId]);

  React.useEffect(() => {
    if (currentTelemetry !== undefined && props.telemetries.length > currentTelemetry) {
      callValueApi();
    }
  }, [props.telemetries, currentTelemetry]);

  React.useEffect(() => {
    if (lastValidateDate && props.telemetries.length === 0) {
      setLoading(false);
    }
  }, [props.telemetries, lastValidateDate]);

  const handleChange = (event) => {
    const newSD = {
      ...searchData,
      [event.target.name]: event.target.value || event.target.checked,
    };
    setSearchData(newSD);
    localStorage.thermometrySearchGraph = JSON.stringify(newSD);
  };

  const handleDateChange = (date) => {
    const dt = moment(date.toISOString().substring(0, 10));
    const diffDate = moment().diff(dt, 'days');
    setNewOffset(diffDate);
  };

  if (props.loading || loading) {
    return (
      <Skeleton variant="rect" height={200} width="100%" animation="wave" />
    );
  }

  const checkIsEmpty = () => {
    if (props.telemetries[currentTelemetry]) {
      return props.telemetries[currentTelemetry].grain === 'Vazio';
    }
    return false;
  };

  return (
    <Paper style={{ padding: '15px', backgroundColor: 'white' }}>

      <Modal
        title="Resistência de temperatura"
        open={sensorData}
        handleCloseModal={() => setSensorData(undefined)}
      >
        <ThermometrySensorHistory sensorData={sensorData} />
      </Modal>

      <Grid
        container
        spacing={1}
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={12}>
          <h3 style={{ margin: '0', fontWeight: '500' }}>Temperatura</h3>
        </Grid>

        {!view3D ? (
          <>
            <Grid item xs={12} md={12} lg={2}>
              <FormControl variant="filled">
                <InputLabel>Formato</InputLabel>
                <Select
                  value={searchData.format}
                  onChange={handleChange}
                  name="format"
                >
                  <MenuItem value="level">Sensores</MenuItem>
                  <MenuItem value="cable">Cabos</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} lg={2}>
              <FormControl variant="filled">
                <InputLabel>Cores</InputLabel>
                <Select
                  value={searchData.color}
                  onChange={handleChange}
                  name="color"
                >
                  {checkIsEmpty() ? null : (
                    <MenuItem value="quality">Qualidade (faixas)</MenuItem>
                  )}
                  <MenuItem value="gradual">Gradual</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} lg={3}>
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Switch
                    disabled={checkIsEmpty()}
                    checked={searchData.tempNoGrain}
                    onChange={handleChange}
                    name="tempNoGrain"
                    color="primary"
                  />
                }
                label="Sensores fora da massa"
              />
            </Grid>

            <Grid item xs={12} md={12} lg={2}>
              <Grid container>
                <Grid
                  item
                  xs={1}
                  style={{ cursor: 'pointer', margin: 'auto' }}
                  onClick={() => setNewOffset(offset + 1)}
                >
                  <KeyboardArrowLeftIcon style={{ margin: '-4px' }} />
                </Grid>
                <Grid item xs={10}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    style={{ width: '100%' }}
                  >
                    <KeyboardDatePicker
                      margin="normal"
                      label="Data Aferição"
                      format="dd/MM/yyyy"
                      value={currentDate}
                      name="date"
                      onChange={(e) => handleDateChange(e, 'date')}
                      InputProps={{ readOnly: true }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    cursor: 'pointer',
                    margin: 'auto',
                    color: (currentDate.getDate() !== new Date().getDate()) ? '#727272' : '#bdbdbd',
                  }}
                  onClick={() => {
                    if (currentDate.getDate() !== new Date().getDate()) {
                      setNewOffset(offset - 1);
                    } else {
                      handleMessageError('Ops! A data que você esta tentando acessar é superior a data de hoje.');
                    }
                  }}
                >
                  <KeyboardArrowRightIcon />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={12} lg={2}>
              <FormControl variant="filled">
                <InputLabel>Hora Aferição</InputLabel>
                <Select
                  disabled={props.telemetries.length === 0}
                  value={currentTelemetry !== undefined ? currentTelemetry : ''}
                  onChange={(e) => setCurrentTelemetry(e.target.value)}
                  name="hour"
                >
                  {props.telemetries.map((m, i) => (
                    <MenuItem value={i} key={m.id}>
                      {m.hour}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} md={12} lg={10} />
        )}

        {/* <Grid item xs={12} md={12} lg={1}>
          <Tooltip
            title={view3D ? 'Ver gráfico' : 'Ver 3D'}
            onClick={() => setView3D(!view3D)}
          >
            {view3D ? (
              <PollIcon style={{ fontSize: '50px', cursor: 'pointer' }} />
            ) : (
              <ThreeDRotationIcon
                style={{ fontSize: '50px', cursor: 'pointer' }}
              />
            )}
          </Tooltip>
        </Grid> */}

        {lastValidateDate && chartDatas.length === 0 && !view3D ? (
          <>
            <h2
              style={{
                width: '100%',
                textAlign: 'center',
              }}
            >
              <EventBusyIcon
                style={{ marginRight: '10px', fontSize: '35px' }}
              />
              Nenhuma informação encontrada nessa data.
            </h2>
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={() => setCurrentDate(lastValidateDate)}
              startIcon={<HistoryIcon />}
            >
              {`Ver ultima data (${formatDate(
                lastValidateDate,
                'dd/MM/yyyy',
              )})`}
            </Button>
          </>
        ) : (
          <Grid item xs={12}>
            {view3D ? (
              <Thermometry3D
                equipamentId={props.equipamentId}
                strategyId={props.strategyId}
              />
            ) : (
              <>
                {props.telemetries[currentTelemetry] && (
                <div
                  style={{
                    width: '100%',
                    paddingLeft: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '16px',
                  }}
                >
                  <span>
                    Estação meteorológica no momento da aferição:
                    <strong style={{ marginLeft: '5px' }}>Temp:</strong>
                    <strong style={{ marginLeft: '5px' }}>
                      {normalizeErrorData(Number(props.telemetries[currentTelemetry].temp), '°C')}
                    </strong>
                    <strong style={{ marginLeft: '5px' }}>Umid:</strong>
                    <strong style={{ marginLeft: '5px' }}>
                      {normalizeErrorData(Number(props.telemetries[currentTelemetry].humid), '%')}
                    </strong>
                    {props.telemetries[currentTelemetry].rain === 0 ? (
                      <Tooltip title="Sem Chuva">
                        <WbSunnyIcon style={{ marginLeft: '5px' }} />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Chovendo">
                        <OpacityIcon style={{ marginLeft: '5px' }} />
                      </Tooltip>
                    )}
                  </span>
                </div>
                )}

                {chartDatas.length && (
                  chartDatas.map((c) => (

                    <Grid
                      key={c.unitData.sub}
                      container
                      justifyContent="space-around"
                      alignItems="center"
                      style={{
                        border: '1px solid black',
                        padding: '0 8px',
                        marginBottom: '5px',
                        borderRadius: '4px',
                      }}
                    >

                      <GraphThermometry
                        chartData={c}
                        equipamentId={props.equipamentId}
                        date={formatDate(currentDate, 'yyyy-MM-dd')}
                        setSensorData={setSensorData}
                        searchData={searchData}
                      />

                      {/* <Grid item xs={12}>
                        <ThermometrySensorHistory sensorData={sensorData} />
                      </Grid> */}
                    </Grid>
                  ))
                )}
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
