import React from 'react';
import Grid from '@material-ui/core/Grid';
import { RiEmotionUnhappyLine } from 'react-icons/ri';
import { Card, CardContent } from '@mui/material';
import Breadcrumbs from './Breadcrumbs';

export default (props) => (
  <>
    <Breadcrumbs routes={[{ name: 'Equipamentos', to: '/equipamentData' }, { name: 'Equipamento com erro' }]} />

    <Grid container spacing={2} style={{ padding: '0 24px 24px' }}>
      <Grid item xs={12} lg={5}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={2} style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                <RiEmotionUnhappyLine size={40} />
              </Grid>
              <Grid item xs={12} lg={10}>
                <h2>Oops! ocorreu um erro.</h2>

                <h3 style={{ fontWeight: '400' }}>{props.errorMessage}</h3>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </>
);
