/*eslint-disable*/
import * as BABYLON from 'babylonjs';
import * as MyEarcut from 'earcut';
import * as GUI from 'babylonjs-gui';

import { StatusOnOffMotor, LabelNameMotor } from './CriaMotor';
import originalTheme from './theme';

const _ = require('lodash');

let showInfo = false;
let arcSee = [];
let showPanelMotor3D = false;

export const CleanPanelSiloV = () => {
  showPanelMotor3D = false;
};

export const CleanVarsSilo = () => {
  showInfo = false;
  arcSee = [];
};

export const CriaSiloV = (
  scene,
  layout,
  termoReport,
  colorConfig,
  cod,
  id,
  raio,
  alturaCilindro,
  anguloEscada,
  anguloSilo,
  posX,
  posZ,
  objetoCamadaProduto,
  unidadesObservaveis,
  setUnidadesObservaveis,
  fncChangeViewInfo,
  produto_visivel,
  handleOnSetObjetoCamadaProduto,
  produto_info,
  fncChangeViewLevel,
  profundidade,
  between_points,
  grao,
  fchChangeViewStaircase,
  escada_visivel,
  fcnSeeTooltip,
  colorMotorStatus3D1,
  colorMotorStatus3D2,
  colorMotorStatus3D3,
  colorMotorStatus3D4,
  colorMotorStatus3D5,
  colorMotorStatus3D1Shaded,
  colorMotorStatus3D2Shaded,
  colorMotorStatus3D3Shaded,
  colorMotorStatus3D4Shaded,
  colorMotorStatus3D5Shaded,
  fcnSeeTooltipMotor,
  permissaoParaEditarOEstadoDosMotores,
  painelEditaMotoresEmUso,
  motor3D,
  baseMotor3D,
  conjuntoMotor3D,
  suporteDoMotor3D,
) => {
  const pivoSilo = new BABYLON.TransformNode(`pivoSilo${id}`);
  pivoSilo.unidade = cod;
  pivoSilo.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(90 + anguloSilo));
  pivoSilo.position.x = posX;
  pivoSilo.position.z = posZ;
  const raioSilo = raio;
  const alturaSilo = alturaCilindro;
  const alturaBase = 0.5;
  const angulocone = 30;
  let distanciaEntreSensores = 2;

  if (between_points > 0) {
    // console.log('MAIOR QUE ZERO');
    // console.log(between_points);
    distanciaEntreSensores = between_points;
  } else if (alturaSilo > 19.99 && between_points === 0) {
    // console.log('IGUAL A DOIS');
    distanciaEntreSensores = 2;
  } else {
    // console.log('IGUAL A UM PONTO CINCO');
    distanciaEntreSensores = 1.6;
  }

  // Cálculo automático do cone // H=R*tg(a*Pi/180)
  const alturaCone = raioSilo * Math.tan((angulocone * Math.PI) / 180);

  // Base Silo
  // const materialBaseSilo = new BABYLON.StandardMaterial(`materialBaseSilo${id}`, scene);
  // materialBaseSilo.diffuseTexture = new BABYLON.Texture('/images/3DVisualization/texturas/base-silo.png', scene);
  // materialBaseSilo.diffuseTexture.vScale = 1;
  // materialBaseSilo.diffuseTexture.uScale = 35;
  // materialBaseSilo.specularColor = new BABYLON.Color3(0, 0, 0);
  // const baseSilo = new BABYLON.MeshBuilder.CreateCylinder('baseSilo', { diameter: (raioSilo * 2) + 0.5, height: alturaBase, tessellation: 60 });
  // baseSilo.position.y = alturaBase / 2;
  // baseSilo.material = materialBaseSilo;
  // baseSilo.isPickable = false;
  // baseSilo.parent = pivoSilo;

  // Silo
  const texturaSilo = new BABYLON.StandardMaterial(`texturasilo${id}`, scene);
  texturaSilo.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.texturaSilo}`,
    scene,
  );
  texturaSilo.diffuseTexture.uScale = raioSilo;
  texturaSilo.diffuseTexture.vScale = 6; // TODO: - [] Verificar
  texturaSilo.specularColor = new BABYLON.Color3(0, 0, 0);

  // Calçada Silo
  const anelCalcada = [];
  anelCalcada.push(new BABYLON.Vector3(0.0, 0.01, 0.0));
  anelCalcada.push(new BABYLON.Vector3(0.0, 0.001, 0.0));
  const funcRaioCalcada = function (i, d) {
    if (i === 0) {
      return raioSilo + 0.1;
    }
    return raioSilo + 3.8;
  };

  const tipoVisualizacao = BABYLON.Mesh.BACKSIDE;

  // Fundo Silo
  const texturaFundoSilo = new BABYLON.StandardMaterial(
    `texturaFundoSilo${id}`,
    scene,
  );
  texturaFundoSilo.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.baseSilo}`,
    scene,
  );
  // texturaFundoSilo.diffuseTexture.uScale = 40;
  // texturaFundoSilo.diffuseTexture.vScale = 4.6;
  texturaFundoSilo.backFaceCulling = false;
  texturaFundoSilo.sideOrientation = BABYLON.Mesh.BACKSIDE;
  texturaFundoSilo.specularColor = new BABYLON.Color3(0, 0, 0);

  // CRIA TEXTURA METALICA DO ANEL DE SEGURANÇA
  const texturaAnelSeguranca = new BABYLON.StandardMaterial(
    `texturaAnelSeguranca${id}`,
    scene,
  );
  texturaAnelSeguranca.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.amareloSeguranca}`,
    scene,
  );
  texturaAnelSeguranca.diffuseTexture.uScale = 10;
  texturaAnelSeguranca.diffuseTexture.vScale = 1;
  texturaAnelSeguranca.specularColor = new BABYLON.Color3(0, 0, 0);

  const aneisFundo = [];
  aneisFundo.push(new BABYLON.Vector3(0.0, -profundidade, 0.0));
  aneisFundo.push(new BABYLON.Vector3(0.0, -profundidade, 0.0));
  aneisFundo.push(new BABYLON.Vector3(0.0, 1.5, 0.0));

  const funcRaioFundo = function (i, d) {
    if (i === 0) {
      return 0.01;
    }
    if (i === 1) {
      return 0.5;
    }
    return raioSilo + 0.3;
  };

  const siloFundo = new BABYLON.MeshBuilder.CreateTube(
    `siloTopo${id}`,
    {
      path: aneisFundo,
      tessellation: 60,
      radiusFunction: funcRaioFundo,
      sideOrientation: BABYLON.Mesh.BACKSIDE,
      updatable: false,
    },
    scene,
  );

  siloFundo.material = texturaFundoSilo;
  // siloFundo.position.y = alturaBase / 2;
  // PROBLEMA DA ALTURA ENCONTRADO
  siloFundo.position.y = -1.5;
  siloFundo.isPickable = true;
  siloFundo.checkCollisions = true;
  // siloFundo.parent = pivoSilo;

  const aneisSilo = [];
  aneisSilo.push(new BABYLON.Vector3(0.0, 0, 0.0));
  aneisSilo.push(new BABYLON.Vector3(0.0, alturaSilo, 0.0));

  const funcRaioLateral = (i, d) => {
    if (i === 0 || i === 1) {
      return raioSilo;
    }
  };

  const silo = new BABYLON.MeshBuilder.CreateTube(
    `silo${id}`,
    {
      path: aneisSilo,
      tessellation: 60,
      radiusFunction: funcRaioLateral,
      sideOrientation: tipoVisualizacao,
      updatable: false,
    },
    scene,
  );
  silo.material = texturaSilo;
  // silo.position.y = alturaBase / 2;
  silo.position.y = alturaBase / 2;
  silo.isPickable = false;
  silo.parent = siloFundo;

  // Topo Silo
  const texturaTopoSilo = new BABYLON.StandardMaterial(
    `texturaTopoSilo${id}`,
    scene,
  );
  texturaTopoSilo.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.texturaTopoSilo}`,
    scene,
  );
  texturaTopoSilo.diffuseTexture.uScale = 40;
  texturaTopoSilo.diffuseTexture.vScale = 4.6;
  texturaTopoSilo.specularColor = new BABYLON.Color3(0, 0, 0);
  const aneisTopo = [];
  aneisTopo.push(new BABYLON.Vector3(0.0, alturaSilo, 0.0));
  aneisTopo.push(new BABYLON.Vector3(0.0, alturaSilo + alturaCone, 0.0));
  aneisTopo.push(new BABYLON.Vector3(0.0, alturaSilo + alturaCone, 0.0));

  // CRIA A GUARDACORPO
  const coroa1 = [];
  coroa1.push(new BABYLON.Vector3(0.0, alturaSilo, 0.0));
  coroa1.push(new BABYLON.Vector3(0.0, alturaSilo + 1.5, 0.0));

  const funcRaioTopo = function (i, d) {
    if (i === 0) {
      return raioSilo + 0.3;
    }
    if (i === 1) {
      return 0.5;
    }
    return 0.01;
  };

  const siloTopo = new BABYLON.MeshBuilder.CreateTube(
    `siloTopo${id}`,
    {
      path: aneisTopo,
      tessellation: 60,
      radiusFunction: funcRaioTopo,
      sideOrientation: tipoVisualizacao,
      updatable: false,
    },
    scene,
  );

  // CRIA GUARDACORPO 1
  const guardaCorpo1 = new BABYLON.MeshBuilder.CreateTube(
    `teste${id}`,
    {
      path: coroa1,
      tessellation: 60,
      radius: raioSilo,
      sideOrientation: tipoVisualizacao,
      updatable: false,
    },
    scene,
  );

  siloTopo.material = texturaTopoSilo;
  // siloTopo.position.y = alturaBase / 2;
  siloTopo.position.y = alturaBase / 2;
  siloTopo.isPickable = false;
  siloTopo.parent = siloFundo;

  let alturaVzDoisArredondada = (alturaSilo - 1.5 + 0.5) * 2;
  alturaVzDoisArredondada = Math.round(alturaVzDoisArredondada);

  guardaCorpo1.material = texturaAnelSeguranca;
  guardaCorpo1.position.y = alturaBase / 2 + 0.17;
  guardaCorpo1.isPickable = false;
  guardaCorpo1.parent = siloFundo;

  const materialCalcada = new BABYLON.StandardMaterial(
    `materialCalcada${id}`,
    scene,
  );
  materialCalcada.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.calcada}`,
    scene,
  );
  materialCalcada.diffuseTexture.uScale = 8;
  materialCalcada.diffuseTexture.vScale = 1;
  materialCalcada.zOffset = -2;
  materialCalcada.diffuseTexture.hasAlpha = true;

  const calcada = new BABYLON.MeshBuilder.CreateTube(
    `cakcada${id}`,
    {
      path: anelCalcada,
      tessellation: 60,
      radiusFunction: funcRaioCalcada,
      updatable: false,
    },
    scene,
  );
  calcada.material = materialCalcada;
  calcada.position.y = -(alturaBase / 2) + 0.4;
  calcada.isPickable = false;
  calcada.checkCollisions = true;
  calcada.parent = pivoSilo;

  // Constrói o produto, arcos, cabos, sensores
  fncUpdateInfoLevelProductSilo(
    scene,
    raioSilo,
    alturaSilo,
    alturaBase,
    alturaCone,
    distanciaEntreSensores,
    termoReport,
    layout,
    colorConfig,
    fncChangeViewInfo,
    produto_visivel,
    handleOnSetObjetoCamadaProduto,
    produto_info,
    profundidade,
    grao,
    escada_visivel,
    alturaVzDoisArredondada,
    fcnSeeTooltip,
    colorMotorStatus3D1,
    colorMotorStatus3D2,
    colorMotorStatus3D3,
    colorMotorStatus3D4,
    colorMotorStatus3D5,
    colorMotorStatus3D1Shaded,
    colorMotorStatus3D2Shaded,
    colorMotorStatus3D3Shaded,
    colorMotorStatus3D4Shaded,
    colorMotorStatus3D5Shaded,
    fcnSeeTooltipMotor,
    permissaoParaEditarOEstadoDosMotores,
    painelEditaMotoresEmUso,
    motor3D,
    baseMotor3D,
    conjuntoMotor3D,
    suporteDoMotor3D,
  );

  const unidadeOnservavel = new BABYLON.Observable();
  unidadeOnservavel.add((value) => {
    // console.log('VALUEE');
    // console.log(value);
    if (value.produto_visivel !== undefined) {
      // console.log('PRODUOT VISIVEl');
      // console.log(value);
      fncChangeViewLevel(scene, value.produto_visivel);
    }
    if (value.produto_info !== undefined) {
      // console.log('PRODUOT INFO');
      // console.log(value);
      fncChangeViewInfo(scene, value.produto_info);
    }
    if (value.leitura_nivel_produto !== undefined) {
      // console.log('NIVEL PRODUTO');
      // console.log(value);
      // Aqui atualiza o nível quando selecionado uma leitura
      const selectedTermoReport = value.leitura_nivel_produto;
      fncUpdateInfoLevelProductSilo(
        scene,
        raioSilo,
        alturaSilo,
        alturaBase,
        alturaCone,
        distanciaEntreSensores,
        selectedTermoReport,
        layout,
        colorConfig,
        fncChangeViewInfo,
        produto_visivel,
        (vl) => handleOnSetObjetoCamadaProduto(vl),
        produto_info,
        profundidade,
        grao,
        escada_visivel,
        alturaVzDoisArredondada,
        fcnSeeTooltip,
        colorMotorStatus3D1,
        colorMotorStatus3D2,
        colorMotorStatus3D3,
        colorMotorStatus3D4,
        colorMotorStatus3D5,
        colorMotorStatus3D1Shaded,
        colorMotorStatus3D2Shaded,
        colorMotorStatus3D3Shaded,
        colorMotorStatus3D4Shaded,
        colorMotorStatus3D5Shaded,
        fcnSeeTooltipMotor,
        permissaoParaEditarOEstadoDosMotores,
        painelEditaMotoresEmUso,
        motor3D,
        baseMotor3D,
        conjuntoMotor3D,
        suporteDoMotor3D,
      );
    }
    if (value.escada_visivel !== undefined) {
      fchChangeViewStaircase(
        scene,
        value.escada_visivel,
        alturaVzDoisArredondada,
      );
    }
  });

  const unitsObserv = [];
  unitsObserv.push({ unidade: id, observavel: unidadeOnservavel });
  setUnidadesObservaveis(unitsObserv);

  // Chão
  const comprimentoChao = raioSilo - 0.5;
  const larguraChao = raioSilo - 0.5;

  //     const comprimentoChao = 1000;
  // const larguraChao = 1000;
  const distanciaChao = 540 / 2;

  // Poligono da superficie
  const forma = [
    new BABYLON.Vector3(-distanciaChao, 0, -distanciaChao),
    new BABYLON.Vector3(-distanciaChao, 0, distanciaChao),
    new BABYLON.Vector3(distanciaChao, 0, distanciaChao),
    new BABYLON.Vector3(distanciaChao, 0, -distanciaChao),
  ];

  // Buraco da superfície
  const buracoChao = [];
  buracoChao.push([
    // new BABYLON.Vector3(-comprimentoChao, 0, -larguraChao),
    // ---------------- x | y | z

    // new BABYLON.Vector3(-comprimentoChao, 0, -larguraChao), // direita superior
    new BABYLON.Vector3(-(comprimentoChao + 4.0), 0, 0), // direita centro
    // new BABYLON.Vector3(-comprimentoChao), 0, larguraChao), // esquerda cima

    new BABYLON.Vector3(-(comprimentoChao + 2.8), 0, larguraChao / 2), // direita cima/baixo

    new BABYLON.Vector3(-(comprimentoChao - 0.7), 0, larguraChao - 0.7), // direita baixo

    new BABYLON.Vector3(-(comprimentoChao / 2), 0, larguraChao + 2.8), // direita 2º baixo

    new BABYLON.Vector3(0, 0, larguraChao + 4.0), //  baixo centro

    new BABYLON.Vector3(comprimentoChao / 2, 0, larguraChao + 2.8), // esquerda 2º baixo

    new BABYLON.Vector3(comprimentoChao - 0.7, 0, larguraChao - 0.7), // esquerda baixo

    new BABYLON.Vector3(comprimentoChao + 2.8, 0, larguraChao / 2), // esquerda cima/baixo

    // new BABYLON.Vector3(comprimentoChao, 0, larguraChao), // esquerda baixo

    new BABYLON.Vector3(comprimentoChao + 4.0, 0, 0), // esquerda centro
    //= =
    new BABYLON.Vector3(comprimentoChao + 2.8, 0, -(larguraChao / 2)), // esquerda cima/baixo

    new BABYLON.Vector3(comprimentoChao - 0.7, 0, -(larguraChao - 0.7)), // esquerda baixo

    new BABYLON.Vector3(comprimentoChao / 2, 0, -(larguraChao + 2.8)), // esquerda 2º baixo
    //= =

    new BABYLON.Vector3(0, 0, -(larguraChao + 4.0)), // cima centro

    new BABYLON.Vector3(-(comprimentoChao / 2), 0, -(larguraChao + 2.8)), // esquerda 2º baixo

    new BABYLON.Vector3(-(comprimentoChao - 0.7), 0, -(larguraChao - 0.7)), // esquerda baixo

    new BABYLON.Vector3(-(comprimentoChao + 2.8), 0, -(larguraChao / 2)), // esquerda cima/baixo
  ]);

  const matChao = new BABYLON.StandardMaterial('matChao', scene);
  matChao.zOffset = 0;
  matChao.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.ground}`,
    scene,
  );
  matChao.diffuseTexture.uScale = 10;
  matChao.diffuseTexture.vScale = 10;
  matChao.specularColor = new BABYLON.Color3(0, 0, 0);
  const chaoArmazem = BABYLON.MeshBuilder.CreatePolygon(
    'polygonChao',
    {
      shape: forma,
      holes: buracoChao,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
    },
    scene,
    MyEarcut,
  );
  chaoArmazem.isPickable = false;
  chaoArmazem.checkCollisions = true;
  chaoArmazem.material = matChao;
};

const fncUpdateInfoLevelProductSilo = (
  scene,
  raioSilo,
  alturaSilo,
  alturaBase,
  alturaCone,
  distanciaEntreSensores,
  termoReport,
  layoutSilo,
  colorConfig,
  fncChangeViewInfo,
  produto_visivel,
  handleOnSetObjetoCamadaProduto,
  produto_info,
  profundidade,
  grao,
  escada_visivel,
  alturaVzDoisArredondada,
  fcnSeeTooltip,
  colorMotorStatus3D1,
  colorMotorStatus3D2,
  colorMotorStatus3D3,
  colorMotorStatus3D4,
  colorMotorStatus3D5,
  colorMotorStatus3D1Shaded,
  colorMotorStatus3D2Shaded,
  colorMotorStatus3D3Shaded,
  colorMotorStatus3D4Shaded,
  colorMotorStatus3D5Shaded,
  fcnSeeTooltipMotor,
  permissaoParaEditarOEstadoDosMotores,
  painelEditaMotoresEmUso,
  motor3D,
  baseMotor3D,
  conjuntoMotor3D,
  suporteDoMotor3D,
) => {
  // Desenha arcos, cabos, sensores
  let sensorIdForMat = 0;
  // console.log('😱 ');
  // console.log('constroi arcos cabos e sensores');
  // console.log(layoutSilo);
  const objetoSilo = layoutSilo.arcs.slice().reverse(); // ==> 🔴🔴🔴

  const matCabo = new BABYLON.StandardMaterial('matCabo', scene);
  matCabo.diffuseColor = new BABYLON.Color3(0.1, 0.1, 0.1);
  matCabo.specularColor = new BABYLON.Color3(0, 0, 0);

  const matSensor = new BABYLON.StandardMaterial('matSensor', scene);
  matSensor.specularColor = new BABYLON.Color3(0, 0, 0);

  const conjuntoArco = new BABYLON.Mesh('conjuntoArco', scene);

  let menorNivelCabo = -1;
  const vetorDistanciasArcos = [];

  const radius = raioSilo / objetoSilo.length + 1.0;

  const advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI');
  advancedTexture.useInvalidateRectOptimization = false;

  // Criar função que será chamada quando o cabo ou sensor for clicado
  // Essa função criará as Info Labels
  const criaInfoLabels = (arc, arcMesh) => {
    // console.log('ate aqui cheguei ====')
    const cabos = arcMesh.getChildren();
    // for (let i = 0; i < objetoArmazem.length; i += 1) {
    //  if (arc == i) {
    // console.log('CABOS ====================>');
    // console.log(cabos);

    for (let cab = 0; cab < cabos.length; cab += 1) {
      // Cabos
      const sensorsMesh = cabos[cab].getChildMeshes();
      // console.log('sensorsMesh');
      // console.log(sensorsMesh);
      let countSensor = 0;

      const stringNomeDoCabo = cabos[cab].name;
      const newStringNomeDoCabo = stringNomeDoCabo.slice(
        8,
        stringNomeDoCabo.length,
      );

      // console.log('stringNomeDoCabo');
      // console.log(stringNomeDoCabo);
      // console.log(newStringNomeDoCabo);

      for (let sen = 0; sen < sensorsMesh.length; sen++) {
        // console.log('for de criação das labels');
        // console.log(sen);

        const nameOfSensor = sensorsMesh[sen].name;
        const parsedNameOfSensor = nameOfSensor.split('.');

        // console.log('ParsedNameOfSensor');
        // console.log(parsedNameOfSensor);
        // console.log(parsedNameOfSensor.length);

        if (
          parsedNameOfSensor.length === 1 &&
          parsedNameOfSensor[0].includes('Sensor')
        ) {
          // console.log('condição do if de criação = true');
          // console.log('mesh');
          // console.log(sensorsMesh[sen]);
          // console.log('name');
          // console.log(sensorsMesh[sen].name);
          // console.log(sensorsMesh[sen].name.split('.')[0]);

          // const objSensor =  //objetoArmazem[i].cables[cab].sensors[sen];
          // if (objSensor) {
          countSensor++;
          let tempColor = '#FFFFFFFF';
          // tempColor =  `${objSensor.color_hex}FF`;
          tempColor =
            sensorsMesh[sen].sensor && sensorsMesh[sen].sensor.color_hex;
          // console.log('criando 1')
          const objetoSensor = sensorsMesh[sen];
          const objetoDeTrasicaoCabo = { cab: newStringNomeDoCabo };

          // let actualRect = criaTooltip(advancedTexture, objetoSensor, arc, objetoDeTrasicaoCabo, sen - 1, sensorsMesh[sen].sensor, 1)
          NomeObjeto(
            arcMesh.name,
            scene,
            tempColor,
            [{ val: `${sensorsMesh[sen].sensor.temperature}`, tam: 220 }],
            objetoSensor,
            0,
            1.4,
            1,
            1,
            fcnSeeTooltip,
          );
          // }
        }
      }
    }
  };

  const objetoSensorEx = new BABYLON.MeshBuilder.CreateSphere(
    'objetoSensor0',
    {
      diameter: 0.5,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      updatable: false,
    },
    scene,
  );

  const criaTooltip2 = (
    nome,
    scene,
    corFundo,
    id,
    parent,
    altura,
    tamanho,
    x = 0,
    z = 0,
  ) => {
    const toolTip = BABYLON.Mesh.CreatePlane(
      `toolTip${nome}`,
      tamanho,
      scene,
      false,
    );

    // BORDA
    toolTip.enableEdgesRendering();
    toolTip.edgesWidth = 4.0;
    toolTip.edgesColor = corFundo;
    //
    toolTip.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL;

    if (parent.sensor.sensor_id <= 6) {
      toolTip.position.x = 3;
      toolTip.position.z = 1;
      toolTip.position.y = 3;
    } else {
      toolTip.position.x = 3;
      toolTip.position.z = 1;
      toolTip.position.y = -3;
    }

    toolTip.parent = parent;
    const texturaTooltip = new BABYLON.DynamicTexture(
      'texturaTooltip',
      { width: 600, height: 560 },
      scene,
    );
    const fontSize = 100;

    const label1 = `ANEL: ${parent.sensor.arc_index}`;
    const label2 = `CABO: ${parent.sensor.cable_number}`;
    const label3 = `SENSOR: ${parent.sensor.sensor_id}`;
    const label4 = `Temp:${
      parent.sensor.temperature === 999 || parent.sensor.temperature === 'OFF'
        ? 'OFF'
        : parent.sensor.temperature === 998 ||
          parent.sensor.temperature === 'N/D'
        ? 'N/D'
        : parent.sensor.temperature === 997 ||
          parent.sensor.temperature === 'ERR'
        ? 'ERR'
        : `${parent.sensor.temperature}ºC`
    }`;

    const font = `bold ${fontSize}px arial`;

    texturaTooltip.drawText(
      label1,
      null,
      100,
      font,
      'white',
      corFundo,
      true,
      true,
    );
    texturaTooltip.drawText(label2, null, 220, font, 'white', null, true, true);
    texturaTooltip.drawText(label3, null, 340, font, 'white', null, true, true);
    texturaTooltip.drawText(label4, null, 460, font, 'white', null, true, true);
    toolTip.material = new BABYLON.StandardMaterial(
      'materialSuperficie',
      scene,
    );
    toolTip.material.diffuseTexture = texturaTooltip;
    toolTip.material.specularColor = new BABYLON.Color3(0, 0, 0);
    toolTip.material.emissiveColor = new BABYLON.Color3(1, 1, 1);
    toolTip.material.backFaceCulling = false;
    toolTip.isPickable = false;
    texturaTooltip.hasAlpha = true;
    toolTip.material.freeze();

    // toolTip.actionManager = new BABYLON.ActionManager(scene);

    // toolTip.actionManager.registerAction(
    //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function (ev) {
    //     scene.beginAnimation(actualRect, 0, 10, false)
    //   }));

    // toolTip.actionManager.registerAction(
    //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, function (ev) {
    //     scene.beginAnimation(actualRect, 10, 0, false)
    //   }));

    return toolTip;
  };

  const criaTooltip = (
    advancedTexture,
    objetoSensor,
    arc,
    objCabo,
    sen,
    objSensor,
    type,
  ) => {
    const rect1 = new GUI.Rectangle();
    advancedTexture.addControl(rect1);
    rect1.width = '100px';
    rect1.height = '100px';
    rect1.thickness = 1;
    rect1.linkOffsetX = '50px';
    rect1.linkOffsetY = '-50px';
    rect1.transformCenterX = 0;
    rect1.transformCenterY = 1;
    rect1.background = 'grey';
    rect1.alpha = 0.9;
    rect1.scaleX = 0;
    rect1.scaleY = 0;

    rect1.linkWithMesh(objetoSensor);

    const text1 = new GUI.TextBlock();

    if (type === 0) {
      text1.text = `ANEL: ${objetoSilo.length - arc}\nCABO: ${
        objCabo.cab
      }\nSENSOR: ${sen + 1}\nTemp:${
        objSensor.t === 999
          ? 'OFF'
          : objSensor.t === 998
          ? 'N/D'
          : objSensor.t === 997
          ? 'ERR'
          : `${objSensor.t}ºC`
      }`;
    } else {
      text1.text = `ANEL: ${objetoSilo.length - arc}\nCABO: ${
        objCabo.cab
      }\nSENSOR: ${sen + 1}\nTemp:${
        objSensor.temperature === 'OFF'
          ? 'OFF'
          : objSensor.temperature === 'N/D'
          ? 'N/D'
          : objSensor.temperature === 'ERR'
          ? 'ERR'
          : `${objSensor.temperature}°C`
      }`;
    }

    text1.color = 'White';
    text1.fontSize = 14;
    text1.textWrapping = true;
    text1.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP;
    text1.background = '#006994';
    rect1.addControl(text1);
    text1.alpha = 1 / text1.parent.alpha;
    text1.paddingTop = '10px';

    const scaleXAnimation = new BABYLON.Animation(
      'myAnimation',
      'scaleX',
      60,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT,
      BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
    );
    const scaleYAnimation = new BABYLON.Animation(
      'myAnimation',
      'scaleY',
      60,
      BABYLON.Animation.ANIMATIONTYPE_FLOAT,
      BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
    );

    const keys = [];

    keys.push({
      frame: 0,
      value: 0,
    });
    keys.push({
      frame: 10,
      value: 1,
    });

    scaleXAnimation.setKeys(keys);
    scaleYAnimation.setKeys(keys);
    rect1.animations = [];
    rect1.animations.push(scaleXAnimation);
    rect1.animations.push(scaleYAnimation);

    return rect1;
  };

  for (let arc = 0; arc < objetoSilo.length; arc += 1) {
    // Arcos
    const objetoArco = new BABYLON.Mesh(`objetoArco${arc}`, scene);

    objetoArco.parent = conjuntoArco;
    const objArco = objetoSilo[arc];

    if (arc !== 0) {
      objetoSilo[arc].radius = objetoSilo[arc - 1].radius + radius;
    }
    const raioArco = objArco.radius;
    vetorDistanciasArcos.push(raioArco);
    // AQUI RESOLVI O BUG DOS CABOS SAINDO PARA FORA
    const alturaMaxima = alturaSilo + alturaBase + alturaCone - 1.5;

    const alturaCaboTeto =
      alturaMaxima - Math.tan(BABYLON.Tools.ToRadians(30)) * raioArco;

    for (let cab = 0; cab < objArco.cables.length; cab += 1) {
      // Cabos
      // console.log(`Cria Armazem - [x] 7.1.2 ==> cabo (${cab}) | ${new Date().toString()}`);
      const pivoCabo = new BABYLON.TransformNode(
        `pivoCabo${objArco.cables[cab].cab}`,
      );

      // console.log(`Cabo ${objArco.cables[cab].cab}`);
      pivoCabo.parent = objetoArco;
      const objCabo = objArco.cables[cab];

      // if (cab > -1) {
      //   objCabo.haveMotor = 1;
      //   objCabo.info = [{
      //     id: 119,
      //     nameMotor: 'Motor 116',
      //     operation: 'A',
      //     onOff: 'D',
      //     panelMotorActive: false,
      //     status: 1,
      //     aeration: "05:59"
      //   }, {
      //     id: 120,
      //     nameMotor: 'Motor 117',
      //     operation: 'A',
      //     onOff: 'D',
      //     panelMotorActive: false,
      //     status: 1,
      //     aeration: "05:59"
      //   }]
      // }

      if (arc === objetoSilo.length - 1 && cab === 0) {
        // CRIA TEXTURA METALICA DO ANEL DE SEGURANÇA
        const texturaAnelSeguranca2 = new BABYLON.StandardMaterial(
          'texturaAnelSeguranca2',
          scene,
        );
        texturaAnelSeguranca2.diffuseTexture = new BABYLON.Texture(
          `${originalTheme.textures.amareloSeguranca}`,
          scene,
        );
        texturaAnelSeguranca2.diffuseTexture.uScale = 10;
        texturaAnelSeguranca2.diffuseTexture.vScale = 1;
        texturaAnelSeguranca2.specularColor = new BABYLON.Color3(0, 0, 0);

        // CRIA TEXTURA METALICA PARA OS TUBOS DA ESCADA
        const texturaTubosLateraisEscada2 = new BABYLON.StandardMaterial(
          'texturaEscada2',
          scene,
        );
        texturaTubosLateraisEscada2.diffuseTexture = new BABYLON.Texture(
          `${originalTheme.textures.texturaAmarelaParaEscada}`,
          scene,
        );
        texturaTubosLateraisEscada2.diffuseTexture.uScale = 10;
        texturaTubosLateraisEscada2.diffuseTexture.vScale = 1;
        texturaTubosLateraisEscada2.specularColor = new BABYLON.Color3(0, 0, 0);

        // CRIA TEXTURA METALICA PARA OS TUBOS DA ESCADA
        const texturadegrauEscada2 = new BABYLON.StandardMaterial(
          'degrauescada2',
          scene,
        );
        texturadegrauEscada2.diffuseTexture = new BABYLON.Texture(
          `${originalTheme.textures.degrauEscada}`,
          scene,
        );
        texturadegrauEscada2.diffuseTexture.uScale = 10;
        texturadegrauEscada2.diffuseTexture.vScale = 1;
        texturadegrauEscada2.specularColor = new BABYLON.Color3(0, 0, 0);

        // CRIA O GUARDA CORPO
        const guardaCorpoEscada = [];
        guardaCorpoEscada.push(new BABYLON.Vector3(0.0, 0, 0.0));
        guardaCorpoEscada.push(new BABYLON.Vector3(0.0, 0.1, 0.0));

        // CRIA COROA 1
        const guardaCorpo2 = new BABYLON.MeshBuilder.CreateTube(
          'objetoGuardaCorpoEscada0',
          {
            path: guardaCorpoEscada,
            tessellation: 60,
            radius: 0.8,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
          },
          scene,
        );

        // CRIA O TUBO LATERAL DA ESCADA
        const tuboLateralEscada = [];
        tuboLateralEscada.push(new BABYLON.Vector3(0.0, 0, 0.0));
        tuboLateralEscada.push(
          new BABYLON.Vector3(0.0, alturaSilo - 1.5 + 1, 0.0),
        );

        // CRIA TUBO ESQUERDO DA ESCADA
        const tuboEscadaEsquerdo = new BABYLON.MeshBuilder.CreateTube(
          'tuboEscadaEsq',
          {
            path: tuboLateralEscada,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
          },
          scene,
        );

        // CRIA TUBO DIREITO DA ESCADA
        const tuboEscadaDireito = new BABYLON.MeshBuilder.CreateTube(
          'tuboEscadaDir',
          {
            path: tuboLateralEscada,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
          },
          scene,
        );

        tuboEscadaEsquerdo.material = texturaTubosLateraisEscada2;
        tuboEscadaEsquerdo.position.y = 0;

        // CRIA O TUBO LATERAL DA ESCADA
        const escalaTubodegrauescada = [];
        escalaTubodegrauescada.push(new BABYLON.Vector3(0, 0.1, 0));
        escalaTubodegrauescada.push(new BABYLON.Vector3(1, 0.1, 0));

        // CRIA O TUBO LATERAL DA ESCADA
        const escalaTuboApoioEscada = [];
        escalaTuboApoioEscada.push(new BABYLON.Vector3(0, 3, 0));
        escalaTuboApoioEscada.push(new BABYLON.Vector3(0, 3, -1.3));

        // CRIA TUBO DEGRAU DA ESCADA
        const tuboDegrauEscada = new BABYLON.MeshBuilder.CreateTube(
          'objetoDegrau0',
          {
            path: escalaTubodegrauescada,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        // CRIA TUBO DEGRAU DA ESCADA
        const tuboApoioLateralEscada = new BABYLON.MeshBuilder.CreateTube(
          'objetoApoioEsq0',
          {
            path: escalaTuboApoioEscada,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        // CRIA TUBO DEGRAU DA ESCADA
        const tuboApoioLateralEscadaDireita = new BABYLON.MeshBuilder.CreateTube(
          'objetoApoioDir0',
          {
            path: escalaTuboApoioEscada,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        tuboEscadaEsquerdo.position.z = raioSilo + 1;
        tuboEscadaEsquerdo.isPickable = false;
        tuboEscadaEsquerdo.visibility = escada_visivel;
        tuboEscadaEsquerdo.parent = pivoCabo;

        tuboEscadaDireito.material = texturaTubosLateraisEscada2;
        tuboEscadaDireito.position.y = 0;
        tuboEscadaDireito.position.x = 1;
        tuboEscadaDireito.position.z = 0;
        tuboEscadaDireito.isPickable = false;
        tuboEscadaDireito.visibility = escada_visivel;
        tuboEscadaDireito.parent = tuboEscadaEsquerdo;

        let alturaArredondada = Math.round(alturaSilo - 1.5 + 0.5);
        alturaArredondada /= 6;
        let alturaArredondadaParaGuardaCorpoDaEscada = Math.round(
          alturaSilo - 1.5,
        );
        alturaArredondadaParaGuardaCorpoDaEscada /= 10;

        for (let i = 0; i < alturaVzDoisArredondada - 1; i++) {
          var objetoDegrau;
          if (i === 0) {
            objetoDegrau = tuboDegrauEscada;
          } else {
            objetoDegrau = tuboDegrauEscada.clone(`objetoDegrau${i}`);
          }

          objetoDegrau.material = texturaTubosLateraisEscada2;
          if (i === 0) {
            objetoDegrau.position.y = 0.1;
          } else {
            objetoDegrau.position.y = i / 2;
          }
          objetoDegrau.position.x = 0;
          objetoDegrau.position.z = 0;
          objetoDegrau.isPickable = false;
          objetoDegrau.parent = tuboEscadaEsquerdo;
          objetoDegrau.visibility = escada_visivel;
        }

        if (alturaSilo < 5) {
          for (let i = 0; i < 2; i++) {
            var objetoApoioEsq;
            if (i === 0) {
              objetoApoioEsq = tuboApoioLateralEscada;
            } else {
              objetoApoioEsq = tuboApoioLateralEscada.clone(
                `objetoApoioEsq${i}`,
              );
            }

            objetoApoioEsq.material = texturaTubosLateraisEscada2;
            if (i === 0) {
              objetoApoioEsq.position.y = 0.1;
            } else {
              objetoApoioEsq.position.y = i * alturaArredondada;
            }

            objetoApoioEsq.position.x = 0;
            objetoApoioEsq.position.z = 0;
            objetoApoioEsq.isPickable = false;
            objetoApoioEsq.visibility = escada_visivel;
            objetoApoioEsq.parent = tuboEscadaEsquerdo;
          }

          for (let i = 0; i < 2; i++) {
            var objetoApoioDir;
            if (i === 0) {
              objetoApoioDir = tuboApoioLateralEscadaDireita;
            } else {
              objetoApoioDir = tuboApoioLateralEscadaDireita.clone(
                `objetoApoioDir${i}`,
              );
            }

            if (i === 0) {
              objetoApoioDir.position.y = 0.1;
            } else {
              objetoApoioDir.position.y = i * alturaArredondada;
            }

            objetoApoioDir.material = texturaTubosLateraisEscada2;
            objetoApoioDir.position.x = 0;
            objetoApoioDir.position.z = 0;
            objetoApoioDir.isPickable = false;
            objetoApoioDir.visibility = escada_visivel;
            objetoApoioDir.parent = tuboEscadaDireito;
          }
        } else if (alturaSilo < 10) {
          for (let i = 0; i < 4; i++) {
            var objetoApoioEsq;
            if (i === 0) {
              objetoApoioEsq = tuboApoioLateralEscada;
            } else {
              objetoApoioEsq = tuboApoioLateralEscada.clone(
                `objetoApoioEsq${i}`,
              );
            }

            objetoApoioEsq.material = texturaTubosLateraisEscada2;
            if (i === 0) {
              objetoApoioEsq.position.y = 0.1;
            } else {
              objetoApoioEsq.position.y = i * alturaArredondada;
            }

            objetoApoioEsq.position.x = 0;
            objetoApoioEsq.position.z = 0;
            objetoApoioEsq.isPickable = false;
            objetoApoioEsq.visibility = escada_visivel;
            objetoApoioEsq.parent = tuboEscadaEsquerdo;
          }

          for (let i = 0; i < 4; i++) {
            var objetoApoioDir;
            if (i === 0) {
              objetoApoioDir = tuboApoioLateralEscadaDireita;
            } else {
              objetoApoioDir = tuboApoioLateralEscadaDireita.clone(
                `objetoApoioDir${i}`,
              );
            }

            if (i === 0) {
              objetoApoioDir.position.y = 0.1;
            } else {
              objetoApoioDir.position.y = i * alturaArredondada;
            }

            objetoApoioDir.material = texturaTubosLateraisEscada2;
            objetoApoioDir.position.x = 0;
            objetoApoioDir.position.z = 0;
            objetoApoioDir.isPickable = false;
            objetoApoioDir.visibility = escada_visivel;
            objetoApoioDir.parent = tuboEscadaDireito;
          }
        } else {
          for (let i = 0; i < 5; i++) {
            var objetoApoioEsq;
            if (i === 0) {
              objetoApoioEsq = tuboApoioLateralEscada;
            } else {
              objetoApoioEsq = tuboApoioLateralEscada.clone(
                `objetoApoioEsq${i}`,
              );
            }

            objetoApoioEsq.material = texturaTubosLateraisEscada2;
            if (i === 0) {
              objetoApoioEsq.position.y = 0.1;
            } else {
              objetoApoioEsq.position.y = i * alturaArredondada;
            }

            objetoApoioEsq.position.x = 0;
            objetoApoioEsq.position.z = 0;
            objetoApoioEsq.isPickable = false;
            objetoApoioEsq.visibility = escada_visivel;
            objetoApoioEsq.parent = tuboEscadaEsquerdo;
          }

          for (let i = 0; i < 5; i++) {
            var objetoApoioDir;
            if (i === 0) {
              objetoApoioDir = tuboApoioLateralEscadaDireita;
            } else {
              objetoApoioDir = tuboApoioLateralEscadaDireita.clone(
                `objetoApoioDir${i}`,
              );
            }

            if (i === 0) {
              objetoApoioDir.position.y = 0.1;
            } else {
              objetoApoioDir.position.y = i * alturaArredondada;
            }

            objetoApoioDir.material = texturaTubosLateraisEscada2;
            objetoApoioDir.position.x = 0;
            objetoApoioDir.position.z = 0;
            objetoApoioDir.isPickable = false;
            objetoApoioDir.visibility = escada_visivel;
            objetoApoioDir.parent = tuboEscadaDireito;
          }
        }

        for (let i = 2; i < 11; i++) {
          var objetoGuardaCorpoEscada;
          if (i === 2) {
            // console.log('primeiro apoio =============');
            // console.log(guardaCorpo2);
            objetoGuardaCorpoEscada = guardaCorpo2;
          } else if (i > 2) {
            objetoGuardaCorpoEscada = guardaCorpo2.clone(
              `objetoGuardaCorpoEscada${i}`,
            );
          }
          if (i === 10) {
            objetoGuardaCorpoEscada.position.y =
              i * alturaArredondadaParaGuardaCorpoDaEscada;
          } else {
            objetoGuardaCorpoEscada.position.y =
              i * alturaArredondadaParaGuardaCorpoDaEscada;
          }
          objetoGuardaCorpoEscada.material = texturaTubosLateraisEscada2;
          objetoGuardaCorpoEscada.position.x = -0.5;
          objetoGuardaCorpoEscada.position.z = 0.2;
          objetoGuardaCorpoEscada.isPickable = false;
          objetoGuardaCorpoEscada.visibility = escada_visivel;
          objetoGuardaCorpoEscada.parent = tuboEscadaDireito;
        }

        // CRIA AS BARRAS INTERNAS DO GUARDA CORPO ACOPLADO NA ESCADA
        const plane = BABYLON.MeshBuilder.CreatePlane('plane0', {
          height: alturaArredondadaParaGuardaCorpoDaEscada * 8 + 0.5,
          width: 0.1,
          sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          updatable: false,
        });
        plane.material = texturaTubosLateraisEscada2;
        plane.position.y = 6 * alturaArredondadaParaGuardaCorpoDaEscada;
        plane.position.x = 0.27;
        plane.position.z = 0;
        plane.isPickable = false;
        plane.visibility = escada_visivel;
        plane.parent = tuboEscadaDireito;
        plane.rotation = new BABYLON.Vector3(0, 7.8, 0);

        // CRIA AS BARRAS INTERNAS DO GUARDA CORPO ACOPLADO NA ESCADA
        const plane2 = BABYLON.MeshBuilder.CreatePlane('plane2', {
          height: alturaArredondadaParaGuardaCorpoDaEscada * 8 + 0.5,
          width: 0.1,
          size: 5,
          sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          updatable: false,
        });
        plane2.material = texturaTubosLateraisEscada2;
        plane2.position.y = 6 * alturaArredondadaParaGuardaCorpoDaEscada;
        plane2.position.x = -0.5;
        plane2.position.z = -0.6;
        plane2.isPickable = false;
        plane2.visibility = escada_visivel;
        plane2.parent = tuboEscadaDireito;

        // CRIA AS BARRAS INTERNAS DO GUARDA CORPO ACOPLADO NA ESCADA
        const plane3 = BABYLON.MeshBuilder.CreatePlane('plane3', {
          height: alturaArredondadaParaGuardaCorpoDaEscada * 8 + 0.5,
          width: 0.1,
          sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          updatable: false,
        });
        plane3.material = texturaTubosLateraisEscada2;
        plane3.position.y = 6 * alturaArredondadaParaGuardaCorpoDaEscada;
        plane3.position.x = -0.27;
        plane3.position.z = 0;
        plane3.isPickable = false;
        plane3.visibility = escada_visivel;
        plane3.parent = tuboEscadaEsquerdo;
        plane3.rotation = new BABYLON.Vector3(0, 7.8, 0);

        // CRIA AS BARRAS INTERNAS DO GUARDA CORPO ACOPLADO NA ESCADA
        const plane4 = BABYLON.MeshBuilder.CreatePlane('plane4', {
          height: alturaArredondadaParaGuardaCorpoDaEscada * 8 + 0.5,
          width: 0.1,
          size: 5,
          sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          updatable: false,
        });
        plane4.material = texturaTubosLateraisEscada2;
        plane4.position.y = 6 * alturaArredondadaParaGuardaCorpoDaEscada;
        plane4.position.x = -0.5;
        plane4.position.z = 1;
        plane4.isPickable = false;
        plane4.visibility = escada_visivel;
        plane4.parent = tuboEscadaDireito;

        // Poligono da superfície
        const forma = [
          new BABYLON.Vector3(-2.4, 0, -1.4),
          new BABYLON.Vector3(-2.4, 0, 1.2),
          new BABYLON.Vector3(0.6, 0, 1.2),
          new BABYLON.Vector3(0.6, 0, -1.4),
        ];

        // Buraco da superfície
        const buracoChao = [];
        buracoChao.push([
          new BABYLON.Vector3(-1.4, 0, -1.1),
          new BABYLON.Vector3(-1.4, 0, 0.6),
          new BABYLON.Vector3(0.3, 0, 0.6),
          new BABYLON.Vector3(0.3, 0, -1.1),
        ]);

        const baseEscadaSilo = BABYLON.MeshBuilder.CreatePolygon(
          'polygon',
          {
            shape: forma,
            holes: buracoChao,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          },
          scene,
          MyEarcut,
        );
        baseEscadaSilo.isPickable = false;
        baseEscadaSilo.checkCollisions = true;
        baseEscadaSilo.material = texturaTubosLateraisEscada2;
        baseEscadaSilo.position.y = alturaSilo - 1.5 + 0.4;
        baseEscadaSilo.rotation = new BABYLON.Vector3(0, 3.2, 0);
        baseEscadaSilo.parent = tuboEscadaEsquerdo;

        // CRIA O TUBO DO GUARDA CORPO SUPERIOR
        const VetoresTuboVerticalGuardaCorpoSuperior = [];
        VetoresTuboVerticalGuardaCorpoSuperior.push(
          new BABYLON.Vector3(0.0, 0, 0.0),
        );
        VetoresTuboVerticalGuardaCorpoSuperior.push(
          new BABYLON.Vector3(0.0, 1.65, 0.0),
        );

        const tuboVerticalGuardaCorpoSuperior = new BABYLON.MeshBuilder.CreateTube(
          'tuboVerticalGuardaCorpo0',
          {
            path: VetoresTuboVerticalGuardaCorpoSuperior,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
          },
          scene,
        );

        for (let i = 0; i < 7; i++) {
          var tuboVerticalGuardaCorpo;
          if (i === 0) {
            // console.log('primeiro apoio =============');
            // console.log(tuboVerticalGuardaCorpoSuperior);
            tuboVerticalGuardaCorpo = tuboVerticalGuardaCorpoSuperior;
          } else {
            tuboVerticalGuardaCorpo = tuboVerticalGuardaCorpoSuperior.clone(
              `tuboVerticalGuardaCorpo${i}`,
            );
          }
          if (i === 0) {
            tuboVerticalGuardaCorpo.position.x = -2.3;
            tuboVerticalGuardaCorpo.position.z = -1.3;
            tuboVerticalGuardaCorpo.isPickable = false;
            tuboVerticalGuardaCorpo.visibility = escada_visivel;
            tuboVerticalGuardaCorpo.parent = baseEscadaSilo;
          } else if (i === 1) {
            tuboVerticalGuardaCorpo.position.x = -1.25;
            tuboVerticalGuardaCorpo.position.z = -1.3;
            tuboVerticalGuardaCorpo.isPickable = false;
            tuboVerticalGuardaCorpo.visibility = escada_visivel;
            tuboVerticalGuardaCorpo.parent = baseEscadaSilo;
          } else if (i === 2) {
            tuboVerticalGuardaCorpo.position.x = 0.5;
            tuboVerticalGuardaCorpo.position.z = -1.3;
            tuboVerticalGuardaCorpo.isPickable = false;
            tuboVerticalGuardaCorpo.visibility = escada_visivel;
            tuboVerticalGuardaCorpo.parent = baseEscadaSilo;
          } else if (i === 3) {
            tuboVerticalGuardaCorpo.position.x = 0.5;
            tuboVerticalGuardaCorpo.position.z = 1;
            tuboVerticalGuardaCorpo.isPickable = false;
            tuboVerticalGuardaCorpo.visibility = escada_visivel;
            tuboVerticalGuardaCorpo.parent = baseEscadaSilo;
          } else if (i === 4) {
            tuboVerticalGuardaCorpo.position.x = -2.3;
            tuboVerticalGuardaCorpo.position.z = 1;
            tuboVerticalGuardaCorpo.isPickable = false;
            tuboVerticalGuardaCorpo.visibility = escada_visivel;
            tuboVerticalGuardaCorpo.parent = baseEscadaSilo;
          } else if (i === 5) {
            tuboVerticalGuardaCorpo.position.x = -1.3;
            tuboVerticalGuardaCorpo.position.z = 1;
            tuboVerticalGuardaCorpo.isPickable = false;
            tuboVerticalGuardaCorpo.visibility = escada_visivel;
            tuboVerticalGuardaCorpo.parent = baseEscadaSilo;
          }

          tuboVerticalGuardaCorpo.material = texturaTubosLateraisEscada2;
          tuboVerticalGuardaCorpo.position.y = 0;
        }

        // CRIA O TUBO LATERAL DA ESCADA
        const vetoresTuboHorizontalGuardaCorpoSuperior = [];
        vetoresTuboHorizontalGuardaCorpoSuperior.push(
          new BABYLON.Vector3(0, 0.1, 0),
        );
        vetoresTuboHorizontalGuardaCorpoSuperior.push(
          new BABYLON.Vector3(2.8, 0.1, 0),
        );

        // CRIA O TUBO LATERAL DA ESCADA
        const vetoresMiniTuboHorizontalGuardaCorpoSuperior = [];
        vetoresMiniTuboHorizontalGuardaCorpoSuperior.push(
          new BABYLON.Vector3(0, 0.1, 0),
        );
        vetoresMiniTuboHorizontalGuardaCorpoSuperior.push(
          new BABYLON.Vector3(1.8, 0.1, 0),
        );

        // CRIA TUBO DEGRAU DA ESCADA
        const tuboHorizontalGuardaCorpoSuperior = new BABYLON.MeshBuilder.CreateTube(
          'tuboHorizontalGuardaCorpo0',
          {
            path: vetoresTuboHorizontalGuardaCorpoSuperior,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        // CRIA TUBO DEGRAU DA ESCADA
        const miniTuboHorizontalGuardaCorpoSuperior = new BABYLON.MeshBuilder.CreateTube(
          'miniTuboHorizontalGuardaCorpo0',
          {
            path: vetoresMiniTuboHorizontalGuardaCorpoSuperior,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        for (let i = 0; i < 3; i++) {
          var tuboHorizontalGuardaCorpo;
          if (i === 0) {
            // console.log('primeiro apoio =============');
            // console.log(tuboHorizontalGuardaCorpoSuperior);
            tuboHorizontalGuardaCorpo = tuboHorizontalGuardaCorpoSuperior;
          } else {
            tuboHorizontalGuardaCorpo = tuboHorizontalGuardaCorpoSuperior.clone(
              `tuboHorizontalGuardaCorpo${i}`,
            );
          }
          if (i === 0) {
            tuboHorizontalGuardaCorpo.position.x = -2.3;
            tuboHorizontalGuardaCorpo.position.z = -1.3;
            tuboHorizontalGuardaCorpo.isPickable = false;
            tuboHorizontalGuardaCorpo.visibility = escada_visivel;
            tuboHorizontalGuardaCorpo.parent = baseEscadaSilo;
            tuboHorizontalGuardaCorpo.position.y = 0.5;
          } else if (i === 1) {
            tuboHorizontalGuardaCorpo.position.x = -2.3;
            tuboHorizontalGuardaCorpo.position.z = -1.3;
            tuboHorizontalGuardaCorpo.isPickable = false;
            tuboHorizontalGuardaCorpo.visibility = escada_visivel;
            tuboHorizontalGuardaCorpo.parent = baseEscadaSilo;
            tuboHorizontalGuardaCorpo.position.y = 1.0;
          } else if (i === 2) {
            tuboHorizontalGuardaCorpo.position.x = -2.3;
            tuboHorizontalGuardaCorpo.position.z = -1.3;
            tuboHorizontalGuardaCorpo.isPickable = false;
            tuboHorizontalGuardaCorpo.visibility = escada_visivel;
            tuboHorizontalGuardaCorpo.parent = baseEscadaSilo;
            tuboHorizontalGuardaCorpo.position.y = 1.5;
          }

          tuboHorizontalGuardaCorpo.material = texturaTubosLateraisEscada2;
        }

        for (let i = 0; i < 3; i++) {
          var miniTuboHorizontalGuardaCorpo;
          if (i === 0) {
            // console.log('primeiro apoio =============');
            // console.log(miniTuboHorizontalGuardaCorpoSuperior);
            miniTuboHorizontalGuardaCorpo = miniTuboHorizontalGuardaCorpoSuperior;
          } else {
            miniTuboHorizontalGuardaCorpo = miniTuboHorizontalGuardaCorpoSuperior.clone(
              `miniTuboHorizontalGuardaCorpo${i}`,
            );
          }
          if (i === 0) {
            miniTuboHorizontalGuardaCorpo.position.x = -1.3;
            miniTuboHorizontalGuardaCorpo.position.z = 1;
            miniTuboHorizontalGuardaCorpo.isPickable = false;
            miniTuboHorizontalGuardaCorpo.visibility = escada_visivel;
            miniTuboHorizontalGuardaCorpo.parent = baseEscadaSilo;
            miniTuboHorizontalGuardaCorpo.position.y = 0.5;
          } else if (i === 1) {
            miniTuboHorizontalGuardaCorpo.position.x = -1.3;
            miniTuboHorizontalGuardaCorpo.position.z = 1;
            miniTuboHorizontalGuardaCorpo.isPickable = false;
            miniTuboHorizontalGuardaCorpo.visibility = escada_visivel;
            miniTuboHorizontalGuardaCorpo.parent = baseEscadaSilo;
            miniTuboHorizontalGuardaCorpo.position.y = 1.0;
          } else if (i === 2) {
            miniTuboHorizontalGuardaCorpo.position.x = -1.3;
            miniTuboHorizontalGuardaCorpo.position.z = 1;
            miniTuboHorizontalGuardaCorpo.isPickable = false;
            miniTuboHorizontalGuardaCorpo.visibility = escada_visivel;
            miniTuboHorizontalGuardaCorpo.parent = baseEscadaSilo;
            miniTuboHorizontalGuardaCorpo.position.y = 1.5;
          }

          miniTuboHorizontalGuardaCorpo.material = texturaTubosLateraisEscada2;
        }

        // CRIA O TUBO LATERAL DA ESCADA
        const vetoresTuboHorizontalGuardaCorpoSuperiorLateral = [];
        vetoresTuboHorizontalGuardaCorpoSuperiorLateral.push(
          new BABYLON.Vector3(0, 0.1, 0),
        );
        vetoresTuboHorizontalGuardaCorpoSuperiorLateral.push(
          new BABYLON.Vector3(0, 0.1, 2.3),
        );

        // CRIA TUBO DEGRAU DA ESCADA
        const tuboHorizontalGuardaCorpoSuperiorLateral = new BABYLON.MeshBuilder.CreateTube(
          'tuboHorizontalGuardaCorpoLateral0',
          {
            path: vetoresTuboHorizontalGuardaCorpoSuperiorLateral,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        for (let i = 0; i < 6; i++) {
          var tuboHorizontalGuardaCorpoLateral;
          if (i === 0) {
            tuboHorizontalGuardaCorpoLateral = tuboHorizontalGuardaCorpoSuperiorLateral;
          } else {
            tuboHorizontalGuardaCorpoLateral = tuboHorizontalGuardaCorpoSuperiorLateral.clone(
              `tuboHorizontalGuardaCorpoLateral${i}`,
            );
          }
          if (i === 0) {
            tuboHorizontalGuardaCorpoLateral.position.x = -2.3;
            tuboHorizontalGuardaCorpoLateral.position.z = -1.3;
            tuboHorizontalGuardaCorpoLateral.isPickable = false;
            tuboHorizontalGuardaCorpoLateral.visibility = escada_visivel;
            tuboHorizontalGuardaCorpoLateral.parent = baseEscadaSilo;
            tuboHorizontalGuardaCorpoLateral.position.y = 0.5;
          } else if (i === 1) {
            tuboHorizontalGuardaCorpoLateral.position.x = -2.3;
            tuboHorizontalGuardaCorpoLateral.position.z = -1.3;
            tuboHorizontalGuardaCorpoLateral.isPickable = false;
            tuboHorizontalGuardaCorpoLateral.visibility = escada_visivel;
            tuboHorizontalGuardaCorpoLateral.parent = baseEscadaSilo;
            tuboHorizontalGuardaCorpoLateral.position.y = 1.0;
          } else if (i === 2) {
            tuboHorizontalGuardaCorpoLateral.position.x = -2.3;
            tuboHorizontalGuardaCorpoLateral.position.z = -1.3;
            tuboHorizontalGuardaCorpoLateral.isPickable = false;
            tuboHorizontalGuardaCorpoLateral.visibility = escada_visivel;
            tuboHorizontalGuardaCorpoLateral.parent = baseEscadaSilo;
            tuboHorizontalGuardaCorpoLateral.position.y = 1.5;
          } else if (i === 3) {
            tuboHorizontalGuardaCorpoLateral.position.x = 0.5;
            tuboHorizontalGuardaCorpoLateral.position.z = -1.3;
            tuboHorizontalGuardaCorpoLateral.isPickable = false;
            tuboHorizontalGuardaCorpoLateral.visibility = escada_visivel;
            tuboHorizontalGuardaCorpoLateral.parent = baseEscadaSilo;
            tuboHorizontalGuardaCorpoLateral.position.y = 0.5;
          } else if (i === 4) {
            tuboHorizontalGuardaCorpoLateral.position.x = 0.5;
            tuboHorizontalGuardaCorpoLateral.position.z = -1.3;
            tuboHorizontalGuardaCorpoLateral.isPickable = false;
            tuboHorizontalGuardaCorpoLateral.visibility = escada_visivel;
            tuboHorizontalGuardaCorpoLateral.parent = baseEscadaSilo;
            tuboHorizontalGuardaCorpoLateral.position.y = 1.0;
          } else if (i === 5) {
            tuboHorizontalGuardaCorpoLateral.position.x = 0.5;
            tuboHorizontalGuardaCorpoLateral.position.z = -1.3;
            tuboHorizontalGuardaCorpoLateral.isPickable = false;
            tuboHorizontalGuardaCorpoLateral.visibility = escada_visivel;
            tuboHorizontalGuardaCorpoLateral.parent = baseEscadaSilo;
            tuboHorizontalGuardaCorpoLateral.position.y = 1.5;
          }

          tuboHorizontalGuardaCorpoLateral.material = texturaTubosLateraisEscada2;
        }

        const anguloCone2 = 29;

        // CRIA O TUBO LATERAL DA ESCADA
        const vetoresEscadaDiagonal = [];
        vetoresEscadaDiagonal.push(new BABYLON.Vector3(0, 0, 0));
        vetoresEscadaDiagonal.push(
          new BABYLON.Vector3(
            0.6,
            raioSilo * Math.tan((anguloCone2 * Math.PI) / 180) + 0.35,
            raioSilo,
          ),
        );

        const vetoresGuardaCorpoSuperiorDiagonal1 = [];
        vetoresGuardaCorpoSuperiorDiagonal1.push(new BABYLON.Vector3(0, 0, 0));
        vetoresGuardaCorpoSuperiorDiagonal1.push(
          new BABYLON.Vector3(
            0.6,
            raioSilo * Math.tan((anguloCone2 * Math.PI) / 180) + 0.35,
            raioSilo,
          ),
        );

        // CRIA TUBO DEGRAU DA ESCADA
        const tuboEscadaDiagonal = new BABYLON.MeshBuilder.CreateTube(
          'tuboEscadaDiagonal',
          {
            path: vetoresEscadaDiagonal,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        // CRIA TUBO DEGRAU DA ESCADA
        const tuboEscadaDiagonalDireita = new BABYLON.MeshBuilder.CreateTube(
          'tuboEscadaDiagonalDireita',
          {
            path: vetoresEscadaDiagonal,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        // CRIA TUBO DEGRAU DA ESCADA
        const guardaCorpoSuperiorDiagonal1 = new BABYLON.MeshBuilder.CreateTube(
          'guardaCorpoSuperiorDiagonal1',
          {
            path: vetoresGuardaCorpoSuperiorDiagonal1,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        // CRIA TUBO DEGRAU DA ESCADA
        const guardaCorpoSuperiorDiagonal2 = new BABYLON.MeshBuilder.CreateTube(
          'guardaCorpoSuperiorDiagonal2',
          {
            path: vetoresGuardaCorpoSuperiorDiagonal1,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        // CRIA TUBO DEGRAU DA ESCADA
        const guardaCorpoSuperiorDiagonal3 = new BABYLON.MeshBuilder.CreateTube(
          'guardaCorpoSuperiorDiagonal3',
          {
            path: vetoresGuardaCorpoSuperiorDiagonal1,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        // CRIA TUBO DEGRAU DA ESCADA
        const guardaCorpoSuperiorDiagonal4 = new BABYLON.MeshBuilder.CreateTube(
          'guardaCorpoSuperiorDiagonal4',
          {
            path: vetoresGuardaCorpoSuperiorDiagonal1,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        // CRIA TUBO DEGRAU DA ESCADA
        const guardaCorpoSuperiorDiagonal5 = new BABYLON.MeshBuilder.CreateTube(
          'guardaCorpoSuperiorDiagonal5',
          {
            path: vetoresGuardaCorpoSuperiorDiagonal1,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        // CRIA TUBO DEGRAU DA ESCADA
        const guardaCorpoSuperiorDiagonal6 = new BABYLON.MeshBuilder.CreateTube(
          'guardaCorpoSuperiorDiagonal6',
          {
            path: vetoresGuardaCorpoSuperiorDiagonal1,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        tuboEscadaDiagonal.material = texturaTubosLateraisEscada2;
        tuboEscadaDiagonal.position.x = -2.3;
        tuboEscadaDiagonal.position.z = 0.8;
        tuboEscadaDiagonal.isPickable = false;
        tuboEscadaDiagonal.visibility = escada_visivel;
        tuboEscadaDiagonal.parent = baseEscadaSilo;
        tuboEscadaDiagonal.position.y = 0.2;

        tuboEscadaDiagonalDireita.material = texturaTubosLateraisEscada2;
        tuboEscadaDiagonalDireita.position.x = 1;
        tuboEscadaDiagonalDireita.position.z = 0;
        tuboEscadaDiagonalDireita.isPickable = false;
        tuboEscadaDiagonalDireita.visibility = escada_visivel;
        tuboEscadaDiagonalDireita.parent = tuboEscadaDiagonal;
        tuboEscadaDiagonalDireita.position.y = 0;

        guardaCorpoSuperiorDiagonal1.material = texturaTubosLateraisEscada2;
        guardaCorpoSuperiorDiagonal1.position.x = -2.3;
        guardaCorpoSuperiorDiagonal1.position.z = 0.8;
        guardaCorpoSuperiorDiagonal1.isPickable = false;
        guardaCorpoSuperiorDiagonal1.visibility = escada_visivel;
        guardaCorpoSuperiorDiagonal1.parent = baseEscadaSilo;
        guardaCorpoSuperiorDiagonal1.position.y = 0.55;

        guardaCorpoSuperiorDiagonal2.material = texturaTubosLateraisEscada2;
        guardaCorpoSuperiorDiagonal2.position.x = -2.3;
        guardaCorpoSuperiorDiagonal2.position.z = 0.8;
        guardaCorpoSuperiorDiagonal2.isPickable = false;
        guardaCorpoSuperiorDiagonal2.visibility = escada_visivel;
        guardaCorpoSuperiorDiagonal2.parent = baseEscadaSilo;
        guardaCorpoSuperiorDiagonal2.position.y = 1.05;

        guardaCorpoSuperiorDiagonal3.material = texturaTubosLateraisEscada2;
        guardaCorpoSuperiorDiagonal3.position.x = -2.3;
        guardaCorpoSuperiorDiagonal3.position.z = 0.8;
        guardaCorpoSuperiorDiagonal3.isPickable = false;
        guardaCorpoSuperiorDiagonal3.visibility = escada_visivel;
        guardaCorpoSuperiorDiagonal3.parent = baseEscadaSilo;
        guardaCorpoSuperiorDiagonal3.position.y = 1.5;

        guardaCorpoSuperiorDiagonal4.material = texturaTubosLateraisEscada2;
        guardaCorpoSuperiorDiagonal4.position.x = -1.3;
        guardaCorpoSuperiorDiagonal4.position.z = 0.8;
        guardaCorpoSuperiorDiagonal4.isPickable = false;
        guardaCorpoSuperiorDiagonal4.visibility = escada_visivel;
        guardaCorpoSuperiorDiagonal4.parent = baseEscadaSilo;
        guardaCorpoSuperiorDiagonal4.position.y = 0.55;

        guardaCorpoSuperiorDiagonal5.material = texturaTubosLateraisEscada2;
        guardaCorpoSuperiorDiagonal5.position.x = -1.3;
        guardaCorpoSuperiorDiagonal5.position.z = 0.8;
        guardaCorpoSuperiorDiagonal5.isPickable = false;
        guardaCorpoSuperiorDiagonal5.visibility = escada_visivel;
        guardaCorpoSuperiorDiagonal5.parent = baseEscadaSilo;
        guardaCorpoSuperiorDiagonal5.position.y = 1.05;

        guardaCorpoSuperiorDiagonal6.material = texturaTubosLateraisEscada2;
        guardaCorpoSuperiorDiagonal6.position.x = -1.3;
        guardaCorpoSuperiorDiagonal6.position.z = 0.8;
        guardaCorpoSuperiorDiagonal6.isPickable = false;
        guardaCorpoSuperiorDiagonal6.visibility = escada_visivel;
        guardaCorpoSuperiorDiagonal6.parent = baseEscadaSilo;
        guardaCorpoSuperiorDiagonal6.position.y = 1.5;

        // CRIA O TUBO LATERAL DA ESCADA
        const escalaTubodegrauescadaDiagonal = [];
        escalaTubodegrauescadaDiagonal.push(new BABYLON.Vector3(0, 0, 0));
        escalaTubodegrauescadaDiagonal.push(new BABYLON.Vector3(1, 0, 0));

        // CRIA TUBO DEGRAU DA ESCADA
        const tuboDegrauEscadaDiagonal = new BABYLON.MeshBuilder.CreateTube(
          'tuboEscadaDegrauDiagonal0',
          {
            path: escalaTubodegrauescadaDiagonal,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
            invertUV: true,
          },
          scene,
        );

        const anguloCone3 = 29;

        const alturaDiagonalVzDoisArredondada = raioSilo * 2;
        // alturaDiagonalVzDoisArredondada = Math.round(alturaDiagonalVzDoisArredondada);
        const anguloDePosicionamento =
          raioSilo * Math.tan((anguloCone3 * Math.PI) / 180) + 0.35;
        // anguloDePosicionamento = Math.round(anguloDePosicionamento);

        for (let i = 0; i < alturaDiagonalVzDoisArredondada; i++) {
          var objetoDegrauDiagonal;
          if (i === 0) {
            objetoDegrauDiagonal = tuboDegrauEscadaDiagonal;
          } else {
            objetoDegrauDiagonal = tuboDegrauEscadaDiagonal.clone(
              `tuboEscadaDegrauDiagonal${i}`,
            );
          }

          objetoDegrauDiagonal.material = texturaTubosLateraisEscada2;
          if (i === 0) {
            objetoDegrauDiagonal.position.y = 0.1;
            objetoDegrauDiagonal.position.z = 0;
            objetoDegrauDiagonal.position.z = 0;
          } else {
            objetoDegrauDiagonal.position.y =
              i * (anguloDePosicionamento / alturaDiagonalVzDoisArredondada);
            objetoDegrauDiagonal.position.z =
              (i *
                (alturaDiagonalVzDoisArredondada /
                  alturaDiagonalVzDoisArredondada)) /
              2;
            objetoDegrauDiagonal.position.x =
              i * (0.6 / alturaDiagonalVzDoisArredondada);
          }
          objetoDegrauDiagonal.isPickable = false;
          objetoDegrauDiagonal.parent = tuboEscadaDiagonal;
          objetoDegrauDiagonal.visibility = escada_visivel;
        }

        // CRIA O TUBO DO GUARDA CORPO SUPERIOR
        const VetoresTuboVerticalGuardaCorpoSuperiorDiagonal = [];
        VetoresTuboVerticalGuardaCorpoSuperiorDiagonal.push(
          new BABYLON.Vector3(0.0, 0, 0.0),
        );
        VetoresTuboVerticalGuardaCorpoSuperiorDiagonal.push(
          new BABYLON.Vector3(0.0, 1.35, 0.0),
        );

        const tuboVerticalGuardaCorpoSuperiorDiagonal = new BABYLON.MeshBuilder.CreateTube(
          'tuboVerticalGuardaCorpoDiagonal0',
          {
            path: VetoresTuboVerticalGuardaCorpoSuperiorDiagonal,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
          },
          scene,
        );

        const tuboVerticalGuardaCorpoSuperiorDiagonalDireito = new BABYLON.MeshBuilder.CreateTube(
          'tuboVerticalGuardaCorpoDiagonalDireito0',
          {
            path: VetoresTuboVerticalGuardaCorpoSuperiorDiagonal,
            tessellation: 60,
            radius: 0.055,
            sideOrientation: BABYLON.Mesh.DOUBLESIDE,
            updatable: false,
          },
          scene,
        );

        for (let i = 0; i < 5; i++) {
          var objetoTuboVerticalDiagonal;
          if (i === 0) {
            objetoTuboVerticalDiagonal = tuboVerticalGuardaCorpoSuperiorDiagonal;
          } else {
            objetoTuboVerticalDiagonal = tuboVerticalGuardaCorpoSuperiorDiagonal.clone(
              `tuboVerticalGuardaCorpoDiagonal${i}`,
            );
          }

          objetoTuboVerticalDiagonal.material = texturaTubosLateraisEscada2;
          if (i === 0) {
            objetoTuboVerticalDiagonal.position.y =
              (alturaDiagonalVzDoisArredondada / 4) *
              (anguloDePosicionamento / alturaDiagonalVzDoisArredondada);
            objetoTuboVerticalDiagonal.position.z =
              ((alturaDiagonalVzDoisArredondada / 4) *
                (alturaDiagonalVzDoisArredondada /
                  alturaDiagonalVzDoisArredondada)) /
              2;
            objetoTuboVerticalDiagonal.position.x =
              (alturaDiagonalVzDoisArredondada / 4) *
              (0.6 / alturaDiagonalVzDoisArredondada);
          } else if (i === 2) {
            objetoTuboVerticalDiagonal.position.y =
              (alturaDiagonalVzDoisArredondada / 4) *
              2 *
              (anguloDePosicionamento / alturaDiagonalVzDoisArredondada);
            objetoTuboVerticalDiagonal.position.z =
              ((alturaDiagonalVzDoisArredondada / 4) *
                2 *
                (alturaDiagonalVzDoisArredondada /
                  alturaDiagonalVzDoisArredondada)) /
              2;
            objetoTuboVerticalDiagonal.position.x =
              (alturaDiagonalVzDoisArredondada / 4) *
              2 *
              (0.6 / alturaDiagonalVzDoisArredondada);
          } else if (i === 3) {
            objetoTuboVerticalDiagonal.position.y =
              (alturaDiagonalVzDoisArredondada / 4) *
              3 *
              (anguloDePosicionamento / alturaDiagonalVzDoisArredondada);
            objetoTuboVerticalDiagonal.position.z =
              ((alturaDiagonalVzDoisArredondada / 4) *
                3 *
                (alturaDiagonalVzDoisArredondada /
                  alturaDiagonalVzDoisArredondada)) /
              2;
            objetoTuboVerticalDiagonal.position.x =
              (alturaDiagonalVzDoisArredondada / 4) *
              3 *
              (0.6 / alturaDiagonalVzDoisArredondada);
          } else if (i === 4) {
            objetoTuboVerticalDiagonal.position.y =
              (alturaDiagonalVzDoisArredondada / 4) *
              4 *
              (anguloDePosicionamento / alturaDiagonalVzDoisArredondada);
            objetoTuboVerticalDiagonal.position.z =
              ((alturaDiagonalVzDoisArredondada / 4) *
                4 *
                (alturaDiagonalVzDoisArredondada /
                  alturaDiagonalVzDoisArredondada)) /
              2;
            objetoTuboVerticalDiagonal.position.x =
              (alturaDiagonalVzDoisArredondada / 4) *
              4 *
              (0.6 / alturaDiagonalVzDoisArredondada);
          }

          objetoTuboVerticalDiagonal.isPickable = false;
          objetoTuboVerticalDiagonal.parent = tuboEscadaDiagonal;
          objetoTuboVerticalDiagonal.visibility = escada_visivel;
        }

        for (let i = 0; i < 5; i++) {
          var objetoTuboVerticalDiagonalDireito;
          if (i === 0) {
            objetoTuboVerticalDiagonalDireito = tuboVerticalGuardaCorpoSuperiorDiagonalDireito;
          } else {
            objetoTuboVerticalDiagonalDireito = tuboVerticalGuardaCorpoSuperiorDiagonalDireito.clone(
              `tuboVerticalGuardaCorpoDiagonalDireito${i}`,
            );
          }

          objetoTuboVerticalDiagonalDireito.material = texturaTubosLateraisEscada2;
          if (i === 0) {
            objetoTuboVerticalDiagonalDireito.position.y =
              (alturaDiagonalVzDoisArredondada / 4) *
              (anguloDePosicionamento / alturaDiagonalVzDoisArredondada);
            objetoTuboVerticalDiagonalDireito.position.z =
              ((alturaDiagonalVzDoisArredondada / 4) *
                (alturaDiagonalVzDoisArredondada /
                  alturaDiagonalVzDoisArredondada)) /
              2;
            objetoTuboVerticalDiagonalDireito.position.x =
              (alturaDiagonalVzDoisArredondada / 4) *
              (0.6 / alturaDiagonalVzDoisArredondada);
          } else if (i === 2) {
            objetoTuboVerticalDiagonalDireito.position.y =
              (alturaDiagonalVzDoisArredondada / 4) *
              2 *
              (anguloDePosicionamento / alturaDiagonalVzDoisArredondada);
            objetoTuboVerticalDiagonalDireito.position.z =
              ((alturaDiagonalVzDoisArredondada / 4) *
                2 *
                (alturaDiagonalVzDoisArredondada /
                  alturaDiagonalVzDoisArredondada)) /
              2;
            objetoTuboVerticalDiagonalDireito.position.x =
              (alturaDiagonalVzDoisArredondada / 4) *
              2 *
              (0.6 / alturaDiagonalVzDoisArredondada);
          } else if (i === 3) {
            objetoTuboVerticalDiagonalDireito.position.y =
              (alturaDiagonalVzDoisArredondada / 4) *
              3 *
              (anguloDePosicionamento / alturaDiagonalVzDoisArredondada);
            objetoTuboVerticalDiagonalDireito.position.z =
              ((alturaDiagonalVzDoisArredondada / 4) *
                3 *
                (alturaDiagonalVzDoisArredondada /
                  alturaDiagonalVzDoisArredondada)) /
              2;
            objetoTuboVerticalDiagonalDireito.position.x =
              (alturaDiagonalVzDoisArredondada / 4) *
              3 *
              (0.6 / alturaDiagonalVzDoisArredondada);
          } else if (i === 4) {
            objetoTuboVerticalDiagonalDireito.position.y =
              (alturaDiagonalVzDoisArredondada / 4) *
              4 *
              (anguloDePosicionamento / alturaDiagonalVzDoisArredondada);
            objetoTuboVerticalDiagonalDireito.position.z =
              ((alturaDiagonalVzDoisArredondada / 4) *
                4 *
                (alturaDiagonalVzDoisArredondada /
                  alturaDiagonalVzDoisArredondada)) /
              2;
            objetoTuboVerticalDiagonalDireito.position.x =
              (alturaDiagonalVzDoisArredondada / 4) *
              4 *
              (0.6 / alturaDiagonalVzDoisArredondada);
          }

          objetoTuboVerticalDiagonalDireito.isPickable = false;
          objetoTuboVerticalDiagonalDireito.parent = tuboEscadaDiagonalDireita;
          objetoTuboVerticalDiagonalDireito.visibility = escada_visivel;
        }
      }

      if (arc === objetoSilo.length - 1 && objCabo.info !== null) {
        // console.log('DATA LAYOUT');
        // console.log(objCabo.info);
        if (objCabo.info) {
          let camada = 0;
          for (let i = 0; i < objCabo.info.length; i++) {
            // CLONA E CRIA BASE
            const id = objCabo.info[i].idMotor;
            // console.log(`vez == ${i} | id == ${id}`);
            const base = baseMotor3D.clone(`base${id}`);
            base.parent = pivoCabo;
            if (camada === 0) {
              base.position.z = raioSilo + 2;
            } else {
              base.position.z = raioSilo + 2 + camada * 6;
            }

            base.visibility = 1;

            // CRIA SUPORTE DO MOTOR
            const suporteDoMotor = suporteDoMotor3D.clone(`suporte${id}`);
            suporteDoMotor.parent = base;
            suporteDoMotor.visibility = 1;

            const suportePlacaNome = suporteDoMotor3D.clone(
              `suportePlacaNome${id}`,
            );
            suportePlacaNome.parent = base;
            suportePlacaNome.position.x = 0;
            suportePlacaNome.position.z = 2.2;
            suportePlacaNome.visibility = 0;

            const suportePlacaStatus = suporteDoMotor3D.clone(
              `suportePlacaStatus${id}`,
            );
            suportePlacaStatus.parent = base;
            suportePlacaStatus.position.x = 0;
            suportePlacaStatus.position.y = 1;
            suportePlacaStatus.position.z = 2;
            suportePlacaStatus.visibility = 0;

            // CLONA E CRIA MOTOR
            const motor = motor3D.clone(`motor${id}`);
            if (motor) {
              motor.parent = base;
              motor.info = objCabo.info[i];
              motor.visibility = 1;

              if (
                motor.info.status === 1 ||
                motor.info.status === 2 ||
                motor.info.status === 10
              ) {
                motor.material = colorMotorStatus3D3Shaded;
              } else if (motor.info.status === 3 || motor.info.status === 4) {
                motor.material = colorMotorStatus3D2Shaded;
              } else if (motor.info.status === 5 || motor.info.status === 6) {
                motor.material = colorMotorStatus3D4Shaded;
              } else if (
                motor.info.status === 7 ||
                motor.info.status === 8 ||
                motor.info.status === 9
              ) {
                motor.material = colorMotorStatus3D1Shaded;
              }

              motor.actionManager = new BABYLON.ActionManager(scene);

              motor.actionManager.registerAction(
                new BABYLON.ExecuteCodeAction(
                  BABYLON.ActionManager.OnPointerOverTrigger,
                  (ev) => {
                    switch (ev.meshUnderPointer.id) {
                      case ev.meshUnderPointer.id:
                        fcnSeeTooltipMotor(
                          ev.meshUnderPointer.info,
                          scene,
                          ev.meshUnderPointer,
                          ev.meshUnderPointer.absolutePosition,
                        );
                        break;
                    }
                  },
                ),
              );

              motor.actionManager.registerAction(
                new BABYLON.ExecuteCodeAction(
                  BABYLON.ActionManager.OnPointerOutTrigger,
                  (ev) => {
                    switch (ev.meshUnderPointer.id) {
                      case ev.meshUnderPointer.id:
                        fcnSeeTooltipMotor(
                          'remove',
                          scene,
                          ev.meshUnderPointer,
                          ev.meshUnderPointer.absolutePosition,
                        );
                        break;
                    }
                  },
                ),
              );
            }

            if (base) {
              LabelNameMotor(
                scene,
                motor.info.nameMotor,
                1.5,
                suportePlacaNome,
              );
            }

            if (motor && motor.info) {
              // CRIA CONJUNTO MOTO
              const conjuntoMotor = conjuntoMotor3D.clone(
                `conjuntoMotor3D${id}`,
              );
              conjuntoMotor.info = motor.info;
              conjuntoMotor.parent = base;
              conjuntoMotor.visibility = 1;

              const placaStatusOnOffMotor = StatusOnOffMotor(
                scene,
                suportePlacaStatus,
                0.6,
                conjuntoMotor.info.operation,
                conjuntoMotor.info.idMotor,
              );
              // console.log(placaStatusOnOffMotor);

              if (
                conjuntoMotor.info.status === 1 ||
                conjuntoMotor.info.status === 2 ||
                conjuntoMotor.info.status === 10
              ) {
                conjuntoMotor.material = colorMotorStatus3D3;
              } else if (
                conjuntoMotor.info.status === 3 ||
                conjuntoMotor.info.status === 4
              ) {
                conjuntoMotor.material = colorMotorStatus3D2;
              } else if (
                conjuntoMotor.info.status === 5 ||
                conjuntoMotor.info.status === 6
              ) {
                conjuntoMotor.material = colorMotorStatus3D4;
              } else if (
                conjuntoMotor.info.status === 7 ||
                conjuntoMotor.info.status === 8 ||
                conjuntoMotor.info.status === 9
              ) {
                conjuntoMotor.material = colorMotorStatus3D1;
              }

              conjuntoMotor.actionManager = new BABYLON.ActionManager(scene);

              conjuntoMotor.actionManager.registerAction(
                new BABYLON.ExecuteCodeAction(
                  BABYLON.ActionManager.OnPointerOverTrigger,
                  (ev) => {
                    switch (ev.meshUnderPointer.id) {
                      case ev.meshUnderPointer.id:
                        fcnSeeTooltipMotor(
                          ev.meshUnderPointer.info,
                          scene,
                          ev.meshUnderPointer,
                          ev.meshUnderPointer.absolutePosition,
                        );
                        break;
                    }
                  },
                ),
              );

              conjuntoMotor.actionManager.registerAction(
                new BABYLON.ExecuteCodeAction(
                  BABYLON.ActionManager.OnPointerOutTrigger,
                  (ev) => {
                    switch (ev.meshUnderPointer.id) {
                      case ev.meshUnderPointer.id:
                        fcnSeeTooltipMotor(
                          'remove',
                          scene,
                          ev.meshUnderPointer,
                          ev.meshUnderPointer.absolutePosition,
                        );
                        break;
                    }
                  },
                ),
              );
            }

            camada++;
          }
        }
      }

      const caminhoTubo = [
        new BABYLON.Vector3(0, -(raioSilo - raioArco), 0.0),

        new BABYLON.Vector3(0, alturaCaboTeto, 0.0),
      ];
      const objetoCabo = new BABYLON.MeshBuilder.CreateTube(
        `objetoCabo${objArco.cables[cab].cab}`,
        {
          path: caminhoTubo,
          radius: 0.05,
          sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          updatable: false,
        },
        scene,
      );

      objetoCabo.material = matCabo;
      objetoCabo.isPickable = true;
      objetoCabo.parent = pivoCabo;
      objetoCabo.position.z = raioArco;
      const numSensores = objCabo.sensors.length;
      let contSensor = 0;

      objetoCabo.actionManager = new BABYLON.ActionManager(scene);
      objetoCabo.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          (event) => {
            const pickedMesh = event.meshUnderPointer;
            const pivoCabo = pickedMesh.parent;
            const arco = pivoCabo.parent;

            // console.log('showInfo - cabo');
            // console.log(showInfo);

            if (showInfo === false) {
              criaInfoLabels(arc, arco);
              showInfo = true;
              arcSee.push(arco.name);
            } else if (arcSee.includes(arco.name)) {
              // console.log('arco ja existe');
              _.forEach(scene.meshes, (val) => {
                if (
                  val.name.includes('infoObjeto') &&
                  val.name.includes(arco.name)
                ) {
                  val.visibility = !val.visibility;
                }
              });
            } else {
              criaInfoLabels(arc, arco);
              arcSee.push(arco.name);
            }
          },
        ),
      );

      for (let sen = 0; sen < numSensores; sen++) {
        // Sensores
        sensorIdForMat++;
        // const objetoSensor = new BABYLON.MeshBuilder.CreateSphere(`objetoSensor${sensorIdForMat}`, { diameter: 0.5, updatable: false }, scene);

        var objetoSensor;
        if (sensorIdForMat === 1) {
          // console.log('primeiro sensor =============')
          objetoSensor = objetoSensorEx;
        } else {
          objetoSensor = objetoSensorEx.clone(`objetoSensor${sensorIdForMat}`);
        }

        let posSensor =
          profundidade - Math.tan(BABYLON.Tools.ToRadians(30)) * raioArco;
        if (raioArco === 0) {
          posSensor = -posSensor;
          // objetoSensor.position.y = (posSensor) + (2 * sen);
          objetoSensor.position.y =
            posSensor + 1 + distanciaEntreSensores * sen;
        } else {
          // let myY = (posSensor)
          let myY = posSensor;
          if (myY < 0) {
            myY += 1;
            objetoSensor.position.y = myY + distanciaEntreSensores * sen;
          } else {
            objetoSensor.position.y = -myY + distanciaEntreSensores * sen;
          }
        }

        // objetoSensor.position.y = (-(profundidade - raioArco) + 5) + (2 * sen);
        const objSensor = objCabo.sensors[sen];
        const novoMat = matSensor.clone(`novoMat${sensorIdForMat}`);
        novoMat.id = `novoMat${sensorIdForMat}`;

        const tooltipVisibility = 0;

        let tempColor = '#FFFFFFFF';
        if (sen <= objCabo.level - 1) {
          contSensor += 1;
          tempColor = `${objSensor.color}FF`;
          novoMat.diffuseColor = new BABYLON.Color4.FromHexString(tempColor);
        } else {
          novoMat.diffuseColor = new BABYLON.Color4.FromHexString(tempColor); // TemperaturaCor(objSensor);
        }

        // let actualRect = criaTooltip(advancedTexture, objetoSensor, arc, objCabo, sen, objSensor, 0)

        objetoSensor.actionManager = new BABYLON.ActionManager(scene);

        var clickSensor = (event) => {
          // console.log('evento de clique');
          const pickedMesh = event.meshUnderPointer;
          const cabo = pickedMesh.parent;
          const pivoCabo = cabo.parent;
          const arco = pivoCabo.parent;

          // console.log('showInfo');
          // console.log(showInfo);

          if (showInfo === false) {
            criaInfoLabels(arc, arco);
            showInfo = true;
            arcSee.push(arco.name);
          } else {
            if (arcSee.includes(arco.name)) {
              _.forEach(scene.meshes, (val) => {
                if (
                  val.name.includes('infoObjeto') &&
                  val.name.includes(arco.name)
                ) {
                  // console.log('MATCH');
                  // console.log(val);
                  val.visibility = !val.visibility;
                  val.isPickable = !val.isPickable;
                }
                // console.log('AFTER IF');
                // console.log(val);
              });
              // TOGGL
            } else {
              fncChangeViewInfo();
              criaInfoLabels(arc, arco);
              arcSee.push(arco.name);
            }
            // esconde info
          }
        };

        objetoSensor.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction(
            BABYLON.ActionManager.OnPickTrigger,
            (evt) => clickSensor(evt),
          ),
        );

        const toolTip2 = null;

        objetoSensor.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction(
            BABYLON.ActionManager.OnPointerOverTrigger,
            (ev) => {
              switch (ev.meshUnderPointer.id) {
                case ev.meshUnderPointer.id:
                  fcnSeeTooltip(
                    ev.meshUnderPointer.sensor,
                    scene,
                    ev.meshUnderPointer,
                    ev.meshUnderPointer.absolutePosition,
                  );
                  break;
              }
            },
          ),
        );

        objetoSensor.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction(
            BABYLON.ActionManager.OnPointerOutTrigger,
            (ev) => {
              switch (ev.meshUnderPointer.id) {
                case ev.meshUnderPointer.id:
                  fcnSeeTooltip(
                    'remove',
                    scene,
                    ev.meshUnderPointer,
                    ev.meshUnderPointer.absolutePosition,
                  );
                  break;
              }
            },
          ),
        );

        // objetoSensor.actionManager.registerAction(
        //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function (ev) {
        //     switch (ev.meshUnderPointer.id) {
        //       case ev.meshUnderPointer.id:
        //         if (objetoSensor.visibility === 1) {
        //           if (toolTip2) {
        //             if (toolTip2) {
        //               toolTip2.visibility = 1;
        //             }
        //           } else {
        //             toolTip2 = criaTooltip2(sen, scene, 'grey', [{ val: `tooltip${sen}`, tam: 220 }]
        //               , ev.meshUnderPointer, 0, 4.2, 1, 1)

        //             if (toolTip2) {
        //               toolTip2.visibility = 1;
        //             }
        //           }
        //         }
        //         break
        //     }
        //   }));

        // objetoSensor.actionManager.registerAction(
        //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, function (ev) {
        //     switch (ev.meshUnderPointer.id) {
        //       case ev.meshUnderPointer.id:
        //         if (objetoSensor.visibility === 1 && toolTip2) {
        //           toolTip2.visibility = 0
        //         }
        //         break
        //     }
        //   }));

        objetoSensor.isPickable = true;
        objetoSensor.parent = objetoCabo;

        objetoSensor.enablePointerMoveEvents = true;
        objetoSensor.material = novoMat;

        const onOver = (meshEvent) => {
          const div = document.createElement('div');
          div.setAttribute('id', 'mybut');
          const styDiv = div.style;
          styDiv.position = 'absolute';
          styDiv.display = 'flex';
          styDiv.flexDirection = 'column';
          styDiv.padding = '16px';
          styDiv.color = '#ffffff';
          styDiv.borderRadius = '8px';
          styDiv.backgroundColor = 'rgb(51 51 51 / 0.7)'; // "#333333";
          styDiv.fontSize = '12pt';
          styDiv.top = `${scene.pointerY}px`;
          styDiv.left = `${scene.pointerX}px`;
          styDiv.cursor = 'pointer';

          const but = document.createElement('span');

          but.setAttribute('onClick', "alert('ouch!'");

          const but1 = document.createElement('span');
          const but2 = document.createElement('span');
          const but3 = document.createElement('span');

          div.appendChild(but);
          div.appendChild(but1);
          div.appendChild(but2);
          div.appendChild(but3);
          document.body.appendChild(div);
          but.textContent = `Anel: ${objetoSilo.length - arc}`;
          but1.textContent = `Cabo: ${objCabo.cab}`;
          but2.textContent = `Sensor: ${sen + 1}`;
          but3.textContent =
            objSensor.t === 999
              ? 'OFF'
              : objSensor.t === 998
              ? 'N/D'
              : objSensor.t === 997
              ? 'ERR'
              : `Temp.: ${objSensor.t}ºC`;

          const funcSens = (event) => {
            clickSensor(meshEvent);
          };
          div.addEventListener('click', funcSens);
        };

        const onOut = (meshEvent) => {
          while (document.getElementById('mybut')) {
            document
              .getElementById('mybut')
              .parentNode.removeChild(document.getElementById('mybut'));
          }
        };

        // objetoSensor.actionManager.registerAction(
        //   new BABYLON.ExecuteCodeAction(
        //     BABYLON.ActionManager.OnPointerOverTrigger,
        //     onOver
        //   )
        // );

        // objetoSensor.actionManager.registerAction(
        //   new BABYLON.ExecuteCodeAction(
        //     BABYLON.ActionManager.OnPointerOutTrigger,
        //     onOut
        //   )
        // );

        objetoSensor.sensor = {
          cor: novoMat.diffuseColor,
          sensor_id: sen + 1,
          level_sensor: sen + 1,
          arc_id: arc + 1,
          cable_number: objCabo.cab,
          arc_index: arc === 0 ? objetoSilo.length : objetoSilo.length - arc,
          real_level: sen + 1,
          temperature:
            objSensor.t === 999
              ? 'OFF'
              : objSensor.t === 998
              ? 'N/D'
              : objSensor.t === 997
              ? 'ERR'
              : objSensor.t,
          color_hex: tempColor,
        };
        objetoSensor.material.freeze();

        // ===
      }
      // console.log("Nome do cabo ===========================>")
      // console.log(objArco.cables[cab].cab)
      NomeCabo(
        scene,
        '#242d45',
        [
          { val: objArco.cables[cab].cab, tam: 160 },
          {
            val: 'CABO',
            tam: 150,
          },
        ],
        objetoCabo,
        alturaCaboTeto - 0.7,
        1.0,
        0.2,
        0.5,
      );
      pivoCabo.rotate(
        BABYLON.Axis.Y,
        BABYLON.Tools.ToRadians(360 - objCabo.off_set),
      );
      if (menorNivelCabo === -1 || contSensor <= menorNivelCabo) {
        menorNivelCabo = contSensor;
      }
    }
  }

  // Nível produto
  const materialProduto = new BABYLON.StandardMaterial(
    'materialProduto',
    scene,
  );
  if (grao === 'Milho') {
    materialProduto.diffuseTexture = new BABYLON.Texture(
      `${originalTheme.textures.corn}`,
      scene,
    );
  } else if (grao === 'Soja') {
    materialProduto.diffuseTexture = new BABYLON.Texture(
      `${originalTheme.textures.soybean}`,
      scene,
    );
  } else if (grao === 'Trigo Duro' || grao === 'Trigo Mole') {
    materialProduto.diffuseTexture = new BABYLON.Texture(
      `${originalTheme.textures.wheat}`,
      scene,
    );
  } else {
    materialProduto.diffuseTexture = new BABYLON.Texture(
      `${originalTheme.textures.padrao}`,
      scene,
    );
  }
  materialProduto.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
  materialProduto.diffuseTexture.uScale = 10;
  materialProduto.diffuseTexture.vScale = 10;
  materialProduto.specularColor = new BABYLON.Color3(0, 0, 0);
  materialProduto.backFaceCulling = false;
  materialProduto.zOffset = +2;

  // console.log('materialProduto');
  if (materialProduto) {
    // console.log(materialProduto);
  }

  const numLinhasProduto = 30;
  const PIdois = 2 * Math.PI;
  const passoDistancia = PIdois / numLinhasProduto;
  let incPassoDist = 0;

  menorNivelCabo -= profundidade;
  const menorNivel = menorNivelCabo * distanciaEntreSensores;
  const raioProduto = raioSilo - 0.1;
  const aneisArcos = [raioProduto, raioProduto];
  // Ordena os raios dos arcos em ordem decrescente
  vetorDistanciasArcos.reverse();
  // Acrescenta novos valores de raio dos arcos
  vetorDistanciasArcos.forEach((v) => {
    aneisArcos.push(v);
  });
  // Altera a ordem dos arcos
  const objSiloReverso = objetoSilo.slice().reverse();
  const proporcaoLinhaGrau = Math.round(360 / numLinhasProduto + 0.49);
  // Desenha o nível do produto
  const caminhosProduto = [];
  for (let arco = 0; arco < aneisArcos.length; arco++) {
    const radius = aneisArcos[arco];
    var caminhoLinhas = [];
    var posIni = 0;
    var posFim = 0;
    for (let linha = 0; linha < numLinhasProduto; linha++) {
      const x = radius * Math.sin(incPassoDist);
      const y = arco === 0 ? -profundidade : menorNivel;
      const z = radius * Math.cos(incPassoDist);
      const ponto = new BABYLON.Vector3(x, y, z);
      caminhoLinhas.push(ponto);
      incPassoDist += passoDistancia;
    }

    let profund = 0;
    caminhoLinhas = caminhoLinhas.reverse(); // Reverte a ordem de construção dos caminhos da linha pois esta sendo considerado anti-horário
    if (arco > 1) {
      // Desenha o nível do produto
      // console.log('Desenha nível do produto');
      var objArco = objSiloReverso[arco - 2];

      // console.log('objArco');
      // console.log(objArco);

      profund =
        profundidade - Math.tan(BABYLON.Tools.ToRadians(30)) * objArco.radius;
      if (objArco.radius === 0) {
        profund = -profund;
        profund = profund;

        // console.log('profund');
        // console.log(profund);
      } else {
        let myY = profund + 1.2;
        if (myY < 0) {
          myY += 1;
          profund = myY;
        } else {
          profund = -myY;
        }
      }
      if (objArco.cables.length > 1) {
        // Mais de um cabo
        // console.log('mais de um cabo');
        // console.log(objArco.cables.length);

        _.forEach(objArco.cables, (caboVal, cabIdx) => {
          const linhaIni = Math.round(caboVal.off_set / proporcaoLinhaGrau);
          const objProxCabo =
            objArco.cables[cabIdx + 1] === undefined
              ? objArco.cables[0]
              : objArco.cables[cabIdx + 1];
          posIni = linhaIni;
          posFim = Math.round(objProxCabo.off_set / proporcaoLinhaGrau);
          posFim = posFim < posIni ? numLinhasProduto : posFim;
          const alturaAtual = fncAltCabo(
            caboVal,
            distanciaEntreSensores,
            termoReport,
            profund,
          );
          const proxAltura = fncAltCabo(
            objProxCabo,
            distanciaEntreSensores,
            termoReport,
            profund,
          );
          fncLevelSilo(caminhoLinhas, alturaAtual, proxAltura, posIni, posFim);
        });
        const firstCable = objArco.cables[0];
        const altFirstCable = fncAltCabo(
          firstCable,
          distanciaEntreSensores,
          termoReport,
          profund,
        );
        const linhaIni = Math.round(firstCable.off_set / proporcaoLinhaGrau);
        const altLastCable = caminhoLinhas[numLinhasProduto - 1].y;
        fncLevelSilo(caminhoLinhas, altLastCable, altFirstCable, 0, linhaIni);
      } else {
        // Somente o primeiro cabo
        // console.log('somente um cabo');
        // console.log(objArco.cables.length);

        const firstCable = objArco.cables[0];
        const altFirstCable = fncAltCabo(
          firstCable,
          distanciaEntreSensores,
          termoReport,
          profund,
        );
        fncLevelSilo(
          caminhoLinhas,
          altFirstCable,
          altFirstCable,
          0,
          numLinhasProduto,
        );
      }
    }
    caminhosProduto.push(caminhoLinhas);
  }

  // Caso possui um arco com mais de um cabo, o meio deve ser preenchido para evitar um espaço vazio
  if (objSiloReverso.length === 1) {
    if (objSiloReverso[0].cables.length > 1) {
      const cl = [];
      _.forEach(caminhosProduto[caminhosProduto.length - 1], (val, idx) => {
        const ponto = new BABYLON.Vector3(0, val.y, 0);
        cl.push(ponto);
      });
      caminhosProduto.push(cl);
    }
  }

  // Percorre a lateral do produto para definir o nível se esta enchendo ou esvaziando
  if (caminhosProduto.length > 3) {
    _.forEach(caminhosProduto[1], (linhaObj, idx) => {
      const linhaFora = caminhosProduto[2][idx];
      const linhaDentro = caminhosProduto[3][idx];
      if (linhaFora.y === linhaDentro.y) {
        linhaObj.y = linhaFora.y;
      } else if (linhaFora.y > linhaDentro.y) {
        linhaObj.y = linhaFora.y + 1;
      } else if (linhaFora.y < linhaDentro.y) {
        linhaObj.y = linhaFora.y - 1;
      }
      linhaObj.y = Math.abs(linhaObj.y > alturaSilo ? alturaSilo : linhaObj.y);
    });
  } else if (caminhosProduto.length === 3) {
    // Quando possuir somente um cabo central
    _.forEach(caminhosProduto[1], (linhaObj, idx) => {
      const linhaFora = caminhosProduto[1][idx];
      const linhaDentro = caminhosProduto[2][idx];
      if (linhaFora.y === linhaDentro.y) {
        linhaObj.y = linhaFora.y;
      } else if (linhaFora.y > linhaDentro.y) {
        linhaObj.y = linhaFora.y + 1;
      } else if (linhaFora.y < linhaDentro.y) {
        linhaObj.y = linhaFora.y - 1;
      }
      linhaObj.y = Math.abs(linhaObj.y > alturaSilo ? alturaSilo : linhaObj.y);
    });
  }

  // Exatamente onde cria o elemento visual do produto
  const produto = BABYLON.MeshBuilder.CreateRibbon(
    'nivelProduto',
    { pathArray: caminhosProduto, closePath: true },
    scene,
  );
  produto.material = materialProduto;
  produto.isPickable = false;
  // produto.position.y = alturaBase / 2;
  produto.position.y = alturaBase / 2;
  produto.visibility = produto_visivel;
  produto.freezeWorldMatrix();
  produto.parent = conjuntoArco;

  // objetoCamadaProduto = conjuntoArco;
  handleOnSetObjetoCamadaProduto(conjuntoArco);
  fncChangeViewInfo(scene, produto_info);
};

const NomeObjeto = (
  nome,
  scene,
  corFundo,
  id,
  parent,
  altura,
  tamanho,
  x = 0,
  z = 0,
  fcnSeeTooltip,
) => {
  // console.log('parents');
  // console.log(parent);
  const infoObjeto = BABYLON.Mesh.CreatePlane(
    `infoObjeto ${nome}`,
    tamanho,
    scene,
    false,
  );

  // BORDA
  infoObjeto.enableEdgesRendering();
  infoObjeto.edgesWidth = 4.0;
  infoObjeto.edgesColor = corFundo;
  infoObjeto.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
  infoObjeto.checkCollisions = true;
  //
  infoObjeto.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL;
  infoObjeto.position = new BABYLON.Vector3(x - 0.5, altura, z);
  infoObjeto.parent = parent;
  const texturaInfoObjeto = new BABYLON.DynamicTexture(
    'texturaInfoObjeto',
    512,
    scene,
    true,
  );
  const fontSize = 190;
  if (typeof id === 'object') {
    for (let i = 0; i < id.length; i++) {
      texturaInfoObjeto.drawText(
        id[i].val,
        null,
        300 + id[i].tam * i,
        `bold ${id[i].tam}px arial`,
        'black',
        i === 0 ? corFundo : null,
      );
    }
  } else {
    texturaInfoObjeto.drawText(
      id,
      null,
      300,
      `bold ${fontSize}px arial`,
      'black',
      corFundo,
    );
  }
  infoObjeto.material = new BABYLON.StandardMaterial(
    'materialSuperficie',
    scene,
  );
  infoObjeto.material.diffuseTexture = texturaInfoObjeto;
  infoObjeto.material.specularColor = new BABYLON.Color3(0, 0, 0);
  infoObjeto.material.emissiveColor = new BABYLON.Color3(1, 1, 1);
  infoObjeto.material.backFaceCulling = false;
  infoObjeto.isPickable = true;
  // texturaInfoObjeto.hasAlpha = true;
  infoObjeto.material.freeze();

  infoObjeto.actionManager = new BABYLON.ActionManager(scene);

  infoObjeto.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(
      BABYLON.ActionManager.OnPointerOverTrigger,
      (ev) => {
        fcnSeeTooltip(parent.sensor, scene, parent, parent.absolutePosition);
      },
    ),
  );

  infoObjeto.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(
      BABYLON.ActionManager.OnPointerOutTrigger,
      (ev) => {
        fcnSeeTooltip('remove', scene, parent, parent.absolutePosition);
      },
    ),
  );
};

const NomeCabo = (
  scene,
  corFundo,
  id,
  parent,
  altura,
  tamanho,
  x = 0,
  z = 0,
) => {
  const nomeObjeto = BABYLON.Mesh.CreatePlane(
    'nomeCabo',
    tamanho,
    scene,
    false,
  );
  nomeObjeto.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL;
  nomeObjeto.position = new BABYLON.Vector3(x, altura, z);
  nomeObjeto.parent = parent;
  const texturaNomeCabo = new BABYLON.DynamicTexture(
    'texturaNomeCabo',
    512,
    scene,
    true,
  );
  const fontSize = 250;
  if (typeof id === 'object') {
    for (let i = 0; i < id.length; i++) {
      if (id[i].val === 'CABO') {
        texturaNomeCabo.drawText(
          id[i].val,
          null,
          200 + id[i].tam * i,
          'bold 150px arial',
          'white',
          i === 0 ? corFundo : null,
        );
      } else {
        texturaNomeCabo.drawText(
          id[i].val,
          null,
          200 + id[i].tam * i,
          'bold 250px arial',
          'white',
          i === 0 ? corFundo : null,
        );
      }
    }
  } else {
    texturaNomeCabo.drawText(
      id,
      null,
      200,
      `bold ${fontSize}px arial`,
      'white',
      corFundo,
    );
  }
  nomeObjeto.material = new BABYLON.StandardMaterial(
    'materialSuperficieCabo',
    scene,
  );
  nomeObjeto.material.diffuseTexture = texturaNomeCabo;
  nomeObjeto.material.specularColor = new BABYLON.Color3(0, 0, 0);
  nomeObjeto.material.emissiveColor = new BABYLON.Color3(1, 1, 1);

  nomeObjeto.material.backFaceCulling = false;
  nomeObjeto.isPickable = false;
  texturaNomeCabo.hasAlpha = true;
};
const fncAltCabo = (cabo, distSensor, termoReport, profundidade) => {
  if (cabo === undefined) {
    return 0;
  }

  if (profundidade < 0) {
    profundidade = -profundidade;
  }

  return getLevelSensor(cabo, termoReport) * distSensor - profundidade;
};

const getLevelSensor = (c, termoReport) => c.level;

const fncLevelSilo = (superficie, H1, H2, D1, D2) => {
  // console.log('props do func level silo');
  // console.log(superficie);
  // console.log(H1);
  // console.log(H2);
  // console.log(D1);
  // console.log(D2);
  const a = (H2 - H1) / (D2 - D1);
  const b = a * (0 - D1) + H1;
  for (let x = D1; x < D2; x++) {
    const alt = a * x + b;
    // console.log('alt');
    // console.log(alt);
    // if incluido para evitar que fosse gerado nivel de produto em uma unidade com nivel 0
    if (alt < 0) {
      // console.log('alt igual a zero');
      superficie[x].y = 0;
    } else {
      superficie[x].y = alt;
    }
  }

  // console.log('result of props do func level silo');
  // console.log(superficie);
};
