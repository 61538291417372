import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Typography from '@material-ui/core/Typography';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
// import { IoIosArrowBack } from 'react-icons/all';
import titleHelper from '../helpers/titleHelper';

const useStyles = makeStyles(() => ({
  pageHeader: {
    padding: '12px 0px 12px',
  },
  title: {
    margin: '10px 0',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '28px',
    letterSpacing: '0px',
  },
  backButton: {
    background: 'transparent',
    border: '0',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
    color: '#727272',
    transition: 'color 0.2s',
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      color: '#000',
    },
  },
}));

export default (props) => {
  const [goToNew, setGoToNew] = React.useState(false);
  const classes = useStyles();

  React.useEffect(() => {
    const title = props.pageTitle || props.title;
    if (title) {
      titleHelper(title);
    } else if ((props.routes || []).length > 0) {
      titleHelper(props.routes[props.routes.length - 1].name);
    }
  }, [props.pageTitle, props.title, props.routes]);

  React.useEffect(() => {
    setGoToNew(false);
  }, [goToNew]);

  if (goToNew && props.clientName) {
    return <Redirect push to={`${window.location.pathname}/new?client=${props.clientName}`} />;
  }
  if (goToNew) {
    return <Redirect push to={`${window.location.pathname}/new`} />;
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      className={classes.pageHeader}
    >
      <Grid item>
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="Breadcrumb"
        >
          <RouterLink
            color="textPrimary"
            to="/"
            style={{ textDecoration: 'none' }}
          >
            <Typography color="textPrimary">Início</Typography>
          </RouterLink>

          {props.loading ? (
            <Skeleton variant="text" width={210} animation="wave" />
          ) : (
            (props.routes || []).map((value, index) => {
              const last = index === props.routes.length - 1;
              if (last) {
                return (
                  <Typography color="textPrimary" key={value.name}>
                    <strong>{value.name}</strong>
                  </Typography>
                );
              }
              if (!value.to) {
                return (
                  <Typography color="textPrimary" key={value.name}>
                    {value.name}
                  </Typography>
                );
              }
              return (
                <RouterLink
                  color="textPrimary"
                  to={value.to}
                  key={value.name}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography color="textPrimary" key={value.to}>
                    {value.name}
                  </Typography>
                </RouterLink>
              );
            })
          )}
        </Breadcrumbs>
        <p className={classes.title}>{props.title}</p>
        {/* {props.routes && (
          <RouterLink
            to={props.routes[0].to || '/'}
            className={classes.backButton}
          >
            <IoIosArrowBack
              style={{ marginLeft: '-5px', padding: '0' }}
              size={16}
              color="#727272"
            />
            voltar
          </RouterLink>
        )} */}
      </Grid>
      <Grid item>
        {props.newBtn ? (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => setGoToNew(true)}
            startIcon={<AddIcon />}
          >
            {props.newBtn}
          </Button>
        ) : (
          <></>
        )}
      </Grid>
    </Grid>
  );
};
