import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

export function CarouselComponent({ children, infinite,
  centerMode,
  autoPlay,
  className,
}) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1600 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 1600, min: 1365 },
      items: 2,
    },
    smDesktop: {
      breakpoint: { max: 1400, min: 900 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1365, min: 800 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 700, min: 350 },
      items: 1,
    },
    mini: {
      breakpoint: { max: 349, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      infinite={infinite}
      centerMode={centerMode}
      autoPlay={autoPlay}
      autoPlaySpeed={5000}
      className={className}
      responsive={responsive}
    >
      {children}
    </Carousel>
  );
}
