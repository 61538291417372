import * as BABYLON from 'babylonjs';
import * as MyEarcut from 'earcut';
import originalTheme from './theme';

import { StatusOnOffMotor, LabelNameMotor } from './CriaMotor';

import * as GUI from 'babylonjs-gui';

const _ = require('lodash');

let showInfo = false;
let arcSee = [];
let showPanelMotor3D = false;

export const CleanPanelArmazemV = () => {
  showPanelMotor3D = false;
};

export const CleanVarsArma = () => {
  showInfo = false;
  arcSee = [];
};

export const CriaArmazemV = (
  scene,
  layout,
  termoReport,
  colorConfig,
  cod,
  nomeUnidade,
  comprimento,
  largura,
  alturaParede,
  tipoAgua,
  tipoFundo,
  alturaFundo,
  reducaoCabeceira,
  anguloUnidade,
  posX,
  posZ,
  motores,
  fncChangeViewInfo,
  objetoCamadaProduto,
  unidadesObservaveis,
  setUnidadesObservaveis,
  produto_visivel,
  handleOnSetObjetoCamadaProduto,
  produto_info,
  fncChangeViewLevel,
  subareaSelected,
  grao,
  fchChangeViewStaircase,
  escada_visivel,
  fcnSeeTooltip,
  colorMotorStatus3D1,
  colorMotorStatus3D2,
  colorMotorStatus3D3,
  colorMotorStatus3D4,
  colorMotorStatus3D5,
  colorMotorStatus3D1Shaded,
  colorMotorStatus3D2Shaded,
  colorMotorStatus3D3Shaded,
  colorMotorStatus3D4Shaded,
  colorMotorStatus3D5Shaded,
  fcnSeeTooltipMotor,
  permissaoParaEditarOEstadoDosMotores,
  painelEditaMotoresEmUso,
  motor3D,
  baseMotor3D,
  conjuntoMotor3D,
  suporteDoMotor3D,
  entrePontos
) => {
  // console.log('Cria Armazem - [x] 1');
  const pivoArmazem = new BABYLON.TransformNode(`pivoArmazem${nomeUnidade}`)

  pivoArmazem.unidade = cod
  pivoArmazem.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(anguloUnidade))
  pivoArmazem.position.x = posX
  pivoArmazem.position.z = posZ
  const alturaParedeArmazem = alturaParede
  const compBaseArmazem = comprimento
  const largBaseArmazem = largura

  const abaTelhadoArmazem = 1
  const anguloTelhadoArmazem = 30
  // ACRESCENTADO +2 PARA CABER O PRODUTO TODO NO ARMAZEM, MAXIMO ATÉ NIVEL 15
  const alturaTelhadoArmazem =
    (largBaseArmazem / 2) * Math.tan((anguloTelhadoArmazem * Math.PI) / 180) + 2
  const larguraCalcada = 3.5
  const distanciaEntreSensores = entrePontos

  const tipoVisualizacao = BABYLON.Mesh.FRONTSIDE

  const pontoParedeArmazem = [
    new BABYLON.Vector3(0, compBaseArmazem, 0),
    new BABYLON.Vector3(largBaseArmazem, compBaseArmazem, 0),
    new BABYLON.Vector3(largBaseArmazem, 0, 0),
    new BABYLON.Vector3(0, 0, 0),
  ]
  pontoParedeArmazem.push(pontoParedeArmazem[0])
  const caminhoParedeArmazem = [
    new BABYLON.Vector3(0, alturaParedeArmazem, 0),
    new BABYLON.Vector3(0, 0, 0),
  ]
  const texturaParede = new BABYLON.StandardMaterial(
    `texturaParede${nomeUnidade}`,
    scene
  )
  texturaParede.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.paredeLateral}`,
    scene
  )
  texturaParede.diffuseTexture.uScale = 50
  texturaParede.diffuseTexture.vScale = 1
  const armazem = BABYLON.MeshBuilder.ExtrudeShape(
    'armazem',
    {
      cap: BABYLON.Mesh.NO_CAP,
      shape: pontoParedeArmazem,
      path: caminhoParedeArmazem,
      sideOrientation: tipoVisualizacao,
      updatable: false,
    },
    scene
  )
  armazem.position.z = -(largBaseArmazem / 2)
  armazem.position.x = compBaseArmazem / 2
  armazem.material = texturaParede
  armazem.isPickable = false
  armazem.parent = pivoArmazem

  // console.log('Cria Armazem - [x] 2');
  // Telhado =============================
  const texturaTelhado = new BABYLON.StandardMaterial(
    `texturaTelhado${nomeUnidade}`,
    scene
  )
  texturaTelhado.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.texturaTelhado}`,
    scene
  )
  texturaTelhado.diffuseTexture.uScale = 1
  texturaTelhado.diffuseTexture.vScale = 50
  texturaTelhado.sideOrientation = BABYLON.Mesh.DOUBLESIDE

  const texturaTelhadoLateral = new BABYLON.StandardMaterial(
    `texturaTelhadoLateral${nomeUnidade}`,
    scene
  )
  texturaTelhadoLateral.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.texturaTelhadoLateral}`,
    scene
  )
  texturaTelhadoLateral.diffuseTexture.uScale = 25
  texturaTelhadoLateral.diffuseTexture.vScale = 1
  texturaTelhadoLateral.sideOrientation = BABYLON.Mesh.FRONTSIDE
  texturaTelhadoLateral.backFaceCulling = true
  texturaTelhadoLateral.zOffset = -2

  // console.log('Cria Armazem - [x] 3');
  if (tipoAgua === 2) {
    // Duas águas
    const pontoTelhado = [
      new BABYLON.Vector3(
        -(largBaseArmazem / 2 + abaTelhadoArmazem) + 0.05,
        0,
        0
      ),
      new BABYLON.Vector3(0, alturaTelhadoArmazem, 0),
      new BABYLON.Vector3(largBaseArmazem / 2 + abaTelhadoArmazem - 0.05, 0, 0),
    ]
    const caminhoTelhado = [
      new BABYLON.Vector3(-abaTelhadoArmazem, 0, 0),
      new BABYLON.Vector3(compBaseArmazem + abaTelhadoArmazem, 0, 0),
    ]
    const telhado = BABYLON.MeshBuilder.ExtrudeShape(
      `telhado${nomeUnidade}`,
      {
        shape: pontoTelhado,
        path: caminhoTelhado,
        sideOrientation: BABYLON.Mesh.DOUBLESIDE,
        updatable: false,
      },
      scene
    )
    telhado.position.y = alturaParedeArmazem - abaTelhadoArmazem / 2
    telhado.position.x = -(compBaseArmazem / 2)
    telhado.material = texturaTelhado
    telhado.isPickable = false
    telhado.parent = pivoArmazem

    // Lateral telhado Frente
    const pontosLateralTelhado = [
      new BABYLON.Vector2(-(largBaseArmazem / 2), 0),
      new BABYLON.Vector2(largBaseArmazem / 2, 0),
      new BABYLON.Vector2(0, alturaTelhadoArmazem - abaTelhadoArmazem / 2),
    ]

    const trianguloTelhado = new BABYLON.PolygonMeshBuilder(
      `trianguloTelhado${nomeUnidade}`,
      pontosLateralTelhado,
      scene,
      MyEarcut
    )
    const lateralTelhadoF = trianguloTelhado.build(null, 0.0)
    lateralTelhadoF.material = texturaTelhadoLateral
    lateralTelhadoF.rotate(BABYLON.Axis.X, BABYLON.Tools.ToRadians(270))
    lateralTelhadoF.rotate(BABYLON.Axis.Z, BABYLON.Tools.ToRadians(270))
    lateralTelhadoF.position.x = compBaseArmazem
    lateralTelhadoF.position.y = abaTelhadoArmazem / 2
    lateralTelhadoF.isPickable = false
    lateralTelhadoF.parent = telhado
    // lateralTelhadoF.sideOrientation = BABYLON.Mesh.BACKSIDE

    const lateralTelhadoV = lateralTelhadoF.clone('lateralTelhadoV')
    lateralTelhadoV.position.x = -(compBaseArmazem / 2 - compBaseArmazem / 2)
    lateralTelhadoV.isPickable = false
  } else {
    // Curvado
    // Telhado Curvado ======================================================
    const comprimentoTelhadoAngulado = compBaseArmazem + abaTelhadoArmazem
    var alturaTelhadoAngulado = alturaTelhadoArmazem
    const larguraTelhadoAngulado = largBaseArmazem

    // Pontos telhado
    const funcaoPontosTelhado = function (k) {
      const path = []
      for (let i = 0; i <= larguraTelhadoAngulado; i++) {
        const x = i
        const y = 0
        const z = k
        path.push(new BABYLON.Vector3(x, y, z))
      }
      return path
    }

    // Atualiza posicionamento do telhado
    const atualizaPosicionamento = function (path) {
      for (let i = 0; i < path.length; i++) {
        const { x } = path[i]
        const { z } = path[i]
        const y =
          alturaTelhadoAngulado *
          Math.sin(BABYLON.Tools.ToRadians(180 / larguraTelhadoAngulado) * i)
        path[i].x = x
        path[i].y = y
        path[i].z = z
      }
    }
    // Criação do caminhos do telhado curvado
    const vetorCaminhoTelhadoAngulado = []
    for (let i = 0; i <= comprimentoTelhadoAngulado; i++) {
      vetorCaminhoTelhadoAngulado.push(funcaoPontosTelhado(i))
    }
    // Atualiza o comprimento do caminho
    for (let p = 0; p < vetorCaminhoTelhadoAngulado.length; p++) {
      atualizaPosicionamento(vetorCaminhoTelhadoAngulado[p])
    }
    // Telhado
    const telhadoCurvado = BABYLON.Mesh.CreateRibbon(
      `telhadoCurvado${nomeUnidade}`,
      vetorCaminhoTelhadoAngulado,
      false,
      false,
      0,
      scene,
      true
    )
    telhadoCurvado.material = texturaTelhado
    telhadoCurvado.position.z = larguraTelhadoAngulado / 2
    telhadoCurvado.position.x = -(comprimentoTelhadoAngulado / 2)
    telhadoCurvado.position.y = alturaParedeArmazem
    telhadoCurvado.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(90))
    telhadoCurvado.isPickable = false
    telhadoCurvado.parent = pivoArmazem

    const criacaoPontosTelhadoAngular = function () {
      const path = []
      for (let i = 0; i <= larguraTelhadoAngulado; i++) {
        const x = i
        const y =
          alturaTelhadoAngulado *
          Math.sin(BABYLON.Tools.ToRadians(180 / larguraTelhadoAngulado) * i)
        path.push(new BABYLON.Vector2(x, y))
      }
      return path
    }
    // Lateral telhado curvado
    const lateralTelhadoCurvado = new BABYLON.PolygonMeshBuilder(
      `lateralTelhadoCurvado${nomeUnidade}`,
      criacaoPontosTelhadoAngular(),
      scene,
      MyEarcut
    )
    const lateralTelhadoCurvadoF = lateralTelhadoCurvado.build(null, 0.0)
    lateralTelhadoCurvadoF.material = texturaTelhadoLateral
    lateralTelhadoCurvadoF.rotate(BABYLON.Axis.X, BABYLON.Tools.ToRadians(270))
    lateralTelhadoCurvadoF.rotate(BABYLON.Axis.Z, BABYLON.Tools.ToRadians(270))
    lateralTelhadoCurvadoF.position.z = -(larguraTelhadoAngulado / 2)
    lateralTelhadoCurvadoF.position.y = alturaParedeArmazem
    lateralTelhadoCurvadoF.position.x = compBaseArmazem / 2
    lateralTelhadoCurvadoF.isPickable = false
    lateralTelhadoCurvadoF.parent = pivoArmazem

    const lateralTelhadoCurvadoV = lateralTelhadoCurvadoF.clone(
      'lateralTelhadoCurvadoV'
    )
    lateralTelhadoCurvadoV.position.x = -(compBaseArmazem / 2)
    lateralTelhadoCurvadoV.position.z = largBaseArmazem / 2
    lateralTelhadoCurvadoV.rotate(BABYLON.Axis.Z, BABYLON.Tools.ToRadians(180))
    lateralTelhadoCurvadoV.isPickable = false
  }

  // console.log('Cria Armazem - [x] 4');
  // Calçada
  const texturaCalcada = new BABYLON.StandardMaterial(
    `texturaCalcada${nomeUnidade}`,
    scene
  )
  texturaCalcada.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.calcada}`,
    scene
  )
  texturaCalcada.diffuseTexture.uScale = 15
  texturaCalcada.diffuseTexture.vScale = 1
  texturaCalcada.sideOrientation = BABYLON.Mesh.DOUBLESIDE
  texturaCalcada.zOffset = -2

  let pontosCalcada = []
  pontosCalcada.push(new BABYLON.Vector2(0, 0))
  pontosCalcada.push(new BABYLON.Vector2(compBaseArmazem, 0))
  pontosCalcada.push(new BABYLON.Vector2(compBaseArmazem, larguraCalcada))
  pontosCalcada.push(new BABYLON.Vector2(0, larguraCalcada))

  const calcadaArmazem = new BABYLON.PolygonMeshBuilder(
    `calcadaArmazem${nomeUnidade}`,
    pontosCalcada,
    scene,
    MyEarcut
  )
  const calcadaArmazemE = calcadaArmazem.build(null, 0.01)
  calcadaArmazemE.material = texturaCalcada
  calcadaArmazemE.position.x = -(compBaseArmazem / 2)
  calcadaArmazemE.position.z = -(largBaseArmazem / 2 + larguraCalcada)
  calcadaArmazemE.position.y = 0.01
  calcadaArmazemE.isPickable = false
  calcadaArmazemE.checkCollisions = true
  calcadaArmazemE.parent = pivoArmazem

  const calcadaArmazemD = calcadaArmazemE.clone(`calcadaArmazemD${nomeUnidade}`)
  calcadaArmazemD.position.z = largBaseArmazem / 2
  calcadaArmazemD.isPickable = false
  calcadaArmazemD.position.y = 0.01
  calcadaArmazemD.checkCollisions = true
  calcadaArmazemD.parent = pivoArmazem

  const tempLarCalcada = largBaseArmazem + larguraCalcada * 2
  pontosCalcada = []
  pontosCalcada.push(new BABYLON.Vector2(0, 0))
  pontosCalcada.push(new BABYLON.Vector2(tempLarCalcada, 0))
  pontosCalcada.push(new BABYLON.Vector2(tempLarCalcada, larguraCalcada))
  pontosCalcada.push(new BABYLON.Vector2(0, larguraCalcada))

  const calcadaArmazemPontas = new BABYLON.PolygonMeshBuilder(
    `calcadaArmazemPontas${nomeUnidade}`,
    pontosCalcada,
    scene,
    MyEarcut
  )
  const calcadaArmazemPontasE = calcadaArmazemPontas.build(null, 0.01)
  calcadaArmazemPontasE.material = texturaCalcada
  calcadaArmazemPontasE.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(90))
  calcadaArmazemPontasE.position.x = -(compBaseArmazem / 2 + larguraCalcada)
  calcadaArmazemPontasE.position.z = tempLarCalcada / 2
  calcadaArmazemPontasE.position.y = 0.01
  calcadaArmazemPontasE.isPickable = false
  calcadaArmazemPontasE.checkCollisions = true
  calcadaArmazemPontasE.parent = pivoArmazem

  const calcadaArmazemPontasD = calcadaArmazemPontasE.clone(
    `calcadaArmazemPontasD${nomeUnidade}`
  )
  calcadaArmazemPontasD.position.x = compBaseArmazem / 2
  calcadaArmazemPontasD.isPickable = false
  calcadaArmazemPontasD.position.y = 0.01
  calcadaArmazemPontasD.checkCollisions = true
  calcadaArmazemPontasD.parent = pivoArmazem
  // console.log('Cria Armazem - [x] 5');
  // Cria fundo armazém
  const CreateMesh = function (name, vertices, verticesOrder, scene) {
    const mesh = new BABYLON.Mesh(name, scene)
    mesh.verticesOrder = verticesOrder
    const positions = []
    const normals = []
    const indices = []
    for (const i in verticesOrder) {
      positions.push(
        vertices[verticesOrder[i]].x,
        vertices[verticesOrder[i]].y,
        vertices[verticesOrder[i]].z
      )
    }
    _.forEach(vertices, (item) => {
      normals.push(0, 0, 0)
    })
    for (const x in verticesOrder) {
      indices.push(x)
    }
    BABYLON.VertexData.ComputeNormals(positions, indices, normals)
    mesh.setVerticesData(BABYLON.VertexBuffer.PositionKind, positions, true)
    mesh.setVerticesData(BABYLON.VertexBuffer.NormalKind, normals, true)
    mesh.setIndices(indices)
    return mesh
  }

  const profundidadeFundo = alturaFundo
  const comprimentoFundo = compBaseArmazem
  const larguraFundo = largBaseArmazem
  let reducaoSemiV = 0
  if (tipoFundo === 0) {
    // Tipo V
    reducaoSemiV = largBaseArmazem / 2
  } else {
    // Tipo semi V
    reducaoSemiV = tipoFundo
  }

  const fundoBaseXMin = reducaoCabeceira
  const fundoBaseXMax = comprimentoFundo - reducaoCabeceira
  const fundoBaseZMin = reducaoSemiV
  const fundoBaseZMax = larguraFundo - reducaoSemiV
  const fundoTopoXMin = 0
  const fundoTopoXMax = comprimentoFundo
  const fundoTopoZMin = 0
  const fundoTopoZMax = larguraFundo

  // 1-Criar as vértices do fundo do armazém
  const vertices = [
    // x, y, z
    new BABYLON.Vector3(fundoBaseXMin, 0, fundoBaseZMin), // 0
    new BABYLON.Vector3(fundoBaseXMax, 0, fundoBaseZMin), // 1
    new BABYLON.Vector3(fundoBaseXMin, 0, fundoBaseZMax), // 2
    new BABYLON.Vector3(fundoBaseXMax, 0, fundoBaseZMax), // 3

    new BABYLON.Vector3(fundoTopoXMin, profundidadeFundo, fundoTopoZMin), // 4
    new BABYLON.Vector3(fundoTopoXMax, profundidadeFundo, fundoTopoZMin), // 5
    new BABYLON.Vector3(fundoTopoXMin, profundidadeFundo, fundoTopoZMax), // 6
    new BABYLON.Vector3(fundoTopoXMax, profundidadeFundo, fundoTopoZMax), // 7
  ]
  // Preenche as vértices do fundo
  const verticesOrder = [
    0, 6, 2, 0, 4, 6, 1, 5, 4, 0, 1, 4, 3, 7, 5, 5, 1, 3, 2, 6, 7, 7, 3, 2, 3,
    1, 0, 0, 2, 3,
  ]

  //   const matChao = new BABYLON.StandardMaterial('matChao', scene);
  //   matChao.zOffset = 0;
  //   matChao.diffuseTexture = new BABYLON.Texture('/assets/texturas/grama1.jpg', scene);
  //   matChao.diffuseTexture.uScale = 10;
  //   matChao.diffuseTexture.vScale = 10;
  //   matChao.specularColor = new BABYLON.Color3(0, 0, 0);
  //   const chaoArmazem = BABYLON.MeshBuilder.CreatePolygon('polygon', { shape: forma, holes: buracoChao, sideOrientation: BABYLON.Mesh.DOUBLESIDE }, scene, MyEarcut);
  //   chaoArmazem.isPickable = false;
  //   chaoArmazem.checkCollisions = true;
  //   chaoArmazem.material = matChao;
  // };

  // ORIGINAL
  // const texturaFundoArmazem = new BABYLON.StandardMaterial('texturaFundoArmazem', scene);
  // texturaFundoArmazem.diffuseColor = new BABYLON.Color4.FromHexString('#cbd0d8FF');
  // texturaFundoArmazem.backFaceCulling = false;
  // texturaFundoArmazem.sideOrientation = BABYLON.Mesh.BACKSIDE;
  // texturaFundoArmazem.zOffset = -2;
  // // Fundo armazém
  // const fundoArmazem = CreateMesh('fundoArmazem', vertices, verticesOrder, scene);
  // fundoArmazem.material = texturaFundoArmazem;
  // fundoArmazem.position.x = -(comprimentoFundo / 2);
  // fundoArmazem.position.z = -(larguraFundo / 2);
  // fundoArmazem.position.y = -profundidadeFundo;
  // fundoArmazem.isPickable = false;
  // fundoArmazem.checkCollisions = true;

  const texturaFundoArmazem = new BABYLON.StandardMaterial(
    'texturaFundoArmazem',
    scene
  )
  texturaFundoArmazem.diffuseColor = new BABYLON.Color4.FromHexString(
    '#69696900'
  )
  texturaFundoArmazem.backFaceCulling = false
  texturaFundoArmazem.sideOrientation = BABYLON.Mesh.DOUBLESIDE
  texturaFundoArmazem.zOffset = -2

  // Fundo armazém
  const fundoArmazem = CreateMesh(
    'fundoArmazem',
    vertices,
    verticesOrder,
    scene
  )
  fundoArmazem.position.x = -(comprimentoFundo / 2)
  fundoArmazem.position.z = -(larguraFundo / 2)
  fundoArmazem.position.y = -profundidadeFundo
  fundoArmazem.flipFaces(true)
  fundoArmazem.material = texturaFundoArmazem

  fundoArmazem.isPickable = false
  fundoArmazem.checkCollisions = true

  fncUpdateInfoLevelProductArmazem(
    scene,
    compBaseArmazem,
    largBaseArmazem,
    profundidadeFundo,
    alturaParedeArmazem,
    alturaTelhadoAngulado,
    alturaTelhadoArmazem,
    distanciaEntreSensores,
    termoReport,
    colorConfig,
    layout,
    fncChangeViewInfo,
    objetoCamadaProduto,
    produto_info,
    produto_visivel,
    subareaSelected,
    grao,
    fcnSeeTooltip,
    colorMotorStatus3D1,
    colorMotorStatus3D2,
    colorMotorStatus3D3,
    colorMotorStatus3D4,
    colorMotorStatus3D5,
    colorMotorStatus3D1Shaded,
    colorMotorStatus3D2Shaded,
    colorMotorStatus3D3Shaded,
    colorMotorStatus3D4Shaded,
    colorMotorStatus3D5Shaded,
    fcnSeeTooltipMotor,
    permissaoParaEditarOEstadoDosMotores,
    painelEditaMotoresEmUso,
    motor3D,
    baseMotor3D,
    conjuntoMotor3D,
    suporteDoMotor3D
  )

  // CRIA FUNÇÕES PARA O MENU DE OPÇÕES
  const unidadeOnservavel = new BABYLON.Observable()
  unidadeOnservavel.add((value) => {
    if (value.produto_visivel !== undefined) {
      fncChangeViewLevel(scene, value.produto_visivel)
    }

    if (value.produto_info !== undefined) {
      fncChangeViewInfo(scene, value.produto_info) // TODO:
    }
    if (value.leitura_nivel_produto !== undefined) {
      // Aqui atualiza o nível quando selecionado uma leitura
      const selectedTermoReport = value.leitura_nivel_produto
      fncUpdateInfoLevelProductArmazem(
        scene,
        compBaseArmazem,
        largBaseArmazem,
        profundidadeFundo,
        alturaParedeArmazem,
        alturaTelhadoAngulado,
        alturaTelhadoArmazem,
        distanciaEntreSensores,
        selectedTermoReport,
        colorConfig,
        layout,
        fncChangeViewInfo,
        objetoCamadaProduto,
        produto_info,
        produto_visivel,
        subareaSelected,
        grao,
        fcnSeeTooltip,
        colorMotorStatus3D1,
        colorMotorStatus3D2,
        colorMotorStatus3D3,
        colorMotorStatus3D4,
        colorMotorStatus3D5,
        colorMotorStatus3D1Shaded,
        colorMotorStatus3D2Shaded,
        colorMotorStatus3D3Shaded,
        colorMotorStatus3D4Shaded,
        colorMotorStatus3D5Shaded,
        fcnSeeTooltipMotor,
        permissaoParaEditarOEstadoDosMotores,
        painelEditaMotoresEmUso,
        motor3D,
        baseMotor3D,
        conjuntoMotor3D,
        suporteDoMotor3D
      )
    }
  })

  const unitsObserv = []
  unitsObserv.push({ unidade: nomeUnidade, observavel: unidadeOnservavel })
  setUnidadesObservaveis(unitsObserv)

  // Chão
  const comprimentoChao = compBaseArmazem / 2
  const larguraChao = largBaseArmazem / 2
  //   const comprimentoChao = 1000;
  // const larguraChao = 1000;
  const distanciaChao = compBaseArmazem * 2

  // Poligono da superfície
  const forma = [
    new BABYLON.Vector3(-distanciaChao, 0, -distanciaChao),
    new BABYLON.Vector3(-distanciaChao, 0, distanciaChao),
    new BABYLON.Vector3(distanciaChao, 0, distanciaChao),
    new BABYLON.Vector3(distanciaChao, 0, -distanciaChao),
  ]

  // Buraco da superfície
  const buracoChao = []
  buracoChao.push([
    new BABYLON.Vector3(-comprimentoChao, 0, -larguraChao),
    new BABYLON.Vector3(-comprimentoChao, 0, larguraChao),
    new BABYLON.Vector3(comprimentoChao, 0, larguraChao),
    new BABYLON.Vector3(comprimentoChao, 0, -larguraChao),
  ])

  const matChao = new BABYLON.StandardMaterial('matChao', scene)
  matChao.zOffset = 0
  matChao.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.ground}`,
    scene
  )
  matChao.diffuseTexture.uScale = 10
  matChao.diffuseTexture.vScale = 10
  matChao.specularColor = new BABYLON.Color3(0, 0, 0)
  const chaoArmazem = BABYLON.MeshBuilder.CreatePolygon(
    'polygon2',
    {
      shape: forma,
      holes: buracoChao,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
    },
    scene,
    MyEarcut
  )
  chaoArmazem.isPickable = false
  chaoArmazem.checkCollisions = true
  chaoArmazem.material = matChao
}

const fncUpdateInfoLevelProductArmazem = (
  scene,
  compBaseArmazem,
  largBaseArmazem,
  profundidadeFundo,
  alturaParedeArmazem,
  alturaTelhadoAngulado,
  alturaTelhadoArmazem,
  distanciaEntreSensores,
  termoReport,
  colorConfig,
  layout,
  fncChangeViewInfo,
  objetoCamadaProduto,
  produto_info,
  produto_visivel,
  subareaSelected,
  grao,
  fcnSeeTooltip,
  colorMotorStatus3D1,
  colorMotorStatus3D2,
  colorMotorStatus3D3,
  colorMotorStatus3D4,
  colorMotorStatus3D5,
  colorMotorStatus3D1Shaded,
  colorMotorStatus3D2Shaded,
  colorMotorStatus3D3Shaded,
  colorMotorStatus3D4Shaded,
  colorMotorStatus3D5Shaded,
  fcnSeeTooltipMotor,
  permissaoParaEditarOEstadoDosMotores,
  painelEditaMotoresEmUso,
  motor3D,
  baseMotor3D,
  conjuntoMotor3D,
  suporteDoMotor3D
) => {
  // console.log('EXECUTEI A FUNÇÂO DO PRODUTO');
  let sensorIdForMat = 0;
  // Reverte a ordem dos arcos (padrão antigo/anti-horário)
  const objetoArmazem = layout.arcs.slice().reverse()
  // 2-Criação da superfície
  const superficie = []
  const qtdLinhaLarg = largBaseArmazem
  const distEntreCabos = 1
  let distEntreArcos = 0
  const mediaArco = compBaseArmazem / (objetoArmazem.length + 1)
  let incDistArco = 0

  const linhasArcos = objetoArmazem.length * 2 + 3

  for (let x = 0; x <= linhasArcos; x++) {
    superficie.push([])
    if (x > 1 && x < linhasArcos) {
      if (x % 2 === 1) {
        distEntreArcos = 1
      } else if (x === 2 || x === linhasArcos - 1) {
        distEntreArcos = mediaArco - 0.5
      } else {
        distEntreArcos = mediaArco - 1
      }
      incDistArco += distEntreArcos
    }
    fncSurfaceArmazem(superficie, x, incDistArco, qtdLinhaLarg, distEntreCabos) // TODO:
  }

  // Arcos
  const matCabo = new BABYLON.StandardMaterial('matCabo', scene)
  matCabo.diffuseColor = new BABYLON.Color3(0.1, 0.1, 0.1)
  matCabo.specularColor = new BABYLON.Color3(0, 0, 0)

  const matSensor = new BABYLON.StandardMaterial('matSensor', scene)
  matSensor.specularColor = new BABYLON.Color3(0, 0, 0)

  const matInfoSensor = new BABYLON.StandardMaterial(
    'materialSuperficie',
    scene
  )

  matInfoSensor.specularColor = new BABYLON.Color3(0, 0, 0)
  matInfoSensor.emissiveColor = new BABYLON.Color3(1, 1, 1)
  matInfoSensor.backFaceCulling = false

  const conjuntoArco = new BABYLON.Mesh('conjuntoArco', scene)
  conjuntoArco.position.z = -(largBaseArmazem / 2)
  conjuntoArco.position.y = -profundidadeFundo
  conjuntoArco.position.x = -(compBaseArmazem / 2)

  const texturaInfoObjeto = new BABYLON.DynamicTexture(
    'texturaInfoObjeto',
    512,
    scene,
    false
  ) // era TRUE

  var advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI')
  advancedTexture.useInvalidateRectOptimization = false

  const criaInfoLabels = (arc, arcMesh) => {
    // console.log('ate aqui cheguei ====')
    const cabos = arcMesh.getChildren()
    //for (let i = 0; i < objetoArmazem.length; i += 1) {
    //  if (arc == i) {

    for (let cab = 0; cab < cabos.length; cab += 1) {
      // Cabos

      const sensorsMesh = cabos[cab].getChildMeshes()
      let countSensor = 0

      const stringNomeDoCabo = cabos[cab].name
      const newStringNomeDoCabo = stringNomeDoCabo.slice(
        10,
        stringNomeDoCabo.length
      )

      for (let sen = 0; sen < sensorsMesh.length; sen++) {
        const nameOfSensor = sensorsMesh[sen].name
        const parsedNameOfSensor = nameOfSensor.split('.')

        if (
          parsedNameOfSensor.length === 1 &&
          parsedNameOfSensor[0].includes('Sensor')
        ) {
          //const objSensor =  //objetoArmazem[i].cables[cab].sensors[sen];
          //if (objSensor) {
          countSensor++
          let tempColor = '#FFFFFFFF'
          //tempColor =  `${objSensor.color_hex}FF`;
          tempColor =
            sensorsMesh[sen].sensor && sensorsMesh[sen].sensor.color_hex
          // ('criando 1')
          const objetoSensor = sensorsMesh[sen];
          // console.log('OBJETO SENSOR');
          // console.log(objetoSensor);
          const objetoDeTrasicaoCabo = { cab: newStringNomeDoCabo };

          // let actualRect = criaTooltip(advancedTexture, objetoSensor, arc, objetoDeTrasicaoCabo, sen - 1, sensorsMesh[sen].sensor, 1)
          NomeObjeto(
            arcMesh.name,
            scene,
            tempColor,
            [{ val: `${sensorsMesh[sen].sensor.temperature}`, tam: 220 }],
            objetoSensor,
            0,
            1.4,
            1,
            1,
            fcnSeeTooltip
          )
          //}
        }
      }
    }
  }

  // console.log('Hora ==> ' + new Date())
  const objetoSensorEx = new BABYLON.MeshBuilder.CreateSphere(
    `objetoSensor0`,
    {
      diameter: 0.5,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      updatable: false,
    },
    scene
  )

  const criaTooltip = (
    advancedTexture,
    objetoSensor,
    arc,
    objCabo,
    sen,
    objSensor,
    type
  ) => {
    let rect1 = new GUI.Rectangle()
    advancedTexture.addControl(rect1)
    rect1.width = '100px'
    rect1.height = '100px'
    rect1.thickness = 1
    rect1.linkOffsetX = '50px'
    rect1.linkOffsetY = '-50px'
    rect1.transformCenterX = 0
    rect1.transformCenterY = 1
    rect1.background = 'grey'
    rect1.alpha = 0.9
    rect1.scaleX = 0
    rect1.scaleY = 0

    rect1.linkWithMesh(objetoSensor)

    let text1 = new GUI.TextBlock()

    if (type === 0) {
      text1.text = `ANEL: ${objetoArmazem.length - arc}\nCABO: ${
        objCabo.cab
      }\nSENSOR: ${sen + 1}\nTemp:${
        objSensor.t === 999
          ? 'OFF'
          : objSensor.t === 998
          ? 'N/D'
          : objSensor.t === 997
          ? 'ERR'
          : `${objSensor.t}ºC`
      }`
    } else {
      text1.text = `ANEL: ${objetoArmazem.length - arc}\nCABO: ${
        objCabo.cab
      }\nSENSOR: ${sen + 1}\nTemp:${
        objSensor.temperature === 'OFF'
          ? 'OFF'
          : objSensor.temperature === 'N/D'
          ? 'N/D'
          : objSensor.temperature === 'ERR'
          ? 'ERR'
          : `${objSensor.temperature}°C`
      }`
    }

    text1.color = 'White'
    text1.fontSize = 14
    text1.textWrapping = true
    text1.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
    text1.background = '#006994'
    rect1.addControl(text1)
    text1.alpha = 1 / text1.parent.alpha
    text1.paddingTop = '10px'

    // let scaleXAnimation = new BABYLON.Animation("myAnimation", "scaleX", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
    // let scaleYAnimation = new BABYLON.Animation("myAnimation", "scaleY", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);

    // var keys = [];

    // keys.push({
    //   frame: 0,
    //   value: 0
    // });
    // keys.push({
    //   frame: 10,
    //   value: 1
    // });

    // scaleXAnimation.setKeys(keys);
    // scaleYAnimation.setKeys(keys);
    // rect1.animations = [];
    // rect1.animations.push(scaleXAnimation);
    // rect1.animations.push(scaleYAnimation);

    return rect1
  }

  const criaTooltip2 = (
    nome,
    scene,
    corFundo,
    id,
    parent,
    altura,
    tamanho,
    x = 0,
    z = -3
  ) => {
    const toolTip = BABYLON.Mesh.CreatePlane(
      `toolTip${nome}`,
      tamanho,
      scene,
      false
    )

    // console.log('PARENT');
    // console.log(parent.sensor);

    // BORDA
    toolTip.enableEdgesRendering()
    toolTip.edgesWidth = 4.0
    toolTip.edgesColor = '#fff'
    toolTip.checkCollisions = true
    //
    toolTip.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL
    if (parent.sensor.sensor_id <= 6) {
      toolTip.position.x = 3
      toolTip.position.z = 1
      toolTip.position.y = 3
    } else {
      toolTip.position.x = 3
      toolTip.position.z = 1
      toolTip.position.y = -3
    }
    toolTip.parent = parent
    const texturaTooltip = new BABYLON.DynamicTexture(
      'texturaTooltip',
      { width: 600, height: 560 },
      scene
    )
    const fontSize = 100

    let label1 = `ARCO: ${parent.sensor.arc_index}`
    let label2 = `CABO: ${parent.sensor.cable_number}`
    let label3 = `SENSOR: ${parent.sensor.sensor_id}`
    let label4 = `Temp:${
      parent.sensor.temperature === 999 || parent.sensor.temperature === 'OFF'
        ? 'OFF'
        : parent.sensor.temperature === 998 ||
          parent.sensor.temperature === 'N/D'
        ? 'N/D'
        : parent.sensor.temperature === 997 ||
          parent.sensor.temperature === 'ERR'
        ? 'ERR'
        : `${parent.sensor.temperature}ºC`
    }`

    const font = `bold ${fontSize}px arial`

    texturaTooltip.drawText(
      label1,
      null,
      100,
      font,
      'white',
      corFundo,
      true,
      true
    )
    texturaTooltip.drawText(label2, null, 220, font, 'white', null, true, true)
    texturaTooltip.drawText(label3, null, 340, font, 'white', null, true, true)
    texturaTooltip.drawText(label4, null, 460, font, 'white', null, true, true)
    toolTip.material = new BABYLON.StandardMaterial('materialSuperficie', scene)
    toolTip.material.diffuseTexture = texturaTooltip
    toolTip.material.specularColor = new BABYLON.Color3(0, 0, 0)
    toolTip.material.emissiveColor = new BABYLON.Color3(1, 1, 1)
    toolTip.material.backFaceCulling = false
    toolTip.isPickable = true
    texturaTooltip.hasAlpha = true
    toolTip.material.freeze()

    // toolTip.actionManager = new BABYLON.ActionManager(scene);

    // toolTip.actionManager.registerAction(
    //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function (ev) {
    //     scene.beginAnimation(actualRect, 0, 10, false)
    //   }));

    // toolTip.actionManager.registerAction(
    //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, function (ev) {
    //     scene.beginAnimation(actualRect, 10, 0, false)
    //   }));

    return toolTip
  }

  const proporcaoLargB = largBaseArmazem / 7

  // 3-Desenha o arco, cabo e sensor
  for (let arc = 0; arc < objetoArmazem.length; arc += 1) {
    const objetoArco = new BABYLON.Mesh(`objetoArco${arc}`, scene)
    objetoArco.parent = conjuntoArco

    objetoArco.position.x =
      (compBaseArmazem / (objetoArmazem.length + 1)) * (arc + 1)

    const objArco = objetoArmazem[arc]
    const maxDepthCableArc = maxDepthCableArcCalc(objArco) // Quando possuir profundidade no armazém, considera o real nível

    for (let cab = 0; cab < objArco.cables.length; cab += 1) {
      //const alturaCaboTeto = ((alturaTelhadoArmazem) * (Math.sin(BABYLON.Tools.ToRadians(180 / (largBaseArmazem)) * ((cab + 1) * (largBaseArmazem / (objArco.cables.length + 1))))));
      let myPosZ =
        (objArco.cables[cab].positionZ * largBaseArmazem) / 192 -
        largBaseArmazem / 7
      const alturaCaboTeto =
        alturaTelhadoArmazem *
        Math.sin(BABYLON.Tools.ToRadians(180 / largBaseArmazem) * myPosZ)
      const alturaCabo =
        alturaParedeArmazem + profundidadeFundo + alturaCaboTeto
      const objCabo = objArco.cables[cab]
      const posCaboYInit =
        profundidadeFundo *
        Math.sin(BABYLON.Tools.ToRadians(180 / largBaseArmazem) * myPosZ)
      const caminhoTubo = [
        new BABYLON.Vector3(
          0,
          -(posCaboYInit - (profundidadeFundo + 0.75)),
          0.0
        ),
        new BABYLON.Vector3(0, alturaCabo, 0.0),
      ]

      const objetoCabo = BABYLON.MeshBuilder.CreateTube(
        `objetoCabo${objArco.cables[cab].cab}`,
        {
          path: caminhoTubo,
          radius: 0.05,
          sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          updatable: false,
        },
        scene
      )
      objetoCabo.material = matCabo
      // Calcula position z 🌵
      // 255 é o valor maximo do padrao
      // 28 - 220  (35)

      //objetoCabo.position.z = (((objArco.cables[cab].position_z * (largBaseArmazem)) - 28) / 225);
      objetoCabo.position.z =
        (objArco.cables[cab].positionZ * largBaseArmazem) / 192 -
        largBaseArmazem / 7
      // if (arc === 0 || arc === objetoArmazem.length - 1) {
      //   objCabo.haveMotor = 1;
      //   objCabo.info = [{
      //     id: 106,
      //     nameMotor: 'Motor 106',
      //     operation: 'M',
      //     onOff: 'L',
      //     panelMotorActive: false,
      //     status: 8,
      //     aeration: "09:59"
      //   }, {
      //     id: 107,
      //     nameMotor: 'Motor 107',
      //     operation: 'M',
      //     onOff: 'L',
      //     panelMotorActive: false,
      //     status: 8,
      //     aeration: "09:59"
      //   }]
      // } else {
      //   if (cab === 0 || cab === objArco.cables.length - 1) {
      //     objCabo.haveMotor = 1;
      //     objCabo.info = [{
      //       id: 106,
      //       nameMotor: 'Motor 106',
      //       operation: 'M',
      //       onOff: 'L',
      //       panelMotorActive: false,
      //       status: 8,
      //       aeration: "09:59"
      //     }, {
      //       id: 107,
      //       nameMotor: 'Motor 107',
      //       operation: 'M',
      //       onOff: 'L',
      //       panelMotorActive: false,
      //       status: 8,
      //       aeration: "09:59"
      //     }]
      //   }

      //   if (arc % 2 === 0) {
      //     if (cab === 1) {
      //       objCabo.haveMotor = 1;
      //       objCabo.info = [{
      //         id: 106,
      //         nameMotor: 'Motor 106',
      //         operation: 'M',
      //         onOff: 'L',
      //         panelMotorActive: false,
      //         status: 8,
      //         aeration: "09:59"
      //       }, {
      //         id: 107,
      //         nameMotor: 'Motor 107',
      //         operation: 'M',
      //         onOff: 'L',
      //         panelMotorActive: false,
      //         status: 8,
      //         aeration: "09:59"
      //       }]
      //     }
      //   } else {
      //     if (cab === objArco.cables.length - 2) {
      //       objCabo.haveMotor = 1;
      //       objCabo.info = [{
      //         id: 106,
      //         nameMotor: 'Motor 106',
      //         operation: 'M',
      //         onOff: 'L',
      //         panelMotorActive: false,
      //         status: 8,
      //         aeration: "09:59"
      //       }, {
      //         id: 107,
      //         nameMotor: 'Motor 107',
      //         operation: 'M',
      //         onOff: 'L',
      //         panelMotorActive: false,
      //         status: 8,
      //         aeration: "09:59"
      //       }]
      //     }
      //   }
      // }

      if (objCabo.info !== null) {
        if (objCabo.info) {
          let camada = 0
          for (let i = 0; i < objCabo.info.length; i++) {
            const id = objCabo.info[i].idMotor;
            // console.log(`vez = ${i + 1}`);
            const auxRererenciaDoArco = arc + 1;
            const auxPositionX = auxRererenciaDoArco * distEntreArcos;

            // console.log('PROPS FOR GENERATE POSITION');
            // console.log(`arc == ${arc}`);
            // console.log(`auxRererenciaDoArco == ${auxRererenciaDoArco}`);
            // console.log(`auxPositionX == ${auxPositionX}`);

            if (auxPositionX < compBaseArmazem / 2) {
              // console.log('menor');
              if (cab > 0 && cab < objArco.cables.length - 1) {
                if (arc === 0) {
                  // CLONA E CRIA BASE
                  const positionZ = calculaPositionZDoMotor(
                    objArco.cables[cab].positionZ,
                    largBaseArmazem,
                    proporcaoLargB
                  )
                  const base = baseMotor3D.clone(`base${id}`)
                  // base.parent = objetoCabo;
                  base.position.z =
                    positionZ > largBaseArmazem / 2
                      ? positionZ - largBaseArmazem / 2
                      : -(largBaseArmazem / 2 - positionZ)
                  base.position.x = -(compBaseArmazem / 2 + 2)
                  base.position.y = 0
                  base.visibility = 1
                  base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(270))

                  // CRIA SUPORTE DO MOTOR
                  const suporteDoMotor = suporteDoMotor3D.clone(`suporte${id}`)
                  suporteDoMotor.parent = base
                  suporteDoMotor.visibility = 1
                  const suportePlacaNome = suporteDoMotor3D.clone(
                    `suportePlacaNome${id}`
                  )
                  suportePlacaNome.parent = base
                  suportePlacaNome.position.x = 0
                  suportePlacaNome.position.z = 2.2
                  suportePlacaNome.visibility = 0

                  const suportePlacaStatus = suporteDoMotor3D.clone(
                    `suportePlacaStatus${id}`
                  )
                  suportePlacaStatus.parent = base
                  suportePlacaStatus.position.x = 0
                  suportePlacaStatus.position.y = 1
                  suportePlacaStatus.position.z = 2
                  suportePlacaStatus.visibility = 0

                  // CLONA E CRIA MOTOR
                  const motor = motor3D.clone(`motor${id}`)
                  if (motor) {
                    motor.parent = base
                    motor.info = objCabo.info[i]
                    motor.visibility = 1

                    if (
                      motor.info.status === 1 ||
                      motor.info.status === 2 ||
                      motor.info.status === 10
                    ) {
                      motor.material = colorMotorStatus3D3Shaded
                    } else if (
                      motor.info.status === 3 ||
                      motor.info.status === 4
                    ) {
                      motor.material = colorMotorStatus3D2Shaded
                    } else if (
                      motor.info.status === 5 ||
                      motor.info.status === 6
                    ) {
                      motor.material = colorMotorStatus3D4Shaded
                    } else if (
                      motor.info.status === 7 ||
                      motor.info.status === 8 ||
                      motor.info.status === 9
                    ) {
                      motor.material = colorMotorStatus3D1Shaded
                    }

                    motor.actionManager = new BABYLON.ActionManager(scene)

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }

                  if (base) {
                    LabelNameMotor(
                      scene,
                      motor.info.nameMotor,
                      1.5,
                      suportePlacaNome
                    )
                  }

                  if (motor && motor.info) {
                    // CRIA CONJUNTO MOTO
                    const conjuntoMotor = conjuntoMotor3D.clone(
                      `conjuntoMotor3D${id}`
                    )
                    conjuntoMotor.info = motor.info
                    conjuntoMotor.parent = base
                    conjuntoMotor.visibility = 1

                    const placaStatusOnOffMotor = StatusOnOffMotor(
                      scene,
                      suportePlacaStatus,
                      0.6,
                      conjuntoMotor.info.operation,
                      conjuntoMotor.info.idMotor,
                    );
                    // console.log(placaStatusOnOffMotor);

                    if (
                      conjuntoMotor.info.status === 1 ||
                      conjuntoMotor.info.status === 2 ||
                      conjuntoMotor.info.status === 10
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D3
                    } else if (
                      conjuntoMotor.info.status === 3 ||
                      conjuntoMotor.info.status === 4
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D2
                    } else if (
                      conjuntoMotor.info.status === 5 ||
                      conjuntoMotor.info.status === 6
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D4
                    } else if (
                      conjuntoMotor.info.status === 7 ||
                      conjuntoMotor.info.status === 8 ||
                      conjuntoMotor.info.status === 9
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D1
                    }

                    conjuntoMotor.actionManager = new BABYLON.ActionManager(
                      scene
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }
                } else {
                  // console.log('arco não é igual a zero - menor');
                  // console.log(objArco.cables);

                  const positionZ = calculaPositionZDoMotor(
                    objArco.cables[cab].positionZ,
                    largBaseArmazem,
                    proporcaoLargB
                  )

                  const referenciaDoArco = arc + 1
                  const positionX = referenciaDoArco * (distEntreArcos + 0.5)
                  const finalPositionX = -(compBaseArmazem / 2 - positionX)

                  if (positionZ) {
                    // console.log(`é maior | cab = ${cab} = ${positionZ}`);
                    const numberOfMotors =
                      objArco.cables[objArco.cables.length - 1].info

                    // CLONA E CRIA BASE
                    const base = baseMotor3D.clone(`base${id}`)
                    // base.parent = objetoCabo;

                    if (numberOfMotors) {
                      if (camada > 0) {
                        base.position.z =
                          (largBaseArmazem + 5) / 2 +
                          (numberOfMotors.length * 6 + 1.5) +
                          camada * 6
                      } else {
                        base.position.z =
                          (largBaseArmazem + 5) / 2 +
                          (numberOfMotors.length * 6 + 1.5)
                      }
                    } else {
                      if (camada > 0) {
                        base.position.z = base.position.z =
                          (largBaseArmazem + 5) / 2 + camada * 6
                      } else {
                        base.position.z = base.position.z =
                          (largBaseArmazem + 5) / 2
                      }
                    }
                    base.position.x = finalPositionX
                    base.position.y = 0
                    base.visibility = 1
                    // base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(180));

                    // CRIA SUPORTE DO MOTOR
                    const suporteDoMotor = suporteDoMotor3D.clone(
                      `suporte${id}`
                    )
                    suporteDoMotor.parent = base
                    suporteDoMotor.visibility = 1
                    const suportePlacaNome = suporteDoMotor3D.clone(
                      `suportePlacaNome${id}`
                    )
                    suportePlacaNome.parent = base
                    suportePlacaNome.position.x = 0
                    suportePlacaNome.position.z = 2.2
                    suportePlacaNome.visibility = 0

                    const suportePlacaStatus = suporteDoMotor3D.clone(
                      `suportePlacaStatus${id}`
                    )
                    suportePlacaStatus.parent = base
                    suportePlacaStatus.position.x = 0
                    suportePlacaStatus.position.y = 1
                    suportePlacaStatus.position.z = 2
                    suportePlacaStatus.visibility = 0

                    // CLONA E CRIA MOTOR
                    const motor = motor3D.clone(`motor${id}`)
                    if (motor) {
                      motor.parent = base
                      motor.info = objCabo.info[i]
                      motor.visibility = 1

                      if (
                        motor.info.status === 1 ||
                        motor.info.status === 2 ||
                        motor.info.status === 10
                      ) {
                        motor.material = colorMotorStatus3D3Shaded
                      } else if (
                        motor.info.status === 3 ||
                        motor.info.status === 4
                      ) {
                        motor.material = colorMotorStatus3D2Shaded
                      } else if (
                        motor.info.status === 5 ||
                        motor.info.status === 6
                      ) {
                        motor.material = colorMotorStatus3D4Shaded
                      } else if (
                        motor.info.status === 7 ||
                        motor.info.status === 8 ||
                        motor.info.status === 9
                      ) {
                        motor.material = colorMotorStatus3D1Shaded
                      }

                      motor.actionManager = new BABYLON.ActionManager(scene)

                      motor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOverTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  ev.meshUnderPointer.info,
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )

                      motor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOutTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  'remove',
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )
                    }

                    if (base) {
                      LabelNameMotor(
                        scene,
                        motor.info.nameMotor,
                        1.5,
                        suportePlacaNome
                      )
                    }

                    if (motor && motor.info) {
                      // CRIA CONJUNTO MOTO
                      const conjuntoMotor = conjuntoMotor3D.clone(
                        `conjuntoMotor3D${id}`
                      )
                      conjuntoMotor.info = motor.info
                      conjuntoMotor.parent = base
                      conjuntoMotor.visibility = 1

                      const placaStatusOnOffMotor = StatusOnOffMotor(
                        scene,
                        suportePlacaStatus,
                        0.6,
                        conjuntoMotor.info.operation,
                        conjuntoMotor.info.idMotor,
                      );
                      // console.log(placaStatusOnOffMotor);

                      if (
                        conjuntoMotor.info.status === 1 ||
                        conjuntoMotor.info.status === 2 ||
                        conjuntoMotor.info.status === 10
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D3
                      } else if (
                        conjuntoMotor.info.status === 3 ||
                        conjuntoMotor.info.status === 4
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D2
                      } else if (
                        conjuntoMotor.info.status === 5 ||
                        conjuntoMotor.info.status === 6
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D4
                      } else if (
                        conjuntoMotor.info.status === 7 ||
                        conjuntoMotor.info.status === 8 ||
                        conjuntoMotor.info.status === 9
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D1
                      }

                      conjuntoMotor.actionManager = new BABYLON.ActionManager(
                        scene
                      )

                      conjuntoMotor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOverTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  ev.meshUnderPointer.info,
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )

                      conjuntoMotor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOutTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  'remove',
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )
                    }
                  }
                }
              } else {
                const referenciaDoArco = arc + 1
                const positionX = referenciaDoArco * (distEntreArcos + 0.5)
                const finalPositionX = -(compBaseArmazem / 2 - positionX)
                if (cab === objArco.cables.length - 1) {
                  // CLONA E CRIA BASE
                  const base = baseMotor3D.clone(`base${id}`)
                  // base.parent = objetoCabo;
                  if (camada > 0) {
                    base.position.z = (largBaseArmazem + 5) / 2 + camada * 6
                  } else {
                    base.position.z = (largBaseArmazem + 5) / 2
                  }

                  base.position.x = finalPositionX
                  base.position.y = 0
                  base.visibility = 1
                  // base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(-180));

                  // CRIA SUPORTE DO MOTOR
                  const suporteDoMotor = suporteDoMotor3D.clone(`suporte${id}`)
                  suporteDoMotor.parent = base
                  suporteDoMotor.visibility = 1
                  const suportePlacaNome = suporteDoMotor3D.clone(
                    `suportePlacaNome${id}`
                  )
                  suportePlacaNome.parent = base
                  suportePlacaNome.position.x = 0
                  suportePlacaNome.position.z = 2.2
                  suportePlacaNome.visibility = 0

                  const suportePlacaStatus = suporteDoMotor3D.clone(
                    `suportePlacaStatus${id}`
                  )
                  suportePlacaStatus.parent = base
                  suportePlacaStatus.position.x = 0
                  suportePlacaStatus.position.y = 1
                  suportePlacaStatus.position.z = 2
                  suportePlacaStatus.visibility = 0

                  // CLONA E CRIA MOTOR
                  const motor = motor3D.clone(`motor${id}`)
                  if (motor) {
                    motor.parent = base
                    motor.info = objCabo.info[i]
                    motor.visibility = 1

                    if (
                      motor.info.status === 1 ||
                      motor.info.status === 2 ||
                      motor.info.status === 10
                    ) {
                      motor.material = colorMotorStatus3D3Shaded
                    } else if (
                      motor.info.status === 3 ||
                      motor.info.status === 4
                    ) {
                      motor.material = colorMotorStatus3D2Shaded
                    } else if (
                      motor.info.status === 5 ||
                      motor.info.status === 6
                    ) {
                      motor.material = colorMotorStatus3D4Shaded
                    } else if (
                      motor.info.status === 7 ||
                      motor.info.status === 8 ||
                      motor.info.status === 9
                    ) {
                      motor.material = colorMotorStatus3D1Shaded
                    }

                    motor.actionManager = new BABYLON.ActionManager(scene)

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }

                  if (base) {
                    LabelNameMotor(
                      scene,
                      motor.info.nameMotor,
                      1.5,
                      suportePlacaNome
                    )
                  }

                  if (motor && motor.info) {
                    // CRIA CONJUNTO MOTO
                    const conjuntoMotor = conjuntoMotor3D.clone(
                      `conjuntoMotor3D${id}`
                    )
                    conjuntoMotor.info = motor.info
                    conjuntoMotor.parent = base
                    conjuntoMotor.visibility = 1

                    const placaStatusOnOffMotor = StatusOnOffMotor(
                      scene,
                      suportePlacaStatus,
                      0.6,
                      conjuntoMotor.info.operation,
                      conjuntoMotor.info.idMotor,
                    );
                    // console.log(placaStatusOnOffMotor);

                    if (
                      conjuntoMotor.info.status === 1 ||
                      conjuntoMotor.info.status === 2 ||
                      conjuntoMotor.info.status === 10
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D3
                    } else if (
                      conjuntoMotor.info.status === 3 ||
                      conjuntoMotor.info.status === 4
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D2
                    } else if (
                      conjuntoMotor.info.status === 5 ||
                      conjuntoMotor.info.status === 6
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D4
                    } else if (
                      conjuntoMotor.info.status === 7 ||
                      conjuntoMotor.info.status === 8 ||
                      conjuntoMotor.info.status === 9
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D1
                    }

                    conjuntoMotor.actionManager = new BABYLON.ActionManager(
                      scene
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }
                } else {
                  // CLONA E CRIA BASE
                  const base = baseMotor3D.clone(`base${id}`)
                  // base.parent = objetoCabo;
                  if (camada > 0) {
                    base.position.z = -((largBaseArmazem + 5) / 2 + camada * 6)
                  } else {
                    base.position.z = -((largBaseArmazem + 5) / 2)
                  }
                  base.position.x = finalPositionX
                  base.position.y = 0
                  base.visibility = 1
                  // base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(180));

                  // CRIA SUPORTE DO MOTOR
                  const suporteDoMotor = suporteDoMotor3D.clone(`suporte${id}`)
                  suporteDoMotor.parent = base
                  suporteDoMotor.visibility = 1
                  const suportePlacaNome = suporteDoMotor3D.clone(
                    `suportePlacaNome${id}`
                  )
                  suportePlacaNome.parent = base
                  suportePlacaNome.position.x = 0
                  suportePlacaNome.position.z = 2.2
                  suportePlacaNome.visibility = 0

                  const suportePlacaStatus = suporteDoMotor3D.clone(
                    `suportePlacaStatus${id}`
                  )
                  suportePlacaStatus.parent = base
                  suportePlacaStatus.position.x = 0
                  suportePlacaStatus.position.y = 1
                  suportePlacaStatus.position.z = 2
                  suportePlacaStatus.visibility = 0

                  // CLONA E CRIA MOTOR
                  const motor = motor3D.clone(`motor${id}`)
                  if (motor) {
                    motor.parent = base
                    motor.info = objCabo.info[i]
                    motor.visibility = 1

                    if (
                      motor.info.status === 1 ||
                      motor.info.status === 2 ||
                      motor.info.status === 10
                    ) {
                      motor.material = colorMotorStatus3D3Shaded
                    } else if (
                      motor.info.status === 3 ||
                      motor.info.status === 4
                    ) {
                      motor.material = colorMotorStatus3D2Shaded
                    } else if (
                      motor.info.status === 5 ||
                      motor.info.status === 6
                    ) {
                      motor.material = colorMotorStatus3D4Shaded
                    } else if (
                      motor.info.status === 7 ||
                      motor.info.status === 8 ||
                      motor.info.status === 9
                    ) {
                      motor.material = colorMotorStatus3D1Shaded
                    }

                    motor.actionManager = new BABYLON.ActionManager(scene)

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }

                  if (base) {
                    LabelNameMotor(
                      scene,
                      motor.info.nameMotor,
                      1.5,
                      suportePlacaNome
                    )
                  }

                  if (motor && motor.info) {
                    // CRIA CONJUNTO MOTO
                    const conjuntoMotor = conjuntoMotor3D.clone(
                      `conjuntoMotor3D${id}`
                    )
                    conjuntoMotor.info = motor.info
                    conjuntoMotor.parent = base
                    conjuntoMotor.visibility = 1

                    const placaStatusOnOffMotor = StatusOnOffMotor(
                      scene,
                      suportePlacaStatus,
                      0.6,
                      conjuntoMotor.info.operation,
                      conjuntoMotor.info.idMotor,
                    );
                    // console.log(placaStatusOnOffMotor);

                    if (
                      conjuntoMotor.info.status === 1 ||
                      conjuntoMotor.info.status === 2 ||
                      conjuntoMotor.info.status === 10
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D3
                    } else if (
                      conjuntoMotor.info.status === 3 ||
                      conjuntoMotor.info.status === 4
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D2
                    } else if (
                      conjuntoMotor.info.status === 5 ||
                      conjuntoMotor.info.status === 6
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D4
                    } else if (
                      conjuntoMotor.info.status === 7 ||
                      conjuntoMotor.info.status === 8 ||
                      conjuntoMotor.info.status === 9
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D1
                    }

                    conjuntoMotor.actionManager = new BABYLON.ActionManager(
                      scene
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }
                }
              }
            } else {
              // console.log('maior');
              if (cab > 0 && cab < objArco.cables.length - 1) {
                if (arc === objetoArmazem.length - 1) {
                  // CLONA E CRIA BASE
                  const positionZ = calculaPositionZDoMotor(
                    objArco.cables[cab].positionZ,
                    largBaseArmazem,
                    proporcaoLargB
                  )

                  // console.log(`cab = ${cab} -- inner positionZ = ${positionZ}`);
                  // console.log(
                  //   `result positionZ = ${
                  //     positionZ > largBaseArmazem / 2
                  //       ? positionZ - largBaseArmazem / 2
                  //       : -(largBaseArmazem / 2 - positionZ)
                  //   }`,
                  // );
                  const base = baseMotor3D.clone(`base${id}`);
                  // base.parent = objetoCabo;
                  base.position.z =
                    positionZ > largBaseArmazem / 2
                      ? positionZ - largBaseArmazem / 2
                      : -(largBaseArmazem / 2 - positionZ)
                  base.position.x = compBaseArmazem / 2 + 2
                  base.position.y = 0
                  base.visibility = 1
                  base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(-270))

                  // CRIA SUPORTE DO MOTOR
                  const suporteDoMotor = suporteDoMotor3D.clone(`suporte${id}`)
                  suporteDoMotor.parent = base
                  suporteDoMotor.visibility = 1
                  const suportePlacaNome = suporteDoMotor3D.clone(
                    `suportePlacaNome${id}`
                  )
                  suportePlacaNome.parent = base
                  suportePlacaNome.position.x = 0
                  suportePlacaNome.position.z = 2.2
                  suportePlacaNome.visibility = 0

                  const suportePlacaStatus = suporteDoMotor3D.clone(
                    `suportePlacaStatus${id}`
                  )
                  suportePlacaStatus.parent = base
                  suportePlacaStatus.position.x = 0
                  suportePlacaStatus.position.y = 1
                  suportePlacaStatus.position.z = 2
                  suportePlacaStatus.visibility = 0

                  // CLONA E CRIA MOTOR
                  const motor = motor3D.clone(`motor${id}`)
                  if (motor) {
                    motor.parent = base
                    motor.info = objCabo.info[i]
                    motor.visibility = 1

                    if (
                      motor.info.status === 1 ||
                      motor.info.status === 2 ||
                      motor.info.status === 10
                    ) {
                      motor.material = colorMotorStatus3D3Shaded
                    } else if (
                      motor.info.status === 3 ||
                      motor.info.status === 4
                    ) {
                      motor.material = colorMotorStatus3D2Shaded
                    } else if (
                      motor.info.status === 5 ||
                      motor.info.status === 6
                    ) {
                      motor.material = colorMotorStatus3D4Shaded
                    } else if (
                      motor.info.status === 7 ||
                      motor.info.status === 8 ||
                      motor.info.status === 9
                    ) {
                      motor.material = colorMotorStatus3D1Shaded
                    }

                    motor.actionManager = new BABYLON.ActionManager(scene)

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }

                  if (base) {
                    LabelNameMotor(
                      scene,
                      motor.info.nameMotor,
                      1.5,
                      suportePlacaNome
                    )
                  }

                  if (motor && motor.info) {
                    // CRIA CONJUNTO MOTO
                    const conjuntoMotor = conjuntoMotor3D.clone(
                      `conjuntoMotor3D${id}`
                    )
                    conjuntoMotor.info = motor.info
                    conjuntoMotor.parent = base
                    conjuntoMotor.visibility = 1

                    const placaStatusOnOffMotor = StatusOnOffMotor(
                      scene,
                      suportePlacaStatus,
                      0.6,
                      conjuntoMotor.info.operation,
                      conjuntoMotor.info.idMotor,
                    );
                    // console.log(placaStatusOnOffMotor);

                    if (
                      conjuntoMotor.info.status === 1 ||
                      conjuntoMotor.info.status === 2 ||
                      conjuntoMotor.info.status === 10
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D3
                    } else if (
                      conjuntoMotor.info.status === 3 ||
                      conjuntoMotor.info.status === 4
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D2
                    } else if (
                      conjuntoMotor.info.status === 5 ||
                      conjuntoMotor.info.status === 6
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D4
                    } else if (
                      conjuntoMotor.info.status === 7 ||
                      conjuntoMotor.info.status === 8 ||
                      conjuntoMotor.info.status === 9
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D1
                    }

                    conjuntoMotor.actionManager = new BABYLON.ActionManager(
                      scene
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }
                } else {
                  // console.log('arco não é igual a zero - menor');
                  // console.log(objArco.cables);

                  const positionZ = calculaPositionZDoMotor(
                    objArco.cables[cab].positionZ,
                    largBaseArmazem,
                    proporcaoLargB
                  )

                  const referenciaDoArco = arc + 1
                  const positionX = referenciaDoArco * (distEntreArcos + 0.5)
                  const finalPositionX = positionX - compBaseArmazem / 2

                  if (positionZ) {
                    // console.log(`é maior | cab = ${cab} = ${positionZ}`);
                    const numberOfMotors =
                      objArco.cables[objArco.cables.length - 1].info

                    // CLONA E CRIA BASE
                    const base = baseMotor3D.clone(`base${id}`)
                    // base.parent = objetoCabo;

                    if (numberOfMotors) {
                      if (camada > 0) {
                        base.position.z =
                          (largBaseArmazem + 5) / 2 +
                          (numberOfMotors.length * 6 + 1.5) +
                          camada * 6
                      } else {
                        base.position.z =
                          (largBaseArmazem + 5) / 2 +
                          (numberOfMotors.length * 6 + 1.5)
                      }
                    } else {
                      if (camada > 0) {
                        base.position.z = base.position.z =
                          (largBaseArmazem + 5) / 2 + camada * 6
                      } else {
                        base.position.z = base.position.z =
                          (largBaseArmazem + 5) / 2
                      }
                    }

                    base.position.x = finalPositionX
                    base.position.y = 0
                    base.visibility = 1
                    // base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(180));

                    // CRIA SUPORTE DO MOTOR
                    const suporteDoMotor = suporteDoMotor3D.clone(
                      `suporte${id}`
                    )
                    suporteDoMotor.parent = base
                    suporteDoMotor.visibility = 1
                    const suportePlacaNome = suporteDoMotor3D.clone(
                      `suportePlacaNome${id}`
                    )
                    suportePlacaNome.parent = base
                    suportePlacaNome.position.x = 0
                    suportePlacaNome.position.z = 2.2
                    suportePlacaNome.visibility = 0

                    const suportePlacaStatus = suporteDoMotor3D.clone(
                      `suportePlacaStatus${id}`
                    )
                    suportePlacaStatus.parent = base
                    suportePlacaStatus.position.x = 0
                    suportePlacaStatus.position.y = 1
                    suportePlacaStatus.position.z = 2
                    suportePlacaStatus.visibility = 0

                    // CLONA E CRIA MOTOR
                    const motor = motor3D.clone(`motor${id}`)
                    if (motor) {
                      motor.parent = base
                      motor.info = objCabo.info[i]
                      motor.visibility = 1

                      if (
                        motor.info.status === 1 ||
                        motor.info.status === 2 ||
                        motor.info.status === 10
                      ) {
                        motor.material = colorMotorStatus3D3Shaded
                      } else if (
                        motor.info.status === 3 ||
                        motor.info.status === 4
                      ) {
                        motor.material = colorMotorStatus3D2Shaded
                      } else if (
                        motor.info.status === 5 ||
                        motor.info.status === 6
                      ) {
                        motor.material = colorMotorStatus3D4Shaded
                      } else if (
                        motor.info.status === 7 ||
                        motor.info.status === 8 ||
                        motor.info.status === 9
                      ) {
                        motor.material = colorMotorStatus3D1Shaded
                      }

                      motor.actionManager = new BABYLON.ActionManager(scene)

                      motor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOverTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  ev.meshUnderPointer.info,
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )

                      motor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOutTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  'remove',
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )
                    }

                    if (base) {
                      LabelNameMotor(
                        scene,
                        motor.info.nameMotor,
                        1.5,
                        suportePlacaNome
                      )
                    }

                    if (motor && motor.info) {
                      // CRIA CONJUNTO MOTO
                      const conjuntoMotor = conjuntoMotor3D.clone(
                        `conjuntoMotor3D${id}`
                      )
                      conjuntoMotor.info = motor.info
                      conjuntoMotor.parent = base
                      conjuntoMotor.visibility = 1

                      const placaStatusOnOffMotor = StatusOnOffMotor(
                        scene,
                        suportePlacaStatus,
                        0.6,
                        conjuntoMotor.info.operation,
                        conjuntoMotor.info.idMotor,
                      );
                      // console.log(placaStatusOnOffMotor);

                      if (
                        conjuntoMotor.info.status === 1 ||
                        conjuntoMotor.info.status === 2 ||
                        conjuntoMotor.info.status === 10
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D3
                      } else if (
                        conjuntoMotor.info.status === 3 ||
                        conjuntoMotor.info.status === 4
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D2
                      } else if (
                        conjuntoMotor.info.status === 5 ||
                        conjuntoMotor.info.status === 6
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D4
                      } else if (
                        conjuntoMotor.info.status === 7 ||
                        conjuntoMotor.info.status === 8 ||
                        conjuntoMotor.info.status === 9
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D1
                      }

                      conjuntoMotor.actionManager = new BABYLON.ActionManager(
                        scene
                      )

                      conjuntoMotor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOverTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  ev.meshUnderPointer.info,
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )

                      conjuntoMotor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOutTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  'remove',
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )
                    }
                  }
                }
              } else {
                const referenciaDoArco = arc + 1;
                // console.log(`referenciaDoArco 2 = ${referenciaDoArco}`);
                const positionX = referenciaDoArco * (distEntreArcos + 0.5);
                // console.log(`positionX 2 = ${positionX}`);
                const finalPositionX = positionX - compBaseArmazem / 2;
                // console.log(`finalPositionX 2 = ${finalPositionX}`);
                if (cab === objArco.cables.length - 1) {
                  // CLONA E CRIA BASE
                  const base = baseMotor3D.clone(`base${id}`)
                  // base.parent = objetoCabo;
                  if (camada > 0) {
                    base.position.z = (largBaseArmazem + 5) / 2 + camada * 6
                  } else {
                    base.position.z = (largBaseArmazem + 5) / 2
                  }

                  base.position.x = finalPositionX
                  base.position.y = 0
                  base.visibility = 1
                  // base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(90));

                  // CRIA SUPORTE DO MOTOR
                  const suporteDoMotor = suporteDoMotor3D.clone(`suporte${id}`)
                  suporteDoMotor.parent = base
                  suporteDoMotor.visibility = 1
                  const suportePlacaNome = suporteDoMotor3D.clone(
                    `suportePlacaNome${id}`
                  )
                  suportePlacaNome.parent = base
                  suportePlacaNome.position.x = 0
                  suportePlacaNome.position.z = 2.2
                  suportePlacaNome.visibility = 0

                  const suportePlacaStatus = suporteDoMotor3D.clone(
                    `suportePlacaStatus${id}`
                  )
                  suportePlacaStatus.parent = base
                  suportePlacaStatus.position.x = 0
                  suportePlacaStatus.position.y = 1
                  suportePlacaStatus.position.z = 2
                  suportePlacaStatus.visibility = 0

                  // CLONA E CRIA MOTOR
                  const motor = motor3D.clone(`motor${id}`)
                  if (motor) {
                    motor.parent = base
                    motor.info = objCabo.info[i]
                    motor.visibility = 1

                    if (
                      motor.info.status === 1 ||
                      motor.info.status === 2 ||
                      motor.info.status === 10
                    ) {
                      motor.material = colorMotorStatus3D3Shaded
                    } else if (
                      motor.info.status === 3 ||
                      motor.info.status === 4
                    ) {
                      motor.material = colorMotorStatus3D2Shaded
                    } else if (
                      motor.info.status === 5 ||
                      motor.info.status === 6
                    ) {
                      motor.material = colorMotorStatus3D4Shaded
                    } else if (
                      motor.info.status === 7 ||
                      motor.info.status === 8 ||
                      motor.info.status === 9
                    ) {
                      motor.material = colorMotorStatus3D1Shaded
                    }

                    motor.actionManager = new BABYLON.ActionManager(scene)

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }

                  if (base) {
                    LabelNameMotor(
                      scene,
                      motor.info.nameMotor,
                      1.5,
                      suportePlacaNome
                    )
                  }

                  if (motor && motor.info) {
                    // CRIA CONJUNTO MOTO
                    const conjuntoMotor = conjuntoMotor3D.clone(
                      `conjuntoMotor3D${id}`
                    )
                    conjuntoMotor.info = motor.info
                    conjuntoMotor.parent = base
                    conjuntoMotor.visibility = 1

                    const placaStatusOnOffMotor = StatusOnOffMotor(
                      scene,
                      suportePlacaStatus,
                      0.6,
                      conjuntoMotor.info.operation,
                      conjuntoMotor.info.idMotor,
                    );
                    // console.log(placaStatusOnOffMotor);

                    if (
                      conjuntoMotor.info.status === 1 ||
                      conjuntoMotor.info.status === 2 ||
                      conjuntoMotor.info.status === 10
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D3
                    } else if (
                      conjuntoMotor.info.status === 3 ||
                      conjuntoMotor.info.status === 4
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D2
                    } else if (
                      conjuntoMotor.info.status === 5 ||
                      conjuntoMotor.info.status === 6
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D4
                    } else if (
                      conjuntoMotor.info.status === 7 ||
                      conjuntoMotor.info.status === 8 ||
                      conjuntoMotor.info.status === 9
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D1
                    }

                    conjuntoMotor.actionManager = new BABYLON.ActionManager(
                      scene
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }
                } else {
                  // CLONA E CRIA BASE
                  const base = baseMotor3D.clone(`base${id}`)
                  // base.parent = objetoCabo;
                  if (camada > 0) {
                    base.position.z = -((largBaseArmazem + 5) / 2 + camada * 6)
                  } else {
                    base.position.z = -((largBaseArmazem + 5) / 2)
                  }

                  base.position.x = finalPositionX
                  base.position.y = 0
                  base.visibility = 1
                  // base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(180));

                  // CRIA SUPORTE DO MOTOR
                  const suporteDoMotor = suporteDoMotor3D.clone(`suporte${id}`)
                  suporteDoMotor.parent = base
                  suporteDoMotor.visibility = 1
                  const suportePlacaNome = suporteDoMotor3D.clone(
                    `suportePlacaNome${id}`
                  )
                  suportePlacaNome.parent = base
                  suportePlacaNome.position.x = 0
                  suportePlacaNome.position.z = 2.2
                  suportePlacaNome.visibility = 0

                  const suportePlacaStatus = suporteDoMotor3D.clone(
                    `suportePlacaStatus${id}`
                  )
                  suportePlacaStatus.parent = base
                  suportePlacaStatus.position.x = 0
                  suportePlacaStatus.position.y = 1
                  suportePlacaStatus.position.z = 2
                  suportePlacaStatus.visibility = 0

                  // CLONA E CRIA MOTOR
                  const motor = motor3D.clone(`motor${id}`)
                  if (motor) {
                    motor.parent = base
                    motor.info = objCabo.info[i]
                    motor.visibility = 1

                    if (
                      motor.info.status === 1 ||
                      motor.info.status === 2 ||
                      motor.info.status === 10
                    ) {
                      motor.material = colorMotorStatus3D3Shaded
                    } else if (
                      motor.info.status === 3 ||
                      motor.info.status === 4
                    ) {
                      motor.material = colorMotorStatus3D2Shaded
                    } else if (
                      motor.info.status === 5 ||
                      motor.info.status === 6
                    ) {
                      motor.material = colorMotorStatus3D4Shaded
                    } else if (
                      motor.info.status === 7 ||
                      motor.info.status === 8 ||
                      motor.info.status === 9
                    ) {
                      motor.material = colorMotorStatus3D1Shaded
                    }

                    motor.actionManager = new BABYLON.ActionManager(scene)

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }

                  if (base) {
                    LabelNameMotor(
                      scene,
                      motor.info.nameMotor,
                      1.5,
                      suportePlacaNome
                    )
                  }

                  if (motor && motor.info) {
                    // CRIA CONJUNTO MOTO
                    const conjuntoMotor = conjuntoMotor3D.clone(
                      `conjuntoMotor3D${id}`
                    )
                    conjuntoMotor.info = motor.info
                    conjuntoMotor.parent = base
                    conjuntoMotor.visibility = 1

                    const placaStatusOnOffMotor = StatusOnOffMotor(
                      scene,
                      suportePlacaStatus,
                      0.6,
                      conjuntoMotor.info.operation,
                      conjuntoMotor.info.idMotor,
                    );
                    // console.log(placaStatusOnOffMotor);

                    if (
                      conjuntoMotor.info.status === 1 ||
                      conjuntoMotor.info.status === 2 ||
                      conjuntoMotor.info.status === 10
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D3
                    } else if (
                      conjuntoMotor.info.status === 3 ||
                      conjuntoMotor.info.status === 4
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D2
                    } else if (
                      conjuntoMotor.info.status === 5 ||
                      conjuntoMotor.info.status === 6
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D4
                    } else if (
                      conjuntoMotor.info.status === 7 ||
                      conjuntoMotor.info.status === 8 ||
                      conjuntoMotor.info.status === 9
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D1
                    }

                    conjuntoMotor.actionManager = new BABYLON.ActionManager(
                      scene
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }
                }
              }
            }
            camada++
          }
        }
      }

      objetoCabo.parent = objetoArco

      objetoCabo.actionManager = new BABYLON.ActionManager(scene)

      if (subareaSelected === 0) {
        objetoCabo.visibility = 1
        objetoCabo.isPickable = true
        objetoCabo.enablePointerMoveEvents = true
        objetoCabo.actionManager.registerAction(
          new BABYLON.ExecuteCodeAction(
            BABYLON.ActionManager.OnPickTrigger,
            (event) => {
              const pickedMesh = event.meshUnderPointer

              const arco = pickedMesh.parent

              if (showInfo === false) {
                criaInfoLabels(arc, arco)
                showInfo = true
                arcSee.push(arco.name)
              } else {
                if (arcSee.includes(arco.name)) {
                  _.forEach(scene.meshes, (val) => {
                    if (
                      val.name.includes('infoObjeto') &&
                      val.name.includes(arco.name)
                    ) {
                      val.visibility = !val.visibility
                    }
                  })
                  // TOGGL
                } else {
                  // fncChangeViewInfo()
                  criaInfoLabels(arc, arco)
                  arcSee.push(arco.name)
                }
                // esconde info
              }
            }
          )
        )
      } else {
        objetoCabo.visibility =
          objetoArmazem[arc].subarea === subareaSelected ? 1 : 0

        if (objetoArmazem[arc].subarea === subareaSelected) {
          objetoCabo.isPickable = true
          objetoCabo.enablePointerMoveEvents = true
          objetoCabo.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPickTrigger,
              (event) => {
                const pickedMesh = event.meshUnderPointer

                const arco = pickedMesh.parent

                if (showInfo === false) {
                  criaInfoLabels(arc, arco)
                  showInfo = true
                  arcSee.push(arco.name)
                } else {
                  if (arcSee.includes(arco.name)) {
                    _.forEach(scene.meshes, (val) => {
                      if (
                        val.name.includes('infoObjeto') &&
                        val.name.includes(arco.name)
                      ) {
                        val.visibility = !val.visibility
                      }
                    })
                    // TOGGL
                  } else {
                    // fncChangeViewInfo()
                    criaInfoLabels(arc, arco)
                    arcSee.push(arco.name)
                  }
                  // esconde info
                }
              }
            )
          )
        } else {
          objetoCabo.isPickable = false
          objetoCabo.enablePointerMoveEvents = false
        }
      }

      for (let sen = 0; sen < objCabo.sensors.length; sen++) {
        sensorIdForMat++
        const meuY = (sen + 0.5) * Math.trunc(distanciaEntreSensores)

        var objetoSensor
        if (sensorIdForMat === 1) {
          // console.log('primeiro sensor =============')
          objetoSensor = objetoSensorEx
        } else {
          objetoSensor = objetoSensorEx.clone(`objetoSensor${sensorIdForMat}`)
        }

        //objetoSensor.position.y = objCabo.sensors[sen].position_y;
        // objetoSensor.position.y = (-(posCaboYInit - (profundidadeFundo+0.75)) + 1) + (1.5 * sen);
        objetoSensor.position.y =
          -(posCaboYInit - (profundidadeFundo + 0.75)) + 1 + 1.5 * sen
        const novoMat = matSensor.clone(`novoMat${sensorIdForMat}`)
        novoMat.id = `novoMat${sensorIdForMat}`

        const objSensor = objCabo.sensors[sen]

        const tempVariaSensor = { temperatura: objSensor.t }

        let tempColor = '#FFFFFFFF'

        if (sen <= objCabo.level - 1) {
          tempColor = `${objSensor.color}FF`
          novoMat.diffuseColor = new BABYLON.Color4.FromHexString(tempColor)
        } else {
          novoMat.diffuseColor = new BABYLON.Color4.FromHexString(tempColor)
        }

        objetoSensor.parent = objetoCabo

        var clickSensor = (event) => {
          const pickedMesh = event.meshUnderPointer
          const cabo = pickedMesh.parent
          const arco = cabo.parent
          if (showInfo === false) {
            criaInfoLabels(arc, arco)
            showInfo = true
            arcSee.push(arco.name)
          } else {
            if (arcSee.includes(arco.name)) {
              _.forEach(scene.meshes, (val) => {
                if (
                  val.name.includes('infoObjeto') &&
                  val.name.includes(arco.name)
                ) {
                  // console.log('MATCH');
                  // console.log(val);
                  val.visibility = !val.visibility;
                  val.isPickable = !val.isPickable;
                }
                // console.log('AFTER IF');
                // console.log(val);
              });
              // TOGGL
            } else {
              fncChangeViewInfo()
              criaInfoLabels(arc, arco)
              arcSee.push(arco.name)
            }
            // esconde info
          }
        }

        // let actualRect = criaTooltip(advancedTexture, objetoSensor, arc, objCabo, sen, objSensor, 0)

        objetoSensor.actionManager = new BABYLON.ActionManager(scene)

        if (subareaSelected === 0) {
          // console.log('inner subArea selected');
          // console.log(subareaSelected);

          objetoSensor.visibility = 1
          objetoSensor.isPickable = true
          objetoSensor.enablePointerMoveEvents = true

          let toolTip2 = null

          objetoSensor.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPointerOverTrigger,
              function (ev) {
                switch (ev.meshUnderPointer.id) {
                  case ev.meshUnderPointer.id:
                    fcnSeeTooltip(
                      ev.meshUnderPointer.sensor,
                      scene,
                      ev.meshUnderPointer,
                      ev.meshUnderPointer.absolutePosition
                    )
                    break
                }
              }
            )
          )

          objetoSensor.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPointerOutTrigger,
              function (ev) {
                switch (ev.meshUnderPointer.id) {
                  case ev.meshUnderPointer.id:
                    fcnSeeTooltip(
                      'remove',
                      scene,
                      ev.meshUnderPointer,
                      ev.meshUnderPointer.absolutePosition
                    )
                    break
                }
              }
            )
          )

          // objetoSensor.actionManager.registerAction(
          //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnLongPressTrigger, function (ev) {
          //     switch (ev.meshUnderPointer.id) {
          //       case ev.meshUnderPointer.id:
          //         if (objetoSensor.visibility === 1) {
          //           if (toolTip2) {
          //             if (toolTip2) {
          //               toolTip2.visibility = 1;
          //             }
          //           } else {
          //             toolTip2 = criaTooltip2(sen, scene, 'grey', [{ val: `tooltip${sen}`, tam: 220 }]
          //               , ev.meshUnderPointer, 0, 4.2, 1, -3)

          //             if (toolTip2) {
          //               toolTip2.visibility = 1;
          //             }
          //           }
          //         }
          //         break
          //     }
          //   }));

          // objetoSensor.actionManager.registerAction(
          //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, function (ev) {
          //     switch (ev.meshUnderPointer.id) {
          //       case ev.meshUnderPointer.id:
          //         if (objetoSensor.visibility === 1 && toolTip2) {
          //           toolTip2.visibility = 0
          //         }
          //         break
          //     }
          //   }));

          objetoSensor.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPickTrigger,
              (evt) => clickSensor(evt)
            )
          )
        } else {
          // console.log('else');
          // console.log(subareaSelected);

          objetoSensor.visibility =
            objetoArmazem[arc].subarea === subareaSelected ? 1 : 0

          if (objetoSensor.visibility === 1) {
            let toolTip2 = null

            objetoSensor.actionManager.registerAction(
              new BABYLON.ExecuteCodeAction(
                BABYLON.ActionManager.OnPointerOverTrigger,
                function (ev) {
                  switch (ev.meshUnderPointer.id) {
                    case ev.meshUnderPointer.id:
                      fcnSeeTooltip(
                        ev.meshUnderPointer.sensor,
                        scene,
                        ev.meshUnderPointer,
                        ev.meshUnderPointer.absolutePosition
                      )
                      break
                  }
                }
              )
            )

            objetoSensor.actionManager.registerAction(
              new BABYLON.ExecuteCodeAction(
                BABYLON.ActionManager.OnPointerOutTrigger,
                function (ev) {
                  switch (ev.meshUnderPointer.id) {
                    case ev.meshUnderPointer.id:
                      fcnSeeTooltip(
                        'remove',
                        scene,
                        ev.meshUnderPointer,
                        ev.meshUnderPointer.absolutePosition
                      )
                      break
                  }
                }
              )
            )

            // objetoSensor.actionManager.registerAction(
            //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function (ev) {
            //     switch (ev.meshUnderPointer.id) {
            //       case ev.meshUnderPointer.id:
            //         if (objetoSensor.visibility === 1) {
            //           if (toolTip2) {
            //             if (toolTip2) {
            //               toolTip2.visibility = 1;
            //             }
            //           } else {
            //             toolTip2 = criaTooltip2(sen, scene, 'grey', [{ val: `tooltip${sen}`, tam: 220 }]
            //               , ev.meshUnderPointer, 0, 4.2, 1, -3)

            //             if (toolTip2) {
            //               toolTip2.visibility = 1;
            //             }
            //           }
            //         }
            //         break
            //     }
            //   }));

            // objetoSensor.actionManager.registerAction(
            //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, function (ev) {
            //     switch (ev.meshUnderPointer.id) {
            //       case ev.meshUnderPointer.id:
            //         if (objetoSensor.visibility === 1 && toolTip2) {
            //           toolTip2.visibility = 0
            //         }
            //         break
            //     }
            //   }));

            objetoSensor.actionManager.registerAction(
              new BABYLON.ExecuteCodeAction(
                BABYLON.ActionManager.OnPickTrigger,
                (evt) => clickSensor(evt)
              )
            )
          }

          if (objetoSensor.visibility === 1) {
            objetoSensor.isPickable = true
            objetoSensor.enablePointerMoveEvents = true
          } else {
            objetoSensor.isPickable = false
            objetoSensor.enablePointerMoveEvents = false
          }
        }

        objetoSensor.material = novoMat

        var onOver = (meshEvent) => {
          var div = document.createElement('div')
          div.setAttribute('id', 'mybut')
          var styDiv = div.style
          styDiv.position = 'absolute'
          styDiv.display = 'flex'
          styDiv.flexDirection = 'column'
          styDiv.padding = '16px'
          styDiv.color = '#ffffff'
          styDiv.borderRadius = '8px'
          styDiv.backgroundColor = 'rgb(51 51 51 / 0.7)' //"#333333";
          styDiv.fontSize = '12pt'
          styDiv.top = scene.pointerY + 'px'
          styDiv.left = scene.pointerX + 'px'
          styDiv.cursor = 'pointer'

          var but = document.createElement('span')

          but.setAttribute('onClick', "alert('ouch!'")
          var but1 = document.createElement('span')
          var but2 = document.createElement('span')
          var but3 = document.createElement('span')
          div.appendChild(but)
          div.appendChild(but1)
          div.appendChild(but2)
          div.appendChild(but3)
          document.body.appendChild(div)
          but.textContent = `Arco: ${objetoArmazem.length - arc}`
          but1.textContent = `Cabo: ${objCabo.cab}`
          but2.textContent = `Sensor: ${sen + 1}`
          but3.textContent =
            objSensor.t === 999
              ? 'OFF'
              : objSensor.t === 998
              ? 'N/D'
              : objSensor.t === 997
              ? 'ERR'
              : `Temp.: ${objSensor.t}ºC`

          const funcSens = (event) => {
            clickSensor(meshEvent)
          }
          div.addEventListener('click', funcSens)
        }

        var onOut = (meshEvent) => {
          while (document.getElementById('mybut')) {
            document
              .getElementById('mybut')
              .parentNode.removeChild(document.getElementById('mybut'))
          }
        }

        // objetoSensor.actionManager.registerAction(
        //   new BABYLON.ExecuteCodeAction(
        //     BABYLON.ActionManager.OnPointerOverTrigger,
        //     onOver
        //   )
        // );

        // objetoSensor.actionManager.registerAction(
        //   new BABYLON.ExecuteCodeAction(
        //     BABYLON.ActionManager.OnPointerOutTrigger,
        //     onOut
        //   )
        // );
        // + (maxDepthCableArc - objCabo.depth)
        objetoSensor.sensor = {
          cor: novoMat.diffuseColor,
          sensor_id: sen + 1,
          level_sensor: objCabo.level,
          arc_id: arc + 1,
          cable_number: objCabo.cab,
          arc_index:
            arc === 0 ? objetoArmazem.length : objetoArmazem.length - arc,
          real_level: sen + 1,
          temperature:
            objSensor.t === 999
              ? 'OFF'
              : objSensor.t === 998
              ? 'N/D'
              : objSensor.t === 997
              ? 'ERR'
              : objSensor.t,
          color_hex: tempColor,
        }
      }

      if (subareaSelected === 0) {
        NomeCabo(
          scene,
          '#242d45',
          [
            { val: objArco.cables[cab].cab, tam: 160 },
            {
              val: `CABO`,
              tam: 150,
            },
          ],
          objetoCabo,
          alturaCaboTeto - 0.7,
          1.0,
          0.2,
          0.5
        )
      } else if (objetoArmazem[arc].subarea === subareaSelected) {
        NomeCabo(
          scene,
          '#242d45',
          [
            { val: objArco.cables[cab].cab, tam: 160 },
            {
              val: `CABO`,
              tam: 150,
            },
          ],
          objetoCabo,
          alturaCaboTeto - 0.7,
          1.0,
          0.2,
          0.5
        )
      }
    }
  }

  // 4-Elevação da superfície
  for (let arc = 0; arc < objetoArmazem.length; arc++) {
    let ultAltCabo = 0
    let ultPntInit = 1
    const posArcoNivel = Math.trunc(arc * distanciaEntreSensores + 2)
    let alturaParedeMaxima = profundidadeFundo + alturaParedeArmazem // TODO: Testar colocar profundidade dinamica
    const numCables = objetoArmazem[arc].cables.length

    for (let cab = 0; cab <= numCables; cab++) {
      // PROFUNDIDADE DINÂMICA
      // console.log('CABBBBBBBBBBBBBBBBBBBBBBBBBBBBBB')
      // console.log(objetoArmazem[arc])

      let myPosZ = 0
      if (numCables === 1) {
        myPosZ =
          (objetoArmazem[arc].cables.positionZ * largBaseArmazem) / 192 -
          largBaseArmazem / 7
      } else {
        if (objetoArmazem[arc].cables[cab]) {
          myPosZ =
            (objetoArmazem[arc].cables[cab].positionZ * largBaseArmazem) / 192 -
            largBaseArmazem / 7
        }
      }
      let posCaboYInt =
        profundidadeFundo *
        Math.sin(BABYLON.Tools.ToRadians(180 / largBaseArmazem) * myPosZ)
      let posInicial = -(posCaboYInt - (profundidadeFundo + 0.75))
      // alturaParedeMaxima = posInicial + alturaParedeArmazem
      alturaParedeMaxima = alturaParedeArmazem

      const contNivelProduto = fncAltCabo(
        objetoArmazem[arc].cables[cab],
        distanciaEntreSensores,
        termoReport,
        posCaboYInt
      ) // TODO:
      if (cab === 8) {
        // console.log('NIVEL DO PRODUTO CABO 9')
        // console.log(objetoArmazem[arc].cables[cab])
        // console.log(contNivelProduto)
      }
      let altProxima = contNivelProduto === 1 ? 2 : contNivelProduto
      // CALC PROXPNT TODO:
      let proxPnt =
        Math.trunc(
          (largBaseArmazem / (objetoArmazem[arc].cables.length + 1)) * (cab + 1)
        ) + 1
      if (cab === 0) {
        // Caso for o caso do primeiro cabo define o nível lateral
        const proxNivelProd = fncAltCabo(
          objetoArmazem[arc].cables[cab + 1],
          distanciaEntreSensores,
          termoReport,
          posCaboYInt
        )
        if (proxNivelProd >= contNivelProduto) {
          // console.log('ENCHENDDOOOOOOOOOOO')
          // console.log(proxNivelProd)
          // console.log(contNivelProduto)
          // console.log(objetoArmazem[arc].cables[0])
          // Enchendo
          ultAltCabo =
            contNivelProduto > alturaParedeMaxima
              ? alturaParedeMaxima
              : contNivelProduto
          // console.log('ULT ALTURA CABO')
          // console.log(ultAltCabo)
        } else {
          // Esvaziando
          ultAltCabo =
            contNivelProduto + 1 > alturaParedeMaxima
              ? alturaParedeMaxima - 1
              : contNivelProduto - 1
        }
      } else if (cab === numCables) {
        // Quando for o último cabo define o nível lateral
        proxPnt += 1
        const anteNivelProd = fncAltCabo(
          objetoArmazem[arc].cables[numCables - 2],
          distanciaEntreSensores,
          termoReport,
          posCaboYInt
        )
        const anteNivelAtual = fncAltCabo(
          objetoArmazem[arc].cables[numCables - 1],
          distanciaEntreSensores,
          termoReport,
          posCaboYInt
        )
        if (anteNivelProd >= anteNivelAtual) {
          // Enchendo
          altProxima =
            anteNivelAtual > alturaParedeMaxima
              ? alturaParedeMaxima
              : anteNivelAtual
        } else {
          // Esvaziando
          // console.log('ESVAZIANDO')
          // console.log(anteNivelAtual)
          // console.log(alturaParedeMaxima)
          altProxima =
            anteNivelAtual + 1 > alturaParedeMaxima
              ? alturaParedeMaxima - 1
              : anteNivelAtual + 1
        }
      }
      fncLevelArmazem(
        superficie,
        posArcoNivel,
        ultAltCabo,
        altProxima,
        ultPntInit,
        proxPnt
      ) // TODO:
      // Eleva os níveis das cabeceiras
      if (arc === 0 || arc === objetoArmazem.length - 1) {
        // console.log('MAIOR QUE B')
        const maiorQueB = fncAvgProductCompare(
          superficie,
          posArcoNivel,
          posArcoNivel - 1,
          ultPntInit,
          proxPnt
        ) // TODO:
        // console.log(maiorQueB)
        let altPosA = 0
        let altPosB = 0
        let posicaoArco = posArcoNivel
        // console.log('POSIÇÃO DO ARCO')
        // console.log(posicaoArco)
        if (arc === 0) {
          // Caso for a cabeceira inicial

          altPosA = maiorQueB ? ultAltCabo * 0.85 : ultAltCabo * 1.15
          altPosB = maiorQueB ? altProxima * 0.85 : altProxima * 1.15
          posicaoArco -= 1
        } else {
          // Caso for a cabeceira final
          posicaoArco += 2
          altPosA = !maiorQueB ? ultAltCabo * 0.85 : ultAltCabo * 1.15
          altPosB = !maiorQueB ? altProxima * 0.85 : altProxima * 1.15
        }
        // console.log('ALTPOSA')
        // console.log(altPosA)
        // console.log('ALTPOSB')
        // console.log(altPosB)
        // console.log('ALTPAREDEMAXIMA')
        // console.log(alturaParedeMaxima)
        altPosA = altPosA < 0 ? 0 : altPosA
        altPosB = altPosB < 0 ? 0 : altPosB
        altPosA = altPosA > alturaParedeMaxima ? alturaParedeMaxima : altPosA
        altPosB = altPosB > alturaParedeMaxima ? alturaParedeMaxima : altPosB
        fncLevelArmazem(
          superficie,
          posicaoArco,
          altPosA,
          altPosB,
          ultPntInit,
          proxPnt,
          true
        ) // TODO:
      }
      ultAltCabo = altProxima
      ultPntInit = proxPnt
    }
  }

  // Material Produto
  const matProduto = new BABYLON.StandardMaterial('matProduto', scene)
  matProduto.backFaceCulling = false
  if (grao === 'Milho') {
    matProduto.diffuseTexture = new BABYLON.Texture(
      `${originalTheme.textures.corn}`,
      scene
    )
  } else if (grao === 'Soja') {
    matProduto.diffuseTexture = new BABYLON.Texture(
      `${originalTheme.textures.soybean}`,
      scene
    )
  } else if (grao === 'Trigo Duro' || grao === 'Trigo Mole') {
    matProduto.diffuseTexture = new BABYLON.Texture(
      `${originalTheme.textures.wheat}`,
      scene
    )
  } else {
    matProduto.diffuseTexture = new BABYLON.Texture(
      `${originalTheme.textures.padrao}`,
      scene
    )
  }
  matProduto.diffuseTexture.uScale = 10
  matProduto.diffuseTexture.vScale = 10
  matProduto.specularColor = new BABYLON.Color3(0, 0, 0)

  // Malha do Produto
  // CRIA A MALHA DE PRODUTO
  // console.log('CRIA A MALHA DE PRODUTO')
  // console.log(superficie)
  const produto = BABYLON.MeshBuilder.CreateRibbon(
    'nivelProduto',
    { pathArray: superficie, updatable: false },
    scene
  )
  produto.material = matProduto
  produto.backFaceCulling = true
  produto.isPickable = false
  // EDITADO
  // produto.position.y = 3;
  produto.position.y = 3
  // produto.visibility = 0.5 => ORIGINAL
  produto.visibility = produto_visivel
  produto.parent = conjuntoArco
  // produto.checkCollisions = true;

  objetoCamadaProduto = conjuntoArco
  fncChangeViewInfo(scene, produto_info)
}

const fncSurfaceArmazem = (
  superficie,
  linha,
  incDistArco,
  qtdLinhaLarg,
  distEntreCabos
) => {
  // Superficie lateral inicial
  superficie[linha].push(new BABYLON.Vector3(incDistArco, 0, 0))
  for (let i = 0; i <= qtdLinhaLarg; i++) {
    superficie[linha].push(
      new BABYLON.Vector3(incDistArco, 0, i * distEntreCabos)
    )
  }
  // Superficie lateral final
  superficie[linha].push(
    new BABYLON.Vector3(incDistArco, 0, qtdLinhaLarg * distEntreCabos)
  )
}

const maxDepthCableArcCalc = (arc) => {
  // Coleta o real nível do produto do armazém
  // console.log('maxDepthCableArcCalc')
  // console.log(arc)
  if (arc !== null || arc !== undefined) {
    let maxVal = 0
    const retorno = _.maxBy(arc.cables, (cabo, cc) => cabo.level, 0)
    if (retorno.level > maxVal) {
      maxVal = retorno.level
    }
    return maxVal
  }
  return 0
}

const fncAltCabo = (cabo, distSensor, termoReport, posCaboYInt) => {
  if (cabo === undefined) {
    return 0
  }

  if (cabo.cab === 13) {
    // console.log('FUNC ALTA CABO 13')
    // console.log(cabo.cab)
    // console.log(posCaboYInt)
  }

  let valueForDiscountOfLevelProduct = 0
  if (posCaboYInt >= 1) {
    if (cabo.cab === 13) {
      // console.log('LEVEL 1 - a')
    }
    valueForDiscountOfLevelProduct = posCaboYInt - 1.5
    const level = getLevelSensor(cabo, termoReport)
    if (level >= 3) {
      if (cabo.cab === 13) {
        // console.log('((level - valueForDiscountOfLevelProduct) * distSensor)')
        // console.log(((level - valueForDiscountOfLevelProduct) * distSensor))
      }
      return (level - valueForDiscountOfLevelProduct) * distSensor
    } else {
      if (level > 0) {
        // console.log('NAAAAAAAAAAAAAAAAAAAAAAO maior ou igual a tres')
        if (cabo.cab === 13) {
          // console.log('LEVEL 2 - a')
        }
        if (level < 3 && posCaboYInt > 3) {
          if (cabo.cab === 13) {
            // console.log('LEVEL 3 - a')
          }
          let valueForDiscount = posCaboYInt - (2 + level / 5)
          valueForDiscount = valueForDiscount - level
          return valueForDiscount - valueForDiscount * 2
        }
        if (cabo.cab === 13) {
          // console.log('LEVEL 3 - b')
          // console.log(level)
          // console.log(valueForDiscountOfLevelProduct)
          // console.log(((level - valueForDiscountOfLevelProduct) * distSensor))
        }
        return (level - valueForDiscountOfLevelProduct) * distSensor - 1.5
      } else {
        return 0
      }
    }
  } else {
    if (cabo.cab === 1) {
      // console.log('LEVEL 1 - b')
    }
    const level = getLevelSensor(cabo, termoReport)
    valueForDiscountOfLevelProduct = posCaboYInt + posCaboYInt / 5
    if (level > 0) {
      return (
        (getLevelSensor(cabo, termoReport) + valueForDiscountOfLevelProduct) *
        distSensor
      )
    } else {
      return 0
    }
  }

  return getLevelSensor(cabo, termoReport) * distSensor
}

const getLevelSensor = (c, termoReport) => c.level

const fncLevelArmazem = (
  superficie,
  linha,
  H1,
  H2,
  D1,
  D2,
  umaSuperficie = false
) => {
  // console.log('FCN LEVEL ARMAZEM V')
  // console.log('superficie')
  // console.log(superficie)
  // console.log('superficie[linha]')
  // console.log(superficie[linha])
  // console.log('linha')
  // console.log(linha)
  // console.log('H1')
  // console.log(H1)
  // console.log('H2')
  // console.log(H2)
  // console.log('D1')
  // console.log(D1)
  // console.log('D2')
  // console.log(D2)
  // console.log('umaSuperficie')
  // console.log(umaSuperficie)

  const a = (H2 - H1) / (D2 - D1)
  const b = a * (0 - D1) + H1
  for (let x = D1; x < D2; x++) {
    const alt = a * x + b
    // superficie[linha][x].y = alt;
    superficie[linha][x].y = alt
    // console.log('ALT')
    // console.log(alt)
    if (!umaSuperficie) {
      // console.log('ALT UMA SUPERFICIE')
      // console.log(alt)
      // superficie[linha + 1][x].y = alt;
      superficie[linha + 1][x].y = alt
    }
  }
}

const fncAvgProductCompare = (superficie, arco1, arco2, pIni, pFim) => {
  let AisBigger = false
  const totalLarg = superficie[arco1].length
  let contA = 0
  let contB = 0
  for (let x = pIni; x <= pFim; x++) {
    contA += superficie[arco1][x].y
    contB += superficie[arco2][x].y
  }
  const mediaA = contA / totalLarg
  const mediaB = contB / totalLarg
  if (mediaA > mediaB) {
    AisBigger = true
  }
  return AisBigger
}

const NomeObjeto = (
  nome,
  scene,
  corFundo,
  id,
  parent,
  altura,
  tamanho,
  x = 0,
  z = 0,
  fcnSeeTooltip
) => {
  const infoObjeto = BABYLON.Mesh.CreatePlane(
    `infoObjeto ${nome}`,
    tamanho,
    scene,
    false
  )

  // console.log('parent');
  // console.log(parent);

  // BORDA
  infoObjeto.enableEdgesRendering()
  infoObjeto.edgesWidth = 4.0
  infoObjeto.edgesColor = corFundo
  //
  infoObjeto.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL
  infoObjeto.position = new BABYLON.Vector3(x - 0.5, altura, z)
  infoObjeto.parent = parent

  const texturaInfoObjeto = new BABYLON.DynamicTexture(
    'texturaInfoObjeto',
    512,
    scene,
    false
  )
  const fontSize = 190
  if (typeof id === 'object') {
    for (let i = 0; i < id.length; i++) {
      texturaInfoObjeto.drawText(
        id[i].val,
        null,
        300 + id[i].tam * i,
        `bold ${id[i].tam}px arial`,
        'black',
        i === 0 ? corFundo : null
      )
    }
  } else {
    texturaInfoObjeto.drawText(
      id,
      null,
      300,
      `bold ${fontSize}px arial`,
      'black',
      corFundo
    )
  }
  infoObjeto.material = new BABYLON.StandardMaterial(
    'materialSuperficie',
    scene
  )
  infoObjeto.material.diffuseTexture = texturaInfoObjeto

  infoObjeto.material.specularColor = new BABYLON.Color3(0, 0, 0)
  infoObjeto.material.emissiveColor = new BABYLON.Color3(1, 1, 1)
  infoObjeto.material.backFaceCulling = false
  infoObjeto.isPickable = true
  // texturaInfoObjeto.hasAlpha = true;
  infoObjeto.material.freeze()

  infoObjeto.actionManager = new BABYLON.ActionManager(scene)

  infoObjeto.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(
      BABYLON.ActionManager.OnPointerOverTrigger,
      function (ev) {
        fcnSeeTooltip(parent.sensor, scene, parent, parent.absolutePosition)
      }
    )
  )

  infoObjeto.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(
      BABYLON.ActionManager.OnPointerOutTrigger,
      function (ev) {
        fcnSeeTooltip('remove', scene, parent, parent.absolutePosition)
      }
    )
  )
}

const calculaPositionZDoMotor = (
  positionZ,
  larguraDoArmazem,
  proporçãoLarguraB
) => {
  const position = (positionZ * larguraDoArmazem) / 192 - proporçãoLarguraB
  return position
}

const NomeCabo = (
  scene,
  corFundo,
  id,
  parent,
  altura,
  tamanho,
  x = 0,
  z = 0
) => {
  const nomeObjeto = BABYLON.Mesh.CreatePlane('nomeCabo', tamanho, scene, false)
  nomeObjeto.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL
  // nomeObjeto.position = new BABYLON.Vector3(x, altura + 5.5, z);
  nomeObjeto.position = new BABYLON.Vector3(x, altura + 10.5, z)
  nomeObjeto.parent = parent
  const texturaNomeCabo = new BABYLON.DynamicTexture(
    'texturaNomeCabo',
    512,
    scene,
    true
  )
  const fontSize = 250
  if (typeof id === 'object') {
    for (let i = 0; i < id.length; i++) {
      if (id[i].val === 'CABO') {
        texturaNomeCabo.drawText(
          id[i].val,
          null,
          200 + id[i].tam * i,
          'bold 150px arial',
          'white',
          i === 0 ? corFundo : null
        )
      } else {
        texturaNomeCabo.drawText(
          id[i].val,
          null,
          200 + id[i].tam * i,
          'bold 250px arial',
          'white',
          i === 0 ? corFundo : null
        )
      }
    }
  } else {
    texturaNomeCabo.drawText(
      id,
      null,
      200,
      `bold ${fontSize}px arial`,
      'white',
      corFundo
    )
  }
  nomeObjeto.material = new BABYLON.StandardMaterial(
    'materialSuperficieCabo',
    scene
  )
  nomeObjeto.material.diffuseTexture = texturaNomeCabo
  nomeObjeto.material.specularColor = new BABYLON.Color3(0, 0, 0)
  nomeObjeto.material.emissiveColor = new BABYLON.Color3(1, 1, 1)
  nomeObjeto.material.backFaceCulling = false
  nomeObjeto.isPickable = false
  texturaNomeCabo.hasAlpha = true
}
