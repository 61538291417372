import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons/';
import { sub, add } from 'date-fns';
import { DatePicker, MultiInputSelect } from '../../../../../../components';
import { formatDate } from '../../../../../../helpers/Intl';

export function SearchHeader({ setRequestParams, requestParams, searchPeriod,
  setSearchPeriod, handleSelectPlant, availablePlants }) {
  const [quantityOfDays, setQuantityOfDays] = React.useState(1);
  const [selectedDate, setSelectedDate] = React.useState({
    dateStart: new Date(),
    dateFinish: new Date(),
    groupBy: 'day',
  });

  const handleGroupBy = (event) => {
    const { value } = event.target;
    const copyState = { ...selectedDate };
    const copyStateRequestParam = { ...requestParams };
    setSelectedDate({ ...copyState, groupBy: value });
    setRequestParams({ ...copyStateRequestParam, groupBy: value });
  };

  const handleChangePeriodSerch = async (value) => {
    setSearchPeriod(value);
    let newStartDateConfig;
    let newFinishDateConfig;

    const copyState = { ...selectedDate };
    switch (value) {
      case 'today':
        newStartDateConfig = new Date();
        newFinishDateConfig = new Date();

        setQuantityOfDays(1);
        break;
      case 7:
        newStartDateConfig = sub(new Date(), {
          days: value,
        });
        newFinishDateConfig = new Date();

        setQuantityOfDays(value);
        break;

      case 30:
        newStartDateConfig = sub(new Date(), {
          months: 1,
        });
        newFinishDateConfig = new Date();

        setQuantityOfDays(value);
        break;
      case 12:
        newStartDateConfig = sub(new Date(), {
          years: 1,
        });
        newFinishDateConfig = new Date();

        setQuantityOfDays(value);
        break;
      case 'custom':
        setSearchPeriod(value);
        break;

      default:
        break;
    }

    setSelectedDate({
      ...copyState,
      dateStart: newStartDateConfig,
      dateFinish: newFinishDateConfig,
    });

    const copyRequestState = { ...requestParams };

    const formatedNewStartDate = formatDate(newStartDateConfig, 'yyyy-MM-dd');
    const formatedNewFinishDate = formatDate(newFinishDateConfig, 'yyyy-MM-dd');

    setRequestParams({
      ...copyRequestState,
      dateStart: formatedNewStartDate,
      dateFinish: formatedNewFinishDate,
    });
  };

  const handleToogleDate = (type) => {
    let period;
    let newSubDateStart;
    let newSubDateFinish;

    if (quantityOfDays === 12) {
      period = {
        years: 1,
      };
    } else if (quantityOfDays === 30) {
      period = {
        months: 1,
      };
    } else {
      period = {
        days: quantityOfDays,
      };
    }

    const { dateStart, dateFinish } = { ...selectedDate };

    if (type === 'sub') {
      newSubDateStart = sub(dateStart, period);
      newSubDateFinish = sub(dateFinish, period);
    } else {
      newSubDateStart = add(dateStart, period);
      newSubDateFinish = add(dateFinish, period);
    }

    const copyRequestState = { ...requestParams };

    const formatedNewStartDate = formatDate(newSubDateStart, 'yyyy-MM-dd');
    const formatedNewFinishDate = formatDate(newSubDateFinish, 'yyyy-MM-dd');

    setRequestParams({
      ...copyRequestState,
      dateStart: formatedNewStartDate,
      dateFinish: formatedNewFinishDate,
    });

    setSelectedDate({
      dateStart: newSubDateStart,
      dateFinish: newSubDateFinish,
    });
  };

  const handleManualChangeStartData = (e) => {
    const copyDate = { ...selectedDate };
    setSelectedDate({ ...copyDate, dateStart: e });

    const formatedNewStartDate = formatDate(e, 'yyyy-MM-dd');
    const copyRequestState = { ...requestParams };

    setRequestParams({
      ...copyRequestState,
      dateStart: formatedNewStartDate,
    });
  };

  const handleManualChangeFinishData = (e) => {
    const copyDate = { ...selectedDate };
    setSelectedDate({ ...copyDate, dateFinish: e });
    const formatedNewFinishDate = formatDate(e, 'yyyy-MM-dd');
    const copyRequestState = { ...requestParams };

    setRequestParams({
      ...copyRequestState,
      dateStart: formatedNewFinishDate,
    });
  };

  const DEFAULT_GROUP_DAYS = [
    {
      value: 'day',
      label: 'Dia',
    },
    {
      value: 'month',
      label: 'Mês',
    },
  ];

  return (
    <Grid
      container
      spacing={1}
      justifyContent="space-between"
      alignItems="center"
      direction="row"
    >

      <Grid item xs={12} md={12} lg={2}>
        <MultiInputSelect
          label="Plantas de Fotovoltaica"
          value={requestParams.plantId}
          onChange={handleSelectPlant}
          name="plants"
          data={availablePlants}
          nameLabel="technicalDescription"
          valueData="id"
        />
      </Grid>

      <Grid item sm={12} md={6} lg={4}>
        <ToggleButtonGroup
          value={searchPeriod}
          color="primary"
          variant="contained"
        >
          <ToggleButton
            value="today"
            onClick={() => handleChangePeriodSerch('today')}
          >
            Hoje
          </ToggleButton>
          <ToggleButton value={7} onClick={() => handleChangePeriodSerch(7)}>
            7 Dias
          </ToggleButton>
          <ToggleButton value={30} onClick={() => handleChangePeriodSerch(30)}>
            30 Dias
          </ToggleButton>
          <ToggleButton value={12} onClick={() => handleChangePeriodSerch(12)}>
            12 Meses
          </ToggleButton>
          <ToggleButton
            value="custom"
            onClick={() => handleChangePeriodSerch('custom')}
          >
            Outro
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid container xs={12} sm={12} md={6} lg={6} direction="row" alignItems="center" spacing={1}>
        <Grid item>
          <Tooltip title={searchPeriod === 'custom' ? 'Bloequeado' : 'Avançar'}>
            <button type="button" onClick={() => handleToogleDate('sub')} disabled={searchPeriod === 'custom'}>
              <KeyboardArrowLeft />
            </button>
          </Tooltip>
        </Grid>

        <Grid item>

          <DatePicker
            disabled={searchPeriod !== 'custom'}
            label="Início"
            format="dd/MM/yyyy"
            selectedDate={requestParams.dateStart}
            name="from"
            handleDateChange={handleManualChangeStartData}
          />
        </Grid>

        <Grid item>
          <DatePicker
            disabled={searchPeriod !== 'custom'}
            label="Fim"
            format="dd/MM/yyyy"
            selectedDate={requestParams.dateFinish}
            name="from"
            handleDateChange={handleManualChangeFinishData}
          />
        </Grid>

        <Grid item>
          <Tooltip title={searchPeriod === 'custom' ? 'Bloequeado' : 'Avançar'}>
            <button type="button" disabled={searchPeriod === 'custom'} onClick={() => handleToogleDate('add')}>
              <KeyboardArrowRight />
            </button>
          </Tooltip>
        </Grid>

      </Grid>

      <Grid item xs={1} sm={1}>
        <MultiInputSelect
          disabled={searchPeriod !== 'custom'}
          label="Agrupado"
          value={requestParams.groupBy}
          onChange={handleGroupBy}
          name="groupBy"
          data={DEFAULT_GROUP_DAYS}
          nameLabel="label"
          valueData="value"
        />
      </Grid>

    </Grid>
  );
}
