import React from 'react';
import { Switch } from '@material-ui/core';

import * as S from './styles';

export function SwitchCard({ data }) {
  return (
    <S.SwitchCard key={data.title}>
      <span>{data.title}</span>
      <Switch checked={data.checked} />
    </S.SwitchCard>
  );
}
