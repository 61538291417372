import React, { useEffect, useState } from 'react';
import './style.scss';
import { Grid } from '@material-ui/core';
import {
  FlatBottom,
  SemiConicalBottom,
  ConicalBottom,
  MelitaBottom,
  MelitaBottom2,
} from '../bottomTypes';
import {
  calcTempPositionY,
  startDispositionsSensorsY,
  heatmapPositionY,
  startDispositionsHeatMapY,
} from './sensorsDispositions';
import ViewSensorsStyle from './viewSensorsStyle';
import verifyTemperature from '../../helpers/verifyTemperature';

function HeatMap({ data, selectedNumberCables, siloType, modal, mobile, viewStyle }) {
  const [cables, setCables] = useState([]);
  const [heatMapData, setHeatMapData] = useState([]);
  const [heatMapDataOneCable, setHeatMapDataOneCable] = useState([]);
  // const [sensorSize, setSensorSize] = useState({ x: 25, y: 25 });
  const [sensorsNumber, setSensorsNumber] = useState();
  // const [cablesNumber, setCablesNumber] = useState();
  // const [teste, setTeste] = useState();
  // const sensors = Math.max(...cables.map((x) => x.sensors.length));
  const [cuttingPositions, setCuttingPositions] = useState([]);
  // const [viewSensor, setViewSensor] = useState(false);
  const viewSensor = false;
  const lengthTermovisorModal = 865;

  if (!viewStyle) {
    viewStyle = false;
  }

  function headerCalc(type) {
    if ((type === 'plainArm') || (type === 'R1Arm')) {
      return 980;
    }

    if (type === 'semiVArm') {
      return 850;
    }

    if (type === 'VArm') {
      return 790;
    }

    if (type === 'WArm') {
      return 590;
    }

    if ((type === 'SR1Arm')) {
      return 1200;
    }

    // return 390;
  }

  function headerCableMobileCalc(cableLength) {
    let x;
    // console.log('caboslenght', cableLength);
    switch (cableLength) {
      case 1:
        x = 7;
        break;
      case 2:
        x = 6;
        break;
      case 3:
        x = 3;
        break;
      case 4:
        x = 16;
        break;
      default:
        x = 12;
    }
    return x;
  }

  function headerSensorMobileCalc(sensorLength) {
    let x;
    switch (sensorLength) {
      case 1:
        x = 25;
        break;
      case 2:
        x = 6;
        break;
      case 3:
        x = 4;
        break;
      case 4:
        x = 10;
        break;
      default:
        x = 12;
    }
    return x;
  }

  function reordenateCuttingPositions(positions) {
    if (positions.length > 0) {
      let aux;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < positions.length; i++) {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < positions.length; j++) {
          if (positions[j] > positions[i]) {
            aux = positions[j];
            positions[j] = positions[i];
            positions[i] = aux;
          }
        }
      }
      setCuttingPositions(positions);
      // console.log('após metodo bolha ->', cuttingPositions);
    }
  }

  React.useEffect(() => {
    const pos = [];
    if (selectedNumberCables.length > 0) {
      // cutting positions
      let exist;
      // //////////////////////
      const auxCables = [];
      const refCables = [];
      data.chartData.forEach((cd) => {
        cd.unitData.arcs.forEach((arc) => {
          arc.cabs.forEach((cab, cableIndex) => {
            if (selectedNumberCables.indexOf(cab.num) !== -1) {
              auxCables.push({
                cableNumber: cab.num,
                sensors: cab.sens.map((c) => ({
                  color: c.gradualColor,
                  t: c.t,
                })),
                cableIndex,
                z: cab.positionZ,
              });
            }
            // cutting positions
            exist = 0;
            pos.forEach((positionExist) => {
              if (cab.positionZ === positionExist) {
                exist += 1;
              }
            });
            if (exist === 0) {
              pos.push(cab.positionZ);
            }
            // ////////////////////////////////////////
          });
        });
      });

      // console.log('cuttingPositions -> ', cuttingPositions);
      reordenateCuttingPositions(pos);

      // Just to keep the selectedNumberCables order
      if (auxCables.length > 0) {
        selectedNumberCables.forEach((e) => {
          refCables.push(auxCables.find((f) => f.cableNumber === e));
        });
      }

      // console.log('refCables', refCables);
      setCables(refCables);
    }
  }, [data, selectedNumberCables]);

  function typeFactor(cuttingsLength) {
    if (cuttingsLength > 9) {
      cuttingsLength -= 10;
    }
    let factor = cuttingsLength / 10;

    if (factor === 0) {
      factor = 0.4;
    }

    return factor;
  }

  function radialGradientPositionX(cuttingIndex, cuttingsLength) {
    let positionX;
    if ((siloType === 'R1Arm')) {
      const lengthModal = lengthTermovisorModal;
      const lengthforCable = (lengthModal / cuttingsLength);
      positionX = (((lengthforCable * 100) / lengthModal) * (cuttingIndex + 1));
      // fator de correção em pocentagem
      positionX -= cuttingIndex !== 0 ? typeFactor(cuttingsLength) : 0;
    } else {
      const lengthModal = lengthTermovisorModal;
      const lengthforCable = lengthModal / cuttingsLength;
      positionX = (cuttingIndex + 1) * ((lengthforCable * 100) / lengthModal);

      positionX -= typeFactor(cuttingsLength) * cuttingIndex; // fator de correção em pocentagem
    }
    return positionX;
  }

  function radialGradientPositionY(
    sensorIndex,
    numberOfSensors,
    cuttingsLength,
    cuttingIndex,
    positionZ,
  ) {
    return (
      (heatmapPositionY(
        siloType,
        sensorIndex,
        numberOfSensors,
        cuttingsLength,
        cuttingIndex,
      ) *
        100) /
        132 -
      startDispositionsHeatMapY(siloType, cuttingPositions, positionZ)
    );
  }

  function radialGradientColor(color) {
    return `${color} ${viewSensor ? 10 : 0}px,
      transparent ${viewSensor ? 0 : cuttingPositions.length <= 3 ? 60 : 25}%)`;
  }

  useEffect(() => {
    const largeCable = Math.max(...cables.map((x) => x.sensors.length));
    // const numberOfCables = cables?.length;
    setSensorsNumber(largeCable);
    // setCablesNumber(numberOfCables);
    // const sensorDynamicSizeX = 88 / cuttingPositions.length;
    // const sensorDynamicSizeX = (250 / largeCable).toFixed(2);
    // const sensorDynamicSizeXModal = (100 / largeCable).toFixed(5);
    const linearDigitalSize = (100 / largeCable).toFixed(2);
    // setSensorSize({ x: sensorDynamicSizeY, y: sensorDynamicSizeX });
    // console.log(siloType);
    // console.log(cuttingPositions);

    // cables?.forEach((x, indice) =>
    //   // console.log(
    //   //   'opa!!',
    //   //   cuttingPositions.findIndex((item) => item === x.z),
    //   // )
    //   );

    const normalSensors = [];
    cuttingPositions.forEach((cutting, cuttingIndex) =>
      cables?.forEach((cable) => {
        if (cutting === cable.z) {
          // console.log('calc -> ', cI * (lengthTermovisorModal / cuttingPositions.length));
          cable.sensors.forEach((sensor, index) => {
            const a = radialGradientPositionX(
              cuttingIndex,
              cuttingPositions.length,
              cable.z,
            );
            const b = radialGradientPositionY(
              index,
              cable.sensors.length,
              cuttingPositions.length,
              cuttingPositions.findIndex((item) => item === cable.z),
              cable.z,
            );
            const c = radialGradientColor(sensor.color);
            // const radial = `radial-gradient(at ${2}% 3%, 3)`;
            // eslint-disable-next-line no-new-wrappers
            const radial = ['radial-gradient(at ', a, '% ', b, '%, ', c].join(
              '',
            );
            // `radial-gradient(at ${2}% 3%, 3)`;
            normalSensors.push({
              t: sensor.t,
              radial,
            });
          });
        }
      }));

    normalSensors.sort((a, b) => b.t - a.t);

    const normalSensorsFix = normalSensors.map((x) => x.radial);
    const oneCableCase = cables.map((x) =>
      x.sensors.map((s, is) => `${s.color} ${linearDigitalSize * is}%`));
    setHeatMapDataOneCable(oneCableCase);
    setHeatMapData(normalSensorsFix);
    // setTeste(Math.random());
  }, [cables, data, selectedNumberCables, siloType, viewSensor]);

  return (
    <Grid container spacing={2} style={{ height: modal ? 750 : 'auto' }}>
      <Grid item xs={12} lg={12}>
        <div id="termovisor">

          {/* indicação dos cabos no silo, headers do cabo no modal */}
          {modal && !mobile && (
            <div className="cableHeadersArm" style={{ width: lengthTermovisorModal, marginBottom: -60 }}>
              {cuttingPositions.map((cutting) => (
                <svg
                  width={headerCalc(siloType) / cuttingPositions.length}
                  height="50"
                >
                  {/* {console.log(
                    'teste',
                    lengthTermovisorModal / cuttingPositions.length / 2 - 30,
                  )} */}
                  {cables?.map(
                    (cable) =>
                      cutting === cable.z && (
                        <>
                          <rect
                            x={
                              headerCalc(siloType) / cuttingPositions.length / 2 - 30
                            }
                            width="30px"
                            height="30px"
                            style={{ fill: '#d1d1d1' }}
                            stroke="#d1d1d1"
                            strokeWidth="2px"
                          />
                          <text
                            x={
                              headerCalc(siloType) / cuttingPositions.length / 2 -
                              (cable.cableNumber < 10 ?
                                10
                                :
                                cable.cableNumber < 100 ?
                                  (siloType === 'R1Arm' ? 15 : 23)
                                  :
                                  cable.cableNumber < 1000 ?
                                    20
                                    :
                                    0)
                            }
                            y={18}
                            fill="black"
                            style={{ fontSize: 12, fontWeight: 700 }}
                          >
                            {cable.cableNumber}
                          </text>
                        </>
                      ),
                  )}
                </svg>
              ))}
            </div>
          )}

          {mobile ? (
            <div className="heatmap" style={{ justifyContent: 'center' }}>
              {cables?.map((cable) => (
                <svg width={70} height={sensorsNumber * 30 + 35}>
                  <rect
                    y={0}
                    width="60px"
                    height="30px"
                    style={{ fill: '#d1d1d1' }}
                    stroke="#d1d1d1"
                    strokeWidth="2px"
                  />
                  <text
                    x={headerCableMobileCalc(
                      cable.cableNumber.toFixed(0).length,
                    )}
                    y={20}
                    fill="black"
                    style={{ fontSize: 12, fontWeight: 700 }}
                  >
                    {/* Cabo{' '} */}
                    {cable.cableNumber}
                  </text>
                  {cable.sensors.map((sensor, index) => (
                    <rect
                      y={sensorsNumber * 30 + 35 - (index * 30 + 30)}
                      width="60px"
                      height="30px"
                      style={{ fill: sensor.color }}
                      stroke="#d1d1d1"
                      strokeWidth="2px"
                    />
                  ))}
                  {cable.sensors.map((sensor, index) => (
                    <text
                      x={headerSensorMobileCalc(verifyTemperature(sensor.t).toString().length)}
                      y={sensorsNumber * 30 + 35 - (index * 30 + 8)}
                      fill="black"
                      style={{ fontSize: 20, fontWeight: 700 }}
                    >
                      {verifyTemperature(sensor.t)}
                    </text>
                  ))}
                </svg>
              ))}
            </div>
          ) : (
            <div
              style={{
                position: 'relative',
                height: 280,
                width: 280,
                // marginLeft: mobile ? -15 : 0,
              }}
            >
              {(siloType === 'plainArm' ||
                siloType === 'R1Arm' ||
                siloType === 'plainTestArm') && (
                <FlatBottom modal={modal} mobile={window.matchMedia('(max-width: 844px)').matches} thermicVision="white" />
              )}
              {(siloType === 'semiVArm' || siloType === 'SR1Arm') && (
                <SemiConicalBottom modal={modal} thermicVision="white" />
              )}
              {(siloType === 'VArm' || siloType === 'digitalArm') && (
                <ConicalBottom modal={modal} thermicVision="white" />
              )}
              {(siloType === 'WArm' ||
                siloType === 'w1Arm' ||
                siloType === 'w2Arm') && (
                <MelitaBottom modal={modal} thermicVision="white" />
              )}
              {siloType === 'MArm' && (
                <MelitaBottom2 modal={modal} thermicVision="white" />
              )}

              <div
                className={(modal) ? `${siloType}Modal` : siloType}
                style={{
                  backgrounColor: 'white',
                  backgroundImage:
                    ((cables?.length === 1) && (viewStyle === false))
                      ? `linear-gradient(360deg, ${heatMapDataOneCable}`
                      : ((viewStyle === false)) && heatMapData,
                  position: 'absolute',
                  top: 8,
                  left: 17.5,
                }}
              >
                <div style={{ bottom: 0, width: lengthTermovisorModal }}>
                  {modal &&
                    cuttingPositions.map((cutting) => (
                      <svg
                        width={lengthTermovisorModal / cuttingPositions.length}
                        height={((siloType === 'plainArm') || (siloType === 'R1Arm') || (siloType === 'plainTestArm')) ? '385' :
                          ((siloType === 'semiVArm') || (siloType === 'SR1Arm')) ? '499' :
                            ((siloType === 'VArm') || (siloType === 'digitalArm') || (siloType === 'WArm')) ? '540' : '600'}
                      >
                        {cables?.map((cable) => {
                          if (cutting === cable.z) {
                            return cable.sensors.map((sensor, index) => (
                              <>
                                <ViewSensorsStyle
                                  x={(lengthTermovisorModal / cuttingPositions.length) / 2 - (siloType === 'R1Arm') ? 8 : 0}
                                  y={
                                calcTempPositionY(
                                  siloType,
                                  index,
                                  cable.sensors.length,
                                  cuttingPositions.length,
                                  cuttingPositions.findIndex((x) => (x === cable.z)),
                                ) +
                                startDispositionsSensorsY(siloType, cuttingPositions, cable.z)
                                  }
                                  color={sensor.color}
                                  temperature={sensor.t}
                                  style={viewStyle}
                                  siloType={siloType}
                                />
                              </>
                            ));
                          }
                          return '';
                        })}
                      </svg>
                    ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default HeatMap;
