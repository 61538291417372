import styled from 'styled-components';

export const Main = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  @media (max-width: 600px){
    flex-direction: column;
  }
`;

export const Content = styled.a`
  width: 220px;
  height: 200px;
  margin: 20px 10px;
  border-radius: 5px;
  border: 2px solid #556E7B;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: auto;
  h1{
    font-weight: 500;
    font-size: 25px;
    margin: 0;
    color: #556E7B;
  }
  @media (max-width: 600px){
    width: 90%;
    margin: 10px 0;
  }
  `;

export const ContentDiv = styled.div`
  width: 90%;
  height: auto;
  margin: 10px 0;
  display: flex;
  color: #000;
  justify-content: start;
  align-items: center;

  color: #556E7B;
  div{
    display: flex;
    flex-direction: column;
    margin: 0 15px;
  }
  p{
    color: #556E7B;
    font-size: 12px;
    margin: 0;
  }
`;
