export const systemTypes = [
  'Termometria',
  'Irrigação',
  'Fotovoltaica',
  'Automação',
];

export const systemTypesNumeric = [
  { id: 1, text: 'Irrigação' },
  { id: 2, text: 'Termometria' },
  { id: 3, text: 'Automação' },
  { id: 4, text: 'Fotovoltaica' },
  { id: 5, text: 'Estação Meteorológica' },
];

export const AvailablesTypes = ['number', 'string', 'boolean', 'object'];
