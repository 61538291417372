import * as BABYLON from 'babylonjs'

import originalTheme from './theme'

export const LabelNameMotor = (scene, name, tamanho, parent) => {
  const labelNameMotor = BABYLON.Mesh.CreatePlane(
    `nameMotor${name}`,
    tamanho,
    scene,
    false
  )

  // BORDA
  labelNameMotor.enableEdgesRendering()
  labelNameMotor.edgesWidth = 4.0
  labelNameMotor.edgesColor = '#333'
  labelNameMotor.sideOrientation = BABYLON.Mesh.DOUBLESIDE
  labelNameMotor.checkCollisions = true
  //
  labelNameMotor.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL
  // labelNameMotor.position = new BABYLON.Vector3(
  //   x >= 0 ? x + 0.5 : x - 0.5,
  //   y - 0.6,
  //   z - 0.5
  // )
  // labelNameMotor.position = new BABYLON.Vector3(x, y, z)
  labelNameMotor.parent = parent
  const texturaInfoObjeto = new BABYLON.DynamicTexture(
    'texturaInfoObjeto',
    512,
    scene,
    true
  )
  const fontSize = 80
  texturaInfoObjeto.drawText(
    name,
    null,
    120,
    `bold ${fontSize}px arial`,
    'black',
    '#fff'
  )
  labelNameMotor.material = new BABYLON.StandardMaterial(
    'materialSuperficie',
    scene
  )
  labelNameMotor.material.diffuseTexture = texturaInfoObjeto
  labelNameMotor.material.specularColor = new BABYLON.Color3(0, 0, 0)
  labelNameMotor.material.emissiveColor = new BABYLON.Color3(1, 1, 1)
  labelNameMotor.material.backFaceCulling = false
  labelNameMotor.isPickable = true
  // texturaInfoObjeto.hasAlpha = true;
  labelNameMotor.material.freeze()
}

export const StatusOnOffMotor = (scene, parent, tamanho, onOff, idMotor) => {
  const statusOnOffMotorPlane = BABYLON.Mesh.CreatePlane(
    `StatusOnOffMotor${idMotor}`,
    tamanho,
    scene,
    false
  )
  statusOnOffMotorPlane.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL
  statusOnOffMotorPlane.parent = parent

  const materialBaseSilo2 = new BABYLON.StandardMaterial(
    `materialBaseSilo2`,
    scene
  )
  materialBaseSilo2.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.baseSilo}`,
    scene
  )

  const auto = new BABYLON.StandardMaterial(`auto`, scene)
  auto.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.auto}`,
    scene
  )
  auto.diffuseTexture.uScale = 1
  auto.diffuseTexture.vScale = 1
  // auto.specularColor = new BABYLON.Color3(0, 0, 0)
  const hand = new BABYLON.StandardMaterial(`hand`, scene)
  hand.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.hand}`,
    scene
  )
  hand.diffuseTexture.uScale = 1
  hand.diffuseTexture.vScale = 1
  const block = new BABYLON.StandardMaterial(`block`, scene)
  block.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.block}`,
    scene
  )

  switch (onOff) {
    case 'M':
      statusOnOffMotorPlane.material = hand
      break
    case 'B':
      statusOnOffMotorPlane.material = block
      break
    default:
      statusOnOffMotorPlane.material = auto
  }

  //   statusOnOffMotorPlane.material.specularColor = new BABYLON.Color3(0, 0, 0)
  //   statusOnOffMotorPlane.material.emissiveColor = new BABYLON.Color3(1, 1, 1)

  statusOnOffMotorPlane.material.backFaceCulling = false
  statusOnOffMotorPlane.isPickable = false
}

const criaMotor3D = (scene) => {
  // CRIA BASE MOTOR
  const materialBaseSilo2 = new BABYLON.StandardMaterial(
    `materialBaseSilo2`,
    scene
  )
  materialBaseSilo2.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.baseSilo}`,
    scene
  )
  materialBaseSilo2.specularColor = new BABYLON.Color3(0, 0, 0)

  const pat = BABYLON.Mesh.FLIP_TILE
  const av = BABYLON.Mesh.TOP
  const ah = BABYLON.Mesh.LEFT

  const options = {
    sideOrientation: BABYLON.Mesh.DOUBLESIDE,
    pattern: pat,
    alignVertical: av,
    alignHorizontal: ah,
    width: 2,
    height: 0.9,
    depth: 2.8,
    tileSize: 1,
    tileWidth: 1,
  }

  const baseMotor3D = BABYLON.MeshBuilder.CreateTiledBox('baseMotor3D', options)
  baseMotor3D.material = materialBaseSilo2
  baseMotor3D.visibility = 0

  var columns = 6
  var rows = 1

  const faceUV = new Array(6)

  for (let i = 0; i < 6; i++) {
    faceUV[i] = new BABYLON.Vector4(i / columns, 0, (i + 1) / columns, 1 / rows)
  }

  const optionsSuporteElevadoMotor = {
    faceUV: faceUV,
    wrap: true,
    width: 0.7,
    height: 1.5,
    sideOrientation: BABYLON.Mesh.DOUBLESIDE,
    contours: new BABYLON.Vector2(1, 1),
    checkCollisions: true,
  }

  const suporteElevadoMotor = BABYLON.MeshBuilder.CreateBox(
    'suporteElevadoMotor',
    optionsSuporteElevadoMotor
  )
  suporteElevadoMotor.position.x = 0.3

  const motor = BABYLON.MeshBuilder.CreateCylinder('motor0', {
    height: 0.7,
    diameter: 0.8,
    sideOrientation: BABYLON.Mesh.DOUBLESIDE,
  })
  motor.position.y = 1.3
  motor.rotate(BABYLON.Axis.Z, BABYLON.Tools.ToRadians(90))
  motor.parent = suporteElevadoMotor

  const ventilador = BABYLON.MeshBuilder.CreateCylinder('ventilador', {
    height: 0.7,
    diameter: 1.7,
    sideOrientation: BABYLON.Mesh.DOUBLESIDE,
  })
  ventilador.position.y = 1.3
  ventilador.position.x = -0.7
  ventilador.rotate(BABYLON.Axis.Z, BABYLON.Tools.ToRadians(90))
  ventilador.parent = suporteElevadoMotor

  const optionsSaidaDeAr = {
    faceUV: faceUV,
    wrap: true,
    width: 1.5,
    height: 0.7,
    size: 0.3,
    sideOrientation: BABYLON.Mesh.DOUBLESIDE,
    contours: new BABYLON.Vector2(1, 1),
  }

  const saidaDeAr = BABYLON.MeshBuilder.CreateBox('saidaDeAr', optionsSaidaDeAr)
  saidaDeAr.parent = ventilador
  saidaDeAr.position.x = -0.85
  saidaDeAr.position.z = 0.7

  const baseMotorDiagonalDir = BABYLON.MeshBuilder.CreatePlane(
    'baseMotorDiagonalDir',
    { height: 0.7, width: 0.3, sideOrientation: BABYLON.Mesh.DOUBLESIDE }
  )

  baseMotorDiagonalDir.parent = motor
  baseMotorDiagonalDir.position.x = -0.4
  baseMotorDiagonalDir.position.z = -0.3
  baseMotorDiagonalDir.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(-20))

  const baseMotorPlanaDir = BABYLON.MeshBuilder.CreatePlane(
    'baseMotorPlanaDir',
    { height: 0.7, width: 0.1, sideOrientation: BABYLON.Mesh.DOUBLESIDE }
  )

  baseMotorPlanaDir.parent = baseMotorDiagonalDir
  baseMotorPlanaDir.position.x = -0.155
  baseMotorPlanaDir.position.z = 0
  baseMotorPlanaDir.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(-70))

  const baseMotorDiagonalEsq = BABYLON.MeshBuilder.CreatePlane(
    'baseMotorDiagonalEsq',
    { height: 0.7, width: 0.3, sideOrientation: BABYLON.Mesh.DOUBLESIDE }
  )

  baseMotorDiagonalEsq.parent = motor
  baseMotorDiagonalEsq.position.x = -0.4
  baseMotorDiagonalEsq.position.z = 0.3
  baseMotorDiagonalEsq.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(20))

  const baseMotorPlanaEsq = BABYLON.MeshBuilder.CreatePlane(
    'baseMotorPlanaEsq',
    { height: 0.7, width: 0.1, sideOrientation: BABYLON.Mesh.DOUBLESIDE }
  )

  baseMotorPlanaEsq.parent = baseMotorDiagonalEsq
  baseMotorPlanaEsq.position.x = -0.155
  baseMotorPlanaEsq.position.z = 0
  baseMotorPlanaEsq.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(70))

  const motor3D = BABYLON.Mesh.MergeMeshes(
    [
      motor,
      baseMotorDiagonalDir,
      baseMotorDiagonalEsq,
      baseMotorPlanaEsq,
      baseMotorPlanaDir,
    ],
    true,
    true,
    undefined,
    false,
    true
  )
  if (motor3D) {
    motor3D.material = materialBaseSilo2
    motor3D.visibility = 0
  }

  const suporteDoMotor3D = suporteElevadoMotor
  if (suporteDoMotor3D) {
    suporteDoMotor3D.material = materialBaseSilo2
    suporteDoMotor3D.isPickable = false
    suporteDoMotor3D.visibility = 0
  }

  const conjuntoMotor3D = BABYLON.Mesh.MergeMeshes(
    [ventilador, saidaDeAr],
    true,
    true,
    undefined,
    false,
    true
  )
  if (conjuntoMotor3D) {
    conjuntoMotor3D.material = materialBaseSilo2
    conjuntoMotor3D.visibility = 0
  }

  return {
    motor3D,
    baseMotor3D,
    conjuntoMotor3D,
    suporteDoMotor3D,
  }
}

export default criaMotor3D
