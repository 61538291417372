import React from 'react';
import DataTable from 'react-data-table-component';
import { columns } from './historicColumns';

export function VariableHistoric({ data }) {
  return (
    <DataTable
      pagination
      paginationPerPage={5}
      paginationComponentOptions={{ rowsPerPageText: 'Linhas:', rangeSeparatorText: 'de' }}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
      noDataComponent="Não existem dados para serem mostrados"
      columns={columns}
      data={data}
    />
  );
}
