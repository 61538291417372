import React from 'react';
import { Grid } from '@material-ui/core';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export function GraphicDatePicker({
  disabled,
  handleSubstractTaggleDay,
  selectedDate,
  setSelectedDate,
  handleAddTaggleDay,
}) {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Grid item>
        <button
          style={{ background: 'transparent', border: 'none' }}
          className="button-date"
          type="button"
          disabled={disabled}
          onClick={handleSubstractTaggleDay}
        >
          <IoIosArrowBack size={18} />
        </button>
      </Grid>
      <Grid item>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
        >

          <KeyboardDatePicker
            disabled={disabled}
            margin="normal"
            format="dd/MM/yyyy"
            value={selectedDate}
            name="initialDate"
            onChange={(e) => setSelectedDate(e)}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item>
        <button
          style={{ background: 'transparent', border: 'none' }}
          className="button-date"
          disabled={disabled}
          type="button"
          onClick={handleAddTaggleDay}
        >
          <IoIosArrowForward size={18} />
        </button>
      </Grid>
    </div>
  );
}
