import * as BABYLON from 'babylonjs'

import originalTheme from './theme'

export const CriaPaletaDeCores = (scene) => {
  const colorMotorStatus3D1 = new BABYLON.StandardMaterial(
    `colorMotorStatus3D1`,
    scene
  )
  colorMotorStatus3D1.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.blueLight}`,
    scene
  )
  colorMotorStatus3D1.zOffset = -2
  colorMotorStatus3D1.diffuseTexture.hasAlpha = true

  const colorMotorStatus3D2 = new BABYLON.StandardMaterial(
    `colorMotorStatus3D2`,
    scene
  )
  colorMotorStatus3D2.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.greenLight}`,
    scene
  )
  colorMotorStatus3D2.zOffset = -2
  colorMotorStatus3D2.diffuseTexture.hasAlpha = true

  const colorMotorStatus3D3 = new BABYLON.StandardMaterial(
    `colorMotorStatus3D3`,
    scene
  )
  colorMotorStatus3D3.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.greyLight}`,
    scene
  )
  colorMotorStatus3D3.zOffset = -2
  colorMotorStatus3D3.diffuseTexture.hasAlpha = true

  const colorMotorStatus3D4 = new BABYLON.StandardMaterial(
    `colorMotorStatus3D4`,
    scene
  )
  colorMotorStatus3D4.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.redLight}`,
    scene
  )
  colorMotorStatus3D4.zOffset = -2
  colorMotorStatus3D4.diffuseTexture.hasAlpha = true

  const colorMotorStatus3D5 = new BABYLON.StandardMaterial(
    `colorMotorStatus3D5`,
    scene
  )
  colorMotorStatus3D5.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.yellowLight}`,
    scene
  )
  colorMotorStatus3D5.zOffset = -2
  colorMotorStatus3D5.diffuseTexture.hasAlpha = true

  const colorMotorStatus3D1Shaded = new BABYLON.StandardMaterial(
    `colorMotorStatus3D1Shaded`,
    scene
  )
  colorMotorStatus3D1Shaded.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.blueDeep}`,
    scene
  )
  colorMotorStatus3D1Shaded.zOffset = -2
  colorMotorStatus3D1Shaded.diffuseTexture.hasAlpha = true

  const colorMotorStatus3D2Shaded = new BABYLON.StandardMaterial(
    `colorMotorStatus3D2Shaded`,
    scene
  )
  colorMotorStatus3D2Shaded.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.greenDeep}`,
    scene
  )
  colorMotorStatus3D2Shaded.zOffset = -2
  colorMotorStatus3D2Shaded.diffuseTexture.hasAlpha = true

  const colorMotorStatus3D3Shaded = new BABYLON.StandardMaterial(
    `colorMotorStatus3D3Shaded`,
    scene
  )
  colorMotorStatus3D3Shaded.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.greyDeep}`,
    scene
  )
  colorMotorStatus3D3Shaded.zOffset = -2
  colorMotorStatus3D3Shaded.diffuseTexture.hasAlpha = true

  const colorMotorStatus3D4Shaded = new BABYLON.StandardMaterial(
    `colorMotorStatus3D4Shaded`,
    scene
  )
  colorMotorStatus3D4Shaded.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.redDeep}`,
    scene
  )
  colorMotorStatus3D4Shaded.zOffset = -2
  colorMotorStatus3D4Shaded.diffuseTexture.hasAlpha = true

  const colorMotorStatus3D5Shaded = new BABYLON.StandardMaterial(
    `colorMotorStatus3D5Shaded`,
    scene
  )
  colorMotorStatus3D5Shaded.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.yellowDeep}`,
    scene
  )
  colorMotorStatus3D5Shaded.zOffset = -2
  colorMotorStatus3D5Shaded.diffuseTexture.hasAlpha = true

  // CRIA BASE MOTOR
  const materialBaseSilo2 = new BABYLON.StandardMaterial(
    `materialBaseSilo2`,
    scene
  )
  materialBaseSilo2.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.baseSilo}`,
    scene
  )
  materialBaseSilo2.specularColor = new BABYLON.Color3(0, 0, 0)

  const auto = new BABYLON.StandardMaterial(`auto`, scene)
  auto.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.auto}`,
    scene
  )
  auto.diffuseTexture.uScale = 1
  auto.diffuseTexture.vScale = 1
  // auto.specularColor = new BABYLON.Color3(0, 0, 0)
  const hand = new BABYLON.StandardMaterial(`hand`, scene)
  hand.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.hand}`,
    scene
  )
  hand.diffuseTexture.uScale = 1
  hand.diffuseTexture.vScale = 1
  const block = new BABYLON.StandardMaterial(`block`, scene)
  block.diffuseTexture = new BABYLON.Texture(
    `${originalTheme.textures.block}`,
    scene
  )

  const material = {
    colorMotorStatus3D1,
    colorMotorStatus3D2,
    colorMotorStatus3D3,
    colorMotorStatus3D4,
    colorMotorStatus3D5,
    colorMotorStatus3D1Shaded,
    colorMotorStatus3D2Shaded,
    colorMotorStatus3D3Shaded,
    colorMotorStatus3D4Shaded,
    colorMotorStatus3D5Shaded,
    auto,
    hand,
    block,
  }

  return material
}
