import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Fade,
  Grid,
  IconButton,
  Modal,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  RiCloseFill,
} from 'react-icons/ri';
import { HiOutlineChatAlt2 } from 'react-icons/hi';
import moment from 'moment';
import { ReadFeedBack } from '../helpers/callApi';
import Chat from './chat';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 0,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: 0,
    borderRadius: 6,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: '270px',
    width: '80%',
    // minHeight: 200,
    maxHeight: 500,
    // overflowY: 'auto',
  },
}));

export default function ModalReadFeedBack({ openModal, closeModal, data }) {
  if (!openModal) {
    return <div />;
  }
  const classes = useStyles();
  const [attachments, setAttachments] = useState([]);
  const [feedbackRead, setFeedbackRead] = useState({ files: [] });
  const [answered, setAnswered] = useState(false);

  const handleClose = () => {
    setFeedbackRead([]);
    setAnswered(false);
    closeModal(!openModal);
  };

  if (data.length === 0) {
    data = {
      id: '',
      status: '',
      conversation: [
        {
          ts: '',
          user: {
            name: '',
          },
          message: '',
        },
      ],
    };
  }

  async function read() {
    setFeedbackRead(await ReadFeedBack({ feedbackId: data?.id }));
  }

  useEffect(() => {
    if (data.id !== '') {
      read();
    }

    if ((data.status === 'A') || (data.status === 'S')) {
      setAnswered(true);
    }
    if ((data.status === 'N') || (data.status === '')) {
      setAnswered(false);
    }
  }, [data]);

  useEffect(() => {
    let newAttachment = [];
    if (feedbackRead.files.length > 0) {
      feedbackRead.files.forEach(async (item) => {
        newAttachment.push(item);
      });
      setAttachments(newAttachment);
    } else {
      newAttachment = [];
      setAttachments([]);
    }
  }, [feedbackRead]);

  return (
    <>
      <Modal
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ marginTop: 0, textAlign: 'right' }}>
                <IconButton onClick={handleClose} style={{ marginRight: 0 }}>
                  <RiCloseFill size={30} color="#556E7B" />
                </IconButton>
              </Grid>
              <Grid item xs={12} style={{ marginTop: -65 }}>
                <div>
                  <div>
                    <HiOutlineChatAlt2 size={30} color="#556E7B" />
                    <h2
                      style={{
                        color: '#556E7B',
                        marginTop: -33,
                        marginLeft: 33,
                      }}
                    >
                      Fale Conosco
                    </h2>
                  </div>
                </div>
              </Grid>
            </Grid>
            <div
              style={{ minHeight: '200px', maxHeight: '400px', overflowY: 'auto' }}
            >
              <h4
                style={{
                  marginTop: 10,
                  color: '#556E7B',
                }}
              >
                Protocolo:
                {' '}
                {feedbackRead?.protocol}
              </h4>
              <h4
                style={{
                  color: '#556E7B',
                }}
              >
                Tela:
                {' '}
                {data?.sourcePage}
              </h4>
              <Chat
                type={0}
                idFeedback={data?.id}
                date={moment.unix(data?.conversation[0]?.ts).format('DD/MM/YYYY')}
                time={moment.unix(data?.conversation[0]?.ts).format('HH:mm:ss')}
                user={data?.conversation[0]?.user?.name}
                comments={data?.conversation[0]?.message}
                attachments={attachments}
              />
              {
                answered &&
                (
                <Chat
                  type={1}
                  date={moment.unix(data?.conversation[1]?.ts).format('DD/MM/YYYY')}
                  time={moment.unix(data?.conversation[1]?.ts).format('HH:mm:ss')}
                  user={data?.conversation[1]?.user?.name}
                  comments={data?.conversation[1]?.message}
                  attachments={attachments}
                />
                )
            }
            </div>
          </div>
        </Fade>
      </Modal>
    </>

  );
}
