import React from 'react';
import { SliderUnitInfor } from './components/SliderUnitInfo';
import { useDashboardContext } from '../../../hooks/dashboard';
import Card from '../../Card';
import CardText from '../../CardText';
import CardTitle from '../../CardTitle';

export function StorageUnitCard() {
  const { formattedReportAerationStorage, plantIdToRequest } = useDashboardContext();
  return (
    <Card pt="4px" pr="4px" pl="4px" pb="0">
      <CardTitle pt="4px" pr="4px" pl="4px" pb="0" size="1rem">
        Unidades Armazenadoras
        {' '}
        {plantIdToRequest && '(Planta)'}
      </CardTitle>
      <CardText pt="4px" pr="4px" pl="4px" pb="10px" size="1rem">
        {formattedReportAerationStorage && (
          <SliderUnitInfor
            level={formattedReportAerationStorage.level}
            media={formattedReportAerationStorage.media}
            nivel={formattedReportAerationStorage.level}
            report={formattedReportAerationStorage.report}
          />
        )}
      </CardText>
    </Card>
    // <DashboardCardSider className="element1" title="Unidades Armazenadoras">

  // </DashboardCardSider>
  );
}
