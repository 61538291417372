import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { number, currency } from '../../helpers/Intl';
import EnergyCostSVG from '../EnergyCostSVG';

export function EnergyCostCard({ energyCost }) {
  const [ranges, setRanges] = React.useState([]);

  const buildTextValue = (i) => (
    <Grid item xs={i.size || 12} key={i.title}>
      <h4
        style={{
          width: '100%',
          fontWeight: '400',
          margin: '10px 0 0',
        }}
      >
        {i.title}
      </h4>
      <h3
        style={{
          width: '100%',
          fontWeight: '500',
          margin: '0 0 5px',
        }}
      >
        {i.content}
      </h3>
    </Grid>
  );

  React.useEffect(() => {
    const ret = [];

    if (
      energyCost &&
      energyCost.peakHour &&
      energyCost.peakHour.start &&
      energyCost.peakHour.finish
    ) {
      ret.push({
        name: 'Horário de Ponta',
        from: energyCost.peakHour.start,
        to: energyCost.peakHour.finish,
        color: '#FFE0E0',
        demand: energyCost.peakHour.demand,
        consumption: energyCost.peakHour.consumption,
      });
    }

    if (
      energyCost &&
      energyCost.commonHour &&
      energyCost.commonHour.start &&
      energyCost.commonHour.finish
    ) {
      ret.push({
        name: 'Horário Fora de Ponta',
        from: energyCost.commonHour.start,
        to: energyCost.commonHour.finish,
        color: '#FFF5E0',
        demand: energyCost.commonHour.demand,
        consumption: energyCost.commonHour.consumption,
      });
    }

    if (
      energyCost &&
      energyCost.specialHour &&
      energyCost.specialHour.start &&
      energyCost.specialHour.finish
    ) {
      ret.push({
        name: 'Horário Especial (Irrigação)',
        from: energyCost.specialHour.start,
        to: energyCost.specialHour.finish,
        color: '#E7FFE0',
        demand: energyCost.specialHour.demand,
        consumption: energyCost.specialHour.consumption,
      });
    }

    if (
      energyCost &&
      energyCost.intermediateHour1 &&
      energyCost.intermediateHour1.start &&
      energyCost.intermediateHour1.finish
    ) {
      ret.push({
        name: 'Horário Intermediário 1',
        from: energyCost.intermediateHour1.start,
        to: energyCost.intermediateHour1.finish,
        color: '#E0FCFF',
        demand: energyCost.intermediateHour1.demand,
        consumption: energyCost.intermediateHour1.consumption,
      });
    }

    if (
      energyCost &&
      energyCost.intermediateHour2 &&
      energyCost.intermediateHour2.start &&
      energyCost.intermediateHour2.finish
    ) {
      ret.push({
        name: 'Horário Intermediário 2',
        from: energyCost.intermediateHour2.start,
        to: energyCost.intermediateHour2.finish,
        color: '#EAE0FF',
        demand: energyCost.intermediateHour2.demand,
        consumption: energyCost.intermediateHour2.consumption,
      });
    }

    setRanges(ret);
  }, [energyCost]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12} lg={10} style={{ height: '100%' }}>
        <Card variant="outlined" style={{ padding: '10px' }}>
          <Grid container spacing={3}>
            {energyCost ? (
              [
                {
                  title: 'Nome Tarifador',
                  content: energyCost.description,
                  size: 6,
                },
                {
                  title: 'Modalidade Tarifária',
                  content: energyCost.taxType.taxType,
                  size: 6,
                },
                {
                  title: 'UC',
                  content: number(energyCost.consumerUnit),
                  size: 6,
                },
                {
                  title: 'Demanda Contratada (kW)',
                  content: number(energyCost.demand),
                  size: 6,
                },
              ].map(buildTextValue)
            ) : (
              <h2
                style={{
                  width: '100%',
                  textAlign: 'center',
                  fontWeight: '500',
                }}
              >
                Tarifação não configurada para esse equipamento
              </h2>
            )}
          </Grid>
        </Card>
      </Grid>

      <Grid item xs={12} md={12} lg={2} style={{ height: '100%' }}>
        <EnergyCostSVG timeRanges={ranges} />
      </Grid>

      {ranges.map((r) => (
        <Grid item xs={12} md={12} lg={4} style={{ height: '100%' }}>
          <Card
            variant="outlined"
            style={{ padding: '10px', background: r.color }}
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <h3
                  style={{
                    margin: '0',
                    textAlign: 'center',
                    fontWeight: '400',
                  }}
                >
                  {r.name}
                </h3>
              </Grid>
              {[
                { title: 'Início', content: r.from, size: 6 },
                { title: 'Fim', content: r.to, size: 6 },
                {
                  title: 'Demanda',
                  content: r.demand ? currency(r.demand) : '-',
                  size: 6,
                },
                { title: 'Consumo', content: currency(r.consumption), size: 6 },
              ].map(buildTextValue)}
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
