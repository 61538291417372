import React, { useCallback, useEffect, useState } from 'react';
import { subDays, addDays } from 'date-fns';
import { Skeleton, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Grid, makeStyles } from '@material-ui/core';
import FockinkAPI from '../../../../../middlewares/Api';
import { RainGraphic } from './components/RainIrrigationGraphic';
import { formatDate } from '../../../../../helpers/Intl';
// import EquipError from '../../../../../components/EquipError';
import { GraphicDatePicker } from './components/DatePicker';
import { MenuItemComponent } from '../../../../../components/MenuItemComponent';
import { TableStatistics } from './components/TableStatistics';

const useStyles = makeStyles((theme) => ({
  root: {
    width: ' 100%',
    height: '100%',
    minHeight: '680px',
    margin: '0 15px',
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  optionsDiv: {
    width: '95%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '280px',
    },
  },
  graphicDiv: {
    width: '100%',
    marginTop: '4rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}));

export function IrrigationGraphic({ equipamentId }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [quantityOfDays, setQuantityOfDays] = useState(1);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState();
  const [hasError, setHasError] = useState();
  const [statisticsData, setStatisticsData] = useState([]);

  const rangeDays = [1, 5, 10];

  const [selectedSeriesData, setSelectSeriesData] = useState({
    cultivationColorSequence: [],
    series: [],
  });

  const handleSelectData = (event) => {
    setSelectSeriesData({
      cultivationColorSequence: selectedSeriesData.cultivationColorSequence,
      series: event.target.value,
    });
  };

  const handleChangeQuantityDyas = (toggleDay) => {
    setQuantityOfDays(toggleDay);
  };

  const handleFormatDate = useCallback(() => {
    let startDate;
    let dateFinish;

    if (quantityOfDays === 1) {
      startDate = selectedDate;
      dateFinish = selectedDate;
    } else {
      const today = new Date();
      startDate = subDays(today, quantityOfDays);
      dateFinish = today;
    }

    const replace = /\//gi;
    const formatStartDate = formatDate(startDate, 'yyyy/MM/dd').replace(
      replace,
      '-',
    );
    const formatFinishDate = formatDate(dateFinish, 'yyyy/MM/dd').replace(
      replace,
      '-',
    );

    return {
      startDate: formatStartDate,
      dateFinish: formatFinishDate,
    };
  }, [quantityOfDays, selectedDate]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const requestData = handleFormatDate();
        setLoading(true);
        const response = await FockinkAPI.get(
          `/dashboards/irrigation/${equipamentId}/charts/cultiveRainAndIrrigation`,
          {
            params: requestData,
          },
        );

        setStatisticsData(Object.entries(response.data.data.table));

        const { chart } = response.data.data;
        setData(chart);
        setSelectSeriesData({
          cultivationColorSequence: [...chart.cultivationColorSequence],
          series: [],
        });
        setLoading(false);
      } catch (error) {
        setHasError('Erro ao consultar dados de irrigação');
      }

      setLoading(false);
    })();
  }, [equipamentId, quantityOfDays, selectedDate]);

  const handleSubstractTaggleDay = () => {
    const currentDate = selectedDate;
    const newSelectedDate = subDays(currentDate, 1);
    setSelectedDate(newSelectedDate);
  };

  const handleAddTaggleDay = () => {
    const currentDate = selectedDate;
    const newSelectedDate = addDays(currentDate, 1);
    setSelectedDate(newSelectedDate);
  };

  if (hasError) {
    return (
      <Grid container spacing={2} style={{ padding: '0 24px 24px' }}>
        <Grid item xs={12}>
          <h2>Oops.. ocorreu um erro :(</h2>
          <h3 style={{ fontWeight: '400' }}>{hasError}</h3>
        </Grid>
      </Grid>
    );
  }
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.optionsDiv}>
          <Grid item>
            <ToggleButtonGroup
              value={quantityOfDays}
              exclusive
              onChange={(event, currentSolution) =>
                handleChangeQuantityDyas(currentSolution)
              }
            >
              {rangeDays.map((day) => (
                <ToggleButton key={day} value={day} aria-label="all">
                  {day}
                  {day === 1 ? ' Dia' : ' Dias'}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>

          <GraphicDatePicker
            disabled={quantityOfDays !== 1}
            handleSubstractTaggleDay={handleSubstractTaggleDay}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            handleAddTaggleDay={handleAddTaggleDay}
          />

          {data && (
            <MenuItemComponent
              selectedSeriesData={selectedSeriesData}
              handleChange={handleSelectData}
              options={data.series}
            />
          )}
        </div>

        <div className={classes.graphicDiv}>
          <main>
            {loading ? (
              <Skeleton
                variant="rect"
                height="350px"
                width="100%"
                animation="wave"
              />
            ) : (
              data && (
                <RainGraphic
                  data={
                    selectedSeriesData.series.length > 0
                      ? selectedSeriesData
                      : data
                  }
                />
              )
            )}
          </main>

          <div>
            {statisticsData.map((table) => (
              <TableStatistics data={table} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
