// import React from 'react';

export default function selectedSection(cableNumber, cortes) {
  let sectionForReturn = 0;
  // console.log('cablesInSection ', cablesInSection);
  // console.log('cortes ->', cortes);

  // console.log('=cortes[0] ->>>>', cortes[0]);
  // if (cortes[0].length > 0) {
  sectionForReturn = cortes.map((item) => item);
  // } else {
  //   sectionForReturn = [];
  // }

  return (sectionForReturn);
}
