import React, { useState, useEffect } from 'react';
import { DivCable, DivSilo, DivMotor } from './style';
import SiloSVAuto from './siloSVAuto';
import autoDispositionCables from './autoDispositionCables';
import CableSVAuto from './cableSVAuto';
import crossSection from './crossSection';
import selectedSection from './selectedSection';
import autoDispositionMotors from './autoDispositionMotors';
import MotorSVAuto from './motorSVAuto';
import findBiggerTemperatureColor from '../../helpers/findBiggerTemperatureColor';

const siloDiameter = 200; // definição do diametro do silo na visão superior
const cableDiameter = 30; // definição do diametro do cabo na visão superior
const motorDiameter = 30; // definição do diametro do motor na visão superior

export default function SuperiorViewAuto({ data, cablesTest, setSelectedNumberCables }) {
  const [cables, setCables] = useState([]);
  const [engines, setEngines] = useState([]);
  const [disposition, setDisposition] = useState([]);
  const [enginesDisposition, setEnginesDisposition] = useState([]);
  const [cortes, setCortes] = React.useState([]);
  const [section, setSection] = React.useState([]);

  function handleCableClick(cableNumber) {
    setSection(selectedSection(cableNumber, cortes)); // seta os cabos da seção selecionada
  }

  React.useEffect(() => {
    if (data) {
      const auxCables = [];
      data.chartData.forEach((cd) => {
        cd.unitData.arcs.forEach((arc) => {
          arc.cabs.forEach((cab) => {
            auxCables.push({
              cable: cab.num,
              arc: arc.arc,
              color: findBiggerTemperatureColor(cab.sens),
            });
          });
        });
      });
      setCables(auxCables);
      setEngines(data.engines);
    }
  }, [data, cablesTest]);

  useEffect(() => {
    if ((cables[0]?.cable !== undefined)) {
    // carrega um array com a disposição dos cabos com o top e left de posicionamento
      setDisposition(autoDispositionCables(cables, siloDiameter, cableDiameter));
    }
  }, [cables]);

  useEffect(() => {
  // carrega informações do corte transversal
    setCortes(crossSection(cables, disposition));
  }, [disposition]);

  function AlterTopLeftArmaz() {
    let auxLeft = 0;
    let auxTop = 0;
    enginesDisposition.forEach((item) => {
      if (auxLeft > item.engineLeft) {
        auxLeft = item.engineLeft;
      }
      if (auxTop > item.engineTop) {
        auxTop = item.engineTop;
      }
    });

    if (auxLeft < 0) {
      auxLeft *= -1;
    }
    if (auxTop < 0) {
      auxTop *= -1;
    }

    return { left: auxLeft, top: auxTop };
  }

  useEffect(() => { // inicializa os cortes
    if ((cables[0]?.cable !== undefined)) { // verifica se os cabos ja foram carregados
      if ((cortes?.length > 0)) { // verifica s eos cortes ja foram calculados
        handleCableClick(cables[0].cable); // seleciona o primeiro corte
      }
    }
  }, [cortes]);

  useEffect(() => {
    setSelectedNumberCables(section); // envia os cabos do corte par ao termovisor
  }, [section]); // a cada novo corte selecionado

  useEffect(() => {
    engines && (
      setEnginesDisposition(autoDispositionMotors(engines, disposition))
    );
  }, [engines, disposition]);

  return (
    <div
      style={{
        marginLeft: AlterTopLeftArmaz().left,
        marginTop: AlterTopLeftArmaz().top }}
    >
      <DivSilo width={siloDiameter} height={siloDiameter}>

        <SiloSVAuto diameter={siloDiameter} />
        {disposition.map((item) => (
          <DivCable
            key={item.cable}
            width={cableDiameter + 100}
            height={cableDiameter + 100}
            top={item.top}
            left={item.left}
          >
            <CableSVAuto
              cable={item.cable}
              tempColor={item.color}
              handleClick={handleCableClick}
              diameter={cableDiameter}
              selectedSection={section}
            />
          </DivCable>
        ))}
        {enginesDisposition.map((item) => (
          <DivMotor
            key={item.engineId}
            width={motorDiameter}
            height={motorDiameter}
            top={item.engineTop}
            left={item.engineLeft}
          >
            <MotorSVAuto
              motor={item}
              diameter={motorDiameter}
            />
          </DivMotor>
        ))}
      </DivSilo>
    </div>
  );
}
