import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Redirect } from 'react-router-dom';
import '../../App.css';
import jwtDecode from 'jwt-decode';
import Breadcrumbs from '../../components/Breadcrumbs';
import CardIconText from '../../components/CardIconText';
import PortalPermissions from '../../helpers/enums/PortalPermissions';
import { hasPermission } from '../../helpers/permission';

export default () => {
  const [settings, setSettings] = React.useState([]);

  React.useEffect(() => {
    const token = localStorage.getItem('@FockinkAPP:token');
    const { unit } = jwtDecode(token);
    // if (PortalPermissions.USERS_VIEW.value === true) {
    //   console.log('teste');
    // }
    const opts = [
      {
        title: 'Tarifador',
        icon: (
          <img
            style={{ width: 58, height: 58 }}
            src="\images\icons\energyTariff.svg"
            alt="icon"
          />
        ),
        url: '/energyCost',
        permissionId: PortalPermissions.ENERGY_COST_MANAGE.value,
      },
      {
        title: unit?.plural || 'Unidades',
        icon: (
          <img
            style={{ width: 58, height: 58 }}
            src="\images\icons\place.svg"
            alt="icon"
          />
        ),
        url: '/units',
        permissionId: PortalPermissions.UNITS_VIEW.value,
      },
      {
        title: 'Usuários',
        icon: (
          <img
            style={{ width: 58, height: 58 }}
            src="\images\icons\users.svg"
            alt="icon"
          />
        ),
        url: '/portalUsers',
        permissionId: PortalPermissions.USERS_VIEW.value,
      },
      {
        title: 'Alarmes',
        icon: (
          <img
            style={{ width: 58, height: 58 }}
            src="\images\icons\configAlarm.svg"
            alt="icon"
          />
        ),
        url: '/alarms',
        permissionId: PortalPermissions.USERS_VIEW.value,
      },
      {
        title: 'Cadastro de combustíveis',
        icon: (
          <img
            style={{ width: 58, height: 58 }}
            src="\images\icons\fuel.svg"
            alt="icon"
          />
        ),
        url: '/settings/costFuel',
        permissionId: PortalPermissions.USERS_VIEW.value,
      },

      {
        title: 'Relatórios',
        icon: (
          <img
            style={{ width: 58, height: 58 }}
            src="\images\icons\configReport.svg"
            alt="icon"
          />
        ),
        url: '/settings/reportSettings',
        permissionId: PortalPermissions.USERS_VIEW.value,
      },
    ];

    // if( configuração de relatorio for == true então apresenta ){
    // if (localStorage.userProfile === 'Administrador' || 'Gestor') {
    //   filtered.push(
    //     ...[
    //       {
    //         title: 'Configurações de relatórios',
    //         icon: <AssessmentIcon style={style} />,
    //         url: '/settings/reportSettings',
    //         permissionId: PortalPermissions.USERS_VIEW.value,
    //       },
    //     ],
    //   );
    // }

    const filtered = opts.filter((f) => hasPermission(f.permissionId));

    if (localStorage.userProfile === 'Administrador') {
      filtered.push(
        ...[
          {
            title: 'Bloqueio de Subdominios',
            icon: (
              <img
                style={{ width: 58, height: 58 }}
                src="\images\iconsSettings\padlock.svg"
                alt="icon"
              />
            ),
            url: '/settings/blockedHosts',
          },
          {
            title: 'Config. de Telemetria',
            icon: (
              <img
                style={{ width: 58, height: 58 }}
                src="\images\iconsSettings\telemetryConfig.svg"
                alt="icon"
              />
            ),
            url: '/settings/telemetry',
          },
          {
            title: 'Aprovadores de Entrega Técnica',
            icon: (
              <img
                style={{ width: 58, height: 58 }}
                src="\images\iconsSettings\approvedIcon.svg"
                alt="icon"
              />
            ),
            url: '/settings/tdApprovers',
          },
          {
            title: 'Modelos de Entrega Técnica',
            icon: (
              <img
                style={{ width: 58, height: 58 }}
                src="\images\iconsSettings\checklist.svg"
                alt="icon"
              />
            ),
            url: '/settings/generateTechDelivery',
          },
          {
            title: 'Subtipos de Entrega Técnica',
            icon: (
              <img
                style={{ width: 58, height: 58 }}
                src="\images\iconsSettings\checklists.svg"
                alt="icon"
              />
            ),
            url: '/settings/techDeliveryTypes',
          },
          {
            title: 'Análise Química do Solo',
            icon: (
              <img
                style={{ width: 58, height: 58 }}
                src="\images\iconsSettings\chemistryAnalysis.svg"
                alt="icon"
              />
            ),
            url: '/settings/soilAnalysis',
          },
          {
            title: 'Análise Física do Solo',
            icon: (
              <img
                style={{ width: 58, height: 58 }}
                src="\images\iconsSettings\soilAnalysis.svg"
                alt="icon"
              />
            ),
            url: '/settings/soilPhysicalAnalysis',
          },
          {
            title: 'Cadastro de Planos',
            icon: (
              <img
                style={{ width: 58, height: 58 }}
                src="\images\icons\configVars.svg"
                alt="icon"
              />
            ),
            url: '/settings/plans',
          },
        ],
      );
    }

    if (localStorage.userProfile === 'Gestor') {
      filtered.push(
        ...[
          {
            title: 'Conta',
            icon: (
              <img
                style={{ width: 58, height: 58 }}
                src="\images\icons\user.svg"
                alt="icon"
              />
            ),
            url: '/account/settings/',
          },
          {
            title: 'Cadastro de cultura',
            icon: (
              <img
                style={{ width: 58, height: 58 }}
                src="\images\iconsSettings\checklist.svg"
                alt="icon"
              />
            ),
            url: '/culture/new',
          },
          {
            title: 'Variáveis',
            icon: (
              <img
                style={{ width: 58, height: 58 }}
                src="\images\icons\configVars.svg"
                alt="icon"
              />
            ),
            url: '/variaveis/config',
          },
        ],
      );
    }

    setSettings(filtered);
  }, []);

  const breadcrumb = {
    title: 'Configurações',
    routes: [{ name: 'Configurações' }],
  };

  if (!localStorage.profile) {
    return <Redirect to="/logout" />;
  }

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1}>
        {settings.length === 0 && (
          <h2>Você não tem acesso a nenhuma configuração </h2>
        )}
        {settings.length > 0 &&
          settings.map((s) => (
            <Grid item lg={2} md={2} xs={6} key={s.url}>
              <CardIconText icon={s.icon} title={s.title} url={s.url} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};
