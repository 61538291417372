// Basic
import React, { useContext, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Accordion,
  Grid,
  Snackbar,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// UI, images, styles and icons
import './index.scss';
import { Skeleton, Alert } from '@material-ui/lab';
import {
  MapContainer,
  TileLayer,
  Marker,
  // Popup,
  LayersControl,
  SVGOverlay,
} from 'react-leaflet';
import { makeStyles } from '@material-ui/core/styles';
import L /* map */ from 'leaflet';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import WeatherStation from '../../../../components/WeatherStation/WeatherStation';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import PivoSVG from '../../../../components/PivotSVG/index';
import 'leaflet/dist/leaflet.css';

import { PageContext } from '../../../../hooks/Contexts/Page';

// API
import FockinkAPI from '../../../../middlewares/Api';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: 'white',
  },
  accordion: {
    background: 'linear-gradient(90deg, rgba(0,0,0,0.19091386554621848) 100%, rgba(0,0,0,1) 100%)',
  },
}));

export default () => {
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  // const [plantId, setPlantId] = React.useState(false);
  const [alertText, setAlertText] = React.useState();
  const [redirect, setRedirect] = React.useState(false);
  const [breadcrumbs, setBreadcrumbs] = React.useState({});
  const [zoom, setZoom] = React.useState();
  // const [plantName, setPlantName] = React.useState();
  const plantName = '';
  const [equipaments, setEquipaments] = React.useState();
  const [weatherStations, setWeatherStations] = React.useState();
  const [plantBounds, setPlantBounds] = React.useState();
  const [centerPosition, setCenterPosition] = React.useState([0, 0]);
  const [showMarkers, setShowMarkers] = React.useState(false);
  const classes = useStyles();
  const editMarkerInfos = [
    `Clique para ${
      showMarkers
        ? 'desabilitar o reposicionamento dos equipamentos'
        : 'habilitar o reposicionamento dos equipamentos'
    }`,
  ];
  const eventHandlers = useMemo(
    () => ({
      dragend(y) {
        setLoading(true);
        const newEquipaments = [...equipaments];
        const found = newEquipaments.find((x) => x.id === y.target.options.id);
        if (found) {
          // eslint-disable-next-line no-underscore-dangle
          found.settings.location.lat = y.target._latlng.lat;
          // eslint-disable-next-line no-underscore-dangle
          found.customLocation.lat = y.target._latlng.lat;
          // eslint-disable-next-line no-underscore-dangle
          found.settings.location.lon = y.target._latlng.lng;
          // eslint-disable-next-line no-underscore-dangle
          found.customLocation.lon = y.target._latlng.lng;
          setEquipaments(newEquipaments || []);
          setCenterPosition([found.settings.location.lat, found.settings.location.lon]);
          const dataToSend = {
            lat: parseFloat(found.settings.location.lat.toFixed(8)),
            lon: parseFloat(found.settings.location.lon.toFixed(8)),
          };
          FockinkAPI.put(`/equipaments/${y.target.options.id}/updateLatLon`, dataToSend);
        }
        setLoading(false);
      },
    }),
    [equipaments],
  );
  const { setBackPageContext, setPlantIDContext } = useContext(PageContext);
  React.useEffect(() => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const pid = urlParams.get('plantId');
    setPlantIDContext(pid);
    FockinkAPI.get('/dashboards/equipaments', {
      params: { plantId: pid, extended: true },
    }).then((response) => {
      setWeatherStations(response.data.data.equipaments?.filter((x) => x.operationId === 5));
      setZoom(15);
      let bigLat = -9999;
      let bigLon = -9999;
      let smallLat = 9999;
      let smallLon = 9999;
      response.data.data.equipaments.forEach((equip) => {
        if (equip?.telemetries?.op1000 && equip?.telemetries?.op1001) {
          if ((equip?.customLocation?.lat || equip.settings.location.lat) > bigLat) {
            bigLat = equip.settings.location.lat;
          }
          if ((equip?.customLocation?.lon || equip.settings.location.lon) > bigLon) {
            bigLon = equip.settings.location.lon;
          }
          if ((equip?.customLocation?.lat || equip.settings.location.lat) < smallLat) {
            smallLat = equip.settings.location.lat;
          }
          if ((equip?.customLocation?.lon || equip.settings.location.lon) < smallLon) {
            smallLon = equip.settings.location.lon;
          }
        }
      });

      const radiLat = bigLat - smallLat;
      const radiLon = bigLon - smallLon;
      const radi =
          radiLat > radiLon
            ? (radiLat * 0.9235) / 0.00001 / 2 + 50
            : (radiLon * 0.9235) / 0.00001 / 2 + 50;
      const centerLat = (bigLat + smallLat) / 2;
      const centerLon = (bigLon + smallLon) / 2;
      setPlantBounds(findBoundsSVG(centerLat, centerLon, radi));
      setCenterPosition([centerLat, centerLon]);
      setBreadcrumbs({
        routes: [
          { name: 'Plantas', to: '/plants' },
        ],
      });
      setEquipaments(response.data.data.equipaments || []);
    })
      .catch((error) => {
        console.error('global map', error);
        const em =
          (error?.response?.data.validation && error?.response?.data.validation.body.message) ||
          error?.response?.data.reason ||
          error?.response?.data.message;
        setAlertText(em);
        setOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function findBoundsSVG(latitude, longitude, radius) {
    const angularRadius = (radius * 0.00001) / 0.9235;
    const lat1 = latitude - angularRadius;
    const lat2 = latitude + angularRadius;
    const long1 = longitude - angularRadius;
    const long2 = longitude + angularRadius;
    return [
      [lat1, long1],
      [lat2, long2],
    ];
  }

  const handleClose = () => {
    setOpen(false);
  };

  if (redirect) return <Redirect push to={redirect} />;
  if (loading) {
    return (
      <Skeleton width="100%" height="200px">
        <div className="map_container" />
      </Skeleton>
    );
  }
  return (
    <>
      <Breadcrumbs routes={breadcrumbs.routes} loading={loading} />
      <Grid container spacing={0} style={{ position: 'relative' }}>
        <Grid item xs={12} lg={12} xl={12} style={{ zIndex: 1 }}>
          {loading ? (
            <Skeleton width="100%">
              <div className="map_container" />
            </Skeleton>
          ) : (
            <MapContainer
              center={centerPosition}
              zoom={zoom}
              maxZoom={19}
              zoomSnap={0.1}
              zoomDelta={0.1}
              wheelPxPerZoomLevel={120}
              scrollWheelZoom
              className="map_container"
              whenReady={(mapa) => {
                mapa.target.on('zoom', (e) => {
                  // eslint-disable-next-line no-underscore-dangle
                  setZoom(e.target._animateToZoom);
                });
                // Solução porca que não resolveu o problema do click:
                // map.target.on('click', (e) => {
                // });
              }}
            >
              <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="Imagem Satelite">
                  <TileLayer
                    attribution="World Map"
                    // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Colorido">
                  <TileLayer
                    attribution="World Map"
                    url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Alto Contraste">
                  <TileLayer
                    attribution="World Map"
                    url="http://a.tile.stamen.com/toner/{z}/{x}/{y}.png"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Escala de cinza">
                  <TileLayer
                    attribution="World Map"
                    url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Escuro">
                  <TileLayer
                    attribution="World Map"
                    url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_all/{z}/{x}/{y}.png"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Claro">
                  <TileLayer
                    attribution="World Map"
                    url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
                  />
                </LayersControl.BaseLayer>
              </LayersControl>
              {
                plantBounds && (
                <SVGOverlay interactive bounds={plantBounds}>
                  <svg viewBox="0 0 100 100">
                    <g className={zoom < 12 ? 'show_plant' : 'hide_plant'}>
                      <circle cx="50" cy="50" r="50" className="area_point" />
                      <text x="50" y="60" className="plant_name">
                        {plantName}
                      </text>
                    </g>
                  </svg>
                </SVGOverlay>
                )}

              {weatherStations &&
              ((weatherStations).map((station) => (
                <SVGOverlay
                  key={station?.id}
                  interactive
                  bounds={findBoundsSVG(
                    station?.customLocation?.lat || station?.settings?.location.lat,
                    station?.customLocation?.lon || station?.settings?.location.lon,
                    600,
                  )}
                >
                  <WeatherStation
                    WeatherStationId={station.id}
                    onClickWs={() => {
                      setRedirect(`/weatherStation/${station.id}`);
                      setBackPageContext('mapa');
                    // setPlantIDContext({ plantId })
                    }}
                  />
                </SVGOverlay>
              )))}
              { equipaments &&
              ((equipaments || []).map((equipament) => {
                if (
                  equipament?.operationId === 1 &&
                  equipament?.telemetries.op1000 &&
                  equipament?.telemetries.op1001
                ) {
                  return (
                    <Marker
                      key={equipament.id}
                      id={equipament.id}
                      position={{
                        lat: equipament?.customLocation?.lat || equipament?.settings?.location.lat,
                        lng: equipament?.customLocation?.lon || equipament?.settings?.location.lon,
                      }}
                      draggable={showMarkers}
                      eventHandlers={eventHandlers}
                      opacity={showMarkers ? 100 : 0}
                      icon={
                        new L.Icon({
                          // eslint-disable-next-line global-require
                          iconUrl: '/images/icons/mapMarkerIcon.svg',
                          iconSize: new L.Point(40, 40),
                          iconAnchor: [0, 0],
                        })
                      }
                    >
                      <SVGOverlay
                        key={equipament.id}
                        interactive
                        bounds={findBoundsSVG(
                          equipament.customLocation?.lat || equipament.settings.location.lat,
                          equipament.customLocation?.lon || equipament.settings.location.lon,
                          equipament.telemetries.op1000.wheelLength || 200,
                        )}
                      >
                        <PivoSVG
                          name={equipament.clientDescription || equipament.description}
                          tooltipData={equipament || ''}
                          radius={equipament.telemetries.op1000.wheelLength || 200}
                          startAngle={0}
                          endAngle={equipament.telemetries.op1000.maxSectorAngle}
                          zeroAngle={equipament.settings.northFaceAngle}
                          isMapMode
                          isEditingMode={false}
                          zoomLevel={zoom}
                          latitude={equipament.telemetries.op1000.latitude}
                          longitude={equipament.telemetries.op1000.longitude}
                          cannonAngles={equipament.telemetries.op1000.cannons}
                          destinationAngle={equipament.telemetries.op1001.recipePosition}
                          currentAngle={equipament.telemetries.op1001.positionActual}
                          recipeEndAngle={equipament.telemetries.op1001.recipePosition}
                          isRotatingLeft={equipament.telemetries.op1001.stDirectionRotationLeft}
                          isRotatingRight={equipament.telemetries.op1001.stDirectionRotationRight}
                          isStoped={!equipament.telemetries.op1001.stMovingPivot}
                          isStandbyTime={equipament.telemetries.op1001.stPressureTime}
                          waterBladeSize={equipament.telemetries.op1001.recipeWaterMm}
                          hasWater={equipament.telemetries.op1001.recipeWater}
                          isPowerOn={equipament.telemetries.op1001.stOnOff}
                          isDisconnected={equipament.connection.offline}
                          isEmergencyWarning={equipament.telemetries.op1001.stFailEmergency}
                          isFailing={equipament.failures.list}
                          showCovered
                          equipamentId={equipament.id}
                          onClickPower={() => {
                            setRedirect(`/irrigationDash/${equipament.id}`);
                            setBackPageContext('mapa');
                            // setPlantIDContext({ plantId })
                          }}
                        />
                      </SVGOverlay>
                    </Marker>
                  );
                }
                return null;
              }))}
            </MapContainer>
          )}
        </Grid>
        { editMarkerInfos && (
        <Tooltip title={editMarkerInfos || ''} placement="left">
          <EditLocationIcon
            onClick={() => setShowMarkers(!showMarkers)}
            style={{
              position: 'absolute',
              zIndex: 10,
              bottom: 20,
              right: 5,
              width: 70,
              height: 70,
              color: 'white',
            }}
          />
        </Tooltip>
        )}
        <Grid
          item
          xs={12}
          lg={12}
          xl={12}
          className="label_container desktop"
          style={{ position: 'absolute', zIndex: 10, bottom: 5, left: 5, width: 200 }}
        >
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>Legenda</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography style={{ width: 210 }}>
                <div className="label">
                  <div className="label_type">
                    <div className="current_position line" />
                  </div>
                  <div className="label_name">Posição atual</div>
                </div>
                <div className="label">
                  <div className="label_type">
                    <div className="recipe_start line" />
                  </div>
                  <div className="label_name">Posição de origem</div>
                </div>
                <div className="label">
                  <div className="label_type">
                    <div className="recipe_end line" />
                  </div>
                  <div className="label_name">Posição de destino</div>
                </div>
                <div className="label">
                  <div className="label_type pivot_on_water" />
                  <div className="label_name">Pivô ligado e com água</div>
                </div>
                <div className="label">
                  <div className="label_type pivot_on_no_water" />
                  <div className="label_name">Pivô ligado e sem água</div>
                </div>
                <div className="label">
                  <div className="label_type standby" />
                  <div className="label_name">Pivô ligado e aguardando</div>
                </div>
                <div className="label">
                  <div className="label_type pivot_off" />
                  <div className="label_name">Pivô desligado</div>
                </div>
                <div className="label">
                  <div className="label_type emergency" />
                  <div className="label_name">Pivô em emergência</div>
                </div>
                <div className="label">
                  <div className="label_type fail" />
                  <div className="label_name">Pivô sem comunicação</div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {alertText}
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
};
