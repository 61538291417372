/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import '../../../../../App.css';
import ReactApexChart from 'react-apexcharts';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import FockinkAPI from '../../../../../middlewares/Api';
import { formatDate } from '../../../../../helpers/Intl';

export default (props) => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(undefined);
  const [state, setState] = React.useState(undefined);
  const [days, setDays] = React.useState(5);

  const callApi = () => {
    setLoading(true);
    FockinkAPI.get(`/dashboards/thermometry/${props.equipamentId}/charts/levelTemperature?date=${props.lastValidDate}`, { params: { sub: props.sub, days } }).then((response) => {
      setData(response.data.data.chartData);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    callApi();
  }, [days, props.lastValidDate]);

  React.useEffect(() => {
    if (data) {
      setState({
        series: data.series.map((a) => ({ ...a, data: a.data.reverse() })),
        options: {
          chart: {
            height: 450,
            type: 'line',
            stacked: false,
            toolbar: {
              show: false,
            },
          },
          colors: ['#39FD6A', '#9130BF', '#CFBA1F', '#009AFF'],
          dataLabels: {
            enabled: false,
          },
          title: {
            show: false,
          },
          legend: {
            position: 'top',
          },
          xaxis: {
            categories: data.categories.map((m) => formatDate(m, 'dd/MM/yyyy')).reverse(),
          },
          yaxis: [
            {
              opposite: true,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
              },
              title: {
                text: 'Nível (%)',
              },
            },
            {
              max: Math.max(...(data.series[1]?.data || [])) + 2,
              axisTicks: {
                show: true,
              },
              axisBorder: {
                show: true,
              },
              title: {
                text: 'Temperatura média (°C)',
              },
            },
            {
              show: false,
              max: Math.max(...(data.series[1]?.data || [])) + 2,
            },
            {
              show: false,
              max: Math.max(...(data.series[1]?.data || [])) + 2,
            },
          ],
          tooltip: {
            followCursor: true,
            y: {
              formatter(y, x) {
                if (typeof y !== 'undefined') {
                  if (x.seriesIndex === 0) return `${y}%`;
                  return `${y}°C`;
                }
                return y;
              },
            },
          },
        },
      });
    }
  }, [data]);

  if (loading || !state) {
    return (
      <Skeleton variant="rect" height={350} width="100%" animation="wave" />
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        {/* <h3 style={{ fontWeight: 400 }}>
          Nível e temperaturas médias
        </h3> */}
      </Grid>
      <Grid item xs={12} style={{ textAlign: 'right' }}>
        <ToggleButtonGroup
          value={days}
          exclusive
          onChange={(event, currentSolution) => {
            if (currentSolution !== null) {
              setDays(currentSolution);
            }
          }}
        >
          <ToggleButton value={5} aria-label="all">
            5 Dias
          </ToggleButton>
          <ToggleButton value={10} aria-label="all">
            10 Dias
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>

      <Grid item xs={12}>
        <ReactApexChart
          style={{ width: '100%' }}
          options={state.options}
          series={state.series}
          height={state.options.chart.height}
        />
      </Grid>
    </Grid>
  );
};
