import React from 'react';
import {
  makeStyles,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Grid,
  Hidden,
} from '@material-ui/core';
import { TextValue } from './TextValue';
import { number, secondsToHm } from '../../../../../../helpers/Intl';

const useStyles = makeStyles({
  root: {
    width: '100%',
    background: '#FFF',
    padding: '1rem',
    borderRadius: '0.2rem',
    minHeight: '5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export function SumHeader({
  reportHeader,
  reportMotors,
  reportGrains,
  reportType,
  selectedGrain,
  equipamentId,
  setSelectedGrain,
}) {
  const classes = useStyles();

  const motorsKeys = Object.keys(reportMotors.perHP);

  const eqpTypes = {
    1: 'Silo',
    2: 'Silo',
    3: 'Armazém',
    4: 'Armazem',
    5: 'Armazém',
    6: 'Armazem',
    7: 'Armazém',
    8: 'Armazém',
    9: 'Silo',
    10: 'Silo',
    11: 'Silo',
    12: 'Tulha',
    13: 'Tulha',
    14: 'Bunker',
    15: 'Bunker',
    16: 'Armazém',
    17: 'Armazém',
  };

  const aerationData = selectedGrain
    ? reportHeader.aerationHoursPerGrain[selectedGrain]
    : reportHeader.hoursPerYear;

  return (
    <div className={classes.root}>
      <Grid
        spacing={2}
        justifyContent="space-between"
        display="flex"
        alignItems="flex-start"
        // alignItems="stretch"
        container
        direction="row"
      >
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          md={12}
          style={{
            background: '#CECECE',
            textAlign: 'center',
            border: '1px solid #000',
          }}
        >
          Controle de horas por mês
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Grid container spacing={2}>
            {[
              'Jan',
              'Fev',
              'Mar',
              'Abr',
              'Mai',
              'Jun',
              'Jul',
              'Ago',
              'Set',
              'Out',
              'Nov',
              'Dez',
            ].map((month, i) => (
              <Grid item xs={3} sm={3} md={1} lg={1}>
                <TextValue
                  header={month}
                  value={secondsToHm(reportHeader.hoursPerMonth[i])}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item sm={12} lg={12} md={12} style={{ marginBottom: '5px' }} />

        <Grid item sm={12} lg={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={3} md={3} lg={3}>
              <TextValue
                header="Total de Horas Ano"
                value={secondsToHm(reportHeader.hoursPerYear)}
              />
            </Grid>

            <Grid item xs={6} lg={3} md={3}>
              <TextValue
                header="Total Dias Aerados"
                value={reportHeader.daysWithAeration}
              />
            </Grid>

            <Hidden xsUp>
              <Grid item xs={12} style={{ marginBottom: '5px' }} />
            </Hidden>

            <Grid item xs={6} sm={3} md={3} lg={3}>
              <TextValue
                header={`Horas Aeração (${selectedGrain || 'Total'})`}
                value={secondsToHm(aerationData)}
              />
            </Grid>

            <Grid item xs={6} sm={3} md={3} lg={3}>
              <TextValue
                header="Total Horas Aeradas após Ajuste"
                value={secondsToHm(
                  aerationData * (reportHeader.engines.percent / 100),
                )}
              />
            </Grid>

            <Grid item sm={12} style={{ marginBottom: '5px' }} />
          </Grid>
        </Grid>

        {/* Aqui começa */}

        <Grid item xs={12} md={4} lg={4}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                background: '#CECECE',
                textAlign: 'center',
                border: '1px solid #000',
              }}
            >
              Temperatura
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <TextValue
                header="Início (média)"
                value={number(reportHeader.temp.start)}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4}>
              <TextValue
                header="Fim (média)"
                value={number(reportHeader.temp.finish)}
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4}>
              <TextValue
                header="Média (total)"
                value={number(reportHeader.temp.avg)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Hidden xsUp>
          <Grid item xs={12} style={{ marginBottom: '5px' }} />
        </Hidden>

        <Hidden mdUp>
          <Grid item md={12} style={{ marginBottom: '5px' }} />
        </Hidden>

        <Grid item xs={12} lg={4} md={4}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                background: '#CECECE',
                textAlign: 'center',
                border: '1px solid #000',
              }}
            >
              Umidade Relativa
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <TextValue
                header="Início (média)"
                value={number(reportHeader.humid.start)}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <TextValue
                header="Fim (média)"
                value={number(reportHeader.humid.finish)}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <TextValue
                header="Média (total)"
                value={number(reportHeader.humid.avg)}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <Hidden xsUp>
          <Grid item xs={12} sm={12} style={{ marginBottom: '5px' }} />
        </Hidden> */}

        <Hidden mdUp>
          <Grid item md={12} style={{ marginBottom: '5px' }} />
        </Hidden>

        <Grid item xs={12} lg={4} md={4}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{
                background: '#CECECE',
                textAlign: 'center',
                border: '1px solid #000',
              }}
            >
              Motores
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextValue
                header="Média (total)"
                value={number(reportHeader.engines.avgOn)}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={6}>
              <TextValue
                header="Percentual (utilizado)"
                value={`${number(reportHeader.engines.percent)}%`}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Aqui acaba */}
        <Grid item xs={6} sm={12} md={3} lg={3} style={{ marginBottom: '5px' }} />

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ minHeight: 15, padding: 0 }}
        >
          <table border="2px" width="100%">
            <tr bgcolor="#CECECE" border="2px">
              <td align="center" height="40px">Ventiladores</td>
              <td align="center">Produtos</td>
              <td align="center">Local</td>
            </tr>
            <tr>
              <td align="center">
                <p>
                  Quantidade de motores:
                  <span>{reportMotors.total}</span>
                </p>

                <p>
                  Potência:
                  {motorsKeys.map((key) => (
                    <span key={key}>
                      {`${reportMotors.perHP[key]} x ${key}cv`}
                      {motorsKeys.length - 1 > motorsKeys.indexOf(key)
                        ? ' / '
                        : ''}
                    </span>
                  ))}
                </p>
              </td>

              <td align="center">
                <div style={{ padding: 5 }}>
                  <FormControl>
                    <RadioGroup
                      row
                      value={selectedGrain || 'ALL'}
                      onChange={(e) => {
                        const v =
                          e.target.value === 'ALL' ? undefined : e.target.value;
                        setSelectedGrain(v, equipamentId);
                      }}
                    >
                      <FormControlLabel
                        value="ALL"
                        control={<Radio />}
                        label="Todos"
                      />
                      {reportGrains.map((grain) => (
                        <FormControlLabel
                          value={grain}
                          control={<Radio />}
                          label={grain}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              </td>
              <td align="center" text-align="center">
                {eqpTypes[reportType.unitType] || 'Tipo Desconhecido'}
              </td>
            </tr>
          </table>
        </Grid>

      </Grid>
    </div>
  );
}
