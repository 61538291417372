import React from 'react';
import { BsPlus } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import FockinkAPI from '../../../../middlewares/Api';
import { useAuth } from '../../../../hooks/account';
import { Modal } from '../../../../components/Modal';
import { ButtonComponent as Button } from '../../../../components/Button';
import BackButton from '../../../../components/Buttons/BackButton';
import { CultureTable, NewCultureForm, UpdateCultureForm } from './components';

import * as S from './styles';

const breadcrumb = {
  title: 'Cadastro de Cultura',
  routes: [
    { name: 'Configurações', to: '/settings' },
    { name: 'Cadastro de Cultura' },
  ],
};

export function CultureForm() {
  const history = useHistory();

  const { user } = useAuth();

  const [openNewCultureModalForm, setOpenNewCultureModalForm] = React.useState(
    false,
  );

  const [
    openUpdateCultureModalForm,
    setOpenUpdateCultureModalForm,
  ] = React.useState(false);

  // const [hasError, setHasError] = React.useState(false);
  const [cultures, setCultures] = React.useState([]);
  const [selectedCultureToEdit, setSelectedCultureToEdit] = React.useState();

  // const [isLoading, setIsLoading] = React.useState(false);

  const handleGetCultures = async () => {
    try {
      // setIsLoading(true);
      const cultureResponse = await FockinkAPI.get('/cultivationAccount/', {
        params: {
          accountId: user.accountId,
        },
      });

      setCultures(
        cultureResponse.data.data.sort((a, b) => {
          if (a.culture.toLowerCase() < b.culture.toLowerCase()) {
            return -1;
          }

          if (a.culture.toLowerCase() > b.culture.toLowerCase()) {
            return 1;
          }

          return 0;
        }),
      );
      // setIsLoading(false);
    } catch (error) {
      // setIsLoading(false);
      // setHasError(true);
    }
  };

  React.useEffect(() => {
    handleGetCultures();
  }, []);

  const handleCloseModalForm = () => {
    setOpenNewCultureModalForm(false);
  };

  const handleCloseUpdateModalForm = () => {
    setOpenUpdateCultureModalForm(false);
  };

  const handleUpdateTelemetryModal = (culture) => {
    setOpenUpdateCultureModalForm(true);
    setSelectedCultureToEdit(culture);
  };

  const editButtonModal = {
    name: '',
    selector: 'edit',
    center: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <button type="button" style={{ color: '#2F80ED' }} onClick={() => handleUpdateTelemetryModal(row)}>
          <EditIcon size={18} />
        </button>
      </div>
    ),
  };

  return (
    <S.Wrapper>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />

      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginBottom: 20 }}>
        <Button
          color="secondary"
          icon={<BsPlus />}
          handle={() => setOpenNewCultureModalForm(true)}
        >
          Adicionar cultura
        </Button>
      </div>

      <CultureTable data={cultures} editButtonModal={editButtonModal} />
      <Modal
        open={openNewCultureModalForm}
        handleCloseModal={handleCloseModalForm}
        title="Cadastrar nova cultura"
      >
        <NewCultureForm handleGetCultures={handleGetCultures} />
      </Modal>

      <Modal
        open={openUpdateCultureModalForm}
        handleCloseModal={handleCloseUpdateModalForm}
        title={
          selectedCultureToEdit
            ? `Editar: ${selectedCultureToEdit.culture}`
            : ''
        }
      >
        <UpdateCultureForm
          culture={selectedCultureToEdit}
          handleGetCultures={handleGetCultures}
        />
      </Modal>

      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: 50 }}>
        <BackButton back={() => history.goBack()} />
      </div>
    </S.Wrapper>
  );
}
