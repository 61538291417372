import React from 'react';
import { IoMdAlert } from 'react-icons/all';

export const columns = [
  {
    name: '',
    selector: '',
    right: true,
    maxWidth: '5px',
    cell: (row) => (
      <span style={{ fontWeight: 'bold' }}>
        {row.customized ? <IoMdAlert size={20} /> : null}
      </span>
    ),
  },
  {
    name: 'Nome amigável',
    selector: 'friendlyName',
    wrap: true,
  },
  {
    name: 'Padrão da variável',
    selector: 'variablePattern',
  },
  {
    name: 'Sistema',
    selector: 'system',
  },
  {
    name: 'Min. Threshold',
    selector: 'thresholdMin',
    cell: (row) => (
      <span>
        {row.thresholdMin !== undefined ? `${row.thresholdMin}` : 'N/A'}
      </span>
    ),
  },
  {
    name: 'Max. Threshold',
    selector: 'thresholdMax',
    cell: (row) => (
      <span>
        {row.thresholdMax !== undefined ? `${row.thresholdMax}` : 'N/A'}
      </span>
    ),
  },
];
