import * as yup from 'yup';

export const validationSchema = yup.object().shape({
  telemetryVariablesId: yup.string().required('Obrigatório'),
  thresholdMin: yup
    .number()
    .integer(),
  thresholdMax: yup
    .number()
    .integer()
    .moreThan(yup.ref('thresholdMin'), 'Deve ser maior que o limite mínimo'),
});
