import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import MessageIcon from '@material-ui/icons/Message';
import Paper from '@material-ui/core/Paper';
import { toast } from 'react-toastify';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { Grid, IconButton, Tooltip,
  Card, CardContent, TextField } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { RiAttachment2 } from 'react-icons/ri';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@mui/material/Box';
import { HiDownload } from 'react-icons/hi';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formatDate } from '../../../../helpers/Intl';
import FockinkAPI from '../../../../middlewares/Api';
import { handleMessageError } from '../../../../helpers/handlerMessageError';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import BackButton from '../../../../components/Buttons/BackButton';
import {
  DropZoneContainer,
  Modal as ModalUpload,
  ButtonComponent as ButtonUpload,
} from '../../../../components';
import { ReadFile } from '../../../../helpers/callApi';

import * as S from './styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function Interactions(props) {
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const [msgs, setMsgs] = React.useState();
  const [data, setData] = React.useState();
  const [ticketId, setTicketId] = React.useState();
  const [dataAttendance, setDataAttendance] = React.useState();
  const [dataContact, setDataContact] = React.useState();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();
  const breadcrumb = {
    title: 'Dados do Chamado',
    routes: [
      { to: '/support', name: 'Suporte' },
      { name: 'Dados do Chamado' },
    ],
  };

  // const innerState = {
  //   disabled: props.fieldData.props.disabled,
  //   hidden: props.fieldData.props.hidden,
  // };

  const onDrop = React.useCallback((acceptedFiles) => {
    setSelectedFiles((preventState) => [...preventState, ...acceptedFiles]);
  }, []);

  const handleUploadFile = async ({ file }) => {
    try {
      const formData = new FormData();
      formData.append('uploadedFile', file);

      const config = {
        onUploadProgress: (progressEvent) => progressEvent.loaded,
      };

      const resp = await FockinkAPI.post(
        `/attendanceTecAssist/${props.match.params.id}/upload`,
        formData,
        config,
      );
      const copyState = [...selectedFiles];
      const newState = copyState.filter(
        (fileFromState) => fileFromState.path !== file.path,
      );
      setSelectedFiles(newState);

      if (resp.data.success) {
        toast.success('Anexo enviado');
        FockinkAPI.get(`/attendanceTecAssist/${props.match.params.id}`).then((response) => {
          setData(response.data.data.attendance.arquivos);
        });
      } else {
        toast.error('Falha no envio do Anexo');
      }
    } catch (error) {
      handleMessageError(error);
    }
  };

  React.useEffect(() => {
    setLoading(true);
    FockinkAPI.get(`/attendanceTecAssist/${props.match.params.id}`).then((response) => {
      setMsgs(response.data.data.interations);
      setData(response.data.data.attendance.arquivos);
      setTicketId(response.data.data.attendance.codigo);
      setDataAttendance(response.data.data.attendance);
      setDataContact(response.data.data.contact);
      setLoading(false);
    });
  }, []);
  if (loading) {
    return (
      <Skeleton variant="rect" height={500} width="100%" animation="wave" />
    );
  }

  async function downloadAttachment(name, extension) {
    const dt = {
      ticketId: ticketId.toString(),
      name,
      extension: extension.replace(/\./g, ''),
    };

    FockinkAPI.post('/attendanceTecAssist/download', dt, {
      responseType: 'arraybuffer',
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = name + extension;
        document.body.appendChild(link);
        link.click();
      })
      .catch(async (error) => {
        const { response } = error;

        if (response.status === 400) {
          toast.error(response.data.validation?.body.message || response.data.message);
        }
      });
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <div>
      <Breadcrumbs
        routes={breadcrumb.routes}
      />
      <Card>
        <CardContent>

          <S.Title>
            Dados do Chamado Nº
            {dataAttendance?.codigo}
          </S.Title>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>

            <TextField
              defaultValue={formatDate(moment.utc(dataAttendance?.dataSolicitacao).toDate())}
              style={{ margin: '10px 15px', width: '310px' }}
              label="Data da Solicitação"
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
            <TextField
              defaultValue={dataContact?.nome}
              style={{ margin: '10px 15px', width: '800px' }}
              label="Aberto por"
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
            <TextField
              defaultValue={dataAttendance?.status}
              style={{ margin: '10px 15px', width: '280px' }}
              label="Status"
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
            <TextField
              defaultValue={dataAttendance?.prioridade}
              style={{ margin: '10px 15px', width: '310px' }}
              label="Prioridade"
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
            <TextField
              defaultValue={dataAttendance?.assunto}
              label="Assunto"
              style={{ margin: '10px 15px', width: '100%' }}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />

            <TextField
              defaultValue={dataAttendance?.descricao}
              label="Descrição"
              multiline
              maxRows={5}
              minRows={5}
              style={{ margin: '10px 15px', width: '100%' }}
              InputProps={{
                readOnly: true,
              }}
              variant="outlined"
            />
            <Accordion style={{ marginTop: '10px', boxShadow: 'none', border: 'none' }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ boxShadow: 'none', border: 'none' }}
              >
                <Typography className={classes.heading}>Mais Detalhes</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ boxShadow: 'none', border: 'none' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={9}>

                      <TextField
                        defaultValue={dataAttendance?.clienteNome}
                        label="Nome do Cliente"
                        style={{ margin: '10px 5px' }}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <TextField
                        defaultValue={formatDate(moment.utc(dataAttendance?.dataInclusao).toDate())}
                        style={{ margin: '10px 5px' }}
                        label="Data da Inclusão"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={5}>
                      <TextField
                        defaultValue={dataAttendance?.cidade}
                        label="Cidade"
                        style={{ margin: '10px 5px' }}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} lg={5}>
                      <TextField
                        defaultValue={dataAttendance?.estado}
                        label="Estado"
                        style={{ margin: '10px 5px' }}
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} lg={2}>

                      <TextField
                        defaultValue={dataAttendance?.UF}
                        style={{ margin: '10px 5px' }}
                        label="UF"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={3}>
                      <TextField
                        defaultValue={dataContact?.nome}
                        style={{ margin: '10px 5px' }}
                        label="Aberto por"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                      <TextField
                        defaultValue={dataContact?.email}
                        style={{ margin: '10px 5px' }}
                        label="Email"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} lg={1}>
                      <TextField
                        defaultValue={dataAttendance?.telefoneDDD}
                        style={{ margin: '10px 5px' }}
                        label="DDD"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                      <TextField
                        defaultValue={dataContact?.telefone}
                        style={{ margin: '10px 5px' }}
                        label="Telefone"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} lg={1}>
                      <TextField
                        defaultValue={dataAttendance?.celularDDD}
                        style={{ margin: '10px 5px' }}
                        label="DDD"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} lg={2}>
                      <TextField
                        defaultValue={dataContact?.celular}
                        style={{ margin: '10px 5px' }}
                        label="Celular"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </CardContent>
      </Card>
      <Card style={{ marginTop: '10px' }}>
        <CardContent>
          <Box sx={{ width: '100%', typography: 'body1' }}>

            <Tabs
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
              value={value}
              onChange={handleChange}
            >
              <Tab label="Interação" {...a11yProps(0)} />
              <Tab label="Anexos" {...a11yProps(1)} />
            </Tabs>
          </Box>
          {value === 0 && (
          <Grid container>
            <Timeline style={{ marginTop: '20px' }}>
              {msgs ? msgs?.map((msg) => (

                <TimelineItem>
                  <TimelineOppositeContent style={{ maxWidth: 200 }}>
                    <Typography variant="body2" color="textSecondary">
                      {formatDate(moment.utc(msg.dtInclusao).toDate())}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="secondary">
                      <MessageIcon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography variant="h6" component="h1">
                        {msg.usuarioRemetente}
                      </Typography>
                      <Typography>{msg.descricao}</Typography>
                      {
                  msg?.arquivos.map((item) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>

                        <RiAttachment2 />
                        {item.NAME}
                        {item.EXTENSION}
                      </div>
                      <Tooltip title={item.NAME} arrow>
                        <IconButton
                          onClick={
                          () => {
                            downloadAttachment(item.NAME, item.EXTENSION);
                            // alert('Ops! parece que esta função ainda esta em desenvolvimento!');
                          }
                        }
                        >
                          <HiDownload />
                        </IconButton>
                      </Tooltip>
                    </div>
                  ))
                }
                    </Paper>
                  </TimelineContent>
                </TimelineItem>

              )) : (
                <p>
                  Não existem dados de interação para serem mostrados
                </p>
              )}
            </Timeline>
          </Grid>
          )}
          {value === 1 && (
          <>
            <Grid container spacing={2} style={{ paddingLeft: '20px', marginTop: '10px' }}>

              {/* <S.Title>Dados do Chamado: </S.Title> */}
              {data?.map((res) => (
                <Grid item xs={12} sm={3} md={3} lg={12}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div>

                      <RiAttachment2 />
                      {res.NAME}
                      {res.EXTENSION}
                    </div>
                    <Tooltip title={res.NAME} arrow>
                      <IconButton
                        onClick={
                          () => {
                            downloadAttachment(res.NAME, res.EXTENSION);
                            // alert('Ops! parece que esta função ainda esta em desenvolvimento!');
                          }
                        }
                      >
                        <HiDownload />
                      </IconButton>
                    </Tooltip>

                  </div>
                </Grid>
              ))}
              {/* <div style={{ background: 'green', width: '100%', display: 'flex', justifyContent: 'flex-end' }}> */}

              <S.ButtonDeleteDiv>
                <ButtonUpload
                  type="button"
                  handle={() => setOpenModal(true)}
                >
                  upload de anexos
                </ButtonUpload>
              </S.ButtonDeleteDiv>
              <ModalUpload
                title="Upload de anexos"
                open={openModal}
                handleCloseModal={handleCloseModal}
              >
                <S.Content>
                  <DropZoneContainer
                    confirmUpload={handleUploadFile}
                    alternativeLabel="Upload de anexos"
                    onDrop={onDrop}
                    files={selectedFiles}
                    noFilename
                    multiple={false}
                  />
                </S.Content>
              </ModalUpload>
              {/* </div> */}
            </Grid>
          </>
          )}
        </CardContent>
      </Card>

      <Grid item xs={12} style={{ margin: '25px 0', textAlign: 'right' }}>
        <BackButton redirectTo="/support" />
      </Grid>
    </div>
  );
}
