import React from 'react';
import { Grid, makeStyles, FormControl } from '@material-ui/core';
import { Ellipsis } from 'react-spinners-css';
import AddIcon from '@material-ui/icons/Add';
import CachedIcon from '@material-ui/icons/Cached';
import { ButtonComponent } from '../../../../../../components/Button';
import GridDatePicker from '../../../../../../components/GridDatePick';
// import { formatDate } from '../../../../../../helpers/Intl';
import { GridItem } from '../../../ThermometryReport/components/GridItem';
import { VariableLine } from './VariableLine';

const useStyles = makeStyles({
  root: {
    width: '100%',
    background: '#FFF',
    padding: '1rem',
    borderRadius: '0.2rem',
    minHeight: '5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export function HeaderPainel({
  search,
  setSearch,
  equipaments,
  variableTracking,
  runSearch,
}) {
  const classes = useStyles();

  const handleDateChange = (newDate, name) => {
    setSearch({
      ...search,
      [name]: newDate,
    });
  };

  const addVariable = () => {
    const reports = [...search.reports];
    reports.push({ rid: new Date().valueOf() });
    setSearch({ ...search, reports });
  };

  return (
    <div className={classes.root}>
      {equipaments.length > 0 ? (
        <Grid
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          container
          direction="row"
        >
          <GridItem>
            <FormControl>
              <GridDatePicker
                value={search.dateStart}
                label="Data Início"
                handleChangeDate={(date) => handleDateChange(date, 'dateStart')}
              />
            </FormControl>
          </GridItem>

          <GridItem>
            <FormControl>
              <GridDatePicker
                value={search.dateFinish}
                label="Data Fim"
                handleChangeDate={(date) =>
                  handleDateChange(date, 'dateFinish')
                }
              />
            </FormControl>
          </GridItem>

          <Grid item>
            <ButtonComponent handle={addVariable} icon={<AddIcon />}>
              Adicionar Variável
            </ButtonComponent>
          </Grid>

          {search.reports.map(
            (r) =>
              r.removed !== true && (
                <Grid item sm={12} key={r.rid}>
                  <VariableLine
                    telemetryVariableId={r.telemetryVariableId}
                    equipamentId={r.equipamentId}
                    color={r.color}
                    joinStrategy={r.joinStrategy}
                    equipaments={equipaments}
                    variableTracking={variableTracking}
                    search={search}
                    setSearch={setSearch}
                    report={r}
                  />
                </Grid>
              ),
          )}

          <Grid item sm={12} style={{ textAlign: 'right' }}>
            <ButtonComponent handle={runSearch} icon={<CachedIcon />}>
              Gerar Relatório
            </ButtonComponent>
          </Grid>
        </Grid>
      ) : (
        <Ellipsis color="#3D5059" />
      )}
    </div>
  );
}
