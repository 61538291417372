/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DataTable from 'react-data-table-component';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Hidden from '@material-ui/core/Hidden';
import HelpIcon from '@material-ui/icons/Help';
import jwtDecode from 'jwt-decode';
import { number, secondsToHm } from '../../../../helpers/Intl';

export default (props) => {
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const token = localStorage.getItem('@FockinkAPP:token');
  const { unit } = jwtDecode(token);

  const getColor = (ind) => {
    if (ind === '=') return '#ECE101';
    if (ind === '>') return '#FF0100';
    if (ind === '<') return '#008000';
    return 'purple';
  };

  const getIcon = (ind, color) => {
    if (ind === '=') {
      return (
        <ArrowRightIcon style={{ fontWeight: 'bold', color, fontSize: '35px' }} />
      );
    }
    if (ind === '>') {
      return (
        <ArrowDropUpIcon style={{ fontWeight: 'bold', color, fontSize: '35px' }} />
      );
    }
    if (ind === '<') {
      return (
        <ArrowDropDownIcon style={{ fontWeight: 'bold', color, fontSize: '35px' }} />
      );
    }
    return (
      <HelpIcon style={{ fontWeight: 'bold', color: color || 'purple', fontSize: '35px' }} />
    );
  };

  const getCollName = (range, rangeIndx, rangeLen) => {
    if (rangeIndx === 0) {
      return `Pontos < ${range.limitMax}°C`;
    } if (rangeIndx === rangeLen - 1) {
      return `Pontos > ${range.limitMin}°C`;
    } if (rangeIndx === rangeLen - 2) {
      return `Pontos de ${range.limitMin}°C a ${range.limitMax}°C`;
    }
    return `Pontos de ${range.limitMin}°C a ${range.limitMax - 1}°C`;
  };

  React.useEffect(() => {
    const columns = [
      { name: unit?.plural || 'Unidades',
        cell: (row) => (row.units || []).length > 0 ? row.units.map((m) => m.description).join(', ') : 'Não associado',
      },
      { name: 'Equipamento', cell: (row) => row.equipament.clientDescription || row.equipament.description },
      { name: 'Planta', selector: 'plant.description' },
      { name: 'Data', selector: 'date' },
      { name: 'Grão', selector: 'grain' },
    ];

    if (props.data && props.data[0]) {
      props.data[0].ranges.forEach((e, i) => {
        columns.push(...[
          {
            name: getCollName(e, i, props.data[0].ranges.length),
            center: true,
            cell: (row) => (
              <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
                {row[`btw${e.limitMin}&${e.limitMax}`]}
                {/* {getIcon(e.ind, e.color)} */}
                {getIcon(row[`ind${e.limitMin}&${e.limitMax}`], row[`clr${e.limitMin}&${e.limitMax}`])}
              </div>
            ),
          },
        ]);
      });
    }

    columns.push(...[
      {
        name: 'Total Pontos Quentes',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            {row.totalHotSensors}
            {getIcon(row.totalHotSensorsInd, getColor(row.totalHotSensorsInd))}
          </div>
        ),
      },
      { name: 'Total Pontos Armazenados', selector: 'totalSensors', center: true },
      {
        name: '% Pontos Quentes',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents">{`${number(row.percentHotSensors, 1)}%`}</div>
        ),
      },
      {
        // name: 'Horas de Aeração',
        name: 'Aeração das últimas 24h até a data selecionada',
        center: true,
        cell: (row) => secondsToHm(row.aerationDaySec),
      },
    ]);

    const data = props.data.map((e) => {
      const ranges = {};
      e.ranges.forEach((f) => {
        ranges[`btw${f.limitMin}&${f.limitMax}`] = f.qty;
        ranges[`ind${f.limitMin}&${f.limitMax}`] = f.ind;
        ranges[`clr${f.limitMin}&${f.limitMax}`] = f.color;
      });
      return {
        date: e.date,
        plant: e.plant,
        units: e.units,
        equipament: e.equipament,
        grain: e.grain,
        totalHotSensors: e.totalHotSensors,
        totalHotSensorsInd: e.totalHotSensorsInd,
        totalSensors: e.totalSensors,
        percentHotSensors: e.percentHotSensors,
        aerationDaySec: e.aerationDaySec,
        ...ranges,
      };
    });
    setDtCl({ columns, data });
  }, [props.data]);

  return (
    <Grid container>
      <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', textAlign: 'right' }}>
        <ArrowDropDownIcon style={{ fontWeight: 'bold', fontSize: '35px' }} />
        Diminuindo
        <ArrowRightIcon style={{ fontWeight: 'bold', fontSize: '35px' }} />
        Igual
        <ArrowDropUpIcon style={{ fontWeight: 'bold', fontSize: '35px' }} />
        Aumentando
        <Hidden only="xs">
          <small style={{ marginLeft: '5px', fontStyle: 'italic' }}>(sensores dentro da massa de grão em relação a leitura anterior)</small>
        </Hidden>
      </Grid>
      <Hidden smUp>
        <Grid item xs={12} style={{ display: 'flex' }}>
          <small style={{ marginLeft: '5px', fontStyle: 'italic' }}>(sensores dentro da massa de grão em relação a leitura anterior)</small>
        </Grid>
      </Hidden>
      <Grid item xs={12}>
        <DataTable
          pagination
          paginationPerPage={5}
          paginationComponentOptions={{ rowsPerPageText: 'Linhas:', rangeSeparatorText: 'de' }}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
          noDataComponent="Não existem dados para serem mostrados"
          columns={dtCl.columns}
          data={dtCl.data}
          title="Quantidade de Pontos"
        />
      </Grid>
    </Grid>
  );
};
