import styled from 'styled-components';

export const Container = styled.div`
 height: 100%;
 width: 140px;
 margin: 0 auto;
`;

export const OpenModalBtn = styled.button`
  position: absolute;
  left: -90px;
  height: 400px;
  top: 0;
  width: 250px;
  font-size: 0px;
  color: purple;
  @media (max-width: 800px) {
    height: 230px !important;
  }
`;
