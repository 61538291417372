import React from 'react';
import { RiAttachment2 } from 'react-icons/ri';
import { IconButton, Tooltip } from '@material-ui/core';
import { ReadFile } from '../helpers/callApi';

export default function Chat({
  type,
  idFeedback,
  date,
  time,
  user,
  comments,
  attachments,
}) {
  if (!type) {
    type = 0;
  }

  comments = comments.split('\n');

  const colorChat = {
    background: (!type) ? '#D6E0E6' : '#556E7B',
    color: (!type) ? '#556E7B' : '#D6E0E6',
    colorDate: '#556E7B',
  };

  function fileNameForButtonDownload(file) {
    file = file.replace(/\\/g, '/');
    const arq = file.substring(file.lastIndexOf('/') + 1);
    // const type = file.substring(file.lastIndexOf('.') + 1);
    return `${arq}`;
  }

  async function downloadFile(file) {
    const fr = (await fileRead(file));
    await Promise.resolve(fr).then((r) => {
      const download = window.document.createElement('a');
      download.href = r;
      // link.download = 'export.txt';
      download.click();
      window.URL.revokeObjectURL(download.href);
    });
  }

  async function fileRead(item) {
    const linkFile = await ReadFile({
      feedbackId: idFeedback,
      file: item,
    });
    return linkFile;
  }

  return (
    <>
      <br />
      <div
        style={{
          display: 'flex',
          justifyContent: (!type) ? 'Left' : 'right',
        }}
      >
        {/* <div
        style={{ display: 'flex', justifyContent: 'center', padding: 10 }}
      >
        <h4
          style={{ color: colorChat.colorDate }}
        >
          {date}
        </h4>
      </div> */}
        <div
          style={{
            background: colorChat.background,
            padding: 10,
            borderRadius: 10,
            width: '90%',
          // display: 'flex',
          // justifyContent: 'right',
          }}
        >
          <h3
            style={{ color: colorChat.color, wordWrap: 'break-word' }}
          >
            Usuário:
            {' '}
            {user}
          </h3>
          <h4
            style={{ paddingLeft: 0, color: colorChat.color, wordWrap: 'break-word' }}
          >
            Mensagem:
            {' '}
            {comments.map((item) => (
              <>
                <br />
                {item}
              </>
            ))}
          </h4>

          {(!type) &&
            (
            <>
              <div style={{ overflowX: 'auto' }}>
                {
                  attachments.map((item) => (
                    <Tooltip title={fileNameForButtonDownload(item)} arrow>
                      <IconButton
                        onClick={
                          () => {
                            downloadFile(item);
                          }
                        }
                      >
                        <RiAttachment2 />
                      </IconButton>
                    </Tooltip>
                  ))
                }
              </div>
            </>
            )
          }
          <h5
            style={{ display: 'flex', justifyContent: 'right', color: colorChat.color }}
          >
            {date}
            {' '}
            -
            {' '}
            {time}
          </h5>
        </div>
      </div>
    </>
  );
}
