import React from 'react';
import { useDashboardContext } from '../../../../hooks/dashboard';
import { MotorsSummaryChart } from './components/MotorSummaryChart';
import Card from '../../../Card';
import CardText from '../../../CardText';
import CardTitle from '../../../CardTitle';

export function MotorSummary() {
  const { dataMotorSummaryReport, plantIdToRequest } = useDashboardContext();

  return (
    <Card similar>
      <CardTitle pt="8px" pr="8px" pl="8px" pb="0" size="1rem">
        Resumo dos Motores
        {' '}
        {plantIdToRequest && '(Planta)'}

      </CardTitle>

      <CardText pt="4px" pr="4px" pl="4px" pb="10px" size="1rem">
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90%' }}>

          {dataMotorSummaryReport && (
          <MotorsSummaryChart data={dataMotorSummaryReport} />
          )}
        </div>

      </CardText>
    </Card>
  );
}
