const subGroups = (filter) => {
  const subGroupList = [
    {
      id: '8a7ad2a8-62d1-43ba-8ecb-8b81aa8deb15',
      subGroup: 'A1',
      description:
        'Consumidor de alta tensão, fornecimento em tensão igual ou superior a 230kV',
    },
    {
      id: 'cdbc4933-80e3-4a08-9bb0-2637d9e99f98',
      subGroup: 'A2',
      description:
        'Consumidor de alta tensão, fornecimento em tensão igual ou superior a 88kV e menor igual a 138kV',
    },
    {
      id: 'c9aee156-1118-47aa-9756-294f90a92bf5',
      subGroup: 'A3',
      description:
        'Consumidor de alta tensão, fornecimento em tensão igual a 69kV',
    },
    {
      id: 'a9fa18c2-33dd-4ade-8ac9-562f4f3567c1',
      subGroup: 'A3A',
      description:
        'Consumidor de alta tensão, fornecimento em tensão igual ou superior a 30kV e menor  igual a 44kV',
    },
    {
      id: 'c61b3ab7-0eba-4ca5-b3df-a0a275a1a787',
      subGroup: 'A4',
      description:
        'Consumidor de alta tensão, fornecimento em tensão igual ou superior a 2,3kV e menor  igual a 25kV',
    },
    {
      id: 'c7fca3c4-43f5-4376-b264-a8a525237293',
      subGroup: 'AS',
      description: 'Consumidor de alta tensão, sistema subterrâneo',
    },
    {
      id: 'd32d2665-aec3-4978-b452-9f83f1bf0548',
      subGroup: 'B1',
      description:
        'Consumidor de baixa tensão residencial e residencial baixa renda',
    },
    {
      id: '511149a3-7794-4df5-af23-73ee6e7c2df9',
      subGroup: 'B2',
      description:
        'Consumidor de baixa tensão, rural e cooperativa de eletrificação rural',
    },
    {
      id: '3aad8aaf-721e-4a5d-81c3-1d1a9a610a5f',
      subGroup: 'B3',
      description: 'Consumidor de baixa tensão, demais classes',
    },
    {
      id: '8d23d312-62c6-4e89-8958-5fedb4ad692d',
      subGroup: 'B4',
      description: 'Consumidor de baixa tensão,  iluminação pública',
    },
  ];

  if (filter && filter.id) {
    return subGroupList.find((item) => item.id === filter.id);
  }
  if (filter && filter.subGroup) {
    return subGroupList.find((item) => item.subGroup === filter.subGroup);
  }
  return subGroupList;
};

const taxTypes = (filter) => {
  const taxTypeList = [
    {
      id: 'bfb237a9-52a1-491f-a870-e432c1ed55ec',
      taxType: 'Tarifa Horária Azul',
      subGroups: ['A1', 'A2', 'A3', 'A3A', 'A4', 'AS'],
    },
    {
      id: '0d8749c5-7be8-4dbe-9e4e-3f0871757a94',
      taxType: 'Tarifa Horária Verde',
      subGroups: ['A1', 'A2', 'A3', 'A3A', 'A4', 'AS'],
    },
    {
      id: '027f6064-992d-4b45-a039-4675f4dbdb4c',
      taxType: 'Tarifa Horária Azul + Especial',
      subGroups: ['A1', 'A2', 'A3', 'A3A', 'A4', 'AS'],
    },
    {
      id: '2d965f53-0448-4fd2-9eb9-8ac8bc96f465',
      taxType: 'Tarifa Horária Verde + Especial',
      subGroups: ['A1', 'A2', 'A3', 'A3A', 'A4', 'AS'],
    },
    {
      id: '5bc39efb-1e7a-4e26-921a-0265963fae2c',
      taxType: 'Tarifa Monômia',
      subGroups: ['B1', 'B2', 'B3', 'B4'],
    },
    {
      id: '4b6f1c6e-5fad-4d1d-9d91-6f0e91ff6abf',
      taxType: 'Tarifa Social',
      subGroups: ['B1'],
    },
    {
      id: '819173da-7b8e-4c31-a0a6-3624d5ce5eb3',
      taxType: 'Tarifa Branca',
      subGroups: ['B1', 'B2', 'B3'],
    },
    {
      id: '5cfde98f-5d89-4c6e-9788-10dc0a804294',
      taxType: 'Tarifa Especial',
      subGroups: ['B2'],
    },
  ];

  if (filter && filter.id) {
    return taxTypeList.find((item) => item.id === filter.id);
  }
  if (filter && filter.subGroup) {
    return taxTypeList.filter((item) => item.subGroups.some(filter.subGroup));
  }
  if (filter && filter.subGroupId) {
    const sb = subGroups({ id: filter.subGroupId });
    return taxTypeList.filter((item) => item.subGroups.indexOf(sb.subGroup) !== -1);
  }
  return taxTypeList;
};

module.exports = {
  subGroups,
  taxTypes,
};
