import React from 'react';
import {
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Radio,
  FormControl,
  ListItemText,
  ListSubheader,
} from '@material-ui/core';
import { CirclePicker } from 'react-color';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { GridItem } from '../../../ThermometryReport/components/GridItem';

export function VariableLine({
  equipaments,
  variableTracking,
  search,
  setSearch,
  report,
}) {
  const [equipName, setEquipName] = React.useState();
  const [equipGroup, setEquipGroup] = React.useState({});
  const [vts, setVts] = React.useState([
    { id: 'ndf', name: 'Selecione um equipamento' },
  ]);
  const [vtName, setVtName] = React.useState();
  const [joinName, setJoinName] = React.useState();

  const joins = [
    { id: undefined, name: 'Nenhum' },
    { id: 'AVG', name: 'Média' },
    { id: 'SUM', name: 'Soma' },
    { id: 'LAST', name: 'Último' },
    { id: 'FIRST', name: 'Primeiro' },
  ];

  const details = {
    1: {
      icon: '/images/icons/pivotIcon.svg',
      name: 'Irrigação',
    },
    2: {
      icon: '/images/icons/thermometryIcon.svg',
      name: 'Termometria',
    },
    3: {
      icon: '/images/icons/automationIcon.svg',
      name: 'Automação',
    },
    4: {
      icon: '/images/icons/powerPlantIcon.svg',
      name: 'Fotovoltaica',
    },
    5: {
      icon: '/images/grainType/undefined.svg',
      name: 'Estação Meteorológica',
    },
  };

  React.useEffect(() => {
    const ret = {};
    equipaments.forEach((e) => {
      if (!ret[details[e.operationId].name]) {
        ret[details[e.operationId].name] = [];
      }
      ret[details[e.operationId].name].push(e);
    });
    setEquipGroup(ret);
  }, [equipaments]);

  React.useEffect(() => {
    const equip = equipaments.find((f) => f.id === report.equipamentId);
    if (equip && equip.operationId) {
      setEquipName(equip.clientDescription || equip.description);
      const system = details[equip.operationId].name;
      setVts(
        variableTracking.filter(
          (f) => f.system === system && f.trackingAvailable,
        ),
      );
    } else {
      setVts([{ id: 'ndf', friendlyName: 'Selecione um equipamento' }]);
    }
  }, [report.equipamentId]);

  React.useEffect(() => {
    const vt = variableTracking.find(
      (f) => f.id === report.telemetryVariableId,
    );
    if (vt) {
      setVtName(vt.friendlyName);
    }
  }, [report.telemetryVariableId]);

  React.useEffect(() => {
    const vt = joins.find((f) => f.id === report.joinStrategy);
    if (vt) {
      setJoinName(vt.name);
    }
  }, [report.joinStrategy]);

  const handleSelectPlant = (e) => {
    if (e.target.name === 'equipamentId') {
      report.telemetryVariableId = '';
      setVtName();
    }
    report[e.target.name] = e.target.value;
    setSearch({ ...search });
  };

  const handleChangeColor = (newColor) => {
    report.color = newColor.hex;
    setSearch({ ...search });
  };

  const handleRemove = () => {
    report.removed = true;
    setSearch({ ...search });
  };

  const buildEquipSelect = () => {
    const ret = [];

    Object.keys(equipGroup).forEach((group) => {
      ret.push(<ListSubheader>{group}</ListSubheader>);

      equipGroup[group].forEach((equip) => {
        ret.push(
          <MenuItem key={equip.id} value={equip.id}>
            <Radio checked={report.equipamentId === equip.id} />
            <img
              alt=""
              src={details[equip.operationId].icon}
              style={{ height: '20px', marginRight: '8px' }}
            />
            <ListItemText
              primary={`${equip.clientDescription || equip.description}`}
            />
          </MenuItem>,
        );
      });
    });

    return ret;
  };

  const buildTrackSelect = (empty) => (
    <FormControl variant="filled">
      <InputLabel>Tracking Variável</InputLabel>
      <Select
        value={report.telemetryVariableId}
        onChange={handleSelectPlant}
        name="telemetryVariableId"
        renderValue={() => (empty ? 'Selecione uma Variável' : vtName)}
      >
        {vts.map((vt) => (
          <MenuItem key={vt.id} value={vt.id}>
            <Radio checked={report.telemetryVariableId === vt.id} />
            <ListItemText primary={`${vt.friendlyName}`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <Grid
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      container
      direction="row"
      style={{
        marginBottom: 0,
        border: `1px solid ${report.color}`,
        borderLeft: `8px solid ${report.color}`,
      }}
    >
      <GridItem>
        <FormControl variant="filled">
          <InputLabel>Equipamento</InputLabel>
          <Select
            value={report.equipamentId}
            onChange={handleSelectPlant}
            name="equipamentId"
            renderValue={() => equipName}
          >
            {buildEquipSelect()}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem>{buildTrackSelect(!report.telemetryVariableId)}</GridItem>

      <GridItem>
        <CirclePicker
          onChangeComplete={handleChangeColor}
          color={report.color}
          width="200"
          colors={[
            '#f44336',
            '#e91e63',
            '#9c27b0',
            '#673ab7',
            '#3f51b5',
            '#2196f3',
            '#00bcd4',
            '#009688',
            '#4caf50',
            '#8bc34a',
            '#ffeb3b',
            '#ffc107',
            '#ff9800',
            '#ff5722',
            '#795548',
            '#607d8b',
          ]}
          circleSize={14}
          circleSpacing={10}
        />
      </GridItem>

      <GridItem>
        <FormControl variant="filled">
          <InputLabel>Acumulador</InputLabel>
          <Select
            value={report.joinStrategy}
            onChange={handleSelectPlant}
            name="joinStrategy"
            renderValue={() => joinName}
          >
            {joins.map((vt) => (
              <MenuItem key={vt.id} value={vt.id}>
                <Radio checked={report.joinStrategy === vt.id} />
                <ListItemText primary={`${vt.name}`} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </GridItem>

      <GridItem style={{ textAlign: 'end' }}>
        <IconButton onClick={handleRemove}>
          <DeleteIcon />
        </IconButton>
      </GridItem>
    </Grid>
  );
}
