import React from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { CardInformation } from '../CradInformation';
import { SwitchCard } from '../SwitchCard';
import * as S from './style';

export function EquipamentsIrrigationCard({ operationalEquipaments }) {
  return (
    <S.Wrapper>
      <S.CardEquipament size="medium">
        <div>
          <S.DivDates>
            <S.DateCard>
              <div>
                <strong>Início horário de ponta</strong>
                <span>{operationalEquipaments.tsStartHP}</span>
              </div>

              <AiOutlineClockCircle color="#cecece" size={28} />
            </S.DateCard>

            <S.DateCard>
              <div>
                <strong>Fim horário de ponta</strong>
                <span>{operationalEquipaments.tsEndHP}</span>
              </div>
              <AiOutlineClockCircle color="#cecece" size={28} />

            </S.DateCard>
          </S.DivDates>

          <S.DivInformations>
            <S.DivSwitch>
              {[{ title: 'Domingo', checked: !!(operationalEquipaments.sundayHP) },
                { title: 'Segunda-feira', checked: !!(operationalEquipaments.mondayHP) },
                { title: 'Terça-feira', checked: !!(operationalEquipaments.tuesdayHP) },
                { title: 'Quarta-feira', checked: !!(operationalEquipaments.wednesdayHP) },
                { title: 'Quinta-feira', checked: !!(operationalEquipaments.thursdayHP) },
                { title: 'Sexta-feira', checked: !!(operationalEquipaments.fridayHP) },
                { title: 'Sabado', checked: !!(operationalEquipaments.saturday) }].map((dataHp) => (
                  <SwitchCard key={dataHp.title} data={dataHp} />
              ))}
            </S.DivSwitch>

            <S.RowDiv>
              {[
                { title: 'Tempo para pressurização', data: operationalEquipaments.timePressurization, measureType: 's' },
                { title: 'Tempo para falha de pressão', data: operationalEquipaments.timePressureFail, measureType: 's' },
              ].map((sectionData) => (
                <CardInformation key={sectionData.title} data={sectionData} />
              ))}
            </S.RowDiv>
          </S.DivInformations>
        </div>
      </S.CardEquipament>
    </S.Wrapper>
  );
}
