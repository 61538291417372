import React from 'react';
import { CarouselComponent } from '../../../../Carousel';

import { MotorInfoCard } from '../MotorInfoCard';

export function SliderMotorInfo({ data }) {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    if (window) {
      const resize = () => {
        if (window.innerWidth) {
          setWidth(window.innerWidth);
        }
      };
      window.addEventListener('resize', resize);

      return () => {
        window.removeEventListener('resize', resize);
      };
    }
  }, [window, window.innerWidth]);

  const formatMotors = () => {
    // const motorsOn = data.filter((motor) => motor.motors_on > 0);
    const itemsMorots = data.map((m) => (
      <MotorInfoCard
        key={m.id}
        name={m.name}
        plantId={m.plantId}
        subarea={m.subarea}
        hours={m.hours_worked}
        percent={m.motors_on}
        existData={data && data.length > 1}
        failMotor={m.failMotor}
        haveAerationShared={m.haveAerationShared}
        equipamentId={m.equipamentId}
      />
    ));

    return itemsMorots;
  };
  formatMotors();

  return (
    <CarouselComponent
      infinite={data && width > 760 && data.length > 1}
      centerMode={data && width > 760 && data.length > 1}
      autoPlay={data && width > 760 && data.length > 1}
      className={
        (data && data.length > 1) || !data
          ? 'full-card-responsive'
          : 'full-card-responsive-width'
      }
    >
      {formatMotors()}
    </CarouselComponent>
  );
}
