const percent95 = {
  1: 12.706,
  2: 4.303,
  3: 3.182,
  4: 2.776,
  5: 2.571,
  6: 2.447,
  7: 2.365,
  8: 2.306,
  9: 2.262,
  10: 2.228,
  11: 2.201,
  12: 2.179,
  13: 2.160,
  14: 2.145,
  15: 2.131,
  16: 2.120,
  17: 2.110,
  18: 2.101,
  19: 2.093,
  20: 2.086,
  21: 2.080,
  22: 2.074,
  23: 2.069,
  24: 2.064,
  25: 2.060,
  26: 2.056,
  27: 2.052,
  28: 2.048,
  29: 2.045,
  30: 2.042,
  40: 2.041,
  60: 2.000,
  80: 1.990,
  100: 1.984,
  1000: 1.962,
  andMore: 1.960,
};

const find95Percent = (degreesOfFreedom) => {
  if (!degreesOfFreedom) return 0;
  if (degreesOfFreedom <= 30) return percent95[degreesOfFreedom];
  if (degreesOfFreedom <= 40) return percent95[40];
  if (degreesOfFreedom <= 60) return percent95[60];
  if (degreesOfFreedom <= 80) return percent95[80];
  if (degreesOfFreedom <= 100) return percent95[100];
  if (degreesOfFreedom <= 1000) return percent95[1000];

  return percent95.andMore;
};

export { find95Percent, percent95 };
