export function groupData(reportData) {
  const format = {};

  (reportData || []).forEach((e, i) => {
    const dt = e.sentDate.substring(0, 10);
    e.idx = i;
    if (!format[dt]) { format[dt] = {}; }

    if (format[dt][e.equipament.id]) {
      format[dt][e.equipament.id].detailedData.push(e);
      format[dt][e.equipament.id].finishDate = e.finishDate;
      format[dt][e.equipament.id].weatherFinish = e.weatherFinish;

      if (!format[dt][e.equipament.id].diffDateSecs[e.sub]) {
        format[dt][e.equipament.id].diffDateSecs[e.sub] = 0;
      }

      format[dt][e.equipament.id].enginesPerSub.total[e.sub] =
          { on: e.enginesHps.total.on, has: e.enginesHps.total.has };

      Object.keys(e.enginesHps.hps).forEach((hp) => {
        if (!format[dt][e.equipament.id].enginesPerSub[hp]) {
          format[dt][e.equipament.id].enginesPerSub[hp] = { [e.sub]: {} };
        }

        format[dt][e.equipament.id].enginesPerSub[hp][e.sub] =
          { on: e.enginesHps.hps[hp].on, has: e.enginesHps.hps[hp].has };
      });

      format[dt][e.equipament.id].diffDateSecs[e.sub] += new Date(e.finishDate).getTime() -
          new Date(e.sentDate).getTime();
    } else {
      format[dt][e.equipament.id] = {
        ...e,
        date: dt,
        detailedData: [e],
        enginesPerSub: {
          total: {
            [e.sub]: { on: e.enginesHps.total.on, has: e.enginesHps.total.has },
          },
        },
        diffDateSecs: {
          [e.sub]: new Date(e.finishDate).getTime() - new Date(e.sentDate).getTime(),
        },
      };

      Object.keys(e.enginesHps.hps).forEach((hp) => {
        if (!format[dt][e.equipament.id].enginesPerSub[hp]) {
          format[dt][e.equipament.id].enginesPerSub[hp] = { [e.sub]: {} };
        }

        format[dt][e.equipament.id].enginesPerSub[hp][e.sub] =
          { on: e.enginesHps.hps[hp].on, has: e.enginesHps.hps[hp].has };
      });
    }
  });
  const toGroup = [];
  Object.values(format).forEach((a) => toGroup.push(...Object.values(a)));
  toGroup.sort((a, b) => a.ts - b.ts);

  return toGroup;
}
