import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import EditIcon from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import { Redirect } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import FockinkAPI from '../../../../middlewares/Api';
import { formatDate } from '../../../../helpers/Intl';

const ReportSettings = () => {
  const [data, setData] = useState();
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);

  const breadcrumb = {
    title: 'Configuração de Relatórios',
    routes: [{ name: 'Configurações', to: '/settings' }, { name: 'Configuração de relatórios' }],
  };

  useEffect(() => {
    FockinkAPI.get('/reports/settings').then((response) => {
      setData(response.data.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const name = {
      qualityReport: 'Informe de Qualidade',
    };
    const columns = [
      {
        name: 'Relatório',
        selector: 'reportName',
        cell: (row) => name[row.reportName],
        sortable: true,
      },
      {
        name: 'Criado em',
        selector: 'insertDate',
        cell: (row) => formatDate(moment.utc(row.insertDate).toDate()),
        sortable: true,
        center: true,
      },
      {
        name: 'Atualizado em',
        selector: 'modifiedDate',
        cell: (row) => formatDate(moment.utc(row.modifiedDate).toDate()),
        sortable: true,
        center: true,
      },
      {
        name: 'Status',
        selector: 'active',
        cell: (row) => row.active ? 'Ativo' : 'Inativo',
        sortable: true,
      },
      {
        name: '',
        center: true,
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton color="secondary" onClick={() => setRedirect(row.reportName)}>
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];
    setDtCl({ columns, data });
  }, [data]);

  if (loading) {
    return (
      <>
        <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
        <Skeleton variant="rect" height={500} width="100%" animation="wave" />
      </>
    );
  }

  if (redirect) return <Redirect push to={`/settings/reportSettings/${redirect}`} />;

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <DataTable
        pagination
        paginationPerPage={15}
        paginationComponentOptions={{
          rowsPerPageText: 'Linhas:',
          rangeSeparatorText: 'de',
        }}
        paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
        noDataComponent="Não existem relatórios para configurar"
        columns={dtCl.columns}
        data={dtCl.data}
        title=""
      />
    </>
  );
};

export default ReportSettings;
