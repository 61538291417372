import React from 'react';
import {
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Checkbox,
  Grid,
  makeStyles,
  FormControl,
} from '@material-ui/core';
// import { Ellipsis } from 'react-spinners-css';
import UnitSelectSingleField from '../../../../../../components/Fields/UnitSelectSingleField';
import { ButtonComponent } from '../../../../../../components';
import { YearDatePicker } from '../../../../../../components/YearDatePicker';
import { GridItem } from '../../../ThermometryReport/components/GridItem';

const useStyles = makeStyles({
  root: {
    width: '100%',
    background: '#FFF',
    padding: '1rem',
    borderRadius: '0.2rem',
    minHeight: '5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export function HeaderPainel({
  selectedPlant,
  handleSelectPlant,
  handleDate,
  date,
  // equipaments,
  selectedEquipaments,
  handleSelectEquipaments,
  // handleSelectSubs,
  // selectedSubs,
  handleRequestAerationReport,
}) {
  const classes = useStyles();

  const equipamentLabelName = () => selectedEquipaments.indexOf('Todos') > -1 ? 'Todos' :
    `${selectedEquipaments.length} de ${selectedPlant.equipaments.length}`;

  // const formatEquipamentValueSubs = useMemo(() => {
  //   const equipamentsSubs = new Set();
  //   selectedEquipaments.forEach((element) => {
  //     element.subs.forEach((sub) => {
  //       equipamentsSubs.add(sub);
  //     });
  //   });
  //   return [...equipamentsSubs];
  // }, [selectedEquipaments]);

  return (
    <div className={classes.root}>
      <Grid
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        container
        direction="row"
      >
        <GridItem>
          <UnitSelectSingleField
            selectedPlant={selectedPlant}
            setSelectedPlant={(p) => {
              handleSelectPlant(p);
            }}
            systemFilter={2}
            label="Selecione uma Planta"
          />
        </GridItem>

        <GridItem>
          <FormControl variant="filled">
            <InputLabel>Selecione um Equipamento</InputLabel>
            <Select
              disabled={selectedPlant.equipaments.length === 0}
              multiple
              value={selectedEquipaments}
              onChange={handleSelectEquipaments}
              name="plantId"
              renderValue={() => equipamentLabelName()}
            >
              <MenuItem value="Todos">
                <Checkbox checked={selectedEquipaments.indexOf('Todos') > -1} />
                <ListItemText primary="Todos" />
              </MenuItem>
              {selectedPlant.equipaments.map((equipament) => (
                <MenuItem key={equipament.id} value={equipament.id}>
                  <Checkbox checked={selectedEquipaments.indexOf(equipament.id) > -1} />
                  <ListItemText primary={`${equipament.clientDescription || equipament.description}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>

        {/* <GridItem>
            <FormControl variant="filled">
              <InputLabel>Selecione uma Subdivisão</InputLabel>
              <Select
                disabled={selectedEquipaments.length === 0}
                multiple
                value={selectedSubs}
                onChange={handleSelectSubs}
                name="plantId"
                renderValue={() => `Subdivisões selecionados: ${selectedSubs.sort().join(' - ')}`}
              >
                {formatEquipamentValueSubs.map((sub) => (
                  <MenuItem key={sub} value={sub}>
                    <Checkbox checked={selectedSubs.indexOf(sub) > -1} />
                    <ListItemText primary={sub} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem> */}

        <GridItem>
          <FormControl>
            <YearDatePicker selectedDate={date} onChange={handleDate} />
          </FormControl>
        </GridItem>

        <Grid item>
          <ButtonComponent handle={handleRequestAerationReport}>Gerar Relatório</ButtonComponent>
        </Grid>
      </Grid>
    </div>
  );
}
