/* eslint-disable */
import { makeStyles } from '@material-ui/core';



export const useStyles = makeStyles((theme) => ({
  container: {
    margin: '50px auto',
    width: '100%',
    maxWidth: '1790px'
  },
  divOptions: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '0.4rem'
  }
}))
