import React from 'react';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { hasPermission } from '../../helpers/permission';

export default (props) => {
  const MySwal = withReactContent(Swal);
  const disabled = props.isDeleting || props.isDisabled;

  const text = () => {
    if (props.isDeleting) return 'Apagando';
    return 'Apagar';
  };

  const icon = () => {
    if (props.isDeleting) return <CircularProgress size={26} />;
    return <DeleteIcon />;
  };

  const confDelete = () => {
    MySwal.fire({
      title: 'Tem certeza?',
      text: 'Essa ação não poderá ser revertida',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, APAGAR!',
      cancelButtonText: 'Não, MANTER',
    }).then((result) => {
      if (result.isConfirmed) {
        props.delete();
      }
    });
  };

  const defaultButton = (
    <Button
      disabled={disabled}
      size="large"
      variant="contained"
      color="secondary"
      className={props.className}
      style={{ background: disabled ? '#CECECE' : '#AE2828', color: 'white', ...props.style }}
      onClick={() => confDelete()}
      startIcon={icon()}
    >
      {text()}
    </Button>
  );

  const notAllowButton = (
    <Button
      disabled
      size="large"
      variant="contained"
      color="secondary"
      className={props.className}
      style={{ background: '#CECECE', color: 'white', ...props.style }}
      startIcon={<NotInterestedIcon />}
    >
      Você não tem permissão para apagar
    </Button>
  );

  if (props.canSave) {
    if (hasPermission(props.canSave)) {
      return defaultButton;
    }
    return notAllowButton;
  }
  return defaultButton;
};
