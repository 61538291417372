/* eslint-disable no-underscore-dangle */
// Basic
import React from 'react';

// UI, images, styles and icons
import '../../../../../App.css';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { formatDate } from '../../../../../helpers/Intl';

const useStyles = makeStyles({
  title: {
    fontWeight: '300',
    fontSize: '12px',
    textAlign: 'center',
    margin: '10px 0 0',
  },
  value: {
    fontWeight: '400',
    fontSize: '15px',
    textAlign: 'center',
    margin: '0',
  },
  expand: {
    borderBottom: '1px solid #cecece',
    paddingBottom: '5px',
    margin: '0 auto',
    width: '96%',
  },
});

export default ({ data }) => {
  const classes = useStyles();

  const getBit = (bit) => (bit === 0 ? 'Desabilitado' : 'Habilitado');

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="stretch"
      spacing={1}
      className={classes.expand}
    >
      <Grid item xs={6} md={3} lg={2}>
        <h4 className={classes.title}> Horário de Ponta </h4>
        <h4 className={classes.value}>{getBit(data.sentData.recipeHP)}</h4>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <h4 className={classes.title}> Canhão </h4>
        <h4 className={classes.value}>{getBit(data.sentData.recipeCannon)}</h4>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <h4 className={classes.title}> Usuário </h4>
        <h4 className={classes.value}>{data.user.name}</h4>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <h4 className={classes.title}> Data de Envio </h4>
        <h4 className={classes.value}>
          {formatDate(moment.unix(data._ts).toDate())}
        </h4>
      </Grid>
      <Grid item xs={6} md={3} lg={2}>
        <h4 className={classes.title}> Data da Confirmação </h4>
        <h4 className={classes.value}>
          {data.completedAt ? formatDate(data.completedAt) : '-'}
        </h4>
      </Grid>
      {/* <Grid item xs={6} md={3} lg={3}>
        <h4 className={classes.title}> Status do Pivô </h4>
        <h4 className={classes.value}>{getBit(data.sentData.stOnOff)}</h4>
      </Grid> */}
      {/* <Grid item xs={6} md={3} lg={3}>
        <h4 className={classes.title}> Lâmina d&apos;água </h4>
        <h4 className={classes.value}>{getBit(data.sentData.recipeWater)}</h4>
      </Grid> */}
      {/* <Grid item xs={6} md={3} lg={3}>
        <h4 className={classes.title}> Lâmina d&apos;água (mm) </h4>
        <h4 className={classes.value}>{data.sentData.recipeWaterMm / 100}</h4>
      </Grid> */}
      {/* <Grid item xs={6} md={3} lg={3}>
        <h4 className={classes.title}> Loop </h4>
        <h4 className={classes.value}>{getBit(data.sentData.recipeLoop)}</h4>
      </Grid> */}
      {/* <Grid item xs={6} md={3} lg={3}>
        <h4 className={classes.title}> Direção </h4>
        <h4 className={classes.value}>
          {getDiretction(data.sentData.recipeDirectionRotation)}
        </h4>
      </Grid> */}
      {/* <Grid item xs={6} md={3} lg={3}>
        <h4 className={classes.title}> Posição de destino do Pivô </h4>
        <h4 className={classes.value}>
          {data.sentData.recipePosition === 360
            ? 'Sem Destino'
            : data.sentData.recipePosition}
        </h4>
      </Grid> */}
      {/* <Grid item xs={6} md={3} lg={3}>
        <h4 className={classes.title}> Programação do Pivô </h4>
        <h4 className={classes.value}>
          {data.sentData.recipeProgram === 0
            ? 'Automático'
            : data.sentData.recipeProgram}
        </h4>
      </Grid> */}

      {/* {data.sentData.recipeProgram !== 0 ? (
        <Grid item xs={6} md={3} lg={3}>
          <h4 className={classes.title}> recipeTsProgram </h4>
          <h4 className={classes.value}>
            {formatDate(moment.unix(data.sentData.recipeTsProgram).toDate())}
          </h4>
        </Grid>
      ) : (
        <></>
      )} */}

      {/* <Grid item xs={6} md={3} lg={3}>
        <h4 className={classes.title}> ID da Ação </h4>
        <h4 className={classes.value}>{data.sentData.actionId}</h4>
      </Grid> */}
    </Grid>
  );
};
