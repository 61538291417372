import { Tooltip } from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import { FiUpload } from 'react-icons/fi';
import { bytesToSize } from '../../../../helpers/bytesToSize';
import { Input } from '../../../Input';
import { validationSchema } from './validationSchema';
import * as S from './styles';

export function FileCard({ file, confirmUpload, noFilename }) {
  const formik = useFormik({
    initialValues: {
      fileName: '',
    },
    validationSchema: noFilename ? undefined : validationSchema,
    onSubmit: async (values) => {
      confirmUpload({ ...values, file });
    },
  });

  return (
    <S.Card>
      <S.Form onSubmit={formik.handleSubmit}>
        <S.Content>
          <strong>{file.name}</strong>
          <span>{bytesToSize(file.size)}</span>

          {!noFilename && (
            <Input
              name="fileName"
              placeholder="Defina um nome do arquivo"
              label="Nome de exibição"
              onChange={formik.handleChange}
              value={formik.values.fileName}
              error={formik.errors.fileName}
            />
          )}
        </S.Content>

        <Tooltip title="Fazer Upload">
          <button type="submit">
            <FiUpload size={22} />
          </button>
        </Tooltip>
      </S.Form>
    </S.Card>
  );
}
