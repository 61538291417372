import React from 'react';
import { AiOutlineCheckCircle } from 'react-icons/all';
import { Wrapper } from './styles';

export function CardOption({ children, onClick, checked, title }) {
  return (
    <Wrapper type="button" onClick={onClick} checked={checked}>
      {children}
      <span>{title}</span>
      {checked && <AiOutlineCheckCircle size={20} color="#2fbf26" />}
    </Wrapper>
  );
}
