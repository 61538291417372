/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { number } from '../../../../../helpers/Intl';

export default (props) => {
  const [currentStrategy, setCurrentStrategy] = React.useState(1);
  const [strategyData, setStrategyData] = React.useState({});

  React.useEffect(() => {
    const hasMotors = (
      props.data.subs.find((f) => f.sub === currentStrategy) || { motors: 0 }
    ).motors;
    setStrategyData({
      ...props.data.data.find((f) => f.sub === currentStrategy),
      hasMotors: !!hasMotors,
    });
  }, [currentStrategy]);

  const buildTextValue = (i) => (
    <Grid item xs={12} md={6} lg={6}>
      <h4 style={{
        width: '100%', fontWeight: '400', marginBottom: '0', textAlign: 'center',
      }}
      >
        {i.title}
      </h4>
      <h3 style={{
        width: '100%', fontWeight: '500', marginTop: '0', textAlign: 'center',
      }}
      >
        {i.content}
      </h3>
    </Grid>
  );

  const getByStrategy = () => {
    if (!strategyData.hasMotors) {
      return (
        <h3 style={{ width: '100%', textAlign: 'center', fontWeight: '500' }}>
          Não possui motores
        </h3>
      );
    }

    if (strategyData.strategy === 'Bloqueado') {
      return <h3 style={{ textAlign: 'center', fontWeight: '400' }}>Essa estratégia não contém parâmetros</h3>;
    }

    if (strategyData.strategy === 'Secagem') {
      return [
        { title: 'Umidade Inicial', content: `${number(strategyData.details.moistureIni, 1)}%` },
        { title: 'Umidade Objetiva', content: `${number(strategyData.details.moistureObjective, 1)}%` },
        { title: 'Umidade Atual', content: `${number(strategyData.details.moistureActual, 1)}%` },
        { title: 'Horas Mínimas', content: `${number(strategyData.details.hoursMin, 0)}h` },
        { title: 'Temperatura Máxima', content: `${number(strategyData.details.tempMaxGrain, 1)}°C` },
        { title: 'Temperatura Mínima', content: `${number(strategyData.details.tempMaxGrain - 6, 1)}°C` },
      ].map(buildTextValue);
    }

    if (strategyData.strategy === 'Intervalo') {
      return [
        { title: 'Temperatura Máxima', content: `${number(strategyData.details.tempMax, 1)}°C` },
        { title: 'Temperatura Mínima', content: `${number(strategyData.details.tempMin, 1)}°C` },
        { title: 'Umidade Máxima', content: `${number(strategyData.details.humidityMax, 1)}%` },
        { title: 'Umidade Mínima', content: `${number(strategyData.details.humidityMin, 1)}%` },
        { title: 'Umidade Atual', content: `${number(strategyData.details.moistureActual, 1)}%` },
      ].map(buildTextValue);
    }

    if (strategyData.strategy === 'Conservação') {
      return [
        { title: 'Temperatura Máxima', content: `${number(strategyData.details.tempMaxGrain, 1)}°C` },
        { title: 'Temperatura Mínima', content: `${number(strategyData.details.tempMaxGrain - 6, 1)}°C` },
        { title: 'Umidade Atual', content: `${number(strategyData.details.moistureActual, 1)}%` },
        { title: 'Horas Mínimas', content: `${number(strategyData.details.hoursMin, 0)}h` },
      ].map(buildTextValue);
    }

    return <></>;
  };

  return (
    <Grid container spacing={3}>

      <Grid item xs={12} md={12} lg={4}>
        <Card variant="outlined" style={{ padding: '10px' }}>
          <h3 style={{ marginTop: '5px', textAlign: 'center', fontWeight: '400' }}>Tipo de Grão</h3>
          <FormControl variant="filled">
            <InputLabel>Subdivisão</InputLabel>
            <Select
              value={currentStrategy}
              onChange={(value) => {
                setCurrentStrategy(value.target.value);
              }}
            >
              {props.data.subs.map((v) => (
                <MenuItem
                  key={v.sub}
                  value={v.sub}
                >
                  {`#${v.sub} - ${v.grain}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <hr style={{ border: 'none', borderTop: '1px solid #0000001f' }} />
          <h3 style={{ marginTop: '5px', textAlign: 'center', fontWeight: '400' }}>Observações</h3>
          <h4 style={{ marginTop: '5px', textAlign: 'center', fontWeight: '400' }}>{strategyData.note || 'Sem observações'}</h4>
        </Card>
      </Grid>

      <Grid item xs={12} md={12} lg={8}>
        <Card variant="outlined">
          <Grid container spacing={1} style={{ padding: '0 15px' }}>
            <Grid item xs={12}>
              <h3 style={{ marginTop: '5px', textAlign: 'center', fontWeight: '400' }}>Estratégia</h3>
            </Grid>

            <Grid item xs={4} md={4} lg={2}>
              <h4 style={{ fontWeight: strategyData.hasMotors && strategyData.strategy === 'Bloqueado' ? '600' : '300', marginBottom: '0' }}>Bloqueado</h4>
              {/* <h5 style={{ fontWeight: '400', marginTop: '0' }}>xxxx:xx</h5> */}

              <h4 style={{ fontWeight: strategyData.hasMotors && strategyData.strategy === 'Secagem' ? '600' : '300', marginBottom: '0' }}>Secagem</h4>
              {strategyData.hasMotors && <h5 style={{ fontWeight: '400', marginTop: '0' }}>{strategyData.hoursDrying}</h5>}

              <h4 style={{ fontWeight: strategyData.hasMotors && strategyData.strategy === 'Conservação' ? '600' : '300', marginBottom: '0' }}>Conservação</h4>
              {strategyData.hasMotors && <h5 style={{ fontWeight: '400', marginTop: '0' }}>{strategyData.hoursConservation}</h5>}

              <h4 style={{ fontWeight: strategyData.hasMotors && strategyData.strategy === 'Intervalo' ? '600' : '300', marginBottom: '0' }}>Intervalo</h4>
              {strategyData.hasMotors && <h5 style={{ fontWeight: '400', marginTop: '0' }}>{strategyData.hoursInterval}</h5>}
            </Grid>

            <Grid item xs={8} md={8} lg={10}>
              <Card variant="outlined">
                <Grid container style={{ padding: '0 15px' }}>
                  <Grid item xs={12}>
                    <h3 style={{ marginTop: '5px', textAlign: 'center', fontWeight: '400' }}>Parâmetros</h3>
                  </Grid>
                  {getByStrategy()}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Card>
      </Grid>

    </Grid>
  );
};
