import React from 'react';
import '../../../../../App.css';
import { Redirect } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import FockinkAPI from '../../../../../middlewares/Api';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import BackButton from '../../../../../components/Buttons/BackButton';
// import { formatDate } from '../../../../../helpers/Intl';

export default (props) => {
  const [inputs, setInputs] = React.useState([]);
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);

  const status = (s) => {
    switch (s) {
      case 'A':
        return 'Ativo';
      case 'I':
        return 'Inativo';
      case 'C':
        return 'Deletado';
      default:
        return `Status ${s}`;
    }
  };

  const breadcrumb = {
    title: 'Consulta de Usuários',
    routes: [{ name: 'Usuários' }],
  };

  React.useEffect(() => {
    setLoading(true);
    FockinkAPI.get('/users', { params: { accountId: props.match.params.accId } }).then((response) => {
      setInputs(response.data.data);
      setLoading(false);
    });
  }, []);

  React.useEffect(() => {
    const columns = [
      { name: 'Nome', selector: 'name', center: true },
      { name: 'Email', selector: 'login', center: true },
      {
        name: 'Perfil',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            {row.profile.description}
          </div>
        ),
      },
      {
        name: 'Status',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            {status(row.status)}
          </div>
        ),
      },
      {
        name: '',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton color="secondary" onClick={() => setRedirect(row.id)}>
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];
    setDtCl({ columns, data: inputs });
  }, [inputs]);

  if (redirect) return <Redirect push to={`/organization/${props.match.params.orgId}/account/${props.match.params.accId}/user/${redirect}`} />;

  if (loading) {
    return <Skeleton variant="rect" height={500} width="100%" animation="wave" />;
  }

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} newBtn="Novo Usuário" />
      <Grid container spacing={1} style={{ minHeight: 'calc(100vh - 101px)' }}>
        <Grid item xs={12} style={{ paddingTop: '15px' }}>
          <DataTable
            pagination
            paginationPerPage={5}
            paginationComponentOptions={{ rowsPerPageText: 'Linhas:', rangeSeparatorText: 'de' }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
            noDataComponent="Não existem dados para serem mostrados"
            columns={dtCl.columns}
            data={dtCl.data}
            title=""
          />
        </Grid>
        <Grid item xs={12} style={{ margin: '25px 0', textAlign: 'right' }}>
          <BackButton redirectTo={`/organization/${props.match.params.orgId}`} />
        </Grid>
      </Grid>
    </>
  );
};
