import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

export default (props) => (
  <Grid item xs={12} sm={12} md={8} lg={9} xl={10} style={{ visibility: props.visibility || 'visible' }}>
    <TextField
      variant="outlined"
      label="Observações:"
      defaultValue={props.selectedStrategy.note || ''}
      multiline
      InputProps={{
        readOnly: true,
      }}
    />
  </Grid>
);
