import { useFormik } from 'formik';
import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import { toast } from 'react-toastify';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import * as S from './styles';
import { Input, ButtonComponent as Button } from '../../../../../../components';
import { systemTypesNumeric } from '../../utils';
// import { validationSchema } from './validationSchema';
import FockinkAPI from '../../../../../../middlewares/Api';
import { handleMessageError } from '../../../../../../helpers/handlerMessageError';

export function NewApproverForm({ handleGetTelemetry, handleCloseNewModal, updateData }) {
  const [newEmail, setNewEmail] = React.useState('');
  const initialValues = {
    groupName: '',
    emails: [],
    systems: [],
    status: 'A',
  };
  if (updateData) {
    initialValues.groupName = updateData.groupName;
    initialValues.emails = updateData.emails;
    initialValues.systems = updateData.systems;
    initialValues.status = updateData.status;
  }

  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit: async (values) => {
      try {
        if (updateData) {
          await FockinkAPI.put(`/techDeliveryApprovers/${updateData.id}`, values);
        } else {
          await FockinkAPI.post('/techDeliveryApprovers/', values);
        }

        handleGetTelemetry();
        formik.resetForm();
        handleCloseNewModal();
        toast.success(`Aprovador ${updateData ? 'Atualizado' : 'Criado'}`);
      } catch (error) {
        handleMessageError(error);
      }
    },
  });

  const handleEmailChange = (e) => {
    setNewEmail(e.target.value);
  };

  const handleAddEmailChange = () => {
    if (formik.values.emails.indexOf(newEmail) === -1) {
      formik.setValues({
        ...formik.values,
        emails: [...formik.values.emails, newEmail],
      });
    }
    setNewEmail('');
  };

  const handleDeleteEmail = (e) => {
    formik.setValues({
      ...formik.values,
      emails: [...formik.values.emails.filter((f) => f !== e)],
    });
  };
  function randomName() {
    return `form_number_${Math.random() * 100000000}`;
  }

  return (
    <S.Wrapper>
      <form autoComplete="off" id={randomName()} onSubmit={formik.handleSubmit}>
        <input style={{ display: 'none' }} autoComplete={false} />
        <Input
          autoComplete="off"
          id={randomName()}
          error={formik.errors.groupName}
          name="groupName"
          label="Nome do Grupo"
          onChange={formik.handleChange}
          variant="filled"
          value={formik.values.groupName}
          type="text"
        />

        <div className="row">
          <FormControl variant="filled">
            <InputLabel>Sistemas</InputLabel>
            <Select
              name="systems"
              multiple
              value={formik.values.systems}
              onChange={formik.handleChange}
              renderValue={(selected) =>
                selected.map((s) => systemTypesNumeric[s - 1].text).join(', ')
              }
              label="systems"
            >
              {systemTypesNumeric.map(({ id, text }) => (
                <MenuItem key={id} value={id}>
                  <Checkbox checked={formik.values.systems.indexOf(id) > -1} />
                  <ListItemText primary={text} />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{formik.errors.systems}</FormHelperText>
          </FormControl>
        </div>

        <div className="row">
          <Input
            autoComplete="off"
            name="emails"
            label="Adicione um email"
            onChange={handleEmailChange}
            id={randomName()}
            variant="filled"
            value={newEmail}
            type="text"
          />

          <Button type="button" handle={handleAddEmailChange}>
            +
          </Button>
        </div>

        <div className="rowChip">
          {formik.values.emails.map((e) => (
            <Chip label={e} key={e} color="primary" onDelete={() => handleDeleteEmail(e)} />
          ))}
        </div>

        <div className="confirm-button">
          <Button type="submit">{updateData ? 'Atualizar' : 'Criar'}</Button>
        </div>
      </form>
    </S.Wrapper>
  );
}
