import React from 'react';
import {
  makeStyles,
  Step,
  Stepper,
  StepConnector,
  withStyles,
  StepButton,
} from '@material-ui/core/';
import clsx from 'clsx';
import { Check } from '@material-ui/icons';

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#a9a9ad',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#2fbf26',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#2fbf26',
    zIndex: 1,
    fontSize: 18,
  },
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  completed: {
    '& $line': {
      borderColor: '#2fbf26',
    },
  },
  line: {
    borderColor: '#a9a9ad',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

function QontoStepIcon({ active, completed }) {
  const classes = useQontoStepIconStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

export function StepForm({ steps, setpLevel, setLevelStep, falseNonLinear }) {
  const handleStep = (index) => {
    setLevelStep(index);
  };

  return (
    <Stepper
      style={{ padding: '0', background: 'transparent' }}
      alternativeLabel
      nonLinear={falseNonLinear}
      activeStep={setpLevel}
      connector={<QontoConnector />}
    >
      {steps.map((label, index) => {
        const stepProps = {};
        const buttonProps = {};

        return (
          <Step key={label} {...stepProps}>
            <StepButton
              onClick={() => handleStep(index)}
              StepIconComponent={QontoStepIcon}
              {...buttonProps}
            >
              {label}
            </StepButton>
          </Step>
        );
      })}
    </Stepper>
  );
}
