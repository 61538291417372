/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import '../../../../../App.css';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
// import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { Container } from './styles';
// import { number } from '../../../../../helpers/Intl';
import FockinkAPI from '../../../../../middlewares/Api';
// import PieGraph from '../../../../../components/PieGraph';
import Card from '../../../../../components/Card';
import CardTitle from '../../../../../components/CardTitle';
import CardText from '../../../../../components/CardText';
import CardActions from '../../../../../components/CardActions';
import Spacer from '../../../../../components/Spacer';

// const useStyles = makeStyles((theme) => ({
//   square: {
//     display: 'inline-flex',
//     color: '#FFFFFF',
//     width: 'auto',
//     height: 'auto',
//     padding: '3px 10px',
//   },
//   subTitle: {
//     margin: '0',
//     fontSize: '14px',
//     fontWeight: '500',
//   },
// }));

function formatDataLabel(v) {
  return `${v}`;
}

export default (props) => {
  // const theme = useTheme();
  // const lg = useMediaQuery(theme.breakpoints.down('lg'));
  // const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  // const [state, setState] = React.useState(undefined);
  const [series, setSeries] = React.useState([]);
  const options = {
    responsive: [
      {
        breakpoint: 1919,
        options: {
          chart: {
            height: 'auto',
            toolbar: {
              show: false,
            },
            type: 'donut',
          },
          legend: {
            float: true,
            show: false,
          },
          dataLabels: {
            enabled: false,
            formater: formatDataLabel,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: false,
                  },
                  value: {
                    show: true,
                  },
                  total: {
                    show: true,
                    alwaysShow: true,
                    fontSize: '8px',
                    fontFamily: 'Roboto',
                    fontWeight: 800,
                  },
                },
              },
              hollow: {
                size: '40%',
              },
            },
          },
          labels: ['Ligados', 'Desligados'],
          colors: ['#22AA22', '#FF5555'],
        },
      },
      {
        breakpoint: 1279,
        options: {
          chart: {
            height: 'auto',
            toolbar: {
              show: false,
            },
            type: 'donut',
          },
          legend: {
            float: true,
            show: false,
          },
          dataLabels: {
            enabled: false,
            formater: formatDataLabel,
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    show: false,
                  },
                  value: {
                    show: true,
                  },
                  total: {
                    show: true,
                    alwaysShow: true,
                    fontSize: '8px',
                    fontFamily: 'Roboto',
                    fontWeight: 800,
                  },
                },
              },
              hollow: {
                size: '40%',
              },
            },
          },
          labels: ['Ligados', 'Desligados'],
          colors: ['#22AA22', '#FF5555'],
        },
      },
    ],
    chart: {
      height: 'auto',
      toolbar: {
        show: false,
      },
      type: 'donut',
    },
    legend: {
      float: true,
      show: false,
    },
    dataLabels: {
      enabled: false,
      formater: formatDataLabel,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
            },
            total: {
              show: true,
              alwaysShow: true,
              fontSize: '8px',
              fontFamily: 'Roboto',
              fontWeight: 800,
            },
          },
        },
        hollow: {
          size: '40%',
        },
      },
    },
    labels: ['Ligados', 'Desligados'],
    colors: ['#22AA22', '#FF5555'],
  };
  // const classes = useStyles();

  const callApi = () => {
    setLoading(true);
    FockinkAPI.get(
      `/dashboards/thermometry/${props.equipamentId}/motors?sub=${props.selectedStrategy?.sub || 'all'}`,
    ).then((response) => {
      setData(response.data.data.motors);
      const turnedOn = response.data.data.motors.filter(
        (f) => [3, 4, 7, 8, 9].indexOf(f.st) > -1,
      ).length;
      const turnedOff = response.data.data.motors.filter(
        (f) => [1, 2, 5, 6, 10].indexOf(f.st) > -1,
      ).length;
      // const turnedOff = response.data.data.motors.length - turnedOn;
      setSeries([turnedOn, turnedOff]);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    // TODO: check motors and check if is "all"
    // if (props.selectedStrategy?.motors > 0) {
    callApi();
    const interval = setInterval(() => {
      callApi();
    }, 60000);
    return () => clearInterval(interval);
    // }
  }, [props.selectedStrategy?.sub]);

  if (props.selectedStrategy?.motors !== 0 && (props.loading || loading)) {
    return (
      <Skeleton variant="rect" height={200} width="100%" animation="wave" />
    );
  }

  return (
    <Card similar>
      <CardTitle size="1.17em" pb="4px">
        Aeração
      </CardTitle>
      <CardText pt="0">
        <Grid container spacing={1} justifyContent="space-evenly">
          <Grid
            item
            xs={12}
            lg={!props.selectedStrategy?.sub ? 12 : 6}
            style={{ height: !props.selectedStrategy?.sub && 157.19 }}
          >
            <Card outlined similar>
              <CardTitle pb="2px" pt="2px" pl="8px" pr="8px" size="0.75rem">
                Motores
                <Spacer />
                {data.length}
              </CardTitle>
              <CardText pb="0px" pt="0px">
                {props.selectedStrategy?.motors === 0 ? (
                  <h2 style={{ textAlign: 'center', width: '100%' }}>
                    Não possui motores
                  </h2>
                ) : (
                  <Container>
                    <ReactApexChart
                      options={options}
                      series={series}
                      type="donut"
                    />
                  </Container>
                )}
              </CardText>
              {props.selectedStrategy?.motors && (
                <CardActions pt="0px" pb="4px" size="0.75rem">
                  <Spacer />
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FiberManualRecordIcon style={{ color: '#22AA22' }} />
                    <span> Ligados</span>
                  </div>
                  <Spacer />
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FiberManualRecordIcon style={{ color: '#FF5555' }} />
                    <span> Desligados</span>
                  </div>
                  <Spacer />
                </CardActions>
              )}
            </Card>
          </Grid>
          {props.selectedStrategy && (
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Card outlined similar>
                <CardTitle pb="2px" pt="2px" pl="8px" pr="8px" size="0.75rem">
                  Funcionamento
                </CardTitle>
                <CardText>
                  <div style={{ padding: '0 0 6px' }}>
                    <TextField
                      variant="outlined"
                      label="Estratégia"
                      size="small"
                      value={props.selectedStrategy?.strategy}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                  <div style={{ padding: '6px 0 0' }}>
                    <TextField
                      variant="outlined"
                      label="Horas Trabalhadas"
                      size="small"
                      value={props.selectedStrategy?.hoursAeration}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </div>
                </CardText>
              </Card>
            </Grid>
          )}
        </Grid>
      </CardText>
    </Card>
  );
};
