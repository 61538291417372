import React from 'react';
import DataTable from 'react-data-table-component';
import {
  Tooltip,
} from '@material-ui/core';
import { formatDate, number, secondsToHm } from '../../../../../../helpers/Intl';
import { normalizeErrorData } from '../../../../../../helpers/normalizeErrorData';

export default function ExpandedRow({ data, motorsKeys }) {
  const { detailedData: reportData } = data;
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });

  const formatMotors = (motor) => motor && motor.has ? (
    <spam>
      <Tooltip title="Ligados">
        <b>{motor.on}</b>
      </Tooltip>
      <Tooltip title="Contém">
        <small>
          /
          {motor.has}
        </small>
      </Tooltip>
    </spam>
  ) : '-/-';

  React.useEffect(() => {
    const columns = [
      { name: 'Seq.', cell: (row, index) => index + 1 },
      { name: 'Produto', selector: 'grain', compact: true },
      { name: 'Equipamento', selector: 'equipament.description', compact: true },
      { name: 'Sub', selector: 'sub', compact: true },
      { name: 'Data', selector: 'date', cell: (row) => formatDate(row.sentDate, 'dd/MM/yyyy'), compact: true },
      { name: 'Início', selector: 'sentDate', cell: (row) => formatDate(row.sentDate, "HH':'mm"), compact: true },
      { name: 'Fim', selector: 'finishDate', cell: (row) => formatDate(row.finishDate, "HH':'mm"), compact: true },
      { name: 'Horas Aeradas', selector: 'diffDate', cell: (row) => secondsToHm((new Date(row.finishDate).getTime() - new Date(row.sentDate).getTime()) / 1000).replace('h', ':').replace('m', ''), compact: true },
      { name: 'Temp. Início', selector: 'weatherStart.weatherTemp', cell: (row) => normalizeErrorData(row.weatherStart.weatherTemp, '°C'), compact: true },
      { name: 'Temp. Fim', selector: 'weatherFinish.weatherTemp', cell: (row) => normalizeErrorData(row.weatherFinish.weatherTemp, '°C'), compact: true },
      { name: 'Umid. Início', selector: 'weatherStart.weatherHumid', cell: (row) => normalizeErrorData(row.weatherStart.weatherHumid, '%'), compact: true },
      { name: 'Umid. Fim', selector: 'weatherFinish.weatherHumid', cell: (row) => normalizeErrorData(row.weatherFinish.weatherHumid, '%'), compact: true },
      { name: 'Nº Mot', cell: (row) => formatMotors(row.enginesHps.total), compact: true },
      // { name: 'Nº Mot. Ligados', selector: 'enginesHps.total.on', compact: true },
      ...motorsKeys.map((motors) => (
        // { name: `Mot. ${motors}cv On`, selector: `enginesHps.hps.${motors}.on`, compact: true }
        { name: `Mot. ${motors}cv`, cell: (row) => formatMotors(row.enginesHps.hps[motors]), compact: true }
      )),
      { name: 'Total', selector: 'enginesHps.total.on', cell: (row) => getTotalPercentageMotorsActive(row.enginesHps.total.on, row.enginesHps.total.has), compact: true },
    ];

    setDtCl({ columns, data: reportData });
  }, [reportData]);

  const getTotalPercentageMotorsActive = (count, base) => {
    const percentage = number((count / base) * 100, 2, 2);
    return `${percentage}%`;
  };

  return (
    <div style={{ paddingLeft: '15px' }}>
      <div style={{ border: '2px solid #cecece', borderTop: 'none' }}>
        <DataTable
          noHeader
          pagination
          paginationPerPage={5}
          paginationComponentOptions={{
            rowsPerPageText: 'Linhas:',
            rangeSeparatorText: 'de',
          }}
          customStyles={{
            headRow: {
              style: {
                backgroundColor: '#cecece',
              },
            },
            rows: {
              style: {
                backgroundColor: '#eaeaea',
              },
            },
            pagination: {
              style: {
                backgroundColor: '#e0dede',
              },
            },
          }}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
          noDataComponent="Não existem dados para serem mostrados"
          columns={dtCl.columns}
          data={dtCl.data}
        />
      </div>
    </div>
  );
}
