export function startDispositionsSensorsY(siloType, cableQtd, cableIndex) {
  /* ***********************************************************************
      Esta função referencia em pixels onde irá iniciar a
      disposição dos sensores dentro do termovisor no eixo Y
    *********************************************************************** */
  const startDistribuition = [];
  startDistribuition.pop();

  if (!siloType) { // caso não tenha sido passado o tipo de fundo
    siloType = 'plainSilo'; // seta um tipo de fundo padrão
  }

  if (siloType === 'plainSilo') {
    return -270;
  }

  if (siloType === 'semiVSilo') {
    switch (cableQtd) {
      case 1:
        startDistribuition.push(95);
        break;
      case 2:
        startDistribuition.push(95, 25);
        break;
      case 3:
        startDistribuition.push(-280, -305, -280);
        break;
      case 4:
        startDistribuition.push(25, 25, 25, 25);
        break;
      case 5:
        startDistribuition.push(25, 25, 25, 25, 25);
        break;
      case 6:
        startDistribuition.push(113, 107, 101, 102, 107, 113.5);
        // Esse daqui por exemplo pode ser testado com o Bunker QA
        break;
      default:
        return 0;
    }
  }

  if (siloType === 'VSilo') {
    switch (cableQtd) {
      case 1:
        startDistribuition.push(95);
        break;
      case 2:
        startDistribuition.push(95, 25);
        break;
      case 3:
        startDistribuition.push(25, 25, 25);
        break;
      case 4:
        startDistribuition.push(25, 25, 25, 25);
        break;
      case 5:
        startDistribuition.push(-250, -300, -345, -300, -250);
        break;
      case 6:
        startDistribuition.push(75, 35.5, 0, 0, 35.5, 75);
        // Esse daqui por exemplo pode ser testado com o Bunker QA
        break;
      case 7:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25);
        break;
      case 8:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 9:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 10:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 11:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 12:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 13:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 14:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 15:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      default:
        return 0;
    }
  }

  if (siloType === 'elevatedSilo') {
    switch (cableQtd) {
      case 1:
        startDistribuition.push(-335);
        break;
      case 2:
        startDistribuition.push(95, 25);
        break;
      case 3:
        startDistribuition.push(25, 25, 25);
        break;
      case 4:
        startDistribuition.push(25, 25, 25, 25);
        break;
      case 5:
        startDistribuition.push(25, 25, 25, 25, 25);
        break;
      case 6:
        startDistribuition.push(113, 107, 101, 102, 107, 113.5);
        // Esse daqui por exemplo pode ser testado com o Bunker QA
        break;
      default:
        return 0;
    }
  }

  if (siloType === 'WSilo') {
    switch (cableQtd) {
      case 1:
        startDistribuition.push(95);
        break;
      case 2:
        startDistribuition.push(95, 25);
        break;
      case 3:
        startDistribuition.push(-290, -220, -290);
        break;
      case 4:
        startDistribuition.push(25, 25, 25, 25);
        break;
      case 5:
        startDistribuition.push(25, 25, 25, 25, 25);
        break;
      case 6:
        startDistribuition.push(113, 107, 101, 102, 107, 113.5);
        // Esse daqui por exemplo pode ser testado com o Bunker QA
        break;
      default:
        return 0;
    }
  }

  return (startDistribuition[cableIndex]);
}

export function calcTempPositionY(siloType, index, sensorsInCableQtd, cableQtd, cableIndex) {
  /* ***********************************************************************
      Esta função referencia em pixels o espaço útil para efetuar a
      distribuição dos sensores no eixo Y e retorna a posição dentro deste
      espaço que o mesmo irá ser posicionado
    *********************************************************************** */
  // console.log('calcTempPositionY - cableQtd: ', cableQtd);
  let cableSpacing;

  if (!siloType) { // caso não tenha sido passado o tipo de fundo
    siloType = 'plainSilo'; // seta um tipo de fundo padrão
  }

  if ((siloType === 'plainSilo')) {
    cableSpacing = {
      1: [550],
      2: [550, 650],
      3: [550, 650, 550],
      4: [550, 515, 515, 170],
      5: [550, 605, 650, 605, 550],
      6: [520, 580, 620, 615, 556, 520],
      7: [550, 590, 620, 670, 640, 590, 550],
      8: [550, 515, 560, 560, 560, 560, 515, 550],
      9: [550, 515, 560, 560, 560, 560, 560, 515, 550],
      10: [550, 515, 560, 560, 560, 560, 560, 560, 515, 550],
      11: [550, 515, 560, 560, 560, 560, 560, 560, 560, 515, 550],
      12: [550, 515, 560, 560, 560, 560, 560, 560, 560, 560, 515, 550],
      13: [550, 515, 560, 560, 560, 560, 560, 560, 560, 560, 560, 515, 550],
      14: [550, 515, 560, 560, 560, 560, 560, 560, 560, 560, 560, 560, 515, 550],
      15: [550, 515, 560, 560, 560, 560, 560, 560, 560, 560, 560, 560, 560, 515, 550],

    };
  }

  if ((siloType === 'semiVSilo')) {
    cableSpacing = {
      1: [550],
      2: [550, 650],
      3: [590, 690, 590],
      4: [170, 215, 215, 170],
      5: [170, 215, 260, 215, 170],
      6: [170, 215, 260, 240, 215, 170],
      7: [170, 215, 260, 260, 260, 215, 170],
      8: [170, 215, 260, 260, 260, 260, 215, 170],
      9: [170, 215, 260, 260, 260, 260, 260, 215, 170],
      10: [170, 215, 260, 260, 260, 260, 260, 260, 215, 170],
      11: [170, 215, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      12: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      13: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      14: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      15: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],

    };
  }

  if (siloType === 'VSilo') {
    cableSpacing = {
      1: [550],
      2: [550, 650],
      3: [370, 215, 215],
      4: [370, 215, 215, 170],
      5: [530, 630, 730, 630, 530],
      6: [150, 260, 358, 350, 250, 150], // Esse daqui por exemplo pode ser testado com o Bunker QA
      7: [170, 215, 260, 260, 260, 215, 170],
      8: [170, 215, 260, 260, 260, 260, 215, 170],
      9: [170, 215, 260, 260, 260, 260, 260, 215, 170],
      10: [170, 215, 260, 260, 260, 260, 260, 260, 215, 170],
      11: [170, 215, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      12: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      13: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      14: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      15: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
    };
  }

  if (siloType === 'elevatedSilo') {
    cableSpacing = {
      1: [780],
      2: [550, 650],
      3: [370, 215, 215],
      4: [370, 215, 215, 170],
      5: [270, 215, 260, 215, 170],
      6: [150, 260, 358, 350, 250, 150], // Esse daqui por exemplo pode ser testado com o Bunker QA
      7: [170, 215, 260, 260, 260, 215, 170],
      8: [170, 215, 260, 260, 260, 260, 215, 170],
      9: [170, 215, 260, 260, 260, 260, 260, 215, 170],
      10: [170, 215, 260, 260, 260, 260, 260, 260, 215, 170],
      11: [170, 215, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      12: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      13: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      14: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      15: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
    };
  }

  if (siloType === 'WSilo') {
    cableSpacing = {
      1: [550],
      2: [550, 650],
      3: [605, 590, 605],
      4: [370, 215, 215, 170],
      5: [270, 215, 260, 215, 170],
      6: [150, 260, 358, 350, 250, 150], // Esse daqui por exemplo pode ser testado com o Bunker QA
      7: [170, 215, 260, 260, 260, 215, 170],
      8: [170, 215, 260, 260, 260, 260, 215, 170],
      9: [170, 215, 260, 260, 260, 260, 260, 215, 170],
      10: [170, 215, 260, 260, 260, 260, 260, 260, 215, 170],
      11: [170, 215, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      12: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      13: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      14: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      15: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
    };
  }

  const spacing = cableSpacing[cableQtd].map((t) => 380 - t);

  return (
    (cableSpacing[cableQtd][cableIndex] / sensorsInCableQtd) *
        (sensorsInCableQtd - index) +
      spacing[cableIndex]
  );
}

export function heatmapPositionY(siloType, index, sensorsInCableQtd, cableQtd, cableIndex) {
  /* ***********************************************************************
    Esta função referencia em pixels o distânciamento entre focos de calor
    para o eixo Y
  *********************************************************************** */
  // console.log('calcTempPositionY - cableQtd: ', cableQtd);
  let cableSpacing;

  if (!siloType) { // caso não tenha sido passado o tipo de fundo
    siloType = 'plainSilo'; // seta um tipo de fundo padrão
  }

  if ((siloType === 'plainSilo')) {
    cableSpacing = {
      1: [550],
      2: [550, 650],
      3: [170, 200, 170],
      4: [170, 215, 215, 170],
      5: [163, 178, 194, 178, 163],
      6: [160.5, 180, 190, 190, 172, 160],
      7: [164, 175, 185, 199, 190, 175, 162],
      8: [170, 215, 260, 260, 260, 260, 215, 170],
      9: [170, 215, 260, 260, 260, 260, 260, 215, 170],
      10: [170, 215, 260, 260, 260, 260, 260, 260, 215, 170],
      11: [170, 215, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      12: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      13: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      14: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      15: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],

    };
  }

  if ((siloType === 'semiVSilo')) {
    cableSpacing = {
      1: [550],
      2: [550, 650],
      3: [170, 198, 170],
      4: [170, 215, 215, 170],
      5: [170, 215, 260, 215, 170],
      6: [170, 215, 260, 240, 215, 170],
      7: [170, 215, 260, 260, 260, 215, 170],
      8: [170, 215, 260, 260, 260, 260, 215, 170],
      9: [170, 215, 260, 260, 260, 260, 260, 215, 170],
      10: [170, 215, 260, 260, 260, 260, 260, 260, 215, 170],
      11: [170, 215, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      12: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      13: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      14: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      15: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],

    };
  }

  if (siloType === 'VSilo') {
    cableSpacing = {
      1: [550],
      2: [550, 650],
      3: [370, 215, 215],
      4: [370, 215, 215, 170],
      5: [144, 170, 198, 170, 144],
      6: [46, 80, 110, 106, 77, 43.5], // Esse daqui por exemplo pode ser testado com o Bunker QA
      7: [170, 215, 260, 260, 260, 215, 170],
      8: [170, 215, 260, 260, 260, 260, 215, 170],
      9: [170, 215, 260, 260, 260, 260, 260, 215, 170],
      10: [170, 215, 260, 260, 260, 260, 260, 260, 215, 170],
      11: [170, 215, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      12: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      13: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      14: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      15: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
    };
  }

  if (siloType === 'elevatedSilo') {
    cableSpacing = {
      1: [610],
      2: [550, 650],
      3: [370, 215, 215],
      4: [370, 215, 215, 170],
      5: [270, 215, 260, 215, 170],
      6: [46, 80, 110, 106, 77, 43.5], // Esse daqui por exemplo pode ser testado com o Bunker QA
      7: [170, 215, 260, 260, 260, 215, 170],
      8: [170, 215, 260, 260, 260, 260, 215, 170],
      9: [170, 215, 260, 260, 260, 260, 260, 215, 170],
      10: [170, 215, 260, 260, 260, 260, 260, 260, 215, 170],
      11: [170, 215, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      12: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      13: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      14: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      15: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
    };
  }

  if (siloType === 'WSilo') {
    cableSpacing = {
      1: [550],
      2: [550, 650],
      3: [160, 153, 160],
      4: [370, 215, 215, 170],
      5: [270, 215, 260, 215, 170],
      6: [46, 80, 110, 106, 77, 43.5], // Esse daqui por exemplo pode ser testado com o Bunker QA
      7: [170, 215, 260, 260, 260, 215, 170],
      8: [170, 215, 260, 260, 260, 260, 215, 170],
      9: [170, 215, 260, 260, 260, 260, 260, 215, 170],
      10: [170, 215, 260, 260, 260, 260, 260, 260, 215, 170],
      11: [170, 215, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      12: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      13: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      14: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      15: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
    };
  }
  // console.log('=============================================================');
  // console.log('cableSpacing ->', cableSpacing);
  // console.log('cableQtd ->', cableQtd);
  const spacing = cableSpacing[cableQtd].map((t) => 380 - t);

  return (
    (cableSpacing[cableQtd][cableIndex] / sensorsInCableQtd) *
          (sensorsInCableQtd - index) +
        spacing[cableIndex]
  );
}

export function startDispositionsHeatMapY(siloType, cableQtd, cableIndex) {
  /* ***********************************************************************
    Esta função referencia em pixels onde irá iniciar a
    disposição do heatmap no eixo Y
  *********************************************************************** */
  const startDistribuition = [];
  startDistribuition.pop();

  if (!siloType) { // caso não tenha sido passado o tipo de fundo
    siloType = 'plainSilo'; // seta um tipo de fundo padrão
  }

  if (siloType === 'plainSilo') {
    return 94;
  }

  if (siloType === 'semiVSilo') {
    switch (cableQtd) {
      case 1:
        startDistribuition.push(95);
        break;
      case 2:
        startDistribuition.push(95, 25);
        break;
      case 3:
        startDistribuition.push(96, 92, 96);
        break;
      case 4:
        startDistribuition.push(25, 25, 25, 25);
        break;
      case 5:
        startDistribuition.push(25, 25, 25, 25, 25);
        break;
      case 6:
        startDistribuition.push(113, 107, 101, 102, 107, 113.5);
        // Esse daqui por exemplo pode ser testado com o Bunker QA
        break;
      default:
        return 0;
    }
  }

  if (siloType === 'VSilo') {
    switch (cableQtd) {
      case 1:
        startDistribuition.push(95);
        break;
      case 2:
        startDistribuition.push(95, 25);
        break;
      case 3:
        startDistribuition.push(25, 25, 25);
        break;
      case 4:
        startDistribuition.push(25, 25, 25, 25);
        break;
      case 5:
        startDistribuition.push(105, 98, 92, 98, 105);
        break;
      case 6:
        startDistribuition.push(113, 107, 101, 102, 107, 113.5);
        // Esse daqui por exemplo pode ser testado com o Bunker QA
        break;
      case 7:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25);
        break;
      case 8:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 9:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 10:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 11:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 12:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 13:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 14:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 15:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      default:
        return 0;
    }
  }

  if (siloType === 'elevatedSilo') {
    switch (cableQtd) {
      case 1:
        startDistribuition.push(105);
        break;
      case 2:
        startDistribuition.push(95, 25);
        break;
      case 3:
        startDistribuition.push(25, 25, 25);
        break;
      case 4:
        startDistribuition.push(25, 25, 25, 25);
        break;
      case 5:
        startDistribuition.push(25, 25, 25, 25, 25);
        break;
      case 6:
        startDistribuition.push(113, 107, 101, 102, 107, 113.5);
        // Esse daqui por exemplo pode ser testado com o Bunker QA
        break;
      default:
        return 0;
    }
  }

  if (siloType === 'WSilo') {
    switch (cableQtd) {
      case 1:
        startDistribuition.push(95);
        break;
      case 2:
        startDistribuition.push(95, 25);
        break;
      case 3:
        startDistribuition.push(102, 112, 102);
        break;
      case 4:
        startDistribuition.push(25, 25, 25, 25);
        break;
      case 5:
        startDistribuition.push(25, 25, 25, 25, 25);
        break;
      case 6:
        startDistribuition.push(113, 107, 101, 102, 107, 113.5);
        // Esse daqui por exemplo pode ser testado com o Bunker QA
        break;
      default:
        return 0;
    }
  }
  return (startDistribuition[cableIndex]);
}
