import * as BABYLON from 'babylonjs'
import * as GUI from 'babylonjs-gui'
import settings from './img/settings.svg'
import left from './img/left.svg'
import right from './img/right.svg'
import up from './img/up.svg'
import level from './img/level.svg'
import temperatureOff from './img/temperature-off.svg'
import fullScreen from './img/full-screen.svg'
import calendar from './img/calendar.svg'
import clock from './img/clock.svg'
import water from './img/water.svg'
import chuvasim from './img/chuvasim.svg'
import chuvanao from './img/chuvanao.svg'
import grain from './img/grain.svg'
import thermostat from './img/thermostat.svg'
import line from './img/line.svg'
import cevada from './img/grainType/cevada.svg'
import info from './img/info.svg'
import reset from './img/reset.svg'
import manualLeft from './img/manual-left.svg'
import manualRight from './img/manual-right.svg'
import stairs from './img/stairs.svg'
import { shade } from 'polished'
import moment from 'moment'

import verifyTemperature from '../../helpers/verifyTemperature';


// CriaMenu - Responsável por criar o menu
// eslint-disable-next-line
const CriaMenu = () => {
  const buttonOpcoes = document.createElement('button')
  buttonOpcoes.style.top = '50px'
  buttonOpcoes.style.right = '30px'
  buttonOpcoes.textContent = 'click'
  buttonOpcoes.style.width = '100px'
  buttonOpcoes.style.height = '100px'
  buttonOpcoes.setAttribute = ('id', 'but')
  buttonOpcoes.style.position = 'absolete'
  buttonOpcoes.style.color = 'black'
  const canvas = document.getElementById('renderCanvas')
  canvas.appendChild(buttonOpcoes)

  buttonOpcoes.addEventListener('click', () => {
    alert('X JAPAN!!!! ')
  })
}

const CriaCeu = (scene, type) => {
  if (type === 0) {
    // const ceu = BABYLON.Mesh.CreateBox('ceu', 1000, scene);
    const ceu = BABYLON.Mesh.CreateBox('ceu', 1000, scene)
    const materialCeu = new BABYLON.StandardMaterial('materialCeu', scene)
    materialCeu.backFaceCulling = false
    materialCeu.reflectionTexture = new BABYLON.CubeTexture(
      '/images/3DVisualization/assets/texturas/skybox2/skybox',
      scene,
      ['_px.jpg', '_py.jpg', '_pz.jpg', '_nx.jpg', '_ny.jpg', '_nz.jpg']
    )
    materialCeu.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE
    materialCeu.disableLighting = true
    ceu.material = materialCeu
    ceu.position.y = +10
    ceu.isPickable = false
  } else {
    // const ceu = BABYLON.Mesh.CreateBox('ceu', 1000, scene);
    const ceu = BABYLON.Mesh.CreateBox('ceu', 1000, scene)
    const materialCeu = new BABYLON.StandardMaterial('materialCeu', scene)
    materialCeu.backFaceCulling = false
    materialCeu.reflectionTexture = new BABYLON.CubeTexture(
      '/images/3DVisualization/texturas/skybox2/type1/skybox',
      scene,
      ['_px.jpg', '_py.jpg', '_pz.jpg', '_nx.jpg', '_ny.jpg', '_nz.jpg']
    )
    materialCeu.reflectionTexture.coordinatesMode = BABYLON.Texture.SKYBOX_MODE
    materialCeu.disableLighting = true
    ceu.material = materialCeu
    ceu.position.y = +10
    ceu.isPickable = false
  }
}

const CriaLuzAmbiente = (scene, luminosidade) => {
  const luz = new BABYLON.HemisphericLight(
    'luz',
    new BABYLON.Vector3(0, 1, 0),
    scene
  )
  luz.groundColor = new BABYLON.Color3.Gray()
  luz.specular = new BABYLON.Color3.Black()
  luz.intensity = luminosidade
}

const LabelInfo = station => {
  const myGUI = GUI.AdvancedDynamicTexture.CreateFullscreenUI('interfaceInfo')
  const infoPanel = new GUI.StackPanel()
  infoPanel.width = '460px'
  infoPanel.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT
  infoPanel.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  infoPanel.isVertical = true
  myGUI.addControl(infoPanel)

  const painelInfoHeader = new GUI.StackPanel()
  painelInfoHeader.width = '460px'
  painelInfoHeader.height = '35px'
  painelInfoHeader.background = '#d0d0d0'
  painelInfoHeader.isVertical = false
  painelInfoHeader.cornerRadius = 5
  painelInfoHeader.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT
  painelInfoHeader.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER
  infoPanel.addControl(painelInfoHeader)

  const txtLabelDate = new GUI.TextBlock()
  txtLabelDate.text = 'Data: '
  txtLabelDate.fontStyle = 'bold'
  txtLabelDate.fontSize = 13
  txtLabelDate.width = '40px'
  txtLabelDate.paddingLeft = '5px'
  txtLabelDate.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  txtLabelDate.color = '#333'
  painelInfoHeader.addControl(txtLabelDate)

  const txtDate = new GUI.TextBlock()
  //txtDate.text = station.date;
  txtDate.fontSize = 13
  txtDate.width = '110px'
  txtDate.paddingLeft = '5px'
  txtDate.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  txtDate.color = '#333'
  painelInfoHeader.addControl(txtDate)

  const txtLabelTemperature = new GUI.TextBlock()
  txtLabelTemperature.text = 'Temp. Ambiente: '
  txtLabelTemperature.fontSize = 13
  txtLabelTemperature.fontStyle = 'bold'
  txtLabelTemperature.width = '110px'
  txtLabelTemperature.textHorizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  txtLabelTemperature.color = '#333'
  painelInfoHeader.addControl(txtLabelTemperature)

  const txtTemperature = new GUI.TextBlock()
  txtTemperature.fontSize = 13
  txtTemperature.width = '40px'
  txtTemperature.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  txtTemperature.color = '#333'
  painelInfoHeader.addControl(txtTemperature)

  const txtLabelHumidity = new GUI.TextBlock()
  txtLabelHumidity.text = 'Umid. Ambiente: '
  txtLabelHumidity.fontStyle = 'bold'
  txtLabelHumidity.fontSize = 13
  txtLabelHumidity.width = '110px'
  txtLabelHumidity.paddingLeft = '2px'
  txtLabelHumidity.textHorizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  txtLabelHumidity.color = '#333'
  painelInfoHeader.addControl(txtLabelHumidity)

  const txtHumidity = new GUI.TextBlock()
  //txtHumidity.text = `${station.humidity}%`;
  txtHumidity.fontSize = 13
  txtHumidity.width = '40px'
  txtHumidity.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  txtHumidity.color = '#333'
  painelInfoHeader.addControl(txtHumidity)

  // const txtLabelRain = new GUI.TextBlock();
  // txtLabelRain.text = 'Chuva: ';
  // txtLabelRain.fontSize = 13;
  // txtLabelRain.fontStyle = 'bold';
  // txtLabelRain.width = '50px';
  // txtLabelRain.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
  // txtLabelRain.color = '#333';
  // painelInfoHeader.addControl(txtLabelRain);
  //
  // const txtRain = new GUI.TextBlock();
  // txtRain.text = (station.rain ? 'Sim' : 'Não');
  // txtRain.fontSize = 13;
  // txtRain.width = '30px';
  // txtRain.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT;
  // txtRain.color = '#333';
  // painelInfoHeader.addControl(txtRain);
}

const CriaPainelTooltip = engine => {
  // Painel de opções
  const myToolTipGuy =
    GUI.AdvancedDynamicTexture.CreateFullscreenUI('panelTooltip')

  const painel = new GUI.StackPanel()
  painel.width = '20px'
  painel.background = '#ff0000'
  painel.isHorizontal = true
  painel.isVertical = true
  painel.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER
  painel.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_CENTER

  myToolTipGuy.addControl(painel)
}

const CriaPaineisCameras = (
  tipo,
  engine,
  camera,
  cameraReset,
  changeInfoProduct,
  changeViewProduct,
  scene,
  valorPadraoParaCameras,
  x,
  y,
  z,
  infoAboutUnity,
  layout,
  changeInfoStaircase,
  unitInfo,
  subareaSelected,
  diameter
) => {
  // FORMATA A DATA PARA O CARD INFO NO MENU 3D
  const notPolitedate = layout.ts
  const dateAndHours = notPolitedate.split('T')

  let listOfGrainBySubAreas = layout.dataGrain
  let readingGrain = ''
  let multiReadingGrain = ''

  if (subareaSelected === 0) {
    for (let i = 0; i < listOfGrainBySubAreas.length; i++) {
      if (i === listOfGrainBySubAreas.length - 1) {
        multiReadingGrain =
          multiReadingGrain + `${listOfGrainBySubAreas[i].nameGrain}`
      } else {
        multiReadingGrain =
          multiReadingGrain + `${listOfGrainBySubAreas[i].nameGrain}\n`
      }
    }
  } else {
    readingGrain = listOfGrainBySubAreas[subareaSelected - 1]
  }

  // DFEFINE A TEMPERATURA E UMIDADE

  const temperature = layout.weather.weatherTemp
  const humidity = layout.weather.weatherHumid
  console.log("here go again", temperature === 999)

  // console.log('temperature', typeof temperature)
  // console.log('humidity', typeof humidity)

  let numeroTotalDeArcosDoArmazem = layout.arcs.length

  let maxCable = 0
  layout.arcs.map(arc => {
    if (maxCable < arc.cables.length) {
      maxCable = arc.cables.length
    }
  })

  let larguraTotalDoArmazem = maxCable * 10

  let innerX = x
  let innerY = y
  let innerZ = z
  let orientationZ = 1
  let orientationX = 0

  let clickCountSilosAndBunkers = 0
  let clickCountTulha = 0
  let clickCountArmazemVAndPlan = 0
  let clickCountArmazemSemiV = 0

  let viewsPersonalizatedSilos = [
    new BABYLON.Vector3(
      -(diameter + diameter / 1.5),
      valorPadraoParaCameras.position.y,
      0
    ),
    new BABYLON.Vector3(
      -(diameter + diameter / 1.5),
      valorPadraoParaCameras.position.y,
      -15.5
    ),
    new BABYLON.Vector3(
      -15.5,
      valorPadraoParaCameras.position.y,
      -(diameter + diameter / 1.5)
    ),
    new BABYLON.Vector3(
      0,
      valorPadraoParaCameras.position.y,
      -(diameter + diameter / 1.5)
    ),
    new BABYLON.Vector3(
      diameter + diameter / 1.5,
      valorPadraoParaCameras.position.y,
      0
    ),
    new BABYLON.Vector3(
      diameter + diameter / 1.5,
      valorPadraoParaCameras.position.y,
      15.5
    ),
    new BABYLON.Vector3(
      15.5,
      valorPadraoParaCameras.position.y,
      diameter + diameter / 1.5
    ),
    new BABYLON.Vector3(
      0,
      valorPadraoParaCameras.position.y,
      diameter + diameter / 1.5
    )
  ]

  let viewsPersonalizatedBunkers = [
    new BABYLON.Vector3(
      -(diameter + diameter / 3.5),
      valorPadraoParaCameras.position.y - 10,
      0
    ),
    new BABYLON.Vector3(
      -(diameter + diameter / 3.5),
      valorPadraoParaCameras.position.y - 10,
      -15.5
    ),
    new BABYLON.Vector3(
      -15.5,
      valorPadraoParaCameras.position.y - 10,
      -(diameter + diameter / 3.5)
    ),
    new BABYLON.Vector3(
      0,
      valorPadraoParaCameras.position.y - 10,
      -(diameter + diameter / 3.5)
    ),
    new BABYLON.Vector3(
      diameter + diameter / 3.5,
      valorPadraoParaCameras.position.y - 10,
      0
    ),
    new BABYLON.Vector3(
      diameter + diameter / 3.5,
      valorPadraoParaCameras.position.y - 10,
      15.5
    ),
    new BABYLON.Vector3(
      15.5,
      valorPadraoParaCameras.position.y - 10,
      diameter + diameter / 3.5
    ),
    new BABYLON.Vector3(
      0,
      valorPadraoParaCameras.position.y - 10,
      diameter + diameter / 3.5
    )
  ]

  let viewsPersonalizatedTulha = [
    new BABYLON.Vector3(-27.5, valorPadraoParaCameras.position.y, 0),
    new BABYLON.Vector3(-25, valorPadraoParaCameras.position.y, -15.5),
    new BABYLON.Vector3(-15.5, valorPadraoParaCameras.position.y, -25),
    new BABYLON.Vector3(0, valorPadraoParaCameras.position.y, -27.5),
    new BABYLON.Vector3(27.5, valorPadraoParaCameras.position.y, 0),
    new BABYLON.Vector3(25, valorPadraoParaCameras.position.y, 15.5),
    new BABYLON.Vector3(15.5, valorPadraoParaCameras.position.y, 25),
    new BABYLON.Vector3(0, valorPadraoParaCameras.position.y, 27.5)
  ]

  let viewsPersonalizatedArmazemVAndPlan = [
    new BABYLON.Vector3(
      (numeroTotalDeArcosDoArmazem / 2 + larguraTotalDoArmazem / 10) * 6.34999,
      20,
      0
    ),
    new BABYLON.Vector3(
      larguraTotalDoArmazem * 0.61,
      20,
      -(larguraTotalDoArmazem * 0.92 + (larguraTotalDoArmazem * 0.92) / 2)
    ),
    new BABYLON.Vector3(
      larguraTotalDoArmazem * 0.61,
      20,
      -(larguraTotalDoArmazem * 0.92 + (larguraTotalDoArmazem * 0.92) / 2)
    ),
    new BABYLON.Vector3(
      -(larguraTotalDoArmazem * 0.61),
      20,
      -(larguraTotalDoArmazem * 0.92 + (larguraTotalDoArmazem * 0.92) / 2)
    ),
    new BABYLON.Vector3(
      -(larguraTotalDoArmazem * 0.61),
      20,
      -(larguraTotalDoArmazem * 0.92 + (larguraTotalDoArmazem * 0.92) / 2)
    ),
    new BABYLON.Vector3(
      (numeroTotalDeArcosDoArmazem / 2 + larguraTotalDoArmazem / 10) * 6.34999 -
        (numeroTotalDeArcosDoArmazem / 2 + larguraTotalDoArmazem / 10) *
          6.34999 *
          2,
      20,
      0
    ),
    new BABYLON.Vector3(
      -(larguraTotalDoArmazem * 0.61),
      20,
      larguraTotalDoArmazem * 0.92 + (larguraTotalDoArmazem * 0.92) / 2
    ),
    new BABYLON.Vector3(
      -(larguraTotalDoArmazem * 0.61),
      20,
      larguraTotalDoArmazem * 0.92 + (larguraTotalDoArmazem * 0.92) / 2
    ),
    new BABYLON.Vector3(
      larguraTotalDoArmazem * 0.61,
      20,
      larguraTotalDoArmazem * 0.92 + (larguraTotalDoArmazem * 0.92) / 2
    ),
    new BABYLON.Vector3(
      larguraTotalDoArmazem * 0.61,
      20,
      larguraTotalDoArmazem * 0.92 + (larguraTotalDoArmazem * 0.92) / 2
    )
  ]

  let targetsPersonalizatedArmazemVAndPlan = [
    new BABYLON.Vector3(0, 5, 0),
    new BABYLON.Vector3((numeroTotalDeArcosDoArmazem / 2) * 3.5, 5, 0),
    new BABYLON.Vector3((numeroTotalDeArcosDoArmazem / 4) * 3.5, 5, 0),
    new BABYLON.Vector3(
      (numeroTotalDeArcosDoArmazem / 4) * 3.5 -
        (numeroTotalDeArcosDoArmazem / 4) * 3.5 * 2,
      5,
      0
    ),
    new BABYLON.Vector3(
      (numeroTotalDeArcosDoArmazem / 2) * 3.5 -
        (numeroTotalDeArcosDoArmazem / 2) * 3.5 * 2,
      5,
      0
    ),
    new BABYLON.Vector3(0, 5, 0),
    new BABYLON.Vector3(
      (numeroTotalDeArcosDoArmazem / 2) * 3.5 -
        (numeroTotalDeArcosDoArmazem / 2) * 3.5 * 2,
      5,
      0
    ),
    new BABYLON.Vector3(
      (numeroTotalDeArcosDoArmazem / 4) * 3.5 -
        (numeroTotalDeArcosDoArmazem / 4) * 3.5 * 2,
      5,
      0
    ),
    new BABYLON.Vector3((numeroTotalDeArcosDoArmazem / 4) * 3.5, 5, 0),
    new BABYLON.Vector3((numeroTotalDeArcosDoArmazem / 2) * 3.5, 5, 0)
  ]

  let viewsPersonalizatedArmazemSemiV = [
    new BABYLON.Vector3(
      (numeroTotalDeArcosDoArmazem / 2 + larguraTotalDoArmazem / 10) * 10,
      15,
      0
    ),
    new BABYLON.Vector3(
      larguraTotalDoArmazem * 0.61,
      15,
      larguraTotalDoArmazem * 1.3 - larguraTotalDoArmazem * 1.3 * 2
    ),
    new BABYLON.Vector3(
      larguraTotalDoArmazem * 0.61,
      15,
      larguraTotalDoArmazem * 1.3 - larguraTotalDoArmazem * 1.3 * 2
    ),
    new BABYLON.Vector3(
      larguraTotalDoArmazem * 0.61 - larguraTotalDoArmazem * 0.61 * 2,
      15,
      larguraTotalDoArmazem * 1.3 - larguraTotalDoArmazem * 1.3 * 2
    ),
    new BABYLON.Vector3(
      larguraTotalDoArmazem * 0.61 - larguraTotalDoArmazem * 0.61 * 2,
      15,
      larguraTotalDoArmazem * 1.3 - larguraTotalDoArmazem * 1.3 * 2
    ),
    new BABYLON.Vector3(
      (numeroTotalDeArcosDoArmazem / 2 + larguraTotalDoArmazem / 10) * 10 -
        (numeroTotalDeArcosDoArmazem / 2 + larguraTotalDoArmazem / 10) * 10 * 2,
      15,
      0
    ),
    new BABYLON.Vector3(
      larguraTotalDoArmazem * 0.61 - larguraTotalDoArmazem * 0.61 * 2,
      15,
      larguraTotalDoArmazem * 1.3
    ),
    new BABYLON.Vector3(
      larguraTotalDoArmazem * 0.61 - larguraTotalDoArmazem * 0.61 * 2,
      15,
      larguraTotalDoArmazem * 1.3
    ),
    new BABYLON.Vector3(
      larguraTotalDoArmazem * 0.61,
      15,
      larguraTotalDoArmazem * 1.3
    ),
    new BABYLON.Vector3(
      larguraTotalDoArmazem * 0.61,
      15,
      larguraTotalDoArmazem * 1.3
    )
  ]

  let targetsPersonalizatedArmazemSemiV = [
    new BABYLON.Vector3(0, 5, 0),
    new BABYLON.Vector3((numeroTotalDeArcosDoArmazem / 2) * 3.5, 5, 0),
    new BABYLON.Vector3((numeroTotalDeArcosDoArmazem / 4) * 3.5, 5, 0),
    new BABYLON.Vector3(
      (numeroTotalDeArcosDoArmazem / 4) * 3.5 -
        (numeroTotalDeArcosDoArmazem / 4) * 3.5 * 2,
      5,
      0
    ),
    new BABYLON.Vector3(
      (numeroTotalDeArcosDoArmazem / 2) * 3.5 -
        (numeroTotalDeArcosDoArmazem / 2) * 3.5 * 2,
      5,
      0
    ),
    new BABYLON.Vector3(0, 5, 0),
    new BABYLON.Vector3(
      (numeroTotalDeArcosDoArmazem / 2) * 3.5 -
        (numeroTotalDeArcosDoArmazem / 2) * 3.5 * 2,
      5,
      0
    ),
    new BABYLON.Vector3(
      (numeroTotalDeArcosDoArmazem / 4) * 3.5 -
        (numeroTotalDeArcosDoArmazem / 4) * 3.5 * 2,
      5,
      0
    ),
    new BABYLON.Vector3((numeroTotalDeArcosDoArmazem / 4) * 3.5, 5, 0),
    new BABYLON.Vector3((numeroTotalDeArcosDoArmazem / 2) * 3.5, 5, 0)
  ]

  // Painel de opções
  const myGUI =
    GUI.AdvancedDynamicTexture.CreateFullscreenUI('interfaceCameras')

  const painel = new GUI.StackPanel()
  painel.width = '100%'
  painel.isHorizontal = true
  painel.isVertical = false
  painel.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painel.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  myGUI.addControl(painel)
  const painelCabecalho = new GUI.StackPanel()
  painelCabecalho.width = '35px'
  painelCabecalho.height = '40px'
  painelCabecalho.isHorizontal = true
  painelCabecalho.isVertical = true
  painelCabecalho.cornerRadius = 5
  painelCabecalho.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelCabecalho.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painel.addControl(painelCabecalho)
  const painelTitulo = new GUI.Button.CreateImageOnlyButton(
    'painelTitulo',
    settings
  )
  painelTitulo.fontSize = '11px'
  painelTitulo.width = '35px'
  painelTitulo.height = '35px'
  painelTitulo.background = '#ffffff'
  painelTitulo.paddingTop = '3px'
  painelTitulo.paddingLeft = '7px'
  painelTitulo.cornerRadius = 2
  // painelTitulo.paddingLeft = '-50px';
  painelTitulo.fontStyle = 'bold'
  painel.isHorizontal = true
  painel.isVertical = true
  painelTitulo.cornerRadius = 6
  painelTitulo.thickness = 0
  // painelTitulo.textHorizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_CENTER;
  painelTitulo.color = '#333'

  if (
    tipo === 1 ||
    tipo === 2 ||
    tipo === 9 ||
    tipo === 10 ||
    tipo === 11 ||
    tipo === 14 ||
    tipo === 15
  ) {
    painelTitulo.onPointerClickObservable.add(() => {
      painelCamera1.isVisible = !painelCamera1.isVisible
      painelCamera2.isVisible = !painelCamera2.isVisible
      painelCamera3.isVisible = !painelCamera3.isVisible
      painelVisaoNivelSensor.isVisible = !painelVisaoNivelSensor.isVisible
      painelInfoProduto.isVisible = !painelInfoProduto.isVisible
      painelInfoStaircase.isVisible = !painelInfoStaircase.isVisible
      painelTelacheia.isVisible = !painelTelacheia.isVisible
      painelPosicaoInicial.isVisible = !painelPosicaoInicial.isVisible
      painelPosicaoLeft.isVisible = !painelPosicaoLeft.isVisible
      painelPosicaoRight.isVisible = !painelPosicaoRight.isVisible
      painelInfo.isVisible = !painelInfo.isVisible
    })
  } else {
    painelTitulo.onPointerClickObservable.add(() => {
      painelCamera1.isVisible = !painelCamera1.isVisible
      painelInfo.isVisible = !painelInfo.isVisible
      painelCamera2.isVisible = !painelCamera2.isVisible
      painelCamera3.isVisible = !painelCamera3.isVisible
      painelVisaoNivelSensor.isVisible = !painelVisaoNivelSensor.isVisible
      painelInfoProduto.isVisible = !painelInfoProduto.isVisible
      painelTelacheia.isVisible = !painelTelacheia.isVisible
      painelPosicaoInicial.isVisible = !painelPosicaoInicial.isVisible
      painelPosicaoLeft.isVisible = !painelPosicaoLeft.isVisible
      painelPosicaoRight.isVisible = !painelPosicaoRight.isVisible
    })
  }
  painelCabecalho.addControl(painelTitulo)

  painelTitulo.pointerEnterAnimation = () => {
    painelTitulo.background = shade(0.075, '#ffffff')
  }

  painelTitulo.pointerOutAnimation = () => {
    painelTitulo.background = shade(0.075, '#ffffff')
  }

  const painelInfo = new GUI.StackPanel()
  painelInfo.width = '35px'
  painelInfo.height = '30px'
  painelInfo.isHorizontal = true
  painelInfo.isVertical = false
  // painelInfo.background = '#666';
  painelInfo.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelInfo.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelInfo.isVisible = 0
  painel.addControl(painelInfo)
  const btnInfo = new GUI.Button.CreateImageOnlyButton('btnInfo', info)
  btnInfo.color = '#333'
  btnInfo.fontSize = '11px'
  btnInfo.width = '35px'
  btnInfo.height = '30px'
  btnInfo.paddingLeft = '7px'
  btnInfo.background = '#ffffff'
  btnInfo.cornerRadius = 6
  btnInfo.thickness = 0
  btnInfo.onPointerClickObservable.add(() => {
    painelInfoExibitorPanel1.isVisible = !painelInfoExibitorPanel1.isVisible
  })
  painelInfo.addControl(btnInfo)

  btnInfo.pointerEnterAnimation = () => {
    painelTitulo.background = '#ffffff'
    btnPosIni.background = '#ffffff'
    btnInfo.background = shade(0.05, '#ffffff')
    btnInfoProduto.background = '#ffffff'
    btnNivelSensor.background = '#ffffff'
    btnCamera3.background = '#ffffff'
    btnCamera2.background = '#ffffff'
    btnCamera1.background = '#ffffff'

    painelTooltipCamera1.isVisible = 0
    painelTooltipCamera2.isVisible = 0
    painelTooltipCamera3.isVisible = 0
    painelTooltipNivelProduto.isVisible = 0
    painelTooltipOcultarTemperaturas.isVisible = 0
    painelTooltipInfo.isVisible = 1
    painelTooltipTelaCheia.isVisible = 0
    painelTooltipPosicaoInicial.isVisible = 0
    painelTooltipPosicaoLeft.isVisible = 0
    painelTooltipPosicaoRight.isVisible = 0
    painelTooltipStaircase.isVisible = 0
  }

  btnInfo.pointerOutAnimation = () => {
    btnInfo.background = '#ffffff'
    painelTooltipInfo.isVisible = 0
  }

  // Câmera 1//////////////////
  const painelCamera1 = new GUI.StackPanel()
  painelCamera1.width = '35px'
  painelCamera1.height = '30px'
  painelCamera1.padding = '4px'
  painelCamera1.borderRadius = 10
  // painelCamera1.background = 'black';
  painelCamera1.isHorizontal = true
  painelCamera1.isVertical = false
  painelCamera1.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelCamera1.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelCamera1.isVisible = 0
  painel.addControl(painelCamera1)
  const btnCamera1 = new GUI.Button.CreateImageOnlyButton('btnCamera1', left)
  btnCamera1.color = '#333'
  btnCamera1.fontSize = '11px'
  btnCamera1.width = '35px'
  btnCamera1.height = '30px'
  btnCamera1.background = '#ffffff'
  btnCamera1.paddingLeft = '7px'
  btnCamera1.cornerRadius = 6
  btnCamera1.thickness = 0
  btnCamera1.onPointerClickObservable.add(val => {
    if (tipo !== 5 && tipo !== 4 && tipo !== 3 && tipo !== 15 && tipo !== 14) {
      camera.setPosition(
        new BABYLON.Vector3(45, valorPadraoParaCameras.position.y, 15.5)
      ) // original 80, 80, 80
    } else {
      camera.setPosition(new BABYLON.Vector3(0, diameter, 110)) // original 80, 80, 80
      camera.setTarget(
        new BABYLON.Vector3(0, cameraInit.target.y, cameraInit.target.z)
      )
    }
  })
  painelCamera1.addControl(btnCamera1)

  btnCamera1.pointerEnterAnimation = () => {
    painelTitulo.background = '#ffffff'
    btnPosIni.background = '#ffffff'
    btnTelacheia.background = '#ffffff'
    btnInfoProduto.background = '#ffffff'
    btnNivelSensor.background = '#ffffff'
    btnCamera3.background = '#ffffff'
    btnCamera2.background = '#ffffff'
    btnInfo.background = '#ffffff'
    btnCamera1.background = shade(0.075, '#ffffff')
    painelTooltipCamera1.isVisible = 1
    painelTooltipCamera2.isVisible = 0
    painelTooltipCamera3.isVisible = 0
    painelTooltipNivelProduto.isVisible = 0
    painelTooltipOcultarTemperaturas.isVisible = 0
    painelTooltipTelaCheia.isVisible = 0
    painelTooltipPosicaoInicial.isVisible = 0
    painelTooltipStaircase.isVisible = 0
    painelTooltipInfo.isVisible = 0
  }

  btnCamera1.pointerOutAnimation = () => {
    btnCamera1.background = '#ffffff'
    painelTooltipCamera1.isVisible = 0
  }

  // Câmera 2/////////////////

  const painelCamera2 = new GUI.StackPanel()
  painelCamera2.width = '35px'
  painelCamera2.height = '30px'
  // painelCamera2.background = 'black';
  painelCamera2.isHorizontal = true
  painelCamera2.isVertical = false
  painelCamera2.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelCamera2.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelCamera2.isVisible = 0
  painel.addControl(painelCamera2)
  const btnCamera2 = new GUI.Button.CreateImageOnlyButton('btnCamera2', up)
  btnCamera2.color = '#333'
  btnCamera2.fontSize = '11px'
  btnCamera2.width = '35px'
  btnCamera2.height = '30px'
  btnCamera2.background = '#ffffff'
  btnCamera2.paddingLeft = '7px'
  btnCamera2.cornerRadius = 6
  btnCamera2.thickness = 0
  btnCamera2.onPointerClickObservable.add(val => {
    if (tipo !== 5 && tipo !== 4 && tipo !== 3) {
      camera.setPosition(new BABYLON.Vector3(0, diameter + diameter / 2, 0))
    } else {
      camera.setPosition(new BABYLON.Vector3(0, 50, 0)) // original 80, 80, 80
      camera.setTarget(
        new BABYLON.Vector3(0, cameraInit.target.y, cameraInit.target.z)
      )
    }
  })
  painelCamera2.addControl(btnCamera2)

  btnCamera2.pointerEnterAnimation = () => {
    painelTitulo.background = '#ffffff'
    btnPosIni.background = '#ffffff'
    btnTelacheia.background = '#ffffff'
    btnInfoProduto.background = '#ffffff'
    btnNivelSensor.background = '#ffffff'
    btnCamera3.background = '#ffffff'
    btnCamera2.background = shade(0.075, '#ffffff')
    btnCamera1.background = '#ffffff'
    btnInfo.background = '#ffffff'

    painelTooltipCamera1.isVisible = 0
    painelTooltipCamera2.isVisible = 1
    painelTooltipCamera3.isVisible = 0
    painelTooltipNivelProduto.isVisible = 0
    painelTooltipOcultarTemperaturas.isVisible = 0
    painelTooltipTelaCheia.isVisible = 0
    painelTooltipPosicaoInicial.isVisible = 0
    painelTooltipPosicaoLeft.isVisible = 0
    painelTooltipPosicaoRight.isVisible = 0
    painelTooltipStaircase.isVisible = 0
    painelTooltipInfo.isVisible = 0
  }

  btnCamera2.pointerOutAnimation = () => {
    btnCamera2.background = '#ffffff'
    painelTooltipCamera2.isVisible = 0
  }

  // Câmera 3/////////////////
  const painelCamera3 = new GUI.StackPanel()
  painelCamera3.width = '35px'
  painelCamera3.height = '30px'
  // painelCamera3.background = '#666';
  painelCamera3.isHorizontal = true
  painelCamera3.isVertical = false
  painelCamera3.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelCamera3.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelCamera3.isVisible = 0
  painel.addControl(painelCamera3)
  const btnCamera3 = new GUI.Button.CreateImageOnlyButton('btnCamera3', right)
  btnCamera3.color = '#333'
  btnCamera3.fontSize = '11px'
  btnCamera3.width = '35px'
  btnCamera3.height = '30px'
  btnCamera3.background = '#ffffff'
  btnCamera3.paddingLeft = '7px'
  btnCamera3.cornerRadius = 6
  btnCamera3.thickness = 0

  btnCamera3.onPointerClickObservable.add(val => {
    // console.log(tipo)
    if (tipo !== 5 && tipo !== 4 && tipo !== 3 && tipo !== 15 && tipo !== 14) {
      camera.setPosition(
        new BABYLON.Vector3(-45, valorPadraoParaCameras.position.y, -15.5)
      )
    } else {
      camera.setPosition(new BABYLON.Vector3(0, diameter, -110)) // original 80, 80, 80
      camera.setTarget(
        new BABYLON.Vector3(0, cameraInit.target.y, cameraInit.target.z)
      )
    }
  })
  painelCamera3.addControl(btnCamera3)

  btnCamera3.pointerEnterAnimation = () => {
    painelTitulo.background = '#ffffff'
    btnPosIni.background = '#ffffff'
    btnTelacheia.background = '#ffffff'
    btnInfoProduto.background = '#ffffff'
    btnNivelSensor.background = '#ffffff'
    btnCamera3.background = shade(0.075, '#ffffff')
    btnCamera2.background = '#ffffff'
    btnCamera1.background = '#ffffff'
    btnInfo.background = '#ffffff'

    painelTooltipCamera1.isVisible = 0
    painelTooltipCamera2.isVisible = 0
    painelTooltipCamera3.isVisible = 1
    painelTooltipNivelProduto.isVisible = 0
    painelTooltipOcultarTemperaturas.isVisible = 0
    painelTooltipTelaCheia.isVisible = 0
    painelTooltipPosicaoInicial.isVisible = 0
    painelTooltipPosicaoLeft.isVisible = 0
    painelTooltipPosicaoRight.isVisible = 0
    painelTooltipStaircase.isVisible = 0
    painelTooltipInfo.isVisible = 0
  }

  btnCamera3.pointerOutAnimation = () => {
    btnCamera3.background = '#ffffff'
    painelTooltipCamera3.isVisible = 0
  }

  // ////////////Evento movimentação por botão left
  const painelPosicaoLeft = new GUI.StackPanel()
  painelPosicaoLeft.width = '35px'
  painelPosicaoLeft.height = '30px'
  painelPosicaoLeft.borderRadius = 10
  // painelPosicaoLeft.background = '#666';
  painelPosicaoLeft.isHorizontal = true
  painelPosicaoLeft.isVertical = false
  painelPosicaoLeft.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelPosicaoLeft.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelPosicaoLeft.isVisible = 0
  painel.addControl(painelPosicaoLeft)
  const btnPosLeft = new GUI.Button.CreateImageOnlyButton(
    'btnPosLeft',
    manualLeft
  )
  btnPosLeft.color = '#333'
  btnPosLeft.fontSize = '11px'
  btnPosLeft.width = '35px'
  btnPosLeft.height = '30px'
  btnPosLeft.background = '#ffffff'
  btnPosLeft.paddingLeft = '7px'
  btnPosLeft.cornerRadius = 6
  btnPosLeft.thickness = 0

  btnPosLeft.onPointerClickObservable.add(() => {
    if (tipo === 1 || tipo === 2 || tipo === 9 || tipo === 10 || tipo === 11) {
      if (clickCountSilosAndBunkers - 1 >= 0) {
        clickCountSilosAndBunkers--
        camera.setPosition(viewsPersonalizatedSilos[clickCountSilosAndBunkers])
      } else if (clickCountSilosAndBunkers - 1 < 0) {
        clickCountSilosAndBunkers = 7
        camera.setPosition(viewsPersonalizatedSilos[clickCountSilosAndBunkers])
      }
    } else if (tipo === 14 || tipo === 15) {
      if (clickCountSilosAndBunkers - 1 >= 0) {
        clickCountSilosAndBunkers--
        camera.setPosition(
          viewsPersonalizatedBunkers[clickCountSilosAndBunkers]
        )
      } else if (clickCountSilosAndBunkers - 1 < 0) {
        clickCountSilosAndBunkers = 7
        camera.setPosition(
          viewsPersonalizatedBunkers[clickCountSilosAndBunkers]
        )
      }
    } else if (tipo === 13 || tipo === 12) {
      if (clickCountTulha - 1 >= 0) {
        clickCountTulha--
        camera.setPosition(viewsPersonalizatedTulha[clickCountTulha])
      } else if (clickCountTulha - 1 < 0) {
        clickCountTulha = 3
        camera.setPosition(viewsPersonalizatedTulha[clickCountTulha])
      }
    } else if (
      tipo === 3 ||
      tipo === 5 ||
      tipo === 6 ||
      tipo === 7 ||
      tipo === 8 ||
      tipo === 16 ||
      tipo === 17
    ) {
      if (clickCountArmazemVAndPlan + 1 <= 9) {
        clickCountArmazemVAndPlan++
        camera.setPosition(
          viewsPersonalizatedArmazemVAndPlan[clickCountArmazemVAndPlan]
        )
        camera.setTarget(
          targetsPersonalizatedArmazemVAndPlan[clickCountArmazemVAndPlan]
        )
      } else if (clickCountArmazemVAndPlan + 1 > 9) {
        clickCountArmazemVAndPlan = 0
        camera.setPosition(
          viewsPersonalizatedArmazemVAndPlan[clickCountArmazemVAndPlan]
        )
        camera.setTarget(
          targetsPersonalizatedArmazemVAndPlan[clickCountArmazemVAndPlan]
        )
      }
    } else if (tipo === 4) {
      if (clickCountArmazemSemiV + 1 <= 9) {
        clickCountArmazemSemiV++
        camera.setPosition(
          viewsPersonalizatedArmazemSemiV[clickCountArmazemSemiV]
        )
        camera.setTarget(
          targetsPersonalizatedArmazemSemiV[clickCountArmazemSemiV]
        )
      } else if (clickCountArmazemSemiV + 1 > 9) {
        clickCountArmazemSemiV = 0
        camera.setPosition(
          viewsPersonalizatedArmazemSemiV[clickCountArmazemSemiV]
        )
        camera.setTarget(
          targetsPersonalizatedArmazemSemiV[clickCountArmazemSemiV]
        )
      }
    }
  })
  painelPosicaoLeft.addControl(btnPosLeft)

  btnPosLeft.pointerEnterAnimation = () => {
    painelTitulo.background = '#ffffff'
    btnPosIni.background = '#ffffff'
    btnTelacheia.background = '#ffffff'
    btnInfoProduto.background = '#ffffff'
    btnNivelSensor.background = '#ffffff'
    btnCamera3.background = '#ffffff'
    btnCamera2.background = '#ffffff'
    btnCamera1.background = '#ffffff'
    btnPosLeft.background = shade(0.075, '#ffffff')
    btnInfo.background = '#ffffff'

    painelTooltipCamera1.isVisible = 0
    painelTooltipCamera2.isVisible = 0
    painelTooltipCamera3.isVisible = 0
    painelTooltipNivelProduto.isVisible = 0
    painelTooltipOcultarTemperaturas.isVisible = 0
    painelTooltipTelaCheia.isVisible = 0
    painelTooltipPosicaoInicial.isVisible = 0
    painelTooltipPosicaoLeft.isVisible = 1
    painelTooltipStaircase.isVisible = 0
    painelTooltipInfo.isVisible = 0
  }

  btnPosLeft.pointerOutAnimation = () => {
    btnPosLeft.background = '#ffffff'
    painelTooltipPosicaoLeft.isVisible = 0
  }

  // ////////////Evento movimentação por botão right
  const painelPosicaoRight = new GUI.StackPanel()
  painelPosicaoRight.width = '35px'
  painelPosicaoRight.height = '30px'
  painelPosicaoRight.borderRadius = 10
  // painelPosicaoRight.background = '#666';
  painelPosicaoRight.isHorizontal = true
  painelPosicaoRight.isVertical = false
  painelPosicaoRight.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelPosicaoRight.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelPosicaoRight.isVisible = 0
  painel.addControl(painelPosicaoRight)
  const btnPosRight = new GUI.Button.CreateImageOnlyButton(
    'btnPosRight',
    manualRight
  )
  btnPosRight.color = '#333'
  btnPosRight.fontSize = '11px'
  btnPosRight.width = '35px'
  btnPosRight.height = '30px'
  btnPosRight.background = '#ffffff'
  btnPosRight.paddingLeft = '7px'
  btnPosRight.cornerRadius = 6
  btnPosRight.thickness = 0

  btnPosRight.onPointerClickObservable.add(() => {
    if (tipo === 1 || tipo === 2 || tipo === 9 || tipo === 10 || tipo === 11) {
      if (clickCountSilosAndBunkers + 1 <= 7) {
        clickCountSilosAndBunkers++
        camera.setPosition(viewsPersonalizatedSilos[clickCountSilosAndBunkers])
      } else if (clickCountSilosAndBunkers + 1 > 7) {
        clickCountSilosAndBunkers = 0
        camera.setPosition(viewsPersonalizatedSilos[clickCountSilosAndBunkers])
      }
    } else if (tipo === 14 || tipo === 15) {
      if (clickCountSilosAndBunkers + 1 <= 7) {
        clickCountSilosAndBunkers++
        camera.setPosition(
          viewsPersonalizatedBunkers[clickCountSilosAndBunkers]
        )
      } else if (clickCountSilosAndBunkers + 1 > 7) {
        clickCountSilosAndBunkers = 0
        camera.setPosition(
          viewsPersonalizatedBunkers[clickCountSilosAndBunkers]
        )
      }
    } else if (tipo === 13 || tipo === 12) {
      if (clickCountTulha + 1 <= 7) {
        clickCountTulha++
        camera.setPosition(viewsPersonalizatedTulha[clickCountTulha])
      } else if (clickCountTulha + 1 > 7) {
        clickCountTulha = 0
        camera.setPosition(viewsPersonalizatedTulha[clickCountTulha])
      }
    } else if (
      tipo === 3 ||
      tipo === 5 ||
      tipo === 6 ||
      tipo === 7 ||
      tipo === 8 ||
      tipo === 16 ||
      tipo === 17
    ) {
      if (clickCountArmazemVAndPlan - 1 >= 0) {
        clickCountArmazemVAndPlan--
        camera.setPosition(
          viewsPersonalizatedArmazemVAndPlan[clickCountArmazemVAndPlan]
        )
        camera.setTarget(
          targetsPersonalizatedArmazemVAndPlan[clickCountArmazemVAndPlan]
        )
      } else if (clickCountArmazemVAndPlan - 1 < 0) {
        clickCountArmazemVAndPlan = 9
        camera.setPosition(
          viewsPersonalizatedArmazemVAndPlan[clickCountArmazemVAndPlan]
        )
        camera.setTarget(
          targetsPersonalizatedArmazemVAndPlan[clickCountArmazemVAndPlan]
        )
      }
    } else if (tipo === 4) {
      if (clickCountArmazemSemiV - 1 >= 0) {
        clickCountArmazemSemiV--
        camera.setPosition(
          viewsPersonalizatedArmazemSemiV[clickCountArmazemSemiV]
        )
        camera.setTarget(
          targetsPersonalizatedArmazemSemiV[clickCountArmazemSemiV]
        )
      } else if (clickCountArmazemSemiV - 1 < 0) {
        clickCountArmazemSemiV = 9
        camera.setPosition(
          viewsPersonalizatedArmazemSemiV[clickCountArmazemSemiV]
        )
        camera.setTarget(
          targetsPersonalizatedArmazemSemiV[clickCountArmazemSemiV]
        )
      }
    }
    // camera.setTarget(new BABYLON.Vector3(cameraInit.target.x, cameraInit.target.y, cameraInit.target.z));
  })
  painelPosicaoRight.addControl(btnPosRight)

  btnPosRight.pointerEnterAnimation = () => {
    painelTitulo.background = '#ffffff'
    btnPosIni.background = '#ffffff'
    btnTelacheia.background = '#ffffff'
    btnInfoProduto.background = '#ffffff'
    btnNivelSensor.background = '#ffffff'
    btnCamera3.background = '#ffffff'
    btnCamera2.background = '#ffffff'
    btnCamera1.background = '#ffffff'
    btnPosLeft.background = '#ffffff'
    btnPosRight.background = shade(0.075, '#ffffff')
    btnInfo.background = '#ffffff'

    painelTooltipCamera1.isVisible = 0
    painelTooltipCamera2.isVisible = 0
    painelTooltipCamera3.isVisible = 0
    painelTooltipNivelProduto.isVisible = 0
    painelTooltipOcultarTemperaturas.isVisible = 0
    painelTooltipTelaCheia.isVisible = 0
    painelTooltipPosicaoInicial.isVisible = 0
    painelTooltipPosicaoRight.isVisible = 1
    painelTooltipStaircase.isVisible = 0
    painelTooltipInfo.isVisible = 0
  }

  btnPosRight.pointerOutAnimation = () => {
    btnPosRight.background = '#ffffff'
    painelTooltipPosicaoRight.isVisible = 0
  }

  // //////////// Exibe Nível de produto

  const painelVisaoNivelSensor = new GUI.StackPanel()
  painelVisaoNivelSensor.width = '35px'
  painelVisaoNivelSensor.height = '30px'
  painelVisaoNivelSensor.isHorizontal = true
  painelVisaoNivelSensor.isVertical = false
  // painelVisaoNivelSensor.background = '#666';
  painelVisaoNivelSensor.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelVisaoNivelSensor.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelVisaoNivelSensor.isVisible = 0
  painel.addControl(painelVisaoNivelSensor)

  const btnNivelSensor = new GUI.Button.CreateImageOnlyButton(
    'btnNivelSensor',
    level
  )
  btnNivelSensor.color = '#333'
  btnNivelSensor.fontSize = '11px'
  btnNivelSensor.width = '35px'
  btnNivelSensor.height = '30px'
  btnNivelSensor.paddingLeft = '7px'
  btnNivelSensor.background = '#ffffff'
  btnNivelSensor.cornerRadius = 6
  btnNivelSensor.thickness = 0

  btnNivelSensor.onPointerClickObservable.add(changeViewProduct)
  painelVisaoNivelSensor.addControl(btnNivelSensor)

  btnNivelSensor.pointerEnterAnimation = () => {
    painelTitulo.background = '#ffffff'
    btnPosIni.background = '#ffffff'
    btnTelacheia.background = '#ffffff'
    btnInfoProduto.background = '#ffffff'
    btnNivelSensor.background = shade(0.075, '#ffffff')
    btnCamera3.background = '#ffffff'
    btnCamera2.background = '#ffffff'
    btnCamera1.background = '#ffffff'
    btnInfo.background = '#ffffff'

    painelTooltipCamera1.isVisible = 0
    painelTooltipCamera2.isVisible = 0
    painelTooltipCamera3.isVisible = 0
    painelTooltipNivelProduto.isVisible = 1
    painelTooltipOcultarTemperaturas.isVisible = 0
    painelTooltipTelaCheia.isVisible = 0
    painelTooltipPosicaoInicial.isVisible = 0
    painelTooltipPosicaoLeft.isVisible = 0
    painelTooltipPosicaoRight.isVisible = 0
    painelTooltipStaircase.isVisible = 0
    painelTooltipInfo.isVisible = 0
  }

  btnNivelSensor.pointerOutAnimation = () => {
    btnNivelSensor.background = '#ffffff'
    painelTooltipNivelProduto.isVisible = 0
  }

  // ////////////Exibe Informação produto
  const painelInfoProduto = new GUI.StackPanel()
  painelInfoProduto.width = '35px'
  painelInfoProduto.height = '30px'
  painelInfoProduto.isHorizontal = true
  painelInfoProduto.isVertical = false
  // painelInfoProduto.background = '#666';
  painelInfoProduto.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelInfoProduto.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelInfoProduto.isVisible = 0
  painel.addControl(painelInfoProduto)
  const btnInfoProduto = new GUI.Button.CreateImageOnlyButton(
    'btnInfoProduto',
    temperatureOff
  )
  btnInfoProduto.color = '#333'
  btnInfoProduto.fontSize = '11px'
  btnInfoProduto.width = '35px'
  btnInfoProduto.height = '30px'
  btnInfoProduto.paddingLeft = '7px'
  btnInfoProduto.background = '#ffffff'
  btnInfoProduto.cornerRadius = 6
  btnInfoProduto.thickness = 0

  btnInfoProduto.onPointerClickObservable.add(changeInfoProduct)
  painelInfoProduto.addControl(btnInfoProduto)

  btnInfoProduto.pointerEnterAnimation = () => {
    painelTitulo.background = '#ffffff'
    btnPosIni.background = '#ffffff'
    btnTelacheia.background = '#ffffff'
    btnInfoProduto.background = shade(0.075, '#ffffff')
    btnNivelSensor.background = '#ffffff'
    btnCamera3.background = '#ffffff'
    btnCamera2.background = '#ffffff'
    btnCamera1.background = '#ffffff'
    btnInfo.background = '#ffffff'

    painelTooltipCamera1.isVisible = 0
    painelTooltipCamera2.isVisible = 0
    painelTooltipCamera3.isVisible = 0
    painelTooltipNivelProduto.isVisible = 0
    painelTooltipOcultarTemperaturas.isVisible = 1
    painelTooltipTelaCheia.isVisible = 0
    painelTooltipPosicaoInicial.isVisible = 0
    painelTooltipPosicaoLeft.isVisible = 0
    painelTooltipPosicaoRight.isVisible = 0
    painelTooltipStaircase.isVisible = 0
    painelTooltipInfo.isVisible = 0
  }

  btnInfoProduto.pointerOutAnimation = () => {
    btnInfoProduto.background = '#ffffff'
    painelTooltipOcultarTemperaturas.isVisible = 0
  }

  const painelTelacheia = new GUI.StackPanel()
  painelTelacheia.width = '35px'
  painelTelacheia.height = '30px'
  painelTelacheia.isHorizontal = true
  painelTelacheia.isVertical = false
  // painelTelacheia.background = '#666';
  painelTelacheia.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelTelacheia.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelTelacheia.isVisible = 0
  painel.addControl(painelTelacheia)
  const btnTelacheia = new GUI.Button.CreateImageOnlyButton(
    'btnTelaCheia',
    fullScreen
  )
  btnTelacheia.color = '#333'
  btnTelacheia.fontSize = '11px'
  btnTelacheia.width = '35px'
  btnTelacheia.height = '30px'
  btnTelacheia.background = '#ffffff'
  btnTelacheia.paddingLeft = '7px'
  btnTelacheia.cornerRadius = 6
  btnTelacheia.thickness = 0
  btnTelacheia.onPointerClickObservable.add(() => {
    engine.switchFullscreen(false)
  })
  painelTelacheia.addControl(btnTelacheia)

  btnTelacheia.pointerEnterAnimation = () => {
    painelTitulo.background = '#ffffff'
    btnPosIni.background = '#ffffff'
    btnTelacheia.background = shade(0.075, '#ffffff')
    btnInfoProduto.background = '#ffffff'
    btnNivelSensor.background = '#ffffff'
    btnCamera3.background = '#ffffff'
    btnCamera2.background = '#ffffff'
    btnCamera1.background = '#ffffff'
    btnInfo.background = '#ffffff'

    painelTooltipCamera1.isVisible = 0
    painelTooltipCamera2.isVisible = 0
    painelTooltipCamera3.isVisible = 0
    painelTooltipNivelProduto.isVisible = 0
    painelTooltipOcultarTemperaturas.isVisible = 0
    painelTooltipTelaCheia.isVisible = 1
    painelTooltipPosicaoInicial.isVisible = 0
    painelTooltipPosicaoLeft.isVisible = 0
    painelTooltipPosicaoRight.isVisible = 0
    painelTooltipStaircase.isVisible = 0
    painelTooltipInfo.isVisible = 0
  }

  btnTelacheia.pointerOutAnimation = () => {
    btnTelacheia.background = '#ffffff'
    painelTooltipTelaCheia.isVisible = 0
  }

  // ////////////Evento posição padrão
  const painelPosicaoInicial = new GUI.StackPanel()
  painelPosicaoInicial.width = '35px'
  painelPosicaoInicial.height = '30px'
  painelPosicaoInicial.borderRadius = 10
  // painelPosicaoInicial.background = '#666';
  painelPosicaoInicial.isHorizontal = true
  painelPosicaoInicial.isVertical = false
  painelPosicaoInicial.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelPosicaoInicial.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelPosicaoInicial.isVisible = 0
  painel.addControl(painelPosicaoInicial)
  const btnPosIni = new GUI.Button.CreateImageOnlyButton('btnPosIni', reset)
  btnPosIni.color = '#333'
  btnPosIni.fontSize = '11px'
  btnPosIni.width = '35px'
  btnPosIni.height = '30px'
  btnPosIni.background = '#ffffff'
  btnPosIni.paddingLeft = '7px'
  btnPosIni.cornerRadius = 6
  btnPosIni.thickness = 0

  const cameraInit = cameraReset
  btnPosIni.onPointerClickObservable.add(() => {
    clickCountArmazemVAndPlan = 0
    clickCountSilosAndBunkers = 0
    clickCountTulha = 0
    camera.setPosition(
      new BABYLON.Vector3(
        cameraInit.position.x,
        cameraInit.position.y,
        cameraInit.position.z
      )
    )
    camera.setTarget(
      new BABYLON.Vector3(
        cameraInit.target.x,
        cameraInit.target.y,
        cameraInit.target.z
      )
    )
  })
  painelPosicaoInicial.addControl(btnPosIni)

  btnPosIni.pointerEnterAnimation = () => {
    painelTitulo.background = '#ffffff'
    btnPosIni.background = shade(0.075, '#ffffff')
    btnTelacheia.background = '#ffffff'
    btnInfoProduto.background = '#ffffff'
    btnNivelSensor.background = '#ffffff'
    btnCamera3.background = '#ffffff'
    btnCamera2.background = '#ffffff'
    btnCamera1.background = '#ffffff'
    btnInfo.background = '#ffffff'

    painelTooltipCamera1.isVisible = 0
    painelTooltipCamera2.isVisible = 0
    painelTooltipCamera3.isVisible = 0
    painelTooltipNivelProduto.isVisible = 0
    painelTooltipOcultarTemperaturas.isVisible = 0
    painelTooltipTelaCheia.isVisible = 0
    painelTooltipPosicaoInicial.isVisible = 1
    painelTooltipPosicaoLeft.isVisible = 0
    painelTooltipPosicaoRight.isVisible = 0
    painelTooltipStaircase.isVisible = 0
    painelTooltipInfo.isVisible = 0
  }

  btnPosIni.pointerOutAnimation = () => {
    btnPosIni.background = '#ffffff'
    painelTooltipPosicaoInicial.isVisible = 0
  }

  // ////////////Exibe ou não a escada
  const painelInfoStaircase = new GUI.StackPanel()
  painelInfoStaircase.width = '35px'
  painelInfoStaircase.height = '30px'
  painelInfoStaircase.isHorizontal = true
  painelInfoStaircase.isVertical = false
  // painelInfoStaircase.background = '#666';
  painelInfoStaircase.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelInfoStaircase.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelInfoStaircase.isVisible = 0
  painel.addControl(painelInfoStaircase)
  const btnInfoStaircase = new GUI.Button.CreateImageOnlyButton(
    'btnInfoStaircase',
    stairs
  )
  btnInfoStaircase.color = '#ff0000'
  btnInfoStaircase.fontSize = '11px'
  btnInfoStaircase.width = '35px'
  btnInfoStaircase.height = '30px'
  btnInfoStaircase.paddingLeft = '7px'
  btnInfoStaircase.background = '#ffffff'
  btnInfoStaircase.cornerRadius = 6
  btnInfoStaircase.thickness = 0

  btnInfoStaircase.onPointerClickObservable.add(changeInfoStaircase)
  painelInfoStaircase.addControl(btnInfoStaircase)

  btnInfoStaircase.pointerEnterAnimation = () => {
    painelTitulo.background = '#ffffff'
    btnPosIni.background = '#ffffff'
    btnTelacheia.background = '#ffffff'
    btnInfoStaircase.background = shade(0.075, '#ffffff')
    btnNivelSensor.background = '#ffffff'
    btnCamera3.background = '#ffffff'
    btnCamera2.background = '#ffffff'
    btnCamera1.background = '#ffffff'
    btnInfo.background = '#ffffff'

    painelTooltipCamera1.isVisible = 0
    painelTooltipCamera2.isVisible = 0
    painelTooltipCamera3.isVisible = 0
    painelTooltipNivelProduto.isVisible = 0
    painelTooltipOcultarTemperaturas.isVisible = 0
    painelTooltipTelaCheia.isVisible = 0
    painelTooltipPosicaoInicial.isVisible = 0
    painelTooltipPosicaoLeft.isVisible = 0
    painelTooltipPosicaoRight.isVisible = 0
    painelTooltipInfo.isVisible = 0
    painelTooltipStaircase.isVisible = 1
  }

  btnInfoStaircase.pointerOutAnimation = () => {
    btnInfoStaircase.background = '#ffffff'
    painelTooltipStaircase.isVisible = 0
  }

  const myGUIToolTip = GUI.AdvancedDynamicTexture.CreateFullscreenUI(
    'interfaceCamerasToolTip'
  )

  const painelTooltip = new GUI.StackPanel()
  painelTooltip.width = '130px'
  painelTooltip.height = '600px'
  painelTooltip.left = '45px'
  painelTooltip.isHorizontal = true
  painelTooltip.isVertical = true
  painelTooltip.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelTooltip.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  myGUI.addControl(painelTooltip)

  // ////////////Evento tooltip escada
  const painelTooltipInfo = new GUI.StackPanel()
  painelTooltipInfo.width = '130px'
  painelTooltipInfo.height = '80px'
  // painelTooltipInfo.background = '#666';
  painelTooltipInfo.isHorizontal = true
  painelTooltipInfo.isVertical = true
  painelTooltipInfo.horizontalAlignment = GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT
  painelTooltipInfo.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM
  painelTooltipInfo.isVisible = 0
  painelTooltip.addControl(painelTooltipInfo)
  const btnTooltipInfo = new GUI.Button.CreateSimpleButton(
    'btnTooltipInfo',
    'INFORMAÇÃO'
  )
  btnTooltipInfo.color = '#333'
  btnTooltipInfo.fontSize = '11px'
  btnTooltipInfo.width = '130px'
  btnTooltipInfo.height = '70px'
  btnTooltipInfo.background = shade(0.075, '#ffffff')
  btnTooltipInfo.paddingLeft = '7px'
  btnTooltipInfo.paddingTop = '40px'
  btnTooltipInfo.cornerRadius = 6
  btnTooltipInfo.thickness = 0

  painelTooltipInfo.addControl(btnTooltipInfo)

  // ////////////Evento tooltip camera 1
  const painelTooltipCamera1 = new GUI.StackPanel()
  painelTooltipCamera1.width = '130px'
  painelTooltipCamera1.height = '120px'
  // painelTooltipCamera1.background = '#666';
  painelTooltipCamera1.isHorizontal = true
  painelTooltipCamera1.isVertical = true
  painelTooltipCamera1.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT
  painelTooltipCamera1.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_BOTTOM
  painelTooltipCamera1.isVisible = 0
  painelTooltip.addControl(painelTooltipCamera1)
  const btnTooltipCamera1 = new GUI.Button.CreateSimpleButton(
    'btnTooltipCamera1',
    'VISÃO FRONTAL'
  )
  btnTooltipCamera1.color = '#333'
  btnTooltipCamera1.fontSize = '11px'
  btnTooltipCamera1.width = '130px'
  btnTooltipCamera1.height = '100px'
  btnTooltipCamera1.background = shade(0.075, '#ffffff')
  btnTooltipCamera1.paddingLeft = '7px'
  btnTooltipCamera1.cornerRadius = 6
  btnTooltipCamera1.thickness = 0
  btnTooltipCamera1.paddingTop = '70px'

  painelTooltipCamera1.addControl(btnTooltipCamera1)

  // ////////////Evento tooltip camera 2
  const painelTooltipCamera2 = new GUI.StackPanel()
  painelTooltipCamera2.width = '130px'
  painelTooltipCamera2.height = '150px'
  // painelTooltipCamera2.background = '#666';
  painelTooltipCamera2.isHorizontal = true
  painelTooltipCamera2.isVertical = true
  painelTooltipCamera2.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelTooltipCamera2.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelTooltipCamera2.isVisible = 0
  painelTooltip.addControl(painelTooltipCamera2)
  const btnTooltipCamera2 = new GUI.Button.CreateSimpleButton(
    'btnTooltipCamera2',
    'VISÃO SUPERIOR'
  )
  btnTooltipCamera2.color = '#333'
  btnTooltipCamera2.fontSize = '11px'
  btnTooltipCamera2.width = '130px'
  btnTooltipCamera2.height = '130px'
  btnTooltipCamera2.background = shade(0.075, '#ffffff')
  btnTooltipCamera2.paddingLeft = '7px'
  btnTooltipCamera2.paddingTop = '100px'
  btnTooltipCamera2.cornerRadius = 2
  btnTooltipCamera2.thickness = 0

  painelTooltipCamera2.addControl(btnTooltipCamera2)

  // ////////////Evento tooltip camera 3
  const painelTooltipCamera3 = new GUI.StackPanel()
  painelTooltipCamera3.width = '130px'
  painelTooltipCamera3.height = '180px'
  // painelTooltipCamera3.background = '#666';
  painelTooltipCamera3.isHorizontal = true
  painelTooltipCamera3.isVertical = true
  painelTooltipCamera3.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelTooltipCamera3.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelTooltipCamera3.isVisible = 0
  painelTooltip.addControl(painelTooltipCamera3)
  const btnTooltipCamera3 = new GUI.Button.CreateSimpleButton(
    'btnTooltipCamera3',
    'VISÃO POSTERIOR'
  )
  btnTooltipCamera3.color = '#333'
  btnTooltipCamera3.fontSize = '11px'
  btnTooltipCamera3.width = '130px'
  btnTooltipCamera3.height = '160px'
  btnTooltipCamera3.background = shade(0.075, '#ffffff')
  btnTooltipCamera3.paddingLeft = '7px'
  btnTooltipCamera3.paddingTop = '130px'
  btnTooltipCamera3.cornerRadius = 2
  btnTooltipCamera3.thickness = 0

  painelTooltipCamera3.addControl(btnTooltipCamera3)

  // ////////////Evento tooltip posição esquerda
  const painelTooltipPosicaoLeft = new GUI.StackPanel()
  painelTooltipPosicaoLeft.width = '130px'
  painelTooltipPosicaoLeft.height = '210px'
  // painelTooltipPosicaoLeft.background = '#666';
  painelTooltipPosicaoLeft.isHorizontal = true
  painelTooltipPosicaoLeft.isVertical = false
  painelTooltipPosicaoLeft.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT
  painelTooltipPosicaoLeft.verticalAlignment =
    GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelTooltipPosicaoLeft.isVisible = 0
  painelTooltip.addControl(painelTooltipPosicaoLeft)
  const btnTooltipPosicaoLeft = new GUI.Button.CreateSimpleButton(
    'btnTooltipPosicaoLeft',
    'MOVER A ESQUERDA'
  )
  btnTooltipPosicaoLeft.color = '#333'
  btnTooltipPosicaoLeft.fontSize = '11px'
  btnTooltipPosicaoLeft.width = '130px'
  btnTooltipPosicaoLeft.height = '190px'
  btnTooltipPosicaoLeft.background = shade(0.075, '#ffffff')
  btnTooltipPosicaoLeft.paddingLeft = '7px'
  btnTooltipPosicaoLeft.paddingTop = '160px'
  btnTooltipPosicaoLeft.cornerRadius = 2
  btnTooltipPosicaoLeft.thickness = 0

  painelTooltipPosicaoLeft.addControl(btnTooltipPosicaoLeft)

  // ////////////Evento tooltip posição direira
  const painelTooltipPosicaoRight = new GUI.StackPanel()
  painelTooltipPosicaoRight.width = '130px'
  painelTooltipPosicaoRight.height = '240px'
  // painelTooltipPosicaoRight.background = '#666';
  painelTooltipPosicaoRight.isHorizontal = true
  painelTooltipPosicaoRight.isVertical = true
  painelTooltipPosicaoRight.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelTooltipPosicaoRight.verticalAlignment =
    GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelTooltipPosicaoRight.isVisible = 0
  painelTooltip.addControl(painelTooltipPosicaoRight)
  const btnTooltipPosicaoRight = new GUI.Button.CreateSimpleButton(
    'btnTooltipPosicaoRight',
    'MOVER A DIREITA'
  )
  btnTooltipPosicaoRight.color = '#333'
  btnTooltipPosicaoRight.fontSize = '11px'
  btnTooltipPosicaoRight.width = '130px'
  btnTooltipPosicaoRight.height = '220px'
  btnTooltipPosicaoRight.background = shade(0.075, '#ffffff')
  btnTooltipPosicaoRight.paddingLeft = '7px'
  btnTooltipPosicaoRight.paddingTop = '190px'
  btnTooltipPosicaoRight.cornerRadius = 2
  btnTooltipPosicaoRight.thickness = 0

  painelTooltipPosicaoRight.addControl(btnTooltipPosicaoRight)

  // ////////////Evento tooltip nivel produto
  const painelTooltipNivelProduto = new GUI.StackPanel()
  painelTooltipNivelProduto.width = '130px'
  painelTooltipNivelProduto.height = '270px'
  // painelTooltipNivelProduto.background = '#666';
  painelTooltipNivelProduto.isHorizontal = true
  painelTooltipNivelProduto.isVertical = true
  painelTooltipNivelProduto.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelTooltipNivelProduto.verticalAlignment =
    GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelTooltipNivelProduto.isVisible = 0
  painelTooltip.addControl(painelTooltipNivelProduto)
  const btnTooltipNivelProduto = new GUI.Button.CreateSimpleButton(
    'btnTooltipNivelProduto',
    'EXIBIR / OCULTAR O NÍVEL'
  )
  btnTooltipNivelProduto.color = '#333'
  btnTooltipNivelProduto.fontSize = '11px'
  btnTooltipNivelProduto.width = '130px'
  btnTooltipNivelProduto.height = '250px'
  btnTooltipNivelProduto.background = shade(0.075, '#ffffff')
  btnTooltipNivelProduto.paddingLeft = '7px'
  btnTooltipNivelProduto.paddingTop = '220px'
  btnTooltipNivelProduto.cornerRadius = 2
  btnTooltipNivelProduto.thickness = 0

  painelTooltipNivelProduto.addControl(btnTooltipNivelProduto)

  // ////////////Evento tooltip ocultar temperaturas
  const painelTooltipOcultarTemperaturas = new GUI.StackPanel()
  painelTooltipOcultarTemperaturas.width = '130px'
  painelTooltipOcultarTemperaturas.height = '300px'
  // painelTooltipOcultarTemperaturas.background = '#666';
  painelTooltipOcultarTemperaturas.isHorizontal = true
  painelTooltipOcultarTemperaturas.isVertical = true
  painelTooltipOcultarTemperaturas.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelTooltipOcultarTemperaturas.verticalAlignment =
    GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelTooltipOcultarTemperaturas.isVisible = 0
  painelTooltip.addControl(painelTooltipOcultarTemperaturas)
  const btnTooltipOcultarTemperaturas = new GUI.Button.CreateSimpleButton(
    'btnTooltipOcultarTemperaturas',
    'OCULTAR TEMPERATURAS'
  )
  btnTooltipOcultarTemperaturas.color = '#333'
  btnTooltipOcultarTemperaturas.fontSize = '11px'
  btnTooltipOcultarTemperaturas.width = '130px'
  btnTooltipOcultarTemperaturas.height = '280px'
  btnTooltipOcultarTemperaturas.background = shade(0.075, '#ffffff')
  btnTooltipOcultarTemperaturas.paddingLeft = '7px'
  btnTooltipOcultarTemperaturas.paddingTop = '250px'
  btnTooltipOcultarTemperaturas.cornerRadius = 2
  btnTooltipOcultarTemperaturas.thickness = 0

  painelTooltipOcultarTemperaturas.addControl(btnTooltipOcultarTemperaturas)

  // ////////////Evento tooltip tela cheia
  const painelTooltipTelaCheia = new GUI.StackPanel()
  painelTooltipTelaCheia.width = '130px'
  painelTooltipTelaCheia.height = '330px'
  // painelTooltipTelaCheia.background = '#666';
  painelTooltipTelaCheia.isHorizontal = true
  painelTooltipTelaCheia.isVertical = true
  painelTooltipTelaCheia.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelTooltipTelaCheia.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelTooltipTelaCheia.isVisible = 0
  painelTooltip.addControl(painelTooltipTelaCheia)
  const btnTooltipTelaCheia = new GUI.Button.CreateSimpleButton(
    'btnTooltipTelaCheia',
    'TELA CHEIA'
  )
  btnTooltipTelaCheia.color = '#333'
  btnTooltipTelaCheia.fontSize = '11px'
  btnTooltipTelaCheia.width = '130px'
  btnTooltipTelaCheia.height = '310px'
  btnTooltipTelaCheia.background = shade(0.075, '#ffffff')
  btnTooltipTelaCheia.paddingLeft = '7px'
  btnTooltipTelaCheia.paddingTop = '280px'
  btnTooltipTelaCheia.cornerRadius = 2
  btnTooltipTelaCheia.thickness = 0

  painelTooltipTelaCheia.addControl(btnTooltipTelaCheia)

  // ////////////Evento tooltip posição inicial
  const painelTooltipPosicaoInicial = new GUI.StackPanel()
  painelTooltipPosicaoInicial.width = '130px'
  painelTooltipPosicaoInicial.height = '360px'
  // painelTooltipPosicaoInicial.background = '#666';
  painelTooltipPosicaoInicial.isHorizontal = true
  painelTooltipPosicaoInicial.isVertical = true
  painelTooltipPosicaoInicial.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelTooltipPosicaoInicial.verticalAlignment =
    GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelTooltipPosicaoInicial.isVisible = 0
  painelTooltip.addControl(painelTooltipPosicaoInicial)
  const btnTooltipPosicaoInicial = new GUI.Button.CreateSimpleButton(
    'btnTooltipPosicaoInicial',
    'POSIÇÃO INICIAL'
  )
  btnTooltipPosicaoInicial.color = '#333'
  btnTooltipPosicaoInicial.fontSize = '11px'
  btnTooltipPosicaoInicial.width = '130px'
  btnTooltipPosicaoInicial.height = '340px'
  btnTooltipPosicaoInicial.background = shade(0.075, '#ffffff')
  btnTooltipPosicaoInicial.paddingLeft = '7px'
  btnTooltipPosicaoInicial.paddingTop = '310px'
  btnTooltipPosicaoInicial.cornerRadius = 2
  btnTooltipPosicaoInicial.thickness = 0

  painelTooltipPosicaoInicial.addControl(btnTooltipPosicaoInicial)

  // ////////////Evento tooltip escada
  const painelTooltipStaircase = new GUI.StackPanel()
  painelTooltipStaircase.width = '130px'
  painelTooltipStaircase.height = '390px'
  // painelTooltipStaircase.background = '#666';
  painelTooltipStaircase.isHorizontal = true
  painelTooltipStaircase.isVertical = true
  painelTooltipStaircase.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_LEFT
  painelTooltipStaircase.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelTooltipStaircase.isVisible = 0
  painelTooltip.addControl(painelTooltipStaircase)
  const btnTooltipStaircase = new GUI.Button.CreateSimpleButton(
    'btnTooltipStaircase',
    'EXIBIR / OCULTAR A ESCADA'
  )
  btnTooltipStaircase.color = '#333'
  btnTooltipStaircase.fontSize = '11px'
  btnTooltipStaircase.width = '130px'
  btnTooltipStaircase.height = '370px'
  btnTooltipStaircase.background = shade(0.075, '#ffffff')
  btnTooltipStaircase.paddingLeft = '7px'
  btnTooltipStaircase.paddingTop = '340px'
  btnTooltipStaircase.cornerRadius = 2
  btnTooltipStaircase.thickness = 0

  painelTooltipStaircase.addControl(btnTooltipStaircase)

  const painelInfoExibitor = new GUI.StackPanel()
  painelInfoExibitor.top = '7px'
  painelInfoExibitor.width = '120px'
  painelInfoExibitor.height = '500px'
  painelInfoExibitor.isHorizontal = true
  painelInfoExibitor.isVertical = true
  painelInfoExibitor.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT
  painelInfoExibitor.verticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
  myGUI.addControl(painelInfoExibitor)

  // ////////////Evento tooltip camera 1
  const painelInfoExibitorPanel1 = new GUI.StackPanel()
  painelInfoExibitorPanel1.width = '120px'
  painelInfoExibitorPanel1.height = '500px'
  painelInfoExibitorPanel1.opacity = 0.5
  painelInfoExibitorPanel1.cornerRadius = 5
  painelInfoExibitorPanel1.background = '#ffffff'
  painelInfoExibitorPanel1.isHorizontal = true
  painelInfoExibitorPanel1.isVertical = true
  painelInfoExibitorPanel1.horizontalAlignment =
    GUI.Control.HORIZONTAL_ALIGNMENT_RIGHT
  painelInfoExibitorPanel1.verticalAlignment =
    GUI.Control.VERTICAL_ALIGNMENT_TOP
  painelInfoExibitorPanel1.isVisible = 0
  painelInfoExibitor.addControl(painelInfoExibitorPanel1)

  var text1 = new GUI.TextBlock()
  text1.text = `${moment(dateAndHours[0]).format('DD/MM/YYYY')}`
  text1.color = 'black'
  text1.fontSize = 12
  text1.textHorizontalAlignment = 'left'
  text1.resizeToFit = true
  text1.lineSpacing = '2px'
  text1.paddingTop = '4px'

  // console.log(dateAndHours[1])

  var text2 = new GUI.TextBlock()
  text2.text = `${
    dateAndHours && dateAndHours.length > 0 && dateAndHours[1].slice(0, 8)
  }`
  text2.color = 'black'
  text2.fontSize = 12
  text2.textHorizontalAlignment = 'left'
  text2.resizeToFit = true
  text2.lineSpacing = '2px'
  text2.paddingTop = '4px'

  var text3 = new GUI.TextBlock()
  text3.text = `${
    subareaSelected > 0 ? readingGrain.nameGrain : multiReadingGrain
  }`
  text3.color = 'black'
  text3.fontSize = 12
  text3.textHorizontalAlignment = 'left'
  text3.resizeToFit = true
  text3.lineSpacing = '2px'
  text3.paddingTop = '4px'

  var text4 = new GUI.TextBlock()
  text4.text =
  temperature === 997? 'ERR': temperature === 998? 'N/D': temperature === 999? 'OFF':
    temperature === 'OFF' || temperature === 'N/D' || temperature === 'ERR'
      ? `${temperature}`
      : `${temperature}°C`
  text4.color = 'black'
  text4.fontSize = 12
  text4.textHorizontalAlignment = 'left'
  text4.resizeToFit = true
  text4.lineSpacing = '2px'
  text4.paddingTop = '4px'

  var text5 = new GUI.TextBlock()
  text5.text =
  humidity === 997? 'ERR': humidity === 998? 'N/D': humidity === 999? 'OFF':
    humidity === 'OFF' || humidity === 'N/D' || humidity === 'ERR'
      ? `${humidity}`
      : `${humidity}%`
  text5.color = 'black'
  text5.fontSize = 12
  text5.textHorizontalAlignment = 'left'
  text5.resizeToFit = true
  text5.lineSpacing = '2px'
  text5.paddingTop = '4px'

  var text8 = new GUI.TextBlock()
  text8.text = `     Estação\nMeteorológica`
  text8.color = 'black'
  text8.fontSize = 12
  text8.textHorizontalAlignment = 'center'
  text8.resizeToFit = true
  text8.lineSpacing = '2px'
  text8.paddingTop = '24px'

  var image = new GUI.Image('image', calendar)
  image.width = '20px'
  image.height = '20px'

  var image2 = new GUI.Image('image2', clock)
  image2.width = '20px'
  image2.height = '20px'

  var image3 = new GUI.Image('image3', grain)
  image3.width = '20px'
  image3.height = '20px'

  var image4 = new GUI.Image('image4', thermostat)
  image4.width = '20px'
  image4.height = '20px'

  var image5 = new GUI.Image('image5', water)
  image5.width = '20px'
  image5.height = '20px'

  var image8 = new GUI.Image('image8', line)
  image8.width = '80px'
  image8.height = '5px'

  const aux = 1

  painelInfoExibitorPanel1.addControl(image)
  painelInfoExibitorPanel1.addControl(text1)

  painelInfoExibitorPanel1.addControl(image2)
  painelInfoExibitorPanel1.addControl(text2)

  painelInfoExibitorPanel1.addControl(image3)
  painelInfoExibitorPanel1.addControl(text3)

  painelInfoExibitorPanel1.addControl(text8)
  painelInfoExibitorPanel1.addControl(image8)

  painelInfoExibitorPanel1.addControl(image4)
  painelInfoExibitorPanel1.addControl(text4)

  painelInfoExibitorPanel1.addControl(image5)
  painelInfoExibitorPanel1.addControl(text5)
}

// CRIA CENA DA VISUALIZAÇÃO 3D
const CriarCena = (
  engine,
  canvas,
  building,
  changeInfoProduct,
  changeViewProduct,
  station,
  layout,
  optionSelected,
  changeInfoStaircase,
  unitInfo,
  subareaSelected,
  seeTooltip
) => {
  // AS 5 VARIAVEIS ABAIXO SÃO UTILIZADAS PARA ALTERAR A CAMERA E O ANGULO DELA
  let targetArm = 0
  let valorProporcao = 0
  let valorPZ = 0
  let zPosArm = 3
  let xPos = 0
  let x = 0
  let y = 80
  let z = 40
  // console.log('TIPO ==> '+ building.type)
  // TIPOS ARMAZEM V, ARMAZEM SEMI V, ARMAZEM PLANO
  if (
    building.type === 5 ||
    building.type === 4 ||
    building.type === 3 ||
    building.type === 7 ||
    building.type === 17 ||
    building.type === 16
  ) {
    // console.log('HEIGHT ==> ' + building.height)
    // console.log('Arcos ==> ' + layout.arcs.length)
    valorProporcao = (layout.arcs.length / 4) * 6
    // console.log('VALOR PROPORÇÃO ==> ' + valorProporcao)
    valorPZ = (layout.arcs.length / 4) * 5
    zPosArm = 4
    xPos = 100
    targetArm = 28
    // TIPO BUNKER FUNDO V
  } else if (building.type === 15) {
    valorProporcao = building.height * 6
    valorPZ = building.diameter / 3
    // TODOS OS OUTROS TIPOS
  } else if (building.type === 14) {
    valorProporcao = building.height * 3
    valorPZ = building.diameter / 3
    // TODOS OS OUTROS TIPOS
  } else if (
    building.type === 1 ||
    building.type === 2 ||
    building.type === 9 ||
    building.type === 10 ||
    building.type === 11
  ) {
    zPosArm = 2.5
    valorProporcao = building.height
    valorPZ = building.height
    // TODOS OS OUTROS TIPOS
  } else {
    valorProporcao = building.height
    valorPZ = building.height
  }

  // CRIA A CENA (QUADRADO QUE CONTEM TODO O 3D)
  // console.log('VAMOS CRIAR O 3D - ENGINE')
  // console.log(engine)
  const scene = new BABYLON.Scene(engine)
  // CRIA CAMERA E ANGULO DA CAMERA
  const camera = new BABYLON.ArcRotateCamera(
    'camera',
    0,
    0,
    0,
    new BABYLON.Vector3(targetArm, valorProporcao / 2, 0),
    scene
  )
  // console.log('VAMOS CRIAR A CAMERA - CAMERA')
  // console.log(camera)
  camera.checkCollisions = true
  // zoom
  camera.wheelPrecision = 60
  // camera.setPosition(new BABYLON.Vector3(0, valorProporcao * 0.8, valorProporcao * 2));
  // SETA POSIÇÃO DA CAMERA
  //camera.setPosition(new BABYLON.Vector3(xPos, valorProporcao * 1.2, valorPZ * zPosArm));
  camera.setPosition(
    new BABYLON.Vector3(xPos, valorProporcao * 1.2, valorPZ * zPosArm)
  )
  // Gravo uma posição inicial da câmera
  const cameraReset = {
    // position: new BABYLON.Vector3(0, valorProporcao * 0.8, valorProporcao * 2),
    position: new BABYLON.Vector3(
      xPos,
      valorProporcao * 1.2,
      valorPZ * zPosArm
    ),
    target: new BABYLON.Vector3(targetArm, valorProporcao / 2, 0)
  }

  const valorPadraoParaCameras = {
    // position: new BABYLON.Vector3(0, valorProporcao * 0.8, valorProporcao * 2),
    position: new BABYLON.Vector3(
      xPos,
      valorProporcao * 1.2,
      valorPZ * zPosArm
    ),
    target: new BABYLON.Vector3(targetArm, valorProporcao / 2, 0)
  }
  camera.attachControl(canvas, true)

  // AS 2 VARIAVEIS ABAIXO DEFINEM A SENSIBILIDADE AO CLICAR NA TELA
  // camera.inertia = 0.16;
  camera.inertia = 0.16
  camera.wheelDeltaPercentage = 0.02
  // camera.lowerRadiusLimit = 100;
  // camera.lowerAlphaLimit = 0;
  // camera.lowerBetaLimit = 0;
  // camera.lowerRadiusLimit = 0;

  // camera.upperBetaLimit = 450;
  camera.upperRadiusLimit = 450
  // camera,checkCollisions = true;
  // Luz Ambiente
  CriaLuzAmbiente(scene, 1.7)

  // Painel de Opções de Câmera
  CriaPaineisCameras(
    building.type,
    engine,
    camera,
    cameraReset,
    changeInfoProduct,
    changeViewProduct,
    scene,
    valorPadraoParaCameras,
    xPos,
    valorProporcao * 1.2,
    valorPZ * zPosArm,
    building,
    layout,
    changeInfoStaircase,
    unitInfo,
    subareaSelected,
    building.diameter + 10
  )

  CriaPainelTooltip(engine)

  // Céu
  if (optionSelected !== undefined) {
    CriaCeu(scene, optionSelected)
  } else {
    CriaCeu(scene, 0)
  }

  return [scene, cameraReset, camera]
}

export default CriarCena
