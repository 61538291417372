import React from 'react';
import '../../../../../App.css';
import { Redirect } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import BuildIcon from '@material-ui/icons/Build';
import RouterIcon from '@material-ui/icons/Router';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import FockinkAPI from '../../../../../middlewares/Api';
import Equips from './equips/Equips';
import AdminPermissions from '../../../../../helpers/enums/AdminPermissions';
import { hasPermission } from '../../../../../helpers/permission';
import DeleteTrashButton from '../../../../../components/Buttons/DeleteTrashButton';
// import { formatDate } from '../../../../../helpers/Intl';

createTheme('plantTable', {
  background: {
    default: '#F7F7F7',
  },
});

export default (props) => {
  const [inputs, setInputs] = React.useState([]);
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const ordData = JSON.parse(localStorage.getItem('ordPlantsOrganization'));
  const pageData = JSON.parse(localStorage.getItem('pagePlantsOrganization'));
  const rowsData = JSON.parse(localStorage.getItem('rowsPlantsOrganization'));

  // methods
  const setPage = (pageVal) => {
    const dataSort = {
      page: pageVal,
    };
    localStorage.setItem('pagePlantsOrganization', JSON.stringify(dataSort));
  };
  const setOrdenation = (ordenationVal, directionVal) => {
    const dataSort = {
      ordenation: ordenationVal,
      direction: directionVal,
    };
    localStorage.setItem('ordPlantsOrganization', JSON.stringify(dataSort));
  };
  const setRowsPage = (rowsVal) => {
    const dataSort = {
      rows: rowsVal,
    };
    localStorage.setItem('rowsPlantsOrganization', JSON.stringify(dataSort));
  };

  React.useEffect(() => {
    setLoading(true);
    FockinkAPI.get('/plants', { params: { accountId: props.accountId } }).then(
      (response) => {
        setInputs(response.data.data);
        setLoading(false);
      },
    );
  }, []);

  const expandableRowExpanded = (data) => props.search.plantId === data.id;
  const onRowExpandToggled = (open, data) => {
    if (open) {
      props.setSearch({
        accountId: props.accountId,
        plantId: data.id,
        equipmentId: undefined,
      });
    }
    return true;
  };

  const ExpandedComponent = ({ data }) => (
    <div style={{ background: '#F7F7F7' }}>
      <Equips
        plantId={data.id}
        accountId={props.accountId}
        organizationId={props.organizationId}
        operationId={data.operationId}
        search={props.search}
        setSearch={props.setSearch}
      />
    </div>
  );

  React.useEffect(() => {
    const columns = [
      {
        name: 'Identificação Fockink',
        selector: 'technicalDescription',
        sortable: true,
      },
      { name: 'Nome', selector: 'description', sortable: true },
      {
        name: 'Endereço',
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {row.location
              ? `${row.location.address1} ${row.location.address2} ${row.location.city} ${row.location.state} ${row.location.country} ${row.location.postalCode}`
              : '-'}
          </div>
        ),
      },
      {
        name: 'Status',
        center: true,
        sortable: true,
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {row.status === 'A' ? 'Ativo' : 'Inativo'}
          </div>
        ),
      },
    ];

    if (hasPermission(AdminPermissions.COMMUNICATORS_VIEW.value)) {
      columns.push({
        name: 'Comunicadores',
        center: true,
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton
              color="secondary"
              onClick={() =>
                setRedirect(
                  `/organization/${props.organizationId}/account/${props.accountId}/plant/${row.id}/comm`,
                )
              }
            >
              <RouterIcon />
            </IconButton>
          </div>
        ),
      });
    }

    columns.push({
      name: 'Ações',
      center: true,
      grow: 2,
      cell: (row) => (
        <div
          data-tag="allowRowEvents"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          {hasPermission(AdminPermissions.PLANTS_VIEW.value) && (
            <Tooltip title="Ver Planta">
              <IconButton
                color="secondary"
                onClick={() =>
                  setRedirect(
                    `/organization/${props.organizationId}/account/${props.accountId}/plant/${row.id}/view`,
                  )
                }
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
          )}
          {hasPermission(AdminPermissions.PLANTS_MANAGE.value) && (
            <>
              <Tooltip title="Editar Planta">
                <IconButton
                  color="secondary"
                  onClick={() =>
                    setRedirect(
                      `/organization/${props.organizationId}/account/${props.accountId}/plant/${row.id}`,
                    )
                  }
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Apagar Planta">
                <DeleteTrashButton url={`/plants/${row.id}`} />
              </Tooltip>
            </>
          )}
          {hasPermission(AdminPermissions.EQUIPAMENTS_MANAGE.value) && (
            <Tooltip title="Novo Equipamento">
              <IconButton
                color="secondary"
                onClick={() =>
                  setRedirect(
                    `/organization/${props.organizationId}/account/${props.accountId}/plant/${row.id}/equip/new?op=${row.operationId}`,
                  )
                }
              >
                <BuildIcon />
                <AddIcon
                  style={{ position: 'absolute', left: '4px', bottom: '3px' }}
                />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
    });
    setDtCl({ columns, data: inputs });
  }, [inputs]);

  if (redirect) return <Redirect push to={redirect} />;

  if (loading) {
    return (
      <Skeleton variant="rect" height={500} width="100%" animation="wave" />
    );
  }

  return (
    <DataTable
      theme="plantTable"
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Linhas:',
        rangeSeparatorText: 'de',
      }}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
      onChangePage={(e) => setPage(e)}
      onSort={(e, x) => setOrdenation(e.selector, x)}
      paginationDefaultPage={parseInt(pageData?.page || 1, 10)}
      onChangeRowsPerPage={(e) => setRowsPage(e)}
      noDataComponent="Não existem dados para serem mostrados"
      expandableRows
      columns={dtCl.columns}
      data={dtCl.data}
      expandableRowsComponent={<ExpandedComponent />}
      expandableRowExpanded={expandableRowExpanded}
      onRowExpandToggled={onRowExpandToggled}
      title="Plantas"
      defaultSortField={ordData?.ordenation}
      defaultSortAsc={ordData?.direction !== 'desc'}
      paginationPerPage={rowsData?.rows || 5}
    />
  );
};
