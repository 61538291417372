import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import EditIcon from '@material-ui/icons/Edit';
import { Redirect } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import FockinkAPI from '../../../../middlewares/Api';
import Breadcrumbs from '../../../../components/Breadcrumbs';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: 30,
  },
});

const PlansList = () => {
  const [dataColumns, setDataColumns] = useState({ columns: [], data: [] });
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [data, setData] = useState();
  const classes = useStyles();

  const breadcrumbs = {
    routes: [
      { name: 'Configurações', to: '/settings' },
      { name: 'Cadastro de Planos' },
    ],
    title: 'Cadastro de Planos',
  };
  const systemsTranslate = {
    1: 'irrigação',
    2: 'Termometria',
    3: 'Automação',
    4: 'Fotovoltaica',
    5: 'Estação Metereológica',
  };

  useEffect(() => {
    FockinkAPI.get('/plan').then((response) => {
      setData(response.data.data);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const defaultColumns = [
      {
        name: 'Nome',
        selector: 'planName',
        cell: (row) => row.planName,
        sortable: true,
      },
      {
        name: 'Sistemas Atendidos',
        selector: 'systems',
        // cell: (row) => row.systems,
        cell: (row) => row.systems.map((s) => systemsTranslate[s]).join(','),
        sortable: true,
      },
      {
        name: 'Período de Validade',
        selector: 'validMonths',
        cell: (row) => `${row.validMonths} meses`,
        sortable: true,
      },
      {
        name: 'Frequência de Faturamento',
        selector: 'status',
        cell: (row) => row.invoiceFrequency === 'eachMonth' ? 'Mensal' :
          row.invoiceFrequency === 'each12Months' ? 'Anual ' :
            row.invoiceFrequency === 'each2Months' ? 'Bimestral' :
              row.invoiceFrequency === 'each3Months' ? 'Trimestral' :
                row.invoiceFrequency === 'each4Months' ? 'Quadrimestral' :
                  row.invoiceFrequency === 'each6Months' ? 'Semestral' :
                    '',
        sortable: true,
      },
      {
        name: 'Dia de Fatura',
        selector: 'invoiceDay',
        cell: (row) => row.invoiceDay,
        sortable: true,
      },
      {
        name: 'Bloquear depois da Expiração',
        selector: 'lockAfterExpiration',
        cell: (row) => row.lockAfterExpiration === true ? 'Ativo' : 'Inativo',
        sortable: true,
      },
      {
        name: 'Limitação de Cadastro de Usuários',
        selector: 'userLimitation',
        cell: (row) => row.userLimitation === true ? 'Ativo' : 'Inativo',
        sortable: true,
      },
      {
        name: 'Código do Item',
        selector: 'fockinkId',
        cell: (row) => row.fockinkId,
        sortable: true,
      },
      {
        name: 'Habilita Operação',
        selector: 'cloudToDevice',
        cell: (row) => row.cloudToDevice === true ? 'Ativo' : 'Inativo',
        sortable: true,
      },
      {
        name: 'Status',
        selector: 'status',
        cell: (row) => row.status === 'A' ? 'Ativo' : 'Inativo',
        sortable: true,
      },
      {
        name: '',
        center: true,
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton color="secondary" onClick={() => setRedirect(row.id)}>
              <EditIcon />
            </IconButton>
          </div>
        ),
      },
    ];
    setDataColumns({ columns: defaultColumns, data });
  }, [data]);

  if (redirect) {
    return <Redirect push to={`/settings/plans/${redirect}`} />;
  }
  return (
    <div>
      <Breadcrumbs
        routes={breadcrumbs.routes}
        title={breadcrumbs.title}
        newBtn="Cadastrar plano"
      />

      {
       loading ?
         (
           <div className={classes.root}>
             <Skeleton />
             <Skeleton animation={false} />
             <Skeleton animation="wave" />
             <Skeleton />
             <Skeleton animation={false} />
             <Skeleton animation="wave" />
             <Skeleton />
             <Skeleton animation={false} />
             <Skeleton animation="wave" />
           </div>
         )
         :
         (
           <div>
             <DataTable
               pagination
               paginationPerPage={15}
               paginationComponentOptions={{
                 rowsPerPageText: 'Linhas:',
                 rangeSeparatorText: 'de',
               }}
               paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
               noDataComponent="Não existem dados para serem mostrados"
               columns={dataColumns.columns}
               data={dataColumns.data}
               defaultSortField="plantName"
               defaultSortAsc={false}
               title=""
             />
           </div>
         )
      }

    </div>
  );
};

export default PlansList;
