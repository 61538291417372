import React from 'react';
import '../../App.css';
import FormControl from '@material-ui/core/FormControl';
// import FilledInput from '@material-ui/core/FilledInput';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
// import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import LockOutlined from '@material-ui/icons/LockOutlined';
import LockOpenOutlined from '@material-ui/icons/LockOpenOutlined';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export default (props) => {
  const [locked, setLocked] = React.useState(true);
  const MySwal = withReactContent(Swal);

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const lockUnlock = () => {
    if (locked) {
      MySwal.fire({
        title: 'Deseja desbloquear o campo?',
        text: props.unlockText,
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Manter Bloqueado',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonColor: '#d33',
        cancelButtonText: 'Desbloquear',
      }).then((result) => {
        if (result.isDismissed) setLocked(false);
      });
    } else {
      setLocked(true);
    }
  };

  return (
    <FormControl variant="outlined" style={props.style}>
      <TextField
        variant="outlined"
        name={props.name}
        id={props.name}
        label={props.label}
        // type={locked ? 'text' : 'password'}
        disabled={locked}
        value={props.value}
        onChange={props.handleChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={lockUnlock}
                onMouseDown={handleMouseDownPassword}
              >
                {locked ? <LockOutlined /> : <LockOpenOutlined />}
              </IconButton>
            </InputAdornment>),
        }}
      />
    </FormControl>
  );
};
