import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: calc(100vh - 150px);
`;

export const Content = styled.div`
  flex: 1;
`;

export const Options = styled.div`
  margin: 2.5rem 0;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  right: 5rem;
  bottom: 0;
  padding: 0 14px;

`;
