import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import BuildIcon from '@material-ui/icons/Build';
import Swal from 'sweetalert2';
import RouterIcon from '@material-ui/icons/Router';
import withReactContent from 'sweetalert2-react-content';
import InputAdornment from '@material-ui/core/InputAdornment';
import {
  RiFileCopyLine,
  RiListSettingsLine,
  RiCalendarCheckLine,
} from 'react-icons/ri';
import Spacer from '../../../../../../components/Spacer';
import ConnectedAcordeon from '../../../../../../components/ConnectedAcordeon';
import FockinkAPI from '../../../../../../middlewares/Api';
import BottomButtons from '../../../../../../components/Buttons/BottomButtons';
import Breadcrumbs from '../../../../../../components/Breadcrumbs';
import AdminPermissions from '../../../../../../helpers/enums/AdminPermissions';
import Card from '../../../../../../components/Card';
import CardTitle from '../../../../../../components/CardTitle';
import CardText from '../../../../../../components/CardText';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  noMargin: {
    margin: '0',
  },
  subTitle: {
    borderBottom: '1px solid #ccc',
    textAlign: 'center',
    paddingBottom: '0 !important',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

export default (props) => {
  const [loading, setLoading] = React.useState(true);
  const [tab, setTab] = React.useState(0);

  const [data, setData] = React.useState({
    description: '',
    technicalDescription: '',
    operationId: '',
    // alias: '',
    status: 'E',
  });

  const breadcrumb = {
    title: `Comunicador: ${data.description}`,
    routes: [
      { to: '/organization', name: 'Organizações' },
      { to: `/organization/${props.match.params.orgId}`, name: 'Organização' },
      {
        to: `/organization/${props.match.params.orgId}/account/${props.match.params.accId}`,
        name: 'Comunicador',
      },
      { name: 'Cadastro de Comunicador' },
    ],
  };

  const authTypes = {
    symmetricKey: 'Chave Simétrica',
    'symmetricKey+SAS': 'Chave Simétrica + SAS Token',
    selfSigned: 'Certificado Autoassinado',
  };

  const activeInactive = [
    {
      value: 'E',
      name: 'Ativo',
    },
    {
      value: 'D',
      name: 'Inativo',
    },
  ];

  const MySwal = withReactContent(Swal);
  const copyToClipBoard = async (copyMe, title) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      MySwal.fire({
        icon: 'success',
        title,
        text: 'Texto copiado',
      });
    } catch (err) {
      MySwal.fire({
        icon: 'error',
        title,
        text: 'Texto não copiado',
      });
    }
  };

  React.useEffect(() => {
    if (props.match.params.id === 'new') {
      setData({
        description: '',
        technicalDescription: '',
        operationId: '',
        // alias: '',
        authMode: 'selfSigned',
        status: 'E',
      });
      setLoading(false);
    } else {
      setLoading(true);
      FockinkAPI.get(`/communicators/${props.match.params.id}`).then(
        (response) => {
          setData({
            ...response.data.data,
            activationDate: new Date(response.data.data.activationDate * 1000),
            expirationDate: new Date(response.data.data.expirationDate * 1000),
          });
          setLoading(false);
        },
      );
    }
  }, [props.match.params.id]);

  const classes = useStyles();

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const saveData = () => {
    const dt = {
      data: {
        plantId: props.match.params.plId,
        description: data.description,
        technicalDescription: data.technicalDescription,
        operationId: parseInt(data.operationId, 10),
        authMode: data.authMode,
        tokenTime: data.tokenTime,
        status: data.status,
      },
    };
    if (props.match.params.id === 'new') {
      dt.method = 'post';
      dt.url = '/communicators';
    } else {
      dt.method = 'put';
      dt.url = `/communicators/${props.match.params.id}`;
    }
    return dt;
  };

  const deleteData = () => ({ url: `/communicators/${props.match.params.id}` });
  if (loading) {
    return (
      <Skeleton variant="rect" height={500} width="100%" animation="wave" />
    );
  }

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Paper square className={classes.root}>
            <Tabs
              value={tab}
              onChange={(event, newValue) => setTab(newValue)}
              variant="fullWidth"
              indicatorColor="secondary"
              textColor="secondary"
            >
              <Tab icon={<RouterIcon />} label="Dados" />
              {props.match.params.id !== 'new' && (
                <Tab icon={<BuildIcon />} label="Últimos Equipamentos" />
              )}
            </Tabs>
          </Paper>
        </Grid>

        {tab === 0 && (
          <>
            <Grid item xs={12}>
              <Card>
                <CardTitle>
                  Identificação
                  <Spacer />
                </CardTitle>
                <CardText>
                  <Grid container spacing={3}>
                    <Grid item lg={4} md={4} xs={12}>
                      <TextField
                        variant="outlined"
                        name="description"
                        onChange={handleChange}
                        value={data.description || ''}
                        label="Descrição"
                      />
                    </Grid>

                    <Grid item lg={4} md={4} xs={6}>
                      <FormControl variant="outlined" color="secondary">
                        <InputLabel id="operationIdLabel">Sistema</InputLabel>
                        <Select
                          value={data.operationId}
                          onChange={handleChange}
                          name="operationId"
                          id="operationId"
                          labelId="operationIdLabel"
                          label="Sistema"
                        >
                          <MenuItem value="1">Irrigação</MenuItem>
                          <MenuItem value="2">Termometria</MenuItem>
                          <MenuItem value="3">Automação Industrial</MenuItem>
                          <MenuItem value="4">Fotovoltaica</MenuItem>
                          <MenuItem value="5">Estação Meteorológica</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item lg={4} md={4} xs={12}>
                      <FormControl variant="outlined">
                        <InputLabel id="statusLabel">Status</InputLabel>
                        <Select
                          labelId="statusLabel"
                          id="status"
                          name="status"
                          value={data.status}
                          onChange={handleChange}
                          label="Status"
                        >
                          {
                              activeInactive.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              ))
                            }

                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </CardText>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardTitle>
                  Autenticação
                  <Spacer />
                </CardTitle>
                <CardText>
                  {props.match.params.id === 'new' ? (
                    <Grid container spacing={3}>
                      <Grid item lg={4} md={4} xs={12}>
                        <FormControl variant="outlined">
                          <InputLabel id="modeLabel">Modo de Autenticação</InputLabel>
                          <Select
                            value={data.authMode}
                            onChange={handleChange}
                            name="authMode"
                            id="authMode"
                            idLabel="modeLabel"
                            label="Modo de Autenticação"
                            startAdornment={(
                              <InputAdornment position="start">
                                <RiListSettingsLine size={20} />
                              </InputAdornment>
                              )}
                          >
                            {Object.keys(authTypes).map((v) => (
                              <MenuItem value={v}>{authTypes[v]}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      {data.authMode !== 'symmetricKey' ? (
                        <Grid item lg={4} md={4} xs={12}>
                          <TextField
                            variant="outlined"
                            name="tokenTime"
                            onChange={handleChange}
                            value={data.tokenTime}
                            label="Dias Válidos Token"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <RiCalendarCheckLine size={20} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  ) : (
                    <>
                      <Grid container spacing={3}>
                        <Grid item lg={4} md={4} xs={12}>
                          <TextField
                            variant="outlined"
                            name="devideId"
                            value={data.authentication.deviceId || '-'}
                            label="DeviceId"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  onClick={() => copyToClipBoard(data.authentication.deviceId, 'DeviceId')}
                                >
                                  <RiFileCopyLine size={20} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                          <TextField
                            variant="outlined"
                            name="method"
                            value={authTypes[data.authentication.type] || '-'}
                            label="Método"
                          />
                        </Grid>
                        {data.authentication.primaryConnectionString && (
                        <>
                          <Grid item lg={12} md={4} xs={12}>
                            <TextField
                              variant="outlined"
                              name="primaryConnectionString"
                              value={data.authentication.primaryConnectionString || '-'}
                              label="Primary Connection String"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    onClick={() => copyToClipBoard(data.authentication.primaryConnectionString, 'Primary Connection String')}
                                  >
                                    <RiFileCopyLine size={20} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </>
                        )}
                        {data.authentication.secondaryConnectionString && (
                        <>
                          <Grid item lg={12} md={4} xs={12}>
                            <TextField
                              variant="outlined"
                              name="secondaryConnectionString"
                              value={data.authentication.secondaryConnectionString || '-'}
                              label="Secondary Connection String"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    onClick={() => copyToClipBoard(data.authentication.secondaryConnectionString, 'Secondary Connection String')}
                                  >
                                    <RiFileCopyLine size={20} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Grid>
                        </>
                        )}
                        {data.authentication.sasToken ? (
                          <>
                            <Grid item lg={12} md={4} xs={12}>
                              <TextField
                                variant="outlined"
                                name="sasToken"
                                value={data.authentication.sasToken || '-'}
                                label="SAS Token"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      onClick={() => copyToClipBoard(data.authentication.sasToken, 'SAS Token')}
                                    >
                                      <RiFileCopyLine size={20} />
                                    </InputAdornment>
                                  ),
                                }}

                              />
                            </Grid>
                          </>
                        ) : (
                          <></>
                        )}

                        {data.authentication.selfsignedKeys &&
                    data.authentication.selfsignedKeys.primary &&
                    data.authentication.selfsignedKeys.primary.cert && (
                      <>
                        <Grid item lg={6} md={4} xs={12}>
                          <TextField
                            multiline
                            variant="outlined"
                            name="primaryCertificate"
                            value={data.authentication.selfsignedKeys.primary.cert}
                            label="Certificado Primário"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  onClick={() => copyToClipBoard(data.authentication.selfsignedKeys.primary.cert, 'Certificado Primário')}
                                >
                                  <RiFileCopyLine size={20} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </>
                        )}

                        {data.authentication.selfsignedKeys &&
                    data.authentication.selfsignedKeys.primary &&
                    data.authentication.selfsignedKeys.primary.private && (
                      <>
                        <Grid item lg={6} md={4} xs={12}>
                          <TextField
                            multiline
                            variant="outlined"
                            name="primaryCertificatePrivate"
                            value={data.authentication.selfsignedKeys.primary.private}
                            label="Certificado Primário Privado"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  onClick={() => copyToClipBoard(data.authentication.selfsignedKeys.primary.private, 'Certificado Primário Privado')}
                                >
                                  <RiFileCopyLine size={20} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </>
                        )}

                        {data.authentication.selfsignedKeys &&
                    data.authentication.selfsignedKeys.secondary &&
                    data.authentication.selfsignedKeys.secondary.cert && (
                      <>
                        <Grid item lg={6} md={4} xs={12}>
                          <TextField
                            multiline
                            variant="outlined"
                            name="secondaryCertificate"
                            value={data.authentication.selfsignedKeys.secondary.cert}
                            label="Certificado Secundário"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  onClick={() => copyToClipBoard(data.authentication.selfsignedKeys.secondary.cert, 'Certificado Secundário')}
                                >
                                  <RiFileCopyLine size={20} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </>

                        )}

                        {data.authentication.selfsignedKeys &&
                    data.authentication.selfsignedKeys.secondary &&
                    data.authentication.selfsignedKeys.secondary.private && (
                      <>
                        <Grid item lg={6} md={4} xs={12}>
                          <TextField
                            multiline
                            variant="outlined"
                            name="secondaryCertificatePrivate"
                            value={data.authentication.selfsignedKeys.secondary.private}
                            label="Certificado Secundário Privado"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment
                                  onClick={() => copyToClipBoard(data.authentication.selfsignedKeys.secondary.private, 'Certificado Secundário Privado')}
                                >
                                  <RiFileCopyLine size={20} />
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      </>
                      // eslint-disable-next-line indent
                    )}
                      </Grid>
                    </>
                  )}
                </CardText>
              </Card>
            </Grid>
          </>
        )}

        {tab === 1 && (
          <>
            <Grid item xs={12}>
              {props.match.params.id !== 'new' ? (
                <Grid item xs={12}>
                  <ConnectedAcordeon
                    rows={data.equipaments}
                    title="Equipamentos"
                  />
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </>
        )}

        <Grid
          item
          xs={12}
          className={`${classes.textAlign} ${classes.bottomButtons}`}
        >
          <BottomButtons
            saveData={tab === 0 && saveData}
            deleteData={
              tab === 0 && props.match.params.id !== 'new' && deleteData
            }
            backTo={`/organization/${props.match.params.orgId}/account/${props.match.params.accId}/plant/${props.match.params.plId}/comm`}
            canSave={AdminPermissions.COMMUNICATORS_MANAGE.value}
          />
        </Grid>
      </Grid>
    </>
  );
};
