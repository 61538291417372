import React from 'react';
import { useTheme } from 'styled-components';
import { Ellipsis } from 'react-spinners-css';
import { useHistory } from 'react-router-dom';
import { ProgressBarWithLabel } from '../../../../ProgressBarWithLabel';
import { formatDate } from '../../../../../helpers/Intl';

import * as S from './styles';
import { ChartUnitsCard } from '../ChartUnitsCard';
import { useDashboardContext } from '../../../../../hooks/dashboard';
import { formatName } from '../../../helpers';

export function StorageUnitInfoCard({
  name,
  subarea,
  plantId,
  temperature,
  percent,
  series,
  grain,
  existData,
  equipamentId,
}) {
  const originalTheme = useTheme();

  const history = useHistory();

  const {
    levelTemperatureChartData,
    setPlantToRequestData,
  } = useDashboardContext();

  const [width, setWidth] = React.useState(window.innerWidth);

  const formatLevelTemperatureChartData = () => {
    const chartData = levelTemperatureChartData.find(
      (data) => data.sub === subarea && data.nameUnit === name,
    );

    if (chartData) {
      for (const date of chartData.dates) {
        date.sub = subarea;
        date.nameUnit = name;
      }

      return chartData.dates;
    }

    return [];
  };

  React.useEffect(() => {
    if (window) {
      const resize = () => {
        if (window.innerWidth) {
          setWidth(window.innerWidth);
        }
      };
      window.addEventListener('resize', resize);

      return () => {
        window.removeEventListener('resize', resize);
      };
    }
  }, [window, window.opener, window.screen, navigator.userAgent.match]);

  return (
    <S.Container
      onClick={() => setPlantToRequestData(plantId, name)}
      style={{
        marginRight: !existData ? '25%' : '',
        marginLeft: !existData ? '25%' : '',
      }}
      size={width}
    >
      <S.NameContainer>{formatName(name, subarea)}</S.NameContainer>

      {temperature ? (
        <>
          <S.Header>
            <S.ContainerTemperature>
              <S.Temperature size={width}>
                {temperature.toFixed(1)}
                ºC
              </S.Temperature>
              <S.LegendTemperature>Média interna</S.LegendTemperature>

              <S.DateTemperature>
                {series &&
                  series.length > 0 &&
                  formatDate(
                    series[series.length - 1].date,
                    'DD/MM/YYYY HH:mm',
                  )}
              </S.DateTemperature>
            </S.ContainerTemperature>
            <ProgressBarWithLabel
              trailColor={originalTheme.colors.blueLight}
              r="84"
              g="178"
              b="255"
              percent={percent}
            />
          </S.Header>
          {levelTemperatureChartData ? (
            <S.DivChart>
              {series && series.length > 0 && (
                <ChartUnitsCard
                  isDashboard
                  series={formatLevelTemperatureChartData()}
                />
              )}
            </S.DivChart>
          ) : (
            <Ellipsis />
          )}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <div>
            {grain === 'Vazio' ? (
              <p className="p-info">Produto não configurado</p>
            ) : (
              <p className="p-info">Não foi possivel calcular o nível</p>
            )}
          </div>
        </div>
      )}

      {equipamentId && (
        <button
          type="button"
          style={{ fontSize: 14.5 }}
          onClick={() => history.push(`thermometryDash/${equipamentId}`)}
        >
          Visualizar Equipamento
        </button>
      )}
    </S.Container>
  );
}
