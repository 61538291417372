import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import FockinkAPI from '../../../../../../../../../middlewares/Api';
import { useAuth } from '../../../../../../../../../hooks/account';
import {
  ButtonComponent as Button,
  DatePicker,
} from '../../../../../../../../../components';
import { formatDate } from '../../../../../../../../../helpers/Intl';
import { validationSchema } from './validationSchema';
import parseFloatCustom from '../../../../../../../../../helpers/parseFloatCustom';

import * as S from './styles';
import { Input } from '../../../../../../../../../components/Input';

export function AssociateCultureForm({
  handleGetCultures,
  handleCloseAssociateCultureFormModal,
}) {
  const { user } = useAuth();
  const { id } = useParams();

  const [date, setDate] = React.useState();
  const [cultures, setCultures] = React.useState();
  const [success, setSuccess] = React.useState();
  const [errorRequest, setErrorRequest] = React.useState();

  React.useEffect(() => {
    (async () => {
      const response = await FockinkAPI.get('/cultivationAccount/', {
        params: {
          accountId: user.accountId,
        },
      });

      const availableCultures = response.data.data.filter(
        (c) => c.status === 'A',
      );
      setCultures(availableCultures);
    })();
  }, []);

  const formik = useFormik({
    initialValues: {
      cultivationAccountId: '',
      plantingDate: '',
      angleFrom: '',
      angleTo: '',
      maturationGroup: '',
      averageDensity: '',
      distancing: '',
      observation: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const data = {
          equipamentId: id,
          cultivationAccountId: values.cultivationAccountId,
          plantingDate: values.plantingDate,
          angleFrom: values.angleFrom,
          angleTo: values.angleTo,
          maturationGroup: parseFloatCustom(values.maturationGroup),
          averageDensity: parseFloatCustom(values.averageDensity),
          distancing: parseFloatCustom(values.distancing),
          observation: values.observation,
          status: 'A',
        };

        if (data.observation === '') delete data.observation;

        await FockinkAPI.post('cultivation', data);

        setSuccess(true);
        handleGetCultures();
        formik.resetForm();

        setTimeout(() => {
          setSuccess(false);
          handleCloseAssociateCultureFormModal();
        }, 4000);
      } catch (error) {
        setErrorRequest(JSON.parse(error.request.response).message);
        setTimeout(() => {
          setErrorRequest();
        }, 3000);
      }
    },
  });

  const handleSelectCulture = (event) => {
    formik.setValues({
      ...formik.values,
      cultivationAccountId: event.target.value,
    });
  };

  const handleDateChange = (haverstDateSelect) => {
    const formatHaversDate = formatDate(haverstDateSelect, 'yyyy-MM-dd');
    setDate(formatHaversDate);
    formik.setValues({ ...formik.values, plantingDate: formatHaversDate });
  };

  return (
    <S.Wrapper>
      <div className="header">
        <h2>Nova cultura</h2>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <FormControl
          variant="outlined"
          error={!!formik.errors.cultivationAccountId}
        >
          <InputLabel>Cultura</InputLabel>
          <Select
            native
            value={formik.values.cultivationAccountId}
            onChange={handleSelectCulture}
            label="Cultura"
            inputProps={{
              name: 'culture',
            }}
          >
            <option aria-label="None" value="" />
            {cultures &&
              cultures.map((cultureData) => (
                <option key={cultureData.id} value={cultureData.id}>
                  {cultureData.culture}
                </option>
              ))}
          </Select>
          <FormHelperText>{formik.errors.cultivationAccountId}</FormHelperText>
        </FormControl>

        <div className="date-picker">
          <DatePicker
            error={formik.errors.plantingDate}
            selectedDate={date}
            label="Data do Plantio"
            handleDateChange={handleDateChange}
          />
        </div>

        <div className="angles-field">
          <Input
            name="angleFrom"
            value={formik.values.angleFrom}
            onChange={formik.handleChange}
            label="Ângulo inicial"
            error={formik.errors.angleFrom}
            type="number"
          />

          <Input
            type="number"
            style={{ marginLeft: '0.5rem' }}
            name="angleTo"
            value={formik.values.angleTo}
            onChange={formik.handleChange}
            label="Ângulo final"
            error={formik.errors.angleTo}
          />
        </div>

        <Input
          name="maturationGroup"
          value={formik.values.maturationGroup}
          onChange={formik.handleChange}
          label="Grupo de Maturação"
          error={formik.errors.maturationGroup}
          type="number"
        />
        <Input
          name="averageDensity"
          value={formik.values.averageDensity}
          onChange={formik.handleChange}
          label="Densidade Média"
          error={formik.errors.averageDensity}
          type="number"
        />
        <Input
          name="distancing"
          value={formik.values.distancing}
          onChange={formik.handleChange}
          label="Distanciamento"
          error={formik.errors.distancing}
          type="number"
        />
        <Input
          name="observation"
          value={formik.values.observation}
          onChange={formik.handleChange}
          label="Observações"
          error={formik.errors.observation}
        />

        <div className="button-confirm">
          {success ? (
            <strong className="success">Sucesso!</strong>
          ) : errorRequest ? (
            <strong className="error">{errorRequest}</strong>
          ) : (
            <Button
              isDisabled={
                !!formik.errors.plantingDate ||
                !!formik.errors.angleFrom ||
                !!formik.errors.angleTo ||
                !!formik.errors.cultivationAccountId
              }
              type="submit"
            >
              cadastrar
            </Button>
          )}
        </div>
      </form>
    </S.Wrapper>
  );
}
