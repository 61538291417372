/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Basic
import React, { useRef } from 'react';
import { Redirect } from 'react-router-dom';

// UI, images, styles and icons
import '../../../App.css';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FilledInput from '@material-ui/core/FilledInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { Alert } from '@material-ui/lab';
import './styles.css';
import titleHelper from '../../../helpers/titleHelper';

// API
import FockinkAPI from '../../../middlewares/Api';
import { useAuth } from '../../../hooks/account';

// Random image
const currentSlide = Math.floor(Math.random() * 2) + 1;

export default () => {
  // States
  const [iconData, setIconData] = React.useState(undefined);
  const [loginMethod, setLoginMethod] = React.useState(undefined);
  const [redirect, setRedirect] = React.useState(false);
  const [forgot, setForgot] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [credentials, setCredentials] = React.useState({
    login: '',
    password: '',
  });

  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const { signIn } = useAuth();

  // Lifecycle methods
  React.useEffect(() => {
    titleHelper('Login');
    const urlParams = new URLSearchParams(window.location.search);
    const reason = urlParams.get('reason');

    if (reason) {
      let message = '';
      switch (reason) {
        case '401':
          message = 'Sua sessão expirou - Entre novamente';
          break;
        default:
          message = 'Algo de errado aconteceu :/ Entre novamente';
      }
      setErrorMessage(message);
    }
  }, []);

  React.useEffect(() => {
    const userAlreadyLogged = localStorage[process.env.REACT_APP_STORAGE_KEY];
    if (userAlreadyLogged) {
      setRedirect(!redirect);
    }

    FockinkAPI.get('login/checkMethod').then((response) => {
      setLoginMethod(response.data.data.type);

      if (response.data.data.accountId && response.data.data.organizationId) {
        setIconData(
          `${process.env.REACT_APP_FILESTORAGE_API}/organizations/${
            response.data.data.organizationId
          }/accounts/${response.data.data.accountId}/logo.jpg?${Math.floor(
            Math.random() * 1000000000,
          )}`,
        );
      }

      if (response.data.data.type === 'simpleAzureADApp') {
        window.location.href = response.data.data.url;
      }
    });
  }, []);

  // Handle Methods
  const handleChange = (event) => {
    setCredentials({ ...credentials, [event.target.name]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const email = emailInputRef.current.value;
      const password = passwordInputRef.current.value;

      if (!email || !password) {
        setErrorMessage('Por favor, insira suas credenciais');
        return;
      }
      const success = await signIn(email, password);
      if (success) {
        setRedirect(true);
      } else {
        setErrorMessage(
          'Erro ao processar requisição. Tente novamente mais tarde',
        );
      }
    } catch (error) {
      switch (error.response.status) {
        case 400: // Bad request
          setErrorMessage(error.response.data.message);
          break;

        case 401:
          setErrorMessage('Login e/ou Senha inválidos');
          break;

        case 404: // Not Found
          setErrorMessage('Login e/ou Senha inválidos');
          break;

        default:
          setErrorMessage(
            error?.response?.data?.message || 'Erro ao processar requisição. Tente novamente mais tarde',
          );
      }
    }
  };

  // eslint-disable-next-line no-restricted-globals
  const { from } = location.state || {
    from: { pathname: localStorage.getItem('sessionLastPage') || '/' },
  };
  if (redirect) return <Redirect to={from} />;
  if (forgot) return <Redirect to="/forgotPassword" />;

  return (
    <div className="loginPage">
      <div className="slides">
        <img src={`/images/login/slide${currentSlide}.jpg`} alt="Fockink" />
      </div>
      <div className="form">
        {loginMethod === undefined && <CircularProgress size={100} />}
        {loginMethod === 'local' && (
          <div className="form-content">
            {/* <div className="logoHolder"> */}
            {iconData && (
              <img
                src={iconData}
                alt="logo"
                className="sublogo"
                onError={(e) => {
                  e.target.style.display = 'none';
                }}
              />
            )}

            <img
              src="/images/IoT Fockink/Ativo 12@3x.png"
              alt="Logo"
              className="logo"
            />
            {/* </div> */}

            <Box mt={6} mb={3}>
              <form
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit}
                method="post"
              >
                <FormControl variant="filled">
                  <InputLabel htmlFor="login">E-mail</InputLabel>
                  <FilledInput
                    id="login"
                    inputRef={emailInputRef}
                    name="login"
                    type="email"
                    value={credentials.login}
                    onChange={handleChange}
                  />
                </FormControl>
                <br />
                <br />
                <FormControl variant="filled">
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <FilledInput
                    name="password"
                    id="password"
                    inputRef={passwordInputRef}
                    type={showPassword ? 'text' : 'password'}
                    value={credentials.password}
                    onChange={handleChange}
                    endAdornment={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                <div className="forgotPassword" onClick={() => setForgot(true)}>
                  <span>Esqueceu sua senha?</span>
                </div>
                <br />
                <div className="to-right">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    className="btnSubmit"
                  >
                    Entrar
                  </Button>
                </div>
              </form>
            </Box>
            {errorMessage && (
              <Alert severity="warning" mt={2}>
                {errorMessage}
              </Alert>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
