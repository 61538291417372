import styled from 'styled-components';

export const Led = styled.div`
    width: 20px;
    height: 20px;
    background-color: ${(props) => props.ledColor};
    background-size: cover;
    border-radius: 5px;
`;

export const LedWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Label = styled.h3`
    margin: 0;
    margin-left: 5px;
    margin-bottom: 2px;
    :hover {
        color: green;
        cursor: pointer;
}
`;
export const LabelTitle = styled.label`
    font-size: 18px;
    font-weight: 700;
    margin-right: 5px;
`;

export const tableLabel = styled.label`
    font-size: 13px;
`;
