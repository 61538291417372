import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';

export default function GradualThermometryGraphic(props) {
  const { data: allColors, type } = props;
  const [seriesData, setSeriesData] = React.useState();
  useEffect(() => {
    const data = allColors[type];
    if (type === 'gradual') {
      const { min, max, colors } = data;
      const newSeriesData = [];
      for (let i = min; i <= max; i += 1) {
        newSeriesData.push({ color: colors[i], from: i });
      }
      const setColors = newSeriesData?.map((x, index) => ({
        color: colors[index],
        from: x.from,
      }));
      setSeriesData(setColors);
    } else {
      setSeriesData(data);
    }
  }, [props]);

  const handleformatTemperatureValueWidth = (range) => {
    const screenWidth = window.screen.width;
    if (screenWidth < 720 && seriesData.length > 11) {
      return null;
    }

    return type === 'gradual'
      ? `${range.from} ºC`
      : `${range.limitMin} a ${range.limitMax} °C`;
  };
  return (
    <Grid container style={{ marginTop: '12px' }}>
      <Grid item xs={12}>
        {seriesData ? (
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <tbody>
              <tr>
                {seriesData.map((range) => (
                  <td
                    key={range.color + (range.from || range.limitMin)}
                    style={{
                      textAlign: 'center',
                      background: range.color,
                      height: '40px',
                      fontWeight: 'bold',
                      fontSize: '0.8rem',
                      color: 'black',
                      padding: 0,
                    }}
                  >
                    {handleformatTemperatureValueWidth(range)}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        ) : (
          <span
            style={{
              textAlign: 'center',
              display: 'block',
              fontSize: '1rem',
              marginTop: '20px',
            }}
          >
            {`Não há dados de temperaturas
            ${
              type === 'grad' ? 'graduais' : 'de qualidade'
            } para serem exibidos`}
          </span>
        )}
      </Grid>
    </Grid>
  );
}
