import styled from 'styled-components';

export const DivSilo = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    position: relative;
`;

export const DivCable = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    position: absolute;
    top:${(props) => `${props.top}px`};
    left:${(props) => `${props.left}px`};
    :hover {
        cursor: pointer;
    }
`;

export const DivMotor = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    position: absolute;
    top:${(props) => `${props.top}px`};
    left:${(props) => `${props.left}px`};
    :hover {
        cursor: pointer;
    }
`;
