import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

const translateType = {
  number: 'Número',
  string: 'Texto',
  boolean: 'Boleano',
  object: 'Objeto',
};

export const columns = [
  {
    name: 'Sistema',
    selector: 'system',
    /* center: true, */
    wrap: true,
    sortable: true,
  },
  {
    name: 'ID da Operação',
    selector: 'operationId',
    center: true,
    wrap: true,
    sortable: true,
  },
  {
    name: 'Padrão da Variável',
    selector: 'variablePattern',
    /* center: true, */
    wrap: true,
    sortable: true,
  },
  {
    name: 'Nome amigável',
    selector: 'friendlyName',
    /* center: true, */
    wrap: true,
    sortable: true,
  },
  {
    name: 'Tipo',
    selector: 'type',
    /* center: true, */
    wrap: true,
    cell: (row) => translateType[row.type] || row.type,
  },
  {
    name: 'Threshold Mínimo',
    selector: 'thresholdMin',
    /* center: true, */
  },
  {
    name: 'Threshold Máximo',
    selector: 'thresholdMax',
    /* center: true, */
  },
  {
    name: 'Elegível a trackink',
    selector: 'trackingAvailable',
    center: true,
    cell: (row) => (
      <Checkbox
        disabled
        checked={row.trackingAvailable}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    ),
    sortable: true,
  },
  {
    name: 'Elegível a alarme',
    selector: 'alarmAvailable',
    center: true,
    cell: (row) => (
      <Checkbox
        disabled
        checked={row.alarmAvailable}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    ),
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    center: true,
    cell: (row) => <span>{row.status === 'A' ? 'Ativa' : 'Inativa'}</span>,
  },
];
