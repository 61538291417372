import styled from 'styled-components';

export const Wrapper = styled.button`
  width: 100%;
  height: 4rem;
  border-radius: 0.3rem;
  margin: 0.2rem;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  font-size: 0.9rem;
  position: relative;

  &:hover::after {
    content: '';
    height: 2px;
    width: 100%;
    position: absolute;
    border-radius: 3px 3px 0 0;
    bottom: 0;
    left: 0;
    background: #2fbf26;
  }

  border-color: ${(props) => props.checked ? '#2fbf26' : '#b0b0b0'}
`;
