import React, { useState, useEffect } from 'react';
// import { first } from 'lodash';
import { DivCable, DivSilo, DivMotor } from './style';
import CableSVAuto from './cableSVAuto';
import autoDispositionMotors from './autoDispositionMotors';
import MotorSVAuto from './motorSVAuto';
import findBiggerTemperatureColor from '../../helpers/findBiggerTemperatureColor';

// const siloDiameter = 200; // definição do diametro do silo na visão superior
const cableDiameter = 20; // definição do diametro do cabo na visão superior
const motorDiameter = 30; // definição do diametro do motor na visão superior

export default function SuperiorViewAuto({
  data,
  cablesTest,
  setSelectedNumberCables,
  modalControlSectionView,
  numberMaxSubarea,
  subarea,
}) {
  const [arcs, setArcs] = useState([]);
  const [engines, setEngines] = useState([]);
  const [disposition, setDisposition] = useState([]);
  const [enginesDisposition, setEnginesDisposition] = useState([]);
  const [section, setSection] = React.useState([]);
  const typeSuperiorView = data.typeUnit;

  let lastArc = 0;

  disposition.forEach((arc) => {
    // console.log('????', Math.max(arc.arc));
    // if (index + 1 === disposition.length) {
    if (lastArc < arc.arc) {
      lastArc = arc.arc;
    }
    // }
  });

  // console.log('lastArc->>', lastArc);

  function handleCableClick(cableNumber) {
    const foundArc = arcs.find((f) => f.cables.find((g) => g.num === cableNumber));

    if (foundArc) {
      setSection(foundArc.cables.map((m) => m.num));
    }
  }
  function reordenateCuttingPositions(positions) {
    if (positions.length > 0) {
      let aux;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < positions.length; i++) {
        // eslint-disable-next-line no-plusplus
        for (let j = 0; j < positions.length; j++) {
          if (positions[j] > positions[i]) {
            aux = positions[j];
            positions[j] = positions[i];
            positions[i] = aux;
          }
        }
      }
      // setCuttingPositions(positions);
      // console.log('após metodo bolha ->', cuttingPositions);
    }
  }

  useEffect(() => { // modal controla a visualização de qual arco quer visualizar
    if (modalControlSectionView !== 0) {
      handleCableClick(modalControlSectionView);
    }
  }, [modalControlSectionView]);

  React.useEffect(() => {
    const pos = [];
    let exist;
    if (data) {
      const auxArcs = [];
      data.chartData.forEach((cd) => {
        cd.unitData.arcs.forEach((arc) => {
          auxArcs.push({
            cables: arc.cabs.map((cab) => ({
              num: cab.num,
              z: cab.positionZ,
              color: findBiggerTemperatureColor(cab.sens),
            })),
            arc: arc.arc,
          });
          arc.cabs.forEach((cab) => {
            // cutting positions
            exist = 0;
            pos.forEach((positionExist) => {
              if (cab.positionZ === positionExist) {
                exist += 1;
              }
            });
            if (exist === 0) {
              pos.push(cab.positionZ);
            }
          });
        });
      });
      reordenateCuttingPositions(pos);
      setArcs(auxArcs);
      setEngines(data.engines);
    }
  }, [data, cablesTest]);

  useEffect(() => {
    if (arcs.length > 0) {
      // check here the top/bottom left/right
      setDisposition(arcs);
      if (arcs[0].cables[0]) {
        handleCableClick(arcs[0].cables[0].num);
      }
    }
  }, [arcs]);

  function AlterTopLeftArmaz() {
    let auxLeft = 0;
    let auxTop = 0;
    enginesDisposition.forEach((item) => {
      if (auxLeft > item.engineLeft) {
        auxLeft = item.engineLeft;
      }
      if (auxTop > item.engineTop) {
        auxTop = item.engineTop;
      }
    });

    if (auxLeft < 0) {
      auxLeft *= -1;
    }
    if (auxTop < 0) {
      auxTop *= -1;
    }

    return { left: auxLeft, top: auxTop };
  }

  // useEffect(() => {
  // carrega informações do corte transversal
  // setCortes(crossSection(cables, disposition));
  // }, [disposition]);

  useEffect(() => {
    setSelectedNumberCables(section); // envia os cabos do corte par ao termovisor
  }, [section]); // a cada novo corte selecionado

  useEffect(() => {
    engines &&
      setEnginesDisposition(autoDispositionMotors(engines, disposition));
    !engines && setEnginesDisposition([]);
  }, [engines, disposition]);
  // console.log('&&&', disposition);
  return (
    <DivSilo>
      {/* <SV2 />
      <SV3 /> */}
      {/* {console.log('subarea - ', subarea, ' max: ', numberMaxSubarea)} */}
      <div
        style={{
          marginLeft: AlterTopLeftArmaz().left,
          marginTop: AlterTopLeftArmaz().top,
          display: 'flex',
          height: 240, // (cableDiameter + 9.5) * cuttingPositions.length,
          width: (cableDiameter + 10.8) * lastArc,
          top: '27px',
          position: 'absolute',
          background: '#D6E0E6',
          padding: 15,
          borderTopRightRadius:
            ((typeSuperiorView === 16 ||
            typeSuperiorView === 17 ||
            typeSuperiorView === 7 ||
            typeSuperiorView === 8) &&
            ((numberMaxSubarea === subarea) ||
            (subarea === 'all')))
              ? 200
              : 0,
          borderBottomRightRadius:
          ((typeSuperiorView === 16 ||
            typeSuperiorView === 17 ||
            typeSuperiorView === 7 ||
            typeSuperiorView === 8) &&
            ((numberMaxSubarea === subarea) ||
            (subarea === 'all')))
            ? 200
            : 0,
          borderTopLeftRadius:
            ((typeSuperiorView === 7 ||
              typeSuperiorView === 8) &&
            ((subarea === 1) ||
            (subarea === 'all'))) ? 200 : 0,
          borderBottomLeftRadius:
            typeSuperiorView === 7 || typeSuperiorView === 8 ? 200 : 0,
        }}
      >
        {/* DISPOSIÇÃO DOS CABOS NA TELA */}
        {disposition.map(
          (arc) =>
            /* { */ arc.cables.map((cable) => (
              // <div style={{ display: 'flex', height: '100%', width: '100%',
              // fontSize: '10px', alignItems: 'center', textAlign: 'center' }}>
              //   {/* {cable.num} */}
              //   <CableSVAuto
              //     cable={cable.num}
              //     handleClick={handleCableClick}
              //     diameter="19px"
              //     selectedSection={section}
              //   />
              // </div>

              <DivCable
                key={cable.num}
                width={cableDiameter + 200}
                height={cableDiameter + 200}
                top={(cable.z - 33)}
                left={(cableDiameter + 10) * arc.arc}
              >
                {/* T
              {cable.num} */}
                <CableSVAuto
                  cable={cable.num}
                  tempColor={cable.color}
                  handleClick={handleCableClick}
                  diameter={cableDiameter}
                  selectedSection={section}
                />
              </DivCable>
            )) /* } */,
          // </div>
        )}

        {enginesDisposition.map((item) => (
          <DivMotor
            key={item.engineId}
            width={motorDiameter}
            height={motorDiameter}
            top={item.engineTop}
            left={item.engineLeft}
          >
            <MotorSVAuto motor={item} diameter={motorDiameter} />
          </DivMotor>
        ))}
      </div>
    </DivSilo>
  );
}
