import React, { useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../../App.css';
import moment from 'moment';
// import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import HistoryIcon from '@material-ui/icons/History';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import DateFnsUtils from '@date-io/date-fns';
import { isMobile, isDesktop } from 'react-device-detect';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { IconButton, Tooltip } from '@material-ui/core';
import {
  RiCloseFill,
  RiArrowLeftSLine,
  RiArrowRightSLine,
} from 'react-icons/ri';
import { MdSensors, MdSensorsOff } from 'react-icons/md';
import { formatDate } from '../../../../../helpers/Intl';
import FockinkAPI from '../../../../../middlewares/Api';
import {
  SuperiorViewAuto as SuperiorViewAutoArmazem,
  HeatMap as HeatMapArmazem,
  ActionsArea as ActionsAreaArmazem,
  StatisticsModal,
} from '../../../../../components/SVG/Armazem/index';
import {
  SuperiorViewAuto as SuperiorViewAutoSilo,
  HeatMap as HeatMapSilo,
  ActionsArea as ActionsAreaSilo,
} from '../../../../../components/SVG/Silo/index';
import {
  SuperiorViewAuto as SuperiorViewAutoBunker,
  HeatMap as HeatMapBunker,
  ActionsArea as ActionsAreaBunker,
} from '../../../../../components/SVG/Bunker/index';
import {
  SuperiorViewAuto as SuperiorViewAutoTulha,
  HeatMap as HeatMapTulha,
  ActionsArea as ActionsAreaTulha,
} from '../../../../../components/SVG/Tulha/index';
// import {
//   flatBottom,
// } from '../../../../../components/SVG/Armazem/MockData/index';
import { OpenModalBtn } from './styles';
import { handleMessageError } from '../../../../../helpers/handlerMessageError';

let element = '';
let elementWidth = '';
export const superiorViewWidth = () => {
  element = document.querySelector('#superiorview>div>div');
  elementWidth = element?.offsetWidth;
  // console.log('elementWidth =>', elementWidth);
};

export default ({
  currentDate,
  setCurrentDate,
  currentTelemetry,
  setCurrentTelemetry,
  lastValidateDate,
  ...props
}) => {
  const [loading, setLoading] = React.useState(true);
  const [offset, setOffset] = React.useState(0);
  const [data, setData] = React.useState();
  const [statistics, setStatistics] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleGetStatistics = (value) => {
    setStatistics(value);
  };
  const [selectedNumberCables, setSelectedNumberCables] = React.useState([]);
  const [leftButtonModal, setLeftButtonModal] = React.useState('0px');
  const [StorageView, setStorageView] = React.useState();
  const [modalControlView, setModalControlView] = React.useState(0);
  const [showControlViewModal, setShowControlViewModal] = React.useState(false);
  const [view, setView] = React.useState(0);
  const [topMenuModalControl, setTopMenuModalControl] = React.useState(40);

  // superiorViewWidth();

  // const mobile = window.matchMedia('(max-width: 844px)').matches;
  const type = {
    1: {
      name: 'Silo Plano',
      main: 'Silo',
      subType: 'plainSilo',
    },
    2: {
      name: 'Silo V',
      main: 'Silo',
      subType: 'VSilo',
    },
    3: {
      name: 'Armazém Plano',
      main: 'Armazém',
      subType: 'plainArm',
    },
    4: {
      name: 'Armazém SemiV',
      main: 'Armazém',
      subType: 'semiVArm',
    },
    5: {
      name: 'Armazém V',
      main: 'Armazém',
      subType: 'VArm',
    },
    6: {
      name: 'Armazem W',
      main: 'Armazém',
      subType: 'WArm',
    },
    7: {
      name: 'Armazém Bordas Arredondadas - Plano',
      main: 'Armazém',
      subType: 'R1Arm',
    },
    8: {
      // n foi considerado
      name: 'Armazém Bordas Arredondadas - Fundo V',
      main: 'Armazém',
      subType: 'VArm',
    },
    9: {
      name: 'Silo Elevado',
      main: 'Silo',
      subType: 'elevatedSilo',
    },
    10: {
      name: 'Silo Semi V',
      main: 'Silo',
      subType: 'semiVSilo',
    },
    11: {
      name: 'Silo W',
      main: 'Silo',
      subType: 'WSilo',
    },
    12: {
      name: 'Tulha Plano',
      main: 'Tulha',
      subType: 'plainTulha',
    },
    13: {
      name: 'Tulha Fundo V',
      main: 'Tulha',
      subType: 'VTulha',
    },
    14: {
      name: 'Bunker Plano',
      main: 'Bunker',
      subType: 'plainBunker',
    },
    15: {
      name: 'Bunker Fundo V',
      main: 'Bunker',
      subType: 'VBunker',
    },
    16: {
      // n foi considerado
      name: 'Armazém Bordas Semi Arredondadas - Plano',
      main: 'Armazém',
      subType: 'plainArm',
    },
    17: {
      name: 'Armazém Bordas Semi Arredondadas - Semi V',
      main: 'Armazém',
      subType: 'SR1Arm',
    },
  };

  const setNewOffset = (newOffset) => {
    setLoading(true);
    setOffset(newOffset);
    const nd = new Date(currentDate);
    nd.setDate(nd.getDate() - newOffset);
    setCurrentDate(new Date(nd));
  };

  const callValueApi = () => {
    setLoading(true);
    FockinkAPI.get(
      `/dashboards/thermometry/${props.equipamentId}/charts/svg?sub=${props.strategyId}&telemetryId=${props.telemetries[currentTelemetry].id}`,
    )
      .then((response) => {
        setData(response.data.data);
        setLoading(false);
      })

      .catch(() => {
        setLoading(false);
      });
  };

  const scrollable = useRef(null);

  const scroll = (scrollOffset) => {
    scrollable.current.scrollLeft += scrollOffset;
  };

  React.useEffect(() => {
    if (data) {
      if (type[data.typeUnit].main === 'Armazém') {
        setStorageView({
          HeatMap: HeatMapArmazem,
          SuperiorViewAuto: SuperiorViewAutoArmazem,
          ActionsArea: ActionsAreaArmazem,
        });
        setShowControlViewModal(true);
        setLeftButtonModal('5px');
        setTopMenuModalControl(-70);
      } else if (type[data.typeUnit].main === 'Silo') {
        setStorageView({
          HeatMap: HeatMapSilo,
          SuperiorViewAuto: SuperiorViewAutoSilo,
          ActionsArea: ActionsAreaSilo,
        });
        setShowControlViewModal(false);
        setLeftButtonModal('5px');
        setTopMenuModalControl(0);
      } else if (type[data.typeUnit].main === 'Bunker') {
        setStorageView({
          HeatMap: HeatMapBunker,
          SuperiorViewAuto: SuperiorViewAutoBunker,
          ActionsArea: ActionsAreaBunker,
        });
        setShowControlViewModal(false);
        setLeftButtonModal('15px');
        setTopMenuModalControl(-90);
      } else if (type[data.typeUnit].main === 'Tulha') {
        setStorageView({
          HeatMap: HeatMapTulha,
          SuperiorViewAuto: SuperiorViewAutoTulha,
          ActionsArea: ActionsAreaTulha,
        });
        setShowControlViewModal(false);
        setLeftButtonModal('5px');
        setTopMenuModalControl(40);
      }
    }
  }, [data]);

  React.useEffect(() => {
    superiorViewWidth();
  });

  React.useEffect(() => {
    if (
      currentTelemetry !== undefined &&
      props.telemetries.length > currentTelemetry
    ) {
      callValueApi();
    } else {
      setData(undefined);
      setStorageView(undefined);
    }
  }, [props.telemetries, currentTelemetry]);

  React.useEffect(() => {
    if (lastValidateDate && props.telemetries.length === 0) {
      setLoading(false);
    }
  }, [props.telemetries, lastValidateDate]);

  const handleDateChange = (date) => {
    const dt = moment(date.toISOString().substring(0, 10));
    const diffDate = moment().diff(dt, 'days');
    setNewOffset(diffDate);
  };

  if (props.loading || loading) {
    return (
      <Skeleton variant="rect" height={200} width="100%" animation="wave" />
    );
  }

  return (
  // <Paper style={{ padding: '15px', backgroundColor: 'white' }}>

    <Grid
      container
      spacing={2}
      // justifyContent="space-around"
      alignItems="center"
    >
      <Grid item xs={12}>
        <h3 style={{ margin: '0', fontWeight: '500' }}>Termometria</h3>
      </Grid>

      {/* <Grid item xs={12} md={12} lg={3}>
          <FormControlLabel
            control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
              <Switch
                disabled={checkIsEmpty()}
                checked={searchData.tempNoGrain}
                onChange={handleChange}
                name="tempNoGrain"
                color="primary"
              />
                }
            label="Sensores fora da massa"
          />
        </Grid> */}

      <Grid item xs={12} md={4} lg={2}>
        <Grid container>
          <Grid
            item
            xs={1}
            style={{ cursor: 'pointer', margin: 'auto' }}
            onClick={() => setNewOffset(offset + 1)}
          >
            <KeyboardArrowLeftIcon style={{ margin: '-4px' }} />
          </Grid>

          <Grid item xs={10}>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              style={{ width: '100%' }}
              variant="outlined"
            >
              <KeyboardDatePicker
                variant="outlined"
                margin="normal"
                label="Data Aferição"
                format="dd/MM/yyyy"
                value={currentDate}
                name="date"
                onChange={(e) => {
                  handleDateChange(e, 'date');
                }}
                InputProps={{ readOnly: true }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid
            item
            xs={1}
            style={{
              cursor: 'pointer',
              margin: 'auto',
              color:
                currentDate.getDate() !== new Date().getDate()
                  ? '#727272'
                  : '#bdbdbd',
            }}
            onClick={() => {
              if (currentDate.getDate() !== new Date().getDate()) {
                setNewOffset(offset - 1);
              } else {
                handleMessageError(
                  'Ops! A data que você esta tentando acessar é superior a data de hoje.',
                );
              }
            }}
          >
            <KeyboardArrowRightIcon />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={4} lg={2}>
        <FormControl variant="filled">
          <InputLabel>Hora Aferição</InputLabel>
          <Select
            // variant="outlined"
            disabled={props.telemetries.length === 0}
            value={currentTelemetry !== undefined ? currentTelemetry : ''}
            onChange={(e) => setCurrentTelemetry(e.target.value)}
            name="hour"
          >
            {props.telemetries.map((m, i) => (
              <MenuItem value={i} key={m.id}>
                {m.hour}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {!StorageView ? (
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          {lastValidateDate && !data ? (
            <>
              <h2
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                <EventBusyIcon
                  style={{ marginRight: '10px', fontSize: '35px' }}
                />
                Nenhuma informação encontrada nessa data.
              </h2>
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={() => setCurrentDate(lastValidateDate)}
                startIcon={<HistoryIcon />}
              >
                {`Ver ultima data (${formatDate(
                  lastValidateDate,
                  'dd/MM/yyyy',
                )})`}
              </Button>
            </>
          ) : (
            <p>Sem SVG para esse equipamento</p>
          )}
        </Grid>
      ) : (
        <>
          <Grid item xs={12} lg={10} style={{ paddingLeft: 30 }}>
            <div
              style={{
                paddingLeft: 20,
                marginTop: -10,
                width: '100%',
                zIndex: 1001,
                position: 'relative',
              }}
            >
              <OpenModalBtn
                onClick={() => setOpen(!open)}
                type="button"
                style={{
                  position: 'absolute',
                  // eslint-disable-next-line
                  left: leftButtonModal,
                  height: '300px',
                  top: '35px',
                  width: '290px',
                  fontSize: '0px',
                }}
              >
                Abrir Modal
              </OpenModalBtn>
            </div>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <div style={{ paddingTop: 20, marginLeft: 10 }}>
              <StorageView.HeatMap
                data={data}
                selectedNumberCables={selectedNumberCables}
                siloType={type[data.typeUnit].subType}
              />
            </div>
          </Grid>
          {/* {console.log('strategyGrain->', props.strategyGrain)} */}

          {isDesktop && elementWidth > 700 ? (
            type[data.typeUnit].main === 'Armazém' ? (
              <IconButton
                style={{ marginBottom: '100px' }}
                onClick={() => scroll(-20)}
              >
                <KeyboardArrowLeftIcon style={{ fontSize: '3rem' }} />
              </IconButton>
            ) : null
          ) : null}
          <Grid item xs={12} md={12} xl={6} lg={6}>
            <div
              ref={scrollable}
              style={{
                minHeight: 400,
                marginLeft: 10,
                // maxWidth: '100%',
                overflowX:
                  type[data.typeUnit].main === 'Armazém' ? 'scroll' : 'none',
                overflow: 'hidden',
              }}
              id="superiorview"
            >
              <StorageView.SuperiorViewAuto
                data={data}
                setSelectedNumberCables={setSelectedNumberCables}
                modalControlSectionView={modalControlView}
                numberMaxSubarea={props.strategyGrain.length}
                subarea={props.strategyId}
              />
            </div>
          </Grid>

          {isDesktop && elementWidth > 700 ? (
            type[data.typeUnit].main === 'Armazém' ? (
              <IconButton
                style={{ marginBottom: '100px' }}
                onClick={() => scroll(20)}
              >
                <KeyboardArrowRightIcon style={{ fontSize: '3rem' }} />
              </IconButton>
            ) : null
          ) : null}

          {/* {isMobile ? (
            <>
              <IconButton style={{ marginBottom: '100px', left: '100px' }} onClick={() => scroll(-20)}><KeyboardArrowLeftIcon style={{ fontSize: '3rem' }} /></IconButton>
              <IconButton style={{ marginBottom: '100px', right: '-80px' }} onClick={() => scroll(20)}><KeyboardArrowRightIcon style={{ fontSize: '3rem' }} /></IconButton>
            </>
          )
            : null} */}

          <Grid item xs={12}>
            <div style={{ paddingTop: 20, marginLeft: 10 }}>
              <StorageView.ActionsArea
                statisticsCallback={handleGetStatistics}
                data={data}
                selectedNumberCables={selectedNumberCables}
              />
            </div>
          </Grid>

          <StatisticsModal
            statistics={statistics}
            close={() => setStatistics(undefined)}
          />
        </>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          marginTop: 0,
        }}
      >
        <Fade in={open}>
          <>
            <div
              style={{
                padding: 5,
                textAlign: 'right',
                justifyContent: 'right',
                display: 'flex',
                marginTop: -20,
                marginBottom: 5,
              }}
            >
              <IconButton
                onClick={() => {
                  setOpen(false);
                }}
              >
                <RiCloseFill size={50} color="#FFFFFF" />
              </IconButton>
            </div>
            <div
              style={{
                width: 930,
                height: 730,
                margin: '0 auto',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {!StorageView ? (
                <p>HeatMap não preparado</p>
              ) : (
                <StorageView.HeatMap
                  modal
                  data={data}
                  selectedNumberCables={selectedNumberCables}
                  siloType={type[data.typeUnit].subType}
                  mobile={window.matchMedia('(max-width: 844px)').matches}
                  viewStyle={view}
                />
              )}
            </div>
            <div
              style={{
                padding: 5,
                textAlign: 'center',
                justifyContent: 'center',
                display: 'flex',
                marginTop: topMenuModalControl,
                marginBottom: 5,
              }}
            >
              {showControlViewModal && (
                <>
                  {/* {console.log(type[data?.typeUnit].main)} */}
                  <Tooltip title="Arco Anterior">
                    <IconButton
                      onClick={() => {
                        selectedNumberCables.length > 0 &&
                          setModalControlView(selectedNumberCables[0] - 1);
                      }}
                    >
                      <RiArrowLeftSLine size={50} color="#FFFFFF" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Próximo Arco">
                    <IconButton
                      onClick={() => {
                        selectedNumberCables.length > 0 &&
                          setModalControlView(
                            selectedNumberCables[
                              selectedNumberCables.length - 1
                            ] + 1,
                          );
                      }}
                    >
                      <RiArrowRightSLine size={50} color="#FFFFFF" />
                    </IconButton>
                  </Tooltip>
                </>
              )}
              <Tooltip
                title={
                  !view
                    ? 'Ver Sensores e Ocultar Termovisor'
                    : 'Ocultar Sensores e Visualizar Termovisor'
                }
              >
                <IconButton
                  onClick={() => {
                    setView(!view);
                  }}
                >
                  {!view ? (
                    <MdSensors size={50} color="#FFFFFF" />
                  ) : (
                    <MdSensorsOff size={50} color="#FFFFFF" />
                  )}
                </IconButton>
              </Tooltip>
            </div>
          </>
        </Fade>
      </Modal>
    </Grid>
    // </Paper>
  );
};

// teste
// teste 2
