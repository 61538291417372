import React from 'react';
import colors from '../../styles/colors';

export default (props) => {
  const { recipeWater /* stOnOff */ } = props.status;

  const text = recipeWater === 1 ? 'COM ÁGUA' : 'SEM ÁGUA';
  const status = /* stOnOff
    ? */recipeWater
      ? {
        textColor: colors.white,
        statusColor: colors.pivotOk,
        backgroundColor: colors.pivotOk,
      }
      : {
        textColor: colors.black,
        statusColor: colors.black,
        backgroundColor: colors.white,
      };
    /*: {
        textColor: colors.black,
        statusColor: colors.black,
        backgroundColor: colors.white,
      }; */

  return (
    <svg viewBox="0 0 500 500" width="100%">
      <defs>
        <grid x="0" y="0" width="122.653" height="92.449" />
      </defs>
      <g id="WATER_TEXT">
        <rect
          y="358.899"
          width="500.204"
          height="139.499"
          style={{ fill: status.backgroundColor }}
          rx="21.419"
          ry="21.419"
        />
        <text
          style={{
            fill: status.textColor,
            fontFamily: 'Roboto',
            fontWeight: 800,
            fontSize: '36px',
            textAnchor: 'middle',
            whiteSpace: 'pre',
          }}
          x="254.694"
          y="445.48"
        >
          {text}
        </text>
      </g>
      <g
        id="PIPE"
        transform="matrix(4.255754, 0, 0, 4.255754, -1271.572754, -456.962311)"
      >
        <g transform="matrix(1.197406, 0, 0, 1.197406, -70.931076, -20.438078)">
          <rect
            x="366.502"
            y="123.159"
            width="28.808"
            height="11.424"
            style={{ fill: colors.grey }}
          />
          <polygon points="393.079 131.577 393.079 126.011 390.123 126.011 386.055 131.577" />
          <polygon points="385.019 126.088 374.502 126.088 370.395 131.577 380.605 131.577" />
          <polygon points="369.736 126.088 359.219 126.088 355.112 131.577 365.322 131.577" />
          <rect
            x="331.665"
            y="123.159"
            width="15.383"
            height="11.424"
            style={{ fill: colors.grey }}
          />
          <rect
            x="325.216"
            y="120.096"
            width="6.879"
            height="17.419"
            style={{ stroke: colors.grey, fill: colors.white }}
          />
          <polygon points="354.221 126.088 343.704 126.088 339.597 131.577 349.807 131.577" />
          <polygon points="339.23 126.088 333.259 126.088 333.259 131.653 334.816 131.577" />
        </g>
        <path
          style={{ stroke: colors.black, fill: status.statusColor }}
          d="M 357.526 181.095 L 368.388 181.026 L 368.388 175.541 L 366.276 175.124 L 366.276 119.575 C 366.276 119.575 365.949 111.485 356.468 111.485 C 347.13 111.485 346.471 119.557 346.426 120.242 L 346.296 121.269 L 343.767 121.532 L 343.723 126.934 L 355.634 126.934 L 355.59 121.595 L 353.022 121.155 L 353.11 119.706 C 353.11 119.706 354.114 117.405 356.432 117.405 C 358.75 117.405 359.674 119.196 359.999 120.454 L 359.904 174.914 L 357.52 175.471 L 357.526 181.095 Z"
        />
        <g
          id="DROP"
          transform="matrix(2.425291, 0, 0, 2.425291, -529.742004, -268.161316)"
        >
          <circle
            style={{ fill: status.statusColor }}
            cx="355.463"
            cy="181.723"
            r="5.189"
          />
          <path
            d="M 94.62 40.982 Q 94.78 40.706 94.94 40.982 L 98.711 47.516 Q 98.871 47.792 98.552 47.792 L 91.008 47.792 Q 90.689 47.792 90.849 47.516 Z"
            style={{ fill: status.statusColor }}
            transform="matrix(-0.5, -0.866025, 0.866025, -0.5, 363.50946, 281.023499)"
            shape="triangle 90.689 40.706 8.182 7.086 0.5 0.039 1@80d03729"
          />
        </g>
      </g>
    </svg>
  );
};
