import { useFormik } from 'formik';
import React from 'react';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { Input, ButtonComponent as Button, CardOption } from '../../../../../../components';
import { systemTypesNumeric } from '../../utils';
import * as S from './style';

export const TelemetryFilters = ({ handleSubmit, closeModal, state, setState }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      system: state.system,
      groupName: state.groupName,
      email: state.email,
      status: state.status,
    },
    onSubmit: async (values) => {
      const valueKeys = Object.keys(values);

      const formatKeys = {};

      for (const key of valueKeys) {
        if (values[key] !== '') {
          formatKeys[key] = values[key];
        }
      }

      await handleSubmit(formatKeys);
      closeModal();
    },
  });

  const handleStatusValue = () => {
    if (state.status === 'A') {
      setState({ ...state, status: 'I' });
    } else {
      setState({ ...state, status: 'A' });
    }
  };

  const clearFormAndResetList = async () => {
    setState({ system: [], groupName: '', email: '', status: 'A' });
  };

  // const handleChangeFilterValue = (event) => {
  //   const { name, value } = event.target;
  //   const newValues = { ...state };
  //   newValues[name] = value;

  //   setState(newValues);
  // };
  function randomName() {
    return `form_filter_number_${Math.random() * 100000000}`;
  }
  return (
    <S.Wrapper>
      <form action="" autoComplete="off" onSubmit={formik.handleSubmit}>
        <Input
          autoComplete="off"
          id={randomName()}
          type="text"
          name="groupName"
          label="Nome do Grupo"
          onChange={formik.handleChange}
          value={formik.values.groupName}
        />

        <Input
          autoComplete="off"
          id={randomName()}
          type="text"
          name="email"
          label="Email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />

        <FormControl variant="filled">
          <InputLabel>Sistemas</InputLabel>
          <Select
            name="system"
            autoComplete="off"
            multiple
            value={formik.values.system}
            onChange={formik.handleChange}
            renderValue={(selected) =>
              selected.map((s) => systemTypesNumeric[s - 1].text).join(', ')
            }
            label="system"
          >
            {systemTypesNumeric.map(({ id, text }) => (
              <MenuItem key={id} value={id}>
                <Checkbox checked={formik.values.system.indexOf(id) > -1} />
                <ListItemText primary={text} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{formik.errors.system}</FormHelperText>
        </FormControl>

        <CardOption checked={formik.values.status === 'A'} onClick={() => handleStatusValue()}>
          Status:
          {formik.values.status === 'A' ? 'Ativo' : 'Inativo'}
        </CardOption>

        <Button type="submit" style={{ width: '100%', margin: '0.3rem 0.2rem' }}>
          Aplicar
        </Button>
        <Button
          type="button"
          handle={clearFormAndResetList}
          color="secondary"
          style={{ width: '100%', margin: '0.3rem 0.2rem' }}
        >
          Limpar Filtros
        </Button>
      </form>
    </S.Wrapper>
  );
};
