import React, { useEffect, useState } from 'react';
import './style.scss';
import { Grid } from '@material-ui/core';
import {
  FlatBottomSilo,
  ConicalBottomSilo,
} from '../siloTypes';
import { calcTempPositionY, startDispositionsSensorsY, heatmapPositionY, startDispositionsHeatMapY } from './sensorsDispositions';
import ViewSensorsStyle from './viewSensorsStyle';
import verifyTemperature from '../../helpers/verifyTemperature';

function HeatMap({ data, selectedNumberCables, siloType, modal, mobile, viewStyle }) {
  const [cables, setCables] = useState([]);
  const [heatMapData, setHeatMapData] = useState([]);
  const [heatMapDataOneCable, setHeatMapDataOneCable] = useState([]);
  // const [sensorSize, setSensorSize] = useState({ x: 25, y: 25 });
  const [sensorsNumber, setSensorsNumber] = useState();
  const [cablesNumber, setCablesNumber] = useState();
  // const [teste, setTeste] = useState();
  // const [viewSensor, setViewSensor] = useState(false);
  const viewSensor = false;
  const lengthTermovisorModal = 635;

  if (!viewStyle) {
    viewStyle = false;
  }

  // let sensors = 0;
  // if (cables) {
  //   sensors = Math.max(...cables.map((x) => x?.sensors.length));
  // }

  function headerCalc() {
    if (siloType === 'plainBunker') {
      return 280;
    }

    if (siloType === 'VBunker') {
      return 550;
    }

    if (siloType === 'V2Bunker') {
      return 250;
    }

    if (siloType === 'V3Bunker') {
      return 250;
    }

    if (siloType === 'V4Bunker') {
      return 250;
    }

    // return 250;
  }

  function headerCableMobileCalc(cableLength) {
    let x;
    switch (cableLength) {
      case 1:
        x = 25;
        break;
      case 2:
        x = 22;
        break;
      case 3:
        x = 5;
        break;
      case 4:
        x = 16;
        break;
      default:
        x = 12;
    }
    return x;
  }

  function headerSensorMobileCalc(sensorLength) {
    let x;
    switch (sensorLength) {
      case 1:
        x = 25;
        break;
      case 2:
        x = 6;
        break;
      case 3:
        x = 4;
        break;
      case 4:
        x = 3;
        break;
      default:
        x = 12;
    }
    return x;
  }

  React.useEffect(() => {
    if (selectedNumberCables !== undefined) {
      if (selectedNumberCables.length > 0) {
        const auxCables = [];
        const refCables = [];
        data.chartData.forEach((cd) => {
          cd.unitData.arcs.forEach((arc) => {
            arc.cabs.forEach((cab) => {
              if (selectedNumberCables.indexOf(cab.num) !== -1) {
                auxCables.push({
                  cableNumber: cab.num,
                  sensors: cab.sens.map((c) => ({ color: c.gradualColor, t: c.t })),
                });
              }
            });
          });
        });

        // Just to keep the selectedNumberCables order
        if (auxCables.length > 0) {
          selectedNumberCables.forEach((e) => {
            refCables.push(auxCables.find((f) => f.cableNumber === e));
          });
        }

        // console.log('refCables', refCables);
        setCables(refCables);
      }
    }
  }, [data, selectedNumberCables]);

  function radialGradientPositionX(indice, sensorDynamicSizeX) {
    return indice * sensorDynamicSizeX + 11;
  }

  function radialGradientPositionY(index, sensorsInCableQtd, cableQtd, cableIndex) {
    return (
      (heatmapPositionY(siloType, index, sensorsInCableQtd, cableQtd, cableIndex) * 100)
      / 200 -
      startDispositionsHeatMapY(siloType, cableQtd, cableIndex)
    );

    // return (((calcTempY * (modal ? 50 : 70)) / 285) + (modal ? 50 : 30));
  }

  function radialGradientColor(color, numberOfCables) {
    return `${color} ${viewSensor ? 10 : 0}px,
      transparent ${viewSensor ? 0 : numberOfCables <= 3 ? 23 : 18}%)`;
  }

  useEffect(() => {
    const largeCable = Math.max(...cables.map((x) => x?.sensors.length));
    const numberOfCables = cables?.length;
    setSensorsNumber(largeCable);
    setCablesNumber(numberOfCables);
    const sensorDynamicSizeX = 88 / numberOfCables;
    // const sensorDynamicSizeX = (250 / largeCable).toFixed(2);
    // const sensorDynamicSizeXModal = (100 / largeCable).toFixed(5);
    const linearDigitalSize = (100 / largeCable).toFixed(2);
    // setSensorSize({ x: sensorDynamicSizeY, y: sensorDynamicSizeX });
    // console.log(largeCable);
    // console.log(selectedNumberCables.length);

    const normalSensors = [];

    cables.forEach((x, indice) =>
      x?.sensors.forEach((s, is) => {
        const a = radialGradientPositionX(indice, sensorDynamicSizeX);
        const b = radialGradientPositionY(
          is, x?.sensors.length, numberOfCables, indice,
        );
        const c = radialGradientColor(s.color, cables?.length);
        const radial = ['radial-gradient(at ', a, '% ', b, '%, ', c].join('');
        normalSensors.push({
          t: s.t,
          radial,
        });
      }));

    normalSensors.sort((a, b) => b.t - a.t);

    const normalSensorsFix = normalSensors.map((x) => x.radial);
    const oneCableCase = cables.map((x) =>
      // x?.sensors.map((s, is) => `${s.color} ${linearDigitalSize * is}%`));
      // eslint-disable-next-line prefer-template
      x?.sensors.map((s, is) => (s.color + ' ' + (linearDigitalSize * is) + '%')));
    setHeatMapDataOneCable(oneCableCase);
    setHeatMapData(normalSensorsFix);
    // setTeste(Math.random());
  }, [cables, data, selectedNumberCables, siloType]);

  // console.log('>>>>', sensorsNumber);
  // const heatMapData = cables.map((x, indice) =>
  // (`radial-gradient(at ${indice * sensorSize.x}% ${}0%, ${x.sensors.map((y) => (y.color))}
  // 0px, transparent ${sensorSize.x}%)`));

  /* const heatMapData = cables.map((x, indice) =>
  // x.sensors.map((s, is) => (`radial-gradient(at ${indice * 24}% ${is * 8}%, ${s.color}
  // 5px, transparent 20%)`))); */

  return (
    <Grid container spacing={2} style={{ height: modal ? 750 : 'auto' }}>
      <Grid item xs={12} lg={12}>
        <div id="termovisor">
          {/* indicação dos cabos no silo */}
          {(modal) && (!mobile) && (
            <div className="cableHeadersBunker" style={{ marginLeft: 190, textAlign: 'center' }}>
              {cables?.map((cable) => (
                <svg
                  width={headerCalc(siloType) / cablesNumber}
                  height="50"
                >
                  {/* aqui mostra os números encima do modal sem ser um display menor */}
                  <rect
                    x={headerCalc(siloType) / cablesNumber / 2 - 15}
                    width="30px"
                    height="30px"
                    style={{ fill: '#d1d1d1' }}
                    stroke="#d1d1d1"
                    strokeWidth="2px"
                  />
                  <text
                    x={
                      headerCalc(siloType) / cablesNumber / 2 -
                      (cable.cableNumber < 10 ? 10 : cable.cableNumber < 1000 ? 10 : -25)
                    }
                    y={18}
                    fill="black"
                    style={{ fontSize: 12, fontWeight: 700 }}
                  >
                    {cable.cableNumber}
                  </text>
                </svg>
              ))}
            </div>
          )}

          {mobile ? (
            <div className="heatmap" style={{ justifyContent: 'center' }}>
              {cables?.map((cable) => (
                <svg width={70} height={sensorsNumber * 30 + 35}>
                  <rect
                    y={0}
                    width="60px"
                    height="30px"
                    style={{ fill: '#d1d1d1' }}
                    stroke="#d1d1d1"
                    strokeWidth="2px"
                  />
                  {/* <text
                    x={headerCableMobileCalc(cable.cableNumber.toFixed(0).length)}
                    y={20}
                    fill="black"
                    style={{ fontSize: 12, fontWeight: 700, textAlign: 'center' }}
                  > */}
                  <text
                    x={
                      headerCableMobileCalc(cable.cableNumber.toFixed(0).length)
                    }
                    y={20}
                    fill="black"
                    style={{ fontSize: 12, fontWeight: 700 }}
                  >
                    {cable.cableNumber}
                  </text>
                  {cable.sensors.map((sensor, index) => (
                    <rect
                      y={sensorsNumber * 30 + 35 - (index * 30 + 30)}
                      width="60px"
                      height="30px"
                      style={{ fill: sensor.color }}
                      stroke="#d1d1d1"
                      strokeWidth="2px"
                    />
                  ))}
                  {cable.sensors.map((sensor, index) => (
                    <text
                      x={headerSensorMobileCalc(verifyTemperature(sensor.t).toString().length)}
                      y={sensorsNumber * 30 + 35 - (index * 30 + 8)}
                      fill="black"
                      style={{ fontSize: 20, fontWeight: 700 }}
                    >
                      {verifyTemperature(sensor.t)}
                    </text>
                  ))}
                </svg>
              ))}
            </div>

          ) : (
            <div
              style={{
                position: 'relative',
                // margin: 0,
                // display: 'flex',
                // justifyContent: 'center',
              }}
            >
              {/* Arquivo salvo(biruleibe) */}

              {/* aqui mostra o fundo cinza no modal */}
              {siloType === 'plainBunker' && (
                <div style={{ marginTop: modal ? -150 : 0, textAlign: modal ? 'center' : 'left' }}>
                  <FlatBottomSilo modal={modal} thermicVision="white" />
                </div>
              )}
              {(siloType === 'VBunker' || siloType === 'V2Bunker' || siloType === 'V3Bunker' || siloType === 'V4Bunker' || siloType === 'V5Bunker') && (
                <div style={{ marginTop: modal ? -150 : -100, textAlign: modal ? 'center' : 'left' }}>
                  <ConicalBottomSilo
                    modal={modal}
                    cables={cables}
                    cablesNumber={cablesNumber}
                    thermicVision="white"
                  />
                </div>
              )}
              {/* {console.log(siloType)} */}
              <div
            // corzinha do silo
                className={modal ? `${siloType}Modal` : siloType}
                style={{
                  backgrounColor: 'white',
                  backgroundImage:
                  ((cables?.length === 1) && (viewStyle === false))
                    ? `linear-gradient(360deg, ${heatMapDataOneCable}`
                    : ((viewStyle === false) || (!modal)) && heatMapData,
                  position: 'absolute',
                  top: 8,
                  left: 55,
                  marginLeft: modal ? 105 : 0,
                }}
              >
                {/* Números dentro do silo no modal */}
                {modal && siloType === 'plainBunker' && (
                  <div style={{ marginLeft: '50px', width: '800px' }}>
                    {cables?.map((cable, cI) => (
                      <>
                        {/* <svg width={22} height="285" /> */}
                        <svg style={{ margin: '0 10px' }} width={400 / cablesNumber} height="285">
                          {cable.sensors.map((sensor, index) => (
                            <>
                              <ViewSensorsStyle
                                x={lengthTermovisorModal / cablesNumber / 2}
                                y={
                              calcTempPositionY(
                                siloType,
                                index,
                                cable.sensors.length,
                                selectedNumberCables.length,
                                cI,
                              )
                              -
                              startDispositionsSensorsY(
                                siloType,
                                index,
                                cable.sensors.length,
                                selectedNumberCables.length,
                                cI,
                              )
                            }
                                color={sensor.color}
                                temperature={sensor.t}
                                style={viewStyle}
                              />
                            </>
                          ))}
                        </svg>
                      </>
                    ))}
                  </div>

                )}

                {modal &&
                (siloType === 'VBunker' ||
                  siloType === 'V2Bunker' ||
                  siloType === 'V3Bunker' ||
                  siloType === 'V4Bunker' ||
                  siloType === 'V5Bunker') && (
                  <>
                    <svg width="12" height="405" />
                    {cables?.map((cable, cI) => (
                      <svg width={lengthTermovisorModal / cablesNumber} height="405">
                        {cable.sensors.map((sensor, index) => (
                          <>
                            <ViewSensorsStyle
                              x={lengthTermovisorModal / cablesNumber / 2}
                              y={
                              calcTempPositionY(
                                siloType,
                                index,
                                cable.sensors.length,
                                selectedNumberCables.length,
                                cI,
                              )
                              -
                              startDispositionsSensorsY(
                                siloType,
                                selectedNumberCables.length,
                                cI,
                              )
                            }
                              color={sensor.color}
                              temperature={sensor.t}
                              style={viewStyle}
                            />
                          </>
                        ))}
                      </svg>
                    ))}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default HeatMap;
