// Basic
import axios from 'axios';
import jwtDecode from 'jwt-decode';

// Init axios object
const FockinkAPI = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API,
  timeout: 30000,
});

// Insert jwt token from localstorage in authorization header of all requests
FockinkAPI.interceptors.request.use(
  (config) => {
    if (localStorage[process.env.REACT_APP_STORAGE_KEY]) {
      config.headers = {
        Authorization: `Bearer ${localStorage[process.env.REACT_APP_STORAGE_KEY]}`,
      };
      config.timeout = 60000;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

FockinkAPI.interceptors.response.use(
  (response) => {
    if (response.data.token) {
      parseSaveToken(response.data.token);
    }
    return response;
  },
  (error) => {
    // TODO -> Redirect to /logout with the reason to send it to /login
    if (error.response && error.response.status === 401) {
      delete localStorage[process.env.REACT_APP_STORAGE_KEY];
      if (error.config.url !== 'login') {
        window.location.href = '/logout?reason=401';
      }
    }
    if (error.response && error.response.status === 403) {
      window.location.href = '/403';
    }
    return Promise.reject(error);
  },
);

const parseSaveToken = (rawToken) => {
  localStorage[process.env.REACT_APP_STORAGE_KEY] = rawToken;

  // Decode it
  const token = jwtDecode(rawToken);

  // Save public info
  localStorage.userId = token.userId;
  localStorage.login = token.login;
  localStorage.name = token.name;
  localStorage.localeTag = token.locale.tag;
  localStorage.localeCurrency = token.locale.currency;
  localStorage.authType = token.authType;
  localStorage.userProfile = token.profile.description;
  if (token.accountId) localStorage.accountId = token.accountId;
  if (token.organizationId) localStorage.organizationId = token.organizationId;
  localStorage.forceResetPassword = token.forceResetPassword || false;
  localStorage.permissions = JSON.stringify(token.permissions);

  if (token.profile.profileId >= 10 && token.profile.profileId <= 13) {
    localStorage.profile = 'admin';
  } else if (token.profile.profileId >= 51 && token.profile.profileId <= 54) {
    localStorage.profile = 'clientadmin';
  }
};

export { parseSaveToken };
export default FockinkAPI;
