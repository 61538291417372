import React, { useCallback } from 'react';
import Chart from 'react-apexcharts';

export const RainGraphic = ({ data }) => {
  const { series, cultivationColorSequence } = data;

  const handleFormatSeries = useCallback(() => {
    const colors = [
      '#0ecfff',
      '#0ec56f',
      '#0e39c5',
      ...cultivationColorSequence,
    ];

    const newSeries = series.map((serie) => {
      if (!serie.color) {
        serie.color = colors[series.indexOf(serie)];
        return serie;
      }
      return serie;
    });

    return newSeries;
  }, [series]);

  const option = {
    chart: {
      height: 350,
      type: 'radar',
      dropShadow: {
        enabled: true,
        blur: 1,
        left: 1,
        top: 1,
      },
    },
    stroke: {
      width: 2,
    },
    fill: {
      opacity: 0.1,
    },
    markers: {
      size: 1,
    },
    tooltip: {
      enabled: true,
      x: {
        formatter(val) {
          return `Ângulo atual: ${val}`;
        },
      },
      y: {
        formatter(value, x) {
          if (
            ['Irrigação', 'Chuva', 'Irrigação + Chuva'].indexOf(
              x.w.globals.initialSeries[x.seriesIndex].name,
            ) !== -1
          ) {
            return `${Number(value.toFixed(2))}mm`;
          }
          return '';
        },
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
    },
    xaxis: {
      labels: {
        // eslint-disable-next-line
        formatter(val, i) {
          if (val === '0' || val === '90' || val === '180' || val === '270') {
            return val;
          }
          return '';
        },
      },
      categories: Array(360)
        .fill(0)
        .map((_, idx) => `${idx}`),
    },
  };

  return (
    <Chart
      options={option}
      series={handleFormatSeries()}
      type="radar"
      height={450}
      width={350}
    />
  );
};
