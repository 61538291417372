/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import withReactContent from 'sweetalert2-react-content';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NumberFormat from 'react-number-format';
import Tooltip from '@material-ui/core/Tooltip';
// import PortalPermissions from '../../../../helpers/enums/PortalPermissions';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'moment/locale/pt-br';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import jwtDecode from 'jwt-decode';
import Button from '@mui/material/Button';
import BottomButtons from '../../../../components/Buttons/BottomButtons';
import WaterSprinkler from '../../../../components/SVGPivotIcons/Buttons/WaterSprinkler';
import Cannon from '../../../../components/SVGPivotIcons/Buttons/Cannon';
import WaitHour from '../../../../components/SVGPivotIcons/Buttons/WaitHour';
import Loop from '../../../../components/SVGPivotIcons/Buttons/Loop';
import Destiny from '../../../../components/SVGPivotIcons/Buttons/Destiny';
import Card from '../../../../components/Card';
import CardText from '../../../../components/CardText';
import colors from '../../../../styles/colors';
import PivotSVG from '../../../../components/PivotSVG';
// import PivoSVG from '../../../../components/PivotSVG/index';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '800px',
    margin: '20px auto',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(0, 4, 3),
    'overflow-y': 'scroll',
    height: '100%',
  },
  smallButton: {
    maxWidth: '86px',
  },
  errorMessage: {
    margin: '0',
    fontWeight: '500',
    padding: '10px',
    border: '2px solid red',
    background: '#ff000040',
    color: 'black',
  },
  bottomButtons: {
    textAlign: 'right',
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
  title: {
    margin: '0',
    fontWeight: '500',
    background: '#3d5059',
    textAlign: 'center',
    color: 'white',
    padding: '5px',
  },
  status: {
    margin: '7px',
    fontWeight: '400',
  },
  noMargin: {
    margin: 0,
  },
}));

export default (props) => {
  const token = localStorage.getItem('@FockinkAPP:token');
  const { cloudToDevice } = jwtDecode(token);
  const showNewFeatures = false;
  const delayTime = 500;
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  // const [open, setOpen] = React.useState(false);
  moment.locale('pt-br');
  const [sendData, setSendData] = React.useState({
    stOnOff: props.newRecipe ? props.newRecipe.stOnOff || 0 : 0,
    recipeWater: props.newRecipe ? props.newRecipe.recipeWater || 0 : 0,
    recipeHP: props.newRecipe ? props.newRecipe.recipeHP || 0 : 0,
    recipeCannon: props.newRecipe ? props.newRecipe.recipeCannon || 0 : 0,
    recipeLoop: props.newRecipe ? props.newRecipe.recipeLoop || 0 : 0,
    recipeDirectionRotation: props.newRecipe
      ? props.newRecipe.recipeDirectionRotation || 2
      : 2,
    recipePosition: props.newRecipe
      ? props.newRecipe.recipePosition || 359
      : 359,
    recipeWaterMm: props.newRecipe ? props.newRecipe.recipeWaterMm : 0,
    recipeProgram: props.newRecipe ? props.newRecipe.recipeProgram || 0 : 0,
    recipeTsProgram: props.newRecipe
      ? moment.unix(props.newRecipe.recipeTsProgram).format('YYYY-MM-DD HH:mm') || moment().toDate() : moment().toDate(),
    _controlSchedule: 1,
    _controlWithDestiny: 1,
    stDirectionRotationLeft: props.newRecipe
      ? props.newRecipe.stDirectionRotationLeft || 0
      : 0,
    stDirectionRotationRight: props.newRecipe
      ? props.newRecipe.stDirectionRotationRight || 0
      : 0,
    startingPosition: props.newRecipe
      ? props.newRecipe.startingPosition || 19
      : 23,
    positionActual: props.newRecipe ? props.newRecipe.positionActual || 15 : 32,
    maxSectorAngle: props.newRecipe ? props.newRecipe.maxSectorAngle : 360,
    finalPosition: props.newRecipe ? props.newRecipe.finalPosition || 0 : 0,
    northFaceAngle: props.newRecipe
      ? props.newRecipe.northFaceAngle
      : undefined,
    direction: props.newRecipe ? props.newRecipe.direction : 0,
    recipeWhitoutPressure: props.newRecipe.recipeWhitoutPressure || 0,
    recipeStopIdentification: props.newRecipe.recipeStopIdentification || 0,
    recipeInvertAdditionalOutput: props.newRecipe.recipeInvertAdditionalOutput || 0,
    1: props.newRecipe.recipeAdditionalOutputs ?
      props.newRecipe.recipeAdditionalOutputs[0]?.output || 0 : 0,
    2: props.newRecipe.recipeAdditionalOutputs ?
      props.newRecipe.recipeAdditionalOutputs[1]?.output || 0 : 0,
    3: props.newRecipe.recipeAdditionalOutputs ?
      props.newRecipe.recipeAdditionalOutputs[2]?.output || 0 : 0,
    4: props.newRecipe.recipeAdditionalOutputs ?
      props.newRecipe.recipeAdditionalOutputs[3]?.output || 0 : 0,
    5: props.newRecipe.recipeAdditionalOutputs ?
      props.newRecipe.recipeAdditionalOutputs[4]?.output || 0 : 0,
    6: props.newRecipe.recipeAdditionalOutputs ?
      props.newRecipe.recipeAdditionalOutputs[5]?.output || 0 : 0,
    7: props.newRecipe.recipeAdditionalOutputs ?
      props.newRecipe.recipeAdditionalOutputs[6]?.output || 0 : 0,
    8: props.newRecipe.recipeAdditionalOutputs ?
      props.newRecipe.recipeAdditionalOutputs[7]?.output || 0 : 0,

  });
  // console.log('props.newRecipe', props.newRecipe.recipeHP);
  const MySwal = withReactContent(Swal);
  React.useEffect(() => {
    setTimeout(() => {
      setSendData({ ...sendData, ...props.intentRecipe });
    }, delayTime);
  }, []);
  function clickLeft(status) {
    if (status === 1) {
      if (sendData.maxSectorAngle !== 360) {
        setSendData({ ...sendData });
      } else {
        setSendData({ ...sendData, stDirectionRotationLeft: 0, direction: 1 });
      }
    } else if (sendData.maxSectorAngle !== 360) {
      setSendData({ ...sendData });
    } else {
      setSendData({
        ...sendData,
        stDirectionRotationLeft: 1,
        stDirectionRotationRight: 0,
        direction: 2,
      });
    }
  }

  function clickRight(status) {
    if (status === 1) {
      if (sendData.maxSectorAngle !== 360) {
        setSendData({ ...sendData });
      } else {
        setSendData({ ...sendData, stDirectionRotationRight: 0, direction: 1 });
      }
    } else if (sendData.maxSectorAngle !== 360) {
      setSendData({ ...sendData });
    } else {
      setSendData({
        ...sendData,
        stDirectionRotationRight: 1,
        stDirectionRotationLeft: 0,
        direction: 1,
      });
    }
  }

  function clickPower(status) {
    if (status === 1) {
      setSendData({ ...sendData, stOnOff: 0 });
    } else {
      setSendData({ ...sendData, stOnOff: 1 });
    }
  }

  // const handleRotation = (event, currentRotation) => {
  //   setSendData({ ...sendData, recipeDirectionRotation: currentRotation });
  // };

  const handleChange = (event) => {
    setSendData({ ...sendData, [event.target.name]: event.target.value });
  };

  /*   const handleChangeData = (event) => {
    setSendData({
      ...sendData,
      [event.target.name]: new Date(event.target.value),
    });
  }; */

  const handleChangeSwitch = (event) => {
    setSendData({
      ...sendData,
      [event.target.name]: event.target.checked ? 1 : 0,
    });
  };

  const handleChangeButton = (val, name) => {
    setSendData({
      ...sendData,
      [name]: val === 1 ? 0 : 1,
    });
  };

  const classes = useStyles();

  const customResponse = () => {
    // setOpen(false);
    setErrorMessage(undefined);
    MySwal.fire({
      icon: 'success',
      title: 'Operação enviada!',
    });
  };

  const customErrorResponse = (error) => {
    // setOpen(false);
    MySwal.fire({
      title: 'Oops...',
      text: error.response.data.message,
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Corrigir',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        // setOpen(true);
      }
    });

    setErrorMessage(error.response.data.message);
  };

  const saveData = () => {
    const dt = {
      data: {
        stOnOff: sendData.stOnOff,
        recipeWater: sendData.recipeWater,
        recipeHP: sendData.recipeHP,
        recipeCannon: sendData.recipeCannon,
        recipeLoop: sendData.recipeLoop,
        recipeDirectionRotation: sendData.recipeDirectionRotation,
        recipePosition: sendData.recipePosition,
        recipeWaterMm: sendData.recipeWaterMm,
        recipeProgram: sendData.recipeProgram,
        recipeTsProgram: new Date(sendData.recipeTsProgram).toISOString(),
        recipeWhitoutPressure: sendData.recipeWhitoutPressure,
        recipeStopIdentification: sendData.recipeStopIdentification || 0,
        recipeInvertAdditionalOutput: sendData.recipeInvertAdditionalOutput || 0,
        recipeAdditionalOutput1: sendData[1] || 0,
        recipeAdditionalOutput2: sendData[2] || 0,
        recipeAdditionalOutput3: sendData[3] || 0,
        recipeAdditionalOutput4: sendData[4] || 0,
        recipeAdditionalOutput5: sendData[5] || 0,
        recipeAdditionalOutput6: sendData[6] || 0,
        recipeAdditionalOutput7: sendData[7] || 0,
        recipeAdditionalOutput8: sendData[8] || 0,

      },
    };

    if (sendData.maxSectorAngle !== 360) {
      if (sendData.positionActual > sendData.recipePosition) {
        dt.data.recipeDirectionRotation = 2;
      } else if (sendData.positionActual < sendData.recipePosition) {
        dt.data.recipeDirectionRotation = 1;
      } else {
        dt.data.recipeDirectionRotation = 0;
      }
    } else {
      dt.data.recipeDirectionRotation = sendData.direction;
    }
    dt.data.recipeProgram = 0;
    /* if (dt.data.recipeProgram === 0) {
      dt.data.recipeLoop = 0;
      delete dt.data.recipeTsProgram;
    } *//*  else if (typeof dt.data.recipeTsProgram === 'number') {
      dt.data.recipeTsProgram = moment()
        .add(10, 'seconds')
        .toDate()
        .toISOString();
    } */

    if (sendData._controlWithDestiny === 0 && sendData.maxSectorAngle === 360) {
      dt.data.recipePosition = 360;
    }

    dt.method = 'put';
    dt.url = `/equipaments/${props.equipamentId}/send/1200`;
    return dt;
  };
  return (
    <Grid
      container
      spacing={1}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Grid item xs={12} sm={6}>
        <Card>
          <CardText>
            <PivotSVG
              controlMode
              selectedRecipe={{
                stDirectionRotationLeft: sendData.stDirectionRotationLeft || 0,
                stDirectionRotationRight:
                  sendData.stDirectionRotationRight || 0,
                stOnOff: sendData.stOnOff || 0,
              }}
              initial={sendData.positionActual}
              current={undefined}
              destiny={sendData.recipePosition}
              cannons={[]}
              water={sendData.recipeWater}
              direction={sendData.direction}
              northFaceAngle={sendData.northFaceAngle}
              onClickLeft={(r) => {
                clickLeft(r);
              }}
              onClickRight={(r) => {
                clickRight(r);
              }}
              onClickPower={(r) => {
                clickPower(r);
              }}
              maxSectorAngle={
                !sendData.maxSectorAngle || sendData.maxSectorAngle === 360
                  ? undefined
                  : sendData.maxSectorAngle
              }
            />
            {/* <PivoSVG
              isProgrammingScreen
              showCovered
              showUncovered
              isViewMode
              startAngle={0}
              endAngle={
                !sendData.maxSectorAngle || sendData.maxSectorAngle === 360
                  ? undefined
                  : sendData.maxSectorAngle
                }
              zeroAngle={sendData.northFaceAngle}
              destinationAngle={sendData.recipePosition}
              currentAngle={undefined}
              recipeStartAngle={sendData.positionActual}
              onClickLeft={(r) => {
                clickLeft(r);
              }}
              onClickRight={(r) => {
                clickRight(r);
              }}
              onClickPower={(r) => {
                clickPower(r);
              }}
              recipeEndAngle={pivotRecipe.all.finalPosition}
              isRotatingLeft={pivotRecipe.direction === 2}
              isRotatingRight={pivotRecipe.direction === 1}
              isStoped={pivotRecipe.direction === 0}
              hasWater={pivotRecipe.all.recipeWater}
              recipe={pivotRecipe.all}
              isPowerOn={sendData.stOnOff || 0}
              cannonAngles={pivotRecipe.all.cannon}
            /> */}
          </CardText>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container alignContent="center" alignItems="center" spacing={2}>
          <Grid item xs={4} sm={3}>
            <Tooltip
              title={
                sendData.recipeWater
                  ? "Desativar lâmina d'agua"
                  : "Ativar lâmina d'agua"
              }
            >
              <div className={classes.smallButton}>
                <Card
                  outlined
                  hover
                  changeBgColor
                  color={colors.pivotCommands}
                  onClick={() => {
                    handleChangeButton(sendData.recipeWater, 'recipeWater');
                  }}
                >
                  <CardText pa="0px">
                    <WaterSprinkler status={sendData.recipeWater} />
                  </CardText>
                </Card>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={8} sm={9}>
            <NumberFormat
              value={sendData.recipeWaterMm}
              label="mm"
              variant="filled"
              decimalScale={2}
              customInput={TextField}
              onValueChange={(v) =>
                handleChange({
                  target: { name: 'recipeWaterMm', value: v.floatValue },
                })
              }
              thousandSeparator=""
              decimalSeparator=","
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <Tooltip
              title={
                sendData.maxSectorAngle === 360
                  ? sendData._controlWithDestiny
                    ? 'Desativar Posição de destino do Pivô'
                    : 'Ativar Posição de destino do Pivô'
                  : 'Pivô Setorial deve ter posição de destino'
              }
            >
              <div className={classes.smallButton}>
                <Card
                  outlined
                  hover
                  changeBgColor
                  color={colors.pivotCommands}
                  onClick={() => {
                    if (sendData.maxSectorAngle === 360) {
                      handleChangeButton(
                        sendData._controlWithDestiny,
                        '_controlWithDestiny',
                      );
                    }
                  }}
                >
                  <CardText pa="0px">
                    <Destiny status={sendData._controlWithDestiny} />
                  </CardText>
                </Card>
              </div>
            </Tooltip>
          </Grid>

          {sendData._controlWithDestiny === 0 && (
            <Grid item xs={8} sm={9}>
              Girando sem destino
            </Grid>
          )}

          {sendData._controlWithDestiny === 1 && (
            <Grid item xs={8} sm={9}>
              <NumberFormat
                value={sendData.recipePosition}
                label="Destino"
                variant="filled"
                decimalScale={2}
                customInput={TextField}
                onValueChange={(v) =>
                  handleChange({
                    target: { name: 'recipePosition', value: v.floatValue },
                  })
                }
                thousandSeparator=""
                decimalSeparator=","
              />
            </Grid>
          )}
          <Grid item xs={4} sm={3}>
            <Tooltip
              title={
                sendData.recipeHP
                  ? 'Desativar Horário de ponta'
                  : 'Ativar Horário de ponta'
              }
            >
              <div className={classes.smallButton}>
                <Card
                  outlined
                  hover
                  changeBgColor
                  color={colors.pivotCommands}
                  onClick={() => {
                    handleChangeButton(sendData.recipeHP, 'recipeHP');
                  }}
                >
                  <CardText pa="0px">
                    <WaitHour status={sendData.recipeHP} />
                  </CardText>
                </Card>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={8} sm={9}>

            {`Horário de ponta ${
              sendData.recipeHP ? 'ativado' : 'desativado'
            }`}

          </Grid>

          <Grid item xs={4} sm={3}>
            <Tooltip
              title={
                sendData.recipeCannon ? 'Desativar Canhão' : 'Ativar Canhão'
              }
            >
              <div className={classes.smallButton}>
                <Card
                  outlined
                  hover
                  changeBgColor
                  color={colors.pivotCommands}
                  onClick={() => {
                    handleChangeButton(sendData.recipeCannon, 'recipeCannon');
                  }}
                >
                  <CardText pa="0px">
                    <Cannon status={sendData.recipeCannon} />
                  </CardText>
                </Card>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={8} sm={9}>

            {`Canhão ${sendData.recipeCannon ? 'ativado' : 'desativado'}`}
          </Grid>
          {showNewFeatures && (
            <>
              <Grid item xs={4} sm={3}>
                <Tooltip
                  title={
                    sendData.recipeLoop
                      ? 'Desativar Repetição de programas'
                      : 'Ativar Repetição de programas'
                  }
                >
                  <div className={classes.smallButton}>
                    <Card
                      outlined
                      hover
                      changeBgColor
                      color={colors.pivotCommands}
                      onClick={() => {
                        handleChangeButton(sendData.recipeLoop, 'recipeLoop');
                      }}
                    >
                      <CardText pa="0px">
                        <Loop status={sendData.recipeLoop} />
                      </CardText>
                    </Card>
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={8} sm={9}>

                {`Repetição de programação ${
                  sendData.recipeLoop ? 'ativada' : 'desativada'
                }`}

              </Grid>
            </>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={6} xs={6}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              style={{ width: '100%' }}
            >
              <KeyboardDateTimePicker
                variant="filled"
                margin="normal"
                label="Data e hora programada"
                format="DD/MM/YYYY HH:mm"
                value={sendData?.recipeTsProgram}
                name="recipeTsProgram"
                autoOk
                onChange={(date) => {
                  setSendData({
                    ...sendData,
                    recipeTsProgram: date.format('YYYY-MM-DD HH:mm'),
                  });
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            item
            lg={6}
            xs={6}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <FormControlLabel
              control={
                <Switch
                  checked={sendData.recipeWhitoutPressure === 1}
                  onChange={handleChangeSwitch}
                  name="recipeWhitoutPressure"
                  color="secondary"
                />
        }
              label={sendData.recipeWhitoutPressure === 1 ? 'Com pressão' : 'Sem pressão'}
            />
          </Grid>
        </Grid>

        {props.newRecipe.maxSectorAngle < 360 && (
          <Grid item lg={6} xs={12}>
            <FormControl variant="filled">
              <InputLabel id="recipeStopIdentification">Identificação de batente?</InputLabel>
              <Select
                labelId="recipeStopIdentification"
                id="recipeStopIdentification"
                name="recipeStopIdentification"
                value={sendData.recipeStopIdentification}
                onChange={handleChange}
              >
                <MenuItem value={1} key={1}>
                  Sim
                </MenuItem>
                <MenuItem value={0} key={0}>
                  Não
                </MenuItem>

              </Select>
            </FormControl>
          </Grid>
        )}

      </Grid>

      {props.newRecipe.recipeAdditionalOutputs?.length > 0 && (
      <Grid item lg={6} xs={6}>
        <FormControlLabel
          control={
            <Switch
              checked={sendData.recipeInvertAdditionalOutput === 1}
              onChange={handleChangeSwitch}
              name="recipeInvertAdditionalOutput"
              color="secondary"
            />
        }
          label="Inversão de saídas"
        />
      </Grid>
      )}
      <Grid container spacing={2}>
        {props.newRecipe.recipeAdditionalOutputs?.map((x, index) => (
          <Grid item lg={3} xs={3}>
            <FormControlLabel
              control={
                <Switch
                  checked={sendData[index + 1] === 1}
                  onChange={handleChangeSwitch}
                  name={index + 1}
                  color="secondary"
                />
        }
              label={index + 1}
            />
          </Grid>
        ))}
      </Grid>

      {showNewFeatures && (
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                checked={sendData._controlSchedule === 1}
                onChange={handleChangeSwitch}
                name="_controlSchedule"
              />
            }
            label="Programação do Pivô"
          />
        </Grid>
      )}

      {showNewFeatures && sendData._controlSchedule === 0 && (
        <Grid item xs={6}>
          Manual
        </Grid>
      )}

      {/* <>
        <Grid item xs={6}>
          <NumberFormat
            value={sendData.recipeProgram}
            label="Número do Programa"
            variant="filled"
            decimalScale={0}
            customInput={TextField}
            onValueChange={(v) =>
              handleChange({
                target: { name: 'recipeProgram', value: v.floatValue },
              })
            }
            thousandSeparator=""
            decimalSeparator=","
          />
        </Grid>
        <Grid item xs={6} />
      </> */}

      {errorMessage && (
        <Grid item xs={12}>
          <h3 className={classes.errorMessage}>{errorMessage}</h3>
        </Grid>
      )}
      <Grid item xs={12} className={classes.bottomButtons}>
        {cloudToDevice === false ? (
          <Button variant="contained" disabled>Este plano não contêm opção de enviar mensagem</Button>
        ) :

          <BottomButtons
            hideBack
            saveData={saveData}
            customResponse={customResponse}
            customErrorResponse={customErrorResponse}
            customBack={props.closeDialog}
            customSaveText="Aplicar Programação"
            customBackText="Cancelar"
          />
    }
      </Grid>
    </Grid>
  );
};
