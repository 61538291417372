const parseFloatCustom = (value) => {
  /* const withDot = value.replace(/,/gi, '.'); */
  if (typeof value !== 'number') {
    const formatedValue = value.replace(/,/g, '.');
    const finalValue = parseFloat(formatedValue);
    return finalValue;
  }
  return value;
};

export default parseFloatCustom;
