import { useFormik } from 'formik';
import React from 'react';
import FockinkAPI from '../../../../../../../../../middlewares/Api';
import { ButtonComponent as Button } from '../../../../../../../../../components/Button';
import { Input } from '../../../../../../../../../components/Input';
import { validationSchema } from './validationSchema';
import parseFloatCustom from '../../../../../../../../../helpers/parseFloatCustom';

import * as S from './styles';
import { CardOption } from '../../../../../../../../../components/CardOption';

export function AssociatedCultureUpdateForm({
  culture,
  handleCloseHarvestFormModal,
  handleGetCultures,
}) {
  const [errorRequest, setErrorRequest] = React.useState();

  const formik = useFormik({
    initialValues: {
      plantingDate: culture.plantingDate,
      angleFrom: culture.angleFrom,
      angleTo: culture.angleTo,
      maturationGroup: culture.maturationGroup,
      averageDensity: culture.averageDensity,
      distancing: culture.distancing,
      observation: culture.observation,
      status: culture.status,
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const data = {
          equipamentId: culture.equipamentId,
          cultivationAccountId: culture.cultivationAccount.id,
          plantingDate: values.plantingDate,
          angleFrom: values.angleFrom,
          angleTo: values.angleTo,
          maturationGroup: parseFloatCustom(values.maturationGroup),
          averageDensity: parseFloatCustom(values.averageDensity),
          distancing: parseFloatCustom(values.distancing),
          observation: String(values.observation),
          status: values.status,
        };

        await FockinkAPI.put(`cultivation/${culture.id}`, data);
        handleGetCultures();
        handleCloseHarvestFormModal();
      } catch (error) {
        setErrorRequest(true);

        setTimeout(() => {
          setErrorRequest(false);
        }, 3000);
      }
    },
  });

  const handleChangeStatus = () => {
    const { status } = formik.values;
    const newStatusValue = status === 'A' ? 'I' : 'A';
    formik.setValues({ ...formik.values, status: newStatusValue });
  };
  return (
    <S.Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <div className="angles-field">
          <Input
            name="angleFrom"
            value={formik.values.angleFrom}
            onChange={formik.handleChange}
            label="Ângulo inicial"
            error={formik.errors.angleFrom}
            type="number"
          />

          <Input
            type="number"
            style={{ marginLeft: '0.5rem' }}
            name="angleTo"
            value={formik.values.angleTo}
            onChange={formik.handleChange}
            label="Ângulo final"
            error={formik.errors.angleTo}
          />
        </div>

        <Input
          name="maturationGroup"
          value={formik.values.maturationGroup}
          onChange={formik.handleChange}
          label="Grupo de Maturação"
          error={formik.errors.maturationGroup}
          type="number"
        />
        <Input
          name="averageDensity"
          value={formik.values.averageDensity}
          onChange={formik.handleChange}
          label="Densidade Média"
          error={formik.errors.averageDensity}
          type="number"
        />
        <Input
          name="distancing"
          value={formik.values.distancing}
          onChange={formik.handleChange}
          label="Distanciamento"
          error={formik.errors.distancing}
          type="number"
        />
        <Input
          name="observation"
          value={formik.values.observation}
          onChange={formik.handleChange}
          label="Observações"
          error={formik.errors.observation}
        />

        <CardOption
          checked={formik.values.status === 'A'}
          onClick={handleChangeStatus}
        >
          {' '}
          Status -
          {' '}
          {formik.values.status === 'A' ? 'Ativo' : 'Inativo'}
        </CardOption>

        <div className="button-confirm">
          {errorRequest ? (
            <span>Erro na requisição</span>
          ) : (
            <Button
              isDisabled={!!formik.errors.angleTo || !!formik.errors.angleFrom}
              type="submit"
            >
              Atualizar
            </Button>
          )}
        </div>
      </form>
    </S.Wrapper>
  );
}
