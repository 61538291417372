// Basic
import React, { useState } from 'react';
// UI, images, styles and icons
import '../../../../../App.css';
import Grid from '@material-ui/core/Grid';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NavigationIcon from '@material-ui/icons/Navigation';
import Skeleton from '@material-ui/lab/Skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Tabs } from 'antd';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
// import { IoAlertCircleOutline } from 'react-icons/io5';
// import IconButton from '@material-ui/core/IconButton';
import colors from '../../../../../styles/colors';
import { /* number, */ formatDate } from '../../../../../helpers/Intl';
import Spacer from '../../../../../components/Spacer';
import CardActions from '../../../../../components/CardActions';
import CardText from '../../../../../components/CardText';
import CardTitle from '../../../../../components/CardTitle';
import Card from '../../../../../components/Card';
import TempAtual from '../../../../../components/Weather/Components/TempAtual/tempAtual';
import UmidAtual from '../../../../../components/Weather/Components/UmidAtual/umidAtual';
import ChuvaAtual from '../../../../../components/Weather/Components/ChuvaAtual/chuvaAtual';
import VentoAtual from '../../../../../components/Weather/Components/VentoAtual/ventoAtual';
import UvAtual from '../../../../../components/Weather/Components/UvAtual/uvAtual';
import ForecastCard from '../../../../../components/Weather/ForecastCard/forecastCard';
import HistoricalCard from '../../../../../components/Weather/HistoricalCard/historicalCard';

const useStyles = makeStyles((theme) => ({
  sourceSub: {
    fontSize: '12px',
    fontWeight: '300',
    margin: '5px 3px 0',
    textAlign: 'left',
  },
  sourceSubInverse: {
    fontSize: '12px',
    fontWeight: '300',
    margin: '5px 0 0 3px',
    textAlign: 'right',
  },
  iconTemp: {
    fontSize: '30px',
    margin: 'auto',
    width: '100%',
  },
  iconTempImg: {
    background: 'grey',
    borderRadius: '10px',
    maxHeight: '40px',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    margin: 'auto 3px',
    textAlign: 'left',
  },
  secondaryHeadingTitle: {
    fontSize: '12px',
  },
  secondaryHeadingValue: {
    fontWeight: '500',
    fontSize: '17px',
  },
  tempSub: {
    fontSize: '13px',
    fontWeight: 'bold',
    margin: 'auto 3px',
    textAlign: 'left',
  },
  eachBox: {
    borderRight: '1px solid gray',
  },
  dayHead: {
    textAlign: 'center',
    margin: '0 0 17px',
  },
  title: {
    margin: '0px 0px 8px',
  },
}));
export default (props) => {
  const [data, setData] = useState(undefined);
  const [dialog, setDialog] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const { TabPane } = Tabs;
  const sourceData = props?.weatherData?.current?.source.split(' + ');
  const lastTelemetryWS = props?.weatherData?.current?.date;
  const dateLastTelemetrWS = (formatDate(new Date(lastTelemetryWS), 'dd/MM/YYYY'));
  // const timeLastTelemetryWS = (formatDate(new Date(lastTelemetryWS), 'HH:mm:ss'));
  const [equipamentFailure, setEquipamentFailure] = useState(false);
  const [stationtFailureSecundary, setStationFailureSecundary] = useState(false);
  const [stationtFailurePrimary, setStationFailurePrimary] = useState(false);

  React.useEffect(() => {
    if (sourceData) {
      const dateNow = (formatDate(new Date(), 'dd/MM/YYYY'));
      const dateFromLastTelemetryWS = ((((dateNow - dateLastTelemetrWS) / 1000) / 60));
      const stationFailure = (dateFromLastTelemetryWS > 20.0);

      if (sourceData[0] === 'Equipamento') {
        setEquipamentFailure(props?.EquipamentState?.offline);
        setStationFailureSecundary(stationFailure);
      }
      if (sourceData[0] === 'Estação Meteorológica') {
        setStationFailurePrimary(stationFailure);
      }
    }
    // console.log(equipamentFailure);
  }, [props?.EquipamentState?.offline]);

  React.useEffect(() => {
    const dt = {};

    if (
      props.weatherData &&
      props.weatherData.forecast &&
      props.weatherData.forecast.list &&
      props.weatherData.forecast.list.length > 0
    ) {
      Object.assign(dt, {
        ...props.weatherData.forecast.list[0],
        source: props.weatherData.forecast.source,
      });
    }

    if (props.weatherData && props.weatherData.current) {
      let { source } = props.weatherData.current;
      if (source !== dt.source && dt.source) {
        source = `${source} + ${dt.source}`;
      } else {
        source = `${source}`;
      }

      Object.assign(dt, { ...props.weatherData.current, source });
    }

    setData(dt);
  }, [props.weatherData]);

  const getIcon = (iconUrl, iconId) => {
    if (iconUrl) {
      return (
        <img style={{ maxWidth: '100%' }} src={iconUrl} alt="" className={classes.iconTempImg} />
      );
    }
    if (iconId) {
      return (
        <img
          style={{ maxWidth: '100%' }}
          src={`/images/accuweatherIcons/${iconId}-s.png`}
          alt=""
          className={classes.iconTempImg}
        />
      );
    }
    return <WbSunnyIcon className={classes.iconTemp} />;
  };

  if (!data) {
    return <Skeleton variant="rect" height="100%" width="100%" animation="wave" />;
  }

  return (
    <Card similar flat>
      <CardTitle pt="8px" pr="8px" pl="8px" pb="0" size="1rem">
        Dados Meteorológicos:
      </CardTitle>
      <CardText pa="8px">
        <Card
          outlined
          hover
          changeBgColor
          color={colors.primaryUserBrighter}
          onClick={() => {
            setDialog(true);
          }}
        >
          <CardText pa="8px">
            <h3 className={classes.title}>Tempo Atual</h3>
            {
              equipamentFailure ?
                (
                  <Alert severity="error">Equipamento sem comunicação.</Alert>
                )
                :
                stationtFailurePrimary ?
                  (
                    <Alert severity="error">Estação Meteorológica a mais de 20 min sem comunicação.</Alert>
                  )
                  :
                  (
                    <Grid container spacing={1} alignContent="center" alignItems="center">
                      <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: 'center' }}>
                        {getIcon(data.iconUrl, data.iconId)}
                      </Grid>
                      <Grid item lg={10} xs={10}>
                        <Grid container spacing={2}>
                          <Grid item xs={6} sm={4} md={4} lg={6} xl={4}>
                            <small style={{ fontSize: '13px', fontWeight: '200' }}>T. Atual </small>
                            <h4 className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}>
                              <TempAtual info1={data.temp} info2="" source={data.source} type={0} />
                            </h4>
                          </Grid>
                          <Grid item xs={6} sm={4} md={4} lg={6} xl={4}>
                            <small style={{ fontSize: '13px', fontWeight: '200' }}>Umid. </small>
                            <h4 className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}>
                              <UmidAtual
                                info1={data.humid}
                                info2={data.humidity}
                                source={data.source}
                                type={0}
                              />
                            </h4>
                          </Grid>
                          <Grid item xs={6} sm={4} md={4} lg={6} xl={4}>
                            <small style={{ fontSize: '13px', fontWeight: '200' }}>Chuva </small>
                            <h4 className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}>
                              <ChuvaAtual
                                info1={props.weatherData?.current?.rain}
                                info2={props.weatherData?.forecast?.list[0].rain}
                                source={data.source}
                                type={0}
                              />
                            </h4>
                          </Grid>
                          {
                          stationtFailureSecundary ?
                            (
                              <Grid item xs={12}>
                                <Alert severity="error">Estação Meteorológica a mais de 20 min sem comunicação.</Alert>
                              </Grid>
                            )
                            :
                            (
                              <>
                                <Grid item xs={6} sm={4} md={4} lg={6} xl={4}>
                                  <small style={{ fontSize: '13px', fontWeight: '200' }}>UV </small>
                                  <h4 className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}>
                                    <UvAtual info1={data.uv} info2={data.uvIndex} />
                                  </h4>
                                </Grid>
                                <Grid item xs={6} sm={4} md={4} lg={6} xl={4}>
                                  <small style={{ fontSize: '13px', fontWeight: '200' }}>
                                    Vento
                                    {' '}
                                    <Tooltip title={`${data.windDirection}°`}>
                                      <NavigationIcon
                                        style={{
                                          width: 'auto',
                                          height: '12px',
                                          transform: `rotate(${data.windDirection}deg)`,
                                        }}
                                      />
                                    </Tooltip>
                                  </small>
                                  <h4 className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}>
                                    <VentoAtual
                                      info1={props.weatherData?.current?.windSpeed}
                                      info2={props.weatherData?.forecast?.list[0].windSpeed}
                                      source={data.source}
                                      type={0}
                                    />
                                  </h4>
                                </Grid>
                              </>
                            )
                        }

                        </Grid>
                      </Grid>
                    </Grid>
                  )
            }

          </CardText>
          <CardActions>
            {lg ? (
              <Tooltip title={`Data de Aferição: ${formatDate(new Date(data.date))}`}>
                <span className={classes.sourceSub}>{`Fonte: ${data.source}`}</span>
              </Tooltip>
            ) : (
              <>
                <span className={classes.sourceSub}>{`Fonte: ${data.source}`}</span>
                <Spacer />
                <span className={classes.sourceSubInverse}>
                  {`${formatDate(new Date(data.date))}`}
                </span>
              </>
            )}
          </CardActions>
        </Card>
        {props.weatherData?.alerts && props.weatherData?.alerts.length > 0 && (
          <Alert severity="warning" style={{ marginTop: 5 }}>
            <strong>{props.weatherData?.alerts[0].event}</strong>
          </Alert>
        )}
      </CardText>
      <Dialog open={dialog} fullWidth maxWidth="lg" fullScreen={sm} aria-labelledby="termo">
        <CardTitle color={sm ? '#3d5059' : ''} textColor={sm ? '#FFF' : ''}>
          Dados Meteorológicos
          {' '}
          <Spacer />
          <CloseIcon
            style={{ cursor: 'pointer' }}
            onClick={(e) => {
              e.stopPropagation();
              setDialog(false);
            }}
          />
        </CardTitle>
        <DialogContent>
          <Grid item xs={12} sm={12} lg={12}>
            <h3 className={classes.title}>Hoje</h3>
            <Card>
              <CardText pa={sm ? '8px' : ''}>
                {
                  equipamentFailure ?
                    (
                      <Alert severity="error">Equipamento sem comunicação.</Alert>
                    )
                    :
                    stationtFailurePrimary ?
                      (
                        <Alert severity="error">Estação Meteorológica a mais de 20 min sem comunicação.</Alert>
                      )
                      :
                      (
                        <Grid container spacing={1} alignContent="center" alignItems="center">
                          <Grid item xs={2} sm={3} md={2} lg={2} xl={2} style={{ textAlign: 'center' }}>
                            {getIcon(data.iconUrl, data.iconId)}
                          </Grid>
                          <Grid item xs={9} sm={9} md={10} lg={10} xl={10}>
                            <Grid container spacing={3}>
                              <Grid item xs={5} sm={3} md={2} lg={2} xl={2}>
                                <h4
                                  className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                                >
                                  T. Atual
                                  {' '}
                                </h4>
                                <h4
                                  className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                                >
                                  <TempAtual info1={data.temp} info2="" source={data.source} type={0} />
                                </h4>
                              </Grid>
                              <Grid item xs={5} sm={3} md={2} lg={2} xl={2}>
                                <h4 className={classes.tempSub}>
                                  <TempAtual
                                    info1={props.weatherData?.historical.data[0].maxTemp}
                                    info2={props.weatherData?.forecast?.list[0].max}
                                    source=""
                                    type={1}
                                  />
                                </h4>
                                <h4 className={classes.tempSub}>
                                  <TempAtual
                                    info1={props.weatherData?.historical.data[0].minTemp}
                                    info2={props.weatherData?.forecast?.list[0].min}
                                    source=""
                                    type={2}
                                  />
                                </h4>
                              </Grid>
                              <Grid item xs={5} sm={3} md={2} lg={2} xl={2}>
                                <h4
                                  className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                                >
                                  Umid.
                                  {' '}
                                </h4>
                                <h4
                                  className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                                >
                                  <UmidAtual
                                    info1={data.humid}
                                    info2={data.humidity}
                                    source={data.source}
                                    type={0}
                                  />
                                </h4>
                              </Grid>
                              <Grid item xs={5} sm={3} md={2} lg={2} xl={2}>
                                <h4
                                  className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                                >
                                  Chuva
                                  {' '}
                                </h4>
                                <h4
                                  className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                                >
                                  <ChuvaAtual
                                    info1={props.weatherData?.current?.rain}
                                    info2={props.weatherData?.forecast?.list[0].rain}
                                    source={data.source}
                                    type={0}
                                  />
                                </h4>
                              </Grid>
                              {
                          stationtFailureSecundary ?
                            (
                              <Grid item xs={10} sm={6} md={4} lg={4} xl={4}>
                                <Alert severity="error">Estação Meteorológica a mais de 20 min sem comunicação.</Alert>
                              </Grid>
                            )
                            :
                            (
                              <>
                                <Grid item xs={5} sm={3} md={2} lg={2} xl={2}>
                                  <h4
                                    className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                                  >
                                    UV
                                    {' '}
                                  </h4>
                                  <h4
                                    className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                                  >
                                    <UvAtual info1={data.uv} info2={data.uvIndex} />
                                  </h4>
                                </Grid>
                                <Grid item xs={5} sm={3} md={2} lg={2} xl={2}>
                                  <h4
                                    className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                                  >
                                    Vento
                                    {' '}
                                    <Tooltip title={`${data.windDirection}°`}>
                                      <NavigationIcon
                                        style={{
                                          width: 'auto',
                                          height: '12px',
                                          transform: `rotate(${data.windDirection}deg)`,
                                        }}
                                      />
                                    </Tooltip>
                                  </h4>
                                  <h4
                                    className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                                  >
                                    <VentoAtual
                                      info1={props.weatherData?.current?.windSpeed}
                                      info2={props.weatherData?.forecast?.list[0].windSpeed}
                                      source={data.source}
                                      type={0}
                                    />
                                  </h4>
                                </Grid>
                              </>
                            )
                                  }
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                }

              </CardText>
            </Card>
            <Grid container spacing={2} style={{ marginBotton: 15 }}>
              <Grid item xs={6}>
                <Tooltip title={`Data de Aferição: ${formatDate(new Date(data.date))}`}>
                  <h4 className={classes.sourceSub}>{`Fonte: ${data.source}`}</h4>
                </Tooltip>
              </Grid>
              <Grid item xs={6}>
                <h4 className={classes.sourceSubInverse}>* openweathermap</h4>
              </Grid>
            </Grid>
            {props.weatherData?.alerts && props.weatherData?.alerts.length > 0 && (
              <Alert severity="warning" style={{ marginTop: 5 }}>
                <AlertTitle>{props.weatherData?.alerts[0].event}</AlertTitle>
                {props.weatherData?.alerts[0].description}
              </Alert>
            )}
          </Grid>

          <Tabs defaultActiveKey="1">
            <TabPane tab="Previsão do Tempo" key="1">
              <DialogContentText>
                <Grid container spacing={3} alignContent="flex-start" alignItems="flex-start">
                  <Grid item xs={12} lg={12}>
                    <Grid container spacing={2}>
                      {props.weatherData &&
                      props.weatherData.forecast &&
                      props.weatherData.forecast.list ? (
                          [1, 2, 3, 4, 5, 6].map((e) => {
                            const nd = new Date();
                            nd.setDate(nd.getDate() + e);
                            const item = props.weatherData.forecast.list[e];

                            if (!item) {
                              return <Grid key={nd} item xs={12} lg={6} />;
                            }

                            return <ForecastCard nd={nd} sm={sm} item={item} />;
                          })
                        ) : (
                          <Grid item xs={12}>
                            <h3 style={{ textAlign: 'center', fontWeight: '400' }}>
                              Previsão do tempo indisponível
                            </h3>
                          </Grid>
                        )}
                    </Grid>

                    <Grid container spacing={2} style={{ marginBotton: 15 }}>
                      <Grid item xs={12}>
                        <h4 className={classes.sourceSub} style={{ marginTop: 0 }}>
                          {props.weatherData && `Fonte: ${props.weatherData.forecast.source}`}
                        </h4>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogContentText>
            </TabPane>
            <TabPane tab="Histórico" key="2">
              <DialogContentText>
                <Grid container spacing={3} alignContent="flex-start" alignItems="flex-start">
                  <Grid item xs={12} lg={12}>
                    <Grid container spacing={2}>
                      {props.weatherData &&
                      props.weatherData.historical &&
                      props.weatherData.historical.data ? (
                          [0, 1, 2, 3, 4].map((e) => {
                            const nd = new Date();
                            nd.setDate(nd.getDate() + e);
                            const item = props.weatherData.historical.data[e];
                            const indiceDados = 7;
                            let dadosIndisp = 0;

                            item?.maxTemp ? (dadosIndisp += 0) : (dadosIndisp += 1);
                            item?.minTemp ? (dadosIndisp += 0) : (dadosIndisp += 1);
                            item?.maxHumd ? (dadosIndisp += 0) : (dadosIndisp += 1);
                            item?.minHumd ? (dadosIndisp += 0) : (dadosIndisp += 1);
                            item?.rain ? (dadosIndisp += 0) : (dadosIndisp += 1);
                            item?.uv ? (dadosIndisp += 0) : (dadosIndisp += 1);
                            item?.windSpeed ? (dadosIndisp += 0) : (dadosIndisp += 1);

                            if (!item) {
                              return <Grid key={nd} item xs={12} lg={6} />;
                            }

                            return (
                            // historicalCard(nd, sm, item, dadosIndisp, indiceDados);
                              <HistoricalCard
                                nd={nd}
                                sm={sm}
                                item={item}
                                dadosIndisp={dadosIndisp}
                                indiceDados={indiceDados}
                              />
                            );
                          })
                        ) : (
                          <Grid item xs={12}>
                            <h3 style={{ textAlign: 'center', fontWeight: '400' }}>
                              Previsão do tempo indisponível
                            </h3>
                          </Grid>
                        )}
                    </Grid>
                    <h4 className={classes.sourceSub}>
                      {props.weatherData && `Fonte: ${props.weatherData.historical?.source || ''}`}
                    </h4>
                  </Grid>
                </Grid>
              </DialogContentText>
            </TabPane>
          </Tabs>
        </DialogContent>
      </Dialog>
    </Card>
  );
};
