import styled from 'styled-components';
import { generateMedia } from 'styled-media-query';

const customMedia = generateMedia({
  xs: '120px',
  xxs: '400px',
  sm: '800px',
  md: '1024px',
  lg: '1200px',
});

export const Container = styled.div`
  display: flex;
  cursor: pointer;
  max-width: 250px;
  height: 100%;
  max-height: 280px;
  padding: 0.5rem;
  margin-left: 2rem;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.blueLight2};
  border-radius: 0.4rem;
  @media (max-width: 1200px) {
    height: 100%;
  }

  ${customMedia.between('xs', 'xxs')`
    min-width: 60%;
    height: 100%;
  `}
`;

export const NameContainer = styled.div`
  text-align: center;
  margin-bottom: 0.5rem;
`;

export const Name = styled.p`
  margin: 0px;
  font-size: 1.2rem;
`;

export const Header = styled.div`
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export const ContainerHour = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Hours = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.blueDeep};
  margin: 0px;

  ${customMedia.between('xxs', 'sm')`
    font-size: 23px;
  `}

  ${customMedia.between('xs', 'xxs')`
    font-size: 16px;
  `}
`;

export const DateHours = styled.p`
  font-size: 1.2rem;
  margin: 0px;

  ${customMedia.between('xxs', 'sm')`
    font-size: 10px;
  `}

  ${customMedia.between('xs', 'xxs')`
    font-size: 9px;
  `}
`;

export const LegendHours = styled.p`
  font-size: 1rem;
  font-weight: bold;
  margin: 0px;
  text-align: center;

  ${customMedia.between('xxs', 'sm')`
    font-size: 10px;
  `}

  ${customMedia.between('xs', 'xxs')`
    font-size: 9px;
  `}
`;

export const Chart = styled.div`
  display: flex;
  flex-direction: column;
  justify-items: baseline;
  max-width: 100%;
  max-height: 100%;
`;

export const Loader = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
