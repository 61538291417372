/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../App.css';
import Skeleton from '@material-ui/lab/Skeleton';
import Swal from 'sweetalert2';
import WarningIcon from '@material-ui/icons/Warning';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import withReactContent from 'sweetalert2-react-content';
import XLSX from 'xlsx-js-style'; // Exportar em Excel
import jwtDecode from 'jwt-decode';
import {
  headerCell,
  headerCellSplit,
  dataCell,
  dataCellSplit,
  dataArrowCell,
  descriptionHeaderCell,
  getColor,
  fitToColumn,
} from '../../../../components/AuxExcelExport/index'; // Exportar em Excel
import FockinkAPI from '../../../../middlewares/Api';
import { secondsToHm } from '../../../../helpers/Intl';
import ThermometryHotSensorsTable from './ThermometryHotSensorsTable';

export default (props) => {
  const [loading, setLoading] = React.useState(true);
  const [HS, setHS] = React.useState([]);
  const [reportLogs, setReportLogs] = React.useState([]);
  const [mDS, setMDS] = useState([]);
  const MySwal = withReactContent(Swal);
  const token = localStorage.getItem('@FockinkAPP:token');
  const { unit } = jwtDecode(token);

  React.useEffect(() => {
    const aux = [];
    if (HS.length > 0) {
      // const titleExcel = 'Quantidade de Pontos';
      HS.forEach((bloco) => {
        // bloco
        /** ** inicia o preencimento do header para excel *** */
        const len = bloco[0].ranges.length;
        const merges = [
          { s: { r: 0, c: 0 }, e: { r: 0, c: 4 } },
          { s: { r: 0, c: 5 }, e: { r: 0, c: len * 2 + 9 } },
          { s: { r: 1, c: 5 + len * 2 }, e: { r: 1, c: 6 + len * 2 } },
        ];

        const description = [
          descriptionHeaderCell({ value: 'Filial' }),
          descriptionHeaderCell({ value: '' }),
          descriptionHeaderCell({ value: '' }),
          descriptionHeaderCell({ value: '' }),
          descriptionHeaderCell({ value: '' }),
          descriptionHeaderCell({ value: 'Termometria' }),
          descriptionHeaderCell({ value: '' }),
          descriptionHeaderCell({ value: '' }),
          descriptionHeaderCell({ value: '' }),
          descriptionHeaderCell({ value: '' }),
        ];

        const hedrExcel = [
          headerCell({ value: unit?.plural || 'Unidades' }),
          headerCell({ value: 'Equipamento' }),
          headerCell({ value: 'Planta' }),
          headerCell({ value: 'Data' }),
          headerCellSplit({ value: 'Grão' }),
        ];
        bloco[0].ranges.forEach((range, rangeIndx) => {
          /** ** preenche o heaedr com os range de temperatura *** */

          if (rangeIndx === 0) {
            hedrExcel.push(
              headerCell({ value: `Pontos < ${range.limitMax}°C` }),
            );
          } else if (rangeIndx === len - 1) {
            hedrExcel.push(
              headerCell({ value: `Pontos > ${range.limitMin}°C` }),
            );
          } else if (rangeIndx === len - 2) {
            hedrExcel.push(
              headerCell({ value: `Pontos de ${range.limitMin}°C a ${range.limitMax}°C` }),
            );
          } else {
            hedrExcel.push(
              headerCell({ value: `Pontos de ${range.limitMin}°C a ${range.limitMax - 1}°C` }),
            );
          }

          hedrExcel.push(headerCell({ value: '' }));
          description.push(...[descriptionHeaderCell({ value: '' }), descriptionHeaderCell({ value: '' })]);
          merges.push({ s: { r: 1, c: 5 + rangeIndx * 2 }, e: { r: 1, c: 6 + rangeIndx * 2 } });
        });

        /** ** completa o preenchimento do header para excel *** */
        hedrExcel.push(headerCell({ value: 'Total Pontos Quentes' }));
        hedrExcel.push(headerCell({ value: '' }));
        hedrExcel.push(headerCell({ value: 'Total Pontos Armazenados' }));
        hedrExcel.push(headerCell({ value: '% Pontos Quentes' }));
        // hedrExcel.push(headerCell({ value: 'Horas de Aeração' }));
        hedrExcel.push(headerCell({ value: 'Aeração das últimas 24h até a data selecionada' }));

        const itemDataExcel = [];
        bloco.forEach((item) => {
          const dtExcel = [
            dataCell({ value: (item.units || []).length > 0 ? item.units.map((m) => m.description).join(', ') : 'Não associado' }),
            dataCell({ value: item.equipament.clientDescription || item.equipament.description }),
            dataCell({ value: item.plant.description }),
            dataCell({ value: item.date }),
            dataCellSplit({ value: item.grain }),
          ];

          item.ranges.forEach((range) => {
            /** ** preenche o data com os range de temperatura *** */
            dtExcel.push(dataCell({ value: range.qty }));
            dtExcel.push(dataArrowCell({ value: range.ind, color: range.color }));
          });

          /** ** completa o preenchimento do data para excel *** */
          dtExcel.push(dataCell({ value: item.totalHotSensors.toString() }));
          dtExcel.push(
            dataArrowCell({
              value: item.totalHotSensorsInd,
              color: getColor(item.totalHotSensorsInd),
            }),
          );
          // dataExcel.push(dataCell({ value: item.totalHotSensorsRef }));
          dtExcel.push(dataCell({ value: item.totalSensors.toString() }));
          dtExcel.push(dataCell({ value: (item.percentHotSensors || 0).toFixed(2).toString() }));
          dtExcel.push(dataCell({ value: secondsToHm(item.aerationDaySec || 0) }));

          itemDataExcel.push(dtExcel);
        });

        aux.push({
          sheet: [
            // [titleExcel],
            description,
            hedrExcel,
            ...itemDataExcel,
          ],
          merges,
        });
      });
      setMDS(aux);
    }
  }, [HS]);

  const callApi = () => {
    const searchDataFilter = { ...props.searchData };
    if (searchDataFilter.equipamentIds.length === 0) {
      delete searchDataFilter.equipamentIds;
    }
    // if (searchDataFilter.grain.indexOf('Todas') !== -1) delete searchDataFilter.grain;
    if (searchDataFilter.grain === '--ALL--') delete searchDataFilter.grain;
    delete searchDataFilter.searched;
    delete searchDataFilter.units;

    setLoading(true);
    FockinkAPI.get('/reports/thermometry/hotSensors', {
      params: searchDataFilter,
    })
      .then((responseHS) => {
        setHS(responseHS.data.data.reportData);
        setReportLogs(responseHS.data.data.reportLogs);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    callApi();
  }, [props.searchData]);

  const showErrors = () => {
    MySwal.fire({
      icon: 'warning',
      title: `${(reportLogs || []).length} log${
        (reportLogs || []).length > 1 ? 's' : ''
      } encontrado${(reportLogs || []).length > 1 ? 's' : ''}`,
      html: `
        <div style="text-align: left">
          ${(reportLogs || []).map((m, i) => `<p>${i + 1}) ${m}</p>`).join('')}
        </div>`,
    });
  };

  const iconLog = () => {
    if ((reportLogs || []).length === 0) {
      return (
        <Tooltip title="Perfeito! Nenhum log encontrado ao gerar o relatório">
          <CheckIcon style={{ color: 'green' }} />
        </Tooltip>
      );
    }
    return (
      <Tooltip title="Logs encontrados ao gerar o relatório! Clique para saber mais..">
        <WarningIcon style={{ color: 'red', cursor: 'pointer' }} onClick={showErrors} />
      </Tooltip>
    );
  };

  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();
    mDS.forEach(({ sheet, merges }, index) => {
      const ws = XLSX.utils.aoa_to_sheet(sheet);
      ws['!merges'] = merges;
      ws['!cols'] = fitToColumn(sheet);
      XLSX.utils.book_append_sheet(wb, ws, `Tabela ${index + 1}`);
    });
    XLSX.writeFile(wb, `report-informe-de-qualidade-${new Date().toISOString().substring(0, 10)}.xlsx`);
  };

  if (loading) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Skeleton variant="rect" height={500} width="100%" animation="wave" />
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid container spacing={1} justifyContent="space-between" alignItems="stretch" style={{ marginTop: 10 }}>
      <Grid item xs={12} md={10} lg={10}>
        <h3 style={{ display: 'flex' }}>
          Pontos quentes por Plantas e Grãos
          {iconLog()}
        </h3>
      </Grid>
      <Grid item xs={12} md={2} lg={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={() => {
            handleExportExcel();
          }}
        >
          <GetAppIcon />
          XSLX
        </Button>
      </Grid>

      {/* {reportLogs && reportLogs.length > 0 && (
        <Grid item xs={12}>
          <h3
            onClick={() => showErrors()}
            style={{
              fontWeight: '500',
              color: 'black',
              margin: '0px auto',
              width: 'fit-content',
              border: '2px solid red',
              background: 'rgba(255, 0, 0, 0.12)',
              textAlign: 'center',
              cursor: 'pointer',
              padding: '10px',
            }}
          >
            <ErrorIcon />
            Erros encontrados ao gerar o relatório! Clique para saber mais..
          </h3>
        </Grid>
      )} */}

      {HS.map((e) => (
        <Grid key={e[0] ? e[0].id : 'empty'} item xs={12}>
          <ThermometryHotSensorsTable data={e} />
        </Grid>
      ))}
    </Grid>
  );
};
