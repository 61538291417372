import React from 'react';
import { Grid } from '@mui/material';
import verifyTemperature from '../../../../../helpers/verifyTemperature';
import { IconTemperature } from '../Icons/WeatherIcons';
import { number } from '../../../../../helpers/Intl';

export default function Temp({ value, icon, noValue }) {
  if ((!value) && (value !== 0)) {
    value = 'OFF';
  } else {
    value = number(value, 1, 1);
    value = verifyTemperature(value);
  }

  if (!icon) {
    icon = false;
  }

  if (!noValue) {
    noValue = false;
  }

  return (
    <div style={{ textAlign: 'center' }}>
      {!noValue ? (
        <Grid item xs={12}>
          {value}
          {value !== 'OFF' ? ' °C' : '' }
        </Grid>
      )
        :
        ''}
      <Grid item xs={12}>
        {
          icon ?
            <IconTemperature size={50} color="#556E7B" />
            :
            ''
        }
      </Grid>
      <Grid item xs={12}>
        Temperatura
      </Grid>
    </div>
  );
}
