// TEXTURAS UTILIZADAS NO 3D
import duvida from './assets/texturas/duvida.png';
import pilarMetal from './assets/texturas/pilar-metal.jpg';
import amareloSeguranca from './assets/texturas/amarelo-seguranca.png';
import auto from './assets/texturas/auto.png';
import baseSilo from './assets/texturas/base-silo.png';
import block from './assets/texturas/block.png';
import calcada from './assets/texturas/calcada.png';
import degrauEscada from './assets/texturas/degrau-escada.png';
import ground from './assets/texturas/grama1.jpg';
import corn from './assets/texturas/graos/corn.jpg';
import padrao from './assets/texturas/graos/produto.jpg';
import soybean from './assets/texturas/graos/soybean.jpg';
import wheat from './assets/texturas/graos/wheat.jpg';
import hand from './assets/texturas/hand.png';
import paredeLateral from './assets/texturas/parede-lateral.png';
import produto from './assets/texturas/produto.jpg';
import skybox_nx from './assets/texturas/skybox2/skybox_nx.jpg';
import skybox_ny from './assets/texturas/skybox2/skybox_ny.jpg';
import skybox_nz from './assets/texturas/skybox2/skybox_nz.jpg';
import skybox_px from './assets/texturas/skybox2/skybox_px.jpg';
import skybox_py from './assets/texturas/skybox2/skybox_py.jpg';
import skybox_pz from './assets/texturas/skybox2/skybox_pz.jpg';
// const skybox_pz = '/images/3DVisualization/texturas/skybox2/skybox_pz.jpg';
import blueDeep from './assets/texturas/status/bluedeep.png';
import blueLight from './assets/texturas/status/bluelight.png';
import greenDeep from './assets/texturas/status/greendeep.png';
import greenLight from './assets/texturas/status/greenlight.png';
import greyDeep from './assets/texturas/status/greydeep.png';
import greyLight from './assets/texturas/status/greylight.png';
import redDeep from './assets/texturas/status/reddeep.png';
import redLight from './assets/texturas/status/redlight.png';
import yellowDeep from './assets/texturas/status/yellowdeep.png';
import yellowLight from './assets/texturas/status/yellowlight.png';
import texturaAmarelaParaEscada from './assets/texturas/textura-amarela-para-escada.png';
import texturaTelhadoLateral from './assets/texturas/textura-telhado-lateral.png';
import texturaTelhado from './assets/texturas/textura-telhado.png';
import texturaSilo from './assets/texturas/texturasilo.png';
import texturaTopoSilo from './assets/texturas/texturatoposilo.png';

const theme = {
  colors: {
    background: '#e1e1e6',
    backgroundLight: '#f0f0f0',
    backgroundLight2: '#eeeeee',
    backgroundLight3: '#e3e3e3',
    backgroundLight4: '#ececec',
    backgroundLight5: '#fddede',
    backgroundLight6: '#e8e8e8',
    backgroundLight7: '#dbead5',
    backgroundLight8: '#e2e2e2',
    backgroundLight9: '#f4ede8',
    backgroundLight10: '#dcdcdc',
    backgroundLight11: '#e7e6e6',

    backgroundDark: '#010101',
    backgroundDark2: '#000000',
    backgroundDark3: '#232129',
    backgroundDark4: '#666666',
    backgroundDark5: '#1b1c1d',

    backgroundGrey: '#bcbcbc',
    backgroundGrey2: '#808080',
    backgroundGrey3: '#bfbfbf',
    backgroundGrey4: '#555555',
    backgroundGrey5: '#757575',
    backgroundGrey6: '#d3d3d3',

    primary: '#333333',

    snow: '#efefef',

    card_light: '#ffffff',
    card_info_light: '#f1f1f1',

    container_light: '#f4f4f4',

    button_login_dark: '#010101',
    button_save_shaded: '#2749ad',
    button_save: '#3b64dd',

    text: '#121214',
    chip_text_light: '#ffffff',

    message_light: '#ffffff',

    message_dark: '#777777',
    message_dark_2: '#888888',
    messageDark3: '#222222',
    messageDark4: '#717171',
    messageDark5: '#000000',
    messageDark6: '#333333',
    messageDark7: '#999999',
    messageDark8: '#373d3f',
    messageDark9: '#666360',
    messageDark10: '#666666',
    messageDark11: '#919191',
    messageDark12: '#c1c1c1',
    messageDark13: '#444444',
    messageDark14: '#808080',

    message_red: '#ff0000',
    messageRed2: '#ff3333',
    messageRed3: '#ff4949',
    messageRed4: '#ed7a78',
    red: '#ed7272',
    red2: '#ff2400',
    red3: '#c53030',
    red4: '#fbb6c1',
    red5: '#e83525',
    red6: '#ed7a78',
    red7: '#f9a7a7',
    red8: '#ff0000',
    red9: '##ff6961',
    red10: '#cb0000',

    yellow: '#f4f142',
    yellowLight: '#f9eac3',
    yellowDeep: '#ECE101',

    message_green: '#008000',
    message_green2: '#58f45e',
    message_green3: '#7eed78',
    green: '#008000',
    greenDeep: '#319a5d',
    greenDeep2: '#1f9827',
    greenDeep3: '#2e656a',
    greenDeep4: '#26e7a5',
    greenDeep5: '#7eed78',
    greenDeep6: '#008f39',
    greendeep7: '#74d175',
    greenLight: '#d6fce6',
    greenLight2: '#e0e1e2',
    greenLight3: '#d4fdd7',
    greenLight4: '#a9b7b4',
    greenLight5: '#effffa',
    greenLight6: '#e5eae2',
    greenLight7: '#b6f9a7',
    greenLight8: '#58f45e',
    greenLight9: '#2fbf26',

    grassLight: '#B7FBA6',
    grass: '#55CA38',

    statusMotorOn: '#69cf50',
    statusMotorOff: '#cccccc',
    statusMotorFail: '#cf5050',
    statusMotorBlock: '#dbc443',
    statusMotorAuto: '#0a0a8f',

    greyLight: '#bebebe',
    greyLight2: '#bababa',

    ocean: '#338ddf',
    ocean2: 'rgb(51, 141, 223)',
    smog: '#959394',

    blueDeep: '#416ce0',
    blueDeep1: '#3172b7',
    blueDeep2: '#3880ff',
    blueDeep3: '#78abed',
    blueDeep4: '#6fa1e2',
    blueDeep5: '#a2f9e5',
    blueDeep6: 'rgb(51, 141, 223)',
    blueDeep7: '#7f9bff',
    blueDeep8: '#2185d0',
    blueDeep9: '#12145b',
    blueLight: '#e0e0e0',
    blueLight2: '#d4e1fd',
    blueLight3: '#ebf8ff',
    blueLight4: '#d6d6d6',
    blueLight5: '#dddddd',
    blueLight6: '#a7c0f9',
    blueLight7: '#d9d9d9',

    cristalWater: '#a2f9e5',

    messageBlueDeep: '#78909C',
    messageBlueDeep2: '#247BA0',
    messageBlueDeep3: '#4580ff',

    violet: '#3f51b5',

    orangeLight: '#fbc0a6',
    orange: '#f25c1B',
    orange2: '#ffa500',
    orange3: '#FF8900',

    label_up_input: 'ff3333',
    menu_light: '#ffffff',
    shadow: '#727272',
    dropdown_background: '#e0e1e2',
    logoutButton: '#bbbbbb',
    skyBlue: '#6da4c9',
    skyBlueLight: '#cdcdcd',
    skyBlue2: '#a1b9c9',

    chartRed: '#F44336',
    chartRed2: '#cb2027',
    chartRed3: '#dd4b39',
    chartRedLight: '#ed6666',
    chartRedLight2: '#f66b6b',

    chartBlue: '#416ce0',
    chartBlueLight: '#6691ed',
    chartBlueLight2: '#398ef2',
    chartBlueDeep: '#6790f9',
    chartBlueDeep2: '#1166e8',
    chartBlueSurf: '#00bfff',

    chartViolet: '#253b80',
    chartVioletDeep: '#400191',

    chartYellow: '#ffbf00',
    chartYellow2: '#fdd500',
    chartGreen: '#1fad29',
    chartGreen2: '#107b11',
    chartGreenLight: '#78ed66',
    chartGreenLight2: '#5cff75',
    chartGreenLight3: '#7ab800',

    chartGrey: '#979899',

    chartDark: '#060707',

    overlay: 'rgba(0, 0, 0, 0.1)',
  },
  textures: {
    paredeLateral,
    duvida,
    pilarMetal,
    amareloSeguranca,
    auto,
    baseSilo,
    block,
    calcada,
    degrauEscada,
    hand,
    produto,
    texturaAmarelaParaEscada,
    texturaTelhadoLateral,
    texturaTelhado,
    texturaSilo,
    texturaTopoSilo,
    ground,
    blueLight,
    blueDeep,
    greenLight,
    greenDeep,
    greyLight,
    greyDeep,
    redLight,
    redDeep,
    yellowLight,
    yellowDeep,
    corn,
    padrao,
    soybean,
    wheat,
    skybox_nx,
    skybox_ny,
    skybox_nz,
    skybox_px,
    skybox_py,
    skybox_pz,
  },
};

export default theme;
