import React from 'react';

import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import NavigationIcon from '@material-ui/icons/Navigation';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Card from '../../Card';
import CardText from '../../CardText';
import { number } from '../../../helpers/Intl';

/*
const useStyles = makeStyles((theme) => ({
  iconTemp: {
    fontSize: '30px',
    margin: 'auto',
    width: '100%',
  },
  iconTempImg: {
    background: 'grey',
    borderRadius: '10px',
    maxHeight: '40px',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    margin: 'auto 3px',
    textAlign: 'left',
  },
  secondaryHeadingTitle: {
    fontSize: '12px',
  },
  secondaryHeadingValue: {
    fontWeight: '500',
    fontSize: '17px',
  },
  tempSub: {
    fontSize: '13px',
    fontWeight: 'bold',
    margin: 'auto 3px',
    textAlign: 'left',
  },
}));
*/

export default function ForecastCard({ nd, sm, item }) {
  const classes = ''; // useStyles();
  // const theme = useTheme();

  const getIcon = (iconUrl, iconId) => {
    if (iconUrl) {
      return (
        <img
          src={iconUrl}
          alt=""
          className={classes.iconTempImg}
          style={{
            maxWidth: '100%',
            background: 'grey',
            borderRadius: '10px',
            maxHeight: '40px',
          }}
        />
      );
    }
    if (iconId) {
      return (
        <img
          src={`/images/accuweatherIcons/${iconId}-s.png`}
          alt=""
          className={classes.iconTempImg}
          style={{
            maxWidth: '100%',
            background: 'grey',
            borderRadius: '10px',
            maxHeight: '40px',
          }}
        />
      );
    }
    return (
      <WbSunnyIcon
        className={classes.iconTemp}
        style={{
          fontSize: '30px',
          margin: 'auto',
          width: '100%',
        }}
      />
    );
  };

  const formatData = (date) => {
    const reg = date.match(/(\d{4})-(\d{2})-(\d{2})/);
    if (reg) {
      return `${reg[3]}/${reg[2]}`;
    }
    return date;
  };

  return (
    <>
      <Grid key={nd} item xs={12} sm={4}>
        <Card style={{ minHeight: 164, alignContent: 'center' }}>
          <CardText pa={sm ? '8px' : ''} style={{ minHeight: 164, alignContent: 'center' }}>
            <Grid container style={{ minHeight: 164, alignContent: 'center' }}>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                <h4
                  style={{
                    marginBottom: '4px',
                    marginTop: '36px',
                  }}
                >
                  {`${formatData(item.dateText)}`}
                </h4>
                {getIcon(item.iconUrl, item.iconId)}
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <h4
                      className={classes.tempSub}
                      style={{
                        fontSize: '13px',
                        fontWeight: 'bold',
                        margin: 'auto 3px',
                        textAlign: 'left',
                      }}
                    >
                      {`${number(item.max, 1, 1)}°C Max.`}
                    </h4>
                    <h4
                      className={classes.tempSub}
                      style={{
                        fontSize: '13px',
                        fontWeight: 'bold',
                        margin: 'auto 3px',
                        textAlign: 'left',
                      }}
                    >
                      {`${number(item.min, 1, 1)}°C Min.`}
                    </h4>
                  </Grid>
                  <Grid item xs={5}>
                    <h4
                      className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                      style={{
                        fontSize: '12px',
                        margin: 'auto 3px',
                        textAlign: 'left',
                      }}
                    >
                      Umid.
                      {' '}
                    </h4>
                    <h4
                      className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                      style={{
                        margin: 'auto 3px',
                        textAlign: 'left',
                        fontWeight: '500',
                        fontSize: '17px',
                      }}
                    >
                      {`${number(item.humid || item.humidity, 1, 1)}%`}
                    </h4>
                  </Grid>
                  <Grid item xs={7}>
                    <h4
                      className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                      style={{
                        fontSize: '12px',
                        margin: 'auto 3px',
                        textAlign: 'left',
                      }}
                    >
                      Precip.
                      {' '}
                    </h4>
                    <h4
                      className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                      style={{
                        margin: 'auto 3px',
                        textAlign: 'left',
                        fontWeight: '500',
                        fontSize: '17px',
                      }}
                    >
                      {`${number(item.pop * 100, 2, 2)}%`}
                    </h4>
                  </Grid>
                  <Grid item xs={5}>
                    <h4
                      className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                      style={{
                        fontSize: '12px',
                        margin: 'auto 3px',
                        textAlign: 'left',
                      }}
                    >
                      UV
                      {' '}
                    </h4>
                    <h4
                      className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                      style={{
                        margin: 'auto 3px',
                        textAlign: 'left',
                        fontWeight: '500',
                        fontSize: '17px',
                      }}
                    >
                      {`${number(item.uvIndex, 1, 1)}%`}
                    </h4>
                  </Grid>
                  <Grid item xs={5}>
                    <h4
                      className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                      style={{
                        fontSize: '12px',
                        margin: 'auto 3px',
                        textAlign: 'left',
                      }}
                    >
                      Vento
                      {' '}
                      <Tooltip title={`${item.windDirection}°`}>
                        <NavigationIcon
                          style={{
                            width: 'auto',
                            height: '12px',
                            transform: `rotate(${item.windDirection}deg)`,
                          }}
                        />
                      </Tooltip>
                    </h4>
                    <h4
                      className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                      style={{
                        margin: 'auto 3px',
                        textAlign: 'left',
                        fontWeight: '500',
                        fontSize: '17px',
                      }}
                    >
                      {`${number(item.windSpeed, 1, 1)}km/h`}
                    </h4>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardText>
        </Card>
      </Grid>
    </>
  );
}
