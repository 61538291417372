import React from 'react';
import { useFormik } from 'formik';
import { FiAlertCircle } from 'react-icons/all';
import { ChromePicker } from 'react-color';
import { useAuth } from '../../../../../../hooks/account';
import FockinkAPI from '../../../../../../middlewares/Api';
import { ButtonComponent as Button } from '../../../../../../components/Button';
import { CardOption } from '../../../../../../components/CardOption';

import * as S from './styles';
import { Input } from '../../../../../../components/Input';

export function NewCultureForm({ handleGetCultures }) {
  const { user } = useAuth();

  const [selectedColor, setSelectedColor] = React.useState('');
  const [hasError, setHasError] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const validate = (values) => {
    const errors = {};
    if (!values.culture) {
      errors.culture = 'Defina um nome de identificação para cultura';
    } else if (values.culture.length < 2) {
      errors.culture = 'O nome de identificação deve ter ao menos 2 caracteres';
    }

    if (!values.color) {
      errors.color = 'Necessário definir uma cor para cultura';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      culture: '',
      color: '',
      status: 'A',
    },
    validate,
    onSubmit: async (values) => {
      try {
        const data = {
          culture: values.culture,
          color: values.color,
          accountId: user.accountId,
          status: values.status,
        };
        await FockinkAPI.post('/cultivationAccount', data);
        handleGetCultures();
        setSuccess(true);
        formik.resetForm();
        setTimeout(() => {
          setSuccess(false);
        }, 4000);
      } catch (error) {
        setHasError(true);

        setTimeout(() => {
          setHasError(false);
        }, 2000);
      }
    },
  });

  const handleChangeComplete = (color) => {
    setSelectedColor(color.hex);
    formik.setValues({ ...formik.values, color: color.hex });
  };

  const handleChangeStatus = () => {
    const { status } = formik.values;
    const newStatusValue = status === 'A' ? 'I' : 'A';
    formik.setValues({ ...formik.values, status: newStatusValue });
  };

  return (
    <S.Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <span>Preencha o formulário para cadastro de nova cultura</span>

        <Input
          error={formik.errors.culture}
          name="culture"
          label="Defina um identificador para cultura"
          onChange={formik.handleChange}
          value={formik.values.culture}
        />

        <CardOption
          checked={formik.values.status === 'A'}
          onClick={handleChangeStatus}
        >
          Status -
          {' '}
          {formik.values.status === 'A' ? 'Ativo' : 'Inativo'}
        </CardOption>

        {formik.errors.color ? (
          <S.ErrorText>
            <FiAlertCircle />
            {' '}
            {formik.errors.color}
          </S.ErrorText>
        ) : null}

        <div className="color-picker">
          <ChromePicker
            onChangeComplete={handleChangeComplete}
            color={selectedColor}
          />
        </div>

        <div className="confirm-button">
          {success ? (
            <strong className="success">Sucesso!</strong>
          ) : hasError ? (
            <strong className="error">Erro ao cadastrar nova configuração</strong>
          ) : (
            <Button type="submit">cadastrar</Button>
          )}
        </div>

      </form>
    </S.Wrapper>
  );
}
