import { useFormik } from 'formik';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import { Checkbox, FormControlLabel } from '@material-ui/core/';
import FockinkAPI from '../../../../middlewares/Api';
import { Input, ButtonComponent as Button } from '../../../../components';

export const ModalSubEquip = ({ handleSubmit, closeModal, stateSub }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      tag: stateSub.tag,
      equipamentId: stateSub.equipamentId,
      area: stateSub?.area,
      jobDescription: stateSub?.jobDescription,
      lat: stateSub?.lat,
      lon: stateSub?.lon,
      maintenance: stateSub?.maintenance,
      maintenanceTriggerHours: stateSub?.maintenanceTriggerHours,
    },
    onSubmit: async (values) => {
      let formatKeys;
      if (stateSub.id) {
        const response = await FockinkAPI.put(
          `/subEquipament/${stateSub.id}`,
          values,
        );
        formatKeys = response.data.data;
      } else {
        const response = await FockinkAPI.post('/subEquipament', values);
        formatKeys = response.data.data;
      }

      if (formatKeys) {
        handleSubmit(formatKeys);
      }
      closeModal();
    },
  });

  return (
    <div style={{ width: '100%', minWidth: '200px', maxWidth: '450px' }}>
      <form action="" autoComplete="off" onSubmit={formik.handleSubmit}>
        {/*
          maintenance: state?.maintenance,
          maintenanceTriggerHours: state?.maintenanceTriggerHours,
        */}

        <Input
          id="area"
          type="text"
          name="area"
          label="Área"
          onChange={formik.handleChange}
          value={formik.values.area}
        />

        <Input
          autoComplete="off"
          type="text"
          name="jobDescription"
          label="Descrição"
          onChange={formik.handleChange}
          value={formik.values.jobDescription}
        />

        <NumberFormat
          value={formik.values.lat || ''}
          label="Latitude"
          customInput={TextField}
          variant="outlined"
          thousandSeparator=""
          decimalSeparator="."
          onValueChange={(v) =>
            formik.handleChange({
              target: { name: 'lat', value: v.floatValue },
            })
          }
          style={{ margin: '3px' }}
        />

        <NumberFormat
          value={formik.values.lon}
          label="Longitude"
          customInput={TextField}
          variant="outlined"
          thousandSeparator=""
          decimalSeparator="."
          onValueChange={(v) =>
            formik.handleChange({
              target: { name: 'lon', value: v.floatValue },
            })
          }
          style={{ margin: '3px' }}
        />

        <FormControlLabel
          control={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Checkbox
              checked={formik.values.maintenance}
              value={formik.values.maintenance}
              onChange={formik.handleChange}
              name="maintenance"
            />
          }
          label="Gatilho de Manutenção"
        />

        {formik.values.maintenance && (
          <NumberFormat
            value={formik.values.maintenanceTriggerHours}
            label="Gatilho de Manutenção (em horas)"
            customInput={TextField}
            variant="outlined"
            thousandSeparator=""
            decimalSeparator="."
            onValueChange={(v) =>
              formik.handleChange({
                target: {
                  name: 'maintenanceTriggerHours',
                  value: v.floatValue,
                },
              })
            }
            style={{ margin: '3px' }}
          />
        )}

        <Button
          type="submit"
          style={{ width: '100%', margin: '0.3rem 0.2rem' }}
        >
          Aplicar
        </Button>
      </form>
    </div>
  );
};
