import React from 'react';
import '../../../../../../App.css';
import { Redirect } from 'react-router-dom';
import DataTable, { createTheme } from 'react-data-table-component';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import FockinkAPI from '../../../../../../middlewares/Api';
import AdminPermissions from '../../../../../../helpers/enums/AdminPermissions';
import { hasPermission } from '../../../../../../helpers/permission';
import DeleteTrashButton from '../../../../../../components/Buttons/DeleteTrashButton';

createTheme('equipTable', {
  background: {
    default: '#EAEBEB',
  },
});

export default (props) => {
  const [inputs, setInputs] = React.useState([]);
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const ordData = JSON.parse(localStorage.getItem('ordEquipsOrganization'));
  const pageData = JSON.parse(localStorage.getItem('pageEquipsOrganization'));
  const rowsData = JSON.parse(localStorage.getItem('rowsEquipsOrganization'));

  // methods
  const setPage = (pageVal) => {
    const dataSort = {
      page: pageVal,
    };
    localStorage.setItem('pageEquipsOrganization', JSON.stringify(dataSort));
  };
  const setOrdenation = (ordenationVal, directionVal) => {
    const dataSort = {
      ordenation: ordenationVal,
      direction: directionVal,
    };
    localStorage.setItem('ordEquipsOrganization', JSON.stringify(dataSort));
  };
  const setRowsPage = (rowsVal) => {
    const dataSort = {
      rows: rowsVal,
    };
    localStorage.setItem('rowsEquipsOrganization', JSON.stringify(dataSort));
  };

  const dict = {
    1: 'Irrigação',
    2: 'Termometria',
    3: 'Automação Industrial',
    4: 'Fotovoltaica',
    5: 'Estação Meteorológica',
  };

  const details = {
    1: 'irrigationDash',
    2: 'thermometryDash',
    3: 'automationDash',
    4: 'photovoltaicDash',
  };

  React.useEffect(() => {
    setLoading(true);
    FockinkAPI.get('/equipaments', { params: { plantId: props.plantId } }).then(
      (response) => {
        setInputs(response.data.data);
        setLoading(false);
      },
    );
  }, []);

  const expandableRowExpanded = (data) => props.search.equipmentId === data.id;

  const onRowExpandToggled = (open, data) => {
    if (open) {
      props.setSearch({
        accountId: props.accountId,
        plantId: props.plantId,
        equipmentId: data.id,
      });
    }
    return true;
  };

  React.useEffect(() => {
    const columns = [
      {
        name: 'Identificação Fockink',
        selector: 'description',
        sortable: true,
      },
      {
        name: 'Nome',
        selector: 'clientDescription',
        sortable: true,
      },
      {
        name: 'Sistema',
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {dict[row.operationId]}
          </div>
        ),
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true,
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {row.status === 'A' ? 'Ativo' : 'Inativo'}
          </div>
        ),
      },
      {
        name: 'Ações',
        center: true,
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {hasPermission(AdminPermissions.EQUIPAMENTS_VIEW.value) && (
              <>
                <Tooltip title="Ver Equipamento">
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      setRedirect(
                        `/organization/${props.organizationId}/account/${props.accountId}/plant/${props.plantId}/equip/${row.id}/view?op=${props.operationId}`,
                      )
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Ir Para Equipamento">
                  <DashboardIcon
                    color="secondary"
                    onClick={() =>
                      setRedirect(`/${details[row.operationId]}/${row.id}`)
                    }
                  >
                    <VisibilityIcon />
                  </DashboardIcon>
                </Tooltip>
              </>
            )}
            {hasPermission(AdminPermissions.EQUIPAMENTS_MANAGE.value) && (
              <>
                <Tooltip title="Editar Equipamento">
                  <IconButton
                    color="secondary"
                    onClick={() =>
                      setRedirect(
                        `/organization/${props.organizationId}/account/${props.accountId}/plant/${props.plantId}/equip/${row.id}?op=${props.operationId}`,
                      )
                    }
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Apagar Equipamento">
                  <DeleteTrashButton url={`/equipaments/${row.id}`} />
                </Tooltip>
              </>
            )}
          </div>
        ),
      },
    ];
    setDtCl({ columns, data: inputs });
  }, [inputs]);

  if (redirect) return <Redirect push to={redirect} />;

  if (loading) {
    return (
      <Skeleton variant="rect" height={500} width="100%" animation="wave" />
    );
  }

  return (
    <DataTable
      theme="equipTable"
      pagination
      paginationComponentOptions={{
        rowsPerPageText: 'Linhas:',
        rangeSeparatorText: 'de',
      }}
      onChangePage={(e) => setPage(e)}
      onSort={(e, x) => setOrdenation(e.selector, x)}
      onChangeRowsPerPage={(e) => setRowsPage(e)}
      paginationDefaultPage={parseInt(pageData?.page || 1, 10)}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
      noDataComponent="Não existem dados para serem mostrados"
      columns={dtCl.columns}
      data={dtCl.data}
      expandableRowExpanded={expandableRowExpanded}
      onRowExpandToggled={onRowExpandToggled}
      title="Equipamentos"
      defaultSortField={ordData?.ordenation}
      defaultSortAsc={ordData?.direction !== 'desc'}
      paginationPerPage={rowsData?.rows || 5}
    />
  );
};
