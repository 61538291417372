import React from 'react';
import colors from '../../../helpers/colors';

function resizingTextCable(lengthCableNumber, totalArcs) {
  let result = '';
  if (lengthCableNumber < 4) {
    result = totalArcs < 6 ? '12' : totalArcs < 7 ? '10' : '9';
  } else if ((lengthCableNumber > 4) || (lengthCableNumber === 4)) {
    result = '8';
  }
  return result;
}

function positionTextCable(lengthCableNumber) {
  let result = '';
  switch (lengthCableNumber) {
    case 1:
      result = '11';
      break;
    case 2:
      result = '6';
      break;
    case 3:
      result = '2';
      break;
    default:
      result = '1';
  }
  return result;
}

export default function CableSVAuto({
  cable,
  tempColor,
  totalArcs,
  diameter,
  handleClick,
  selectedSection,
}) {
  const radius = (diameter / 2);

  function colorSelectedSection() {
    let color = '';
    if (selectedSection !== undefined) {
      selectedSection.forEach((item) => {
        if (item === cable) {
          color = colors.cable;
        }
      });
    }

    if (color === '') {
      color = colors.silo;
    }

    return color;
  }

  return (
    <svg width={diameter + 4} height={diameter + 4} onClick={() => handleClick(cable)}>
      <circle
        cx={radius + 2}
        cy={radius + 2}
        r={radius}
        stroke={colorSelectedSection()}
        strokeWidth="3"
        fill={tempColor}
      />
      Sorry, your browser does not support inline SVG.
      <text
        fill="#000000"
        fontSize={resizingTextCable(cable.toString().length, totalArcs)}
        fontFamily="Roboto"
        fontWeight="450"
        x={positionTextCable(cable.toString().length)}
        y={totalArcs < 6 ? '18' : totalArcs < 7 ? '14' : '12'}
      >
        {cable}
      </text>
    </svg>
  );
}
