/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Skeleton from '@material-ui/lab/Skeleton';
import EquipError from '../../../../components/EquipError';
import WeatherCard from './Components/WeatherCard';
import ProductionGraph from './Graphs/ProductionGraph';
import CreditGraph from './Graphs/CreditGraph';
import PhotovoltaicStatusGraph from './Graphs/PhotovoltaicStatusGraph';
import PhotovoltaicProduction from './PhotovoltaicProduction';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import DashesHeader from '../../../../components/DashesHeader';
import FockinkAPI from '../../../../middlewares/Api';
import BackButton from '../../../../components/Buttons/BackButton';
import { DashTitle } from '../DashesStyles/index';
import AuroraVisionAlert from './Components/AuroraVisionAlert/AuroraVisionAlert';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  spacing: {
    margin: '12px 0',
  },
  recipe: {
    padding: '25px',
  },
  eachStatus: {
    padding: '15px',
    marginTop: '5px',
    fontSize: '1.2em',
  },
  loadCircle: {
    width: '150px !important',
    height: '150px !important',
  },
  title: {
    textAlign: 'center',
    width: '100%',
  },
  squareOff: {
    color: 'gray',
  },
  squareGreen: {
    color: 'green',
  },
  squareRed: {
    color: 'red',
  },
  locationLine: {
    color: '#9a9a9a',
    textAlign: 'right',
    padding: '0 24px',
    fontSize: '14px',
    margin: '8px 0 10px',
    fontWeight: '400',
  },
  bottomButtons: {
    margin: '15px 0',
    textAlign: 'right',
    paddingTop: '10px !important',
  },
});

export default (props) => {
  const [loading, setLoading] = React.useState(true);
  const [returnToList, setReturnToList] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const [data, setData] = React.useState({ operationId: 'loading' });
  const [weather, setWeather] = React.useState(undefined);

  // const [search, setSearch] = React.useState({});

  const [breadcrumbs, setBreadcrumbs] = React.useState({ routes: [] });
  const [tab, setTab] = React.useState(0);

  const changeTab = (event, newValue) => {
    setTab(newValue);
  };
  const classes = useStyles();

  const callApiStatus = () => {
    setLoading(true);
    FockinkAPI.get(`/dashboards/photovoltaic/${props.match.params.id}/status`)
      .then((response) => {
        setData(response.data.data);
        setBreadcrumbs({
          routes: [
            { name: 'Equipamentos', to: '/equipamentData' },
            {
              name:
                response.data.data.clientDescription ||
                response.data.data.description,
            },
          ],
          secondRoute: [
            { name: 'Clientes', to: '/clientsAdm/' },
            {
              name: 'Equipamentos',
              to: `/equipamentDataAdm?accountId=${response.data.data.account.id}&op=all`,
            },
            {
              name:
                response.data.data.clientDescription ||
                response.data.data.description,
            },
          ],
        });
        setLoading(false);
      })
      .catch((e) => {
        console.error('e', e);
        if (e.response?.status === 503) {
          setErrorMessage('Equipamento sem conexão para trazer os dados');
        } else {
          setErrorMessage(`${e.response.data.message} (${e.response.status})`);
        }
        // setLoading(false);
      });
  };

  const callApiWeather = () => {
    setLoading(true);
    FockinkAPI.get(
      `/dashboards/photovoltaic/${props.match.params.id}/weatherStation`,
    ).then((response) => {
      setWeather(response.data.data.weatherStation);
      setLoading(false);
    });
  };
  const urlParams = new URLSearchParams(window.location.search);
  const whatRoute = urlParams.get('whatRoute');
  React.useEffect(() => {
    callApiStatus();
    const intervalStatus = setInterval(() => {
      callApiStatus();
    }, 900000);

    callApiWeather();
    const intervalWeather = setInterval(() => {
      callApiWeather();
    }, 3600000); // 1h

    return () => {
      clearInterval(intervalStatus);
      clearInterval(intervalWeather);
    };
  }, [props.match.params.id]);
  const getTab = () => {
    if (tab === 0) {
      return <ProductionGraph equipamentid={props.match.params.id} />;
    }
    if (tab === 1) {
      return <CreditGraph equipamentid={props.match.params.id} />;
    }
    // } if (tab === 2) {
    //   return <PhotovoltaicGraph equipamentid={props.match.params.id} />;
    // }
    return <h2>Aba não conhecida</h2>;
  };
  if (errorMessage) return <EquipError errorMessage={errorMessage} />;
  if (returnToList) {
    return (
      <Redirect
        push
        to={
          whatRoute === 'Equips'
            ? `/equipamentDataAdm?accountId=${data.account?.id}&op=all`
            : '/equipamentData'
        }
      />
    );
  }
  if (loading) {
    return (
      <>
        <Breadcrumbs loading={loading} routes={breadcrumbs.routes} />
        <Skeleton variant="rect" height={420} width="100%" animation="wave" />
      </>
    );
  }

  return (
    <>
      <Breadcrumbs
        loading={loading}
        routes={
          whatRoute === 'Equips' ? breadcrumbs.secondRoute : breadcrumbs.routes
        }
      />
      <DashTitle>{data.account?.description}</DashTitle>
      <DashesHeader
        loading={loading}
        title={data.clientDescription || data.description}
        type="Usina"
        /* noSettings={hasPermission(
          PortalPermissions.PHOTOVOLTAIC_SETTINGS_VIEW.value,
        )} */
        hasCustomRoute={whatRoute === 'Equips' ? `?whatRoute=${whatRoute}` : ''}
        connection={data.connection}
        running={data.running}
        alert={data.failures}
      />

      {data?.settings?.location?.address1 ? (
        <h3 className={classes.locationLine}>
          {`${data.settings.location.address1} ${data.settings.location.address2}, ${data.settings.location.city} - ${data.settings.location.state}, ${data.settings.location.postalCode} - ${data.settings.location.country}`}
        </h3>
      ) : (
        <></>
      )}

      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        direction="row"
        alignItems="stretch"
      >
        <Grid item xs={12} md={12} lg={3}>
          <PhotovoltaicStatusGraph
            auroraVisionComponent={<AuroraVisionAlert data={data} />}
            loading={loading}
            currentPower={data.currentPower}
            capacity={data.settings ? data.settings.capacity || 0 : 0}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <PhotovoltaicProduction equipamentid={props.match.params.id} />
        </Grid>

        <Grid item xs={12} md={12} lg={3}>
          <WeatherCard weatherData={weather} />
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Paper square>
            <Tabs
              value={tab}
              onChange={changeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Performance" />
              <Tab label="Créditos" />
              {/* <Tab label="Alarmes" /> */}
            </Tabs>
          </Paper>

          <Paper style={{ padding: '15px', borderRadius: '0' }}>
            {getTab()}
          </Paper>
        </Grid>

        <Grid item xs={12} className={classes.bottomButtons}>
          <BackButton
            back={() => setReturnToList(true)}
            className={classes.btnSpace}
          />
        </Grid>
      </Grid>
    </>
  );
};
