import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 3rem;
  width: 180px;

  margin-top: 0.4rem;
  padding: 0 8px;
  box-shadow: 5px 5px 22px -2px rgba(0,0,0,0.1);
  border: 1px solid #cecece;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.4rem;

  span {
    margin-left: 0.5rem;
  }

  @media (min-width: 720px) {
    max-width: 250px;
  }
`;
