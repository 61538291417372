import React from 'react';
import DataTable from 'react-data-table-component';
import { columns } from './makeCultureColumns';

export function CultureTable({ data, editButtonModal }) {
  const formattedColumns = [...columns, editButtonModal];

  return (
    <DataTable
      pagination
      paginationPerPage={5}
      paginationComponentOptions={{ rowsPerPageText: 'Linhas:', rangeSeparatorText: 'de' }}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
      noDataComponent="Não existem dados para serem mostrados"
      columns={formattedColumns}
      data={data}
    />
  );
}
