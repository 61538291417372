export default function verifyTemperature(t) {
  let tChecked;

  switch (t) {
    case 997:
      tChecked = 'ERR';
      break;
    case 998:
      tChecked = 'N/D';
      break;
    case 999:
      tChecked = 'OFF';
      break;
    default:
      tChecked = t;
      break;
  }

  return tChecked;
}
