import * as yup from 'yup';
import { transformDate } from '../transformDate';

export const validationSchema = yup.object().shape({
  cultivationAccountId: yup.string().required('Obrigatório'),
  plantingDate: yup
    .date()
    .required('Dado Obrigatório')
    .transform(transformDate)
    .max(new Date(), 'Data selecionada não é permitida'),
  angleFrom: yup.number('Deve ser um número').required('Obrigatório'),
  angleTo: yup.number('Deve ser um número').required('Obrigatório'),
});
