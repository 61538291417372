import styled from 'styled-components';

export const Wrapper = styled.div`
 width: 100%;
   display: flex;
   flex-direction: column;
   margin-bottom: 1.3rem;

   strong {
     font-size: 1.5rem;
     font-weight: 500;
   }

   span {
     font-size: 1.2rem;
     color: rgb(151, 151, 151)
   }
`;
