import React from 'react';
import { Button } from '@material-ui/core';

export function ButtonComponent({
  handle,
  children,
  isDisabled,
  type,
  icon,
  color,
  style,
  size,
  endIcon,
}) {
  return (
    <Button
      style={{ marginBottom: '0.5rem', ...style }}
      color={color || 'primary'}
      type={type || 'submit'}
      variant="contained"
      onClick={handle}
      disabled={isDisabled}
      startIcon={icon}
      size={size || 'medium'}
      endIcon={endIcon}
    >
      {children}
    </Button>
  );
}
