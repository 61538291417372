import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { Ouroboro } from 'react-spinners-css';
import { IoSearchCircle } from 'react-icons/io5';
import { Tabs } from 'antd';
import { TableAeration } from './components/TableAeration';
import FockinkAPI from '../../../../middlewares/Api';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { HeaderPainel } from './components/HeaderPainel';
import { SumHeader } from './components/SumHeader';
import BackButton from '../../../../components/Buttons/BackButton';
import BuildExcel from './components/BuildExcel';
import { groupData } from './groupData';

const useStyles = makeStyles({
  root: {
    minHeight: ' calc(100vh - 145px)',
  },
  content: {
    minHeight: '62vh',
    marginBottom: '1rem',
  },
  loading: {
    minHeight: '500px',
    display: 'flex',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonBack: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export function AerationReport() {
  const classes = useStyles();
  const history = useHistory();
  const [breadcrumbs, setBreadcrumbs] = React.useState({ routes: [] });
  // const urlParams = window.location.search;

  const [data, setData] = useState();
  const [selectedPlant, setSelectedPlant] = useState({ plant: '', equipaments: [] });
  const [selectedEquipaments, setSelectedEquipaments] = useState([]);
  const [selectedGrain, setSelectedGrain] = useState({});
  const [groupedData, setGroupedData] = useState({});
  const [date, setDate] = useState();
  const [isLoading, setIsloading] = useState(false);
  const { TabPane } = Tabs;
  const urlParams = new URLSearchParams(window.location.search);
  const url = urlParams.get('accountId');

  const breadcrumb = {
    title: 'Relatório de Aeração',
    routes: [{ name: 'Relatórios', to: '/reports' }, { name: 'Relatório de Aeração' }],
  };

  const handleSelectPlant = (plant) => {
    setSelectedPlant(plant);
    setSelectedEquipaments(['Todos']);
    setData(undefined);
  };

  const handleChangeGrain = (grain, eqpId) => {
    setSelectedGrain({
      ...selectedGrain,
      [eqpId]: grain,
    });
  };

  const handleSelectEquipaments = (event) => {
    if (event.target.value[event.target.value.length - 1] === 'Todos') {
      setSelectedEquipaments(['Todos']);
    } else {
      setSelectedEquipaments(event.target.value.filter((f) => f !== 'Todos'));
    }
    setData(undefined);
  };

  const handleDateChange = (newDate) => {
    setDate(newDate.target.value);
    setData(undefined);
  };

  const equipName = (id) => {
    const eqp = selectedPlant.equipaments.find((f) => f.id === id);
    return eqp?.clientDescription || '-';
  };

  React.useEffect(() => {
    setBreadcrumbs({
      title: 'Relatório de Aeração',
      routes: [{ name: 'Relatórios', to: '/reports' }, { name: 'Relatório de Aeração' }],
      secondRoute: [
        { name: 'Clientes', to: '/clientsAdm/' },
        { name: 'Relatórios', to: `/reportsAdm?accountId=${url}&op=all` },
        { name: 'Relatório de Aeração' },
      ] });
    if (data?.reportData) {
      const rdKeys = Object.keys(data.reportData);
      const ret = {};

      rdKeys.forEach((k) => {
        ret[k] = groupData(data.reportData[k]);
      });
      setGroupedData(ret);
    }
  }, [data?.reportData]);

  const handleRequestAerationReport = useCallback(async () => {
    try {
      if (!selectedPlant) {
        throw new Error();
      }
      setData();
      setIsloading(true);

      const params = {
        year: date || new Date().getFullYear(),
        equipaments: selectedEquipaments.indexOf('Todos') === -1
          ? selectedEquipaments
          : selectedPlant.equipaments.map((m) => m.id),
      };

      const response = await FockinkAPI.get(`reports/thermometry/aerationControl?accountId=${url}`, {
        params,
      });

      setIsloading(false);
      setData(response.data.data);
    } catch (error) {
      setIsloading(false);
      // console.log(error);
    }
  }, [selectedPlant, selectedEquipaments, date]);

  return (
    <div className={classes.root}>
      <Breadcrumbs routes={localStorage.profile === 'admin' && urlParams ? breadcrumbs.secondRoute : breadcrumbs.routes} title={breadcrumbs.title} />
      <Grid container className={classes.content} direction="column" spacing={2}>
        <HeaderPainel
          selectedPlant={selectedPlant}
          handleSelectPlant={handleSelectPlant}
          handleSelectEquipaments={handleSelectEquipaments}
          handleDate={handleDateChange}
          date={date}
          selectedEquipaments={selectedEquipaments}
          // selectedSubs={selectedSubs}
          // handleSelectSubs={handleSelectSubs}
          handleRequestAerationReport={handleRequestAerationReport}
        />

        {isLoading && (
          <div className={classes.loading}>
            <Ouroboro color="#3D5059" />
          </div>
        )}

        {data ? (
          <Paper style={{ width: '100%', padding: 10, marginTop: 10 }}>
            <Grid item lg={12} xs={12} style={{ marginLeft: 'auto', marginRight: 0 }}>
              <BuildExcel
                reportHeader={data.reportHeader}
                reportGrains={data.reportGrains}
                reportMotors={data.reportMotors}
                reportType={data.reportType}
                accountData={data.accountData}
                selectedGrain={selectedGrain}
                equipName={equipName}
                reportData={data.reportData}
                selectedDate={date}
                groupedData={groupedData}
              />
            </Grid>
            <Grid item lg={12} xs={12} style={{ marginLeft: 'auto', marginRight: 0 }}>
              <Tabs defaultActiveKey={Object.keys(data.reportData)[0]}>
                {Object.keys(data.reportData).map((k) => (
                  <TabPane tab={equipName(k)} key={k}>
                    <Grid item xs={12}>
                      <SumHeader
                        reportHeader={data.reportHeader[k]}
                        reportGrains={data.reportGrains[k]}
                        reportMotors={data.reportMotors[k]}
                        reportType={data.reportType[k]}
                        selectedGrain={selectedGrain[k]}
                        equipamentId={k}
                        setSelectedGrain={handleChangeGrain}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TableAeration
                        reportMotors={data.reportMotors[k]}
                        reportGrains={data.reportGrains[k]}
                        groupedData={groupedData[k]}
                        selectedGrain={selectedGrain[k]}
                      />
                    </Grid>
                  </TabPane>
                ))}

              </Tabs>
            </Grid>
          </Paper>
        ) : (
          <>
            <Grid item style={{ justifyContent: 'center', textAlign: 'center', marginTop: 20 }}>
              <IoSearchCircle size="35" />
            </Grid>
            <Grid item>
              {' '}
              <h5 style={{ justifyContent: 'center', textAlign: 'center' }}>
                Selecione uma planta e um equipamento.
              </h5>
            </Grid>
          </>
        )}
      </Grid>

      <div className={classes.buttonBack}>
        <BackButton back={() => history.goBack()} className={classes.buttonBack} />
      </div>
    </div>
  );
}
