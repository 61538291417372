import React from 'react';
import { useDropzone } from 'react-dropzone';
import { ImFolderUpload } from 'react-icons/im';
import { FileCard } from './components/FileCard';
import * as S from './style';

export function DropZoneContainer({
  files,
  onDrop,
  alternativeLabel,
  confirmUpload,
  noFilename,
  multiple,
}) {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple,
  });

  return (
    <S.Wrapper>
      <S.DropZoneContent {...getRootProps()}>
        <input {...getInputProps()} />
        <div className="alternative-indo">
          <ImFolderUpload size={52} color="#9d9d9d" />
          <p>{alternativeLabel}</p>
          {files && <span>{`${files.length} arquivos selecionados`}</span>}
        </div>
      </S.DropZoneContent>

      <S.ContentFile>
        {files.map((file) => (
          <FileCard
            key={file.filename}
            file={file}
            noFilename={noFilename}
            confirmUpload={confirmUpload}
          />
        ))}
      </S.ContentFile>
    </S.Wrapper>
  );
}
