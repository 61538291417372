import React, { useEffect, useState } from 'react';
import { Modal } from '@material-ui/core';
import { Spinner } from 'react-spinners-css';
import CloseIcon from '@material-ui/icons/Close';
import * as S from './style';
import { useThermometryReport } from '../../../../../../hooks/thermometryReport';
import { formatDateToRequest } from '../../../../../../helpers/formatDateToRequest';
import { TableReport } from '../TableReport';

export function EquiapmentsModal({
  isOpen,
  onClose,
  grains,
  plant,
  selectedDate,
  labelDays }) {
  const [monitoringEquipaments, setMonitoringEquipaments] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [equipamentReportData, setEquipamentReportsData] = useState([]);
  const { getMonitoringReportDrillDown } = useThermometryReport();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const dateFormattedToYearAndMonth = formatDateToRequest(selectedDate);
      const ResponseEquipamments = await getMonitoringReportDrillDown(
        dateFormattedToYearAndMonth,
        grains,
        plant,
      );
      const { reportData } = ResponseEquipamments.data;
      if (reportData[0]) {
        const { reports } = reportData[0];
        setEquipamentReportsData(reports);
        setMonitoringEquipaments(reportData[0]);
        setIsLoading(false);
      }

      setIsLoading(false);
    })();
  }, [plant]);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={isOpen}
      onClose={onClose}
    >
      <S.Wrapper>
        <div className="close-btn">
          <button type="button" onClick={() => onClose()}>
            <CloseIcon />
          </button>
        </div>

        {isLoading ? (
          <Spinner
            color="#3D5059"
          />
        ) : (
          equipamentReportData.map((equipamentReport) => (
            <TableReport
              subtitle={equipamentReport.grains}
              reportData={equipamentReport}
              labelDays={labelDays}
              plant={monitoringEquipaments.plant}
              selectedDate={selectedDate}
            />
          ))
        )}

        {!isLoading && equipamentReportData.length === 0 && (
          <span>Sem dados para exibição</span>
        )}
      </S.Wrapper>
    </Modal>
  );
}
