import React from 'react';
import { BsPlus, BsCloudUpload, BsFilter } from 'react-icons/bs';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { FiPlus } from 'react-icons/fi';
import { Tooltip } from '@material-ui/core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { toast } from 'react-toastify';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import { ButtonComponent as Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal';
import {
  TelemetryTable,
  NewTelemetrySettingForm,
  UpdateTelemetrySettingForm,
  TelemetryFilters,
  UploadTelemetryVariables,
} from './components';
import FockinkAPI from '../../../../middlewares/Api';

import * as S from './styles';
import { handleMessageError } from '../../../../helpers/handlerMessageError';

export function TelemetrySettings() {
  const [telemetry, setTelemetry] = React.useState([]);
  const [selectedRowToUpdate, setSelectedRowtToUpdate] = React.useState();
  const [
    openNewThermometrySettingsModal,
    setOpenNewThermometrySettingsModal,
  ] = React.useState(false);

  const [
    openUpdateThermometrySettingsModal,
    setOpenUpdateThermometrySettingsModal,
  ] = React.useState(false);

  const [openUploadModal, setOpenUploadModal] = React.useState(false);
  const [openFilterModal, setOpenFilterModal] = React.useState(false);

  const breadcrumb = {
    title: 'Configuração de Telemetria',
    routes: [
      { name: 'Configurações', to: '/settings' },
      { name: 'Configuração de Telemetria' },
    ],
  };

  const MySwal = withReactContent(Swal);

  const handleGetTelemetry = async (paramsData) => {
    const response = await FockinkAPI.get('/telemetryVariables/', {
      params: paramsData,
    });
    setTelemetry(response.data.data);
  };

  React.useEffect(() => {
    (async () => {
      await handleGetTelemetry();
    })();
  }, []);

  const handleIOpenFilterModal = () => setOpenFilterModal(true);
  const handleICloseFilterModal = () => setOpenFilterModal(false);

  const handleCloseNewTelemetryModal = () => {
    setOpenNewThermometrySettingsModal(false);
  };

  const handleCloseUpdateTelemetryModal = () => {
    setOpenUpdateThermometrySettingsModal(false);
  };

  const handleUpdateTelemetryModal = (row) => {
    setOpenUpdateThermometrySettingsModal(true);
    setSelectedRowtToUpdate(row);
  };

  const handleDeleteVariable = async (values) => {
    try {
      values.status = 'I';
      const copyValuesData = { ...values };
      delete copyValuesData.id;

      MySwal.fire({
        title: `Deseja inativar variável ${values.friendlyName}? `,
        text:
          'A variável inativada ficará disponível na listagem de variáveis inativas',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Inativar Variável',
        cancelButtonText: 'Cancelar Ação',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await FockinkAPI.put(
            `/telemetryVariables/${values.id}`,
            copyValuesData,
          );
          toast.success('Variável excluída');
          handleGetTelemetry();
        }
      });
    } catch (error) {
      handleMessageError(error);
    }
  };

  const handleActiveVariable = async (values) => {
    try {
      values.status = 'A';
      const copyValuesData = { ...values };
      delete copyValuesData.id;

      MySwal.fire({
        title: `Deseja ativar variável ${values.friendlyName}? `,
        text: 'A variável ativada ficará disponível na listagem padrão',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'ativar Variável',
        cancelButtonText: 'Cancelar Ação',
      }).then(async (result) => {
        if (result.isConfirmed) {
          await FockinkAPI.put(
            `/telemetryVariables/${values.id}`,
            copyValuesData,
          );
          toast.success('Variável reativada');
          handleGetTelemetry();
        }
      });
    } catch (error) {
      handleMessageError(error);
    }
  };

  const aditionalButtons = [
    {
      selector: 'action',
      center: true,
      cell: (row) => (
        <>
          {row.status === 'A' && (
            <Tooltip title="Editar">
              <button
                type="button"
                style={{ color: '#2F80ED' }}
                onClick={() => handleUpdateTelemetryModal(row)}
              >
                <EditIcon size={18} />
              </button>
            </Tooltip>
          )}
          <Tooltip title={row.status === 'A' ? 'Inativar' : 'Ativar'}>
            {row.status === 'A' ? (
              <button
                type="button"
                style={{ color: '#2F80ED' }}
                onClick={() => handleDeleteVariable(row)}
              >
                <DeleteIcon size={18} />
              </button>
            ) : (
              <button
                type="button"
                style={{ color: '#2F80ED' }}
                onClick={() => handleActiveVariable(row)}
              >
                <FiPlus />
              </button>
            )}
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <S.Wrapper>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />

      <div className="button-new">
        <Button
          icon={<BsCloudUpload />}
          style={{ marginRight: '5px' }}
          handle={() => setOpenUploadModal(true)}
        >
          Importar
        </Button>
        <Button
          icon={<BsFilter />}
          style={{ marginRight: '5px' }}
          handle={() => handleIOpenFilterModal(true)}
        >
          Aplicar Filtros
        </Button>
        <Button
          color="secondary"
          icon={<BsPlus />}
          handle={() => setOpenNewThermometrySettingsModal(true)}
        >
          Adicionar nova variável
        </Button>
      </div>

      <Modal
        title="Aplicar filtros"
        open={openFilterModal}
        handleCloseModal={handleICloseFilterModal}
      >
        <TelemetryFilters
          handleSubmit={handleGetTelemetry}
          closeModal={handleICloseFilterModal}
        />
      </Modal>

      <Modal
        title="Upload"
        open={openUploadModal}
        handleCloseModal={() => setOpenUploadModal(false)}
      >
        <UploadTelemetryVariables
          handleGetTelemetry={handleGetTelemetry}
          handleCloseNewTelemetryModal={() => setOpenUploadModal(false)}
        />
      </Modal>

      <Modal
        title="Nova Configuração de Telemetria"
        open={openNewThermometrySettingsModal}
        handleCloseModal={handleCloseNewTelemetryModal}
      >
        <NewTelemetrySettingForm
          handleGetTelemetry={handleGetTelemetry}
          handleCloseNewTelemetryModal={handleCloseNewTelemetryModal}
        />
      </Modal>

      <Modal
        title="Editar Configuração de Telemetria"
        open={openUpdateThermometrySettingsModal}
        handleCloseModal={handleCloseUpdateTelemetryModal}
      >
        <UpdateTelemetrySettingForm
          telemetry={selectedRowToUpdate}
          handleGetTelemetry={handleGetTelemetry}
          handleCloseUpdateTelemetryModal={handleCloseUpdateTelemetryModal}
        />
      </Modal>
      <TelemetryTable data={telemetry} aditionalButtons={aditionalButtons} />
    </S.Wrapper>
  );
}
