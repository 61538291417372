/* eslint-disable no-underscore-dangle */
// Basic
import React from 'react';

// UI, images, styles and icons
import '../../../../../App.css';
import Grid from '@material-ui/core/Grid';
import DataTable from 'react-data-table-component';
import Skeleton from '@material-ui/lab/Skeleton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ErrorIcon from '@material-ui/icons/Error';
import AccessTime from '@material-ui/icons/AccessTime';
// import moment from 'moment';
import FockinkAPI from '../../../../../middlewares/Api';
import { number } from '../../../../../helpers/Intl';
import ExpandedComponent from './IrrigationTabHistoricalExpanded';

export default (props) => {
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [rawData, setRawData] = React.useState(undefined);
  const [loading, setLoading] = React.useState(true);

  const statusData = {
    done: {
      text: 'Finalizado',
      icon: <CheckCircleIcon style={{ color: 'green' }} />,
    },
    sent: {
      text: 'Enviado',
      icon: <HourglassEmptyIcon style={{ color: 'gold' }} />,
    },
    expired: {
      text: 'Expirado',
      icon: <AccessTime style={{ color: 'red' }} />,
    },
    error: {
      text: 'Erro',
      icon: <ErrorIcon style={{ color: 'red' }} />,
    },
  };

  const getDiretction = (recipeDirectionRotation) => {
    if (recipeDirectionRotation === 0) {
      return 'Desligado';
    }
    if (recipeDirectionRotation === 1) {
      return 'Direita';
    }
    if (recipeDirectionRotation === 2) {
      return 'Esquerda';
    }
    return 'NDef';
  };

  const callDataApi = () => {
    setLoading(true);
    FockinkAPI.get(`/equipaments/${props.equipamentId}/logs?operationId=1200`)
      .then((response) => {
        setRawData(response.data.data);
        setLoading(false);
      })
      .catch((e) => {
        setDtCl({ columns: [], data: [] });
        console.error(`${e.response.data.message} (${e.response.status})`);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    callDataApi();
    // const interval = setInterval(() => {
    // callDataApi();
    // }, 60000);
    // return () => clearInterval(interval);
  }, [props.equipamentId]);

  React.useEffect(() => {
    const columns = [
      {
        name: 'Status Comando',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ alignItems: 'center' }}>
            {statusData[row.status].icon}
          </div>
        ),
      },
      {
        name: 'Comando Pivô',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ alignItems: 'center' }}>
            {row.sentData.stOnOff === 0 ? 'Desligado' : 'Ligado'}
          </div>
        ),
      },
      {
        name: 'Água',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ alignItems: 'center' }}>
            {row.sentData.recipeWater === 0 ? 'Sem Água' : 'Com Água'}
          </div>
        ),
      },
      {
        name: 'Lâmina (mm)',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ alignItems: 'center' }}>
            {number(row.sentData.recipeWaterMm / 100, 2, 2)}
          </div>
        ),
      },
      {
        name: 'Sentido de Giro',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ alignItems: 'center' }}>
            {getDiretction(row.sentData.recipeDirectionRotation)}
          </div>
        ),
      },
      {
        name: 'Destino',
        center: true,
        cell: (row) => (
          <div data-tag="allowRowEvents" style={{ alignItems: 'center' }}>
            {row.sentData.recipePosition === 360
              ? 'Sem Destino'
              : `${row.sentData.recipePosition}°`}
          </div>
        ),
      },
      // {
      //   name: 'Enviado em',
      //   center: true,
      //   cell: (row) => (
      //     <div data-tag="allowRowEvents" style={{ alignItems: 'center' }}>
      //       <p>{`${formatDate(moment.unix(row._ts).toDate())}`}</p>
      //     </div>
      //   ),
      // },
      // { name: 'Usuário', selector: 'user.name', center: true },
      // {
      //   name: 'Enviado em',
      //   center: true,
      //   cell: (row) => (
      //     <div data-tag="allowRowEvents" style={{ alignItems: 'center' }}>
      //       <p>{`${formatDate(moment.unix(row._ts).toDate())}`}</p>
      //     </div>
      //   ),
      // },
      // {
      //   name: 'Confirmação em',
      //   center: true,
      //   cell: (row) => (
      //     <div data-tag="allowRowEvents" style={{ alignItems: 'center' }}>
      //       <p>{`${row.completedAt ? formatDate(row.completedAt) : '-'}`}</p>
      //     </div>
      //   ),
      // },
    ];

    setDtCl({ columns, data: rawData });
  }, [rawData]);

  if (loading) {
    return (
      <Skeleton variant="rect" height={300} width="100%" animation="wave" />
    );
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="stretch"
      spacing={1}
    >
      <Grid item xs={12}>
        <DataTable
          noDataComponent="Não existem dados para serem mostrados"
          columns={dtCl.columns}
          data={dtCl.data}
          expandableRows
          expandableRowsComponent={<ExpandedComponent />}
        />
      </Grid>
    </Grid>
  );
};
