// import React from 'react';
import countCablesInArc from '../../../helpers/countCablesInArc';
import findCenterCable from '../../../helpers/findCableCenter';

export default function crossSection(data, disposition) {
  if (data.length > 0) {
    let dividersCount = 0;
    const maximoArco = data[(data.length - 1)].arc;
    const totalcabos = data.length;
    const primeiroCabo = data[0].cable;
    const caboCentral = findCenterCable(data) ? 1 : 0;
    const totalDispositions = disposition.length;

    const corteCabo = [[]];
    const auxCorteCabo = [[]];

    // console.log('cs ->', data);
    // console.log('disposition ->', disposition);

    dividersCount = countCablesInArc(data, 1);

    let qtCorte1;
    let qtCorte2;
    let auxCont;
    let aux1;
    let aux5;
    let aux4;
    let valorAntes1;
    let valorAntes2;
    let diferenca1;
    let diferenca2;
    let auxMenor;
    let auxMaior;
    let totalCabo;
    let auxDiferenca1;
    let auxDiferenca2;
    let auxMaiorAnterior;
    let auxMenorAnterior;
    let auxCaboCentral;
    let auxZ;
    let x;
    let y;
    let auxY;
    let z;

    if ((corteCabo.length === 1) && (corteCabo[0].length === 0)) {
    // Define o tamanho da matriz
      if (maximoArco === 1) {
      // se possuir somente um arco sem o cabo central
        qtCorte1 = 1;
        qtCorte2 = totalcabos;
      } else if (maximoArco !== 1) {
      // Definide quantos posições de Corte para a leitura
        qtCorte1 = dividersCount;

        // Definie quantos cabos terão em cada Corte
        qtCorte2 = ((maximoArco * 2) - (caboCentral)); // 1 + caboCentral
      }
    }

    for (x = 0; x < qtCorte1; x += 1) {
      if (!auxCorteCabo[x]) {
        auxCorteCabo[x] = [];
      }
      if (!corteCabo[x]) {
        corteCabo[x] = [];
      }

      for (y = 0; y < qtCorte2; y += 1) {
        auxCorteCabo[x][y] = 0;
        corteCabo[x][y] = 0;
      }
    }

    if (maximoArco === 1) {
      for (aux1 = 0; aux1 < qtCorte2; aux1 += 1) {
        auxCorteCabo[0][aux1] = ((disposition[aux1].cable - primeiroCabo));
      }
    } else if (maximoArco !== 1) {
      for (aux1 = 0; aux1 < qtCorte1; aux1 += 1) {
        auxCont = -1;
        auxCaboCentral = true;

        // for (aux4 = 0; aux4 < maximoArco - 2; aux4 += 1) {
        for (aux4 = 0; aux4 < maximoArco; aux4 += 1) {
          totalCabo = countCablesInArc(data, aux4 + 1);

          if (aux4 === 0) {
          // anel de fora
            auxMenor = aux4 + aux1;
            auxMaior = Math.trunc(totalCabo / 2) + auxMenor;
            if (auxMaior >= totalCabo) {
              auxMaior = Math.abs(totalCabo - auxMaior);
            }
          } else {
            // anel de dentro se possuir
            diferenca1 = 1000;
            valorAntes1 = disposition[auxMenor].angle;

            diferenca2 = 1000;
            valorAntes2 = disposition[auxMaior].angle;

            auxMenorAnterior = auxMenor;
            auxMaiorAnterior = auxMaior;

            for (aux5 = 0; aux5 < totalDispositions; aux5 += 1) {
              if (disposition[aux5].arc === (aux4 + 1)) {
              // procura somente os cabos do anel selecionado

                auxDiferenca1 = Math.abs(disposition[aux5].angle - valorAntes1);
                if ((auxDiferenca1 <= diferenca1) && (auxMenorAnterior !== aux5)) {
                  auxMenor = aux5;
                  diferenca1 = auxDiferenca1;
                }

                auxDiferenca2 = Math.abs(disposition[aux5].angle - valorAntes2);
                if ((auxDiferenca2 <= diferenca2) && (auxMaiorAnterior !== aux5)) {
                  auxMaior = aux5;
                  diferenca2 = auxDiferenca2;
                }
              }
            }
          }
          auxZ = false;
          auxCont += 1;
          if ((auxCont > Math.trunc(qtCorte2 / 2) - 1) && (auxCaboCentral === true)) {
            if (auxCont < qtCorte2) {
              auxCorteCabo[aux1][auxCont] =
              (disposition[(totalDispositions - 1)].cable - primeiroCabo);
            }
            auxCaboCentral = false;
            auxCont += 1;
          }
          if (auxCont < qtCorte2) {
            for (z = 0; z < qtCorte2; z += 1) {
              // Verifica se o valor ja existe e se e diferente de zero
              if ((auxCorteCabo[aux1][z] === (disposition[(auxMenor)].cable - primeiroCabo)) &&
               (auxCorteCabo[aux1][z] !== 0)) {
                auxZ = true;
              }
            }
            if (auxZ === false) {
              auxCorteCabo[aux1][auxCont] = (disposition[(auxMenor)].cable - primeiroCabo);
            } else {
              auxCorteCabo[aux1][auxCont] = (disposition[(auxMaior)].cable - primeiroCabo);
            }
          }
          auxCont += 1;
          if ((auxCont > Math.trunc(qtCorte2 / 2) - 1) && (auxCaboCentral === true)) {
            if (auxCont < qtCorte2) {
              auxCorteCabo[aux1][auxCont] =
              (disposition[(totalDispositions - 1)].cable - primeiroCabo);
            }
            auxCaboCentral = false;
            auxCont += 1;
          }
          auxZ = false;
          if (auxCont < qtCorte2) {
            for (z = 0; z < qtCorte2; z += 1) {
              // Verifica se o valor ja existe e se e diferente de zero
              if ((auxCorteCabo[aux1][z] === (disposition[(auxMaior)].cable - primeiroCabo)) &&
              (auxCorteCabo[aux1][z] !== 0)) {
                auxZ = true;
              }
            }
            if (auxZ === false) {
              auxCorteCabo[aux1][auxCont] = (disposition[(auxMaior)].cable - primeiroCabo);
            } else {
              auxCorteCabo[aux1][auxCont] = (disposition[(auxMenor)].cable - primeiroCabo);
            }
          }
        }
      }
    }

    if (qtCorte2 > 3) {
      // reordena posicao na matriz
      for (x = 0; x < qtCorte1; x += 1) {
        for (y = 0; y < qtCorte2; y += 1) {
          switch (qtCorte2) {
            case 4:
              switch (y) {
                case 0:
                  auxY = 0; // 1 anel
                  break;
                case 1:
                  auxY = 3; // 2 anel
                  break;
                case 2:
                  auxY = 2; // 3 anel ou central
                  break;
                case 3:
                  auxY = 1; // 2 anel
                  break;
                default:
              // do nothing
              }
              break;
            case 5:
              switch (y) {
                case 0:
                  auxY = 0; // 1 anel
                  break;
                case 1:
                  auxY = 3; // 2 anel
                  break;
                case 2:
                  auxY = 2; // 3 anel ou central
                  break;
                case 3:
                  auxY = 4; // 2 anel
                  break;
                case 4:
                  auxY = 1; // 1 anel
                  break;
                default:
                // do nothing
              }
              break;
            case 6:
              switch (y) {
                case 0:
                  auxY = 0; // 1 anel
                  break;
                case 1:
                  auxY = 2; // 2 anel
                  break;
                case 2:
                  auxY = 5; // 3 anel ou central
                  break;
                case 3:
                  auxY = 3; // 2 anel
                  break;
                case 4:
                  auxY = 4; // 1 anel
                  break;
                case 5:
                  auxY = 1; // 1 anel
                  break;
                default:
                  // do nothing
              }
              break;
            case 7:
              switch (y) {
                case 0:
                  auxY = 0; // 1 anel
                  break;
                case 1:
                  auxY = 2; // 2 anel
                  break;
                case 2:
                  auxY = 5; // 3 anel
                  break;
                case 3:
                  auxY = 3; // 4 anel ou central
                  break;
                case 4:
                  auxY = 6; // 3 anel
                  break;
                case 5:
                  auxY = 4; // 2 anel
                  break;
                case 6:
                  auxY = 1; // 1 anel
                  break;
                default:
                    // do nothing
              }
              break;
            default:
              // do nothing
          }
          corteCabo[x][y] = disposition[auxCorteCabo[x][auxY]].cable;
        }
      }
    } else {
      for (x = 0; x < qtCorte1; x += 1) {
        for (y = 0; y < qtCorte2; y += 1) {
          corteCabo[x][y] = disposition[auxCorteCabo[x][y]].cable;
          // console.log('entrou no else');
        }
      }
    }

    return (corteCabo);
  }
}
