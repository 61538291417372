import React from 'react';
import colors from '../../styles/colors';

export default (props) => {
  const {
    stCannon,
  } = props.status;

  const textCannon = stCannon === 0 ? 'CANHÃO DESLIGADO' : 'CANHÃO LIGADO';
  const textColor = stCannon === 0 ? colors.black : colors.white;
  const cannonColor = stCannon === 0 ? colors.black : colors.pivotOk;
  const backgroundColor = stCannon === 0 ? colors.white : colors.pivotOk;

  return (
    <svg viewBox="0 0 500 500" width="100%">
      <defs>
        <grid x="0" y="0" width="122.653" height="92.449" />
      </defs>
      <g id="CANNON_TEXT">
        <rect
          y="358.899"
          width="500.204"
          height="139.499"
          style={{ fill: backgroundColor, stroke: colors.grey }}
          rx="21.419"
          ry="21.419"
        />
        <text
          style={{
            fill: textColor,
            fontFamily: 'Roboto',
            fontSize: '36px',
            fontWeight: 800,
            textAnchor: 'middle',
            whiteSpace: 'pre',
          }}
          x="254.694"
          y="445.48"
        >
          {textCannon}
        </text>
      </g>
      <g
        id="CANNON"
        transform="matrix(7.385252, 0, 0, 7.385252, -5119.227051, -768.654724)"
      >
        <polyline
          style={{ fill: cannonColor }}
          points="705.929 148.267 706.035 140.621 704.549 140.409 699.664 146.249 699.558 140.409 713.362 133.613 713.681 130.215 723.663 125.011 728.76 125.011 753.465 109.739 753.452 114.455 749.998 115.879 749.891 118.852 747.237 120.976 742.564 120.87 729.078 129.79 729.291 132.445 718.141 138.71 718.247 143.064 713.256 148.373 707.628 147.948"
        />
      </g>
    </svg>
  );
};
