/* eslint-disable  */
import * as BABYLON from 'babylonjs';

import theme from './theme'

// CRIA O ELEMENTO CHÃO NO 3D
const CriaChao = (scene) => {
  const materialChao = new BABYLON.StandardMaterial('materialChao', scene);
  materialChao.diffuseTexture = new BABYLON.Texture(
    `${theme.textures.ground}`,
    scene,
  );
  materialChao.diffuseTexture.uScale = 12;
  materialChao.diffuseTexture.vScale = 8;
  // const chao = BABYLON.Mesh.CreateGround('chao', 1000, 1000, 2, scene);
  const chao = BABYLON.Mesh.CreateGround('chao', 1000, 1000, 2, scene);
  chao.isPickable = false;
  chao.checkCollisions = true;
  chao.material = materialChao;
};

export default CriaChao;
