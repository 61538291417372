import React from 'react';
import Chart from 'react-apexcharts';
import { Paper } from '@material-ui/core';

export function GraphicEarnings({ data, searchPeriod }) {
  const options = {
    chart: {
      toolbar: {
        show: true,
        export: {
          csv: {
            filename: 'relatorio-de-ganhos-fotovoltaica',
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
        },
      },
      stacked: true,
    },
    stroke: {
      curve: 'smooth',
    },
    xaxis: {
      type: !data.categories ? 'datetime' : undefined,
      categories: data.categories || [],
    },
    yaxis: {
      show: true,
      labels: {
        show: true,
        align: 'right',
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [],
          fontSize: '12px',
          fontFamily: 'Helvetica, Arial, sans-serif',
          fontWeight: 400,
          cssClass: 'apexcharts-yaxis-label',
        },
        offsetX: 0,
        offsetY: 0,
        rotate: 0,
        formatter: (value) => value.toFixed(2),
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: '12px',
        fontFamily: undefined,
      },
      x: {
        show: true,
        formatter: undefined,
      },
      y: {
        formatter(val) {
          return `${val.toFixed(2)} kWh`;
        },
        title: {
          formatter: (seriesName) => seriesName,
        },
      },
      z: {
        formatter: undefined,
        title: 'Size: ',
      },
      marker: {
        show: true,
      },
      items: {
        display: 'flex',
      },
    },
    dataLabels: {
      enabled: false,
    },
  };

  const getGraphicTypeByPeriod = React.useMemo(() => {
    if (searchPeriod === 'today' || searchPeriod === 12) {
      return 'line';
    }
    return 'bar';
  }, [searchPeriod]);

  return (
    <Paper>
      <Chart
        options={options}
        series={data.series}
        type={getGraphicTypeByPeriod}
        height="500"
      />
      {console.log('dadosGrafico', data)}
    </Paper>
  );
}
