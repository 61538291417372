import React from 'react';
import { BsPlus } from 'react-icons/all';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Tooltip } from '@material-ui/core';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { AlarmsTable, CreateNewAlarmRule } from './components';
import { ButtonComponent as Button, Modal } from '../../../components';
import { useAlarmsContext } from '../../../hooks/AlarmsRule';

import * as S from './styles';
import { useTelemetryVariablesHook } from '../../../hooks/telemetryVariables';

export function AlarmRole() {
  const breadcrumb = {
    title: 'Controle de Alarmes',
    routes: [
      { name: 'Configurações', to: '/settings' },
      { name: 'Controle de Alarmes' },
    ],
  };

  const { handleGetTelemetryVariables } = useTelemetryVariablesHook();

  const [openCreateNewAlarmModal, setOpenCreateNewAlarmModal] = React.useState(
    false,
  );

  const handleCloseCreateNewAlarmModal = () => {
    setOpenCreateNewAlarmModal(false);
    handleClearAlarmToUpdate();
  };

  const {
    handleGetAlarms,
    selectAlarmToEdit,
    handleClearAlarmToUpdate,
    handleDisableAlarm,
    alarmToEdit,
  } = useAlarmsContext();

  React.useEffect(() => {
    (async () => {
      await handleGetTelemetryVariables();
      await handleGetAlarms();
    })();
  }, []);

  const handleSelectAlarmToUpdate = (selectedAlarm) => {
    selectAlarmToEdit(selectedAlarm);
    setOpenCreateNewAlarmModal(true);
  };

  const aditionalColumns = [
    {
      right: true,
      wrap: true,
      maxWidth: '50px',
      cell: (row) => (
        <>
          <Tooltip title="Visualizar/Editar">
            <button
              type="button"
              style={{ color: '#2F80ED' }}
              onClick={() => handleSelectAlarmToUpdate(row)}
            >
              <EditIcon size={18} />
            </button>
          </Tooltip>

          <Tooltip title="Excluir">
            <button
              type="button"
              style={{ color: '#2F80ED' }}
              onClick={() => handleDisableAlarm(row)}
            >
              <DeleteIcon size={18} />
            </button>
          </Tooltip>
        </>
      ),
    },
  ];

  return (
    <S.Wrapper>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />

      <div className="button-new">
        <Button
          color="secondary"
          icon={<BsPlus />}
          handle={() => setOpenCreateNewAlarmModal(true)}
        >
          Adicionar Alarme
        </Button>
      </div>

      <AlarmsTable aditionalColumns={aditionalColumns} />

      <Modal
        title={alarmToEdit ? 'Editar Alarme' : 'Cadastro de novo Alarme'}
        open={openCreateNewAlarmModal}
        handleCloseModal={handleCloseCreateNewAlarmModal}
      >
        <CreateNewAlarmRule
          handleCloseCreateNewAlarmModal={handleCloseCreateNewAlarmModal}
        />
      </Modal>
    </S.Wrapper>
  );
}
