import React from 'react';

const formatActionLabel = (actions) => {
  const availableActions = [];
  const actionKeys = Object.keys(actions);
  for (const action of actionKeys) {
    if (actions[action].length >= 1) {
      availableActions.push(action);
    }
  }
  return availableActions.join(' - ');
};

export const columns = [
  {
    name: 'Variável de Telemetria',
    selector: 'telemetryVariablesInfo.findlyName',
    left: true,
    wrap: true,
    maxWidth: '500px',
    cell: (row) => <strong>{row.telemetryVariablesInfo.friendlyName}</strong>,
  },
  {
    name: 'Ações Cadastradas',
    selector: 'actions',
    maxWidth: '500px',
    cell: (row) => <span>{formatActionLabel(row.actions)}</span>,
  },
  {
    name: 'status',
    selector: 'status',
    maxWidth: '500px',
    center: true,
    cell: (row) => <span>{row.status === 'A' ? 'Ativo' : 'Inativo'}</span>,
  },
  {
    name: 'Qtd. Condições',
    selector: 'conditions',
    center: true,
    maxWidth: '500px',
    cell: (row) => <span>{row.conditions.length}</span>,
  },
];
