import React from 'react';
import { CardCannons } from '../Cannons';
import { CardInformation } from '../CradInformation';
import { SwitchCard } from '../SwitchCard';
import * as S from './styles';

export function DatasheetCard({ dataSheetEquipaments }) {
  const { cannons } = dataSheetEquipaments;
  return (
    <S.Wrapper>
      <S.Content>
        <div>
          <S.DivInformations>
            <S.ContentCollumn>
              <S.RowDiv>
                {[
                  { title: 'Tempo de emergência', data: dataSheetEquipaments.timeEmergency, measureType: 's' },
                  { title: 'Lâmina mínima', data: dataSheetEquipaments.minimumMM, measureType: 'mm' },
                  { title: 'Velocidade máxima', data: dataSheetEquipaments.maximumSpeed, measureType: 'mm' },
                  { title: 'Comprimento Pivô', data: dataSheetEquipaments.wheelLength, measureType: 'm' },
                  { title: 'Tempo de reversão', data: dataSheetEquipaments.timeReversal, measureType: 's' },
                  { title: 'Ângulo máximo setorial', data: dataSheetEquipaments.maxSectorAngle, measureType: 'º' },
                ].map((sectionData) => (
                  <CardInformation key={sectionData.title} data={sectionData} />
                ))}
              </S.RowDiv>

              <S.DivSwitch>
                {[
                  { title: 'Identificador de Posição', checked: !!(dataSheetEquipaments.positionIdentifier) },
                  { title: 'Opcionais - Canhão', checked: !!(dataSheetEquipaments.optionalCannon) },
                  { title: 'Opcionais - GSM', checked: !!(dataSheetEquipaments.optionalGsm) },
                  { title: 'Opcionais - Ecopump', checked: !!(dataSheetEquipaments.optionalEcopump) },
                  { title: 'Opcionais - Linear', checked: !!(dataSheetEquipaments.optionalLinear) },
                  { title: 'Opcionais - Estação', checked: !!(dataSheetEquipaments.optionalStation) },
                ].map((dataHp) => (
                  <SwitchCard key={dataHp.title} data={dataHp} />
                ))}
              </S.DivSwitch>
            </S.ContentCollumn>

            {cannons && (
            <S.ContentCollumn>
              <S.CradTitle>Canhão</S.CradTitle>

              <S.RowDiv>
                {dataSheetEquipaments.cannons.map((cannon) => (
                  <CardCannons key={cannon} cannon={cannon} />
                ))}
              </S.RowDiv>
            </S.ContentCollumn>
            )}

          </S.DivInformations>
        </div>
      </S.Content>

    </S.Wrapper>
  );
}
