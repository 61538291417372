import React from 'react';
import { InputLabel,
  MenuItem,
  ListItem,
  Checkbox,
  Select,
  FormControl,
} from '@material-ui/core';

export function MenuItemComponent({ options, selectedSeriesData, handleChange }) {
  const formatLabel = selectedSeriesData.series.map((serie) => serie.name);

  return (
    <div>
      <FormControl style={{ maxWidth: 300 }}>
        <InputLabel id="demo-mutiple-checkbox-label">Categorias</InputLabel>
        <Select
          style={{ width: '210px', maxWidth: '210px' }}
          multiple
          value={selectedSeriesData.series}
          onChange={handleChange}
          renderValue={() => selectedSeriesData.series.length > 0 ? formatLabel.join(' - ') : 'Filtro de dados'}
        >
          {options.map((option) => (
            <MenuItem key={option.name} value={option}>
              <Checkbox checked={selectedSeriesData.series.indexOf(option) > -1} />
              <ListItem>{option.name}</ListItem>
            </MenuItem>
          ))}
        </Select>
      </FormControl>

    </div>
  );
}
