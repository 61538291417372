import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { Redirect } from 'react-router-dom';

export default (props) => {
  const [redirect, setRedirect] = React.useState(false);

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <Button
      size="large"
      variant="contained"
      color="secondary"
      style={{ ...props.style }}
      onClick={() => (props.externalNew ? props.externalNew() : setRedirect(props.redirectTo))}
      startIcon={<AddIcon />}
    >
      {props.title}
    </Button>
  );
};
