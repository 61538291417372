// Basic
import React from 'react';

// UI, images, styles and icons
import '../../../../App.css';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import DirectionsIcon from '@material-ui/icons/Directions';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import Avatar from '@material-ui/core/Avatar';
import Tooltip from '@material-ui/core/Tooltip';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import FlashOffIcon from '@material-ui/icons/FlashOff';
import WarningIcon from '@material-ui/icons/Warning';
import LockIcon from '@material-ui/icons/Lock';
// import UpdateIcon from '@material-ui/icons/Update';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './IndustrialAutomationRoute.css';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { Modal } from '../../../../components/Modal';
import SubEquipament from './SubEquipament';
import { ModalSubEquip } from './ModalSubEquip';

const useStyles = makeStyles({
  card: {
    padding: '15px',
  },
  cardTitle: {
    fontWeight: 500,
    display: 'inline-block',
    margin: 0,
  },
  header: {
    marginBottom: '20px',
  },
  message: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  messageIcon: {
    marginRight: '10px',
    fontSize: '38px',
  },
  messageLabel: {
    color: '#555555',
  },
  routeHeader: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '20px',
    margin: 'auto',
    marginTop: '0',
    fontWeight: '400',
  },
  routeIcon: {
    marginRight: '10px',
    fontSize: '38px',
  },
  routeLabel: {
    color: '#555555',
  },
  square: {
    display: 'inline-flex',
    color: '#FFFFFF',
    width: 'auto',
    height: 'auto',
    padding: '3px 10px',
  },
  routeStatus: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
  },
  slider: {
    display: 'flex',
  },
  sliderButton: {
    border: 'none',
    backgroundColor: 'transparent',
  },
});

// Route status
const routeStatus = [];
routeStatus[5] = 'Falta de permissão.';
routeStatus[4] = 'Limpando linha e desligando.';
routeStatus[3] = 'Ligada com nível alto acionado.';
routeStatus[2] = 'Ligada sem nível alto acionado.';
routeStatus[1] = 'Ligando.';
routeStatus[0] = 'Rota desligada.';
routeStatus[-1] = 'Equipamento em uso para a sequencia selecionada';
routeStatus[-2] = `
  Não foram encontrados todos os tags dos equipamentos no vetor banco equipamentos
`;
routeStatus[-3] = 'Encontrados dois registros alimentadores na mesma rota.';
routeStatus[-4] = 'Encontrado dois níveis na mesma rota.';
routeStatus[-5] = 'Nível Alto Acionado, a rota não liga com nível alto.';

export default (props) => {
  const classes = useStyles();
  const [subModal, setSubModal] = React.useState({ open: false });

  if (props.route.qtyDevices === 0) {
    return (
      <Paper className={classes.card}>
        <Grid item xs={12} md={12} lg={12}>
          <h3 className={classes.routeHeader}>
            <span className={classes.routeLabel}>
              Gerenciador disponível. Nenhuma Rota associada.
            </span>
          </h3>
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper className={classes.card}>
      <Grid container>
        <Grid container className={classes.header}>
          <Grid item xs={12} md={6} lg={6}>
            <h3 className={classes.routeHeader}>
              <DirectionsIcon className={classes.routeIcon} />
              <span className={classes.routeLabel}>{props.route.name}</span>
            </h3>
          </Grid>
          <Grid item xs={12} md={6} lg={6} style={{ textAlign: 'right' }}>
            {props.route.message && props.route.routeStatus < 0 && (
              <div className={classes.message}>
                <MarkunreadIcon className={classes.messageIcon} />
                <span className={classes.messageLabel}>
                  {props.route.message}
                </span>
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={2} lg={2}>
            <h3 className={classes.cardTitle}>Status</h3>
            <div className={classes.routeStatus}>
              {props.route.routeStatus === 5 && (
                <Tooltip title={routeStatus[props.route.routeStatus]}>
                  <>
                    <LockIcon style={{ color: '#CDCDCD', fontSize: '80px' }} />
                    <span>Nível Alto</span>
                  </>
                </Tooltip>
              )}
              {props.route.routeStatus === 4 && (
                <Tooltip title={routeStatus[props.route.routeStatus]}>
                  <>
                    <FlashOffIcon
                      style={{ color: '#026502', fontSize: '80px' }}
                    />
                    <span>Desligando</span>
                  </>
                </Tooltip>
              )}
              {props.route.routeStatus === 3 && (
                <Tooltip title={routeStatus[props.route.routeStatus]}>
                  <>
                    <FlashOnIcon
                      style={{ color: '#DC3F3F', fontSize: '80px' }}
                    />
                    <span>Ligada</span>
                  </>
                </Tooltip>
              )}
              {props.route.routeStatus === 2 && (
                <Tooltip title={routeStatus[props.route.routeStatus]}>
                  <>
                    <FlashOnIcon
                      style={{ color: '#DC3F3F', fontSize: '80px' }}
                    />
                    <span>Ligada</span>
                  </>
                </Tooltip>
              )}
              {props.route.routeStatus === 1 && (
                <Tooltip title={routeStatus[props.route.routeStatus]}>
                  <>
                    <FlashOnIcon
                      style={{ color: '#DC3F3F', fontSize: '80px' }}
                    />
                    <span>{`Ligando (${props.route.percent}%)`}</span>
                  </>
                </Tooltip>
              )}
              {props.route.routeStatus === 0 && (
                <Tooltip title={routeStatus[props.route.routeStatus]}>
                  <>
                    <FlashOffIcon
                      style={{ color: '#026502', fontSize: '80px' }}
                    />
                    <span>Desligada</span>
                  </>
                </Tooltip>
              )}
              {props.route.routeStatus < 0 && (
                <Tooltip title={routeStatus[props.route.routeStatus]}>
                  <>
                    <WarningIcon
                      style={{ color: '#FFCD41', fontSize: '80px' }}
                    />
                    <span>Falha</span>
                  </>
                </Tooltip>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <h3 className={classes.cardTitle}>Componentes: &nbsp;</h3>
            <Avatar variant="square" className={classes.square}>
              {props.route.qtyDevices}
            </Avatar>
            <br />
            <br />
            <CarouselProvider
              naturalSlideWidth={100}
              naturalSlideHeight={125}
              totalSlides={props.route.devices.length}
              visibleSlides={Math.trunc(
                document.documentElement.clientWidth / 250,
              )}
            >
              <div className={classes.slider}>
                <ButtonBack className={classes.sliderButton}>
                  <NavigateBeforeIcon />
                </ButtonBack>
                <Slider>
                  {props.route.devices.map((r, i) => (
                    <Slide key={r.name} index={i}>
                      <SubEquipament
                        subEqp={r}
                        equipamentId={props.equipamentId}
                        subEquipaments={props.subEquipaments}
                        setSubModal={setSubModal}
                      />
                    </Slide>
                  ))}
                </Slider>
                <ButtonNext className={classes.sliderButton}>
                  <NavigateNextIcon />
                </ButtonNext>
              </div>
            </CarouselProvider>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        title="Detalhar Componente"
        open={subModal.open}
        handleCloseModal={() => setSubModal({ open: false })}
      >
        {subModal.open ? (
          <ModalSubEquip
            handleSubmit={() => {
              props.loadRoutes();
            }}
            closeModal={() => setSubModal({ open: false })}
            stateSub={subModal?.subEqp}
          />
        ) : (
          <h3>Carregando..</h3>
        )}
      </Modal>
    </Paper>
  );
};
