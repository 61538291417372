/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DataTable from 'react-data-table-component';
import { number } from '../../../../../helpers/Intl';

export default (props) => {
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });

  React.useEffect(() => {
    const columns = [
      { name: 'Grão', selector: 'name', center: true },
      {
        name: 'Angulo',
        center: true,
        cell: (row) => (<div data-tag="allowRowEvents">{`${number(row.angle, 0)}°`}</div>),
      },
      // { name: 'Porosidade', selector: 'porosity', center: true },
      {
        name: 'Peso',
        center: true,
        cell: (row) => (<div data-tag="allowRowEvents">{`${number(row.weight, 1)} T/m³`}</div>),
      },
      {
        name: 'Compactação',
        center: true,
        cell: (row) => (<div data-tag="allowRowEvents">{`${number(row.compaction, 0)}%`}</div>),
      },
      // {
      //   name: 'Valor A',
      //   center: true,
      //   cell: (row) => (<div data-tag="allowRowEvents">{`${number(row.valueA, 1)}`}</div>),
      // },
      // {
      //   name: 'Valor B',
      //   center: true,
      //   cell: (row) => (<div data-tag="allowRowEvents">{`${number(row.valueB, 1)}`}</div>),
      // },
    ];

    let maxI = 0;

    const data = props.data.map((e) => {
      const ranges = {};
      e.parameters.forEach((f, i) => {
        if (i > maxI) maxI = i;
        ranges[`fc${i}`] = f.color;
        ranges[`fl${i}`] = `${f.limitMin} a ${f.limitMax}`;
      });
      return {
        name: e.name,
        angle: e.angle,
        // porosity: e.porosity,
        weight: e.weight,
        compaction: e.compaction,
        // valueA: e.valueA,
        // valueB: e.valueB,
        ...ranges,
      };
    });

    new Array(maxI + 1).fill(0).forEach((j, i) => {
      columns.push(...[
        {
          name: `Faixa ${i + 1}`,
          center: true,
          compact: true,
          cell: (row) => (
            <div
              data-tag="allowRowEvents"
              style={{
                width: '100%', height: '100%', alignItems: 'center', background: row[`fc${i}`], display: 'grid',
              }}
            >
              <p style={{
                textAlign: 'center',
                textShadow: 'rgb(0, 0, 0) 0.5px 0px 0px, rgb(0, 0, 0) 0.540302px 0.841471px 0px, rgb(0, 0, 0) -0.416147px 0.909297px 0px, rgb(0, 0, 0) -0.989992px 0.14112px 0px, rgb(0, 0, 0) -0.653644px -0.756802px 0px, rgb(0, 0, 0) 0.283662px -0.958924px 0px, rgb(0, 0, 0) 0.96017px -0.279415px 0px',
                color: 'white',
                margin: 'auto 0',
                // fontSize: '15px',
              }}
              >
                {row[`fl${i}`]}
              </p>
            </div>
          ),
        },
      ]);
    });

    setDtCl({ columns, data });
  }, [props.data]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <DataTable
          pagination
          paginationPerPage={5}
          paginationComponentOptions={{ rowsPerPageText: 'Linhas:', rangeSeparatorText: 'de' }}
          paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
          noDataComponent="Não existem dados para serem mostrados"
          columns={dtCl.columns}
          data={dtCl.data}
          // title="Range de Qualidade"
        />
      </Grid>
    </Grid>
  );
};
