import React, { useEffect, useState } from 'react';
import { Tooltip } from '@material-ui/core';
import NavigationIcon from '@material-ui/icons/Navigation';
import FockinkAPI from '../../middlewares/Api';
import './style.scss';

const WeatherStation = (props) => {
  const { WeatherStationId, onClickWs } = props;
  const [weatherStationData, setWeatherStationData] = useState();
  const [infos, setInfos] = useState();

  useEffect(() => {
    const getData = async () => {
      await FockinkAPI.get(
        `/dashboards/weatherStation/${WeatherStationId}/status`,
      ).then((response) => {
        setWeatherStationData(response.data.data);
        if (response.data.data?.running) {
          setInfos([
            `${response.data.data.clientDescription}`,
            <br />,
            `Temperatura: ${response.data.data.lastTelemetry?.temperature.toFixed(1)}°C`,
            <br />,
            `Umidade: ${response.data.data.lastTelemetry?.humidity.toFixed(1)}%`,
            <br />,
            `Chuva do dia: ${response.data.data.lastTelemetry?.rainDayAmount}mm`,
            <br />,
            `Taxa de precipitação: ${response.data.data.lastTelemetry?.precipitationRate}mm/h`,
            <br />,
            ` Velocidade do vento: ${response.data.data.lastTelemetry?.windSpeed}km/h`,
            <NavigationIcon
              style={{
                width: 'auto',
                height: '12px',
                transform: `rotate(${response.data.data.lastTelemetry?.windDirection}deg)`,
              }}
            />,
            <br />,
            `Sensação térmica: ${response.data.data.lastTelemetry?.thermicSensation.toFixed(1)}°C`,
            <br />,
            `Ponto de orvalho: ${response.data.data.lastTelemetry?.dewPoint}°C`,
            <br />,
            `Radiação solar: ${response.data.data.lastTelemetry?.solarRadiation}W/m²`,
            <br />,
            `Evapotranspiração: ${response.data.data.lastTelemetry?.evapotranspiration}mm/dia`,
            <br />,
            `Nível da bateria: ${response.data.data.lastTelemetry?.batteryLevel}%`,
          ]);
        } else {
          setInfos([
            'Não há dados',
          ]);
        }
      });
    };
    getData();
  }, []);

  return (

    <Tooltip
      title={infos}
      placement="top-start"
    >

      {weatherStationData?.running ? (
        <svg
          x="0px"
          y="0px"
          viewBox="0 0 1000 1000"
          enableBackground="new 0 0 1000 1000"
          xmlSpace="preserve"
          onClick={() => {
            onClickWs();
          }}
        >
          <rect
            x="3"
            y="3"
            rx="20"
            ry="20"
            width="150"
            height="150"
            style={{ fill: '#005db4', stroke: 'black', strokeWidth: 5, opacity: 0.5 }}
          />
          <text
            fill="#ffffff"
            fontSize="43"
            fontFamily="Roboto"
            x="15"
            y="45"
          >
            {`${weatherStationData?.lastTelemetry?.temperature.toFixed(1)}°C`}

          </text>
          <text
            fill="#ffffff"
            fontSize="30"
            fontFamily="Roboto"
            x="15"
            y="90"
          >
            &#127778;
            {` ${weatherStationData?.lastTelemetry?.humidity.toFixed(1)}%`}

          </text>
          <img src="/images/icons/automationIcon.svg" alt="icon" />
          <text
            fill="#ffffff"
            fontSize="30"
            fontFamily="Roboto"
            x="15"
            y="130"
          >
            &#127783;
            {` ${weatherStationData?.lastTelemetry?.rainDayAmount}mm`}

          </text>
          Sorry, your browser does not support inline SVG.
        </svg>
      ) :
        (
          <svg
            x="0px"
            y="0px"
            viewBox="0 0 1000 1000"
            enableBackground="new 0 0 1000 1000"
            xmlSpace="preserve"
            onClick={() => {
              onClickWs();
            }}
          >
            <rect
              x="3"
              y="3"
              rx="20"
              ry="20"
              width="150"
              height="150"
              style={{ fill: 'black', stroke: 'black', strokeWidth: 5, opacity: 0.5 }}
            />
            <text
              fill="#ffffff"
              fontSize="30"
              fontFamily="Roboto"
              x="15"
              y="45"
            >
              NÃO
            </text>
            <text
              fill="#ffffff"
              fontSize="30"
              fontFamily="Roboto"
              x="15"
              y="90"
            >
              HÁ

            </text>
            <img src="/images/icons/automationIcon.svg" alt="icon" />
            <text
              fill="#ffffff"
              fontSize="30"
              fontFamily="Roboto"
              x="15"
              y="130"
            >
              DADOS
            </text>
            Sorry, your browser does not support inline SVG.
          </svg>
        )
      }

    </Tooltip>
  );
};

export default WeatherStation;
