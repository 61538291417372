import React, { useEffect, useState, useCallback } from 'react';
import { Grid, FormControlLabel, Radio } from '@material-ui/core';
import { Ouroboro } from 'react-spinners-css';
import { GrDocumentStore } from 'react-icons/gr';
import { useHistory } from 'react-router-dom';
import ReportPlantComponent from './ReportPlant';
import FockinkAPI from '../../../../middlewares/Api';
import { useStyles } from './styles';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import BackButton from '../../../../components/Buttons/BackButton';
import { grains } from '../../../../helpers/mocks/grains';
import { formatDateToRequest } from '../../../../helpers/formatDateToRequest';

import { ReportForm } from './components';

export default function ThermometryReport() {
  const breadcrumb = {
    title: 'Acompanhamento de Temperatura',
    routes: [
      { name: 'Relatórios', to: '/reports' },
      { name: 'Acompanhamento de Temperatura' },
    ],
  };

  const [breadcrumbs, setBreadcrumbs] = useState({ routes: [] });
  const urlParams = window.location.search;

  const classes = useStyles();

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [recivedThermometryData, setRecivedThermometryData] = useState([]);
  const [plantThermometrySeleceted, setPlantThermometrySeleceted] = useState();
  const [loading, setLoadgin] = useState(false);
  const [selectedPlants, setSelectedPlants] = useState([]);
  const [selectedGrains, setSelectedGrains] = useState([]);
  const [isAllGrainsSelected, setIsAllGrainsSelected] = useState([]);

  const history = useHistory();
  useEffect(() => {
    setBreadcrumbs({
      title: 'Acompanhamento de Temperatura',
      routes: [
        { name: 'Relatórios', to: '/reports' },
        { name: 'Acompanhamento de Temperatura' },
      ],
      secondRoute: [
        { name: 'Clientes', to: '/clientsAdm/' },
        { name: 'Relatórios', to: `/reportsAdm${urlParams}&op=all` },
        { name: 'Acompanhamento de Temperatura' },
      ] });
  }, []);
  const handleSelectPlantThermometry = (recivedThhermometry) => {
    const plantResult = recivedThermometryData.find(
      (item) => item.plant === recivedThhermometry.target.value,
    );
    setPlantThermometrySeleceted(plantResult);
  };

  const handleGetReport = useCallback(async () => {
    try {
      setPlantThermometrySeleceted(null);
      setLoadgin(true);
      setRecivedThermometryData([]);

      const verifyPlantValue =
        selectedPlants.indexOf('Todas') > -1 ? '' : selectedPlants;
      const verifyGrainValue =
        selectedGrains.indexOf('Todos') > -1 ? '' : selectedGrains;

      const res = await FockinkAPI.get('reports/thermometry/monitoringReport', {
        params: {
          date: formatDateToRequest(selectedDate),
          grains: verifyGrainValue,
          plants: verifyPlantValue,
        },
      });

      const { reportData } = res.data.data;
      setLoadgin(false);
      setRecivedThermometryData(reportData);
    } catch (error) {
      // setError(error);
      setLoadgin(false);
    }
  }, [selectedGrains, selectedPlants, selectedDate]);

  const handleDateChange = useCallback(
    (date) => {
      setPlantThermometrySeleceted(null);
      setSelectedDate(date);
    },
    [selectedGrains],
  );

  const handleSelectPlant = (p) => {
    setSelectedPlants(p);
  };

  const handleSelectedGrain = (event) => {
    if (event.target.value.indexOf('Todos') > -1 || []) {
      setIsAllGrainsSelected(event.target.value);
      setSelectedGrains();
    }
    setSelectedGrains(event.target.value);
  };

  return (
    <>
      <Breadcrumbs routes={localStorage.profile === 'admin' && urlParams ? breadcrumbs.secondRoute : breadcrumbs.routes} title={breadcrumbs.title} />
      <Grid
        container
        direction="column"
        spacing={1}
        className={classes.wrapper}
      >
        <ReportForm
          handleDateChange={handleDateChange}
          handleSelectPlant={handleSelectPlant}
          selectedDate={selectedDate}
          handleSelectedGrain={handleSelectedGrain}
          grains={grains}
          selectedGrains={selectedGrains}
          isAllGrainsSelected={isAllGrainsSelected}
          selectedPlants={selectedPlants}
          handleGetReport={handleGetReport}
        />
        <Grid
          container
          xs={12}
          direction="column"
          alignItems="center"
          style={{ flex: '1', margin: '0.8rem 0' }}
        >
          <Grid container direction="row" lg={10} md={12} sm={12}>
            <div className={classes.divScroll}>
              {recivedThermometryData.map((plant) => (
                <FormControlLabel
                  key={plant.plant}
                  value={plant.plant}
                  control={(
                    <Radio
                      checked={
                        plantThermometrySeleceted &&
                        plantThermometrySeleceted.plant === plant.plant
                      }
                      onChange={(e) => handleSelectPlantThermometry(e)}
                    />
                  )}
                  label={plant.plant}
                  onChange
                />
              ))}
            </div>

            {loading && (
              <div className={classes.loadingContainer}>
                <Ouroboro color="#3D5059" />
              </div>
            )}
          </Grid>
        </Grid>

        {plantThermometrySeleceted && (
          <Grid
            xs={12}
            md={12}
            sm={12}
            lg={12}
            container
            spacing={1}
            alignItems="center"
            justifyContent="center"
            style={{
              background: 'white',
              borderRadius: '6px',
              padding: '24px',
              flex: '1',
            }}
          >
            {plantThermometrySeleceted ? (
              <Grid item xs={12} md={12} sm={12} lg={12}>
                {plantThermometrySeleceted.reports.map((dataReport) => (
                  <ReportPlantComponent
                    key={dataReport.report}
                    data={dataReport}
                    selectedPlant={plantThermometrySeleceted}
                    selectedDate={selectedDate}
                  />
                ))}
              </Grid>
            ) : (
              <div className={classes.noDataToView}>
                <GrDocumentStore size="40" />
                <strong>Não há dados selecionados para serem exibidos</strong>
                <span>Gere uma consulta para visualizar novos dados</span>
              </div>
            )}
          </Grid>
        )}

        <Grid className={classes.btnBttn} item xs={12}>
          <BackButton
            back={() => history.goBack()}
            className={classes.btnSpace}
          />
        </Grid>
      </Grid>
    </>
  );
}
