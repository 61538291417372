import React from 'react';
import colors from '../../../helpers/colors';

function resizingTextCable(lengthCableNumber) {
  let result = '';
  if (lengthCableNumber < 4) {
    result = '16';
  } else if ((lengthCableNumber > 4) || (lengthCableNumber === 4)) {
    result = '12';
  }
  return result;
}

function positionTextCable(lengthCableNumber) {
  let result = '';
  switch (lengthCableNumber) {
    case 1:
      result = '12';
      break;
    case 2:
      result = '6';
      break;
    default:
      result = '1';
  }
  return result;
}

export default function CableSVAuto({ cable, tempColor, diameter, handleClick, selectedSection }) {
  const radius = (diameter / 2);

  function colorSelectedSection() {
    let color = '';
    if (selectedSection !== undefined) {
      selectedSection.forEach((item) => {
        if (item === cable) {
          color = colors.cable;
        }
      });
    }

    if (color === '') {
      color = colors.silo;
    }

    return color;
  }

  return (
    <svg width={diameter + 4} height={diameter + 4} onClick={() => handleClick(cable)}>
      <circle
        cx={radius + 2}
        cy={radius + 2}
        r={radius}
        stroke={colorSelectedSection()}
        strokeWidth="3"
        fill={tempColor}
      />
      Sorry, your browser does not support inline SVG.
      <text
        fill="#000000"
        fontSize={resizingTextCable(cable.toString().length)}
        fontFamily="Roboto"
        fontWeight="450"
        x={positionTextCable(cable.toString().length)}
        y="21"
      >
        {cable}
      </text>
    </svg>
  );
}
