import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 80vh;
  margin-top: 1rem;
`;

export const Content = styled.div`
  flex: 1;
  height: 100%;
`;

export const Resume = styled.div`
  display: flex;
  max-width: 820px;
  flex-direction: column;

 > span {
    font-size: 1.4rem;
    font-weight: 600;
    color: #000;
  }

 > div {
    flex: 1;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    @media screen and (max-width: 547px) {
      justify-content: center;
    }
  }
`;
