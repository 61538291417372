// Basic
import React from 'react';

// UI, images, styles and icons
import '../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import BackButton from '../../../components/Buttons/BackButton';
import Breadcrumbs from '../../../components/Breadcrumbs';
import PlantCards from './PlantCards';
import PlantMap from './PlantMap';
import './styles.css';

const useStyles = makeStyles({
  spacing: {
    marginTop: '15px',
  },
  bottomButtons: {
    margin: '25px 0',
    textAlign: 'right',
  },
});

export default () => {
  const [view, setView] = React.useState();
  const breadcrumb = {
    routes: [{ name: 'Plantas' }],
  };

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const mapCard = params.get('view');
    setView(mapCard || 'cards');
  }, [window.location.search]);

  const classes = useStyles();
  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} />
      <Grid container spacing={3} alignItems="flex-start">
        <Grid item xs={12} style={{ paddingTop: '0' }}>
          {!view && <Skeleton variant="rect" height={150} />}
          {view === 'cards' && <PlantCards view={view} setView={setView} />}
          {view === 'map' && <PlantMap view={view} setView={setView} />}
        </Grid>

        <Grid item xs={12} className={classes.bottomButtons}>
          <BackButton redirectTo="/" className={classes.btnSpace} />
        </Grid>
      </Grid>
    </>
  );
};
