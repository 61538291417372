import React from 'react';
// import Checkbox from '@material-ui/core/Checkbox';

const translateType = {
  1: 'Irrigação',
  2: 'Termometria',
  3: 'Automação',
  4: 'Fotovoltaica',
};

export const columns = [
  {
    name: 'Grupo',
    selector: 'groupName',
    // center: true,
    wrap: true,
    sortable: true,
  },
  {
    name: 'Emails',
    selector: 'emails',
    // center: true,
    wrap: true,
    cell: (row) => row.emails.join(', '),
  },
  {
    name: 'Sistemas',
    selector: 'systems',
    // center: true,
    wrap: true,
    cell: (row) => row.systems.map((s) => translateType[s]).join(', '),
  },
  // {
  //   name: 'Elegível a alarme',
  //   selector: 'alarmAvailable',
  //   center: true,
  //   cell: (row) => (
  //     <Checkbox
  //       disabled
  //       checked={row.alarmAvailable}
  //       inputProps={{ 'aria-label': 'primary checkbox' }}
  //     />
  //   ),
  //   sortable: true,
  // },
  {
    name: 'Status',
    selector: 'status',
    center: true,
    cell: (row) => <span>{row.status === 'A' ? 'Ativa' : 'Inativa'}</span>,
  },
];
