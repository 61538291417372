import React from 'react';
import Grid from '@material-ui/core/Grid';
import DataTable from 'react-data-table-component';
import Button from '@material-ui/core/Button';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import TextField from '@material-ui/core/TextField';
import ClientSearch from '../../../../components/ClientSearch';
import Spacer from '../../../../components/Spacer';

export default function App(props) {
  const [clients, setClients] = React.useState([]);

  const columns = [
    { name: 'Nome', selector: 'nome', center: true },
    { name: 'GNF', selector: 'gnf', center: true },
    {
      name: 'Tipo',
      selector: 'tipoPessoa',
      center: true,
      cell: (row) => (
        row.tipoPessoa === 'F' ? 'Física' : 'Jurídica'
      ),
    },
    { name: 'Documento', selector: 'cpfCnpj', center: true },
    {
      name: 'Endereço',
      center: true,
      grow: 2,
      cell: (row) => (
        <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
          {row.logradouro
            ? `${row.logradouro || ''} ${row.bairro || ''} ${row.numero || ''} ${row.municipio || ''} ${row.estado || ''} ${`CEP ${row.cep}` || ''}`.replace(/\s\s+/g, ' ')
            : '-'}
        </div>
      ),
    },
    {
      name: 'Status',
      center: true,
      cell: (row) => (
        <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
          {row.status === 'A' ? 'Ativo' : 'Inativo'}
        </div>
      ),
    },
    {
      name: 'Escolher',
      center: true,
      cell: (row) => (
        <div data-tag="allowRowEvents" style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            style={{ ...props.style }}
            onClick={() => {
              props.setClient(row);
              setClients([]);
            }}
            startIcon={<ImportExportIcon />}
          >
            Alterar
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      {props.onlyDataVisualization ? null : (
        <>
          <Grid item xs={12}>
            <ClientSearch setClients={setClients} />
          </Grid>
          {clients && (
          <Grid item xs={12}>
            <DataTable
              paginationComponentOptions={{ rowsPerPageText: 'Linhas:', rangeSeparatorText: 'de' }}
              noDataComponent=""
              columns={columns}
              data={clients}
              title=""
              style={{ marginTop: -70 }}
            />
          </Grid>

          )}
        </>
      )}
      {
      // console.log('opaa->', props.client.clienteNegocio)
      }

      <Grid item xs={12}>
        <Spacer />
        {props.client.gnf && (
        <>
          <Grid container spacing={3} style={{ marginTop: 20 }}>
            <Grid item lg={4} md={4} xs={12}>
              <TextField
                variant="outlined"
                name="name"
                disabled={props.client.nome === undefined}
                value={props.client.nome || ' '}
                label="Nome"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <TextField
                variant="outlined"
                name="gnf"
                value={props.client.gnf || ' '}
                disabled={props.client.gnf === undefined}
                label="GNF"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <TextField
                variant="outlined"
                name="type"
                value={
                  props.client.tipoPessoa === 'F' ?
                    'Física'
                    :
                    props.client.tipoPessoa === 'J' ?
                      'Jurídica'
                      :
                      ''
                            }
                disabled={props.client.tipoPessoa === undefined}
                label="Tipo"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <TextField
                variant="outlined"
                name="document"
                value={props.client.cpfCnpj || ' '}
                disabled={props.client.cpfCnpj === undefined}
                label="Documento - CPF/CNPJ"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item lg={6} md={4} xs={12}>
              <TextField
                variant="outlined"
                name="address"
                value={props.client.logradouro ?
                  `${props.client.logradouro || ''} ${props.client.bairro || ''} 
                  ${props.client.numero || ''} ${props.client.municipio || ''} 
                  ${props.client.estado || ''} 
                  ${`CEP ${props.client.cep}` || ''}`.replace(/\s\s+/g, ' ') : ' '}
                disabled={props.client.logradouro === undefined}
                label="Endereço"
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </>
        )}

      </Grid>

    </>
  );
}
