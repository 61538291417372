import React from 'react';
import { Tooltip } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { HiDownload } from 'react-icons/hi';
import FockinkAPI from '../../../../middlewares/Api';
import { handleMessageError } from '../../../../helpers/handlerMessageError';
import * as S from './styles';

export function FilesToDownload({ files }) {
  const normalizeFileName = (file) => file.split('.').slice(0, -1).join('.');
  const { id } = useParams();

  const handleMakeDownload = async (file, fileName) => {
    try {
      const response = await FockinkAPI.get(`equipaments/${id}/readFile`, {
        params: {
          fileName: file,
        },
      });

      const downloadURLLink = response.data.data;
      console.log('urlLink', downloadURLLink);
      const a = document.createElement('a');
      a.href = downloadURLLink;
      a.download = fileName;
      a.click();
    } catch (error) {
      handleMessageError(error);
    }
  };
  return (
    <S.Wrapper>
      <ul>
        {files.length === 0 ? (
          <span>Sem documentos para download</span>
        ) : (
          files.map(({ fileName, file }) => (
            <S.CardDownload key={file}>
              <strong>{normalizeFileName(fileName)}</strong>
              <Tooltip title="Fazer Download">
                <button
                  type="button"
                  onClick={() => handleMakeDownload(file, fileName)}
                >
                  <HiDownload />
                </button>
              </Tooltip>
            </S.CardDownload>
          ))
        )}
      </ul>
    </S.Wrapper>
  );
}
