import React from 'react';
import { useDashboardContext } from '../../../hooks/dashboard';
import { SliderMotorInfo } from './components/SliderMotorInfo';

import Card from '../../Card';
import CardText from '../../CardText';
import CardTitle from '../../CardTitle';

export function Aerationinfo() {
  const { filteresReportResultData, plantIdToRequest } = useDashboardContext();

  return (
    <Card pt="4px" pr="4px" pl="4px" pb="0">
      <CardTitle pt="4px" pr="4px" pl="4px" pb="0" size="1rem">
        {`Aeração ${plantIdToRequest ? '(Planta)' : ''}`}
      </CardTitle>
      <CardText pt="4px" pr="4px" pl="4px" pb="10px" size="1rem">
        {filteresReportResultData && (
          <SliderMotorInfo data={filteresReportResultData} />
        )}
      </CardText>
    </Card>
  );
}
