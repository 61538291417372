export function startDispositionsSensorsY(siloType, cableQtd, cableIndex) {
  /* ***********************************************************************
      Esta função referencia em pixels onde irá iniciar a
      disposição dos sensores dentro do termovisor no eixo Y
    *********************************************************************** */
  const startDistribuition = [];
  startDistribuition.pop();

  if (!siloType) { // caso não tenha sido passado o tipo de fundo
    siloType = 'plainArm'; // seta um tipo de fundo padrão
  }

  if (siloType === 'plainBunker') {
    return 100;
  }
  if (siloType === 'VBunker') {
    switch (cableQtd) {
      case 4:
        startDistribuition.push(25, 25, 25, 25);
        break;
      case 5:
        startDistribuition.push(25, 25, 25, 25, 25);
        break;
      case 6:
        startDistribuition.push(75, 48.5, 7, 0, 35.5, 75);
        // Esse daqui por exemplo pode ser testado com o Bunker QA
        break;
      case 7:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25);
        break;
      case 8:
        startDistribuition.push(77, 50, 25, 0, 0, 15, 42, 72);
        break;
      case 9:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 10:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 11:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 12:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 13:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 14:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 15:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      default:
        return 0;
    }
  }

  return (startDistribuition[cableIndex]);
}

export function calcTempPositionY(siloType, index, sensorsInCableQtd, cableQtd, cableIndex) {
  /* ***********************************************************************
      Esta função referencia em pixels o espaço útil para efetuar a
      distribuição dos sensores no eixo Y e retorna a posição dentro deste
      espaço que o mesmo irá ser posicionado
    *********************************************************************** */
  // console.log('calcTempPositionY - cableQtd: ', cableQtd);
  let cableSpacing;

  if (!siloType) { // caso não tenha sido passado o tipo de fundo
    siloType = 'plainBunker'; // seta um tipo de fundo padrão
  }

  if ((siloType === 'plainBunker')) {
    cableSpacing = {
      4: [170, 215, 215, 170],
      5: [170, 215, 260, 215, 170],
      6: [170, 215, 260, 260, 215, 170],
      7: [170, 215, 260, 260, 260, 215, 170],
      8: [170, 215, 260, 260, 260, 260, 215, 170],
      9: [170, 215, 260, 260, 260, 260, 260, 215, 170],
      10: [170, 160, 188, 230, 267, 267, 230, 188, 165, 125],
      11: [130, 160, 190, 220, 260, 280, 250, 210, 180, 150, 115],
      12: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      13: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      14: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      15: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],

    };
  }

  if (siloType === 'VBunker') {
    cableSpacing = {
      4: [370, 215, 215, 170],
      5: [270, 215, 260, 215, 170],
      6: [140, 240, 348, 340, 240, 140], // Esse daqui por exemplo pode ser testado com o Bunker QA
      7: [170, 215, 260, 260, 260, 215, 170],
      8: [130, 210, 285, 360, 370, 285, 210, 130],
      9: [170, 215, 260, 260, 260, 260, 260, 215, 170],
      10: [170, 215, 260, 260, 260, 260, 260, 260, 215, 170],
      11: [170, 215, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      12: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      13: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      14: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      15: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
    };
  }

  const spacing = cableSpacing[cableQtd].map((t) => 380 - t);

  return (
    (cableSpacing[cableQtd][cableIndex] / sensorsInCableQtd) *
        (sensorsInCableQtd - index) +
      spacing[cableIndex]
  );
}

export function heatmapPositionY(siloType, index, sensorsInCableQtd, cableQtd, cableIndex) {
  /* ***********************************************************************
    Esta função referencia em pixels o distânciamento entre focos de calor
    para o eixo Y
  *********************************************************************** */
  // console.log('calcTempPositionY - cableQtd: ', cableQtd);
  let cableSpacing;

  if (!siloType) { // caso não tenha sido passado o tipo de fundo
    siloType = 'plainBunker'; // seta um tipo de fundo padrão
  }

  if ((siloType === 'plainBunker')) {
    cableSpacing = {
      4: [170, 215, 215, 170],
      5: [170, 215, 260, 215, 170],
      6: [170, 215, 260, 260, 215, 170],
      7: [170, 215, 260, 260, 260, 215, 170],
      8: [170, 215, 260, 260, 260, 260, 215, 170],
      9: [170, 215, 260, 260, 260, 260, 260, 215, 170],
      10: [44, 58, 68, 83, 97.5, 97.5, 83, 67, 59, 44],
      11: [47, 59, 70, 80, 95, 100, 90, 76, 66, 67, 40],
      12: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      13: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      14: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      15: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],

    };
  }

  if (siloType === 'VBunker') {
    cableSpacing = {
      4: [370, 215, 215, 170],
      5: [270, 215, 260, 215, 170],
      6: [46, 80, 110, 106, 77, 43.5], // Esse daqui por exemplo pode ser testado com o Bunker QA
      7: [170, 215, 260, 260, 260, 215, 170],
      8: [48, 80, 110, 120, 120, 91, 68, 40],
      9: [170, 215, 260, 260, 260, 260, 260, 215, 170],
      10: [170, 215, 260, 260, 260, 260, 260, 260, 215, 170],
      11: [170, 215, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      12: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      13: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      14: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
      15: [170, 215, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 260, 215, 170],
    };
  }

  if (cableQtd < 16) {
    const spacing = cableSpacing[cableQtd].map((t) => 380 - t);

    return (
      (cableSpacing[cableQtd][cableIndex] / sensorsInCableQtd) *
          (sensorsInCableQtd - index) +
        spacing[cableIndex]
    );
  }

  return (
    (100 / sensorsInCableQtd) *
          (sensorsInCableQtd - index) +
        280
  );
}

export function startDispositionsHeatMapY(siloType, cableQtd, cableIndex) {
  /* ***********************************************************************
    Esta função referencia em pixels onde irá iniciar a
    disposição do heatmap no eixo Y
  *********************************************************************** */
  const startDistribuition = [];
  startDistribuition.pop();

  if (!siloType) { // caso não tenha sido passado o tipo de fundo
    siloType = 'plainBunker'; // seta um tipo de fundo padrão
  }

  if (siloType === 'plainBunker') {
    return 95;
  }
  if (siloType === 'VBunker') {
    switch (cableQtd) {
      case 4:
        startDistribuition.push(25, 25, 25, 25);
        break;
      case 5:
        startDistribuition.push(25, 25, 25, 25, 25);
        break;
      case 6:
        startDistribuition.push(108, 103, 97, 97, 102, 108.5);
        // Esse daqui por exemplo pode ser testado com o Bunker QA
        break;
      case 7:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25);
        break;
      case 8:
        startDistribuition.push(107, 103, 97, 96, 96, 99, 103, 108.5);
        break;
      case 9:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 10:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 11:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 12:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 13:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 14:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      case 15:
        startDistribuition.push(25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25);
        break;
      default:
        return 0;
    }
  }
  return (startDistribuition[cableIndex]);
}
