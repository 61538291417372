/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../App.css';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FockinkAPI from '../../../../middlewares/Api';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import DashesHeader from '../../../../components/DashesHeader';
import BackButton from '../../../../components/Buttons/BackButton';
import UnitDataSelectField from '../../../../components/Fields/UnitDataSelectField';
import { DatePicker } from '../../../../components';
import ThermometryHotSensors from './ThermometryHotSensors';
import ThermometryHotSensorsResistance from './ThermometryHotSensorsResistance';
import { grains } from '../../../../helpers/mocks/grains';
import { GridItem } from '../ThermometryReport/components/GridItem';

const useStyles = makeStyles({
  btnBttn: {
    textAlign: 'right',
    marginTop: '15px',
  },
  root: {
    width: '100%',
    background: '#FFF',
    padding: '1rem',
    borderRadius: '0.2rem',
    minHeight: '5rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default (props) => {
  const classes = useStyles();
  const [searchData, setSearchData] = React.useState({
    date: new Date(),
    units: [],
    equipamentIds: [],
    grain: '--ALL--',
    days: 10,
    searched: false,
  });
  // const [plants, setPlants] = React.useState([]);
  const [equipId, setEquipId] = React.useState(undefined);
  const [loading, setLoading] = React.useState(true);
  const [breadcrumbs, setBreadcrumbs] = React.useState({});
  const url = window.location.search;

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenInt = urlParams.get('equipmentId');
    setEquipId(tokenInt);
  }, []);

  const handleChange = (event) => {
    const newSD = {
      ...searchData,
      searched: false,
      [event.target.name]: event.target.value || event.target.checked,
    };
    setSearchData(newSD);
  };

  const callApi = () => {
    setLoading(true);
    FockinkAPI.get(`/dashboards/thermometry/${equipId}/strategy`).then((response) => {
      setBreadcrumbs({
        title: 'Informe de Qualidade',
        routes: [
          { name: 'Equipamentos', to: '/equipamentData' },
          {
            name: response.data.data.description,
            to: `/thermometryDash/${equipId}`,
          },
          { name: 'Informe de Qualidade' },
        ],
      });
      setLoading(false);
    });
  };

  const history = useHistory();

  React.useEffect(() => {
    if (equipId) {
      callApi();
    } else {
      setBreadcrumbs({
        title: 'Informe de Qualidade',
        routes: [{ name: 'Relatórios', to: '/reports' }, { name: 'Informe de Qualidade' }],
        secondRoute: [
          { name: 'Clientes', to: '/clientsAdm/' },
          { name: 'Relatórios', to: `/reportsAdm${url}&op=all` },
          { name: 'Informe de Qualidade' }],
      });
      setLoading(false);
      // callApiPlants();
    }
  }, [props.match.params.id, equipId]);

  if (loading) {
    return (
      <>
        <Breadcrumbs loading={loading} />
        <DashesHeader loading={loading} />
        <Grid container spacing={2} style={{ minHeight: 'calc(100vh - 101px)' }}>
          {[0, 1].map((k) => (
            <Grid key={k} item xs={12} md={12} lg={6}>
              <Skeleton variant="rect" height={500} width="100%" animation="wave" />
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  const handleDateChange = (selectedDate) => {
    setSearchData({ ...searchData, searched: false, date: selectedDate });
  };

  return (
    <>
      <Breadcrumbs routes={localStorage.profile === 'admin' && url ? breadcrumbs.secondRoute : breadcrumbs.routes} title={breadcrumbs.title} />

      <Grid
        container
        spacing={1}
        style={{ minHeight: 'calc(100vh - 101px)' }}
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <div className={classes.root}>
          <Grid
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            container
            direction="row"
          >
            <GridItem>
              <DatePicker selectedDate={searchData.date} handleDateChange={handleDateChange} />
            </GridItem>

            <GridItem>
              <UnitDataSelectField
                selectedUnits={searchData.units}
                setSelectedUnits={(p) => {
                  setSearchData({
                    ...searchData,
                    units: p.units,
                    equipamentIds: p.equipamentIds,
                    searched: false,
                  });
                }}
                systemFilter={2}
              />
            </GridItem>

            <GridItem>
              <FormControl variant="filled">
                <InputLabel>Grão</InputLabel>
                <Select value={searchData.grain} onChange={handleChange} name="grain">
                  <MenuItem value="--ALL--">Todas</MenuItem>
                  {grains.map((m) => (
                    <MenuItem key={m} value={m}>
                      {m}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl variant="filled">
                <InputLabel>Dias de Resistência</InputLabel>
                <Select value={searchData.days} onChange={handleChange} name="days">
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="10">10</MenuItem>
                </Select>
              </FormControl>
            </GridItem>

            <GridItem>
              <Button
                onClick={() => setSearchData({ ...searchData, searched: true })}
                variant="contained"
                color="primary"
                fullWidth
              >
                Gerar Relatório
              </Button>
            </GridItem>
          </Grid>
        </div>

        {searchData.searched && (
          <>
            <Grid item xs={12}>
              <ThermometryHotSensors searchData={searchData} />
            </Grid>
            <Grid item xs={12}>
              <ThermometryHotSensorsResistance searchData={searchData} />
            </Grid>
          </>
        )}
        <Grid item xs={12} className={classes.btnBttn}>
          <BackButton back={() => history.goBack()} className={classes.btnSpace} />
        </Grid>
      </Grid>
    </>
  );
};
