import React from 'react';
import * as S from './styles';

export function CardInfo({ positive, description, children }) {
  return (
    <S.Wrapper positive={positive}>
      {children}
      <span className="description">{description}</span>
    </S.Wrapper>
  );
}
