/* eslint-disable  */
import * as BABYLON from 'babylonjs'
import * as MyEarcut from 'earcut'
import * as GUI from 'babylonjs-gui'

// import { theme } from '../../styles/themes/theme'
import  theme  from './theme'

import { StatusOnOffMotor, LabelNameMotor } from './CriaMotor'

const _ = require('lodash')

let showInfo = false
let arcSee = []
let showPanelMotor3D = false

export const CleanPanelArmazemPlano = () => {
  showPanelMotor3D = false
}

export const CleanVarsArmaPlano = () => {
  showInfo = false
  arcSee = []
}

export const CriaArmazemArredondadoPlano = (
  scene,
  layout,
  termoReport,
  colorConfig,
  cod,
  nomeUnidade,
  comprimento,
  largura,
  alturaParede,
  tipoAgua,
  tipoFundo,
  alturaFundo,
  reducaoCabeceira,
  anguloUnidade,
  posX,
  posZ,
  motores,
  fncChangeViewInfo,
  objetoCamadaProduto,
  unidadesObservaveis,
  setUnidadesObservaveis,
  produto_visivel,
  handleOnSetObjetoCamadaProduto,
  produto_info,
  fncChangeViewLevel,
  subareaSelected,
  grao,
  fchChangeViewStaircase,
  escada_visivel,
  fcnSeeTooltip,
  colorMotorStatus3D1,
  colorMotorStatus3D2,
  colorMotorStatus3D3,
  colorMotorStatus3D4,
  colorMotorStatus3D5,
  colorMotorStatus3D1Shaded,
  colorMotorStatus3D2Shaded,
  colorMotorStatus3D3Shaded,
  colorMotorStatus3D4Shaded,
  colorMotorStatus3D5Shaded,
  fcnSeeTooltipMotor,
  permissaoParaEditarOEstadoDosMotores,
  painelEditaMotoresEmUso,
  motor3D,
  baseMotor3D,
  conjuntoMotor3D,
  suporteDoMotor3D
) => {
  const pivoArmazem = new BABYLON.TransformNode(`pivoArmazem${nomeUnidade}`)
  pivoArmazem.unidade = cod
  pivoArmazem.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(anguloUnidade))
  pivoArmazem.position.x = posX
  pivoArmazem.position.z = posZ
  const alturaParedeArmazem = alturaParede
  const compBaseArmazem = comprimento
  const largBaseArmazem = largura

  const anguloTelhadoArmazem = 30
  const alturaTelhadoArmazem =
    (largBaseArmazem / 2) * Math.tan((anguloTelhadoArmazem * Math.PI) / 180)
  const larguraCalcada = 3.5
  const distanciaEntreSensores = 1.5
  const distanciaEntreArcos = 2
  // ---------------------------------------PAREDE----------------------------------------
  // CRIA A TEXTURA DA PAREDE
  const texturaParede = new BABYLON.StandardMaterial(
    `texturaParede${nomeUnidade}`,
    scene
  )
  texturaParede.diffuseTexture = new BABYLON.Texture(
    `${theme.textures.paredeLateral}`,
    scene
  )
  texturaParede.diffuseTexture.uScale = 50
  texturaParede.diffuseTexture.vScale = 1

  const orientationParede = BABYLON.Mesh.BACKSIDE
  const anguloFaces = 15
  const larguraFace = getLarguraFaceDinamico(largBaseArmazem)

  //CRIA CAMINHO DA FACE DA PAREDE SEMI-CIRCULAR
  let caminhoFaceParedeSemiCircular = []
  let alturaFaceParede = 1
  for (let i = 0; i < 2; i++) {
    const x = 0
    const y = largBaseArmazem / 2
    const z = alturaFaceParede
    caminhoFaceParedeSemiCircular.push(new BABYLON.Vector3(x, y, z))

    alturaFaceParede = alturaFaceParede + alturaParedeArmazem
  }

  //ESPECIFICA LARGURA DA FACE DA PAREDE SEMI-CIRCULAR
  const corpoParedeSemiCircular = [
    new BABYLON.Vector3(larguraFace, 0, 0),
    new BABYLON.Vector3(-larguraFace, 0, 0)
  ]

  // REENDERIZA NA TELA A FACE DA PAREDE SEMI-CIRCULAR
  let faceParedeArredondada = BABYLON.MeshBuilder.ExtrudeShape(
    'star',
    {
      cap: BABYLON.Mesh.NO_CAP,
      shape: corpoParedeSemiCircular,
      path: caminhoFaceParedeSemiCircular,
      sideOrientation: orientationParede,
      updatable: true
    },
    scene
  )
  faceParedeArredondada.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(180))
  faceParedeArredondada.rotate(BABYLON.Axis.X, BABYLON.Tools.ToRadians(90))
  faceParedeArredondada.position.x = -compBaseArmazem / 2 + largBaseArmazem / 2
  faceParedeArredondada.position.y = alturaParedeArmazem
  faceParedeArredondada.material = texturaParede
  faceParedeArredondada.isPickable = false
  faceParedeArredondada.parent = pivoArmazem
  // faceParedeArredondada.checkCollisions = true

  // CRIA UM SEMI-CIRCULO COM AS FACES
  for (let i = -180; i < 0; i += anguloFaces) {
    const clonesFaceParede = faceParedeArredondada.clone('clonesFaceParede')
    clonesFaceParede.rotate(BABYLON.Axis.Z, BABYLON.Tools.ToRadians(i))
  }

  // REENDERIZA NA TELA O OUTRO LADO DA FACE DA PAREDE SEMI-CIRCULAR
  let faceParedeArredondada2 = BABYLON.MeshBuilder.ExtrudeShape(
    'star',
    {
      cap: BABYLON.Mesh.NO_CAP,
      shape: corpoParedeSemiCircular,
      path: caminhoFaceParedeSemiCircular,
      sideOrientation: orientationParede,
      updatable: true
    },
    scene
  )
  faceParedeArredondada2.rotate(BABYLON.Axis.X, BABYLON.Tools.ToRadians(90))
  faceParedeArredondada2.position.x = compBaseArmazem / 2 - largBaseArmazem / 2
  faceParedeArredondada2.position.y = alturaParedeArmazem
  faceParedeArredondada2.material = texturaParede
  faceParedeArredondada2.isPickable = false
  faceParedeArredondada2.parent = pivoArmazem

  // CRIA UM SEMI-CIRCULO COM AS FACES
  for (let i = -180; i < 0; i += anguloFaces) {
    const clonesFaceParede2 = faceParedeArredondada2.clone('clonesFaceParede2')
    clonesFaceParede2.rotate(BABYLON.Axis.Z, BABYLON.Tools.ToRadians(i))
  }
  //CRIA CAMINHO DA FACE DA PAREDE MEIO
  let caminhoFaceParedeMeio = []
  let alturaFaceParedeMeio = 1
  for (let i = 0; i < 2; i++) {
    const x = 0
    const y = largBaseArmazem / 2
    const z = alturaFaceParedeMeio
    caminhoFaceParedeMeio.push(new BABYLON.Vector3(x, y, z))

    alturaFaceParedeMeio = alturaFaceParedeMeio + alturaParedeArmazem
  }
  // ESPECIFICA LARGURA DA FACE DA PAREDE DO MEIO DO ARMAZEM
  const larguraFaceMeio = (compBaseArmazem - largBaseArmazem) / 2
  const corpoFitaParedeMeio = [
    new BABYLON.Vector3(larguraFaceMeio, 0, 0),
    new BABYLON.Vector3(-larguraFaceMeio, 0, 0)
  ]

  // CRIA FACE PAREDE MEIO
  let faceParedeMeio = BABYLON.MeshBuilder.ExtrudeShape(
    'star',
    {
      cap: BABYLON.Mesh.NO_CAP,
      shape: corpoFitaParedeMeio,
      path: caminhoFaceParedeMeio,
      sideOrientation: orientationParede,
      updatable: true
    },
    scene
  )
  faceParedeMeio.rotate(BABYLON.Axis.X, BABYLON.Tools.ToRadians(90))
  faceParedeMeio.position.y = alturaParedeArmazem
  faceParedeMeio.material = texturaParede
  faceParedeMeio.isPickable = false
  faceParedeMeio.parent = pivoArmazem

  //CRIA OUTRO LADO DA PAREDE DO MEIO
  const cloneParedeMeio = faceParedeMeio.clone('clonesFitaParedeMeio')
  cloneParedeMeio.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(180))
  cloneParedeMeio.rotate(BABYLON.Axis.X, BABYLON.Tools.ToRadians(180))
  cloneParedeMeio.isPickable = false

  // Telhado =============================
  // CRIA A TEXTURA DO TELHADO
  const texturaTelhado = new BABYLON.StandardMaterial(
    `texturaTelhado${nomeUnidade}`,
    scene
  )
  texturaTelhado.diffuseTexture = new BABYLON.Texture(
    `${theme.textures.texturaTelhado}`,
    scene
  )
  texturaTelhado.diffuseTexture.uScale = 1
  texturaTelhado.diffuseTexture.vScale = 50
  texturaTelhado.sideOrientation = BABYLON.Mesh.DOUBLESIDE

  const texturaTelhadoLateral = new BABYLON.StandardMaterial(
    `texturaTelhadoLateral${nomeUnidade}`,
    scene
  )
  texturaTelhadoLateral.diffuseTexture = new BABYLON.Texture(
    `${theme.textures.texturaTelhadoLateral}`,
    scene
  )
  texturaTelhadoLateral.diffuseTexture.uScale = 25
  texturaTelhadoLateral.diffuseTexture.vScale = 1
  texturaTelhadoLateral.sideOrientation = BABYLON.Mesh.FRONTSIDE
  texturaTelhadoLateral.backFaceCulling = true
  texturaTelhadoLateral.zOffset = -2

  // ---------------------------------------NOVO TELHADO----------------------------------------
  let ajusteTelhado = 7
  const incrementoAlturaDaFita =
    getAjusteDaAlturaTelhadoDinamico(largBaseArmazem)
  const espacoOcupadoDaParteArredondadaSobreTotalDoArmazem =
    getAjusteDaLarguraTelhadoDinamico(largBaseArmazem)
  const naoSeiPqEsseValor = 210
  var alturaTelhadoAngulado = alturaTelhadoArmazem
  const diametroTelhadoSemiEsferico =
    naoSeiPqEsseValor * espacoOcupadoDaParteArredondadaSobreTotalDoArmazem +
    ajusteTelhado

  const ajusteAlturaTelhado = 0.2
  // CRIA TELHADO SEMI ESFERICO
  // FRONTSIDE/ BACKSIDE / DOUBLESIDE
  const orientationTelhado = BABYLON.Mesh.FRONTSIDE
  const anguloFaceTelhadoSemiEsferico = 15
  const quantidadePontosCaminhoDoTelhadoSemiEsferico = 10
  const tamanhoDeCadaPedacoDoCaminho =
    diametroTelhadoSemiEsferico /
    2 /
    quantidadePontosCaminhoDoTelhadoSemiEsferico

  let caminhoFita = []
  let alturaFita = 1
  let comprimentoFita = 0
  for (let i = 0; i < quantidadePontosCaminhoDoTelhadoSemiEsferico; i++) {
    const x = 0
    const y = alturaFita
    const z = comprimentoFita
    caminhoFita.push(new BABYLON.Vector3(x, y, z))

    alturaFita = alturaFita + alturaFita * incrementoAlturaDaFita
    comprimentoFita = comprimentoFita + tamanhoDeCadaPedacoDoCaminho
  }

  const larguraFita = [
    new BABYLON.Vector3(larguraFace, 0, 0),
    new BABYLON.Vector3(-larguraFace, 0, 0)
  ]

  let fitaTelhadoSemiEsferico = BABYLON.MeshBuilder.ExtrudeShape(
    'star',
    {
      cap: BABYLON.Mesh.NO_CAP,
      shape: larguraFita,
      path: caminhoFita,
      sideOrientation: orientationTelhado,
      updatable: true
    },
    scene
  )
  fitaTelhadoSemiEsferico.rotate(BABYLON.Axis.X, BABYLON.Tools.ToRadians(180))
  fitaTelhadoSemiEsferico.material = texturaTelhado
  fitaTelhadoSemiEsferico.position.x =
    -compBaseArmazem / 2 + largBaseArmazem / 2
  fitaTelhadoSemiEsferico.position.y =
    alturaParedeArmazem + alturaTelhadoArmazem + ajusteAlturaTelhado
  fitaTelhadoSemiEsferico.isPickable = false
  fitaTelhadoSemiEsferico.parent = pivoArmazem

  // Cria um semi-circulo com as fitas
  for (let i = -180; i < 0; i += anguloFaceTelhadoSemiEsferico) {
    const clonesFitaTelhadoSemiEsferico = fitaTelhadoSemiEsferico.clone(
      'clonesFitaTelhadoSemiEsferico'
    )
    clonesFitaTelhadoSemiEsferico.rotate(
      BABYLON.Axis.Y,
      BABYLON.Tools.ToRadians(i)
    )
  }

  let fitaTelhadoSemiEsferico2 = BABYLON.MeshBuilder.ExtrudeShape(
    'star',
    {
      cap: BABYLON.Mesh.NO_CAP,
      shape: larguraFita,
      path: caminhoFita,
      sideOrientation: orientationTelhado,
      updatable: true
    },
    scene
  )
  fitaTelhadoSemiEsferico2.rotate(BABYLON.Axis.Z, BABYLON.Tools.ToRadians(180))
  fitaTelhadoSemiEsferico2.material = texturaTelhado
  fitaTelhadoSemiEsferico2.position.x =
    compBaseArmazem / 2 - largBaseArmazem / 2
  fitaTelhadoSemiEsferico2.position.y =
    alturaParedeArmazem + alturaTelhadoArmazem + ajusteAlturaTelhado
  fitaTelhadoSemiEsferico2.isPickable = false
  fitaTelhadoSemiEsferico2.parent = pivoArmazem

  // Cria um semi-circulo com as fitas
  for (let i = -180; i < 0; i += anguloFaceTelhadoSemiEsferico) {
    const clonesFitaTelhadoSemiEsferico2 = fitaTelhadoSemiEsferico2.clone(
      'clonesFitaTelhadoSemiEsferico2'
    )
    clonesFitaTelhadoSemiEsferico2.rotate(
      BABYLON.Axis.Y,
      BABYLON.Tools.ToRadians(i)
    )
  }

  // CRIA MEIO DO ARMAZEM
  const corpoMeioFace = [
    new BABYLON.Vector3(larguraFaceMeio, 0, 0),
    new BABYLON.Vector3(-larguraFaceMeio, 0, 0)
  ]

  let fitaMeio = BABYLON.MeshBuilder.ExtrudeShape(
    'star',
    {
      shape: corpoMeioFace,
      path: caminhoFita,
      sideOrientation: orientationTelhado,
      updatable: true
    },
    scene
  )
  fitaMeio.rotate(BABYLON.Axis.X, BABYLON.Tools.ToRadians(180))
  fitaMeio.material = texturaTelhado
  fitaMeio.isPickable = false
  fitaMeio.position.y =
    alturaParedeArmazem + alturaTelhadoArmazem + ajusteAlturaTelhado

  const cloneFitaMeio = fitaMeio.clone('clonesFitaMeio')
  cloneFitaMeio.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(180))
  cloneFitaMeio.isPickable = false
  // --------------------------------------- FIM NOVO TELHADO----------------------------------------

  // Calçada
  const texturaCalcada = new BABYLON.StandardMaterial(
    `texturaCalcada${nomeUnidade}`,
    scene
  )
  texturaCalcada.diffuseTexture = new BABYLON.Texture(
    `${theme.textures.calcada}`,
    scene
  )
  texturaCalcada.diffuseTexture.uScale = 15
  texturaCalcada.diffuseTexture.vScale = 1
  texturaCalcada.sideOrientation = BABYLON.Mesh.DOUBLESIDE
  texturaCalcada.zOffset = -2

  let pontosCalcada = []
  pontosCalcada.push(new BABYLON.Vector2(0, 0))
  pontosCalcada.push(new BABYLON.Vector2(compBaseArmazem, 0))
  pontosCalcada.push(new BABYLON.Vector2(compBaseArmazem, larguraCalcada))
  pontosCalcada.push(new BABYLON.Vector2(0, larguraCalcada))

  const calcadaArmazem = new BABYLON.PolygonMeshBuilder(
    `calcadaArmazem${nomeUnidade}`,
    pontosCalcada,
    scene,
    MyEarcut
  )
  const calcadaArmazemE = calcadaArmazem.build(null, 0.01)
  calcadaArmazemE.material = texturaCalcada
  calcadaArmazemE.position.x = -(compBaseArmazem / 2)
  calcadaArmazemE.position.y = 0.01
  calcadaArmazemE.position.z = -(largBaseArmazem / 2 + larguraCalcada)
  calcadaArmazemE.isPickable = false
  calcadaArmazemE.checkCollisions = true
  calcadaArmazemE.parent = pivoArmazem

  const calcadaArmazemD = calcadaArmazemE.clone(`calcadaArmazemD${nomeUnidade}`)
  calcadaArmazemD.position.z = largBaseArmazem / 2
  calcadaArmazemD.position.y = 0.01
  calcadaArmazemD.isPickable = false
  calcadaArmazemD.checkCollisions = true
  calcadaArmazemD.parent = pivoArmazem

  const tempLarCalcada = largBaseArmazem + larguraCalcada * 2
  pontosCalcada = []
  pontosCalcada.push(new BABYLON.Vector2(0, 0))
  pontosCalcada.push(new BABYLON.Vector2(tempLarCalcada, 0))
  pontosCalcada.push(new BABYLON.Vector2(tempLarCalcada, larguraCalcada))
  pontosCalcada.push(new BABYLON.Vector2(0, larguraCalcada))

  const calcadaArmazemPontas = new BABYLON.PolygonMeshBuilder(
    `calcadaArmazemPontas${nomeUnidade}`,
    pontosCalcada,
    scene,
    MyEarcut
  )
  const calcadaArmazemPontasE = calcadaArmazemPontas.build(null, 0.01)
  calcadaArmazemPontasE.material = texturaCalcada
  calcadaArmazemPontasE.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(90))
  calcadaArmazemPontasE.position.x = -(compBaseArmazem / 2 + larguraCalcada)
  calcadaArmazemPontasE.position.y = 0.01
  calcadaArmazemPontasE.position.z = tempLarCalcada / 2
  calcadaArmazemPontasE.isPickable = false
  calcadaArmazemPontasE.checkCollisions = true
  calcadaArmazemPontasE.parent = pivoArmazem

  const calcadaArmazemPontasD = calcadaArmazemPontasE.clone(
    `calcadaArmazemPontasD${nomeUnidade}`
  )
  calcadaArmazemPontasD.position.x = compBaseArmazem / 2
  calcadaArmazemPontasD.position.y = 0.01
  calcadaArmazemPontasD.isPickable = false
  calcadaArmazemPontasD.checkCollisions = true
  calcadaArmazemPontasD.parent = pivoArmazem
  // console.log('Cria Armazem - [x] 5');
  // Cria fundo armazém
  const CreateMesh = function (name, vertices, verticesOrder, scene) {
    const mesh = new BABYLON.Mesh(name, scene)
    mesh.verticesOrder = verticesOrder
    const positions = []
    const normals = []
    const indices = []
    for (const i in verticesOrder) {
      positions.push(
        vertices[verticesOrder[i]].x,
        vertices[verticesOrder[i]].y,
        vertices[verticesOrder[i]].z
      )
    }
    _.forEach(vertices, item => {
      normals.push(0, 0, 0)
    })
    for (const x in verticesOrder) {
      indices.push(x)
    }
    BABYLON.VertexData.ComputeNormals(positions, indices, normals)
    mesh.setVerticesData(BABYLON.VertexBuffer.PositionKind, positions, true)
    mesh.setVerticesData(BABYLON.VertexBuffer.NormalKind, normals, true)
    mesh.setIndices(indices)
    return mesh
  }

  const profundidadeFundo = alturaFundo
  const comprimentoFundo = compBaseArmazem
  const larguraFundo = largBaseArmazem
  let reducaoSemiV = 0
  if (tipoFundo === 0) {
    // Tipo V
    reducaoSemiV = largBaseArmazem / 2
  } else {
    // Tipo semi V
    reducaoSemiV = tipoFundo
  }

  const fundoBaseXMin = reducaoCabeceira
  const fundoBaseXMax = comprimentoFundo - reducaoCabeceira
  const fundoBaseZMin = reducaoSemiV
  const fundoBaseZMax = larguraFundo - reducaoSemiV
  const fundoTopoXMin = 0
  const fundoTopoXMax = comprimentoFundo
  const fundoTopoZMin = 0
  const fundoTopoZMax = larguraFundo

  // 1-Criar as vértices do fundo do armazém
  ///const vertices = [ // x, y, z
  ///  new BABYLON.Vector3(fundoBaseXMin, 0, fundoBaseZMin), // 0
  ///  new BABYLON.Vector3(fundoBaseXMax, 0, fundoBaseZMin), // 1
  ///  new BABYLON.Vector3(fundoBaseXMin, 0, fundoBaseZMax), // 2
  ///  new BABYLON.Vector3(fundoBaseXMax, 0, fundoBaseZMax), // 3
  ///
  ///  new BABYLON.Vector3(fundoTopoXMin, 0, fundoTopoZMin), // 4
  ///  new BABYLON.Vector3(fundoTopoXMax, 0, fundoTopoZMin), // 5
  ///  new BABYLON.Vector3(fundoTopoXMin, 0, fundoTopoZMax), // 6
  ///  new BABYLON.Vector3(fundoTopoXMax, 0, fundoTopoZMax), // 7
  ///];
  // Preenche as vértices do fundo
  //const verticesOrder = [
  //  0, 6, 2,
  //  0, 4, 6,
  //  1, 5, 4,
  //  0, 1, 4,
  //  3, 7, 5,
  //  5, 1, 3,
  //  2, 6, 7,
  //  7, 3, 2,
  //  3, 1, 0,
  //  0, 2, 3,
  //];

  const vertices = [
    new BABYLON.Vector3(-compBaseArmazem / 2, 0, -(largBaseArmazem / 2)),
    new BABYLON.Vector3(-compBaseArmazem / 2, 0, largBaseArmazem / 2),
    new BABYLON.Vector3(compBaseArmazem / 2, 0, largBaseArmazem / 2),
    new BABYLON.Vector3(compBaseArmazem / 2, 0, -largBaseArmazem / 2)
  ]

  const texturaFundoArmazem = new BABYLON.StandardMaterial(
    'texturaFundoArmazem',
    scene
  )
  texturaFundoArmazem.diffuseColor = new BABYLON.Color4.FromHexString(
    '#353839FF'
  )
  texturaFundoArmazem.backFaceCulling = false
  texturaFundoArmazem.sideOrientation = BABYLON.Mesh.DOUBLESIDE
  //texturaFundoArmazem.zOffset = -2;
  texturaFundoArmazem.checkReadyOnEveryCall = false

  const fundoArmazem = BABYLON.MeshBuilder.CreatePolygon(
    'polygonfund',
    { shape: vertices, sideOrientation: BABYLON.Mesh.FRONTSIDE },
    scene,
    MyEarcut
  )
  fundoArmazem.isPickable = false
  fundoArmazem.checkCollisions = true
  fundoArmazem.position.y = 0.1
  //fundoArmazem.position.z = -(largBaseArmazem / 2);
  //fundoArmazem.position.x = -compBaseArmazem;
  fundoArmazem.material = texturaFundoArmazem
  // Fundo armazém
  //const fundoArmazem = CreateMesh('fundoArmazem', vertices, scene);
  //fundoArmazem.material = texturaFundoArmazem;
  //fundoArmazem.position.x = -(comprimentoFundo / 2);
  //fundoArmazem.position.z = -(larguraFundo / 2);
  //fundoArmazem.position.y = -profundidadeFundo;
  //fundoArmazem.isPickable = true;
  //fundoArmazem.checkCollisions = true;

  fncUpdateInfoLevelProductArmazem(
    scene,
    compBaseArmazem,
    largBaseArmazem,
    profundidadeFundo,
    alturaParedeArmazem,
    alturaTelhadoAngulado,
    alturaTelhadoArmazem,
    distanciaEntreSensores,
    termoReport,
    colorConfig,
    layout,
    fncChangeViewInfo,
    objetoCamadaProduto,
    produto_info,
    produto_visivel,
    subareaSelected,
    grao,
    fcnSeeTooltip,
    colorMotorStatus3D1,
    colorMotorStatus3D2,
    colorMotorStatus3D3,
    colorMotorStatus3D4,
    colorMotorStatus3D5,
    colorMotorStatus3D1Shaded,
    colorMotorStatus3D2Shaded,
    colorMotorStatus3D3Shaded,
    colorMotorStatus3D4Shaded,
    colorMotorStatus3D5Shaded,
    fcnSeeTooltipMotor,
    permissaoParaEditarOEstadoDosMotores,
    painelEditaMotoresEmUso,
    motor3D,
    baseMotor3D,
    conjuntoMotor3D,
    suporteDoMotor3D,
    distanciaEntreArcos
  )

  const unidadeOnservavel = new BABYLON.Observable()
  unidadeOnservavel.add(value => {
    if (value.produto_visivel !== undefined) {
      fncChangeViewLevel(scene, value.produto_visivel)
    }

    if (value.produto_info !== undefined) {
      fncChangeViewInfo(scene, value.produto_info) // TODO:
    }
    if (value.leitura_nivel_produto !== undefined) {
      // Aqui atualiza o nível quando selecionado uma leitura
      const selectedTermoReport = value.leitura_nivel_produto
      fncUpdateInfoLevelProductArmazem(
        scene,
        compBaseArmazem,
        largBaseArmazem,
        profundidadeFundo,
        alturaParedeArmazem,
        alturaTelhadoAngulado,
        alturaTelhadoArmazem,
        distanciaEntreSensores,
        selectedTermoReport,
        colorConfig,
        layout,
        fncChangeViewInfo,
        objetoCamadaProduto,
        produto_info,
        produto_visivel,
        subareaSelected,
        grao,
        fcnSeeTooltip,
        colorMotorStatus3D1,
        colorMotorStatus3D2,
        colorMotorStatus3D3,
        colorMotorStatus3D4,
        colorMotorStatus3D5,
        colorMotorStatus3D1Shaded,
        colorMotorStatus3D2Shaded,
        colorMotorStatus3D3Shaded,
        colorMotorStatus3D4Shaded,
        colorMotorStatus3D5Shaded,
        fcnSeeTooltipMotor,
        permissaoParaEditarOEstadoDosMotores,
        painelEditaMotoresEmUso,
        motor3D,
        baseMotor3D,
        conjuntoMotor3D,
        suporteDoMotor3D,
        distanciaEntreArcos
      )
    }
  })

  const unitsObserv = []
  unitsObserv.push({ unidade: nomeUnidade, observavel: unidadeOnservavel })
  setUnidadesObservaveis(unitsObserv)

  // Chão
  const comprimentoChao = compBaseArmazem / 2
  const larguraChao = largBaseArmazem / 2

  // const comprimentoChao = 1000;
  // const larguraChao = 1000;
  const distanciaChao = 540 / 2

  // Poligono da superfície
  const forma = [
    new BABYLON.Vector3(-distanciaChao, 0, -distanciaChao),
    new BABYLON.Vector3(-distanciaChao, 0, distanciaChao),
    new BABYLON.Vector3(distanciaChao, 0, distanciaChao),
    new BABYLON.Vector3(distanciaChao, 0, -distanciaChao)
  ]

  // Buraco da superfície
  const buracoChao = []
  buracoChao.push([
    new BABYLON.Vector3(-comprimentoChao, 0, -larguraChao),
    new BABYLON.Vector3(-comprimentoChao, 0, larguraChao),
    new BABYLON.Vector3(comprimentoChao, 0, larguraChao),
    new BABYLON.Vector3(comprimentoChao, 0, -larguraChao)
  ])

  const matChao = new BABYLON.StandardMaterial('matChao', scene)
  matChao.zOffset = 0
  matChao.diffuseTexture = new BABYLON.Texture(
    `${theme.textures.ground}`,
    scene
  )
  matChao.diffuseTexture.uScale = 10
  matChao.diffuseTexture.vScale = 10
  matChao.specularColor = new BABYLON.Color3(0, 0, 0)
  matChao.checkReadyOnEveryCall = false

  const chaoArmazem = BABYLON.MeshBuilder.CreatePolygon(
    'polygon',
    { shape: forma, sideOrientation: BABYLON.Mesh.DOUBLESIDE },
    scene,
    MyEarcut
  )
  chaoArmazem.isPickable = false
  chaoArmazem.checkCollisions = true
  chaoArmazem.material = matChao
}

const fncUpdateInfoLevelProductArmazem = (
  scene,
  compBaseArmazem,
  largBaseArmazem,
  profundidadeFundo,
  alturaParedeArmazem,
  alturaTelhadoAngulado,
  alturaTelhadoArmazem,
  distanciaEntreSensores,
  termoReport,
  colorConfig,
  layout,
  fncChangeViewInfo,
  objetoCamadaProduto,
  produto_info,
  produto_visivel,
  subareaSelected,
  grao,
  fcnSeeTooltip,
  colorMotorStatus3D1,
  colorMotorStatus3D2,
  colorMotorStatus3D3,
  colorMotorStatus3D4,
  colorMotorStatus3D5,
  colorMotorStatus3D1Shaded,
  colorMotorStatus3D2Shaded,
  colorMotorStatus3D3Shaded,
  colorMotorStatus3D4Shaded,
  colorMotorStatus3D5Shaded,
  fcnSeeTooltipMotor,
  permissaoParaEditarOEstadoDosMotores,
  painelEditaMotoresEmUso,
  motor3D,
  baseMotor3D,
  conjuntoMotor3D,
  suporteDoMotor3D,
  distanciaEntreArcos
) => {
  let sensorIdForMat = 0
  // Reverte a ordem dos arcos (padrão antigo/anti-horário)
  const objetoArmazem = layout.arcs.slice().reverse()
  // 2-Criação da superfície
  const superficie = []
  const qtdLinhaLarg = largBaseArmazem
  const distEntreCabos = 1
  let distEntreArcos = 0
  const mediaArco = compBaseArmazem / (objetoArmazem.length + 1)
  let incDistArco = 0

  const totalArcos = objetoArmazem.length * 2 + 3
  // console.log('totalArcos', totalArcos)

  for (let arcoAtual = 0; arcoAtual <= totalArcos; arcoAtual++) {
    superficie.push([])
    if (arcoAtual > 1 && arcoAtual < totalArcos) {
      if (arcoAtual % 2 === 1) {
        distEntreArcos = 1
      } else if (arcoAtual === 2 || arcoAtual === totalArcos - 1) {
        distEntreArcos = mediaArco - 0.5
      } else {
        distEntreArcos = mediaArco - 1
      }
      incDistArco += distEntreArcos
    }
    fncSurfaceArmazem(
      superficie,
      arcoAtual,
      incDistArco,
      qtdLinhaLarg,
      distEntreCabos,
      totalArcos
    ) // TODO:
  }

  // Arcos
  const matCabo = new BABYLON.StandardMaterial('matCabo', scene)
  matCabo.diffuseColor = new BABYLON.Color3(0.1, 0.1, 0.1)
  matCabo.specularColor = new BABYLON.Color3(0, 0, 0)
  matCabo.checkReadyOnEveryCall = false

  const matSensor = new BABYLON.StandardMaterial('matSensor', scene)
  matSensor.specularColor = new BABYLON.Color3(0, 0, 0)

  const matInfoSensor = new BABYLON.StandardMaterial(
    'materialSuperficie',
    scene
  )

  matInfoSensor.specularColor = new BABYLON.Color3(0, 0, 0)
  matInfoSensor.emissiveColor = new BABYLON.Color3(1, 1, 1)
  matInfoSensor.backFaceCulling = false
  matInfoSensor.checkReadyOnEveryCall = false
  const conjuntoArco = new BABYLON.Mesh('conjuntoArco', scene)
  conjuntoArco.position.z = -(largBaseArmazem / 2)
  conjuntoArco.position.y = 0.03 //-(profundidadeFundo);
  conjuntoArco.position.x = -(compBaseArmazem / 2)

  const texturaInfoObjeto = new BABYLON.DynamicTexture(
    'texturaInfoObjeto',
    512,
    scene,
    false
  ) // era TRUE

  var advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI('UI')
  advancedTexture.useInvalidateRectOptimization = false

  const criaInfoLabels = (arc, arcMesh) => {
    const cabos = arcMesh.getChildren()
    //for (let i = 0; i < objetoArmazem.length; i += 1) {
    //  if (arc == i) {

    for (let cab = 0; cab < cabos.length; cab += 1) {
      // Cabos

      const sensorsMesh = cabos[cab].getChildMeshes()
      let countSensor = 0

      const stringNomeDoCabo = cabos[cab].name
      const newStringNomeDoCabo = stringNomeDoCabo.slice(
        10,
        stringNomeDoCabo.length
      )

      for (let sen = 0; sen < sensorsMesh.length; sen++) {
        const nameOfSensor = sensorsMesh[sen].name
        const parsedNameOfSensor = nameOfSensor.split('.')

        // console.log('ParsedNameOfSensor')
        // console.log(parsedNameOfSensor)
        // console.log(parsedNameOfSensor.length)

        if (
          parsedNameOfSensor.length === 1 &&
          parsedNameOfSensor[0].includes('Sensor')
        ) {
          //const objSensor =  //objetoArmazem[i].cables[cab].sensors[sen];
          //if (objSensor) {
          countSensor++
          let tempColor = '#FFFFFFFF'
          //tempColor =  `${objSensor.color_hex}FF`;
          tempColor =
            sensorsMesh[sen].sensor && sensorsMesh[sen].sensor.color_hex
          // console.log('criando 1')
          const objetoSensor = sensorsMesh[sen]
          const objetoDeTrasicaoCabo = { cab: newStringNomeDoCabo }

          // let actualRect = criaTooltip(advancedTexture, objetoSensor, arc, objetoDeTrasicaoCabo, sen - 1, sensorsMesh[sen].sensor, 1)
          NomeObjeto(
            arcMesh.name,
            scene,
            tempColor,
            [{ val: `${sensorsMesh[sen].sensor.temperature}`, tam: 220 }],
            objetoSensor,
            0,
            1.4,
            1,
            1,
            fcnSeeTooltip
          )
          //}
        }
      }
    }
  }
  // console.log('Hora ==> ' + new Date())
  const objetoSensorEx = new BABYLON.MeshBuilder.CreateSphere(
    `objetoSensor0`,
    {
      diameter: 0.5,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
      updatable: false
    },
    scene
  )

  const criaTooltip = (
    advancedTexture,
    objetoSensor,
    arc,
    objCabo,
    sen,
    objSensor,
    type
  ) => {
    let rect1 = new GUI.Rectangle()
    advancedTexture.addControl(rect1)
    rect1.width = '100px'
    rect1.height = '100px'
    rect1.thickness = 1
    rect1.linkOffsetX = '50px'
    rect1.linkOffsetY = '-50px'
    rect1.transformCenterX = 0
    rect1.transformCenterY = 1
    rect1.background = 'grey'
    rect1.alpha = 0.9
    rect1.scaleX = 0
    rect1.scaleY = 0

    rect1.linkWithMesh(objetoSensor)

    let text1 = new GUI.TextBlock()

    if (type === 0) {
      text1.text = `ANEL: ${objetoArmazem.length - arc}\nCABO: ${
        objCabo.cab
      }\nSENSOR: ${sen + 1}\nTemp:${
        objSensor.t === 999
          ? 'OFF'
          : objSensor.t === 998
          ? 'N/D'
          : objSensor.t === 997
          ? 'ERR'
          : `${objSensor.t}ºC`
      }`
    } else {
      text1.text = `ANEL: ${objetoArmazem.length - arc}\nCABO: ${
        objCabo.cab
      }\nSENSOR: ${sen + 1}\nTemp:${
        objSensor.temperature === 'OFF'
          ? 'OFF'
          : objSensor.temperature === 'N/D'
          ? 'N/D'
          : objSensor.temperature === 'ERR'
          ? 'ERR'
          : `${objSensor.temperature}°C`
      }`
    }

    text1.color = 'White'
    text1.fontSize = 14
    text1.textWrapping = true
    text1.textVerticalAlignment = GUI.Control.VERTICAL_ALIGNMENT_TOP
    text1.background = '#006994'
    rect1.addControl(text1)
    text1.alpha = 1 / text1.parent.alpha
    text1.paddingTop = '10px'

    // let scaleXAnimation = new BABYLON.Animation("myAnimation", "scaleX", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);
    // let scaleYAnimation = new BABYLON.Animation("myAnimation", "scaleY", 60, BABYLON.Animation.ANIMATIONTYPE_FLOAT, BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT);

    // var keys = [];

    // keys.push({
    //   frame: 0,
    //   value: 0
    // });
    // keys.push({
    //   frame: 10,
    //   value: 1
    // });

    // scaleXAnimation.setKeys(keys);
    // scaleYAnimation.setKeys(keys);
    // rect1.animations = [];
    // rect1.animations.push(scaleXAnimation);
    // rect1.animations.push(scaleYAnimation);

    return rect1
  }

  const criaTooltip2 = (
    nome,
    scene,
    corFundo,
    id,
    parent,
    altura,
    tamanho,
    x = 0,
    z = -3
  ) => {
    const toolTip = BABYLON.Mesh.CreatePlane(
      `toolTip${nome}`,
      tamanho,
      scene,
      false
    )

    // console.log('PARENT')
    // console.log(parent.sensor)

    // BORDA
    toolTip.enableEdgesRendering()
    toolTip.edgesWidth = 4.0
    toolTip.edgesColor = corFundo
    //
    toolTip.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL
    if (parent.sensor.sensor_id <= 6) {
      toolTip.position.x = 3
      toolTip.position.z = 1
      toolTip.position.y = 3
    } else {
      toolTip.position.x = 3
      toolTip.position.z = 1
      toolTip.position.y = -3
    }
    toolTip.parent = parent
    const texturaTooltip = new BABYLON.DynamicTexture(
      'texturaTooltip',
      { width: 600, height: 560 },
      scene
    )
    const fontSize = 100

    let label1 = `ARCO: ${parent.sensor.arc_index}`
    let label2 = `CABO: ${parent.sensor.cable_number}`
    let label3 = `SENSOR: ${parent.sensor.sensor_id}`
    let label4 = `Temp:${
      parent.sensor.temperature === 999 || parent.sensor.temperature === 'OFF'
        ? 'OFF'
        : parent.sensor.temperature === 998 ||
          parent.sensor.temperature === 'N/D'
        ? 'N/D'
        : parent.sensor.temperature === 997 ||
          parent.sensor.temperature === 'ERR'
        ? 'ERR'
        : `${parent.sensor.temperature}ºC`
    }`

    const font = `bold ${fontSize}px arial`

    texturaTooltip.drawText(
      label1,
      null,
      100,
      font,
      'white',
      corFundo,
      true,
      true
    )
    texturaTooltip.drawText(label2, null, 220, font, 'white', null, true, true)
    texturaTooltip.drawText(label3, null, 340, font, 'white', null, true, true)
    texturaTooltip.drawText(label4, null, 460, font, 'white', null, true, true)
    toolTip.material = new BABYLON.StandardMaterial('materialSuperficie', scene)
    toolTip.material.diffuseTexture = texturaTooltip
    toolTip.material.specularColor = new BABYLON.Color3(0, 0, 0)
    toolTip.material.emissiveColor = new BABYLON.Color3(1, 1, 1)
    toolTip.material.backFaceCulling = false
    toolTip.isPickable = true
    texturaTooltip.hasAlpha = true
    toolTip.material.freeze()

    // toolTip.actionManager = new BABYLON.ActionManager(scene);

    // toolTip.actionManager.registerAction(
    //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function (ev) {
    //     scene.beginAnimation(actualRect, 0, 10, false)
    //   }));

    // toolTip.actionManager.registerAction(
    //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, function (ev) {
    //     scene.beginAnimation(actualRect, 10, 0, false)
    //   }));

    return toolTip
  }

  // 3-Desenha o arco, cabo e sensor
  const proporcaoArco = compBaseArmazem / (objetoArmazem.length + 1)
  const proporcaoLargB = largBaseArmazem / 7
  const radLargBase = BABYLON.Tools.ToRadians(180 / largBaseArmazem)
  //const objetoArcoEx = new BABYLON.Mesh(`objetoArco0`, scene);

  for (let arc = 0; arc < objetoArmazem.length; arc += 1) {
    // console.log('objetoArmazem', objetoArmazem)
    // console.log('Arco ==> ' + arc)
    //var objetoArco
    //if (arc === 0) {
    //  objetoArco = objetoArcoEx
    //} else {
    //  objetoArco = objetoArcoEx.clone(`objetoArco${arc}`);
    //}
    const objetoArco = new BABYLON.Mesh(`objetoArco${arc}`, scene)

    objetoArco.parent = conjuntoArco

    objetoArco.position.x = proporcaoArco * (arc + 1)

    const objArco = objetoArmazem[arc]
    //const maxDepthCableArc = maxDepthCableArcCalc(objArco); // Quando possuir profundidade no armazém, considera o real nível

    for (let cab = 0; cab < objArco.cables.length; cab += 1) {
      //const alturaCaboTeto = ((alturaTelhadoArmazem) * (Math.sin(BABYLON.Tools.ToRadians(180 / (largBaseArmazem)) * ((cab + 1) * (largBaseArmazem / (objArco.cables.length + 1))))));
      // console.log('objArco', objArco.cables.length)
      // function myLoop() {
      //   //  create a loop function
      //   setTimeout(function () {

      let myPosZ =
        (objArco.cables[cab].positionZ * largBaseArmazem) / 192 - proporcaoLargB

      let alturaCaboTeto

      switch (arc) {
        case 0:
          alturaCaboTeto =
            alturaTelhadoArmazem * Math.sin(radLargBase * myPosZ) - 20
          break
        case 1:
          alturaCaboTeto =
            alturaTelhadoArmazem * Math.sin(radLargBase * myPosZ) - 10
          break
        case 2:
          alturaCaboTeto =
            alturaTelhadoArmazem * Math.sin(radLargBase * myPosZ) - 6
          break
        case objetoArmazem.length - 1:
          alturaCaboTeto =
            alturaTelhadoArmazem * Math.sin(radLargBase * myPosZ) - 20
          break
        case objetoArmazem.length - 2:
          alturaCaboTeto =
            alturaTelhadoArmazem * Math.sin(radLargBase * myPosZ) - 10
          break
        case objetoArmazem.length - 3:
          alturaCaboTeto =
            alturaTelhadoArmazem * Math.sin(radLargBase * myPosZ) - 6
          break
        default:
          alturaCaboTeto =
            alturaTelhadoArmazem * Math.sin(radLargBase * myPosZ) - 2
      }

      const alturaCabo = alturaParedeArmazem + 0.03 + alturaCaboTeto
      const objCabo = objArco.cables[cab]

      const caminhoTubo = [
        new BABYLON.Vector3(0, 0.01, 0.0),
        new BABYLON.Vector3(0, alturaCabo, 0.0)
      ]

      const objetoCabo = BABYLON.MeshBuilder.CreateTube(
        `objetoCabo${objArco.cables[cab].cab}`,
        {
          path: caminhoTubo,
          radius: 0.05,
          sideOrientation: BABYLON.Mesh.DOUBLESIDE,
          updatable: false
        },
        scene
      )
      objetoCabo.material = matCabo
      // Calcula position z 🌵
      // 255 é o valor maximo do padrao
      // 28 - 220  (35)

      //objetoCabo.position.z = (((objArco.cables[cab].position_z * (largBaseArmazem)) - 28) / 225);
      objetoCabo.position.z =
        (objArco.cables[cab].positionZ * largBaseArmazem) / 192 - proporcaoLargB

      // if (arc === 0 || arc === objetoArmazem.length - 1) {
      //   objCabo.haveMotor = 1;
      //   objCabo.info = [{
      //     id: 106,
      //     nameMotor: 'Motor 106',
      //     operation: 'M',
      //     onOff: 'L',
      //     panelMotorActive: false,
      //     status: 8,
      //     aeration: "09:59"
      //   }, {
      //     id: 107,
      //     nameMotor: 'Motor 107',
      //     operation: 'M',
      //     onOff: 'L',
      //     panelMotorActive: false,
      //     status: 8,
      //     aeration: "09:59"
      //   }]
      // } else {
      //   if (cab === 0 || cab === objArco.cables.length - 1) {
      //     objCabo.haveMotor = 1;
      //     objCabo.info = [{
      //       id: 106,
      //       nameMotor: 'Motor 106',
      //       operation: 'M',
      //       onOff: 'L',
      //       panelMotorActive: false,
      //       status: 8,
      //       aeration: "09:59"
      //     }, {
      //       id: 107,
      //       nameMotor: 'Motor 107',
      //       operation: 'M',
      //       onOff: 'L',
      //       panelMotorActive: false,
      //       status: 8,
      //       aeration: "09:59"
      //     }]
      //   }

      //   if (arc % 2 === 0) {
      //     if (cab === 1) {
      //       objCabo.haveMotor = 1;
      //       objCabo.info = [{
      //         id: 106,
      //         nameMotor: 'Motor 106',
      //         operation: 'M',
      //         onOff: 'L',
      //         panelMotorActive: false,
      //         status: 8,
      //         aeration: "09:59"
      //       }, {
      //         id: 107,
      //         nameMotor: 'Motor 107',
      //         operation: 'M',
      //         onOff: 'L',
      //         panelMotorActive: false,
      //         status: 8,
      //         aeration: "09:59"
      //       }]
      //     }
      //   } else {
      //     if (cab === objArco.cables.length - 2) {
      //       objCabo.haveMotor = 1;
      //       objCabo.info = [{
      //         id: 106,
      //         nameMotor: 'Motor 106',
      //         operation: 'M',
      //         onOff: 'L',
      //         panelMotorActive: false,
      //         status: 8,
      //         aeration: "09:59"
      //       }, {
      //         id: 107,
      //         nameMotor: 'Motor 107',
      //         operation: 'M',
      //         onOff: 'L',
      //         panelMotorActive: false,
      //         status: 8,
      //         aeration: "09:59"
      //       }]
      //     }
      //   }
      // }

      if (objCabo.info !== null) {
        if (objCabo.info) {
          let camada = 0
          for (let i = 0; i < objCabo.info.length; i++) {
            const id = objCabo.info[i].idMotor
            // console.log(`vez = ${i + 1}`)
            const auxRererenciaDoArco = arc + 1
            const auxPositionX = auxRererenciaDoArco * distEntreArcos

            // console.log('PROPS FOR GENERATE POSITION')
            // console.log(`arc == ${arc}`)
            // console.log(`auxRererenciaDoArco == ${auxRererenciaDoArco}`)
            // console.log(`auxPositionX == ${auxPositionX}`)

            if (auxPositionX < compBaseArmazem / 2) {
              // console.log('menor')
              if (cab > 0 && cab < objArco.cables.length - 1) {
                if (arc === 0) {
                  // CLONA E CRIA BASE
                  const positionZ = calculaPositionZDoMotor(
                    objArco.cables[cab].positionZ,
                    largBaseArmazem,
                    proporcaoLargB
                  )
                  const base = baseMotor3D.clone(`base${id}`)
                  // base.parent = objetoCabo;
                  base.position.z =
                    positionZ > largBaseArmazem / 2
                      ? positionZ - largBaseArmazem / 2
                      : -(largBaseArmazem / 2 - positionZ)
                  base.position.x = -(compBaseArmazem / 2 + 2)
                  base.position.y = 0
                  base.visibility = 1
                  base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(270))

                  // CRIA SUPORTE DO MOTOR
                  const suporteDoMotor = suporteDoMotor3D.clone(`suporte${id}`)
                  suporteDoMotor.parent = base
                  suporteDoMotor.visibility = 1
                  const suportePlacaNome = suporteDoMotor3D.clone(
                    `suportePlacaNome${id}`
                  )
                  suportePlacaNome.parent = base
                  suportePlacaNome.position.x = 0
                  suportePlacaNome.position.z = 2.2
                  suportePlacaNome.visibility = 0

                  const suportePlacaStatus = suporteDoMotor3D.clone(
                    `suportePlacaStatus${id}`
                  )
                  suportePlacaStatus.parent = base
                  suportePlacaStatus.position.x = 0
                  suportePlacaStatus.position.y = 1
                  suportePlacaStatus.position.z = 2
                  suportePlacaStatus.visibility = 0

                  // CLONA E CRIA MOTOR
                  const motor = motor3D.clone(`motor${id}`)
                  if (motor) {
                    motor.parent = base
                    motor.info = objCabo.info[i]
                    motor.visibility = 1

                    if (
                      motor.info.status === 1 ||
                      motor.info.status === 2 ||
                      motor.info.status === 10
                    ) {
                      motor.material = colorMotorStatus3D3Shaded
                    } else if (
                      motor.info.status === 3 ||
                      motor.info.status === 4
                    ) {
                      motor.material = colorMotorStatus3D2Shaded
                    } else if (
                      motor.info.status === 5 ||
                      motor.info.status === 6
                    ) {
                      motor.material = colorMotorStatus3D4Shaded
                    } else if (
                      motor.info.status === 7 ||
                      motor.info.status === 8 ||
                      motor.info.status === 9
                    ) {
                      motor.material = colorMotorStatus3D1Shaded
                    }

                    motor.actionManager = new BABYLON.ActionManager(scene)

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }

                  if (base) {
                    LabelNameMotor(
                      scene,
                      motor.info.nameMotor,
                      1.5,
                      suportePlacaNome
                    )
                  }

                  if (motor && motor.info) {
                    // CRIA CONJUNTO MOTOR
                    const conjuntoMotor = conjuntoMotor3D.clone(
                      `conjuntoMotor3D${id}`
                    )
                    conjuntoMotor.info = motor.info
                    conjuntoMotor.parent = base
                    conjuntoMotor.visibility = 1

                    const placaStatusOnOffMotor = StatusOnOffMotor(
                      scene,
                      suportePlacaStatus,
                      0.6,
                      conjuntoMotor.info.operation,
                      conjuntoMotor.info.idMotor
                    )
                    // console.log(placaStatusOnOffMotor)

                    if (
                      conjuntoMotor.info.status === 1 ||
                      conjuntoMotor.info.status === 2 ||
                      conjuntoMotor.info.status === 10
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D3
                    } else if (
                      conjuntoMotor.info.status === 3 ||
                      conjuntoMotor.info.status === 4
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D2
                    } else if (
                      conjuntoMotor.info.status === 5 ||
                      conjuntoMotor.info.status === 6
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D4
                    } else if (
                      conjuntoMotor.info.status === 7 ||
                      conjuntoMotor.info.status === 8 ||
                      conjuntoMotor.info.status === 9
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D1
                    }

                    conjuntoMotor.actionManager = new BABYLON.ActionManager(
                      scene
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }
                } else {
                  // console.log('arco não é igual a zero - menor')
                  // console.log(objArco.cables)

                  const positionZ = calculaPositionZDoMotor(
                    objArco.cables[cab].positionZ,
                    largBaseArmazem,
                    proporcaoLargB
                  )

                  const referenciaDoArco = arc + 1
                  const positionX = referenciaDoArco * (distEntreArcos + 0.5)
                  const finalPositionX = -(compBaseArmazem / 2 - positionX)

                  if (positionZ) {
                    // console.log(`é maior | cab = ${cab} = ${positionZ}`)
                    const numberOfMotors =
                      objArco.cables[objArco.cables.length - 1].info

                    // CLONA E CRIA BASE
                    const base = baseMotor3D.clone(`base${id}`)
                    // base.parent = objetoCabo;

                    if (numberOfMotors) {
                      if (camada > 0) {
                        base.position.z =
                          (largBaseArmazem + 5) / 2 +
                          (numberOfMotors.length * 6 + 1.5) +
                          camada * 6
                      } else {
                        base.position.z =
                          (largBaseArmazem + 5) / 2 +
                          (numberOfMotors.length * 6 + 1.5)
                      }
                    } else {
                      if (camada > 0) {
                        base.position.z = base.position.z =
                          (largBaseArmazem + 5) / 2 + camada * 6
                      } else {
                        base.position.z = base.position.z =
                          (largBaseArmazem + 5) / 2
                      }
                    }
                    base.position.x = finalPositionX
                    base.position.y = 0
                    base.visibility = 1
                    // base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(180));

                    // CRIA SUPORTE DO MOTOR
                    const suporteDoMotor = suporteDoMotor3D.clone(
                      `suporte${id}`
                    )
                    suporteDoMotor.parent = base
                    suporteDoMotor.visibility = 1
                    const suportePlacaNome = suporteDoMotor3D.clone(
                      `suportePlacaNome${id}`
                    )
                    suportePlacaNome.parent = base
                    suportePlacaNome.position.x = 0
                    suportePlacaNome.position.z = 2.2
                    suportePlacaNome.visibility = 0

                    const suportePlacaStatus = suporteDoMotor3D.clone(
                      `suportePlacaStatus${id}`
                    )
                    suportePlacaStatus.parent = base
                    suportePlacaStatus.position.x = 0
                    suportePlacaStatus.position.y = 1
                    suportePlacaStatus.position.z = 2
                    suportePlacaStatus.visibility = 0

                    // CLONA E CRIA MOTOR
                    const motor = motor3D.clone(`motor${id}`)
                    if (motor) {
                      motor.parent = base
                      motor.info = objCabo.info[i]
                      motor.visibility = 1

                      if (
                        motor.info.status === 1 ||
                        motor.info.status === 2 ||
                        motor.info.status === 10
                      ) {
                        motor.material = colorMotorStatus3D3Shaded
                      } else if (
                        motor.info.status === 3 ||
                        motor.info.status === 4
                      ) {
                        motor.material = colorMotorStatus3D2Shaded
                      } else if (
                        motor.info.status === 5 ||
                        motor.info.status === 6
                      ) {
                        motor.material = colorMotorStatus3D4Shaded
                      } else if (
                        motor.info.status === 7 ||
                        motor.info.status === 8 ||
                        motor.info.status === 9
                      ) {
                        motor.material = colorMotorStatus3D1Shaded
                      }

                      motor.actionManager = new BABYLON.ActionManager(scene)

                      motor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOverTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  ev.meshUnderPointer.info,
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )

                      motor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOutTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  'remove',
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )
                    }

                    if (base) {
                      LabelNameMotor(
                        scene,
                        motor.info.nameMotor,
                        1.5,
                        suportePlacaNome
                      )
                    }

                    if (motor && motor.info) {
                      // CRIA CONJUNTO MOTO
                      const conjuntoMotor = conjuntoMotor3D.clone(
                        `conjuntoMotor3D${id}`
                      )
                      conjuntoMotor.info = motor.info
                      conjuntoMotor.parent = base
                      conjuntoMotor.visibility = 1

                      const placaStatusOnOffMotor = StatusOnOffMotor(
                        scene,
                        suportePlacaStatus,
                        0.6,
                        conjuntoMotor.info.operation,
                        conjuntoMotor.info.idMotor
                      )
                      // console.log(placaStatusOnOffMotor)

                      if (
                        conjuntoMotor.info.status === 1 ||
                        conjuntoMotor.info.status === 2 ||
                        conjuntoMotor.info.status === 10
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D3
                      } else if (
                        conjuntoMotor.info.status === 3 ||
                        conjuntoMotor.info.status === 4
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D2
                      } else if (
                        conjuntoMotor.info.status === 5 ||
                        conjuntoMotor.info.status === 6
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D4
                      } else if (
                        conjuntoMotor.info.status === 7 ||
                        conjuntoMotor.info.status === 8 ||
                        conjuntoMotor.info.status === 9
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D1
                      }

                      conjuntoMotor.actionManager = new BABYLON.ActionManager(
                        scene
                      )

                      conjuntoMotor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOverTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  ev.meshUnderPointer.info,
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )

                      conjuntoMotor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOutTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  'remove',
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )
                    }
                  }
                }
              } else {
                const referenciaDoArco = arc + 1
                const positionX = referenciaDoArco * (distEntreArcos + 0.5)
                const finalPositionX = -(compBaseArmazem / 2 - positionX)
                if (cab === objArco.cables.length - 1) {
                  // CLONA E CRIA BASE
                  const base = baseMotor3D.clone(`base${id}`)
                  // base.parent = objetoCabo;
                  if (camada > 0) {
                    base.position.z = (largBaseArmazem + 5) / 2 + camada * 6
                  } else {
                    base.position.z = (largBaseArmazem + 5) / 2
                  }

                  base.position.x = finalPositionX
                  base.position.y = 0
                  base.visibility = 1
                  // base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(-180));

                  // CRIA SUPORTE DO MOTOR
                  const suporteDoMotor = suporteDoMotor3D.clone(`suporte${id}`)
                  suporteDoMotor.parent = base
                  suporteDoMotor.visibility = 1
                  const suportePlacaNome = suporteDoMotor3D.clone(
                    `suportePlacaNome${id}`
                  )
                  suportePlacaNome.parent = base
                  suportePlacaNome.position.x = 0
                  suportePlacaNome.position.z = 2.2
                  suportePlacaNome.visibility = 0

                  const suportePlacaStatus = suporteDoMotor3D.clone(
                    `suportePlacaStatus${id}`
                  )
                  suportePlacaStatus.parent = base
                  suportePlacaStatus.position.x = 0
                  suportePlacaStatus.position.y = 1
                  suportePlacaStatus.position.z = 2
                  suportePlacaStatus.visibility = 0

                  // CLONA E CRIA MOTOR
                  const motor = motor3D.clone(`motor${id}`)
                  if (motor) {
                    motor.parent = base
                    motor.info = objCabo.info[i]
                    motor.visibility = 1

                    if (
                      motor.info.status === 1 ||
                      motor.info.status === 2 ||
                      motor.info.status === 10
                    ) {
                      motor.material = colorMotorStatus3D3Shaded
                    } else if (
                      motor.info.status === 3 ||
                      motor.info.status === 4
                    ) {
                      motor.material = colorMotorStatus3D2Shaded
                    } else if (
                      motor.info.status === 5 ||
                      motor.info.status === 6
                    ) {
                      motor.material = colorMotorStatus3D4Shaded
                    } else if (
                      motor.info.status === 7 ||
                      motor.info.status === 8 ||
                      motor.info.status === 9
                    ) {
                      motor.material = colorMotorStatus3D1Shaded
                    }

                    motor.actionManager = new BABYLON.ActionManager(scene)

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }

                  if (base) {
                    LabelNameMotor(
                      scene,
                      motor.info.nameMotor,
                      1.5,
                      suportePlacaNome
                    )
                  }

                  if (motor && motor.info) {
                    // CRIA CONJUNTO MOTO
                    const conjuntoMotor = conjuntoMotor3D.clone(
                      `conjuntoMotor3D${id}`
                    )
                    conjuntoMotor.info = motor.info
                    conjuntoMotor.parent = base
                    conjuntoMotor.visibility = 1

                    const placaStatusOnOffMotor = StatusOnOffMotor(
                      scene,
                      suportePlacaStatus,
                      0.6,
                      conjuntoMotor.info.operation,
                      conjuntoMotor.info.idMotor
                    )
                    // console.log(placaStatusOnOffMotor)

                    if (
                      conjuntoMotor.info.status === 1 ||
                      conjuntoMotor.info.status === 2 ||
                      conjuntoMotor.info.status === 10
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D3
                    } else if (
                      conjuntoMotor.info.status === 3 ||
                      conjuntoMotor.info.status === 4
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D2
                    } else if (
                      conjuntoMotor.info.status === 5 ||
                      conjuntoMotor.info.status === 6
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D4
                    } else if (
                      conjuntoMotor.info.status === 7 ||
                      conjuntoMotor.info.status === 8 ||
                      conjuntoMotor.info.status === 9
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D1
                    }

                    conjuntoMotor.actionManager = new BABYLON.ActionManager(
                      scene
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }
                } else {
                  // CLONA E CRIA BASE
                  const base = baseMotor3D.clone(`base${id}`)
                  // base.parent = objetoCabo;
                  if (camada > 0) {
                    base.position.z = -((largBaseArmazem + 5) / 2 + camada * 6)
                  } else {
                    base.position.z = -((largBaseArmazem + 5) / 2)
                  }
                  base.position.x = finalPositionX
                  base.position.y = 0
                  base.visibility = 1
                  // base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(180));

                  // CRIA SUPORTE DO MOTOR
                  const suporteDoMotor = suporteDoMotor3D.clone(`suporte${id}`)
                  suporteDoMotor.parent = base
                  suporteDoMotor.visibility = 1
                  const suportePlacaNome = suporteDoMotor3D.clone(
                    `suportePlacaNome${id}`
                  )
                  suportePlacaNome.parent = base
                  suportePlacaNome.position.x = 0
                  suportePlacaNome.position.z = 2.2
                  suportePlacaNome.visibility = 0

                  const suportePlacaStatus = suporteDoMotor3D.clone(
                    `suportePlacaStatus${id}`
                  )
                  suportePlacaStatus.parent = base
                  suportePlacaStatus.position.x = 0
                  suportePlacaStatus.position.y = 1
                  suportePlacaStatus.position.z = 2
                  suportePlacaStatus.visibility = 0

                  // CLONA E CRIA MOTOR
                  const motor = motor3D.clone(`motor${id}`)
                  if (motor) {
                    motor.parent = base
                    motor.info = objCabo.info[i]
                    motor.visibility = 1

                    if (
                      motor.info.status === 1 ||
                      motor.info.status === 2 ||
                      motor.info.status === 10
                    ) {
                      motor.material = colorMotorStatus3D3Shaded
                    } else if (
                      motor.info.status === 3 ||
                      motor.info.status === 4
                    ) {
                      motor.material = colorMotorStatus3D2Shaded
                    } else if (
                      motor.info.status === 5 ||
                      motor.info.status === 6
                    ) {
                      motor.material = colorMotorStatus3D4Shaded
                    } else if (
                      motor.info.status === 7 ||
                      motor.info.status === 8 ||
                      motor.info.status === 9
                    ) {
                      motor.material = colorMotorStatus3D1Shaded
                    }

                    motor.actionManager = new BABYLON.ActionManager(scene)

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }

                  if (base) {
                    LabelNameMotor(
                      scene,
                      motor.info.nameMotor,
                      1.5,
                      suportePlacaNome
                    )
                  }

                  if (motor && motor.info) {
                    // CRIA CONJUNTO MOTO
                    const conjuntoMotor = conjuntoMotor3D.clone(
                      `conjuntoMotor3D${id}`
                    )
                    conjuntoMotor.info = motor.info
                    conjuntoMotor.parent = base
                    conjuntoMotor.visibility = 1

                    const placaStatusOnOffMotor = StatusOnOffMotor(
                      scene,
                      suportePlacaStatus,
                      0.6,
                      conjuntoMotor.info.operation,
                      conjuntoMotor.info.idMotor
                    )
                    // console.log(placaStatusOnOffMotor)

                    if (
                      conjuntoMotor.info.status === 1 ||
                      conjuntoMotor.info.status === 2 ||
                      conjuntoMotor.info.status === 10
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D3
                    } else if (
                      conjuntoMotor.info.status === 3 ||
                      conjuntoMotor.info.status === 4
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D2
                    } else if (
                      conjuntoMotor.info.status === 5 ||
                      conjuntoMotor.info.status === 6
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D4
                    } else if (
                      conjuntoMotor.info.status === 7 ||
                      conjuntoMotor.info.status === 8 ||
                      conjuntoMotor.info.status === 9
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D1
                    }

                    conjuntoMotor.actionManager = new BABYLON.ActionManager(
                      scene
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }
                }
              }
            } else {
              // console.log('maior')
              if (cab > 0 && cab < objArco.cables.length - 1) {
                if (arc === objetoArmazem.length - 1) {
                  // CLONA E CRIA BASE
                  const positionZ = calculaPositionZDoMotor(
                    objArco.cables[cab].positionZ,
                    largBaseArmazem,
                    proporcaoLargB
                  )

                  // console.log(`cab = ${cab} -- inner positionZ = ${positionZ}`)
                  // console.log(`result positionZ = ${positionZ > (largBaseArmazem / 2) ? positionZ - (largBaseArmazem / 2) : -((largBaseArmazem / 2) - positionZ)}`)
                  const base = baseMotor3D.clone(`base${id}`)
                  // base.parent = objetoCabo;
                  base.position.z =
                    positionZ > largBaseArmazem / 2
                      ? positionZ - largBaseArmazem / 2
                      : -(largBaseArmazem / 2 - positionZ)
                  base.position.x = compBaseArmazem / 2 + 2
                  base.position.y = 0
                  base.visibility = 1
                  base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(-270))

                  // CRIA SUPORTE DO MOTOR
                  const suporteDoMotor = suporteDoMotor3D.clone(`suporte${id}`)
                  suporteDoMotor.parent = base
                  suporteDoMotor.visibility = 1
                  const suportePlacaNome = suporteDoMotor3D.clone(
                    `suportePlacaNome${id}`
                  )
                  suportePlacaNome.parent = base
                  suportePlacaNome.position.x = 0
                  suportePlacaNome.position.z = 2.2
                  suportePlacaNome.visibility = 0

                  const suportePlacaStatus = suporteDoMotor3D.clone(
                    `suportePlacaStatus${id}`
                  )
                  suportePlacaStatus.parent = base
                  suportePlacaStatus.position.x = 0
                  suportePlacaStatus.position.y = 1
                  suportePlacaStatus.position.z = 2
                  suportePlacaStatus.visibility = 0

                  // CLONA E CRIA MOTOR
                  const motor = motor3D.clone(`motor${id}`)
                  if (motor) {
                    motor.parent = base
                    motor.info = objCabo.info[i]
                    motor.visibility = 1

                    if (
                      motor.info.status === 1 ||
                      motor.info.status === 2 ||
                      motor.info.status === 10
                    ) {
                      motor.material = colorMotorStatus3D3Shaded
                    } else if (
                      motor.info.status === 3 ||
                      motor.info.status === 4
                    ) {
                      motor.material = colorMotorStatus3D2Shaded
                    } else if (
                      motor.info.status === 5 ||
                      motor.info.status === 6
                    ) {
                      motor.material = colorMotorStatus3D4Shaded
                    } else if (
                      motor.info.status === 7 ||
                      motor.info.status === 8 ||
                      motor.info.status === 9
                    ) {
                      motor.material = colorMotorStatus3D1Shaded
                    }

                    motor.actionManager = new BABYLON.ActionManager(scene)

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }

                  if (base) {
                    LabelNameMotor(
                      scene,
                      motor.info.nameMotor,
                      1.5,
                      suportePlacaNome
                    )
                  }

                  if (motor && motor.info) {
                    // CRIA CONJUNTO MOTO
                    const conjuntoMotor = conjuntoMotor3D.clone(
                      `conjuntoMotor3D${id}`
                    )
                    conjuntoMotor.info = motor.info
                    conjuntoMotor.parent = base
                    conjuntoMotor.visibility = 1

                    const placaStatusOnOffMotor = StatusOnOffMotor(
                      scene,
                      suportePlacaStatus,
                      0.6,
                      conjuntoMotor.info.operation,
                      conjuntoMotor.info.idMotor
                    )
                    // console.log(placaStatusOnOffMotor)

                    if (
                      conjuntoMotor.info.status === 1 ||
                      conjuntoMotor.info.status === 2 ||
                      conjuntoMotor.info.status === 10
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D3
                    } else if (
                      conjuntoMotor.info.status === 3 ||
                      conjuntoMotor.info.status === 4
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D2
                    } else if (
                      conjuntoMotor.info.status === 5 ||
                      conjuntoMotor.info.status === 6
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D4
                    } else if (
                      conjuntoMotor.info.status === 7 ||
                      conjuntoMotor.info.status === 8 ||
                      conjuntoMotor.info.status === 9
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D1
                    }

                    conjuntoMotor.actionManager = new BABYLON.ActionManager(
                      scene
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }
                } else {
                  // console.log('arco não é igual a zero - menor')
                  // console.log(objArco.cables)

                  const positionZ = calculaPositionZDoMotor(
                    objArco.cables[cab].positionZ,
                    largBaseArmazem,
                    proporcaoLargB
                  )

                  const referenciaDoArco = arc + 1
                  const positionX = referenciaDoArco * (distEntreArcos + 0.5)
                  const finalPositionX = positionX - compBaseArmazem / 2

                  if (positionZ) {
                    // console.log(`é maior | cab = ${cab} = ${positionZ}`)
                    const numberOfMotors =
                      objArco.cables[objArco.cables.length - 1].info

                    // CLONA E CRIA BASE
                    const base = baseMotor3D.clone(`base${id}`)
                    // base.parent = objetoCabo;

                    if (numberOfMotors) {
                      if (camada > 0) {
                        base.position.z =
                          (largBaseArmazem + 5) / 2 +
                          (numberOfMotors.length * 6 + 1.5) +
                          camada * 6
                      } else {
                        base.position.z =
                          (largBaseArmazem + 5) / 2 +
                          (numberOfMotors.length * 6 + 1.5)
                      }
                    } else {
                      if (camada > 0) {
                        base.position.z = base.position.z =
                          (largBaseArmazem + 5) / 2 + camada * 6
                      } else {
                        base.position.z = base.position.z =
                          (largBaseArmazem + 5) / 2
                      }
                    }

                    base.position.x = finalPositionX
                    base.position.y = 0
                    base.visibility = 1
                    // base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(180));

                    // CRIA SUPORTE DO MOTOR
                    const suporteDoMotor = suporteDoMotor3D.clone(
                      `suporte${id}`
                    )
                    suporteDoMotor.parent = base
                    suporteDoMotor.visibility = 1
                    const suportePlacaNome = suporteDoMotor3D.clone(
                      `suportePlacaNome${id}`
                    )
                    suportePlacaNome.parent = base
                    suportePlacaNome.position.x = 0
                    suportePlacaNome.position.z = 2.2
                    suportePlacaNome.visibility = 0

                    const suportePlacaStatus = suporteDoMotor3D.clone(
                      `suportePlacaStatus${id}`
                    )
                    suportePlacaStatus.parent = base
                    suportePlacaStatus.position.x = 0
                    suportePlacaStatus.position.y = 1
                    suportePlacaStatus.position.z = 2
                    suportePlacaStatus.visibility = 0

                    // CLONA E CRIA MOTOR
                    const motor = motor3D.clone(`motor${id}`)
                    if (motor) {
                      motor.parent = base
                      motor.info = objCabo.info[i]
                      motor.visibility = 1

                      if (
                        motor.info.status === 1 ||
                        motor.info.status === 2 ||
                        motor.info.status === 10
                      ) {
                        motor.material = colorMotorStatus3D3Shaded
                      } else if (
                        motor.info.status === 3 ||
                        motor.info.status === 4
                      ) {
                        motor.material = colorMotorStatus3D2Shaded
                      } else if (
                        motor.info.status === 5 ||
                        motor.info.status === 6
                      ) {
                        motor.material = colorMotorStatus3D4Shaded
                      } else if (
                        motor.info.status === 7 ||
                        motor.info.status === 8 ||
                        motor.info.status === 9
                      ) {
                        motor.material = colorMotorStatus3D1Shaded
                      }

                      motor.actionManager = new BABYLON.ActionManager(scene)

                      motor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOverTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  ev.meshUnderPointer.info,
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )

                      motor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOutTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  'remove',
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )
                    }

                    if (base) {
                      LabelNameMotor(
                        scene,
                        motor.info.nameMotor,
                        1.5,
                        suportePlacaNome
                      )
                    }

                    if (motor && motor.info) {
                      // CRIA CONJUNTO MOTO
                      const conjuntoMotor = conjuntoMotor3D.clone(
                        `conjuntoMotor3D${id}`
                      )
                      conjuntoMotor.info = motor.info
                      conjuntoMotor.parent = base
                      conjuntoMotor.visibility = 1

                      const placaStatusOnOffMotor = StatusOnOffMotor(
                        scene,
                        suportePlacaStatus,
                        0.6,
                        conjuntoMotor.info.operation,
                        conjuntoMotor.info.idMotor
                      )
                      // console.log(placaStatusOnOffMotor)

                      if (
                        conjuntoMotor.info.status === 1 ||
                        conjuntoMotor.info.status === 2 ||
                        conjuntoMotor.info.status === 10
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D3
                      } else if (
                        conjuntoMotor.info.status === 3 ||
                        conjuntoMotor.info.status === 4
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D2
                      } else if (
                        conjuntoMotor.info.status === 5 ||
                        conjuntoMotor.info.status === 6
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D4
                      } else if (
                        conjuntoMotor.info.status === 7 ||
                        conjuntoMotor.info.status === 8 ||
                        conjuntoMotor.info.status === 9
                      ) {
                        conjuntoMotor.material = colorMotorStatus3D1
                      }

                      conjuntoMotor.actionManager = new BABYLON.ActionManager(
                        scene
                      )

                      conjuntoMotor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOverTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  ev.meshUnderPointer.info,
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )

                      conjuntoMotor.actionManager.registerAction(
                        new BABYLON.ExecuteCodeAction(
                          BABYLON.ActionManager.OnPointerOutTrigger,
                          function (ev) {
                            switch (ev.meshUnderPointer.id) {
                              case ev.meshUnderPointer.id:
                                fcnSeeTooltipMotor(
                                  'remove',
                                  scene,
                                  ev.meshUnderPointer,
                                  ev.meshUnderPointer.absolutePosition
                                )
                                break
                            }
                          }
                        )
                      )
                    }
                  }
                }
              } else {
                const referenciaDoArco = arc + 1
                const positionX = referenciaDoArco * (distEntreArcos + 0.5)
                const finalPositionX = positionX - compBaseArmazem / 2
                if (cab === objArco.cables.length - 1) {
                  // CLONA E CRIA BASE
                  const base = baseMotor3D.clone(`base${id}`)
                  // base.parent = objetoCabo;
                  if (camada > 0) {
                    base.position.z = (largBaseArmazem + 5) / 2 + camada * 6
                  } else {
                    base.position.z = (largBaseArmazem + 5) / 2
                  }

                  base.position.x = finalPositionX
                  base.position.y = 0
                  base.visibility = 1
                  // base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(90));

                  // CRIA SUPORTE DO MOTOR
                  const suporteDoMotor = suporteDoMotor3D.clone(`suporte${id}`)
                  suporteDoMotor.parent = base
                  suporteDoMotor.visibility = 1
                  const suportePlacaNome = suporteDoMotor3D.clone(
                    `suportePlacaNome${id}`
                  )
                  suportePlacaNome.parent = base
                  suportePlacaNome.position.x = 0
                  suportePlacaNome.position.z = 2.2
                  suportePlacaNome.visibility = 0

                  const suportePlacaStatus = suporteDoMotor3D.clone(
                    `suportePlacaStatus${id}`
                  )
                  suportePlacaStatus.parent = base
                  suportePlacaStatus.position.x = 0
                  suportePlacaStatus.position.y = 1
                  suportePlacaStatus.position.z = 2
                  suportePlacaStatus.visibility = 0

                  // CLONA E CRIA MOTOR
                  const motor = motor3D.clone(`motor${id}`)
                  if (motor) {
                    motor.parent = base
                    motor.info = objCabo.info[i]
                    motor.visibility = 1

                    if (
                      motor.info.status === 1 ||
                      motor.info.status === 2 ||
                      motor.info.status === 10
                    ) {
                      motor.material = colorMotorStatus3D3Shaded
                    } else if (
                      motor.info.status === 3 ||
                      motor.info.status === 4
                    ) {
                      motor.material = colorMotorStatus3D2Shaded
                    } else if (
                      motor.info.status === 5 ||
                      motor.info.status === 6
                    ) {
                      motor.material = colorMotorStatus3D4Shaded
                    } else if (
                      motor.info.status === 7 ||
                      motor.info.status === 8 ||
                      motor.info.status === 9
                    ) {
                      motor.material = colorMotorStatus3D1Shaded
                    }

                    motor.actionManager = new BABYLON.ActionManager(scene)

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }

                  if (base) {
                    LabelNameMotor(
                      scene,
                      motor.info.nameMotor,
                      1.5,
                      suportePlacaNome
                    )
                  }

                  if (motor && motor.info) {
                    // CRIA CONJUNTO MOTO
                    const conjuntoMotor = conjuntoMotor3D.clone(
                      `conjuntoMotor3D${id}`
                    )
                    conjuntoMotor.info = motor.info
                    conjuntoMotor.parent = base
                    conjuntoMotor.visibility = 1

                    const placaStatusOnOffMotor = StatusOnOffMotor(
                      scene,
                      suportePlacaStatus,
                      0.6,
                      conjuntoMotor.info.operation,
                      conjuntoMotor.info.idMotor
                    )
                    // console.log(placaStatusOnOffMotor)

                    if (
                      conjuntoMotor.info.status === 1 ||
                      conjuntoMotor.info.status === 2 ||
                      conjuntoMotor.info.status === 10
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D3
                    } else if (
                      conjuntoMotor.info.status === 3 ||
                      conjuntoMotor.info.status === 4
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D2
                    } else if (
                      conjuntoMotor.info.status === 5 ||
                      conjuntoMotor.info.status === 6
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D4
                    } else if (
                      conjuntoMotor.info.status === 7 ||
                      conjuntoMotor.info.status === 8 ||
                      conjuntoMotor.info.status === 9
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D1
                    }

                    conjuntoMotor.actionManager = new BABYLON.ActionManager(
                      scene
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }
                } else {
                  // CLONA E CRIA BASE
                  const base = baseMotor3D.clone(`base${id}`)
                  // base.parent = objetoCabo;
                  if (camada > 0) {
                    base.position.z = -((largBaseArmazem + 5) / 2 + camada * 6)
                  } else {
                    base.position.z = -((largBaseArmazem + 5) / 2)
                  }

                  base.position.x = finalPositionX
                  base.position.y = 0
                  base.visibility = 1
                  // base.rotate(BABYLON.Axis.Y, BABYLON.Tools.ToRadians(180));

                  // CRIA SUPORTE DO MOTOR
                  const suporteDoMotor = suporteDoMotor3D.clone(`suporte${id}`)
                  suporteDoMotor.parent = base
                  suporteDoMotor.visibility = 1
                  const suportePlacaNome = suporteDoMotor3D.clone(
                    `suportePlacaNome${id}`
                  )
                  suportePlacaNome.parent = base
                  suportePlacaNome.position.x = 0
                  suportePlacaNome.position.z = 2.2
                  suportePlacaNome.visibility = 0

                  const suportePlacaStatus = suporteDoMotor3D.clone(
                    `suportePlacaStatus${id}`
                  )
                  suportePlacaStatus.parent = base
                  suportePlacaStatus.position.x = 0
                  suportePlacaStatus.position.y = 1
                  suportePlacaStatus.position.z = 2
                  suportePlacaStatus.visibility = 0

                  // CLONA E CRIA MOTOR
                  const motor = motor3D.clone(`motor${id}`)
                  if (motor) {
                    motor.parent = base
                    motor.info = objCabo.info[i]
                    motor.visibility = 1

                    if (
                      motor.info.status === 1 ||
                      motor.info.status === 2 ||
                      motor.info.status === 10
                    ) {
                      motor.material = colorMotorStatus3D3Shaded
                    } else if (
                      motor.info.status === 3 ||
                      motor.info.status === 4
                    ) {
                      motor.material = colorMotorStatus3D2Shaded
                    } else if (
                      motor.info.status === 5 ||
                      motor.info.status === 6
                    ) {
                      motor.material = colorMotorStatus3D4Shaded
                    } else if (
                      motor.info.status === 7 ||
                      motor.info.status === 8 ||
                      motor.info.status === 9
                    ) {
                      motor.material = colorMotorStatus3D1Shaded
                    }

                    motor.actionManager = new BABYLON.ActionManager(scene)

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    motor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }

                  if (base) {
                    LabelNameMotor(
                      scene,
                      motor.info.nameMotor,
                      1.5,
                      suportePlacaNome
                    )
                  }

                  if (motor && motor.info) {
                    // CRIA CONJUNTO MOTO
                    const conjuntoMotor = conjuntoMotor3D.clone(
                      `conjuntoMotor3D${id}`
                    )
                    conjuntoMotor.info = motor.info
                    conjuntoMotor.parent = base
                    conjuntoMotor.visibility = 1

                    const placaStatusOnOffMotor = StatusOnOffMotor(
                      scene,
                      suportePlacaStatus,
                      0.6,
                      conjuntoMotor.info.operation,
                      conjuntoMotor.info.idMotor
                    )
                    // console.log(placaStatusOnOffMotor)

                    if (
                      conjuntoMotor.info.status === 1 ||
                      conjuntoMotor.info.status === 2 ||
                      conjuntoMotor.info.status === 10
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D3
                    } else if (
                      conjuntoMotor.info.status === 3 ||
                      conjuntoMotor.info.status === 4
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D2
                    } else if (
                      conjuntoMotor.info.status === 5 ||
                      conjuntoMotor.info.status === 6
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D4
                    } else if (
                      conjuntoMotor.info.status === 7 ||
                      conjuntoMotor.info.status === 8 ||
                      conjuntoMotor.info.status === 9
                    ) {
                      conjuntoMotor.material = colorMotorStatus3D1
                    }

                    conjuntoMotor.actionManager = new BABYLON.ActionManager(
                      scene
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOverTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                ev.meshUnderPointer.info,
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )

                    conjuntoMotor.actionManager.registerAction(
                      new BABYLON.ExecuteCodeAction(
                        BABYLON.ActionManager.OnPointerOutTrigger,
                        function (ev) {
                          switch (ev.meshUnderPointer.id) {
                            case ev.meshUnderPointer.id:
                              fcnSeeTooltipMotor(
                                'remove',
                                scene,
                                ev.meshUnderPointer,
                                ev.meshUnderPointer.absolutePosition
                              )
                              break
                          }
                        }
                      )
                    )
                  }
                }
              }
            }
            camada++
          }
        }
      }

      objetoCabo.isPickable = true
      objetoCabo.parent = objetoArco
      if (subareaSelected === 0) {
        objetoCabo.visibility = 1
      } else {
        objetoCabo.visibility =
          objetoArmazem[arc].subarea === subareaSelected ? 1 : 0
      }
      objetoCabo.actionManager = new BABYLON.ActionManager(scene)
      objetoCabo.actionManager.registerAction(
        new BABYLON.ExecuteCodeAction(
          BABYLON.ActionManager.OnPickTrigger,
          event => {
            const pickedMesh = event.meshUnderPointer

            const arco = pickedMesh.parent

            if (showInfo === false) {
              criaInfoLabels(arc, arco)
              showInfo = true
              arcSee.push(arco.name)
            } else {
              if (arcSee.includes(arco.name)) {
                _.forEach(scene.meshes, val => {
                  if (
                    val.name.includes('infoObjeto') &&
                    val.name.includes(arco.name)
                  ) {
                    val.visibility = !val.visibility
                  }
                })
                // TOGGL
              } else {
                // fncChangeViewInfo()
                criaInfoLabels(arc, arco)
                arcSee.push(arco.name)
              }
              // esconde info
            }
          }
        )
      )
      const alturaBase = 0.5
      for (let sen = 0; sen < objCabo.sensors.length; sen++) {
        sensorIdForMat++
        ///const meuY = (sen + 0.5) * Math.trunc(distanciaEntreSensores);

        //const objetoSensor = new BABYLON.MeshBuilder.CreateSphere(`objetoSensor${sensorIdForMat}`, { diameter: 0.5, sideOrientation: BABYLON.Mesh.DOUBLESIDE, updatable: false }, scene);

        var objetoSensor
        if (sensorIdForMat === 1) {
          // console.log('primeiro sensor =============')
          objetoSensor = objetoSensorEx
        } else {
          objetoSensor = objetoSensorEx.clone(`objetoSensor${sensorIdForMat}`)
        }
        //objetoSensor.position.y = objCabo.sensors[sen].position_y;
        objetoSensor.position.y = alturaBase + 1 + distanciaEntreSensores * sen
        const novoMat = matSensor.clone(`novoMat${sensorIdForMat}`)
        novoMat.id = `novoMat${sensorIdForMat}`
        novoMat.checkReadyOnEveryCall = false
        const objSensor = objCabo.sensors[sen]

        const tempVariaSensor = { temperatura: objSensor.t }

        let tempColor = '#FFFFFFFF'

        if (sen <= objCabo.level - 1) {
          tempColor = `${objSensor.color}FF`
          novoMat.diffuseColor = new BABYLON.Color4.FromHexString(tempColor)
        } else {
          novoMat.diffuseColor = new BABYLON.Color4.FromHexString(tempColor)
        }

        objetoSensor.parent = objetoCabo
        var clickSensor = event => {
          const pickedMesh = event.meshUnderPointer
          const cabo = pickedMesh.parent
          const arco = cabo.parent
          if (showInfo === false) {
            criaInfoLabels(arc, arco)
            showInfo = true
            arcSee.push(arco.name)
          } else {
            if (arcSee.includes(arco.name)) {
              _.forEach(scene.meshes, val => {
                if (
                  val.name.includes('infoObjeto') &&
                  val.name.includes(arco.name)
                ) {
                  val.visibility = !val.visibility
                }
              })
              // TOGGL
            } else {
              fncChangeViewInfo()
              criaInfoLabels(arc, arco)
              arcSee.push(arco.name)
            }
            // esconde info
          }
        }

        // let actualRect = criaTooltip(advancedTexture, objetoSensor, arc, objCabo, sen, objSensor, 0)

        objetoSensor.actionManager = new BABYLON.ActionManager(scene)

        if (subareaSelected === 0) {
          objetoSensor.visibility = 1
          objetoSensor.isPickable = true
          objetoSensor.enablePointerMoveEvents = true

          let toolTip2 = null

          //         objetoSensor.actionManager.registerAction(
          //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function (ev) {
          //     switch (ev.meshUnderPointer.id) {
          //       case ev.meshUnderPointer.id:
          //         if (objetoSensor.visibility === 1) {
          //           if (toolTip2) {
          //             if (toolTip2) {
          //               toolTip2.visibility = 1;
          //             }
          //           } else {
          //             toolTip2 = criaTooltip2(sen, scene, 'grey', [{ val: `tooltip${sen}`, tam: 220 }]
          //             , ev.meshUnderPointer, 0, 4.2, 1, -3)

          //             if (toolTip2) {
          //               toolTip2.visibility = 1;
          //             }
          //           }
          //         }
          //         break
          //     }
          //   }));

          // objetoSensor.actionManager.registerAction(
          //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, function (ev) {
          //     switch (ev.meshUnderPointer.id) {
          //       case ev.meshUnderPointer.id:
          //         if (objetoSensor.visibility === 1 && toolTip2) {
          //           toolTip2.visibility = 0
          //         }
          //         break
          //     }
          //   }));

          objetoSensor.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPointerOverTrigger,
              function (ev) {
                switch (ev.meshUnderPointer.id) {
                  case ev.meshUnderPointer.id:
                    fcnSeeTooltip(
                      ev.meshUnderPointer.sensor,
                      scene,
                      ev.meshUnderPointer,
                      ev.meshUnderPointer.absolutePosition
                    )
                    break
                }
              }
            )
          )

          objetoSensor.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPointerOutTrigger,
              function (ev) {
                switch (ev.meshUnderPointer.id) {
                  case ev.meshUnderPointer.id:
                    fcnSeeTooltip(
                      'remove',
                      scene,
                      ev.meshUnderPointer,
                      ev.meshUnderPointer.absolutePosition
                    )
                    break
                }
              }
            )
          )

          objetoSensor.actionManager.registerAction(
            new BABYLON.ExecuteCodeAction(
              BABYLON.ActionManager.OnPickTrigger,
              evt => clickSensor(evt)
            )
          )
        } else {
          objetoSensor.visibility =
            objetoArmazem[arc].subarea === subareaSelected ? 1 : 0

          if (objetoSensor.visibility === 1) {
            // let toolTip2 = null

            //         objetoSensor.actionManager.registerAction(
            //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOverTrigger, function (ev) {
            //     switch (ev.meshUnderPointer.id) {
            //       case ev.meshUnderPointer.id:
            //         if (objetoSensor.visibility === 1) {
            //           if (toolTip2) {
            //             if (toolTip2) {
            //               toolTip2.visibility = 1;
            //             }
            //           } else {
            //             toolTip2 = criaTooltip2(sen, scene, 'grey', [{ val: `tooltip${sen}`, tam: 220 }]
            //             , ev.meshUnderPointer, 0, 4.2, 1, -3)

            //             if (toolTip2) {
            //               toolTip2.visibility = 1;
            //             }
            //           }
            //         }
            //         break
            //     }
            //   }));

            // objetoSensor.actionManager.registerAction(
            //   new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnPointerOutTrigger, function (ev) {
            //     switch (ev.meshUnderPointer.id) {
            //       case ev.meshUnderPointer.id:
            //         if (objetoSensor.visibility === 1 && toolTip2) {
            //           toolTip2.visibility = 0
            //         }
            //         break
            //     }
            //   }));

            objetoSensor.actionManager.registerAction(
              new BABYLON.ExecuteCodeAction(
                BABYLON.ActionManager.OnPointerOverTrigger,
                function (ev) {
                  switch (ev.meshUnderPointer.id) {
                    case ev.meshUnderPointer.id:
                      fcnSeeTooltip(
                        ev.meshUnderPointer.sensor,
                        scene,
                        ev.meshUnderPointer,
                        ev.meshUnderPointer.absolutePosition
                      )
                      break
                  }
                }
              )
            )

            objetoSensor.actionManager.registerAction(
              new BABYLON.ExecuteCodeAction(
                BABYLON.ActionManager.OnPointerOutTrigger,
                function (ev) {
                  switch (ev.meshUnderPointer.id) {
                    case ev.meshUnderPointer.id:
                      fcnSeeTooltip(
                        'remove',
                        scene,
                        ev.meshUnderPointer,
                        ev.meshUnderPointer.absolutePosition
                      )
                      break
                  }
                }
              )
            )

            objetoSensor.actionManager.registerAction(
              new BABYLON.ExecuteCodeAction(
                BABYLON.ActionManager.OnPickTrigger,
                evt => clickSensor(evt)
              )
            )
          }

          if (objetoSensor.visibility === 1) {
            objetoSensor.isPickable = true
            objetoSensor.enablePointerMoveEvents = true
          } else {
            objetoSensor.isPickable = false
            objetoSensor.enablePointerMoveEvents = false
          }
        }

        objetoSensor.material = novoMat

        var onOver = meshEvent => {
          var div = document.createElement('div')
          div.setAttribute('id', `mybut${sensorIdForMat}`)
          var styDiv = div.style
          styDiv.position = 'absolute'
          styDiv.display = 'flex'
          styDiv.flexDirection = 'column'
          styDiv.padding = '16px'
          styDiv.color = '#ffffff'
          styDiv.borderRadius = '8px'
          styDiv.backgroundColor = 'rgb(51 51 51 / 0.7)' //"#333333";
          styDiv.fontSize = '12pt'
          styDiv.top = scene.pointerY + 'px'
          styDiv.left = scene.pointerX + 'px'
          styDiv.cursor = 'pointer'

          var but = document.createElement('span')

          but.setAttribute('onClick', "alert('ouch!'")
          var but1 = document.createElement('span')
          var but2 = document.createElement('span')
          var but3 = document.createElement('span')
          div.appendChild(but)
          div.appendChild(but1)
          div.appendChild(but2)
          div.appendChild(but3)
          document.body.appendChild(div)
          but.textContent = `Arco: ${objetoArmazem.length - arc}`
          but1.textContent = `Cabo: ${objCabo.cab}`
          but2.textContent = `Sensor: ${sen + 1}`
          but3.textContent =
            objSensor.t === 999
              ? 'OFF'
              : objSensor.t === 998
              ? 'N/D'
              : objSensor.t === 997
              ? 'ERR'
              : `Temp.: ${objSensor.t}ºC`

          const funcSens = event => {
            clickSensor(meshEvent)
          }
          div.addEventListener('click', funcSens)
        }

        var onOut = meshEvent => {
          while (document.getElementById(`mybut${sensorIdForMat}`)) {
            document
              .getElementById(`mybut${sensorIdForMat}`)
              .parentNode.removeChild(
                document.getElementById(`mybut${sensorIdForMat}`)
              )
          }
        }

        // objetoSensor.actionManager.registerAction(
        //   new BABYLON.ExecuteCodeAction(
        //     BABYLON.ActionManager.OnPointerOverTrigger,
        //     onOver
        //   )
        // );

        // objetoSensor.actionManager.registerAction(
        //   new BABYLON.ExecuteCodeAction(
        //     BABYLON.ActionManager.OnPointerOutTrigger,
        //     onOut
        //   )
        // );

        // + (maxDepthCableArc - objCabo.depth)
        objetoSensor.sensor = {
          cor: novoMat.diffuseColor,
          sensor_id: sen + 1,
          level_sensor: objCabo.level,
          arc_id: arc + 1,
          cable_number: objCabo.cab,
          arc_index:
            arc === 0 ? objetoArmazem.length : objetoArmazem.length - arc,
          real_level: sen + 1,
          temperature:
            objSensor.t === 999
              ? 'OFF'
              : objSensor.t === 998
              ? 'N/D'
              : objSensor.t === 997
              ? 'ERR'
              : objSensor.t,
          color_hex: tempColor
        }

        //objetoSensor.setEnabled(false)
      }
      // Nome Cabo here

      if (subareaSelected === 0) {
        NomeCabo(
          scene,
          '#242d45',
          [
            { val: objArco.cables[cab].cab, tam: 160 },
            {
              val: `CABO`,
              tam: 150
            }
          ],
          objetoCabo,
          alturaCaboTeto - 0.7,
          1.0,
          0.2,
          0.5
        )
      } else if (objetoArmazem[arc].subarea === subareaSelected) {
        NomeCabo(
          scene,
          '#242d45',
          [
            { val: objArco.cables[cab].cab, tam: 160 },
            {
              val: `CABO`,
              tam: 150
            }
          ],
          objetoCabo,
          alturaCaboTeto - 0.7,
          1.0,
          0.2,
          0.5
        )
      }

      //   i++
      //   if (i < 10) {
      //     myLoop()
      //   }
      // }, 500)
      // }

      // myLoop() //  start the loop
    }
  }
  // 4-Elevação da superfície
  for (let arc = 0; arc < objetoArmazem.length; arc++) {
    let ultAltCabo = 0
    let ultPntInit = 1
    const posArcoNivel = Math.trunc(arc * distanciaEntreArcos + 2)
    const alturaParedeMaxima = profundidadeFundo + alturaParedeArmazem
    const cablesLenght = objetoArmazem[arc].cables.length

    for (let cab = 0; cab <= cablesLenght; cab++) {
      const contNivelProduto = fncAltCabo(
        objetoArmazem[arc].cables[cab],
        distanciaEntreSensores,
        termoReport
      ) // TODO:
      let altProxima = contNivelProduto === 1 ? 2 : contNivelProduto
      // CALC PROXPNT NOVO:
      let proxPnt
      const pontoZInicioBaseArmazem = 24

      const cable = objetoArmazem[arc].cables[cab]
      if (cable !== undefined) {
        const cablePositionZ =
          objetoArmazem[arc].cables[cab].positionZ - pontoZInicioBaseArmazem
        proxPnt = Math.trunc((largBaseArmazem * cablePositionZ) / 192)
        // if (cab === cablesLenght - 1) {
        //   const cablePositionZ =
        //     objetoArmazem[arc].cables[cab].positionZ - pontoZInicioBaseArmazem
        //   proxPnt = Math.trunc((largBaseArmazem * cablePositionZ) / 192)
        //   // proxPnt = largBaseArmazem + 1
        // } else {
        //   const cablePositionZ =
        //     objetoArmazem[arc].cables[cab].positionZ - pontoZInicioBaseArmazem
        //   proxPnt = Math.trunc((largBaseArmazem * cablePositionZ) / 192)
        // }
      } else {
        // CALC PROX PNT ANTIGO
        proxPnt =
          Math.trunc(
            (largBaseArmazem / (objetoArmazem[arc].cables.length + 1)) *
              (cab + 1)
          ) + 1
      }
      if (cab === 0) {
        // Caso for o caso do primeiro cabo define o nível lateral
        const proxNivelProd = fncAltCabo(
          objetoArmazem[arc].cables[cab + 1],
          distanciaEntreSensores,
          termoReport
        )
        if (proxNivelProd >= contNivelProduto) {
          // Enchendo
          ultAltCabo =
            contNivelProduto > alturaParedeMaxima
              ? alturaParedeMaxima
              : contNivelProduto
        } else {
          // Esvaziando
          ultAltCabo =
            contNivelProduto + 1 > alturaParedeMaxima
              ? alturaParedeMaxima - 1
              : contNivelProduto + 1
        }
      } else if (cab === cablesLenght) {
        // Quando for o último cabo define o nível lateral
        proxPnt += 1
        const anteNivelProd = fncAltCabo(
          objetoArmazem[arc].cables[cablesLenght - 2],
          distanciaEntreSensores,
          termoReport
        )
        const anteNivelAtual = fncAltCabo(
          objetoArmazem[arc].cables[cablesLenght - 1],
          distanciaEntreSensores,
          termoReport
        )
        if (anteNivelProd >= anteNivelAtual) {
          // Enchendo
          altProxima =
            anteNivelAtual > alturaParedeMaxima
              ? alturaParedeMaxima
              : anteNivelAtual
        } else {
          // Esvaziando
          altProxima =
            anteNivelAtual + 1 > alturaParedeMaxima
              ? alturaParedeMaxima - 1
              : anteNivelAtual + 1
        }
      }
      fncLevelArmazem(
        superficie,
        posArcoNivel,
        ultAltCabo,
        altProxima,
        ultPntInit,
        proxPnt
      ) // TODO:
      // Eleva os níveis das cabeceiras
      if (arc === 0 || arc === objetoArmazem.length - 1) {
        const maiorQueB = fncAvgProductCompare(
          superficie,
          posArcoNivel,
          posArcoNivel - 1,
          ultPntInit,
          proxPnt
        ) // TODO:
        let altPosA = 0
        let altPosB = 0
        let posicaoArco = posArcoNivel
        if (arc === 0) {
          // Caso for a cabeceira inicial
          // posicaoArco += 2
          // altPosA = !maiorQueB ? ultAltCabo * 0.85 : ultAltCabo * 1.15
          // altPosB = !maiorQueB ? altProxima * 0.85 : altProxima * 1.15
          // ESTAVA INVERTIDO
          altPosA = maiorQueB ? ultAltCabo * 0.85 : ultAltCabo * 1.15
          altPosB = maiorQueB ? altProxima * 0.85 : altProxima * 1.15
          posicaoArco -= 1
        } else {
          // Caso for a cabeceira final
          // altPosA = maiorQueB ? ultAltCabo * 0.85 : ultAltCabo * 1.15
          // altPosB = maiorQueB ? altProxima * 0.85 : altProxima * 1.15
          // posicaoArco -= 1
          // ESTAVA INVERTIDO
          posicaoArco += 2
          altPosA = !maiorQueB ? ultAltCabo * 0.85 : ultAltCabo * 1.15
          altPosB = !maiorQueB ? altProxima * 0.85 : altProxima * 1.15
        }
        altPosA = altPosA < 0 ? 0 : altPosA
        altPosB = altPosB < 0 ? 0 : altPosB
        altPosA = altPosA > alturaParedeMaxima ? alturaParedeMaxima : altPosA
        altPosB = altPosB > alturaParedeMaxima ? alturaParedeMaxima : altPosB
        fncLevelArmazem(
          superficie,
          posicaoArco,
          altPosA,
          altPosB,
          ultPntInit,
          proxPnt,
          true
        ) // TODO:
      }
      ultAltCabo = altProxima
      ultPntInit = proxPnt
    }
  }

  // Material Produto
  const matProduto = new BABYLON.StandardMaterial('matProduto', scene)
  if (grao === 'Milho') {
    matProduto.diffuseTexture = new BABYLON.Texture(
      `${theme.textures.corn}`,
      scene
    )
  } else if (grao === 'Soja') {
    matProduto.diffuseTexture = new BABYLON.Texture(
      `${theme.textures.soybean}`,
      scene
    )
  } else if (grao === 'Trigo Duro' || grao === 'Trigo Mole') {
    matProduto.diffuseTexture = new BABYLON.Texture(
      `${theme.textures.wheat}`,
      scene
    )
  } else {
    matProduto.diffuseTexture = new BABYLON.Texture(
      `${theme.textures.padrao}`,
      scene
    )
  }
  matProduto.sideOrientation = BABYLON.Mesh.DOUBLESIDE
  matProduto.diffuseTexture.uScale = 10
  matProduto.diffuseTexture.vScale = 10
  matProduto.specularColor = new BABYLON.Color3(0, 0, 0)
  matProduto.backFaceCulling = false
  matProduto.zOffset = +2

  // Malha do Produto
  const produto = BABYLON.MeshBuilder.CreateRibbon(
    'nivelProduto',
    { pathArray: superficie, updatable: false },
    scene
  )
  produto.material = matProduto
  // produto.material.emissiveColor = new BABYLON.Color4(1, 0, 0, 1)
  produto.backFaceCulling = true
  produto.isPickable = false
  // produto.visibility = 0.5 => ORIGINAL
  produto.visibility = produto_visivel
  produto.parent = conjuntoArco
  // produto.material.wireframe = true
  // produto.checkCollisions = true
  // if (produto.intersectsMesh(faceParedeArredondada, true)) {
  //   produto.material.emissiveColor = new BABYLON.Color4(1, 0, 0, 1)
  // } else {
  //   produto.material = matProduto
  // }

  objetoCamadaProduto = conjuntoArco
  fncChangeViewInfo(scene, produto_info)
}

const calculaPositionZDoMotor = (
  positionZ,
  larguraDoArmazem,
  proporçãoLarguraB
) => {
  const position = (positionZ * larguraDoArmazem) / 192 - proporçãoLarguraB
  return position
}

const fncSurfaceArmazem = (
  superficie,
  arco,
  incDistArco,
  qtdLinhaLarg,
  distEntreCabos,
  totalArcos
) => {
  // Superficie lateral inicial
  function superFunctionRetornaIncremento1(largura) {
    const multiplicador = largura / 10
    const valorInicial = 0.875
    const resultado = valorInicial - multiplicador * 0.0475
    return resultado
  }

  function superFunctionRetornaIncremento2(largura) {
    const multiplicador = largura / 10
    const valorInicial = 0.935
    const resultado = valorInicial - multiplicador * 0.02625
    return resultado
  }

  function superFunctionRetornaIncremento3(largura) {
    const multiplicador = largura / 10
    const valorInicial = 0.93
    const resultado = valorInicial - multiplicador * 0.015
    return resultado
  }

  let distanciaEntrePontoZPadrao = 0.133
  let incrementoDaDistanciaEntrePontoZPrimeiroArco =
    superFunctionRetornaIncremento1(qtdLinhaLarg)
  let incrementoDaDistanciaEntrePontoZSegundoArco =
    superFunctionRetornaIncremento2(qtdLinhaLarg)
  let incrementoDaDistanciaEntrePontoZTerceiroArco =
    superFunctionRetornaIncremento3(qtdLinhaLarg)
  let pontoMeioArmazem = qtdLinhaLarg / 2

  let primeiroArcoPosicaoZ = Math.trunc(
    pontoMeioArmazem - (distanciaEntrePontoZPadrao * qtdLinhaLarg) / 2
  )
  let segundoArcoPosicaoZ = Math.trunc(
    pontoMeioArmazem -
      (distanciaEntrePontoZPadrao * qtdLinhaLarg +
        incrementoDaDistanciaEntrePontoZPrimeiroArco * qtdLinhaLarg) /
        2
  )
  let terceiroArcoPosicaoZ = Math.trunc(
    pontoMeioArmazem -
      (distanciaEntrePontoZPadrao * qtdLinhaLarg +
        incrementoDaDistanciaEntrePontoZSegundoArco * qtdLinhaLarg) /
        2
  )
  let quartoArcoPosicaoZ = Math.trunc(
    pontoMeioArmazem -
      (distanciaEntrePontoZPadrao * qtdLinhaLarg +
        incrementoDaDistanciaEntrePontoZTerceiroArco * qtdLinhaLarg) /
        2
  )

  // Superficie lateral inicial
  if (arco <= 1 || arco >= totalArcos - 1) {
    superficie[arco].push(
      new BABYLON.Vector3(incDistArco, 0, primeiroArcoPosicaoZ)
    )
  } else if ((arco >= 1 && arco <= 3) || arco >= totalArcos - 3) {
    superficie[arco].push(
      new BABYLON.Vector3(incDistArco, 0, segundoArcoPosicaoZ)
    )
  } else if ((arco >= 4 && arco <= 5) || arco >= totalArcos - 5) {
    superficie[arco].push(
      new BABYLON.Vector3(incDistArco, 0, terceiroArcoPosicaoZ)
    )
  } else if ((arco >= 6 && arco <= 7) || arco >= totalArcos - 7) {
    superficie[arco].push(
      new BABYLON.Vector3(incDistArco, 0, quartoArcoPosicaoZ)
    )
  } else {
    superficie[arco].push(new BABYLON.Vector3(incDistArco, 0, 0))
  }
  for (let i = 0; i <= qtdLinhaLarg; i++) {
    if (arco <= 1 || arco >= totalArcos - 1) {
      primeiroArcoPosicaoZ += distanciaEntrePontoZPadrao
      superficie[arco].push(
        new BABYLON.Vector3(incDistArco, 0, primeiroArcoPosicaoZ)
      )
    } else if ((arco >= 1 && arco <= 3) || arco >= totalArcos - 3) {
      segundoArcoPosicaoZ +=
        distanciaEntrePontoZPadrao +
        incrementoDaDistanciaEntrePontoZPrimeiroArco
      superficie[arco].push(
        new BABYLON.Vector3(incDistArco, 0, segundoArcoPosicaoZ)
      )
    } else if ((arco >= 4 && arco <= 5) || arco >= totalArcos - 5) {
      terceiroArcoPosicaoZ +=
        distanciaEntrePontoZPadrao + incrementoDaDistanciaEntrePontoZSegundoArco
      superficie[arco].push(
        new BABYLON.Vector3(incDistArco, 0, terceiroArcoPosicaoZ)
      )
    } else if ((arco >= 6 && arco <= 7) || arco >= totalArcos - 7) {
      quartoArcoPosicaoZ +=
        distanciaEntrePontoZPadrao +
        incrementoDaDistanciaEntrePontoZTerceiroArco
      superficie[arco].push(
        new BABYLON.Vector3(incDistArco, 0, quartoArcoPosicaoZ)
      )
    } else {
      superficie[arco].push(
        new BABYLON.Vector3(incDistArco, 0, i * distEntreCabos)
      )
    }
  }
  // Superficie lateral final
  if (arco <= 1 || arco >= totalArcos - 1) {
    superficie[arco].push(
      new BABYLON.Vector3(incDistArco, 0, primeiroArcoPosicaoZ)
    )
  } else if ((arco >= 1 && arco <= 3) || arco >= totalArcos - 3) {
    superficie[arco].push(
      new BABYLON.Vector3(incDistArco, 0, segundoArcoPosicaoZ)
    )
  } else if ((arco >= 4 && arco <= 5) || arco >= totalArcos - 5) {
    superficie[arco].push(
      new BABYLON.Vector3(incDistArco, 0, terceiroArcoPosicaoZ)
    )
  } else if ((arco >= 6 && arco <= 7) || arco >= totalArcos - 7) {
    superficie[arco].push(
      new BABYLON.Vector3(incDistArco, 0, quartoArcoPosicaoZ)
    )
  } else {
    superficie[arco].push(
      new BABYLON.Vector3(incDistArco, 0, qtdLinhaLarg * distEntreCabos)
    )
  }
}

const maxDepthCableArcCalc = arc => {
  // Coleta o real nível do produto do armazém
  if (arc !== null || arc !== undefined) {
    let maxVal = 0
    const retorno = _.maxBy(arc.cables, (cabo, cc) => cabo.level, 0)
    if (retorno.level > maxVal) {
      maxVal = retorno.level
    }
    return maxVal
  }
  return 0
}

const fncAltCabo = (cabo, distSensor, termoReport) => {
  if (cabo === undefined) {
    return 0
  }
  return getLevelSensor(cabo, termoReport) * distSensor + distSensor / 2
}

const getLevelSensor = (c, termoReport) => c.level

const fncLevelArmazem = (
  superficie,
  posArcoNivel,
  ultAltCabo,
  altProxima,
  ultPntInit,
  proxPnt,
  umaSuperficie = false
) => {
  // console.log('fncLevelArmazem2 - superficie', superficie)
  // console.log('fncLevelArmazem2 - posArcoNivel', posArcoNivel)
  // console.log('fncLevelArmazem2 - ultAltCabo', ultAltCabo)
  // console.log('fncLevelArmazem2 - altProxima', altProxima)
  // console.log('fncLevelArmazem2 - ultPntInit', ultPntInit)
  // console.log('fncLevelArmazem2 - proxPnt', proxPnt)

  const a = (altProxima - ultAltCabo) / (proxPnt - ultPntInit)
  const b = a * (0 - ultPntInit) + ultAltCabo
  for (let i = ultPntInit; i < proxPnt; i++) {
    const alt = a * i + b
    superficie[posArcoNivel][i].y = alt
    if (!umaSuperficie) {
      superficie[posArcoNivel + 1][i].y = alt
    }
  }
}

const fncAvgProductCompare = (superficie, arco1, arco2, pIni, pFim) => {
  let AisBigger = false
  const totalLarg = superficie[arco1].length
  let contA = 0
  let contB = 0
  for (let x = pIni; x <= pFim; x++) {
    contA += superficie[arco1][x].y
    contB += superficie[arco2][x].y
  }
  const mediaA = contA / totalLarg
  const mediaB = contB / totalLarg
  if (mediaA > mediaB) {
    AisBigger = true
  }
  return AisBigger
}

const NomeObjeto = (
  nome,
  scene,
  corFundo,
  id,
  parent,
  altura,
  tamanho,
  x = 0,
  z = 0,
  fcnSeeTooltip
) => {
  // console.log('parents')
  // console.log(parent)
  const infoObjeto = BABYLON.Mesh.CreatePlane(
    `infoObjeto ${nome}`,
    tamanho,
    scene,
    false
  )

  // BORDA
  infoObjeto.enableEdgesRendering()
  infoObjeto.edgesWidth = 4.0
  infoObjeto.edgesColor = corFundo
  infoObjeto.sideOrientation = BABYLON.Mesh.DOUBLESIDE
  infoObjeto.checkCollisions = true
  //
  infoObjeto.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL
  infoObjeto.position = new BABYLON.Vector3(x - 0.5, altura, z)
  infoObjeto.parent = parent
  const texturaInfoObjeto = new BABYLON.DynamicTexture(
    'texturaInfoObjeto',
    512,
    scene,
    true
  )
  const fontSize = 190
  if (typeof id === 'object') {
    for (let i = 0; i < id.length; i++) {
      texturaInfoObjeto.drawText(
        id[i].val,
        null,
        300 + id[i].tam * i,
        `bold ${id[i].tam}px arial`,
        'black',
        i === 0 ? corFundo : null
      )
    }
  } else {
    texturaInfoObjeto.drawText(
      id,
      null,
      300,
      `bold ${fontSize}px arial`,
      'black',
      corFundo
    )
  }
  infoObjeto.material = new BABYLON.StandardMaterial(
    'materialSuperficie',
    scene
  )
  infoObjeto.material.diffuseTexture = texturaInfoObjeto
  infoObjeto.material.specularColor = new BABYLON.Color3(0, 0, 0)
  infoObjeto.material.emissiveColor = new BABYLON.Color3(1, 1, 1)
  infoObjeto.material.backFaceCulling = false
  infoObjeto.isPickable = true
  // texturaInfoObjeto.hasAlpha = true;
  infoObjeto.material.freeze()

  infoObjeto.actionManager = new BABYLON.ActionManager(scene)

  infoObjeto.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(
      BABYLON.ActionManager.OnPointerOverTrigger,
      function (ev) {
        fcnSeeTooltip(parent.sensor, scene, parent, parent.absolutePosition)
      }
    )
  )

  infoObjeto.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(
      BABYLON.ActionManager.OnPointerOutTrigger,
      function (ev) {
        fcnSeeTooltip('remove', scene, parent, parent.absolutePosition)
      }
    )
  )
}

const NomeCabo = (
  scene,
  corFundo,
  id,
  parent,
  altura,
  tamanho,
  x = 0,
  z = 0
) => {
  const nomeObjeto = BABYLON.Mesh.CreatePlane('nomeCabo', tamanho, scene, false)
  nomeObjeto.billboardMode = BABYLON.AbstractMesh.BILLBOARDMODE_ALL
  nomeObjeto.position = new BABYLON.Vector3(x, altura + 6, z)
  nomeObjeto.parent = parent
  const texturaNomeCabo = new BABYLON.DynamicTexture(
    'texturaNomeCabo',
    512,
    scene,
    true
  )
  const fontSize = 250
  if (typeof id === 'object') {
    for (let i = 0; i < id.length; i++) {
      if (
        id[i].val === 'CABO'
      ) {
        texturaNomeCabo.drawText(
          id[i].val,
          null,
          200 + id[i].tam * i,
          'bold 150px arial',
          'white',
          i === 0 ? corFundo : null
        )
      } else {
        texturaNomeCabo.drawText(
          id[i].val,
          null,
          200 + id[i].tam * i,
          'bold 250px arial',
          'white',
          i === 0 ? corFundo : null
        )
      }
    }
  } else {
    texturaNomeCabo.drawText(
      id,
      null,
      200,
      `bold ${fontSize}px arial`,
      'white',
      corFundo
    )
  }
  nomeObjeto.material = new BABYLON.StandardMaterial(
    'materialSuperficieCabo',
    scene
  )
  nomeObjeto.material.diffuseTexture = texturaNomeCabo
  nomeObjeto.material.specularColor = new BABYLON.Color3(0, 0, 0)
  nomeObjeto.material.emissiveColor = new BABYLON.Color3(1, 1, 1)
  nomeObjeto.material.backFaceCulling = false
  nomeObjeto.isPickable = false
  texturaNomeCabo.hasAlpha = true
}

// Função que ajusta dinamicidade da altura do telhado do armazem
function getAjusteDaAlturaTelhadoDinamico(largura) {
  const totalLoops = Math.trunc(largura / 10)
  let incrementoAltura

  let result = 0.21
  for (let i = 0; i < totalLoops; i++) {
    if (i >= 6) {
      incrementoAltura = 0.02
    } else {
      incrementoAltura = 0.03
    }
    result += incrementoAltura
  }
  return result
}
// Função que ajusta dinamicidade da largura telhado do armazem
function getAjusteDaLarguraTelhadoDinamico(largura) {
  const totalLoops = Math.trunc(largura / 10)
  let incrementoLargura

  let result = -0.019
  for (let i = 0; i < totalLoops; i++) {
    if (i >= 4) {
      incrementoLargura = 0.055
    } else {
      incrementoLargura = 0.05
    }
    result += incrementoLargura
  }
  return result
}

function getLarguraFaceDinamico(largura) {
  return Math.trunc(largura / 10) * 0.667
}
