// Basic
import React from 'react';
import { Grid } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import CheckBoxTwoToneIcon from '@material-ui/icons/CheckBoxTwoTone';
import CheckBoxOutlineBlankTwoToneIcon from '@material-ui/icons/CheckBoxOutlineBlankTwoTone';
// import WarningIcon from '@material-ui/icons/Warning';
import OpacityIcon from '@material-ui/icons/Opacity';
import FormatColorResetIcon from '@material-ui/icons/FormatColorReset';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';
import CardText from '../../../../../components/CardText';
import CardTitle from '../../../../../components/CardTitle';
import Card from '../../../../../components/Card';
import Spacer from '../../../../../components/Spacer';
import colors from '../../../../../styles/colors';
import './RecipeCard.scss';

export default (props) => {
  // const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
  // const [progress, setProgress] = React.useState(0);
  // const getWeekDay = (day) => weekDays[new Date(`${day}T20:00`).getDay()];
  function getStatusText() {
    if (props.data.all.completed) {
      return <div style={{ color: colors.success }}>Finalizado</div>;
    }
    if (props.data.current) {
      return <div>Em Andamento</div>;
    }
    return <div style={{ color: colors.warning }}>Aguardando</div>;
  }
  const getStatusIcon = (status) =>
    status ? (
      <Tooltip title="Ligado">
        <CheckBoxTwoToneIcon style={{ color: colors.success }} />
      </Tooltip>
    ) : (
      <Tooltip title="Desligado">
        <CheckBoxOutlineBlankTwoToneIcon style={{ color: colors.darkGrey }} />
      </Tooltip>
    );
  const getWaterIcon = (status) =>
    status === 1 ? (
      <Tooltip title="Com Água">
        <OpacityIcon style={{ color: colors.info, fontSize: '42px' }} />
      </Tooltip>
    ) : (
      <Tooltip title="Sem Água">
        <FormatColorResetIcon
          style={{ color: colors.darkGrey, fontSize: '42px' }}
        />
      </Tooltip>
    );
  const getMM = (water) => {
    if (water.status) {
      return <small>mm</small>;
    }
    return null;
  };
  const getDiretction = () => {
    if (props.data.direction === 0) {
      return (
        <Tooltip title="Desligado">
          <ClearIcon style={{ color: colors.darkGrey, fontSize: '42px' }} />
        </Tooltip>
      );
    }
    if (props.data.direction === 1) {
      return (
        <Tooltip title="Rotação a Direita">
          <RotateRightIcon
            style={{ color: colors.pivotOk, fontSize: '42px' }}
          />
        </Tooltip>
      );
    }
    if (props.data.direction === 2) {
      return (
        <Tooltip title="Rotação a Esquerda">
          <RotateLeftIcon style={{ color: colors.pivotOk, fontSize: '42px' }} />
        </Tooltip>
      );
    }
    return 'NDef';
  };
  // function getPercentage() {
  //   let percentage = 0;
  //   if (props.data.all.completed) {
  //     percentage = 100;
  //   } else if (props.data.direction === 1) {
  //     const range =
  //       props.data.all.startingPosition < props.data.all.finalPosition
  //         ? props.data.all.finalPosition - props.data.all.startingPosition
  //         : props.data.all.finalPosition +
  //           360 -
  //           props.data.all.startingPosition;
  //     percentage =
  //       props.data.all.startingPosition < props.data.all.finalPosition
  //         ? (100 *
  //             (props.data.all.positionActual -
  //               props.data.all.startingPosition)) /
  //           range
  //         : (100 *
  //             (props.data.all.positionActual > props.data.all.finalPosition
  //               ? props.data.all.positionActual -
  //                 props.data.all.startingPosition
  //               : props.data.all.positionActual +
  //                 360 -
  //                 props.data.all.startingPosition)) /
  //           range;
  //   } else if (props.data.direction === 2) {
  //     const range =
  //       props.data.all.startingPosition > props.data.all.finalPosition
  //         ? props.data.all.finalPosition - props.data.all.startingPosition
  //         : props.data.all.finalPosition +
  //           360 -
  //           props.data.all.startingPosition;
  //     percentage =
  //       props.data.all.startingPosition < props.data.all.finalPosition
  //         ? (100 *
  //             (props.data.all.positionActual -
  //               props.data.all.startingPosition)) /
  //           range
  //         : (100 *
  //             (props.data.all.positionActual > props.data.all.finalPosition
  //               ? props.data.all.positionActual -
  //                 props.data.all.startingPosition
  //               : props.data.all.positionActual +
  //                 360 -
  //                 props.data.all.startingPosition)) /
  //           range;
  //   } else {
  //     percentage = 100;
  //   }
  //   return percentage;
  // }
  // React.useEffect(() => {
  //   setProgress(getPercentage());
  // }, []);
  return (
    <div
      id={props.id}
      className="recipeItem"
      onKeyPress={(e) => {
        if (e.key === 'Enter' && !props.isPlaying) props.setCurrent(props.data);
      }}
      onClick={() => {
        if (!props.isPlaying) {
          props.setCurrent(props.data);
        }
      }}
      role="button"
      tabIndex={0}
      style={{
        backgroundColor: `${
          props.current === props.data.id
            ? colors.primaryUserBright
            : colors.transparent
        }`,
      }}
    >
      <Card
        square
        hover
        color={props.data.current ? colors.active : colors.white}
      >
        <CardTitle
          color={
            props.data.all.completed ? colors.transparent : colors.primaryUser
          }
          textColor={props.data.all.completed ? colors.darkGrey : colors.white}
          size="0.75rem"
          pa="8"
          pt="2"
          pb="2"
        >
          Status:
          <Spacer />
          {getStatusText()}
        </CardTitle>
        <CardText pa="8" pt="2">
          {/* Porcentagem */}
          {/* <div style={{ margin: '0px auto 4px', width: '100%' }}>
            <Tooltip title={`${Math.ceil(progress)}% concluídos`}>
              <LinearProgress variant="determinate" value={progress} />
            </Tooltip>
          </div> */}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} lg={3}>
              <Card
                similar
                square
                outlined
                color={props.data.current ? colors.primaryUser : colors.grey}
              >
                <CardTitle size="0.75rem" pa="2px">
                  <Spacer />
                  Período:
                  <Spacer />
                </CardTitle>
                <CardText pa="4px">
                  <Grid
                    container
                    alignItems="center"
                    alignContent="center"
                    justifyContent="center"
                  >
                    <Grid item xs={5}>
                      <div>
                        <h5
                          style={{
                            margin: '0',
                            lineHeight: '14px',
                            textAlign: 'center',
                          }}
                        >
                          {props.data.dateStart.day || '--'}
                        </h5>
                        <h5
                          style={{
                            margin: '0',
                            lineHeight: '14px',
                            textAlign: 'center',
                          }}
                        >
                          {props.data.dateStart.weekDay || '--'}
                        </h5>
                        <h5
                          style={{
                            margin: '0',
                            lineHeight: '14px',
                            textAlign: 'center',
                          }}
                        >
                          {props.data.dateStart.time || '--'}
                        </h5>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <ArrowRightAltIcon />
                    </Grid>
                    <Grid item xs={5}>
                      <div>
                        <h5
                          style={{
                            margin: '0',
                            lineHeight: '14px',
                            textAlign: 'center',
                          }}
                        >
                          {props.data.dateFinish.day || '--'}
                        </h5>
                        <h5
                          style={{
                            margin: '0',
                            lineHeight: '14px',
                            textAlign: 'center',
                          }}
                        >
                          {props.data.dateFinish.weekDay || '--'}
                        </h5>
                        <h5
                          style={{
                            margin: '0',
                            lineHeight: '14px',
                            textAlign: 'center',
                          }}
                        >
                          {props.data.dateFinish.time || '--'}
                        </h5>
                      </div>
                    </Grid>
                  </Grid>
                </CardText>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Card
                similar
                square
                outlined
                color={props.data.current ? colors.primaryUser : colors.grey}
              >
                <CardTitle size="0.75rem" pa="2px">
                  <Spacer />
                  Irrigação:
                  <Spacer />
                </CardTitle>
                <CardText pa="4px">
                  <Grid
                    container
                    alignItems="center"
                    alignContent="center"
                    justifyContent="center"
                  >
                    <Grid item xs={6}>
                      {getWaterIcon(props.data.all.recipeWater)}
                    </Grid>
                    <Grid item xs={6}>
                      <h3 style={{ margin: '2px 0px 2px' }}>
                        {`${props.data.water.size}`}
                        {getMM(props.data.water)}
                      </h3>
                    </Grid>
                  </Grid>
                </CardText>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Card
                similar
                square
                outlined
                color={props.data.current ? colors.primaryUser : colors.grey}
              >
                <CardTitle size="0.75rem" pa="2px">
                  <Spacer />
                  Sentido:
                  <Spacer />
                </CardTitle>
                <CardText pa="4px">
                  <Grid container alignItems="center" alignContent="center">
                    <Grid item xs={4}>
                      <h5
                        style={{
                          margin: '0px 0px',
                          lineHeight: '14px',
                          fontWeight: '200',
                          fontSize: '12px',
                        }}
                      >
                        Origem:
                      </h5>
                      <h5
                        style={{
                          margin: '0px 0px',
                          lineHeight: '16px',
                          fontSize: '16px',
                        }}
                      >
                        {props.data.startingPosition}
                      </h5>
                    </Grid>
                    <Grid item xs={4}>
                      {getDiretction()}
                    </Grid>
                    <Grid item xs={4}>
                      <h5
                        style={{
                          margin: '0px 0px',
                          lineHeight: '14px',
                          fontWeight: '200',
                          fontSize: '12px',
                        }}
                      >
                        Destino:
                      </h5>
                      <h5
                        style={{
                          margin: '0px 0px',
                          lineHeight: '16px',
                          fontSize: '16px',
                        }}
                      >
                        {props.data.finalPosition}
                      </h5>
                    </Grid>
                  </Grid>
                </CardText>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <Card
                similar
                square
                outlined
                color={props.data.current ? colors.primaryUser : colors.grey}
              >
                <CardTitle size="0.75rem" pa="2px">
                  <Spacer />
                  Programação:
                  <Spacer />
                </CardTitle>
                <CardText pa="4px">
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                      color: props.data.recipeLoop
                        ? colors.success
                        : colors.darkGrey,
                    }}
                  >
                    {getStatusIcon(props.data.recipeLoop)}
                    Repetir programa
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      alignItems: 'center',
                      color: props.data.stHP ? colors.success : colors.darkGrey,
                    }}
                  >
                    {getStatusIcon(props.data.stHP)}
                    Horário de ponta
                  </div>
                </CardText>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card
                square
                similar
                outlined
                color={props.data.current ? colors.primaryUser : colors.grey}
              >
                <CardTitle size="0.75rem" pa="2px">
                  Avisos:
                </CardTitle>
                <CardText pa="4px">
                  <div className="warnings">
                    {Object.keys(props.alerts).map((key) => (
                      <div>
                        <span>
                          {`${new Date(props.alerts[key][0]).toLocaleString(
                            'pt-BR',
                          )}: `}
                        </span>
                        <span
                          className={`${
                            key === 'Falha de Emergência'
                              ? 'error_alert'
                              : 'error_warning'
                          }`}
                        >
                          {`${key}`}
                        </span>
                      </div>
                    ))}
                  </div>
                </CardText>
              </Card>
            </Grid>
          </Grid>
        </CardText>
      </Card>
    </div>
  );
};
