import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Paper from '@material-ui/core/Paper';
// import WarningIcon from '@material-ui/icons/Warning';
import Skeleton from '@material-ui/lab/Skeleton';
import { number } from '../../../../../helpers/Intl';

export default (props) => {
  const maxValue = props.capacity;
  const state = {
    series: [
      maxValue === 0 ? 100 : ((props.currentPower / 1000) / maxValue) * 100,
    ],
    options: {
      chart: {
        height: 150,
        type: 'radialBar',
        offsetY: -10,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: '30%',
          },
          startAngle: -90,
          endAngle: 90,
          dataLabels: {
            name: {
              fontSize: '0.875rem',
              color: 'gray',
              offsetY: 61,
            },
            value: {
              offsetY: 18,
              fontSize: '1.5em',
              color: '#727272',
              fontWeight: '600',
              formatter() {
                return maxValue === 0 ? `${number(props.currentPower / 1000)} kW` : `${number(props.currentPower / 1000)} kW / ${number(((props.currentPower / 1000) / maxValue) * 100)}%`;
              },
            },
          },
        },
      },
      labels: [maxValue === 0 ? 'Configure a Capacidade' : `Capacidade: ${number(maxValue)} kW`],
    },
  };

  // const getIcon = () => (<WarningIcon style={{ color: 'red' }} />);

  if (props.loading) {
    return <Skeleton variant="rect" height="214px" width="100%" animation="wave" />;
  }

  return (
    <Paper style={{ padding: '15px', height: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3 style={{ margin: '0', fontWeight: '500', marginRight: 5 }}>
          Status
        </h3>
        {props.auroraVisionComponent}
      </div>
      <ReactApexChart options={state.options} series={state.series} type="radialBar" height={state.options.chart.height} />
      {/* <h1 style={{ margin: '0', textAlign: 'center' }}>
        {getIcon(status)}
        {' '}
        {status}
      </h1> */}
    </Paper>
  );
};
