import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-width: 200px;
  max-width: 450px;
`;

export const Content = styled.div`
  width: 720px;
  padding: 1rem;
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 720px) {
    width: 100%;
  }
`;
