import React from 'react';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

export default (props) => {
  const [redirect, setRedirect] = React.useState(false);

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <Button
      disabled={props.isDisabled}
      size="large"
      variant="contained"
      color="default"
      style={{ background: '#828282', color: 'white', ...props.style }}
      onClick={() =>
        props.back ? props.back() : setRedirect(props.redirectTo)
      }
      startIcon={<KeyboardBackspaceIcon />}
    >
      {props.customBackText || 'Voltar'}
    </Button>
  );
};
