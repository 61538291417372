import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Grid } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import AddressFields from '../../../../../components/AddressFields';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import FockinkAPI from '../../../../../middlewares/Api';
import { AccordionContainer } from '../../../../../components/ExpandAccordion';
import BottomButtons from '../../../../../components/Buttons/BottomButtons';
import PortalPermissions from '../../../../../helpers/enums/PortalPermissions';
import { Input } from '../../../../../components';

import { handleMessageError } from '../../../../../helpers/handlerMessageError';
import { FilesToDownload } from '../../FilasToDownloadList';

import * as S from './styles';

export function IndustrialAutomationSettings(props) {
  const { id } = useParams();

  const [settignsData, setSettignsData] = useState({});
  const [expanded, setExpanded] = useState('equipaments');
  const [breadcrumbs, setBreadcrumbs] = useState(null);
  const [location, setLocation] = useState();
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const whatRoute = urlParams.get('whatRoute');
  const [
    availableFilesToDownload,
    setAvailableFilesToDownload,
  ] = React.useState([]);

  const MySwal = withReactContent(Swal);

  const handleGetEquipamentSettings = async () => {
    try {
      setLoading(true);
      const response = await FockinkAPI.get(`equipaments/${id}/settings`);
      setLocation(response.data.data.settings.location || {});

      setSettignsData(response.data.data.clientDescription || null);

      setBreadcrumbs({
        routes: [
          { name: 'Equipamentos', to: '/equipamentData' },
          {
            name:
              response.data.data.clientDescription ||
              response.data.data.description,
            to: `/automationDash/${id}`,
          },
          { name: 'Configurações' },
        ],
      });
      setLoading(false);
    } catch (error) {
      setLoading(true);
      handleMessageError(error);
    }
  };

  const getDocumentsFileToDonwload = async () => {
    try {
      const response = await FockinkAPI.get(`equipaments/${id}/readFiles`);
      const fomattedFileObject = response.data.data.map((file) => ({
        file,
        fileName: file.split('/').pop(),
      }));

      setAvailableFilesToDownload(fomattedFileObject);
    } catch (error) {
      handleMessageError(error);
    }
  };

  useEffect(() => {
    (async () => {
      await getDocumentsFileToDonwload();
      await handleGetEquipamentSettings();
    })();
  }, []);

  const handleExpandendAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChangeLocation = (l) => {
    setLocation(l);
  };

  const handleChangeClientDescription = (event) => {
    setSettignsData(event.target.value);
  };

  const throwWarning = (text) =>
    MySwal.fire({
      title: text,
    });

  const checkValidation = () => {
    if (!location.address1 || !location.address1.length > 60) {
      throwWarning('Endereço 1 deve ter entre 1 e 60 caracteres');
      return false;
    }

    const tests = [
      { text: 'CEP', input: 'postalCode' },
      { text: 'Cidade', input: 'city' },
      { text: 'Estado', input: 'state' },
      { text: 'Pais', input: 'country' },
      { text: 'Latitude', input: 'lat' },
      { text: 'Longitude', input: 'lon' },
    ]
      .map((e) => {
        if (!location[e.input]) {
          throwWarning(`${e.text} deve ser preenchido`);
          return false;
        }
        return true;
      })
      .reduce((a, b) => a && b);

    if (!tests) {
      return false;
    }

    return true;
  };

  const saveData = () => {
    const valid = checkValidation();
    if (valid) {
      const dt = {
        data: {
          location,
          clientDescription: settignsData,
        },
        url: `/equipaments/${id}/automationSettings`,
        method: 'put',
      };

      return dt;
    }
    return undefined;
  };

  if (loading) {
    return (
      <>
        <Breadcrumbs loading={loading} />
        <Grid container spacing={2}>
          {[0, 1, 2, 3].map((k) => (
            <React.Fragment key={k}>
              <Grid item xs={12}>
                <Skeleton
                  variant="rect"
                  height={20}
                  width={100}
                  animation="wave"
                />
                <hr />
              </Grid>
              {[0, 1, 2, 3, 4, 5, 6].map((j) => (
                <Grid item key={j} xs={12} md={12} lg={3}>
                  <Skeleton
                    key={`${k}${j}`}
                    variant="rect"
                    height={50}
                    width="100%"
                    animation="wave"
                  />
                </Grid>
              ))}
            </React.Fragment>
          ))}
        </Grid>
      </>
    );
  }

  return (
    <S.Wrapper>
      {breadcrumbs && <Breadcrumbs routes={breadcrumbs.routes} />}

      <S.Content>
        <AccordionContainer
          title="Descrição do Equipamento"
          expanded={expanded}
          name="clientDescription"
          handleExpandAccordion={handleExpandendAccordion}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} md={12} lg={3}>
              <Input
                variant="filled"
                name="clientDescription"
                placeholder="Defina uma descrição para o equipamento"
                label="Defina uma descrição para o equipamento"
                onChange={handleChangeClientDescription}
                value={settignsData}
              />
            </Grid>
          </Grid>
        </AccordionContainer>

        {location && (
          <AccordionContainer
            title="Endereço"
            expanded={expanded}
            name="address"
            handleExpandAccordion={handleExpandendAccordion}
          >
            <AddressFields
              handleChangeLocation={handleChangeLocation}
              data={location}
            />
          </AccordionContainer>
        )}

        <AccordionContainer
          title="Arquivos e Manuais"
          expanded={expanded}
          name="manuasl"
          handleExpandAccordion={handleExpandendAccordion}
        >
          <FilesToDownload files={availableFilesToDownload} />
        </AccordionContainer>
      </S.Content>

      <S.Options>
        <BottomButtons
          saveData={
            (expanded === 'address' || expanded === 'clientDescription') &&
            saveData
          }
          backTo={(whatRoute === 'Equips' ? `/automationDash/${props.match.params.id}?whatRoute=Equips` : `/automationDash/${id}`)}
          canSave={PortalPermissions.THERMOMETRY_SETTINGS_EDIT.value}
        />
      </S.Options>
    </S.Wrapper>
  );
}
