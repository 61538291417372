import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 420px;

  form {
    width: 100%;
    max-width: 720px;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

     > span {
      font-size: 1rem;
      margin-bottom: 0.6rem;
    }

    .success {
      color: #07AC10;
      margin-top: 1rem;
      font-weight: 500;

    }

    .color-picker {
      margin-top: 1rem;
    }

    .confirm-button {
      display: block;
      margin-left: auto;
      margin-top: 1rem;
    }
  }
`;

export const ErrorText = styled.strong`
  color: #c53030;
  margin-top: 0.7rem;
`;
