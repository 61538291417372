import React from 'react';
import { SiderGrainQuality } from './components/SiderGrainQuality';
import { useDashboardContext } from '../../../hooks/dashboard';
import Card from '../../Card';
import CardText from '../../CardText';
import CardTitle from '../../CardTitle';

export function GrainQualityDashboardCard() {
  const { data, plantIdToRequest } = useDashboardContext();
  const { dataGrain } = data;
  return (

    <Card pt="4px" pr="4px" pl="4px" pb="0">
      <CardTitle pt="4px" pr="4px" pl="4px" pb="0" size="1rem">
        Qualidade do Grão
        {' '}
        {plantIdToRequest && '(Planta)'}

      </CardTitle>
      <CardText pt="4px" pr="4px" pl="4px" pb="10px" size="1rem">
        {dataGrain && <SiderGrainQuality grainQuality={dataGrain} />}
      </CardText>
    </Card>
  );
}
