import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../App.css';
import { Redirect } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FockinkAPI from '../../../middlewares/Api';
import SaveButton from '../../../components/Buttons/SaveButton';
import Breadcrumbs from '../../../components/Breadcrumbs';
import NewPasswordChecker from '../../../components/NewPasswordChecker';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

export default () => {
  const [returnToLogin, setReturnToLogin] = React.useState(false);
  const [token, setToken] = React.useState(undefined);
  const [isSaving, setIsSaving] = React.useState(false);
  const [data, setData] = React.useState({ ready: false });
  const MySwal = withReactContent(Swal);

  const breadcrumb = {
    title: 'Nova Senha',
    routes: [
      { name: 'Nova Senha' }],
  };

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenInt = urlParams.get('token');
    setToken(tokenInt);
  }, []);

  const classes = useStyles();

  const save = () => {
    setIsSaving(true);
    const sendData = {
      newPassword: data.pw,
      confNewPassword: data.checkPw,
      token: token || localStorage[process.env.REACT_APP_STORAGE_KEY],
    };
    FockinkAPI.post('/login/saveNewPassword', sendData).then(() => {
      MySwal.fire({
        icon: 'success',
        title: 'Sua senha foi definida com sucesso!',
        text: 'Você já tem acesso a plataforma.',
        confirmButtonText: 'Ir Para Login',
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          setReturnToLogin(true);
        }
      });
    }).catch((e) => {
      setIsSaving(false);
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: e.response.data.reason || e.response.data.message,
      });
    });
  };

  if (returnToLogin) return <Redirect push to="/logout" />;

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1} style={{ padding: '0 24px', minHeight: 'calc(100vh - 101px)' }}>

        {localStorage.accountId && (
          <Grid item xs={12}>
            <h3 style={{ fontWeight: 400, marginTop: 0 }}>
              Você precisa mudar sua senha para continuar com o acesso
            </h3>
          </Grid>
        )}

        <Grid item xs={12}>
          <NewPasswordChecker data={data} setData={setData} />
        </Grid>

        <Grid item xs={12} className={`${classes.textAlign} ${classes.bottomButtons}`}>
          <SaveButton
            save={save}
            isSaving={isSaving}
            className={classes.btnSpace}
            notComplete={!data.ready}
          />
        </Grid>
      </Grid>
    </>
  );
};
