// Basic
import React from 'react';

// UI, images, styles and icons
import '../App.css';
import {
  MapContainer, TileLayer, Marker, Tooltip as LeafletTooltip,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'; import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});

L.Marker.prototype.options.icon = DefaultIcon;

export default (props) => {
  const [latLon, setLatLon] = React.useState(undefined);
  const [bounds, setBounds] = React.useState([
    [5.536711886793118, -68.08021545410158], [-24.512141466217635, -51.5643310546875]]);

  React.useEffect(() => {
    if (props.lat !== undefined && props.lon !== undefined) {
      setLatLon({ lat: props.lat, lon: props.lon });
    }
    setBounds([[props.lat, props.lon]]);
  }, [props.lat, props.lon]);

  React.useEffect(() => {
    if (props.handleClick) {
      props.handleClick(latLon);
    }
  }, [latLon]);

  return (
    <MapContainer
      bounds={bounds}
      maxZoom={17}
      style={{ height: '450px' }}
      scrollWheelZoom={false}
      whenReady={(map) => {
        map.target.on('click', (e) => {
          const { lat, lng } = e.latlng;
          // console.log('latLon', lat, lng);
          setLatLon({ lat, lon: lng });
        });
      }}
    >
      <TileLayer
        attribution="Google Hybrid"
        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        url="https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
        // url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
      />

      {latLon && (
        <Marker
          // icon={Icon(details[i.operationId].icon)}
          position={[latLon.lat, latLon.lon]}
        >
          <LeafletTooltip>Posição</LeafletTooltip>
        </Marker>
      )}

    </MapContainer>
  );
};
