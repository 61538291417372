import React from 'react';
import DataTable from 'react-data-table-component';
import { columns } from './makeTelemetryTableColumns';

export function TDApproversTable({ data, aditionalButtons }) {
  const formattedColumns = [...columns, ...aditionalButtons];

  return (
    <DataTable
      pagination
      paginationPerPage={10}
      paginationComponentOptions={{
        rowsPerPageText: 'Linhas:',
        rangeSeparatorText: 'de',
      }}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
      noDataComponent="Não existem dados para serem mostrados"
      columns={formattedColumns}
      data={data}
      defaultSortField="friendlyName"
    />
  );
}
