/* eslint-disable indent */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../../App.css';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import WarningIcon from '@material-ui/icons/Warning';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import WbCloudyIcon from '@material-ui/icons/WbCloudy';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import NavigationIcon from '@material-ui/icons/Navigation';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import Card from '../../../../../components/Card';
import CardText from '../../../../../components/CardText';
import CardTitle from '../../../../../components/CardTitle';
import CardActions from '../../../../../components/CardActions';
import FockinkAPI from '../../../../../middlewares/Api';
import { number, formatDate } from '../../../../../helpers/Intl';
import Spacer from '../../../../../components/Spacer';
import colors from '../../../../../styles/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  dayHead: {
    textAlign: 'center',
    margin: '0 0 17px',
  },
  dayHeadTemp: {
    textAlign: 'center',
    margin: '0 0 17px',
  },
  eachBox: {
    borderRight: '1px solid gray',
  },
  firstBox: {
    borderRight: '3px solid gray',
  },
  heading: {
    fontSize: '1.17em',
    fontWeight: '500',
    flexBasis: '40%',
    flexShrink: 0,
    margin: 'auto 0',
  },
  source: {
    textAlign: 'right',
    margin: 'auto 0',
    flexBasis: '45%',
    flexShrink: 0,
    fontSize: '14px',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    margin: 'auto 3px',
    textAlign: 'left',
  },
  titleSub: {
    fontSize: '13px',
    fontWeight: '500',
    margin: 'auto 3px',
    textAlign: 'left',
  },
  sourceSub: {
    fontSize: '12px',
    fontWeight: '300',
    margin: '5px 3px 0',
    textAlign: 'left',
  },
  tempSub: {
    fontSize: '13px',
    fontWeight: 'bold',
    margin: 'auto 3px',
    textAlign: 'left',
  },
  secondaryHeadingTitle: {
    fontSize: '12px',
  },
  secondaryHeadingValue: {
    fontWeight: '500',
    fontSize: '17px',
  },
  warningText: {
    display: 'flex',
    fontWeight: '500',
    fontSize: '16px',
    margin: 'auto 3px',
    textAlign: 'left',
  },
  iconTemp: {
    fontSize: '30px',
    margin: 'auto',
    width: '100%',
  },
  iconTempImg: {
    background: 'grey',
    borderRadius: '10px',
    maxHeight: '40px',
  },
  iconOut: {
    fontSize: '21px',
    marginTop: '5px',
  },
  iconText: {
    textAlign: 'center',
  },
}));

export default (props) => {
  const classes = useStyles();
  const [data, setData] = React.useState(undefined);
  const [expiredTelemetry, setExpiredTelemetry] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [modal, setModal] = React.useState(false);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const formatData = (date) => {
    const reg = date.match(/(\d{4})-(\d{2})-(\d{2})/);
    if (reg) {
      return `${reg[3]}/${reg[2]}`;
    }
    return date;
  };

  const getIcon = (iconUrl, iconId) => {
    if (iconUrl) {
      return <img src={iconUrl} alt="" className={classes.iconTempImg} />;
    }
    if (iconId) {
      return (
        <img
          src={`/images/accuweatherIcons/${iconId}-s.png`}
          alt=""
          className={classes.iconTempImg}
        />
      );
    }
    return <WbSunnyIcon className={classes.iconTemp} />;
  };

  const formatPredictDataLabel = (dataToFormat, signalPrediction) => {
    if (dataToFormat === 999) {
      return 'OFF';
    }
    if (dataToFormat === 998) {
      return 'N/D';
    }
    if (dataToFormat === 997) {
      return 'ERR';
    }
    if (dataToFormat === -9999999) {
      return ' ';
    }

    const predictionWeather = `${number(dataToFormat)}${signalPrediction}`;
    return predictionWeather;
  };

  const callApi = () => {
    setLoading(true);
    FockinkAPI.get(
      `/dashboards/thermometry/${props.equipamentId}/weatherStation`,
    ).then((response) => {
      setData(response.data.data.weatherStation);
      const weatherDate = new Date(
        response.data.data.weatherStation.current.date,
      );
      const dateDiff = weatherDate.getTime() - new Date().getTime();
      setExpiredTelemetry(Math.abs(dateDiff) > 1200000);
      props.setConnection({
        connection: response.data.data.connection,
        running: response.data.data.running,
        failures: response.data.data.failures,
      });
      setLoading(false);
    });
  };

  React.useEffect(() => {
    callApi();
    const interval = setInterval(() => {
      callApi();
    }, 600000);
    return () => clearInterval(interval);
  }, [props.equipamentId]);

  if (loading) {
    return (
      <Skeleton variant="rect" height="100%" width="100%" animation="wave" />
    );
  }

  return (
    <Card similar>
      <CardTitle size="1.17em" pb="4px">Tempo e Previsão</CardTitle>
      <CardText pt="0">
        <Card
          outlined
          hover
          changeBgColor
          color={colors.primaryUserBrighter}
          onClick={(e) => {
            e.stopPropagation();
            setModal(true);
          }}
        >
          <CardTitle pt="4px" pb="0" pl="8px" pr="8px" size="0.75rem">
            Hoje
          </CardTitle>
          <CardText pt="4px" pr="4px" pl="4px" pb="0px">
            <Grid
              container
              spacing={0}
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              {expiredTelemetry ? (
                <Grid item xs={12} alignItems="center" alignContent="center">
                  <div
                    style={{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      justifyItems: 'center',
                      height: '124px',
                    }}
                  >
                    <div style={{ marginRight: '12px' }}>
                      <WarningIcon />
                    </div>
                    <div>
                      <h4 className={classes.warningText}>
                        ATENÇÃO: Sem comunicação com a estação meteorológica
                        local a mais de 20 minutos
                      </h4>
                    </div>
                  </div>
                </Grid>
              ) : (
                <>
                  <Grid
                    item
                    xs={6}
                    alignItems="center"
                    alignContent="center"
                  >
                    {data.current.rainStatus === 0 ? (
                      <Tooltip title="Sem Chuva">
                        <div style={{ width: '36px' }}>
                          <WbSunnyIcon className={classes.iconTemp} />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Chovendo">
                        <div style={{ width: '36px' }}>
                          <WbCloudyIcon className={classes.iconTemp} />
                        </div>
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    alignItems="center"
                    alignContent="center"
                  >
                    {!expiredTelemetry && (
                    <span style={{ fontSize: '1.75rem', fontWeight: '800' }}>
                      {`${formatPredictDataLabel(data.current.temp, 'ºC')}`}
                    </span>
            )}
                  </Grid>

                  {/* <Grid item xs={6} sm={6} md={4} lg={6} xl={4}>
                    <small style={{ fontSize: '13px', fontWeight: '200' }}>
                      Precip.
                    </small>
                    <h4
                      className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                    >
                      {data.forecast
                        ? formatPredictDataLabel(
                            data.forecast.list[0].pop * 100,
                            '%',
                          )
                        : '-'}
                    </h4>
                  </Grid> */}

                  {/* <Grid item xs={6} sm={6} md={4} lg={6} xl={4}>
                    <small style={{ fontSize: '13px', fontWeight: '200' }}>
                      Umid.
                    </small>
                    <h4
                      className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                    >
                      {formatPredictDataLabel(data.current.humid, '%')}
                    </h4>
                  </Grid> */}

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    alignItems="center"
                    alignContent="center"
                  >
                    <div>
                      {/* <small style={{ fontSize: '13px', fontWeight: '200' }}>
                        Chovendo:
                      </small> */}
                      <span
                        className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                      >
                        {data.current.rainStatus === 1
                          ? 'Chovendo'
                          : 'Sem Chuva'}
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={12} alignItems="center" alignContent="center">
                    <div>
                      <small style={{ fontSize: '13px', fontWeight: '200' }}>
                        Umidade:
                      </small>
                      <span
                        className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                      >
                        {formatPredictDataLabel(data.current.humid, '%')}
                      </span>
                    </div>
                  </Grid>

                  {/* <Grid item xs={6} sm={6} md={4} lg={6} xl={4}>
                    <small style={{ fontSize: '13px', fontWeight: '200' }}>
                      UV
                    </small>
                    <h4
                      className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                    >
                      {formatPredictDataLabel(
                        data.forecast.list[0].uvIndex,
                        '%',
                      )}
                    </h4>
                  </Grid> */}

                </>
              )}
            </Grid>
            <Dialog
              fullWidth
              maxWidth="lg"
              fullScreen={sm}
              open={modal}
              onClose={() => {
                setModal(false);
              }}
            >
              <CardTitle
                color={sm ? '#3d5059' : ''}
                textColor={sm ? '#FFF' : ''}
              >
                Tempo e previsão
                <Spacer />
                <CloseIcon
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setModal(false);
                  }}
                />
              </CardTitle>
              <DialogContent>
                <DialogContentText>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <Card>
                        <CardTitle
                          pt="8px"
                          pb="8px"
                          pl="8px"
                          pr="8px"
                          size="1rem"
                        >
                          Hoje
                          <Spacer />
                          <span>
                            {formatPredictDataLabel(data.current.temp, '°C')}
                          </span>
                        </CardTitle>

                        <CardText>
                          <Grid
                            container
                            spacing={1}
                            alignContent="center"
                            alignItems="center"
                          >
                            {expiredTelemetry ? (
                              <Grid
                                item
                                xs={12}
                                alignItems="center"
                                alignContent="center"
                              >
                                <h4 className={classes.warningText}>
                                  <WarningIcon />
                                  ATENÇÃO: Sem comunicação com a estação
                                  meteorológica local a mais de 20 minutos
                                </h4>
                              </Grid>
                            ) : (
                              <>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                  {data.current.rainStatus === 0 ? (
                                    <Tooltip title="Sem Chuva">
                                      <div style={{ width: '36px' }}>
                                        <WbSunnyIcon
                                          className={classes.iconTemp}
                                        />
                                      </div>
                                    </Tooltip>
                                  ) : (
                                    <Tooltip title="Chovendo">
                                      <div style={{ width: '36px' }}>
                                        <WbCloudyIcon
                                          className={classes.iconTemp}
                                        />
                                      </div>
                                    </Tooltip>
                                  )}
                                </Grid>

                                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                  <h4
                                    className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                                  >
                                    Precip.
                                  </h4>
                                  <h4
                                    className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                                  >
                                    {data.forecast &&
                                    data.forecast.list &&
                                    data.forecast.list[0]
                                      ? formatPredictDataLabel(
                                          data.forecast.list[0].pop * 100,
                                          '%',
                                        )
                                      : '-'}
                                  </h4>
                                </Grid>

                                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                  <h4
                                    className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                                  >
                                    Umid.
                                  </h4>
                                  <h4
                                    className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                                  >
                                    {formatPredictDataLabel(
                                      data.current.humid,
                                      '%',
                                    )}
                                  </h4>
                                </Grid>

                                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                  <h4
                                    className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                                  >
                                    Chovendo
                                  </h4>
                                  <h4
                                    className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                                  >
                                    {data.current.rainStatus === 1
                                      ? 'Sim'
                                      : 'Não'}
                                  </h4>
                                </Grid>

                                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                                  <h4
                                    className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                                  >
                                    UV
                                  </h4>
                                  <h4
                                    className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                                  >
                                    {formatPredictDataLabel(
                                      data.forecast.list[0].uvIndex,
                                      '%',
                                    )}
                                  </h4>
                                </Grid>

                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                  <h4
                                    className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                                  >
                                    Vento
                                    <Tooltip
                                      title={`${data.forecast.list[0].windDirection}°`}
                                    >
                                      <NavigationIcon
                                        style={{
                                          width: 'auto',
                                          height: '12px',
                                          transform: `rotate(${data.forecast.list[0].windDirection}deg)`,
                                        }}
                                      />
                                    </Tooltip>
                                  </h4>
                                  <h4
                                    className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                                  >
                                    {formatPredictDataLabel(
                                      data.current.windSpeed,
                                      'km/h',
                                    )}
                                  </h4>
                                </Grid>

                                <Tooltip
                                  title={`Data de Aferição: ${formatDate(
                                    new Date(data.current.date),
                                  )}`}
                                >
                                  <Grid item xs={12}>
                                    <h4 className={classes.sourceSub}>
                                      Fonte: Estação Meteorológica e Openweathermap
                                    </h4>
                                  </Grid>
                                </Tooltip>
                              </>
                            )}
                          </Grid>
                        </CardText>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={12} lg={8}>
                      <Grid container spacing={1}>
                        {/* <Grid item xs={12} sm={6} md={4} lg={2}>
                          <Card similar outlined color="#55F">
                            <CardText></CardText>
                          </Card>
                        </Grid> */}

                        {data.forecast ? (
                          [1, 2, 3, 4, 5, 6].map((e) => {
                            const nd = new Date();
                            nd.setDate(nd.getDate() + e);
                            const item = data.forecast.list[e];

                            if (!item) {
                              return (
                                <Grid key={nd} item xs={12} md={6} lg={6} />
                              );
                            }

                            return (
                              <Grid key={nd} item xs={12} sm={6} md={6} lg={6}>
                                <Card similar>
                                  <CardTitle
                                    pt="8px"
                                    pb="0"
                                    pl="8px"
                                    pr="8px"
                                    size="1rem"
                                  >
                                    {`${formatData(item.dateText)}`}
                                  </CardTitle>

                                  <CardText>
                                    <Grid
                                      container
                                      spacing={1}
                                      alignContent="center"
                                      alignItems="center"
                                    >
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                      >
                                        {getIcon(item.iconUrl, item.iconId)}
                                      </Grid>

                                      <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                      >
                                        <h4
                                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                                        >
                                          Precip.
                                        </h4>
                                        <h4
                                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                                        >
                                          {formatPredictDataLabel(
                                            item.pop * 100,
                                            '%',
                                          )}
                                        </h4>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                      >
                                        <h4
                                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                                        >
                                          Umid.
                                        </h4>
                                        <h4
                                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                                        >
                                          {formatPredictDataLabel(
                                            item.humid || item.humidity,
                                            '%',
                                          )}
                                        </h4>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                      >
                                        <h4 className={classes.tempSub}>
                                          {formatPredictDataLabel(
                                            item.max,
                                            '°C Max.',
                                          )}
                                        </h4>
                                        <h4 className={classes.tempSub}>
                                          {formatPredictDataLabel(
                                            item.min,
                                            '°C Min.',
                                          )}
                                        </h4>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                      >
                                        <h4
                                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                                        >
                                          UV
                                        </h4>
                                        <h4
                                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                                        >
                                          {formatPredictDataLabel(
                                            item.uvIndex,
                                            '%',
                                          )}
                                        </h4>
                                      </Grid>

                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                      >
                                        <h4
                                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                                        >
                                          Vento
                                          <Tooltip
                                            title={`${item.windDirection}°`}
                                          >
                                            <NavigationIcon
                                              style={{
                                                width: 'auto',
                                                height: '12px',
                                                transform: `rotate(${item.windDirection}deg)`,
                                              }}
                                            />
                                          </Tooltip>
                                        </h4>
                                        <h4
                                          className={`${classes.secondaryHeading} ${classes.secondaryHeadingValue}`}
                                        >
                                          {formatPredictDataLabel(
                                            item.windSpeed,
                                            'm/s',
                                          )}
                                        </h4>
                                      </Grid>

                                      <Grid item xs={12}>
                                        <h4
                                          className={classes.sourceSub}
                                          style={{ marginTop: '11px' }}
                                        >
                                          {`Previsão por: ${data.forecast.source}`}
                                        </h4>
                                      </Grid>
                                    </Grid>
                                  </CardText>
                                </Card>
                              </Grid>
                            );
                          })
                        ) : (
                          <Grid item xs={6} md={8} lg={10}>
                            <h3
                              style={{
                                textAlign: 'center',
                                fontWeight: '400',
                              }}
                            >
                              Previsão do tempo indisponível
                            </h3>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
            </Dialog>
          </CardText>
          <CardActions pt="8px" pb="4px" pr="8px" pl="8px" size="10px">
            <Tooltip
              title={`Data de Aferição: ${formatDate(
                new Date(data.current.date),
              )}`}
            >
              <Grid item xs={12}>
                Fonte:Estação Meteorológica
              </Grid>
            </Tooltip>
          </CardActions>
        </Card>
      </CardText>
    </Card>
  );
};
