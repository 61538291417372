import React from 'react';
import { number } from '../../../../helpers/Intl';
import DadosIndisponiveis from '../DadosIndisponiveis';

export default function ChuvaAtual({ info1, info2, source, type }) {
  let displayInfo = '';
  if ((info1 || info1 === 0) && (info1 !== undefined || info1 !== '')) {
    displayInfo = info1;
  } else if ((info2 || info2 === 0) && (info2 !== undefined || info2 !== '')) {
    displayInfo = info2;
    source = 'openweathermap';
  }

  let indicador = '';
  if (source === 'openweathermap') {
    indicador = '*';
  }

  type === undefined && (type = 0);

  // type = [ 0 , 1 , 2, 3 ]
  const unity = [
    `mm ${indicador}`,
    `mm Max. ${indicador}`,
    `mm Min. ${indicador}`,
    `% ${indicador}`,
  ];

  return (
    <>
      {displayInfo !== '' ? (
        `${number(displayInfo, 2, 2)}${unity[type]}`
      ) : (
        <DadosIndisponiveis unitType={unity[type]} />
      )}
    </>
  );
}
