import React from 'react';
import Grid from '@material-ui/core/Grid';
// import { makeStyles, useTheme } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Card from '../../Card';
import CardText from '../../CardText';
import { number } from '../../../helpers/Intl';
import dadosIndisponiveis from '../Components/DadosIndisponiveis';

/*
const useStyles = makeStyles((theme) => ({
  iconTemp: {
    fontSize: '30px',
    margin: 'auto',
    width: '100%',
  },
  iconTempImg: {
    background: 'grey',
    borderRadius: '10px',
    maxHeight: '40px',
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    margin: 'auto 3px',
    textAlign: 'left',
  },
  secondaryHeadingTitle: {
    fontSize: '12px',
  },
  secondaryHeadingValue: {
    fontWeight: '500',
    fontSize: '17px',
  },
  tempSub: {
    fontSize: '13px',
    fontWeight: 'bold',
    margin: 'auto 3px',
    textAlign: 'left',
  },
}));
*/

export default function HistoricalCard({ nd, sm, item, dadosIndisp, indiceDados }) {
  const classes = ''; // useStyles();
  // const theme = useTheme();

  const formatData = (date) => {
    const reg = date.match(/(\d{4})-(\d{2})-(\d{2})/);
    if (reg) {
      return `${reg[3]}/${reg[2]}`;
    }
    return date;
  };

  return (
    <>
      <Grid key={nd} item xs={12} sm={4}>
        <Card style={{ minHeight: 163, alignContent: 'center' }}>
          <CardText pa={sm ? '8px' : ''} style={{ minHeight: 163, alignContent: 'center' }}>
            <Grid container style={{ minHeight: 163, alignContent: 'center' }}>
              <Grid
                item
                xs={4}
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <h3>{`${formatData(item.date)}`}</h3>
              </Grid>
              {item.errors && item.errors[0] ? (
                <Grid
                  container
                  xs={8}
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <Alert severity="error" style={{ alignItems: 'center' }}>
                    {item.errors[0]}
                  </Alert>
                </Grid>
              ) : dadosIndisp === indiceDados ? (
                <Grid
                  container
                  xs={8}
                  style={{
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                  }}
                >
                  <Alert severity="error" style={{ alignItems: 'center' }}>
                    Dados Indisponíveis
                  </Alert>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={8}
                  style={{
                    textAlign: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid container spacing={1}>
                    <Grid item xs={7}>
                      <h4
                        className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                        style={{
                          fontSize: '12px',
                          margin: 'auto 3px',
                          textAlign: 'left',
                        }}
                      >
                        Temp.
                        {' '}
                      </h4>

                      <h4
                        className={classes.tempSub}
                        style={{
                          fontSize: '13px',
                          fontWeight: 'bold',
                          margin: 'auto 3px',
                          textAlign: 'left',
                        }}
                      >
                        {item.maxTemp
                          ? `${number(item.maxTemp, 1, 1)}°C Max.`
                          : dadosIndisponiveis('°C Max.')}
                      </h4>
                      <h4
                        className={classes.tempSub}
                        style={{
                          fontSize: '13px',
                          fontWeight: 'bold',
                          margin: 'auto 3px',
                          textAlign: 'left',
                        }}
                      >
                        {item.minTemp
                          ? `${number(item.minTemp, 1, 1)}°C Min.`
                          : dadosIndisponiveis('°C Min.')}
                      </h4>
                    </Grid>

                    <Grid item xs={5}>
                      <h4
                        className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                        style={{
                          fontSize: '12px',
                          margin: 'auto 3px',
                          textAlign: 'left',
                        }}
                      >
                        Umid.
                        {' '}
                      </h4>
                      <h4
                        className={classes.tempSub}
                        style={{
                          fontSize: '13px',
                          fontWeight: 'bold',
                          margin: 'auto 3px',
                          textAlign: 'left',
                        }}
                      >
                        {item.maxHumd
                          ? `${number(item.maxHumd, 1, 1)}% Max.`
                          : dadosIndisponiveis('% Max.')}
                      </h4>
                      <h4
                        className={classes.tempSub}
                        style={{
                          fontSize: '13px',
                          fontWeight: 'bold',
                          margin: 'auto 3px',
                          textAlign: 'left',
                        }}
                      >
                        {item.minHumd
                          ? `${number(item.minHumd, 1, 1)}% Min.`
                          : dadosIndisponiveis('% Min.')}
                      </h4>
                    </Grid>
                    <Grid item xs={7}>
                      <h4
                        className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                        style={{
                          fontSize: '12px',
                          margin: 'auto 3px',
                          textAlign: 'left',
                        }}
                      >
                        Chuva
                        {' '}
                      </h4>
                      <h4
                        className={classes.tempSub}
                        style={{
                          fontSize: '13px',
                          fontWeight: 'bold',
                          margin: 'auto 3px',
                          textAlign: 'left',
                        }}
                      >
                        {item.rain === 0 ? 0 : item.rain ? `${number(item.rain, 2, 2)}mm` : dadosIndisponiveis('mm')}
                      </h4>
                    </Grid>
                    <Grid item xs={5}>
                      <h4
                        className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                        style={{
                          fontSize: '12px',
                          margin: 'auto 3px',
                          textAlign: 'left',
                        }}
                      >
                        UV
                        {' '}
                      </h4>
                      <h4
                        className={classes.tempSub}
                        style={{
                          fontSize: '13px',
                          fontWeight: 'bold',
                          margin: 'auto 3px',
                          textAlign: 'left',
                        }}
                      >
                        {item.uv === 0 ? 0 : item.uv ? `${number(item.uv, 1, 1)} W/m²` : ''}
                        {item.uvIndex ? `${number(item.uvIndex, 1, 1)} %` : ''}
                        {!item.uvIndex && !item.uv ? dadosIndisponiveis('W/m²') : ''}
                      </h4>
                    </Grid>
                    <Grid item xs={7}>
                      <h4
                        className={`${classes.secondaryHeading} ${classes.secondaryHeadingTitle}`}
                        style={{
                          fontSize: '12px',
                          margin: 'auto 3px',
                          textAlign: 'left',
                        }}
                      >
                        Vento
                        {' '}
                        {/* <Tooltip
                    title={`${item.windDirection}°`}
                >
                    <NavigationIcon
                    style={{
                        width: 'auto',
                        height: '12px',
                        transform: `rotate(${item.windDirection}deg)`,
                    }}
                    />
                </Tooltip> */}
                      </h4>
                      <h4
                        className={classes.tempSub}
                        style={{
                          fontSize: '13px',
                          fontWeight: 'bold',
                          margin: 'auto 3px',
                          textAlign: 'left',
                        }}
                      >
                        {item.windSpeed
                          ? `${number(item.windSpeed, 1, 1)}Km/h`
                          : dadosIndisponiveis('Km/h')}
                      </h4>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </CardText>
        </Card>
      </Grid>
    </>
  );
}
