import styled from 'styled-components';

export const Card = styled.div`
  width:  100%;
  height: 100%;
  padding: 0.5rem 1rem;
  background: #e4ebeb;
  border: 1px solid #e8ebeb;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &+& {
    margin-top: 0.4rem;
  }

  button {
    cursor: pointer
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const Form = styled.form`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
