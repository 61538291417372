import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  Settings as SettingsIcon,
  Warning as WarningIcon,
  // DateRange as DateRangeIcon,
} from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import '../App.css';
import Grid from '@material-ui/core/Grid';
import OpacityIcon from '@material-ui/icons/Opacity';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import BuildIcon from '@material-ui/icons/Build';
import PortalPermissions from '../helpers/enums/PortalPermissions';
import { hasPermission } from '../helpers/permission';
import { normalizeErrorData } from '../helpers/normalizeErrorData';

const useStyles = makeStyles({
  card: {
    padding: '15px',
  },
  title: {
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#0386FF',
    margin: 0,
  },
  links: {
    cursor: 'pointer',
  },
  subtitle: {
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    color: '#3D5059',
    margin: 0,
  },
  masterIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '40px',
  },
  masterImage: {
    height: '40px',
  },
  notifications: {
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'space-around',
  },
  notificationIcon: {
    fontSize: '30px',
  },
  onIcon: {
    color: 'green',
    fontSize: '30px',
  },
  offIcon: {
    color: 'gray',
    fontSize: '30px',
    opacity: 0.4,
  },
  locationLine: {
    color: '#9a9a9a',
    padding: '0',
    fontSize: '14px',
    margin: '8px 0 10px',
    fontWeight: '400',
  },
  warningText: {
    display: 'flex',
    fontWeight: '500',
    fontSize: '16px',
    margin: 'auto 3px',
    textAlign: 'left',
  },
});

export default (props) => {
  const classes = useStyles();
  const [redirect, setRedirect] = React.useState(false);

  const details = {
    1: {
      icon: (
        <img
          src="/images/icons/pivotIcon.svg"
          className={classes.masterImage}
          alt=""
        />
      ),
      url: 'irrigationDash',
    },
    2: {
      icon: (
        <img
          src="/images/icons/thermometryIcon.svg"
          className={classes.masterImage}
          alt=""
        />
      ),
      url: 'thermometryDash',
    },
    3: {
      icon: (
        <img
          src="/images/icons/automationIcon.svg"
          className={classes.masterImage}
          alt=""
        />
      ),
      url: 'automationDash',
    },
    4: {
      icon: (
        <img
          src="/images/icons/powerPlantIcon.svg"
          className={classes.masterImage}
          alt=""
        />
      ),
      url: 'photovoltaicDash',
    },
    5: {
      icon: (
        <img
          src="/images/icons/wheater-station.svg"
          className={classes.masterImage}
          alt=""
        />
      ),
      url: 'weatherStation',
    },
    6: {
      icon: (
        <img
          src="/images/icons/painelEletrico.png"
          className={classes.masterImage}
          alt=""
        />
      ),
      url: 'equipamentData',
    },
  };

  const [operation, setOperation] = React.useState();

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const op = params.get('op');
    setOperation(op);
  }, [window.location.search]);

  if (redirect) return <Redirect push to={redirect} />;

  const checkWeatherExpired = () => {
    const weatherDate = new Date(props.data.weatherStation.date);
    const dateDiff = weatherDate.getTime() - new Date().getTime();
    return Math.abs(dateDiff) > 1200000;
  };

  return (
    <Paper>
      <Grid container className={classes.card}>
        <Grid item xs={10} style={{ height: '40px' }}>
          <h3
            onClick={() =>
              !props.simpleCard &&
              props.data.operationId === 1 &&
              setRedirect(`/globalMap?plantId=${props.data.id}`)
            }
            className={classes.title}
            style={{
              cursor: props.data.operationId === 1 ? 'pointer' : 'default',
            }}
          >
            {props.data.description ||
              props.data.technicalDescription ||
              // props.data.alias ||
              props.data.id}
          </h3>
        </Grid>

        <Grid item xs={2} className={classes.masterIcon}>
          {details[props.data.operationId].icon}
        </Grid>

        <Grid item xs={12}>
          <h3 className={classes.locationLine}>
            {props.data.location
              ? `${props.data.location.address1} ${props.data.location.address2},
            ${props.data.location.city} - ${props.data.location.state},
            ${props.data.location.postalCode} - ${props.data.location.country}`
              : 'Endereço não informado'}
          </h3>
        </Grid>

        {props.data.operationId === 2 &&
          props.data.weatherStation &&
          checkWeatherExpired() && (
            <Grid item xs={12} alignItems="center">
              <h4 className={classes.warningText}>
                <WarningIcon />
                ATENÇÃO: Sem comunicação com a estação meteorológica local a
                mais de 20 minutos
              </h4>
            </Grid>
            // eslint-disable-next-line indent
          )}

        {props.data.operationId === 2 &&
          props.data.weatherStation &&
          !checkWeatherExpired() && (
            <>
              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <h4 style={{ marginBottom: '10px', textAlign: 'center' }}>
                      Umidade
                    </h4>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <img
                      src="/images/thermometryIcons/UmidadeGray.svg"
                      alt=""
                      style={{ height: '32px', width: 'auto' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h2
                      style={{
                        margin: '0',
                        textAlign: 'center',
                        fontWeight: '500',
                      }}
                    >
                      {normalizeErrorData(props.data.weatherStation.humid, '%')}
                    </h2>
                    <h5
                      style={{
                        margin: '0',
                        textAlign: 'center',
                        fontWeight: '400',
                      }}
                    >
                      Ambiente
                    </h5>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <h4 style={{ marginBottom: '10px', textAlign: 'center' }}>
                      Chuva
                    </h4>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    {props.data.weatherStation.rainStatus ? (
                      <OpacityIcon style={{ height: '32px', width: 'auto' }} />
                    ) : (
                      <WbSunnyIcon style={{ height: '32px', width: 'auto' }} />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <h2
                      style={{
                        margin: '0',
                        textAlign: 'center',
                        fontWeight: '500',
                      }}
                    >
                      {`${props.data.weatherStation.rain} mm`}
                    </h2>
                    <h5
                      style={{
                        margin: '0',
                        textAlign: 'center',
                        fontWeight: '400',
                      }}
                    >
                      Acumulada no período
                    </h5>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={4}>
                <Grid container>
                  <Grid item xs={12}>
                    <h4 style={{ marginBottom: '10px', textAlign: 'center' }}>
                      Temperatura
                    </h4>
                  </Grid>
                  <Grid item xs={12} style={{ textAlign: 'center' }}>
                    <img
                      src="/images/thermometer.svg"
                      alt=""
                      style={{ height: '32px', width: 'auto' }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <h2
                      style={{
                        margin: '0',
                        textAlign: 'center',
                        fontWeight: '500',
                      }}
                    >
                      {normalizeErrorData(props.data.weatherStation.temp, '°')}
                    </h2>
                    <h5
                      style={{
                        margin: '0',
                        textAlign: 'center',
                        fontWeight: '400',
                      }}
                    >
                      Ambiente
                    </h5>
                  </Grid>
                </Grid>
              </Grid>
            </>
            // eslint-disable-next-line indent
          )}

        <Grid item xs={6} style={{ marginTop: '15px', paddingTop: '4px' }}>
          <Chip
            icon={<BuildIcon />}
            color="secondary"
            style={{ cursor: 'pointer' }}
            label={`${(props.data.equipaments || []).length} Equipamentos`}
            onClick={() =>
              !props.simpleCard &&
              setRedirect(
                `/equipamentData?plantId=${props.data.id}${
                  operation ? `&op=${operation}` : ''
                }`,
              )
            }
          />
          {/* <Button>Ver Mapa</Button> */}
        </Grid>

        <Grid item xs={6} style={{ marginTop: '15px', textAlign: 'right' }}>
          {hasPermission(PortalPermissions.PLANTS_SETTINGS_VIEW.value) &&
            !props.simpleCard && (
              <>
                <Button
                  size="large"
                  variant="contained"
                  style={{
                    background: '#828282',
                    color: 'white',
                    marginLeft: '13px',
                    padding: '8px',
                    minWidth: 'fit-content',
                  }}
                  onClick={() =>
                    setRedirect(`/plants/${props.data.id}/settings`)
                  }
                >
                  <SettingsIcon />
                </Button>
              </>
              // eslint-disable-next-line indent
            )}
        </Grid>
      </Grid>
    </Paper>
  );
};
