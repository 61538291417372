import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  makeStyles,
  ListItemText,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import ptBrLocale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { GridItem } from '../GridItem';
import UnitSelectField from '../../../../../../components/Fields/UnitSelectField';
import { ButtonComponent as Button } from '../../../../../../components';

export function ReportForm({
  handleDateChange,
  handleSelectPlant,
  selectedDate,
  selectedPlants,
  handleSelectedGrain,
  grains,
  selectedGrains,
  isAllGrainsSelected,
  handleGetReport,
}) {
  const styles = makeStyles({
    root: {
      width: '100%',
      background: '#FFF',
      padding: '1rem',
      borderRadius: '0.2rem',
      minHeight: '5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  });

  const classes = styles();

  const labelGrainMultSelect = () => {
    const grainLabel = grains.find((grain) => grain === selectedGrains[0]);
    if (!grainLabel) {
      return 'Todos os grãos selecionados';
    }

    const allGrainsLabel = selectedGrains.join(' - ');

    return allGrainsLabel;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <GridItem>
          <MuiPickersUtilsProvider locale={ptBrLocale} utils={DateFnsUtils}>
            <KeyboardDatePicker
              views={['month', 'year']}
              label="Selecione o Mês e o Ano"
              format="MM/yyyy"
              value={selectedDate}
              name="selectMonthAndYear"
              onChange={(date) => handleDateChange(date)}
            />
          </MuiPickersUtilsProvider>
        </GridItem>

        <GridItem>
          <UnitSelectField
            selectedPlants={selectedPlants}
            setSelectedPlants={(p) => {
              handleSelectPlant(p.plants);
            }}
            systemFilter={2}
            label="Plantas"
            selectAllLabel="Todas selecionadas"
          />
        </GridItem>

        <GridItem>
          <FormControl variant="filled">
            <InputLabel>Grãos</InputLabel>
            <Select
              multiple
              value={selectedGrains}
              onChange={handleSelectedGrain}
              name="grains"
              renderValue={labelGrainMultSelect}
            >
              <MenuItem value="Todos">
                <Checkbox checked={isAllGrainsSelected.indexOf('Todos') > -1} />
                Todos os Grãos
              </MenuItem>
              {grains.map((grain) => (
                <MenuItem
                  key={grain}
                  value={grain}
                  disabled={isAllGrainsSelected.indexOf('Todos') > -1}
                >
                  <Checkbox checked={selectedGrains.indexOf(grain) > -1} />
                  <ListItemText primary={grain} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>

        <Grid>
          <Button handle={handleGetReport}>Gerar Relatório</Button>
        </Grid>
      </Grid>
    </div>
  );
}
