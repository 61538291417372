import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Grid, TextField, Tooltip, IconButton, Zoom, InputAdornment, MenuItem, Select, InputLabel, FormControl } from '@material-ui/core';
import { Help } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {
  RiAccountCircleFill,
  RiMapPinLine,
  RiTimeFill,
  RiRoadMapLine,
  RiHonourLine,
  RiPassportLine,
  RiLockPasswordLine,
} from 'react-icons/ri';
import { Container } from './styles';
import AccountImageUploader from '../../adminFockink/organization/account/AccountImageUploader';
import { useAuth } from '../../../hooks/account';
import BottomButtons from '../../../components/Buttons/BottomButtons';
import AccountClient from '../../adminFockink/organization/account/AccountClient';
import Breadcrumbs from '../../../components/Breadcrumbs';
import Card from '../../../components/Card';
import CardText from '../../../components/CardText';
import CardTitle from '../../../components/CardTitle';
import Spacer from '../../../components/Spacer';

const useStyles = makeStyles({
  bottom: {
    bottom: '0',
  },
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  subTitle: {
    borderBottom: '1px solid #ccc',
    textAlign: 'center',
    paddingBottom: '0 !important',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

export default function AccountSettings() {
  const { handleGetMyAccount } = useAuth();
  const previewCanvasRef = useRef(null);
  const [accountData, setAccountData] = useState();
  const [systemIdThermometry, setSystemIdThermometry] = useState(false);
  const [dataToUpdate, setDataToUpdate] = useState({
    authentication: {
      type: null,
    },
    unit: {
      singular: '',
      plural: '',
    },
  });
  const [isCompleteState, setIsCompleteState] = useState(false);

  const [azureTypeState, setAzureTypeState] = useState();

  const classes = useStyles();
  const breadcrumb = {
    title: 'Conta',
    routes: [
      { name: 'Configurações', to: '/settings' },
      { name: 'Conta' },
    ],
  };

  const handelChangeAuthenticationMode = (event) => {
    const copyStateValue = { ...dataToUpdate };
    copyStateValue.authentication.type = event.target.value;
    setAzureTypeState(event.target.value);
    setDataToUpdate(copyStateValue);
  };

  useEffect(() => {
    (async () => {
      const data = await handleGetMyAccount();
      const { organizationId, authentication, unit, aerationReportTimeout, systemIds } = data;
      const copyStateValue = dataToUpdate;
      copyStateValue.systemIds = systemIds;
      copyStateValue.organizationId = organizationId;
      copyStateValue.authentication = authentication;
      copyStateValue.aerationReportTimeout = aerationReportTimeout || 0;
      copyStateValue.unit = unit || {
        singular: '',
        plural: '',
      };
      setSystemIdThermometry(systemIds.includes(2));
      setAzureTypeState(authentication.type);
      setDataToUpdate(copyStateValue);
      setAccountData(data);
    })();
  }, []);

  const autenticationItemns = [
    {
      value: 'local',
      name: 'Local',
    },
    {
      value: 'simpleAzureADApp',
      name: 'Azure AD Simples',
    },
  ];

  const handleUpdateAzureData = useCallback(async (event) => {
    const identifier = event.target.name;
    const valueToUpdate = event.target.value;

    const copyValueState = { ...dataToUpdate };
    copyValueState.authentication[identifier] = valueToUpdate;

    setDataToUpdate(copyValueState);
  }, []);

  const handleUpdateUnit = (e) => {
    setDataToUpdate({ ...dataToUpdate, ...dataToUpdate.unit[e.target.name] = e.target.value });
  };

  useEffect(() => {
    if (dataToUpdate.authentication.type === 'simpleAzureADApp') {
      const { tenantId, clientId, secret } = dataToUpdate.authentication;
      if (!tenantId || !clientId || !secret) {
        setIsCompleteState(false);
        return;
      }
      setIsCompleteState(true);
    }
    setIsCompleteState(true);
  }, [dataToUpdate]);

  const saveData = () => {
    const dataURL = previewCanvasRef.current
      ? previewCanvasRef.current.toDataURL()
      : undefined;
    const dt = {
      data: { ...dataToUpdate, logoImage: dataURL },
      method: 'put',
      url: '/accounts/myAccount',
    };

    if (dt.data.authentication.type === 'local') {
      delete dt.data.authentication.tenantId;
      delete dt.data.authentication.clientId;
      delete dt.data.authentication.secret;
    }

    return dt;
  };

  return (
    <Container>
      {accountData ? (
        <>

          <Breadcrumbs routes={breadcrumb.routes} title={`Conta: ${accountData.description}`} />

          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card>
                <CardTitle>
                  Geral
                  <Spacer />
                </CardTitle>

                <CardText>
                  <Grid container spacing={3}>
                    <Grid item lg={4} md={4} xs={12}>
                      <FormControl variant="outlined">
                        <InputLabel id="authLabel">Autenticação</InputLabel>
                        <Select
                          labelId="authLabel"
                          id="auth"
                          name="auth"
                          value={dataToUpdate.authentication.type}
                          onChange={handelChangeAuthenticationMode}
                          label="Autenticação"
                          startAdornment={(
                            <InputAdornment position="start">
                              <RiAccountCircleFill size={20} />
                            </InputAdornment>
                          )}
                        >
                          {

                              autenticationItemns.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.name}
                                </MenuItem>
                              ))
                            }

                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <TextField
                        variant="outlined"
                        name="singular"
                        onChange={handleUpdateUnit}
                        value={dataToUpdate.unit?.singular}
                        label="Unidade singular"
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><RiMapPinLine size={20} /></InputAdornment>,
                        }}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xs={12}>
                      <TextField
                        variant="outlined"
                        name="plural"
                        onChange={handleUpdateUnit}
                        value={dataToUpdate.unit?.plural}
                        label="Unidade plural"
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><RiRoadMapLine size={20} /></InputAdornment>,
                        }}
                      />
                    </Grid>
                  </Grid>
                </CardText>
              </Card>
            </Grid>

            {systemIdThermometry && (
              <Grid item xs={12}>
                <Card>
                  <CardTitle>
                    Aeração
                    <Spacer />
                  </CardTitle>
                  <CardText>

                    <Grid container spacing={3}>
                      <Grid item lg={4} md={4} xs={12}>
                        <TextField
                          variant="outlined"
                          name="aerationReportTimeout"
                          onChange={(e) =>
                            setDataToUpdate({ ...dataToUpdate, [e.target.name]: e.target.value })
                          }
                          value={dataToUpdate.aerationReportTimeout}
                          label="Tempo para aeração efetiva"
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><RiTimeFill size={20} /></InputAdornment>,
                            endAdornment: <InputAdornment position="end">min</InputAdornment>,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardText>
                </Card>
              </Grid>
            )}

            {azureTypeState === 'simpleAzureADApp' && (
            <Grid item xs={12}>
              <Card>
                <CardTitle>
                  Configurações Azure AD Simples
                  <Tooltip
                    title="Precisa de ajuda com estes campos?"
                    placement="bottom-end"
                    arrow
                    TransitionComponent={Zoom}
                    aria-label="help"
                  >
                    <IconButton
                      color="primary"
                      target="_blank"
                      href="/docs/TutorialAzureADSimples.pdf"
                    >
                      <Help />
                    </IconButton>
                  </Tooltip>
                  <Spacer />
                </CardTitle>

                <CardText>
                  <Grid container spacing={3}>
                    <Grid item lg={4} md={4} xs={12}>
                      <TextField
                        variant="outlined"
                        name="tenantId"
                        onChange={handleUpdateAzureData}
                        value={dataToUpdate.authentication.tenantId}
                        label="Tenant ID"
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><RiHonourLine size={20} /></InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid item lg={4} md={4} xs={12}>
                      <TextField
                        variant="outlined"
                        name="clientId"
                        onChange={(e) => handleUpdateAzureData(e)}
                        value={dataToUpdate.authentication.clientId}
                        label="Client ID"
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><RiPassportLine size={20} /></InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid item lg={4} md={4} xs={12}>
                      <TextField
                        variant="outlined"
                        name="secret"
                        onChange={(e) => handleUpdateAzureData(e)}
                        value={dataToUpdate.authentication.secret}
                        label="Secret"
                        InputProps={{
                          startAdornment: <InputAdornment position="start"><RiLockPasswordLine size={20} /></InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid item lg={4} md={4} xs={12}>
                      <strong className="strong-url">
                        URL Redirect:
                        <span className="span-url">
                          {' '}
                          {`${process.env.REACT_APP_BACKEND_API}/login/simpleAzureADRedirect/anyId`}
                        </span>
                      </strong>
                      <strong className="strong-url">
                        URL Logout:
                        <span className="span-url">
                          {' '}
                          {`https://${accountData.hostname}.konecta.ind.br/login`}
                        </span>
                      </strong>
                    </Grid>
                  </Grid>
                </CardText>
              </Card>
            </Grid>
            )}

            <Grid item xs={12}>

              <Card>
                <CardTitle>
                  Cliente
                </CardTitle>
                <CardText>
                  <Grid container spacing={3}>
                    <Grid item xs={12} style={{ marginTop: 15 }}>
                      <AccountClient
                        onlyDataVisualization
                        client={{
                          ...accountData.client,
                          clientId: accountData.clientId,
                        }}
                      />
                    </Grid>

                    <Grid item lg={4} md={4} xs={12}>
                      <AccountImageUploader
                        organizationId={accountData.organizationId}
                        accountId={accountData.id}
                        previewCanvasRef={previewCanvasRef}
                      />
                    </Grid>
                  </Grid>
                </CardText>
              </Card>
            </Grid>
            <Grid
              xs={12}
              className={`${classes.textAlign} ${classes.bottomButtons}`}
            >
              <BottomButtons
                saveData={saveData}
                backTo="/settings"
                notComplete={!isCompleteState}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <h1>carregando</h1>
        // fazer um loading
      )}
    </Container>
  );
}
