import React from 'react';
import { Switch, Link, Route } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessIcon from '@material-ui/icons/Business';
import BuildIcon from '@material-ui/icons/Build';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import SettingsIcon from '@material-ui/icons/Settings';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
// import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import DescriptionIcon from '@material-ui/icons/Description';
import AssessmentIcon from '@material-ui/icons/Assessment';
import QuestionAnswerSharpIcon from '@material-ui/icons/QuestionAnswerSharp';

import { IoTicket } from 'react-icons/io5';
import { Home } from '../pages/Home';
import Settings from '../pages/settings/Settings';
import BlockedHosts from '../pages/settings/admin/BlockedHosts';
import TechDeliveryTypes from '../pages/settings/admin/TechDeliveryTypes';
import Login from '../pages/auth/Login/Index';
import Logout from '../pages/auth/Logout';
import Profile from '../pages/auth/Profile/Profile';
import ResetPassword from '../pages/auth/ResetPassword/ResetPassword';
import ForgotPassword from '../pages/auth/ForgotPassword/ForgotPassword';
import Organization from '../pages/adminFockink/organization/Organization';
import OrganizationForm from '../pages/adminFockink/organization/OrganizationForm';
import OrganizationCreate from '../pages/adminFockink/organization/OrganizationCreate';
import AccountForm from '../pages/adminFockink/organization/account/AccountForm';
import AccountView from '../pages/adminFockink/organization/account/AccountView';
import PlantsForm from '../pages/adminFockink/organization/account/plants/PlantsForm';
import PlantsView from '../pages/adminFockink/organization/account/plants/PlantsView';
import EquipsForm from '../pages/adminFockink/organization/account/plants/equips/EquipsForm';
import EquipsView from '../pages/adminFockink/organization/account/plants/equips/EquipsView';
import Comms from '../pages/adminFockink/organization/account/plants/comms/Comms';
import CommsForm from '../pages/adminFockink/organization/account/plants/comms/CommsForm';
import Users from '../pages/adminFockink/organization/account/users/Users';
import UsersForm from '../pages/adminFockink/organization/account/users/UsersForm';
import PR from './PrivateRoute';
import IndustrialAutomationDash from '../pages/adminClient/Dashes/IndustrialAutomationDash/IndustrialAutomationDash';
import ThermometryDash from '../pages/adminClient/Dashes/ThermometryDash/ThermometryDash';
import ThermometrySettings from '../pages/adminClient/Dashes/ThermometryDash/Settings/ThermometrySettings';
import ThermometryQualityReport from '../pages/adminClient/Reports/ThermoQuality/ThermometryQualityReport';
import ThermometryReport from '../pages/adminClient/Reports/ThermometryReport';
import VariableTracking from '../pages/adminClient/Reports/VariableTracking';
import Reports from '../pages/adminClient/Reports/Reports';
import ReportsAdm from '../pages/adminClient/Reports/ReportsAdm';
import IrrigationDash from '../pages/adminClient/Dashes/IrrigationDash/IrrigationDash';
import PhotovoltaicDash from '../pages/adminClient/Dashes/PhotovoltaicDash/PhotovoltaicDash';
import PhotovoltaicSettings from '../pages/adminClient/Dashes/PhotovoltaicDash/PhotovoltaicSettings';
import EquipmentDash from '../pages/adminClient/EquipmentDash/EquipmentDash';
import PlantDash from '../pages/adminClient/PlantsDash/PlantDash';
import PlantSettings from '../pages/adminClient/PlantsDash/PlantSettings';
import ViewDocs from '../pages/adminClient/ViewDocs/ViewDocs';
import Units from '../pages/settings/client/Units/Units';
import UnitsForm from '../pages/settings/client/Units/UnitsForm';
import PortalUsers from '../pages/settings/client/PortalUsers/PortalUsers';
import EnergyCost from '../pages/settings/client/EnergyCost/EnergyCost';
import PortalUsersForm from '../pages/settings/client/PortalUsers/PortalUsersForm';
import EnergyCostForm from '../pages/settings/client/EnergyCost/EnergyCostForm';
import Forbidden403 from '../pages/auth/Forbidden403';
import AuthByToken from '../pages/auth/AuthByToken';
import AdminPermissions from '../helpers/enums/AdminPermissions';
// import PortalPermissions from '../helpers/enums/PortalPermissions';
import GlobalMap from '../pages/adminClient/PlantsDash/GlobalMap/index';
import AccountClientSettings from '../pages/adminClient/AccountSettings';
import { IrrigationSettings } from '../pages/adminClient/Dashes/IrrigationDash/IrrigationSettings';
import { AerationReport } from '../pages/adminClient/Reports/AerationReports';
import { PhotovoltaicReportEarning } from '../pages/adminClient/Reports/PhotovoltaicReportEarning';
import { IrrigationPlanning } from '../pages/adminClient/Reports/IrrigationPlanning';
import { CultureForm } from '../pages/settings/client/CultureForm';
import { TelemetrySettings } from '../pages/settings/admin/TelemetrySettings';
import { TechDeliveryApprovers } from '../pages/settings/admin/TechDeliveryApprovers';
import { ThresholdConfig } from '../pages/settings/client/ThresholdConfig';
import { AlarmRole } from '../pages/adminClient/AlarmsRole';
// import { DatasheetForm } from '../pages/adminClient/DatasheetForm';
// import { DatasheetList } from '../pages/adminFockink/DatasheetList';
import { IndustrialAutomationSettings } from '../pages/adminClient/Dashes/IndustrialAutomationDash/IndustrialAutomationSettings';
// import { SelectTechnicalDeliveryType } from '../pages/adminFockink/TechnicalDelivery';
// import { CentralThermometry } from '../pages/adminFockink/TechnicalDelivery/CentralThermometry';
// import TecnicalDeliveryIrrigationPDF from '../pages/adminClient/DatasheetForm/components/PDF';
import SoilAnalysis from '../pages/settings/admin/SoilAnalysis/index';
import soilAnalysisList from '../pages/settings/admin/SoilAnalysis/SoilChemicalAnalysis/soilAnalysisList';
import soilChemicalAnalysis from '../pages/settings/admin/SoilAnalysis/SoilChemicalAnalysis/index';
import SoilPhysicalAnalysis from '../pages/settings/admin/SoilPhysicalAnalysis/SoilPhysicalAnalysis';
import SoilPhysicalAnalysisForm from '../pages/settings/admin/SoilPhysicalAnalysis/SoilPhysicalAnalysisForm';
import SoilPhysicalAnalysisCl from '../pages/settings/admin/SoilPhysicalAnalysis/index';
import ticketsList from '../pages/adminClient/TechnicalAssistance/Tickets/ticketsList';
import ticketsForm from '../pages/adminClient/TechnicalAssistance/Tickets/ticketsForm';
import interactions from '../pages/adminClient/TechnicalAssistance/Tickets/interactions';
import weatherStationReport from '../pages/adminClient/Reports/WeatherStationReport/weatherStationReport';
import FuelCostForm from '../pages/settings/client/FuelCost/FuelCostForm';
import FuelCostList from '../pages/settings/client/FuelCost/FuelCostList';
import PlansForm from '../pages/settings/client/Plans/PlansForm';
import PlansList from '../pages/settings/client/Plans/PlansList';
import StorageCostForm from '../pages/settings/admin/StorageCost/StorageCostForm';
import StorageCostList from '../pages/settings/admin/StorageCost/StorageCostList';
import EnergyConsumption from '../pages/adminClient/Reports/EngineCost/EnergyConsumption';
import ReportSettings from '../pages/settings/client/ReportSettings/reportSettings';
import RepFormQualityReport from '../pages/settings/client/ReportSettings/repFormQualityReport';
import WheaterStationDash from '../pages/adminClient/Dashes/WeatherStation/WeatherStationDash';
import WeatherStationSettings from '../pages/adminClient/Dashes/WeatherStation/WeatherStationSettings';
import ListMyFeedBacks from '../pages/ContactUs/Client/listMyFeedBacks';
import EditFeedback from '../pages/ContactUs/Client/editFeedBack';
import ListFeedBacks from '../pages/ContactUs/Admin/listFeedBacks';
import AnswerFeedback from '../pages/ContactUs/Admin/answerFeedback';

const links = [
  {
    name: 'Início',
    icon: <DashboardIcon />,
    path: '/',
    component: Home,
    isPrivate: true,
    showIn: ['all'],
  },
  {
    name: 'Equipamentos',
    icon: <BuildIcon />,
    path: '/equipamentData',
    component: EquipmentDash,
    isPrivate: true,
    showIn: ['clientadmin'],
  },
  {
    path: '/settings/soilAnalysis',
    component: SoilAnalysis,
    isPrivate: true,
  },
  {
    path: '/settings/soilAnalysis/:clid',
    component: soilAnalysisList,
    isPrivate: true,
  },
  {
    path: '/settings/soilAnalysis/:clid/:id',
    component: soilChemicalAnalysis,
    isPrivate: true,
  },
  // {
  //   name: 'Entrega Técnica',
  //   icon: <AssignmentIndIcon />,
  //   path: '/technicalDeliveryGenerator',
  //   component: TechnicalDeliveryGenerator,
  //   isPrivate: true,
  //   showIn: ['admin'],
  // },
  {
    name: 'Plantas',
    icon: <AccountTreeIcon />,
    path: '/plants/',
    component: PlantDash,
    isPrivate: true,
    showIn: ['clientadmin'],
  },
  {
    name: 'Relatórios',
    icon: <TrendingUpIcon />,
    path: '/reports',
    component: Reports,
    isPrivate: true,
    showIn: ['all'],
  },
  {
    name: 'Relatórios',
    icon: <TrendingUpIcon />,
    path: '/reportsAdm',
    component: ReportsAdm,
    isPrivate: true,
  },
  // {
  //   name: 'Fale Conosco',
  //   icon: <TrendingUpIcon />,
  //   path: '/feedback',
  //   component: Reports,
  //   isPrivate: true,
  //   showIn: ['clientadmin'],
  // },
  {
    name: 'Documentos',
    icon: <DescriptionIcon />,
    path: '/viewDocs',
    component: ViewDocs,
    isPrivate: true,
    showIn: ['clientadmin'],
  },
  {
    name: 'Relatório de Ganhos',
    path: '/reports/photolcoltaic-earnings',
    component: PhotovoltaicReportEarning,
    isPrivate: true,
  },

  {
    name: 'Relatório de Estação Meteorológica',
    path: '/reports/weatherStationReport',
    component: weatherStationReport,
    isPrivate: true,
  },
  {
    name: 'Relatório de Estação Meteorológica',
    path: '/reportsAdm/weatherStationReport',
    component: weatherStationReport,
    isPrivate: true,
  },

  // {
  //   icon: <VerticalSplitIcon />,
  //   path: '/datasheet/',
  //   component: DatasheetForm,
  //   isPrivate: true,
  // },
  // {
  //   path: '/datasheet/type',
  //   component: SelectTechnicalDeliveryType,
  //   isPrivate: true,
  // },
  // {
  //   path: '/technical-delivery/central-thermometry',
  //   component: CentralThermometry,
  //   isPrivate: true,
  // },
  // {
  //   path: '/technical-delivery/central-thermometry/:id/pdf',
  //   component: TecnicalDeliveryIrrigationPDF,
  //   isPrivate: true,
  // },
  // {
  //   name: 'Entrega Técnica',
  //   icon: <VerticalSplitIcon />,
  //   path: '/datasheet/list',
  //   component: DatasheetList,
  //   isPrivate: true,
  //   showIn: ['admin'],
  // },
  {
    name: 'Organizações',
    icon: <BusinessIcon />,
    path: '/organization',
    component: Organization,
    isPrivate: true,
    showIn: ['admin'],
    permissionId: AdminPermissions.ORGANIZATIONS_VIEW.value,
  },
  {
    name: 'Organizações',
    icon: <BusinessIcon />,
    path: '/organization/organizationCreate',
    component: OrganizationCreate,
    isPrivate: true,
    permissionId: AdminPermissions.ORGANIZATIONS_VIEW.value,
  },
  {
    name: 'Configurações',
    icon: <SettingsIcon />,
    path: '/settings',
    component: Settings,
    isPrivate: true,
    showIn: ['admin', 'clientadmin'],
  },
  {
    name: 'Mapa Teste',
    icon: <SettingsIcon />,
    path: '/globalMap',
    component: GlobalMap,
    isPrivate: true,
    showIn: [],
  },
  {
    name: 'Configuração de Alarmes',
    path: '/alarms',
    component: AlarmRole,
    isPrivate: true,
  },
  {
    name: 'Login',
    icon: <PersonIcon />,
    path: '/login',
    component: Login,
    showIn: ['all'],
    noMenu: true,
  },
  // deep links

  {
    path: '/logout',
    component: Logout,
    isPrivate: true,
  },
  {
    path: '/authByToken/:source/:token',
    component: AuthByToken,
    isPrivate: false,
  },
  {
    path: '/resetPassword',
    component: ResetPassword,
    isPrivate: false,
  },
  {
    path: '/culture/new',
    component: CultureForm,
    isPrivate: true,
  },
  {
    path: '/variaveis/config',
    component: ThresholdConfig,
    isPrivate: true,
  },
  {
    path: '/settings/telemetry',
    component: TelemetrySettings,
    isPrivate: true,
  },
  {
    path: '/settings/tdApprovers',
    component: TechDeliveryApprovers,
    isPrivate: true,
  },
  {
    path: '/forgotPassword',
    component: ForgotPassword,
    isPrivate: false,
  },
  {
    path: '/profile',
    component: Profile,
    isPrivate: true,
  },
  {
    path: '/portalUsers/:id',
    component: PortalUsersForm,
    isPrivate: true,
    /* permissionId: PortalPermissions.USERS_VIEW.value, */
  },
  {
    path: '/energyCost/:id',
    component: EnergyCostForm,
    isPrivate: true,
    /* permissionId: PortalPermissions.ENERGY_COST_MANAGE.value, */
  },
  {
    path: '/units/:id',
    component: UnitsForm,
    isPrivate: true,
    /* permissionId: PortalPermissions.UNITS_MANAGE.value, */
  },
  {
    path: '/organization/:id',
    component: OrganizationForm,
    isPrivate: true,
    permissionId: AdminPermissions.ORGANIZATIONS_VIEW.value,
  },
  {
    path: '/organization/:orgId/account/:id',
    component: AccountForm,
    isPrivate: true,
    permissionId: AdminPermissions.ACCOUNTS_MANAGE.value,
  },
  {
    path: '/organization/:orgId/account/:id/view',
    component: AccountView,
    isPrivate: true,
    permissionId: AdminPermissions.ACCOUNTS_VIEW.value,
  },
  {
    path: '/organization/:orgId/account/:accId/plant/:id',
    component: PlantsForm,
    isPrivate: true,
    permissionId: AdminPermissions.PLANTS_MANAGE.value,
  },
  {
    path: '/organization/:orgId/account/:accId/plant/:id/view',
    component: PlantsView,
    isPrivate: true,
    permissionId: AdminPermissions.PLANTS_VIEW.value,
  },
  {
    path: '/organization/:orgId/account/:accId/plant/:plId/equip/:id',
    component: EquipsForm,
    isPrivate: true,
    permissionId: AdminPermissions.EQUIPAMENTS_MANAGE.value,
  },
  {
    path: '/organization/:orgId/account/:accId/plant/:plId/equip/:id/view',
    component: EquipsView,
    isPrivate: true,
    permissionId: AdminPermissions.EQUIPAMENTS_VIEW.value,
  },
  {
    path: '/organization/:orgId/account/:accId/plant/:plId/comm',
    component: Comms,
    isPrivate: true,
    permissionId: AdminPermissions.COMMUNICATORS_VIEW.value,
  },
  {
    path: '/organization/:orgId/account/:accId/plant/:plId/comm/:id',
    component: CommsForm,
    isPrivate: true,
    permissionId: AdminPermissions.COMMUNICATORS_MANAGE.value,
  },
  {
    path: '/organization/:orgId/account/:accId/user',
    component: Users,
    isPrivate: true,
    permissionId: AdminPermissions.USERS_VIEW.value,
  },
  {
    path: '/organization/:orgId/account/:accId/user/:id',
    component: UsersForm,
    isPrivate: true,
    permissionId: AdminPermissions.USERS_VIEW.value,
  },
  {
    path: '/photovoltaicDash/:id',
    component: PhotovoltaicDash,
    isPrivate: true,
    /* permissionId: PortalPermissions.PHOTOVOLTAIC_DASHBOARD_VIEW.value, */
  },
  {
    path: '/photovoltaicDash/:id/settings',
    component: PhotovoltaicSettings,
    isPrivate: true,
    /* permissionId: PortalPermissions.PHOTOVOLTAIC_SETTINGS_VIEW.value, */
  },
  {
    path: '/automationDash/:id',
    component: IndustrialAutomationDash,
    isPrivate: true,
    /* permissionId: PortalPermissions.AUTOMATION_DASHBOARD_VIEW.value, */
  },
  {
    path: '/automationDash/:id/settings',
    component: IndustrialAutomationSettings,
    isPrivate: true,
    /* permissionId: PortalPermissions.AUTOMATION_DASHBOARD_VIEW.value, */
  },
  {
    path: '/thermometryDash/:id',
    component: ThermometryDash,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_DASHBOARD_VIEW.value, */
  },
  {
    path: '/thermometryDash/:id/settings',
    component: ThermometrySettings,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_SETTINGS_VIEW.value, */
  },
  {
    path: '/irrigationDash/:id',
    component: IrrigationDash,
    isPrivate: true,
    /* permissionId: PortalPermissions.IRRIGATION_DASHBOARD_VIEW.value, */
  },
  {
    path: '/irrigationDash/:id/settings',
    component: IrrigationSettings,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_SETTINGS_VIEW.value, */
  },
  {
    path: '/reports/irrigation-planning',
    component: IrrigationPlanning,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_SETTINGS_VIEW.value, */
  },
  {
    path: '/reportsAdm/irrigation-planning',
    component: IrrigationPlanning,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_SETTINGS_VIEW.value, */
  },
  {
    path: '/plants/:id/settings',
    component: PlantSettings,
    isPrivate: true,
    /* permissionId: PortalPermissions.IRRIGATION_DASHBOARD_VIEW.value, */
  },
  {
    path: '/reports/thermoquality',
    component: ThermometryQualityReport,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value, */
  },
  {
    path: '/reportsAdm/thermoquality',
    component: ThermometryQualityReport,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value, */
  },
  {
    path: '/reports/thermometry',
    component: ThermometryReport,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value, */
  },
  {
    path: '/reports/aeration',
    component: AerationReport,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value, */
  },
  {
    path: '/reports/variableTracking',
    component: VariableTracking,
    isPrivate: true,
    // permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
  },
  {
    path: '/reportsAdm/engineCost',
    component: EnergyConsumption,
    isPrivate: true,
    // permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
  },
  {
    path: '/reportsAdm/photolcoltaic-earnings',
    component: PhotovoltaicReportEarning,
    isPrivate: true,
    // permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
  },
  {
    path: '/reportsAdm/variableTracking',
    component: VariableTracking,
    isPrivate: true,
    // permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
  },
  {
    path: '/reportsAdm/thermometry',
    component: ThermometryReport,
    isPrivate: true,
    // permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
  },
  {
    path: '/reportsAdm/aeration',
    component: AerationReport,
    isPrivate: true,
    // permissionId: PortalPermissions.THERMOMETRY_REPORTS_MANAGE.value,
  },
  {
    path: '/energyCost',
    component: EnergyCost,
    isPrivate: true,
    /* permissionId: PortalPermissions.ENERGY_COST_MANAGE.value, */
  },
  {
    path: '/units',
    component: Units,
    isPrivate: true,
    /* permissionId: PortalPermissions.UNITS_VIEW.value, */
  },
  {
    path: '/portalUsers',
    component: PortalUsers,
    isPrivate: true,
    /* permissionId: PortalPermissions.USERS_VIEW.value, */
  },
  {
    path: '/settings/blockedHosts',
    component: BlockedHosts,
    isPrivate: true,
  },
  {
    path: '/settings/techDeliveryTypes',
    component: TechDeliveryTypes,
    isPrivate: true,
  },
  {
    path: '/403',
    component: Forbidden403,
    isPrivate: true,
  },
  {
    path: '/account/settings',
    component: AccountClientSettings,
    isPrivate: true,
  },
  {
    path: '/settings/soilPhysicalAnalysis',
    component: SoilPhysicalAnalysisCl,
    isPrivate: true,
  },
  {
    path: '/settings/soilPhysicalAnalysis/:clid',
    component: SoilPhysicalAnalysis,
    isPrivate: true,
  },
  {
    path: '/settings/soilPhysicalAnalysis/:clid/:id',
    component: SoilPhysicalAnalysisForm,
    isPrivate: true,
  },
  {
    path: '/settings/costFuel/:id',
    component: FuelCostForm,
    isPrivate: true,
  },
  {
    path: '/settings/costFuel',
    component: FuelCostList,
    isPrivate: true,
  },
  {
    path: '/settings/plans/:id',
    component: PlansForm,
    isPrivate: true,
  },
  {
    path: '/settings/plans',
    component: PlansList,
    isPrivate: true,
  },
  {
    path: '/ListCost/ConfigCost/costManagement',
    component: StorageCostList,
    isPrivate: true,
  },
  {
    path: '/ListCost/ConfigCost/costManagement/:id',
    component: StorageCostForm,
    isPrivate: true,
  },
  {
    path: '/reports/engineCost',
    component: EnergyConsumption,
    isPrivate: true,
  },
  {
    path: '/weatherStation/:id',
    component: WheaterStationDash,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_DASHBOARD_VIEW.value, */
  },
  {
    path: '/weatherStation/:id/settings',
    component: WeatherStationSettings,
    isPrivate: true,
    /* permissionId: PortalPermissions.THERMOMETRY_SETTINGS_VIEW.value, */
  },
  {
    path: '/settings/reportSettings',
    component: ReportSettings,
    isPrivate: true,
  },
  {
    path: '/settings/reportSettings/qualityReport',
    component: RepFormQualityReport,
    isPrivate: true,
  },
  {
    name: 'Assistência Técnica',
    icon: <IoTicket size={22} />,
    path: '/support',
    component: ticketsList,
    isPrivate: true,
    showIn: ['clientadmin'],
  },
  {
    path: '/support/:id',
    component: ticketsForm,
    isPrivate: true,
  },
  {
    path: '/support/ticket/:id',
    component: interactions,
    isPrivate: true,
  },
  {
    name: 'Fale Conosco',
    icon: <QuestionAnswerSharpIcon />,
    path: '/myfeedbacks',
    component: ListMyFeedBacks,
    isPrivate: true,
    showIn: ['clientadmin'],
  },
  {
    path: '/myfeedbacks/edit/:id',
    component: EditFeedback,
    isPrivate: true,
  },
  {
    name: 'Fale Conosco',
    icon: <QuestionAnswerSharpIcon />,
    path: '/feedbacks',
    component: ListFeedBacks,
    isPrivate: true,
    showIn: ['admin'],
  },
  {
    path: '/feedbacks/answer/:id',
    component: AnswerFeedback,
    isPrivate: true,
  },
];

const findByLink = (link) => {
  const replaceLink = link.replace(
    /([0-9a-f]{8})\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b([0-9a-f]{12})/,
    ':id',
  );
  const route = links.find(
    (f) => f.path === `${replaceLink.startsWith('/') ? '' : '/'}${replaceLink}`,
  );

  if (route) {
    route.to = replaceLink;
  }

  return route;
};

const navbarLinks = (token, profile) => (
  <div>
    {links
      .filter((f) => {
        if (
          !token &&
          !f.isPrivate &&
          !f.noMenu &&
          ((f.showIn || [])[0] === 'all' ||
            (f.showIn || []).indexOf(profile) > -1)
        ) {
          return true;
        }
        if (
          token &&
          f.isPrivate &&
          !f.noMenu &&
          ((f.showIn || [])[0] === 'all' ||
            (f.showIn || []).indexOf(profile) > -1)
        ) {
          if (f.permissionId) {
            const p = JSON.parse(localStorage.permissions || '[]');
            if (p.indexOf(f.permissionId) > -1) {
              return true;
            }
            return false;
          }
          return true;
        }
        return false;
      })
      .map((e) => (
        <Link to={e.path} key={e.path} style={{ textDecoration: 'none' }}>
          <ListItem button>
            <ListItemIcon>{e.icon}</ListItemIcon>
            <ListItemText primary={e.name} />
          </ListItem>
        </Link>
      ))}
  </div>
);

const routerLinks = () => (
  <Switch>
    {links.map((e) => {
      if (e.isPrivate) {
        return (
          <PR.PrivateRoute
            exact
            path={e.path}
            permissionId={e.permissionId}
            component={e.component}
            key={e.path}
          />
        );
      }
      return <Route path={e.path} component={e.component} key={e.path} />;
    })}
  </Switch>
);

export default {
  findByLink,
  navbarLinks,
  routerLinks,
};
