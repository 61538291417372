import React from 'react';
import '../../../../App.css';
import { Redirect } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Skeleton from '@material-ui/lab/Skeleton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { Card, CardContent, Tooltip } from '@material-ui/core';
import { RiAttachment2 } from 'react-icons/ri';
import { formatDate } from '../../../../helpers/Intl';
import FockinkAPI from '../../../../middlewares/Api';
import BackButton from '../../../../components/Buttons/BackButton';
import Breadcrumbs from '../../../../components/Breadcrumbs';

export default () => {
  const [inputs, setInputs] = React.useState([]);
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);

  const breadcrumb = {
    title: 'Suporte',
    routes: [{ name: 'Suporte' }],
  };

  React.useEffect(() => {
    setLoading(true);
    FockinkAPI.get('/attendanceTecAssist', { params: {
      // status: 'A',
    } }).then((response) => {
      setInputs(Object.values(response.data.data));
      setLoading(false);
    });
    // setLoading(false);
  }, []);

  React.useEffect(() => {
    const columns = [
      {
        name: 'Número',
        selector: 'numero',
        cell: (row) => row.codigo,
        sortable: true },
      {
        name: 'Assunto',
        selector: 'assunto',
        cell: (row) => row.assunto,
        sortable: true,
      },
      // {
      //   name: 'Tipo',
      //   selector: 'tipoManutencaoDescricao',
      //   cell: (row) => row.tipoAtividadeDescricao,
      //   sortable: true },
      {
        name: 'Status',
        selector: 'status',
        cell: (row) => row.status,
        sortable: true },
      {
        name: 'Data de inclusão',
        selector: 'dataInclusao',
        cell: (row) => formatDate(moment.utc(row.dataInclusao).toDate()),
        sortable: true,
        center: true,
      },
      {
        name: 'Data de solicitação',
        selector: 'dataSolicitacao',
        cell: (row) => formatDate(moment.utc(row.dataSolicitacao).toDate()),
        sortable: true,
        center: true,
      },
      {
        name: 'Prioridade',
        cell: (row) => row.prioridade,
        selector: 'prioridade',
        sortable: true,
      },
      {
        name: 'Interações',
        cell: (row) => row.totalDeInteracoes,
        selector: 'totalDeInteracoes',
        sortable: true,
      },
      {
        name: '',
        center: true,
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton color="secondary" onClick={() => setRedirect(row.codigo)}>
              <VisibilityIcon />
            </IconButton>
          </div>
        ),
      },

    ];
    setDtCl({ columns, data: inputs });
  }, [inputs]);

  if (redirect) {
    return <Redirect push to={`/support/ticket/${redirect}`} />;
  }

  // if (loading) {
  //   return (
  //     <Skeleton variant="rect" height={500} width="100%" animation="wave" />
  //   );
  // }

  return (
    <>
      <Breadcrumbs
        routes={breadcrumb.routes}
        title={breadcrumb.title}
        newBtn="Novo Ticket"
      />
      {loading === true ?
        (<Skeleton variant="rect" height={500} width="100%" animation="wave" />)
        : (
          <Card>
            <CardContent>
              <div>
                <DataTable
                  pagination
                  paginationPerPage={15}
                  paginationComponentOptions={{
                    rowsPerPageText: 'Linhas:',
                    rangeSeparatorText: 'de',
                  }}
                  paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
                  noDataComponent="Não existem dados para serem mostrados"
                  columns={dtCl.columns}
                  data={dtCl.data}
                  defaultSortField="dataInclusao"
                  defaultSortAsc={false}
                  title=""
                  style={{ marginTop: -70 }}
                />
              </div>
            </CardContent>
          </Card>
        )}
      <Grid item xs={12} style={{ margin: '25px 0', textAlign: 'right' }}>
        <BackButton redirectTo="/" />
      </Grid>

    </>
  );
};
