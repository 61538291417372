import React from 'react';
import DataTable from 'react-data-table-component';
import { number } from '../../../../helpers/Intl';

const columns = [
  { name: 'Data', selector: 'date', center: true, sortable: true },
  {
    name: 'Temp. Max',
    selector: 'maxTemp',
    center: true,
    sortable: true,
    compact: true,
    cell: (r) => `${number(r.maxTemp)}°C`,
  },
  {
    name: 'Temp. Min',
    selector: 'minTemp',
    center: true,
    sortable: true,
    compact: true,
    cell: (r) => `${number(r.minTemp)}°C`,
  },
  {
    name: 'Umid. Max',
    selector: 'maxHumd',
    center: true,
    sortable: true,
    compact: true,
    cell: (r) => `${number(r.maxHumd)}%`,
  },
  {
    name: 'Umid. Min',
    selector: 'minHumd',
    center: true,
    sortable: true,
    compact: true,
    cell: (r) => `${number(r.minHumd)}%`,
  },
  {
    name: 'Chuva Acumulada',
    selector: 'rainDayAmount',
    center: true,
    sortable: true,
    compact: true,
    cell: (r) => `${number(r.rainDayAmount)}mm`,
  },
  {
    name: 'Velocidade do Vento',
    selector: 'windSpeed',
    center: true,
    sortable: true,
    compact: true,
    cell: (r) => `${number(r.windSpeed)}km/h`,
  },
  {
    name: 'UV',
    selector: 'solarRadiation',
    center: true,
    sortable: true,
    compact: true,
    cell: (r) => `${number(r.solarRadiation)}W/m²`,
  },
  // {
  //   name: 'Sensação Térmica',
  //   selector: 'thermicSensation',
  //   center: true,
  //   sortable: true,
  //   compact: true,
  //   cell: (r) => `${number(r.thermicSensation)}°C`,
  // },
  // {
  //   name: 'Ponto de Orvalho',
  //   selector: 'dewPoint',
  //   center: true,
  //   sortable: true,
  //   compact: true,
  //   cell: (r) => `${number(r.dewPoint)}°C`,
  // },
  // {
  //   name: 'Pressão Atmosférica',
  //   selector: 'atmosphericPressure',
  //   center: true,
  //   sortable: true,
  //   compact: true,
  //   cell: (r) => `${number(r.atmosphericPressure)}°C`,
  // },
  {
    name: 'Evapotranspiração',
    selector: 'evapotranspiration',
    center: true,
    sortable: true,
    compact: true,
    cell: (r) => `${number(r.evapotranspiration)}mm`,
  },
];

export default function WeatherStationReportTable({ data }) {
  const formattedColumns = [...columns];
  return (
    <DataTable
      pagination
      paginationPerPage={15}
      paginationComponentOptions={{
        rowsPerPageText: 'Linhas:',
        rangeSeparatorText: 'de',
      }}
      paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
      noDataComponent="Não existem dados para serem mostrados"
      columns={formattedColumns}
      data={data}
    />
  );
}
