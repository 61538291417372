import React from 'react';
import colors from '../../styles/colors';

export default (props) => {
  const {
    stScheduledDate,
    stPressureTime,
    stHP,
    stPivot,
    stPivotLocked,
    stInitialTtime,
    stStoppedByRain,
    stFailEnergy,
    stProgramOff,
    stReversal,
  } = props.status;

  const status = defineStatus();
  function defineStatus() {
    return {
      clockColor: defineColor(),
      textColor: colors.white,
      /* textTop: stScheduledDate || stPressureTime || stHP ? 'AGUARDANDO' : '--', */
      textBottom: defineTextBottom(),
      backgroundColor: defineColor(),
    };
  }
  function defineTextBottom() {
    if (stScheduledDate) {
      return 'DATA PROGRAMADA';
    } if (stHP) {
      return 'HORA PONTA';
    } if (stPressureTime) {
      return 'PRESSÃO';
    } if (stPivot) {
      return 'EM MOVIMENTO';
    } if (stPivotLocked) {
      return 'BLOQUEADO';
    } if (stInitialTtime) {
      return 'TEMPO INICAL';
    } if (stStoppedByRain) {
      return 'CHUVA';
    } if (stFailEnergy) {
      return 'ENERGIA';
    } if (stProgramOff) {
      return 'DATA PROGRAMADA';
    } if (stReversal) {
      return 'REVERSÃO';
    }
    return 'LIBERADO';
  }

  function defineColor() {
    if (stScheduledDate) {
      return colors.warning;
    } if (stHP) {
      return colors.warning;
    } if (stPressureTime) {
      return colors.warning;
    } if (stPivot) {
      return colors.pivotOk;
    } if (stPivotLocked) {
      return colors.error;
    } if (stInitialTtime) {
      return colors.warning;
    } if (stStoppedByRain) {
      return colors.warning;
    } if (stFailEnergy) {
      return colors.warning;
    } if (stProgramOff) {
      return colors.warning;
    } if (stReversal) {
      return colors.warning;
    }
    return colors.pivotOk;
  }
  return (
    <svg viewBox="0 0 500 500" width="100%">
      <defs>
        <grid x="0" y="0" width="122.653" height="92.449" />
      </defs>

      <g id="CLOCK">
        <g transform="matrix(1, 0, 0, 1, 0, -10)" id="STATUS_ICON">
          <path
            style={{ fill: colors.grey }}
            transform="matrix(0.506885, 0, 0, 0.506885, -182.370163, -146.555939)"
            d="M 1114.099 569.522 A 290.059 290.059 0 0 1 986.759 939.039 L 847.778 684.444 Z"
            shape="pie 847.778 684.444 0 290.059 66.659 151.37 1@666ea4cd"
          />
          <circle
            style={{
              fill: 'none',
              stroke: status.clockColor,
              strokeWidth: '12px',
            }}
            cx="248.22"
            cy="202.574"
            r="148.498"
          />
          <line
            style={{
              strokeLinecap: 'round',
              stroke: status.clockColor,
              strokeWidth: '8px',
            }}
            x1="246.108"
            y1="200.085"
            x2="246.6"
            y2="85.472"
          />
          <line
            style={{
              strokeLinecap: 'round',
              stroke: status.clockColor,
              strokeWidth: '8px',
            }}
            x1="246.476"
            y1="200.352"
            x2="322.584"
            y2="168.826"
          />
          <line
            style={{
              strokeLinecap: 'round',
              stroke: status.clockColor,
              strokeWidth: '3px',
            }}
            x1="246.807"
            y1="74.54"
            x2="246.807"
            y2="57.315"
          />
          <line
            style={{
              strokeLinecap: 'round',
              stroke: status.clockColor,
              strokeWidth: '3px',
            }}
            x1="246.807"
            y1="346.343"
            x2="246.807"
            y2="329.117"
          />
          <line
            style={{
              strokeLinecap: 'round',
              stroke: status.clockColor,
              strokeWidth: '3px',
            }}
            x1="102.477"
            y1="204.055"
            x2="115.631"
            y2="204.055"
          />
          <line
            style={{
              strokeLinecap: 'round',
              stroke: status.clockColor,
              strokeWidth: '3px',
            }}
            x1="377.799"
            y1="204.055"
            x2="390.953"
            y2="204.055"
          />
          <line
            style={{
              strokeLinecap: 'round',
              stroke: status.clockColor,
              strokeWidth: '3px',
            }}
            x1="182.235"
            y1="92.317"
            x2="173.622"
            y2="77.399"
          />
          <line
            style={{
              strokeLinecap: 'round',
              stroke: status.clockColor,
              strokeWidth: '3px',
            }}
            x1="318.136"
            y1="327.705"
            x2="309.523"
            y2="312.787"
          />
          <line
            style={{
              strokeLinecap: 'round',
              stroke: status.clockColor,
              strokeWidth: '3px',
            }}
            x1="121.998"
            y1="276.646"
            x2="133.39"
            y2="270.069"
          />
          <line
            style={{
              strokeLinecap: 'round',
              stroke: status.clockColor,
              strokeWidth: '3px',
            }}
            x1="360.434"
            y1="138.984"
            x2="371.826"
            y2="132.407"
          />
          <line
            style={{
              strokeLinecap: 'round',
              stroke: status.clockColor,
              strokeWidth: '3px',
            }}
            x1="135.868"
            y1="137.501"
            x2="120.95"
            y2="128.888"
          />
          <line
            style={{
              strokeLinecap: 'round',
              stroke: status.clockColor,
              strokeWidth: '3px',
            }}
            x1="371.256"
            y1="273.403"
            x2="356.339"
            y2="264.79"
          />
          <line
            style={{
              strokeLinecap: 'round',
              stroke: status.clockColor,
              strokeWidth: '3px',
            }}
            x1="175.867"
            y1="327.253"
            x2="182.444"
            y2="315.861"
          />
          <line
            style={{
              strokeLinecap: 'round',
              stroke: status.clockColor,
              strokeWidth: '3px',
            }}
            x1="313.528"
            y1="88.817"
            x2="320.105"
            y2="77.425"
          />
          <circle
            style={{ fill: status.clockColor }}
            cx="247.414"
            cy="198.254"
            r="18.744"
          />
          <line
            style={{
              fill: status.clockColor,
              stroke: status.clockColor,
              strokeWidth: '4px',
            }}
            x1="245.293"
            y1="201.074"
            x2="190.934"
            y2="291.928"
          />
        </g>
        <g id="STATUS_TEXT">
          <rect
            x="2.095"
            y="371.841"
            width="495.584"
            height="126.027"
            style={{
              fill: status.backgroundColor,
              stroke: colors.grey,
              strokeWidth: '5px',
            }}
            rx="20.974"
            ry="20.974"
            id="STATUS_TEXT_BACKGROUND"
          />
          {/* <text
            style={{
              fill: status.textColor,
              fontFamily: 'Roboto',
              fontSize: '42px',
              fontWeight: 700,
              textAnchor: 'middle',
              whiteSpace: 'pre',
            }}
            x="248.344"
            y="426.288"
            id="STATUS_TEXT_FIRST"
          >
            {status.textTop}
          </text> */}
          <text
            style={{
              fill: status.textColor,
              fontFamily: 'Roboto',
              fontSize: '42px',
              fontWeight: 700,
              textAnchor: 'middle',
              whiteSpace: 'pre',
            }}
            x="248.344"
            y="445.212"
            id="STATUS_TEXT_SECOND"
          >
            {status.textBottom}
          </text>
        </g>
      </g>
    </svg>
  );
};
