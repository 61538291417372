import { useFormik } from 'formik';
import React from 'react';
import { DatePicker } from '../../../../../../../../../components/DatePicker';
import { formatDate } from '../../../../../../../../../helpers/Intl';
import FockinkAPI from '../../../../../../../../../middlewares/Api';
import { validationSchema } from './validationSchema';
import { ButtonComponent as Button } from '../../../../../../../../../components/Button';
import { Wrapper } from './styles';
import { Input } from '../../../../../../../../../components/Input';
import { CardOption } from '../../../../../../../../../components/CardOption';

export function HarvestForm({ handleCloseHarvestFormModal, culture, handleGetCultures }) {
  const [date, setDate] = React.useState();
  const [errorRequest, setErrorRequest] = React.useState();

  const formik = useFormik({
    initialValues: {
      harvestDate: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const data = {
          equipamentId: culture.equipamentId,
          cultivationAccountId: culture.cultivationAccount.id,
          plantingDate: culture.plantingDate,
          angleFrom: culture.angleFrom,
          angleTo: culture.angleTo,
          observation: String(culture.observation),
          status: culture.status,
          harvestDate: values.harvestDate,
        };

        await FockinkAPI.put(`cultivation/${culture.id}`, data);
        handleCloseHarvestFormModal();
        handleGetCultures();
      } catch (error) {
        setErrorRequest(JSON.parse(error.request).message);

        setTimeout(() => {
          setErrorRequest(false);
        }, 3000);
      }
    },
  });

  const handleDateChange = (haverstDateSelect) => {
    setDate(haverstDateSelect);
    const formatHaversDate = formatDate(haverstDateSelect, 'yyyy-MM-dd');
    formik.setValues({ ...formik.values, harvestDate: formatHaversDate });
  };

  return (
    <Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <div className="angles-field">
          <Input
            disabled
            name="angleFrom"
            value={culture.angleFrom}
            onChange={formik.handleChange}
            label="Ângulo inicial"
            error={formik.errors.angleFrom}
            type="number"
          />

          <Input
            disabled
            type="number"
            style={{ marginLeft: '0.5rem' }}
            name="angleTo"
            value={culture.angleTo}
            onChange={formik.handleChange}
            label="Ângulo final"
            error={formik.errors.angleTo}
          />
        </div>

        <Input
          disabled
          name="observation"
          value={culture.observation}
          onChange={formik.handleChange}
          label="Observações"
          error={formik.errors.observation}
        />

        <CardOption
          checked={culture.status === 'A'}
        >
          {' '}
          Status -
          {' '}
          {formik.values.status === 'A' ? 'Ativo' : 'Inativo'}
        </CardOption>

        <DatePicker selectedDate={date} handleDateChange={handleDateChange} label="Data de colheita" />
        {formik.errors.harvestDate}

        <div className="button-confirm">
          <Button type="submit" isDisabled={!!formik.errors.harvestDate || date === undefined}>
            Atualizar
          </Button>
        </div>

        {errorRequest && <span>Erro na requisição</span>}
      </form>
    </Wrapper>
  );
}
