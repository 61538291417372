/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DataTable from 'react-data-table-component';
import jwtDecode from 'jwt-decode';
import Card from '../../../../components/Card';
import CardText from '../../../../components/CardText';
import { formatDate, number } from '../../../../helpers/Intl';
import ThermometrySensorHistory from '../../Dashes/ThermometryDash/Cards/ThermometrySensorHistory';

export default (props) => {
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const token = localStorage.getItem('@FockinkAPP:token');
  const { unit } = jwtDecode(token);

  React.useEffect(() => {
    const columns = [
      { name: unit?.plural || 'Unidades',
        cell: (row) => (row.units || []).length > 0 ? row.units.map((m) => m.description).join(', ') : 'Não associado',
      },
      { name: 'Equipamento', cell: (row) => row.equipament.clientDescription || row.equipament.description },
      { name: 'Planta', selector: 'plant.description' },
      { name: 'Data', selector: 'date' },
      { name: 'Grão', selector: 'grain' },
      { name: 'Subárea', selector: 'sub', center: true },
      { name: 'Cabo', selector: 'cab', center: true },
      { name: 'Sensor', selector: 'sen', center: true },
      {
        name: 'Temperatura',
        cell: (row) => <div data-tag="allowRowEvents">{`${number(row.temp, 1)}°C`}</div>,
        center: true,
      },
    ];

    const data = props.data.map((m) => ({
      date: formatDate(m.date, 'dd/MM/yyyy'),
      plant: m.plant,
      grain: m.grain,
      equipament: m.equipament,
      units: m.units,
      sub: m.sub,
      cab: m.cab,
      sen: m.sen,
      temp: m.temp,
      equipamentId: m.equipamentId,
      arc: m.arc,
    }));

    setDtCl({ columns, data });
  }, [props.data]);

  const ExpandedComponent = ({ data }) => (
    <ThermometrySensorHistory
      sensorData={{
        equipamentId: data.equipamentId,
        sub: data.sub,
        arc: data.arc,
        cab: data.cab,
        sen: data.sen,
        date: data.date,
      }}
    />
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Card square>
          <CardText pt="0px">
            <DataTable
              noHeader
              pagination
              paginationPerPage={5}
              paginationComponentOptions={{
                rowsPerPageText: 'Linhas:',
                rangeSeparatorText: 'de',
              }}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
              noDataComponent="Não existem dados para serem mostrados"
              expandableRows
              columns={dtCl.columns}
              data={dtCl.data}
              expandableRowsComponent={<ExpandedComponent />}
            />
          </CardText>
        </Card>
      </Grid>
    </Grid>
  );
};
