import { useFormik } from 'formik';
import React from 'react';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { Button } from '@material-ui/core';
import { BsCloudDownload } from 'react-icons/bs';
import withReactContent from 'sweetalert2-react-content';
import * as S from './style';
import { DropZoneContainer } from '../../../../../../components';
import FockinkAPI from '../../../../../../middlewares/Api';
import { handleMessageError } from '../../../../../../helpers/handlerMessageError';

export function UploadTelemetryVariables({
  handleGetTelemetry,
  handleCloseNewTelemetryModal,
}) {
  const formik = useFormik({
    initialValues: {
      friendlyName: '',
      variablePattern: '',
      system: '',
      operationId: '',
      trackingAvailable: false,
      alarmAvailable: false,
      type: '',
      status: 'A',
    },
    onSubmit: async (values) => {
      try {
        await FockinkAPI.post('/telemetryVariables/', values);

        handleGetTelemetry();
        formik.resetForm();
        handleCloseNewTelemetryModal();
        toast.success('Variável cadastrada');
      } catch (error) {
        handleMessageError(error);
      }
    },
  });

  const MySwal = withReactContent(Swal);
  const [selectedFiles, setSelectedFiles] = React.useState([]);

  const onDrop = React.useCallback((acceptedFiles) => {
    setSelectedFiles((preventState) => [...preventState, ...acceptedFiles]);
  }, []);

  const handleUploadFile = async ({ file }) => {
    try {
      const formData = new FormData();
      formData.append('uploadedFile', file);

      const config = {
        onUploadProgress: (progressEvent) => progressEvent.loaded,
      };

      const resp = await FockinkAPI.post(
        'telemetryVariables/upload',
        formData,
        config,
      );
      const copyState = [...selectedFiles];
      const newState = copyState.filter(
        (fileFromState) => fileFromState.path !== file.path,
      );
      setSelectedFiles(newState);

      toast.success(
        `Sucesso! ${resp.data.data.inserted} Inseridos e ${resp.data.data.updated} Atualizados`,
      );
    } catch (error) {
      MySwal.fire({
        title: error.response.data.message,
        html: `<div style="text-align: left">${error.response.data.errors
          .map((e) => `Linha ${e.row} - ${e.column}<br/>`)
          .join('')}</div>`,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    }
  };

  return (
    <S.Content>
      <Button
        color="primary"
        variant="contained"
        target="_blank"
        href="/docs/KonectaVariables.xlsx"
        startIcon={<BsCloudDownload />}
        style={{ marginBottom: '15px' }}
      >
        Baixar Template
      </Button>
      <DropZoneContainer
        confirmUpload={handleUploadFile}
        alternativeLabel="Upload de arquivos"
        onDrop={onDrop}
        noFilename
        multiple={false}
        files={selectedFiles}
      />
    </S.Content>
  );
}
