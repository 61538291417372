import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { number } from '../helpers/Intl';

export default (props) => {
  const [state, setState] = React.useState(undefined);

  const formatNumber = (n) => {
    if (n === 999) {
      return 'OFF';
    }
    if (n === 998) {
      return 'N/D';
    }
    if (n === 997) {
      return 'ERR';
    }
    return `${number(n)}%`;
  };

  React.useEffect(() => {
    const stateInt = {
      series: [props.level || 0],
      options: {
        chart: {
          toolbar: {
            show: false,
          },
          height: 150,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '40%',
            },
            dataLabels: {
              name: {
                fontSize: '0.7rem',
                color: 'gray',
              },
              value: {
                offsetY: props.noName ? -8 : 18,
                fontSize: '1.3em',
                color: '#727272',
                fontWeight: '600',
                formatter(value) {
                  return formatNumber(value);
                },
              },
            },
          },
        },
        labels: [props.noName ? '' : props.name || 'Nível'],
        colors: [props.color || '#319EFA'],
      },
    };
    setState(stateInt);
  }, [props.level, props.name, props.color, props.noName]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ padding: '0' }}>
        {!state ? (
          <Skeleton variant="rect" height={120} width="100%" animation="wave" />
        ) : (
          <ReactApexChart
            options={state.options}
            series={state.series}
            type={state.options.chart.type}
            height={state.options.chart.height}
          />
        )}
      </Grid>
    </Grid>
  );
};
