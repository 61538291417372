/* eslint-disable react-hooks/exhaustive-deps */
// Basic
import React from 'react';

// UI, images, styles and icons
import '../../../../App.css';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import EquipError from '../../../../components/EquipError';
import IndustrialAutomationRoute from './IndustrialAutomationRoute';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import DashesHeader from '../../../../components/DashesHeader';
import BackButton from '../../../../components/Buttons/BackButton';
import { handleMessageError } from '../../../../helpers/handlerMessageError';
import { DashTitle } from '../DashesStyles/index';

// API
import FockinkAPI from '../../../../middlewares/Api';
import { Modal } from '../../../../components';
import { FilesToDownload } from '../FilasToDownloadList';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  spacing: {
    marginTop: '15px',
  },
  btnBttn: {
    textAlign: 'right',
  },
  container: {
    height: '100%',
  },
  content: {
    margin: '0',
  },
  title: {
    textAlign: 'center',
    width: '100%',
  },
  off: {
    color: 'red',
  },
  on: {
    color: '#026502',
  },
  titleDescription: {
    textAlign: 'left',
    width: '100%',
    margin: '0',
  },
  skeleton: {
    marginTop: '15px',
  },
});

export default (props) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState(undefined);
  const [returnToList, setReturnToList] = React.useState(false);
  const [breadcrumbs, setBreadcrumbs] = React.useState(null);
  const [
    availableFilesToDownload,
    setAvailableFilesToDownload,
  ] = React.useState([]);
  const [openModalDownloadFiles, setOpenModalDownloadFiles] = React.useState(
    false,
  );

  const handleCloseModelDownloadFiles = () => setOpenModalDownloadFiles(false);

  const getDocumentsFileToDonwload = async () => {
    try {
      const response = await FockinkAPI.get(
        `equipaments/${props.match.params.id}/readFiles`,
      );

      const fomattedFileObject = response.data.data.map((file) => ({
        file,
        fileName: file.split('/').pop(),
      }));

      setAvailableFilesToDownload(fomattedFileObject);
    } catch (error) {
      handleMessageError(error);
    }
  };

  React.useEffect(() => {
    for (let x = 1; x <= 16; x += 1) {
      new Image().src = `/images/industrialAutomation/${x}.svg`;
    }
  }, []);
  React.useEffect(() => {
    (async () => {
      await getDocumentsFileToDonwload();
    })();
    loadRoutes();
    const interval = setInterval(() => {
      loadRoutes();
    }, 600000);
    return () => clearInterval(interval);
  }, [props.match.params.id]);

  // Methods
  const loadRoutes = () => {
    setLoading(true);
    FockinkAPI.get(
      `/dashboards/industrialAutomation/${props.match.params.id}/routes`,
    )
      .then((response) => {
        setBreadcrumbs({
          routes: [
            { name: 'Equipamentos', to: '/equipamentData' },
            {
              name:
                response.data.data.clientDescription ||
                response.data.data.description,
            },
            { name: 'Rotas' },
          ],
          secondRoute: [
            { name: 'Clientes', to: '/clientsAdm/' },
            {
              name: 'Equipamentos',
              to: `/equipamentDataAdm?accountId=${response.data.data.account.id}&op=all`,
            },
            {
              name:
                response.data.data.clientDescription ||
                response.data.data.description,
            },
          ],
        });
        setData(response.data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.error('IAD catch', e);
        if (e?.response?.status === 503) {
          setErrorMessage('Equipamento sem conexão para trazer os dados');
        } else {
          setErrorMessage(`${e.response.data.message} (${e.response.status})`);
        }
        setLoading(false);
      });
  };
  const urlParams = new URLSearchParams(window.location.search);
  const whatRoute = urlParams.get('whatRoute');
  if (returnToList) {
    return (
      <Redirect
        push
        to={
          whatRoute === 'Equips'
            ? `/equipamentDataAdm?accountId=${data.account?.id}&op=all`
            : '/equipamentData'
        }
      />
    );
  }
  if (errorMessage) return <EquipError errorMessage={errorMessage} />;

  if (loading) {
    return (
      <>
        <Breadcrumbs loading={loading} />
        <DashesHeader loading={loading} />
        <Grid container spacing={2} direction="column">
          <Skeleton variant="rect" height={250} width="100%" animation="wave" />
          {[0, 1, 2, 3].map((k) => (
            <Skeleton
              key={k}
              className={classes.skeleton}
              variant="rect"
              height={250}
              width="100%"
              animation="wave"
            />
          ))}
        </Grid>
      </>
    );
  }

  return (
    <>
      <Breadcrumbs
        routes={whatRoute ? breadcrumbs.secondRoute : breadcrumbs.routes}
        loading={loading}
      />
      <DashTitle>{data.account?.description}</DashTitle>
      <DashesHeader
        title={data.clientDescription || data.description}
        openModal={setOpenModalDownloadFiles}
        connection={data.connection}
        running={data.running}
        alert={data.failures}
        hasCustomRoute={whatRoute === 'Equips' ? `?whatRoute=${whatRoute}` : ''}
        type="Planta"
        loading={loading}
      />
      <Grid
        container
        spacing={2}
        direction="column"
        style={{ minHeight: 'calc(100vh - 101px)' }}
      >
        {data.routes.map((r) => (
          <Grid key={`${r.name}${Math.random()}`} item>
            <IndustrialAutomationRoute
              route={r}
              equipamentId={props.match.params.id}
              subEquipaments={data.subEquipaments}
              loadRoutes={loadRoutes}
            />
          </Grid>
        ))}
        <Grid item xs={12} className={classes.btnBttn}>
          <BackButton
            back={() => setReturnToList(true)}
            className={classes.btnSpace}
          />
        </Grid>
      </Grid>
      {availableFilesToDownload && (
        <Modal
          title="Arquivos Disponíveis para Download"
          open={openModalDownloadFiles}
          handleCloseModal={handleCloseModelDownloadFiles}
        >
          <FilesToDownload files={availableFilesToDownload} />
        </Modal>
      )}
    </>
  );
};
