// import React from 'react';

export default function crossSection(data) {
  if (data.length > 0) {
    const corteCabo = [];

    data.forEach((arc) => {
      corteCabo.push(arc.cable);
    });

    // console.log('corteCabo->', corteCabo);

    return (corteCabo);
  }
}
