// import React from 'react';

function motorTopLeft(arc, z) {
  let top;
  let left;
  let indicator; // [0,1,2,3] === ['Esquerda', 'em cima', 'direita', 'em baixo']

  // Motores em cima
  if (z <= 89) {
    top = -40;
    left = (arc * 30);
    indicator = 1;
  }
  // Motores abaixo
  if (z >= 161) {
    top = 250;
    left = (arc * 30);
    indicator = 3;
  }
  // Motores nas Cabeceiras
  if ((z >= 90) && (z <= 160)) {
    top = z - 15;
    if (arc < 4) { // Motores a esquerda
      left = -40;
      indicator = 0;
    } else if (arc > 4) { // Motores a direita
      left = 40 + (arc * 30);
      indicator = 2;
    }
  }

  return { top, left, indicator };
}

function motorsWithCableRefEquals(disposition) {
  const newDisposition = [];
  let count = 0;
  let engineLeft;
  let engineTop;

  disposition.forEach((item1) => {
    count = 0;
    disposition.forEach((item2) => {
      if (
        (item1.cable === item2.cable) && // mesmo cabo de referencia
        (item1.engineId !== item2.engineId) && // em diferentes motores
        (item1.engineTop === item2.engineTop) && // mesmo top
        (item1.engineLeft === item2.engineLeft) // mesmo left
      ) {
        count += 1;
        switch (item2.engineIndicator) {
          case 0:
            engineTop = item2.engineTop;
            engineLeft = item2.engineLeft - (40 * count);
            break;
          case 1:
            engineTop = item2.engineTop - (40 * count);
            engineLeft = item2.engineLeft;
            break;
          case 2:
            engineTop = item2.engineTop;
            engineLeft = item2.engineLeft + (40 * count);
            break;
          case 3:
            engineTop = item2.engineTop + (40 * count);
            engineLeft = item2.engineLeft;
            break;
          default:
            engineTop = item2.engineTop;
            engineLeft = item2.engineLeft;
        }
        newDisposition.push(
          {
            ...item2,
            engineTop,
            engineLeft,
          },
        );
      } else {
        newDisposition.push({ ...item2 });
      }
    });
    // console.log('item->', item1);
  });

  return newDisposition;
}

export default function autoDispositionMotors(engines, cablesDisposition) {
  const disposition = [];
  if ((!engines) || (!cablesDisposition)) {
    return '';
  }

  engines.map((engine) =>
    cablesDisposition.map((cablesInArc) => (
      cablesInArc.cables.map((cable) => {
        if (cable.num === engine.cableRef) {
          disposition.push({
            cable: engine.cableRef,
            arc: cablesInArc.arc,
            z: cable.z,
            engineId: engine.idMotor,
            engineName: engine.nameMotor,
            engineOnOff: engine.onOff,
            engineTop: motorTopLeft(cablesInArc.arc, cable.z).top,
            engineLeft: motorTopLeft(cablesInArc.arc, cable.z).left,
            engineIndicator: motorTopLeft(cablesInArc.arc, cable.z).indicator,
            engineAeration: engine.aeration,
          });
        }
        return '';
      })
    )));

  // MotorsWithCableRefEquals(disposition)

  return motorsWithCableRefEquals(disposition);
}
