import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import { BiErrorCircle } from 'react-icons/all';

export function Input({
  name,
  placeholder,
  label,
  type,
  style,
  onChange,
  value,
  error,
  disabled,
  variant,
  rows,
  multiline,
  autoComplete,
}) {
  return (
    <TextField
      disabled={disabled}
      autoComplete={autoComplete}
      error={error}
      placeholder={placeholder}
      type={type}
      style={{ margin: '0.3rem 0.2rem', ...style }}
      name={name}
      rows={rows}
      multiline={multiline}
      label={label}
      variant={variant || 'outlined'}
      onChange={onChange}
      value={value}
      helperText={error}
      InputProps={{
        startAdornment: error ? (
          <InputAdornment position="start">
            <BiErrorCircle color="#c53030" />
          </InputAdornment>
        ) : null,
      }}
    />
  );
}
