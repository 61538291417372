/* eslint-disable */
const tinycolor = require("tinycolor2");

const baseColors = {
    primaryUser: '#3D5059',
    primaryUserBright: new tinycolor('#3D5059').brighten(30).toHexString(),
    primaryUserBrighter: new tinycolor('#3D5059').brighten(60).toHexString(),
    secondaryUser: '#2F80ED',
    primaryAdmin: '#1e1f1d',
    secondaryAdmin: '#2F80ED',
    warning: '#ff7f17',
    error: '#ff3d3d',
    success: '#008800',
    info: '#219bff',
    background: '#E5E5E5',
    grey: '#d9d7d7',
    lightGrey: '#727272',
    darkGrey: '#999999',
    white: '#ffffff',
    black: '#333333',
    transparent: '#ffffff00',
    pivotOk: '#2f80ed',
    active: '#f5faff',
    pivotCommands:new tinycolor('#3D5059').brighten(60).toHexString(),
    disabled: '#f9f9f9',
    pivotNoWater: '#2f2f2f77',
    pivotWater: '#0048ff77',
    pivotCannon: '#0048ffAA',
    pivotZero: '#FF0000',
    pivotPosition: '#fc9003',
    pivotDestination: '#00FFFF',
    pivotOffStroke: '#9C9C9C',
    pivotOffFill: '#9C9C9C66',
    pivotOnFill: '#fc900366',
    pivotOnStroke: '#fc9003',
    pivotInfoFillWater: '#2f80edDD',
    pivotInfoFillNoWater: '#9A9A9ADD',
    pivotInfoStroke: '#ffffffDD',
}




export default {
    ...baseColors
}