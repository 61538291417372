import { useFormik } from 'formik';
import React from 'react';
import {
  MultiInputSelect,
  Input,
  ButtonComponent as Button,
  CardOption,
} from '../../../../../../components';
import { systemTypes } from '../../../../../../helpers/systemList';
import { useTelemetryVariablesHook } from '../../../../../../hooks/telemetryVariables';
import * as S from './style';

export const TelemetryFilters = ({ handleSubmit, closeModal }) => {
  const {
    telemetryVariablesFiltes,
    setTelemetryVariablesFiltes,
    DEFAULT_FILTER_VALUES,
  } = useTelemetryVariablesHook();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      system: telemetryVariablesFiltes.system,
      variablePattern: telemetryVariablesFiltes.variablePattern,
      friendlyName: telemetryVariablesFiltes.friendlyName,
      status: telemetryVariablesFiltes.status,
    },
    onSubmit: async (values) => {
      const valueKeys = Object.keys(values);

      const formatKeys = {};

      for (const key of valueKeys) {
        if (values[key] !== '') {
          formatKeys[key] = values[key];
        }
      }

      await handleSubmit(formatKeys);
      closeModal();
    },
  });

  const handleStatusValue = () => {
    if (telemetryVariablesFiltes.status === 'A') {
      setTelemetryVariablesFiltes({ ...telemetryVariablesFiltes, status: 'I' });
    } else {
      setTelemetryVariablesFiltes({ ...telemetryVariablesFiltes, status: 'A' });
    }
  };

  const clearFormAndResetList = async () => {
    setTelemetryVariablesFiltes(DEFAULT_FILTER_VALUES);
  };

  const handleChangeFilterValue = (event) => {
    const { name, value } = event.target;
    const newValues = { ...telemetryVariablesFiltes };
    newValues[name] = value;

    setTelemetryVariablesFiltes(newValues);
  };

  return (
    <S.Wrapper>
      <form action="" onSubmit={formik.handleSubmit}>
        <MultiInputSelect
          name="system"
          onChange={handleChangeFilterValue}
          label="Tipo de sistema"
          nameLabel="system"
          value={formik.values.system}
          data={systemTypes}
          valueData="system"
        />
        <Input
          name="variablePattern"
          label="Padrão da Variável"
          placeholder="Padrão da Variável"
          onChange={handleChangeFilterValue}
          value={formik.values.variablePattern}
        />

        <Input
          name="friendlyName"
          label="Nome Amigável"
          placeholder="Nome Amigável"
          onChange={handleChangeFilterValue}
          value={formik.values.friendlyName}
        />

        <CardOption
          checked={formik.values.status === 'A'}
          onClick={() => handleStatusValue()}
        >
          Status: Variáveis
          {' '}
          {formik.values.status === 'A' ? 'Ativas' : 'Inativas'}
        </CardOption>

        <Button
          type="submit"
          style={{ width: '100%', margin: '0.3rem 0.2rem' }}
        >
          Aplicar
        </Button>
        <Button
          type="button"
          handle={clearFormAndResetList}
          color="secondary"
          style={{ width: '100%', margin: '0.3rem 0.2rem' }}
        >
          Limpar Filtros
        </Button>
      </form>
    </S.Wrapper>
  );
};
