import { getDaysInMonth } from 'date-fns';
import { formatDate } from './Intl';

export const createDaysFromMonth = (date, lastTelemetry) => {
  const quantityDaysOfMonth = getDaysInMonth(new Date(date));

  const lastTelemetryList = Object.values(lastTelemetry);

  const labelDays = [];
  // eslint-disable-next-line
  for (let day = 1; day <= quantityDaysOfMonth; day++) {
    const telemetryFromDay = lastTelemetryList.find(
      (selectedDay) => new Date(selectedDay).getDate() === day,
    );

    if (telemetryFromDay) {
      const labelDate = {
        day,
        lastTelemetryOnDay: formatDate(new Date(telemetryFromDay), 'HH:mm'),
      };

      labelDays.push(labelDate);
    } else {
      const labelDate = {
        day,
        lastTelemetryOnDay: '-',
      };
      labelDays.push(labelDate);
    }
  }

  return labelDays;
};
