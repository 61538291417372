import React from 'react';
import '../../../../../App.css';
import { Redirect } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import EditIcon from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { toast } from 'react-toastify';
import DeleteIcon from '@material-ui/icons/Delete';
import FockinkAPI from '../../../../../middlewares/Api';
import BackButton from '../../../../../components/Buttons/BackButton';
import Breadcrumbs from '../../../../../components/Breadcrumbs';

export default (props) => {
  const [inputs, setInputs] = React.useState([]);
  const [dtCl, setDtCl] = React.useState({ columns: [], data: [] });
  const [loading, setLoading] = React.useState(true);
  const [redirect, setRedirect] = React.useState(false);
  const [refresh, setRefresh] = React.useState();
  const breadcrumb = {
    title: 'Análise Química do Solo',
    routes: [
      { name: 'Configurações', to: '/settings' },
      { name: 'Análise Química do Solo' },
    ],
  };

  React.useEffect(() => {
    FockinkAPI.get(
      `/soilChemicalAnalysis/?accountId=${props.match.params.clid}`,
    ).then((response) => {
      setInputs(response.data.data);
      setLoading(false);
    });
  }, [refresh]);

  const deleteItem = (value) => {
    FockinkAPI.delete(`/soilChemicalAnalysis/${value}`).then(() => {
      toast.success('Dados deletados com sucesso');
    });
    setRefresh(Math.random());
  };

  React.useEffect(() => {
    const columns = [
      {
        name: 'Pivô',
        selector: 'equipamentName',
        sortable: true,
      },
      {
        name: 'Registro',
        selector: 'registry',
        sortable: true,
      },
      {
        name: 'Laboratório',
        selector: 'laboratory',
        sortable: true,
      },
      {
        name: 'Data de recebimento',
        selector: 'receivingDate',
        cell: (row) => moment.utc(row.receivingDate).format('DD/MM/YYYY'),
        sortable: true,
      },
      {
        name: 'Data de expedição',
        selector: 'expirationDate',
        cell: (row) => moment.utc(row.expirationDate).format('DD/MM/YYYY'),
        sortable: true,
      },
      {
        name: '',
        center: true,
        cell: (row) => (
          <div
            data-tag="allowRowEvents"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <IconButton color="secondary" onClick={() => setRedirect(row.id)}>
              <EditIcon />
            </IconButton>
            <IconButton color="secondary" onClick={() => deleteItem(row.id)}>
              <DeleteIcon />
            </IconButton>
          </div>
        ),
      },
    ];
    setDtCl({ columns, data: inputs });
  }, [inputs]);

  if (redirect) {
    return (
      <Redirect
        push
        to={`/settings/soilAnalysis/${props.match.params.clid}/${redirect}`}
      />
    );
  }

  if (loading) {
    return (
      <Skeleton variant="rect" height={500} width="100%" animation="wave" />
    );
  }

  return (
    <>
      <Breadcrumbs
        routes={breadcrumb.routes}
        title={breadcrumb.title}
        newBtn="Nova Análise"
      />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <DataTable
            pagination
            paginationPerPage={15}
            paginationComponentOptions={{
              rowsPerPageText: 'Linhas:',
              rangeSeparatorText: 'de',
            }}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 50]}
            noDataComponent="Não existem dados para serem mostrados"
            columns={dtCl.columns}
            data={dtCl.data}
            defaultSortAsc={false}
            title=""
          />
        </Grid>
        <Grid item xs={12} style={{ margin: '25px 0', textAlign: 'right' }}>
          <BackButton redirectTo="/" />
        </Grid>
      </Grid>
    </>
  );
};
