import styled from 'styled-components';

export const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 950px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  padding: 0.7rem;
  flex: 1;
  width: 100%;
  border-radius: 4px;
  width: 100%;
  max-width: ${(props) => props.size === 'medium' ? '820px' : null};
  height: 100%;;

  & + & {
    margin-left: 0.4rem;
  }

  @media (max-width: 760px) {
    &+& {
      margin: 0.6rem 0 0 0;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    margin-top: 1.4rem;
  }
`;

export const CradTitle = styled.span`
  font-size: 1.1rem;
`;

export const InformationCard = styled.div`

`;

export const RowDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;

`;

export const DivInformations = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 1020px) {
    flex-direction: column;
  }
`;

export const DivSwitch = styled.div`
  width: 100%;
  max-width: 1020px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1.5rem;
`;

export const ContentCollumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
