import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import EngineModal from '../../engineModal';
import './motorSVAuto.css';

const colorStateEngine = {
  off1: '#CCCCCC',
  off2: '#ACABAB',
  on1: '#D6E0E6',
  on2: '#556E7B',
};

function Propeller({ onOff }) {
  const color = onOff === 'L' ? colorStateEngine.on2 : colorStateEngine.off2;
  return (
    <path
      d="M 19.821 16.022 L 19.147 12.337 C 21.771 11.32 24.599 11.32 27.087 12.358 C 26.378 9.107 24.391 6.332 21.678 4.59 C 19.202 5.611 17.182 7.617 16.037 10.161 L 12.326 10.806 C 11.354 8.207 11.37 5.375 12.396 2.914 C 9.114 3.62 6.316 5.628 4.57 8.371 C 5.595 10.827 7.59 12.83 10.116 13.97 L 10.783 17.665 C 8.189 18.629 5.366 18.608 2.912 17.582 C 3.609 20.863 5.605 23.664 8.337 25.418 C 10.807 24.399 12.824 22.4 13.97 19.864 L 17.699 19.143 C 18.693 21.756 18.685 24.609 17.655 27.086 C 20.906 26.377 23.68 24.389 25.421 21.675 C 24.396 19.19 22.378 17.165 19.821 16.022 Z"
      id="PropellerMotor"
      style={{ fill: color }}
      transform="matrix(-0.959553, -0.281529, 0.281529, -0.959553, 25.169369, 33.616089)"
    />
  );
}

function EngineAnimated() {
  return (
    <g
      id="PropellerMotor_rJJpYhYvM"
      data-animator-group="true"
      data-animator-type="1"
      transform="matrix(0.242751, 0.970089, -0.970089, 0.242751, 443.282471, -54.317905)"
    >
      <Propeller onOff="L" />
    </g>
  );
}

export default function MotorSVAuto({ motor }) {
  const [openModal, setOpenModal] = useState(false);
  // const [radius, setRadius] = useState();

  const color = motor.engineOnOff === 'L' ? colorStateEngine.on1 : colorStateEngine.off1;

  // useEffect(() => {
  //   if (!diameter) {
  //     diameter = 30;
  //   }
  //   setRadius(diameter / 2);
  //   if ((!motor)) {
  //     return '';
  //   }
  // }, []);

  function handleOpenModal() {
    setOpenModal(true);
  }

  return (
    <>
      <Tooltip title={motor.engineName} arrow>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          id="S1NZ3tnYwf"
          x="0px"
          y="0px"
          width={`${30}px`}
          height={`${30}px`}
        // viewBox="0 0 30 30"
        // style={{ enableBackground: 'new 0 0 30 30' }}
        // preserveAspectRatio="xMidYMid meet"
          onClick={handleOpenModal}
        >

          <circle
            cx="25"
            cy="25"
            r="14.927"
            id="CircleBackMotor"
            style={{ fill: color }}
            transform="matrix(1, 0, 0, 1.00016, -10.07365, -10.075232)"
          />

          { motor.engineOnOff === 'L' ? (

            <EngineAnimated />

          )
            : (

              <Propeller onOff="D" />

            )}
          <circle
            cx="-255.998"
            cy="-255.998"
            r="2"
            id="BkF-3F2Kvf"
            style={{ fill: color }}
            transform="matrix(-0.242714, -0.970098, 0.969943, -0.242753, 201.243419, -295.558252)"
          />

        </svg>
      </Tooltip>
      <EngineModal engineInfo={motor} openModal={openModal} closeModal={setOpenModal} />
    </>
  );
}
