import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-width: 400px;

  flex: 1;

  .row {
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
  }

  .confirm-button {
   display: flex;
   justify-content: flex-end;
  }
`;
