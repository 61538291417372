import React from 'react';
import '../../App.css';
import InputLabel from '@material-ui/core/InputLabel';
import {
  FormControl,
  Checkbox,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import jwtDecode from 'jwt-decode';
import FockinkAPI from '../../middlewares/Api';

export default ({
  selectedUnits, setSelectedUnits, label, selectAllLabel }) => {
  const [loading, setLoading] = React.useState(true);
  const [units, setUnits] = React.useState([]);
  const token = localStorage.getItem('@FockinkAPP:token');
  const { unit } = jwtDecode(token);
  const urlParams = new URLSearchParams(window.location.search);
  const url = urlParams.get('accountId');

  React.useEffect(() => {
    FockinkAPI.get(
      `/units?withEquipaments=true&accountId=${url}`,
    ).then((response) => {
      const unitsRaw = response.data.data.map((u) => ({ id: u.id,
        name: u.name,
        description: u.description,
        equipaments: u.equipaments,
      }));

      if (response.data.equipamentsWithoutUnit.length > 0) {
        unitsRaw.push({ id: 'NDEF',
          name: 'Não Associados',
          description: 'Não Associados',
          equipaments: response.data.equipamentsWithoutUnit,
        });
      }

      setUnits(unitsRaw);
      setLoading(false);
    });
  }, []);

  const handleChangeSelect = (event) => {
    if (event.target.value.indexOf('Todas') > -1) {
      setSelectedUnits([]);
    } else {
      const u = [];

      event.target.value.forEach((e) => {
        if (typeof e === 'string') u.push(e);
        if (Array.isArray(e)) {
          e.forEach((f) => {
            if (typeof f === 'string') u.push(f);
            else console.error('selected a invalid value', f, typeof f);
          });
        }
      });

      const ret = { units: u, equipamentIds: [] };
      units.forEach((un) => {
        if (u.indexOf(un.id) !== -1) {
          ret.equipamentIds.push(...(un.equipaments || []).map((e) => e.id));
        }
      });

      ret.equipamentIds = [...new Set(ret.equipamentIds)];
      setSelectedUnits(ret);
    }
  };

  return (
    <FormControl variant="filled">
      <InputLabel shrink>{label || unit?.plural || 'Unidades'}</InputLabel>
      <Select
        multiple
        disabled={loading}
        value={selectedUnits}
        onChange={handleChangeSelect}
        name="plants"
        renderValue={(selected) =>
          selected.length > 0
            ? `${unit?.plural || 'Unidades'} selecionadas: ${selected.length}`
            : (selectAllLabel || `Todas ${unit?.plural || 'Unidades'} Selecionadas`)
              }
        displayEmpty
      >
        {units?.map((s) => (
          <MenuItem key={s.id} value={s.id}>
            <Checkbox
              checked={!!selectedUnits.find((f) => f === s.id)}
            />
            <ListItemText primary={s.description} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
