import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../App.css';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  RiProfileLine,
} from 'react-icons/ri';
import FockinkAPI from '../middlewares/Api';

// const useStyles = makeStyles({
//   table: {
//     minWidth: 650,
//   },
// });

export default (props) => {
  const [search, setSearch] = React.useState({ gnf: '' });

  const searchClients = () => {
    const sendData = {
      gnf: search.gnf,
    };
    FockinkAPI.post('/technicalDelivery/validateTicket', sendData).then((response) => {
      props.setClients([{
        ...response.data.data.clienteNegocio,
        gnf: response.data.data.codigo,
      }]);
    });
  };

  const handleChange = (event) => {
    setSearch({ ...search, [event.target.name]: event.target.value });
  };

  return (
    <ValidatorForm onSubmit={searchClients}>

      <Grid container spacing={3}>
        <Grid item lg={4} md={4} xs={12}>
          <TextValidator
            style={{ width: '100%' }}
            name="gnf"
            onChange={handleChange}
            value={search.gnf}
            label="GNF"
            variant="outlined"
            validators={['required']}
            errorMessages={['Campo obrigatório']}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <RiProfileLine size={20} />
                </InputAdornment>
              ),
            }}
          />
          <Button style={{ marginTop: '10px', marginBottom: '20px' }} variant="contained" color="primary" type="submit" startIcon={<SearchIcon />}> Buscar </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};
