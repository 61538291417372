import React from 'react';
import { formatDate } from '../../../../../../helpers/Intl';

export const columns = [
  {
    name: 'Data de alteração',
    selector: 'date',
    center: true,
    cell: (row) => (
      <strong>{`${formatDate(row.date, 'dd/MM/yyyy HH:mm')}h`}</strong>
    ),
  },
  {
    name: 'Usuário',
    selector: 'user.name',
    maxWidth: '200px',
    left: true,
    cell: (row) => (
      <strong>{row.user ? row.user.name : 'Usuário não identificado'}</strong>
    ),
  },
  {
    name: 'Min. Threshold',
    selector: 'thresholdMin',
    left: true,
    maxWidth: '20px',
    cell: (row) => (
      <div>
        <span>
          {row.thresholdMin !== undefined ? `${row.thresholdMin}` : 'N/A'}
        </span>
      </div>
    ),
  },
  {
    name: 'Max. Threshold',
    selector: 'thresholdMax',
    maxWidth: '20px',
    left: true,
    cell: (row) => (
      <div>
        <span>
          {row.thresholdMax !== undefined ? `${row.thresholdMax}` : 'N/A'}
        </span>
      </div>
    ),
  },
];
