// Basic
import React from 'react';

// UI, images, styles and icons
import '../../../App.css';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Isotope from 'isotope-layout';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import RoomIcon from '@material-ui/icons/Room';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Tooltip from '@material-ui/core/Tooltip';
import Skeleton from '@material-ui/lab/Skeleton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import jwtDecode from 'jwt-decode';
import EquipCard from '../../../components/EquipCard';

import './styles.css';

// API
import FockinkAPI from '../../../middlewares/Api';

// Solutions
const solutions = [];
solutions[1] = 'irrigation';
solutions[2] = 'thermometry';
solutions[3] = 'automation';
solutions[4] = 'photovoltaic';
solutions[5] = 'weatherStation';

const useStyles = makeStyles({
  spacing: {
    marginTop: '15px',
  },
  bottomButtons: {
    margin: '25px 0',
    textAlign: 'right',
  },
  noMargin: {
    margin: 0,
  },
  toggleButton: {
    paddingTop: 0,
    paddingBottom: 0,
    height: '49px',
  },
  smallIcon: {
    height: '30px',
  },
});

export default (props) => {
  // Stats
  const [loading, setLoading] = React.useState(true);
  const [inputs, setInputs] = React.useState([]);
  const [isotope, setIsotope] = React.useState(null);
  const [solution, setSolution] = React.useState('*');
  const [filterKey, setFilterKey] = React.useState(['*']);
  const [ordenation, setOrdenation] = React.useState('original-order');
  const [sortKey, setSortKey] = React.useState('original-order');
  const [filterTags, setFilterTags] = React.useState([]);
  const [tagFilter, setTagFilter] = React.useState('*');
  const token = localStorage.getItem('@FockinkAPP:token');
  const { systemIds, unit } = jwtDecode(token);
  const history = useHistory();

  // Lifecycle
  React.useEffect(() => {
    [
      '/images/icons/automationIcon.svg',
      '/images/icons/powerPlantIcon.svg',
      '/images/icons/pivotIcon.svg',
      '/images/icons/thermometryIcon.svg',
      '/images/icons/wheater-station.svg',
    ].forEach((image) => {
      new Image().src = image;
    });
  }, []);
  React.useEffect(() => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const pid = urlParams.get('plantId');
    FockinkAPI.get('/dashboards/equipaments', {
      params: { plantId: pid, extended: true },
    }).then((response) => {
      setInputs(response.data.data.equipaments || []);
      setLoading(false);
      setFilterTags(response.data.data.units || []);
      // console.log(response.data.data);
      setIsotope(
        new Isotope('.filter-container', {
          itemSelector: '.filter-item',
          layoutMode: 'masonry',
          masonry: {
            percentPosition: true,
          },
          transitionDuration: '0.3s',
          // containerStyle: null,
          getSortData: {
            name: '[data-name]',
            solution: '[data-solution]',
            status: '[data-status]',
          },
          sortAscending: {
            name: true,
            solution: true,
            status: false,
          },
        }),
      );
    });
  }, []);

  React.useEffect(() => {
    if (isotope) {
      const filterKeyF = filterKey.filter((f) => f && f !== '*');
      if (filterKeyF.length === 0) {
        isotope.arrange({ filter: '*' });
      } else {
        isotope.arrange({ filter: `.${filterKeyF.join('.')}` });
      }
    }
  }, [isotope, filterKey]);

  React.useEffect(() => {
    if (isotope) {
      if (sortKey === 'original-order') {
        isotope.arrange({ sortBy: 'name' });
      } else {
        isotope.arrange({ sortBy: sortKey });
      }
    }
  }, [isotope, sortKey]);

  React.useEffect(() => {
    if (isotope) {
      if (sortKey === 'original-order') {
        isotope.arrange({ sortBy: 'name' });
      } else {
        isotope.arrange({ sortBy: sortKey });
      }
    }
  }, [isotope, sortKey]);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const oid = urlParams.get('op');
    const systems = [
      'automation',
      'photovoltaic',
      'irrigation',
      'thermometry',
      'weatherStation',
    ];
    if (systems.indexOf(oid) !== -1) {
      setSolution(oid);
      setFilterKey([oid, tagFilter]);
    } else if (!oid) {
      setSolution('*');
      setFilterKey(['*', tagFilter]);
    }
  }, [window.location.search]);

  // Handle methods
  const handleSolution = (event, currentSolution) => {
    if (currentSolution !== null && currentSolution !== '*') {
      const params = new URLSearchParams(window.location.search);
      params.set('op', currentSolution);
      history.push({ search: params.toString() });
    } else if (currentSolution !== null && currentSolution === '*') {
      const params = new URLSearchParams(window.location.search);
      params.delete('op');
      history.push({ search: params.toString() });
    }
  };

  const handleOrder = (event, currentOrder) => {
    if (currentOrder !== null) {
      setOrdenation(currentOrder);
      setSortKey(currentOrder);
    }
  };

  const handleView = (event, currentSolution) => {
    if (currentSolution && currentSolution === 'map') {
      const params = new URLSearchParams(window.location.search);
      params.set('view', currentSolution);
      history.push({ search: params.toString() });
    } else if (currentSolution && currentSolution === 'cards') {
      const params = new URLSearchParams(window.location.search);
      params.delete('view');
      history.push({ search: params.toString() });
    }
  };

  const formatClass = (name) => (name ? name.replace(/[^\w]/gi, '') : 'undef');

  const classes = useStyles();
  return (
    <>
      <Grid container spacing={3} alignItems="flex-end">
        <Grid item>
          <p className={classes.noMargin}>Sistemas</p>
          <ToggleButtonGroup
            aria-label="device"
            value={solution}
            exclusive
            onChange={handleSolution}
          >
            <ToggleButton value="*" aria-label="all">
              Todos
            </ToggleButton>
            {systemIds.find((x) => x === 3) && (
              <ToggleButton
                value="automation"
                aria-label="automation"
                className={classes.toggleButton}
              >
                <Tooltip title="Automação">
                  <img
                    src="/images/icons/automationIcon.svg"
                    alt="Automação"
                    className={classes.smallIcon}
                  />
                </Tooltip>
              </ToggleButton>
            )}
            {systemIds.find((x) => x === 4) && (
              <ToggleButton
                value="photovoltaic"
                aria-label="photovoltaic"
                className={classes.toggleButton}
              >
                <Tooltip title="Fotovoltaica">
                  <img
                    src="/images/icons/powerPlantIcon.svg"
                    alt="Fotovoltaica"
                    className={classes.smallIcon}
                  />
                </Tooltip>
              </ToggleButton>
            )}

            {systemIds.find((x) => x === 1) && (
              <ToggleButton
                value="irrigation"
                aria-label="irrigation"
                className={classes.toggleButton}
              >
                <Tooltip title="Irrigação">
                  <img
                    src="/images/icons/pivotIcon.svg"
                    alt="Irrigação"
                    className={classes.smallIcon}
                  />
                </Tooltip>
              </ToggleButton>
            )}
            {systemIds.find((x) => x === 2) && (
              <ToggleButton
                value="thermometry"
                aria-label="thermometry"
                className={classes.toggleButton}
              >
                <Tooltip title="Termometria">
                  <img
                    src="/images/icons/thermometryIcon.svg"
                    alt="Termometria"
                    className={classes.smallIcon}
                  />
                </Tooltip>
              </ToggleButton>
            )}
            {systemIds.find((x) => x === 5) && (
              <ToggleButton
                value="weatherStation"
                aria-label="weatherStation"
                className={classes.toggleButton}
              >
                <Tooltip title="Estação Meteorológica">
                  <img
                    src="/images/icons/wheater-station.svg"
                    alt="Estação"
                    className={classes.smallIcon}
                  />
                </Tooltip>
              </ToggleButton>
            )}
          </ToggleButtonGroup>
        </Grid>

        <Grid item>
          <p className={classes.noMargin}>Ordenação</p>
          <ToggleButtonGroup
            aria-label="device"
            value={ordenation}
            exclusive
            onChange={handleOrder}
          >
            <ToggleButton value="original-order" aria-label="original">
              Nome
            </ToggleButton>
            <ToggleButton value="solution" aria-label="solution">
              Sistema
            </ToggleButton>
            <ToggleButton value="status" aria-label="status">
              Status
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={12} md={3} lg={3}>
          <FormControl variant="filled">
            <InputLabel>{unit?.singular || 'Unidade'}</InputLabel>
            <Select
              value={tagFilter}
              onChange={(e) => {
                setTagFilter(e.target.value);
                setFilterKey([e.target.value, solution]);
              }}
              name="operationId"
            >
              <MenuItem value="*">Todos</MenuItem>
              {filterTags.map((f) => (
                <MenuItem key={formatClass(f.name)} value={formatClass(f.name)}>
                  {`${f.name} (${f.description})`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <p className={classes.noMargin}>Visão</p>
          <ToggleButtonGroup
            aria-label="device"
            value={props.view}
            exclusive
            onChange={handleView}
          >
            <ToggleButton value="cards" className={classes.toggleButton}>
              <Tooltip title="Visualização por Card">
                <DashboardIcon style={{ height: '36px' }} />
              </Tooltip>
            </ToggleButton>
            <ToggleButton value="map" className={classes.toggleButton}>
              <Tooltip title="Visualização por Mapa">
                <RoomIcon style={{ height: '36px' }} />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '0' }}>
          <Grid className="filter-container">
            {loading &&
              [...Array(15)].map((item) => (
                <div key={`${item}${Math.random()}`} className="filter-item">
                  <Skeleton variant="rect" height={150} />
                </div>
              ))}

            {(inputs || []).length === 0 && !loading && (
              <span>Nenhum equipamento cadastrado.</span>
            )}

            {(inputs || []).map((e, i) => (
              <div
                className={`filter-item ${
                  solutions[e.operationId]
                } ${formatClass(e.unit && e.unit.name)}`}
                data-solution={solutions[e.operationId]}
                data-name={e.description}
                data-order={i}
                data-status={e.running}
                key={e.id}
              >
                <Grid>
                  <EquipCard data={e} />
                </Grid>
              </div>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
