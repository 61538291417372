import React from 'react';
import colors from '../../../styles/colors';

export default (props) => {
  const color = props.status ? colors.pivotOk : colors.darkGrey;
  const backgroundColor = 'none';

  return (
    <svg viewBox="0 0 500 500" width="100%">
      <g>
        <rect
          width="500"
          height="500"
          style={{ fill: backgroundColor }}
          rx="57.152"
          ry="57.152"
        />
        <g>
          <path
            style={{ fill: color, fillOpacity: 0.3 }}
            d="M 277.759 475.86 C 109.023 496.187 -18.442 326.229 48.322 169.935 C 110.621 24.096 301.786 -10.451 411.188 104.36 L 251.353 256.665 Z M 250.103 385.263 C 176.328 384.673 112.87 319.017 124.2 238.723 C 136.471 151.751 228.31 105.562 303.251 139.067 L 302.918 139.655 L 289.284 163.711 L 343.822 148.608 L 328.761 94.059 L 315.127 118.115 L 315.038 118.273 C 244.139 85.635 159.922 111.882 120.17 179.214 C 60.548 280.2 132.605 407.858 249.872 408.999 Z"
          />
          <polygon
            style={{ fill: color }}
            points="248.179 254.663 418.481 84.361 426.278 92.158 256.813 261.623"
          />
        </g>
      </g>
    </svg>
  );
};
