import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import '../../../../../App.css';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import { Button } from '@material-ui/core';
import FockinkAPI from '../../../../../middlewares/Api';
import BottomButtons from '../../../../../components/Buttons/BottomButtons';
import Breadcrumbs from '../../../../../components/Breadcrumbs';
import AdminPermissions from '../../../../../helpers/enums/AdminPermissions';
import { PermissionsModal } from '../../../../../components/PermissionsModal';
import Card from '../../../../../components/Card';
import CardText from '../../../../../components/CardText';
import CardTitle from '../../../../../components/CardTitle';
import Spacer from '../../../../../components/Spacer';

const useStyles = makeStyles({
  textAlign: {
    textAlign: 'right',
  },
  btnSpace: {
    marginLeft: '15px',
  },
  bottomButtons: {
    marginTop: '15px',
    borderTop: '1px solid #ccc',
    paddingTop: '10px !important',
  },
});

export default (props) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const [permissionsApi, setPermissionsApi] = useState([]);
  const [defaultPermissionsProfile, setDefaultPermissionsProfile] = useState(
    [],
  );
  const [allowed, setAllowed] = useState([]);
  const [blocked, setBlocked] = useState([]);

  const checkUserCustomPermissions = (
    customPermissions,
  ) => {
    const { allow, block } = customPermissions;
    setAllowed(allow);
    setBlocked(block);

    return [...allow, ...block];
  };

  const [data, setData] = useState({
    status: 'A',
    description: '',
    clientId: '',
    userLimit: 0,
  });

  const profiles = [
    { profileId: 51, description: 'Gestor' },
    { profileId: 52, description: 'Supervisor' },
    { profileId: 53, description: 'Operador' },
    { profileId: 54, description: 'Visualizador' },
  ];

  const breadcrumb = {
    title: 'Cadastro de Usuário',
    routes: [
      { to: '/organization', name: 'Organizações' },
      { to: `/organization/${props.match.params.orgId}`, name: 'Organização' },
      { to: `/organization/${props.match.params.orgId}/account/${props.match.params.accId}`, name: 'Conta' },
      { name: 'Cadastro de Usuário' }],
  };

  useEffect(() => {
    (async () => {
      let customPermissions;

      if (props.match.params.id === 'new') {
        setData({
          status: 'A', description: '', plan: {}, plantId: '',
        });
        setLoading(false);
      } else {
        const response = await FockinkAPI.get(`/users/${id}`);
        setData({
          ...response.data.data,
          profileId: response.data.data.profile.profileId,
        });

        const responseProfiles = await FockinkAPI.get('/users/profiles');
        const permissionsArray = [
          responseProfiles.data.data.permissions.portal.Automação,
          responseProfiles.data.data.permissions.portal.Fotovoltaica,
          responseProfiles.data.data.permissions.portal.Irrigação,
          responseProfiles.data.data.permissions.portal.Plantas,
          responseProfiles.data.data.permissions.portal.Tarifador,
          responseProfiles.data.data.permissions.portal.Termometria,
          responseProfiles.data.data.permissions.portal.Unidades,
          responseProfiles.data.data.permissions.portal.Usuários,
        ];
        const portalPermissions = permissionsArray.reduce((acc, x) => acc.concat(x), []);
        const formatUserPermissionsApiCode = portalPermissions.map(
          (permission) => permission.code,
        );

        customPermissions = response.data.data.customPermissions;
        if (customPermissions) {
          const permissionsAlredyChecked = checkUserCustomPermissions(customPermissions);
          for (let i = 0; i < permissionsAlredyChecked.length; i += 1) {
            const index = formatUserPermissionsApiCode.indexOf(permissionsAlredyChecked[i]);
            formatUserPermissionsApiCode.splice(index, 1);
          }
          setDefaultPermissionsProfile(formatUserPermissionsApiCode);
        } else {
          setDefaultPermissionsProfile(formatUserPermissionsApiCode);
        }

        setLoading(false);
      }

      const responseProfiles = await FockinkAPI.get('/users/profiles');
      const { permissions } = responseProfiles.data.data;
      setPermissionsApi(permissions);
    })();
  }, [props.match.params.id]);

  const classes = useStyles();

  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  const saveData = () => {
    const dt = {
      data: {
        accountId: props.match.params.accId,
        name: data.name,
        login: data.login,
        profileId: data.profileId,
        status: data.status,
        loginIsNotEmail: false,
      },
    };

    if (allowed.length > 0 || blocked.length > 0) {
      dt.data.customPermissions = {
        allow: allowed,
        block: blocked,
      };
    }

    if (props.match.params.id === 'new') {
      dt.method = 'post';
      dt.url = '/users';
    } else {
      dt.method = 'put';
      dt.url = `/users/${props.match.params.id}`;
    }
    return dt;
  };

  const handleModalState = () => {
    setOpenPermissionModal(!openPermissionModal);
  };

  const deleteData = () => ({ url: `/users/${props.match.params.id}` });
  if (loading) return <Skeleton variant="rect" height={500} width="100%" animation="wave" />;

  const handleUpdatePermissonsProfileUser = async () => {
    const dataRequest = {
      accountId: data.accountId,
      name: data.name,
      login: data.login,
      profileId: data.profileId,
      loginIsNotEmail: !!data.loginIsNotEmail,
      status: data.status,
    };

    if (allowed.length > 0 || blocked.length > 0) {
      dataRequest.customPermissions = {
        allow: allowed,
        block: blocked,
      };
    }

    handleModalState();
  };

  const handleBlockSelectPermission = (eventChecked) => {
    const copyBlockedPermission = blocked.slice();
    const newDefaultPermissions = defaultPermissionsProfile.slice();

    const { checked, value } = eventChecked.target;
    const formattedPermission = Number(value);
    if (checked) {
      const defaultPermissionIndex = newDefaultPermissions.indexOf(formattedPermission);
      const allowedPermissionIndex = allowed.indexOf(formattedPermission);

      if (defaultPermissionIndex > -1) {
        newDefaultPermissions.splice(defaultPermissionIndex, 1);
        setDefaultPermissionsProfile(newDefaultPermissions);
      }

      if (allowedPermissionIndex > -1) {
        const newAllowedPermission = allowed.slice();
        newAllowedPermission.splice(allowedPermissionIndex, 1);
        setAllowed(newAllowedPermission);
      }

      copyBlockedPermission.push(formattedPermission);
      setBlocked(copyBlockedPermission);
      return;
    }

    const index = copyBlockedPermission.indexOf(formattedPermission);
    copyBlockedPermission.splice(index, 1);
    newDefaultPermissions.push(formattedPermission);
    setDefaultPermissionsProfile(newDefaultPermissions);
    setBlocked(copyBlockedPermission);
  };

  const handleAllowedSelectPermission = (eventChecked) => {
    const { checked, value } = eventChecked.target;
    const copyAllowedPermission = allowed.slice();
    const newDefaultPermissions = defaultPermissionsProfile.slice();
    const copyBlockedPermissions = blocked.slice();
    const formattedPermission = Number(value);
    if (checked) {
      const defaultPermissionIndex = newDefaultPermissions.indexOf(formattedPermission);
      const blockedPermissionIndex = copyBlockedPermissions.indexOf(formattedPermission);

      if (defaultPermissionIndex > -1) {
        newDefaultPermissions.splice(defaultPermissionIndex, 1);
        setDefaultPermissionsProfile(newDefaultPermissions);
      }

      if (blockedPermissionIndex > -1) {
        copyBlockedPermissions.splice(blockedPermissionIndex, 1);
        setBlocked(copyBlockedPermissions);
      }

      copyAllowedPermission.push(formattedPermission);
      setAllowed(copyAllowedPermission);
      return;
    }

    const index = copyAllowedPermission.indexOf(formattedPermission);
    copyAllowedPermission.splice(index, 1);
    newDefaultPermissions.push(formattedPermission);
    setDefaultPermissionsProfile(newDefaultPermissions);
    setAllowed(copyAllowedPermission);
  };

  const handleHereditarySelectPermission = (eventChecked) => {
    const { checked, value } = eventChecked.target;
    const formattedPermission = Number(value);

    const copyBlockedPermissions = blocked.slice();
    const copyAllowedPermissions = allowed.slice();
    const copyDefautlPermission = defaultPermissionsProfile.slice();

    if (!checked) {
      return;
    }

    const blockedPermissionIndex = copyBlockedPermissions.indexOf(formattedPermission);
    const allowerPermissionIndex = copyAllowedPermissions.indexOf(formattedPermission);
    if (blockedPermissionIndex > -1) {
      copyBlockedPermissions.splice(blockedPermissionIndex, 1);
      setBlocked(copyBlockedPermissions);
    }

    if (allowerPermissionIndex > -1) {
      copyAllowedPermissions.splice(allowerPermissionIndex, 1);
      setAllowed(copyAllowedPermissions);
    }

    copyDefautlPermission.push(formattedPermission);
    setDefaultPermissionsProfile(copyDefautlPermission);
  };

  return (
    <>
      <Breadcrumbs routes={breadcrumb.routes} title={breadcrumb.title} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card>
            <CardTitle>
              Usuário
              <Spacer />
            </CardTitle>
            <CardText>
              <Grid container spacing={3}>

                <Grid item lg={4} md={4} xs={12}>
                  <TextField variant="outlined" name="name" onChange={handleChange} value={data.name || ''} label="Nome" />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <TextField variant="outlined" name="login" onChange={handleChange} value={data.login || ''} label="Login" />
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <FormControl variant="outlined">
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={data.status}
                      onChange={handleChange}
                      name="status"
                      label="Status"
                    >
                      <MenuItem value="A">Ativo</MenuItem>
                      <MenuItem value="I">Inativo</MenuItem>
                      <MenuItem value="C">Deletado</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item lg={4} md={4} xs={12}>
                  <FormControl variant="outlined">
                    <InputLabel>Perfil</InputLabel>
                    <Select
                      value={data.profileId}
                      onChange={handleChange}
                      name="profileId"
                      label="Perfil"
                    >
                      {profiles.map((m) => (
                        <MenuItem value={m.profileId} key={m.profileId}>
                          {m.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item lg={4} md={4} xs={12}>
                  <Button
                    size="medium"
                    variant="contained"
                    color="secondary"
                    style={{ ...props.style }}
                    onClick={() => handleModalState()}
                  >
                    Revisar Permissões
                  </Button>

                  <PermissionsModal
                    itsOpen={openPermissionModal}
                    closeModal={handleModalState}
                    user={data}
                    handleAction={handleModalState}
                    parmissionList={permissionsApi}
                    allowed={allowed}
                    blocked={blocked}
                    handleHereditarySelectPermission={handleHereditarySelectPermission}
                    handleAllowedSelectPermission={handleAllowedSelectPermission}
                    handleBlockSelectPermission={handleBlockSelectPermission}
                    handleUpdatePermissonsProfileUser={handleUpdatePermissonsProfileUser}
                    defaultPermissionsProfile={defaultPermissionsProfile}
                  />
                </Grid>
              </Grid>
            </CardText>
          </Card>
        </Grid>

        <Grid item xs={12} className={`${classes.textAlign} ${classes.bottomButtons}`}>

          <BottomButtons
            saveData={saveData}
            deleteData={props.match.params.id !== 'new' && deleteData}
            backTo={`/organization/${props.match.params.orgId}/account/${props.match.params.accId}/user`}
            canSave={AdminPermissions.USERS_MANAGE.value}
          />
        </Grid>
      </Grid>
    </>
  );
};
