import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 1rem;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  width: 720px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 720px) {
    width: 100%;
  }
`;

export const ButtonDeleteDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
