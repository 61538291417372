import React from 'react';

export function IconTemperature({ size, color }) {
  if (!size) {
    size = 50;
  }

  if (!color) {
    color = '#556E7B';
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 177 530"
    >
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M88 10c-20,0 -36,18 -36,37l0 327 -3 2c-25,13 -39,39 -39,66 0,44 36,78 78,78 44,0 78,-34 78,-78 0,-27 -15,-53 -38,-66l-3 -2 0 -327c0,-19 -16,-37 -37,-37l0 0zm0 520c-49,0 -88,-39 -88,-88 0,-31 16,-58 41,-74l0 -321c0,-26 21,-47 47,-47 26,0 49,21 49,47l0 321c24,16 40,43 40,74 0,49 -40,88 -89,88l0 0z"
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M125 446c0,19 -16,37 -37,37 -20,0 -38,-18 -38,-37 0,-22 18,-38 38,-38 21,0 37,16 37,38z"
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="93,408 85,408 85,174 93,174 "
      />
    </svg>
  );
}

export function IconHumidity({ size, color }) {
  if (!size) {
    size = 50;
  }

  if (!color) {
    color = '#556E7B';
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 427 643"
    >
      <path
        stroke={color}
        strokeWidth="15"
        fill="#FFFFFF"
        d="M399 331l-185 -321c-62,107 -124,214 -187,321 -64,111 12,299 187,307 191,-11 244,-201 185,-307z"
      />
      <text
        fill={color}
        fontSize="250"
        fontFamily="Roboto"
        fontWeight="800"
        x="120"
        y="430"
      >
        %
      </text>
    </svg>
  );
}

export function IconRain({ size, color }) {
  if (!size) {
    size = 50;
  }

  if (!color) {
    color = '#556E7B';
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 555 483"
    >
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="141,309 146,310 134,356 129,354 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="119,391 126,392 113,438 106,436 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="192,321 199,323 187,368 180,366 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="171,401 176,403 164,448 159,446 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="358,310 364,312 352,358 345,356 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="337,391 342,394 330,439 324,438 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="401,351 408,352 396,398 389,396 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="380,433 385,434 373,480 368,478 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="155,354 162,356 150,401 143,399 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="134,436 141,438 129,483 122,481 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="223,303 230,305 218,351 211,349 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="202,385 209,387 197,433 190,431 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="387,298 394,300 382,345 375,344 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="366,380 373,382 361,427 354,426 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="441,305 446,307 436,352 429,351 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="419,387 426,389 413,434 406,433 "
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M555 249c0,-52 -39,-96 -89,-104 -9,-40 -30,-75 -61,-103 -32,-26 -74,-42 -115,-42 -70,0 -133,40 -163,105 0,0 0,0 -1,0 -70,0 -126,55 -126,125 0,33 12,65 37,89 15,16 35,26 55,33l0 -8c-48,-16 -83,-61 -83,-114 0,-64 52,-117 117,-117 1,0 3,0 5,0l2 0 1 -3c26,-61 87,-101 156,-101 83,0 153,59 167,141l2 3 1 0c49,6 86,48 86,96 0,49 -37,91 -82,98l0 9c23,-4 44,-14 61,-32 19,-19 30,-47 30,-75l0 0z"
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M242 351l42 0 -21 68 63 -82 -42 0 21 -68 -63 82zm13 85l24 -82 -44 0 79 -103 -24 82 43 0 -78 103z"
      />
    </svg>
  );
}

export function IconDewPoint({ size, color }) {
  if (!size) {
    size = 50;
  }

  if (!color) {
    color = '#556E7B';
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 748 500"
    >
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M471 410l-27 -128 -97 86c-1,0 -13,14 -19,30 -2,8 -5,26 0,45 6,22 22,39 46,48 25,7 48,4 67,-11 15,-12 24,-28 27,-36 4,-16 3,-32 3,-34l0 0zm-22 -138l26 137c2,1 3,19 -3,37 -3,9 -12,27 -28,39 -13,10 -37,20 -71,12l0 0 0 0c-33,-14 -45,-36 -51,-53 -5,-20 -2,-40 1,-49 6,-16 20,-31 20,-31l0 0 106 -92 0 0z"
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M203 106l-176 304 278 0 0 15 -305 0 189 -328c2,-1 30,-45 73,-70 21,-12 72,-30 128,-27 47,5 114,26 164,106l0 0 0 0c45,84 30,152 9,194 -24,48 -61,80 -82,94l-7 -14c21,-13 54,-43 74,-86 29,-58 26,-118 -7,-180 -37,-60 -88,-93 -152,-97 -54,-5 -100,13 -118,24 -40,22 -67,62 -68,65l0 0z"
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M541 112c-58,12 -125,37 -192,76 -57,33 -106,72 -143,110l-12 -7c38,-40 89,-79 149,-113 67,-41 135,-67 198,-81l0 15z"
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M748 78c-12,0 -24,0 -36,1 -62,3 -120,14 -168,30l-8 -12c53,-16 111,-27 175,-30 7,-1 16,-1 24,-1l13 12z"
      />
    </svg>
  );
}

export function IconThermicSensation({ size, color }) {
  if (!size) {
    size = 50;
  }

  if (!color) {
    color = '#556E7B';
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 298 489"
    >
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M54 68c-27,30 -26,77 4,105 14,14 34,21 53,20 -1,-1 -1,-4 -1,-5 -18,0 -36,-6 -48,-19 -28,-25 -29,-69 -4,-97 13,-11 28,-19 43,-20 1,-3 1,-4 3,-7 -18,2 -36,9 -50,23l0 0z"
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M106 50c1,-1 3,-3 3,-5 -2,0 -4,0 -5,0 -2,3 -2,4 -3,7 1,0 4,-2 5,-2z"
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="30,198 34,202 54,181 50,177 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="48,66 50,62 29,42 27,45 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="87,198 81,227 85,227 92,199 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="1,92 0,96 29,102 29,98 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="87,40 78,13 74,14 83,42 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="1,149 3,153 30,145 29,141 "
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M168 9c-19,0 -34,16 -34,35l0 301 -3 1c-21,13 -35,37 -35,62 0,39 33,72 72,72 40,0 72,-33 72,-72 0,-25 -13,-49 -35,-62l-3 -1 0 -301c0,-19 -15,-35 -34,-35l0 0zm0 480c-44,0 -81,-37 -81,-81 0,-28 14,-55 38,-70l0 -294c0,-24 19,-44 43,-44 24,0 44,20 44,44l0 294c23,15 38,42 38,70 0,44 -37,81 -82,81l0 0z"
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M203 410c0,19 -16,34 -35,34 -19,0 -34,-15 -34,-34 0,-19 15,-35 34,-35 19,0 35,16 35,35z"
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="172,375 164,375 164,236 172,236 "
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M225 121l0 33 0 5 0 9 0 26 0 0 0 2 6 0 0 -29c3,2 15,16 18,19l5 -4c-3,-4 -12,-12 -17,-17 -7,-7 -6,-5 -6,-12l0 -31c1,0 5,3 6,4l22 13c4,2 2,1 4,6 1,5 6,25 6,27l6 -2c0,-5 -5,-19 -6,-25 1,1 5,3 6,4 3,1 4,2 7,4l11 7c1,-1 2,-3 4,-5 -4,-4 -22,-12 -24,-15 2,-1 24,-6 25,-6l-1 -5c-4,0 -22,4 -28,6 -5,1 -4,1 -8,-1 -3,-3 -26,-17 -27,-18 1,-1 5,-2 6,-4l21 -12c4,-3 3,-3 9,-2 8,3 19,6 27,7l1 -5 -27 -7c4,-2 23,-13 26,-15 -2,-2 -3,-4 -4,-5 -4,1 -8,5 -11,8 -4,1 -8,5 -12,6l5 -25 -5 0c-2,2 -5,20 -6,26 -1,2 -1,3 -3,4l-30 19c-1,-10 0,-21 0,-31 0,-6 -1,-4 1,-8 3,-1 20,-19 22,-20 -2,-2 -4,-4 -5,-4 -5,4 -9,9 -14,14 -1,1 -4,4 -4,4l0 -28 -6 0 0 7 0 31c0,1 0,2 0,5l0 30 0 10 0 0z"
      />
    </svg>
  );
}

export function IconWindSpeed({ size, color }) {
  if (!size) {
    size = 50;
  }

  if (!color) {
    color = '#556E7B';
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 619 468"
    >
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M495 107c0,0 0,0 0,0l0 0c-3,-59 -51,-107 -110,-107l0 13c53,0 96,42 96,94 0,53 -43,94 -96,94l-236 0 0 13 238 0 0 0c59,0 105,-48 108,-107l0 0c0,0 0,0 0,0l0 0zm-390 94l-105 0 0 13 105 0 0 -13z"
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M606 171c0,0 0,0 0,-2l0 0c-2,-44 -39,-79 -83,-79l0 13c37,0 68,31 70,68 -2,37 -31,68 -70,68l-112 0 0 13 114 0 0 0c44,-2 79,-38 81,-81l0 0c0,0 0,0 0,0l0 0zm-238 68l-368 0 0 13 368 0 0 -13z"
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M619 374c0,-2 0,-2 0,-2l0 0c0,-50 -41,-92 -94,-94l0 0 -181 0 0 13 181 0c46,0 81,37 83,83 -2,44 -39,81 -83,81l0 13c51,0 94,-41 94,-94l0 0c0,0 0,0 0,0zm-319 -96l-300 0 0 13 300 0 0 -13z"
      />

    </svg>
  );
}

export function IconRadiation({ size, color }) {
  if (!size) {
    size = 50;
  }

  if (!color) {
    color = '#556E7B';
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 541 507"
    >
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M271 103c-92,0 -168,76 -168,168 0,92 76,167 168,167 92,0 167,-75 167,-167 0,-92 -75,-168 -167,-168zm0 348c-100,0 -182,-81 -182,-180 0,-100 82,-182 182,-182 99,0 180,82 180,182 0,99 -81,180 -180,180z"
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="265,0 276,0 276,71 265,71 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="541,265 541,276 469,276 469,265 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="71,265 71,276 0,276 0,265 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="130,38 140,34 176,95 166,101 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="366,445 374,441 410,502 401,507 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="502,130 507,140 445,176 441,166 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="95,366 101,374 38,411 34,401 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="34,140 38,130 101,166 95,176 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="441,374 445,366 507,401 502,411 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="401,34 410,38 374,101 366,95 "
      />
      <polygon
        stroke={color}
        strokeWidth="2"
        fill={color}
        points="166,441 176,445 140,507 130,502 "
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M173 213l11 0 0 68c0,8 0,12 2,15 0,4 1,9 2,12 3,2 6,5 10,7 5,1 9,2 13,2 4,0 7,0 11,-1 3,-3 6,-4 9,-7 1,-3 4,-7 4,-10 2,-3 2,-10 2,-18l0 -68 11 0 0 68c0,10 -1,18 -3,24 -1,7 -6,12 -11,17 -6,4 -14,7 -21,7 -10,0 -17,-3 -25,-7 -7,-5 -11,-10 -12,-17 -2,-5 -3,-13 -3,-24l0 -68 0 0zm95 0l11 0 38 87 39 -87 11 0 -48 113 -3 0 -48 -113z"
      />

    </svg>
  );
}

export function IconPreciptationRate({ size, color }) {
  if (!size) {
    size = 50;
  }

  if (!color) {
    color = '#556E7B';
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 473 513"
    >
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M236 124l27 -116 -112 40c-2,0 -16,7 -27,19 -4,5 -13,19 -17,38 -2,21 4,40 21,55 17,15 38,19 59,13 17,-3 28,-15 34,-21 11,-11 15,-26 15,-28l0 0zm35 -124l-29 126c0,0 -6,17 -17,30 -6,6 -19,17 -38,23 -15,4 -38,6 -63,-15l0 0 0 0c-23,-21 -25,-44 -21,-61 2,-19 11,-34 17,-40 11,-13 29,-19 29,-19l0 0 122 -44 0 0z"
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M210 414l19 -182 -166 82c0,2 -23,14 -36,35 -6,9 -17,32 -19,59 0,32 13,61 42,82 30,19 61,21 91,9 27,-11 44,-30 50,-40 15,-19 19,-44 19,-45l0 0zm28 -195l-21 196c0,0 -4,27 -19,50 -7,9 -26,30 -53,40 -23,10 -59,15 -99,-10l0 0 0 0c-40,-28 -46,-62 -46,-87 2,-29 13,-54 21,-65 15,-21 38,-34 40,-34l0 0 177 -90 0 0z"
      />
      <path
        stroke={color}
        strokeWidth="2"
        fill={color}
        d="M455 257l12 -143 -130 69c0,0 -17,9 -26,27 -6,5 -16,24 -16,45 0,27 12,48 35,63 24,15 49,17 72,6 21,-8 34,-23 38,-31 12,-15 14,-34 15,-36l0 0zm18 -152l-12 152c0,2 -4,23 -15,40 -6,8 -21,23 -42,33 -17,7 -46,13 -78,-6l0 0 0 0c-31,-21 -36,-48 -36,-67 0,-23 9,-44 15,-51 11,-17 28,-29 30,-29l0 0 138 -72z"
      />
    </svg>
  );
}
