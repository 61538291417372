import React from 'react';
import colors from '../../../styles/colors';

export default (props) => {
  const color = props.status ? colors.pivotOk : colors.darkGrey;
  const backgroundColor = 'none';

  return (
    <svg viewBox="0 0 500 500" width="100%">
      <g>
        <rect
          width="500"
          height="500"
          style={{ fill: backgroundColor }}
          rx="57.152"
          ry="57.152"
        />
        <g>
          <g>
            <path
              d="M 465 250 C 465 368.741 368.741 465 250 465 C 131.259 465 35 368.741 35 250 C 35 131.259 131.259 35 250 35 C 368.741 35 465 131.259 465 250 Z M 250 54.344 C 141.942 54.344 54.344 141.942 54.344 250 C 54.344 358.058 141.942 445.656 250 445.656 C 358.058 445.656 445.656 358.058 445.656 250 C 445.656 141.942 358.058 54.344 250 54.344 Z"
              style={{ fill: color }}
            />
            <path
              style={{ fill: color, fillOpacity: 0.3 }}
              transform="matrix(0.791339, 0, 0, 0.791339, -549.996887, -172.076187)"
              d="M 763.333 555.556 A 247.666 247.666 0 0 1 1010 285.668 L 1010 533.333 Z"
              shape="pie 1010 533.333 0 247.666 264.852 360 1@22929496"
            />
            <g>
              <g>
                <rect
                  x="244.364"
                  y="53.984"
                  width="9.651"
                  height="12.166"
                  style={{ fill: color }}
                />
                <rect
                  x="244.364"
                  y="434.123"
                  width="9.651"
                  height="12.166"
                  style={{ fill: color }}
                />
              </g>
              <g transform="matrix(0, -1, 1, 0, -0.947051, 499.326416)">
                <rect
                  x="244.364"
                  y="53.984"
                  width="9.651"
                  height="12.166"
                  style={{ fill: color }}
                />
                <rect
                  x="244.364"
                  y="434.123"
                  width="9.651"
                  height="12.166"
                  style={{ fill: color }}
                />
              </g>
              <g>
                <g transform="matrix(-0.5, -0.866026, 0.866026, -0.5, 157.390427, 591.009644)">
                  <rect
                    x="244.364"
                    y="53.984"
                    width="9.651"
                    height="12.166"
                    style={{ fill: color }}
                  />
                  <rect
                    x="244.364"
                    y="434.123"
                    width="9.651"
                    height="12.166"
                    style={{ fill: color }}
                  />
                </g>
                <g transform="matrix(-0.866026, -0.5, 0.5, -0.866026, 340.156372, 591.356262)">
                  <rect
                    x="244.364"
                    y="53.984"
                    width="9.651"
                    height="12.166"
                    style={{ fill: color }}
                  />
                  <rect
                    x="244.364"
                    y="434.123"
                    width="9.651"
                    height="12.166"
                    style={{ fill: color }}
                  />
                </g>
              </g>
              <g transform="matrix(0, -1, 1, 0, -0.716385, 499.557281)">
                <g transform="matrix(-0.5, -0.866026, 0.866026, -0.5, 157.390427, 591.009644)">
                  <rect
                    x="244.364"
                    y="53.984"
                    width="9.651"
                    height="12.166"
                    style={{ fill: color }}
                  />
                  <rect
                    x="244.364"
                    y="434.123"
                    width="9.651"
                    height="12.166"
                    style={{ fill: color }}
                  />
                </g>
                <g transform="matrix(-0.866026, -0.5, 0.5, -0.866026, 340.156372, 591.356262)">
                  <rect
                    x="244.364"
                    y="53.984"
                    width="9.651"
                    height="12.166"
                    style={{ fill: color }}
                  />
                  <rect
                    x="244.364"
                    y="434.123"
                    width="9.651"
                    height="12.166"
                    style={{ fill: color }}
                  />
                </g>
              </g>
            </g>
          </g>
          <g>
            <circle style={{ fill: color }} cx="250" cy="250" r="22.861" />
            <rect
              x="243.563"
              y="85.446"
              width="11.672"
              height="163.543"
              style={{ fill: color }}
              transform="matrix(0.360921, -0.932596, 0.932596, 0.360921, -75.954788, 392.316132)"
              origin="0.591 1.025"
            />
            <rect
              x="243.563"
              y="54.788"
              width="11.672"
              height="104.864"
              style={{ fill: color }}
              transform="matrix(0.929756, 0.368177, -0.368177, 0.929756, 78.763489, 5.552273)"
              origin="0.591 1.025"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
