import { Card, CardContent } from '@mui/material';
import React from 'react';
import DataTable from 'react-data-table-component';
import { number } from '../../../../../helpers/Intl';

const columns = [
  {
    name: '',
    selector: 'description',
    sortable: true,
    minWidth: 180,
  },
  {
    name: '',
    selector: 'data',
    sortable: true,
    minWidth: 80,
    maxWidth: '35%',
  },
];

export default function StationData({ data }) {
  const dataStation = [
    {
      description: 'Chuva (Últimas 24 Horas)',
      data: `${number(data?.rain24h > 0 ? data?.rain24h : 0, 2, 2)} mm`,
    },
    {
      description: 'Chuva (Últimas 12 Horas)',
      data: `${number(data?.rain12h > 0 ? data?.rain12h : 0, 2, 2)} mm`,
    },
    {
      description: 'Chuva (Últimas 6 Horas)',
      data: `${number(data?.rain6h > 0 ? data?.rain6h : 0, 2, 2)} mm`,
    },
    {
      description: 'Chuva (Últimas 3 Horas)',
      data: `${number(data?.rain3h > 0 ? data?.rain3h : 0, 2, 2)} mm`,
    },
    {
      description: 'Nível de Bateria ',
      data: data ? `${data?.batteryLevel} %` : '!',
    },
    {
      description: 'Pressão Atmosférica',
      data: `${data?.atmosphericPressure} hPa`,
    },
    {
      description: 'Evapotranspiração',
      data: `${number(data?.evapotranspiration, 2, 2)} mm`,
    },
  ];
  return (
    <Card>
      <CardContent>
        {/* <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}> */}
        <DataTable
          noDataComponent="Não existem dados para serem mostrados"
          columns={columns}
          data={dataStation}
          title="DADOS"
          style={{ display: 'flex' }}
          noTableHead
        />
        {/* </div> */}
      </CardContent>
    </Card>
  );
}
