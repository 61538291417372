import React from 'react';
import { useAuth } from '../../../../hooks/account';
import * as S from './styles';

export function Header() {
  const { user } = useAuth();

  return (
    <S.Wrapper>
      <strong>
        Bem-vindo,
        {' '}
        {user.name}
      </strong>
      <span>Navegue pelas opções de serviços disponíveis para seu perfil</span>
    </S.Wrapper>
  );
}
