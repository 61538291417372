import styled from 'styled-components';

export const SwitchCard = styled.div`
  width: 100%;
  max-width: 12rem;
  margin-top: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.6rem;
`;
