import React from 'react';
import { CarouselComponent } from '../../../../Carousel';
import { GrainCard } from '../GrainCard';

export function SiderGrainQuality({ grainQuality }) {
  const [width, setWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    if (window) {
      const resize = () => {
        if (window.innerWidth) {
          setWidth(window.innerWidth);
        }
      };
      window.addEventListener('resize', resize);

      return () => {
        window.removeEventListener('resize', resize);
      };
    }
  }, [window]);

  const makeGrainCard = grainQuality.map((grain, index) => (
    <GrainCard
      data={grain}
      indexKey={index}
      grain={grain.grain}
      date={grain.date}
      existData={grainQuality && grainQuality.length > 1}
    />
  ));

  return (
  // <div style={{ maxWidth: '100vw' }}>
    <CarouselComponent
      infinite={width > 760 && grainQuality.length > 1}
      centerMode={width > 760 && grainQuality.length > 1}
      autoPlay={width > 760 && grainQuality.length > 1}
    >
      {makeGrainCard}
    </CarouselComponent>
  // </div>

  );
}
